import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { percentage, selectors, t } from '@formue-app/core';
import { useSelector } from 'react-redux';
import { useCountUp } from 'react-countup';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_20,
  SPACING_24,
  SPACING_32,
  SPACING_4,
  SPACING_48,
  SPACING_64,
  SPACING_8,
} from '../../../constants/spacing';
import {
  accent,
  backgroundWhite,
  whiteTranslucent,
} from '../../../constants/colors';
import {
  H2,
  H4,
  Paragraph,
  ParagraphSmall,
  ParagraphXSmall,
} from '../../texts';
import { MultipleSwitch } from '../../formElements';
import { Modal } from '../../common/Modal';
import { SpriteIcon } from '../../common/SpriteIcon';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';
import { InvestmentStrategyStrategyPicker } from '../investmentStrategyComponents/InvestmentStrategyStrategyPicker';

import { ReactComponent as GraphicCostSectionOne } from '../../../assets/investment-strategy/graphic-cost-section-1.svg';
import { ReactComponent as GraphicCostSectionTwo } from '../../../assets/investment-strategy/graphic-cost-section-2.svg';
import { ReactComponent as GraphicCostSectionThree } from '../../../assets/investment-strategy/graphic-cost-section-3.svg';
import { useQueryState } from 'react-router-use-location-state';

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_48};
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_64};
  align-items: center;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(234, 226, 215, 0) 0%,
    rgba(234, 226, 215, 0) 40%,
    rgba(234, 226, 215, 0.5) 40%,
    rgba(234, 226, 215, 0) 100%
  );
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: ${SPACING_32};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SPACING_32};
  height: 475px;
`;

const InfoBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 250px;
  height: 113px;
  margin: 0 auto;
  padding: ${SPACING_16};
  padding-top: ${SPACING_24};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${SPACING_8};
  background: ${whiteTranslucent(0.4)};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  position: relative;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_4};
  text-align: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const Footnote = styled.div`
  display: flex;
  gap: ${SPACING_8};
  margin-top: ${SPACING_20};
`;

const Link = styled(ParagraphSmall)`
  text-decoration: underline;
  cursor: pointer;
`;

// const InfoBouble = styled.div`
//   position: absolute;
//   right: -18px;
//   top: -24px;
//   display: flex;
//   width: 64px;
//   height: 64px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   background: ${accent.raspberry250};
//   color: ${accent.raspberry4};
// `;

const {
  entities: {
    investmentStrategyCosts: {
      investmentStrategyCostsByStrategyIdSelector,
      investmentStrategyCostMetadataByIdSelector,
    },
  },
} = selectors;

export const CostSlide = (props) => {
  const { isActiveSlide } = props;
  const [queryParamsSelectedStrategy] = useQueryState('selectedStrategies', '');

  const investmentStrategyCosts = useSelector(
    investmentStrategyCostsByStrategyIdSelector(queryParamsSelectedStrategy)
  );

  const metadata = useSelector(
    investmentStrategyCostMetadataByIdSelector(queryParamsSelectedStrategy)
  );

  const advisoryFeeRelevant = metadata?.advisoryFeeRelevant;
  const structuringFeeRelevant = metadata?.structuringFeeRelevant;
  const twoColumnTableCondition = advisoryFeeRelevant || structuringFeeRelevant;

  const [serviceModal, setServiceModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [timeframe, setTimeframe] = useState('year4plus');
  const [totalServiceCost, setTotalServiceCost] = useState(0);
  const [totalProductCost, setTotalProductCost] = useState(0);
  const [totalExternalReportingCost, setTotalExternalReportingCost] =
    useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const serviceCostRef = useRef(null);
  const productCostRef = useRef(null);
  const totalRef = useRef(null);

  const formatValue = useMemo(() => (val) => percentage(val, 2), []);

  const { start: startServiceCost, update: updateServiceCost } = useCountUp({
    ref: serviceCostRef,
    start: 0,
    end: totalServiceCost * 100,
    duration: 1.2,
    formattingFn: formatValue,
    decimals: 2,
    useEasing: true,
    preserveValue: true,
  });
  const { start: startProductCost, update: updateProductCost } = useCountUp({
    ref: productCostRef,
    start: 0,
    end: totalProductCost * 100,
    duration: 1.2,
    formattingFn: formatValue,
    decimals: 2,
    useEasing: true,
    preserveValue: true,
  });
  const { start: startTotalCost, update: updateTotalCost } = useCountUp({
    ref: totalRef,
    start: 0,
    end: totalCost * 100,
    duration: 1.2,
    formattingFn: formatValue,
    decimals: 2,
    useEasing: true,
    preserveValue: true,
  });

  useEffect(() => {
    if (isActiveSlide) {
      startServiceCost();
      startProductCost();
      startTotalCost();
    }
  }, [isActiveSlide, startProductCost, startServiceCost, startTotalCost]);

  useEffect(() => {
    if (twoColumnTableCondition) {
      setTimeframe(twoColumnTableCondition ? 'year4plus' : 'year1');
    }
  }, [twoColumnTableCondition]);

  useEffect(() => {
    setTotalServiceCost(
      investmentStrategyCosts
        .filter((item) => item.group === 'service')
        .map(
          (item) =>
            item.costByYear.find((item) => item.year === timeframe)?.percentage
        )
        .reduce((acc, cur) => acc + cur, 0)
    );
    setTotalProductCost(
      investmentStrategyCosts
        .filter((item) => item.group === 'products')
        .map(
          (item) =>
            item.costByYear.find((item) => item.year === timeframe)?.percentage
        )
        .reduce((acc, cur) => acc + cur, 0)
    );
    setTotalExternalReportingCost(
      investmentStrategyCosts
        .filter((item) => item.group === 'externalReporting')
        .map(
          (item) =>
            item.costByYear.find((item) => item.year === timeframe)?.percentage
        )
        .reduce((acc, cur) => acc + cur, 0)
    );

    setTotalCost(
      investmentStrategyCosts
        .filter((item) => item.group === 'totals')
        .map(
          (item) =>
            item.costByYear.find((item) => item.year === timeframe)?.percentage
        )
        .reduce((acc, cur) => acc + cur, 0)
    );

    updateServiceCost(totalServiceCost * 100);
    updateProductCost(totalProductCost * 100);
    updateTotalCost(totalCost * 100);
  }, [
    investmentStrategyCosts,
    timeframe,
    totalCost,
    totalProductCost,
    totalServiceCost,
    updateProductCost,
    updateServiceCost,
    updateTotalCost,
  ]);

  // const deduction = 0.001;

  return (
    <>
      <Base>
        <InvestmentStrategyStrategyPicker />

        <ContentWrapper>
          <Item>
            <GraphicCostSectionOne />
            <div>
              <InfoBox>
                {/* <InfoBouble>
                  <H5>{percentage(deduction * 100, 2)}</H5>
                  <ParagraphXSmall style={{ marginTop: -2 }}>
                    {t('presentation:investmentStrategy:cost:deduction')}
                  </ParagraphXSmall>
                </InfoBouble> */}

                <H2 ref={serviceCostRef}>{percentage(0, 2)}</H2>
                <TextWrapper>
                  <ParagraphSmall>
                    {t('presentation:investmentStrategy:cost:serviceFeeTitle')}
                  </ParagraphSmall>
                  <Link onClick={() => setServiceModal(true)}>
                    {t('presentation:investmentStrategy:cost:readMore')}
                  </Link>
                </TextWrapper>
              </InfoBox>
              {totalExternalReportingCost ? (
                <ParagraphXSmall
                  style={{
                    textAlign: 'center',
                    fontStyle: 'italic',
                    marginTop: SPACING_8,
                  }}
                >
                  {t('presentation:investmentStrategy:cost:externalFeeTitle', {
                    value: percentage(totalExternalReportingCost * 100, 3),
                  })}
                </ParagraphXSmall>
              ) : null}
            </div>
          </Item>
          <div>
            <SpriteIconFunctional id="Plus" size={20} />
          </div>
          <Item>
            <GraphicCostSectionTwo />
            <InfoBox>
              <H2 ref={productCostRef}>{percentage(0, 2)}</H2>
              <TextWrapper>
                <ParagraphSmall>
                  {t('presentation:investmentStrategy:cost:productFeeTitle')}
                </ParagraphSmall>
                <Link onClick={() => setProductModal(true)}>
                  {t('presentation:investmentStrategy:cost:readMore')}
                </Link>
              </TextWrapper>
            </InfoBox>
          </Item>
          <div>
            <SpriteIconFunctional id="Equals" size={20} />
          </div>
          <Item>
            <GraphicCostSectionThree />
            <InfoBox>
              {/* <InfoBouble>
                <H5>
                  {percentage(Math.max(totalCost - deduction, 0) * 100, 2)}
                </H5>
                <ParagraphXSmall style={{ marginTop: -2 }}>
                  {t('presentation:investmentStrategy:cost:afterDeduction')}
                </ParagraphXSmall>
              </InfoBouble> */}
              <H2 ref={totalRef}>{percentage(0, 2)}</H2>
              <TextWrapper>
                <ParagraphSmall>
                  {t('presentation:investmentStrategy:cost:totalFeeTitle')}
                </ParagraphSmall>
              </TextWrapper>
            </InfoBox>
          </Item>
        </ContentWrapper>
        {twoColumnTableCondition ? (
          <MultipleSwitch
            backgroundColor={backgroundWhite}
            activeBackgroundColor={accent.ocean230}
            color={accent.ocean270}
            activeColor={accent.ocean490}
            onChange={(value) => {
              setTimeframe(value);
            }}
            options={[
              {
                label: t('presentation:investmentStrategy:cost:year4plus'),
                value: 'year4plus',
              },
              {
                label: t('presentation:investmentStrategy:cost:year1'),
                value: 'year1',
              },
            ]}
          />
        ) : null}
      </Base>
      <Modal
        isOpen={serviceModal}
        onRequestClose={() => setServiceModal(false)}
        contentWidth={600}
        padding={SPACING_48}
      >
        <ModalContent>
          <span>
            <H2 style={{ marginBottom: SPACING_12 }}>
              {t('advisor:strategySimulator:cost:modals:serviceFee:title')}
            </H2>
            <H2>{percentage(totalServiceCost * 100, 2)}</H2>
          </span>
          <Paragraph>
            {t('advisor:strategySimulator:cost:modals:serviceFee:content')}
          </Paragraph>
          <Paragraph>
            {t('advisor:strategySimulator:cost:modals:serviceFee:listTitle')}
          </Paragraph>

          <ul style={{ margin: 0, paddingLeft: SPACING_20 }}>
            {t('advisor:strategySimulator:cost:modals:serviceFee:listContent', {
              returnObjects: true,
            }).map((item, index) => (
              <li>
                <H4>
                  {
                    t('advisor:strategySimulator:cost:modals:serviceFee:list', {
                      returnObjects: true,
                    })[index]
                  }
                </H4>
                <Paragraph>{item}</Paragraph>
              </li>
            ))}
          </ul>
        </ModalContent>
        <Footnote>
          <SpriteIcon id="Create" size={16} />
          <ParagraphSmall>
            {t('advisor:strategySimulator:cost:modals:taxDisclamer')}
          </ParagraphSmall>
        </Footnote>
      </Modal>
      <Modal
        isOpen={productModal}
        onRequestClose={() => setProductModal(false)}
        contentWidth={600}
        padding={SPACING_48}
      >
        <ModalContent>
          <span>
            <H2 style={{ marginBottom: SPACING_12 }}>
              {t('advisor:strategySimulator:cost:modals:productFee:title')}
            </H2>
            <H2>{percentage(totalProductCost * 100, 2)}</H2>
          </span>
          <Paragraph>
            {t('advisor:strategySimulator:cost:modals:productFee:content')}
          </Paragraph>
        </ModalContent>
      </Modal>
    </>
  );
};
