/* eslint-disable require-yield */
import { put, call, takeLeading, takeLatest } from 'redux-saga/effects';

import { Sentry, AsyncStorage, actions as coreActions } from '@formue-app/core';

import { actions } from './actions';
import { actions as authActions } from '../auth/actions';

import {
  authenticateUsingWebAuthn,
  registerUsingWebAuthn,
} from '../../services/webauthn';

const {
  entities: { authenticatorDevices: authenticatorDeviceActions },
} = coreActions;

// We want to load any webauthn data from local storage and put into redux store
// as soon as possible, so this is called from the App.js file
function* initialize() {
  yield takeLeading(actions.load, function* () {
    const passkeysEnabledString = yield call(
      AsyncStorage.getItem,
      'passkeysEnabled'
    );
    const data = {
      passkeysEnabled: JSON.parse(passkeysEnabledString),
    };
    yield put(actions.loaded(data));
  });
}

function* startRegistration() {
  yield takeLeading(actions.startRegistration, function* () {
    let data;

    try {
      data = yield call(registerUsingWebAuthn);
    } catch (error) {
      // Registration failed for some reason, we don't want to continue
      // the rest of this saga so we return the yield statement to prevent
      // the finishRegistration from being dispatched.
      return yield put(actions.failedRegistration(error));
    }

    if (!data.verified) {
      return yield put(actions.failedRegistration());
    }

    yield put(actions.finishRegistration(data));
  });
}

function* finishRegistration() {
  yield takeLeading(actions.finishRegistration, function* ({ payload }) {
    // At this point we know that device registration is successful so we can store
    // that so we know it for next time.
    yield call(AsyncStorage.setItem, 'passkeysEnabled', 'true');
  });
}

function* failedRegistration() {
  yield takeLeading(actions.failedRegistration, function* ({ payload }) {
    let error = payload;
    if (!error) {
      error = new Error('Unknown failure during WebAuthn registration');
    }
    Sentry.captureException(payload);
  });
}

function* startAuthentication() {
  yield takeLeading(actions.startAuthentication, function* () {
    try {
      const result = yield call(authenticateUsingWebAuthn);

      if (!result.verified) {
        yield put(actions.failedAuthentication());
      }

      yield put(actions.finishAuthentication(result));
    } catch (error) {
      yield put(actions.failedAuthentication(error));
    }
  });
}

function* finishAuthentication() {
  yield takeLeading(actions.finishAuthentication, function* ({ payload }) {
    const accessToken = payload.token.access_token;
    yield put(authActions.setAccessToken(accessToken));
    yield put(authActions.finishAuthentication());
  });
}

function* failedAuthentication() {
  yield takeLeading(actions.failedAuthentication, function* ({ payload }) {
    let error = payload;
    if (!error) {
      error = new Error('Unknown failure during WebAuthn authentication');
    }
    Sentry.captureException(payload);
  });
}

function* storePasskeysEnabledFlag() {
  yield takeLeading(actions.storePasskeysEnabledFlag, function* ({ payload }) {
    yield call(
      AsyncStorage.setItem,
      'passkeysEnabled',
      JSON.stringify(payload)
    );
  });
}

function* loadAuthenticatorDevicesOnAppInit() {
  yield takeLatest('APP/INIT', function* () {
    // Make sure we load authenticator devices on app/init, which should be after
    // we have logged in. We do this so that we can check if we have any authentication devices,
    // and if we have we can set the passkeysEnabled flag so that the next time the user logs
    // in they can use passkeys.
    yield put(authenticatorDeviceActions.indexRequestBegin());
  });
}

function* checkIfWeHaveAnyDevices() {
  yield takeLatest(
    authenticatorDeviceActions.indexRequestSuccess,
    function* ({ payload }) {
      const hasAuthDevices = Boolean(
        payload?.result['authenticator-devices']?.length
      );
      yield put(actions.storePasskeysEnabledFlag(hasAuthDevices));
    }
  );
}

export const sagas = [
  initialize,
  startRegistration,
  finishRegistration,
  failedRegistration,
  startAuthentication,
  finishAuthentication,
  failedAuthentication,
  storePasskeysEnabledFlag,
  loadAuthenticatorDevicesOnAppInit,
  checkIfWeHaveAnyDevices,
];
