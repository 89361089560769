import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors } from '@formue-app/core';
import { BORDER_RADIUS_LARGE, SPACING_24 } from '../../../../constants/spacing';
import { InvestmentPreferencesChoiceItem } from './InvestmentPreferencesChoiceItem';

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_24};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
  position: relative;
  width: 100%;
`;

const {
  entities: {
    investmentProfile: { choiceTypesSelector, choicesSelector },
  },
} = selectors;

export const InvestmentPreferencesChoices = (props) => {
  const { isActiveSlide } = props;
  const choices = useSelector(choicesSelector);
  const choiceTypes = useSelector(choiceTypesSelector);
  const [activeChoiceId, setActiveChoiceId] = useState(null);

  // Deselect choice if slide is not active
  useEffect(() => {
    if (!isActiveSlide) {
      setActiveChoiceId(null);
    }
  }, [isActiveSlide]);

  const renderChoiceItems = (indexes) => {
    return indexes.map((itemIndex) => {
      if (!choiceTypes[itemIndex]) {
        return null;
      }

      return (
        <InvestmentPreferencesChoiceItem
          choices={choices}
          choiceTypes={choiceTypes}
          itemIndex={itemIndex}
          activeChoiceId={activeChoiceId}
          setActiveChoiceId={setActiveChoiceId}
          key={`choice-item-${itemIndex}`}
          isActiveSlide={isActiveSlide}
        />
      );
    });
  };
  return (
    <RowWrapper>
      <Column isActiveSlide={isActiveSlide}>
        {renderChoiceItems([0, 2, 4])}
      </Column>
      <Column isActiveSlide={isActiveSlide}>
        {renderChoiceItems([1, 3, 5])}
      </Column>
    </RowWrapper>
  );
};
