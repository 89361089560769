import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { selectors, humanFriendlyDate, useResource } from '@formue-app/core';

import { Paragraph, ParagraphSmall } from '../texts';
import { BORDER_RADIUS_LARGE, SPACING_8 } from '../../constants/spacing';
import {
  accent,
  backgroundWhite,
  whiteTranslucent,
} from '../../constants/colors';
import { actions as podcastActions } from '../../store/ui/podcast';
import { ReactComponent as PlayIcon } from '../../assets/icons/podcast-player/play-button.svg';
import { EqualizerIcon } from './EqualizerIcon';

const StyledWrapper = styled.div``;

const StyledEpisode = styled.div`
  display: flex;
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: 28px 24px;
  background-color: ${(props) =>
    props.active ? accent.ocean4 : backgroundWhite};
  gap: 18px;
`;

const StyledTitle = styled(Paragraph)`
  font-weight: 500;
  margin-bottom: 2px;
  max-height: 22px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  ${(props) => props.active && `color: ${backgroundWhite}`}
`;

const StyledThumbnailWrapper = styled.div`
  height: 46px;
  width: 46px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledThumbnail = styled.img`
  height: 46px;
  width: 46px;
  border-radius: ${BORDER_RADIUS_LARGE};
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledButton = styled.button`
  border: none;
  outline: none;
  text-align: left;
  width: 100%;
  shadow: none;
  cursor: pointer;
  margin-top: ${SPACING_8};
  padding: 0;
  box-shadow: none;
`;

const PlayButton = styled.div`
  height: 23px;
  width: 23px;
  border-radius: 12px;
  background-color: ${whiteTranslucent(0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const {
  entities: {
    podcastEpisodes: { allPodcastEpisodesSelector },
  },
} = selectors;

export const PodcastsPlaylist = ({ podcastId }) => {
  const dispatch = useDispatch();
  const loading = useResource(['PODCAST_EPISODES/INDEX']);
  const allEpisodes = useSelector(allPodcastEpisodesSelector);
  const activeEpisodeId = useSelector((state) => state.ui.podcast.episodeId);
  const isPlaying = useSelector((state) => state.ui.podcast.isPlaying);

  if (loading) return null;

  return (
    <StyledWrapper>
      {allEpisodes.map((episode) => {
        const isActive = episode.id === activeEpisodeId;
        return (
          <StyledButton
            onClick={() => {
              dispatch(podcastActions.setPodcastId(podcastId));
              dispatch(podcastActions.setEpisodeId(episode.id));
            }}
            key={`episode-${episode.id}`}
          >
            <StyledEpisode active={isActive}>
              <StyledThumbnailWrapper>
                <StyledThumbnail
                  src={episode.image ? episode.image : episode.podcast.image}
                />

                {isActive && isPlaying ? (
                  <EqualizerIcon />
                ) : (
                  <PlayButton
                    onClick={() => {
                      dispatch(podcastActions.setIsPlaying(true));
                    }}
                  >
                    <PlayIcon />
                  </PlayButton>
                )}
              </StyledThumbnailWrapper>
              <div style={{ flex: 1 }}>
                <StyledTitle active={isActive}>{episode.title}</StyledTitle>
                <ParagraphSmall
                  style={isActive ? { color: backgroundWhite } : null}
                >
                  {humanFriendlyDate(episode.publishedAt)}
                </ParagraphSmall>
              </div>
              <Paragraph
                style={{
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  color: isActive ? backgroundWhite : accent.ocean4,
                }}
              >{`${Math.floor(episode.duration / 60)} min`}</Paragraph>
            </StyledEpisode>
          </StyledButton>
        );
      })}
    </StyledWrapper>
  );
};
