import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t, useResource } from '@formue-app/core';

import { SPACING_24, SPACING_16, SPACING_8 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { H6, ParagraphLarge } from '../../texts';
import { ProfileIcon } from '../../common/ProfileIcon';

const TitleWrapper = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: ${SPACING_24};
`;

const {
  entities: {
    accountTeamMembers: { mainAdvisorSelector },
  },
} = selectors;

export const ContactModalContent = (props) => {
  const loading = useResource(['ACCOUNT_TEAM_MEMBERS/INDEX']);

  const mainAdvisor = useSelector(mainAdvisorSelector);

  if (loading) {
    return null;
  }

  return (
    <TitleWrapper>
      <ProfileIcon
        name={mainAdvisor.name}
        url={mainAdvisor.photoUrl}
        size={48}
        style={{ marginBottom: SPACING_16 }}
      />
      <H6 style={{ marginBottom: SPACING_8, color: accent.ocean440 }}>
        {t('report:annualReview:contact:form:title')}
      </H6>
      <ParagraphLarge style={{ textAlign: 'center' }}>
        {t('report:annualReview:contact:form:description')}
      </ParagraphLarge>
    </TitleWrapper>
  );
};
