import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { signingEvents } from '../../../api/signingEvents';
import { actions as tradeOrdersActions } from './tradeOrders';
import { actions as uiActions } from '../../ui/readStatus';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the signingEvents resource
export const [actions, reducer, resourceSagas] = resourceReducer('signing-events');

// Create the sagas for this entity
function* readSigningEvents() {
  yield takeEvery(actions.indexRequestBegin, function*({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(signingEvents.get, args);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, args }));
      yield put(uiActions.hydrateReadItems('signingEvents'));
      yield put(tradeOrdersActions.indexRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error })
    }
  });
}

function* readSigningEvent() {
  yield takeEvery(actions.showRequestBegin, function*({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(signingEvents.get, args);
      const normalized = normalize(result);
      yield put(actions.showRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.showRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error })
    }
  });
}

export const sagas = [
  readSigningEvents,
  readSigningEvent,
  ...resourceSagas,
];
