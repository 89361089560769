import React from 'react';
import { useSelector } from 'react-redux';

import { useSigningEventsTradeOrders } from '@formue-app/core';

import { BaseListItem } from '../../common/BaseListItem';
import { CollapsibleListItem } from '../../common/CollapsibleListItem';
import { TodoListItem } from './TodoListItem';
import { TradeOrders } from '../../signingEvents/TradeOrders';

import { useSigningTaskColumns } from './columns/signingTaskColumns';

export const SigningTaskListItem = (props) => {
  const { id } = props;
  const item = useSelector((state) => state.entities.signingEvents.byId[id]);
  const tradeOrders = useSigningEventsTradeOrders(item);

  const { status } = item;

  const columns = useSigningTaskColumns({ id, item });

  if (tradeOrders.length > 0) {
    const startExpanded = status.toLowerCase() === 'open';
    return (
      <CollapsibleListItem
        details={<TradeOrders tradeOrders={tradeOrders} />}
        showFlipArrow={false}
        startExpanded={startExpanded}
        detailsStyle={{ paddingBottom: 0 }}
        detailsWrapperStyle={{ padding: 0 }}
      >
        <TodoListItem columns={columns} />
      </CollapsibleListItem>
    );
  }

  return (
    <BaseListItem>
      <TodoListItem columns={columns} />
    </BaseListItem>
  );
};
