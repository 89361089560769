import { call, put, takeEvery, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { marketValues } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { transformActiveFilters, waitFor } from '../../../services';
import { activeFiltersSelector } from '../../ui/portfolio/selectors';

export const [actions, reducer, resourceSagas] =
  resourceReducer('marketvalues');

function* readMarketValues() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    yield call(waitFor, (state) => state.ui.portfolio.initialized);
    const activeFilters = yield select(activeFiltersSelector);
    let args = transformActiveFilters(activeFilters);

    args = { ...args, ...payload.args };

    try {
      const result = yield call(marketValues.get, args);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readMarketValues, ...resourceSagas];
