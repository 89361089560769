import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { isFunction } from 'lodash';

import { BaseSection } from '../common/BaseSection';
import { H2, ParagraphSmall } from '../texts';
import { SubNavigationItem } from './SubNavigationItem';

import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../constants/spacing';
import {
  desktopCondition,
  mobileCondition,
  notDesktopCondition,
  tabletCondition,
} from '../../constants/media';
import { accent, backgroundWhite } from '../../constants/colors';

const StyledContainer = styled(BaseSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${SPACING_48} 40px ${SPACING_48} ${SPACING_48};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${(props) => props.backgroundColor};

  @media ${notDesktopCondition} {
    flex-direction: column;
  }

  @media ${mobileCondition} {
    background: transparent;
    padding: 0;
  }
`;

const StyledTextTitle = styled(H2)`
  margin-bottom: 12px;
  @media ${tabletCondition} {
    font-size: 22px;
  }
  @media ${mobileCondition} {
    font-size: 24px;
    margin-bottom: ${SPACING_16};
  }
`;

const StyledTextWrapper = styled.div`
  @media ${desktopCondition} {
    max-width: ${(props) => props.maxTextWidth}px;
    margin-right: ${SPACING_24};
  }
  @media ${tabletCondition} {
    text-align: center;
    margin-bottom: 24px;
  }

  @media ${mobileCondition} {
    margin-bottom: ${SPACING_48};
  }
`;

const StyledNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${SPACING_8};

  @media ${notDesktopCondition} {
    flex-wrap: wrap;
  }

  @media ${mobileCondition} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const subNavigationStylesThemeLight = {
  backgroundColor: 'transparent',
  textColor: accent.ocean490,
  itemBackgroundColor: 'transparent',
  itemBackgroundColorActive: accent.ocean4,
  itemBorderColor: accent.sand370,
  itemBorderColorActive: accent.ocean4,
  itemTextColor: accent.ocean490,
  itemTextColorActive: backgroundWhite,
  itemHoverColor: accent.sand370
};

export const subNavigationStylesThemeDark = {
  backgroundColor: accent.velvet4,
  textColor: accent.velvet250,
  itemBackgroundColor: 'transparent',
  itemBackgroundColorActive: backgroundWhite,
  itemBorderColor: accent.velvet480,
  itemBorderColorActive: backgroundWhite,
  itemTextColor: backgroundWhite,
  itemTextColorActive: accent.ocean490,
  itemHoverColor: accent.sand370
};

export const SubNavigation = (props) => {
  const {
    routes = [],
    iconSize = 32,
    iconSizeMobile = 24,
    maxTextWidth = 330,
    renderIcon = () => {},
    backgroundColor,
    textColor,
    activeItemColor,
    title,
    text,
    stylesTheme = subNavigationStylesThemeLight,
    ...rest
  } = props;

  const isMobile = useMediaQuery({
    query: mobileCondition,
  });
  return (
    <StyledContainer
      columns={12}
      backgroundColor={stylesTheme.backgroundColor}
      {...rest}
    >
      <StyledTextWrapper maxTextWidth={maxTextWidth}>
        <StyledTextTitle style={{ color: stylesTheme.textColor }}>
          {isFunction(title)
            ? title(window.location.pathname.split('/').pop())
            : title}
        </StyledTextTitle>
        <ParagraphSmall style={{ color: stylesTheme.textColor }}>
          {isFunction(text)
            ? text(window.location.pathname.split('/').pop())
            : text}
        </ParagraphSmall>
      </StyledTextWrapper>
      <StyledNavigationWrapper>
        {routes.map((route, index) => {
          const type = route.path.split('/').pop();
          const icon = renderIcon(
            type,
            window.location.pathname === route.path,
            isMobile ? iconSize : iconSizeMobile
          );
          const disabled = !route.hasOwnProperty('component');

          return (
            <SubNavigationItem
              {...route}
              backgroundColorActive={
                route.color
                  ? route.color
                  : stylesTheme.itemBackgroundColorActive
              }
              itemTextColor={stylesTheme.itemTextColor}
              itemTextColorActive={stylesTheme.itemTextColorActive}
              key={`item-${index}`}
              icon={icon}
              disabled={disabled}
              backgroundColor={stylesTheme.itemBackgroundColor}
              borderColor={stylesTheme.itemBorderColor}
              borderColorActive={stylesTheme.itemBorderColorActive}
              hoverColor={stylesTheme.itemHoverColor}
            />
          );
        })}
      </StyledNavigationWrapper>
    </StyledContainer>
  );
};
