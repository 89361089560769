import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, useResource } from '@formue-app/core';

import { SPACING_16 } from '../../constants/spacing';
import { ProfileIcon } from '../common/ProfileIcon';

const Advisors = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: ${SPACING_16};
`;

const {
  entities: {
    accountTeamMembers: { advisorsSelector },
  },
} = selectors;

export const AdvisorsProfileImages = (props) => {
  const { size = 40, contacts = [], ...rest } = props;

  useResource(['ACCOUNT_TEAM_MEMBERS/INDEX']);

  let advisors = useSelector(advisorsSelector);

  advisors = contacts;

  return (
    <Advisors {...rest}>
      {advisors.map((advisor, index) => (
        <ProfileIcon
          key={`advisor-${index}`}
          name={advisor.name}
          url={advisor.photoUrl}
          size={size}
          style={{ marginLeft: `-${SPACING_16}` }}
        />
      ))}
    </Advisors>
  );
};
