import React from 'react';
import styled from 'styled-components';

import { percentage, t } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { HorizontalDivider } from '../../common/HorizontalDivider';
import { VisibleInCountry } from '../../common/VisibleInCountry';
import { H4, Paragraph } from '../../texts';

import { SPACING_16, SPACING_8 } from '../../../constants/spacing';

import { ActivityIndicator } from '../../common/ActivityIndicator';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${SPACING_8};
`;

const SliderGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  margin: auto;
`;

export const StrategyPreferencesSettings = (props) => {
  const { strategy } = props;

  return (
    <>
      <StyledBaseSection columns={4}>
        {!strategy ? (
          <StyledActivityIndicator />
        ) : (
          <>
            <SliderGroup>
              <H4>
                {t('advisor:strategySimulator:preferences:clientPreferences')}
              </H4>
              <Row>
                <Paragraph>
                  {t('advisor:strategySimulator:preferences:indexExlution')}
                </Paragraph>
                <Paragraph style={{ fontWeight: 500 }}>
                  {percentage(strategy?.preferences?.index * 100, 0)}
                </Paragraph>
              </Row>
              <Row>
                <Paragraph>
                  {t(
                    'advisor:strategySimulator:preferences:sustainableInvestments'
                  )}
                </Paragraph>
                <Paragraph style={{ fontWeight: 500 }}>
                  {percentage(strategy?.preferences?.sustainability, 0)}
                </Paragraph>
              </Row>
            </SliderGroup>

            <HorizontalDivider />

            <SliderGroup>
              <H4>
                {t(
                  'advisor:strategySimulator:preferences:spesificInvestmentPreferences'
                )}
              </H4>
              <Row>
                <Paragraph>
                  {t('advisor:strategySimulator:preferences:hedgeFund')}
                </Paragraph>
                <Paragraph style={{ fontWeight: 500 }}>
                  {percentage(strategy?.preferences?.mtaShare * 100, 0)}
                </Paragraph>
              </Row>
              <VisibleInCountry country={['no', 'dk']}>
                <Row>
                  <Paragraph>
                    {t('advisor:strategySimulator:preferences:realEstate')}
                  </Paragraph>
                  <Paragraph style={{ fontWeight: 500 }}>
                    {percentage(strategy?.preferences?.coreShare * 100, 0)}
                  </Paragraph>
                </Row>
              </VisibleInCountry>
              <Row>
                <Paragraph>
                  {t('advisor:strategySimulator:preferences:highYield')}
                </Paragraph>
                <Paragraph style={{ fontWeight: 500 }}>
                  {percentage(strategy?.preferences?.highYieldShare * 100, 0)}
                </Paragraph>
              </Row>
              <VisibleInCountry country={['no', 'dk']}>
                <Row>
                  <Paragraph>
                    {t('advisor:strategySimulator:preferences:curencyHedge')}
                  </Paragraph>
                  <Paragraph style={{ fontWeight: 500 }}>
                    {strategy?.preferences?.currencyHedging
                      ? t('globals:yes')
                      : t('globals:no')}
                  </Paragraph>
                </Row>
              </VisibleInCountry>
            </SliderGroup>
          </>
        )}
      </StyledBaseSection>
    </>
  );
};
