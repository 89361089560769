import React from 'react';
import { Switch } from 'react-router-dom';

import { NotMobileWrapper } from '../../constants/media';

import { MenuPage } from './MenuPage';
import { RouteWithSubRoutes } from '../../components/router';
import { SubMenu } from '../../components/navigation/SubMenu';

export const SubMenuPage = (props) => {
  const { routes, children } = props;

  return (
    <MenuPage {...props}>
      {children}
      <NotMobileWrapper>
        <SubMenu routes={routes} />
      </NotMobileWrapper>

      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </MenuPage>
  );
};
