import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

import { SPACING_8 } from '../../constants/spacing';
import { StyledBaseSection } from './BaseSection';
import { FlipArrow } from './FlipArrow';
import { H4 } from '../texts';

const StyledBaseLinkItem = styled(StyledBaseSection)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: ${SPACING_8};
`;

export const BaseLinkItem = (props) => {
  const { label, icon: IconSvg, style, ...rest } = props;

  return (
    <StyledBaseLinkItem as={HashLink} {...rest} style={style}>
      {IconSvg ? IconSvg : null}
      <H4>{label}</H4>
      <FlipArrow style={{ marginLeft: 'auto' }} />
    </StyledBaseLinkItem>
  );
};
