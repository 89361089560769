import { stringify } from 'query-string';
import { config } from '../config';

const { host } = config.blog;

const articles = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const query = stringify({
      per_page: 100,
      _fields:
        'id,slug,title,content,date,modified,excerpt,link,categories,_links,_embedded',
      _embed: 'wp:featuredmedia',
    });
    const url = `${host}/wp-json/wp/v2/posts?${query}`;
    const response = await fetch(url, options);

    if (response.status < 200 || response.status >= 300) {
      return null;
    }

    return response.json();
  },

  getOne: async (id = '') => {
    const options = {
      method: 'GET',
    };
    const response = await fetch(`${host}/wp-json/wp/v2/posts/${id}`, options);

    if (response.status < 200 || response.status >= 300) {
      return null;
    }

    return response.json();
  },
};

const categories = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const query = stringify({
      per_page: 100,
      _fields: 'id,name',
    });
    const url = `${host}/wp-json/wp/v2/categories?${query}`;
    const response = await fetch(url, options);

    if (response.status < 200 || response.status >= 300) {
      return null;
    }

    return response.json();
  },
};

export const wordpress = {
  articles,
  categories,
};
