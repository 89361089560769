import moment from 'moment/min/moment-with-locales';

import * as config from './config';

const date = {
  /**
	 * Load library, setting it's initial locale
	 *
	 * @param {string} locale
	 * @return Promise
	 */
  init(locale) {
    return new Promise((resolve, reject) => {
      config
        .supportedLocales[locale]
        .momentLocaleLoader()
        .then(() => {
          moment.locale(locale);
          return resolve();
        })
        .catch((err) => reject(err));
    });
  },

  /**
	 * @param {Date} date
	 * @param {string} format
	 * @return {string}
	 */
  format(date, format) {
    return moment(date).format(format);
  }
};

export default date;
