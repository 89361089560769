import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  securityPerformanceAllIds,
  securityPerformanceById,
  allSecurityPerformanceSelector,
] = resourceSelectors('securityPerformance');

export const securityPerformanceBySecurityIdSelector = createSelector(
  allSecurityPerformanceSelector,
  (allSecurityPerformance) => {
    return allSecurityPerformance.reduce((accumulator, target) => {
      if (accumulator.hasOwnProperty(target.securityId)) {
        accumulator[target.securityId].push(target);
      } else {
        accumulator[target.securityId] = [target];
      }
      return accumulator;
    }, {});
  }
);

export const securityPerformanceBySymbolSelector = createSelector(
  allSecurityPerformanceSelector,
  (allSecurityPerformance) => {
    return allSecurityPerformance.reduce((accumulator, target) => {
      if (accumulator.hasOwnProperty(target.symbol)) {
        accumulator[target.symbol].push(target);
      } else {
        accumulator[target.symbol] = [target];
      }
      return accumulator;
    }, {});
  }
);
