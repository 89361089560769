import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import {
  easeInOut,
  extraLongAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';
import { accent } from '../../../constants/colors';
import {
  SPACING_32,
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_16,
  SPACING_24,
} from '../../../constants/spacing';
import { H1XL } from '../../texts';
import { paragraphLargeSize } from '../../../constants/text';
import { SidebarImage } from '../SidebarImage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Title = styled(H1XL)`
  color: ${accent.ocean490};
  margin-bottom: ${SPACING_32};
  margin-top: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const TextContainer = styled.div`
  width: 60%;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const List = styled.ol`
  padding: 0;
  padding-left: ${SPACING_24};
  margin: 0;
  list-style: decimal !important;
`;

const AgendaItem = styled.li`
  margin-bottom: ${SPACING_16};
  color: ${accent.ocean490};
  position: relative;
  font-size: ${paragraphLargeSize};

  top: 20px;
  opacity: 0;
  transition: opacity ${easeInOut} ${extraLongAnimationTime},
    top ${easeInOut} 1s;
  transition-delay: ${(props) => `${slideAnimationDelay + props.index * 0.2}s`},
    ${(props) => `${slideAnimationDelay + props.index * 0.2}s`};

  ${(props) =>
    props.isActiveSlide &&
    `
    top: 0;
    opacity: 1;
  `}

  &::last-child {
    margin-bottom: 0;
  }
`;

const {
  ui: {
    presentation: { agendaItemsSelector },
  },
} = selectors;

export const Agenda = (props) => {
  const { id, isActiveSlide, title, presentationType } = props;

  const agendaItems = useSelector(agendaItemsSelector);

  return (
    <Wrapper>
      <TextContainer>
        <Title>{t(title)}</Title>
        <List>
          {agendaItems.map((item, index) => (
            <AgendaItem
              key={`agenda-item-${index}`}
              isActiveSlide={isActiveSlide}
              index={index}
            >
              {t(item.title)}
            </AgendaItem>
          ))}
        </List>
      </TextContainer>
      <SidebarImage id={id} presentationType={presentationType} />
    </Wrapper>
  );
};
