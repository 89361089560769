import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';

export const TaskSerializer = new JSONAPISerializer('tasks', {
  keyForAttribute: 'camelCase',
  attributes: [
    'assignee',
    'customer',
    'priority',
    'title',
    'taskType',
    'status',
  ],
});
