import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import {
  easeInOut,
  longAnimationTime,
  shortAnimationTime,
  slideAnimationDelay,
} from '../../constants/animation';

const PyramidWrapper = styled.div`
  display: flex;
  position: relative;

  ${(props) =>
    props.labelPosition === 'right'
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: row-reverse;
        `}
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${(props) => props.height}px;
`;

const LabelWrapper = styled.div`
  ${(props) =>
    props.labelPosition === 'right'
      ? css`
          left: ${(props) => props.offset}px;

          ${(props) =>
            !props.isActiveSlide &&
            css`
              left: ${(props) => props.offset + 25}px;
            `}
        `
      : css`
          right: ${(props) => props.offset}px;

          ${(props) =>
            !props.isActiveSlide &&
            css`
              right: ${(props) => props.offset + 25}px;
            `}
        `}
  position: relative;
  cursor: ${(props) => (props.enableClick ? 'pointer' : 'default')};
  opacity: 1;
  transition: all ${easeInOut} ${shortAnimationTime};
  height: calc(100% / 3);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.isActiveSlide &&
    css`
      opacity: 1;
    `}

  &.animation-enter,
  &.animation-enter-done {
    transition-duration: ${longAnimationTime};
    transition-delay: ${(props) => slideAnimationDelay + 0.2 * props.index}s;
  }

  &:not(:nth-child(${(props) => props.activeSlice + 1})) {
    opacity: 0.16;
  }
`;

const LabelInner = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: ${(props) => Math.min(7 / props.scaler, 10)}px;

  ${(props) =>
    props.labelPosition === 'right'
      ? css`
          flex-direction: column;
          text-align: left;
        `
      : css`
          flex-direction: column;
          text-align: right;
        `};
`;

const PyramidIcon = styled.svg`
  path {
    cursor: ${(props) => (props.enableClick ? 'pointer' : 'default')};
    transition: all ${easeInOut} ${longAnimationTime};
    translate: 0 25px;
    opacity: 0;

    &:not(:nth-child(${(props) => props.activeSlice + 1})) {
      fill: #3c2465;
    }
  }

  ${(props) =>
    props.isActiveSlide &&
    css`
      path {
        translate: 0 0;
        opacity: 1;
      }
    `}

  &.animation-enter,
  &.animation-enter-done {
    path {
      &:nth-child(1) {
        transition-delay: ${slideAnimationDelay}s;
      }

      &:nth-child(2) {
        transition-delay: ${slideAnimationDelay + 0.15 * 1}s;
      }

      &:nth-child(3) {
        transition-delay: ${slideAnimationDelay + 0.15 * 2}s;
      }
    }
  }
`;

export const PortfolioPyramid = (props) => {
  const {
    isActiveSlide = true,
    enableClick = true,
    height = 364,
    labelPosition = 'right',
    setActiveItem = () => {},
    progressBar,
    staticLabels,
    labelComponent,
    labels = [],
    ...rest
  } = props;

  const [activeSlice, setActiveSlice] = useState(undefined);

  useEffect(() => {
    setActiveItem(labels[activeSlice]);
  }, [activeSlice, setActiveItem, labels]);

  const LabelComponent = labelComponent;

  // We use 364 as the base size for the pyramid scaling everything according to that
  const scaler = 364 / height;

  return (
    <PyramidWrapper labelPosition={labelPosition} {...rest}>
      <CSSTransition in={isActiveSlide} classNames="animation" timeout={700}>
        <PyramidIcon
          isActiveSlide={isActiveSlide}
          activeSlice={activeSlice}
          enableClick={enableClick}
          height={height}
          viewBox="0 0 371 364"
        >
          <path
            d="M181.846 6.93436C183.334 4.03614 187.476 4.03615 188.964 6.93437L254.018 133.697C255.384 136.359 253.452 139.523 250.46 139.523H120.35C117.359 139.523 115.426 136.359 116.792 133.697L181.846 6.93436Z"
            fill="#C76FF2"
            onClick={() =>
              enableClick && setActiveSlice(activeSlice === 0 ? undefined : 0)
            }
          />
          <path
            d="M107.864 152.312L59.4492 244.53C58.0509 247.194 59.9826 250.39 62.9908 250.39H306.196C309.181 250.39 311.114 247.239 309.763 244.578L262.933 152.36C262.251 151.017 260.873 150.171 259.366 150.171H111.406C109.919 150.171 108.555 150.995 107.864 152.312Z"
            fill="#C5A5D6"
            onClick={() =>
              enableClick && setActiveSlice(activeSlice === 1 ? undefined : 1)
            }
          />
          <path
            d="M314.524 261.663H54.2897C52.7821 261.663 51.4025 262.511 50.7213 263.856L2.94132 358.193C1.59369 360.853 3.52716 364 6.50974 364H364.068C367.076 364 369.008 360.804 367.609 358.14L318.066 263.803C317.375 262.487 316.011 261.663 314.524 261.663Z"
            fill="#6D4694"
            onClick={() =>
              enableClick && setActiveSlice(activeSlice === 2 ? undefined : 2)
            }
          />
        </PyramidIcon>
      </CSSTransition>

      <LabelContainer height={height} style={{ fontSize: height / 10 }}>
        {labels.length > 0 &&
          labels.map((item, index) => {
            const active = activeSlice === index;

            if (!labelComponent) return null;

            return (
              <CSSTransition
                in={isActiveSlide}
                classNames="animation"
                timeout={700}
                key={`pyramid-label-${index}`}
              >
                <LabelWrapper
                  offset={item.offset}
                  index={index}
                  isActiveSlide={isActiveSlide}
                  isActiveSlice={active}
                  activeSlice={activeSlice}
                  labelPosition={labelPosition}
                  enableClick={enableClick}
                  onClick={() =>
                    enableClick && setActiveSlice(active ? undefined : index)
                  }
                >
                  <LabelInner labelPosition={labelPosition} scaler={scaler}>
                    <LabelComponent
                      isActiveSlice={active}
                      index={index}
                      {...item}
                    />
                  </LabelInner>
                </LabelWrapper>
              </CSSTransition>
            );
          })}
      </LabelContainer>
    </PyramidWrapper>
  );
};
