import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { GridElement } from '../layout/GridElement';

import { SPACING_48, SPACING_16, SPACING_8 } from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';
import { easeInOut, longAnimationTime } from '../../constants/animation';
import {
  accent,
  blackTranslucent,
  whiteTranslucent,
} from '../../constants/colors';
import { t } from '@formue-app/core';

const StyledUnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  position: relative;
  margin: ${SPACING_16} 0 ${SPACING_8};
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : '100%')};
  overflow-y: ${(props) => (props.overflowScrolling ? 'scroll' : 'auto')};

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    /* handle color */
    background: ${blackTranslucent(0.1)};
  }
  &::-webkit-scrollbar-track {
    /* background color */
    background: ${accent.sand2};
  }

  ${(props) =>
    props.overflowScrolling &&
    css`
      &:after {
        content: '';
        position: sticky;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        margin-top: -25px;
        display: block;
        background: linear-gradient(
          180deg,
          ${whiteTranslucent(0)} 0%,
          ${accent.sand1} 100%
        );
      }
    `}

  @media ${mobileCondition} {
    margin-top: ${SPACING_8};
  }
`;

const StyledOrderedList = styled.ol`
  ${StyledUnorderedList}
`;

const StyledListItem = styled.li`
  margin-bottom: ${SPACING_8};

  ${(props) =>
    props.animateItems &&
    css`
      position: relative;
      opacity: 0;
      top: 10px;
      transition: all ${easeInOut} ${longAnimationTime};
      transition-delay: ${props.animationDelay + 0.1 * props.index}s;

      ${(props) =>
        props.enter &&
        css`
          opacity: 1;
          top: 0;
        `}
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledSection = styled.section`
  margin-bottom: ${SPACING_48};

  &:last-child {
    border: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

const StyledHeader = styled.header`
  margin-bottom: ${SPACING_16};

  @media ${mobileCondition} {
    margin-bottom: ${SPACING_8};
  }
`;

const StyledFooter = styled.footer`
  margin-top: ${SPACING_8};

  ${(props) =>
    props.animateItems &&
    css`
      position: relative;
      opacity: 0;
      top: 10px;
      transition: all ${easeInOut} ${longAnimationTime};
      transition-delay: ${props.animationDelay + 0.1 * props.numberOfItems}s;

      ${(props) =>
        props.enter &&
        css`
          opacity: 1;
          top: 0;
        `}
    `}
`;

const SectionListHeader = (props) => {
  const { component } = props;

  if (!component) {
    return null;
  }

  return <header>{component}</header>;
};

const Section = (props) => {
  const {
    index,
    section,
    ordered,
    renderItem,
    renderSectionHeader,
    renderSectionFooter,
    animateItems,
    enter,
    animationDelay,
    maxHeight,
    listStyle,
    listRef,
    overflow,
    paginateItems,
  } = props;

  const itemsLength = section.data.length;

  const [loadedItems, setLoadedItems] = useState(
    paginateItems ? paginateItems + 1 : itemsLength
  );

  // The number of items in section.data might change if we are
  // starting a signing process so we need to update the "loadedItems"
  // as well so that the "item.slice" that's happening in renderListItems
  // don't slice of the last item in the ongoing list
  useEffect(() => {
    setLoadedItems(paginateItems ? paginateItems + 1 : itemsLength);
  }, [section.data, setLoadedItems, itemsLength, paginateItems]);

  const renderListItems = (items) =>
    items.slice(0, loadedItems).map((item, index) => (
      <StyledListItem
        key={index}
        index={index}
        animateItems={animateItems}
        enter={enter}
        animationDelay={animationDelay}
      >
        {renderItem(item)}
      </StyledListItem>
    ));

  return (
    <StyledSection>
      {!!renderSectionHeader ? (
        <StyledHeader>{renderSectionHeader({ section, index })}</StyledHeader>
      ) : null}

      {ordered ? (
        <StyledOrderedList
          ref={listRef}
          overflowScrolling={overflow}
          maxHeight={maxHeight}
          style={listStyle}
        >
          {renderListItems(section.data)}
        </StyledOrderedList>
      ) : (
        <StyledUnorderedList
          ref={listRef}
          overflowScrolling={overflow}
          maxHeight={maxHeight}
          style={listStyle}
        >
          {renderListItems(section.data, index)}
        </StyledUnorderedList>
      )}

      {!!renderSectionFooter ? (
        <StyledFooter
          animateItems={animateItems}
          enter={enter}
          animationDelay={animationDelay}
          numberOfItems={section.data.length}
        >
          {renderSectionFooter({ section, index })}
        </StyledFooter>
      ) : null}
      {!!paginateItems && loadedItems < itemsLength ? (
        <span onClick={() => setLoadedItems(loadedItems + paginateItems)}>
          {t('globals:loadMore')} (+{itemsLength - loadedItems})
        </span>
      ) : null}
    </StyledSection>
  );
};

const SectionListContent = (props) => {
  const { sections, maxHeight, keyExtractor, ...rest } = props;

  const listRef = useRef(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    const listHeight = listRef.current.clientHeight;
    setOverflow(listHeight === maxHeight);
  }, [listRef, maxHeight, sections]);

  return sections.map((section, index) => (
    <Section
      {...rest}
      maxHeight={maxHeight}
      key={keyExtractor(section, index)}
      section={section}
      index={index}
      listRef={listRef}
      overflow={overflow}
    />
  ));
};

export const SectionList = (props) => {
  const {
    className,
    sections,
    ListEmptyComponent,
    ListHeaderComponent,
    columns = 12,
    renderIfEmpty = true,
  } = props;

  // Determine if we have no data to show in sections
  const listIsEmpty = sections.flatMap((section) => section.data).length === 0;

  if (!renderIfEmpty && listIsEmpty) {
    return null;
  }

  return (
    <GridElement className={className} columns={columns}>
      <SectionListHeader component={ListHeaderComponent} />
      {listIsEmpty ? ListEmptyComponent : <SectionListContent {...props} />}
    </GridElement>
  );
};
