export const constructEmploymentString = employment => {
  let string = '';

  if (employment) {
    if (employment.occupation) {
      string += employment.occupation;
    }

    if (employment.occupation && employment.employer) {
      string += ', ';
    }

    if (employment.employer) {
      string += employment.employer;
    }
  }

  return string;
};

export const combineFamilyGroups = familyMembers => {
  // To group the family in the front end we dont really need a seperate category for 'mainmeber'
  // and 'spouse'. So we'll combine it into mainmember and get rid of the 'spouse' category
  const mainmember = familyMembers.mainmember || [];
  const spouse = familyMembers.spouse || [];

  familyMembers.mainmember = [...mainmember, ...spouse];

  delete familyMembers.spouse;

  return familyMembers;
};
