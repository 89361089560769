import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { tasks } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { actions as uiActions } from '../../ui/readStatus';

// Create actions and a reducer for the todo resource
export const [actions, reducer, resourceSagas] = resourceReducer('tasks');

// Create the sagas for this entity
function* readTasks() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(tasks.get, args);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, args }));
      yield put(uiActions.hydrateReadItems('tasks'));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

function* readTask() {
  yield takeEvery(actions.showRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(tasks.get, args);
      const normalized = normalize(result);
      yield put(actions.showRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.showRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

function* createTask() {
  yield takeEvery(actions.createRequestBegin, function* ({ payload }) {
    const { args, data } = payload;
    try {
      const result = yield call(tasks.post, data);
      const normalized = normalize(result);
      yield put(actions.createRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.createRequestFailure({ error }));

      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

function* updateTask() {
  yield takeLeading(actions.updateRequestBegin, function* ({ payload }) {
    const { args, data } = payload;
    try {
      const result = yield call(tasks.update, data);
      const normalized = normalize(result);
      yield put(actions.updateRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.updateRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [
  readTasks,
  readTask,
  createTask,
  updateTask,
  ...resourceSagas,
];
