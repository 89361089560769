import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategyImplementationPlansAllIds,
  investmentStrategyImplementationPlansById,
  investmentStrategyImplementationPlansSelector,
] = resourceSelectors('investmentStrategyImplementationPlans');

export const investmentStrategyImplementationPlansByIdSelector = (id) =>
  createSelector(investmentStrategyImplementationPlansById, (byId) => byId[id]);
