import React, { useState, useEffect, useRef } from 'react';
import { formatTwrTicks } from '@formue-app/core';
import { maxBy, minBy, isFunction } from 'lodash';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryArea,
  VictoryContainer,
  VictoryVoronoiContainer,
} from 'victory';
import { accent, backgroundWhite } from '../../constants/colors';
import { bodyFontStack } from '../../fonts';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { getLabelPositions } from '../../services/utils/charts';
import { SpaghettiChartLabel } from './SpaghettiChartLabel';
import { useQueryState } from 'react-router-use-location-state';
import { hexToRgbaString } from '@formue-app/core/src/services/utilities/colors';
import { SpaghettiChartTimePoint } from './SpaghettiChartTimePoint';
import { H6 } from '../texts';
import { SpaghettiChartTooltip } from './SpaghettiChartTooltip';

const YAxisTick = ({
  ticksColor,
  ticksBackground,
  ticksWidth,
  font,
  text,
  x,
  y,
  fontSize,
  ...rest
}) => {
  return (
    <foreignObject
      x={x - 3}
      y={y - fontSize / 2}
      width={ticksWidth}
      height={fontSize}
      style={{
        backgroundColor: ticksBackground,
        textAlign: 'center',
        verticalAlign: 'center',
        borderRadius: 2,
      }}
    >
      <H6 style={{ fontSize: fontSize, color: ticksColor }}>{text}</H6>
    </foreignObject>
  );
};

export const SpaghettiChart = (props) => {
  const {
    valueKey = 'twr',
    dataObject,
    reverseDataRendering,
    formatLineData = (data) => data,
    xAxisTicks = [],
    xAxisTicksFormat = () => {},
    yAxisTicksFormat = (twr) => formatTwrTicks(twr),
    domainPadding = { x: [0, 45], y: [10, 10] },
    tickCount,
    selected = [],
    isSelected = () => {},
    colorizeFunction = () => {},
    colorizeAreaFunction,
    defaultLineColor = '#dbdbdb',
    labelFormat,
    loading = true,
    showLineLabels = true,
    ticksColor = accent.velvet170,
    ticksBackground = 'transparent',
    ticksWidth = 15,
    gridColor = accent.velvet270,
    areaChart,
    areaChartOpacity = 0.64,
    fromMinValue = false,
    xAxisStickToBottom,
    xAxisBottomOffset = 0,
    showTimePointLabels,
    xLabelsOffset = 4,
    yLabelsOffset = 0,
    xLabelsSolidColor,
    interpolation = 'natural',
    height,
    animate = true,
    startYFromZero,
    paddingBottom = 0,
    paddingTop = 0,
    tooltipEnabled,
    tooltipBackgroundColor = accent.sand1,
    tooltipLineColor = accent.sand3,
    fontSizeY = 3.5,
    fontSizeX = 5,
    strokeWidth = 1,
    ...rest
  } = props;

  const allValues = Object.values(dataObject).flat(1);
  const [labelItems, setLabelItems] = useState({});
  const [exportMode] = useQueryState('exportMode', false);
  let chartRef = useRef(null);
  const chartHeight = height || 160;

  useEffect(() => {
    if (chartRef.current) {
    }
  }, [chartRef]);

  if (loading) {
    return (
      <div style={{ height: 380 }}>
        <CenteredActivityIndicator
          loadingColor={backgroundWhite}
          size={50}
          columns={2}
        />
      </div>
    );
  }

  const VictoryChartComponent = areaChart ? VictoryArea : VictoryLine;

  const maxValue = maxBy(allValues, valueKey)[valueKey];
  const minValue = startYFromZero ? 0 : minBy(allValues, valueKey)[valueKey];

  // We want to make some room in between the "minValue" and "y0" so we do that
  // by "lowering down" the y0 additionally for one fifth of the minValue.
  // We do this only to get a better visual experience
  let y0 = minValue - minValue / 5;

  // If the minimum value isn't negative, we want to "push" the zero axis up a bit
  // We do this only to get a better visual experience
  if (!fromMinValue) {
    y0 = minValue >= 0 ? 0 - maxValue / 7 : 0;
  }

  const labelPositions = getLabelPositions(labelItems, chartHeight, 10);
  let dataForRender = Object.keys(dataObject);
  if (reverseDataRendering) {
    dataForRender = dataForRender.reverse();
  }

  // If we have selected items we want to render them last to be set on top so the line is vissible
  // in scenarios where there are similar or the same data for multiple lines
  if (selected.length) {
    dataForRender.sort((a, b) => {
      return isSelected(selected, a) - isSelected(selected, b);
    });
  }

  const shouldAnimate = !exportMode && animate;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <VictoryChart
        domainPadding={domainPadding}
        padding={{ bottom: paddingBottom, top: paddingTop }}
        height={chartHeight}
        animate={shouldAnimate && { duration: 500 }}
        scale={{ x: 'time', y: 'linear' }}
        style={{ backgroundColor: 'red' }}
        containerComponent={
          tooltipEnabled ? (
            <VictoryVoronoiContainer
              containerRef={(ref) => {
                chartRef.current = ref;
              }}
              voronoiDimension="x"
              labels={({ datum }) => {
                if (selected.length) {
                  if (selected.includes(datum.id?.split('-')[0])) {
                    return yAxisTicksFormat(datum.change);
                  }
                } else {
                  return yAxisTicksFormat(datum.change);
                }
              }}
              labelComponent={
                <SpaghettiChartTooltip
                  backgroundColor={tooltipBackgroundColor}
                  lineColor={tooltipLineColor}
                  reverseDataRendering={reverseDataRendering}
                  selected={selected}
                />
              }
              style={{ display: 'flex' }}
            />
          ) : (
            <VictoryContainer
              containerRef={(ref) => {
                chartRef.current = ref;
              }}
            />
          )
        }
        {...rest}
      >
        <VictoryAxis
          offsetY={xAxisStickToBottom ? chartHeight : 11}
          scale="time"
          groupComponent={
            <g
              transform={`scale(0.97, 1), translate(10, ${xAxisBottomOffset})`}
            />
          }
          padding={{ right: 20, left: 20 }}
          tickValues={xAxisTicks}
          tickFormat={xAxisTicksFormat}
          tickCount={tickCount}
          standalone={false}
          style={{
            axis: { stroke: 'transparent' },
            tickLabels: {
              fontSize: fontSizeX,
              fontWeight: 400,
              padding: 0,
              fill: ticksColor,
              fontFamily: bodyFontStack,
              textAnchor: 'middle',
            },
          }}
        />

        <VictoryAxis
          crossAxis={false}
          dependentAxis
          offsetX={12}
          tickFormat={yAxisTicksFormat}
          tickLabelComponent={
            <YAxisTick
              ticksColor={ticksColor}
              font={bodyFontStack}
              ticksBackground={ticksBackground}
              ticksWidth={ticksWidth}
              fontSize={fontSizeY}
            />
          }
          style={{
            axis: { stroke: 'transparent' },
            grid: { stroke: gridColor },
            tickLabels: {
              fontSize: fontSizeY,
              fontWeight: 300,
              padding: 5,
              fill: ticksColor,
              fontFamily: bodyFontStack,
              textAnchor: 'start',
              verticalAnchor: 'start',
              background: '#333',
            },
          }}
        />

        {dataForRender.map((entryKey, index) => {
          let color = defaultLineColor;
          let areaColor = hexToRgbaString(color, areaChartOpacity);
          if (!selected.length || isSelected(selected, entryKey)) {
            let realIndex = index;
            if (reverseDataRendering) {
              realIndex = dataForRender.length - index - 1;
            }
            color = colorizeFunction(entryKey, realIndex);
            areaColor = hexToRgbaString(color, areaChartOpacity);
            if (isFunction(colorizeAreaFunction)) {
              areaColor = colorizeAreaFunction(entryKey, realIndex);
            }
          }

          let stroke = color;

          return (
            <VictoryChartComponent
              key={`spaghetti-line-${index}`}
              y={valueKey}
              y0={(d) => y0}
              standalone={false}
              interpolation={interpolation}
              data={formatLineData(dataObject[entryKey])}
              labels={(d, { datum }) => {
                return d.label;
              }}
              name={entryKey}
              labelComponent={
                <SpaghettiChartTimePoint
                  color={color}
                  selected={!selected.length || isSelected(selected, entryKey)}
                  entryKey={entryKey}
                  showLabels={showTimePointLabels}
                  callbackY={(y) => {
                    setLabelItems((state) => {
                      return { ...state, [entryKey]: y };
                    });
                  }}
                />
              }
              style={{
                data: {
                  stroke: stroke,
                  fill: areaChart ? areaColor : 'transparent',
                  strokeWidth: strokeWidth,
                  labelColor: color,
                },
              }}
            />
          );
        })}
      </VictoryChart>
      {showLineLabels ? (
        <svg
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
          }}
          height={chartHeight}
          width={450}
          viewBox="0 0 450 160"
        >
          {dataForRender.map((entryKey, index) => {
            let color = defaultLineColor;
            if (!selected.length || isSelected(selected, entryKey)) {
              color = colorizeFunction(entryKey, index);
            }

            return (
              <SpaghettiChartLabel
                key={`spaghetti-label-${index}`}
                label={entryKey}
                width="50"
                cy={labelPositions[entryKey] + yLabelsOffset}
                cx={xLabelsOffset}
                color={color}
                labelFormat={labelFormat}
                solidColor={xLabelsSolidColor}
                selected={isSelected(selected, entryKey)}
              />
            );
          })}
        </svg>
      ) : null}
    </div>
  );
};
