import React from 'react';
import styled from 'styled-components';

import { percentage } from '@formue-app/core';

import { H4, Paragraph } from '../../components/texts';

import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_16,
} from '../../constants/spacing';
import { accent } from '../../constants/colors';

import { SpriteIcon } from '../common/SpriteIcon';

const MetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const MetricsItem = styled.div`
  padding: 12px ${SPACING_16};
  background: ${accent.sand150};
  display: flex;
  align-items: center;
  gap: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const Value = styled(Paragraph)`
  margin-left: auto;
  font-weight: 500;
`;

export const Metrics = (props) => {
  const {
    sectionTitle,
    returnTitle,
    volatilityTitle,
    returnValue,
    volatilityValue,
    ...rest
  } = props;

  return (
    <div style={{ flex: 1 }}>
      <H4 style={{ marginBottom: SPACING_24 }}>{sectionTitle}</H4>
      <MetricsWrapper {...rest}>
        <MetricsItem>
          <SpriteIcon id="Results" />
          <H4>{returnTitle}</H4>
          <Value>{percentage(returnValue * 100)}</Value>
        </MetricsItem>
        <MetricsItem>
          <SpriteIcon id="Volatility" />
          <H4>{volatilityTitle}</H4>
          <Value>{percentage(volatilityValue * 100)}</Value>
        </MetricsItem>
      </MetricsWrapper>
    </div>
  );
};
