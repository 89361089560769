import { useEffect, useState } from 'react';

// Function to retrieve list data from Qlik
export const getListData = async (app, filter) => {
  return new Promise((resolve, reject) => {
    app.createList(
      {
        qDef: {
          qFieldDefs: [filter],
        },
        qExpressions: [],
        qInitialDataFetch: [
          {
            qHeight: 1000,
            qWidth: 1,
          },
        ],
        qLibraryId: null,
        qShowAlternatives: true,
      },
      (data, _app) => {
        const result = data.qListObject.qDataPages[0].qMatrix.map((obj) => {
          // qText is the text value of the for the list data
          // qState is a value that changes based on selections made,
          // possible values are:
          // O - Not selected, not disabled
          // S - Selected
          // X - Disabled
          // A - Alternative - Used for all other options in the list if one
          // item is selected, we ignore it. In Qliks default UI those items
          // are "light gray" but selectable, not dark gray and disabled.
          // https://support.qlik.com/articles/000087437?_ga=2.26980414.227960449.1657141056-946632487.1657015884
          // https://help.qlik.com/en-US/sense-developer/September2019/APIs/EngineAPI/definitions-ListObjectDef.html?_ga=2.26471614.227960449.1657141056-946632487.1657015884
          const { qText, qState } = obj[0];
          return {
            value: qText,
            selected: qState === 'S',
            disabled: qState === 'X',
          };
        });
        resolve(result);
      }
    );
  });
};

export const useQlikListData = (app, selections, fieldName) => {
  const [listData, setListData] = useState();

  /**
   * On every change of app or selections we fetch list data. If we didn't need
   * to fetch the update qState of very item in the list we wouldn't have to do
   * this when selections change. But if we do this after selections are made
   * we get back an updated state for each item. The state tells us if an item
   * is selected, selectable (based on other values selected) etc.
   */
  useEffect(() => {
    if (!app) {
      return;
    }
    const asyncWrapper = async () => {
      const data = await getListData(app, fieldName);
      setListData(data);
    };
    asyncWrapper();
  }, [app, selections]);

  return listData;
};
