import { createSelector } from 'reselect';

import { sortByAssetClass, sortByAssetGroup } from '@formue-app/core';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [portfolioAllIds, portfolioById, allPortfolioItemsSelector] =
  resourceSelectors('portfolios');

export const portfolioTotalSelector = createSelector(
  allPortfolioItemsSelector,
  (items) => {
    const total = items.filter((item) => item.dataType === 'total');
    return total[0];
  }
);

export const portfolioAssetGroupsSelector = createSelector(
  allPortfolioItemsSelector,
  (items) => {
    return items
      .filter((item) => item.dataType === 'assetGroup')
      .sort(sortByAssetGroup);
  }
);

export const portfolioAssetClassesSelector = createSelector(
  allPortfolioItemsSelector,
  (items) => {
    return items
      .filter((item) => item.dataType === 'assetClass')
      .sort(sortByAssetClass);
  }
);

export const portfolioAssetClassesWithSubitemsSelector = createSelector(
  allPortfolioItemsSelector,
  (items) => {
    return items
      .filter((item) => item.dataType === 'assetClass')
      .map((item) => ({
        ...item,
        subItems: items.filter(
          (subItem) =>
            subItem.class === item.class && subItem.dataType === 'product'
        ),
      }))
      .sort(sortByAssetClass);
  }
);

export const portfolioProductsSelector = createSelector(
  allPortfolioItemsSelector,
  (items) => {
    return items.filter((item) => item.dataType === 'product');
  }
);

export const createPortfolioAssetClassesSelector = (group) => {
  return createSelector(portfolioAssetClassesSelector, (items) => {
    return items.filter((item) => item.group === group);
  });
};

export const createPortfolioProductsSelector = (assetClass) => {
  return createSelector(portfolioProductsSelector, (items) => {
    return items.filter((item) => item.class === assetClass);
  });
};

export const portfolioFeeDetailsSelector = createSelector(
  allPortfolioItemsSelector,
  (items) => {
    return items.filter((item) => item.dataType === 'feeDetail');
  }
);

export const portfolioFeeTotalSelector = createSelector(
  allPortfolioItemsSelector,
  (items) => {
    const total = items.filter((item) => item.dataType === 'feeTotal');
    return total[0];
  }
);
