import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { blackTranslucent, textLightest } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_32,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';

import { ParagraphSmall } from '../../texts';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_32};
`;

const ErrorWrapper = styled.div`
  background: ${blackTranslucent(0.13)};
  padding: ${SPACING_8} ${SPACING_16};
  border-radius: 24px;

  p {
    color: ${textLightest};
    font-weight: bold;
  }
`;

export const SlideErrorMessage = (props) => {
  return (
    <Wrapper>
      <ErrorWrapper>
        <ParagraphSmall>
          {t('errorMessages:noDataWealthplan:title')}
        </ParagraphSmall>
      </ErrorWrapper>
    </Wrapper>
  );
};
