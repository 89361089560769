export const ENVIRONMENT_INVESTING_THEMES = [
  {
    key: 'carbonEmissions',
    iconId: 'Pollution Waste',
  },
  {
    key: 'fossilFuelExposure',
    iconId: 'Natural Capital',
  },
  {
    key: 'biodiversityImpact',
    iconId: 'Sustainable Investment',
  },
  {
    key: 'emissionsToWater',
    iconId: 'Liquidity',
  },
  {
    key: 'hazardousWaste',
    iconId: 'Warning',
  },
];

export const SOCIETY_INVESTING_THEMES = [
  {
    key: 'humanRightsViolations',
    iconId: 'Family',
  },
  {
    key: 'controversialWeapons',
    iconId: 'Goal',
  },
  {
    key: 'equalityAndDiversity',
    iconId: 'Law',
  },
];
