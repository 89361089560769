import { ClientSignature } from './ClientSignature';
import { AdvisorComment } from './AdvisorComment';
import { ConstructionInfo } from './ConstructionInfo';
import { HistoricalDrawdown } from './HistoricalDrawdown';
import { HistoricalPerformance } from './HistoricalPerformance';
import { ImplementationPlan } from './ImplementationPlan';
import { ImplementationPlanGraph } from './ImplementationPlanGraph';
import { PortfolioCharacteristics } from './PortfolioCharacteristics';
import { StrategyDetails } from './StrategyDetails';
import { StrategyProducts } from './StrategyProducts';
import { WelcomeSlideExport } from './WelcomeSlideExport';

export const investmentStrategyExportSlides = {
  ClientSignature,
  AdvisorComment,
  ConstructionInfo,
  HistoricalDrawdown,
  HistoricalPerformance,
  ImplementationPlan,
  ImplementationPlanGraph,
  PortfolioCharacteristics,
  StrategyDetails,
  StrategyProducts,
  WelcomeSlideExport,
};
