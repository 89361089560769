import React from 'react';

import { localizeCountryName, t } from '@formue-app/core';

import { BORDER_RADIUS_SMALL } from '../../../../constants/spacing';
import { Flag } from './Flag';
import { LensesListItem } from '../LensesListItem';
import { LensesPercentageBadge } from '../LensesPercentageBadge';
import { LensesTopThree } from '../LensesTopThree';

export const CountryItem = ({
  securities,
  countryCode,
  partOfKnownPortfolio,
  ...rest
}) => {
  const title = countryCode
    ? localizeCountryName(countryCode.toUpperCase())
    : t('globals:other');

  const topThreeSecurities = securities.map((security) => ({
    percentage: security.partOfPortfolio,
    title: security.name,
  }));

  return (
    <LensesListItem {...rest}>
      <LensesPercentageBadge
        title={title}
        percentage={partOfKnownPortfolio}
        icon={
          <Flag
            countryCode={countryCode}
            width={32}
            height={32}
            style={{ borderRadius: BORDER_RADIUS_SMALL }}
          />
        }
      />
      <LensesTopThree
        title={t('geography:topThreeCompanies')}
        items={topThreeSecurities}
      />
    </LensesListItem>
  );
};
