import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { accent, textLightest } from '../../constants/colors';
import { paragraphSmallSize } from '../../constants/text';
import { bodyFontStack } from '../../fonts';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

const StyledContainer = styled.div`
  position: relative;
  border-radius: 50px;
  padding: 2px;
  transition: background ${easeInOut} ${shortAnimationTime};
`;

const StyledButton = styled.button`
  outline: none;
  border: 0;
  background: none;
  font-family: ${bodyFontStack};
  padding: 5px 22px;
  font-size: ${paragraphSmallSize};
  font-weight: 500;
  line-height: 140%;
  cursor: pointer;
  border-radius: 50px;
  z-index: 2;
  position: relative;
  transition: color ${easeInOut} ${shortAnimationTime};
  white-space: nowrap;
`;

const Shield = styled.div`
  position: absolute;
  top: 2px;
  bottom: 2px;
  height: calc(100% - 4px);
  border-radius: 50px;
  z-index: 1;
  transition: all ${easeInOut} ${shortAnimationTime};
`;

export const MultipleSwitch = (props) => {
  const {
    initial = 0,
    options = [],
    color = accent.ocean170,
    backgroundColor = accent.sand1,
    activeBackgroundColor = accent.ocean1,
    activeColor = textLightest,
    onChange = () => {},
    style,
    ...rest
  } = props;

  const [activeIndex, setActiveIndex] = useState(initial);
  const [maxWidthItem, setMaxWidthItem] = useState(null);
  const itemRefs = useRef([]);

  useEffect(() => {
    setMaxWidthItem(
      Math.max(...itemRefs.current.map((ref) => ref.offsetWidth))
    );
  }, [options, itemRefs]);

  return (
    <StyledContainer {...rest} style={{ ...style, backgroundColor }}>
      <Shield
        style={{
          width: maxWidthItem,
          backgroundColor: activeBackgroundColor,
          left: activeIndex === 0 ? 2 : 'calc(50% - 2px)',
        }}
      />
      {options.map((option, index) => (
        <StyledButton
          ref={(el) => (itemRefs.current[index] = el)}
          key={`option-${index}`}
          onClick={() => {
            setActiveIndex(index);
            onChange(option.value);
          }}
          style={{
            color: activeIndex === index ? activeColor : color,
            marginRight: index === 0 ? -4 : 0,
            width: maxWidthItem,
          }}
        >
          {option.label}
        </StyledButton>
      ))}
    </StyledContainer>
  );
};
