import { selectors } from '@formue-app/core';
import { useSelector } from 'react-redux';

const { operatingCountrySelector } = selectors.auth;

/**
 * A generic "wrapper" component that one can use if there is a component
 * one only wants to render if the user is in a specific country. E.g to
 * show something only to swedish customers:
 *
 * <VisibleInCountry country="se">
 *   <JustForSwedes />
 * </VisibleInCountry>
 */
export const VisibleInCountry = (props) => {
  const operatingCountry = useSelector(operatingCountrySelector);
  const { country, children } = props;

  if (!country) {
    throw new Error('Prop country is required');
  }

  // Allow sending in both a string or an array of countries
  let countries = country;
  if (!Array.isArray(country)) {
    countries = [country];
  }

  if (!countries.includes(operatingCountry)) {
    return null;
  }

  return children;
};
