import React from 'react';
import styled from 'styled-components';

import { SPACING_24, SPACING_16 } from '../../constants/spacing';
import { Paragraph } from '../texts';
import { ReactComponent as IconArrow } from '../../assets/icons/arrows/arrow-right-list.svg';

const StyledList = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.spacing};
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_16};
`;

export const UnorderedList = ({
  items,
  fontSize = 15,
  spacing = SPACING_24,
  ...rest
}) => {
  return (
    <StyledList {...rest} spacing={spacing}>
      {items.map((text, index) => (
        <StyledListItem key={index}>
          <IconArrow style={{ position: 'relative', top: 5 }} />
          <Paragraph style={{ flex: 1 }}>{text}</Paragraph>
        </StyledListItem>
      ))}
    </StyledList>
  );
};
