import React from 'react';

import { RadioButton } from '../../formElements';
import { Container, StyledLabel } from './ExtendedReportingCheckbox';

export const ExtendedReportingRadioButton = ({
  label,
  onChange,
  selected,
}) => {
  return (
    <Container
      onClick={onChange}
    >
      <StyledLabel>{label}</StyledLabel>
      <RadioButton
        id={`period-${label}`}
        name={`period-${label}`}
        checked={selected}
        onChange={onChange}
        height={20}
        width={20}
      />
    </Container>
  );
};
