import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import camelcase from 'camelcase';
import { groupBy } from 'lodash';

import { selectors, t } from '@formue-app/core';
import { CONTINENTS } from '@formue-app/core/src/constants/geography';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_32,
  SPACING_64,
  SPACING_16,
  SPACING_8,
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
} from '../../../constants/spacing';
import { accent, textLightest } from '../../../constants/colors';
import { SvgMap } from '../../portfolio/lenses/geography/SvgMap';
import { Paragraph } from '../../texts';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';
import { LensesText } from '../investmentStrategyComponents/LensesText';
import { SelectDropdown } from '../../formElements';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { DotIcon } from '../../common/DotIcon';
import { ActivityIndicator } from '../../common/ActivityIndicator';

const {
  entities: {
    investmentStrategyPortfolioCharacteristics: {
      investmentStrategyPortfolioCharacteristicsByStrategyIdSelector,
    },
  },
} = selectors;

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_64};
  align-items: center;
  position: relative;
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: ${SPACING_8};
  padding-right: 130px;
  padding-top: ${SPACING_32};
`;

const ListItem = styled.li`
  border: 1px solid
    ${(props) => (props.selected ? accent.ocean4 : accent.ocean440)};
  padding: 7px ${SPACING_16};
  background: ${(props) => (props.selected ? accent.ocean4 : 'none')};
  border-radius: 20px;
  cursor: pointer;

  opacity: ${(props) => (props.animate ? 1 : 0)};
  transition: ${(props) => `all ${easeInOut} ${shortAnimationTime}`};

  p {
    color: ${(props) => (props.selected ? textLightest : accent.ocean490)};
    font-weight: 500;
    transition: ${(props) => `all ${easeInOut} ${shortAnimationTime}`};
  }
`;

export const GeographySlide = (props) => {
  const { isActiveSlide } = props;

  const strategies = useEnabledStrategies();

  const [selectedContinent, setSelectedContinent] = useState('Global');
  const [selectedStrategy, setSelectedStrategy] = useState(strategies[0]);
  const portfolioCharacteristics = useSelector(
    investmentStrategyPortfolioCharacteristicsByStrategyIdSelector(
      selectedStrategy?.id
    )
  );

  if (!strategies.length) return null;

  const groupByContinents = groupBy(
    portfolioCharacteristics?.geography?.allocations,
    'continent'
  );

  const continents = Object.keys(groupByContinents)
    .map((key) => ({
      id: camelcase(key),
      continent: key,
      partOfKnownPortfolio: groupByContinents[key].reduce(
        (acc, item) => acc + item.allocation,
        0
      ),
      contries: groupByContinents[key]
        .map((item) => ({ ...item, partOfKnownPortfolio: item.allocation }))
        .sort((a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio),
    }))
    .filter((item) => CONTINENTS.includes(item.continent))
    .sort((a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio);

  const continentsWidthAssets = continents.filter(
    (item, index) => item.partOfKnownPortfolio > 0.01
  );
  const topThreeCountries = portfolioCharacteristics?.geography?.allocations
    .filter((country) => country.continent === selectedContinent)
    .map((item) => ({ ...item, partOfKnownPortfolio: item.allocation }))
    .sort((a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio);

  const highlight =
    selectedContinent === 'Global'
      ? continentsWidthAssets.map((item) => item.continent)
      : selectedContinent;

  return (
    <Base>
      <SelectDropdown
        options={strategies.map((strategy) => ({
          ...strategy,
          label: strategy.title,
          value: strategy.id,
        }))}
        icon={(item) => <DotIcon radius={8} color={item?.color} />}
        optionsIcon={(item) => <DotIcon radius={8} color={item?.color} />}
        selectedCallback={(options) => setSelectedStrategy(options[0])}
        subtitle={t('portfolio:portfolioStrategy:chooseStrategy')}
        style={{
          position: 'absolute',
          right: ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
          top: -43,
          zIndex: 1,
        }}
      />

      {!portfolioCharacteristics ? (
        <ActivityIndicator />
      ) : (
        <>
          <ContentWrapper style={{ width: 'auto', marginTop: 70 }}>
            <SvgMap
              width={600 * 1.4}
              height={350 * 1.4}
              zoom={selectedContinent}
              continents={continents.filter(
                (item, index) => item.partOfKnownPortfolio > 0.01
              )}
              topThreeCountries={topThreeCountries.filter(
                (item, index) => index < 3
              )}
              highlight={highlight}
              selectedContinent={selectedContinent}
              onClick={(continent) => setSelectedContinent(continent)}
              defaultColor={accent.ocean250}
              boxShadowColor={accent.sand1}
              countryMarkersStyles={{ left: 'auto', right: 80 }}
              style={{ left: -95 }}
            />
          </ContentWrapper>
          <ContentWrapper style={{ justifyContent: 'center' }}>
            <LensesText
              i18nKey="geography:globalText"
              values={{
                number: portfolioCharacteristics.geography.allocations.length,
              }}
            />

            <List>
              <ListItem
                onClick={() => setSelectedContinent('Global')}
                selected={selectedContinent === 'Global'}
                index={0}
                animate={isActiveSlide && !!continents}
              >
                <Paragraph>{t('geography:continents:global')}</Paragraph>
              </ListItem>
              {continents.map((continent, index) => (
                <ListItem
                  key={`continent-${index}`}
                  selected={selectedContinent === continent.continent}
                  onClick={() => setSelectedContinent(continent.continent)}
                  index={index + 1}
                  animate={isActiveSlide && !!continents}
                >
                  <Paragraph>
                    {t(
                      `geography:continents:${camelcase(continent.continent)}`
                    )}
                  </Paragraph>
                </ListItem>
              ))}
            </List>
          </ContentWrapper>
        </>
      )}
    </Base>
  );
};
