import { apiFetch } from './utils/api';

export const meetingFeedback = {
  post: async (payload) => {
    const options = {
      method: 'POST',
      body: payload,
    };

    const url = '/v3/meetings/annual-review/feedback';
    return apiFetch(url, options);
  },
};
