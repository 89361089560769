import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';

import {
  financialValues,
  percentage,
  selectors,
  t,
  transformActiveFilters,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { ParagraphSmall } from '../../texts';
import { useSelector } from 'react-redux';
import { printCondition } from '../../../constants/media';
import { BarChart } from '../../graphs/BarChart';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { accent } from '../../../constants/colors';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  @media ${printCondition} {
    margin-left: ${SPACING_24} !important;
    gap: ${SPACING_8};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const BarWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 281px;

  @media ${printCondition} {
    min-height: auto;
  }
`;

const {
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

export const PeriodicGainYear = (props) => {
  const activeFilters = useSelector(activeFiltersSelector);
  const prevActiveFilters = useRef(null);

  const [localLoading, setLocalLoading] = useState(false);
  const [financialValuesData, setFinancialValuesData] = useState([]);

  // Since this data is detached from the time filter we fetch the data manually to
  // not clutter the store with data that is not in line with the active filters of the user.
  // As we naivly assume that the store contains what you need based on the active filters.
  //
  // If we end up needing this type of detached data in the feature extracting this into a seperate
  // function would be good.
  useEffect(() => {
    const fetchData = async () => {
      setLocalLoading(true);
      let args = transformActiveFilters(activeFilters);
      args = {
        ...args,
        granularity: 'YEAR',
        // Hardcoded date for the first entry of data for all portfolios
        startDate: new Date('12.31.12').toISOString().split('T')[0],
        endDate:
          activeFilters.endDate || new Date().toISOString().split('T')[0],
      };
      const data = await financialValues.get(args);
      const formatedData = data.data.map((item) => {
        return {
          id: item.id,
          type: item.type,
          ...item.attributes,
        };
      });

      setFinancialValuesData(formatedData);
      setLocalLoading(false);
    };

    if (!isEqual(activeFilters, prevActiveFilters.current)) {
      prevActiveFilters.current = activeFilters;
      fetchData().catch(console.error);
    }
  }, [activeFilters]);

  return (
    <StyledBaseSection columns={6}>
      <TextWrapper>
        <ParagraphSmall>
          {t('portfolio:periodicReporting:yearlyReturn')}
        </ParagraphSmall>
      </TextWrapper>

      {localLoading ? (
        <CenteredActivityIndicator />
      ) : (
        <BarWrapper>
          <BarChart
            data={financialValuesData.slice(
              Math.max(financialValuesData.length - 5, 0)
            )}
            valueKey="twr"
            labelKey="p"
            valueFormatFunction={(value) => percentage(value * 100)}
            showValue={true}
            zeroAxis={true}
            barSpacing={24}
            yAxis={false}
            color={accent.ocean1}
          />
        </BarWrapper>
      )}
    </StyledBaseSection>
  );
};
