import React, { useState } from 'react';
import styled from 'styled-components';

import { ANNUAL_REVIEW_REPORT_CONTENT_SPACING } from '../../constants/spacing';
import { accent } from '../../constants/colors';

import { MainGrid } from '../layout';
import { StrategiesConfiguration } from './StrategiesConfiguration';

const Wrapper = styled(MainGrid)`
  min-width: 100%;
  height: 100%;
  position: fixed;
  padding: ${ANNUAL_REVIEW_REPORT_CONTENT_SPACING};
  padding-top: 100px;
  background-color: ${accent.sand1};
  z-index: 10;
  overflow: scroll;
`;

export const ConfigureOverlay = (props) => {
  const { slides, enabledSlides, setActiveSlides, setShowConfigurePanel } =
    props;

  const [, setSubmitted] = useState(false);

  return (
    <Wrapper>
      <StrategiesConfiguration
        slides={slides}
        enabledSlides={enabledSlides}
        setActiveSlides={setActiveSlides}
        setSubmitted={setSubmitted}
        setShowConfigurePanel={setShowConfigurePanel}
      />
    </Wrapper>
  );
};
