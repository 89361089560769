import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useImageUrlWithAuthToken } from '@formue-app/core';
import { BaseFeedItem } from './BaseFeedItem';

const imageFallback = require('../../assets/images/default/formue.png');

export const EventFeedItem = ({ id, icon }) => {
  const eventsById = useSelector((state) => state.entities.events.byId);
  const data = eventsById[id];

  const history = useHistory();
  const imageUrl = useImageUrlWithAuthToken(data && data.image);

  const handleOnClick = () => {
    history.push(`/events/${id}`);
  };

  // There is a possibility that /feed api returned a non-existing id
  // so we need to make sure we have the data in the /events api
  if (!data) return null;

  return (
    <BaseFeedItem
      columns={4}
      key={`feed-${id}`}
      {...data}
      date={data.startsAt}
      onClick={handleOnClick}
      image={imageUrl}
      imageFallback={imageFallback}
      translateCategories
      icon={icon}
    />
  );
};
