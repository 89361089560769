import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';
import * as lunr from 'lunr';
import styled from 'styled-components';

import { selectors, filterList, useResource } from '@formue-app/core';

import { backgroundMain, primary } from '../constants/colors';

import { MenuPage } from './base/MenuPage';
import { BaseSection } from '../components/common/BaseSection';
import { ClientList } from '../components/impersonation/ClientList';
import { ListFilterHeader } from '../components/lists/ListFilterHeader';
import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';
import { GridElement } from '../components/layout';

import { ParagraphSmall } from '../components/texts';
import { SPACING_24, SPACING_16, SPACING_8 } from '../constants/spacing';

import { SimplePage } from './base/SimplePage';
import { SpriteIcon } from '../components/common/SpriteIcon';

const Info = styled(GridElement)`
  display: flex;
  gap: ${SPACING_8};
  margin-top: calc(-${SPACING_24} + ${SPACING_16});
  margin-bottom: ${SPACING_16};
`;

const {
  entities: {
    clients: { clientsAllIdsSelector, clientsByIdSelector },
  },
  ui: {
    impersonation: { recentlyImpersonatedClientsSelector },
  },
} = selectors;

export const ClientsPage = (props) => {
  const resourceLoading = useResource(['CLIENTS/INDEX']);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useQueryState('search', '');
  const allIds = useSelector(clientsAllIdsSelector);
  const byId = useSelector(clientsByIdSelector);
  const serializedSearchIndex = useSelector(
    (state) => state.ui.clientSearch.searchIndex
  );
  const [searchIndex, setSearchIndex] = useState(null);
  const recentlyImpersonatedClientIds = useSelector(
    recentlyImpersonatedClientsSelector
  );
  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);

  useEffect(() => {
    if (serializedSearchIndex) {
      const index = lunr.Index.load(JSON.parse(serializedSearchIndex));
      setSearchIndex(index);
    }
  }, [serializedSearchIndex]);

  useEffect(() => {
    if (!resourceLoading && searchIndex && recentlyImpersonatedClientIds) {
      setLoading(false);
    }
  }, [resourceLoading, searchIndex, recentlyImpersonatedClientIds]);

  const filters = {
    search: {
      type: 'search',
      value: search,
      onChange: setSearch,
      label: 'Search for name or client ID',
      searchIndex,
      searchOptions: {
        count: 8,
      },
      style: {
        maxWidth: '100%',
      },
    },
  };

  const [clients, filterData] = filterList(allIds, byId, filters);

  // If the user havn't added more than 4 characters in the search field we
  // just show the list of recently impersonated clients
  let items;
  if (search.length < 5) {
    items = recentlyImpersonatedClientIds.map((id) => byId[id]).filter(Boolean);
  } else {
    items = clients;
  }

  const PageComponent = isImpersonating ? MenuPage : SimplePage;

  return (
    <PageComponent
      {...props}
      showIcon={false}
      titleStyles={{ paddingBottom: 0 }}
    >
      <Info columns={8}>
        <SpriteIcon id="Info" size={SPACING_16} inverted={true} />
        <ParagraphSmall style={{ width: 'fit-content' }}>
          When you log in as a client in the portal or app, the visit is logged
          in our systems. As an employee, you only have read permissions in the
          client portal/app. You don’t have permissions to change, confirm or
          delete any information.
        </ParagraphSmall>
      </Info>
      <ListFilterHeader
        filters={filters}
        data={filterData}
        enablePrint={false}
      />
      {loading ? (
        <BaseSection style={{ background: backgroundMain }} columns="12">
          <CenteredActivityIndicator
            loadingColor={primary}
            size={50}
            columns={2}
          />
        </BaseSection>
      ) : (
        <>
          <ClientList items={items} />
        </>
      )}
    </PageComponent>
  );
};
