import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  selectors,
  getColorForAssetClassGroup,
  lightenColor,
  useResource,
} from '@formue-app/core';
import { useSelector } from 'react-redux';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';
import { useMediaQuery } from 'react-responsive';

import {
  assetClassDefaultColorScheme,
  accent,
} from '../../../constants/colors';
import { PortfolioHoldingsChartLabel } from './PortfolioHoldingsChartLabel';
import { PortfolioHoldingsChartTooltip } from './PortfolioHoldingsChartTooltip';
import { mobileCondition } from '../../../constants/media';

const {
  entities: {
    portfolios: { portfolioAssetClassesSelector },
  },
} = selectors;

const formatData = (data) => {
  const smallSpacing = 0.1;
  const bigSpacing = 0.4;
  return data.map((item, index) => {
    if (index > 2 && index < 5)
      return { ...item, y: item.twr, x: index + bigSpacing };
    if (index > 4)
      return { ...item, y: item.twr, x: index + bigSpacing * 1.75 };
    return { ...item, y: item.twr, x: index + smallSpacing };
  });
};

const Container = styled.div`
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

export const PortfolioHoldingsChart = ({
  selectedItems = [],
  selectCallback,
  height = 180,
  fontSize = 6,
  showTooltip = true,
  style,
}) => {
  const loading = useResource(['PORTFOLIOS/INDEX']);
  const assetClasses = useSelector(portfolioAssetClassesSelector);
  const [activeLabel, setActiveLabel] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(false);
  }, [assetClasses]);

  useEffect(() => {
    if (!loading) {
      setIsVisible(true);
    }
  }, [loading]);

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const handlePress = () => {
    return [
      {
        target: 'data',
        mutation: ({ datum }) => {
          const { class: className } = datum;
          if (selectCallback) {
            if (selectedItems.includes(className)) {
              selectCallback([
                ...selectedItems.filter((item) => item !== className),
              ]);
            } else {
              selectCallback([...selectedItems, className]);
            }
          }
        },
      },
    ];
  };

  const handleOnMouseOver = () => {
    if (showTooltip) {
      return [
        {
          target: 'data',
          mutation: ({ datum }) => {
            const color = getColorForAssetClassGroup(
              datum.group,
              assetClassDefaultColorScheme
            );
            setActiveLabel(datum.class);
            return {
              style: { fill: lightenColor(color, 0.2) },
              labelActive: true,
            };
          },
        },
        {
          target: 'labels',
          mutation: () => ({ active: true }),
        },
      ];
    }
  };

  const handleOnMouseOut = () => {
    setActiveLabel(null);

    return [
      {
        target: 'data',
        mutation: () => {},
      },
      {
        target: 'labels',
        mutation: () => ({ active: false }),
      },
    ];
  };

  return (
    <Container style={style} isVisible={isVisible}>
      <VictoryChart
        padding={{
          left: 15,
          right: 15,
          bottom: mobileSize ? 100 : 30,
          top: mobileSize ? 40 : 30,
        }}
        height={height}
        domainPadding={{ x: [30, 30], y: mobileSize ? 50 : 0 }}
        singleQuadrantDomainPadding={false}
        style={{
          parent: {
            minHeight: '100%',
            height: mobileSize ? height : 'auto',
          },
        }}
      >
        <VictoryBar
          events={[
            {
              target: 'data',
              eventHandlers: {
                onClick: handlePress,
                onMouseOver: handleOnMouseOver,
                onMouseOut: handleOnMouseOut,
              },
            },
          ]}
          //animate={{ duration: 400, delay: 300 }}
          style={{
            data: {
              fill: ({ datum }) => {
                const { class: className, group } = datum;
                if (selectedItems.length) {
                  if (selectedItems.includes(className)) {
                    return getColorForAssetClassGroup(
                      group,
                      assetClassDefaultColorScheme
                    );
                  }
                  return 'rgba(109, 70, 148, 0.2)';
                }
                return getColorForAssetClassGroup(
                  group,
                  assetClassDefaultColorScheme
                );
              },
            },
          }}
          cornerRadius={{ topLeft: 2, topRight: 2 }}
          barRatio={1.08}
          labels={({ datum }) => datum.y}
          labelComponent={
            showTooltip ? (
              <PortfolioHoldingsChartTooltip
                activeClass={activeLabel}
                fontSize={fontSize}
              />
            ) : (
              <PortfolioHoldingsChartLabel
                activeClass={activeLabel}
                fontSize={fontSize}
                showTooltip={showTooltip}
              />
            )
          }
          data={formatData(assetClasses)}
        />
        {isVisible ? (
          <VictoryAxis
            label={null}
            orientation="bottom"
            style={{
              axis: { stroke: accent.velvet2 },
              tickLabels: { fill: 'none' },
            }}
          />
        ) : null}
      </VictoryChart>
    </Container>
  );
};
