import React from 'react';
import styled from 'styled-components';

import { formatGainValue, formatTwrValue } from '@formue-app/core';
import { H4 } from '../../../texts';
import { easeInOut, longAnimationTime } from '../../../../constants/animation';
import { H5LineHeight, H5Size } from '../../../../constants/text';

const LabelWrapper = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  transition: all ${longAnimationTime} ${easeInOut};

  h4 {
    font-size: ${H5Size};
    line-height: ${H5LineHeight};
  }
`;

export const PortfolioReturnChartLabel = (props) => {
  const { dataType = 'twr', value = 0, offsetY, isNegative } = props;

  if (!value) return null;

  return (
    <LabelWrapper style={isNegative ? { top: offsetY } : { bottom: offsetY }}>
      <H4>
        {dataType === 'twr'
          ? formatTwrValue(value, 1, true)
          : formatGainValue(value)}
      </H4>
    </LabelWrapper>
  );
};
