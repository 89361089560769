import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { percentage, t, selectors } from '@formue-app/core';
import { notDesktopCondition } from '../../../../constants/media';
import { BaseSection } from '../../../common/BaseSection';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_8,
} from '../../../../constants/spacing';
import { ModalSection } from './ModalSection';
import { H1, H2Small, Paragraph, ParagraphLarge } from '../../../texts';
import { SustainabilityLineChart } from './SustainabilityLineChart';
import { ComparisonWithOthers } from './ComparisonWithOthers';
import { OrderedList } from '../../../texts/OrderedList';
import { SustainabilityAdvisor } from './SustainabilityAdvisor';
import { Coverage } from './Coverage';
import { backgroundWhite } from '../../../../constants/colors';

const {
  entities: {
    portfolioLenses: {
      scienceBasedShareTimelineSelector,
      scienceBasedShareDataSelector,
      portfolioLensesMetadataSelector,
    },
  },
} = selectors;

const i18nPrefix = 'sustainability:accordions';

const StyledWrapper = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${SPACING_16} 0;
  flex-flow: column nowrap;
  gap: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${notDesktopCondition} {
    flex-flow: column;
    padding: 0;
  }
`;

const SectionTitle = styled(H2Small)`
  padding-bottom: ${SPACING_8};
`;

const RowWrapper = styled.div`
  display: flex;
  gap: ${SPACING_12};
  flex: 1;
  width: 100%;
`;

export const ScienceBasedTargetsModalContent = (props) => {
  const { renderInPresentation, ...rest } = props;

  const scienceBasedShareTimeline = useSelector(
    scienceBasedShareTimelineSelector
  );
  const scienceBasedShareData = useSelector(scienceBasedShareDataSelector);
  const portfolioLensesMetadata = useSelector(portfolioLensesMetadataSelector);
  const { global, domestic } =
    portfolioLensesMetadata.benchmark.scienceBasedTargets;
  return (
    <StyledWrapper>
      <div
        style={
          renderInPresentation && {
            backgroundColor: backgroundWhite,
            padding: SPACING_24,
            borderRadius: 8,
          }
        }
      >
        <H1>
          {percentage(scienceBasedShareData.target * 100, 0, false, false)}
        </H1>
        <ParagraphLarge
          style={{ paddingBottom: renderInPresentation ? 0 : SPACING_16 }}
        >
          {t('sustainability:scienceBasedTargetsScreen:subtitle')}
        </ParagraphLarge>
      </div>
      <RowWrapper>
        <ModalSection highlighted={!renderInPresentation}>
          <SustainabilityLineChart
            style={{ marginTop: SPACING_32 }}
            value={percentage(
              scienceBasedShareData.target * 100,
              0,
              false,
              false
            )}
            data={scienceBasedShareTimeline}
            trendSelector="scienceBasedShare.target"
            trendInPercentage={false}
            formatTrendValue={(trendValue) =>
              percentage(trendValue * 100, 2, false, false)
            }
          />
        </ModalSection>
        <ModalSection highlighted={!renderInPresentation}>
          <SectionTitle>
            {t(`${i18nPrefix}:comparisonWithOthers:title`)}
          </SectionTitle>
          <ComparisonWithOthers
            global={global}
            domestic={domestic}
            value={scienceBasedShareData.target}
            formatValue={(value) => percentage(value * 100, 1, false, false)}
          />
        </ModalSection>
      </RowWrapper>

      {!renderInPresentation && (
        <>
          <ModalSection>
            <SectionTitle style={{ paddingBottom: SPACING_16 }}>
              {t(`${i18nPrefix}:whatAreScienceBasedTargets:title`)}
            </SectionTitle>
            <Paragraph style={{ whiteSpace: 'pre-line' }}>
              <Trans
                i18nKey={t(`${i18nPrefix}:whatAreScienceBasedTargets:text`)}
                components={{
                  a: (
                    <a href="https://sciencebasedtargets.org" target="_blank" />
                  ),
                }}
              />
            </Paragraph>
            <SustainabilityAdvisor />
          </ModalSection>
          <ModalSection>
            <SectionTitle style={{ paddingBottom: SPACING_16 }}>
              {t(`${i18nPrefix}:calculation:scienceBasedTargets:title`)}
            </SectionTitle>
            <Paragraph style={{ whiteSpace: 'pre-line' }}>
              {t(`${i18nPrefix}:calculation:scienceBasedTargets:text`)}
            </Paragraph>
          </ModalSection>
        </>
      )}
    </StyledWrapper>
  );
};
