import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { ParagraphSmall } from '../../../texts';
import { ReactComponent as IconThumbsUp } from '../../../../assets/icons/annual-review/thumb-up.svg';
import { ReactComponent as IconThumbsDown } from '../../../../assets/icons/annual-review/thumb-down.svg';
import { SPACING_24 } from '../../../../constants/spacing';
import { accent } from '../../../../constants/colors';

const i18nPrefix =
  'wealthPlan:investmentProfile:sustainabilityPreferences:engagementStrategy:prosAndCons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING_24} 0;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
`;

const StyledIconThumbsDown = styled(IconThumbsDown)`
  > path {
    fill: ${accent.raspberry1};
  }
`;

export const ProsAndCons = ({ engagementStrategy = 'transition', ...rest }) => {
  return (
    <Container {...rest}>
      <Item>
        <IconThumbsUp
          width={24}
          height={24}
          style={{ marginRight: SPACING_24 }}
        />
        <ParagraphSmall style={{ flex: 1, fontWeight: 500 }}>
          {t(`${i18nPrefix}:${engagementStrategy}Positive`)}
        </ParagraphSmall>
      </Item>
      <Item>
        <StyledIconThumbsDown
          width={24}
          height={24}
          style={{ marginRight: SPACING_24 }}
          fill="red"
        />
        <ParagraphSmall style={{ flex: 1, fontWeight: 500 }}>
          {t(`${i18nPrefix}:${engagementStrategy}Negative`)}
        </ParagraphSmall>
      </Item>
    </Container>
  );
};
