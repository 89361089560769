import { t } from '../localization';

export function generateInitials(fullName) {
  if (!fullName) {
    return '';
  }
  // only letters allowed
  const regex = /^[a-zA-ZäöåøÆÄÖÅØæ]+$/;
  const names = fullName.split(' ');
  let initials = '';

  for (let i = 0; names.length > i; i += 1) {
    if (names[i].match(regex)) {
      initials = names[0].substring(0, 1).toUpperCase();
      break;
    }
  }
  for (let i = names.length - 1; i > 0; i -= 1) {
    if (names[i].match(regex)) {
      initials += names[i].substring(0, 1).toUpperCase();
      break;
    }
  }
  return initials;
}

/**
 * @desc Generates the full name from user object and returns it as a string
 *
 * @param {Object} user - User object (from selector)
 * @param {boolean} includeMiddlename - Include "middle name" or don't
 * @param {number} middleNameMaxLength - Max number of characters for middle name to be returned
 *
 * @return string
 */
export function generateFullName(
  user,
  includeMiddleName = true,
  maxMiddleNameLength = 9
) {
  let name = '';
  if (user) {
    let { firstName, middleName, lastName } = user;

    // We don't want to include the "middle name" in case it's too long
    if (middleName && middleName.length > maxMiddleNameLength) {
      middleName = false;
    }

    name = [firstName, middleName, lastName].filter(Boolean).join(' ');
  }

  return name;
}
