import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

import { actions } from './actions';
export { actions };

export const defaultState = {
  messages: [],
};

export const reducer = handleActions(
  {
    [actions.notify]: (state, { payload }) => {
      const messages = [...state.messages];
      messages.push({
        id: uuidv4(),
        duration: 5000,
        ...payload
      });

      return {
        ...state,
        messages: messages,
      };
    },
    [actions.dismiss]: (state, { payload }) => {
      let messages = [...state.messages];
      // Remove message from messages list
      messages = messages.filter((message) => message.id != payload);

      return {
        ...state,
        messages: messages,
      };
    },
  },
  defaultState
);
