import { createActions } from 'redux-actions';

export const actions = createActions({
  NAVIGATION: {
    TOGGLE_MAIN_MENU: () => {},
    TOGGLE_POPOVER: (type) => type,
    SET_MAIN_MENU_OPEN: (open) => open,
    SET_PODCAST_POPOVER_OPEN: (open) => open,
  },
}).navigation;
