import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import {
  combineCashflowReportsData,
  formatCashflowAxisPointsData,
  selectors,
  t,
  useErrorState,
  useResource,
} from '@formue-app/core';

import {
  SPACING_48,
  SPACING_24,
  SPACING_64,
  SPACING_88,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { H6Size } from '../../../constants/text';
import { mobileCondition } from '../../../constants/media';

import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { ReportTitle } from '../reportComponent/ReportTitle';
import { MultipleSwitch } from '../../formElements';
import { BarChart } from '../../graphs/BarChart';
import { ReportErrorMessage } from '../reportComponent/ReportErrorMessage';

const {
  entities: {
    illiquidCashflow: {
      createIlliquidCashflowByAssetClassSelector,
      allIlliquidCashflowStrategiesSelector,
    },
  },
} = selectors;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-top: ${SPACING_48};
`;

const GraphWrapper = styled.div`
  width: 100%;
  height: 600px;
  margin-top: ${SPACING_64};
  padding: ${SPACING_24} ${SPACING_88};

  @media ${mobileCondition} {
    padding: 0 0 ${SPACING_88} 0;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const CashflowSection = (props) => {
  const { backgroundColor, isVisible, title, ...rest } = props;

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const allStrategies = useSelector(allIlliquidCashflowStrategiesSelector);
  const privateEquity = useSelector(
    createIlliquidCashflowByAssetClassSelector('p')
  );
  const property = useSelector(createIlliquidCashflowByAssetClassSelector('i'));

  const loading = useResource(['ILLIQUID_CASHFLOWS/INDEX']);
  const error = useErrorState(['ILLIQUID_CASHFLOWS/INDEX']);
  const [activeAssetClass, setActiveAssetClass] = useState('p');
  const [includeExternal] = useState(true);

  const privateEquityData = combineCashflowReportsData(
    privateEquity,
    includeExternal
  );
  const propertyData = combineCashflowReportsData(property, includeExternal);

  const axisPoints = formatCashflowAxisPointsData(allStrategies);

  if (error) {
    return <ReportErrorMessage {...rest} />;
  }

  return (
    <>
      <ReportTitle
        {...rest}
        title={title}
        values={{
          assetClass:
            activeAssetClass === 'p'
              ? t('presentation:annualReview:cashFlow:privateEquity')
              : t('presentation:annualReview:cashFlow:realEstate'),
        }}
      />

      <HeaderWrapper>
        <StyledMultipleSwitch
          backgroundColor={accent.velvet270}
          activeBackgroundColor={accent.velvet1}
          color={accent.velvet1}
          onChange={(value) => {
            setActiveAssetClass(value);
          }}
          options={[
            {
              label: t('presentation:annualReview:cashFlow:privateEquity'),
              value: 'p',
            },
            {
              label: t('presentation:annualReview:cashFlow:realEstate'),
              value: 'i',
            },
          ]}
        />
      </HeaderWrapper>

      <GraphWrapper>
        <ChartWrapper>
          {loading ? (
            <CenteredActivityIndicator />
          ) : (
            <BarChart
              data={activeAssetClass === 'p' ? privateEquityData : propertyData}
              axisPoints={axisPoints[activeAssetClass]}
              valueKey="amount"
              labelKey="year"
              labelColor={accent.velvet170}
              labelFontSize={H6Size}
              yAxis={true}
              zeroAxis={true}
              color={accent.velvet1}
              showValue={false}
              yAxisPadding={mobileSize ? 50 : 80}
              axisPointsOffsetLeft={mobileSize ? 50 : 80}
              barSpacing={mobileSize ? 8 : 36}
              axisColor={accent.velvet170}
              zeroAxisColor={accent.velvet170}
              zeroAxisWidth={1}
              labelBackgroundColor={accent.velvet250}
            />
          )}
        </ChartWrapper>
      </GraphWrapper>
    </>
  );
};
