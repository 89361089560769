import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { ChartLegend } from '../slideComponents/portfolio/ChartLegend';
import { PortfolioHoldingsChart } from '../../portfolio/charts/PortfolioHoldingsChart';
import { mobileCondition, tabletCondition } from '../../../constants/media';
import { ReturnContributionPeriodFilter } from '../slideComponents/portfolio/ReturnContributionPeriodFilter';
import { useResource, t } from '@formue-app/core';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { accent } from '../../../constants/colors';

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5%;
  padding-bottom: 0;
  width: 100%;

  @media ${mobileCondition} {
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;
  }
  @media ${tabletCondition} {
    padding: 0 40px;
  }
`;

const StyledReturnContributionPeriodFilter = styled(
  ReturnContributionPeriodFilter
)`
  margin-left: auto;
  @media ${mobileCondition} {
    margin-left: 0;
    margin-top: 20px;
  }
`;

export const ReturnContributionSection = (props) => {
  const { backgroundColor, isVisible, ...rest } = props;
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  return (
    <>
      <ReportTitle {...rest} style={{ marginBottom: 60 }} />

      <LegendWrapper>
        <ChartLegend
          textColor="#83769b"
          style={{
            marginTop: 0,
            alignSelf: mobileSize ? 'center' : 'flex-start',
          }}
          isSelectable={false}
          items={[
            {
              label: t('assetClasses:stability'),
              color: accent.velvet1,
              key: 'S',
            },
            {
              label: t('assetClasses:marketGrowth'),
              color: accent.velvet2,
              key: 'MV',
            },
            {
              label: t('assetClasses:alternativeGrowth'),
              color: accent.velvet3,
              key: 'AV',
            },
          ]}
        />

        <StyledReturnContributionPeriodFilter excludedPeriods={['THISMONTH']} />
      </LegendWrapper>

      <div
        style={{
          height: '40vh',
          width: '100%',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <CenteredActivityIndicator loadingColor={accent.velvet1} />
        ) : (
          <PortfolioHoldingsChart
            fontSize={4}
            height={mobileSize ? 550 : 160}
            showTooltip={false}
            style={{ height: '100%', minHeight: 550 }}
          />
        )}
      </div>
    </>
  );
};
