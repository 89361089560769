import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';

import { backgroundMain } from '../../constants/colors';

import { GridElement } from '../../components/layout/GridElement';
import { GeographyHeader } from '../../components/portfolio/lenses/geography/GeographyHeader';
import { CountryList } from '../../components/portfolio/lenses/geography/CountryList';
import { LensesFooter } from '../../components/portfolio/lenses/LensesFooter';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { ParagraphSmall, Paragraph } from '../../components/texts';
import { SPACING_48 } from '../../constants/spacing';
import { Modal } from '../../components/common/Modal';
import { CoverageReadMore } from '../../components/portfolio/lenses/CoverageReadMore';

const {
  entities: {
    securities: {
      securitiesByContinentSelector,
      securitiesByCountrySelector,
      securitiesMetadataSelector,
    },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

export const GeographyPage = () => {
  const loading = useResource(['SECURITIES/INDEX']);
  const byContinents = useSelector(securitiesByContinentSelector);
  const byCountry = useSelector(securitiesByCountrySelector);
  const metadata = useSelector(securitiesMetadataSelector);
  const { coverageGeo = 0 } = metadata;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedContinent, setSelectedContinent] = useState('Global');
  const sortedContinents = Object.values(byContinents).sort(
    (a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio
  );
  const topThreeCountries = Object.values(byCountry)
    .filter((country) => country.continent === selectedContinent)
    .sort((a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio);

  if (!byContinents) return null;

  if (loading) {
    return (
      <GridElement columns={12}>
        <StyledCenteredActivityIndicator
          columns={12}
          size={30}
          background={backgroundMain}
        />
      </GridElement>
    );
  }

  return (
    <>
      <GeographyHeader
        zoom={selectedContinent}
        continents={sortedContinents.filter(
          (item, index) => item.partOfKnownPortfolio > 0.01
        )}
        topThreeCountries={topThreeCountries.filter((item, index) => index < 3)}
        selectedContinent={selectedContinent}
        onClick={(continent) => setSelectedContinent(continent)}
      />
      <CountryList selectedContinent={selectedContinent} />
      <LensesFooter
        coverage={coverageGeo}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <Paragraph style={{ fontWeight: 500 }}>
          {t('geography:classificationMethod')}
        </Paragraph>
        <ParagraphSmall>{t('geography:countryAllocationText')}</ParagraphSmall>
      </LensesFooter>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel={t(`esg:coverageReadMore:title`)}
        padding={SPACING_48}
      >
        <CoverageReadMore />
      </Modal>
    </>
  );
};
