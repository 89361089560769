import { createSelector } from 'reselect';
import { sortTransactionItems } from '../../../services/utilities/sorting';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  transactionAllIdsSelector,
  transactionByIdSelector,
  allTransactionsSelector,
] = resourceSelectors('transactions');

export const transactionsSelector = createSelector(
  allTransactionsSelector,
  (items) => {
    return items.sort(sortTransactionItems);
  }
);

export const lastYearTransactions = createSelector(
  allTransactionsSelector,
  (items) => {
    return items.filter(
      (item) =>
        new Date(item.date).getFullYear() === new Date().getFullYear() - 1
    );
  }
);
