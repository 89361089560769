import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

import { percentage } from '@formue-app/core';

import { Paragraph } from '../texts';

import { BORDER_RADIUS_LARGE } from '../../constants/spacing';
import { accent } from '../../constants/colors';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 10px;
`;

const AnimatedLabel = styled(animated.div)`
  display: flex;
  opacity: 0;
`;

const AnimatedBar = styled(animated.div)``;

export const PercentageBar = (props) => {
  const {
    value,
    color = accent.ocean1,
    backgroundColor = accent.ocean2,
    width = 98,
    height = 8,
    style,
  } = props;

  const barWidth = value * width;

  const translateBarX = useSpring({
    from: { translateX: -barWidth, opacity: 0 },
    to: { translateX: 0, opacity: 1 },
    reset: true,
  });

  const translateLabelX = useSpring({
    from: { translateX: 0, opacity: 0 },
    to: { translateX: 10, opacity: 1 },
    reset: true,
  });

  return (
    <StyledContainer style={style}>
      <div
        style={{
          width,
          borderRadius: BORDER_RADIUS_LARGE,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div
          style={{
            backgroundColor: backgroundColor,
            position: 'absolute',
            width: '100%',
            height: height,
          }}
        />
        <AnimatedBar
          style={{
            backgroundColor: color,
            width: barWidth,
            height: height,
            position: 'relative',
            zIndex: 1,
            ...translateBarX,
          }}
        />
      </div>
      <AnimatedLabel
        style={{
          marginLeft: 0,
          ...translateLabelX,
        }}
      >
        <Paragraph style={{ marginRight: 5, fontSize: 11, lineHeight: 1 }}>
          {percentage(value * 100, 2)}
        </Paragraph>
      </AnimatedLabel>
    </StyledContainer>
  );
};
