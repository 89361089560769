import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategyCostsAllIds,
  investmentStrategyCostsById,
  investmentStrategyCostsSelector,
  investmentStrategyCostsMetadataSelector,
] = resourceSelectors('investmentStrategyCosts');

export const investmentStrategyCostsByStrategyIdSelector = (strategyId) =>
  createSelector(investmentStrategyCostsSelector, (allCosts) =>
    allCosts.filter((product) => product.investmentStrategy.id == strategyId)
  );

export const investmentStrategyCostMetadataByIdSelector = (id) =>
  createSelector(investmentStrategyCostsMetadataSelector, (meta) => meta[id]);
