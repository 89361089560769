import { apiFetch } from './utils/api';
// const mock = require('./mocks/v1/operational-messages.json');

export const operationalMessages = {
  get: async (args) => {
    const options = {
      method: 'GET',
      baseUrl: '/',
    };

    if (args && args.country) {
      options.country = args.country;
    }

    const url = '/operational-messages';

    const { body } = await apiFetch(url, {
      ...options,
    });

    if (body) {
      return body;
    } else {
      return { data: [] };
    }
  },
};
