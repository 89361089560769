import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { forms } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the network resource
export const [actions, reducer, resourceSagas] = resourceReducer('forms');

// Create the sagas for this entity
function* readForms() {
  yield takeEvery(actions.indexRequestBegin, function*() {
    try {
      const result = yield call(forms.get);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error })
    }
  });
}

function* updateForm() {
  yield takeEvery(actions.updateRequestBegin, function*({ payload }) {
    const { args, data } = payload;
    try {
      const { body } = yield call(forms.update, args, data);
      const normalized = normalize(body);
      yield put(actions.updateRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.updateRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readForms, updateForm, ...resourceSagas];
