import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useQueryState } from 'react-router-use-location-state';

import { t } from '@formue-app/core';

import { textLink } from '../../constants/colors';
import { GridElement } from '../../components/layout';
import { SPACING_24, SPACING_8, SPACING_16 } from '../../constants/spacing';
import { Toggle } from '../../components/formElements';
import { notDesktopCondition } from '../../constants/media';

import { H4, Paragraph } from '../../components/texts';
import {
  factSheetSelector,
  factSheetFinalizedInvestmentsSelector,
  keyInfoSelector,
} from '../../store/ui/factSheet/selectors';
import { CollapsibleListItem } from '../../components/common/CollapsibleListItem';
import { List, ListItem } from '../../components/texts/List';

const StyledLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledH4 = styled(H4)`
  color: ${textLink};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_16};
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: 15px;
`;

const ContentWrapper = styled.div`
  padding-inline: ${SPACING_24};
`;

export const FactSheetsPage = () => {
  const factSheetFinalizedInvestments = useSelector(
    factSheetFinalizedInvestmentsSelector
  );
  const factSheetsPerAssetClass = useSelector(factSheetSelector);
  const keyInfo = useSelector(keyInfoSelector);
  const isTabletOrMobile = useMediaQuery({
    query: notDesktopCondition,
  });
  const renderDocument = (document) => {
    return (
      <ListItem key={`fsdoc-${document.id}`}>
        <StyledLink href={document.url} target="_blank">
          <StyledH4>{document.name}</StyledH4>
        </StyledLink>
      </ListItem>
    );
  };

  const [openAllFFIs, setOpenAllFFIs] = useQueryState('openAllFFIs', false);
  const [openAllFs, setOpenAllFs] = useQueryState('openAllFs', false);
  const [openAllKs, setOpenAllKs] = useQueryState('openAllKs', false);

  if (!factSheetsPerAssetClass.length) {
    return null;
  }

  let columnCount = isTabletOrMobile ? 12 : 6;

  return (
    <>
      <GridElement columns={12}>
        <TitleWrapper>
          <H4>{t(`factSheets:title`)}</H4>
          <Toggle
            label={t(`globals:seeAll`)}
            value={openAllFs}
            onChange={() => setOpenAllFs(!openAllFs)}
          />
        </TitleWrapper>
        {factSheetsPerAssetClass.map((fs) => (
          <CollapsibleListItem
            details={
              <ContentWrapper>
                <List style={{ margin: 0 }}>
                  {fs.documents
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(renderDocument)}
                </List>
              </ContentWrapper>
            }
            startExpanded={openAllFs}
            style={{ marginTop: SPACING_8, padding: SPACING_16 }}
            key={`fsac-${fs.id}`}
          >
            <H4 style={{ marginRight: 'auto' }}>{fs.assetClass}</H4>
          </CollapsibleListItem>
        ))}
      </GridElement>
      {factSheetFinalizedInvestments.length ? (
        <GridElement columns={12}>
          <TitleWrapper>
            <H4>{t(`factSheets:activeInvestments`)}</H4>
            <Toggle
              label={t(`globals:seeAll`)}
              value={openAllFFIs}
              onChange={() => setOpenAllFFIs(!openAllFFIs)}
            />
          </TitleWrapper>
          {factSheetFinalizedInvestments.map((fs) => (
            <CollapsibleListItem
              details={
                <ContentWrapper>
                  <List style={{ margin: 0 }}>
                    {fs.documents
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(renderDocument)}
                  </List>
                </ContentWrapper>
              }
              startExpanded={openAllFFIs}
              style={{ marginTop: SPACING_8, padding: SPACING_16 }}
              key={`fsac-${fs.id}`}
            >
              <H4 style={{ marginRight: 'auto' }}>{fs.assetClass}</H4>
            </CollapsibleListItem>
          ))}
        </GridElement>
      ) : null}
      {keyInfo.length ? (
        <GridElement columns={12}>
          <TitleWrapper>
            <H4>{t(`factSheets:keyInfo`)}</H4>
            <Toggle
              label={t(`globals:seeAll`)}
              value={openAllKs}
              onChange={() => setOpenAllKs(!openAllKs)}
            />
          </TitleWrapper>
          {keyInfo.map((ki) => (
            <CollapsibleListItem
              details={
                <ContentWrapper>
                  <List style={{ margin: 0 }}>
                    {ki.documents
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(renderDocument)}
                  </List>
                </ContentWrapper>
              }
              startExpanded={openAllKs}
              style={{ marginTop: SPACING_8, padding: SPACING_16 }}
              key={`key-info-${ki.id}`}
            >
              <H4 style={{ marginRight: 'auto' }}>{ki.assetClass}</H4>
            </CollapsibleListItem>
          ))}
        </GridElement>
      ) : null}
      <GridElement columns={columnCount}>
        <StyledParagraph>{t(`factSheets:outroText`)}</StyledParagraph>
      </GridElement>
    </>
  );
};
