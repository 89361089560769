import React from 'react';
import styled from 'styled-components';

import { accent, textLightest } from '../../constants/colors';
import { SPACING_24 } from '../../constants/spacing';
import { SpriteIconFunctional } from './SpriteIconFunctional';

const StyledContainer = styled.div`
  display: flex;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${SPACING_24};
  border: 1px solid;
  align-items: center;
  justify-content: center;
`;

export const CheckIcon = ({
  color = accent.ocean4,
  colorChecked = accent.ocean4,
  checked,
  style,
  size = 24,
}) => {
  if (checked) {
    return (
      <StyledContainer
        style={{ backgroundColor: colorChecked, ...style }}
        checked={checked}
        size={size}
      >
        <SpriteIconFunctional id="Check" size={12} color={textLightest} />
      </StyledContainer>
    );
  }
  return (
    <StyledContainer
      style={{ borderColor: color, ...style }}
      checked={checked}
      size={size}
    />
  );
};
