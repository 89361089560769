import React from 'react';
import styled, { css } from 'styled-components';

import {
  SPACING_16,
  SPACING_12,
} from '../../../../constants/spacing';
import { H3, Paragraph } from '../../../texts';
import { SpriteIcon } from '../../../common/SpriteIcon';

const Container = styled.div`
  padding: ${SPACING_16} 0;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_12};
`;

const Item = styled.div`
  display: flex;
  gap: ${SPACING_16};
  align-items: center;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      > p {
        text-decoration: line-through;
      }
    `}
`;

export const ImpactList = (props) => {
  const {
    title,
    items,
    ...rest
  } = props;

  return (
    <Container {...rest}>
      <H3 style={{ paddingBottom: SPACING_16 }}>{title}</H3>
      <Items>
        {items.map((item, index) => (
          <Item key={`impact-list-item-${index}`} disabled={item.disabled}>
            <SpriteIcon id={item.iconId} size={20} />
            <Paragraph>{item.title}</Paragraph>
          </Item>
        ))}
      </Items>
    </Container >
  );
};
