import React from 'react';
import styled from 'styled-components';
import { times } from 'lodash';

import { accent } from '../../../constants/colors';
import { H6 } from '../../texts';
import { BORDER_RADIUS_SMALL, SPACING_16 } from '../../../constants/spacing';

const StyledContainer = styled.div``;

const StyledBarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledBar = styled.div`
  width: 13%;
  height: 22px;
  border-radius: ${BORDER_RADIUS_SMALL};
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${SPACING_16};
`;

export const KnowledgeAndExperienceBar = ({
  title,
  total,
  selected,
  ...props
}) => {
  return (
    <StyledContainer {...props}>
      <StyledHeader>
        <H6>{title}</H6>
        <H6>{`${selected}/${total}`}</H6>
      </StyledHeader>
      <StyledBarContainer>
        {times(total, (index) => {
          const isSelected = index < selected;
          return (
            <StyledBar
              key={index}
              style={{
                backgroundColor: isSelected ? accent.ocean4 : accent.sand1,
              }}
            />
          );
        })}
      </StyledBarContainer>
    </StyledContainer>
  );
};
