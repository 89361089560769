import React from 'react';
import styled from 'styled-components';

import { BaseSection } from '../../common/BaseSection';

import { SPACING_24, SPACING_16 } from '../../../constants/spacing';

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_16};
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  flex: 1;
  height: 100%;
`;

export const LensesListItem = ({ header, children, ...rest }) => {
  return (
    <StyledBaseSection {...rest}>
      <StyledContentContainer>{children}</StyledContentContainer>
    </StyledBaseSection>
  );
};
