import React from 'react';
import { useSelector } from 'react-redux';

import { selectors, useResource } from '@formue-app/core';

import { BaseSection } from '../../components/common/BaseSection';
import { GridElement } from '../../components/layout/GridElement';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';

import { ExpertDocumentList } from '../../components/wealthPlan/expertInputs/ExpertDocumentList';
import { NoDataMessage } from '../../components/wealthPlan/NoDataMessage';

import { SPACING_88 } from '../../constants/spacing';

const {
  entities: {
    expertInputs: { expertAdviceSelector, expertAdviceHasContent },
  },
} = selectors;

export const ExpertInputPage = () => {
  const loading = useResource(['EXPERT_INPUTS/INDEX']);

  const expertInputs = useSelector(expertAdviceSelector);
  const hasContent = useSelector(expertAdviceHasContent);

  return (
    <>
      <BaseSection columns={12} style={{ padding: 0 }}></BaseSection>
      {loading ? (
        <GridElement columns={12}>
          <CenteredActivityIndicator
            size={30}
            style={{ marginTop: SPACING_88 }}
          />
        </GridElement>
      ) : hasContent ? (
        <ExpertDocumentList expertInputs={expertInputs} />
      ) : (
        <NoDataMessage columns={12} />
      )}
    </>
  );
};
