import React from 'react';
import styled from 'styled-components';

import { t, percentage } from '@formue-app/core';

import {
  BORDER_RADIUS_LARGE,
  SECTION_SPACING,
  SPACING_2,
  SPACING_16,
  SPACING_24,
} from '../../../constants/spacing';

import { H2, Paragraph } from '../../texts';

import { numbersFontStack } from '../../../fonts';
import { SpriteIcon } from '../../common/SpriteIcon';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: ${SECTION_SPACING};
`;

const ListItem = styled.div`
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
  flex-grow: 1;
`;

const StyledItem = styled.div`
  width: 50%;
`;

const ItemInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled(Paragraph)`
  text-align: center;
  margin-top: ${SPACING_16};
  margin-bottom: ${SPACING_2};
`;

const StyledNumber = styled(H2)`
  font-family: ${numbersFontStack};
  font-weight: 500;
`;

export const Item = (props) => {
  const { value, icon, description } = props;

  return (
    <ListItem>
      <ItemInner>
        {icon}
        <Description>{description}</Description>
        <StyledNumber>{percentage(value * 100, 1)}</StyledNumber>
      </ItemInner>
    </ListItem>
  );
};

export const ExpectedReturn = (props) => {
  const { annualHistoricPerformance, annualHistoricStandardDeviation } = props;

  return (
    <Content>
      <StyledItem>
        <Item
          value={annualHistoricPerformance}
          icon={<SpriteIcon id="Investment" size={40} />}
          description={t(
            'wealthPlan:investmentSimulator:historicPerformance:historicReturn'
          )}
        />
      </StyledItem>
      <StyledItem>
        <Item
          value={annualHistoricStandardDeviation}
          icon={<SpriteIcon id="Volatility" size={40} />}
          description={t(
            'wealthPlan:investmentSimulator:historicPerformance:historicStandardDeviation'
          )}
        />
      </StyledItem>
    </Content>
  );
};
