import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { SustainabilityBaseItem } from './SustainabilityBaseItem';
import { H6, ParagraphLarge } from '../../../texts';
import { accent } from '../../../../constants/colors';
import { titleFontStack } from '../../../../fonts';
import {
  SPACING_24,
  SPACING_8,
  SPACING_2,
} from '../../../../constants/spacing';
import { SpriteIcon } from '../../../common/SpriteIcon';

const Row = styled.div`
  display: flex;
  gap: ${SPACING_24};
`;

const Label = styled(H6)`
  color: ${accent.ocean470};
  padding: ${SPACING_2} 0 ${SPACING_8} 0;
`;

const Title = styled(ParagraphLarge)`
  color: ${accent.ocean490};
  font-family: ${titleFontStack};
`;

export const FormueHouseViewMessage = (props) => {
  const i18nPrefix =
    'wealthPlan:investmentProfile:sustainabilityPreferences:share';
  return (
    <SustainabilityBaseItem {...props}>
      <Row>
        <SpriteIcon
          id="Decision"
          size={32}
          color={accent.forest3}
          accentColor={accent.forest190}
        />
        <div>
          <Label>{t(`${i18nPrefix}:houseViewLabel`)}</Label>
          <Title>{t(`${i18nPrefix}:houseView`)}</Title>
        </div>
      </Row>
    </SustainabilityBaseItem>
  );
};
