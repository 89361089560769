import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as PyramidIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-small-icon.svg';

import {
  SPACING_64,
  SPACING_32,
  SPACING_24,
  SPACING_8,
} from '../../../constants/spacing';
import { mobileCondition, tabletCondition } from '../../../constants/media';
import { BaseSection } from '../../common/BaseSection';
import { Modal } from '../../common/Modal';
import { H2, Paragraph } from '../../texts';
import { PortfolioStructureContent } from './PortfolioStructureContent';
import { Button } from '../../buttons';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  align-items: center;
  padding: 52px 78px;
  margin-top: ${SPACING_8};
  gap: ${SPACING_64};

  @media ${tabletCondition} {
    padding: ${SPACING_32};
    gap: ${SPACING_32};
  }

  @media ${mobileCondition} {
    padding: 21px 18px;
    align-items: flex-start;
    gap: ${SPACING_24};
    flex-wrap: wrap;
  }
`;

const StyledTextWrapper = styled.div`
  flex-basis: calc(100% - 67px - 24px);

  p {
    margin-top: ${SPACING_8};
  }
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;

export const PortfolioStructure = (props) => {
  const { columns } = props;
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <StyledBaseSection columns={columns}>
      <PyramidIcon width={67} height={67} />

      <StyledTextWrapper>
        <H2 as="h3">{t('portfolio:structure:title')}</H2>
        <Paragraph>{t('portfolio:structure:text')}</Paragraph>
      </StyledTextWrapper>

      <StyledButton onClick={() => setModalIsOpen(true)}>
        {t('globals:readMore')}
      </StyledButton>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={t('portfolio:structure:title')}
      >
        <PortfolioStructureContent />
      </Modal>
    </StyledBaseSection>
  );
};
