import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components';

import { selectors, t } from '@formue-app/core';

import {
  accent,
  textLightest,
  whiteTranslucent,
} from '../../../constants/colors';
import {
  easeInOut,
  longAnimationTime,
  shortAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
  SPACING_8,
} from '../../../constants/spacing';

import { H1, H2, H6 } from '../../texts';
import { MultipleSwitch } from '../../formElements';
import { PortfolioConstructionList } from '../../portfolio/lenses/construction/PortfolioConstructionList';

import { ReactComponent as HexagonLeft } from '../../../assets/portfolio/hexagon-left.svg';
import { ReactComponent as HexagonRight } from '../../../assets/portfolio/hexagon-right.svg';

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 500px;
  background: ${accent.velvet4};
  transition: width ${easeInOut} ${longAnimationTime};

  ${(props) =>
    props.activePanel === 0 &&
    css`
      width: 100%;
    `}
`;

const HexagonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 125px;
  translate: 0 -50%;

  span {
    color: ${textLightest};
    position: absolute;
    top: 48%;
    left: 50%;
    translate: -50% -50%;
  }
`;

const Triangle = styled.div`
  position: absolute;
  width: 500px;
  height: 100%;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background: ${accent.velvet270};
  right: -1px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
  position: relative;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
  position: absolute;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime};

  h1 {
    position: relative;
    opacity: 0;
    left: 20px;
    transition: all ${easeInOut} ${shortAnimationTime};
    transition-delay: ${slideAnimationDelay}s;
  }

  ${(props) =>
    props.isActiveSlide &&
    css`
      h1 {
        opacity: 1;
        left: 0;
      }
    `}
`;

const LeftPanel = styled(ContentWrapper)`
  width: 70%;
  top: 95px;

  ${(props) =>
    props.activePanel === 0 &&
    css`
      opacity: 1;
    `}
`;

const RightPanel = styled(ContentWrapper)`
  width: 65%;
  left: 35%;
  top: 95px;

  ${(props) =>
    props.activePanel === 1 &&
    css`
      opacity: 1;
    `}
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-left: auto;
`;

const StyledPortfolioConstructionList = styled(PortfolioConstructionList)`
  padding: 0;
  margin-top: ${SPACING_48};

  & > p {
    font-size: 18px;
  }

  .list-item {
    p {
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
    }
  }
`;

const {
  entities: {
    securities: { securitiesMetadataSelector, allFunds, allSecurities },
  },
} = selectors;

export const PortfolioConstructionBreakdownSlide = (props) => {
  const { isActiveSlide } = props;

  const securitiesMetadata = useSelector(securitiesMetadataSelector);
  const { distinctCompanies = 0, distinctFunds = 0 } = securitiesMetadata;

  const funds = useSelector(allFunds);
  const securities = useSelector(allSecurities);

  const [activePanel, setActivePanel] = useState(0);

  return (
    <>
      <Mask activePanel={activePanel}>
        <HexagonWrapper style={{ marginTop: -20, marginRight: 50 }}>
          <H1 as="span">{distinctFunds}</H1>
          <HexagonLeft />
        </HexagonWrapper>
        <Triangle activePanel={activePanel}>
          <HexagonWrapper style={{ marginTop: 29, marginRight: -44 }}>
            <H2 as="span">{distinctCompanies}</H2>
            <HexagonRight />
          </HexagonWrapper>
        </Triangle>
      </Mask>
      <Wrapper>
        <StyledMultipleSwitch
          activeBackgroundColor={
            activePanel === 0 ? accent.velvet1 : accent.velvet3
          }
          color={activePanel === 0 ? accent.velvet1 : whiteTranslucent(0.5)}
          backgroundColor={
            activePanel === 0 ? accent.velvet270 : accent.velvet1
          }
          activeColor={textLightest}
          // activeBackgroundColor={accent.ocean1}
          // color={accent.ocean1}
          onChange={(value) => {
            setActivePanel(value);
          }}
          options={[
            {
              label: t('presentation:annualReview:portfolioConstruction:fund'),
              value: 0,
            },
            {
              label: t(
                'presentation:annualReview:portfolioConstruction:companies'
              ),
              value: 1,
            },
          ]}
        />
        <LeftPanel activePanel={activePanel} isActiveSlide={isActiveSlide}>
          <H6
            style={{
              color: accent.velvet2,
              marginBottom: SPACING_8,
            }}
          >
            {t(
              'presentation:annualReview:portfolioConstructionBreakdown:subTitle'
            )}
          </H6>
          <H1 style={{ color: accent.velvet2 }}>
            <Trans
              i18nKey="portfolioConstruction:fundsIntroduction"
              values={{ fundsCount: distinctFunds }}
              components={{
                strong: (
                  <H1
                    as="span"
                    style={{ color: accent.velvet3, display: 'inline' }}
                  />
                ),
              }}
            />
          </H1>
          {!!funds.length && (
            <StyledPortfolioConstructionList
              data={funds.slice(0, 3)}
              title={t('portfolioConstruction:topFunds', {
                fundsCount: 3,
              })}
              titleColor={accent.velvet2}
              textColor={textLightest}
              numberBackgroundColor={accent.velvet3}
              numberColor={textLightest}
              active={isActiveSlide}
            />
          )}
        </LeftPanel>
        <RightPanel activePanel={activePanel} isActiveSlide={isActiveSlide}>
          <H6
            style={{
              color: accent.velvet490,
              marginBottom: SPACING_8,
            }}
          >
            {t(
              'presentation:annualReview:portfolioConstructionBreakdown:subTitle'
            )}
          </H6>
          <H1
            style={{
              color: accent.velvet490,
            }}
          >
            <Trans
              i18nKey="portfolioConstruction:companiesIntroduction"
              values={{ companiesCount: distinctCompanies }}
              components={{
                strong: (
                  <H1
                    as="span"
                    style={{ color: '#810CBB', display: 'inline' }}
                  />
                ),
              }}
            />
          </H1>
          {!!securities.length && (
            <StyledPortfolioConstructionList
              data={securities.slice(0, 3)}
              title={t('portfolioConstruction:topCompanies', {
                companiesCount: 3,
              })}
              titleColor={accent.velvet490}
              textColor={accent.velvet490}
              numberBackgroundColor={accent.velvet3}
              numberColor={textLightest}
              active={isActiveSlide}
            />
          )}
        </RightPanel>
      </Wrapper>
    </>
  );
};
