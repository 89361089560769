import no1summer from '../../assets/images/annual-review/presentation/summer/no/1.jpg';
import no2summer from '../../assets/images/annual-review/presentation/summer/no/2.jpg';
import no3summer from '../../assets/images/annual-review/presentation/summer/no/3.jpg';
import no4summer from '../../assets/images/annual-review/presentation/summer/no/4.jpg';
import no5summer from '../../assets/images/annual-review/presentation/summer/no/5.jpg';
import no6summer from '../../assets/images/annual-review/presentation/summer/no/6.jpg';
import no7summer from '../../assets/images/annual-review/presentation/summer/no/7.jpg';

import no1winter from '../../assets/images/annual-review/presentation/winter/no/1.jpg';
import no2winter from '../../assets/images/annual-review/presentation/winter/no/2.jpg';
import no3winter from '../../assets/images/annual-review/presentation/winter/no/3.jpg';
import no4winter from '../../assets/images/annual-review/presentation/winter/no/4.jpg';
import no5winter from '../../assets/images/annual-review/presentation/winter/no/5.jpg';
import no6winter from '../../assets/images/annual-review/presentation/winter/no/6.jpg';
import no7winter from '../../assets/images/annual-review/presentation/winter/no/7.jpg';

import dk1summer from '../../assets/images/annual-review/presentation/summer/dk/1.jpg';
import dk2summer from '../../assets/images/annual-review/presentation/summer/dk/2.jpg';
import dk3summer from '../../assets/images/annual-review/presentation/summer/dk/3.jpg';
import dk4summer from '../../assets/images/annual-review/presentation/summer/dk/4.jpg';
import dk5summer from '../../assets/images/annual-review/presentation/summer/dk/5.jpg';
import dk6summer from '../../assets/images/annual-review/presentation/summer/dk/6.jpg';
import dk7summer from '../../assets/images/annual-review/presentation/summer/dk/7.jpg';

import dk1winter from '../../assets/images/annual-review/presentation/winter/dk/1.jpg';
import dk2winter from '../../assets/images/annual-review/presentation/winter/dk/2.jpg';
import dk3winter from '../../assets/images/annual-review/presentation/winter/dk/3.jpg';
import dk4winter from '../../assets/images/annual-review/presentation/winter/dk/4.jpg';
import dk5winter from '../../assets/images/annual-review/presentation/winter/dk/5.jpg';
import dk6winter from '../../assets/images/annual-review/presentation/winter/dk/6.jpg';
import dk7winter from '../../assets/images/annual-review/presentation/winter/dk/7.jpg';

import se1summer from '../../assets/images/annual-review/presentation/summer/se/1.jpg';
import se2summer from '../../assets/images/annual-review/presentation/summer/se/3.jpg';
import se3summer from '../../assets/images/annual-review/presentation/summer/se/3.jpg';
import se4summer from '../../assets/images/annual-review/presentation/summer/se/4.jpg';
import se5summer from '../../assets/images/annual-review/presentation/summer/se/5.jpg';
import se6summer from '../../assets/images/annual-review/presentation/summer/se/6.jpg';
import se7summer from '../../assets/images/annual-review/presentation/summer/se/7.jpg';

import se1winter from '../../assets/images/annual-review/presentation/winter/se/1.jpg';
import se2winter from '../../assets/images/annual-review/presentation/winter/se/2.jpg';
import se3winter from '../../assets/images/annual-review/presentation/winter/se/3.jpg';
import se4winter from '../../assets/images/annual-review/presentation/winter/se/4.jpg';
import se5winter from '../../assets/images/annual-review/presentation/winter/se/5.jpg';
import se6winter from '../../assets/images/annual-review/presentation/winter/se/6.jpg';
import se7winter from '../../assets/images/annual-review/presentation/winter/se/7.jpg';

import investmentStrategy1 from '../../assets/images/investment-strategy/presentation/1.jpg';
import investmentStrategy2 from '../../assets/images/investment-strategy/presentation/2.jpg';

export const slideSidebarImages = {
  annualReview: {
    summer: {
      no: {
        1: no1summer,
        1.1: no2summer,
        2: no3summer,
        3: no4summer,
        4: no5summer,
        5: no6summer,
        6: no7summer,
      },
      se: {
        1: se1summer,
        1.1: se2summer,
        2: se3summer,
        3: se4summer,
        4: se5summer,
        5: se6summer,
        6: se7summer,
      },
      dk: {
        1: dk1summer,
        1.1: dk2summer,
        2: dk3summer,
        3: dk4summer,
        4: dk5summer,
        5: dk6summer,
        6: dk7summer,
      },
    },
    winter: {
      no: {
        1: no1winter,
        1.1: no2winter,
        2: no3winter,
        3: no4winter,
        4: no5winter,
        5: no6winter,
        6: no7winter,
      },

      se: {
        1: se1winter,
        1.1: se2winter,
        2: se3winter,
        3: se4winter,
        4: se5winter,
        5: se6winter,
        6: se7winter,
      },

      dk: {
        1: dk1winter,
        1.1: dk2winter,
        2: dk3winter,
        3: dk4winter,
        4: dk5winter,
        5: dk6winter,
        6: dk7winter,
      },
    },
  },
  // Waiting for country specific images
  investmentStrategy: {
    summer: {
      no: {
        1: investmentStrategy1,
        5: investmentStrategy2,
      },
      se: {
        1: investmentStrategy1,
        5: investmentStrategy2,
      },
      dk: {
        1: investmentStrategy1,
        5: investmentStrategy2,
      },
    },
    winter: {
      no: {
        1: investmentStrategy1,
        5: investmentStrategy2,
      },
      se: {
        1: investmentStrategy1,
        5: investmentStrategy2,
      },
      dk: {
        1: investmentStrategy1,
        5: investmentStrategy2,
      },
    },
  },
};
