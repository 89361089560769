import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useQueryState } from 'react-router-use-location-state';

import { selectors, useResource } from '@formue-app/core';

import { ImplementationPlanChart } from '../../investmentStrategies/charts/ImplementationPlanChart';
import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { InvestmentStrategyStrategyPicker } from '../investmentStrategyComponents/InvestmentStrategyStrategyPicker';

const {
  entities: {
    investmentStrategyImplementationPlans: {
      investmentStrategyImplementationPlansByIdSelector,
    },
  },
} = selectors;

const Base = styled.div`
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  position: relative;
`;

export const ImplementationPlanSlide = (props) => {
  const { isActiveSlide } = props;

  const strategies = useEnabledStrategies();
  const [queryParamsSelectedStrategy] = useQueryState('selectedStrategies', '');

  useResource([
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_IMPLEMENTATION_PLANS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  const implementationPlan = useSelector(
    investmentStrategyImplementationPlansByIdSelector(
      queryParamsSelectedStrategy
    )
  );

  return (
    <Base>
      <InvestmentStrategyStrategyPicker />
      <ImplementationPlanChart
        implementationPlan={implementationPlan}
        animate={isActiveSlide}
      />
    </Base>
  );
};
