import React from 'react';
import styled from 'styled-components';
import { VictoryPie } from 'victory';

const StyledPieContainer = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const ChildrenContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  top: 0;
`;

export const PieChart = (props) => {
  const {
    data,
    size = 180,
    innerRadius,
    padAngle,
    cornerRadius,
    children,
    ...rest
  } = props;

  return (
    <StyledPieContainer size={size} {...rest}>
      <VictoryPie
        width={size}
        height={size}
        innerRadius={innerRadius}
        labels={() => null}
        padAngle={padAngle}
        cornerRadius={cornerRadius}
        padding={{ top: 0, bottom: 0, right: 0, left: 0 }}
        style={{
          data: {
            fill: ({ datum }) => datum.color,
          },
        }}
        data={data.map((item) => ({
          y: item.value,
          color: item.color,
        }))}
      />
      {children && <ChildrenContainer>{children}</ChildrenContainer>}
    </StyledPieContainer>
  );
};
