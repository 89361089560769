import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { expertInput } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { actions as uiActions } from '../../ui/readStatus';

// Create actions and a reducer for the expertInput resource
export const [actions, reducer, resourceSagas] = resourceReducer('expert-inputs');

// Create the sagas for this entity
function* readExpertInput() {
  yield takeEvery(actions.indexRequestBegin, function*() {
    try {
      const result = yield call(expertInput.get);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
      yield put(uiActions.hydrateReadItems('expertInputs'));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error })
    }
  });
}

export const sagas = [readExpertInput, ...resourceSagas];
