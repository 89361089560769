import { apiFetch } from './utils/api';
import { PersonalGreetingSerializer } from './serializers/personalGreeting';
// const mockedPersonalGreeting = require('./mocks/v1/personal-greeting.json');

export const personalGreeting = {
  /**
   * @desc Get list of personal greetings
   *
   * @return json - data or error message
   */
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v1/wealth-plan/personal-greetings';

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },

  /**
   * @desc Update a personal greeting
   *
   * @return json - data or error message
   */
  update: async (args, data) => {
    const { id } = args;
    const payload = PersonalGreetingSerializer.serialize(data);

    const options = {
      method: 'PATCH',
      body: payload,
    };
    const url = `/v1/wealth-plan/personal-greetings/${id}`;

    return apiFetch(url, options);
  },
};
