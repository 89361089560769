import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { selectors, useResource, usePrevious } from '@formue-app/core';

import { H4 } from '../texts';
import { BORDER_RADIUS_LARGE, SPACING_16 } from '../../constants/spacing';
import { actions as podcastActions } from '../../store/ui/podcast';
import { PodcastPlayerProgressBar } from './PodcastPlayerProgressBar';
import { PodcastPlayerControls } from './PodcastPlayerControls';
import { contentMaxWitdh } from '../../constants/grid';
import { PodcastPlayerCounter } from './PodcastPlayerCounter';

const StyledWrapper = styled.div`
  height: 100%;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${contentMaxWitdh}px;
  padding: 0 24px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
`;

const StyledThumbnail = styled.img`
  width: 40px;
  height: 40px;
  border-radius: ${BORDER_RADIUS_LARGE};
  margin-right: ${SPACING_16};
`;

const StyledTitle = styled(H4)`
  max-height: 35px;
  -webkit-line-clamp: 2;
  max-width: 230px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledColumn = styled.div`
  width: 33%;
  height: 100%;
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const StyledPodcastPlayerProgressBar = styled(PodcastPlayerProgressBar)`
  position: absolute;
  top: -14px;
  width: 100%;
  left: -2px;
`;

const {
  entities: {
    podcastEpisodes: { podcastEpisodesById },
  },
} = selectors;

export const PodcastsPlayer = ({ mainMenuOpen }) => {
  const dispatch = useDispatch();
  const loading = useResource(['PODCAST_EPISODES/INDEX']);
  const episodesById = useSelector(podcastEpisodesById);
  const podcastUi = useSelector((state) => state.ui.podcast);
  const { isPlaying, trackProgress, episodeId } = podcastUi;

  const episode = episodesById[episodeId];

  const intervalRef = useRef();
  const audioRef = useRef(new Audio(episode && episode.enclosure.url));

  useEffect(() => {
    dispatch(podcastActions.setAudioRef(audioRef));
    dispatch(podcastActions.setIntervalRef(intervalRef));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(podcastActions.setIsReady(true));
  }, [audioRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const prevIsPlaying = usePrevious(isPlaying);
  const prevEpisodeId = usePrevious(episodeId);

  const toNextTrack = () => {
    console.log('TODO go to next');
  };

  useEffect(() => {
    if (prevIsPlaying !== isPlaying) {
      if (isPlaying) {
        audioRef.current.play();
        startTimer();
      } else {
        clearInterval(intervalRef.current);
        audioRef.current.pause();
      }
    }
  }, [isPlaying]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevIsPlaying !== isPlaying) {
      if (isPlaying) {
        audioRef.current.play();
        startTimer();
      } else {
        clearInterval(intervalRef.current);
        audioRef.current.pause();
      }
    }
  }, [trackProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (episodeId !== prevEpisodeId) {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
      audioRef.current = new Audio(episode && episode.enclosure.url);
      const { duration, currentTime } = audioRef.current;
      dispatch(podcastActions.setTrackProgress(currentTime, 0, duration));
      dispatch(podcastActions.setIsPlaying(false));

      if (isPlaying) {
        audioRef.current.play();
        startTimer();
        dispatch(podcastActions.setIsPlaying(true));
      }
    }
  }, [episodeId]); // eslint-disable-line react-hooks/exhaustive-deps

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        const { duration, buffered, currentTime } = audioRef.current;
        dispatch(
          podcastActions.setTrackProgress(
            currentTime,
            buffered.length ? buffered.end(0) : 0,
            duration
          )
        );

        podcastActions.setTrackProgress(
          audioRef.current.currentTime,
          0,
          audioRef.current.duration
        );
      }
    }, [1000]);
  };

  const history = useHistory();

  const handleOnClick = () => {
    history.push(`/podcasts?id=${episodeId}`);
  };

  if (loading || !episode) return null;

  return (
    <StyledWrapper>
      <StyledPodcastPlayerProgressBar />
      <StyledRow mainMenuOpen={mainMenuOpen}>
        <StyledColumn>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledThumbnail
              src={episode.image ? episode.image : episode.podcast.image}
            />
            <StyledTitle onClick={handleOnClick}>{episode.title}</StyledTitle>
          </div>
        </StyledColumn>
        <StyledColumn style={{ justifyContent: 'center' }}>
          <PodcastPlayerControls />
        </StyledColumn>
        <StyledColumn style={{ justifyContent: 'flex-end' }}>
          <PodcastPlayerCounter />
        </StyledColumn>
      </StyledRow>
    </StyledWrapper>
  );
};
