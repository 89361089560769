import React from 'react';
import {
  isPartOfTheAssetClassGroup,
  selectors,
  toLocaleDateString,
  usePortfolioChartData,
  generateContributionChartAssetClassColor,
} from '@formue-app/core';
import { useSelector } from 'react-redux';

import { SpaghettiChart } from '../../../charts/SpaghettiChart';
import { getAssetClassName } from '@formue-app/core/src/services/utilities/portfolio';
import { formatChartData } from '../../../../services/utils/charts';
import { accent } from '../../../../constants/colors';

const {
  entities: {
    marketValues: { marketValuesSelector, marketValuesByAssetClassSelector },
  },
} = selectors;

export const ReturnContributionChart = ({ selected }) => {
  const marketValues = useSelector(marketValuesSelector);
  const marketValuesByAssetClass = useSelector(
    marketValuesByAssetClassSelector
  );

  const chartData = usePortfolioChartData(true, true);

  return (
    <SpaghettiChart
      dataObject={marketValuesByAssetClass}
      valueKey="twr"
      xAxisTicks={chartData.map((value) => value.x)}
      xAxisTicksFormat={(x) => {
        if (chartData.length < 15)
          return toLocaleDateString(x, {
            month: 'short',
          });
        if (x.getMonth() === 0) return x.getFullYear();
      }}
      selected={selected}
      isSelected={(selected, entryKey) =>
        isPartOfTheAssetClassGroup(selected, entryKey)
      }
      colorizeFunction={(entryKey) =>
        generateContributionChartAssetClassColor(entryKey, accent)
      }
      loading={!marketValues.length}
      labelFormat={(entryKey) => getAssetClassName(entryKey)}
      formatLineData={(data) => formatChartData(data, true)}
    />
  );
};
