export const getStartAndEndDate = (monthIndex, year, startDate, endDate) => {
  let newStartDate = startDate;
  let newEndDate = endDate;

  // By default set the date to be the last day in month
  let selectedDate = new Date(Date.UTC(year, monthIndex + 1, 0));

  // If there is no start or end date, set to the first day of the selected month
  if (!startDate && !endDate) {
    selectedDate = new Date(Date.UTC(year, monthIndex, 1));
  }

  // If it's the current month, set to the current date
  const today = new Date();
  const currentMonth = today.getUTCMonth();
  const currentYear = today.getUTCFullYear();

  if (monthIndex === currentMonth && year === currentYear) {
    selectedDate = new Date(Date.UTC(currentYear, currentMonth, 1));
  }

  if (!startDate || (startDate && endDate)) {
    newStartDate = selectedDate;
    newEndDate = null;
  } else if (startDate && !endDate) {
    if (selectedDate.toISOString() === new Date(startDate).toISOString()) {
      newStartDate = null;
    } else if (selectedDate > startDate) {
      newEndDate = selectedDate;
    } else {
      // If the selected date is after the end date we need to flip the dates
      // and set the start date to the first day of the selected month and enddate to be the last
      // day of the previously selected month
      const startDateYear = new Date(startDate).getUTCFullYear();
      const startDateMonthIndex = new Date(startDate).getUTCMonth();

      newStartDate = new Date(Date.UTC(year, monthIndex, 1));
      newEndDate = new Date(
        Date.UTC(startDateYear, startDateMonthIndex + 1, 0)
      );
    }
  }

  // Ensure newEndDate is the current date if it falls in the current month and year
  if (newEndDate) {
    const endDateMonth = newEndDate.getUTCMonth();
    const endDateYear = newEndDate.getUTCFullYear();
    if (endDateMonth === currentMonth && endDateYear === currentYear) {
      newEndDate = today;
    }
  }

  return { newStartDate, newEndDate };
};

export const getPredefinedRanges = (period) => {
  let newStartDate = null;
  let newEndDate = null;

  const now = new Date();
  switch (period) {
    case 'THISMONTH':
      newStartDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
      newEndDate = now;
      break;
    case 'THISYEAR':
      newStartDate = new Date(Date.UTC(now.getFullYear(), 0, 1));
      newEndDate = now;
      break;
    case 'TWELVEMONTHS':
      newStartDate = new Date(
        Date.UTC(now.getFullYear() - 1, now.getMonth(), 1)
      );
      newEndDate = now;
      break;
    case 'THREEYEARS':
      newStartDate = new Date(
        Date.UTC(now.getFullYear() - 3, now.getMonth(), 1)
      );
      newEndDate = now;
      break;
    case 'FIVEYEARS':
      newStartDate = new Date(
        Date.UTC(now.getFullYear() - 5, now.getMonth(), 1)
      );
      newEndDate = now;
      break;
    case 'TENYEARS':
      newStartDate = new Date(
        Date.UTC(now.getFullYear() - 10, now.getMonth(), 1)
      );
      newEndDate = now;
      break;
    default:
      break;
  }

  return { newStartDate, newEndDate };
};

export const transformActiveFilters = (activeFilters) => {
  let args = {
    ssids: activeFilters?.ssids,
    taxClass: activeFilters?.taxClass,
    showInternalPortfolio: activeFilters?.showInternalPortfolio,
    showExternalPortfolio: activeFilters?.showExternalPortfolio,
    account: activeFilters?.account,
  };

  if (!activeFilters?.startDate || !activeFilters?.endDate) {
    args.period = activeFilters?.period;
  } else {
    args.startDate = activeFilters?.startDate;
    const _endDate = new Date(activeFilters?.endDate);
    // Note that we have to add two days to account for both timezone and that the data from backend
    // returns "from start, to end". So if we want to have the last day in month we need to add an
    // extra days and ask for the first day in the next month
    args.endDate = new Date(
      _endDate.getFullYear(),
      _endDate.getMonth(),
      _endDate.getDate() + 2
    )
      .toISOString()
      .split('T')[0];
  }

  return args;
};
