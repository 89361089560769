import React from 'react';
import styled from 'styled-components';

import { closestPowerOfTen, formatBigMoney, nearest } from '@formue-app/core';
import { H4 } from '../../texts';
import { longAnimationTime, easeInOut } from '../../../constants/animation';
import { accent } from '../../../constants/colors';

const AxisWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;

const Zero = styled.div`
  position: absolute;
  width: calc(
    100% - ${(props) => props.yAxisPadding}px -
      ${(props) => props.paddingRight}px
  );
  height: 3px;
  background-color: #000;
  left: ${(props) => props.yAxisPadding}px;
  bottom: -1px;
`;

const TickWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 3px;
  transition: all ${longAnimationTime} ${easeInOut};
`;

const Line = styled.div`
  position: absolute;
  bottom: -1px;
  width: 100%;
  right: 0;
`;

const Label = styled(H4)`
  position: relative;
  display: inline-block;
  margin-left: auto;
  font-size: 12px;
  bottom: 10px;
  left: 12px;
  padding: 0 4px;
`;

export const BarChartYAxis = (props) => {
  const {
    max = 0,
    min = 0,
    numberOfTicks = 2,
    coef = 1,
    axisColor = accent.velvet1,
    zeroAxis,
    zeroAxisWidth,
    axisWidth = 1,
    zeroAxisColor,
    children,
    yAxisPadding,
    paddingRight,
    labelBackgroundColor = 'transparent',
  } = props;

  const step = (max + min) / numberOfTicks;
  const nearestStep = nearest(step, closestPowerOfTen(step));

  return (
    <AxisWrapper>
      {zeroAxis && (
        <TickWrapper style={{ bottom: 0, paddingRight }}>
          <Line
            style={{
              height: axisWidth,
              backgroundColor: axisColor,
            }}
          />
          <Zero
            yAxisPadding={yAxisPadding}
            style={{
              height: zeroAxisWidth,
              backgroundColor: zeroAxisColor,
            }}
            paddingRight={paddingRight}
          />
          <Label
            style={{ color: axisColor, backgroundColor: labelBackgroundColor }}
          >
            0
          </Label>
        </TickWrapper>
      )}
      <TickWrapper style={{ bottom: nearestStep * coef }}>
        <Line
          style={{
            height: axisWidth,
            backgroundColor: axisColor,
          }}
        />
        <Label
          style={{ color: axisColor, backgroundColor: labelBackgroundColor }}
        >
          {formatBigMoney(nearestStep, false)}
        </Label>
      </TickWrapper>
      <TickWrapper style={{ bottom: nearestStep * 2 * coef }}>
        <Line
          style={{
            height: axisWidth,
            backgroundColor: axisColor,
          }}
        />
        <Label
          style={{ color: axisColor, backgroundColor: labelBackgroundColor }}
        >
          {formatBigMoney(nearestStep * 2, false)}
        </Label>
      </TickWrapper>
      {children}
    </AxisWrapper>
  );
};
