import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { getFromAndToFromPeriodId, constants } from '@formue-app/core';

import { CollapsibleFilters } from './CollapsibleFilters';
import { ExtendedReportingRadioButton } from './ExtendedReportingRadioButton';
import { SPACING_16, SPACING_2 } from '../../../constants/spacing';

const { PERIODS } = constants.qlik;

const StyledCollapsibleFilters = styled(CollapsibleFilters)`
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: transparent !important;
`;

const PeriodList = styled.div`
  margin: 0 -${SPACING_16};
`;

const Period = (props) => {
  const {
    app,
    periodId,
    label,
    activePeriodId,
    onClick: onClickCallback,
  } = props;

  const onClick = (event) => {
    onClickCallback();

    // For some reason one needs to clear the selection first when using the
    // `selectMatch` api
    app.field('Period').clear();

    const { from, to } = getFromAndToFromPeriodId(periodId);
    if (!from && to) {
      return null;
    }

    // Make selection based on periods we calculated
    app.field('Period').selectMatch(`=Period >= ${from} and Period <= ${to}`);
  };

  return (
    <ExtendedReportingRadioButton
      label={label}
      onChange={onClick}
      selected={activePeriodId === periodId}
    />
  );
};

export const ExtendedReportingPeriodsFilter = ({
  app,
  activeFilters,
  onChangeCount = () => {},
  ...rest
}) => {
  const [activePeriodId, setActivePeriodId] = useState();
  const field = { name: 'Period', label: 'Periods', dataType: 'Number' };

  // We use this "hack" instead of some sort of deepEqual to compare the array
  // when used as a dependency to the useEffect. This makes sure we only run
  // the effect when the array contents has changed.
  const activeFiltersJsonString = JSON.stringify(activeFilters);
  useEffect(() => {
    // Figure out a selected state on the period buttons based on the active filters.
    // This is not the most robust way, it relies on us comparing the label of the filter
    // against the value in the PERIODS const.
    const periodFilter = activeFilters.find(
      (filter) => filter.fieldName === 'Period'
    );
    if (periodFilter) {
      const periodIndex = Object.values(PERIODS).indexOf(periodFilter.label);
      if (periodIndex > -1) {
        setActivePeriodId(Object.keys(PERIODS)[periodIndex]);
      } else {
        setActivePeriodId(undefined);
      }
    } else {
      setActivePeriodId('SINCESTART');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFiltersJsonString]);

  return (
    <StyledCollapsibleFilters
      title={field.label}
      childrenStyle={{ padding: `${SPACING_2} ${SPACING_16}` }}
      contentStyle={{ backgroundColor: 'transparent' }}
      startExpanded={false}
      {...rest}
    >
      <PeriodList>
        {Object.keys(PERIODS).map((periodId) => (
          <Period
            key={periodId}
            app={app}
            periodId={periodId}
            activePeriodId={activePeriodId}
            label={PERIODS[periodId]}
            onClick={() => {
              setActivePeriodId(periodId);
            }}
          />
        ))}
      </PeriodList>
    </StyledCollapsibleFilters>
  );
};
