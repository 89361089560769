import React from 'react';
import styled from 'styled-components';

import { percentage, sortByCostType, t } from '@formue-app/core';

import {
  SPACING_16,
  SPACING_20,
  SPACING_48,
  SPACING_8,
  SPACING_88,
} from '../../../constants/spacing';

import { StackedBar } from '../../charts/StackedBar';
import { BaseSection } from '../../common/BaseSection';
import { H2, Paragraph, ParagraphXSmall } from '../../texts';
import { DotIcon } from '../../common/DotIcon';
import { strategyCostColorMap } from '../../../services/utils/colorize';

const Header = styled(BaseSection)`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${SPACING_48};
  justify-content: center;
  align-items: center;
  gap: ${SPACING_88};
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_48};
  align-items: flex-end;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_20};
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
`;

export const CostDetailsHeader = (props) => {
  const { costData } = props;

  const filteredCostData = costData.filter(
    (item) =>
      item.group !== 'totals' &&
      item.group !== 'externalReporting' &&
      item.group !== 'platform'
  );

  const maxTotal = Math.max(
    ...Array(4)
      .fill()
      .map((item, index) =>
        filteredCostData
          .map((item) => item.costByYear[index].percentage)
          .reduce((acc, cur) => acc + cur, 0)
      )
  );

  const barLabel = (index) => {
    if (index === 3) {
      return 'advisor:strategySimulator:cost:year4plus';
    } else {
      return `advisor:strategySimulator:cost:year${index + 1}`;
    }
  };

  return (
    <>
      <H2 style={{ marginBottom: SPACING_16 }}>
        {t('presentation:investmentStrategy:cost:yearByYear')}
      </H2>
      <Header>
        <BarWrapper>
          {Array(4)
            .fill()
            .map((item, index) => {
              const yearLabel = t(barLabel(index));
              return (
                <StackedBar
                  items={filteredCostData
                    .filter((item) => item.costByYear[index].percentage)
                    .sort(sortByCostType)
                    .reverse()
                    .map((item) => {
                      return {
                        value: item.costByYear[index].percentage,
                        label: t(
                          `presentation:investmentStrategy:cost:${item.costType}`
                        ),
                        color: strategyCostColorMap[item.costType],
                      };
                    })}
                  animate={true}
                  title={yearLabel}
                  maxTotal={maxTotal}
                  label={percentage(
                    filteredCostData.reduce(
                      (acc, cur) => acc + cur.costByYear[index].percentage,
                      0
                    ) * 100,
                    2
                  )}
                  hover={true}
                />
              );
            })}
        </BarWrapper>
        <LabelWrapper>
          {filteredCostData
            .filter((item) => item.costByYear[0].percentage > 0)
            .sort(sortByCostType)
            .map((item) => (
              <Label>
                <DotIcon color={strategyCostColorMap[item.costType]} />
                <Paragraph>
                  {t(`presentation:investmentStrategy:cost:${item.costType}`)}
                </Paragraph>
              </Label>
            ))}
        </LabelWrapper>
      </Header>
      <ParagraphXSmall style={{ marginTop: SPACING_8, fontStyle: 'italic' }}>
        *{t('presentation:investmentStrategy:cost:costFootnote')}
      </ParagraphXSmall>
    </>
  );
};
