import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors } from '@formue-app/core';

import { H2, H3, H4, Paragraph } from '../components/texts';
import { GridElement } from '../components/layout';

import { GeneralPage as GeneralPageNO2020 } from './tax/no/2020/GeneralPage';
import { GeneralPage as GeneralPageNO2021 } from './tax/no/2021/GeneralPage';
import { GeneralPage as GeneralPageNO2022 } from './tax/no/2022/GeneralPage';
import { GeneralPage as GeneralPageNO2023 } from './tax/no/2023/GeneralPage';

import { GeneralPage as GeneralPageSE2022 } from './tax/se/2022/GeneralPage';
import { GeneralPage as GeneralPageSE2023 } from './tax/se/2023/GeneralPage';

import { Select } from '../components/formElements';
import { SPACING_32, SPACING_8 } from '../constants/spacing';
import { accent } from '../constants/colors';
import { VisibleInCountry } from '../components/common/VisibleInCountry';

// Seperate "translations" from tax spesific general texts. Since Tax is hardcoded for each language
// we use this text file to translate the the general texts based on operatingCountry not language
// like in I18n
import taxText from './tax/text.json';
import { SpriteIcon } from '../components/common/SpriteIcon';

const { operatingCountrySelector } = selectors.auth;

const HelpSection = styled.div`
  padding-top: 50px;
  text-align: center;
`;

const GeneralPageNO = (props) => {
  const { year } = props;

  switch (year) {
    case '2020':
      return <GeneralPageNO2020 year={year} />;
    case '2021':
      return <GeneralPageNO2021 year={year} />;
    case '2022':
      return <GeneralPageNO2022 year={year} />;
    case '2023':
      return <GeneralPageNO2023 year={year} />;
    default:
      return null;
  }
};

const GeneralPageSE = (props) => {
  const { year } = props;

  switch (year) {
    case '2022':
      return <GeneralPageSE2022 year={year} />;
    case '2023':
      return <GeneralPageSE2023 year={year} />;
    default:
      return null;
  }
};

export const TaxInfoGeneralBasePage = (props) => {
  const { year = '2023' } = useParams();
  let operatingCountry = useSelector(operatingCountrySelector);

  const [activeYear, setActiveYear] = useState(year);

  const options = [
    { label: '2020', value: 2020, availableCountry: ['no'] },
    { label: '2021', value: 2021, availableCountry: ['no'] },
    { label: '2022', value: 2022, availableCountry: ['no', 'se'] },
    { label: '2023', value: 2023, availableCountry: ['no', 'se'] },
  ];

  return (
    <>
      <GridElement columns={12} style={{ paddingBlock: SPACING_8 }}>
        <H2 style={{ marginBottom: SPACING_8 }}>
          {taxText[operatingCountry].title} {activeYear}
        </H2>
        <H3 style={{ color: accent.ocean470 }}>
          {taxText[operatingCountry].subTitle}
        </H3>
      </GridElement>

      <VisibleInCountry country={'no'}>
        <GeneralPageNO year={activeYear} />
      </VisibleInCountry>

      <VisibleInCountry country={'se'}>
        <GeneralPageSE year={activeYear} />
      </VisibleInCountry>

      <GridElement columns={12} style={{ marginTop: SPACING_32 }}>
        <Select
          style={{ width: 260 }}
          label={taxText[operatingCountry].yearDropdown}
          popoutLabel={true}
          defaultValue={activeYear}
          options={options.filter((item) =>
            item.availableCountry.includes(operatingCountry)
          )}
          onChange={(e) => {
            window.history.replaceState(
              null,
              '',
              `/portfolio/tax/${e.target.value}`
            );
            window.scrollTo(0, 0);
            setActiveYear(e.target.value);
          }}
        />
      </GridElement>

      <GridElement columns={12}>
        <HelpSection>
          <SpriteIcon id="Contact Mail" navigational={true} />
          <Paragraph>{taxText[operatingCountry].question}</Paragraph>
          <H4>
            {taxText[operatingCountry].contact} 
            <a href="mailto:kundenett@formue.no">kundenett@formue.no</a>
          </H4>
        </HelpSection>
      </GridElement>
    </>
  );
};
