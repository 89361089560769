import React from 'react';
import styled, { css } from 'styled-components';

import Icons from '../../assets/icons/icons-sprite.svg';

// IMPORTANT: We export the sprites from figma using the "SVG sprite Empowerer" plugin
// This plugin gives us a working svg sprite sheete where all icons are wrapped with a symbol and
// has an ID to identify them. This is important to make the sprites work. All icons also come in 4
// variants, mixed colors, solid color, black and white.
//
// To make the icons work with color variables we need to convert the colors into variables.
// This can be done in the following way:
// Replace The hex value "#45A2FF" with "var(--icon-main, #45A2FF)" the hex value with variable
// notates the fallback value if no variable is set.
// Same procidure has to be done with accent and tertiary colors as well. There are only four unique
// color variations, with three shades of colors each.
//
// To use the sprites we have some properties that can be set:
// id: Name of the icon
// inverted: Same value as in Figma, toggles between the four states together with navigational
// navigational: Same value as in Figma, toggles between the four states together with inverted
// size: sets the size of the icon
// color: sets primary color of icon (only used for overriding colors)
// accentColor: sets accent color of icon (only used for overriding colors)
// tertiaryColor: sets tertiary color of icon (only used for overriding colors)
//
// Example:
// <SpriteIcon id="Individual" inverted={true} /> // Gives a solid color icon

const StyledSVG = styled.svg`
  ${(props) =>
    props.color &&
    css`
      --icon-main: ${props.color};
    `}
  ${(props) =>
    props.accentColor &&
    css`
      --icon-accent: ${props.accentColor};
    `}
  ${(props) =>
    props.tertiaryColor &&
    css`
      --icon-tertiary: ${props.tertiaryColor};
    `}
`;

export const SpriteIcon = ({
  id,
  inverted = false,
  navigational = false,
  size = 24,
  ...rest
}) => {
  // To handle the format we get from Figma we need to convert the bool values to strings and
  // convert them to uppercase
  const invertedString =
    inverted.toString().charAt(0).toUpperCase() + inverted.toString().slice(1);
  const navigationalString =
    navigational.toString().charAt(0).toUpperCase() +
    navigational.toString().slice(1);

  let dimentions = {};
  if (size) {
    dimentions.width = size;
    dimentions.height = size;
  }

  return (
    <StyledSVG
      className="svg-icon"
      viewBox="0 0 40 40"
      {...dimentions}
      {...rest}
    >
      <use
        href={`${Icons}#${id}-Inverted=${invertedString}, Navigational=${navigationalString}`}
      />
    </StyledSVG>
  );
};
