import React from 'react';
import styled from 'styled-components';

import Icons from '../../assets/icons/functional-icons-sprite.svg';
import { accent } from '../../constants/colors';

// IMPORTANT: We export the sprites from figma using the "SVG sprite Empowerer" plugin
// This plugin gives us a working svg sprite sheete where all icons are wrapped with a symbol and
// has an ID to identify them. This is important to make the sprites work. All icons have onve
// variant and its important that the color os set to currentColor to allow us to override it

const StyledSVG = styled.svg`
  color: ${(props) => props.color};
`;

export const SpriteIconFunctional = ({
  id,
  direction,
  color = accent.ocean490,
  size = 20,
  ...rest
}) => {
  let dimentions = {};
  if (size) {
    dimentions.width = size;
    dimentions.height = size;
  }

  let string = `${Icons}#${id}`;

  if (direction) {
    // For arrows we need to only add direction
    string = `${Icons}#${id}-Direction=${direction}`;
  }

  return (
    <StyledSVG
      className="functional-svg-icon"
      viewBox="0 0 20 20"
      color={color}
      {...dimentions}
      {...rest}
    >
      <use href={string} />
    </StyledSVG>
  );
};
