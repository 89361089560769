import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useErrorState, useResource, usePrevious } from '@formue-app/core';

import { shortAnimationTime } from '../../../constants/animation';
import { PresentationErrorMessage } from './PresentationErrorMessage';
import { PresentationConfirmationMessage } from './PresentationConfirmationMessage';
import { useQueryState } from 'react-router-use-location-state';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';

const MessagesWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 100;
  opacity: 0;
  z-index: 0;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      z-index: 10;
    `}

  transition: linear all ${shortAnimationTime};
`;

export const InvestmentStrategyPresentationMessages = (props) => {
  const [visible, setVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const [exportMode] = useQueryState('exportMode', false);

  const strategies = useEnabledStrategies();

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_PRODUCT_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_PORTFOLIO_CHARACTERISTICS/INDEX',
      args: { strategyId: strategy.id },
    })),
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_COSTS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  const investmentStrategiesError = useErrorState([
    'INVESTMENT_STRATEGIES/INDEX',
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_PRODUCT_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_PORTFOLIO_CHARACTERISTICS/INDEX',
      args: { strategyId: strategy.id },
    })),
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_COSTS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  const isErrorMessage = investmentStrategiesError;
  const previousIsErrorMessage = usePrevious(isErrorMessage);

  // We need to decide if we want to show the message
  // Basic rules would be:
  // - Always show message if the previous was "error message"
  // - Always show "error messages"

  useEffect(() => {
    if (!loading) {
      if (previousIsErrorMessage) {
        setVisible(true);
      } else {
        if (isErrorMessage) {
          setVisible(true);
        }
        if (!isClosed) {
          setVisible(true);
        }
      }
    }
  }, [loading, previousIsErrorMessage, isErrorMessage, isClosed]);

  if (loading || exportMode) return null;

  return (
    <MessagesWrapper visible={visible}>
      {isErrorMessage ? (
        <PresentationErrorMessage
          resources={[
            {
              title: 'Investment strategies',
              isError: investmentStrategiesError,
            },
          ]}
          closeCallback={() => {
            setVisible(false);
          }}
        />
      ) : (
        <PresentationConfirmationMessage
          closeCallback={() => {
            setVisible(false);
            setIsClosed(true);
          }}
        />
      )}
    </MessagesWrapper>
  );
};
