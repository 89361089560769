import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';
import { sumBy } from 'lodash';

export const [
  cashFlowEventsAllIds,
  cashFlowEventsById,
  allCashFlowEventsSelector,
  allCashFlowEventsMetadataSelector,
] = resourceSelectors('cashFlowEvents');

export const yearlyCashFlowEventsSelector = createSelector(
  allCashFlowEventsSelector,
  (allCashFlowEvents) => {
    return allCashFlowEvents.filter((item) => item.type === 'yearly');
  }
);

export const singleCashFlowEventsSelector = createSelector(
  allCashFlowEventsSelector,
  (allCashFlowEvents) => {
    return allCashFlowEvents.filter((item) => item.type === 'single');
  }
);

export const flexibleCashFlowEventsSelector = createSelector(
  allCashFlowEventsSelector,
  (allCashFlowEvents) => {
    return allCashFlowEvents.filter((item) => item.flexible);
  }
);

// Perhaps include "flexible events" at some point?
export const totalCashFlowInAndOutSelector = createSelector(
  yearlyCashFlowEventsSelector,
  singleCashFlowEventsSelector,
  (yearlyCashFlowEvents, singleCashFlowEvents) => {
    return sumBy([...yearlyCashFlowEvents, ...singleCashFlowEvents], 'value');
  }
);

export const formattedCashFlowEvents = createSelector(
  yearlyCashFlowEventsSelector,
  singleCashFlowEventsSelector,
  (yearlyCashFlowEvents, singleCashFlowEvents) => {
    const events = singleCashFlowEvents.slice();
    for (let i = 0; i < 10; i++) {
      const reacuringItems = yearlyCashFlowEvents.map((item) => ({
        ...item,
        transactedAt: new Date(`${new Date().getFullYear() + i}`).toISOString(),
      }));

      events.push(reacuringItems);
    }
    return events.flat();
  }
);
