import React from 'react';
import styled from 'styled-components';

import { Paragraph } from '../../texts';
import { ProfileIcon } from '../../common/ProfileIcon';
import { SPACING_24 } from '../../../constants/spacing';
import { textLink, textMain } from '../../../constants/colors';

const ContactWrapper = styled.div`
  display: flex;
  gap: ${SPACING_24};
`;
const ContactInfo = styled.div``;
const StyledParagraph = styled(Paragraph)`
  a {
    color: ${textMain};

    &:hover {
      color: ${textLink};
      text-decoration: underline;
    }
  }
`;

export const ExpertContact = (props) => {
  const {
    contact: { name, email, phone, profileImageUrl },
  } = props;

  return (
    <ContactWrapper>
      <ProfileIcon name={name} url={profileImageUrl} size={72} />
      <ContactInfo>
        {/* {role && (
        <H4 style={{ marginBottom: '5px' }}>{getAdvisorRoleName(role)}</H4>
      )} */}
        {name && <Paragraph>{name}</Paragraph>}
        {email && (
          <StyledParagraph>
            <a href={`mailto:${email}`}>{email}</a>
          </StyledParagraph>
        )}
        {phone && (
          <StyledParagraph>
            <a href={`tel:${phone}`}>{phone}</a>
          </StyledParagraph>
        )}
      </ContactInfo>
    </ContactWrapper>
  );
};
