import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAssetClassName,
  percentage,
  selectors,
  getAssetAllocationGroups,
} from '@formue-app/core';

import styled from 'styled-components';
import { ParagraphSmall } from '../../texts';
import { accent } from '../../../constants/colors';

import { ReactComponent as PyramidIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-small-icon.svg';
import {
  BORDER_RADIUS_SMALL,
  SPACING_8,
  SPACING_16,
  SPACING_2,
} from '../../../constants/spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Legend = styled.div`
  padding: ${SPACING_16} 0 0 ${SPACING_8};
`;

const LegendItem = styled(ParagraphSmall)`
  position: relative;
  padding: ${SPACING_2} 0;
  &:before {
    content: '';
    background-color: ${(props) => props.color};
    height: 4px;
    width: 4px;
    left: -10px;
    top: 8px;
    position: absolute;
    width: 4px;
    border-radius ${BORDER_RADIUS_SMALL};
    z-index: 1;
  }
`;

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsByStrategyIdSelector,
    },
  },
} = selectors;

export const AssetAllocationGroups = (props) => {
  const { id, ...rest } = props;

  const investmentStrategiesById = useSelector(
    (state) => state.entities.investmentStrategies.byId
  );

  const strategy = investmentStrategiesById[id];
  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(id)
  );

  const assetAllocationGroups = getAssetAllocationGroups(assetAllocations);

  if (!strategy) return null;

  const velvetAccentColors = [accent.velvet3, accent.velvet2, accent.velvet1];

  const items = [
    {
      label: getAssetClassName('AV'),
      value: percentage(
        assetAllocationGroups.AV.strategyShare * 100,
        0,
        0,
        false
      ),
    },
    {
      label: getAssetClassName('MV'),
      value: percentage(
        assetAllocationGroups.MV.strategyShare * 100,
        0,
        0,
        false
      ),
    },
    {
      label: getAssetClassName('S'),
      value: percentage(
        assetAllocationGroups.S.strategyShare * 100,
        0,
        0,
        false
      ),
    },
  ];

  return (
    <Container {...rest}>
      <PyramidIcon />
      <Legend>
        {items.map((item, index) => (
          <LegendItem
            color={velvetAccentColors[index]}
            key={`legend-item-${index}`}
          >
            {`${item.label} ${percentage(item.value)}`}
          </LegendItem>
        ))}
      </Legend>
    </Container>
  );
};
