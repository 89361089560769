import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { actions as navigationActions } from '../../store/ui/navigation';

import { H2 } from '../texts';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';
import { SPACING_24 } from '../../constants/spacing';

export const StyledPopoverHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING_24};
`;

export const PopoverHeader = (props) => {
  const dispatch = useDispatch();

  const { title } = props;

  return (
    <StyledPopoverHeader>
      <H2>{title}</H2>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => dispatch(navigationActions.togglePopover())}
      >
        <SpriteIconFunctional id="Close" />
      </span>
    </StyledPopoverHeader>
  );
};
