import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectors, t, useResource } from '@formue-app/core';

import { BaseSection } from '../../components/common/BaseSection';
import { TotalWealth } from '../../components/wealthPlan/wealthBalance/TotalWealth';
import { Finance } from '../../components/wealthPlan/wealthBalance/Finance';
import { Distribution } from '../../components/wealthPlan/wealthBalance/Distribution';
import { HeaderWithBackgroundImage } from '../../components/common/HeaderWithBackgroundImage';
import { Debt } from '../../components/wealthPlan/wealthBalance/Debt';
import { SectionWithTitle } from '../../components/common/SectionWithTitle';
import { NoDataMessage } from '../../components/wealthPlan/NoDataMessage';
import { GridElement, MainGrid } from '../../components/layout';
import {
  SPACING_48,
  SPACING_32,
  SPACING_24,
  SPACING_88,
} from '../../constants/spacing';
import { accent } from '../../constants/colors';

import backgroundImage from '../../assets/wealth-plan/wealth-balance-background.jpg';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { CashflowEvents } from '../../components/wealthPlan/wealthBalance/CashflowEvents';

const {
  entities: {
    wealthBalance: { wealthBalanceHasContentSelector },
    cashFlowEvents: { formattedCashFlowEvents },
  },
} = selectors;

const StyledContainer = styled(BaseSection)`
  background-color: transparent;
  padding: 0;
`;

const StyledSubGrid = styled(MainGrid)`
  width: 100%;
  max-width: 100%;
  padding: 0;
`;

export const WealthBalancePage = () => {
  const loading = useResource([
    'BALANCE_ENTRIES/INDEX',
    'CASH_FLOW_EVENTS/INDEX',
  ]);

  const hasContent = useSelector(wealthBalanceHasContentSelector);

  const cashFlowEventsData = useSelector(formattedCashFlowEvents);

  return (
    <>
      <StyledContainer columns={12} style={{ padding: 0 }}>
        <HeaderWithBackgroundImage
          title={t('wealthBalance:title')}
          subtitle={t('wealthBalance:subtitle')}
          backgroundImage={backgroundImage}
          textColor={accent.velvet250}
          fullSize={true}
        >
          <TotalWealth style={{ marginTop: SPACING_24 }} />
        </HeaderWithBackgroundImage>
        {hasContent ? (
          <>
            <SectionWithTitle
              title={t('wealthBalance:distribution')}
              style={{ paddingTop: SPACING_48 }}
            >
              <Distribution />
            </SectionWithTitle>
            <StyledContainer style={{ marginTop: SPACING_32 }}>
              <StyledSubGrid>
                <SectionWithTitle
                  columns={6}
                  title={t('wealthBalance:incomeAndWithdrawalNeeds')}
                >
                  <Finance />
                </SectionWithTitle>
                <SectionWithTitle columns={6} title={t('wealthBalance:debt')}>
                  <Debt />
                </SectionWithTitle>
              </StyledSubGrid>
            </StyledContainer>
            {cashFlowEventsData?.length ? (
              <StyledContainer style={{ marginTop: SPACING_32 }}>
                <StyledSubGrid>
                  <SectionWithTitle
                    columns={6}
                    title={t('wealthBalance:futureCapitalNeeds')}
                    subTitle={t('wealthBalance:futureCapitalNeedsSubTitle')}
                  >
                    <CashflowEvents
                      cashFlowEventsData={cashFlowEventsData}
                      title={t('wealthBalance:upcomingYears')}
                    />
                  </SectionWithTitle>
                </StyledSubGrid>
              </StyledContainer>
            ) : null}
          </>
        ) : loading ? (
          <GridElement columns={12}>
            <CenteredActivityIndicator
              size={30}
              style={{ marginTop: SPACING_88 }}
            />
          </GridElement>
        ) : (
          <NoDataMessage columns={12} />
        )}
      </StyledContainer>
    </>
  );
};
