import React from 'react';
import styled, { css } from 'styled-components';

import {
  easeInOut,
  fadeInFromBottom,
  mediumAnimationTime,
} from '../../constants/animation';
import { accent, backgroundWhite } from '../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { H6, Paragraph } from '../texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  width: 320px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING_16};
  width: 100%;
  border-radius: ${BORDER_RADIUS_LARGE};

  opacity: 0;
  transform: translateY(60px);

  ${(props) =>
    props.animate &&
    props.selected &&
    css`
      animation: ${fadeInFromBottom} ${mediumAnimationTime} ${easeInOut}
        forwards;
      animation-delay: ${(props) => props.delay}s;
    `}
`;

const Title = styled(H6)`
  flex: 1;
  padding-bottom: ${SPACING_8};
`;

const SubItem = styled(Paragraph)`
  display: flex;
  padding-right: 12px;
  line-height: 1;
`;

const SubItemsWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const ChartSummary = (props) => {
  const {
    items,
    style,
    selected = [],
    alwaysSelected = [],
    animate,
    ...rest
  } = props;

  if (!items) return null;

  return (
    <Container style={style} {...rest}>
      {items.map((item, index) => {
        if (!item) return null;
        return (
          <Item
            style={{
              backgroundColor: item.backgroundColor
                ? item.backgroundColor
                : accent.ocean490,
            }}
            selected={
              !selected.length ||
              selected.includes(item.key) ||
              alwaysSelected.includes(item.key)
            }
            key={item.key}
            animate={animate}
            delay={0.3 + index * 0.3}
          >
            <Title
              style={{
                color: item.textColor ? item.textColor : backgroundWhite,
              }}
            >
              {item.title}
            </Title>
            <SubItemsWrapper>
              {item.hasOwnProperty('subItems') &&
                item.subItems.map((subItem, index) => (
                  <SubItem
                    style={{
                      color: item.textColor ? item.textColor : backgroundWhite,
                    }}
                    key={`summary-sub-item-${index}`}
                  >{`${subItem.label}: ${subItem.value}`}</SubItem>
                ))}
            </SubItemsWrapper>
          </Item>
        );
      })}
    </Container>
  );
};
