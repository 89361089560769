export const mock = {
  byId: {
    'random-id-1': {
      id: 'random-id-1',
      title: 'Opprette aksjesparekonto',
      description:
        'Aksjesparekonto (ASK) er en konto i en bank der privatpersoner kan kjøpe og selge børsnoterte aksjer og aksjefond uten å utløse skatt så lenge man ikke tar ut gevinsten fra kontoen.',
      status: 'Completed',
      createdAt: '2020-03-23T13:55:17.000Z',
      dueAt: null,
      completedAt: '2020-03-23T14:07:03.000Z',
      priority: 'high',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Luke Skywalker',
      },
      parent: {
        type: 'expert-inputs',
        id: 'a1Z3O000000Me4bUAC',
      },
    },
    'random-id-2': {
      id: 'random-id-2',
      title: 'Samboerkontrakt',
      description: null,
      status: 'Open',
      createdAt: '2020-03-23T14:00:11.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'high',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Darth Vader',
      },
      parent: {
        type: 'expert-inputs',
        id: 'a1Z3O000000Me4bUAC',
      },
    },
    'random-id-3': {
      id: 'random-id-3',
      title: 'Testament',
      description: null,
      status: 'Open',
      createdAt: '2020-03-23T14:02:36.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'high',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Darth Vader',
      },
      parent: {
        type: 'expert-inputs',
        id: 'a1Z3O000000Me4bUAC',
      },
    },
    'random-id-4': {
      id: 'random-id-4',
      title: 'Fremtidsfullmakt',
      description: null,
      status: 'Open',
      createdAt: '2020-03-23T14:04:34.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'high',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Darth Vader',
      },
      parent: {
        type: 'expert-inputs',
        id: 'a1Z3O000000Me4bUAC',
      },
    },
    'random-id-5': {
      id: 'random-id-5',
      title: 'Avvikle investeringsselskap',
      description: null,
      status: 'Open',
      createdAt: '2020-03-23T14:08:43.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'normal',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Darth Vader',
      },
      parent: {
        type: 'expert-inputs',
        id: 'a1Z3O000000Me4bUAC',
      },
    },
    'random-id-6': {
      id: 'random-id-6',
      title: 'Sameieavtale for sekundærbolig',
      description: null,
      status: 'Open',
      createdAt: '2020-03-23T14:06:48.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'normal',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Darth Vader',
      },
      parent: {
        type: 'expert-inputs',
        id: 'a1Z3O000000Me4bUAC',
      },
    },
    'random-id-7': {
      id: 'random-id-7',
      title: 'Spare i IPS',
      description: null,
      status: 'Open',
      createdAt: '2020-03-23T14:08:11.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'normal',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Darth Vader',
      },
      parent: {
        type: 'expert-inputs',
        id: 'a1Z3O000000Me4bUAC',
      },
    },
    'random-id-8': {
      id: 'random-id-8',
      title: 'Etablere datterselskap',
      description: null,
      status: 'Open',
      createdAt: '2020-03-27T14:58:40.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'normal',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Darth Vader',
      },
      parent: {
        type: 'expert-inputs',
        id: 'a1Z3O000000MeZnUAK',
      },
    },
    'random-id-9': {
      id: 'random-id-9',
      title: 'Ferdigstilt oppgave',
      description: 'Lorem ipsum suppum.',
      status: 'Completed',
      createdAt: '2020-03-27T15:02:23.000Z',
      dueAt: null,
      completedAt: '2020-03-27T15:02:23.000Z',
      priority: 'high',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Luke Skywalker',
      },
      parent: {
        type: 'Advisor Task',
        id: '0013O000002iTc0QAE',
      },
    },
    'random-id-10': {
      id: 'random-id-10',
      title: 'Oppgave bare til Ola Nordmann',
      description: 'Lorem ipsum',
      status: 'Open',
      createdAt: '2020-03-27T15:01:36.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'normal',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Luke Skywalker',
      },
      parent: {
        type: 'Advisor Task',
        id: '0013O000002iTc0QAE',
      },
    },
    'random-id-11': {
      id: 'random-id-11',
      title: 'Husk selvangivelsen til begge to',
      description: 'Lorempus ipsumpus',
      status: 'Open',
      createdAt: '2020-03-27T15:03:08.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'high',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Luke Skywalker',
      },
      parent: {
        type: 'Advisor Task',
        id: '0013O000002iTc0QAE',
      },
    },
    'random-id-12': {
      id: 'random-id-12',
      title: 'Oppgave til rådgiveren',
      description: 'Rabalansere eller bare balansere?',
      status: 'Open',
      createdAt: '2020-03-27T15:04:04.000Z',
      dueAt: null,
      completedAt: null,
      priority: 'normal',
      assignee: {
        profileImageUrl: 'https://example.com/profilephoto.jpg',
        name: 'Luke Skywalker',
      },
      parent: {
        type: 'Advisor Task',
        id: '0013O000002iTc0QAE',
      },
    },
  },
  allIds: [
    'random-id-1',
    'random-id-2',
    'random-id-3',
    'random-id-4',
    'random-id-5',
    'random-id-6',
    'random-id-7',
    'random-id-8',
    'random-id-9',
    'random-id-10',
    'random-id-11',
    'random-id-12',
  ],
};
