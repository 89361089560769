import React from 'react';
import styled from 'styled-components';

import { getAssetClassName, t } from '@formue-app/core';

import { H3, H5, Paragraph } from '../texts';

import { BaseSection } from '../common/BaseSection';
import { SPACING_16 } from '../../constants/spacing';
import { PortfolioPyramid } from '../common/PortfolioPyramid';

const Container = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  flex: 1;
`;

const PyramidWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 0 20px 20px;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
`;

const Title = styled(H3)`
  padding-bottom: ${SPACING_16};
`;

const Text = styled(Paragraph)`
  max-width: 600px;
  white-space: pre-line;
`;

const i18nPrefix = 'wealthPlan:investmentStrategy';

const pyramidLabels = [
  {
    labelKey: 'AV',
    translationKey: 'alternativeGrowth',
    color: '#C76FF2',
    offset: -40,
  },
  {
    labelKey: 'MV',
    translationKey: 'marketGrowth',
    color: '#C5A5D6',
    offset: -10,
  },
  {
    labelKey: 'S',
    translationKey: 'stability',
    color: '#6D4694',
    offset: 20,
  },
];

export const InvestmentPhilosophy = (props) => {
  return (
    <Container columns={12}>
      <Wrapper>
        <Title>{t(`${i18nPrefix}:investmentPhilosophy`)}</Title>
        <Row>
          <Text>{t(`${i18nPrefix}:investmentPhilosophyText`)}</Text>
          <PyramidWrapper>
            <PortfolioPyramid
              labels={pyramidLabels}
              height={200}
              labelComponent={(item) => (
                <H5>{getAssetClassName(item.labelKey)}</H5>
              )}
            />
          </PyramidWrapper>
        </Row>
      </Wrapper>
    </Container>
  );
};
