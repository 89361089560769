import React from 'react';

import { t } from '@formue-app/core';

import { BaseSection } from '../common/BaseSection';
import { Metrics } from '../strategySimulator/Metrics';

const i18nPrefix = 'advisor:strategySimulator:assetAllocation';

export const StrategyMetrics = (props) => {
  const {
    strategyReturn,
    strategyRisk,
    annualAverageReturn,
    annualAverageRisk,
  } = props;
  return (
    <>
      <BaseSection columns={6}>
        <Metrics
          sectionTitle={t(`${i18nPrefix}:expected`)}
          returnTitle={t(`${i18nPrefix}:yearlyReturn`)}
          volatilityTitle={t(`${i18nPrefix}:yearlyVolatility`)}
          returnValue={strategyReturn}
          volatilityValue={strategyRisk}
        />
      </BaseSection>
      <BaseSection columns={6}>
        <Metrics
          sectionTitle={t(`${i18nPrefix}:historical`)}
          returnTitle={t(`${i18nPrefix}:yearlyReturn`)}
          volatilityTitle={t(`${i18nPrefix}:yearlyVolatility`)}
          returnValue={annualAverageReturn}
          volatilityValue={annualAverageRisk}
        />
      </BaseSection>
    </>
  );
};
