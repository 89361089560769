import { FamilyAndOwnershipSection } from './FamilyAndOwnershipSection';
import { ContactSection } from './ContactSection';
import { GoalsSection } from './GoalsSection';
import { PortfolioConstructionSection } from './PortfolioConstructionSection';
import { PortfolioStrategySection } from './PortfolioStrategySection';
import { PortfolioReturnSection } from './PortfolioReturnSection';
import { PortfolioReturnPerYearSection } from './PortfolioReturnPerYearSection';
import { ReturnContributionSection } from './ReturnContributionSection';
import { FiltersSection } from './FiltersSection';
import { TodoSection } from './TodoSection';
import { WelcomeSection } from './WelcomeSection';
import { WealthDevelopmentSection } from './WealthDevelopmentSection';
import { WealthSection } from './WealthSection';
import { CashflowSection } from './CashflowSection';

export const reportSections = {
  ContactSection,
  GoalsSection,
  PortfolioConstructionSection,
  PortfolioStrategySection,
  PortfolioReturnSection,
  PortfolioReturnPerYearSection,
  ReturnContributionSection,
  FiltersSection,
  FamilyAndOwnershipSection,
  TodoSection,
  WelcomeSection,
  WealthDevelopmentSection,
  WealthSection,
  CashflowSection,
};
