import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import {
  SPACING_24,
  SPACING_120,
  SPACING_88,
} from '../../../constants/spacing';

import { H2 } from '../../texts';
import { GridElement, MainGrid } from '../../layout';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MarkdownContent } from '../../common/MarkdownContent';

const Wrapper = styled(MainGrid)`
  flex: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  max-width: 1232px;
`;

const StyledGridElement = styled(GridElement)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  height: 55vh;
`;

const {
  entities: {
    investmentStrategies: { investmentStrategyByIdSelector },
  },
} = selectors;

export const AdvisorComment = (props) => {
  const { title, strategyCommentKey, i18nKey } = props;
  const { search } = useLocation();
  const strategyId = new URLSearchParams(search).get('strategyId');

  const strategy = useSelector(investmentStrategyByIdSelector(strategyId));

  const [content, setContent] = useState();

  // Check to see if strategyCommentKey is part of strategy, else use hardcoded translation
  useEffect(() => {
    if (i18nKey) {
      setContent(t(i18nKey));
    } else {
      if (strategy) {
        if (Object.keys(strategy).find((key) => key === strategyCommentKey)) {
          setContent(strategy[strategyCommentKey]);
        }
      }
    }
  }, [strategy, strategyCommentKey, i18nKey]);

  return (
    <Wrapper>
      <StyledGridElement columns={7} className="scrollable-content">
        <H2>{t(title)}</H2>
        <div>
          <MarkdownContent>{content}</MarkdownContent>
        </div>
      </StyledGridElement>
    </Wrapper>
  );
};
