import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { t } from '@formue-app/core';

import { LinkSummaryBox } from '../../components/common/SummaryBox';
import { InvestmentSimulatorExcerpt } from '../../components/wealthPlan/InvestmentSimulation/InvestmentSimulatorExcerpt';
import { ExpertInputExcerpt } from '../../components/wealthPlan/expertInputs/ExpertInputExcerpt';
import { PersonalGreeting } from '../../components/wealthPlan/PersonalGreeting';
import { TotalWealthExcerpt } from '../../components/wealthPlan/wealthBalance/TotalWealthExcerpt';
import { DistributionExcerpt } from '../../components/wealthPlan/wealthBalance/DistributionExcerpt';

import { H2Small, ParagraphSmall } from '../../components/texts';
import { SPACING_16, SPACING_32, SPACING_8 } from '../../constants/spacing';
import {
  DesktopWrapper,
  MobileWrapper,
  notDesktopCondition,
  NotDesktopWrapper,
  NotMobileWrapper,
} from '../../constants/media';

import { BaseLinkItem } from '../../components/common/BaseLinkItem';

import wealthBalanceBackgroundImage from '../../assets/wealth-plan/wealth-balance-background.jpg';
import { SpriteIcon } from '../../components/common/SpriteIcon';

export const WealthPlanPage = () => {
  const isTabletOrMobile = useMediaQuery({
    query: notDesktopCondition,
  });

  return (
    <>
      <MobileWrapper>
        <PersonalGreeting columns={isTabletOrMobile ? 12 : 2} />
      </MobileWrapper>
      <TotalWealthExcerpt
        columns={isTabletOrMobile ? 12 : 5}
        backgroundImage={wealthBalanceBackgroundImage}
        to={'/wealth-plan/wealth-balance'}
      />
      <DistributionExcerpt columns={isTabletOrMobile ? 12 : 5} />

      <NotMobileWrapper>
        <PersonalGreeting columns={isTabletOrMobile ? 12 : 2} />

        <LinkSummaryBox
          columns={4}
          label={t('globals:readMore')}
          url={'/wealth-plan/goals'}
          icon={<SpriteIcon id="Goal" size={SPACING_32} />}
        >
          <H2Small style={{ marginBottom: SPACING_8 }}>
            {t('wealthPlan:goals:title')}
          </H2Small>
          <ParagraphSmall>{t('wealthPlan:goals:subtitle')}</ParagraphSmall>
        </LinkSummaryBox>
        <LinkSummaryBox
          columns={4}
          label={t('globals:readMore')}
          url={'/wealth-plan/family-and-ownership'}
          icon={<SpriteIcon id="Family" size={SPACING_32} />}
        >
          <H2Small style={{ marginBottom: SPACING_8 }}>
            {t('wealthPlan:familyAndOwnership:title')}
          </H2Small>
          <ParagraphSmall>
            {t('wealthPlan:familyAndOwnership:subtitle')}
          </ParagraphSmall>
        </LinkSummaryBox>
        <LinkSummaryBox
          columns={4}
          label={t('globals:readMore')}
          url={'/wealth-plan/investment-profile'}
          icon={<SpriteIcon id="Risk Tolerance" size={SPACING_32} />}
        >
          <H2Small style={{ marginBottom: SPACING_8 }}>
            {t('wealthPlan:investmentProfile:title')}
          </H2Small>
          <ParagraphSmall>
            {t('wealthPlan:investmentProfile:subtitle')}
          </ParagraphSmall>
        </LinkSummaryBox>
        <LinkSummaryBox
          columns={6}
          label={t('globals:readMore')}
          url={'/wealth-plan/expert-input'}
        >
          <NotDesktopWrapper>
            <SpriteIcon
              id="Competence"
              size={SPACING_32}
              style={{ marginBottom: SPACING_16 }}
            />
          </NotDesktopWrapper>
          <H2Small style={{ marginBottom: SPACING_8 }}>
            {t('wealthPlan:expertAdvice:title')}
          </H2Small>
          <DesktopWrapper>
            <ExpertInputExcerpt />
          </DesktopWrapper>
          <NotDesktopWrapper>
            <ParagraphSmall>
              {t('wealthPlan:investmentProfile:subtitle')}
            </ParagraphSmall>
          </NotDesktopWrapper>
        </LinkSummaryBox>

        <InvestmentSimulatorExcerpt />
      </NotMobileWrapper>

      <MobileWrapper>
        <BaseLinkItem
          columns={12}
          label={t('wealthPlan:goals:title')}
          to={'/wealth-plan/goals'}
          icon={<SpriteIcon id="Goal" size={24} />}
        />
        <BaseLinkItem
          columns={12}
          label={t('wealthPlan:familyAndOwnership:title')}
          to={'/wealth-plan/family-and-ownership'}
          icon={<SpriteIcon id="Family" size={24} />}
        />
        <BaseLinkItem
          columns={12}
          label={t('wealthPlan:investmentProfile:title')}
          to={'/wealth-plan/investment-profile'}
          icon={<SpriteIcon id="Risk Tolerance" size={24} />}
        />
        <BaseLinkItem
          columns={12}
          label={t('wealthPlan:investmentSimulator:title')}
          to={'/wealth-plan/investment-simulator'}
          icon={<SpriteIcon id="Investment" size={24} />}
        />
        <BaseLinkItem
          columns={12}
          label={t('wealthPlan:expertAdvice:title')}
          to={'/wealth-plan/expert-input'}
          icon={<SpriteIcon id="Competence" size={24} />}
        />
      </MobileWrapper>
    </>
  );
};
