import React from 'react';
import styled from 'styled-components';

import {
  H1Size,
  H1LineHeight,
  H1SizeMobile,
  H1LineHeightMobile,
} from '../../constants/text';
import { notDesktopCondition } from '../../constants/media';

export const StyledH1 = styled.h1`
  font-size: ${H1Size};
  line-height: ${H1LineHeight};

  @media ${notDesktopCondition} {
    font-size: ${H1SizeMobile};
    line-height: ${H1LineHeightMobile};
  }
`;

export const H1 = React.forwardRef((props, ref) => {
  const { children } = props;

  return (
    <StyledH1 ref={ref} {...props}>
      {children}
    </StyledH1>
  );
});
