import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [golasAllIds, goalsById, allGoalsSelector] =
  resourceSelectors('goals');

export const goalsHasContentSelector = createSelector(
  allGoalsSelector,
  (goals) => {
    return !![...goals].length;
  }
);
