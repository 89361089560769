import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, formatMoney } from '@formue-app/core';
import { SPACING_48, SPACING_16 } from '../../../constants/spacing';
import { t } from '@formue-app/core';
import { BaseSection } from '../../common/BaseSection';
import { GridElement, MainGrid } from '../../layout';
import { ShapeChart } from './ShapeChart';
import { ListEntries } from './ListEntries';
import { H2, H6 } from '../../texts';
import { ExpandableListItem } from '../../common/ExpandableListItem';
import { numbersFontStack } from '../../../fonts';

const {
  entities: {
    wealthBalance: { createWealthBalanceByCategorySelector },
  },
} = selectors;

const StyledSubGrid = styled(MainGrid)`
  width: 100%;
  max-width: 100%;
  padding: 0;
`;

const StyledAmount = styled(H2)`
  margin: 4px 0 ${SPACING_48} 0;
  font-family: ${numbersFontStack};
  font-weight: 500;
`;

const StyledListEntries = styled(ListEntries)`
  margin-top: 40px;
  padding-top: ${SPACING_16};
`;

export const Distribution = (props) => {
  const safety = useSelector(createWealthBalanceByCategorySelector('Safety'));
  const preserveAndGrow = useSelector(
    createWealthBalanceByCategorySelector('Preserve & Grow')
  );
  const create = useSelector(createWealthBalanceByCategorySelector('Create'));

  return (
    <StyledSubGrid>
      <GridElement columns={4}>
        <ExpandableListItem height={620}>
          <BaseSection>
            <H6>{t('wealthBalance:categories:safety')}</H6>
            <StyledAmount>{formatMoney(safety.amount)}</StyledAmount>
            <ShapeChart category="safety" />
            <StyledListEntries entries={safety.items} />
          </BaseSection>
        </ExpandableListItem>
      </GridElement>
      <GridElement columns={4}>
        <ExpandableListItem height={620}>
          <BaseSection>
            <H6>{t('wealthBalance:categories:preserveAndGrow')}</H6>
            <StyledAmount>{formatMoney(preserveAndGrow.amount)}</StyledAmount>
            <ShapeChart category="preserveAndGrow" />
            <StyledListEntries entries={preserveAndGrow.items} />
          </BaseSection>
        </ExpandableListItem>
      </GridElement>
      <GridElement columns={4}>
        <ExpandableListItem height={620}>
          <BaseSection>
            <H6>{t('wealthBalance:categories:create')}</H6>
            <StyledAmount>{formatMoney(create.amount)}</StyledAmount>
            <ShapeChart category="create" />
            <StyledListEntries entries={create.items} />
          </BaseSection>
        </ExpandableListItem>
      </GridElement>
    </StyledSubGrid>
  );
};
