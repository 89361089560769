import { apiFetch } from './utils/api';
// const mockedInvestmentStrategySimulations = require('./mocks/v1/wealth-plan/investment-strategy-simulations.json');

export const investmentStrategySimulations = {
  /**
   * @desc Get investment strategy object
   *
   * @return json - data or error message
   */
  get: async (args = { riskLevel: 2, alternativeGrowthLevel: 3 }) => {
    const options = {
      method: 'GET',
    };

    const url = `/v1/investment-strategy-simulations?riskLevel=${args.riskLevel}&alternativeGrowthLevel=${args.alternativeGrowthLevel}`;

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
