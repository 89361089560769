import React from 'react';
import styled, { css } from 'styled-components';

import { t, useFullName } from '@formue-app/core';

import { SPACING_24, SPACING_40, SPACING_8 } from '../constants/spacing';
import { accent, hoverBoxShadowLvl2 } from '../constants/colors';
import { easeInOut, shortAnimationTime } from '../constants/animation';

import { MenuPage } from './base/MenuPage';
import { BaseSection } from '../components/common/BaseSection';
import { H2, ParagraphSmall } from '../components/texts';
import { ButtonSmall } from '../components/buttons';
import { ClientMetadata } from '../components/advisorsCorner/ClientMetadata';

import { SpriteIcon } from '../components/common/SpriteIcon';

const ActionCards = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  height: 350px;
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  &:hover {
    box-shadow: ${hoverBoxShadowLvl2};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;

      &:hover {
        box-shadow: none;
      }
    `}
`;

const ActionCardTitle = styled(H2)`
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_8};
  word-wrap: break-word;
  hyphens: auto;
`;

export const AdvisorsCornerOverview = (props) => {
  const name = useFullName(t('advisor:title'));

  return (
    <MenuPage title={name} {...props}>
      <ClientMetadata />
      <ActionCards columns={3}>
        <SpriteIcon id="Art" size={SPACING_40} />
        <ActionCardTitle>{t('advisor:annualReview:title')}</ActionCardTitle>
        {/* <ParagraphSmall style={{ color: accent.ocean470 }}>
          Sendt to client: -
        </ParagraphSmall> */}
        <ButtonSmall
          buttoncolor={accent.ocean220}
          buttoncolorhover={accent.ocean250}
          buttoncoloractive={accent.ocean220}
          textcolor={accent.ocean490}
          style={{ marginTop: 'auto', marginBottom: SPACING_8 }}
          to="/report/annual-review"
        >
          {t('advisor:annualReviewReportButton')}
        </ButtonSmall>
        <ButtonSmall to="/advisor/presentation/annual-review">
          {t('advisor:annualReviewPresentationButton')}
        </ButtonSmall>
      </ActionCards>
      <ActionCards columns={3}>
        <SpriteIcon id="Goal" size={SPACING_40} />
        <ActionCardTitle>
          {t('advisor:strategySimulator:title')}
        </ActionCardTitle>
        {/* <ParagraphSmall style={{ color: accent.ocean470 }}>
          Last edited: -
        </ParagraphSmall> */}
        <ButtonSmall
          style={{ marginTop: 'auto' }}
          to="/advisor/strategy-simulator"
        >
          {t('advisor:strategySimulatorButton')}
        </ButtonSmall>
      </ActionCards>
      <ActionCards columns={3} disabled={true}>
        <SpriteIcon id="Joy" size={SPACING_40} />
        <ActionCardTitle>{t('advisor:statusMetting:title')}</ActionCardTitle>
        <ParagraphSmall
          style={{ marginBottom: 'auto', color: accent.ocean470 }}
        >
          {t('advisor:comingSoon')}
        </ParagraphSmall>
      </ActionCards>
    </MenuPage>
  );
};
