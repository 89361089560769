import React from 'react';
import styled from 'styled-components';

import {
  backgroundWhite,
  blackTranslucent,
  checkboxCustom,
  accent,
} from '../../constants/colors';
import { SPACING_16 } from '../../constants/spacing';
import { Paragraph } from '../../components/texts';

const StyledRadioWrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};
`;

const StyledToggleInner = styled.div`
  border: ${(props) => (props.checked ? 0 : `1px solid ${accent.ocean470}`)};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? `${props.height}px` : '24px')};
  width: ${(props) => (props.width ? `${props.width}px` : '24px')};
  position: relative;
  padding: 2px;
  background: ${(props) =>
    props.checked ? checkboxCustom.background : backgroundWhite};

  &:hover {
    border-color: ${(props) => !props.checked && accent.ocean4};
  }

  &:after {
    content: '';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    height: ${(props) => (props.height ? `${props.height / 2}px` : '12px')};
    width: ${(props) => (props.width ? `${props.width / 2}px` : '12px')};
    border-radius: 50%;
    background: ${backgroundWhite};
  }
`;

const StyledRadio = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 30px;
`;

export const RadioButton = (props) => {
  const {
    id,
    label,
    onChange = () => {},
    disabled,
    checked,
    labelColor,
    backgroundColor = blackTranslucent(0.04),
    backgroundColorActive = checkboxCustom.background,
    type = 'radio',
    height = 24,
    width = 24,
    ...rest
  } = props;

  let { name } = props;

  if (!name) {
    name = id;
  }

  return (
    <StyledRadioWrapper className="toggle" htmlFor={id} {...rest}>
      <StyledToggleInner
        checked={checked}
        backgroundColor={backgroundColor}
        backgroundColorActive={backgroundColorActive}
        height={height}
        width={width}
      >
        <StyledRadio
          type={type}
          name={name}
          id={id}
          checked={checked}
          onChange={(event) => onChange(event)}
          disabled={disabled}
          onClick={e => e.stopPropagation()}
        />
      </StyledToggleInner>
      {label && (
        <label htmlFor={id}>
          <Paragraph style={{ color: labelColor }}>{label}</Paragraph>
        </label>
      )}
    </StyledRadioWrapper>
  );
};
