import { compose, combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { flattenDeep } from 'lodash';
import { createBrowserHistory } from 'history';

import * as app from './app';
import * as errors from './errors';
import * as auth from './auth';
import * as ui from './ui';
import * as webauthn from './webauthn';

import { sagas as coreSagas, reducers as coreReducers } from '@formue-app/core';

export const history = createBrowserHistory();

// We have UI reducers in core and web, so we need to merge them
const uiReducer = combineReducers({
  ...coreReducers.ui.all,
  ...ui.reducers.all,
});

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    api: coreReducers.api,
    entities: coreReducers.entities,
    flags: coreReducers.flags,
    app: app.reducer,
    errors: errors.reducer,
    auth: auth.reducer,
    ui: uiReducer,
    webauthn: webauthn.reducer,
  });

const sagas = flattenDeep([
  ...coreSagas,
  ...app.sagas,
  ...errors.sagas,
  ...auth.sagas,
  ...ui.sagas,
  ...webauthn.sagas,
]);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  createRootReducer(history),
  {},
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);

sagas.forEach((saga) => sagaMiddleware.run(saga));
