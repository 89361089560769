import React from 'react';
import styled from 'styled-components';
import { mobileCondition } from '../../constants/media';
import { backgroundMain } from '../../constants/colors';

const StyledContentWrapper = styled.div`
  background: ${backgroundMain};
  display: flex;
  min-height: 100vh;

  ${({ position }) => {
    if (position === 'center') {
      return `
        align-items: center;
        justify-content: center;
      `;
    }
  }}

  @media ${mobileCondition} {
    flex-direction: column;
    margin-right: 0;
  }
`;

export const ContentWrapper = (props) => {
  const { children } = props;

  return <StyledContentWrapper {...props}>{children}</StyledContentWrapper>;
};
