import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import camelcase from 'camelcase';

import { t, selectors } from '@formue-app/core';
import { H3 } from '../../../texts';
import { accent } from '../../../../constants/colors';

const {
  entities: {
    securities: { securitiesByContinentSelector, securitiesByCountrySelector },
  },
} = selectors;

export const ContinentText = ({
  selectedContinent = 'Global',
  fontSize = 24,
  lineHeight = 29,
  TextComponent = H3,
  style,
  textColor = accent.ocean250,
  highlightColor = accent.ocean3,
  ...rest
}) => {
  const byContinent = useSelector(securitiesByContinentSelector);
  const byCountry = useSelector(securitiesByCountrySelector);

  return (
    <div style={style} {...rest}>
      {selectedContinent === 'Global' ? (
        <TextComponent
          style={{
            color: textColor,
            fontSize,
            lineHeight: `${lineHeight}px`,
          }}
        >
          <Trans
            i18nKey="geography:globalText"
            values={{
              number: Object.keys(byCountry).length,
            }}
            components={{
              strong: (
                <span style={{ color: highlightColor, fontWeight: 500 }} />
              ),
            }}
          />
        </TextComponent>
      ) : (
        <TextComponent
          style={{
            color: textColor,
          }}
        >
          <Trans
            i18nKey="geography:continentText"
            values={{
              number: byContinent[selectedContinent].countries.length,
              continent: t(
                `geography:continents:${camelcase(selectedContinent)}`
              ),
            }}
            components={{
              strong: (
                <span style={{ color: highlightColor, fontWeight: 500 }} />
              ),
            }}
          />
        </TextComponent>
      )}
    </div>
  );
};
