import React from 'react';
import camelcase from 'camelcase';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import { ExpertInputIcon } from './ExpertInputIcon';
import { accent } from '../../../constants/colors';
import { SPACING_16 } from '../../../constants/spacing';
import { H4 } from '../../texts';

const ExpertType = styled.div`
  border-bottom: 1px solid ${accent.sand1};
  padding: 12px 0;
  display: flex;
  align-items: center;
  gap: ${SPACING_16};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const {
  entities: {
    expertInputs: { expertAdviceOverviewTypes },
  },
} = selectors;

export const ExpertInputExcerpt = (props) => {
  return expertAdviceOverviewTypes.map((type, index) => (
    <ExpertType key={`expert-type-${index}`}>
      <IconWrapper>
        <ExpertInputIcon type={type} />
      </IconWrapper>
      <H4 key={`exprt-input-${index}`}>
        {t(`wealthPlan:expertAdvice:${camelcase(type)}:title`)}
      </H4>
    </ExpertType>
  ));
};
