import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { mergeWith, isNumber } from 'lodash';

import {
  formatCashflowDetailsChartData,
  formatMoney,
  selectors,
  t,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_32,
  SPACING_24,
  SPACING_48,
} from '../../../constants/spacing';

import { MainGrid } from '../../layout';
import { TableList } from '../../lists/TableList';
import { H2, H6 } from '../../texts';
import { HorizontalBarChart } from '../../graphs/HorizontalBarChart';

const {
  entities: {
    illiquidSecurities: { allIlliquidSecuritiesSelector },
  },
} = selectors;

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const StyledTableList = styled(TableList)`
  margin-top: 0;
  margin-bottom: ${SPACING_24};

  tbody > tr:last-child {
    background: ${accent.sand3};
    position: sticky;
    bottom: 48px;
  }
`;

const StyledTitle = styled(H2)`
  margin: ${SPACING_48} 0 0 0;
  grid-column: span 12;
`;

const ChartSectionWrapper = styled.div`
  margin: ${SPACING_32} 0 0 0;
  grid-column: span 12;
`;

const ChartWrapper = styled.div`
  position: relative;
  margin-left ${SPACING_24};
`;

const ChartTooltip = styled(H6)`
  position: absolute;
  left: -50px;
  top: 43%;
  rotate: -90deg;
`;

export const CashFlowDetails = ({ isActiveSlide }) => {
  const illiquidSecurities = useSelector(allIlliquidSecuritiesSelector);

  let columns = [
    {
      key: 'name',
      width: '30%',
      label: t('presentation:annualReview:cashFlow:details:holding'),
    },
    {
      key: 'committedCapital',
      label: t('presentation:annualReview:cashFlow:details:commitedCapital'),
      render: ({ committedCapital: val }) => (val ? formatMoney(val) : 0),
      type: 'number',
      width: '17.5%',
    },
    {
      key: 'contributedCapital',
      label: t('presentation:annualReview:cashFlow:details:contributedCapital'),
      render: ({ contributedCapital: val }) => (val ? formatMoney(val) : 0),
      type: 'number',
      width: '17.5%',
    },
    {
      key: 'remainingCommitment',
      label: t(
        'presentation:annualReview:cashFlow:details:remainingCommitment'
      ),
      render: ({ committedCapital, contributedCapital }) =>
        committedCapital && contributedCapital
          ? formatMoney(committedCapital - contributedCapital)
          : 0,
      type: 'number',
      width: '17.5%',
    },
    {
      key: 'marketValue',
      label: t('presentation:annualReview:cashFlow:details:marketValue'),
      render: ({ amount, externalAmount }) =>
        amount || externalAmount ? formatMoney(amount + externalAmount) : 0,
      type: 'number',
      width: '17.5%',
      defaultSort: true,
    },
  ];

  const total = mergeWith({}, ...illiquidSecurities, (objValue, srcValue) =>
    isNumber(objValue) ? objValue + srcValue : srcValue
  );

  const rows = [
    ...illiquidSecurities,
    {
      ...total,
      name: t('presentation:annualReview:cashFlow:details:total'),
      marketValue: total.amount + total.externalAmount,
    },
  ];

  if (!isActiveSlide || !illiquidSecurities) {
    return null;
  }

  const chartData = formatCashflowDetailsChartData(illiquidSecurities);

  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  return (
    <Wrapper>
      <StyledTitle>
        {t('presentation:annualReview:cashFlow:details:title')}
      </StyledTitle>
      <StyledTableList
        className="table-list"
        columns={columns}
        rows={rows}
        keyExtractor={(item, index) => `cashflow-details-${index}`}
        showHeader={true}
      />
      <ChartSectionWrapper>
        <StyledTitle style={{ margin: `0 0 ${SPACING_48} 0` }}>
          {t('presentation:annualReview:cashFlow:details:perHolding')}
        </StyledTitle>
        <ChartWrapper>
          <ChartTooltip>
            {t('presentation:annualReview:cashFlow:details:holding')}
          </ChartTooltip>
          {chartData && chartData.length && (
            <HorizontalBarChart
              data={chartData}
              axis
              headroom={0}
              scale={0.9}
              numberfOfTicks={5}
              valueFormat={(value) => formatter.format(value)}
              valueMargin={8}
              labelsOutside
            />
          )}
        </ChartWrapper>
      </ChartSectionWrapper>
    </Wrapper>
  );
};
