import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { mobileCondition } from '../../constants/media';
import { easeInOut, shortAnimationTime } from '../../constants/animation';
import { SpriteIconFunctional } from './SpriteIconFunctional';

const ArrowContainer = styled.span`
  display: flex;
  margin: 0;
  width: 20px;
  height: 20px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 50%;

  svg {
    transform: rotate(${(props) => props.rotation}deg);
    transition: all ${easeInOut} ${shortAnimationTime};
    transform-origin: center center;
    margin-top: -2px;
  }
`;

export const ExpandableArrow = (props) => {
  const { direction, arrowComponent, size = 16, style } = props;

  const isMobile = useMediaQuery({ query: mobileCondition });
  const arrowSize = isMobile ? 12 : size;
  const arrowOffset = direction === 'up' ? 1 : 2;

  const transform = () => {
    switch (direction) {
      case 'up':
        return 180;
      default:
        return 0;
    }
  };

  const Component = arrowComponent ? (
    arrowComponent
  ) : (
    <SpriteIconFunctional id="Simple arrow" direction="Down" size={size} />
  );

  return (
    <ArrowContainer
      {...props}
      style={{
        width: arrowSize,
        height: arrowSize * 0.7,
        translate: `0 ${arrowOffset}px`,
        ...style,
      }}
      rotation={transform()}
    >
      {Component}
    </ArrowContainer>
  );
};
