import React from 'react';
import styled from 'styled-components';

import { H4 } from '../../texts';
import { PercentageBar } from '../../common/PercentageBar';
import { SPACING_8 } from '../../../constants/spacing';

const StyledContainer = styled.div`
  display: flex;
  gap: ${SPACING_8};
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LensesPercentageBadge = ({ title, percentage, icon, ...rest }) => {
  return (
    <StyledContainer {...rest}>
      {icon}
      <StyledContent>
        <H4 numberOfLines={1} style={{ lineHeight: 1 }}>
          {title}
        </H4>
        <PercentageBar value={percentage} style={{ paddingTop: 0 }} />
      </StyledContent>
    </StyledContainer>
  );
};
