import { flattenDeep } from 'lodash';

import * as meeting from './meeting';
import * as presentation from './presentation';
import * as readStatus from './readStatus';
import * as signingEvents from './signingEvents';
import * as impersonation from './impersonation';
import * as todo from './todo';
import * as user from './user';
import * as portfolio from './portfolio';
import * as qlik from './qlik';

export const sagas = flattenDeep([
  ...signingEvents.sagas,
  ...meeting.sagas,
  ...presentation.sagas,
  ...readStatus.sagas,
  ...impersonation.sagas,
  ...todo.sagas,
  ...user.sagas,
  ...portfolio.sagas,
  ...qlik.sagas,
]);
