import { t } from '@formue-app/core';
import React from 'react';
import styled from 'styled-components';
import { easeInOut, extraLongAnimationTime } from '../../constants/animation';
import { accent } from '../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_8,
} from '../../constants/spacing';

import { H1, H6 } from '../texts';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${SPACING_8};
  width: 100%;
  padding-top: 130px;
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
  z-index: 1;
`;

export const SlideTitle = (props) => {
  const {
    titleKey,
    subTitle,
    subTitleColor,
    titleColor,
    isActiveSlide,
    breakTitle,
    style,
    ...rest
  } = props;

  return (
    <TitleWrapper {...rest}>
      <H1
        style={{
          color: accent[titleColor],
          // top: isActiveSlide ? 0 : 20,
          opacity: isActiveSlide ? 1 : 0,
          transition: `all ${easeInOut} ${extraLongAnimationTime} 0.8s`,
          position: 'relative',
          whiteSpace: 'pre-line',
          ...(breakTitle && { maxWidth: '50%', maxHeight: 55 }),
        }}
      >
        {t(titleKey)}
      </H1>
      <H6
        style={{
          color: accent[subTitleColor],
          // top: isActiveSlide ? 0 : 20,
          opacity: isActiveSlide ? 1 : 0,
          transition: `all ${easeInOut} ${extraLongAnimationTime} 0.7s`,
          position: 'relative',
        }}
      >
        {t(subTitle)}
      </H6>
    </TitleWrapper>
  );
};
