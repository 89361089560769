import React from 'react';
import styled from 'styled-components';

import { t, constructEmploymentString } from '@formue-app/core';

import {
  SPACING_8,
  SPACING_24,
  BORDER_RADIUS_LARGE,
  SPACING_16,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { ProfileIcon } from '../../common/ProfileIcon';
import { H4, ParagraphSmall } from '../../texts';

const StyledFamilyItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: calc(50% - ${SPACING_16} / 2);
  padding: ${SPACING_24};
  padding-top: 0;
  border: 1px solid ${accent.sand370};
  margin-top: 25px;
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const ImageWrapper = styled.div`
  margin-bottom: ${SPACING_8};
  margin-top: -25px;
`;

const StyledName = styled(H4)`
  text-align: center;
  margin-bottom: 4px;
`;

export const FamilyItem = (props) => {
  const { name, birthDate, employment } = props;

  return (
    <StyledFamilyItem>
      <ImageWrapper>
        <ProfileIcon color={accent.raspberry4} size={48} fontSize={15} />
      </ImageWrapper>
      <StyledName>{name}</StyledName>

      <ParagraphSmall style={{ textAlign: 'center' }}>
        {birthDate
          ? t('wealthPlan:familyAndOwnership:family:birthdate', {
              year: new Date(birthDate).getFullYear(),
            })
          : null}
        {birthDate && employment ? ' | ' : null}
        {employment ? constructEmploymentString(employment) : null}
      </ParagraphSmall>
    </StyledFamilyItem>
  );
};
