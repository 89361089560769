import { useSelector } from 'react-redux';
import { KNOWLEDGEBASE_TASK } from '../../constants/tasks';

const TASK_TYPES_TO_EXCLUDE_FROM_SUB_TASKS = [
  KNOWLEDGEBASE_TASK,
];

/**
 * Custom react hook to retrieve a sub tasks for an expert input from store
 * @param {} expertInput
 */
export const useExpertInputSubTasks = expertInput => {
  const todoTasks = useSelector(state => state.entities.tasks.byId);

  if (!expertInput) {
    return [];
  }

  return expertInput.tasks
    .filter(task => {
      if (task.id && todoTasks) {
        const todoTask = todoTasks[task.id];
        const taskType = todoTask ? todoTask.taskType.toLowerCase() : undefined;
        if (todoTask && !TASK_TYPES_TO_EXCLUDE_FROM_SUB_TASKS.includes(taskType)) {
          return true;
        }
      }
    })
    .map(task => todoTasks[task.id])
    .sort(task => (task.priority.toLowerCase() === 'normal' ? 1 : -1));
};
