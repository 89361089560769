import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { getGroupedSingleEvents, t } from '@formue-app/core';

import { SelectDropdown } from '../../formElements';
import { ReactComponent as LollipopIcon } from '../../../assets/icons/investment-simulator/lollipop-icon.svg';
import { Paragraph, ParagraphXSmall } from '../../texts';
import { Toggle } from '../../formElements';
import {
  SPACING_12,
  SPACING_16,
  SPACING_20,
  SPACING_8,
} from '../../../constants/spacing';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { SpriteIcon } from '../../common/SpriteIcon';
import { DotIcon } from '../../common/DotIcon';

const Container = styled.div`
  display: flex;
  position: relative;
  gap: ${SPACING_12};
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
`;

const EventsHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${SPACING_8};
`;

const EventTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_16};
`;

const YearLabel = styled.span`
  width: 84px;
  display: inline-block;
  font-weight: 500;
`;

export const ForecastFilters = (props) => {
  const {
    selectedCallback = () => {},
    singleEvents,
    yearlyEvents,
    loading,
    ...rest
  } = props;

  const strategies = useEnabledStrategies();
  const flexibleEvents = singleEvents.filter((event) => event.flexible);

  const [selected, setSelected] = useState({
    strategy: strategies[0],
    view: { value: null, label: null },
    events: [],
    timePointLabels: true,
  });

  useEffect(() => {
    selectedCallback(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const views = [
    {
      value: 'defaultView',
      label: t('presentation:investmentStrategy:forecast:defaultView'),
    },
    {
      value: 'deviationView',
      label: t('presentation:investmentStrategy:forecast:deviationView'),
    },
    {
      value: 'inflationAdjusted2',
      label: t('presentation:investmentStrategy:forecast:inflationAdjusted2'),
    },
    {
      value: 'inflationAdjusted4',
      label: t('presentation:investmentStrategy:forecast:inflationAdjusted4'),
    },
  ];

  const groupedSingleEvents = getGroupedSingleEvents(singleEvents);

  const events = [
    ...groupedSingleEvents.map(
      ({ id, name, from, to, group, flexible, ...rest }) => {
        return {
          id,
          value: id,
          label: () => (
            <EventTitle>
              {group ? (
                <YearLabel>{`${from}-${to}`}</YearLabel>
              ) : (
                <YearLabel>{from}</YearLabel>
              )}

              <Paragraph>{name}</Paragraph>
              {flexible && <LollipopIcon />}
            </EventTitle>
          ),
          title: () => (
            <EventTitle>
              <Paragraph>{name}</Paragraph>
              {flexible && <LollipopIcon style={{ marginLeft: 8 }} />}
            </EventTitle>
          ),
          group,
          ...rest,
        };
      }
    ),
    ...yearlyEvents.map(({ id, name }) => {
      return {
        id,
        value: id,
        label: () => (
          <EventTitle>
            <YearLabel>
              {t('presentation:investmentStrategy:forecast:yearly')}
            </YearLabel>
            <Paragraph>{name}</Paragraph>
          </EventTitle>
        ),
      };
    }),
  ];

  if (loading) return null;

  return (
    <Container {...rest}>
      <SelectDropdown
        subtitle={t('presentation:investmentStrategy:forecast:selectStrategy')}
        options={strategies.map((strategy) => ({
          ...strategy,
          label: strategy.title,
          value: strategy.id,
        }))}
        selectedCallback={(options) =>
          setSelected({ ...selected, strategy: options[0] })
        }
        icon={(item) => <DotIcon radius={8} color={item?.color} />}
        itemsIcon={(item) => <DotIcon radius={8} color={item?.color} />}
        style={{ marginRight: 12 }}
        itemsPadding={12}
      />
      <SelectDropdown
        subtitle={t('presentation:investmentStrategy:forecast:selectView')}
        options={views}
        selectedCallback={(options) =>
          setSelected({ ...selected, view: options[0] })
        }
        itemsIcon={() => <SpriteIcon id="Review" size={SPACING_20} />}
        style={{ marginRight: 12 }}
        itemsPadding={12}
      />
      <SelectDropdown
        options={events}
        selectedCallback={(options) =>
          setSelected({ ...selected, events: options })
        }
        selectMultiple={true}
        optionsBorder={false}
        optionsPadding={8}
        small={true}
        showAllText={t('presentation:investmentStrategy:common:showAllEvents')}
        title={t('presentation:investmentStrategy:forecast:events')}
        subtitle={t('presentation:investmentStrategy:common:editEvents')}
        headerContent={
          <EventsHeaderContent
            onClick={() => {
              setSelected({
                ...selected,
                timePointLabels: !selected.timePointLabels,
              });
            }}
          >
            <Paragraph>
              {t('presentation:investmentStrategy:common:showLabels')}
            </Paragraph>
            <Toggle
              id="show-time-point-labels"
              name="show-time-point-labels"
              value={selected.timePointLabels}
              onChange={() => {}}
              small={true}
            />
          </EventsHeaderContent>
        }
      >
        {flexibleEvents.length ? (
          <Legend>
            <LollipopIcon style={{ marginRight: 8 }} />
            <ParagraphXSmall>
              {t('presentation:investmentStrategy:common:flexible')}
            </ParagraphXSmall>
          </Legend>
        ) : null}
      </SelectDropdown>
    </Container>
  );
};
