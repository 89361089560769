import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  actions,
  createTodoSections,
  useExpertInputSubTasks,
  useResource,
} from '@formue-app/core';

import { PaddedBaseSection } from '../../common/BaseSection';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { GridElement } from '../../layout/GridElement';

import { H2, H6 } from '../../texts';

import { notMobileCondition } from '../../../constants/media';
import { accent } from '../../../constants/colors';

import { SectionList } from '../../lists';
import { ExpertInputSubtaskItem } from '../expertInputs';
import { ExpertInputDetailsHeader } from '../expertInputs';

const {
  ui: { readStatus: readActions },
} = actions;

const StyledListElement = styled(GridElement)`
  @media ${notMobileCondition} {
    grid-area: List;
  }
`;

const ListHeader = () => {
  const { t } = useTranslation();
  return (
    <H2 style={{ marginBottom: 11 }}>
      {t('todo:expertInput:recommendedSteps')}
    </H2>
  );
};

export const ExpertInputDetail = (props) => {
  const { id } = props;
  const loading = useResource(['EXPERT_INPUTS/INDEX']);

  const dispatch = useDispatch();
  dispatch(readActions.saveReadItem({ type: 'expertInputs', id }));

  const { t } = useTranslation();
  const item = useSelector((state) => state.entities.expertInputs.byId[id]);
  const subTasks = useExpertInputSubTasks(item);
  const sections =
    createTodoSections(subTasks, ['high', 'normal'], 'priority') || [];

  if (loading) {
    return (
      <GridElement columns="12">
        <CenteredActivityIndicator background={accent.sand1} />
      </GridElement>
    );
  }

  if (!item) {
    return null;
  }

  return (
    <>
      <ExpertInputDetailsHeader item={item} />
      {!!subTasks.length && (
        <StyledListElement columns={8}>
          <PaddedBaseSection>
            <SectionList
              sections={sections}
              renderItem={(item) => <ExpertInputSubtaskItem id={item.id} />}
              keyExtractor={(subTask, index) => `todo-${index}`}
              renderSectionHeader={({ section: { title, data, index } }) => {
                if (data.length) {
                  return (
                    <H6>{t(`todo:expertInput:subtasksTitles:${title}`)}</H6>
                  );
                }
              }}
              ListHeaderComponent={subTasks.length && <ListHeader />}
            />
          </PaddedBaseSection>
        </StyledListElement>
      )}
    </>
  );
};
