import React from 'react';
import styled from 'styled-components';
import { mobileCondition } from '../../../constants/media';
import { decodeHTML } from 'entities';

import {
  SPACING_16,
  SPACING_24,
  SPACING_48,
  SPACING_64,
  SPACING_32,
} from '../../../constants/spacing';
import { BaseSection } from '../../common/BaseSection';
import { Paragraph, H2 } from '../../texts';
import { GoalIcon } from './GoalIcon';

const StyledContainer = styled(BaseSection)`
  padding: ${SPACING_48} ${SPACING_64};

  @media ${mobileCondition} {
    padding: ${SPACING_32} ${SPACING_48};
  }
`;

const StyledTitle = styled(H2)`
  padding-bottom: ${SPACING_16};
  padding-top: ${SPACING_24};
`;

export const GoalList = (props) => {
  const { items } = props;

  if (!items.length) {
    return null;
  }

  return (
    <>
      {items.map((item, index) => (
        <StyledContainer columns={6} key={`goal-${index}`}>
          <GoalIcon goalType={item.goalType} />
          <StyledTitle>{decodeHTML(item.title)}</StyledTitle>
          <Paragraph>{item.description}</Paragraph>
        </StyledContainer>
      ))}
    </>
  );
};
