import { combineReducers } from 'redux';

import * as user from './user';
import * as expertInput from './expertInput';
import * as readStatus from './readStatus';
import * as recommendedDistribution from './recommendedDistribution';
import * as simulator from './simulator';
import * as signingEvents from './signingEvents';
import * as impersonation from './impersonation';
import * as tasks from './tasks';
import * as todo from './todo';
import * as portfolio from './portfolio';
import * as presentation from './presentation';
import * as wealthPlan from './wealthPlan';
import * as feed from './feed';
import * as qlik from './qlik';
import * as sustainability from './sustainability';
import * as flashMessages from './flashMessages';

const all = {
  user: user.reducer,
  expertInput: expertInput.reducer,
  recommendedDistribution: recommendedDistribution.reducer,
  simulator: simulator.reducer,
  readStatus: readStatus.reducer,
  signingEvents: signingEvents.reducer,
  tasks: tasks.reducer,
  impersonation: impersonation.reducer,
  todo: todo.reducer,
  portfolio: portfolio.reducer,
  presentation: presentation.reducer,
  wealthPlan: wealthPlan.reducer,
  feed: feed.reducer,
  qlik: qlik.reducer,
  sustainability: sustainability.reducer,
  flashMessages: flashMessages.reducer,
};

const combined = combineReducers(all);

// export an object containing all the reducers we want to
// merge in case a package using @formue-app/core wants to
// merge inn some of of their own reducers.
export const reducers = {
  all,
  combined,
};
