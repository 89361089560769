import React, { useState, useEffect } from 'react';
import { t } from '@formue-app/core';
import styled, { css } from 'styled-components';
import { accent, backgroundWhite } from '../../../../constants/colors';

import { H4 } from '../../../texts';
import { mobileCondition } from '../../../../constants/media';
import { H5Size } from '../../../../constants/text';
import { SPACING_12 } from '../../../../constants/spacing';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_12};
  flex-wrap: wrap;
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${(props) =>
    props.isSelectable &&
    css`
      cursor: pointer;
      padding: 10px 18px;
      border-radius: 25px;
      border: 1px solid ${props.selectedBorderColor};
    `}
  ${(props) =>
    props.selected &&
    props.isSelectable &&
    css`
      background: ${props.selectedBackgroundColor};
      cursor: default;
    `};

  ${(props) =>
    props.selectMultiple &&
    css`
      cursor: pointer;
    `}

  @media ${mobileCondition} {
    padding: 0px;
    border-width: 0px;
  }
`;

const StyledDot = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 6px;
  text-align: center;
`;

const StyledLabel = styled(H4)`
  color: ${accent.ocean2};

  @media ${mobileCondition} {
    font-size: ${H5Size};
  }

  ${(props) =>
    props.selected &&
    css`
      color: ${props.selectedTextColor} !important;
    `}
`;

export const ChartLegend = ({
  textColor = accent.velvet1,
  selectedTextColor = backgroundWhite,
  selectedBackgroundColor = accent.velvet4,
  selectedBorderColor = accent.velvet4,
  isSelectable = true,
  selectMultiple,
  selectedCallback = () => {},
  items = [],
  ...rest
}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (selectMultiple) {
      selectedCallback(selected);
    } else {
      selectedCallback(selected[0]);
    }
  }, [selected, selectedCallback, selectMultiple]);

  if (!items.length) return null;

  return (
    <StyledContainer {...rest}>
      {isSelectable && (
        <StyledItem
          onClick={() => {
            isSelectable && setSelected([]);
          }}
          selected={!selected.length}
          isSelectable={isSelectable}
          selectedBackgroundColor={selectedBackgroundColor}
          selectedBorderColor={selectedBorderColor}
        >
          <StyledLabel
            style={{ color: textColor }}
            selected={!selected.length}
            selectedTextColor={selectedTextColor}
          >
            {t('globals:seeAll')}
          </StyledLabel>
        </StyledItem>
      )}
      {items.map((item, index) => (
        <StyledItem
          onClick={() => {
            if (isSelectable) {
              if (selectMultiple) {
                if (selected.includes(item.key)) {
                  // Remove from selected if it already was selected (basically a "toggle functionality")
                  const filtered = selected.filter(
                    (selectedItem) => selectedItem !== item.key
                  );
                  setSelected(filtered);
                } else {
                  setSelected([...selected, item.key]);
                }
              } else {
                isSelectable && setSelected([item.key]);
              }
            }
          }}
          selected={selected.includes(item.key)}
          selectedBackgroundColor={selectedBackgroundColor}
          selectedBorderColor={selectedBorderColor}
          isSelectable={isSelectable}
          selectMultiple={selectMultiple}
          key={`chart-legend-item-${index}`}
        >
          <StyledDot style={{ backgroundColor: item.color }} />
          <StyledLabel
            style={{ color: textColor }}
            selected={selected.includes(item.key)}
            selectedTextColor={selectedTextColor}
          >
            {item.label}
          </StyledLabel>
        </StyledItem>
      ))}
    </StyledContainer>
  );
};
