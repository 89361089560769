import React from 'react';

import { contentColumnCount } from '../../constants/grid';
import { BaseSection } from '../common/BaseSection';
import { QvObject } from './QvObject';

const HEIGHT_CLASSES = {
  xlarge: '1000px',
  large: '500px',
  medium: '250px',
  small: '110px',
}

export const QvGridObject = props => {
  const {
    columns,
    heightClass,
    ...rest
  } = props;

  // A grid object can have a height "class", 
  let height = HEIGHT_CLASSES.medium;
  if (heightClass && Object.keys(HEIGHT_CLASSES).includes(heightClass)) {
    height = HEIGHT_CLASSES[heightClass];
  }

  return (
    <BaseSection columns={columns} maxColumns={contentColumnCount}>
      <QvObject {...rest} style={{ height }} />
    </BaseSection>
  );
};
