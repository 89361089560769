import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticatedSelector } from '../../store/auth/selectors';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({ render, ...rest }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  // Wait until we have a false or true value to determine if we should redirect
  if (isAuthenticated === null) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          render()
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
