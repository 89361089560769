export const generateOrganizationAccentColors = (length, accent) => {
  const colorsArray = [...Array(length)];
  const pickedAccentColors = [
    accent.raspberry1,
    accent.raspberry2,
    accent.raspberry3,
    accent.raspberry4,
    accent.raspberry190,
    accent.raspberry330,
  ];
  return colorsArray.map((item, index) => {
    const accentColorsLength = Object.keys(pickedAccentColors).length;
    const accentKey =
      Object.keys(pickedAccentColors)[index % accentColorsLength];
    return pickedAccentColors[accentKey];
  });
};

export const generateContributionChartAssetClassColor = (key, accent) => {
  let color;
  switch (key) {
    case 'S':
    case 'o':
    case 'b':
    case 'h':
      color = accent.velvet1;
      break;
    case 'MV':
    case 't':
    case 'g':
    case 'd':
      color = accent.velvet2;
      break;
    default:
      color = accent.velvet3;
      break;
  }
  return color;
};
