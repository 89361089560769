import React from 'react';
import styled from 'styled-components';

import { shortAnimationTime } from '../../constants/animation';
import { accent } from '../../constants/colors';
import { slideTypes } from './slideTypes';
import { investmentStrategySlides } from './investmentStrategySlides';
import { investmentStrategyExportSlides } from './investmentStrategyExportSlides';

import { SlideTitle } from './SlideTitle';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallbackPresentation } from '../common/ErrorFallbackPresentation';

const Slide = styled.section`
  min-width: 100%;
  min-height: 100%;
  position: relative;
  transition: linear background ${shortAnimationTime};
`;

const SlideContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SlideDetails = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  background-color: ${accent.sand1};
  // 64 is the height of the header on the details slide. 50 is the buffer to try to prevent
  // unintensional scrolling up with the snap scroll
  padding-top: calc(64px + 50px);
`;

export const BaseSlide = (props) => {
  const {
    component,
    detailsComponent,
    setShowDetails,
    customProps,
    customDetailsProps,
    showTitle,
    slideIndex,
    title,
    ...rest
  } = props;

  const { activeIndex } = rest;

  const renderSlideCondition =
    activeIndex >= slideIndex - 1 && activeIndex <= slideIndex + 1;

  const allSlides = {
    ...slideTypes,
    ...investmentStrategySlides,
    ...investmentStrategyExportSlides,
  };

  return (
    <Slide {...rest} className={`slide${props.isActiveSlide ? ' active' : ''}`}>
      {renderSlideCondition && (
        <>
          <SlideContent className="content">
            {showTitle && <SlideTitle titleKey={title} {...rest} />}
            <ErrorBoundary FallbackComponent={ErrorFallbackPresentation}>
              {allSlides[component]({
                title,
                ...rest,
                ...customProps,
              })}
            </ErrorBoundary>
          </SlideContent>

          {!!detailsComponent ? (
            <SlideDetails className="details">
              {allSlides[detailsComponent]({ ...rest, ...customDetailsProps })}
            </SlideDetails>
          ) : null}
        </>
      )}
    </Slide>
  );
};
