import React from 'react';

import { SPACING_48 } from '../../../constants/spacing';

import { Modal } from '../../common/Modal';
import { DateFilter } from './DateFilter';

export const PeriodFilterModal = ({ modalOpen, setModalOpen, onChange }) => {
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      contentWidth={830}
      padding={SPACING_48}
    >
      <DateFilter
        onRequestClose={() => setModalOpen(false)}
        onChange={onChange}
      />
    </Modal>
  );
};
