import React from 'react';

import { t } from '@formue-app/core';

import { H1, Paragraph, ParagraphLarge } from '../texts';
import { SPACING_24, SPACING_16 } from '../../constants/spacing';

import { GridElement } from '../layout';

import { SpriteIcon } from '../common/SpriteIcon';
import { Button } from '../buttons';

export const SubmittedPresentation = (props) => {
  const { setShowSharePanel } = props;

  return (
    <GridElement columns={12}>
      <H1 style={{ marginBottom: SPACING_24 }}>
        {t('presentation:share:submitTitle')}
      </H1>
      <ParagraphLarge style={{ marginBottom: SPACING_24 }}>
        {t('presentation:share:submitConfirmation')}
        <SpriteIcon
          id="Iteration"
          size={SPACING_24}
          style={{ verticalAlign: 'bottom', marginLeft: SPACING_16 }}
        />
      </ParagraphLarge>
      <Paragraph style={{ marginBottom: SPACING_24, maxWidth: 550 }}>
        {t('presentation:share:submitIntro')}
      </Paragraph>

      <Button
        onClick={() => {
          setShowSharePanel(false);
        }}
      >
        {t('presentation:share:close')}
      </Button>
    </GridElement>
  );
};
