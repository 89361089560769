import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  width: 24px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
`;

const StyledItem = styled.span`
  background: #fff;
  border-radius: 2px;
  height: 2px;
  margin: 3px 0;
  transition: 0.4s;
`;

const StyledItemFirst = styled(StyledItem)`
  width: 21px;
  ${(props) =>
    props.active &&
    `
    transform-origin:bottom;
    rotate: z 45deg;
    translate: 4px 6.5px;
  `}
`;

const StyledItemSecond = styled(StyledItem)`
  width: 12px;
  ${(props) =>
    props.active &&
    `
    transform-origin:top;
    rotate: z -45deg;
    opacity: 0;
  `}
`;
const StyledItemThird = styled(StyledItem)`
  width: 18px;
  ${(props) =>
    props.active &&
    `
    transform-origin:bottom;
    width:21px;
    translate: 0 -8.5px;
    rotate: z -45deg;
  `}
`;

export const HamburgerIcon = ({ active, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <StyledItemFirst className="hamburger-button-item" active={active} />
      <StyledItemSecond className="hamburger-button-item" active={active} />
      <StyledItemThird className="hamburger-button-item" active={active} />
    </StyledButton>
  );
};
