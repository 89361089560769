import { createSelector } from 'reselect';

import {
  allUsersSelector,
  usersByIdSelector,
} from '../../entities/selectors/users';

const impersonationIdSelector = state => state.ui.user.impersonationId;
const excludedSsids = state => state.ui.portfolio.filters.excludedSsids;

export const currentUserSelector = createSelector(
  allUsersSelector,
  usersByIdSelector,
  impersonationIdSelector,
  (allUsers, usersById, impersonationId) => {
    if (impersonationId) {
      return usersById[impersonationId];
    }

    // Default to the first user in the list if not impersonating
    if (!allUsers) {
      return null;
    }
    return allUsers[0];
  }
);

export const accessibleAccountsSelector = createSelector(
  currentUserSelector,
  user => {
    if (!user) {
      return null;
    }
    return user.accessibleAccounts;
  }
);

// Returns a list of ssids for accessible accounts that are excluded by the current
// excluded accounts filter.
export const excludedAccountIdsSelector = createSelector(
  excludedSsids,
  accessibleAccountsSelector,
  (excludedAccountsSsids, accessibleAccounts) => {
    if (!excludedAccountsSsids || !accessibleAccounts) return null;
    const accessibleAccountsSsids = accessibleAccounts.map(
      account => account.ssid
    );
    // Remove any excluded accounts that are not currently in the list of accessible accounts.
    // If we don't do this, you might end up with a list excluded accounts that is larger than your
    // list of accessible accounts since you might have lost access to some accounts since you
    // stored the default filter.
    return excludedAccountsSsids.filter(ssid =>
      accessibleAccountsSsids.includes(ssid)
    );
  }
);

export const selectedAccountsSelector = createSelector(
  accessibleAccountsSelector,
  excludedAccountIdsSelector,
  (accessibleAccounts, excludedAccountIds) => {
    if (!excludedAccountIds || !accessibleAccounts) return null;
    return accessibleAccounts.filter(
      account =>
        !excludedAccountIds.find(
          axcAccountSsid => axcAccountSsid === account.ssid
        )
    );
  }
);
