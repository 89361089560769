import React, { useEffect } from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  BORDER_RADIUS_LARGE,
  SPACING_64,
  SPACING_16,
  SPACING_8,
  SPACING_32,
} from '../../../constants/spacing';
import { accent, backgroundWhite } from '../../../constants/colors';

import { H3 } from '../../texts';
import { SpriteIcon } from '../../common/SpriteIcon';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';

const StyledConfirmationMessage = styled.div`
  display: flex;
  padding: 12px ${SPACING_64} 12px ${SPACING_16};
  background-color: ${backgroundWhite};
  align-items: center;
  border: 1px solid ${accent.forest3};
  border-radius: ${BORDER_RADIUS_LARGE};
  gap: ${SPACING_16};
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  position: absolute;
  right: ${SPACING_8};
  top: ${SPACING_8};
  cursor: pointer;
`;

const StyledTitle = styled(H3)`
  line-height: 100%;
`;

export const PresentationConfirmationMessage = ({
  closeCallback = () => {},
}) => {
  // Close this message automatically after 20s
  useEffect(() => {
    setTimeout(() => {
      closeCallback();
    }, 10000);
  });

  return (
    <StyledConfirmationMessage>
      <SpriteIcon id="OK" size={SPACING_32} />
      <StyledSpriteIconFunctional
        id="Close"
        size={16}
        onClick={() => {
          closeCallback();
        }}
      />
      <StyledTitle>
        {t('presentation:messages:confirmationMessage')}
      </StyledTitle>
    </StyledConfirmationMessage>
  );
};
