import React from 'react';
import styled from 'styled-components';
import { accent, textLightest } from '../../constants/colors';
import { GridElement } from '../layout';
import { H2Small, Paragraph } from '../texts';

import promotedTaskImageUrl from '../../assets/images/annual-review/report/promoted-task-summary.jpg';

import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_24,
  SPACING_8,
} from '../../constants/spacing';
import { DesktopWrapper, mobileCondition } from '../../constants/media';
import { ButtonSmall } from '../buttons';

const StyledGridElement = styled(GridElement)`
  background: ${accent.sand2};
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media ${mobileCondition} {
    flex-direction: column-reverse;
  }
`;

const Content = styled.div`
  flex: 2;
  padding: ${SPACING_48};

  @media ${mobileCondition} {
    padding: ${SPACING_24};
  }
`;

const Text = styled.div`
  width: 100%;
  max-width: 500px;
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${SPACING_24};
`;

const Image = styled.img`
  width: 31.9%;
  flex: 1;
  object-fit: cover;
  border-top-right-radius: ${BORDER_RADIUS_LARGE};
  border-bottom-right-radius: ${BORDER_RADIUS_LARGE};

  @media ${mobileCondition} {
    width: 100%;
    height: 100px !important;
    object-fit: cover;
    border-bottom-right-radius: 0;
    border-top-left-radius: ${BORDER_RADIUS_LARGE};
  }
`;

export const PromotedTasks = (props) => {
  const { tasks } = props;

  if (!tasks.length) {
    return null;
  }

  // Currently we only support one task, but in the future we need to support
  // several tasks in a carousel or something similar.
  const task = tasks[0];

  return (
    <StyledGridElement columns={12}>
      <Wrapper>
        <Content>
          <Text>
            <H2Small
              style={{
                color: accent.ocean4,
                marginBottom: SPACING_8,
              }}
            >
              {task.title}
            </H2Small>
            <StyledParagraph>{task.body}</StyledParagraph>

            {/*
              This has target set to null to prevent it opening in a new window.
              Opening links in a new window when in advisor mode looses which user
              we are impersonating and the advisor is then routed to the client select
              screen instead of the target of this task. So if we want/need to do this
              in advisor mode we should pass a long the impersonation id as well in the
              url somehow.
            */}
            <ButtonSmall
              buttoncolor={accent.raspberry480}
              buttoncolorhover={accent.raspberry4}
              textcolor={textLightest}
              to={task.to}
              external={true}
              target={null}
            >
              {task.navLabel}
            </ButtonSmall>
          </Text>
        </Content>
        <DesktopWrapper>
          <Image src={promotedTaskImageUrl} />
        </DesktopWrapper>
      </Wrapper>
    </StyledGridElement>
  );
};
