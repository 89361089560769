import { createSelector } from 'reselect';
import { groupBy } from 'lodash';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [familyAllIds, familyById, familySelector] =
  resourceSelectors('family');
export const [networkAllIds, networkById, networkSelector] =
  resourceSelectors('network');
export const [organizationAllIds, organizationById, organizationSelector] =
  resourceSelectors('organization');
export const [milestonesAllIds, milestonesById, milestonesSelector] =
  resourceSelectors('milestones');

export const familyMainMembersSelector = createSelector(familyById, (byId) => {
  const groupedFamilyMembers = groupBy(Object.values(byId), 'relationshipType');

  return groupedFamilyMembers;
});

export const familyAndOwnershipHasContentSelector = createSelector(
  networkSelector,
  milestonesSelector,
  organizationSelector,
  familySelector,
  (network, milestones, organization, family) => {
    return !![...network, ...milestones, ...organization, ...family].length;
  }
);
