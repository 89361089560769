import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { investmentStrategies } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer(
  'investment-strategies'
);

// Create the sagas for this entity
function* readInvestmentStrategies() {
  yield takeEvery(actions.indexRequestBegin, function* () {
    try {
      const result = yield call(investmentStrategies.get);
      const { meta } = result;

      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, meta }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

function* createInvestmentStrategy() {
  yield takeLatest(actions.createRequestBegin, function* ({ payload }) {
    const { args, data } = payload;

    try {
      const result = yield call(investmentStrategies.post, data);
      const normalized = normalize(result);
      yield put(actions.createRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.createRequestFailure({ error }));

      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [
  readInvestmentStrategies,
  createInvestmentStrategy,
  ...resourceSagas,
];
