import { useSelector } from 'react-redux';

import { selectors } from '../store';
import { manipulatedTasks } from '../store/entities/selectors/tasks';
import { makeFilterSelector } from './helpers';

const { openSelector, ongoingSelector, completedSelector } = selectors.ui.todo;

/**
 * Groups given list of items based on their status
 * @param {} items
 * @param {*} sections
 */
export const createTodoSections = (
  items,
  sections,
  groupingProp = 'status'
) => {
  let index = 0;

  return sections.map((section) => {
    const filteredItems = items.filter(
      (item) =>
        typeof item !== 'undefined' &&
        item[groupingProp] &&
        item[groupingProp].toLowerCase() === section
    );

    const object = {
      index,
      title: section,
      data: filteredItems,
    };

    // Only increase index if there are items as we dont show sub sections if items are empty
    if (filteredItems.length) {
      index++;
    }

    return object;
  });
};

/**
 * A custom hook to create the sections of todo items
 */
export const useTodoListSections = () => {
  return [
    {
      title: 'open',
      data: useSelector(openSelector),
    },
    {
      title: 'ongoing',
      data: useSelector(ongoingSelector),
    },
    {
      title: 'completed',
      data: useSelector(completedSelector),
    },
  ];
};

export const useTodoPresentationListSections = () => {
  return [
    {
      title: 'high',
      data: useSelector(
        makeFilterSelector(manipulatedTasks, 'high', false, 'priority')
      )
        .filter((item) => item.status !== 'Completed')
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    },
    {
      title: 'normal',
      data: useSelector(
        makeFilterSelector(manipulatedTasks, 'high', true, 'priority')
      )
        .filter((item) => item.status !== 'Completed')
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    },
    {
      title: 'completed',
      data: useSelector(
        makeFilterSelector(manipulatedTasks, 'completed', false, 'status')
      )
        .filter((item) => item.type !== 'signingEvents')
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    },
  ];
};
