import React from 'react';
import { NavLink as RouteNavLink } from 'react-router-dom';
import styled from 'styled-components';
import { easeInOut, shortAnimationTime } from '../../constants/animation';
import { accent, backgroundWhite } from '../../constants/colors';
import {
  desktopCondition,
  mobileCondition,
  notDesktopCondition,
  tabletCondition,
} from '../../constants/media';
import { BORDER_RADIUS_EXTRA_LARGE, SPACING_16 } from '../../constants/spacing';

import { StyledH5 } from '../texts';

const StyledNavLink = styled(RouteNavLink)`
  position: relative;
  display: inline-flex;
  width: 148px;
  height: 74px;
  justify-content: space-between;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${accent.sand1};
  text-decoration: none;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  cursor: pointer;
  transition: background-color ${shortAnimationTime} ${easeInOut};

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }

  @media ${desktopCondition} {
    &:last-child {
      margin-right: 0;
    }
  }

  @media ${tabletCondition} {
    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  &.active {
    background-color: ${(props) => props.backgroundColorActive};
    color: ${backgroundWhite};
    border-color: ${(props) => props.borderColorActive};
  }

  @media ${tabletCondition} {
    flex: 0 0 48%;
  }

  @media ${notDesktopCondition} {
    flex-direction: row;
    align-items: center;
    padding: 5px 14px;
    height: auto;
    justify-content: flex-start;
  }

  @media ${mobileCondition} {
    padding: 0 ${SPACING_16};
    margin: 0 ${SPACING_16} 0 0;
    width: auto;
  }

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.3;
    `}
`;

const StyledIconWrapper = styled.div`
  margin: 4px 0 0 ${SPACING_16};
  width: 15px;
  height: 15px;

  > svg {
    width: 100%;
    height: 100%;
  }
  @media ${notDesktopCondition} {
    padding: 0;
    margin-right: 18px;
  }

  @media ${mobileCondition} {
    width: 24px;
  }
`;

const StyledText = styled(StyledH5)`
  padding: ${SPACING_16};
  padding-top: 16px;

  @media ${notDesktopCondition} {
    padding: 0;
  }

  @media ${mobileCondition} {
    word-break: normal;
    padding-bottom: 3px;
  }

  ${(props) => props.isActive && `color: ${backgroundWhite}`}
`;

export const SubNavigationItem = (props) => {
  const {
    title,
    path,
    icon,
    showInMenu = true,
    disabled,
    backgroundColor = 'transparent',
    borderColor = accent.sand370,
    itemTextColor,
    itemTextColorActive,
    backgroundColorActive,
    borderColorActive,
    hoverColor,
  } = props;
  const isActive = window.location.pathname === path;

  if (!showInMenu) return null;

  const handleClick = (e) => {
    if (disabled) e.preventDefault();
  };

  return (
    <StyledNavLink
      exact={true}
      to={path}
      activeClassName="active"
      disabled={disabled}
      onClick={handleClick}
      backgroundColorActive={backgroundColorActive}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderColorActive={borderColorActive}
      hoverColor={hoverColor}
    >
      <StyledIconWrapper>{icon}</StyledIconWrapper>
      <StyledText
        isActive={isActive}
        style={{ color: isActive ? itemTextColorActive : itemTextColor }}
      >
        {title}
      </StyledText>
    </StyledNavLink>
  );
};
