import React from 'react';
import styled from 'styled-components';

import { accent } from '../../../constants/colors';
import { H5Size } from '../../../constants/text';
import { H5 } from '../../texts';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 32px;
  padding: 5px 10px 7px 10px;
  border: 1px solid ${accent.sand2};
  background-color: ${accent.sand260};
  cursor: pointer;
`;

const Label = styled(H5)`
  line-height: ${H5Size};
  font-weight: 400;
  margin-right: 5px;
`;

export const FilterButton = ({ label, onClick, ...rest }) => {
  return (
    <Container onClick={onClick} {...rest}>
      <Label>{label}</Label>
    </Container>
  );
};
