import React from 'react';
import styled from 'styled-components';

import { selectors } from '@formue-app/core';

import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';
import { InvestmentPreferences } from '../../wealthPlan/investmentProfile/InvestmentPreferences';
import { MainGrid } from '../../layout';
import { useSelector } from 'react-redux';

const {
  entities: {
    investmentProfile: { investmentProfileHasContent },
  },
} = selectors;

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

export const SustainabilityPreferencesDetails = (props) => {
  const hasContent = useSelector(investmentProfileHasContent);

  return (
    <Wrapper>
      {hasContent && <InvestmentPreferences style={{ maxWidth: 'auto' }} />}
    </Wrapper>
  );
};
