import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  t,
  combineFamilyGroups,
  selectors,
  sortByFamilyhierarchy,
  constructEmploymentString,
} from '@formue-app/core';

import { accent } from '../../../../constants/colors';
import {
  BORDER_RADIUS_SMALL,
  SPACING_32,
  SPACING_24,
  SPACING_64,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { H2Small, H4, ParagraphXSmall } from '../../../texts';
import {
  easeInOut,
  extraLongAnimationTime,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';

import { ProfileIcon } from '../../../common/ProfileIcon';

const FamilyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_32};
`;

const FamilyGroup = styled.div`
  position: relative;
  left: 50px;
  opacity: 0;
  transition: all ${easeInOut} ${extraLongAnimationTime};
  transition-delay: ${(props) => `${slideAnimationDelay + props.index * 0.2}s`};

  ${(props) =>
    props.isActiveSlide &&
    `
    left: 0;
    opacity: 1;
  `}
`;

const FamilyInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${SPACING_16};
`;

const StyledFamilyItem = styled.div`
  padding: ${SPACING_24};
  padding-top: ${SPACING_32};
  padding-bottom: ${SPACING_16};
  border: 2px solid ${(props) => props.color};
  border-radius: ${BORDER_RADIUS_SMALL};
  position: relative;
  width: calc((100% / 3) - (16px * 2 / 3));
  cursor: pointer;

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        border-color: ${(props) => props.hoverColor};

        & > div {
          background: ${(props) => props.hoverColor};
        }
      }
    `}

  ${(props) =>
    props.active &&
    `
    background-color: ${(props) => props.color};
  `}

  &:nth-child(-n+3) {
    margin-top: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
`;

const StyledProfileIcon = styled(ProfileIcon)`
  position: absolute;
  left: 50%;
  top: 0;
  translate: -50% -50%;

  p {
    color: ${(props) => props.textColor};
  }
`;

const StyledTitle = styled(H2Small)`
  position: relative;
  color: ${accent.raspberry250};
  margin-bottom: ${SPACING_32};
  left: 25px;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime};

  ${(props) =>
    props.isActiveSlide &&
    css`
      left: 0;
      opacity: 1;
    `}
`;

const {
  entities: {
    familyAndOwnership: { familySelector, familyMainMembersSelector },
  },
} = selectors;

export const FamilyItem = (props) => {
  const {
    item,
    active,
    color = accent.raspberry1,
    hoverColor = accent.raspberry190,
    textColor = accent.raspberry250,
    ...rest
  } = props;

  return (
    <StyledFamilyItem color={color} hoverColor={hoverColor} {...rest}>
      <StyledProfileIcon
        name={item.name}
        color={color}
        textColor={textColor}
        size={31}
        fontSize={13}
        style={{
          border: active && `2px solid ${color}`,
        }}
      />
      <H4
        style={{
          color: textColor,
          textAlign: 'center',
        }}
      >
        {item.name}
      </H4>
      {active && (item.birthDate || item.employment) && (
        <ParagraphXSmall
          style={{
            color: textColor,
            marginTop: SPACING_8,
          }}
        >
          {item.birthDate
            ? t('wealthPlan:familyAndOwnership:family:birthdate', {
                year: new Date(item.birthDate).getFullYear(),
              })
            : null}
          {item.birthDate && item.employment ? ' | ' : null}
          {item.employment ? constructEmploymentString(item.employment) : null}
        </ParagraphXSmall>
      )}
    </StyledFamilyItem>
  );
};

export const Family = (props) => {
  const { isActiveSlide } = props;

  const familyContent = useSelector(familySelector);
  const familyMembers = useSelector(familyMainMembersSelector);

  const [activeFamilyMember, setActiveFamilyMember] = useState(null);

  if (!familyContent.length) {
    return null;
  }

  return (
    <>
      <StyledTitle isActiveSlide={isActiveSlide}>
        {t('presentation:annualReview:familyAndOwnership:familyTitle')}
      </StyledTitle>
      <FamilyWrapper style={{ marginBottom: SPACING_64 }}>
        {Object.keys(combineFamilyGroups(familyMembers))
          .sort(sortByFamilyhierarchy)
          .map((key, familyMembersIndex) => (
            <FamilyGroup
              key={`familymembers-${familyMembersIndex}-${key}`}
              index={familyMembersIndex}
              isActiveSlide={isActiveSlide}
            >
              <FamilyInner>
                {familyMembers[key].map((item, familyMemberIndex) => (
                  <FamilyItem
                    index={familyMemberIndex}
                    key={`familymember-${key}-${item.id}`}
                    onClick={() =>
                      setActiveFamilyMember(
                        activeFamilyMember === item.id ? null : item.id
                      )
                    }
                    active={activeFamilyMember === item.id}
                    item={item}
                  />
                ))}
              </FamilyInner>
            </FamilyGroup>
          ))}
      </FamilyWrapper>
    </>
  );
};
