import React from 'react';
import styled, { css } from 'styled-components';

import { truncateString, percentage } from '@formue-app/core';

import { ParagraphSmall, Paragraph } from '../../../texts';

import {
  SPACING_32,
  SPACING_24,
  SPACING_16,
} from '../../../../constants/spacing';
import {
  accent,
  primary,
  textLightest,
  textLink,
} from '../../../../constants/colors';
import { Link } from 'react-router-dom';
import {
  easeInOut,
  shortAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';

const TopThreeListWrapper = styled.div`
  padding: ${SPACING_24};
  padding-bottom: ${SPACING_32};
`;

const Title = styled(Paragraph)`
  color: ${(props) => props.titleColor};
  font-weight: 500;
  margin-bottom: ${SPACING_16};
  opacity: 0;
  top: 8px;
  position: relative;
  transition: all ${easeInOut} ${shortAnimationTime};
  transition-delay: ${slideAnimationDelay * 2}s;

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      top: 0;
    `}
`;

const TopThreeListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};
  margin-bottom: 12px;
  opacity: 0;
  top: 8px;
  position: relative;
  transition: all ${easeInOut} ${shortAnimationTime};
  transition-delay: ${(props) =>
    slideAnimationDelay * 2 + 0.2 * props.index + 0.4}s;

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      top: 0;
    `}
`;

const StyledNumber = styled.div`
  display: flex;
  background: ${accent.velvet3};
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
`;

const NumberText = styled(ParagraphSmall)`
  text-align: center;
  color: ${textLightest};
  font-weight: bold;
  font-size: 11px;
  line-height: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.linkColor};

  p {
    color: ${(props) => props.linkColor};
    margin-top: ${SPACING_16};
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const PortfolioConstructionList = (props) => {
  const {
    data,
    title,
    titleColor = primary,
    textColor = primary,
    numberColor = primary,
    numberBackgroundColor,
    sectionBackground,
    linkColor = textLink,
    linkText,
    to = 'Home',
    active = true,
    ...rest
  } = props;

  return (
    <TopThreeListWrapper
      style={{ backgroundColor: sectionBackground }}
      {...rest}
    >
      {title && (
        <Title titleColor={titleColor} active={active}>
          {title}
        </Title>
      )}
      <div>
        {data.map((item, index) => (
          <TopThreeListItem
            key={item.id}
            active={active}
            index={index}
            className="list-item"
          >
            <StyledNumber style={{ backgroundColor: numberBackgroundColor }}>
              <NumberText style={{ color: numberColor }}>
                {index + 1}
              </NumberText>
            </StyledNumber>
            <ParagraphSmall
              style={{
                color: textColor,
              }}
            >
              {percentage(item.partOfPortfolio * 100, 2)}
            </ParagraphSmall>
            <ParagraphSmall style={{ color: textColor }}>
              {truncateString(item.name, 28)}
            </ParagraphSmall>
          </TopThreeListItem>
        ))}
      </div>
      {linkText && (
        <StyledLink to={to} linkColor={linkColor}>
          <Paragraph style={{ fontWeight: 400 }}>{linkText}</Paragraph>
        </StyledLink>
      )}
    </TopThreeListWrapper>
  );
};
