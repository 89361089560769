import React from 'react';
import styled from 'styled-components';

import { formatMoney, sortByAmount } from '@formue-app/core';
import { accent } from '../../../constants/colors';
import { SPACING_16 } from '../../../constants/spacing';

import { H4 } from '../../texts';
import { DotIcon } from '../../common/DotIcon';

const StyledContainer = styled.div`
  ${(props) =>
    props.border &&
    `
    border-top: 1px;
    border-color: ${accent.sand1};
    border-top-style: solid;`}

  display: flex;
  gap: ${SPACING_16};
  flex-direction: column;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${SPACING_16};
`;

export const ListEntries = ({ entries, border = true, ...rest }) => {
  if (!entries) return null;
  return (
    <StyledContainer border={border} {...rest}>
      {entries.sort(sortByAmount).map((entry, index) => (
        <StyledRow key={index}>
          {entry.color ? <DotIcon color={entry.color} /> : null}
          <H4>{entry.name}</H4>
          <H4 style={{ whiteSpace: 'nowrap' }}>{formatMoney(entry.amount)}</H4>
        </StyledRow>
      ))}
    </StyledContainer>
  );
};
