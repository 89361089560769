import { createSelector } from 'reselect';

import { resourceSelectors } from '../../utils/resourceSelectors';
import { periodToDate } from '../../../services';

export const [
  portfolioLensesAllIds,
  portfolioLensesById,
  rawAllPortfolioLensesSelector,
  portfolioLensesMetadataSelector,
] = resourceSelectors('portfolioLenses');

// We want to exclude the items with missing data
// (or where the data is only partialy available)
// "carbonFootprint" and "impliedRise" values are proven to be good
// indicators of whether data is complete for a given record.
export const allPortfolioLensesSelector = createSelector(
  rawAllPortfolioLensesSelector,
  (items) => {
    return items.filter(
      (item) =>
        item.carbonFootprint.carbonFootprint && item.temperature.impliedRise
    );
  }
);

export const portfolioLensesTimelineSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    return items.map((item) => ({
      date: periodToDate(item.period),
      ...item,
    }));
  }
);

export const carbonFootprintTimelineSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    return items.map(({ id, period, carbonFootprint }) => ({
      id,
      period,
      date: periodToDate(period),
      value: carbonFootprint.carbonFootprint,
    }));
  }
);

export const carbonFootprintDataSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    // Last item in a collection should represent the "current value"
    return items[items.length - 1]?.carbonFootprint;
  }
);

export const temperatureRiseTimelineSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    return items.map(({ id, period, temperature }) => ({
      id,
      period,
      date: periodToDate(period),
      value: temperature.impliedRise,
    }));
  }
);

export const temperatureRiseDataSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    // Last item in a collection should represent the "current value"
    return items[items.length - 1]?.temperature;
  }
);

export const fundSustainabilityDataSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    // Last item in a collection should represent the "current value"
    return items[items.length - 1]?.fundSustainabilityClassification;
  }
);

export const socialResponsibilityDataSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    // Last item in a collection should represent the "current value"
    return items[items.length - 1]?.socialResponsibility;
  }
);

export const socialResponsibilityTimelineSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    return items
      .filter((item) => item.socialResponsibility.globalCompactCompliant)
      .map(({ id, period, socialResponsibility }) => ({
        id,
        period,
        date: periodToDate(period),
        ...socialResponsibility,
      }));
  }
);

export const scienceBasedShareDataSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    // Last item in a collection should represent the "current value"
    return items[items.length - 1]?.scienceBasedShare;
  }
);

export const scienceBasedShareTimelineSelector = createSelector(
  allPortfolioLensesSelector,
  (items) => {
    return items
      .filter((item) => item.scienceBasedShare.target)
      .map(({ id, period, scienceBasedShare }) => ({
        id,
        period,
        date: periodToDate(period),
        value: scienceBasedShare.target,
      }));
  }
);
