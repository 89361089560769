import React from 'react';
import styled from 'styled-components';

import { Paragraph, ParagraphLarge } from '../../texts';
import { UnorderedList } from '../../common/UnorderedList';
import { BaseSection } from '../../common/BaseSection';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_32,
  SPACING_4,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { Breadcrumbs } from '../../common/Breadcrumbs';

const Container = styled(BaseSection)`
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${accent.sand150};
  padding: ${SPACING_32};
`;

const Content = styled.div`
  width: 70%;
`;

const Heading = styled(ParagraphLarge)`
  padding-top: ${SPACING_4};
  padding-bottom: ${SPACING_16};
  font-weight: 600;
`;

const StyledParagraph = styled(Paragraph)`
  padding-bottom: ${SPACING_12};
`;

export const TermsAndConditionsSE = () => (
  <>
    <Breadcrumbs
      items={[
        { title: '1. Bakgrund' },
        { title: '2. Portföljen' },
        { title: '3. Ramar för förvaltningen' },
        { title: '4. Avkastning' },
        { title: '5. Risker' },
        { title: '6. Ersättning' },
        { title: '7. Delegering' },
        { title: '8. Ytterligare' },
        { title: '9. Varaktighet' },
      ]}
      style={{ margin: `${SPACING_16} 0` }}
      itemsSpacing={4}
    />
    <Container>
      <Content>
        <Heading>1. Bakgrund och uppdrag</Heading>

        <StyledParagraph>
          Syftet med detta avtal (nedan kallat ”Portföljförvaltningsavtalet”) är
          att reglera villkoren för tjänsten portföljförvaltning som Formue
          Sverige AB (”Formue”) tillhandahåller Kunden. Kunden och Formue
          benämns tillsammans ”Parterna” och enskilt ”Part”. Detta avtal
          ersätter eventuella tidigare Portföljförvaltningsavtal mellan
          Parterna.
        </StyledParagraph>
        <StyledParagraph>
          Portföljförvaltningsavtalet är utarbetat som ett tillägg till
          Kundavtalet och skall läsas tillsammans med Kundavtalets allmänna
          villkor.
        </StyledParagraph>
        <StyledParagraph>
          Kunden ger Formue en exklusiv rätt att, på diskretionär basis,
          förvalta en närmare angiven del av Kundens portfölj
          (”Förvaltningsportföljen”) som definieras i bilaga 1. Genom
          Portföljförvaltningsavtalet uppdras Formue att löpande, utan
          föregående godkännande från Kunden, för Kundens räkning fatta
          förvaltningsbeslut och genomföra dessa i Förvaltningsportföljen. Vid
          utförandet av förvaltningen ska Formue tillämpa den strategi för
          Förvaltningsportföljen som överenskommits med Kunden och som Formue
          bedömt vara lämplig för Kunden utifrån dennes ekonomiska situation och
          andra omständigheter.
        </StyledParagraph>
        <StyledParagraph>
          Genom Portföljförvaltningsavtalet ger Kunden Formue fullmakt att vid
          utförandet av förvaltningen för Kundens räkning vidta följande
          åtgärder:
        </StyledParagraph>
        <UnorderedList
          items={[
            'ta del av information om tillgångarna ingående i Förvaltningsportföljen,',
            'fatta förvaltningsbeslut och självständigt köpa, teckna, sälja eller på annat sätt disponera finansiella instrument i Förvaltningsportföljen, samt',
            'vidta andra därtill hörande åtgärder, såsom men inte uteslutande ingå avtal och underteckna andra handlingar, som enligt Formues egna bedömning är nödvändiga för förvaltningen enligt Portföljförvaltningsavtalet.',
          ]}
        />
        <StyledParagraph>
          Formue ska på begäran ge Kunden information om de åtgärder som
          vidtagits för Kundens räkning inom ramen för
          Portföljförvaltningsavtalet, inkludera information om avtal som
          ingåtts.
        </StyledParagraph>
        <StyledParagraph>
          Kunden har inte rätt att under Portföljförvaltningsavtalets
          giltighetstid själv förfoga över de tillgångar som ingår i
          Förvaltningsportföljen. Önskar Kunden disponera tillgångarna ska
          Formue först underrättas därom.
        </StyledParagraph>
        <StyledParagraph>
          Formue ska utföra förvaltningen avseende Förvaltningsportföljen inom
          de ramar som fastställs i punkt 3 i detta Portföljförvaltningsavtal.
          Kunden åtar sig att informera Formue om ändringar av Kundens
          ekonomiska situation och andra omständigheter som kan påverka
          förmögenhetsplanen och de enligt Portföljförvaltningsavtalet
          fastställda ramarna för förvaltningen.
        </StyledParagraph>

        <Heading>2. Portföljen</Heading>
        <StyledParagraph>
          I Förvaltningsportföljen får följande finansiella instrument ingå:
          andelar i värdepappersfonder (s.k. UCITS-fonder, Undertakings for the
          Collective Investment of Transferable Securities) och alternativa
          investeringsfonder (AIF), likvida medel samt övriga finansiella
          instrument i Formues depå som uppdragsgivaren inte vill ska säljas.
        </StyledParagraph>
        <StyledParagraph>
          Alla fonder som används ska ha hemvist inom EES-området och under
          tillsyn av en EES tillsynsmyndighet. Förvaltningsportföljen ska bestå
          av finansiella instrument inom varje tillgångsslag som rekommenderas
          av Förvaltaren.
        </StyledParagraph>
        <StyledParagraph>
          Ändringar kan göras av finansiella instrument i förvaltningsportföljen
          för att överensstämma med förvaltarens rekommenderade
          investeringsuniversum. Rekommenderade produkter är fonder utvalda av
          Formue som säkerställer kvaliteten på investeringarna.
        </StyledParagraph>
        <StyledParagraph>
          Förvaltningsportföljen kommer att bestå av produkter som rekommenderas
          av förvaltaren och kommer därmed att följa Förvaltarens
          investeringsstrategi relaterat till hållbarhet. Portföljavtalet kommer
          att förvaltas så att det främjar hållbara fastigheter i enlighet med
          Public Disclosure Regulation (SFDR) Artikel 8. Ytterligare information
          om denna investeringsmetod följer i bilagan Artikel 8 Information.
        </StyledParagraph>
        <StyledParagraph>
          Förvaltares rekommenderade produktutbud kan inkludera fonder med
          investeringsstrategier inom tillgångsklasserna Private Equity och
          Fastighet. Dessa investeringar kommer att genomföras genom AIF-fonder
          vilka kan sakna rätt till inlösen.
        </StyledParagraph>
        <StyledParagraph>
          Portföljförvaltningsavtalet kan avse tillgångar som förvaras för
          Kundens räkning inom ramen för en Formue ISK, Kapitalförsäkring eller
          Formue depå. I det fall Kunden uppdragit åt Formue att förvalta flera
          förvaltningsmandat ska en portfölj- och strategibilaga upprättas per
          mandat.
        </StyledParagraph>
        <StyledParagraph>
          Inför Formues påbörjande av förvaltningen enligt
          Portföljförvaltningsavtalet kan Kunden ha en existerande portfölj med
          likvida medel och finansiella instrument (”Ingångsportföljen”).
          Investeringar för pensionssparande hos externa plattformar som
          hanteras av Formue Sverige AB, benämns ”Pensionsportföljen” och
          omfattas inte av detta avtal.
        </StyledParagraph>
        <StyledParagraph>
          Övriga värdepapper eller bankbehållningar, benämns
          ”Rapporteringsportföljen” och omfattas inte av detta avtal.
          Förvaltningsportföljen, Pensionsportföljen och Rapporteringsportföljen
          utgör tillsammans Kundens totalportfölj (”Totalportföljen”).
        </StyledParagraph>

        <Heading>3. Ramar för förvaltningen</Heading>
        <StyledParagraph>
          Ramarna för förvaltningen av Förvaltningsportföljen utgår ifrån
          Kundens strategi som är fastställd i dokumentet Investeringsstrategi
          som medföljer Förmögenhetsplanen. Detta dokument sammanfattar Kundens
          mål, behov och riskpreferenser och finns tillgängligt på kundportalen
          och i Formue-appen.
        </StyledParagraph>
        <StyledParagraph>
          Kundens strategi beskrivs i Förmögenhetsplanen och tar hänsyn till
          alla investeringar i Kundens Totalportfölj. De tillgångar som ingår i
          förvaltningen enligt detta Portföljförvaltningsavtal har en separat
          strategi och ramar som beskrivs i dokumentet Investeringsstrategi.
          Strategierna kan vara olika, beroende på innehållet i rapporterings- /
          pensionsportfölj och uppdragsgivarens önskemål.
        </StyledParagraph>
        <StyledParagraph>
          I samband med handel kan allokeringen till tillgångsklassen
          penningmarknad dock vara högre än övriga ramar och allokeringen till
          övriga tillgångsklasser kan tillfälligt vara lägre än de nedre
          ramarna.
        </StyledParagraph>
        <StyledParagraph>
          Efter att avtal om portföljförvaltning ingåtts kommer Förvaltaren i
          regel bruka upp till 6 månader för att implementera ändringar i den
          likvida delen av portföljen. Kundens portfölj kommer under denna
          period kunna avvika från strategin som beskrivs i
          Investeringsstrategin och beskrivs närmre i det dokumentet under
          avsnittet implementering.
        </StyledParagraph>
        <StyledParagraph>
          I de fall Investeringsstrategin inkluderar allokering mot
          tillgångsslagen Private Equity och Fastigheter som är långsiktiga
          investeringar kommer Implementeringsperioden av dessa tillgångsslag
          vara upp till flera år. I denna fas kommer det allokeringen av
          portföljen avvika mot uppsatta ramar. Anpassning av kundens strategi
          görs i de tillfällen det på kort sikt inte är möjligt att hålla
          portföljen inom ramarna och kan härledas till att likviditeten i
          Fastigheter och Private Equity fördelas mellan övriga
          tillgångsklasser.
        </StyledParagraph>
        <StyledParagraph>
          I samband med att Kunden genomför stora insättningar eller uttag av
          likvida medel alternativt vid en avveckling av Förvaltningsportföljen
          kan fastställda ramar komma att över- eller underskridas.
        </StyledParagraph>
        <StyledParagraph>
          Även i andra fall än ovan kan fastställda ramar för förvaltningen
          tillfälligt komma att över- eller underskridas, t.ex. vid snabba
          kursförändringar avseende de tillgångar som ingår i
          Förvaltningsportföljen eller andra omständigheter som Formue inte
          råder över. Vid överträdelse av de fastställda ramarna ska Formue
          genomföra omallokeringar i Förvaltningsportföljen för att återställa
          denna till en sammansättning som ligger inom ramarna så snart Formue
          finner det lämpligt med hänsyn till förhållandena i det enskilda
          fallet.
        </StyledParagraph>

        <Heading>4. Avkastning – insättning – uttag</Heading>
        <StyledParagraph>
          All avkastning läggs till i Förvaltningsportföljen.
        </StyledParagraph>
        <StyledParagraph>
          Formue ska informera Kunden om det sammanlagda värdet av
          Förvaltningsportföljen, enligt värderingen i början av varje
          rapporteringsperiod, minskat med 10 procentenheter och därefter vid
          multiplar om 10 procentenheter. Kunden ska informeras senast i slutet
          av den affärsdag då tröskelvärdet för rapporteringen har överskridits
          eller, om tröskelvärdet har överskridits på en ej affärsdag, senast i
          slutet av närmast följande affärsdag.
        </StyledParagraph>
        <StyledParagraph>
          Avkastningen i Förvaltningsportföljen mäts och rapporteras mot ett
          relevant referensvärde som överenskommes mellan Kunden och Formue och
          som anges i förmögenhetsplanen.
        </StyledParagraph>
        <StyledParagraph>
          Om Kunden vill sätta in ytterligare likvida medel för förvaltning i
          Förvaltningsportföljen skickar Kunden ett meddelande till Kundens
          personliga rådgivare, eller via andra etablerade kommunikationskanaler
          som nämns i Formues allmänna affärsvillkor, med önskat belopp.
        </StyledParagraph>
        <StyledParagraph>
          Om Kunden vill göra uttag från Förvaltningsportföljen ska Kunden
          skicka ett meddelande om önskat uttagsbelopp till Kundens personliga
          rådgivare, eller via andra etablerade kommunikationskanaler som nämns
          i Formues allmänna affärsvillkor. Utbetalningar görs när Formue har
          tagit emot inlösenlikvid för inlöst(a) fondandelar. Formue ska inte
          hållas ansvariga om önskat uttagsbelopp överskrider det realiserbara
          värdet avseende tillgångarna i Förvaltningsportföljen alternativt om
          inlösen inte kan verkställas inom begärd tidsperiod till följd av
          inlösenvillkor för fonder ingående i Förvaltningsportföljen
          alternativt andra omständigheter som ligger utanför Formues kontroll.
          Utbetalning görs till Kundens registrerade utbetalningskonto.
        </StyledParagraph>

        <Heading>5. Risker</Heading>
        <StyledParagraph>
          Investeringar i finansiella instrument är förknippade med risk och en
          investering kan både öka och minska i värde. Historisk avkastning
          eller värdeökning är ingen garanti för framtida avkastning eller
          värdeökning. Detta gäller även vid en i övrigt positiv utveckling på
          de finansiella marknaderna. Det kapital som placeras i
          portföljförvaltning kan därför såväl öka som minska i värde och det är
          inte säkert att hela det insatta kapitalet är intakt vid
          förvaltningsperiodens slut.
        </StyledParagraph>
        <StyledParagraph>
          Kunden bekräftar att Kunden är medveten om de risker som är förenade
          med investeringar i finansiella instrument. Kunden bekräftar vidare
          att Kunden är medveten om att de förvaltningsbeslut avseende
          Förvaltningsportföljen som fattas och genomförs av Formue sker
          uteslutande på Kundens fulla risk.
        </StyledParagraph>
        <StyledParagraph>
          Formue ska under inga omständigheter hållas ansvariga för
          värdeminskning avseende tillgångarna i Förvaltningsportföljen
          hänförlig till negativ utveckling på de finansiella marknaderna.
        </StyledParagraph>
        <StyledParagraph>
          När Förvaltningsportföljen innehåller en allokering till
          tillgångsklasserna Private Equity och/eller Fastighet kommer denna att
          vara exponerad mot högre likviditetsrisk. Investeringar i dessa
          tillgångsklasser består vanligtvis av dels en första insättning, dels
          ett åtagande att tillskjuta ytterligare kapital när underliggande
          investeringar ska genomföras. Eventuell betydande inlösen av likvida
          tillgångar i Förvaltningsportföljen som genomförts på Kundens begäran
          kan komma att medföra bristande förmåga att fullfölja åtagandet om att
          tillskjuta kapital med enbart befintliga tillgångar i
          Förvaltningsportföljen. Kunden bär ansvaret för att fullfölja
          åtaganden om att tillskjuta kapital till investeringar i
          tillgångsklasserna Private Equity och Fastighet, även i de fall sådana
          investeringar genomförts av Formue för Kundens räkning inom ramen för
          Portföljförvaltningsavtalet.
        </StyledParagraph>

        <Heading>6. Ersättning</Heading>
        <StyledParagraph>
          Ersättning till Formue för förvaltningen enligt
          Portföljförvaltningsavtalet utgår enligt det som framgår i
          Kundavtalet. Produkter som ingår i Förvaltningsportföljen kommer att
          ha relaterade produktkostnader som belastas av produktleverantörerna.
          Dessa kostnader kan komma att variera över tid, antingen som en följd
          av kostnaderna i själva fonderna eller av ett byta av fonder i
          Förvalningsportföljen.
        </StyledParagraph>

        <Heading>7. Delegering</Heading>
        <StyledParagraph>
          Formue äger rätt att vid fullgörandet av förvaltningen enligt
          Portföljförvaltningsavtalet anlita tredje part för att utföra del av
          uppdraget för Formues räkning, innefattande, men inte begränsat till,
          förvaltningstjänster, rapportering och orderhantering.
        </StyledParagraph>

        <Heading>8. Ytterligare ansvarsbegränsningar</Heading>
        <StyledParagraph>
          Formue ansvarar inte för skada som uppkommer vid utförandet av
          förvaltningsuppdraget till följd av försenad kursinformation från
          tredje part, uppskjutna och/eller inställda handelsdagar eller andra
          sådana omständigheter som ligger utanför Formues kontroll.
        </StyledParagraph>
        <StyledParagraph>
          Formue ansvarar inte heller för skada som förorsakats av förvaltare av
          eller leverantör till de finansiella instrument som Formue investerar
          i för Kundens räkning inom Förvaltningsportföljen.
        </StyledParagraph>

        <Heading>9. Varaktighet och uppsägning – ändringar</Heading>
        <StyledParagraph>
          Portföljförvaltningsavtalet träder i kraft vid undertecknandet och
          gäller tills dess att det sägs upp skriftligen av en av Parterna.
          Kunden har rätt att säga upp Portföljförvaltningsavtalet till
          upphörande omedelbart efter det att Formue fått del av Kundens
          skriftliga meddelande. Vid uppsägning från Formues sida upphör Avtalet
          från och med trettionde kalenderdagen efter det att Kunden ska anses
          ha mottagit skriftligt meddelande därom.
        </StyledParagraph>
        <StyledParagraph>
          Uppsägning av kundavtalet ska samtidigt innebära en uppsägning av
          Portföljförvaltningsavtalet.
        </StyledParagraph>
        <StyledParagraph>
          Om Kunden gör uttag eller ändringar i Förvaltningsportföljen med
          konsekvensen att Formue inte kan följa de avtalade ramarna med normal
          förvaltning (till exempel för hög andel värdepapper som förvaltaren
          inte kan sälja och/eller illikvida värdepapper) ska Formue meddela
          Kunden med 1 månads frist för korrigering, annars anses
          Portföljförvaltningsavtalet vara uppsagt och ska omedelbart upphöra
          efter fristens slut.
        </StyledParagraph>
        <StyledParagraph>
          När Portföljförvaltningsavtalet upphör att gälla ska den diskretionära
          förvaltningen upphöra och kundförhållandet uteslutande att regleras av
          kundavtalet. Detta gäller även vid Formues uppsägning av
          Portföljförvaltningsavtalet.
        </StyledParagraph>
        <StyledParagraph>
          Order eller avtal om köp, sälj, teckning eller inlösen av finansiella
          instrument som Formue har ingått eller lämnat för Kundens räkning
          innan en skriftlig uppsägning har kommit Formue tillhanda kommer att
          vara bindande för Kunden.
        </StyledParagraph>
        <StyledParagraph>
          Formue äger rätt att ändra detta Portföljförvaltningsavtal. Sådana
          ändringar gäller mot Kunden från och med den trettionde kalenderdagen
          efter det att Kunden ska anses ha mottagit meddelande om ändringen.
        </StyledParagraph>
        <StyledParagraph>
          Kunden bekräftar att denne har mottagit, läst och accepterat
          Portföljförvaltningsavtalet. Kunden bekräftar också att den har
          mottagit, läst och accepterat Formues allmänna avtalsvillkor, som
          tillsammans med kundavtalet och Portföljförvaltningsavtalet reglerar
          uppdragsförhållandet mellan Kunden och Formue.
          Portföljförvaltningsavtalet ska anses vara ett tillägg till befintligt
          kundavtal och servicetyp med Formue med eventuella ändringar och
          bilagor. Portföljförvaltningsavtalet har företräde framför både
          kundavtalet och de allmänna avtalsvillkoren. Kunden bekräftar även att
          denne ska informera Formue om förändringar i Kundens ekonomiska
          situation och andra omständigheter som lagts till grund för
          lämplighetsbedömningen samt annan information som framgår av detta
          Portföljförvaltningsavtal.
        </StyledParagraph>

        <StyledParagraph style={{ fontWeight: 500 }}>
          Jag godkänner detta avtal genom signering med BankID
        </StyledParagraph>
      </Content>
    </Container>
  </>
);
