// import { apiFetch } from './utils/api';
const mockedInvestmentPreferences = require('./mocks/v1/investment-preferences.json');

export const investmentPreferences = {
  /**
   * @desc Get list of investment preferences
   *
   * @return json - data or error message
   */
  get: async () => {
    /*
    const options = {
      method: 'GET',
    };

    const url = '/v1/investment-preferences';
    */

    // for now just return the mocked response
    return mockedInvestmentPreferences;
  },
};
