import React, { useState } from 'react';
import styled from 'styled-components';

import { H3 } from '../../texts';
import { Input } from '../../formElements';
import { Modal } from '../../common/Modal';
import { accent } from '../../../constants/colors';
import { SPACING_48, SPACING_24 } from '../../../constants/spacing';
import { bodyFontStack } from '../../../fonts';
import { Button } from '../../buttons';

const StyledInput = styled(Input)`
  width: 100%;
  border: 1px solid ${accent.ocean440};
  margin: ${SPACING_24} 0;
  font-family: ${bodyFontStack} !important;
`;

export const ModalSaveFilters = (props) => {
  const [name, setName] = useState('');
  const { createBookmark, onRequestClose, ...rest } = props;

  const submit = () => {
    createBookmark(name);
    setName('');
    onRequestClose();
  };

  return (
    <Modal
      {...rest}
      onRequestClose={onRequestClose}
      contentWidth={400}
      padding={SPACING_48}
      iconSize="12px"
    >
      <H3>Save filter set</H3>
      <StyledInput
        onChange={(event) => setName(event.target.value)}
        type="text"
        value={name}
        placeholder="Add name"
      />
      <Button onClick={submit}>Save</Button>
    </Modal>
  );
};
