import React from 'react';
import styled from 'styled-components';

import { useResource } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
  SPACING_24,
  SPACING_8,
} from '../../../constants/spacing';
import { contentMaxWitdh } from '../../../constants/grid';
import { accent, backgroundWhite } from '../../../constants/colors';

import { H2, H3, Paragraph, ParagraphSmall } from '../../texts';

import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { AssetAllocationGroups } from '../investmentStrategyComponents/ChooseStrategyDetailsComponents';
import { PortfolioCharacteristicsItemPieChart } from '../investmentStrategyComponents/PortfolioCharacteristicsItemPieChart';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  background-color: ${accent.sand1};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${contentMaxWitdh}px;
`;

const Content = styled.div`
  width: 100%;
  gap: ${SPACING_8};
  margin: ${SPACING_24} 0 ${SPACING_48} 0;
  display: flex;
  flex-direction: column;
`;

const Cell = styled.div`
  flex: 1;
  display: flex;

  &:first-child {
    max-width: 230px;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  padding: 20px ${SPACING_24};
  background-color: ${backgroundWhite};
  border-radius: 16px;
`;

const ColumnTitle = styled(H3)``;

const RowTitle = styled(Paragraph)``;

export const ChooseStrategyDetails = (props) => {
  const strategies = useEnabledStrategies();

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  if (!strategies.length) return null;

  const rows = [
    {
      component: ({ title }) => <ColumnTitle>{title}</ColumnTitle>,
    },
    {
      title: 'Fördelning',
      component: (props) => <AssetAllocationGroups {...props} />,
    },
    {
      title: 'Yearly return',
      component: ({ yearlyReturn }) => <H2>6,9%</H2>,
    },
    {
      title: 'Yearly volatility',
      component: ({ yearlyVolatility }) => <H2>6,4%</H2>,
    },
    {
      title: 'Total expense ratio',
      component: ({ totalExpenseRatio }) => <H2>0,7%</H2>,
    },
    {
      title: 'Carbon footprint',
      component: ({ carbonFootprint }) => (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <H2 style={{ marginRight: 8 }}>106</H2>
          <ParagraphSmall>Tonn CO2 per M$ i omsetning</ParagraphSmall>
        </div>
      ),
    },
    {
      title: 'Portfolio liquidity',
      component: (props) => (
        <PortfolioCharacteristicsItemPieChart
          size={48}
          innerRadius={15}
          data={[
            { label: 'Liquid', amount: 0.6 },
            { label: '2-5y', amount: 0.15 },
            { label: '5-8y', amount: 0.25 },
          ]}
        />
      ),
    },
    {
      title: 'Active vs Passive',
      component: (props) => (
        <PortfolioCharacteristicsItemPieChart
          size={48}
          innerRadius={15}
          padding={16}
          data={[
            { label: 'Active', amount: 0.5 },
            { label: 'Passive', amount: 0.5 },
          ]}
        />
      ),
    },
  ];

  if (loading) return null;

  return (
    <Wrapper>
      <Container>
        <Content>
          {rows.map((row, index) => {
            const RowComponent = row.component;

            return (
              <Row key={`row-${index}`}>
                <Cell>
                  <RowTitle>{row.title}</RowTitle>
                </Cell>

                {strategies.map((strategy) => (
                  <Cell>
                    <RowComponent key={strategy.id} {...strategy} />{' '}
                  </Cell>
                ))}
              </Row>
            );
          })}
        </Content>
      </Container>
    </Wrapper>
  );
};
