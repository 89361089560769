import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  illiquidCashflowAllIds,
  illiquidCashflowById,
  allIlliquidCashflowSelector,
  illiquidCashflowMeta,
] = resourceSelectors('illiquidCashflow');

export const allIlliquidCashflowStrategiesSelector = createSelector(
  illiquidCashflowMeta,
  (meta) => {
    if (meta.hasOwnProperty('strategy')) {
      return meta.strategy.strategies;
    }
    return [];
  }
);

export const createIlliquidCashflowByAssetClassSelector = (assetClass) => {
  return createSelector(allIlliquidCashflowSelector, (items) => {
    return items.filter((item) => item.assetClass === assetClass);
  });
};
