import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { GridElement } from '../layout/GridElement';
import { StyledBaseSection } from '../common/BaseSection';
import { FlipArrow } from '../common/FlipArrow';
import { H2 } from '../texts';
import { SPACING_16 } from '../../constants/spacing';
import { easeInOut, shortAnimationTime } from '../../constants/animation';
import { hoverBoxShadowLvl2 } from '../../constants/colors';

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

const StyledListItem = styled(StyledBaseSection)`
  padding: ${SPACING_16};
  margin-bottom: 5px;
  position: relative;
  ${(props) => (props.onClick ? 'cursor: pointer;' : null)}
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  &:hover {
    box-shadow: ${hoverBoxShadowLvl2};
  }
`;

const StyledFlipArrow = styled(FlipArrow)`
  position: absolute;
  top: 50%;
  margin-top: 0;
  translate: 0 -50%;
  right: ${SPACING_16};
`;

const FlatListContent = (props) => {
  const {
    rows,
    renderItem,
    keyExtractor,
    toExtractor,
    component: Component = StyledListItem,
  } = props;
  let history = useHistory();

  return rows.map((item, index) => (
    <Component
      as="li"
      key={keyExtractor(item, index)}
      item={item}
      index={index}
      onClick={() => (toExtractor ? history.push(toExtractor(item)) : null)}
    >
      {renderItem ? renderItem(item, index) : null}
      {toExtractor ? <StyledFlipArrow direction="right" /> : null}
    </Component>
  ));
};

export const FlatList = (props) => {
  const { title, columns, className, rows, ListEmptyComponent } = props;

  // Determine if we have no rows
  const listIsEmpty = rows.length === 0;

  return (
    <GridElement className={className} columns={columns}>
      {title ? <H2 style={{ marginBottom: 20 }}>{title}</H2> : null}

      <StyledList>
        {listIsEmpty ? ListEmptyComponent : <FlatListContent {...props} />}
      </StyledList>
    </GridElement>
  );
};
