import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFlagsSelector } from '@formue-app/core';

import annualReviewSlides from './annual-review.json';

const EXCLUDED_SLIDES = [3.3];

export const useAnnualReviewSlides = () => {
  const flags = useSelector(getFlagsSelector);

  const { features } = flags;

  const [filteredAnnualReviewSlides, setFilteredAnnualReviewSlides] = useState(
    []
  );

  useEffect(() => {
    let slides = annualReviewSlides;
    if (!features?.portfolioLab) {
      // Then exclude some slides for users without "portfolioLab" feature flag
      slides = annualReviewSlides.filter(
        (slide) => !EXCLUDED_SLIDES.includes(slide.id)
      );
    }
    setFilteredAnnualReviewSlides(slides);
  }, [features]);

  return filteredAnnualReviewSlides;
};
