import React from 'react';
import styled, { css } from 'styled-components';

import { accent } from '../../../../constants/colors';
import { SPACING_32 } from '../../../../constants/spacing';

import {
  easeInOut,
  shortAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';

import { ReactComponent as PortfolioStrategyCircleIcon } from '../../../../assets/icons/presentation/portfolio-strategy-circle.svg';

const StyledPortfolioStrategyCircle = styled(PortfolioStrategyCircleIcon)`
  margin-bottom: ${SPACING_32};

  path {
    transition: fill ${easeInOut} ${shortAnimationTime};
    transition-delay: ${slideAnimationDelay}s;
  }

  // A bit brute force since there is no good way to do loops in styled-components without
  // extracting into a function
  path:nth-child(2) {
    transition-delay: ${slideAnimationDelay + 0.1}s;
  }
  path:nth-child(3) {
    transition-delay: ${slideAnimationDelay + 0.1 * 2}s;
  }
  path:nth-child(4) {
    transition-delay: ${slideAnimationDelay + 0.1 * 3}s;
  }
  path:nth-child(5) {
    transition-delay: ${slideAnimationDelay + 0.1 * 4}s;
  }
  path:nth-child(6) {
    transition-delay: ${slideAnimationDelay + 0.1 * 5}s;
  }
  path:nth-child(7) {
    transition-delay: ${slideAnimationDelay + 0.1 * 6}s;
  }

  ${(props) =>
    props.isactiveslide === 1 &&
    css`
      path:not(:nth-child(1n + ${props.numinside + 1})) {
        fill: ${accent.velvet3};
      }
    `}
`;

export const PortfolioStrategyCircle = (props) => {
  const { isActiveSlide, numInside, ...rest } = props;

  return (
    <StyledPortfolioStrategyCircle
      {...rest}
      numinside={numInside}
      isactiveslide={isActiveSlide ? 1 : 0}
    />
  );
};
