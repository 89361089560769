import { apiFetch } from './utils/api';
// const mockedForms = require('./mocks/v1/forms.json');
import { FormSerializer } from './serializers/forms';

export const forms = {
  get: async () => {
    // return mockedForms;

    const options = {
      method: 'GET',
    };

    const url = '/v1/forms';

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },

  update: async (args, data) => {
    const { id } = args;
    const payload = FormSerializer.serialize({ id, data });

    const options = {
      method: 'PATCH',
      body: payload,
    };
    const url = `/v1/forms/${id}`;

    return apiFetch(url, options);
  },
};
