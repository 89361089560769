import React from 'react';
import styled from 'styled-components';

import { accent } from '../../constants/colors';
import { paragraphStyle } from '../texts/Paragraph';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { FilledInputStyle, OutlineInputStyle, StyledInputBase } from './Input';
import { Label } from './Label';

const StyledTextarea = styled.textarea`
  ${StyledInputBase}
  ${paragraphStyle}
  color: ${accent.ocean490};
  padding: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  ${(props) => props.inputstyle === 'filled' && FilledInputStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineInputStyle}
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

export const Textarea = (props) => {
  const { style, inputstyle = 'outline', popoutLabel, label, ...rest } = props;
  return (
    <InputWrapper>
      {popoutLabel && label && <Label>{label}</Label>}
      <StyledTextarea style={style} inputstyle={inputstyle} {...rest} />
    </InputWrapper>
  );
};
