import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

import { mobileCondition } from '../../constants/media';
import {
  BORDER_RADIUS_MODAL,
  SPACING_16,
  SPACING_24,
  SPACING_48,
} from '../../constants/spacing';

import { H3 } from '../texts';
import {
  backgroundWhite,
  blackTranslucent,
  overlayColor,
  textMain,
} from '../../constants/colors';
import { easeInOut, mediumAnimationTime } from '../../constants/animation';
import { SpriteIconFunctional } from './SpriteIconFunctional';

const StyledContentElement = styled.div`
  width: ${(props) =>
    !!props.contentWidth
      ? `min(${props.contentWidth}px, 100% - ${SPACING_24} - ${SPACING_24})`
      : `calc(100% - ${SPACING_24} - ${SPACING_24})`};
  max-width: 1050px !important;
  max-height: calc(100% - ${SPACING_24} - ${SPACING_24}) !important;
  left: 50% !important;
  right: auto !important;
  top: 50% !important;
  bottom: auto !important;
  translate: -50% calc(-50% + ${SPACING_48});
  opacity: 0;
  transition: all ${easeInOut} ${mediumAnimationTime};

  &.ReactModal__Content--after-open {
    translate: -50% -50%;
    opacity: 1;
  }
  &.ReactModal__Content--before-close {
    translate: -50% calc(-50% + ${SPACING_48});
    opacity: 0;
  }

  > header {
    h3 {
      @media ${mobileCondition} {
        text-align: left;
      }
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      background: none;
      padding-top: max(${(props) => props.buttonPadding}, ${SPACING_16});
      padding-right: max(${(props) => props.buttonPadding}, ${SPACING_16});
      margin: 0;
      z-index: 2;
      cursor: pointer;

      svg {
        vertical-align: unset;
      }
    }
  }
`;

const StyledOverlay = styled.div`
  background: rgba(0, 0, 0, 0) !important;
  z-index: 9999;
  height: auto;
  transition: background ${easeInOut} ${mediumAnimationTime};

  &.ReactModal__Overlay--after-open {
    background: ${(props) => props.overlayColor} !important;
  }
  &.ReactModal__Overlay--before-close {
    background: rgba(0, 0, 0, 0) !important;
  }
`;

const modalStyle = (padding, backgroundColor = backgroundWhite, isOpen) => ({
  content: {
    background: backgroundColor,
    borderRadius: BORDER_RADIUS_MODAL,
    padding: padding,
    border: 'none',
    boxShadow: `0 4px 4px 0 ${blackTranslucent(0.25)})`,
  },
});

const createContentElement = (
  contentLabel,
  labelComponent = H3,
  onRequestClose,
  closable,
  contentWidth,
  iconSize,
  iconColor,
  padding,
  buttonPadding
) => {
  const LabelComponent = labelComponent;

  return (props, children) => (
    <StyledContentElement
      {...props}
      contentWidth={contentWidth}
      padding={padding}
      buttonPadding={buttonPadding ? buttonPadding : padding}
    >
      <header>
        {contentLabel ? <LabelComponent>{contentLabel}</LabelComponent> : null}
        {closable ? (
          <button onClick={onRequestClose}>
            <SpriteIconFunctional
              id="Close"
              color={iconColor}
              size={iconSize}
            />
          </button>
        ) : null}
      </header>
      {children}
    </StyledContentElement>
  );
};

export const Modal = (props) => {
  const {
    contentLabel,
    labelComponent,
    onRequestClose,
    style,
    closable = true,
    padding = SPACING_24,
    buttonPadding,
    backgroundColor,
    contentWidth,
    iconSize = SPACING_16,
    iconColor = textMain,
    isOpen,
    ...rest
  } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <ReactModal
      contentElement={createContentElement(
        contentLabel,
        labelComponent,
        onRequestClose,
        closable,
        contentWidth,
        iconSize,
        iconColor,
        padding,
        buttonPadding
      )}
      overlayElement={(props, contentElement) => (
        <StyledOverlay overlayColor={overlayColor} {...props}>
          {contentElement}
        </StyledOverlay>
      )}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={closable}
      style={{ ...modalStyle(padding, backgroundColor), ...style }}
      isOpen={isOpen}
      closeTimeoutMS={500}
      {...rest}
    />
  );
};
