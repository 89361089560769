import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

import { FlipArrow } from './FlipArrow';
import { BaseSection } from './BaseSection';
import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl1,
  textMain,
} from '../../constants/colors';
import {
  BOX_PADDING_HORIZONTAL,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_2,
  SPACING_12,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

import { H3, H4 } from '../texts';
import { SpriteIconFunctional } from './SpriteIconFunctional';

const StyledBaseSection = styled(BaseSection)`
  padding: 0;
  background: none;
`;

const BaseSectionWithDropshadow = styled(StyledBaseSection)`
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  &:hover {
    box-shadow: ${hoverBoxShadowLvl1};
  }
`;

const StyledHeader = styled.header`
  background: ${backgroundWhite};
  border-top-left-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  border-top-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  padding: ${SPACING_12};
  padding-bottom: ${SPACING_16};
  padding-top: ${SPACING_16};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${(props) => props.iconSpacing}px;
`;

const headerHeight = '64px';
const footerHeight = '46px';

const StyledContentWrapper = styled.div`
  background: ${backgroundWhite};
  padding: ${SPACING_24};
  padding-top: ${(props) => (props.headerCondition ? 0 : SPACING_24)};
  height: ${(props) =>
    `calc(100% - ${props.headerCondition ? headerHeight : '0px'} - ${
      props.url ? footerHeight : '0px'
    })`};

  ${(props) =>
    props.rounded
      ? `
      border-bottom-left-radius: ${BORDER_RADIUS_EXTRA_LARGE};
      border-bottom-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};
      `
      : ''}

  @media ${mobileCondition} {
    padding: ${SPACING_16} ${SPACING_16};
  }
`;

const StyledFooter = styled.footer`
  margin-top: ${SPACING_2};
  background: ${backgroundWhite};
  border-bottom-left-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  border-bottom-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  padding: ${SPACING_8} ${BOX_PADDING_HORIZONTAL};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: ${SPACING_16};
  border-top: ${(props) => (props.noBorder ? 'none !important' : 'inherit')};

  h4 {
    color: ${(props) => (props.linkColor ? props.linkColor : textMain)};
  }

  span {
    text-decoration: none;
  }
`;

const LinkWrapper = styled(HashLink)`
  text-decoration: none;
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  footer:hover {
    box-shadow: ${hoverBoxShadowLvl1};
  }
`;

const ArrowWrapper = styled.div`
  background: ${accent.sand1};
  width: 28px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SummaryBox = (props) => {
  const {
    title,
    titleFontSize = 18,
    icon: IconSvg,
    iconSpacing = 16,
    label,
    url,
    target,
    children,
    linkColor,
    ...rest
  } = props;

  const headerCondition = IconSvg || title;

  return (
    <StyledBaseSection {...rest}>
      {headerCondition ? (
        <StyledHeader iconSpacing={iconSpacing}>
          {IconSvg ? IconSvg : null}
          {title ? <H3 style={{ fontSize: titleFontSize }}>{title}</H3> : null}
        </StyledHeader>
      ) : null}

      <StyledContentWrapper
        rounded={!url}
        title={title}
        icon={IconSvg}
        headerCondition={headerCondition}
        url={url}
        className="content-wrapper"
      >
        {children}
      </StyledContentWrapper>

      {url && (
        <LinkWrapper to={url} target={target}>
          <StyledFooter linkColor={linkColor} noBorder={!label}>
            <H4>{label}</H4>{' '}
            <ArrowWrapper>
              <SpriteIconFunctional
                id="Simple arrow"
                direction="Right"
                size={SPACING_16}
              />
            </ArrowWrapper>
          </StyledFooter>
        </LinkWrapper>
      )}
    </StyledBaseSection>
  );
};

export const SummaryBoxClickable = ({ url, target, columns, ...rest }) => {
  return (
    <StyledBaseSection columns={columns}>
      <LinkWrapper to={url} target={target}>
        <SummaryBox {...rest} url={url} target={target} />
      </LinkWrapper>
    </StyledBaseSection>
  );
};

export const LinkSummaryBox = (props) => {
  const {
    title,
    icon: IconSvg,
    label,
    url,
    target,
    children,
    linkColor,
    ...rest
  } = props;

  const headerCondition = IconSvg || title;

  return (
    <BaseSectionWithDropshadow {...rest}>
      <LinkWrapper to={url} target={target}>
        {headerCondition ? (
          <StyledHeader>
            {IconSvg ? IconSvg : null}
            {title ? <H3 as="h2">{title}</H3> : null}
          </StyledHeader>
        ) : null}

        <StyledContentWrapper
          rounded={!url}
          title={title}
          icon={IconSvg}
          headerCondition={headerCondition}
          url={url}
          className="content-wrapper"
        >
          {children}
        </StyledContentWrapper>

        <StyledFooter linkColor={linkColor}>
          <H4>{label}</H4> <FlipArrow />
        </StyledFooter>
      </LinkWrapper>
    </BaseSectionWithDropshadow>
  );
};
