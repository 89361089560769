import React from 'react';
import styled from 'styled-components';

import { formatPieChartData, percentage } from '@formue-app/core';

import {
  BORDER_RADIUS_SMALL,
  SPACING_16,
  SPACING_24,
  SPACING_4,
} from '../../../constants/spacing';

import { accent } from '../../../constants/colors';
import { PieChart } from '../../graphs/PieChart';
import { ParagraphSmall } from '../../texts';

const PieChartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_24};
`;

const PieChartLegend = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => props.padding}px;
  flex: 1;
  gap: ${SPACING_4};
`;

const PieChartLegendItem = styled(ParagraphSmall)`
  display: flex;
  gap: ${SPACING_16};
  position: relative;
  margin-left: 10px;

  &:before {
    content: '';
    background-color: ${(props) => props.color};
    height: 4px;
    width: 4px;
    left: -10px;
    top: 7.5px;
    position: absolute;
    width: 4px;
    border-radius ${BORDER_RADIUS_SMALL};
    z-index: 1;
  }
`;

const oceanAccentColors = [
  accent.ocean1,
  accent.ocean2,
  accent.ocean3,
  accent.ocean4,
];

export const PortfolioCharacteristicsItemPieChart = (props) => {
  const { data, size = 96, innerRadius = 33, padding = 0, ...rest } = props;

  return (
    <PieChartWrapper {...rest}>
      <PieChart
        size={size}
        innerRadius={innerRadius}
        padAngle={4}
        cornerRadius={size / 25}
        data={formatPieChartData(
          data.map((item, index) => ({
            name: item.group,
            amount: item.allocation,
            index: index,
          })),
          oceanAccentColors
        )}
      />
      <PieChartLegend padding={padding}>
        {data.map((item, index) => (
          <PieChartLegendItem
            color={oceanAccentColors[index]}
            key={`legend-item-${index}`}
          >
            {item.group}
            <span>
              {percentage(parseFloat(item.allocation * 100).toFixed(1), 1)}
            </span>
          </PieChartLegendItem>
        ))}
      </PieChartLegend>
    </PieChartWrapper>
  );
};
