import React, { useState } from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { GridElement } from '../../../../components/layout';
import { CollapsibleListItem } from '../../../../components/common/CollapsibleListItem';
import { H3, Paragraph } from '../../../../components/texts';

import { Toggle } from '../../../../components/formElements';
import { desktopCondition, tabletCondition } from '../../../../constants/media';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { InfoCard } from '../../../../components/common/InfoCard';

const StyledInfoCard = styled(InfoCard)`
  margin-top: 10px;

  @media ${desktopCondition} {
    margin-right: 40px;
  }

  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  max-width: 75%;
  padding: ${SPACING_24};
  padding-top: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 55px;
  margin-bottom: ${SPACING_16};
`;

export const GeneralPage = (props) => {
  const [openAll, setOpenAll] = useState(false);

  const faqData = [
    {
      title: 'Investeringssparkonto (ISK)',
      detailsStyle: { padding: 0 },
      details: (
        <ContentWrapper>
          <Paragraph>
            För tillgångar som finns på ett investeringssparkonto ska den som
            kontoför investeringssparkontot fastställa ett kapitalunderlag och
            beräkna en schablonintäkt. Schablonintäkten beräknas genom att
            kapitalunderlaget multipliceras med 1,25% för inkomståret 2022
            (statslåneräntan 30 november 2021 ökat med en procentenhet, minimum
            1,25%). Schablonintäkten beskattas som inkomst av kapital med 30%.
            Kontoföraren av investeringssparkontot lämnar kontrolluppgift till
            Skatteverket på schablonintäkten som blir förtryckt i kommande
            deklaration. Avdrag för preliminärskatt har inte gjorts.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Kapitalförsäkring',
      details: (
        <ContentWrapper>
          <Paragraph>
            För kapitalförsäkringar fastställs också ett kapitalunderlag som för
            inkomståret 2022 multipliceras med 1,25%. Skatten, som betecknas som
            avkastningsskatt, beräknas med 30% av skatteunderlaget.
            Kontrolluppgift lämnas till Skatteverket på skatteunderlaget och det
            blir då förtryckt i kommande inkomstdeklaration. För utländska
            kapitalförsäkringar blir i vissa fall inte skatteunderlaget
            förtryckt i deklarationen, du måste då själv ta upp skatteunderlaget
            i din inkomstdeklaration.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Fondandelar',
      details: (
        <ContentWrapper>
          <Paragraph>
            För fondandelar beräknas schablonintäkten genom att värdet av
            fondandelarna vid beskattningsårets början multipliceras med 0,4%.
            Schablonintäkten blir förtryckt i inkomstdeklarationen och skatten
            är 30% av schablonintäkten. Avdrag för preliminärskatt har inte
            gjorts.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Annan inkomst',
      details: (
        <ContentWrapper>
          <Paragraph>
            Annan inkomst avser erhållna returprovisioner i värdepappersdepå.
            Avdrag för preliminärskatt har inte gjorts.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'K12-blanketter',
      details: (
        <ContentWrapper>
          <Paragraph>
            För utbetalningar som skett i investeringar i onoterade bolag via
            Formue Sverige assisterar vi med att ta fram en ifylld K12 blankett
            (okvalificerade andelar). Blanketten kommer att finnas på
            kundportalen under februari månad.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title:
        'Erhållen försäkringsprovision från Nordnet och övriga försäkringar',
      details: (
        <ContentWrapper>
          <Paragraph>
            Har ni via oss erhållit försäkringsprovision från Nordnet och/ eller
            övriga försäkringar som betalats ut till er värdepappersdepå eller
            föranmält bankkonto behöver ni ta upp det i er inkomstdeklaration.
            Dessa uppgifter är ej lämnade till Skatteverket. Ett separat brev
            med information om erhållet belopp kommer att finnas på kundportalen
            under februari månad.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Några datum att komma ihåg',
      details: (
        <ContentWrapper>
          <Paragraph>
            Senast den 13 februari ska eventuell fyllnadsinbetalning vara
            Skatteverket tillhanda och senast den 2 maj ska sedan en komplett
            inkomstskattedeklaration vara inlämnad.
          </Paragraph>
          <Paragraph>
            Den behållning som finns i era depåer/ försäkringar vid årsskiftet
            2021/ 2022 finner ni i månadsrapporten för december 2021.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Inkomstdeklaration 2022',
      details: (
        <ContentWrapper>
          <Paragraph>
            Önskar Ni hjälp med inkomstdeklarationen för 2022 kan vi bistå med
            upprättande och med olika typer av skattefrågor.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Frågor',
      details: (
        <ContentWrapper>
          <Paragraph>
            Om Ni har frågor eller vill diskutera något är ni alltid välkommen
            att kontakta er förmögenhetsrådgivare.
          </Paragraph>
        </ContentWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledInfoCard
        title="Information Deklarationsunderlag 2022"
        body="I slutet av januari kommer deklarationsunderlag för 2022 att finnas på kundportalen."
      />
      <GridElement columns={12}>
        <TitleWrapper>
          <Toggle
            label="Se alla"
            value={openAll}
            onChange={() => setOpenAll(!openAll)}
          />
        </TitleWrapper>
        {faqData.map((q, index) => (
          <CollapsibleListItem
            details={q.details}
            detailsStyle={q.detailsStyle}
            startExpanded={openAll}
            style={{ marginTop: SPACING_8, padding: SPACING_24 }}
            key={`faq-${camelcase(q.title)}-${index}`}
          >
            <H3 style={{ marginRight: 'auto' }}>{q.title}</H3>
          </CollapsibleListItem>
        ))}
      </GridElement>
    </>
  );
};
