import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  localizeFloat,
  numberWithSpaces,
  selectors,
  t,
  useResource,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_12,
  SPACING_16,
  SPACING_32,
  SPACING_40,
} from '../../../constants/spacing';
import {
  easeInOut,
  extraLongAnimationTime,
} from '../../../constants/animation';
import { titleFontStack } from '../../../fonts';
import { H1 } from '../../texts';
import { TemperatureRise } from '../../portfolio/lenses/sustainability/TemperatureRise';
import { ScienceBasedTargets } from '../../portfolio/lenses/sustainability/ScienceBasedTargets';
import { SocialResponsibility } from '../../portfolio/lenses/sustainability/SocialResponsibility';
import { FundClassification } from '../../portfolio/lenses/sustainability/FundClassification';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { MainGrid } from '../../layout';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: 0;
  flex-direction: column;
`;

const ContentWrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: ${SPACING_40} 0;
  flex: 1;
`;

const TitleWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: ${SPACING_12};
`;

const {
  entities: {
    portfolioLenses: {
      allPortfolioLensesSelector,
      carbonFootprintDataSelector,
    },
  },
} = selectors;

export const SustainabilityLensSlide = (props) => {
  const { isActiveSlide, titleColor, highlightColor } = props;

  const loading = useResource(['PORTFOLIO_LENSES/INDEX']);
  const portfolioLenses = useSelector(allPortfolioLensesSelector);
  const carbonFootprintData = useSelector(carbonFootprintDataSelector);

  if (!portfolioLenses.length) return null;

  return (
    <Wrapper>
      {loading && <CenteredActivityIndicator />}
      {carbonFootprintData ? (
        <>
          <TitleWrapper>
            <H1
              style={{
                color: accent[titleColor],
                whiteSpace: 'pre-line',
                opacity: isActiveSlide ? 1 : 0,
                transition: `all ${easeInOut} ${extraLongAnimationTime} 0.8s`,
              }}
            >
              <Trans
                i18nKey={t('sustainability:tonsOfCo2', {
                  value: numberWithSpaces(
                    localizeFloat(
                      carbonFootprintData.carbonFootprint.toFixed(0),
                      0
                    )
                  ),
                })}
                components={{
                  strong: (
                    <span
                      style={{
                        color: accent[highlightColor],
                        fontWeight: 700,
                        fontFamily: titleFontStack,
                      }}
                    />
                  ),
                }}
              />
            </H1>
          </TitleWrapper>

          <ContentWrapper>
            <TemperatureRise
              columns={3}
              backgroundColor={accent.forest490}
              textColor={accent.forest2}
              badgeBackgroundColor={accent.forest2}
              badgeTextColor={accent.forest4}
              positiveBackgroundColor={accent.forest2}
              positiveArrowColor={accent.forest4}
              negativeBackgroundColor={accent.forest2}
              negativeArrowColor={accent.forest4}
              showModalButton={false}
            />
            <ScienceBasedTargets
              columns={3}
              backgroundColor={accent.forest490}
              textColor={accent.forest2}
              chartColors={[accent.forest2, accent.forest1]}
              positiveBackgroundColor={accent.forest2}
              positiveArrowColor={accent.forest4}
              negativeBackgroundColor={accent.forest2}
              negativeArrowColor={accent.forest4}
              showModalButton={false}
            />
            <SocialResponsibility
              columns={3}
              backgroundColor={accent.forest490}
              textColor={accent.forest2}
              chartColors={[accent.forest2, accent.forest1]}
              showModalButton={false}
            />
            <FundClassification
              columns={3}
              backgroundColor={accent.forest490}
              textColor={accent.forest2}
              chartColors={[accent.forest2, accent.forest2, accent.forest1]}
              showModalButton={false}
            />
          </ContentWrapper>
        </>
      ) : null}
    </Wrapper>
  );
};
