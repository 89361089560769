import React from 'react';
import styled from 'styled-components';

import { Flag } from '@formue-app/core';

import {
  SPACING_48,
  SPACING_24,
  SPACING_64,
  SPACING_16,
  SPACING_2,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { AdvisorsProfileImages } from '../AdvisorsProfileImages';
import { H3, H6 } from '../../texts';

import logo from '../../../assets/images/logo-contact.png';
import { CollapsibleListItem } from '../../common/CollapsibleListItem';
import { MarkdownContent } from '../../common/MarkdownContent';
import { GridElement } from '../../layout';

const StyledImage = styled.img`
  height: ${SPACING_64};
  width: ${SPACING_64};
  border-radius: 50%;
  margin-bottom: ${SPACING_16};
`;

const Content = styled.div`
  display: flex;
  margin-right: auto;
  align-items: center;
  gap: ${SPACING_16};

  h6 {
    color: ${accent.ocean450};
    margin-bottom: ${SPACING_2};
  }
`;

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  & + .details > div {
    padding-top: 0 !important;
  }
`;

export const AdvisorsComment = (props) => {
  const { navigation, subTitle, title, body, ...rest } = props;

  if (!body) {
    return null;
  }

  return (
    <GridElement {...rest}>
      <StyledCollapsibleListItem
        details={<MarkdownContent>{body}</MarkdownContent>}
        detailsStyle={{
          paddingInline: SPACING_48,
          marginTop: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          paddingTop: 0,
        }}
        expandedStyle={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        startExpanded={false}
        style={{ paddingBlock: SPACING_24, paddingInline: SPACING_48 }}
      >
        <Content>
          <Flag
            name={['isLead']}
            render={() => <StyledImage src={logo} />}
            fallbackRender={() => <AdvisorsProfileImages size={40} />}
          />
          <div>
            <H6>{subTitle}</H6>
            <H3>{title}</H3>
          </div>
        </Content>
      </StyledCollapsibleListItem>
    </GridElement>
  );
};
