import { AuthError } from './AuthError';

export class ApiError extends Error {
  constructor(location = '', ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError);
    }

    this.location = location;
  }
}
