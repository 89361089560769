import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  SPACING_24,
  SPACING_120,
  SPACING_88,
} from '../../../constants/spacing';

import { H2 } from '../../texts';
import { GridElement, MainGrid } from '../../layout';

import { PortfolioPyramid } from '../../common/PortfolioPyramid';
import { PortfolioConstructionPyramidStaticLabel } from '../../portfolio/strategy/PortfolioConstructionPyramidStaticLabel';
import { MarkdownContent } from '../../common/MarkdownContent';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  max-width: 1232px;
`;

const StyledGridElement = styled(GridElement)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  padding: 0;
  margin: 0;
`;

const StyledPortfolioPyramid = styled(PortfolioPyramid)`
  position: absolute;
  bottom: 0;
  right: 70px;
`;

export const ConstructionInfo = (props) => {
  const { title, strategyCommentKey } = props;
  const height = 470;
  // We use 364 as the base size for the pyramid scaling everything according to that
  const scaler = 364 / height;

  const pyramidLabels = [
    {
      labelKey: 'AV',
      translationKey: 'alternativeGrowth',
      offset: -(80 / scaler),
      color: '#C76FF2',
    },
    {
      labelKey: 'MV',
      translationKey: 'marketGrowth',
      offset: -(10 / scaler),
      color: '#C5A5D6',
    },
    {
      labelKey: 'S',
      translationKey: 'stability',
      offset: 50 / scaler,
      color: '#6D4694',
    },
  ];

  return (
    <Wrapper>
      <StyledGridElement columns={6}>
        <H2>{t(title)}</H2>
        <div>
          <MarkdownContent>{t(strategyCommentKey)}</MarkdownContent>
        </div>
      </StyledGridElement>
      <StyledGridElement columns={6}>
        <StyledPortfolioPyramid
          height={height}
          labels={pyramidLabels}
          labelComponent={(item) => (
            <PortfolioConstructionPyramidStaticLabel labelKey={item.labelKey} />
          )}
          style={{ marginTop: 'auto' }}
        />
      </StyledGridElement>
    </Wrapper>
  );
};
