export const POSITIONS = [
  {
    x: 26,
    y: 47,
  },
  {
    x: 189,
    y: 146,
  },
  {
    x: 191,
    y: 24,
  },
  {
    x: 87,
    y: 209,
  },
  {
    x: 311,
    y: 103,
  },
  {
    x: 42,
    y: 209,
  },
  {
    x: 311,
    y: 53,
  },
  {
    x: 331,
    y: 200,
  },
  {
    x: 311,
    y: 265,
  },
  {
    x: 42,
    y: 259,
  },
  {
    x: 261,
    y: 275,
  },
  {
    x: 311,
    y: 265,
  },
];

export const SIZES = [150, 120, 100, 75, 45, 35, 35, 35, 35, 35, 35, 35];
