import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { humanFriendlyDate, selectors, useResource } from '@formue-app/core';

import { H2, Paragraph } from '../texts';
import {
  BORDER_RADIUS_LARGE,
  BORDER_RADIUS_SMALL,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { PodcastPlayerProgressBar } from './PodcastPlayerProgressBar';
import { PodcastPlayerControls } from './PodcastPlayerControls';
import { PodcastPlayerCounter } from './PodcastPlayerCounter';
import { accent, backgroundWhite } from '../../constants/colors';
import { desktopCondition } from '../../constants/media';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${accent.ocean4};
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const StyledThumbnail = styled.img`
  width: 78px;
  height: 78px;
  border-radius: ${BORDER_RADIUS_LARGE};
  margin-right: ${SPACING_24};

  @media ${desktopCondition} {
    display: none;
  }
`;

const StyledTitle = styled(H2)`
  color: ${backgroundWhite};
  margin-bottom: 12px;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${SPACING_8};
`;

const ProgressWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${SPACING_16} 0;
`;

const ControlsWrapper = styled.div`
  width: 210px;
  padding: ${SPACING_8} 0;
`;

const Ellipse = styled.div`
  width: 4px;
  height: 4px;
  border-radius: ${BORDER_RADIUS_SMALL};
  background-color: ${backgroundWhite};
`;

const StyledPodcastPlayerCounter = styled(PodcastPlayerCounter)`
  position: absolute;
  right: 0;
  top: 20px;
`;

const {
  entities: {
    podcastEpisodes: { podcastEpisodesById },
  },
} = selectors;

export const PodcastsPagePlayer = ({ podcastImage }) => {
  const loading = useResource(['PODCAST_EPISODES/INDEX']);
  const podcastEpisodes = useSelector(podcastEpisodesById);
  const episodeId = useSelector((state) => state.ui.podcast.episodeId);

  if (loading || !episodeId) return null;

  const episode = podcastEpisodes[episodeId];

  const { image, duration, publishedAt, title } = episode;

  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledThumbnail src={image ? image : podcastImage} />
        <StyledTitle>{title}</StyledTitle>
      </div>
      <TimeWrapper>
        <Paragraph style={{ color: accent.ocean2 }}>
          {humanFriendlyDate(publishedAt)}
        </Paragraph>
        <Ellipse />
        <Paragraph style={{ color: accent.ocean2 }}>{`${Math.floor(
          duration / 60
        )} min`}</Paragraph>
      </TimeWrapper>

      <ProgressWrapper>
        <PodcastPlayerProgressBar
          colorPrimary="#CCDDEF"
          colorSecondary={accent.ocean470}
        />
        <StyledPodcastPlayerCounter
          colorPrimary={accent.ocean2}
          colorSecondary={accent.ocean470}
        />
      </ProgressWrapper>
      <ControlsWrapper>
        <PodcastPlayerControls
          colorPrimary={accent.ocean4}
          colorSecondary="#babdd0"
          playButtonBackground={backgroundWhite}
        />
      </ControlsWrapper>
    </Container>
  );
};
