import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [feedAllIds, feedById, allFeedItemsSelector] =
  resourceSelectors('feed');

export const feedTypesSelector = createSelector(
  allFeedItemsSelector,
  (allFeedItems) => {
    let types = [];
    const order = ['articles', 'events', 'webinars', 'podcasts'];

    allFeedItems.forEach((item) => {
      if (!types.includes(item.itemType)) types.push(item.itemType);
    });

    types.sort((a, b) => order.indexOf(a) - order.indexOf(b));

    return types;
  }
);
