import React from 'react';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';

import { SubMenuPage } from '../base/SubMenuPage';

const { currentUserSelector } = selectors.ui.user;

const ProfileTitle = () => {
  const user = useSelector(currentUserSelector);
  if (!user) {
    return null;
  }

  const { firstName, middleName, lastName } = user;
  return [firstName, middleName, lastName].filter(Boolean).join(' ');
};

export const ProfilePage = (props) => {
  return (
    <SubMenuPage
      {...props}
      title={ProfileTitle()}
      documentTitle={t('menu:profile')}
    />
  );
};
