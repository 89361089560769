import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { accounts }  from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer('accounts');

// Create the sagas for this entity
function* readAccounts() {
  yield takeEvery(actions.indexRequestBegin, function*() {
    try {
      const result = yield call(accounts.get);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error })
    }
  });
}

export const sagas = [readAccounts, ...resourceSagas];
