import React from 'react';
import styled from 'styled-components';
import { primary, uiActive } from '../../constants/colors';
import { mobileCondition } from '../../constants/media';

const RedDot = styled.span`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${primary};
  display: inline-block;
  translate: 0 calc(-50% + 1px);

  @media ${mobileCondition} {
    translate: 0 calc(-50% + 2px);
    margin-right: 6px;
    background: ${uiActive};
  }
`;

export const UnreadIndicator = (props) => {
  const { isUnread, ...rest } = props;
  return <span {...rest}>{isUnread && <RedDot />}</span>;
};
