import { createActions } from 'redux-actions';

export const actions = createActions({
  PODCAST: {
    SET_TRACK_INDEX: (index) => index,
    SET_IS_PLAYING: (isPlaying) => isPlaying,
    SET_EPISODE_ID: (id) => id,
    SET_PODCAST_ID: (id) => id,
    SET_NEXT_AND_PREVIOUS_EPISODE_ID: (nextId, previousId) => ({
      nextId,
      previousId,
    }),
    SET_TRACK_PROGRESS: (progress, buffered, duration) => ({
      progress,
      buffered,
      duration,
    }),
    SET_IS_READY: (isReady) => isReady,
    SET_AUDIO_REF: (audioRef) => audioRef,
    SET_INTERVAL_REF: (intervalRef) => intervalRef,
  },
}).podcast;
