import React from 'react';
import styled from 'styled-components';

import { accent } from '../../../../constants/colors';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';
import { SPACING_12 } from '../../../../constants/spacing';
import { Paragraph } from '../../../texts';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_12};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

export const TrendBadge = (props) => {
  const {
    positiveBackgroundColor = accent.forest230,
    positiveArrowColor = accent.forest490,
    negativeBackgroundColor = accent.raspberry330,
    negativeArrowColor = accent.raspberry4,
    textColor = accent.ocean490,
    positiveTrend = true,
    direction = 'Down',
    title,
    ...rest
  } = props;

  return (
    <Container {...rest}>
      <IconWrapper
        color={
          positiveTrend ? positiveBackgroundColor : negativeBackgroundColor
        }
      >
        <SpriteIconFunctional
          id="Pointer arrow"
          direction={direction}
          color={positiveTrend ? positiveArrowColor : negativeArrowColor}
          size={15}
        />
      </IconWrapper>
      <Paragraph style={{ color: textColor }}>{title}</Paragraph>
    </Container>
  );
};
