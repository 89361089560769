import { apiFetch } from './utils/api';

// const mockedMeetings = require('./mocks/v2/meetings.json');

export const meetings = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v3/meetings';

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
