import React from 'react';
import styled, { css } from 'styled-components';
import camelcase from 'camelcase';

import { truncateString } from '@formue-app/core';

import { accent, backgroundWhite } from '../../../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
} from '../../../constants/spacing';
import { easeInOut, longAnimationTime } from '../../../constants/animation';

import { Paragraph } from '../../texts';
import { Checkbox } from '../../formElements';
import { CollapsibleListItem } from '../../common/CollapsibleListItem';

const OptionItem = styled.label`
  display: flex;
  gap: ${SPACING_12};
  align-items: center;
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_12};
`;

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  background: ${accent.sand150};
  transition: background ${easeInOut} ${longAnimationTime};
  padding: ${SPACING_24} ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  & + .details {
    transition: background ${easeInOut} ${longAnimationTime};
    background: ${accent.sand150};
    padding: ${SPACING_16};
    padding-top: 0;
    margin-top: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    & > div {
      padding: 0 !important;
    }
  }

  ${(props) =>
    props.enableHover
      ? css`
          &:hover {
            background: ${backgroundWhite};

            & + .details {
              background: ${backgroundWhite};
            }
          }
        `
      : null}
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ToggleFilterGroupDetails = (props) => {
  const { options, title } = props;

  if (!options.length) return null;

  return (
    <OptionsWrapper>
      {options.map((option, index) => {
        const { name, selected, onClick, disabled } = option;
        return (
          <OptionItem
            key={`${camelcase(title)}-${index}`}
            htmlFor={`${camelcase(title)}-${index}`}
          >
            <Paragraph style={{ marginRight: 'auto' }}>{name}</Paragraph>
            <StyledCheckbox
              id={`${camelcase(title)}-${index}`}
              checked={selected}
              onChange={onClick}
              disabled={disabled}
            />
          </OptionItem>
        );
      })}
    </OptionsWrapper>
  );
};

export const ToggleFilterGroupTitle = (props) => {
  const { title, options, showSelected = true } = props;

  const selectedOptions = options.filter((item) => item.selected);

  return (
    <TitleWrapper>
      <Paragraph style={{ marginRight: 'auto' }}>{title}</Paragraph>
      {showSelected ? (
        <Paragraph style={{ fontWeight: 500, marginTop: 0 }}>
          {truncateString(`${selectedOptions.map((item) => item.name)}`, 25)}
        </Paragraph>
      ) : null}
    </TitleWrapper>
  );
};

export const ToggleFilterGroup = (props) => {
  const {
    options,
    title,
    enableHover,
    expandedStyle,
    detailsExpandedStyle,
    ...rest
  } = props;
  if (!options.length) {
    return null;
  }

  return (
    <div>
      <StyledCollapsibleListItem
        expandedStyle={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          background: backgroundWhite,
          ...expandedStyle,
        }}
        detailsExpandedStyle={{
          background: backgroundWhite,
          ...detailsExpandedStyle,
        }}
        showFlipArrow={false}
        details={
          <OptionsWrapper>
            {options.map((option, index) => {
              const { name, selected, onClick, disabled } = option;
              return (
                <OptionItem
                  key={`${camelcase(title)}-${index}`}
                  for={`${camelcase(title)}-${index}`}
                >
                  <Paragraph style={{ marginRight: 'auto' }}>{name}</Paragraph>
                  <StyledCheckbox
                    id={`${camelcase(title)}-${index}`}
                    checked={selected}
                    onChange={onClick}
                    disabled={disabled}
                  />
                </OptionItem>
              );
            })}
          </OptionsWrapper>
        }
        {...rest}
      >
        <Paragraph style={{ marginRight: 'auto' }}>{title}</Paragraph>
      </StyledCollapsibleListItem>
    </div>
  );
};
