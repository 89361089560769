import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategiesAllIds,
  investmentStrategiesById,
  investmentStrategiesSelector,
  investmentStrategiesMetadataSelector,
] = resourceSelectors('investmentStrategies');

export const investmentStrategyByIdSelector = (id) =>
  createSelector(investmentStrategiesById, (byId) => byId[id]);

export const allInvestmentStrategiesSelector = createSelector(
  investmentStrategiesSelector,
  (investmentStrategy) => {
    return investmentStrategy.filter((item) => item.id);
  }
);

export const publishedInvestmentStrategiesSelector = createSelector(
  allInvestmentStrategiesSelector,
  (investmentStrategy) => {
    const publishedStatuses = [
      'selected',
      'active',
      'pendingsignature',
      'signed',
      'archived',
    ];
    return investmentStrategy.filter(
      (item) =>
        publishedStatuses.includes(item?.status?.toLowerCase()) &&
        !Boolean(item?.migrated)
    );
  }
);
