import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { accent } from '../../../../constants/colors';
import { SPACING_4 } from '../../../../constants/spacing';

import { Paragraph, ParagraphSmall } from '../../../texts';
import { BaseSection } from '../../../common/BaseSection';
import { Checkbox } from '../../../formElements';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';

const ListItem = styled(BaseSection)`
  padding: 11px 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;

  ${(props) =>
    props.completed &&
    css`
      background: none;
      padding-left: 0;
    `};
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${SPACING_4};
`;

const EditWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_4};
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0;
`;

export const TaskListItem = (props) => {
  const { index, onEdit, onUpdate, isActiveSlide, item } = props;
  const { id, status, title, assignee } = item;

  const [completed, setCompleted] = useState(status?.toLowerCase());

  useEffect(() => {
    if (status) {
      setCompleted(status.toLowerCase());
    }
  }, [completed, status]);

  const onSubmit = (event) => {
    event.preventDefault();
    onUpdate({
      ...item,
      status: status.toLowerCase() === 'open' ? 'Completed' : 'Open',
    });
  };

  return (
    <ListItem
      completed={completed === 'completed'}
      index={index}
      isActiveSlide={isActiveSlide}
    >
      {onUpdate ? (
        <IconWrapper>
          <StyledCheckbox
            checked={completed === 'completed'}
            onChange={onSubmit}
            size={16}
          />
        </IconWrapper>
      ) : null}
      <TextWrapper>
        <Paragraph style={{ color: accent.ocean490, fontWeight: 500 }}>
          {title}
        </Paragraph>
        {assignee ? <ParagraphSmall>{assignee.name}</ParagraphSmall> : null}
      </TextWrapper>
      {onEdit ? (
        <EditWrapper>
          <SpriteIconFunctional
            id="Edit"
            onClick={() => onEdit(id)}
            style={{ cursor: 'pointer' }}
          />
        </EditWrapper>
      ) : null}
    </ListItem>
  );
};
