import React from 'react';
import styled from 'styled-components';

import { Bubble } from './Bubble';
import { POSITIONS, SIZES } from '../../../../constants/bubbles';
import { tabletCondition } from '../../../../constants/media';

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;

  @media ${tabletCondition} {
    justify-content: flex-start;
  }
`;

export const SectorsBubbleChart = (props) => {
  const {
    style,
    scale = 1,
    animate = true,
    borderColor,
    featuredIconColor,
    defaultIconColor,
    featuredTextColor,
    featuredBackgroundColor,
    sectors = [],
    ...rest
  } = props;

  const renderBubbles = (bubles) => {
    return Object.values(sectors)
      .sort(
        (a, b) => b.partOfKnownSectorPortfolio - a.partOfKnownSectorPortfolio
      )
      .slice(0, 12)
      .map((sector, index) => {
        return (
          <Bubble
            size={SIZES[index]}
            position={POSITIONS[index]}
            scale={scale}
            highlight={index <= 2}
            numberOfCompanies={sector.securities.length}
            animate={animate}
            index={index}
            borderColor={borderColor}
            featuredIconColor={featuredIconColor}
            defaultIconColor={defaultIconColor}
            featuredTextColor={featuredTextColor}
            featuredBackgroundColor={featuredBackgroundColor}
            {...sector}
          />
        );
      });
  };

  return (
    <StyledContainer
      style={{
        ...style,
        height: 330 * scale,
        width: 390 * scale,
        position: 'relative',
      }}
      {...rest}
    >
      {renderBubbles()}
    </StyledContainer>
  );
};
