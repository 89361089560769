import { apiFetch } from './utils/api';

export const documents = {
  get: async () => {
    const options = {
      method: 'GET',
    };
    const url = '/v2/documents';
    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },

  update: async (id) => {
    const payload = {
      data: {
        id: id,
        type: 'documents',
        attributes: {
          read: true,
        },
      },
    };

    const options = {
      method: 'PATCH',
      body: JSON.stringify(payload),
    };
    const url = `/v2/documents/${id}`;
    const { body } = await apiFetch(url, options);
    return body;
  },
};
