import React from 'react';
import styled from 'styled-components';

import { t, toLocaleDateString } from '@formue-app/core';

import {
  SPACING_24,
  SPACING_120,
  SPACING_88,
  SPACING_32,
  SPACING_8,
} from '../../../constants/spacing';

import { H2, Paragraph } from '../../texts';
import { GridElement, MainGrid } from '../../layout';
import { accent } from '../../../constants/colors';
import { MarkdownContent } from '../../common/MarkdownContent';

const Wrapper = styled(MainGrid)`
  flex: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  max-width: 1232px;
`;

const StyledGridElement = styled(GridElement)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  padding: 0;
  margin: 0;
`;

const SignaturLine = styled(GridElement)`
  border-bottom: 1px solid ${accent.ocean440};
  padding-top: ${SPACING_32};
`;

const FancyParagraph = styled(Paragraph)`
  translate: 0 calc(100% + ${SPACING_8});
`;

export const ClientSignature = (props) => {
  const { title, i18nKey } = props;

  return (
    <Wrapper>
      <StyledGridElement
        columns={7}
      >
        <H2>{t(title)}</H2>
        <div>
          <MarkdownContent>{t(i18nKey, { date: toLocaleDateString(new Date()) })}</MarkdownContent>
        </div>
      </StyledGridElement>
      <SignaturLine columns={3} style={{ gridColumn: 'span 3 / 4' }}>
        <Paragraph>
          {t('advisor:strategySimulator:export:signature:place')}:
        </Paragraph>
      </SignaturLine>
      <SignaturLine columns={3}>
        <Paragraph>
          {t('advisor:strategySimulator:export:signature:date')}:
        </Paragraph>
      </SignaturLine>
      <SignaturLine columns={6} style={{ gridColumn: 'span 6 / 7' }}>
        <FancyParagraph style={{}}>
          {t('advisor:strategySimulator:export:signature:signature')}
        </FancyParagraph>
      </SignaturLine>
    </Wrapper>
  );
};
