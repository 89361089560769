import React, { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { accent } from '../../constants/colors';
import { notDesktopCondition } from '../../constants/media';
import { SPACING_24, SPACING_16 } from '../../constants/spacing';
import { BaseSection } from '../common/BaseSection';
import { Modal } from '../common/Modal';
import { H3 } from '../texts';

import { SpriteIcon } from '../common/SpriteIcon';

const StyledFieldset = styled.fieldset`
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
`;

const StyledLegend = styled.legend`
  padding: 0 35px ${SPACING_16} 0;
  margin-bottom: ${SPACING_16};
  width: 100%;
  border-bottom: 1px solid ${accent.sand3};
  position: relative;

  svg {
    position: absolute;
    top: 1px;
    right: 0;
    float: right;
  }
`;

export const Fieldset = (props) => {
  const { legend, helpText, children, style } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({
    query: notDesktopCondition,
  });

  const showHelpText = helpText && isTabletOrMobile;

  return (
    <BaseSection style={{ ...style, marginBottom: SPACING_24 }}>
      <StyledFieldset>
        <StyledLegend>
          <H3>{legend}</H3>
          {showHelpText && (
            <SpriteIcon
              id="Info"
              size={SPACING_24}
              onClick={() => setModalIsOpen(true)}
              style={{ cursor: 'pointer' }}
            />
          )}
        </StyledLegend>
        {children}
      </StyledFieldset>

      {showHelpText && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
        >
          {helpText}
        </Modal>
      )}
    </BaseSection>
  );
};
