import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors } from '@formue-app/core';
import { GridElement, MainGrid } from '../../layout';
import { SustainableShareOfPortfolio } from './sustainabilityPreferences/SustainableShareOfPortfolio';
import { ImproveNegativeImpactsByMonitoring } from './sustainabilityPreferences/ImproveNegativeImpactsByMonitoring';
import { EngagementStrategy } from './sustainabilityPreferences/EngagementStrategy';
import { ImproveNegativeImpactsByInvesting } from './sustainabilityPreferences/ImproveNegativeImpactsByInvesting';
import { FormueHouseViewMessage } from './sustainabilityPreferences/FormueHouseViewMessage';

const {
  entities: {
    investmentProfile: { sustainabilityPreferencesSelector },
  },
} = selectors;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

export const SustainabilityPreferences = (props) => {
  const sustainabilityPreferences = useSelector(
    sustainabilityPreferencesSelector
  );

  const {
    hasPreferences,
    shareOfPortfolioToAllocate,
    engagementStrategy,
    improveNegativeImpactsBy,
    investingThemes,
  } = sustainabilityPreferences;

  return (
    <StyledSubGrid>
      <GridElement columns={7}>
        <StyledSubGrid>
          {!hasPreferences ? <FormueHouseViewMessage columns={12} /> : null}
          <SustainableShareOfPortfolio
            columns={12}
            shareOfPortfolioToAllocate={shareOfPortfolioToAllocate}
            hasPreferences={hasPreferences}
          />
          <EngagementStrategy
            engagementStrategy={engagementStrategy}
            hasPreferences={hasPreferences}
            columns={12}
          />
        </StyledSubGrid>
      </GridElement>
      {improveNegativeImpactsBy === 'InvestInSpecificThemes' ? (
        <ImproveNegativeImpactsByInvesting
          columns={5}
          investingThemes={investingThemes}
        />
      ) : (
        <ImproveNegativeImpactsByMonitoring columns={5} />
      )}
    </StyledSubGrid>
  );
};
