import React from 'react';
import styled from 'styled-components';
import { times } from 'lodash';

import { accent, backgroundWhite } from '../../constants/colors';
import { ParagraphSmall } from '../texts';
import { BORDER_RADIUS_SMALL, SPACING_12 } from '../../constants/spacing';

const ChartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_12};
  align-items: center;
`;

const Bar = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const BarItem = styled.div`
  border-radius: ${BORDER_RADIUS_SMALL};
`;

export const StackedChart = (props) => {
  const {
    maxItems = 5,
    defaultColor = backgroundWhite,
    barHeight = 8,
    barWidth = 80,
    barSpacing = 4,
    labelColor = accent.ocean490,
    data = [],
    ...rest
  } = props;
  return (
    <ChartWrapper {...rest}>
      {data.map((dataItem, dataItemindex) => {
        return (
          <BarWrapper key={`bar-${dataItemindex}`}>
            <Bar style={{ gap: barSpacing, width: barWidth }}>
              {times(maxItems, (barItemIndex) => (
                <BarItem
                  key={`bar-item-${barItemIndex}`}
                  style={{
                    backgroundColor:
                      barItemIndex < dataItem.value
                        ? dataItem.color
                        : defaultColor,
                    height: barHeight,
                  }}
                />
              ))}
            </Bar>
            {dataItem.label && (
              <ParagraphSmall style={{ color: labelColor }}>
                {dataItem.label}
              </ParagraphSmall>
            )}
          </BarWrapper>
        );
      })}
    </ChartWrapper>
  );
};
