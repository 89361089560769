import React from 'react';
import styled, { css } from 'styled-components';

import { mobileCondition } from '../../constants/media';
import {
  paragraphSize,
  paragraphLineHeight,
  paragraphSizeMobile,
  paragraphLineHeightMobile,
} from '../../constants/text';

import { textLink } from '../../constants/colors';

export const paragraphStyle = css`
  font-size: ${paragraphSize};
  line-height: ${paragraphLineHeight};
  font-weight: 400;

  /* @media ${mobileCondition} {
    font-size: ${paragraphSizeMobile};
    line-height: ${paragraphLineHeightMobile};
  } */

  a {
    color: ${textLink};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledParagraph = styled.p`
  ${paragraphStyle}
`;

export const Paragraph = (props) => {
  const { children } = props;

  return <StyledParagraph {...props}>{children}</StyledParagraph>;
};
