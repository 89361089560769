import React from 'react';
import styled, { css } from 'styled-components';

import { t } from '@formue-app/core';
import { accent } from '../../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_16,
} from '../../../../constants/spacing';
import { H4, ParagraphSmall } from '../../../texts';
import { CheckIcon } from '../../../common/CheckIcon';
import {
  easeInOut,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  background-color: ${(props) =>
    props.checked ? accent.ocean2 : accent.ocean1};
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${(props) =>
    slideAnimationDelay + 0.2 * props.index + slideAnimationDelay}s;
  opacity: 0;
  top: 20px;
  position: relative;

  ${(props) =>
    props.isActiveSlide &&
    css`
      opacity: 1;
      top: 0;
    `}

  ${(props) =>
    props.checked
      ? css`
          border: 1px solid ${accent.ocean1};
        `
      : css`
          border: 1px dashed ${accent.ocean270};
        `}

  h4 {
    color: ${(props) => (props.checked ? accent.ocean1 : accent.ocean2)};
  }
`;

const SelectIcon = styled.div`
  background: none;
  margin-right: ${SPACING_16};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InvestmentPreferencesChoiceItem = (props) => {
  const {
    choices,
    choiceTypes,
    itemIndex,
    activeChoiceId,
    setActiveChoiceId,
    isActiveSlide,
  } = props;

  const choice = choiceTypes[itemIndex];
  const title = t(
    `wealthPlan:investmentProfile:preferencesTypes:${choice}:title`
  );
  const text = t(
    `wealthPlan:investmentProfile:preferencesTypes:${choice}:text`
  );
  const selected = choices.includes(choice);

  return (
    <Item
      checked={selected}
      onClick={() =>
        setActiveChoiceId(activeChoiceId === itemIndex ? null : itemIndex)
      }
      key={`choice-index-${itemIndex}`}
      index={itemIndex}
      isActiveSlide={isActiveSlide}
    >
      <TitleWrapper>
        <SelectIcon checked={selected}>
          <CheckIcon
            checked={selected}
            color={selected ? accent.ocean4 : accent.ocean270}
          />
        </SelectIcon>
        <H4 key={itemIndex}>{title}</H4>
      </TitleWrapper>
      {activeChoiceId === itemIndex && (
        <ParagraphSmall
          style={{
            marginTop: SPACING_16,
            color: selected ? accent.ocean490 : accent.ocean2,
          }}
        >
          {text}
        </ParagraphSmall>
      )}
    </Item>
  );
};
