import { createSelector } from 'reselect';

import { resourceSelectors } from '../../utils/resourceSelectors';

export const [strategyAllIds, strategyById, allStrategiesSelector] =
  resourceSelectors('strategy');

export const strategyHasContent = createSelector(strategyAllIds, (allIds) => {
  return !!allIds.length;
});
