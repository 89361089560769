import React from 'react';
import styled from 'styled-components';

import { accent, textLightest } from '../../constants/colors';
import { BORDER_RADIUS_LARGE } from '../../constants/spacing';
import { ParagraphXSmall } from '../texts';

const StyledPill = styled.div`
  background-color: ${accent.ocean270};
  border-radius: ${BORDER_RADIUS_LARGE};
  padding-inline: 5px;
`;

export const FilterSelectionPill = ({ data, ...rest }) => {
  return (
    <StyledPill {...rest}>
      <ParagraphXSmall
        style={{
          marginRight: 'auto',
          fontWeight: 500,
          color: textLightest,
        }}
      >
        {data.join(' | ')}
      </ParagraphXSmall>
    </StyledPill>
  );
};
