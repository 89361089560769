import { apiFetch } from './utils/api';

export const presentationGenerate = {
  post: async (payload) => {
    const options = {
      method: 'POST',
      body: payload,
    };

    const url = '/v3/presentations/annual-review/archive';
    return apiFetch(url, options);
  },
};

export const investmentStrategyExport = {
  post: async (payload) => {
    const options = {
      method: 'POST',
      body: payload,
    };

    const url = '/v3/presentations/investment-strategy-export/archive';
    return apiFetch(url, options);
  },
};
