import React from 'react';
import styled from 'styled-components';

import {
  generateSpeedometerRiskChartColorsInvers,
  riskAndLockinValueToText,
} from '@formue-app/core';

import { SPACING_24, SPACING_48 } from '../../../constants/spacing';
import {
  accent,
  backgroundWhite,
  textLightest,
} from '../../../constants/colors';

import { Modal } from '../../common/Modal';
import { H3 } from '../../texts';
import { SpeedometerChart } from '../../graphs/SpeedometerChart';
import { HeaderWithBackgroundImage } from '../../common/HeaderWithBackgroundImage';

const ContentWrapper = styled.div`
  padding: ${SPACING_48};
`;

const Label = styled(H3)`
  margin-top: -22px;
  text-align: center;
  color: ${textLightest};
`;

export const InvestmentProfileModal = (props) => {
  const { title, subTitle, value, children, ...rest } = props;

  return (
    <Modal
      {...rest}
      padding={0}
      buttonPadding={SPACING_24}
      iconColor={textLightest}
    >
      <HeaderWithBackgroundImage
        title={title}
        subtitle={subTitle}
        textColor={accent.ocean250}
        backgroundColor={accent.ocean4}
        fullSize={true}
        sideChildren={true}
      >
        <div style={{ height: '100%', display: 'flex' }}>
          <div style={{ margin: 'auto auto 0' }}>
            <SpeedometerChart
              value={value}
              pieCount={3}
              padAngle={3}
              cornerRadius={4}
              startAngle={-90}
              endAngle={90}
              width={200}
              height={100}
              arrowPosition="top"
              colorScale={generateSpeedometerRiskChartColorsInvers(value)}
              pointerColor={backgroundWhite}
              style={{ margin: '0 auto' }}
            />
            <Label>{riskAndLockinValueToText(value)}</Label>
          </div>
        </div>
      </HeaderWithBackgroundImage>

      <ContentWrapper>{children}</ContentWrapper>
    </Modal>
  );
};
