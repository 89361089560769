import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategyProductsAllIds,
  investmentStrategyProductsById,
  investmentStrategyProductsSelector,
] = resourceSelectors('investmentStrategyProducts');

export const investmentStrategyProductsByStrategyIdSelector = (strategyId) =>
  createSelector(investmentStrategyProductsSelector, (allProducts) => (
    allProducts.filter((product) => product.investmentStrategy.id == strategyId)
  ));
