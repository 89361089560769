import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { H6 } from '../texts';
import { accent } from '../../constants/colors';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 12px;
`;

const pad = (d) => {
  return d < 10 ? '0' + d.toString() : d.toString();
};

const formatTime = (seconds) => {
  return pad(Math.floor(seconds / 60)) + ':' + pad(Math.floor(seconds % 60));
};

export const PodcastPlayerCounter = ({
  colorPrimary = accent.ocean490,
  colorSecondary = accent.ocean470,
}) => {
  const podcastUi = useSelector((state) => state.ui.podcast);
  const { duration, trackProgress } = podcastUi;

  if (!duration || !trackProgress) return <StyledWrapper />;

  const current = formatTime(trackProgress);
  const remaining = formatTime(duration);

  return (
    <StyledWrapper>
      <H6 style={{ color: colorPrimary, marginRight: 2 }}>{current}</H6>
      <H6 style={{ color: colorSecondary }}>{`/ ${remaining}`}</H6>
    </StyledWrapper>
  );
};
