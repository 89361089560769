import React from 'react';
import styled from 'styled-components';

import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_32,
  SPACING_48,
} from '../../../../constants/spacing';
import { accent, backgroundWhite } from '../../../../constants/colors';

const StyledContainer = styled.div`
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${SPACING_32};
  background-color: ${(props) =>
    props.highlighted ? accent.neutral150 : backgroundWhite};
`;

const StyledWrapper = styled.div`
  max-width: ${(props) => props.maxWidth}px;
`;

export const ModalSection = ({
  highlighted = true,
  maxWidth = 600,
  children,
  ...rest
}) => {
  return (
    <StyledContainer highlighted={highlighted} {...rest}>
      <StyledWrapper maxWidth={maxWidth}>{children}</StyledWrapper>
    </StyledContainer>
  );
};
