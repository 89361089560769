import React from 'react';
import styled from 'styled-components';

import { shortAnimationTime } from '../../constants/animation';
import { backgroundWhite, textLightest } from '../../constants/colors';
import { SPACING_2 } from '../../constants/spacing';
import { paragraphSize } from '../../constants/text';
import { Paragraph } from '../texts';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

const StyledButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 90px;
  cursor: pointer;
  align-items: center;
  background-color: ${backgroundWhite};
  outline: none;
  border: none;
  padding: 9px 12px 11px 12px;
  border-radius: 48px;
  z-index: 10;
`;

const Label = styled(Paragraph)`
  font-weight: 500;
  line-height: ${paragraphSize};
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  transition: ${shortAnimationTime};
  margin-top: ${SPACING_2};
  ${(props) =>
    !props.active &&
    `
    rotate: z 45deg;
  `};
`;

export const FiltersButton = ({ active, onClick, ...rest }) => {
  return (
    <StyledButton onClick={onClick} {...rest}>
      <Label>{active ? 'Close' : 'Filter'}</Label>
      <StyledSpriteIconFunctional
        id="Close"
        color={textLightest}
        size={16}
        active={active}
      />
    </StyledButton>
  );
};
