import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { clients } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { actions as uiImpersonationActions } from '../../ui/impersonation';

// Create actions and a reducer for the clients resource
export const [actions, reducer] = resourceReducer('clients');

// Create the sagas for this entity
function* readClients() {
  yield takeEvery(actions.indexRequestBegin, function*() {
    try {
      const result = yield call(clients.get);
      const normalized = normalize(result);
      yield put(uiImpersonationActions.hydrateRecentlyImpersonatedClientIds());
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error })
    }
  });
}

export const sagas = [readClients];
