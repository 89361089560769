import moment from 'moment';
import jwtDecode from 'jwt-decode';

import { AsyncStorage } from '../../services/AsyncStorage';
import { Sentry } from '../../services/sentry';
import { config } from '../../config';
import { getAccessToken } from '../../services/tokens';
import {
  LANGUAGE_TO_LANGUAGE_HEADER,
  COUNTRY_CODE_TO_LANGUAGE_HEADER,
} from '../../services';

import { AuthError, ApiError } from '../errors';
// import { saveMocks } from './saveMocks';

let impersonateId = null;

/**
 * Create a User-Agent string for this client
 *
 * User-Agent: Name/version (Mobile; Platform; bundleId; countryCode)
 *
 * Native client:
 * User-Agent: FormueApp/2020.04.2312 (Mobile; iOS; no.formue.formue.no; no)
 *
 * Web client:
 * User-Agent: FormueApp/2020.04.2312 (Web; iOS; no.formue.formue.no; no)
 */
export const getUserAgent = (options) => {
  const name = 'FormueApp';
  const {
    app: { bundleId, appVersion, platform, devicePlatform },
    countryCode: country,
  } = config;

  let countryCode = country;
  // Use country code from options if it is provided
  if (options && options.country) {
    countryCode = options.country;
  }

  return `${name}/${appVersion} (${platform}; ${devicePlatform}; ${bundleId}; ${countryCode})`;
};

/**
 * Create accept language string, we send the user selected locale
 * as the highest priority one, but fallbacks to default country code
 * and wildcard as the lowest priority one.
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language
 * https://developer.mozilla.org/en-US/docs/Glossary/quality_values
 *
 * Eg. en;q=1, no;q=0.8 *;q=0.5
 */
const getAcceptLanguage = async () => {
  const languages = [
    `${COUNTRY_CODE_TO_LANGUAGE_HEADER[config.countryCode]};q=0.8`,
    '*;q=0.5',
  ];

  try {
    const selectedLanguage = await AsyncStorage.getItem('language');
    if (selectedLanguage) {
      const headerValue = `${LANGUAGE_TO_LANGUAGE_HEADER[selectedLanguage]};q=1`;
      headerValue && languages.unshift(headerValue);
    }
  } catch (e) {
    // we don't really care if this fails, we fall back to the other languages
  }

  return languages.join(' ');
};

export const apiFetch = async (
  relativeUrl,
  options,
  headers = {},
  impersonate = true
) => {
  const {
    app: { demoMode, bundleId },
    api: { host },
  } = config;

  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'User-Agent': getUserAgent(options),
    'X-User-Agent': getUserAgent(options),
    'X-App-Platform': config.app.platform,
    'X-App-Identifier': bundleId,
    ...headers,
  };

  const normalizedRelativeUrl =
    relativeUrl.substring(0, 1) === '/'
      ? relativeUrl.substring(1)
      : relativeUrl;

  const baseUrl = options.baseUrl || config.api.baseUrl;
  const normalizedBaseUrl =
    baseUrl.substring(-1) === '/' ? baseUrl : `${baseUrl}/`;

  const url = `${host}${normalizedBaseUrl}${normalizedRelativeUrl}`;
  let accessToken;

  // Only try to get the access token if we are not in demo mode
  if (!demoMode) {
    accessToken = await getAccessToken();
  }

  // Check if access token is about to expire, if it is, then re-authenticate
  // the user
  if (accessToken) {
    const parsedToken = jwtDecode(accessToken);
    const now = moment().format('X');
    if (parsedToken.exp - 60 <= now) {
      throw new AuthError('Token is about to expire, refusing to use it');
    }
  }

  let body;
  if (options.body) {
    body =
      typeof options.body === 'string'
        ? options.body
        : JSON.stringify(options.body);
  }

  headers.Authorization = `Bearer ${accessToken}`;
  headers['Accept-Language'] = await getAcceptLanguage();

  if (impersonateId && impersonate) {
    headers['x-impersonate-id'] = impersonateId;
  }

  // If we are in demo mode, we will change the headers to include a custom
  // HTTP header that will prevent us from needing to authenticate and will
  // return some dummy data.
  if (demoMode) {
    headers['X-Demo-Request'] = true;
  }

  const response = await fetch(url, {
    ...options,
    headers,
    body,
  });

  if (response.status < 200 || response.status >= 300) {
    Sentry.addBreadcrumb({
      category: 'api',
      level: 'info',
      data: {
        url,
        response,
        demoMode,
      },
    });
    const errorMessage = response.statusText
      ? response.statusText
      : 'Heroku Fetch Error';

    const error = new ApiError(relativeUrl, errorMessage);
    error.response = response;
    error.status = response.status;
    throw error;
  }

  const { status, headers: responseHeaders } = response;
  // If response code is 204, then return null, if not, try to parse json
  // 204 No Content means that the response is successfull, but there is no
  // response body, and thusly, it would cause an error trying to parse it.
  const responseBody = status === 204 ? null : await response.json();

  // Enable this if you want to save all API requests in .mock format for use
  // with the demo mode of the API.
  // await saveMocks(normalizedRelativeUrl, options, response, responseBody)

  return {
    body: responseBody,
    headers: responseHeaders,
    status,
  };
};

export const apiImpersonate = ssid => {
  impersonateId = ssid;
};
