import { createSelector } from 'reselect';
import camelcase from 'camelcase';

import { CONTINENTS, COUNTRY_CODES } from '../../../constants/geography';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  securitiesAllIdsSelector,
  securitiesByIdSelector,
  allSecuritiesSelector,
] = resourceSelectors('securities');

export const allSecurities = createSelector(
  allSecuritiesSelector,
  (allItems) => {
    return allItems
      .filter((item) => item.type === 'security')
      .sort((a, b) => b.partOfPortfolio - a.partOfPortfolio);
  }
);

export const allFunds = createSelector(allSecuritiesSelector, (allItems) => {
  return allItems
    .filter((item) => item.type === 'fund')
    .sort((a, b) => b.partOfPortfolio - a.partOfPortfolio);
});

export const securitiesMetadataSelector = (state) =>
  state.entities.securities.meta;

export const allStabilitySecurities = createSelector(
  allSecurities,
  (allItems) => {
    return allItems
      .filter((item) =>
        item.assetParts.find((_item) => _item.assetGroup === 'S')
      )
      .map((item) => {
        const part = item.assetParts.find((_item) => _item.assetGroup === 'S');

        return {
          ...item,
          marketValue: part.marketValue,
          partOfPortfolio: part.partOfPortfolio,
        };
      })
      .sort((a, b) => b.partOfPortfolio - a.partOfPortfolio);
  }
);

export const allStabilityFunds = createSelector(allFunds, (allItems) => {
  return allItems
    .filter((item) => item.assetParts.find((_item) => _item.assetGroup === 'S'))
    .map((item) => {
      const part = item.assetParts.find((_item) => _item.assetGroup === 'S');

      return {
        ...item,
        marketValue: part.marketValue,
        partOfPortfolio: part.partOfPortfolio,
      };
    })
    .sort((a, b) => b.partOfPortfolio - a.partOfPortfolio);
});

export const allMarketGrowthSecurities = createSelector(
  allSecurities,
  (allItems) => {
    return allItems
      .filter((item) =>
        item.assetParts.find((_item) => _item.assetGroup === 'MV')
      )
      .map((item) => {
        const part = item.assetParts.find((_item) => _item.assetGroup === 'MV');

        return {
          ...item,
          marketValue: part.marketValue,
          partOfPortfolio: part.partOfPortfolio,
        };
      })
      .sort((a, b) => b.partOfPortfolio - a.partOfPortfolio);
  }
);

export const allMarketGrowthFunds = createSelector(allFunds, (allItems) => {
  return allItems
    .filter((item) =>
      item.assetParts.find((_item) => _item.assetGroup === 'MV')
    )
    .map((item) => {
      const part = item.assetParts.find((_item) => _item.assetGroup === 'MV');

      return {
        ...item,
        marketValue: part.marketValue,
        partOfPortfolio: part.partOfPortfolio,
      };
    })
    .sort((a, b) => b.partOfPortfolio - a.partOfPortfolio);
});

export const allAlternativeGrowthSecurities = createSelector(
  allSecurities,
  (allItems) => {
    return allItems
      .filter((item) =>
        item.assetParts.find((_item) => _item.assetGroup === 'AV')
      )
      .map((item) => {
        const part = item.assetParts.find((_item) => _item.assetGroup === 'AV');

        return {
          ...item,
          marketValue: part.marketValue,
          partOfPortfolio: part.partOfPortfolio,
        };
      })
      .sort((a, b) => b.partOfPortfolio - a.partOfPortfolio);
  }
);

export const allAlternativeGrowthFunds = createSelector(
  allFunds,
  (allItems) => {
    return allItems
      .filter((item) =>
        item.assetParts.find((_item) => _item.assetGroup === 'AV')
      )
      .map((item) => {
        const part = item.assetParts.find((_item) => _item.assetGroup === 'AV');

        return {
          ...item,
          marketValue: part.marketValue,
          partOfPortfolio: part.partOfPortfolio,
        };
      })
      .sort((a, b) => b.partOfPortfolio - a.partOfPortfolio);
  }
);

export const securitiesByContinentSelector = createSelector(
  allSecuritiesSelector,
  (allItems) => {
    return allItems
      .filter((item) => CONTINENTS.includes(item.continent))
      .reduce(
        (
          accumulator,
          {
            continent,
            marketValue,
            partOfPortfolio,
            partOfKnownPortfolio,
            id,
            countryCode,
          }
        ) => {
          if (accumulator.hasOwnProperty(continent)) {
            accumulator[continent].securities.push(id);
            accumulator[continent].marketValue += marketValue;
            accumulator[continent].partOfPortfolio += partOfPortfolio;
            accumulator[continent].partOfKnownPortfolio += partOfKnownPortfolio;
            if (!accumulator[continent].countries.includes(countryCode))
              accumulator[continent].countries.push(countryCode);
          } else if (CONTINENTS.includes(continent)) {
            accumulator[continent] = {
              securities: [id],
              countries: [countryCode],
              marketValue: marketValue,
              partOfPortfolio: partOfPortfolio,
              partOfKnownPortfolio: partOfKnownPortfolio,
              continent: continent,
            };
          }
          return accumulator;
        },
        {}
      );
  }
);

export const securitiesByCountrySelector = createSelector(
  allSecuritiesSelector,
  (allItems) => {
    return allItems.reduce(
      (
        accumulator,
        {
          continent,
          marketValue,
          partOfPortfolio,
          partOfKnownPortfolio,
          id,
          countryCode,
        }
      ) => {
        // Skip the securities with countryCode: "null"
        if (!countryCode) return accumulator;

        // If the accumulator already has a securities country code
        // push the security under that key
        if (accumulator.hasOwnProperty(countryCode)) {
          accumulator[countryCode].securities.push(id);
          accumulator[countryCode].marketValue += marketValue;
          accumulator[countryCode].partOfPortfolio += partOfPortfolio;
          accumulator[countryCode].partOfKnownPortfolio += partOfKnownPortfolio;
        } else {
          // If the accumulator doesn't have a securities country code
          // and country code is recognized as a legit one, create the
          // accumulators key and push the security there
          if (Object.keys(COUNTRY_CODES).includes(countryCode)) {
            accumulator[countryCode] = {
              securities: [id],
              continent,
              marketValue,
              partOfPortfolio,
              partOfKnownPortfolio,
              countryCode,
            };
          } else {
            // Handle securities that contain a country code but the code
            // doesn't belong to a real country
            // for example: 'MULT', 'SNAT', '-', etc.
            if (accumulator.hasOwnProperty('other')) {
              accumulator.other.securities.push(id);
              accumulator.other.marketValue += marketValue;
              accumulator.other.partOfPortfolio += partOfPortfolio;
              accumulator.other.partOfKnownPortfolio += partOfKnownPortfolio;
              accumulator.other.countryCode = null;
            } else {
              accumulator.other = {
                securities: [id],
                marketValue,
                partOfPortfolio,
                partOfKnownPortfolio,
                countryCode,
              };
            }
          }
        }
        return accumulator;
      },
      {}
    );
  }
);

export const continentsByMarketValue = createSelector(
  securitiesByContinentSelector,
  (continents) => {
    return continents.reduce((accumulator, item, currentIndex, array) => {
      if (accumulator.hasOwnProperty(item.continent)) {
        accumulator[item.continent].push(item);
      } else if (CONTINENTS.includes(item.continent)) {
        accumulator[item.continent] = [item];
      }
      return accumulator;
    }, {});
  }
);

export const securitiesBySectorSelector = createSelector(
  allSecuritiesSelector,
  (securities) => {
    return securities.reduce(
      (
        accumulator,
        { id, sector, marketValue, partOfPortfolio, partOfKnownSectorPortfolio }
      ) => {
        // Skip the securities with sector: "null" or "-"
        if (!sector || sector === '-') return accumulator;

        const sectorSlug = camelcase(sector);

        if (accumulator.hasOwnProperty(sectorSlug)) {
          accumulator[sectorSlug].securities.push(id);
          accumulator[sectorSlug].marketValue += marketValue;
          accumulator[sectorSlug].partOfPortfolio += partOfPortfolio;
          accumulator[sectorSlug].partOfKnownSectorPortfolio +=
            partOfKnownSectorPortfolio;
        } else {
          accumulator[sectorSlug] = {
            securities: [id],
            marketValue,
            partOfPortfolio,
            partOfKnownSectorPortfolio,
            sector: sectorSlug,
          };
        }
        return accumulator;
      },
      {}
    );
  }
);
