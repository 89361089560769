import React from 'react';

import { ReactComponent as ShapeTriangle } from '../../../assets/wealth-plan/shape-triangle.svg';
import { ReactComponent as ShapeRectangle } from '../../../assets/wealth-plan/shape-rectangle.svg';
import { ReactComponent as ShapeRoundedRectangle } from '../../../assets/wealth-plan/shape-rounded-rectangle.svg';

import { SpriteIcon } from '../../common/SpriteIcon';

const categories = {
  safety: {
    icon: () => <SpriteIcon id="Safety" size={24} />,
    shape: () => <ShapeTriangle />,
  },
  preserveAndGrow: {
    icon: () => <SpriteIcon id="Preserve and Develop" size={24} />,
    shape: () => <ShapeRoundedRectangle />,
  },
  create: {
    icon: () => <SpriteIcon id="Create" size={24} />,
    shape: () => <ShapeRectangle />,
  },
};

export const ShapeChart = ({ category }) => {
  if (!category) return null;

  return (
    <div
      style={{
        width: '100%',
        height: 117,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ marginBottom: 4 }}>{categories[category].icon()}</div>
      {categories[category].shape()}
    </div>
  );
};
