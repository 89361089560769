import {
  getAssetClassName,
  getColorForAssetClassGroup,
} from '@formue-app/core';

export const sumAssetClassGroups = (assetClass, colorScheme) => {
  const uniqueAssetClass = assetClass.map((item) => item.assetClassGroup);
  return [...new Set(uniqueAssetClass)].map((item) => {
    const allInAssetClass = assetClass.filter(
      (asset) => asset.assetClassGroup === item
    );

    const value = allInAssetClass.reduce((a, { share }) => a + share, 0);

    return {
      value,
      color: getColorForAssetClassGroup(item, colorScheme),
      label: getAssetClassName(item),
      group: item,
    };
  });
};
