import { useState, useEffect } from 'react';

/**
 * This hook will connect to Qlik using the provided Qlik app instance, fetch
 * all bookmarks for this app (scoped to the authenticated user) and return a
 * list of all bookmarks, as well as three methods, create, apply and remove.
 * 
 * @param {*} app - The Qlik app instance
 * @returns {Array.<{bookmarks: Array, create: Function, apply: Function, remove: Function}>}
 * [
 *   [],
 *   (title, description) => void,
 *   (id) => void,
 *   (id) => void,
 * ]
 */
export const useQlikBookmarks = (app) => {
  const [bookmarks, setBookmarks] = useState([]);
  // To fetch the list of bookmarks for an app in Qlik we need to create a
  // "genericObject" in Qlik, where we send in some props. In return we get a
  // list of all bookmarks in this app.
  useEffect(() => {
    if (!app) { return; }

    const bookmarkListProps = {
      qInfo: {
        qId: 'BookmarkList',
        qType: 'BookmarkList',
      },
      qBookmarkListDef: {
        qType: 'bookmark',
        qData: {
          title: '/qMetaDef/title',
          description: '/qMetaDef/description',
          //sheetId: '/sheetId',
          selectionFields: '/selectionFields',
          creationDate: '/creationDate',
        },
      },
    };
  
    const asyncWrapper = async () => {
      const bookmarkListModel = await app.createGenericObject(bookmarkListProps);
 
      // callback function for when something changes and we want to update the
      // dataset for the bookmarks list
      const update = () => {
        bookmarkListModel.getLayout().then((layout) => {
          const { qItems } = layout.qBookmarkList;
          // At this point we have the bookmark list items, we can
          // update a local react state variable with this data
          setBookmarks(qItems.map((item) => (
            {
              id: item.qInfo.qId,
              title: item.qData.title,
              description: item.qData.description,
              selectionFields: item.qData.selectionFields,
            }
          )));
        });
      };
    
      // Make sure we attach an event handler to the changed event on the
      // qlik bookmark list model, that way we can update our local react
      // state whenever there is changes (e.g we add or remove a bookmark).
      bookmarkListModel.on('changed', update);

      // Call on update intially to fetch bookmark lists before any changes
      // might have occured.
      update();
    }
    asyncWrapper();  
  }, [app]);

  const createBookmark = (title, description) => {
    if (!app || !title) { return; }
    // https://help.qlik.com/en-US/sense-developer/May2021/Subsystems/APIs/Content/Sense_ClientAPIs/CapabilityAPIs/BookmarkAPI/create-bookmark-method.htm
    app.bookmark.create(title, description);
  }

  const applyBookmark = (bookmarkId) => {
    if (!app) { return; }
    // https://help.qlik.com/en-US/sense-developer/May2021/Subsystems/APIs/Content/Sense_ClientAPIs/CapabilityAPIs/BookmarkAPI/apply-bookmark-method.htm
    app.bookmark.apply(bookmarkId);
  }

  const removeBookmark = (bookmarkId) => {
    if (!app) { return; }
    // https://help.qlik.com/en-US/sense-developer/May2021/Subsystems/APIs/Content/Sense_ClientAPIs/CapabilityAPIs/BookmarkAPI/remove-bookmark-method.htm
    app.bookmark.remove(bookmarkId);
  }

  return [bookmarks, createBookmark, applyBookmark, removeBookmark];
}
