import React from 'react';

import { GridElement } from '../layout/GridElement';
import { SPACING_16, SPACING_4 } from '../../constants/spacing';
import { H2, ParagraphSmall } from '../texts';
import styled from 'styled-components';

const StyledGridElement = styled(GridElement)`
  display: flex;
  flex-direction: column;
`;

export const SectionWithTitle = ({
  title,
  subTitle,
  children,
  columns = 12,
  ...rest
}) => {
  return (
    <StyledGridElement columns={columns} {...rest}>
      <div style={{ paddingBottom: SPACING_16 }}>
        <H2>{title}</H2>
        {subTitle ? (
          <ParagraphSmall style={{ marginTop: SPACING_4 }}>
            {subTitle}
          </ParagraphSmall>
        ) : null}
      </div>
      {children}
    </StyledGridElement>
  );
};
