import React from 'react';
import styled from 'styled-components';

import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';

import { MainGrid } from '../../layout';

import { BusinessAndOwnership } from '../../wealthPlan/familyAndOwnership/BusinessAndOwnership';
import { Family } from '../../wealthPlan/familyAndOwnership/Family';
import { Milestone } from '../../wealthPlan/familyAndOwnership/Milestone';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

export const FamilyAndOwnershipDetails = (props) => {
  const { isActiveSlide } = props;

  if (!isActiveSlide) {
    return null;
  }

  return (
    <Wrapper>
      <Milestone />
      <Family />
      <BusinessAndOwnership />
    </Wrapper>
  );
};
