import React from 'react';
import { VictoryPie } from 'victory';
import styled from 'styled-components';

import {
  t,
  generateOrganizationAccentColors,
  percentage,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { SPACING_16 } from '../../../constants/spacing';
import { GridElement } from '../../layout';
import { BaseSection } from '../../common/BaseSection';
import { H6, H4, H3, Paragraph } from '../../texts';

import { DotIcon } from '../../common/DotIcon';
import { ExpandableListItem } from '../../common/ExpandableListItem';

const ChartWrapper = styled.div`
  width: 140px;
  height: 140px;
  margin: 40px auto;
`;

const OwnerWrapper = styled.li`
  padding: ${SPACING_16} 0;
  border-bottom: 1px solid ${accent.sand1};

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
`;

const Owner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 12px;
`;

export const BusinessAndOwnershipDetailsItem = (props) => {
  const { item } = props;

  const colors = generateOrganizationAccentColors(
    item.shareHolders.length,
    accent
  );

  const Icon = <div>{/* <BusinessIcon /> */}</div>;

  const owners = item.shareHolders.map(
    (
      { companyShare, name, classification, description, beneficialOwner },
      index
    ) => ({
      value: companyShare,
      label: name,
      color: colors[index],
      classification,
      description,
      beneficialOwner,
    })
  );

  return (
    <GridElement columns={4}>
      <ExpandableListItem height={421}>
        <BaseSection title={item.name} icon={Icon}>
          <H3>{item.name}</H3>
          <ChartWrapper>
            <VictoryPie
              innerRadius={150}
              labels={() => null}
              padAngle={() => 3}
              padding={{ top: 0, bottom: 0, right: 0, left: 0 }}
              style={{
                data: {
                  fill: ({ datum }) => datum.color,
                },
              }}
              data={owners.map((item) => ({
                y: item.value,
                color: item.color,
              }))}
            />
          </ChartWrapper>
          <>
            <H6>
              {t(
                'wealthPlan:familyAndOwnership:businessAndOwnership:shareholders'
              )}
            </H6>
            <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
              {owners.map((owner, index) => (
                <OwnerWrapper key={`dot-${index}`}>
                  <Owner>
                    {owner.color && <DotIcon color={owner.color} />}
                    <H4 style={{ flex: 1 }}>{`${owner.label}`}</H4>
                    <H4 style={{ marginLeft: 'auto' }}>
                      {percentage(owner.value, 2, true)}
                    </H4>
                  </Owner>
                  {owner.classification || owner.beneficialOwner ? (
                    <div style={{ paddingLeft: 22 }}>
                      {owner.beneficialOwner ? (
                        <Paragraph
                          style={{ paddingTop: 8, fontStyle: 'italic' }}
                        >
                          {t(
                            `wealthPlan:familyAndOwnership:businessAndOwnership:beneficalOwner`
                          )}
                        </Paragraph>
                      ) : null}
                      {owner.classification ? (
                        <Paragraph style={{ paddingTop: 6 }}>
                          {t(
                            `wealthPlan:familyAndOwnership:businessAndOwnership:${owner.classification.toLowerCase()}Actions`
                          )}
                        </Paragraph>
                      ) : null}
                      {owner.classification && owner.description ? (
                        <Paragraph
                          style={{ wordBreak: 'break-word', paddingTop: 8 }}
                        >
                          {owner.description}
                        </Paragraph>
                      ) : null}
                    </div>
                  ) : null}
                </OwnerWrapper>
              ))}
            </ul>
          </>
        </BaseSection>
      </ExpandableListItem>
    </GridElement>
  );
};
