import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  config,
  formatPeriodLabel,
  selectors,
  t,
  usePortfolioExternalReportingFilter,
  usePortfolioInvestmentAccountsFilter,
  usePortfolioRelationshipFilter,
  usePortfolioTaxClassFilter,
  useStartegyOptions,
} from '@formue-app/core';

import { GridElement } from '../../layout';
import { H1Small, H4, H5, ParagraphSmall, ParagraphXSmall } from '../../texts';
import { SPACING_32, SPACING_64, SPACING_8 } from '../../../constants/spacing';
import { Logo } from '../../common/Logo';
import styled from 'styled-components';
import { accent } from '../../../constants/colors';

import PrintBackground from '../../../assets/images/default/print-background.svg';
import { DataVerificationNotice } from './DataVerificationNotice';

const {
  entities: {
    accounts: { allAccountsSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

const Wrapper = styled(GridElement)`
  @page:first {
    margin: 0 !important;
    padding: 0 !important;
  }

  padding: ${SPACING_64};

  background-color: ${accent.sand1};
  background-image: url(${PrintBackground}) !important;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

const RelationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  margin-bottom: ${SPACING_32};
`;

const RelationRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};

  & > div {
    display: flex;
    align-items: center;
    gap: ${SPACING_8};
  }
`;

const formatedName = (list) => {
  return list.reduce(
    (text, value, i, array) =>
      text + (i < array.length - 1 ? ', ' : ' & ') + value
  );
};

const activeStrategyAccountsName = (strategyAccounts, accounts) =>
  strategyAccounts?.map(
    (id) => accounts?.find((account) => account.id === id)?.name
  );

export const PeriodicReportingPrintHeader = (props) => {
  const [activeStrategy, setActiveStrategy] = useState(null);

  const { period, startDate, endDate } = useSelector(
    (state) => state.ui.portfolio.filters
  );

  const activeStrategyId = useSelector(activeStrategySelector);
  const accounts = useSelector(allAccountsSelector);
  const allStrategies = useStartegyOptions();

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [activeStrategyId, allStrategies]);

  const externalreportingFilter = usePortfolioExternalReportingFilter();
  const relationshipFilter = usePortfolioRelationshipFilter();
  const accountsFilter = usePortfolioInvestmentAccountsFilter();
  const taxClassFilter = usePortfolioTaxClassFilter();

  const selectedRelationships = relationshipFilter.filter(
    (item) => item.selected
  );
  const selectedAccounts = accountsFilter.filter((item) => item.selected);
  const selectedTaxClass = taxClassFilter.filter((item) => item.selected);
  const selectedExternalReporting = externalreportingFilter.filter(
    (item) => item.selected
  );

  const strategyString = activeStrategy
    ? `${t(
        `presentation:annualReview:portfolioStrategy:strategyTypes:${activeStrategy?.strategyType}`
      )} (${formatedName(
        activeStrategyAccountsName(activeStrategy.accounts, accounts)
      )}), `
    : '';

  const reportingString = selectedExternalReporting.length
    ? `${selectedExternalReporting
        .map((item) => t(`filters:${item.name}`, { companyName: config.title }))
        .join(', ')}`
    : '';

  const accountsString = selectedAccounts.length
    ? `${selectedAccounts.map((item) => t(`filters:${item.name}`)).join(', ')}`
    : '';
  const selectedTaxClassString = selectedTaxClass.length
    ? `${selectedTaxClass.map((item) => t(`filters:${item.name}`)).join(', ')}`
    : '';

  let string = strategyString;
  let subString = '';

  if (selectedExternalReporting.length < externalreportingFilter.length)
    string += reportingString;
  if (selectedAccounts.length < accountsFilter.length)
    subString += accountsString;
  if (selectedTaxClass.length < taxClassFilter.length)
    subString += selectedTaxClassString;

  return (
    <Wrapper columns={12} className="printBackground printOnly">
      <Content>
        <H1Small>{t('portfolio:periodicReporting:title')}</H1Small>

        <H4 style={{ marginTop: SPACING_32, marginBottom: 72 }}>
          {formatPeriodLabel(period, startDate, endDate, true)}
        </H4>
        <RelationWrapper>
          {selectedRelationships.length
            ? selectedRelationships.map((item, index) => (
                <RelationRow key={`row-${index}`}>
                  <div>
                    <H5>{item.name}</H5>
                    {string ? (
                      <ParagraphSmall>({string})</ParagraphSmall>
                    ) : null}
                  </div>
                  {subString ? (
                    <ParagraphXSmall>{subString}</ParagraphXSmall>
                  ) : null}
                </RelationRow>
              ))
            : null}
        </RelationWrapper>
        <DataVerificationNotice />
        <Logo height={15} style={{ marginTop: 'auto' }} />
      </Content>
    </Wrapper>
  );
};
