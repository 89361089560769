import { call, put, takeEvery, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { filterValues } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the network resource
export const [actions, reducer, resourceSagas] =
  resourceReducer('filter-values');

// Create the sagas for this entity
function* readFilterValues() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(filterValues.get, args);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readFilterValues, ...resourceSagas];
