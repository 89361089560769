import React from 'react';
import styled from 'styled-components';

import { formatGainValue, formatTwrValue } from '@formue-app/core';

import { SPACING_8 } from '../../../constants/spacing';

import { H2, H6 } from '../../texts';

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: ${SPACING_8};
`;

export const GainValue = ({ color, value, twr, selectedClasses }) => {
  const renderValue = () => {
    return (
      <>
        <H2 as="h3" style={{ color }}>
          {formatGainValue(value, false)}
        </H2>
        {!selectedClasses.length && (
          <H6 style={{ color }}>{`(${formatTwrValue(twr)})`}</H6>
        )}
      </>
    );
  };

  return <StyledRow>{renderValue()}</StyledRow>;
};
