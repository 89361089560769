import { apiFetch } from './utils/api';

export const investmentStrategies = {
  get: async (id) => {
    const options = {
      method: 'GET',
    };

    let url = '/v3/investment-strategies';

    if (id) {
      url = `/v3/investment-strategies/${id}`;
    }

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
  post: async () => {
    const options = {
      method: 'GET',
    };

    let url = '/v3/investment-strategies';

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
