import { apiFetch } from './utils/api';
// const mockedMilestones = require('./mocks/v1/wealth-plan/milestones.json');

export const milestones = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v1/wealth-plan/milestones';

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
