import React from 'react';
import styled from 'styled-components';

import {
  t,
  formatBigMoney,
  lightenColor,
  toLocaleDateString,
  useForecastData,
  hexToRgbaString,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { SpaghettiChart } from '../../charts/SpaghettiChart';

const InnerChartWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 0 10px;
`;

export const ForecastChart = (props) => {
  const {
    activeStrategy,
    strategyTitle,
    totalPortfolio,
    expectedReturn,
    inflation,
    deviation,
    inflationTitle,
    singleEvents,
    yearlyEvents,
    activeEvents,
    numberOfYearsToForecast,
    showTimePointLabels,
  } = props;

  const { data: chartData } = useForecastData(
    singleEvents,
    yearlyEvents,
    totalPortfolio,
    numberOfYearsToForecast * 12
  );

  const weakGrowthKey = t(
    'presentation:investmentStrategy:forecast:weakGrowth'
  ).toUpperCase();
  const weakGrowthData = useForecastData(
    singleEvents,
    yearlyEvents,
    totalPortfolio,
    numberOfYearsToForecast * 12,
    expectedReturn,
    0.02,
    activeEvents
  ).data;

  const strongGrowthKey = t(
    'presentation:investmentStrategy:forecast:strongGrowth'
  ).toUpperCase();
  const strongGrowthData = useForecastData(
    singleEvents,
    yearlyEvents,
    totalPortfolio,
    numberOfYearsToForecast * 12,
    expectedReturn,
    -0.02,
    activeEvents
  ).data;

  const dataObject = {
    [inflationTitle]: useForecastData(
      singleEvents,
      yearlyEvents,
      totalPortfolio,
      numberOfYearsToForecast * 12,
      expectedReturn,
      inflation,
      activeEvents
    ).data,
    [strategyTitle]: useForecastData(
      singleEvents,
      yearlyEvents,
      totalPortfolio,
      numberOfYearsToForecast * 12,
      expectedReturn,
      0,
      activeEvents,
      true
    ).data,
  };

  if (deviation) {
    dataObject[weakGrowthKey] = weakGrowthData;
    dataObject[strongGrowthKey] = strongGrowthData;
  }

  if (!inflation) {
    delete dataObject[inflationTitle];
  }

  return (
    <InnerChartWrapper style={{ flexDirection: 'row', paddingTop: 20 }}>
      <SpaghettiChart
        dataObject={dataObject}
        valueKey="value"
        xAxisTicks={chartData.map((value) => value.date)}
        xAxisTicksFormat={(x) => {
          if (chartData.length < 15)
            return toLocaleDateString(x, {
              month: 'short',
            });
          if (x.getMonth() === 0) return x.getFullYear();
        }}
        yAxisTicksFormat={formatBigMoney}
        isSelected={(selectedKeys, entryKey) => selectedKeys.includes(entryKey)}
        colorizeFunction={(entryKey) => {
          if (entryKey === 'STRONG GROWTH')
            return lightenColor(activeStrategy?.color, 0.7);
          if (entryKey === 'WEAK GROWTH')
            return lightenColor(activeStrategy?.color, 0.3);
          return activeStrategy?.color;
        }}
        colorizeAreaFunction={(entryKey) => {
          if (entryKey === 'STRONG GROWTH')
            return hexToRgbaString(activeStrategy?.color, 0.04);
          if (entryKey === 'WEAK GROWTH')
            return hexToRgbaString(activeStrategy?.color, 0.16);
          return hexToRgbaString(activeStrategy?.color, 0.08);
        }}
        areaChart={true}
        domainPadding={{ x: [30, 0], y: [10, 20] }}
        defaultLineColor="transparent"
        ticksColor={accent.ocean170}
        gridColor={lightenColor(accent.ocean440, 0.4)}
        formatLineData={(data) =>
          data.map((item) => ({ ...item, x: item.date }))
        }
        fromMinValue={true}
        xLabelsOffset={-30}
        xLabelsSolidColor={accent.ocean4}
        loading={false}
        ticksBackground={accent.sand1}
        ticksWidth={27}
        showTimePointLabels={showTimePointLabels}
        animate={false}
        interpolation="monotoneX"
        height={128}
        paddingBottom={10}
        yLabelsOffset={20}
        areaChartOpacity={0.08}
        reverseDataRendering={true}
      />
    </InnerChartWrapper>
  );
};
