import React from 'react';
import styled from 'styled-components';

import {
  paragraphLargeSize,
  paragraphLargeMobileSize,
  paragraphLargeLineHeight,
} from '../../constants/text';
import { titleFontStack } from '../../fonts';
import { mobileCondition } from '../../constants/media';

export const StyledParagraphLarge = styled.p`
  font-family: ${titleFontStack};
  font-size: ${paragraphLargeSize};
  line-height: ${paragraphLargeLineHeight};
  font-weight: 400;

  @media ${mobileCondition} {
    font-size: ${paragraphLargeMobileSize};
    font-weight: 600;
  }
`;

export const ParagraphLarge = (props) => {
  const { children } = props;

  return <StyledParagraphLarge {...props}>{children}</StyledParagraphLarge>;
};
