import React from 'react';
import styled from 'styled-components';

import {
  getTemperatureRiseContributionRating,
  localizeFloat,
} from '@formue-app/core';
import { H2Small, ParagraphXSmall } from '../../../texts';
import { SPACING_2 } from '../../../../constants/spacing';

const StyledContainer = styled.div`
  height: 124px;
  width: 124px;
  border-radius: 62px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
`;

export const TemperatureRiseBadge = ({
  temperature,
  backgroundColor,
  textColor,
  ...rest
}) => {
  if (!temperature) return null;

  const temperatureRiseRating =
    getTemperatureRiseContributionRating(temperature);

  return (
    <StyledContainer
      backgroundColor={
        backgroundColor ? backgroundColor : temperatureRiseRating.color
      }
      {...rest}
    >
      <H2Small
        style={{
          color: textColor ? textColor : temperatureRiseRating.textColor,
          paddingBottom: SPACING_2,
        }}
      >
        {`+ ${localizeFloat(temperature)}°C`}
      </H2Small>
      <ParagraphXSmall
        style={{
          color: textColor ? textColor : temperatureRiseRating.textColor,
        }}
      >
        {temperatureRiseRating.text}
      </ParagraphXSmall>
    </StyledContainer>
  );
};
