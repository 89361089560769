import React from 'react';
import styled from 'styled-components';

import { H3 } from '../../texts';
import { longAnimationTime, easeInOut } from '../../../constants/animation';
import { accent } from '../../../constants/colors';
import { SPACING_8 } from '../../../constants/spacing';

const TickWrapper = styled.div`
  display: flex;
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: center;
  transition: all ${longAnimationTime} ${easeInOut};
`;

const Line = styled.div`
  position: absolute;
  bottom: 0;
`;

const Label = styled(H3)`
  position: relative;
  margin-left: auto;
  bottom: ${SPACING_8};
`;

export const BarChartPoint = (props) => {
  const {
    coef = 1,
    axisWidth = 3,
    value,
    color = accent.ocean4,
    label,
    labelColor = accent.velvet4,
    offsetLeft = 0,
  } = props;

  return (
    <TickWrapper style={{ bottom: value * coef }}>
      <Line
        style={{
          height: axisWidth,
          backgroundColor: color,
          left: offsetLeft,
          width: `calc(100% - ${offsetLeft}px)`,
        }}
      />
      <Label style={{ color: labelColor }}>{label}</Label>
    </TickWrapper>
  );
};
