import { TaskSerializer } from './serializers/tasks';
import { apiFetch } from './utils/api';

//const mockedTasks = require('./mocks/v1/tasks.json');

export const tasks = {
  get: async (id) => {
    //return mockedTasks;
    const options = {
      method: 'GET',
    };
    let url = '/v1/tasks';

    if (id) {
      url = `${url}/${id}`;
    }

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },

  post: async (data) => {
    const payload = TaskSerializer.serialize(data);

    const options = {
      method: 'POST',
      body: payload,
    };

    const url = '/v1/tasks';
    const { body } = await apiFetch(url, options);

    return body;
  },

  update: async (data) => {
    const payload = TaskSerializer.serialize(data);
    const { id } = data;

    const options = {
      method: 'PATCH',
      body: payload,
    };
    let url = '/v1/tasks';

    if (id) {
      url = `${url}/${id}`;
    }

    const { body } = await apiFetch(url, options);

    return body;
  },
};
