import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t, useErrorState, useResource } from '@formue-app/core';

import { accent, textLightest } from '../../../constants/colors';
import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';

import { PortfolioReturnTitle } from '../slideComponents/portfolio/PortfolioReturnTitle';
import { PortfolioReturnChart } from '../slideComponents/portfolio/PortfolioReturnChart';
import { PortfolioPeriodFilter } from '../../portfolio/filters/PortfolioPeriodFilter';
import { MultipleSwitch } from '../../formElements';
import { PortfolioGainPerPeriodChart } from '../slideComponents/portfolio/PortfolioGainPerPeriodChart';
import { mobileCondition } from '../../../constants/media';
import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';

const Base = styled.div`
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 95px;
`;

const ContentWrapper = styled.div`
  width: calc(50% - 95px / 2);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
`;
const TitleWrapper = styled.div`
  min-height: 160px;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-left: auto;

  @media ${mobileCondition} {
    margin-left: 0;
  }
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
} = selectors;

export const PortfolioReturnSlide = (props) => {
  const [visibleChart, setVisibleChart] = useState('graph');
  const portfolioTotal = useSelector(portfolioTotalSelector);
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const error = useErrorState(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const { period, startDate, endDate } = useSelector(
    (state) => state.ui.portfolio.filters
  );

  if (error) {
    return <SlideErrorMessage />;
  }

  return (
    <Base>
      <Wrapper>
        <RowWrapper>
          <ContentWrapper>
            <TitleWrapper>
              {!loading ? (
                <PortfolioReturnTitle
                  gain={portfolioTotal.gain}
                  twr={portfolioTotal.twr}
                  period={period}
                  startDate={startDate}
                  endDate={endDate}
                  {...props}
                />
              ) : null}
            </TitleWrapper>
          </ContentWrapper>
          <ContentWrapper>
            <StyledMultipleSwitch
              backgroundColor={accent.ocean270}
              activeBackgroundColor={accent.ocean1}
              color={accent.ocean1}
              activeColor={textLightest}
              onChange={(value) => {
                setVisibleChart(value);
              }}
              options={[
                {
                  label: t(
                    'presentation:annualReview:portfolio:performanceOverTime'
                  ),
                  value: 'graph',
                },
                {
                  label: t('presentation:annualReview:portfolio:aggregated'),
                  value: 'bar',
                },
              ]}
            />
          </ContentWrapper>
        </RowWrapper>
      </Wrapper>
      <ChartWrapper>
        {visibleChart === 'graph' ? (
          <>
            <div style={{ minHeight: 400 }}>
              <PortfolioReturnChart loading={loading} />
            </div>
            <PortfolioPeriodFilter
              style={{
                position: 'relative',
                zIndex: 2,
                top: -4,
                borderColor: accent.ocean4,
                borderWidth: 1,
                borderStyle: 'solid',
              }}
              excludedPeriods={['THISMONTH']}
            />
          </>
        ) : (
          <PortfolioGainPerPeriodChart />
        )}
      </ChartWrapper>
    </Base>
  );
};
