import { config } from '@formue-app/core';
import { useLocation } from 'react-router-dom';

export const BasePage = (props) => {
  const { title, documentTitle, children } = props;
  const location = useLocation();
  const { pathname } = location;

  // A list of pathnames where we don't want the title to be prepended
  const pageTitleBlackList = ['/'];

  if ((title || documentTitle) && !pageTitleBlackList.includes(pathname)) {
    document.title = `${documentTitle ? documentTitle : title} - ${
      config.title
    }`;
  } else {
    document.title = `${config.title}`;
  }

  return children;
};
