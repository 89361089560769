import React from 'react';
import { useSelector } from 'react-redux';
import striptags from 'striptags';
import he from 'he';
import { useHistory } from 'react-router-dom';

import { BaseFeedItem } from './BaseFeedItem';

export const PodcastFeedItem = ({ id, onClick }) => {
  const podcastsById = useSelector(
    (state) => state.entities.podcastEpisodes.byId
  );
  const data = podcastsById[id];

  const history = useHistory();

  const handleOnClick = () => {
    history.push(`/podcasts?id=${id}`);
  };

  // There is a possibility that /feed api returned a non-existing id
  // so we need to make sure we have the data in the /podcasts api
  if (!data) return null;

  const ingressNoHtml = striptags(data.description);
  const decodedHtml = he.decode(ingressNoHtml);

  return (
    <BaseFeedItem
      columns={4}
      key={`feed-${id}`}
      {...data}
      date={data.publishedAt}
      description={decodedHtml}
      imageFallback={data.podcast.image}
      onClick={handleOnClick}
      //url={data.podcast.link}
      categories={['Podcast']}
    />
  );
};
