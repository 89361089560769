import { getAccessToken } from '../services';
import { config } from '../config';
import { getUserAgent } from './utils/api';

export const webAuthn = {
  generateRegistrationOptions: async () => {
    const url = `${config.api.host}/auth/webauthn/generate-registration-options`;
    const accessToken = await getAccessToken();

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Agent': getUserAgent(),
      'X-User-Agent': getUserAgent(),
    });

    const options = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, options);
    return response.json();
  },

  verifyRegistration: async (data) => {
    const url = `${config.api.host}/auth/webauthn/verify-registration`;
    const accessToken = await getAccessToken();

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Agent': getUserAgent(),
      'X-User-Agent': getUserAgent(),
    });

    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers,
    };
    const response = await fetch(url, options);
    return response.json();
  },

  generateAuthenticationOptions: async () => {
    const url = `${config.api.host}/auth/webauthn/generate-authentication-options`;
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Agent': getUserAgent(),
      'X-User-Agent': getUserAgent(),
    });
    const options = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, options);
    const sessionId = response.headers.get('x-webauthn-session-id');

    return {
      options: await response.json(),
      sessionId,
    };
  },

  verifyAuthentication: async (data, sessionId) => {
    const url = `${config.api.host}/auth/webauthn/verify-authentication`;

    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Agent': getUserAgent(),
      'X-User-Agent': getUserAgent(),
      'X-WebAuthn-Session-Id': sessionId,
    });

    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: headers,
    };
    const response = await fetch(url, options);
    return response.json();
  },
};
