import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { accent } from '../../../constants/colors';
import { H2 } from '../../texts';

const Text = styled(H2)`
  font-size: 40px;
  line-height: 48px;
`;

export const LensesText = (props) => {
  const { i18nKey, values, ...rest } = props;
  return (
    <Text {...rest}>
      <Trans
        i18nKey={i18nKey}
        values={values}
        components={{
          strong: (
            <span
              style={{
                textDecoration: 'underline',
                textDecorationColor: accent.ocean330,
                whiteSpace: 'nowrap',
              }}
            />
          ),
        }}
      />
    </Text>
  );
};
