import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { accent } from '../../../../constants/colors';
import {
  SPACING_64,
  SPACING_40,
  SPACING_8,
  SPACING_32,
  SPACING_16,
  SPACING_24,
  SPACING_12,
  SPACING_48,
} from '../../../../constants/spacing';
import { mobileCondition } from '../../../../constants/media';
import {
  easeInOut,
  extraLongAnimationTime,
  longAnimationTime,
  shortAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';

import { H2, H4, H6, List, ListItem, ParagraphSmall } from '../../../texts';
import { Modal } from '../../../common/Modal';
import { HorizontalDivider } from '../../../common/HorizontalDivider';

import thumbsUpUrl from '../../../../assets/icons/annual-review/thumb-up.svg';
import thumbsDownUrl from '../../../../assets/icons/annual-review/thumb-down.svg';
import { SpriteIcon } from '../../../common/SpriteIcon';
import { t } from '@formue-app/core';

const EsgWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${SPACING_64};
  overflow: hidden;
  height: 100%;
  align-items: center;
`;

const EsgInner = styled.div`
  width: auto;
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
  transition: left ${easeInOut} ${longAnimationTime};
  position: relative;

  @media ${mobileCondition} {
    flex-direction: column;
    align-items: center;
    gap: ${SPACING_64};
  }
`;

const ItemWrapper = styled.div`
  width: calc(
    100% /
      ${(props) => Math.min(props.itemsCount, props.maxItemsBeforeSlideShow)}
  );
  max-width: 265px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
`;

const IconWrapper = styled.div`
  background-color: ${(props) => props.backgroudColor};
  border-radius: 50%;
  width: ${(props) => (props.itemsCount >= props.maxBigItems ? 144 : 190)}px;
  height: ${(props) => (props.itemsCount >= props.maxBigItems ? 144 : 190)}px;
  margin-bottom: ${SPACING_40};
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  scale: 0;
  opacity: 0;
  transition: scale ${easeInOut} ${extraLongAnimationTime},
    opacity ${easeInOut} ${extraLongAnimationTime},
    background ${easeInOut} ${shortAnimationTime};
  transition-delay: ${(props) => `${slideAnimationDelay + props.index * 0.2}s`},
    ${(props) => `${slideAnimationDelay + props.index * 0.2}s`}, 0s;

  ${(props) =>
    props.isActiveSlide &&
    `
    opacity: 1;
    scale: 1;
  `}

  &:hover {
    background-color: ${accent.forest370};
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProsConsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledListItem = styled(ListItem)`
  padding-left: 28px;
  background-image: url(${(props) => (props.up ? thumbsUpUrl : thumbsDownUrl)});
  background-position: 1px 3px;
`;

export const EsgListCardContent = (props) => {
  const { items = [], activeIndex, ...rest } = props;

  const [item, setItem] = useState(null);

  useEffect(() => {
    setItem(items[activeIndex]);
  }, [items, activeIndex]);

  if (!item) {
    return null;
  }

  const {
    title,
    subTitle,
    icon,
    body,
    listTitle,
    listItems,
    pros,
    cons,
    investmentFocus,
  } = item;

  return (
    <CardWrapper {...rest}>
      <div
        style={{ marginBottom: SPACING_16, display: 'flex', gap: SPACING_12 }}
      >
        <SpriteIcon
          id={icon}
          color={accent.forest3}
          accentColor={accent.forest190}
          size={28}
        />
        <H2>{t(title)}</H2>
      </div>

      {subTitle ? (
        <H6 style={{ marginBottom: SPACING_8 }}>{t(subTitle)}</H6>
      ) : null}
      <ParagraphSmall>{t(body)}</ParagraphSmall>

      <HorizontalDivider
        style={{ marginBottom: SPACING_24, marginTop: SPACING_24 }}
      />

      <H4>{t(listTitle)}</H4>
      <List style={{ columns: 2, marginBottom: 0 }}>
        {t(listItems, {
          returnObjects: true,
        }).map((item, index) => (
          <ListItem style={{ marginBottom: SPACING_8 }} key={`item-${index}`}>
            <ParagraphSmall style={{ hyphens: 'auto' }}>{item}</ParagraphSmall>
          </ListItem>
        ))}
      </List>

      <HorizontalDivider
        style={{ marginBottom: SPACING_24, marginTop: SPACING_24 }}
      />

      <ProsConsWrapper>
        <div>
          <H4>Positivt</H4>
          <List style={{ marginBottom: 0 }}>
            {t(pros, {
              returnObjects: true,
            })?.map((item, index) => (
              <StyledListItem up={true} key={`pros-${index}`}>
                <ParagraphSmall>{item}</ParagraphSmall>
              </StyledListItem>
            ))}
          </List>
        </div>
        <div>
          <H4>Negativt</H4>
          <List style={{ marginBottom: 0 }}>
            {t(cons, {
              returnObjects: true,
            })?.map((item, index) => (
              <StyledListItem key={`cons-${index}`}>
                <ParagraphSmall>{item}</ParagraphSmall>
              </StyledListItem>
            ))}
          </List>
        </div>
      </ProsConsWrapper>

      <div style={{ marginTop: 'auto' }}>
        <ParagraphSmall style={{ marginTop: SPACING_48 }}>
          {t(investmentFocus)}
        </ParagraphSmall>
      </div>
    </CardWrapper>
  );
};

export const EsgList = (props) => {
  const { isActiveSlide, items, ...rest } = props;

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  // Deselect goal if slide is not active
  useEffect(() => {
    if (!isActiveSlide) {
      setActiveItemIndex(0);
    }
  }, [isActiveSlide]);

  return (
    <>
      <EsgWrapper {...rest}>
        <EsgInner>
          {items.map((item, index) => (
            <ItemWrapper
              onClick={() => {
                setActiveItemIndex(index);
                setOpenModal(true);
              }}
              index={index}
              isActiveSlide={isActiveSlide}
              key={`esg-preference-${index}`}
            >
              <IconWrapper
                index={index}
                isActiveSlide={isActiveSlide}
                backgroudColor={item.color}
              >
                <SpriteIcon
                  id={item.icon}
                  navigational={true}
                  inverted={true}
                  size={86}
                />
              </IconWrapper>
              <H2 style={{ color: accent.forest250 }}>{t(item.title)}</H2>
              {item.subTitle ? (
                <H6 style={{ color: accent.forest250, marginTop: SPACING_8 }}>
                  {t(item.subTitle)}
                </H6>
              ) : null}
            </ItemWrapper>
          ))}
        </EsgInner>
      </EsgWrapper>
      <Modal
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false);
        }}
        contentWidth={395}
        padding={SPACING_32}
      >
        <EsgListCardContent items={items} activeIndex={activeItemIndex} />
      </Modal>
    </>
  );
};
