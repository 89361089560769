import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [articlesAllIds, articlesById, allArticlesSelector] =
  resourceSelectors('articles');

export const createArticlesByCategorySelector = (category) => {
  return createSelector(allArticlesSelector, (articles) => {
    return articles.filter((article) => article.categories.includes(category));
  });
};
