import React from 'react';
import styled from 'styled-components';
import { useQueryState } from 'react-router-use-location-state';

import { t } from '@formue-app/core';

import { Paragraph } from '../../../texts';
import { BaseSection } from '../../../common/BaseSection';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';

import { accent, whiteTranslucent } from '../../../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../../../constants/animation';

const ListItem = styled(BaseSection)`
  padding: 9px 14px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  background: ${whiteTranslucent(0)};
  border: 2px dashed ${accent.ocean440};
  cursor: pointer;
  transition: all ${easeInOut} ${shortAnimationTime};

  &:hover {
    background: ${whiteTranslucent(0.6)};
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddTodoItem = (props) => {
  const { openOverlay, openNewTaskPriority, priority } = props;

  const [exportMode] = useQueryState('exportMode', false);

  if (exportMode) {
    return null;
  }

  return (
    <ListItem
      onClick={() => {
        openNewTaskPriority(priority);
        openOverlay(true);
      }}
    >
      <IconWrapper>
        <SpriteIconFunctional id="Plus" />
      </IconWrapper>
      <Paragraph style={{ color: accent.ocean490, fontWeight: 500 }}>
        {t('presentation:annualReview:measures:addTask')}
      </Paragraph>
    </ListItem>
  );
};
