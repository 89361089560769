import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, maxForYear, formatYearlyData } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_32 } from '../../../constants/spacing';
import { CashflowDetailsSection } from '../investmentProfile/RiskChart';

const StyledContainer = styled(BaseSection)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${SPACING_32};
`;

const {
  entities: {
    cashFlowEvents: { allCashFlowEventsMetadataSelector },
  },
} = selectors;

export const CashflowEvents = (props) => {
  const { title, cashFlowEventsData } = props;
  const cashFlowEventsMetaData = useSelector(allCashFlowEventsMetadataSelector);

  const totalPortfolio = cashFlowEventsMetaData.totalPortfolio;
  const yearlyFormatedData = formatYearlyData(cashFlowEventsData);
  const max = maxForYear(cashFlowEventsData);
  return (
    <StyledContainer>
      <div style={{ width: '100%' }}>
        <CashflowDetailsSection
          yearlyFormatedData={yearlyFormatedData}
          totalPortfolio={totalPortfolio}
          max={max}
          barWidth={12}
          small={true}
          title={title}
        />
      </div>
    </StyledContainer>
  );
};
