import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useResource } from '@formue-app/core';

import { accent } from '../../constants/colors';
import { actions as podcastActions } from '../../store/ui/podcast';

const StyledInput = styled.input`
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  background: ${(props) => props.color};
  transition: background 0.2s ease;
  cursor: pointer;
  border-radius: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: ${(props) => props.color};
  }
`;

export const PodcastPlayerProgressBar = ({
  colorPrimary = accent.ocean4,
  colorSecondary = accent.ocean450,
  ...rest
}) => {
  const dispatch = useDispatch();
  const loading = useResource(['PODCAST_EPISODES/INDEX']);

  const podcastUi = useSelector((state) => state.ui.podcast);
  const { isPlaying, trackProgress, audioRef, intervalRef } = podcastUi;

  if (!audioRef) return null;

  const { duration, buffered } = audioRef.current;

  const onScrub = (value) => {
    // Clear any timers already running

    clearInterval(intervalRef.current);
    if (value <= buffered.end(0)) {
      audioRef.current.currentTime = value;
    } else {
      audioRef.current.currentTime = buffered.end(0);
    }
    dispatch(
      podcastActions.setTrackProgress(
        audioRef.current.currentTime,
        buffered,
        duration
      )
    );
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      dispatch(podcastActions.setIsPlaying(true));
    }
    startTimer();
  };

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        //toNextTrack();
      } else {
        dispatch(
          podcastActions.setTrackProgress(
            audioRef.current.currentTime,
            buffered,
            duration
          )
        );
      }
    }, [1000]);
  };

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : '0%';
  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, ${colorPrimary}), color-stop(${currentPercentage},${colorSecondary}))
`;

  if (loading) return null;

  return (
    <div {...rest}>
      <StyledInput
        type="range"
        value={trackProgress}
        step="1"
        min="0"
        max={buffered.length ? duration : `${duration}`}
        className="progress"
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        style={{ background: trackStyling }}
        color={colorPrimary}
      />
    </div>
  );
};
