import { createSelector } from 'reselect';
import { selectors } from '@formue-app/core';

const { openSelector } = selectors.ui.todo;
const { allDocumentsSelector } = selectors.entities.documents;

export const unreadSelector = createSelector(
  [allDocumentsSelector],
  (documents) => {
    if (documents) return documents.filter((doc) => !doc.read);
    return [];
  }
);

export const signingTasksSelector = createSelector([openSelector], (tasks) => {
  if (tasks) return tasks.filter((task) => task.type === 'signingEvents');
  return [];
});

export const otherTasksSelector = createSelector([openSelector], (tasks) => {
  if (tasks) return tasks.filter((task) => task.type !== 'signingEvents');
  return [];
});

export const unresolvedTasksSelector = createSelector(
  [openSelector],
  (tasks) => {
    if (tasks) return tasks.length;
    return null;
  }
);
