import { apiFetch } from './utils/api';

export const expertInput = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v1/expert-inputs';

    const { body } = await apiFetch(url, {
      ...options,
    });

    if (body) {
      return body;
    } else {
      return { data: [] };
    }
  },
};
