import { inRange } from 'lodash';
import { esgLevels, esgRatings } from '../../constants/esg';

export const formatEsgChartsData = (esgValue, primaryColor, accent) => {
  const esgLevelKeys = Object.keys(esgLevels);
  const esgLevelString = getEsgLevelString(esgValue);
  const nextLevelString = getEsgLevelString(esgLevels[esgLevelString][1] + 0.1);

  // generates the data for the chart that shows portfolio distribution
  // between the esg ratings
  const esgRating = esgRatings.map((value, index) => {
    return {
      y: value,
      showLabel: index === esgLevelKeys.indexOf(nextLevelString),
      showLabelBar: index === esgLevelKeys.indexOf(nextLevelString),
      label: esgLevels[esgLevelKeys[index]][0],
      labelColor: accent.moss1,
    };
  });
  const nextLevel = pointsToNextLevel(esgValue);

  // generates the data for the chart that shows your esg level and points to
  // next level
  const esg = {
    colors: [accent.ocean1, accent.sand110, accent.sand110],
    data: [
      // your level
      {
        y: esgValue,
        showLabel: true,
        label: esgValue,
        labelColor: primaryColor,
      },
      // points to next level
      {
        y: nextLevel.value,
        showLabel: true,
        label: esgValue + nextLevel.value,
        labelColor: accent.moss1,
        showLabelBar: true,
      },
      // remaining value of the "full circle"
      { y: 10 - esgValue - nextLevel.value, showLabel: false },
    ],
    startData: [{ y: 0 }, { y: 100 }],
    scaleInner: 0,
    scaleOuter: 1,
    labelColor: accent.darkBlue,
  };

  const scale = {
    data: esgRating,
    scaleInner: 0,
    scaleOuter: 0,
    activeItem: false,
  };

  // Each object in the returned array represents one state of two pie-charts.
  // We are manipulating the scale, color and labels on both of them.
  // Animations are handled by the pie-chart component itself and triggered
  // on the data change (switching between the objects inside this array)
  return [
    {
      esg,
      scale,
    },
    {
      esg: { ...esg, scaleOuter: 1, scaleInner: 0, showLabels: true },
      scale: {
        ...scale,
        scaleInner: 0,
        scaleOuter: 0,
        activeItem: false,
        showLabels: false,
      },
    },
    {
      esg: { ...esg, scaleOuter: 0, scaleInner: 0 },
      scale: {
        ...scale,
        scaleInner: 0,
        activeItem: 2,
        scaleOuter: 1,
        colors: [accent.sand110, accent.sand110, accent.moss1],
      },
    },
    {
      esg: { ...esg, scaleOuter: 0, scaleInner: 0 },
      scale: {
        ...scale,
        scaleInner: 0,
        scaleOuter: 1,
        activeItem: 1,
        colors: [accent.sand110, accent.ocean1, accent.sand110],
      },
    },
    {
      esg: { ...esg, scaleOuter: 0, scaleInner: 0 },
      scale: {
        ...scale,
        scaleInner: 0,
        scaleOuter: 1,
        activeItem: 0,
        colors: [accent.raspberry1, accent.sand110, accent.sand110],
      },
    },
  ];
};

// Should return "behind", "average" or "leader" based on the input value
export const getEsgLevelString = (esgValue) => {
  const esgLevel = Object.keys(esgLevels).filter((level) =>
    inRange(esgValue, esgLevels[level][0], esgLevels[level][1])
  );
  return esgLevel[0];
};

// Returns an interval based on a given esg level (string)
// Example: valueString = "average" return [2.9, 7.1]
export const getEsgLevelInterval = (valueString) => {
  return esgLevels[valueString];
};

// Return how far is the given value from the next esg level
// Example: esgValue = 5.6 return 1.5
// (next level would be > 7.1 in the example)
export const pointsToNextLevel = (esgValue) => {
  const esgLevelString = getEsgLevelString(esgValue);
  return {
    value: esgLevels[esgLevelString][1] - esgValue,
    nextLevel: getEsgLevelString(esgLevels[esgLevelString][1] + 0.1),
  };
};

// We use this for the speedometer chart input value.
// The required values for the chart is integer interval from 0 to 4.
export const ratingMomentumLevel = (value) => {
  if (value < -1.5) {
    return 0;
  }
  if (value < -0.5) {
    return 1;
  }
  if (value < 0.5) {
    return 2;
  }
  if (value < 1.5) {
    return 3;
  } else {
    return 4;
  }
};

// Return a color and text for the given temperature rise contribution
export const getTemperatureRiseContributionRating = (temperatureRise) => {
  if (temperatureRise > 3) return { text: "Strongly misaligned", color: '#F692A3', textColor: '#4F143B' }
  if (temperatureRise > 2) return { text: "Misaligned", color: '#FBD0D7', textColor: '#4F143B' }
  if (temperatureRise > 1.4) return { text: "Aligned", color: '#D5E9D1', textColor: '#173621' }
  return { text: "Aligned", color: '#ABD4A3', textColor: '#173621' }
}
