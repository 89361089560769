import { createActions } from 'redux-actions';

export const actions = createActions({
  WEBAUTHN: {
    LOAD: () => ({}),
    LOADED: (data) => data,
    START_REGISTRATION: () => ({}),
    FINISH_REGISTRATION: (data) => data,
    FAILED_REGISTRATION: (error) => error,
    START_AUTHENTICATION: () => ({}),
    FINISH_AUTHENTICATION: (data) => data,
    FAILED_AUTHENTICATION: (error) => error,
    STORE_PASSKEYS_ENABLED_FLAG: (value) => value,
  },
}).webauthn;
