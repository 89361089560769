import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { feed } from '../../../api';
import { actions as uiActions } from '../../ui/readStatus';
import { resourceReducer } from '../../utils/resourceReducer';
import { actions as feedCategoriesActions } from './feedCategories';

// Create actions and a reducer for the feed resource
export const [actions, reducer, ...resourceSagas] = resourceReducer(
  'feed-items'
);

// Create the sagas for this entity
function* readFeed() {
  yield takeEvery(actions.indexRequestBegin, function*() {
    try {
      const result = yield call(feed.get);
      const normalized = normalize(result);

      yield put(uiActions.hydrateReadItems('feed'));
      yield put(actions.indexRequestSuccess(normalized));
      yield put(feedCategoriesActions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readFeed, ...resourceSagas];
