import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { formatMoney, selectors, t } from '@formue-app/core';

import { SPACING_64, SPACING_88, SPACING_8 } from '../../../constants/spacing';
import { mobileCondition } from '../../../constants/media';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { ReportQuestion } from '../reportComponent/ReportQuestion';
import { CategoryItem } from '../slideComponents/wealth/CategoryItem';
import { CategoryButton } from '../slideComponents/wealth/CategoryButton';

const {
  entities: {
    wealthBalance: {
      totalWealthBalanceSelector,
      createWealthBalanceByCategorySelector,
    },
  },
} = selectors;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${SPACING_64};
  margin-bottom: ${SPACING_64};

  @media ${mobileCondition} {
    overflow: hidden;
  }
`;

const CategoryWrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 950px;
  margin-bottom: ${SPACING_88};

  @media ${mobileCondition} {
    flex-direction: column;
  }
`;

const StyledCategoryButton = styled(CategoryButton)`
  width: calc(33% - 10px);

  @media ${mobileCondition} {
    width: 100%;
    margin-bottom: ${SPACING_8};
  }
`;

export const WealthSection = (props) => {
  const { backgroundColor, title, isVisible, ...rest } = props;

  const totalWealth = useSelector(totalWealthBalanceSelector);
  const safety = useSelector(createWealthBalanceByCategorySelector('Safety'));
  const preserveAndGrow = useSelector(
    createWealthBalanceByCategorySelector('Preserve & Grow')
  );
  const create = useSelector(createWealthBalanceByCategorySelector('Create'));

  const maxValue = Math.max(
    safety.amount,
    preserveAndGrow.amount,
    create.amount
  );

  const dataset = {
    safety,
    preserveAndGrow,
    create,
  };

  return (
    <>
      <ReportTitle
        {...rest}
        title={title}
        values={{ totalWealth: formatMoney(totalWealth) }}
        style={{ marginBottom: 'auto' }}
      />

      <CategoryWrapper>
        <CategoryItem
          category="safety"
          width={185}
          maxValue={maxValue}
          dataset={dataset}
          isActiveSlide={isVisible}
          enableClick={false}
        />
        <CategoryItem
          category="preserveAndGrow"
          width={185}
          maxValue={maxValue}
          dataset={dataset}
          isActiveSlide={isVisible}
          enableClick={false}
        />
        <CategoryItem
          category="create"
          width={185}
          maxValue={maxValue}
          dataset={dataset}
          isActiveSlide={isVisible}
          enableClick={false}
        />
      </CategoryWrapper>

      <CategoryWrapperButtons>
        <StyledCategoryButton
          category="safety"
          dataset={dataset}
          isActiveSlide={isVisible}
        />
        <StyledCategoryButton
          category="preserveAndGrow"
          dataset={dataset}
          isActiveSlide={isVisible}
        />
        <StyledCategoryButton
          category="create"
          dataset={dataset}
          isActiveSlide={isVisible}
        />
      </CategoryWrapperButtons>

      <ReportQuestion
        {...rest}
        style={{ marginTop: 'auto' }}
        questionText={t('report:annualReview:wealthBalance:question')}
      />
    </>
  );
};
