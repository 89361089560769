import React from 'react';
import { Trans } from 'react-i18next';

import { accent } from '../../../../constants/colors';
import { titleFontStack } from '../../../../fonts';
import { H1 } from '../../../texts';

export const PortfolioReturnPerYearTitle = (props) => {
  const {
    lastYear,
    lastYearValue,
    thisYearValue,
    titleColor,
    highlightColor,
    ...rest
  } = props;

  // For new users we have data only for current year and thus need to alter the text
  const onlyCurrentYear = !lastYear;

  return (
    <H1 {...rest} style={{ color: accent[titleColor] }}>
      <Trans
        i18nKey={`presentation:annualReview:returnPerYear:${
          onlyCurrentYear ? 'textOneYear' : 'text'
        }`}
        values={{
          lastYear,
          lastYearValue,
          thisYearValue,
        }}
        components={{
          strong: (
            <span
              style={{
                color: accent[highlightColor],
                fontWeight: 700,
                fontFamily: titleFontStack,
                whiteSpace: 'nowrap',
              }}
            />
          ),
        }}
      />
    </H1>
  );
};
