import React from 'react';
import styled from 'styled-components';

import { H3Size, H3SizeMobile, H3LineHeightMobile } from '../../constants/text';
import { notDesktopCondition } from '../../constants/media';

const StyledH2 = styled.h2`
  font-size: ${H3Size};
  line-height: 29px;
  font-weight: 500;

  @media ${notDesktopCondition} {
    font-size: ${H3SizeMobile};
    line-height: ${H3LineHeightMobile};
  }
`;

export const NestedHeadline = (props) => {
  return <StyledH2 {...props} />;
};
