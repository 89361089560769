import React from 'react';
import styled from 'styled-components';

import {
  useForecastData,
  percentage,
  t,
  formatBigMoney,
} from '@formue-app/core';
import { accent } from '../../../constants/colors';
import { H2, H6 } from '../../texts';
import { SPACING_64, SPACING_8 } from '../../../constants/spacing';
import { bodyFontStack } from '../../../fonts';

const Container = styled.div`
  display: flex;
  gap: 0 ${SPACING_64};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled(H6)`
  color: ${accent.ocean450};
  text-transform: uppercase;
`;

const Value = styled(H2)`
  padding: ${SPACING_8} 0;
  font-family: ${bodyFontStack};
  font-weight: 500;
`;

export const ForecastValues = (props) => {
  const {
    expectedReturn,
    totalPortfolio,
    inflation = 0,
    singleEvents,
    yearlyEvents,
    activeEvents,
    numberOfYearsToForecast,
    ...rest
  } = props;

  const {
    data: forecastData,
    totalReturnValue,
    capitalInAndOut,
  } = useForecastData(
    singleEvents,
    yearlyEvents,
    totalPortfolio,
    numberOfYearsToForecast * 12,
    expectedReturn,
    inflation,
    activeEvents
  );

  if (!forecastData.length) return null;

  const finalDataPoint = forecastData[forecastData.length - 1];

  const totalReturn = totalReturnValue;

  return (
    <Container {...rest}>
      <Item>
        <Label>
          {t('presentation:investmentStrategy:forecast:capitalInOut')}
        </Label>
        <Value>{`${formatBigMoney(capitalInAndOut, '', true)}`}</Value>
      </Item>
      <Item>
        <Label>
          {t('presentation:investmentStrategy:forecast:totalReturn')}
        </Label>
        <Value>{`${formatBigMoney(totalReturn, '', true)}`}</Value>
      </Item>
      <Item>
        <Label>
          {t('presentation:investmentStrategy:forecast:portfolioInYear', {
            year: finalDataPoint.date.getFullYear(),
          })}
        </Label>
        <Value>{`${formatBigMoney(finalDataPoint.value)}`}</Value>
      </Item>
      <Item>
        <Label>
          {t('presentation:investmentStrategy:forecast:expectedReturn')}
        </Label>
        <Value>{percentage(expectedReturn * 100)}</Value>
      </Item>
    </Container>
  );
};
