import { handleActions } from 'redux-actions';

export { sagas } from './sagas';
import { actions } from './actions';
export { actions };

import * as selectors from './selectors';
export { selectors };

const defaultState = {
  language: 'nb',
  languages: {
    nb: 'nb',
    sv: 'sv',
    da: 'da',
    en: 'en',
  },
  impersonationId: null,
  isImpersonating: false,
  isEmployeeClientUser: false,
  imageBackground: Math.floor(Math.random() * 6),
};

export const reducer = handleActions(
  {
    [actions.setImpersonationId]: (state, { payload }) => ({
      ...state,
      impersonationId: payload,
    }),
    [actions.setIsImpersonating]: (state, { payload }) => ({
      ...state,
      isImpersonating: payload,
      isEmployeeClientUser: !payload,
    }),
    [actions.setIsEmployeeClientUser]: (state, { payload }) => ({
      ...state,
      isEmployeeClientUser: payload,
    }),
    [actions.setLanguage]: (state, { payload }) => ({
      ...state,
      language: payload,
    }),
  },
  defaultState
);
