import React, { useRef, useEffect, useState } from 'react';

import { formatTwrValue, getAssetClassName } from '@formue-app/core';
import { bodyFontStack } from '../../../fonts';
import { accent, backgroundWhite } from '../../../constants/colors';

export const PortfolioHoldingsChartTooltip = (props) => {
  const { x, y, width, datum, activeClass, fontSize } = props;

  const textRef = useRef(false);
  const [rectWidth, setRectWidth] = useState(0);

  useEffect(() => {
    if (activeClass === datum.class) {
      const svgText = textRef.current.getBBox();
      setRectWidth(svgText.width);
    }
  }, [activeClass, datum.class]);


  // Since our tooltip has two lines of text and the chart library calculates position
  // as it's a single line - we need to fix the vertical positioning slightly
  let correctedY = y;
  if (datum.y < 0) {
    correctedY += 10;
  } else {
    correctedY -= 6;
  }

  const RECT_HORIZONTAL_PADDING = 6;
  const RECT_VERTICAL_PADDING = 5;

  return (
    <g transform={`translate(${x},${correctedY})`} width={width}>
      {activeClass === datum.class && (
        <g transform={`translate(0, ${datum.y < 0 ? `10` : `-20`})`}>
          <rect
            x={-rectWidth / 2 - RECT_HORIZONTAL_PADDING}
            y={-RECT_VERTICAL_PADDING}
            width={rectWidth + RECT_HORIZONTAL_PADDING * 2}
            height={6 + RECT_VERTICAL_PADDING * 2}
            rx={2}
            fill={accent.velvet1}
          ></rect>
          <text
            textAnchor="middle"
            x="0"
            y="5"
            fontSize="10"
            style={{
              fill: backgroundWhite,
              fontFamily: bodyFontStack,
              fontWeight: '100',
              fontSize: `${fontSize}px`,
            }}
            ref={textRef}
          >
            {datum.class ? getAssetClassName(datum.class).toUpperCase() : '' }
          </text>
        </g>
      )}
      <text
        textAnchor="middle"
        style={{
          fill: accent.velvet2,
          fontFamily: bodyFontStack,
          fontWeight: '300',
          fontSize: `${fontSize}px`,
        }}
      >
        {
          // If (twr > 1) set the number of decimals to 0 (zero)
          // We do this in order to fit the labels horizontally
          // Example: twr: 0.6743 => "+67.3%"  twr: 1.2311 => "+123%"
          formatTwrValue(datum.twr, datum.twr >= 1 ? 0 : 1)
        }
      </text>
    </g>
  );
};
