export const parseFeaturedImage = featuredImage => {
  if (!featuredImage.hasOwnProperty('media_details')) return null;

  // We check if there are "sizes" in the WordPress response.
  // If not, the fallback is a default url to the full size.
  const full = featuredImage.media_details.sizes.hasOwnProperty('full')
    ? featuredImage.media_details.sizes.full.source_url
    : featuredImage.source_url;

  const medium = featuredImage.media_details.sizes.hasOwnProperty(
    'medium_large'
  )
    ? featuredImage.media_details.sizes.medium_large.source_url
    : featuredImage.source_url;

  return {
    full,
    thumbnail: medium,
  };
};

// Ideally we would use categoryId or categorySlug, but that requires
// changes on the API side.
export const localizedEsgCategoryNames = {
  se: {
    esgCategoryName: 'Hållbarhet',
  },
  no: {
    esgCategoryName: 'Bærekraft',
  },
  dk: {
    esgCategoryName: 'Bæredygtighed',
  },
};
