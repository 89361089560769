import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  selectors,
  useResource,
  t,
  getEventsInForecastPeriod,
} from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
  SPACING_8,
} from '../../../constants/spacing';
import { ForecastFilters } from '../investmentStrategyComponents/ForecastFilters';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { ForecastChart } from '../investmentStrategyComponents/ForecastChart';
import { ForecastValues } from '../investmentStrategyComponents/ForecastValues';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { H3 } from '../../texts';

const {
  entities: {
    cashFlowEvents: {
      singleCashFlowEventsSelector,
      yearlyCashFlowEventsSelector,
    },
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsMetadataSelector,
    },
  },
} = selectors;

const Base = styled.div`
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-bottom: 100px;
  align-items: center;
  justify-content: center;
`;

const InnerChartWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  padding-top: 80px;
`;

const StrategyTitle = styled(H3)`
  position: absolute;
  z-index: 1;
  top: ${SPACING_8};
  left: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const StyledForecastFilters = styled(ForecastFilters)`
  position: absolute;
  right: ${ANNUAL_REVIEW_REPORT_CONTENT_SPACING};
  top: -43px;
  z-index: 1;
`;

export const ForecastSlide = (props) => {
  const strategies = useEnabledStrategies();
  const strategiesMeta = useSelector(
    investmentStrategyAssetAllocationsMetadataSelector
  );
  const numberOfYearsToForecast = 10;

  const loading = useResource([
    'CASH_FLOW_EVENTS/INDEX',
    'INVESTMENT_STRATEGIES/INDEX',
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  const singleCashFlowEvents = useSelector(singleCashFlowEventsSelector);
  const yearlyCashFlowEvents = useSelector(yearlyCashFlowEventsSelector);

  const singleEventsInForecastPeriod = getEventsInForecastPeriod(
    singleCashFlowEvents,
    numberOfYearsToForecast
  );

  const [filters, setFilters] = useState({
    strategy: strategies[0],
    inflation: null,
    inflationTitle: null,
    deviation: null,
    view: { value: null, label: null },
    events: [],
    timePointLabels: true,
  });

  const totalPortfolio = filters?.strategy?.totalPortfolio;

  useEffect(() => {
    if (filters.view) {
      let viewFilters = {
        inflation: null,
        inflationTitle: null,
        deviation: false,
      };
      if (filters.view.value === 'inflationAdjusted2') {
        viewFilters = {
          ...viewFilters,
          inflation: 0.02,
          inflationTitle: t(
            'presentation:investmentStrategy:forecast:inflationAdjusted2'
          ),
        };
      }
      if (filters.view.value === 'inflationAdjusted4') {
        viewFilters = {
          ...viewFilters,
          inflation: 0.04,
          inflationTitle: t(
            'presentation:investmentStrategy:forecast:inflationAdjusted4'
          ),
        };
      }
      if (filters.view.value === 'deviationView') {
        viewFilters = {
          ...viewFilters,
          deviation: true,
        };
      }
      setFilters({ ...filters, ...viewFilters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.view.value]);

  return (
    <Base>
      <ChartWrapper style={{ position: 'relative' }}>
        <StrategyTitle>{filters.strategy.title}</StrategyTitle>
        <StyledForecastFilters
          selectedCallback={(selected) =>
            setFilters({ ...filters, ...selected })
          }
          loading={loading}
          singleEvents={singleEventsInForecastPeriod}
          yearlyEvents={yearlyCashFlowEvents}
        />
        {loading || !filters.strategy ? (
          <CenteredActivityIndicator />
        ) : (
          <InnerChartWrapper>
            <ForecastValues
              style={{ padding: `0 ${ANNUAL_REVIEW_CONTENT_SPACING}` }}
              expectedReturn={
                strategiesMeta[filters.strategy.id].expectedPerformance.return
              }
              totalPortfolio={totalPortfolio}
              inflation={filters.inflation}
              activeEvents={filters.events}
              singleEvents={singleEventsInForecastPeriod}
              yearlyEvents={yearlyCashFlowEvents}
              numberOfYearsToForecast={numberOfYearsToForecast}
            />
            <ForecastChart
              activeStrategy={filters.strategy}
              strategyTitle={
                filters.deviation
                  ? t(
                      'presentation:investmentStrategy:forecast:mediumGrowth'
                    ).toUpperCase()
                  : filters.strategy.title
              }
              expectedReturn={
                strategiesMeta[filters.strategy.id].expectedPerformance.return
              }
              totalPortfolio={totalPortfolio}
              inflation={filters.inflation}
              inflationTitle={filters.inflationTitle}
              activeEvents={filters.events}
              singleEvents={singleEventsInForecastPeriod}
              yearlyEvents={yearlyCashFlowEvents}
              numberOfYearsToForecast={numberOfYearsToForecast}
              showTimePointLabels={filters.timePointLabels}
              deviation={filters.deviation}
            />
          </InnerChartWrapper>
        )}
      </ChartWrapper>
    </Base>
  );
};
