import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

import { selectors, t, config, generateFullName } from '@formue-app/core';
import { actions as authActions } from '../../store/auth';

import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_8,
} from '../../constants/spacing';
import { NotMobileWrapper, printCondition } from '../../constants/media';
import { accent } from '../../constants/colors';

import { ProfileIcon } from '../common/ProfileIcon';
import { SubMenu } from '../common/SubMenu';
import { H4, ParagraphSmall } from '../texts';
import { FlipArrow } from '../common/FlipArrow';
import { SpriteIcon } from '../common/SpriteIcon';

const { usersByIdSelector } = selectors.entities.users;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ProfileWrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    & > h4 {
      text-decoration: underline;
    }

    .profile-icon {
      background: ${accent.ocean330};
    }
  }
`;

const AdvisorProfileWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${accent.ocean250};
  padding: 10px 20px;
  border-bottom-left-radius: ${BORDER_RADIUS_LARGE};
  position: relative;
`;

const StyledProfileIcon = styled(ProfileIcon)`
  margin-right: ${SPACING_8};
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 48px;

  @media ${printCondition} {
    display: none;
  }
`;

const { currentUserSelector } = selectors.ui.user;

export const AdvisorProfileBar = (props) => {
  const dispatch = useDispatch();

  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);
  const isEmployee = useSelector((state) => state.auth.isEmployee);
  const authenticatedUserId = useSelector((state) => state.auth.userId);
  const user = useSelector(currentUserSelector);
  const usersById = useSelector(usersByIdSelector);
  const employeeClientUserId = useSelector(
    (state) => state.auth.employeeClientUser
  )?.id;

  const [showSubMenu, setShowSubMenu] = useState(false);

  let advisor;
  if (isEmployee) {
    advisor = usersById[authenticatedUserId];
  }

  const name = user ? generateFullName(user) : '';
  const advisorName = advisor ? generateFullName(advisor) : name;

  const onImpersonateEmployeeClientUser = () => {
    dispatch(authActions.impersonateUser(employeeClientUserId));
    dispatch(push('/advisor'));
  };

  const onLogOut = () => {
    dispatch(
      authActions.logout({
        url: config.domain,
      })
    );
  };

  const onResetImpersonation = () => {
    dispatch(authActions.resetImpersonation());
    dispatch(push('/advisor/clients'));
  };

  const menuItems = [
    {
      onClick: onResetImpersonation,
      icon: <SpriteIcon id="Process" size={SPACING_24} />,
      title: t('employeeClientSelect:logOffClient'),
      disable: !isImpersonating,
    },
    {
      onClick: onImpersonateEmployeeClientUser,
      icon: <SpriteIcon id="Individual" size={SPACING_24} />,
      title: t('employeeClientSelect:loginAsYourself'),
      disable: !employeeClientUserId,
    },
    {
      onClick: onLogOut,
      icon: <SpriteIcon id="Logout" size={SPACING_24} />,
      title: t('menu:logOut'),
    },
  ];

  return (
    <NotMobileWrapper>
      <Wrapper {...props}>
        <InnerWrapper>
          {isImpersonating && name ? (
            <>
              <ParagraphSmall style={{ margin: 'auto 10px' }}>
                {t('impersonation:as')}
              </ParagraphSmall>
              <ProfileWrapper to="/profile">
                <StyledProfileIcon
                  name={name}
                  size={32}
                  color={accent.ocean270}
                />
                <H4 style={{ marginRight: 20 }}>{name}</H4>
              </ProfileWrapper>
            </>
          ) : null}
          {isEmployee && advisor ? (
            <AdvisorProfileWrapper onClick={() => setShowSubMenu(!showSubMenu)}>
              <SpriteIcon
                id="Advisor"
                inverted={false}
                navigational={true}
                size={SPACING_16}
                style={{ marginRight: SPACING_8 }}
              />
              <H4 style={{ marginRight: SPACING_16 }}>{advisorName}</H4>
              <FlipArrow
                backgroundColor="none"
                color={accent.ocean4}
                direction={showSubMenu ? 'up' : 'down'}
              />
              <SubMenu
                items={menuItems}
                showSubMenu={showSubMenu}
                style={{ right: 11 }}
              />
            </AdvisorProfileWrapper>
          ) : null}
        </InnerWrapper>
      </Wrapper>
    </NotMobileWrapper>
  );
};
