import React from 'react';
import { PieChart } from '../../../graphs/PieChart';

export const SustainabilityPieChart = (props) => {
  const { size = 124, data, children, holeRadius = 46, style, ...rest } = props;

  return (
    <div style={{ position: 'relative', ...style }} {...rest}>
      <PieChart
        data={data}
        size={size}
        padAngle={3}
        style={{ marginRight: 6 }}
        animated={true}
        innerRadius={holeRadius}
        cornerRadius={2}
        holeColor="transparent"
      >
        {children}
      </PieChart>
    </div>
  );
};
