import React from 'react';
import { getFlagsSelector, t, useFullName } from '@formue-app/core';
import { useSelector } from 'react-redux';

import { MenuPage } from './base/MenuPage';
import { Feed } from '../components/feed/Feed';
import { NotDesktopWrapper, DesktopWrapper } from '../constants/media';
import { H2 } from '../components/texts';
import { GridElement } from '../components/layout';
import { SPACING_24 } from '../constants/spacing';

import { TodoHomeContainer } from '../components/todo/TodoHomeContainer';
import { MarketValueSummary } from '../components/portfolio/MarketValueSummary';
import { EventCarousel } from '../components/events/EventCarousel';
import { ModalSaveFilters } from '../components/extendedReporting/filters/ModalSaveFilters';
import { FormueInfo } from '../components/lead/FormueIntro';
import { BookMeeting } from '../components/lead/BookMeeting';
import { QuizPromotion } from '../components/lead/QuizPromotion';

export const HomePage = (props) => {
  let name = useFullName(t('dashboard:welcome'));
  const { isLead } = useSelector(getFlagsSelector);

  if (isLead) {
    // Some leads are confused and think they are looking at their real money, so to mitegate that
    // we prepend demo to the end of the name shown on the front page
    name = `${name} (${t('globals:demo')})`;
  }

  let columns = 12;

  return (
    <MenuPage {...props} title={name} subpage={false}>
      <NotDesktopWrapper>
        <MarketValueSummary columns={columns} />
        <EventCarousel columns={columns} sliderHeight={52} />
      </NotDesktopWrapper>

      <DesktopWrapper>
        <MarketValueSummary columns={8} />
        <EventCarousel columns={4} />
      </DesktopWrapper>

      {/* {!isLead ? (
        <>
          <FormueInfo />
          <BookMeeting />
          <QuizPromotion />
        </>
      ) : (
        <TodoHomeContainer
          taxSEContainEnabled={true}
          taxNOContainEnabled={true}
          QuizPromotionEnabled={true}
        />
      )} */}
      <TodoHomeContainer
        taxSEContainEnabled={true && !isLead}
        taxNOContainEnabled={true && !isLead}
        QuizPromotionEnabled={true}
      />

      <ModalSaveFilters />
      <GridElement columns={columns} style={{ marginTop: SPACING_24 }}>
        <H2>{t('dashboard:news:newsfeed')}</H2>
      </GridElement>
      <Feed />
    </MenuPage>
  );
};
