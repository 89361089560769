import { startRegistration } from '@simplewebauthn/browser';

import { webAuthn } from '@formue-app/core';

export const registerUsingWebAuthn = async () => {
  const registrationOptions = await webAuthn.generateRegistrationOptions();
  let registrationAttestation;

  try {
    // Pass the options to the authenticator and wait for a response
    registrationAttestation = await startRegistration(registrationOptions);
  } catch (error) {
    if (error.name === 'InvalidStateError') {
      console.error('Error: Authenticator was probably already registered by user');
    } else {
      console.error(error);
    }
    throw error;
  }

  // POST the response to the endpoint that calls
  // @simplewebauthn/server -> verifyRegistrationResponse()
  const verificationResult = await webAuthn.verifyRegistration(registrationAttestation);

  if (!verificationResult) {
    throw new Error('WebAuthn verification failed for registration');
  }

  return verificationResult;
}
