import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';

import { selectors } from '@formue-app/core';

import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { InvestmentStrategyStrategyPicker } from '../investmentStrategyComponents/InvestmentStrategyStrategyPicker';
import { PortfolioCharacteristics } from '../../investmentStrategies/portfolioCharacteristics/PortfolioCharacteristics';

const Base = styled.div`
  display: flex;
  flex: 1;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  position: relative;
`;

const {
  entities: {
    investmentStrategyPortfolioCharacteristics: {
      investmentStrategyPortfolioCharacteristicsByStrategyIdSelector,
    },
  },
} = selectors;

export const PortfolioCharacteristicsSlide = (props) => {
  const { isActiveSlide } = props;
  const strategies = useEnabledStrategies();

  const [queryParamsSelectedStrategy] = useQueryState('selectedStrategies', '');

  const portfolioCharacteristics = useSelector(
    investmentStrategyPortfolioCharacteristicsByStrategyIdSelector(
      queryParamsSelectedStrategy
    )
  );

  if (!strategies.length) return null;

  return (
    <Base>
      <InvestmentStrategyStrategyPicker />

      {portfolioCharacteristics ? (
        <PortfolioCharacteristics
          strategyId={queryParamsSelectedStrategy}
          animate={isActiveSlide}
        />
      ) : (
        <CenteredActivityIndicator />
      )}
    </Base>
  );
};
