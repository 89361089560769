import React from 'react';
import styled from 'styled-components';

import {
  H2Size,
  H2LineHeight,
  H2SizeMobile,
  H2LineHeightMobile,
} from '../../constants/text';
import { notDesktopCondition } from '../../constants/media';

export const StyledH2 = styled.h2`
  font-size: ${H2Size};
  line-height: ${H2LineHeight};

  @media ${notDesktopCondition} {
    font-size: ${H2SizeMobile};
    line-height: ${H2LineHeightMobile};
  }
`;

export const H2 = React.forwardRef((props, ref) => {
  const { children } = props;

  return (
    <StyledH2 ref={ref} {...props}>
      {children}
    </StyledH2>
  );
});
