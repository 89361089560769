import React from 'react';
import { useSelector } from 'react-redux';

import { BaseListItem } from '../../common/BaseListItem';
import { TodoListItem } from './TodoListItem';
import { useExpertInputColumns } from './columns/expertInputColumns';

export const ExpertInputListItem = (props) => {
  const { id } = props;
  const item = useSelector((state) => state.entities.expertInputs.byId[id]);

  const columns = useExpertInputColumns({ id, item });

  return (
    <BaseListItem>
      <TodoListItem columns={columns} />
    </BaseListItem>
  );
};
