import { apiFetch } from './utils/api';

export const qlikSessions = {
  post: async () => {
    const options = {
      method: 'POST',
    };

    const url = '/v2/qlik/sessions';
    return apiFetch(url, options);
  },
};
