import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import {
  getAssetClassName,
  formatGainValue,
  formatTwrValue,
  formatMoney,
  percentage,
  getColorForAssetClassGroup,
  t,
  formatPeriodLabel,
  localizeFloat,
  toLocaleDateString,
  selectors,
} from '@formue-app/core';

import {
  tabletCondition,
  mobileCondition,
  notPrintCondition,
} from '../../../constants/media';
import { accent } from '../../../constants/colors';
import { StyledCell } from '../../lists/TableList';

import {
  H5,
  H4,
  Paragraph,
  ParagraphSmall,
  ParagraphXSmall,
} from '../../texts';
import { DonutGraph } from '../../graphs/DonutGraph';
import { BORDER_RADIUS_LARGE } from '../../../constants/spacing';
import { FlipArrow } from '../../common/FlipArrow';
import { easeInOut, longAnimationTime } from '../../../constants/animation';

const {
  entities: {
    funds: { allFundOfFundsSelector },
  },
} = selectors;

const assetClassDefaultColorScheme = [
  accent.velvet1,
  accent.velvet2,
  accent.velvet3,
];

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LightStyledCell = styled(StyledCell)`
  span {
    font-weight: 400;
  }
`;

const StyledHoldingsCell = styled(StyledCell)`
  position: relative;

  &:first-child:before {
    position: absolute;
    content: '';
    top: 4px;
    bottom: 4px;
    left: 4px;
    width: 4px;
    border-radius: ${BORDER_RADIUS_LARGE};
    background-color: ${(props) =>
      getColorForAssetClassGroup(
        props?.item?.group,
        assetClassDefaultColorScheme
      )};
  }
`;

const StyledDonutGraph = styled(DonutGraph)`
  margin-left: 6px;
  margin-right: 10px;
`;

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledFlipArrow = styled(FlipArrow)`
  margin-left: 12px;
  transition: all ${longAnimationTime} ${easeInOut};

  @media ${mobileCondition} {
    width: 20px;
    height: 20px;
  }
`;

const formatDateString = (date) => {
  if (!date || new Date(date) <= new Date('01.01.1970')) {
    return '-';
  } else {
    return toLocaleDateString(date);
  }
};

const formatQuantity = (quantity) => {
  if (!quantity) {
    return '-';
  } else {
    return localizeFloat(quantity, 4);
  }
};

const formatPrice = (price) => {
  if (!price) {
    return '-';
  } else {
    return localizeFloat(price, 4);
  }
};

const formatInt = (value) => {
  if (parseInt(value) === 0) {
    return '-';
  } else {
    return value;
  }
};

export const useHoldingsListColumns = (props) => {
  const items = props;
  const {
    period: activePeriod,
    startDate,
    endDate,
  } = useSelector((state) => state.ui.portfolio.filters);
  const allFunds = useSelector(allFundOfFundsSelector);
  const periodLabel = formatPeriodLabel(activePeriod, startDate, endDate);

  const tabletSize = useMediaQuery({
    query: tabletCondition,
  });

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const notPrint = useMediaQuery({
    query: notPrintCondition,
  });

  if (!items.length) {
    return null;
  }

  const totalMarketValue = items
    .map((item) => item.mv)
    .reduce((accumulator, currentValue) => accumulator + currentValue);

  const getHoldingListItemTitle = (item) => {
    const {
      dataType,
      class: c,
      p,
      mv,
      price,
      priceDate,
      quantity,
      subItem,
    } = item;

    let value = mv / totalMarketValue;
    value = isNaN(value) ? 0 : value;
    const fund = allFunds.filter((fund) => fund.name === p);

    return (
      <ColumnWrapper style={{ width: '100%' }}>
        {!subItem ? (
          <H4>{dataType === 'assetClass' ? getAssetClassName(c) : p}</H4>
        ) : (
          <TitleWrapper>
            <H5 style={{ margin: 0 }}>
              {dataType === 'assetClass' ? getAssetClassName(c) : p}
            </H5>
            {Boolean(fund.length) ? (
              <StyledFlipArrow
                className="flip-arrow"
                width={mobileSize ? 10 : 14}
                height={mobileSize ? 10 : 14}
              />
            ) : null}
          </TitleWrapper>
        )}
        {!subItem ? (
          <GraphWrapper>
            <Paragraph>{percentage(value * 100)}</Paragraph>
            <StyledDonutGraph value={value} />
          </GraphWrapper>
        ) : (
          <RowWrapper style={{ marginTop: 8 }}>
            <ParagraphXSmall style={{ marginRight: 15 }}>
              {t('portfolio:holdingsTable:date', {
                date: formatDateString(priceDate),
              })}
            </ParagraphXSmall>
            <ParagraphXSmall style={{ marginRight: 15 }}>
              {t('portfolio:holdingsTable:price', {
                price: formatPrice(price),
              })}
            </ParagraphXSmall>
            <ParagraphXSmall>
              {t('portfolio:holdingsTable:amount', {
                amount: formatQuantity(quantity),
              })}
            </ParagraphXSmall>
          </RowWrapper>
        )}
      </ColumnWrapper>
    );
  };

  const getHoldingListItemTitleTablet = (item) => {
    const {
      dataType,
      class: c,
      p,
      mv,
      costPrice,
      unrealizedGain,
      price,
      priceDate,
      quantity,
      subItem,
    } = item;

    let value = mv / totalMarketValue;
    value = isNaN(value) ? 0 : value;
    const fund = allFunds.filter((fund) => fund.name === p);

    return (
      <ColumnWrapper style={{ width: '100%' }}>
        {!subItem ? (
          <H4>{dataType === 'assetClass' ? getAssetClassName(c) : p}</H4>
        ) : (
          <TitleWrapper>
            <H5 style={{ margin: 0 }}>
              {dataType === 'assetClass' ? getAssetClassName(c) : p}
            </H5>
            {Boolean(fund.length) ? (
              <StyledFlipArrow
                className="flip-arrow"
                width={mobileSize ? 10 : 14}
                height={mobileSize ? 10 : 14}
              />
            ) : null}
          </TitleWrapper>
        )}
        {!subItem ? (
          <GraphWrapper>
            <Paragraph>{percentage(value * 100)}</Paragraph>
            <StyledDonutGraph value={value} />
          </GraphWrapper>
        ) : (
          <>
            <RowWrapper style={{ marginBottom: 11, marginTop: 8 }}>
              <ColumnWrapper style={{ width: '33%' }}>
                <ParagraphXSmall style={{ color: '#5D6590' }}>
                  {t('portfolio:holdingsTable:costPrice')}
                </ParagraphXSmall>
                <ParagraphSmall>
                  {formatInt(formatMoney(costPrice))}
                </ParagraphSmall>
              </ColumnWrapper>
              <ColumnWrapper style={{ width: '33%' }}>
                <ParagraphXSmall style={{ color: '#5D6590' }}>
                  {t('portfolio:holdingsTable:marketValue')}
                </ParagraphXSmall>
                <ParagraphSmall>{formatInt(formatMoney(mv))}</ParagraphSmall>
              </ColumnWrapper>
              <ColumnWrapper style={{ width: '33%' }}>
                <ParagraphXSmall style={{ color: '#5D6590' }}>
                  {t('portfolio:holdingsTable:unrealizedReturnSinceStart')}
                </ParagraphXSmall>
                <ParagraphSmall>
                  {formatInt(formatGainValue(unrealizedGain, false))}
                </ParagraphSmall>
              </ColumnWrapper>
            </RowWrapper>
            <RowWrapper>
              <ParagraphXSmall style={{ width: '33%' }}>
                {t('portfolio:holdingsTable:date', {
                  date: formatDateString(priceDate),
                })}
              </ParagraphXSmall>
              <ParagraphXSmall style={{ width: '33%' }}>
                {t('portfolio:holdingsTable:price', {
                  price: formatPrice(price),
                })}
              </ParagraphXSmall>
              <ParagraphXSmall style={{ width: '33%' }}>
                {t('portfolio:holdingsTable:amount', {
                  amount: formatQuantity(quantity),
                })}
              </ParagraphXSmall>
            </RowWrapper>
          </>
        )}
      </ColumnWrapper>
    );
  };

  const getGainOverTime = (item) => {
    const { gain, twr, subItem } = item;
    return !subItem ? (
      <ColumnWrapper>
        <H4>{formatGainValue(gain, false)}</H4>
        <Paragraph>({formatTwrValue(twr)})</Paragraph>
      </ColumnWrapper>
    ) : (
      <ColumnWrapper>
        <ParagraphSmall>{formatGainValue(gain, false)}</ParagraphSmall>
        <ParagraphXSmall>({formatTwrValue(twr)})</ParagraphXSmall>
      </ColumnWrapper>
    );
  };

  // Define the table columns
  let columns = [
    {
      key: 'class',
      label: t('portfolio:holdingsTable:assetClass'),
      render: getHoldingListItemTitle,
      component: StyledHoldingsCell,
      defaultSort: true,
      width: '36%',
    },
    {
      key: 'costprice',
      label: t('portfolio:holdingsTable:costPrice'),
      render: ({ costPrice }) => formatInt(formatMoney(costPrice)),
      width: '16%',
      component: LightStyledCell,
      type: 'number',
    },
    {
      key: 'mv',
      label: endDate
        ? `${t('portfolio:holdingsTable:marketValue')} ${toLocaleDateString(
            endDate
          )}`
        : t('portfolio:holdingsTable:marketValue'),
      render: ({ mv }) => formatInt(formatMoney(mv)),
      width: '16%',
      component: LightStyledCell,
      type: 'number',
    },
    {
      key: 'unrealizedGain',
      label: t('portfolio:holdingsTable:unrealizedReturnSinceStart'),
      render: ({ unrealizedGain }) =>
        formatInt(formatGainValue(unrealizedGain, false)),
      width: '16%',
      type: 'number',
    },
    {
      key: 'gain-last-n-years',
      label: t(
        `portfolio:holdingsTable:${
          activePeriod === 'CUSTOM' ? 'returnInCustomPeriod' : 'returnInPeriod'
        }`,
        {
          period: periodLabel,
        }
      ),
      render: getGainOverTime,
      width: '16%',
      type: 'number',
    },
  ];

  if (tabletSize && notPrint) {
    columns = [
      {
        key: 'class',
        label: t('portfolio:holdingsTable:assetClass'),
        render: getHoldingListItemTitleTablet,
        component: StyledHoldingsCell,
        defaultSort: true,
        width: '70%',
      },
      {
        key: 'gain-last-n-years',
        label: t(
          `portfolio:holdingsTable:${
            activePeriod === 'CUSTOM'
              ? 'returnInCustomPeriod'
              : 'returnInPeriod'
          }`,
          {
            period: periodLabel,
          }
        ),
        render: getGainOverTime,
        width: '30%',
        type: 'number',
      },
    ];
  } else if (mobileSize && notPrint) {
    columns = [
      {
        key: 'class',
        label: t('portfolio:holdingsTable:assetClass'),
        render: getHoldingListItemTitle,
        component: StyledHoldingsCell,
        defaultSort: true,
        width: '65%',
      },
      {
        key: 'gain-last-n-years',
        label: t(
          `portfolio:holdingsTable:${
            activePeriod === 'CUSTOM'
              ? 'returnInCustomPeriod'
              : 'returnInPeriod'
          }`,
          {
            period: periodLabel,
          }
        ),
        render: getGainOverTime,
        width: '45%',
        type: 'number',
      },
    ];
  }

  return columns;
};
