import { useAnnualReviewSlides } from './useAnnualReviewSlides';
import { useInvestmentStrategySlides } from './useInvestmentStrategySlides';
import { useInvestmentStrategyExportSlides } from './useInvestmentStrategyExportSlides';

export const useSlides = () => {
  return {
    annualReview: useAnnualReviewSlides(),
    investmentStrategy: useInvestmentStrategySlides(),
    investmentStrategyExport: useInvestmentStrategyExportSlides(),
  };
};
