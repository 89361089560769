import React from 'react';
import styled from 'styled-components';
import { useResource } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_24,
  SPACING_64,
} from '../../../constants/spacing';
import { StrategyChoiceItem } from '../investmentStrategyComponents/StrategyChoiceItem';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';

const Base = styled.div`
  display: flex;
  flex: 1;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const StrategyItemsContainer = styled.div`
  display: flex;
  flex: 1;
  gap: ${(props) => props.spacing};
  justify-content: center;
`;

export const ChooseStrategySlide = (props) => {
  const { isActiveSlide } = props;
  const strategies = useEnabledStrategies();

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  if (!strategies.length) return null;

  return (
    <Base>
      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <StrategyItemsContainer
          spacing={strategies.length > 3 ? SPACING_24 : SPACING_64}
        >
          {strategies.map((strategy, index) => (
            <StrategyChoiceItem
              animate={isActiveSlide}
              key={`strategy-choice-item-${index}`}
              index={index}
              {...strategy}
            />
          ))}
        </StrategyItemsContainer>
      )}
    </Base>
  );
};
