import React from 'react';
import styled from 'styled-components';

import { config } from '@formue-app/core';

import { slideSidebarImages } from './presentationImageLookup';

const ImageWrapper = styled.div`
  width: 40%;
  height: 100%;
  background-size: cover;
  background-position: center center;
`;

export const SidebarImage = (props) => {
  const { id, presentationType } = props;

  // We consider October to March as winter and summer to be April to September
  let season = 'summer';
  if (new Date().getMonth() > 9 && new Date().getMonth() > 4) {
    season = 'winter';
  }

  return (
    <ImageWrapper
      style={{
        backgroundImage: `url(${
          slideSidebarImages[presentationType][season][config.countryCode][id]
        })`,
      }}
    />
  );
};
