import { useSelector } from 'react-redux';

import { getFlagsSelector } from '../../store/flags';

export const useWealthPlan = () => {
  // Use only feature flag for now. At some point we maybe want to
  // reintroduce "wealthPlanVisibleSelector" or a more suitable solution
  // to check if there is some WealthPlan data available at all.
  const {
    features: { wealthPlan: wealthPlanFeatureEnabled },
  } = useSelector(getFlagsSelector);
  return Boolean(wealthPlanFeatureEnabled);
};
