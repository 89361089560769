import React from 'react';
import styled, { css } from 'styled-components';

import { percentage } from '@formue-app/core';

import { H1Small, H5 } from '../../texts';
import { accent } from '../../../constants/colors';
import { mobileCondition } from '../../../constants/media';

const Dash = styled.div`
  position: absolute;
  opacity: 0.3;
  width: ${(props) => props.dashWidth}px;
  left: -${(props) => props.dashWidth + props.dashSpacing}px;
  right: auto;
  /* translate: -35px; */
  border-bottom: 1px dashed ${accent.ocean4};

  @media ${mobileCondition} {
    width: 30px;
    left: -15px;
  }

  ${(props) =>
    props.labelPosition === 'left' &&
    css`
      left: auto;
      right: -${(props) => props.dashWidth + props.dashSpacing}px;

      @media ${mobileCondition} {
        width: 30px;
        right: 15px;
      }
    `}
`;

const Label = styled.div`
  width: max-content;
  position: relative;
  height: calc(100% / 3);
  left: ${(props) => props.offset}px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: calc(${(props) => props.dashWidth}px + 10px);

  @media ${mobileCondition} {
    margin-left: calc(15px + 10px);
  }

  ${(props) =>
    props.labelPosition === 'left' &&
    css`
      flex-direction: row-reverse;
      left: initial;
      right: ${(props) => props.offset}px;
      margin-right: calc(${(props) => props.dashWidth}px + 10px);
      margin-left: 0;

      @media ${mobileCondition} {
        margin-right: calc(15px + 10px);
        margin-left: 0;
      }
    `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const FixedPyramidLegendRow = (props) => {
  const {
    labelPosition,
    label,
    LabelComponent = H5,
    value,
    ValueComponent = H1Small,
    height,
    offset,
    dashWidth = 60,
    dashSpacing = 25,
  } = props;

  return (
    <Label
      offset={offset}
      labelPosition={labelPosition}
      dashWidth={dashWidth}
      dashSpacing={dashSpacing}
    >
      <Dash
        height={height / 3}
        labelPosition={labelPosition}
        dashWidth={dashWidth}
        dashSpacing={dashSpacing}
      />
      <TextWrapper>
        <LabelComponent>{label}</LabelComponent>
        {typeof value === 'number' ? (
          <ValueComponent>{percentage(value * 100, 0)}</ValueComponent>
        ) : null}
      </TextWrapper>
    </Label>
  );
};
