import React from 'react';
import { useSelector } from 'react-redux';

import { config, t, selectors } from '@formue-app/core';

import { MenuPage } from './base/MenuPage';
import { ContactCard } from '../components/common/ContactCard';

import logo from '../assets/images/logo-contact.png';

const { operatingCountrySelector } = selectors.auth;

export const ContactPage = (props) => {
  const operatingCountry = useSelector(operatingCountrySelector);
  const contact = {
    email: t('contact:leadContact:email', {
      context: operatingCountry,
    }),
    phone: t('contact:leadContact:phone', {
      context: operatingCountry,
    }),
    photoUrl: logo,
    name: config.title,
  };

  return (
    <MenuPage {...props}>
      <ContactCard contact={contact} columns={12} />
    </MenuPage>
  );
};
