import React from 'react';
import styled, { css } from 'styled-components';
import { backgroundWhite } from '../../constants/colors';
import { contentColumnCount } from '../../constants/grid';
import {
  mobileCondition,
  notDesktopCondition,
  tabletCondition,
} from '../../constants/media';
import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_32,
  SPACING_16,
} from '../../constants/spacing';
import { GridElement, MainGrid } from '../layout';
import { H2, H3 } from '../texts';
import { BaseSection } from './BaseSection';

const StyledBackgroundContainer = styled(BaseSection)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex: 1;
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: 0;

  ${(props) =>
    props.fullSize
      ? `@media ${mobileCondition} {
    border-radius: 0;
    padding-top: 145px;
    margin: 0 -${SPACING_16};
    margin-top: -145px;
  }`
      : null}
`;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;

  @media ${tabletCondition} {
    grid-template-columns: repeat(
      ${(props) =>
        props.sideChildren ? contentColumnCount / 2 : contentColumnCount},
      1fr
    );
  }
`;

const StyledGridElement = styled(GridElement)`
  padding: 80px ${SPACING_48};

  @media ${tabletCondition} {
    padding: ${SPACING_48};
  }

  @media ${mobileCondition} {
    padding: ${SPACING_32};
  }
`;

const ContentGridElement = styled(StyledGridElement)`
  @media ${notDesktopCondition} {
    ${(props) =>
      props.sideChildren
        ? css`
            padding-bottom: 0;
          `
        : null}
  }
`;

const SidebarGridElement = styled(StyledGridElement)`
  padding-top: ${SPACING_32};

  @media ${notDesktopCondition} {
    ${(props) =>
      props.sideChildren
        ? css`
            padding-top: 0;
          `
        : null}
  }
`;

export const HeaderWithBackgroundImage = (props) => {
  const {
    title,
    subtitle,
    backgroundImage,
    backgroundColor,
    textColor = backgroundWhite,
    subtitleColor = textColor,
    fullSize = false,
    sideChildren = false,
    children,
    ...rest
  } = props;
  return (
    <StyledBackgroundContainer
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundColor }}
      fullSize={fullSize}
      columns={12}
      {...rest}
    >
      <StyledSubGrid sideChildren={sideChildren}>
        <ContentGridElement
          columns={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          sideChildren={sideChildren}
        >
          <H2 style={{ color: textColor, paddingBottom: 12 }}>{title}</H2>
          <H3 as="p" style={{ color: subtitleColor }}>
            {subtitle}
          </H3>
          {!sideChildren && children}
        </ContentGridElement>
        {sideChildren && (
          <SidebarGridElement columns={6} sideChildren={sideChildren}>
            {children}
          </SidebarGridElement>
        )}
      </StyledSubGrid>
    </StyledBackgroundContainer>
  );
};
