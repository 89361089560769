import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { easeInOut, mediumAnimationTime } from '../../constants/animation';
import { overlayColor, blackTranslucent, accent } from '../../constants/colors';
import { actions as navigationActions } from '../../store/ui/navigation';
import { PortfolioFilterPopoverContent } from './PortfolioFilterPopoverContent';

const popoverMaxWidth = '400px';

const StyledPopoverWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  pointer-events: none;
  z-index: 12;

  &.open {
    pointer-events: auto;
  }
`;

const StyledPopoverBackground = styled.div`
  height: 100%;
  width: 100%;
  left: 0;
  position: fixed;
  background: ${overlayColor};
  opacity: 0;
  transition: opacity ${mediumAnimationTime} ${easeInOut},
    width ${mediumAnimationTime} ${easeInOut};
  pointer-events: none;

  &.open {
    width: calc(100% - ${popoverMaxWidth});
    opacity: 1;
    pointer-events: auto;
  }
`;

const StyledPopoverContent = styled.div`
  background: ${accent.sand1};
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  position: absolute;
  right: -${popoverMaxWidth};
  width: 100%;
  max-width: ${popoverMaxWidth};
  transition: all ${mediumAnimationTime} ${easeInOut};
  box-shadow: -4px 0px 10px 0px ${blackTranslucent(0)};

  &.open {
    right: 0;
    box-shadow: -4px 0px 10px 0px ${blackTranslucent(0.2)};
  }
`;

const usePopover = () => {
  const { popoverOpen, popoverType } = useSelector(
    (state) => state.ui.navigation
  );
  return { isOpen: popoverOpen, type: popoverType };
};

export const PopoverBase = (props) => {
  const dispatch = useDispatch();
  const { isOpen, type } = usePopover();

  let ContentComponent;
  switch (type) {
    case 'portfolioFilters':
      ContentComponent = PortfolioFilterPopoverContent;
      break;
    default:
      ContentComponent = () => null;
      break;
  }

  return (
    <StyledPopoverWrapper className={isOpen && 'open'}>
      <StyledPopoverBackground
        className={isOpen && 'open'}
        onClick={() => dispatch(navigationActions.togglePopover())}
      />
      <StyledPopoverContent className={isOpen && 'open'}>
        <ContentComponent />
      </StyledPopoverContent>
    </StyledPopoverWrapper>
  );
};
