import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { authenticatorDevices } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the network resource
export const [actions, reducer, resourceSagas] = resourceReducer(
  'authenticator-devices'
);

// Create the sagas for this entity
function* readAuthenticatorDevices() {
  yield takeEvery(actions.indexRequestBegin, function* () {
    try {
      const result = yield call(authenticatorDevices.get);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

function* deleteAuthenticatorDevice() {
  yield takeEvery(actions.destroyRequestBegin, function* ({ payload }) {
    try {
      yield call(authenticatorDevices.delete, payload);
      yield put(actions.destroyRequestSuccess(payload));
    } catch (error) {
      yield put(actions.destroyRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [
  readAuthenticatorDevices,
  deleteAuthenticatorDevice,
  ...resourceSagas,
];
