import React from 'react';
import styled, { css } from 'styled-components';
import { maxBy, minBy } from 'lodash';

import { accent } from '../../constants/colors';
import { BarChartLabel } from './components/BarChartLabel';
import { H3 } from '../texts';
import { BORDER_RADIUS_LARGE, SPACING_16 } from '../../constants/spacing';
import { useBoundingRect } from '../../services/hooks/layout';
import { easeInOut, longAnimationTime } from '../../constants/animation';
import { BarChartYAxis } from './components/BarChartYAxis';
import { BarChartPoint } from './components/BarChartPoint';
import { mobileCondition } from '../../constants/media';
import { H3Size, paragraphSize } from '../../constants/text';

const AxisWrapper = styled.div`
  // Spacing for labels
  margin-top: 37px;
  margin-bottom: 37px;
  flex: 1;
  position: relative;

  ${(props) =>
    props.yAxis &&
    css`
      padding-left: ${(props) => props.yAxisPadding}px;
    `}
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  align-self: center;

  position: relative;
`;

const Bars = styled.div`
  display: flex;
  flex: 1;
`;

const BarWrapper = styled.div`
  width: ${(props) => 100 / props.itemCount}%;
  text-align: center;
  height: 100%;
`;

const BarInner = styled.div`
  width: 100%;
  top: ${(props) => props.axisPosition}px;
  position: relative;
  width: calc(100% - ${(props) => props.barSpacing}px);
  margin-left: ${(props) => props.barSpacing / 2}px;
  ${(props) =>
    !props.lastItem &&
    !props.zeroAxis &&
    css`
      &:after {
        content: '';
        display: block;
        position: relative;
        height: 2px;
        background: ${accent.ocean2};
        width: 24px;
        margin-left: 6px;
        align-self: flex-start;
        border-radius: 1px;
        transition: all ${longAnimationTime} ${easeInOut};
        left: 100%;
      }
    `}
`;

const BarArea = styled.div`
  width: 100%;
  background-color: ${accent.ocean4};
  border-radius: ${BORDER_RADIUS_LARGE};
  height: ${(props) => Math.abs(props.height)}px;
  transition: all ${longAnimationTime} ${easeInOut};
  position: absolute;
  bottom: ${(props) => (!props.isNegative ? 0 : 'auto')};
`;

const Labels = styled.div`
  display: flex;
  padding-top: ${SPACING_16};
  position: absolute;
  width: 100%;
`;

const LabelWrapper = styled.div`
  width: ${(props) => 100 / props.itemCount}%;
  text-align: center;
  position: relative;
  top: ${(props) => (!props.isNegative ? '0px' : '-50px')};
`;

const Label = styled(H3)`
  color: ${accent.ocean4};
  @media ${mobileCondition} {
    rotate: 90deg;
    left: 10px;
    font-size: ${paragraphSize};
  }
`;

const Bar = (props) => {
  const {
    itemCount,
    value = 0,
    lastItem,
    axisPosition,
    chartHeight,
    coef,
    color = accent.ocean4,
    colorScheme,
    showValue,
    zeroAxis,
    valueFormatFunction,
    barSpacing,
    index,
    costumValueComponent,
    ...rest
  } = props;

  const barHeight = coef * Math.abs(value);

  return (
    <BarWrapper itemCount={itemCount}>
      <BarInner
        axisPosition={axisPosition}
        maxHeight={chartHeight}
        lastItem={lastItem}
        zeroAxis={zeroAxis}
        barSpacing={barSpacing}
      >
        <BarArea
          height={barHeight}
          isNegative={value < 0}
          style={{
            backgroundColor: colorScheme.length ? colorScheme[index] : color,
          }}
          className="printBackground"
        />
        {showValue && (
          <BarChartLabel
            valueColor={color}
            value={value}
            offsetY={barHeight + 10}
            isNegative={value < 0}
            valueFormatFunction={valueFormatFunction}
            costumValueComponent={costumValueComponent}
            {...rest}
          />
        )}
      </BarInner>
    </BarWrapper>
  );
};

export const BarChart = ({
  data = [],
  axisPoints = [],
  axisPointsOffsetLeft = 0,
  valueKey,
  labelKey,
  yAxis,
  zeroAxis,
  color = accent.ocean4,
  zeroAxisColor = color,
  zeroAxisWidth = 2,
  colorScheme = [],
  labelColor,
  labelFontSize = H3Size,
  showValue = true,
  yAxisPadding = 80,
  valueFormatFunction,
  barSpacing = 36,
  costumValueComponent,
  paddingRight = 0,
  labelBackgroundColor,
  labelComponent,
}) => {
  const { ref, dimensions } = useBoundingRect();

  if (!data.length) return null;

  const { height } = dimensions;

  let maxY = maxBy(data, valueKey)[valueKey];
  let minY = Math.min(0, minBy(data, valueKey)[valueKey]);

  if (axisPoints.length) {
    const axisPointsMaxY = maxBy(axisPoints, valueKey)[valueKey];
    const axisPointsMinY = maxBy(axisPoints, valueKey)[valueKey];

    if (axisPointsMaxY > maxY) {
      maxY = axisPointsMaxY;
    }
    if (axisPointsMinY < minY) {
      minY = axisPointsMaxY;
    }
  }

  // Total diff between smallest and largest number + 15% headroom for the icons and text
  let range = Math.abs(minY) + Math.abs(maxY);

  // Range can't be zero, otherwise we'll get an "infinite" coef value
  if (range === 0) {
    range = 1;
  }

  // coef aka 100% that we can use to calculate the height of the bars
  const coef = height / range;
  let axisPosition = coef * Math.abs(maxY);

  if (minY >= 0) {
    axisPosition = height - 1;
  }

  const LabelComponent = labelComponent;

  return (
    <AxisWrapper yAxis={yAxis} yAxisPadding={yAxisPadding}>
      {yAxis && (
        <BarChartYAxis
          max={maxY}
          min={minY < 0 && minY}
          coef={coef}
          axisPosition={axisPosition}
          zeroAxis={zeroAxis}
          zeroAxisColor={zeroAxisColor}
          zeroAxisWidth={zeroAxisWidth}
          axisColor={accent.velvet170}
          yAxisPadding={yAxisPadding}
          paddingRight={paddingRight}
          labelBackgroundColor={labelBackgroundColor}
        >
          {axisPoints.map((item) => (
            <BarChartPoint
              label={item.label}
              value={item[valueKey]}
              coef={coef}
              axisPosition={axisPosition}
              zeroAxis={zeroAxis}
              color={item.color}
              key={`bar-chart-point-${item.label}`}
              offsetLeft={axisPointsOffsetLeft}
            />
          ))}
        </BarChartYAxis>
      )}

      <ChartWrapper ref={ref}>
        <Bars style={{ paddingRight }}>
          {data.map((item, index) => {
            return (
              <Bar
                itemCount={data.length}
                data={data[index]}
                index={index}
                value={item[valueKey]}
                axisPosition={axisPosition}
                chartHeight={height}
                coef={coef}
                lastItem={index === 4}
                color={color}
                colorScheme={colorScheme}
                showValue={showValue}
                zeroAxis={zeroAxis}
                valueFormatFunction={valueFormatFunction}
                key={`bar-${index}`}
                barSpacing={barSpacing}
                costumValueComponent={costumValueComponent}
              />
            );
          })}
        </Bars>
        <Labels style={{ top: axisPosition, paddingRight }}>
          {data.map((item, index) => {
            const value = item[valueKey];
            const label = labelKey ? item[labelKey] : item.label;

            if (label) {
              return (
                <LabelWrapper
                  key={`label-${label}`}
                  isNegative={value < 0}
                  itemCount={data.length}
                >
                  {labelComponent ? (
                    <LabelComponent {...item} index={index} />
                  ) : (
                    <Label
                      style={{
                        color: labelColor ? labelColor : color,
                        fontSize: labelFontSize,
                      }}
                    >
                      {label}
                    </Label>
                  )}
                </LabelWrapper>
              );
            }
            return null;
          })}
        </Labels>
      </ChartWrapper>
    </AxisWrapper>
  );
};
