import { startAuthentication } from '@simplewebauthn/browser';
import { webAuthn } from '@formue-app/core';

export const authenticateUsingWebAuthn = async () => {
  const {
    options: authenticiationOptions,
    sessionId,
  } = await webAuthn.generateAuthenticationOptions();

  let authenticationAssertion;
  try {
    // Pass the options to the authenticator and wait for a response
    authenticationAssertion = await startAuthentication(authenticiationOptions);
  } catch (error) {
    console.error(error);
    throw error;
  }

  // POST the response to the endpoint that calls
  // @simplewebauthn/server -> verifyAuthenticationResponse()
  const verificationResult = await webAuthn.verifyAuthentication(authenticationAssertion, sessionId);

  if (!verificationResult) {
    throw new Error('WebAuthn verification failed for authentication');
  }
  return verificationResult;
}
