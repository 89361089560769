import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { localizeFloat } from '@formue-app/core';
import { accent } from '../../../../constants/colors';
import { CountryMarkers } from './CountryMarkers';
import { tabletCondition, mobileCondition } from '../../../../constants/media';
import styled from 'styled-components';
import { easeInOut, longAnimationTime } from '../../../../constants/animation';

const StyledWrapper = styled.div`
  position: relative;

  &:after {
    box-shadow: inset 0 0 ${(props) => (props.zoomEnabled ? 50 : 0)}px
      ${(props) => `${props.zoomEnabled ? 50 : 0}px ${props.boxShadowColor}`};
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
    transition: all ${easeInOut} ${longAnimationTime};
  }
`;

const zoomPositions = {
  Global: '-10, 0, 363, 199',
  'North America': '-20, 0, 180, 140',
  'South America': '0, 100, 180, 100',
  Europe: '80, 0, 200, 80',
  Africa: '80, 60, 200, 120',
  Asia: '110, 0, 280, 120',
  Oceania: '220, 100, 150, 80',
};

const markerPositions = {
  'North America': { x: 58, y: 65 },
  'South America': { x: 100, y: 145 },
  Europe: { x: 180, y: 55 },
  Africa: { x: 180, y: 110 },
  Asia: { x: 255, y: 50 },
  Oceania: { x: 290, y: 160 },
};

export const SvgMap = ({
  highlight = [],
  zoom = 'Global',
  continents = [],
  topThreeCountries = [],
  onClick,
  width = 600,
  height = 350,
  highlightColor = accent.ocean3,
  defaultColor = accent.ocean1,
  boxShadowColor = accent.ocean4,
  countryMarkersStyles,
  ...rest
}) => {
  let _width = width;
  let _height = height;

  const isTablet = useMediaQuery({
    query: tabletCondition,
  });
  const isMobile = useMediaQuery({
    query: mobileCondition,
  });

  if (isTablet) {
    _width = 450;
    _height = 270;
  }
  if (isMobile) {
    _width = 300;
    _height = 180;
  }

  const animateRef = useRef(null);

  const [oldViewPort, setOldViewPort] = useState(zoomPositions['Global']);
  const [newViewPort, setNewViewPort] = useState(zoomPositions[zoom]);

  useEffect(() => {
    setOldViewPort(newViewPort);
    setNewViewPort(zoomPositions[zoom]);

    if (animateRef) {
      animateRef.current.beginElement();
    }
  }, [zoom, newViewPort]);

  return (
    <StyledWrapper
      zoomEnabled={zoom !== 'Global'}
      boxShadowColor={boxShadowColor}
      {...rest}
    >
      <svg
        width={`${_width}px`}
        height={`${_height}px`}
        viewBox={zoomPositions['Global']}
      >
        <animate
          ref={animateRef}
          attributeName="viewBox"
          from={oldViewPort}
          to={newViewPort}
          values={`${oldViewPort};${newViewPort}`}
          dur="0.6s"
          fill="freeze"
          repeatCount="1"
          restart="whenNotActive"
          keySplines="0.37, 0, 0.63, 1"
          calcMode="spline"
        />
        <g id="World map=Master">
          <g
            id="Oceania"
            fill={highlight.includes('Oceania') ? highlightColor : defaultColor}
            onClick={() => {
              onClick('Oceania');
            }}
          >
            <path d="M313.906 166.141L315.232 166.907V168.438L313.906 169.203L312.58 168.438V166.907L313.906 166.141Z" />
            <path d="M310.844 166.141L312.17 166.907V168.438L310.844 169.203L309.518 168.438V166.907L310.844 166.141Z" />
            <path d="M318.5 158.102L319.826 158.867V160.398L318.5 161.164L317.174 160.398V158.867L318.5 158.102Z" />
            <path d="M321.563 125.945L322.889 126.711V128.242L321.563 129.008L320.237 128.242V126.711L321.563 125.945Z" />
            <path d="M298.594 144.703L299.92 145.469V147L298.594 147.766L297.267 147V145.469L298.594 144.703Z" />
            <path d="M290.937 142.024L292.264 142.789V144.321L290.937 145.086L289.611 144.321V142.789L290.937 142.024Z" />
            <path d="M301.656 144.703L302.982 145.469V147L301.656 147.766L300.33 147V145.469L301.656 144.703Z" />
            <path d="M294 142.024L295.326 142.789V144.321L294 145.086L292.673 144.321V142.789L294 142.024Z" />
            <path d="M300.125 142.024L301.451 142.789V144.321L300.125 145.086L298.799 144.321V142.789L300.125 142.024Z" />
            <path d="M297.062 142.024L298.388 142.789V144.321L297.062 145.086L295.736 144.321V142.789L297.062 142.024Z" />
            <path d="M300.125 147.383L301.451 148.149V149.68L300.125 150.445L298.799 149.68V148.149L300.125 147.383Z" />
            <path d="M281.75 147.383L283.076 148.149V149.68L281.75 150.445L280.424 149.68V148.149L281.75 147.383Z" />
            <path d="M301.656 139.344L302.982 140.11V141.641L301.656 142.406L300.33 141.641V140.11L301.656 139.344Z" />
            <path d="M307.781 139.344L309.107 140.11V141.641L307.781 142.406L306.455 141.641V140.11L307.781 139.344Z" />
            <path d="M323.094 128.625L324.42 129.391V130.922L323.094 131.688L321.768 130.922V129.391L323.094 128.625Z" />
            <path d="M304.719 144.703L306.045 145.469V147L304.719 147.766L303.392 147V145.469L304.719 144.703Z" />
            <path d="M289.406 144.703L290.732 145.469V147L289.406 147.766L288.08 147V145.469L289.406 144.703Z" />
            <path d="M303.187 142.024L304.513 142.789V144.321L303.187 145.086L301.861 144.321V142.789L303.187 142.024Z" />
            <path d="M298.594 155.421L299.92 156.187V157.718L298.594 158.484L297.267 157.718V156.187L298.594 155.421Z" />
            <path d="M280.219 155.421L281.545 156.187V157.718L280.219 158.484L278.892 157.718V156.187L280.219 155.421Z" />
            <path d="M301.656 160.781L302.982 161.547V163.078L301.656 163.844L300.33 163.078V161.547L301.656 160.781Z" />
            <path d="M283.281 160.781L284.608 161.547V163.078L283.281 163.844L281.955 163.078V161.547L283.281 160.781Z" />
            <path d="M307.781 144.703L309.107 145.469V147L307.781 147.766L306.455 147V145.469L307.781 144.703Z" />
            <path d="M292.468 144.703L293.795 145.469V147L292.468 147.766L291.142 147V145.469L292.468 144.703Z" />
            <path d="M301.656 155.421L302.982 156.187V157.718L301.656 158.484L300.33 157.718V156.187L301.656 155.421Z" />
            <path d="M283.281 155.421L284.608 156.187V157.718L283.281 158.484L281.955 157.718V156.187L283.281 155.421Z" />
            <path d="M303.187 147.383L304.513 148.149V149.68L303.187 150.445L301.861 149.68V148.149L303.187 147.383Z" />
            <path d="M284.813 147.383L286.139 148.149V149.68L284.813 150.445L283.487 149.68V148.149L284.813 147.383Z" />
            <path d="M298.594 150.062L299.92 150.828V152.359L298.594 153.125L297.267 152.359V150.828L298.594 150.062Z" />
            <path d="M280.219 150.062L281.545 150.828V152.359L280.219 153.125L278.892 152.359V150.828L280.219 150.062Z" />
            <path d="M300.125 152.742L301.451 153.508V155.039L300.125 155.805L298.799 155.039V153.508L300.125 152.742Z" />
            <path d="M281.75 152.742L283.076 153.508V155.039L281.75 155.805L280.424 155.039V153.508L281.75 152.742Z" />
            <path d="M332.281 150.062L333.607 150.828V152.359L332.281 153.125L330.955 152.359V150.828L332.281 150.062Z" />
            <path d="M301.656 150.062L302.982 150.828V152.359L301.656 153.125L300.33 152.359V150.828L301.656 150.062Z" />
            <path d="M283.281 150.062L284.608 150.828V152.359L283.281 153.125L281.955 152.359V150.828L283.281 150.062Z" />
            <path d="M310.844 139.344L312.17 140.11V141.641L310.844 142.406L309.518 141.641V140.11L310.844 139.344Z" />
            <path d="M303.187 136.664L304.513 137.43V138.961L303.187 139.727L301.861 138.961V137.43L303.187 136.664Z" />
            <path d="M300.125 163.461L301.451 164.226V165.758L300.125 166.523L298.799 165.758V164.226L300.125 163.461Z" />
            <path d="M333.813 174.179L335.139 174.945V176.476L333.813 177.242L332.487 176.476V174.945L333.813 174.179Z" />
            <path d="M300.125 158.102L301.451 158.867V160.398L300.125 161.164L298.799 160.398V158.867L300.125 158.102Z" />
            <path d="M281.75 158.102L283.076 158.867V160.398L281.75 161.164L280.424 160.398V158.867L281.75 158.102Z" />
            <path d="M309.312 136.664L310.638 137.43V138.961L309.312 139.727L307.986 138.961V137.43L309.312 136.664Z" />
            <path d="M306.25 163.461L307.576 164.226V165.758L306.25 166.523L304.924 165.758V164.226L306.25 163.461Z" />
            <path d="M339.938 174.179L341.264 174.945V176.476L339.938 177.242L338.612 176.476V174.945L339.938 174.179Z" />
            <path d="M309.312 131.305L310.638 132.071V133.602L309.312 134.368L307.986 133.602V132.071L309.312 131.305Z" />
            <path d="M306.25 158.102L307.576 158.867V160.398L306.25 161.164L304.924 160.398V158.867L306.25 158.102Z" />
            <path d="M339.938 168.821L341.264 169.586V171.118L339.938 171.883L338.612 171.118V169.586L339.938 168.821Z" />
            <path d="M287.875 158.102L289.201 158.867V160.398L287.875 161.164L286.549 160.398V158.867L287.875 158.102Z" />
            <path d="M309.312 125.945L310.638 126.711V128.242L309.312 129.008L307.986 128.242V126.711L309.312 125.945Z" />
            <path d="M306.25 152.742L307.576 153.508V155.039L306.25 155.805L304.924 155.039V153.508L306.25 152.742Z" />
            <path d="M287.875 152.742L289.201 153.508V155.039L287.875 155.805L286.549 155.039V153.508L287.875 152.742Z" />
            <path d="M312.375 163.461L313.701 164.226V165.758L312.375 166.523L311.049 165.758V164.226L312.375 163.461Z" />
            <path d="M315.437 131.305L316.764 132.071V133.602L315.437 134.368L314.111 133.602V132.071L315.437 131.305Z" />
            <path d="M312.375 158.102L313.701 158.867V160.398L312.375 161.164L311.049 160.398V158.867L312.375 158.102Z" />
            <path d="M294 158.102L295.326 158.867V160.398L294 161.164L292.673 160.398V158.867L294 158.102Z" />
            <path d="M312.375 152.742L313.701 153.508V155.039L312.375 155.805L311.049 155.039V153.508L312.375 152.742Z" />
            <path d="M294 152.742L295.326 153.508V155.039L294 155.805L292.673 155.039V153.508L294 152.742Z" />
            <path d="M300.125 136.664L301.451 137.43V138.961L300.125 139.727L298.799 138.961V137.43L300.125 136.664Z" />
            <path d="M303.187 163.461L304.513 164.226V165.758L303.187 166.523L301.861 165.758V164.226L303.187 163.461Z" />
            <path d="M336.875 174.179L338.201 174.945V176.476L336.875 177.242L335.549 176.476V174.945L336.875 174.179Z" />
            <path d="M303.187 158.102L304.513 158.867V160.398L303.187 161.164L301.861 160.398V158.867L303.187 158.102Z" />
            <path d="M284.813 158.102L286.139 158.867V160.398L284.813 161.164L283.487 160.398V158.867L284.813 158.102Z" />
            <path d="M303.187 152.742L304.513 153.508V155.039L303.187 155.805L301.861 155.039V153.508L303.187 152.742Z" />
            <path d="M284.813 152.742L286.139 153.508V155.039L284.813 155.805L283.487 155.039V153.508L284.813 152.742Z" />
            <path d="M309.312 163.461L310.638 164.226V165.758L309.312 166.523L307.986 165.758V164.226L309.312 163.461Z" />
            <path d="M312.375 131.305L313.701 132.071V133.602L312.375 134.368L311.049 133.602V132.071L312.375 131.305Z" />
            <path d="M309.312 158.102L310.638 158.867V160.398L309.312 161.164L307.986 160.398V158.867L309.312 158.102Z" />
            <path d="M290.937 158.102L292.264 158.867V160.398L290.937 161.164L289.611 160.398V158.867L290.937 158.102Z" />
            <path d="M309.312 152.742L310.638 153.508V155.039L309.312 155.805L307.986 155.039V153.508L309.312 152.742Z" />
            <path d="M290.937 152.742L292.264 153.508V155.039L290.937 155.805L289.611 155.039V153.508L290.937 152.742Z" />
            <path d="M315.437 163.461L316.764 164.226V165.758L315.437 166.523L314.111 165.758V164.226L315.437 163.461Z" />
            <path d="M335.344 176.859L336.67 177.625V179.156L335.344 179.922L334.018 179.156V177.625L335.344 176.859Z" />
            <path d="M332.281 176.859L333.607 177.625V179.156L332.281 179.922L330.955 179.156V177.625L332.281 176.859Z" />
            <path d="M330.75 179.54L332.076 180.306V181.837L330.75 182.602L329.424 181.837V180.306L330.75 179.54Z" />
            <path d="M327.688 179.54L329.014 180.306V181.837L327.688 182.602L326.362 181.837V180.306L327.688 179.54Z" />
            <path d="M329.219 182.219L330.545 182.985V184.516L329.219 185.282L327.893 184.516V182.985L329.219 182.219Z" />
            <path d="M315.437 158.102L316.764 158.867V160.398L315.437 161.164L314.111 160.398V158.867L315.437 158.102Z" />
            <path d="M298.594 139.344L299.92 140.11V141.641L298.594 142.406L297.267 141.641V140.11L298.594 139.344Z" />
            <path d="M316.969 160.781L318.295 161.547V163.078L316.969 163.844L315.643 163.078V161.547L316.969 160.781Z" />
            <path d="M316.969 155.421L318.295 156.187V157.718L316.969 158.484L315.643 157.718V156.187L316.969 155.421Z" />
            <path d="M315.437 152.742L316.764 153.508V155.039L315.437 155.805L314.111 155.039V153.508L315.437 152.742Z" />
            <path d="M298.594 160.781L299.92 161.547V163.078L298.594 163.844L297.267 163.078V161.547L298.594 160.781Z" />
            <path d="M280.219 160.781L281.545 161.547V163.078L280.219 163.844L278.892 163.078V161.547L280.219 160.781Z" />
            <path d="M304.719 160.781L306.045 161.547V163.078L304.719 163.844L303.392 163.078V161.547L304.719 160.781Z" />
            <path d="M338.406 171.5L339.732 172.266V173.797L338.406 174.563L337.08 173.797V172.266L338.406 171.5Z" />
            <path d="M286.344 160.781L287.67 161.547V163.078L286.344 163.844L285.017 163.078V161.547L286.344 160.781Z" />
            <path d="M310.844 144.703L312.17 145.469V147L310.844 147.766L309.518 147V145.469L310.844 144.703Z" />
            <path d="M295.531 144.703L296.857 145.469V147L295.531 147.766L294.205 147V145.469L295.531 144.703Z" />
            <path d="M309.312 142.024L310.638 142.789V144.321L309.312 145.086L307.986 144.321V142.789L309.312 142.024Z" />
            <path d="M304.719 155.421L306.045 156.187V157.718L304.719 158.484L303.392 157.718V156.187L304.719 155.421Z" />
            <path d="M338.406 166.141L339.732 166.907V168.438L338.406 169.203L337.08 168.438V166.907L338.406 166.141Z" />
            <path d="M286.344 155.421L287.67 156.187V157.718L286.344 158.484L285.017 157.718V156.187L286.344 155.421Z" />
            <path d="M304.719 150.062L306.045 150.828V152.359L304.719 153.125L303.392 152.359V150.828L304.719 150.062Z" />
            <path d="M286.344 150.062L287.67 150.828V152.359L286.344 153.125L285.017 152.359V150.828L286.344 150.062Z" />
            <path d="M313.906 133.985L315.232 134.75V136.282L313.906 137.047L312.58 136.282V134.75L313.906 133.985Z" />
            <path d="M310.844 160.781L312.17 161.547V163.078L310.844 163.844L309.518 163.078V161.547L310.844 160.781Z" />
            <path d="M292.468 160.781L293.795 161.547V163.078L292.468 163.844L291.142 163.078V161.547L292.468 160.781Z" />
            <path d="M313.906 128.625L315.232 129.391V130.922L313.906 131.688L312.58 130.922V129.391L313.906 128.625Z" />
            <path d="M301.656 166.141L302.982 166.907V168.438L301.656 169.203L300.33 168.438V166.907L301.656 166.141Z" />
            <path d="M310.844 155.421L312.17 156.187V157.718L310.844 158.484L309.518 157.718V156.187L310.844 155.421Z" />
            <path d="M292.468 155.421L293.795 156.187V157.718L292.468 158.484L291.142 157.718V156.187L292.468 155.421Z" />
            <path d="M310.844 150.062L312.17 150.828V152.359L310.844 153.125L309.518 152.359V150.828L310.844 150.062Z" />
            <path d="M292.468 150.062L293.795 150.828V152.359L292.468 153.125L291.142 152.359V150.828L292.468 150.062Z" />
            <path d="M309.312 147.383L310.638 148.149V149.68L309.312 150.445L307.986 149.68V148.149L309.312 147.383Z" />
            <path d="M290.937 147.383L292.264 148.149V149.68L290.937 150.445L289.611 149.68V148.149L290.937 147.383Z" />
            <path d="M307.781 160.781L309.107 161.547V163.078L307.781 163.844L306.455 163.078V161.547L307.781 160.781Z" />
            <path d="M341.469 171.5L342.795 172.266V173.797L341.469 174.563L340.143 173.797V172.266L341.469 171.5Z" />
            <path d="M289.406 160.781L290.732 161.547V163.078L289.406 163.844L288.08 163.078V161.547L289.406 160.781Z" />
            <path d="M310.844 128.625L312.17 129.391V130.922L310.844 131.688L309.518 130.922V129.391L310.844 128.625Z" />
            <path d="M312.375 142.024L313.701 142.789V144.321L312.375 145.086L311.049 144.321V142.789L312.375 142.024Z" />
            <path d="M303.187 168.821L304.513 169.586V171.118L303.187 171.883L301.861 171.118V169.586L303.187 168.821Z" />
            <path d="M306.25 168.821L307.576 169.586V171.118L306.25 171.883L304.924 171.118V169.586L306.25 168.821Z" />
            <path d="M307.781 176.859L309.107 177.625V179.156L307.781 179.922L306.455 179.156V177.625L307.781 176.859Z" />
            <path d="M309.312 168.821L310.638 169.586V171.118L309.312 171.883L307.986 171.118V169.586L309.312 168.821Z" />
            <path d="M312.375 168.821L313.701 169.586V171.118L312.375 171.883L311.049 171.118V169.586L312.375 168.821Z" />
            <path d="M307.781 155.421L309.107 156.187V157.718L307.781 158.484L306.455 157.718V156.187L307.781 155.421Z" />
            <path d="M289.406 155.421L290.732 156.187V157.718L289.406 158.484L288.08 157.718V156.187L289.406 155.421Z" />
            <path d="M307.781 150.062L309.107 150.828V152.359L307.781 153.125L306.455 152.359V150.828L307.781 150.062Z" />
            <path d="M289.406 150.062L290.732 150.828V152.359L289.406 153.125L288.08 152.359V150.828L289.406 150.062Z" />
            <path d="M306.25 147.383L307.576 148.149V149.68L306.25 150.445L304.924 149.68V148.149L306.25 147.383Z" />
            <path d="M287.875 147.383L289.201 148.149V149.68L287.875 150.445L286.549 149.68V148.149L287.875 147.383Z" />
            <path d="M316.969 133.985L318.295 134.75V136.282L316.969 137.047L315.643 136.282V134.75L316.969 133.985Z" />
            <path d="M313.906 160.781L315.232 161.547V163.078L313.906 163.844L312.58 163.078V161.547L313.906 160.781Z" />
            <path d="M295.531 160.781L296.857 161.547V163.078L295.531 163.844L294.205 163.078V161.547L295.531 160.781Z" />
            <path d="M287.875 163.461L289.201 164.226V165.758L287.875 166.523L286.549 165.758V164.226L287.875 163.461Z" />
            <path d="M284.813 163.461L286.139 164.226V165.758L284.813 166.523L283.487 165.758V164.226L284.813 163.461Z" />
            <path d="M281.75 163.461L283.076 164.226V165.758L281.75 166.523L280.424 165.758V164.226L281.75 163.461Z" />
            <path d="M280.219 166.141L281.545 166.907V168.438L280.219 169.203L278.892 168.438V166.907L280.219 166.141Z" />
            <path d="M278.688 163.461L280.014 164.226V165.758L278.688 166.523L277.361 165.758V164.226L278.688 163.461Z" />
            <path d="M278.688 158.102L280.014 158.867V160.398L278.688 161.164L277.361 160.398V158.867L278.688 158.102Z" />
            <path d="M278.688 152.742L280.014 153.508V155.039L278.688 155.805L277.361 155.039V153.508L278.688 152.742Z" />
            <path d="M277.156 155.421L278.483 156.187V157.718L277.156 158.484L275.83 157.718V156.187L277.156 155.421Z" />
            <path d="M316.969 128.625L318.295 129.391V130.922L316.969 131.688L315.643 130.922V129.391L316.969 128.625Z" />
            <path d="M304.719 166.141L306.045 166.907V168.438L304.719 169.203L303.392 168.438V166.907L304.719 166.141Z" />
            <path d="M307.781 166.141L309.107 166.907V168.438L307.781 169.203L306.455 168.438V166.907L307.781 166.141Z" />
            <path d="M309.312 174.179L310.638 174.945V176.476L309.312 177.242L307.986 176.476V174.945L309.312 174.179Z" />
            <path d="M313.906 155.421L315.232 156.187V157.718L313.906 158.484L312.58 157.718V156.187L313.906 155.421Z" />
            <path d="M295.531 155.421L296.857 156.187V157.718L295.531 158.484L294.205 157.718V156.187L295.531 155.421Z" />
            <path d="M297.062 158.102L298.388 158.867V160.398L297.062 161.164L295.736 160.398V158.867L297.062 158.102Z" />
            <path d="M313.906 150.062L315.232 150.828V152.359L313.906 153.125L312.58 152.359V150.828L313.906 150.062Z" />
            <path d="M295.531 150.062L296.857 150.828V152.359L295.531 153.125L294.205 152.359V150.828L295.531 150.062Z" />
            <path d="M297.062 152.742L298.388 153.508V155.039L297.062 155.805L295.736 155.039V153.508L297.062 152.742Z" />
            <path d="M297.062 147.383L298.388 148.149V149.68L297.062 150.445L295.736 149.68V148.149L297.062 147.383Z" />
            <path d="M312.375 147.383L313.701 148.149V149.68L312.375 150.445L311.049 149.68V148.149L312.375 147.383Z" />
            <path d="M294 147.383L295.326 148.149V149.68L294 150.445L292.673 149.68V148.149L294 147.383Z" />
          </g>
          <g
            id="Asia"
            fill={highlight.includes('Asia') ? highlightColor : defaultColor}
            onClick={() => {
              onClick('Asia');
            }}
          >
            <path d="M217.437 61.6324L218.763 62.3981V63.9293L217.437 64.6949L216.111 63.9293V62.3981L217.437 61.6324Z" />
            <path d="M266.437 61.6324L267.764 62.3981V63.9293L266.437 64.6949L265.111 63.9293V62.3981L266.437 61.6324Z" />
            <path d="M267.969 101.828L269.295 102.594V104.125L267.969 104.891L266.643 104.125V102.594L267.969 101.828Z" />
            <path d="M271.031 128.625L272.358 129.391V130.922L271.031 131.688L269.705 130.922V129.391L271.031 128.625Z" />
            <path d="M272.562 125.945L273.888 126.711V128.242L272.562 129.008L271.236 128.242V126.711L272.562 125.945Z" />
            <path d="M240.406 64.3124L241.732 65.078V66.6093L240.406 67.3749L239.08 66.6093V65.078L240.406 64.3124Z" />
            <path d="M241.937 104.507L243.263 105.273V106.804L241.937 107.57L240.611 106.804V105.273L241.937 104.507Z" />
            <path d="M220.5 66.9913L221.826 67.757V69.2882L220.5 70.0538L219.174 69.2882V67.757L220.5 66.9913Z" />
            <path d="M218.969 69.672L220.295 70.4376V71.9689L218.969 72.7345L217.643 71.9689V70.4376L218.969 69.672Z" />
            <path d="M220.5 72.351L221.826 73.1166V74.6478L220.5 75.4135L219.174 74.6478V73.1166L220.5 72.351Z" />
            <path d="M218.969 75.0316L220.295 75.7972V77.3285L218.969 78.0941L217.643 77.3285V75.7972L218.969 75.0316Z" />
            <path d="M225.094 64.3124L226.42 65.078V66.6093L225.094 67.3749L223.768 66.6093V65.078L225.094 64.3124Z" />
            <path d="M226.625 66.9913L227.951 67.757V69.2882L226.625 70.0538L225.299 69.2882V67.757L226.625 66.9913Z" />
            <path d="M229.687 66.9913L231.013 67.757V69.2882L229.687 70.0538L228.361 69.2882V67.757L229.687 66.9913Z" />
            <path d="M225.094 69.672L226.42 70.4376V71.9689L225.094 72.7345L223.768 71.9689V70.4376L225.094 69.672Z" />
            <path d="M228.156 69.672L229.482 70.4376V71.9689L228.156 72.7345L226.83 71.9689V70.4376L228.156 69.672Z" />
            <path d="M226.625 72.351L227.951 73.1166V74.6478L226.625 75.4135L225.299 74.6478V73.1166L226.625 72.351Z" />
            <path d="M229.687 72.351L231.013 73.1166V74.6478L229.687 75.4135L228.361 74.6478V73.1166L229.687 72.351Z" />
            <path d="M225.094 75.0316L226.42 75.7972V77.3285L225.094 78.0941L223.768 77.3285V75.7972L225.094 75.0316Z" />
            <path d="M228.156 75.0316L229.482 75.7972V77.3285L228.156 78.0941L226.83 77.3285V75.7972L228.156 75.0316Z" />
            <path d="M222.031 69.672L223.357 70.4376V71.9689L222.031 72.7345L220.705 71.9689V70.4376L222.031 69.672Z" />
            <path d="M223.562 72.351L224.888 73.1166V74.6478L223.562 75.4135L222.236 74.6478V73.1166L223.562 72.351Z" />
            <path d="M222.031 75.0316L223.357 75.7972V77.3285L222.031 78.0941L220.705 77.3285V75.7972L222.031 75.0316Z" />
            <path d="M225.094 58.9521L226.42 59.7178V61.249L225.094 62.0146L223.768 61.249V59.7178L225.094 58.9521Z" />
            <path d="M274.094 58.9521L275.42 59.7178V61.249L274.094 62.0146L272.767 61.249V59.7178L274.094 58.9521Z" />
            <path d="M275.625 99.1482L276.951 99.9138V101.445L275.625 102.211L274.299 101.445V99.9138L275.625 99.1482Z" />
            <path d="M248.062 61.6324L249.388 62.3981V63.9293L248.062 64.6949L246.736 63.9293V62.3981L248.062 61.6324Z" />
            <path d="M297.062 61.6324L298.388 62.3981V63.9293L297.062 64.6949L295.736 63.9293V62.3981L297.062 61.6324Z" />
            <path d="M298.594 58.9521L299.92 59.7178V61.249L298.594 62.0146L297.267 61.249V59.7178L298.594 58.9521Z" />
            <path d="M295.531 64.3124L296.857 65.078V66.6093L295.531 67.3749L294.205 66.6093V65.078L295.531 64.3124Z" />
            <path d="M292.468 64.3124L293.795 65.078V66.6093L292.468 67.3749L291.142 66.6093V65.078L292.468 64.3124Z" />
            <path d="M289.406 64.3124L290.732 65.078V66.6093L289.406 67.3749L288.08 66.6093V65.078L289.406 64.3124Z" />
            <path d="M300.125 56.2731L301.451 57.0387V58.5699L300.125 59.3356L298.799 58.5699V57.0387L300.125 56.2731Z" />
            <path d="M301.656 32.1564L302.982 32.922V34.4532L301.656 35.2189L300.33 34.4532V32.922L301.656 32.1564Z" />
            <path d="M304.719 32.1564L306.045 32.922V34.4532L304.719 35.2189L303.392 34.4532V32.922L304.719 32.1564Z" />
            <path d="M306.25 34.8361L307.576 35.6017V37.1329L306.25 37.8986L304.924 37.1329V35.6017L306.25 34.8361Z" />
            <path d="M301.656 58.9521L302.982 59.7178V61.249L301.656 62.0146L300.33 61.249V59.7178L301.656 58.9521Z" />
            <path d="M301.656 53.5938L302.982 54.3594V55.8906L301.656 56.6562L300.33 55.8906V54.3594L301.656 53.5938Z" />
            <path d="M303.187 29.4764L304.513 30.2421V31.7733L303.187 32.5389L301.861 31.7733V30.2421L303.187 29.4764Z" />
            <path d="M225.094 53.5938L226.42 54.3594V55.8906L225.094 56.6562L223.768 55.8906V54.3594L225.094 53.5938Z" />
            <path d="M274.094 53.5938L275.42 54.3594V55.8906L274.094 56.6562L272.767 55.8906V54.3594L274.094 53.5938Z" />
            <path d="M275.625 93.7893L276.951 94.5549V96.0862L275.625 96.8518L274.299 96.0862V94.5549L275.625 93.7893Z" />
            <path d="M275.625 29.4764L276.951 30.2421V31.7733L275.625 32.5389L274.299 31.7733V30.2421L275.625 29.4764Z" />
            <path d="M315.437 29.4764L316.764 30.2421V31.7733L315.437 32.5389L314.111 31.7733V30.2421L315.437 29.4764Z" />
            <path d="M277.156 69.672L278.483 70.4376V71.9689L277.156 72.7345L275.83 71.9689V70.4376L277.156 69.672Z" />
            <path d="M248.062 56.2731L249.388 57.0387V58.5699L248.062 59.3356L246.736 58.5699V57.0387L248.062 56.2731Z" />
            <path d="M249.593 96.4688L250.919 97.2344V98.7656L249.593 99.5312L248.267 98.7656V97.2344L249.593 96.4688Z" />
            <path d="M249.594 32.1564L250.92 32.922V34.4532L249.594 35.2189L248.268 34.4532V32.922L249.594 32.1564Z" />
            <path d="M251.125 72.351L252.451 73.1166V74.6478L251.125 75.4135L249.799 74.6478V73.1166L251.125 72.351Z" />
            <path d="M297.062 56.2731L298.388 57.0387V58.5699L297.062 59.3356L295.736 58.5699V57.0387L297.062 56.2731Z" />
            <path d="M298.594 32.1564L299.92 32.922V34.4532L298.594 35.2189L297.267 34.4532V32.922L298.594 32.1564Z" />
            <path d="M225.094 48.2341L226.42 48.9998V50.531L225.094 51.2966L223.768 50.531V48.9998L225.094 48.2341Z" />
            <path d="M274.094 48.2341L275.42 48.9998V50.531L274.094 51.2966L272.767 50.531V48.9998L274.094 48.2341Z" />
            <path d="M275.625 88.4297L276.951 89.1953V90.7266L275.625 91.4922L274.299 90.7266V89.1953L275.625 88.4297Z" />
            <path d="M275.625 24.1174L276.951 24.8831V26.4143L275.625 27.1799L274.299 26.4143V24.8831L275.625 24.1174Z" />
            <path d="M248.062 50.9142L249.388 51.6798V53.2111L248.062 53.9767L246.736 53.2111V51.6798L248.062 50.9142Z" />
            <path d="M249.593 91.1093L250.919 91.8749V93.4061L249.593 94.1718L248.267 93.4061V91.8749L249.593 91.1093Z" />
            <path d="M249.594 26.7974L250.92 27.563V29.0942L249.594 29.8599L248.268 29.0942V27.563L249.594 26.7974Z" />
            <path d="M251.125 66.9913L252.451 67.757V69.2882L251.125 70.0538L249.799 69.2882V67.757L251.125 66.9913Z" />
            <path d="M249.594 64.3124L250.92 65.078V66.6093L249.594 67.3749L248.268 66.6093V65.078L249.594 64.3124Z" />
            <path d="M248.062 29.4764L249.388 30.2421V31.7733L248.062 32.5389L246.736 31.7733V30.2421L248.062 29.4764Z" />
            <path d="M249.594 69.672L250.92 70.4376V71.9689L249.594 72.7345L248.268 71.9689V70.4376L249.594 69.672Z" />
            <path d="M249.594 58.9521L250.92 59.7178V61.249L249.594 62.0146L248.268 61.249V59.7178L249.594 58.9521Z" />
            <path d="M214.375 66.9913L215.701 67.757V69.2882L214.375 70.0538L213.049 69.2882V67.757L214.375 66.9913Z" />
            <path d="M212.844 69.672L214.17 70.4376V71.9689L212.844 72.7345L211.518 71.9689V70.4376L212.844 69.672Z" />
            <path d="M211.313 66.9913L212.639 67.757V69.2882L211.313 70.0538L209.987 69.2882V67.757L211.313 66.9913Z" />
            <path d="M215.906 69.672L217.232 70.4376V71.9689L215.906 72.7345L214.58 71.9689V70.4376L215.906 69.672Z" />
            <path d="M217.437 72.351L218.763 73.1166V74.6478L217.437 75.4135L216.111 74.6478V73.1166L217.437 72.351Z" />
            <path d="M215.906 75.0316L217.232 75.7972V77.3285L215.906 78.0941L214.58 77.3285V75.7972L215.906 75.0316Z" />
            <path d="M217.437 66.9913L218.763 67.757V69.2882L217.437 70.0538L216.111 69.2882V67.757L217.437 66.9913Z" />
            <path d="M249.594 48.2341L250.92 48.9998V50.531L249.594 51.2966L248.268 50.531V48.9998L249.594 48.2341Z" />
            <path d="M251.125 88.4297L252.451 89.1953V90.7266L251.125 91.4922L249.799 90.7266V89.1953L251.125 88.4297Z" />
            <path d="M251.125 24.1174L252.451 24.8831V26.4143L251.125 27.1799L249.799 26.4143V24.8831L251.125 24.1174Z" />
            <path d="M245 24.1174L246.326 24.8831V26.4143L245 27.1799L243.674 26.4143V24.8831L245 24.1174Z" />
            <path d="M249.594 53.5938L250.92 54.3594V55.8906L249.594 56.6562L248.268 55.8906V54.3594L249.594 53.5938Z" />
            <path d="M251.125 93.7893L252.451 94.5549V96.0862L251.125 96.8518L249.799 96.0862V94.5549L251.125 93.7893Z" />
            <path d="M251.125 29.4764L252.451 30.2421V31.7733L251.125 32.5389L249.799 31.7733V30.2421L251.125 29.4764Z" />
            <path d="M252.656 69.672L253.982 70.4376V71.9689L252.656 72.7345L251.33 71.9689V70.4376L252.656 69.672Z" />
            <path d="M251.125 50.9142L252.451 51.6798V53.2111L251.125 53.9767L249.799 53.2111V51.6798L251.125 50.9142Z" />
            <path d="M252.656 91.1093L253.982 91.8749V93.4061L252.656 94.1718L251.33 93.4061V91.8749L252.656 91.1093Z" />
            <path d="M252.656 26.7974L253.982 27.563V29.0942L252.656 29.8599L251.33 29.0942V27.563L252.656 26.7974Z" />
            <path d="M254.187 66.9913L255.513 67.757V69.2882L254.187 70.0538L252.861 69.2882V67.757L254.187 66.9913Z" />
            <path d="M252.656 64.3124L253.982 65.078V66.6093L252.656 67.3749L251.33 66.6093V65.078L252.656 64.3124Z" />
            <path d="M252.656 37.5156L253.982 38.2812V39.8125L252.656 40.5781L251.33 39.8125V38.2812L252.656 37.5156Z" />
            <path d="M254.187 77.7106L255.513 78.4762V80.0074L254.187 80.7731L252.861 80.0074V78.4762L254.187 77.7106Z" />
            <path d="M254.187 13.3982L255.513 14.1638V15.6951L254.187 16.4607L252.861 15.6951V14.1638L254.187 13.3982Z" />
            <path d="M226.625 40.1957L227.951 40.9613V42.4926L226.625 43.2582L225.299 42.4926V40.9613L226.625 40.1957Z" />
            <path d="M275.625 40.1957L276.951 40.9613V42.4926L275.625 43.2582L274.299 42.4926V40.9613L275.625 40.1957Z" />
            <path d="M315.437 40.1957L316.764 40.9613V42.4926L315.437 43.2582L314.111 42.4926V40.9613L315.437 40.1957Z" />
            <path d="M277.156 80.3909L278.483 81.1565V82.6877L277.156 83.4534L275.83 82.6877V81.1565L277.156 80.3909Z" />
            <path d="M254.187 40.1957L255.513 40.9613V42.4926L254.187 43.2582L252.861 42.4926V40.9613L254.187 40.1957Z" />
            <path d="M255.719 80.3909L257.045 81.1565V82.6877L255.719 83.4534L254.393 82.6877V81.1565L255.719 80.3909Z" />
            <path d="M255.719 16.0773L257.045 16.8429V18.3741L255.719 19.1398L254.393 18.3741V16.8429L255.719 16.0773Z" />
            <path d="M228.156 42.8746L229.482 43.6403V45.1715L228.156 45.9371L226.83 45.1715V43.6403L228.156 42.8746Z" />
            <path d="M277.156 42.8746L278.483 43.6403V45.1715L277.156 45.9371L275.83 45.1715V43.6403L277.156 42.8746Z" />
            <path d="M316.969 42.8746L318.295 43.6403V45.1715L316.969 45.9371L315.643 45.1715V43.6403L316.969 42.8746Z" />
            <path d="M278.688 83.0702L280.014 83.8358V85.3671L278.688 86.1327L277.361 85.3671V83.8358L278.688 83.0702Z" />
            <path d="M226.625 34.8361L227.951 35.6017V37.1329L226.625 37.8986L225.299 37.1329V35.6017L226.625 34.8361Z" />
            <path d="M275.625 34.8361L276.951 35.6017V37.1329L275.625 37.8986L274.299 37.1329V35.6017L275.625 34.8361Z" />
            <path d="M315.437 34.8361L316.764 35.6017V37.1329L315.437 37.8986L314.111 37.1329V35.6017L315.437 34.8361Z" />
            <path d="M277.156 75.0316L278.483 75.7972V77.3285L277.156 78.0941L275.83 77.3285V75.7972L277.156 75.0316Z" />
            <path d="M255.719 42.8746L257.045 43.6403V45.1715L255.719 45.9371L254.393 45.1715V43.6403L255.719 42.8746Z" />
            <path d="M257.25 83.0702L258.576 83.8358V85.3671L257.25 86.1327L255.924 85.3671V83.8358L257.25 83.0702Z" />
            <path d="M257.25 18.7571L258.576 19.5227V21.054L257.25 21.8196L255.924 21.054V19.5227L257.25 18.7571Z" />
            <path d="M229.687 45.554L231.013 46.3196V47.8508L229.687 48.6165L228.361 47.8508V46.3196L229.687 45.554Z" />
            <path d="M231.218 85.7496L232.544 86.5153V88.0465L231.218 88.8121L229.892 88.0465V86.5153L231.218 85.7496Z" />
            <path d="M278.688 45.554L280.014 46.3196V47.8508L278.688 48.6165L277.361 47.8508V46.3196L278.688 45.554Z" />
            <path d="M280.219 85.7496L281.545 86.5153V88.0465L280.219 88.8121L278.892 88.0465V86.5153L280.219 85.7496Z" />
            <path d="M228.156 37.5156L229.482 38.2812V39.8125L228.156 40.5781L226.83 39.8125V38.2812L228.156 37.5156Z" />
            <path d="M277.156 37.5156L278.483 38.2812V39.8125L277.156 40.5781L275.83 39.8125V38.2812L277.156 37.5156Z" />
            <path d="M316.969 37.5156L318.295 38.2812V39.8125L316.969 40.5781L315.643 39.8125V38.2812L316.969 37.5156Z" />
            <path d="M278.688 77.7106L280.014 78.4762V80.0074L278.688 80.7731L277.361 80.0074V78.4762L278.688 77.7106Z" />
            <path d="M254.187 61.6324L255.513 62.3981V63.9293L254.187 64.6949L252.861 63.9293V62.3981L254.187 61.6324Z" />
            <path d="M228.156 64.3124L229.482 65.078V66.6093L228.156 67.3749L226.83 66.6093V65.078L228.156 64.3124Z" />
            <path d="M211.313 61.6324L212.639 62.3981V63.9293L211.313 64.6949L209.987 63.9293V62.3981L211.313 61.6324Z" />
            <path d="M260.312 61.6324L261.639 62.3981V63.9293L260.312 64.6949L258.986 63.9293V62.3981L260.312 61.6324Z" />
            <path d="M261.844 101.828L263.17 102.594V104.125L261.844 104.891L260.518 104.125V102.594L261.844 101.828Z" />
            <path d="M234.281 64.3124L235.607 65.078V66.6093L234.281 67.3749L232.955 66.6093V65.078L234.281 64.3124Z" />
            <path d="M249.594 42.8746L250.92 43.6403V45.1715L249.594 45.9371L248.268 45.1715V43.6403L249.594 42.8746Z" />
            <path d="M251.125 83.0702L252.451 83.8358V85.3671L251.125 86.1327L249.799 85.3671V83.8358L251.125 83.0702Z" />
            <path d="M251.125 18.7571L252.451 19.5227V21.054L251.125 21.8196L249.799 21.054V19.5227L251.125 18.7571Z" />
            <path d="M248.062 45.554L249.388 46.3196V47.8508L248.062 48.6165L246.736 47.8508V46.3196L248.062 45.554Z" />
            <path d="M249.593 85.7496L250.919 86.5153V88.0465L249.593 88.8121L248.267 88.0465V86.5153L249.593 85.7496Z" />
            <path d="M249.594 21.4375L250.92 22.2031V23.7344L249.594 24.5L248.268 23.7344V22.2031L249.594 21.4375Z" />
            <path d="M243.469 21.4375L244.795 22.2031V23.7344L243.469 24.5L242.143 23.7344V22.2031L243.469 21.4375Z" />
            <path d="M245 18.7571L246.326 19.5227V21.054L245 21.8196L243.674 21.054V19.5227L245 18.7571Z" />
            <path d="M246.531 16.0773L247.857 16.8429V18.3741L246.531 19.1398L245.205 18.3741V16.8429L246.531 16.0773Z" />
            <path d="M251.125 45.554L252.451 46.3196V47.8508L251.125 48.6165L249.799 47.8508V46.3196L251.125 45.554Z" />
            <path d="M252.656 85.7496L253.982 86.5153V88.0465L252.656 88.8121L251.33 88.0465V86.5153L252.656 85.7496Z" />
            <path d="M252.656 21.4375L253.982 22.2031V23.7344L252.656 24.5L251.33 23.7344V22.2031L252.656 21.4375Z" />
            <path d="M246.531 21.4375L247.857 22.2031V23.7344L246.531 24.5L245.205 23.7344V22.2031L246.531 21.4375Z" />
            <path d="M248.062 18.7571L249.388 19.5227V21.054L248.062 21.8196L246.736 21.054V19.5227L248.062 18.7571Z" />
            <path d="M251.125 34.8361L252.451 35.6017V37.1329L251.125 37.8986L249.799 37.1329V35.6017L251.125 34.8361Z" />
            <path d="M252.656 75.0316L253.982 75.7972V77.3285L252.656 78.0941L251.33 77.3285V75.7972L252.656 75.0316Z" />
            <path d="M241.937 8.03955L243.263 8.80518V10.3364L241.937 11.102L240.611 10.3364V8.80518L241.937 8.03955Z" />
            <path d="M252.656 48.2341L253.982 48.9998V50.531L252.656 51.2966L251.33 50.531V48.9998L252.656 48.2341Z" />
            <path d="M297.062 125.945L298.388 126.711V128.242L297.062 129.008L295.736 128.242V126.711L297.062 125.945Z" />
            <path d="M254.187 88.4297L255.513 89.1953V90.7266L254.187 91.4922L252.861 90.7266V89.1953L254.187 88.4297Z" />
            <path d="M263.375 104.507L264.701 105.273V106.804L263.375 107.57L262.049 106.804V105.273L263.375 104.507Z" />
            <path d="M254.187 24.1174L255.513 24.8831V26.4143L254.187 27.1799L252.861 26.4143V24.8831L254.187 24.1174Z" />
            <path d="M248.062 24.1174L249.388 24.8831V26.4143L248.062 27.1799L246.736 26.4143V24.8831L248.062 24.1174Z" />
            <path d="M226.625 50.9142L227.951 51.6798V53.2111L226.625 53.9767L225.299 53.2111V51.6798L226.625 50.9142Z" />
            <path d="M275.625 50.9142L276.951 51.6798V53.2111L275.625 53.9767L274.299 53.2111V51.6798L275.625 50.9142Z" />
            <path d="M277.156 91.1093L278.483 91.8749V93.4061L277.156 94.1718L275.83 93.4061V91.8749L277.156 91.1093Z" />
            <path d="M277.156 26.7974L278.483 27.563V29.0942L277.156 29.8599L275.83 29.0942V27.563L277.156 26.7974Z" />
            <path d="M316.969 26.7974L318.295 27.563V29.0942L316.969 29.8599L315.643 29.0942V27.563L316.969 26.7974Z" />
            <path d="M278.688 66.9913L280.014 67.757V69.2882L278.688 70.0538L277.361 69.2882V67.757L278.688 66.9913Z" />
            <path d="M252.656 53.5938L253.982 54.3594V55.8906L252.656 56.6562L251.33 55.8906V54.3594L252.656 53.5938Z" />
            <path d="M254.187 93.7893L255.513 94.5549V96.0862L254.187 96.8518L252.861 96.0862V94.5549L254.187 93.7893Z" />
            <path d="M263.375 109.867L264.701 110.633V112.164L263.375 112.93L262.049 112.164V110.633L263.375 109.867Z" />
            <path d="M254.187 29.4764L255.513 30.2421V31.7733L254.187 32.5389L252.861 31.7733V30.2421L254.187 29.4764Z" />
            <path d="M255.719 69.672L257.045 70.4376V71.9689L255.719 72.7345L254.393 71.9689V70.4376L255.719 69.672Z" />
            <path d="M226.625 56.2731L227.951 57.0387V58.5699L226.625 59.3356L225.299 58.5699V57.0387L226.625 56.2731Z" />
            <path d="M275.625 56.2731L276.951 57.0387V58.5699L275.625 59.3356L274.299 58.5699V57.0387L275.625 56.2731Z" />
            <path d="M277.156 32.1564L278.483 32.922V34.4532L277.156 35.2189L275.83 34.4532V32.922L277.156 32.1564Z" />
            <path d="M316.969 32.1564L318.295 32.922V34.4532L316.969 35.2189L315.643 34.4532V32.922L316.969 32.1564Z" />
            <path d="M278.688 72.351L280.014 73.1166V74.6478L278.688 75.4135L277.361 74.6478V73.1166L278.688 72.351Z" />
            <path d="M254.187 50.9142L255.513 51.6798V53.2111L254.187 53.9767L252.861 53.2111V51.6798L254.187 50.9142Z" />
            <path d="M301.656 128.625L302.982 129.391V130.922L301.656 131.688L300.33 130.922V129.391L301.656 128.625Z" />
            <path d="M255.719 91.1093L257.045 91.8749V93.4061L255.719 94.1718L254.393 93.4061V91.8749L255.719 91.1093Z" />
            <path d="M264.906 107.187L266.232 107.953V109.484L264.906 110.249L263.58 109.484V107.953L264.906 107.187Z" />
            <path d="M255.719 26.7974L257.045 27.563V29.0942L255.719 29.8599L254.393 29.0942V27.563L255.719 26.7974Z" />
            <path d="M257.25 66.9913L258.576 67.757V69.2882L257.25 70.0538L255.924 69.2882V67.757L257.25 66.9913Z" />
            <path d="M255.719 64.3124L257.045 65.078V66.6093L255.719 67.3749L254.393 66.6093V65.078L255.719 64.3124Z" />
            <path d="M228.156 53.5938L229.482 54.3594V55.8906L228.156 56.6562L226.83 55.8906V54.3594L228.156 53.5938Z" />
            <path d="M277.156 53.5938L278.483 54.3594V55.8906L277.156 56.6562L275.83 55.8906V54.3594L277.156 53.5938Z" />
            <path d="M278.688 93.7893L280.014 94.5549V96.0862L278.688 96.8518L277.361 96.0862V94.5549L278.688 93.7893Z" />
            <path d="M278.688 29.4764L280.014 30.2421V31.7733L278.688 32.5389L277.361 31.7733V30.2421L278.688 29.4764Z" />
            <path d="M318.5 29.4764L319.826 30.2421V31.7733L318.5 32.5389L317.174 31.7733V30.2421L318.5 29.4764Z" />
            <path d="M280.219 69.672L281.545 70.4376V71.9689L280.219 72.7345L278.892 71.9689V70.4376L280.219 69.672Z" />
            <path d="M257.25 56.2731L258.576 57.0387V58.5699L257.25 59.3356L255.924 58.5699V57.0387L257.25 56.2731Z" />
            <path d="M258.781 96.4688L260.107 97.2344V98.7656L258.781 99.5312L257.455 98.7656V97.2344L258.781 96.4688Z" />
            <path d="M258.781 32.1564L260.107 32.922V34.4532L258.781 35.2189L257.455 34.4532V32.922L258.781 32.1564Z" />
            <path d="M260.312 72.351L261.639 73.1166V74.6478L260.312 75.4135L258.986 74.6478V73.1166L260.312 72.351Z" />
            <path d="M231.219 58.9521L232.545 59.7178V61.249L231.219 62.0146L229.893 61.249V59.7178L231.219 58.9521Z" />
            <path d="M280.219 58.9521L281.545 59.7178V61.249L280.219 62.0146L278.892 61.249V59.7178L280.219 58.9521Z" />
            <path d="M257.25 50.9142L258.576 51.6798V53.2111L257.25 53.9767L255.924 53.2111V51.6798L257.25 50.9142Z" />
            <path d="M304.719 128.625L306.045 129.391V130.922L304.719 131.688L303.392 130.922V129.391L304.719 128.625Z" />
            <path d="M258.781 91.1093L260.107 91.8749V93.4061L258.781 94.1718L257.455 93.4061V91.8749L258.781 91.1093Z" />
            <path d="M267.969 107.187L269.295 107.953V109.484L267.969 110.249L266.643 109.484V107.953L267.969 107.187Z" />
            <path d="M277.156 123.265L278.483 124.031V125.562L277.156 126.328L275.83 125.562V124.031L277.156 123.265Z" />
            <path d="M275.625 120.586L276.951 121.351V122.883L275.625 123.648L274.299 122.883V121.351L275.625 120.586Z" />
            <path d="M258.781 26.7974L260.107 27.563V29.0942L258.781 29.8599L257.455 29.0942V27.563L258.781 26.7974Z" />
            <path d="M260.312 66.9913L261.639 67.757V69.2882L260.312 70.0538L258.986 69.2882V67.757L260.312 66.9913Z" />
            <path d="M258.781 64.3124L260.107 65.078V66.6093L258.781 67.3749L257.455 66.6093V65.078L258.781 64.3124Z" />
            <path d="M231.219 53.5938L232.545 54.3594V55.8906L231.219 56.6562L229.893 55.8906V54.3594L231.219 53.5938Z" />
            <path d="M232.75 93.7893L234.076 94.5549V96.0862L232.75 96.8518L231.424 96.0862V94.5549L232.75 93.7893Z" />
            <path d="M280.219 53.5938L281.545 54.3594V55.8906L280.219 56.6562L278.892 55.8906V54.3594L280.219 53.5938Z" />
            <path d="M281.75 93.7893L283.076 94.5549V96.0862L281.75 96.8518L280.424 96.0862V94.5549L281.75 93.7893Z" />
            <path d="M281.75 29.4764L283.076 30.2421V31.7733L281.75 32.5389L280.424 31.7733V30.2421L281.75 29.4764Z" />
            <path d="M321.563 29.4764L322.889 30.2421V31.7733L321.563 32.5389L320.237 31.7733V30.2421L321.563 29.4764Z" />
            <path d="M283.281 69.672L284.608 70.4376V71.9689L283.281 72.7345L281.955 71.9689V70.4376L283.281 69.672Z" />
            <path d="M289.406 69.672L290.732 70.4376V71.9689L289.406 72.7345L288.08 71.9689V70.4376L289.406 69.672Z" />
            <path d="M255.719 37.5156L257.045 38.2812V39.8125L255.719 40.5781L254.393 39.8125V38.2812L255.719 37.5156Z" />
            <path d="M257.25 77.7106L258.576 78.4762V80.0074L257.25 80.7731L255.924 80.0074V78.4762L257.25 77.7106Z" />
            <path d="M257.25 13.3982L258.576 14.1638V15.6951L257.25 16.4607L255.924 15.6951V14.1638L257.25 13.3982Z" />
            <path d="M246.531 10.7189L247.857 11.4845V13.0157L246.531 13.7814L245.205 13.0157V11.4845L246.531 10.7189Z" />
            <path d="M229.687 40.1957L231.013 40.9613V42.4926L229.687 43.2582L228.361 42.4926V40.9613L229.687 40.1957Z" />
            <path d="M231.219 80.3909L232.545 81.1565V82.6877L231.219 83.4534L229.893 82.6877V81.1565L231.219 80.3909Z" />
            <path d="M278.688 40.1957L280.014 40.9613V42.4926L278.688 43.2582L277.361 42.4926V40.9613L278.688 40.1957Z" />
            <path d="M318.5 40.1957L319.826 40.9613V42.4926L318.5 43.2582L317.174 42.4926V40.9613L318.5 40.1957Z" />
            <path d="M280.219 80.3909L281.545 81.1565V82.6877L280.219 83.4534L278.892 82.6877V81.1565L280.219 80.3909Z" />
            <path d="M257.25 40.1957L258.576 40.9613V42.4926L257.25 43.2582L255.924 42.4926V40.9613L257.25 40.1957Z" />
            <path d="M258.781 80.3909L260.107 81.1565V82.6877L258.781 83.4534L257.455 82.6877V81.1565L258.781 80.3909Z" />
            <path d="M258.781 16.0773L260.107 16.8429V18.3741L258.781 19.1398L257.455 18.3741V16.8429L258.781 16.0773Z" />
            <path d="M231.219 42.8746L232.545 43.6403V45.1715L231.219 45.9371L229.893 45.1715V43.6403L231.219 42.8746Z" />
            <path d="M232.75 83.0702L234.076 83.8358V85.3671L232.75 86.1327L231.424 85.3671V83.8358L232.75 83.0702Z" />
            <path d="M280.219 42.8746L281.545 43.6403V45.1715L280.219 45.9371L278.892 45.1715V43.6403L280.219 42.8746Z" />
            <path d="M320.031 42.8746L321.357 43.6403V45.1715L320.031 45.9371L318.705 45.1715V43.6403L320.031 42.8746Z" />
            <path d="M281.75 83.0702L283.076 83.8358V85.3671L281.75 86.1327L280.424 85.3671V83.8358L281.75 83.0702Z" />
            <path d="M229.687 34.8361L231.013 35.6017V37.1329L229.687 37.8986L228.361 37.1329V35.6017L229.687 34.8361Z" />
            <path d="M231.219 75.0316L232.545 75.7972V77.3285L231.219 78.0941L229.893 77.3285V75.7972L231.219 75.0316Z" />
            <path d="M278.688 34.8361L280.014 35.6017V37.1329L278.688 37.8986L277.361 37.1329V35.6017L278.688 34.8361Z" />
            <path d="M318.5 34.8361L319.826 35.6017V37.1329L318.5 37.8986L317.174 37.1329V35.6017L318.5 34.8361Z" />
            <path d="M280.219 75.0316L281.545 75.7972V77.3285L280.219 78.0941L278.892 77.3285V75.7972L280.219 75.0316Z" />
            <path d="M258.781 42.8746L260.107 43.6403V45.1715L258.781 45.9371L257.455 45.1715V43.6403L258.781 42.8746Z" />
            <path d="M260.312 83.0702L261.639 83.8358V85.3671L260.312 86.1327L258.986 85.3671V83.8358L260.312 83.0702Z" />
            <path d="M260.312 18.7571L261.639 19.5227V21.054L260.312 21.8196L258.986 21.054V19.5227L260.312 18.7571Z" />
            <path d="M232.75 45.554L234.076 46.3196V47.8508L232.75 48.6165L231.424 47.8508V46.3196L232.75 45.554Z" />
            <path d="M234.281 85.7496L235.607 86.5153V88.0465L234.281 88.8121L232.955 88.0465V86.5153L234.281 85.7496Z" />
            <path d="M215.906 85.7496L217.232 86.5153V88.0465L215.906 88.8121L214.58 88.0465V86.5153L215.906 85.7496Z" />
            <path d="M281.75 45.554L283.076 46.3196V47.8508L281.75 48.6165L280.424 47.8508V46.3196L281.75 45.554Z" />
            <path d="M283.281 85.7496L284.608 86.5153V88.0465L283.281 88.8121L281.955 88.0465V86.5153L283.281 85.7496Z" />
            <path d="M231.219 37.5156L232.545 38.2812V39.8125L231.219 40.5781L229.893 39.8125V38.2812L231.219 37.5156Z" />
            <path d="M232.75 77.7106L234.076 78.4762V80.0074L232.75 80.7731L231.424 80.0074V78.4762L232.75 77.7106Z" />
            <path d="M280.219 37.5156L281.545 38.2812V39.8125L280.219 40.5781L278.892 39.8125V38.2812L280.219 37.5156Z" />
            <path d="M320.031 37.5156L321.357 38.2812V39.8125L320.031 40.5781L318.705 39.8125V38.2812L320.031 37.5156Z" />
            <path d="M281.75 77.7106L283.076 78.4762V80.0074L281.75 80.7731L280.424 80.0074V78.4762L281.75 77.7106Z" />
            <path d="M249.594 37.5156L250.92 38.2812V39.8125L249.594 40.5781L248.268 39.8125V38.2812L249.594 37.5156Z" />
            <path d="M251.125 77.7106L252.451 78.4762V80.0074L251.125 80.7731L249.799 80.0074V78.4762L251.125 77.7106Z" />
            <path d="M251.125 13.3982L252.451 14.1638V15.6951L251.125 16.4607L249.799 15.6951V14.1638L251.125 13.3982Z" />
            <path d="M243.468 5.35889L244.794 6.12451V7.65576L243.468 8.42139L242.142 7.65576V6.12451L243.468 5.35889Z" />
            <path d="M251.125 40.1957L252.451 40.9613V42.4926L251.125 43.2582L249.799 42.4926V40.9613L251.125 40.1957Z" />
            <path d="M252.656 80.3909L253.982 81.1565V82.6877L252.656 83.4534L251.33 82.6877V81.1565L252.656 80.3909Z" />
            <path d="M252.656 16.0773L253.982 16.8429V18.3741L252.656 19.1398L251.33 18.3741V16.8429L252.656 16.0773Z" />
            <path d="M225.094 42.8746L226.42 43.6403V45.1715L225.094 45.9371L223.768 45.1715V43.6403L225.094 42.8746Z" />
            <path d="M274.094 42.8746L275.42 43.6403V45.1715L274.094 45.9371L272.767 45.1715V43.6403L274.094 42.8746Z" />
            <path d="M313.906 42.8746L315.232 43.6403V45.1715L313.906 45.9371L312.58 45.1715V43.6403L313.906 42.8746Z" />
            <path d="M275.625 83.0702L276.951 83.8358V85.3671L275.625 86.1327L274.299 85.3671V83.8358L275.625 83.0702Z" />
            <path d="M252.656 42.8746L253.982 43.6403V45.1715L252.656 45.9371L251.33 45.1715V43.6403L252.656 42.8746Z" />
            <path d="M254.187 83.0702L255.513 83.8358V85.3671L254.187 86.1327L252.861 85.3671V83.8358L254.187 83.0702Z" />
            <path d="M254.187 18.7571L255.513 19.5227V21.054L254.187 21.8196L252.861 21.054V19.5227L254.187 18.7571Z" />
            <path d="M226.625 45.554L227.951 46.3196V47.8508L226.625 48.6165L225.299 47.8508V46.3196L226.625 45.554Z" />
            <path d="M275.625 45.554L276.951 46.3196V47.8508L275.625 48.6165L274.299 47.8508V46.3196L275.625 45.554Z" />
            <path d="M315.437 45.554L316.764 46.3196V47.8508L315.437 48.6165L314.111 47.8508V46.3196L315.437 45.554Z" />
            <path d="M277.156 85.7496L278.483 86.5153V88.0465L277.156 88.8121L275.83 88.0465V86.5153L277.156 85.7496Z" />
            <path d="M254.187 45.554L255.513 46.3196V47.8508L254.187 48.6165L252.861 47.8508V46.3196L254.187 45.554Z" />
            <path d="M301.656 123.265L302.982 124.031V125.562L301.656 126.328L300.33 125.562V124.031L301.656 123.265Z" />
            <path d="M255.719 85.7496L257.045 86.5153V88.0465L255.719 88.8121L254.393 88.0465V86.5153L255.719 85.7496Z" />
            <path d="M255.719 21.4375L257.045 22.2031V23.7344L255.719 24.5L254.393 23.7344V22.2031L255.719 21.4375Z" />
            <path d="M228.156 48.2341L229.482 48.9998V50.531L228.156 51.2966L226.83 50.531V48.9998L228.156 48.2341Z" />
            <path d="M277.156 48.2341L278.483 48.9998V50.531L277.156 51.2966L275.83 50.531V48.9998L277.156 48.2341Z" />
            <path d="M278.688 88.4297L280.014 89.1953V90.7266L278.688 91.4922L277.361 90.7266V89.1953L278.688 88.4297Z" />
            <path d="M278.688 24.1174L280.014 24.8831V26.4143L278.688 27.1799L277.361 26.4143V24.8831L278.688 24.1174Z" />
            <path d="M254.187 34.8361L255.513 35.6017V37.1329L254.187 37.8986L252.861 37.1329V35.6017L254.187 34.8361Z" />
            <path d="M255.719 75.0316L257.045 75.7972V77.3285L255.719 78.0941L254.393 77.3285V75.7972L255.719 75.0316Z" />
            <path d="M245 8.03955L246.326 8.80518V10.3364L245 11.102L243.674 10.3364V8.80518L245 8.03955Z" />
            <path d="M255.719 48.2341L257.045 48.9998V50.531L255.719 51.2966L254.393 50.531V48.9998L255.719 48.2341Z" />
            <path d="M303.187 125.945L304.513 126.711V128.242L303.187 129.008L301.861 128.242V126.711L303.187 125.945Z" />
            <path d="M257.25 88.4297L258.576 89.1953V90.7266L257.25 91.4922L255.924 90.7266V89.1953L257.25 88.4297Z" />
            <path d="M266.437 104.507L267.764 105.273V106.804L266.437 107.57L265.111 106.804V105.273L266.437 104.507Z" />
            <path d="M257.25 24.1174L258.576 24.8831V26.4143L257.25 27.1799L255.924 26.4143V24.8831L257.25 24.1174Z" />
            <path d="M229.687 50.9142L231.013 51.6798V53.2111L229.687 53.9767L228.361 53.2111V51.6798L229.687 50.9142Z" />
            <path d="M231.218 91.1093L232.544 91.8749V93.4061L231.218 94.1718L229.892 93.4061V91.8749L231.218 91.1093Z" />
            <path d="M278.688 50.9142L280.014 51.6798V53.2111L278.688 53.9767L277.361 53.2111V51.6798L278.688 50.9142Z" />
            <path d="M280.219 91.1093L281.545 91.8749V93.4061L280.219 94.1718L278.892 93.4061V91.8749L280.219 91.1093Z" />
            <path d="M280.219 26.7974L281.545 27.563V29.0942L280.219 29.8599L278.892 29.0942V27.563L280.219 26.7974Z" />
            <path d="M320.031 26.7974L321.357 27.563V29.0942L320.031 29.8599L318.705 29.0942V27.563L320.031 26.7974Z" />
            <path d="M281.75 66.9913L283.076 67.757V69.2882L281.75 70.0538L280.424 69.2882V67.757L281.75 66.9913Z" />
            <path d="M257.25 45.554L258.576 46.3196V47.8508L257.25 48.6165L255.924 47.8508V46.3196L257.25 45.554Z" />
            <path d="M304.719 123.265L306.045 124.031V125.562L304.719 126.328L303.392 125.562V124.031L304.719 123.265Z" />
            <path d="M258.781 85.7496L260.107 86.5153V88.0465L258.781 88.8121L257.455 88.0465V86.5153L258.781 85.7496Z" />
            <path d="M258.781 21.4375L260.107 22.2031V23.7344L258.781 24.5L257.455 23.7344V22.2031L258.781 21.4375Z" />
            <path d="M231.219 48.2341L232.545 48.9998V50.531L231.219 51.2966L229.893 50.531V48.9998L231.219 48.2341Z" />
            <path d="M232.75 88.4297L234.076 89.1953V90.7266L232.75 91.4922L231.424 90.7266V89.1953L232.75 88.4297Z" />
            <path d="M280.219 48.2341L281.545 48.9998V50.531L280.219 51.2966L278.892 50.531V48.9998L280.219 48.2341Z" />
            <path d="M281.75 88.4297L283.076 89.1953V90.7266L281.75 91.4922L280.424 90.7266V89.1953L281.75 88.4297Z" />
            <path d="M281.75 24.1174L283.076 24.8831V26.4143L281.75 27.1799L280.424 26.4143V24.8831L281.75 24.1174Z" />
            <path d="M257.25 34.8361L258.576 35.6017V37.1329L257.25 37.8986L255.924 37.1329V35.6017L257.25 34.8361Z" />
            <path d="M258.781 75.0316L260.107 75.7972V77.3285L258.781 78.0941L257.455 77.3285V75.7972L258.781 75.0316Z" />
            <path d="M263.375 40.1957L264.701 40.9613V42.4926L263.375 43.2582L262.049 42.4926V40.9613L263.375 40.1957Z" />
            <path d="M264.906 80.3909L266.232 81.1565V82.6877L264.906 83.4534L263.58 82.6877V81.1565L264.906 80.3909Z" />
            <path d="M237.344 42.8746L238.67 43.6403V45.1715L237.344 45.9371L236.018 45.1715V43.6403L237.344 42.8746Z" />
            <path d="M238.875 83.0702L240.201 83.8358V85.3671L238.875 86.1327L237.549 85.3671V83.8358L238.875 83.0702Z" />
            <path d="M220.5 83.0702L221.826 83.8358V85.3671L220.5 86.1327L219.174 85.3671V83.8358L220.5 83.0702Z" />
            <path d="M286.344 42.8746L287.67 43.6403V45.1715L286.344 45.9371L285.017 45.1715V43.6403L286.344 42.8746Z" />
            <path d="M235.812 34.8361L237.138 35.6017V37.1329L235.812 37.8986L234.486 37.1329V35.6017L235.812 34.8361Z" />
            <path d="M237.344 75.0316L238.67 75.7972V77.3285L237.344 78.0941L236.018 77.3285V75.7972L237.344 75.0316Z" />
            <path d="M284.813 34.8361L286.139 35.6017V37.1329L284.813 37.8986L283.487 37.1329V35.6017L284.813 34.8361Z" />
            <path d="M324.625 34.8361L325.951 35.6017V37.1329L324.625 37.8986L323.299 37.1329V35.6017L324.625 34.8361Z" />
            <path d="M235.812 29.4764L237.138 30.2421V31.7733L235.812 32.5389L234.486 31.7733V30.2421L235.812 29.4764Z" />
            <path d="M237.344 69.672L238.67 70.4376V71.9689L237.344 72.7345L236.018 71.9689V70.4376L237.344 69.672Z" />
            <path d="M232.75 29.4764L234.076 30.2421V31.7733L232.75 32.5389L231.424 31.7733V30.2421L232.75 29.4764Z" />
            <path d="M234.281 69.672L235.607 70.4376V71.9689L234.281 72.7345L232.955 71.9689V70.4376L234.281 69.672Z" />
            <path d="M263.375 34.8361L264.701 35.6017V37.1329L263.375 37.8986L262.049 37.1329V35.6017L263.375 34.8361Z" />
            <path d="M264.906 75.0316L266.232 75.7972V77.3285L264.906 78.0941L263.58 77.3285V75.7972L264.906 75.0316Z" />
            <path d="M264.906 37.5156L266.232 38.2812V39.8125L264.906 40.5781L263.58 39.8125V38.2812L264.906 37.5156Z" />
            <path d="M266.437 77.7106L267.764 78.4762V80.0074L266.437 80.7731L265.111 80.0074V78.4762L266.437 77.7106Z" />
            <path d="M238.875 40.1957L240.201 40.9613V42.4926L238.875 43.2582L237.549 42.4926V40.9613L238.875 40.1957Z" />
            <path d="M240.406 80.3909L241.732 81.1565V82.6877L240.406 83.4534L239.08 82.6877V81.1565L240.406 80.3909Z" />
            <path d="M222.031 80.3909L223.357 81.1565V82.6877L222.031 83.4534L220.705 82.6877V81.1565L222.031 80.3909Z" />
            <path d="M287.875 40.1957L289.201 40.9613V42.4926L287.875 43.2582L286.549 42.4926V40.9613L287.875 40.1957Z" />
            <path d="M327.688 40.1957L329.014 40.9613V42.4926L327.688 43.2582L326.362 42.4926V40.9613L327.688 40.1957Z" />
            <path d="M289.406 16.0773L290.732 16.8429V18.3741L289.406 19.1398L288.08 18.3741V16.8429L289.406 16.0773Z" />
            <path d="M237.344 32.1564L238.67 32.922V34.4532L237.344 35.2189L236.018 34.4532V32.922L237.344 32.1564Z" />
            <path d="M238.875 72.351L240.201 73.1166V74.6478L238.875 75.4135L237.549 74.6478V73.1166L238.875 72.351Z" />
            <path d="M237.344 26.7974L238.67 27.563V29.0942L237.344 29.8599L236.018 29.0942V27.563L237.344 26.7974Z" />
            <path d="M238.875 66.9913L240.201 67.757V69.2882L238.875 70.0538L237.549 69.2882V67.757L238.875 66.9913Z" />
            <path d="M248.062 34.8361L249.388 35.6017V37.1329L248.062 37.8986L246.736 37.1329V35.6017L248.062 34.8361Z" />
            <path d="M249.594 75.0316L250.92 75.7972V77.3285L249.594 78.0941L248.268 77.3285V75.7972L249.594 75.0316Z" />
            <path d="M249.594 10.7189L250.92 11.4845V13.0157L249.594 13.7814L248.268 13.0157V11.4845L249.594 10.7189Z" />
            <path d="M240.406 5.35889L241.732 6.12451V7.65576L240.406 8.42139L239.08 7.65576V6.12451L240.406 5.35889Z" />
            <path d="M246.531 37.5156L247.857 38.2812V39.8125L246.531 40.5781L245.205 39.8125V38.2812L246.531 37.5156Z" />
            <path d="M248.062 77.7106L249.388 78.4762V80.0074L248.062 80.7731L246.736 80.0074V78.4762L248.062 77.7106Z" />
            <path d="M229.687 77.7106L231.013 78.4762V80.0074L229.687 80.7731L228.361 80.0074V78.4762L229.687 77.7106Z" />
            <path d="M258.781 37.5156L260.107 38.2812V39.8125L258.781 40.5781L257.455 39.8125V38.2812L258.781 37.5156Z" />
            <path d="M260.312 77.7106L261.639 78.4762V80.0074L260.312 80.7731L258.986 80.0074V78.4762L260.312 77.7106Z" />
            <path d="M232.75 40.1957L234.076 40.9613V42.4926L232.75 43.2582L231.424 42.4926V40.9613L232.75 40.1957Z" />
            <path d="M234.281 80.3909L235.607 81.1565V82.6877L234.281 83.4534L232.955 82.6877V81.1565L234.281 80.3909Z" />
            <path d="M215.906 80.3909L217.232 81.1565V82.6877L215.906 83.4534L214.58 82.6877V81.1565L215.906 80.3909Z" />
            <path d="M281.75 40.1957L283.076 40.9613V42.4926L281.75 43.2582L280.424 42.4926V40.9613L281.75 40.1957Z" />
            <path d="M321.563 40.1957L322.889 40.9613V42.4926L321.563 43.2582L320.237 42.4926V40.9613L321.563 40.1957Z" />
            <path d="M283.281 80.3909L284.608 81.1565V82.6877L283.281 83.4534L281.955 82.6877V81.1565L283.281 80.3909Z" />
            <path d="M283.281 16.0773L284.608 16.8429V18.3741L283.281 19.1398L281.955 18.3741V16.8429L283.281 16.0773Z" />
            <path d="M231.219 32.1564L232.545 32.922V34.4532L231.219 35.2189L229.893 34.4532V32.922L231.219 32.1564Z" />
            <path d="M232.75 72.351L234.076 73.1166V74.6478L232.75 75.4135L231.424 74.6478V73.1166L232.75 72.351Z" />
            <path d="M231.219 69.672L232.545 70.4376V71.9689L231.219 72.7345L229.893 71.9689V70.4376L231.219 69.672Z" />
            <path d="M261.844 37.5156L263.17 38.2812V39.8125L261.844 40.5781L260.518 39.8125V38.2812L261.844 37.5156Z" />
            <path d="M263.375 77.7106L264.701 78.4762V80.0074L263.375 80.7731L262.049 80.0074V78.4762L263.375 77.7106Z" />
            <path d="M235.812 40.1957L237.138 40.9613V42.4926L235.812 43.2582L234.486 42.4926V40.9613L235.812 40.1957Z" />
            <path d="M237.344 80.3909L238.67 81.1565V82.6877L237.344 83.4534L236.018 82.6877V81.1565L237.344 80.3909Z" />
            <path d="M218.969 80.3909L220.295 81.1565V82.6877L218.969 83.4534L217.643 82.6877V81.1565L218.969 80.3909Z" />
            <path d="M284.813 40.1957L286.139 40.9613V42.4926L284.813 43.2582L283.487 42.4926V40.9613L284.813 40.1957Z" />
            <path d="M324.625 40.1957L325.951 40.9613V42.4926L324.625 43.2582L323.299 42.4926V40.9613L324.625 40.1957Z" />
            <path d="M286.344 16.0773L287.67 16.8429V18.3741L286.344 19.1398L285.017 18.3741V16.8429L286.344 16.0773Z" />
            <path d="M234.281 32.1564L235.607 32.922V34.4532L234.281 35.2189L232.955 34.4532V32.922L234.281 32.1564Z" />
            <path d="M235.812 72.351L237.138 73.1166V74.6478L235.812 75.4135L234.486 74.6478V73.1166L235.812 72.351Z" />
            <path d="M234.281 26.7974L235.607 27.563V29.0942L234.281 29.8599L232.955 29.0942V27.563L234.281 26.7974Z" />
            <path d="M235.812 66.9913L237.138 67.757V69.2882L235.812 70.0538L234.486 69.2882V67.757L235.812 66.9913Z" />
            <path d="M231.219 26.7974L232.545 27.563V29.0942L231.219 29.8599L229.893 29.0942V27.563L231.219 26.7974Z" />
            <path d="M232.75 66.9913L234.076 67.757V69.2882L232.75 70.0538L231.424 69.2882V67.757L232.75 66.9913Z" />
            <path d="M229.687 24.1174L231.013 24.8831V26.4143L229.687 27.1799L228.361 26.4143V24.8831L229.687 24.1174Z" />
            <path d="M232.75 24.1174L234.076 24.8831V26.4143L232.75 27.1799L231.424 26.4143V24.8831L232.75 24.1174Z" />
            <path d="M238.875 24.1174L240.201 24.8831V26.4143L238.875 27.1799L237.549 26.4143V24.8831L238.875 24.1174Z" />
            <path d="M241.937 24.1174L243.263 24.8831V26.4143L241.937 27.1799L240.611 26.4143V24.8831L241.937 24.1174Z" />
            <path d="M240.406 21.4375L241.732 22.2031V23.7344L240.406 24.5L239.08 23.7344V22.2031L240.406 21.4375Z" />
            <path d="M238.875 18.7571L240.201 19.5227V21.054L238.875 21.8196L237.549 21.054V19.5227L238.875 18.7571Z" />
            <path d="M241.937 18.7571L243.263 19.5227V21.054L241.937 21.8196L240.611 21.054V19.5227L241.937 18.7571Z" />
            <path d="M243.469 16.0773L244.795 16.8429V18.3741L243.469 19.1398L242.143 18.3741V16.8429L243.469 16.0773Z" />
            <path d="M237.344 21.4375L238.67 22.2031V23.7344L237.344 24.5L236.018 23.7344V22.2031L237.344 21.4375Z" />
            <path d="M234.281 21.4375L235.607 22.2031V23.7344L234.281 24.5L232.955 23.7344V22.2031L234.281 21.4375Z" />
            <path d="M235.812 24.1174L237.138 24.8831V26.4143L235.812 27.1799L234.486 26.4143V24.8831L235.812 24.1174Z" />
            <path d="M266.437 34.8361L267.764 35.6017V37.1329L266.437 37.8986L265.111 37.1329V35.6017L266.437 34.8361Z" />
            <path d="M267.969 75.0316L269.295 75.7972V77.3285L267.969 78.0941L266.643 77.3285V75.7972L267.969 75.0316Z" />
            <path d="M220.5 29.4764L221.826 30.2421V31.7733L220.5 32.5389L219.174 31.7733V30.2421L220.5 29.4764Z" />
            <path d="M222.031 32.1564L223.357 32.922V34.4532L222.031 35.2189L220.705 34.4532V32.922L222.031 32.1564Z" />
            <path d="M220.5 34.8361L221.826 35.6017V37.1329L220.5 37.8986L219.174 37.1329V35.6017L220.5 34.8361Z" />
            <path d="M269.5 34.8361L270.826 35.6017V37.1329L269.5 37.8986L268.174 37.1329V35.6017L269.5 34.8361Z" />
            <path d="M309.312 34.8361L310.638 35.6017V37.1329L309.312 37.8986L307.986 37.1329V35.6017L309.312 34.8361Z" />
            <path d="M271.031 75.0316L272.358 75.7972V77.3285L271.031 78.0941L269.705 77.3285V75.7972L271.031 75.0316Z" />
            <path d="M220.5 29.4764L221.826 30.2421V31.7733L220.5 32.5389L219.174 31.7733V30.2421L220.5 29.4764Z" />
            <path d="M222.031 32.1564L223.357 32.922V34.4532L222.031 35.2189L220.705 34.4532V32.922L222.031 32.1564Z" />
            <path d="M225.094 32.1564L226.42 32.922V34.4532L225.094 35.2189L223.768 34.4532V32.922L225.094 32.1564Z" />
            <path d="M223.562 34.8361L224.888 35.6017V37.1329L223.562 37.8986L222.236 37.1329V35.6017L223.562 34.8361Z" />
            <path d="M272.562 34.8361L273.888 35.6017V37.1329L272.562 37.8986L271.236 37.1329V35.6017L272.562 34.8361Z" />
            <path d="M312.375 34.8361L313.701 35.6017V37.1329L312.375 37.8986L311.049 37.1329V35.6017L312.375 34.8361Z" />
            <path d="M274.094 75.0316L275.42 75.7972V77.3285L274.094 78.0941L272.767 77.3285V75.7972L274.094 75.0316Z" />
            <path d="M223.562 29.4764L224.888 30.2421V31.7733L223.562 32.5389L222.236 31.7733V30.2421L223.562 29.4764Z" />
            <path d="M226.625 29.4764L227.951 30.2421V31.7733L226.625 32.5389L225.299 31.7733V30.2421L226.625 29.4764Z" />
            <path d="M225.094 26.7974L226.42 27.563V29.0942L225.094 29.8599L223.768 29.0942V27.563L225.094 26.7974Z" />
            <path d="M223.562 24.1174L224.888 24.8831V26.4143L223.562 27.1799L222.236 26.4143V24.8831L223.562 24.1174Z" />
            <path d="M225.094 21.4375L226.42 22.2031V23.7344L225.094 24.5L223.768 23.7344V22.2031L225.094 21.4375Z" />
            <path d="M222.031 26.7974L223.357 27.563V29.0942L222.031 29.8599L220.705 29.0942V27.563L222.031 26.7974Z" />
            <path d="M220.5 34.8361L221.826 35.6017V37.1329L220.5 37.8986L219.174 37.1329V35.6017L220.5 34.8361Z" />
            <path d="M269.5 34.8361L270.826 35.6017V37.1329L269.5 37.8986L268.174 37.1329V35.6017L269.5 34.8361Z" />
            <path d="M309.312 34.8361L310.638 35.6017V37.1329L309.312 37.8986L307.986 37.1329V35.6017L309.312 34.8361Z" />
            <path d="M271.031 75.0316L272.358 75.7972V77.3285L271.031 78.0941L269.705 77.3285V75.7972L271.031 75.0316Z" />
            <path d="M260.312 40.1957L261.639 40.9613V42.4926L260.312 43.2582L258.986 42.4926V40.9613L260.312 40.1957Z" />
            <path d="M261.844 80.3909L263.17 81.1565V82.6877L261.844 83.4534L260.518 82.6877V81.1565L261.844 80.3909Z" />
            <path d="M261.844 16.0773L263.17 16.8429V18.3741L261.844 19.1398L260.518 18.3741V16.8429L261.844 16.0773Z" />
            <path d="M234.281 42.8746L235.607 43.6403V45.1715L234.281 45.9371L232.955 45.1715V43.6403L234.281 42.8746Z" />
            <path d="M235.812 83.0702L237.138 83.8358V85.3671L235.812 86.1327L234.486 85.3671V83.8358L235.812 83.0702Z" />
            <path d="M217.437 83.0702L218.763 83.8358V85.3671L217.437 86.1327L216.111 85.3671V83.8358L217.437 83.0702Z" />
            <path d="M283.281 42.8746L284.608 43.6403V45.1715L283.281 45.9371L281.955 45.1715V43.6403L283.281 42.8746Z" />
            <path d="M323.094 42.8746L324.42 43.6403V45.1715L323.094 45.9371L321.768 45.1715V43.6403L323.094 42.8746Z" />
            <path d="M284.813 83.0702L286.139 83.8358V85.3671L284.813 86.1327L283.487 85.3671V83.8358L284.813 83.0702Z" />
            <path d="M232.75 34.8361L234.076 35.6017V37.1329L232.75 37.8986L231.424 37.1329V35.6017L232.75 34.8361Z" />
            <path d="M234.281 75.0316L235.607 75.7972V77.3285L234.281 78.0941L232.955 77.3285V75.7972L234.281 75.0316Z" />
            <path d="M281.75 34.8361L283.076 35.6017V37.1329L281.75 37.8986L280.424 37.1329V35.6017L281.75 34.8361Z" />
            <path d="M321.563 34.8361L322.889 35.6017V37.1329L321.563 37.8986L320.237 37.1329V35.6017L321.563 34.8361Z" />
            <path d="M260.312 34.8361L261.639 35.6017V37.1329L260.312 37.8986L258.986 37.1329V35.6017L260.312 34.8361Z" />
            <path d="M261.844 75.0316L263.17 75.7972V77.3285L261.844 78.0941L260.518 77.3285V75.7972L261.844 75.0316Z" />
            <path d="M257.25 61.6324L258.576 62.3981V63.9293L257.25 64.6949L255.924 63.9293V62.3981L257.25 61.6324Z" />
            <path d="M258.781 101.828L260.107 102.594V104.125L258.781 104.891L257.455 104.125V102.594L258.781 101.828Z" />
            <path d="M267.969 117.906L269.295 118.672V120.203L267.969 120.969L266.643 120.203V118.672L267.969 117.906Z" />
            <path d="M231.219 64.3124L232.545 65.078V66.6093L231.219 67.3749L229.893 66.6093V65.078L231.219 64.3124Z" />
            <path d="M214.375 61.6324L215.701 62.3981V63.9293L214.375 64.6949L213.049 63.9293V62.3981L214.375 61.6324Z" />
            <path d="M263.375 61.6324L264.701 62.3981V63.9293L263.375 64.6949L262.049 63.9293V62.3981L263.375 61.6324Z" />
            <path d="M264.906 101.828L266.232 102.594V104.125L264.906 104.891L263.58 104.125V102.594L264.906 101.828Z" />
            <path d="M267.969 123.265L269.295 124.031V125.562L267.969 126.328L266.643 125.562V124.031L267.969 123.265Z" />
            <path d="M237.344 64.3124L238.67 65.078V66.6093L237.344 67.3749L236.018 66.6093V65.078L237.344 64.3124Z" />
            <path d="M238.875 104.507L240.201 105.273V106.804L238.875 107.57L237.549 106.804V105.273L238.875 104.507Z" />
            <path d="M240.406 112.546L241.732 113.312V114.843L240.406 115.609L239.08 114.843V113.312L240.406 112.546Z" />
            <path d="M243.468 112.546L244.794 113.312V114.843L243.468 115.609L242.142 114.843V113.312L243.468 112.546Z" />
            <path d="M245 115.227L246.326 115.993V117.524L245 118.29L243.674 117.524V115.993L245 115.227Z" />
            <path d="M220.5 61.6324L221.826 62.3981V63.9293L220.5 64.6949L219.174 63.9293V62.3981L220.5 61.6324Z" />
            <path d="M269.5 61.6324L270.826 62.3981V63.9293L269.5 64.6949L268.174 63.9293V62.3981L269.5 61.6324Z" />
            <path d="M271.031 101.828L272.358 102.594V104.125L271.031 104.891L269.705 104.125V102.594L271.031 101.828Z" />
            <path d="M266.437 125.945L267.764 126.711V128.242L266.437 129.008L265.111 128.242V126.711L266.437 125.945Z" />
            <path d="M243.469 64.3124L244.795 65.078V66.6093L243.469 67.3749L242.143 66.6093V65.078L243.469 64.3124Z" />
            <path d="M255.719 58.9521L257.045 59.7178V61.249L255.719 62.0146L254.393 61.249V59.7178L255.719 58.9521Z" />
            <path d="M257.25 99.1482L258.576 99.9138V101.445L257.25 102.211L255.924 101.445V99.9138L257.25 99.1482Z" />
            <path d="M266.437 115.227L267.764 115.993V117.524L266.437 118.29L265.111 117.524V115.993L266.437 115.227Z" />
            <path d="M229.687 61.6324L231.013 62.3981V63.9293L229.687 64.6949L228.361 63.9293V62.3981L229.687 61.6324Z" />
            <path d="M278.688 61.6324L280.014 62.3981V63.9293L278.688 64.6949L277.361 63.9293V62.3981L278.688 61.6324Z" />
            <path d="M255.719 53.5938L257.045 54.3594V55.8906L255.719 56.6562L254.393 55.8906V54.3594L255.719 53.5938Z" />
            <path d="M257.25 93.7893L258.576 94.5549V96.0862L257.25 96.8518L255.924 96.0862V94.5549L257.25 93.7893Z" />
            <path d="M257.25 29.4764L258.576 30.2421V31.7733L257.25 32.5389L255.924 31.7733V30.2421L257.25 29.4764Z" />
            <path d="M258.781 69.672L260.107 70.4376V71.9689L258.781 72.7345L257.455 71.9689V70.4376L258.781 69.672Z" />
            <path d="M229.687 56.2731L231.013 57.0387V58.5699L229.687 59.3356L228.361 58.5699V57.0387L229.687 56.2731Z" />
            <path d="M278.688 56.2731L280.014 57.0387V58.5699L278.688 59.3356L277.361 58.5699V57.0387L278.688 56.2731Z" />
            <path d="M280.219 96.4688L281.545 97.2344V98.7656L280.219 99.5312L278.892 98.7656V97.2344L280.219 96.4688Z" />
            <path d="M280.219 32.1564L281.545 32.922V34.4532L280.219 35.2189L278.892 34.4532V32.922L280.219 32.1564Z" />
            <path d="M320.031 32.1564L321.357 32.922V34.4532L320.031 35.2189L318.705 34.4532V32.922L320.031 32.1564Z" />
            <path d="M281.75 72.351L283.076 73.1166V74.6478L281.75 75.4135L280.424 74.6478V73.1166L281.75 72.351Z" />
            <path d="M261.844 58.9521L263.17 59.7178V61.249L261.844 62.0146L260.518 61.249V59.7178L261.844 58.9521Z" />
            <path d="M263.375 99.1482L264.701 99.9138V101.445L263.375 102.211L262.049 101.445V99.9138L263.375 99.1482Z" />
            <path d="M261.844 117.906L263.17 118.672V120.203L261.844 120.969L260.518 120.203V118.672L261.844 117.906Z" />
            <path d="M235.812 61.6324L237.138 62.3981V63.9293L235.812 64.6949L234.486 63.9293V62.3981L235.812 61.6324Z" />
            <path d="M237.343 101.828L238.669 102.594V104.125L237.343 104.891L236.017 104.125V102.594L237.343 101.828Z" />
            <path d="M284.813 61.6324L286.139 62.3981V63.9293L284.813 64.6949L283.487 63.9293V62.3981L284.813 61.6324Z" />
            <path d="M286.344 101.828L287.67 102.594V104.125L286.344 104.891L285.017 104.125V102.594L286.344 101.828Z" />
            <path d="M287.875 104.507L289.201 105.273V106.804L287.875 107.57L286.549 106.804V105.273L287.875 104.507Z" />
            <path d="M289.406 107.187L290.732 107.953V109.484L289.406 110.249L288.08 109.484V107.953L289.406 107.187Z" />
            <path d="M292.468 112.546L293.795 113.312V114.843L292.468 115.609L291.142 114.843V113.312L292.468 112.546Z" />
            <path d="M289.406 112.546L290.732 113.312V114.843L289.406 115.609L288.08 114.843V113.312L289.406 112.546Z" />
            <path d="M290.937 115.227L292.264 115.993V117.524L290.937 118.29L289.611 117.524V115.993L290.937 115.227Z" />
            <path d="M286.344 107.187L287.67 107.953V109.484L286.344 110.249L285.017 109.484V107.953L286.344 107.187Z" />
            <path d="M284.813 109.867L286.139 110.633V112.164L284.813 112.93L283.487 112.164V110.633L284.813 109.867Z" />
            <path d="M283.281 112.546L284.608 113.312V114.843L283.281 115.609L281.955 114.843V113.312L283.281 112.546Z" />
            <path d="M283.281 117.906L284.608 118.672V120.203L283.281 120.969L281.955 120.203V118.672L283.281 117.906Z" />
            <path d="M289.406 123.265L290.732 124.031V125.562L289.406 126.328L288.08 125.562V124.031L289.406 123.265Z" />
            <path d="M294 120.586L295.326 121.351V122.883L294 123.648L292.673 122.883V121.351L294 120.586Z" />
            <path d="M284.813 115.227L286.139 115.993V117.524L284.813 118.29L283.487 117.524V115.993L284.813 115.227Z" />
            <path d="M280.219 117.906L281.545 118.672V120.203L280.219 120.969L278.892 120.203V118.672L280.219 117.906Z" />
            <path d="M286.344 123.265L287.67 124.031V125.562L286.344 126.328L285.017 125.562V124.031L286.344 123.265Z" />
            <path d="M284.813 104.507L286.139 105.273V106.804L284.813 107.57L283.487 106.804V105.273L284.813 104.507Z" />
            <path d="M261.844 53.5938L263.17 54.3594V55.8906L261.844 56.6562L260.518 55.8906V54.3594L261.844 53.5938Z" />
            <path d="M263.375 93.7893L264.701 94.5549V96.0862L263.375 96.8518L262.049 96.0862V94.5549L263.375 93.7893Z" />
            <path d="M272.562 109.867L273.888 110.633V112.164L272.562 112.93L271.236 112.164V110.633L272.562 109.867Z" />
            <path d="M275.625 131.305L276.951 132.071V133.602L275.625 134.368L274.299 133.602V132.071L275.625 131.305Z" />
            <path d="M281.75 125.945L283.076 126.711V128.242L281.75 129.008L280.424 128.242V126.711L281.75 125.945Z" />
            <path d="M263.375 29.4764L264.701 30.2421V31.7733L263.375 32.5389L262.049 31.7733V30.2421L263.375 29.4764Z" />
            <path d="M264.906 69.672L266.232 70.4376V71.9689L264.906 72.7345L263.58 71.9689V70.4376L264.906 69.672Z" />
            <path d="M235.812 56.2731L237.138 57.0387V58.5699L235.812 59.3356L234.486 58.5699V57.0387L235.812 56.2731Z" />
            <path d="M237.343 96.4688L238.669 97.2344V98.7656L237.343 99.5312L236.017 98.7656V97.2344L237.343 96.4688Z" />
            <path d="M284.813 56.2731L286.139 57.0387V58.5699L284.813 59.3356L283.487 58.5699V57.0387L284.813 56.2731Z" />
            <path d="M286.344 96.4688L287.67 97.2344V98.7656L286.344 99.5312L285.017 98.7656V97.2344L286.344 96.4688Z" />
            <path d="M286.344 32.1564L287.67 32.922V34.4532L286.344 35.2189L285.017 34.4532V32.922L286.344 32.1564Z" />
            <path d="M326.156 32.1564L327.482 32.922V34.4532L326.156 35.2189L324.83 34.4532V32.922L326.156 32.1564Z" />
            <path d="M287.875 72.351L289.201 73.1166V74.6478L287.875 75.4135L286.549 74.6478V73.1166L287.875 72.351Z" />
            <path d="M289.406 75.0316L290.732 75.7972V77.3285L289.406 78.0941L288.08 77.3285V75.7972L289.406 75.0316Z" />
            <path d="M290.937 77.7106L292.264 78.4762V80.0074L290.937 80.7731L289.611 80.0074V78.4762L290.937 77.7106Z" />
            <path d="M261.844 48.2341L263.17 48.9998V50.531L261.844 51.2966L260.518 50.531V48.9998L261.844 48.2341Z" />
            <path d="M263.375 88.4297L264.701 89.1953V90.7266L263.375 91.4922L262.049 90.7266V89.1953L263.375 88.4297Z" />
            <path d="M272.562 104.507L273.888 105.273V106.804L272.562 107.57L271.236 106.804V105.273L272.562 104.507Z" />
            <path d="M267.969 128.625L269.295 129.391V130.922L267.969 131.688L266.643 130.922V129.391L267.969 128.625Z" />
            <path d="M281.75 120.586L283.076 121.351V122.883L281.75 123.648L280.424 122.883V121.351L281.75 120.586Z" />
            <path d="M287.875 125.945L289.201 126.711V128.242L287.875 129.008L286.549 128.242V126.711L287.875 125.945Z" />
            <path d="M263.375 24.1174L264.701 24.8831V26.4143L263.375 27.1799L262.049 26.4143V24.8831L263.375 24.1174Z" />
            <path d="M235.812 50.9142L237.138 51.6798V53.2111L235.812 53.9767L234.486 53.2111V51.6798L235.812 50.9142Z" />
            <path d="M237.343 91.1093L238.669 91.8749V93.4061L237.343 94.1718L236.017 93.4061V91.8749L237.343 91.1093Z" />
            <path d="M218.969 91.1093L220.295 91.8749V93.4061L218.969 94.1718L217.643 93.4061V91.8749L218.969 91.1093Z" />
            <path d="M284.813 50.9142L286.139 51.6798V53.2111L284.813 53.9767L283.487 53.2111V51.6798L284.813 50.9142Z" />
            <path d="M286.344 26.7974L287.67 27.563V29.0942L286.344 29.8599L285.017 29.0942V27.563L286.344 26.7974Z" />
            <path d="M324.625 24.1174L325.951 24.8831V26.4143L324.625 27.1799L323.299 26.4143V24.8831L324.625 24.1174Z" />
            <path d="M287.875 66.9913L289.201 67.757V69.2882L287.875 70.0538L286.549 69.2882V67.757L287.875 66.9913Z" />
            <path d="M294 66.9913L295.326 67.757V69.2882L294 70.0538L292.673 69.2882V67.757L294 66.9913Z" />
            <path d="M303.187 66.9913L304.513 67.757V69.2882L303.187 70.0538L301.861 69.2882V67.757L303.187 66.9913Z" />
            <path d="M218.969 58.9521L220.295 59.7178V61.249L218.969 62.0146L217.643 61.249V59.7178L218.969 58.9521Z" />
            <path d="M267.969 58.9521L269.295 59.7178V61.249L267.969 62.0146L266.643 61.249V59.7178L267.969 58.9521Z" />
            <path d="M269.5 99.1482L270.826 99.9138V101.445L269.5 102.211L268.174 101.445V99.9138L269.5 99.1482Z" />
            <path d="M264.906 123.265L266.232 124.031V125.562L264.906 126.328L263.58 125.562V124.031L264.906 123.265Z" />
            <path d="M241.937 61.6324L243.263 62.3981V63.9293L241.937 64.6949L240.611 63.9293V62.3981L241.937 61.6324Z" />
            <path d="M243.468 101.828L244.794 102.594V104.125L243.468 104.891L242.142 104.125V102.594L243.468 101.828Z" />
            <path d="M290.937 61.6324L292.264 62.3981V63.9293L290.937 64.6949L289.611 63.9293V62.3981L290.937 61.6324Z" />
            <path d="M218.969 53.5938L220.295 54.3594V55.8906L218.969 56.6562L217.643 55.8906V54.3594L218.969 53.5938Z" />
            <path d="M267.969 53.5938L269.295 54.3594V55.8906L267.969 56.6562L266.643 55.8906V54.3594L267.969 53.5938Z" />
            <path d="M269.5 93.7893L270.826 94.5549V96.0862L269.5 96.8518L268.174 96.0862V94.5549L269.5 93.7893Z" />
            <path d="M269.5 29.4764L270.826 30.2421V31.7733L269.5 32.5389L268.174 31.7733V30.2421L269.5 29.4764Z" />
            <path d="M309.312 29.4764L310.638 30.2421V31.7733L309.312 32.5389L307.986 31.7733V30.2421L309.312 29.4764Z" />
            <path d="M271.031 69.672L272.358 70.4376V71.9689L271.031 72.7345L269.705 71.9689V70.4376L271.031 69.672Z" />
            <path d="M241.937 56.2731L243.263 57.0387V58.5699L241.937 59.3356L240.611 58.5699V57.0387L241.937 56.2731Z" />
            <path d="M243.468 96.4688L244.794 97.2344V98.7656L243.468 99.5312L242.142 98.7656V97.2344L243.468 96.4688Z" />
            <path d="M290.937 56.2731L292.264 57.0387V58.5699L290.937 59.3356L289.611 58.5699V57.0387L290.937 56.2731Z" />
            <path d="M292.468 32.1564L293.795 32.922V34.4532L292.468 35.2189L291.142 34.4532V32.922L292.468 32.1564Z" />
            <path d="M332.281 32.1564L333.607 32.922V34.4532L332.281 35.2189L330.955 34.4532V32.922L332.281 32.1564Z" />
            <path d="M218.969 48.2341L220.295 48.9998V50.531L218.969 51.2966L217.643 50.531V48.9998L218.969 48.2341Z" />
            <path d="M267.969 48.2341L269.295 48.9998V50.531L267.969 51.2966L266.643 50.531V48.9998L267.969 48.2341Z" />
            <path d="M269.5 88.4297L270.826 89.1953V90.7266L269.5 91.4922L268.174 90.7266V89.1953L269.5 88.4297Z" />
            <path d="M269.5 24.1174L270.826 24.8831V26.4143L269.5 27.1799L268.174 26.4143V24.8831L269.5 24.1174Z" />
            <path d="M241.937 50.9142L243.263 51.6798V53.2111L241.937 53.9767L240.611 53.2111V51.6798L241.937 50.9142Z" />
            <path d="M243.468 91.1093L244.794 91.8749V93.4061L243.468 94.1718L242.142 93.4061V91.8749L243.468 91.1093Z" />
            <path d="M225.094 91.1093L226.42 91.8749V93.4061L225.094 94.1718L223.768 93.4061V91.8749L225.094 91.1093Z" />
            <path d="M215.906 101.828L217.232 102.594V104.125L215.906 104.891L214.58 104.125V102.594L215.906 101.828Z" />
            <path d="M217.437 99.1482L218.763 99.9138V101.445L217.437 102.211L216.111 101.445V99.9138L217.437 99.1482Z" />
            <path d="M215.906 96.4688L217.232 97.2344V98.7656L215.906 99.5312L214.58 98.7656V97.2344L215.906 96.4688Z" />
            <path d="M218.969 96.4688L220.295 97.2344V98.7656L218.969 99.5312L217.643 98.7656V97.2344L218.969 96.4688Z" />
            <path d="M222.031 96.4688L223.357 97.2344V98.7656L222.031 99.5312L220.705 98.7656V97.2344L222.031 96.4688Z" />
            <path d="M220.5 99.1482L221.826 99.9138V101.445L220.5 102.211L219.174 101.445V99.9138L220.5 99.1482Z" />
            <path d="M217.437 93.7893L218.763 94.5549V96.0862L217.437 96.8518L216.111 96.0862V94.5549L217.437 93.7893Z" />
            <path d="M290.937 50.9142L292.264 51.6798V53.2111L290.937 53.9767L289.611 53.2111V51.6798L290.937 50.9142Z" />
            <path d="M292.468 26.7974L293.795 27.563V29.0942L292.468 29.8599L291.142 29.0942V27.563L292.468 26.7974Z" />
            <path d="M295.531 26.7974L296.857 27.563V29.0942L295.531 29.8599L294.205 29.0942V27.563L295.531 26.7974Z" />
            <path d="M298.594 26.7974L299.92 27.563V29.0942L298.594 29.8599L297.267 29.0942V27.563L298.594 26.7974Z" />
            <path d="M301.656 26.7974L302.982 27.563V29.0942L301.656 29.8599L300.33 29.0942V27.563L301.656 26.7974Z" />
            <path d="M304.719 26.7974L306.045 27.563V29.0942L304.719 29.8599L303.392 29.0942V27.563L304.719 26.7974Z" />
            <path d="M306.25 29.4764L307.576 30.2421V31.7733L306.25 32.5389L304.924 31.7733V30.2421L306.25 29.4764Z" />
            <path d="M297.062 24.1174L298.388 24.8831V26.4143L297.062 27.1799L295.736 26.4143V24.8831L297.062 24.1174Z" />
            <path d="M252.656 58.9521L253.982 59.7178V61.249L252.656 62.0146L251.33 61.249V59.7178L252.656 58.9521Z" />
            <path d="M226.625 61.6324L227.951 62.3981V63.9293L226.625 64.6949L225.299 63.9293V62.3981L226.625 61.6324Z" />
            <path d="M275.625 61.6324L276.951 62.3981V63.9293L275.625 64.6949L274.299 63.9293V62.3981L275.625 61.6324Z" />
            <path d="M223.562 61.6324L224.888 62.3981V63.9293L223.562 64.6949L222.236 63.9293V62.3981L223.562 61.6324Z" />
            <path d="M272.562 61.6324L273.888 62.3981V63.9293L272.562 64.6949L271.236 63.9293V62.3981L272.562 61.6324Z" />
            <path d="M274.094 101.828L275.42 102.594V104.125L274.094 104.891L272.767 104.125V102.594L274.094 101.828Z" />
            <path d="M269.5 125.945L270.826 126.711V128.242L269.5 129.008L268.174 128.242V126.711L269.5 125.945Z" />
            <path d="M258.781 58.9521L260.107 59.7178V61.249L258.781 62.0146L257.455 61.249V59.7178L258.781 58.9521Z" />
            <path d="M260.312 99.1482L261.639 99.9138V101.445L260.312 102.211L258.986 101.445V99.9138L260.312 99.1482Z" />
            <path d="M232.75 61.6324L234.076 62.3981V63.9293L232.75 64.6949L231.424 63.9293V62.3981L232.75 61.6324Z" />
            <path d="M281.75 61.6324L283.076 62.3981V63.9293L281.75 64.6949L280.424 63.9293V62.3981L281.75 61.6324Z" />
            <path d="M258.781 53.5938L260.107 54.3594V55.8906L258.781 56.6562L257.455 55.8906V54.3594L258.781 53.5938Z" />
            <path d="M306.25 131.305L307.576 132.071V133.602L306.25 134.368L304.924 133.602V132.071L306.25 131.305Z" />
            <path d="M260.312 93.7893L261.639 94.5549V96.0862L260.312 96.8518L258.986 96.0862V94.5549L260.312 93.7893Z" />
            <path d="M269.5 109.867L270.826 110.633V112.164L269.5 112.93L268.174 112.164V110.633L269.5 109.867Z" />
            <path d="M272.562 131.305L273.888 132.071V133.602L272.562 134.368L271.236 133.602V132.071L272.562 131.305Z" />
            <path d="M278.688 125.945L280.014 126.711V128.242L278.688 129.008L277.361 128.242V126.711L278.688 125.945Z" />
            <path d="M260.312 29.4764L261.639 30.2421V31.7733L260.312 32.5389L258.986 31.7733V30.2421L260.312 29.4764Z" />
            <path d="M261.844 69.672L263.17 70.4376V71.9689L261.844 72.7345L260.518 71.9689V70.4376L261.844 69.672Z" />
            <path d="M232.75 56.2731L234.076 57.0387V58.5699L232.75 59.3356L231.424 58.5699V57.0387L232.75 56.2731Z" />
            <path d="M234.281 96.4688L235.607 97.2344V98.7656L234.281 99.5312L232.955 98.7656V97.2344L234.281 96.4688Z" />
            <path d="M281.75 56.2731L283.076 57.0387V58.5699L281.75 59.3356L280.424 58.5699V57.0387L281.75 56.2731Z" />
            <path d="M283.281 32.1564L284.608 32.922V34.4532L283.281 35.2189L281.955 34.4532V32.922L283.281 32.1564Z" />
            <path d="M323.094 32.1564L324.42 32.922V34.4532L323.094 35.2189L321.768 34.4532V32.922L323.094 32.1564Z" />
            <path d="M284.813 72.351L286.139 73.1166V74.6478L284.813 75.4135L283.487 74.6478V73.1166L284.813 72.351Z" />
            <path d="M258.781 48.2341L260.107 48.9998V50.531L258.781 51.2966L257.455 50.531V48.9998L258.781 48.2341Z" />
            <path d="M306.25 125.945L307.576 126.711V128.242L306.25 129.008L304.924 128.242V126.711L306.25 125.945Z" />
            <path d="M260.312 88.4297L261.639 89.1953V90.7266L260.312 91.4922L258.986 90.7266V89.1953L260.312 88.4297Z" />
            <path d="M269.5 104.507L270.826 105.273V106.804L269.5 107.57L268.174 106.804V105.273L269.5 104.507Z" />
            <path d="M278.688 120.586L280.014 121.351V122.883L278.688 123.648L277.361 122.883V121.351L278.688 120.586Z" />
            <path d="M260.312 24.1174L261.639 24.8831V26.4143L260.312 27.1799L258.986 26.4143V24.8831L260.312 24.1174Z" />
            <path d="M232.75 50.9142L234.076 51.6798V53.2111L232.75 53.9767L231.424 53.2111V51.6798L232.75 50.9142Z" />
            <path d="M234.281 91.1093L235.607 91.8749V93.4061L234.281 94.1718L232.955 93.4061V91.8749L234.281 91.1093Z" />
            <path d="M281.75 50.9142L283.076 51.6798V53.2111L281.75 53.9767L280.424 53.2111V51.6798L281.75 50.9142Z" />
            <path d="M283.281 91.1093L284.608 91.8749V93.4061L283.281 94.1718L281.955 93.4061V91.8749L283.281 91.1093Z" />
            <path d="M283.281 26.7974L284.608 27.563V29.0942L283.281 29.8599L281.955 29.0942V27.563L283.281 26.7974Z" />
            <path d="M323.094 26.7974L324.42 27.563V29.0942L323.094 29.8599L321.768 29.0942V27.563L323.094 26.7974Z" />
            <path d="M284.813 66.9913L286.139 67.757V69.2882L284.813 70.0538L283.487 69.2882V67.757L284.813 66.9913Z" />
            <path d="M290.937 66.9913L292.264 67.757V69.2882L290.937 70.0538L289.611 69.2882V67.757L290.937 66.9913Z" />
            <path d="M304.719 69.672L306.045 70.4376V71.9689L304.719 72.7345L303.392 71.9689V70.4376L304.719 69.672Z" />
            <path d="M301.656 69.672L302.982 70.4376V71.9689L301.656 72.7345L300.33 71.9689V70.4376L301.656 69.672Z" />
            <path d="M303.187 72.351L304.513 73.1166V74.6478L303.187 75.4135L301.861 74.6478V73.1166L303.187 72.351Z" />
            <path d="M303.187 77.7106L304.513 78.4762V80.0074L303.187 80.7731L301.861 80.0074V78.4762L303.187 77.7106Z" />
            <path d="M301.656 75.0316L302.982 75.7972V77.3285L301.656 78.0941L300.33 77.3285V75.7972L301.656 75.0316Z" />
            <path d="M300.125 77.7106L301.451 78.4762V80.0074L300.125 80.7731L298.799 80.0074V78.4762L300.125 77.7106Z" />
            <path d="M298.594 80.3909L299.92 81.1565V82.6877L298.594 83.4534L297.267 82.6877V81.1565L298.594 80.3909Z" />
            <path d="M301.656 80.3909L302.982 81.1565V82.6877L301.656 83.4534L300.33 82.6877V81.1565L301.656 80.3909Z" />
            <path d="M297.062 83.0702L298.388 83.8358V85.3671L297.062 86.1327L295.736 85.3671V83.8358L297.062 83.0702Z" />
            <path d="M294 83.0702L295.326 83.8358V85.3671L294 86.1327L292.673 85.3671V83.8358L294 83.0702Z" />
            <path d="M295.531 80.3909L296.857 81.1565V82.6877L295.531 83.4534L294.205 82.6877V81.1565L295.531 80.3909Z" />
            <path d="M304.719 75.0316L306.045 75.7972V77.3285L304.719 78.0941L303.392 77.3285V75.7972L304.719 75.0316Z" />
            <path d="M215.906 58.9521L217.232 59.7178V61.249L215.906 62.0146L214.58 61.249V59.7178L215.906 58.9521Z" />
            <path d="M264.906 58.9521L266.232 59.7178V61.249L264.906 62.0146L263.58 61.249V59.7178L264.906 58.9521Z" />
            <path d="M266.437 99.1482L267.764 99.9138V101.445L266.437 102.211L265.111 101.445V99.9138L266.437 99.1482Z" />
            <path d="M238.875 61.6324L240.201 62.3981V63.9293L238.875 64.6949L237.549 63.9293V62.3981L238.875 61.6324Z" />
            <path d="M240.406 101.828L241.732 102.594V104.125L240.406 104.891L239.08 104.125V102.594L240.406 101.828Z" />
            <path d="M241.937 109.867L243.263 110.633V112.164L241.937 112.93L240.611 112.164V110.633L241.937 109.867Z" />
            <path d="M287.875 61.6324L289.201 62.3981V63.9293L287.875 64.6949L286.549 63.9293V62.3981L287.875 61.6324Z" />
            <path d="M215.906 53.5938L217.232 54.3594V55.8906L215.906 56.6562L214.58 55.8906V54.3594L215.906 53.5938Z" />
            <path d="M264.906 53.5938L266.232 54.3594V55.8906L264.906 56.6562L263.58 55.8906V54.3594L264.906 53.5938Z" />
            <path d="M266.437 93.7893L267.764 94.5549V96.0862L266.437 96.8518L265.111 96.0862V94.5549L266.437 93.7893Z" />
            <path d="M266.437 29.4764L267.764 30.2421V31.7733L266.437 32.5389L265.111 31.7733V30.2421L266.437 29.4764Z" />
            <path d="M267.969 69.672L269.295 70.4376V71.9689L267.969 72.7345L266.643 71.9689V70.4376L267.969 69.672Z" />
            <path d="M238.875 56.2731L240.201 57.0387V58.5699L238.875 59.3356L237.549 58.5699V57.0387L238.875 56.2731Z" />
            <path d="M240.406 96.4688L241.732 97.2344V98.7656L240.406 99.5312L239.08 98.7656V97.2344L240.406 96.4688Z" />
            <path d="M287.875 56.2731L289.201 57.0387V58.5699L287.875 59.3356L286.549 58.5699V57.0387L287.875 56.2731Z" />
            <path d="M289.406 32.1564L290.732 32.922V34.4532L289.406 35.2189L288.08 34.4532V32.922L289.406 32.1564Z" />
            <path d="M329.219 32.1564L330.545 32.922V34.4532L329.219 35.2189L327.893 34.4532V32.922L329.219 32.1564Z" />
            <path d="M215.906 48.2341L217.232 48.9998V50.531L215.906 51.2966L214.58 50.531V48.9998L215.906 48.2341Z" />
            <path d="M264.906 48.2341L266.232 48.9998V50.531L264.906 51.2966L263.58 50.531V48.9998L264.906 48.2341Z" />
            <path d="M266.437 88.4297L267.764 89.1953V90.7266L266.437 91.4922L265.111 90.7266V89.1953L266.437 88.4297Z" />
            <path d="M266.437 24.1174L267.764 24.8831V26.4143L266.437 27.1799L265.111 26.4143V24.8831L266.437 24.1174Z" />
            <path d="M238.875 50.9142L240.201 51.6798V53.2111L238.875 53.9767L237.549 53.2111V51.6798L238.875 50.9142Z" />
            <path d="M240.406 91.1093L241.732 91.8749V93.4061L240.406 94.1718L239.08 93.4061V91.8749L240.406 91.1093Z" />
            <path d="M222.031 91.1093L223.357 91.8749V93.4061L222.031 94.1718L220.705 93.4061V91.8749L222.031 91.1093Z" />
            <path d="M287.875 50.9142L289.201 51.6798V53.2111L287.875 53.9767L286.549 53.2111V51.6798L287.875 50.9142Z" />
            <path d="M289.406 26.7974L290.732 27.563V29.0942L289.406 29.8599L288.08 29.0942V27.563L289.406 26.7974Z" />
            <path d="M222.031 58.9521L223.357 59.7178V61.249L222.031 62.0146L220.705 61.249V59.7178L222.031 58.9521Z" />
            <path d="M271.031 58.9521L272.358 59.7178V61.249L271.031 62.0146L269.705 61.249V59.7178L271.031 58.9521Z" />
            <path d="M245 61.6324L246.326 62.3981V63.9293L245 64.6949L243.674 63.9293V62.3981L245 61.6324Z" />
            <path d="M246.531 101.828L247.857 102.594V104.125L246.531 104.891L245.205 104.125V102.594L246.531 101.828Z" />
            <path d="M294 61.6324L295.326 62.3981V63.9293L294 64.6949L292.673 63.9293V62.3981L294 61.6324Z" />
            <path d="M222.031 53.5938L223.357 54.3594V55.8906L222.031 56.6562L220.705 55.8906V54.3594L222.031 53.5938Z" />
            <path d="M271.031 53.5938L272.358 54.3594V55.8906L271.031 56.6562L269.705 55.8906V54.3594L271.031 53.5938Z" />
            <path d="M272.562 93.7893L273.888 94.5549V96.0862L272.562 96.8518L271.236 96.0862V94.5549L272.562 93.7893Z" />
            <path d="M272.562 29.4764L273.888 30.2421V31.7733L272.562 32.5389L271.236 31.7733V30.2421L272.562 29.4764Z" />
            <path d="M312.375 29.4764L313.701 30.2421V31.7733L312.375 32.5389L311.049 31.7733V30.2421L312.375 29.4764Z" />
            <path d="M274.094 69.672L275.42 70.4376V71.9689L274.094 72.7345L272.767 71.9689V70.4376L274.094 69.672Z" />
            <path d="M245 56.2731L246.326 57.0387V58.5699L245 59.3356L243.674 58.5699V57.0387L245 56.2731Z" />
            <path d="M246.531 96.4688L247.857 97.2344V98.7656L246.531 99.5312L245.205 98.7656V97.2344L246.531 96.4688Z" />
            <path d="M294 56.2731L295.326 57.0387V58.5699L294 59.3356L292.673 58.5699V57.0387L294 56.2731Z" />
            <path d="M295.531 32.1564L296.857 32.922V34.4532L295.531 35.2189L294.205 34.4532V32.922L295.531 32.1564Z" />
            <path d="M335.344 32.1564L336.67 32.922V34.4532L335.344 35.2189L334.018 34.4532V32.922L335.344 32.1564Z" />
            <path d="M222.031 48.2341L223.357 48.9998V50.531L222.031 51.2966L220.705 50.531V48.9998L222.031 48.2341Z" />
            <path d="M271.031 48.2341L272.358 48.9998V50.531L271.031 51.2966L269.705 50.531V48.9998L271.031 48.2341Z" />
            <path d="M272.562 88.4297L273.888 89.1953V90.7266L272.562 91.4922L271.236 90.7266V89.1953L272.562 88.4297Z" />
            <path d="M272.562 24.1174L273.888 24.8831V26.4143L272.562 27.1799L271.236 26.4143V24.8831L272.562 24.1174Z" />
            <path d="M245 50.9142L246.326 51.6798V53.2111L245 53.9767L243.674 53.2111V51.6798L245 50.9142Z" />
            <path d="M246.531 91.1093L247.857 91.8749V93.4061L246.531 94.1718L245.205 93.4061V91.8749L246.531 91.1093Z" />
            <path d="M228.156 91.1093L229.482 91.8749V93.4061L228.156 94.1718L226.83 93.4061V91.8749L228.156 91.1093Z" />
            <path d="M251.125 61.6324L252.451 62.3981V63.9293L251.125 64.6949L249.799 63.9293V62.3981L251.125 61.6324Z" />
            <path d="M223.562 56.2731L224.888 57.0387V58.5699L223.562 59.3356L222.236 58.5699V57.0387L223.562 56.2731Z" />
            <path d="M272.562 56.2731L273.888 57.0387V58.5699L272.562 59.3356L271.236 58.5699V57.0387L272.562 56.2731Z" />
            <path d="M274.094 96.4688L275.42 97.2344V98.7656L274.094 99.5312L272.767 98.7656V97.2344L274.094 96.4688Z" />
            <path d="M274.094 32.1564L275.42 32.922V34.4532L274.094 35.2189L272.767 34.4532V32.922L274.094 32.1564Z" />
            <path d="M313.906 32.1564L315.232 32.922V34.4532L313.906 35.2189L312.58 34.4532V32.922L313.906 32.1564Z" />
            <path d="M275.625 72.351L276.951 73.1166V74.6478L275.625 75.4135L274.299 74.6478V73.1166L275.625 72.351Z" />
            <path d="M246.531 58.9521L247.857 59.7178V61.249L246.531 62.0146L245.205 61.249V59.7178L246.531 58.9521Z" />
            <path d="M248.062 99.1482L249.388 99.9138V101.445L248.062 102.211L246.736 101.445V99.9138L248.062 99.1482Z" />
            <path d="M295.531 58.9521L296.857 59.7178V61.249L295.531 62.0146L294.205 61.249V59.7178L295.531 58.9521Z" />
            <path d="M223.562 50.9142L224.888 51.6798V53.2111L223.562 53.9767L222.236 53.2111V51.6798L223.562 50.9142Z" />
            <path d="M272.562 50.9142L273.888 51.6798V53.2111L272.562 53.9767L271.236 53.2111V51.6798L272.562 50.9142Z" />
            <path d="M274.094 91.1093L275.42 91.8749V93.4061L274.094 94.1718L272.767 93.4061V91.8749L274.094 91.1093Z" />
            <path d="M274.094 26.7974L275.42 27.563V29.0942L274.094 29.8599L272.767 29.0942V27.563L274.094 26.7974Z" />
            <path d="M313.906 26.7974L315.232 27.563V29.0942L313.906 29.8599L312.58 29.0942V27.563L313.906 26.7974Z" />
            <path d="M275.625 66.9913L276.951 67.757V69.2882L275.625 70.0538L274.299 69.2882V67.757L275.625 66.9913Z" />
            <path d="M246.531 53.5938L247.857 54.3594V55.8906L246.531 56.6562L245.205 55.8906V54.3594L246.531 53.5938Z" />
            <path d="M248.062 93.7893L249.388 94.5549V96.0862L248.062 96.8518L246.736 96.0862V94.5549L248.062 93.7893Z" />
            <path d="M295.531 53.5938L296.857 54.3594V55.8906L295.531 56.6562L294.205 55.8906V54.3594L295.531 53.5938Z" />
            <path d="M297.062 29.4764L298.388 30.2421V31.7733L297.062 32.5389L295.736 31.7733V30.2421L297.062 29.4764Z" />
            <path d="M300.125 29.4764L301.451 30.2421V31.7733L300.125 32.5389L298.799 31.7733V30.2421L300.125 29.4764Z" />
            <path d="M223.562 45.554L224.888 46.3196V47.8508L223.562 48.6165L222.236 47.8508V46.3196L223.562 45.554Z" />
            <path d="M272.562 45.554L273.888 46.3196V47.8508L272.562 48.6165L271.236 47.8508V46.3196L272.562 45.554Z" />
            <path d="M313.906 48.2341L315.232 48.9998V50.531L313.906 51.2966L312.58 50.531V48.9998L313.906 48.2341Z" />
            <path d="M316.969 48.2341L318.295 48.9998V50.531L316.969 51.2966L315.643 50.531V48.9998L316.969 48.2341Z" />
            <path d="M315.437 50.9142L316.764 51.6798V53.2111L315.437 53.9767L314.111 53.2111V51.6798L315.437 50.9142Z" />
            <path d="M312.375 50.9142L313.701 51.6798V53.2111L312.375 53.9767L311.049 53.2111V51.6798L312.375 50.9142Z" />
            <path d="M313.906 53.5938L315.232 54.3594V55.8906L313.906 56.6562L312.58 55.8906V54.3594L313.906 53.5938Z" />
            <path d="M274.094 85.7496L275.42 86.5153V88.0465L274.094 88.8121L272.767 88.0465V86.5153L274.094 85.7496Z" />
            <path d="M274.094 21.4375L275.42 22.2031V23.7344L274.094 24.5L272.767 23.7344V22.2031L274.094 21.4375Z" />
            <path d="M246.531 48.2341L247.857 48.9998V50.531L246.531 51.2966L245.205 50.531V48.9998L246.531 48.2341Z" />
            <path d="M248.062 88.4297L249.388 89.1953V90.7266L248.062 91.4922L246.736 90.7266V89.1953L248.062 88.4297Z" />
            <path d="M229.687 88.4297L231.013 89.1953V90.7266L229.687 91.4922L228.361 90.7266V89.1953L229.687 88.4297Z" />
            <path d="M222.031 42.8746L223.357 43.6403V45.1715L222.031 45.9371L220.705 45.1715V43.6403L222.031 42.8746Z" />
            <path d="M271.031 42.8746L272.358 43.6403V45.1715L271.031 45.9371L269.705 45.1715V43.6403L271.031 42.8746Z" />
            <path d="M272.562 83.0702L273.888 83.8358V85.3671L272.562 86.1327L271.236 85.3671V83.8358L272.562 83.0702Z" />
            <path d="M272.562 18.7571L273.888 19.5227V21.054L272.562 21.8196L271.236 21.054V19.5227L272.562 18.7571Z" />
            <path d="M245 45.554L246.326 46.3196V47.8508L245 48.6165L243.674 47.8508V46.3196L245 45.554Z" />
            <path d="M246.531 85.7496L247.857 86.5153V88.0465L246.531 88.8121L245.205 88.0465V86.5153L246.531 85.7496Z" />
            <path d="M228.156 85.7496L229.482 86.5153V88.0465L228.156 88.8121L226.83 88.0465V86.5153L228.156 85.7496Z" />
            <path d="M294 45.554L295.326 46.3196V47.8508L294 48.6165L292.673 47.8508V46.3196L294 45.554Z" />
            <path d="M243.469 37.5156L244.795 38.2812V39.8125L243.469 40.5781L242.143 39.8125V38.2812L243.469 37.5156Z" />
            <path d="M245 77.7106L246.326 78.4762V80.0074L245 80.7731L243.674 80.0074V78.4762L245 77.7106Z" />
            <path d="M226.625 77.7106L227.951 78.4762V80.0074L226.625 80.7731L225.299 80.0074V78.4762L226.625 77.7106Z" />
            <path d="M292.468 37.5156L293.795 38.2812V39.8125L292.468 40.5781L291.142 39.8125V38.2812L292.468 37.5156Z" />
            <path d="M332.281 37.5156L333.607 38.2812V39.8125L332.281 40.5781L330.955 39.8125V38.2812L332.281 37.5156Z" />
            <path d="M298.594 37.5156L299.92 38.2812V39.8125L298.594 40.5781L297.267 39.8125V38.2812L298.594 37.5156Z" />
            <path d="M301.656 37.5156L302.982 38.2812V39.8125L301.656 40.5781L300.33 39.8125V38.2812L301.656 37.5156Z" />
            <path d="M303.187 40.1957L304.513 40.9613V42.4926L303.187 43.2582L301.861 42.4926V40.9613L303.187 40.1957Z" />
            <path d="M223.562 40.1957L224.888 40.9613V42.4926L223.562 43.2582L222.236 42.4926V40.9613L223.562 40.1957Z" />
            <path d="M272.562 40.1957L273.888 40.9613V42.4926L272.562 43.2582L271.236 42.4926V40.9613L272.562 40.1957Z" />
            <path d="M312.375 40.1957L313.701 40.9613V42.4926L312.375 43.2582L311.049 42.4926V40.9613L312.375 40.1957Z" />
            <path d="M274.094 80.3909L275.42 81.1565V82.6877L274.094 83.4534L272.767 82.6877V81.1565L274.094 80.3909Z" />
            <path d="M246.531 42.8746L247.857 43.6403V45.1715L246.531 45.9371L245.205 45.1715V43.6403L246.531 42.8746Z" />
            <path d="M248.062 83.0702L249.388 83.8358V85.3671L248.062 86.1327L246.736 85.3671V83.8358L248.062 83.0702Z" />
            <path d="M229.687 83.0702L231.013 83.8358V85.3671L229.687 86.1327L228.361 85.3671V83.8358L229.687 83.0702Z" />
            <path d="M295.531 42.8746L296.857 43.6403V45.1715L295.531 45.9371L294.205 45.1715V43.6403L295.531 42.8746Z" />
            <path d="M298.594 42.8746L299.92 43.6403V45.1715L298.594 45.9371L297.267 45.1715V43.6403L298.594 42.8746Z" />
            <path d="M306.25 40.1957L307.576 40.9613V42.4926L306.25 43.2582L304.924 42.4926V40.9613L306.25 40.1957Z" />
            <path d="M304.719 42.8746L306.045 43.6403V45.1715L304.719 45.9371L303.392 45.1715V43.6403L304.719 42.8746Z" />
            <path d="M245 34.8361L246.326 35.6017V37.1329L245 37.8986L243.674 37.1329V35.6017L245 34.8361Z" />
            <path d="M246.531 75.0316L247.857 75.7972V77.3285L246.531 78.0941L245.205 77.3285V75.7972L246.531 75.0316Z" />
            <path d="M294 34.8361L295.326 35.6017V37.1329L294 37.8986L292.673 37.1329V35.6017L294 34.8361Z" />
            <path d="M333.813 34.8361L335.139 35.6017V37.1329L333.813 37.8986L332.487 37.1329V35.6017L333.813 34.8361Z" />
            <path d="M300.125 34.8361L301.451 35.6017V37.1329L300.125 37.8986L298.799 37.1329V35.6017L300.125 34.8361Z" />
            <path d="M303.187 34.8361L304.513 35.6017V37.1329L303.187 37.8986L301.861 37.1329V35.6017L303.187 34.8361Z" />
            <path d="M304.719 37.5156L306.045 38.2812V39.8125L304.719 40.5781L303.392 39.8125V38.2812L304.719 37.5156Z" />
            <path d="M245 29.4764L246.326 30.2421V31.7733L245 32.5389L243.674 31.7733V30.2421L245 29.4764Z" />
            <path d="M246.531 69.672L247.857 70.4376V71.9689L246.531 72.7345L245.205 71.9689V70.4376L246.531 69.672Z" />
            <path d="M225.094 37.5156L226.42 38.2812V39.8125L225.094 40.5781L223.768 39.8125V38.2812L225.094 37.5156Z" />
            <path d="M274.094 37.5156L275.42 38.2812V39.8125L274.094 40.5781L272.767 39.8125V38.2812L274.094 37.5156Z" />
            <path d="M313.906 37.5156L315.232 38.2812V39.8125L313.906 40.5781L312.58 39.8125V38.2812L313.906 37.5156Z" />
            <path d="M275.625 77.7106L276.951 78.4762V80.0074L275.625 80.7731L274.299 80.0074V78.4762L275.625 77.7106Z" />
            <path d="M248.062 40.1957L249.388 40.9613V42.4926L248.062 43.2582L246.736 42.4926V40.9613L248.062 40.1957Z" />
            <path d="M249.594 80.3909L250.92 81.1565V82.6877L249.594 83.4534L248.268 82.6877V81.1565L249.594 80.3909Z" />
            <path d="M249.594 16.0773L250.92 16.8429V18.3741L249.594 19.1398L248.268 18.3741V16.8429L249.594 16.0773Z" />
            <path d="M297.062 40.1957L298.388 40.9613V42.4926L297.062 43.2582L295.736 42.4926V40.9613L297.062 40.1957Z" />
            <path d="M300.125 40.1957L301.451 40.9613V42.4926L300.125 43.2582L298.799 42.4926V40.9613L300.125 40.1957Z" />
            <path d="M301.656 42.8746L302.982 43.6403V45.1715L301.656 45.9371L300.33 45.1715V43.6403L301.656 42.8746Z" />
            <path d="M246.531 32.1564L247.857 32.922V34.4532L246.531 35.2189L245.205 34.4532V32.922L246.531 32.1564Z" />
            <path d="M248.062 72.351L249.388 73.1166V74.6478L248.062 75.4135L246.736 74.6478V73.1166L248.062 72.351Z" />
            <path d="M246.531 26.7974L247.857 27.563V29.0942L246.531 29.8599L245.205 29.0942V27.563L246.531 26.7974Z" />
            <path d="M248.062 66.9913L249.388 67.757V69.2882L248.062 70.0538L246.736 69.2882V67.757L248.062 66.9913Z" />
            <path d="M246.531 64.3124L247.857 65.078V66.6093L246.531 67.3749L245.205 66.6093V65.078L246.531 64.3124Z" />
            <path d="M209.781 64.3124L211.107 65.078V66.6093L209.781 67.3749L208.455 66.6093V65.078L209.781 64.3124Z" />
            <path d="M254.187 56.2731L255.513 57.0387V58.5699L254.187 59.3356L252.861 58.5699V57.0387L254.187 56.2731Z" />
            <path d="M255.719 96.4688L257.045 97.2344V98.7656L255.719 99.5312L254.393 98.7656V97.2344L255.719 96.4688Z" />
            <path d="M264.906 112.546L266.232 113.312V114.843L264.906 115.609L263.58 114.843V113.312L264.906 112.546Z" />
            <path d="M255.719 32.1564L257.045 32.922V34.4532L255.719 35.2189L254.393 34.4532V32.922L255.719 32.1564Z" />
            <path d="M257.25 72.351L258.576 73.1166V74.6478L257.25 75.4135L255.924 74.6478V73.1166L257.25 72.351Z" />
            <path d="M228.156 58.9521L229.482 59.7178V61.249L228.156 62.0146L226.83 61.249V59.7178L228.156 58.9521Z" />
            <path d="M277.156 58.9521L278.483 59.7178V61.249L277.156 62.0146L275.83 61.249V59.7178L277.156 58.9521Z" />
            <path d="M251.125 56.2731L252.451 57.0387V58.5699L251.125 59.3356L249.799 58.5699V57.0387L251.125 56.2731Z" />
            <path d="M252.656 32.1564L253.982 32.922V34.4532L252.656 35.2189L251.33 34.4532V32.922L252.656 32.1564Z" />
            <path d="M254.187 72.351L255.513 73.1166V74.6478L254.187 75.4135L252.861 74.6478V73.1166L254.187 72.351Z" />
            <path d="M215.906 64.3124L217.232 65.078V66.6093L215.906 67.3749L214.58 66.6093V65.078L215.906 64.3124Z" />
            <path d="M260.312 56.2731L261.639 57.0387V58.5699L260.312 59.3356L258.986 58.5699V57.0387L260.312 56.2731Z" />
            <path d="M307.781 133.985L309.107 134.75V136.282L307.781 137.047L306.455 136.282V134.75L307.781 133.985Z" />
            <path d="M261.844 96.4688L263.17 97.2344V98.7656L261.844 99.5312L260.518 98.7656V97.2344L261.844 96.4688Z" />
            <path d="M271.031 112.546L272.358 113.312V114.843L271.031 115.609L269.705 114.843V113.312L271.031 112.546Z" />
            <path d="M278.688 131.305L280.014 132.071V133.602L278.688 134.368L277.361 133.602V132.071L278.688 131.305Z" />
            <path d="M283.281 133.985L284.608 134.75V136.282L283.281 137.047L281.955 136.282V134.75L283.281 133.985Z" />
            <path d="M286.344 133.985L287.67 134.75V136.282L286.344 137.047L285.017 136.282V134.75L286.344 133.985Z" />
            <path d="M269.5 120.586L270.826 121.351V122.883L269.5 123.648L268.174 122.883V121.351L269.5 120.586Z" />
            <path d="M261.844 32.1564L263.17 32.922V34.4532L261.844 35.2189L260.518 34.4532V32.922L261.844 32.1564Z" />
            <path d="M263.375 72.351L264.701 73.1166V74.6478L263.375 75.4135L262.049 74.6478V73.1166L263.375 72.351Z" />
            <path d="M234.281 58.9521L235.607 59.7178V61.249L234.281 62.0146L232.955 61.249V59.7178L234.281 58.9521Z" />
            <path d="M235.812 99.1482L237.138 99.9138V101.445L235.812 102.211L234.486 101.445V99.9138L235.812 99.1482Z" />
            <path d="M283.281 58.9521L284.608 59.7178V61.249L283.281 62.0146L281.955 61.249V59.7178L283.281 58.9521Z" />
            <path d="M260.312 50.9142L261.639 51.6798V53.2111L260.312 53.9767L258.986 53.2111V51.6798L260.312 50.9142Z" />
            <path d="M307.781 128.625L309.107 129.391V130.922L307.781 131.688L306.455 130.922V129.391L307.781 128.625Z" />
            <path d="M261.844 91.1093L263.17 91.8749V93.4061L261.844 94.1718L260.518 93.4061V91.8749L261.844 91.1093Z" />
            <path d="M271.031 107.187L272.358 107.953V109.484L271.031 110.249L269.705 109.484V107.953L271.031 107.187Z" />
            <path d="M280.219 123.265L281.545 124.031V125.562L280.219 126.328L278.892 125.562V124.031L280.219 123.265Z" />
            <path d="M286.344 128.625L287.67 129.391V130.922L286.344 131.688L285.017 130.922V129.391L286.344 128.625Z" />
            <path d="M289.406 128.625L290.732 129.391V130.922L289.406 131.688L288.08 130.922V129.391L289.406 128.625Z" />
            <path d="M261.844 26.7974L263.17 27.563V29.0942L261.844 29.8599L260.518 29.0942V27.563L261.844 26.7974Z" />
            <path d="M263.375 66.9913L264.701 67.757V69.2882L263.375 70.0538L262.049 69.2882V67.757L263.375 66.9913Z" />
            <path d="M261.844 64.3124L263.17 65.078V66.6093L261.844 67.3749L260.518 66.6093V65.078L261.844 64.3124Z" />
            <path d="M274.094 64.3124L275.42 65.078V66.6093L274.094 67.3749L272.767 66.6093V65.078L274.094 64.3124Z" />
            <path d="M234.281 53.5938L235.607 54.3594V55.8906L234.281 56.6562L232.955 55.8906V54.3594L234.281 53.5938Z" />
            <path d="M235.812 93.7893L237.138 94.5549V96.0862L235.812 96.8518L234.486 96.0862V94.5549L235.812 93.7893Z" />
            <path d="M283.281 53.5938L284.608 54.3594V55.8906L283.281 56.6562L281.955 55.8906V54.3594L283.281 53.5938Z" />
            <path d="M284.813 93.7893L286.139 94.5549V96.0862L284.813 96.8518L283.487 96.0862V94.5549L284.813 93.7893Z" />
            <path d="M284.813 29.4764L286.139 30.2421V31.7733L284.813 32.5389L283.487 31.7733V30.2421L284.813 29.4764Z" />
            <path d="M324.625 29.4764L325.951 30.2421V31.7733L324.625 32.5389L323.299 31.7733V30.2421L324.625 29.4764Z" />
            <path d="M286.344 69.672L287.67 70.4376V71.9689L286.344 72.7345L285.017 71.9689V70.4376L286.344 69.672Z" />
            <path d="M260.312 45.554L261.639 46.3196V47.8508L260.312 48.6165L258.986 47.8508V46.3196L260.312 45.554Z" />
            <path d="M261.844 85.7496L263.17 86.5153V88.0465L261.844 88.8121L260.518 88.0465V86.5153L261.844 85.7496Z" />
            <path d="M261.844 21.4375L263.17 22.2031V23.7344L261.844 24.5L260.518 23.7344V22.2031L261.844 21.4375Z" />
            <path d="M234.281 48.2341L235.607 48.9998V50.531L234.281 51.2966L232.955 50.531V48.9998L234.281 48.2341Z" />
            <path d="M235.812 88.4297L237.138 89.1953V90.7266L235.812 91.4922L234.486 90.7266V89.1953L235.812 88.4297Z" />
            <path d="M217.437 88.4297L218.763 89.1953V90.7266L217.437 91.4922L216.111 90.7266V89.1953L217.437 88.4297Z" />
            <path d="M283.281 48.2341L284.608 48.9998V50.531L283.281 51.2966L281.955 50.531V48.9998L283.281 48.2341Z" />
            <path d="M284.813 88.4297L286.139 89.1953V90.7266L284.813 91.4922L283.487 90.7266V89.1953L284.813 88.4297Z" />
            <path d="M284.813 24.1174L286.139 24.8831V26.4143L284.813 27.1799L283.487 26.4143V24.8831L284.813 24.1174Z" />
            <path d="M222.031 64.3124L223.357 65.078V66.6093L222.031 67.3749L220.705 66.6093V65.078L222.031 64.3124Z" />
            <path d="M217.437 56.2731L218.763 57.0387V58.5699L217.437 59.3356L216.111 58.5699V57.0387L217.437 56.2731Z" />
            <path d="M266.437 56.2731L267.764 57.0387V58.5699L266.437 59.3356L265.111 58.5699V57.0387L266.437 56.2731Z" />
            <path d="M267.969 96.4688L269.295 97.2344V98.7656L267.969 99.5312L266.643 98.7656V97.2344L267.969 96.4688Z" />
            <path d="M263.375 120.586L264.701 121.351V122.883L263.375 123.648L262.049 122.883V121.351L263.375 120.586Z" />
            <path d="M267.969 32.1564L269.295 32.922V34.4532L267.969 35.2189L266.643 34.4532V32.922L267.969 32.1564Z" />
            <path d="M307.781 32.1564L309.107 32.922V34.4532L307.781 35.2189L306.455 34.4532V32.922L307.781 32.1564Z" />
            <path d="M269.5 72.351L270.826 73.1166V74.6478L269.5 75.4135L268.174 74.6478V73.1166L269.5 72.351Z" />
            <path d="M240.406 58.9521L241.732 59.7178V61.249L240.406 62.0146L239.08 61.249V59.7178L240.406 58.9521Z" />
            <path d="M241.937 99.1482L243.263 99.9138V101.445L241.937 102.211L240.611 101.445V99.9138L241.937 99.1482Z" />
            <path d="M243.468 107.187L244.794 107.953V109.484L243.468 110.249L242.142 109.484V107.953L243.468 107.187Z" />
            <path d="M289.406 58.9521L290.732 59.7178V61.249L289.406 62.0146L288.08 61.249V59.7178L289.406 58.9521Z" />
            <path d="M217.437 50.9142L218.763 51.6798V53.2111L217.437 53.9767L216.111 53.2111V51.6798L217.437 50.9142Z" />
            <path d="M266.437 50.9142L267.764 51.6798V53.2111L266.437 53.9767L265.111 53.2111V51.6798L266.437 50.9142Z" />
            <path d="M267.969 91.1093L269.295 91.8749V93.4061L267.969 94.1718L266.643 93.4061V91.8749L267.969 91.1093Z" />
            <path d="M267.969 26.7974L269.295 27.563V29.0942L267.969 29.8599L266.643 29.0942V27.563L267.969 26.7974Z" />
            <path d="M269.5 66.9913L270.826 67.757V69.2882L269.5 70.0538L268.174 69.2882V67.757L269.5 66.9913Z" />
            <path d="M267.969 64.3124L269.295 65.078V66.6093L267.969 67.3749L266.643 66.6093V65.078L267.969 64.3124Z" />
            <path d="M280.219 64.3124L281.545 65.078V66.6093L280.219 67.3749L278.892 66.6093V65.078L280.219 64.3124Z" />
            <path d="M240.406 53.5938L241.732 54.3594V55.8906L240.406 56.6562L239.08 55.8906V54.3594L240.406 53.5938Z" />
            <path d="M241.937 93.7893L243.263 94.5549V96.0862L241.937 96.8518L240.611 96.0862V94.5549L241.937 93.7893Z" />
            <path d="M289.406 53.5938L290.732 54.3594V55.8906L289.406 56.6562L288.08 55.8906V54.3594L289.406 53.5938Z" />
            <path d="M290.937 29.4764L292.264 30.2421V31.7733L290.937 32.5389L289.611 31.7733V30.2421L290.937 29.4764Z" />
            <path d="M217.437 45.554L218.763 46.3196V47.8508L217.437 48.6165L216.111 47.8508V46.3196L217.437 45.554Z" />
            <path d="M266.437 45.554L267.764 46.3196V47.8508L266.437 48.6165L265.111 47.8508V46.3196L266.437 45.554Z" />
            <path d="M267.969 85.7496L269.295 86.5153V88.0465L267.969 88.8121L266.643 88.0465V86.5153L267.969 85.7496Z" />
            <path d="M267.969 21.4375L269.295 22.2031V23.7344L267.969 24.5L266.643 23.7344V22.2031L267.969 21.4375Z" />
            <path d="M240.406 48.2341L241.732 48.9998V50.531L240.406 51.2966L239.08 50.531V48.9998L240.406 48.2341Z" />
            <path d="M241.937 88.4297L243.263 89.1953V90.7266L241.937 91.4922L240.611 90.7266V89.1953L241.937 88.4297Z" />
            <path d="M223.562 88.4297L224.888 89.1953V90.7266L223.562 91.4922L222.236 90.7266V89.1953L223.562 88.4297Z" />
            <path d="M289.406 48.2341L290.732 48.9998V50.531L289.406 51.2966L288.08 50.531V48.9998L289.406 48.2341Z" />
            <path d="M290.937 24.1174L292.264 24.8831V26.4143L290.937 27.1799L289.611 26.4143V24.8831L290.937 24.1174Z" />
            <path d="M264.906 42.8746L266.232 43.6403V45.1715L264.906 45.9371L263.58 45.1715V43.6403L264.906 42.8746Z" />
            <path d="M266.437 83.0702L267.764 83.8358V85.3671L266.437 86.1327L265.111 85.3671V83.8358L266.437 83.0702Z" />
            <path d="M238.875 45.554L240.201 46.3196V47.8508L238.875 48.6165L237.549 47.8508V46.3196L238.875 45.554Z" />
            <path d="M240.406 85.7496L241.732 86.5153V88.0465L240.406 88.8121L239.08 88.0465V86.5153L240.406 85.7496Z" />
            <path d="M222.031 85.7496L223.357 86.5153V88.0465L222.031 88.8121L220.705 88.0465V86.5153L222.031 85.7496Z" />
            <path d="M287.875 45.554L289.201 46.3196V47.8508L287.875 48.6165L286.549 47.8508V46.3196L287.875 45.554Z" />
            <path d="M289.406 21.4375L290.732 22.2031V23.7344L289.406 24.5L288.08 23.7344V22.2031L289.406 21.4375Z" />
            <path d="M237.344 37.5156L238.67 38.2812V39.8125L237.344 40.5781L236.018 39.8125V38.2812L237.344 37.5156Z" />
            <path d="M238.875 77.7106L240.201 78.4762V80.0074L238.875 80.7731L237.549 80.0074V78.4762L238.875 77.7106Z" />
            <path d="M220.5 77.7106L221.826 78.4762V80.0074L220.5 80.7731L219.174 80.0074V78.4762L220.5 77.7106Z" />
            <path d="M286.344 37.5156L287.67 38.2812V39.8125L286.344 40.5781L285.017 39.8125V38.2812L286.344 37.5156Z" />
            <path d="M326.156 37.5156L327.482 38.2812V39.8125L326.156 40.5781L324.83 39.8125V38.2812L326.156 37.5156Z" />
            <path d="M266.437 40.1957L267.764 40.9613V42.4926L266.437 43.2582L265.111 42.4926V40.9613L266.437 40.1957Z" />
            <path d="M267.969 80.3909L269.295 81.1565V82.6877L267.969 83.4534L266.643 82.6877V81.1565L267.969 80.3909Z" />
            <path d="M240.406 42.8746L241.732 43.6403V45.1715L240.406 45.9371L239.08 45.1715V43.6403L240.406 42.8746Z" />
            <path d="M241.937 83.0702L243.263 83.8358V85.3671L241.937 86.1327L240.611 85.3671V83.8358L241.937 83.0702Z" />
            <path d="M223.562 83.0702L224.888 83.8358V85.3671L223.562 86.1327L222.236 85.3671V83.8358L223.562 83.0702Z" />
            <path d="M289.406 42.8746L290.732 43.6403V45.1715L289.406 45.9371L288.08 45.1715V43.6403L289.406 42.8746Z" />
            <path d="M238.875 34.8361L240.201 35.6017V37.1329L238.875 37.8986L237.549 37.1329V35.6017L238.875 34.8361Z" />
            <path d="M240.406 75.0316L241.732 75.7972V77.3285L240.406 78.0941L239.08 77.3285V75.7972L240.406 75.0316Z" />
            <path d="M287.875 34.8361L289.201 35.6017V37.1329L287.875 37.8986L286.549 37.1329V35.6017L287.875 34.8361Z" />
            <path d="M327.688 34.8361L329.014 35.6017V37.1329L327.688 37.8986L326.362 37.1329V35.6017L327.688 34.8361Z" />
            <path d="M238.875 29.4764L240.201 30.2421V31.7733L238.875 32.5389L237.549 31.7733V30.2421L238.875 29.4764Z" />
            <path d="M240.406 69.672L241.732 70.4376V71.9689L240.406 72.7345L239.08 71.9689V70.4376L240.406 69.672Z" />
            <path d="M218.969 37.5156L220.295 38.2812V39.8125L218.969 40.5781L217.643 39.8125V38.2812L218.969 37.5156Z" />
            <path d="M267.969 37.5156L269.295 38.2812V39.8125L267.969 40.5781L266.643 39.8125V38.2812L267.969 37.5156Z" />
            <path d="M307.781 37.5156L309.107 38.2812V39.8125L307.781 40.5781L306.455 39.8125V38.2812L307.781 37.5156Z" />
            <path d="M269.5 77.7106L270.826 78.4762V80.0074L269.5 80.7731L268.174 80.0074V78.4762L269.5 77.7106Z" />
            <path d="M241.937 40.1957L243.263 40.9613V42.4926L241.937 43.2582L240.611 42.4926V40.9613L241.937 40.1957Z" />
            <path d="M243.469 80.3909L244.795 81.1565V82.6877L243.469 83.4534L242.143 82.6877V81.1565L243.469 80.3909Z" />
            <path d="M225.094 80.3909L226.42 81.1565V82.6877L225.094 83.4534L223.768 82.6877V81.1565L225.094 80.3909Z" />
            <path d="M290.937 40.1957L292.264 40.9613V42.4926L290.937 43.2582L289.611 42.4926V40.9613L290.937 40.1957Z" />
            <path d="M330.75 40.1957L332.076 40.9613V42.4926L330.75 43.2582L329.424 42.4926V40.9613L330.75 40.1957Z" />
            <path d="M292.468 16.0773L293.795 16.8429V18.3741L292.468 19.1398L291.142 18.3741V16.8429L292.468 16.0773Z" />
            <path d="M240.406 32.1564L241.732 32.922V34.4532L240.406 35.2189L239.08 34.4532V32.922L240.406 32.1564Z" />
            <path d="M241.937 72.351L243.263 73.1166V74.6478L241.937 75.4135L240.611 74.6478V73.1166L241.937 72.351Z" />
            <path d="M240.406 26.7974L241.732 27.563V29.0942L240.406 29.8599L239.08 29.0942V27.563L240.406 26.7974Z" />
            <path d="M241.937 66.9913L243.263 67.757V69.2882L241.937 70.0538L240.611 69.2882V67.757L241.937 66.9913Z" />
            <path d="M212.844 64.3124L214.17 65.078V66.6093L212.844 67.3749L211.518 66.6093V65.078L212.844 64.3124Z" />
            <path d="M218.969 64.3124L220.295 65.078V66.6093L218.969 67.3749L217.643 66.6093V65.078L218.969 64.3124Z" />
            <path d="M263.375 56.2731L264.701 57.0387V58.5699L263.375 59.3356L262.049 58.5699V57.0387L263.375 56.2731Z" />
            <path d="M264.906 96.4688L266.232 97.2344V98.7656L264.906 99.5312L263.58 98.7656V97.2344L264.906 96.4688Z" />
            <path d="M264.906 32.1564L266.232 32.922V34.4532L264.906 35.2189L263.58 34.4532V32.922L264.906 32.1564Z" />
            <path d="M266.437 72.351L267.764 73.1166V74.6478L266.437 75.4135L265.111 74.6478V73.1166L266.437 72.351Z" />
            <path d="M237.344 58.9521L238.67 59.7178V61.249L237.344 62.0146L236.018 61.249V59.7178L237.344 58.9521Z" />
            <path d="M238.875 99.1482L240.201 99.9138V101.445L238.875 102.211L237.549 101.445V99.9138L238.875 99.1482Z" />
            <path d="M240.406 107.187L241.732 107.953V109.484L240.406 110.249L239.08 109.484V107.953L240.406 107.187Z" />
            <path d="M286.344 58.9521L287.67 59.7178V61.249L286.344 62.0146L285.017 61.249V59.7178L286.344 58.9521Z" />
            <path d="M287.875 99.1482L289.201 99.9138V101.445L287.875 102.211L286.549 101.445V99.9138L287.875 99.1482Z" />
            <path d="M263.375 50.9142L264.701 51.6798V53.2111L263.375 53.9767L262.049 53.2111V51.6798L263.375 50.9142Z" />
            <path d="M264.906 91.1093L266.232 91.8749V93.4061L264.906 94.1718L263.58 93.4061V91.8749L264.906 91.1093Z" />
            <path d="M274.094 107.187L275.42 107.953V109.484L274.094 110.249L272.767 109.484V107.953L274.094 107.187Z" />
            <path d="M283.281 123.265L284.608 124.031V125.562L283.281 126.328L281.955 125.562V124.031L283.281 123.265Z" />
            <path d="M264.906 26.7974L266.232 27.563V29.0942L264.906 29.8599L263.58 29.0942V27.563L264.906 26.7974Z" />
            <path d="M266.437 66.9913L267.764 67.757V69.2882L266.437 70.0538L265.111 69.2882V67.757L266.437 66.9913Z" />
            <path d="M264.906 64.3124L266.232 65.078V66.6093L264.906 67.3749L263.58 66.6093V65.078L264.906 64.3124Z" />
            <path d="M277.156 64.3124L278.483 65.078V66.6093L277.156 67.3749L275.83 66.6093V65.078L277.156 64.3124Z" />
            <path d="M237.344 53.5938L238.67 54.3594V55.8906L237.344 56.6562L236.018 55.8906V54.3594L237.344 53.5938Z" />
            <path d="M238.875 93.7893L240.201 94.5549V96.0862L238.875 96.8518L237.549 96.0862V94.5549L238.875 93.7893Z" />
            <path d="M286.344 53.5938L287.67 54.3594V55.8906L286.344 56.6562L285.017 55.8906V54.3594L286.344 53.5938Z" />
            <path d="M287.875 93.7893L289.201 94.5549V96.0862L287.875 96.8518L286.549 96.0862V94.5549L287.875 93.7893Z" />
            <path d="M287.875 29.4764L289.201 30.2421V31.7733L287.875 32.5389L286.549 31.7733V30.2421L287.875 29.4764Z" />
            <path d="M327.688 29.4764L329.014 30.2421V31.7733L327.688 32.5389L326.362 31.7733V30.2421L327.688 29.4764Z" />
            <path d="M263.375 45.554L264.701 46.3196V47.8508L263.375 48.6165L262.049 47.8508V46.3196L263.375 45.554Z" />
            <path d="M264.906 85.7496L266.232 86.5153V88.0465L264.906 88.8121L263.58 88.0465V86.5153L264.906 85.7496Z" />
            <path d="M264.906 21.4375L266.232 22.2031V23.7344L264.906 24.5L263.58 23.7344V22.2031L264.906 21.4375Z" />
            <path d="M237.344 48.2341L238.67 48.9998V50.531L237.344 51.2966L236.018 50.531V48.9998L237.344 48.2341Z" />
            <path d="M238.875 88.4297L240.201 89.1953V90.7266L238.875 91.4922L237.549 90.7266V89.1953L238.875 88.4297Z" />
            <path d="M220.5 88.4297L221.826 89.1953V90.7266L220.5 91.4922L219.174 90.7266V89.1953L220.5 88.4297Z" />
            <path d="M286.344 48.2341L287.67 48.9998V50.531L286.344 51.2966L285.017 50.531V48.9998L286.344 48.2341Z" />
            <path d="M287.875 24.1174L289.201 24.8831V26.4143L287.875 27.1799L286.549 26.4143V24.8831L287.875 24.1174Z" />
            <path d="M261.844 42.8746L263.17 43.6403V45.1715L261.844 45.9371L260.518 45.1715V43.6403L261.844 42.8746Z" />
            <path d="M263.375 83.0702L264.701 83.8358V85.3671L263.375 86.1327L262.049 85.3671V83.8358L263.375 83.0702Z" />
            <path d="M235.812 45.554L237.138 46.3196V47.8508L235.812 48.6165L234.486 47.8508V46.3196L235.812 45.554Z" />
            <path d="M237.343 85.7496L238.669 86.5153V88.0465L237.343 88.8121L236.017 88.0465V86.5153L237.343 85.7496Z" />
            <path d="M218.969 85.7496L220.295 86.5153V88.0465L218.969 88.8121L217.643 88.0465V86.5153L218.969 85.7496Z" />
            <path d="M284.813 45.554L286.139 46.3196V47.8508L284.813 48.6165L283.487 47.8508V46.3196L284.813 45.554Z" />
            <path d="M286.344 21.4375L287.67 22.2031V23.7344L286.344 24.5L285.017 23.7344V22.2031L286.344 21.4375Z" />
            <path d="M234.281 37.5156L235.607 38.2812V39.8125L234.281 40.5781L232.955 39.8125V38.2812L234.281 37.5156Z" />
            <path d="M235.812 77.7106L237.138 78.4762V80.0074L235.812 80.7731L234.486 80.0074V78.4762L235.812 77.7106Z" />
            <path d="M217.437 77.7106L218.763 78.4762V80.0074L217.437 80.7731L216.111 80.0074V78.4762L217.437 77.7106Z" />
            <path d="M283.281 37.5156L284.608 38.2812V39.8125L283.281 40.5781L281.955 39.8125V38.2812L283.281 37.5156Z" />
            <path d="M323.094 37.5156L324.42 38.2812V39.8125L323.094 40.5781L321.768 39.8125V38.2812L323.094 37.5156Z" />
            <path d="M284.813 77.7106L286.139 78.4762V80.0074L284.813 80.7731L283.487 80.0074V78.4762L284.813 77.7106Z" />
            <path d="M220.5 56.2731L221.826 57.0387V58.5699L220.5 59.3356L219.174 58.5699V57.0387L220.5 56.2731Z" />
            <path d="M269.5 56.2731L270.826 57.0387V58.5699L269.5 59.3356L268.174 58.5699V57.0387L269.5 56.2731Z" />
            <path d="M271.031 96.4688L272.358 97.2344V98.7656L271.031 99.5312L269.705 98.7656V97.2344L271.031 96.4688Z" />
            <path d="M266.437 120.586L267.764 121.351V122.883L266.437 123.648L265.111 122.883V121.351L266.437 120.586Z" />
            <path d="M271.031 32.1564L272.358 32.922V34.4532L271.031 35.2189L269.705 34.4532V32.922L271.031 32.1564Z" />
            <path d="M310.844 32.1564L312.17 32.922V34.4532L310.844 35.2189L309.518 34.4532V32.922L310.844 32.1564Z" />
            <path d="M272.562 72.351L273.888 73.1166V74.6478L272.562 75.4135L271.236 74.6478V73.1166L272.562 72.351Z" />
            <path d="M243.469 58.9521L244.795 59.7178V61.249L243.469 62.0146L242.143 61.249V59.7178L243.469 58.9521Z" />
            <path d="M245 99.1482L246.326 99.9138V101.445L245 102.211L243.674 101.445V99.9138L245 99.1482Z" />
            <path d="M292.468 58.9521L293.795 59.7178V61.249L292.468 62.0146L291.142 61.249V59.7178L292.468 58.9521Z" />
            <path d="M220.5 50.9142L221.826 51.6798V53.2111L220.5 53.9767L219.174 53.2111V51.6798L220.5 50.9142Z" />
            <path d="M269.5 50.9142L270.826 51.6798V53.2111L269.5 53.9767L268.174 53.2111V51.6798L269.5 50.9142Z" />
            <path d="M271.031 91.1093L272.358 91.8749V93.4061L271.031 94.1718L269.705 93.4061V91.8749L271.031 91.1093Z" />
            <path d="M271.031 26.7974L272.358 27.563V29.0942L271.031 29.8599L269.705 29.0942V27.563L271.031 26.7974Z" />
            <path d="M310.844 26.7974L312.17 27.563V29.0942L310.844 29.8599L309.518 29.0942V27.563L310.844 26.7974Z" />
            <path d="M272.562 66.9913L273.888 67.757V69.2882L272.562 70.0538L271.236 69.2882V67.757L272.562 66.9913Z" />
            <path d="M271.031 64.3124L272.358 65.078V66.6093L271.031 67.3749L269.705 66.6093V65.078L271.031 64.3124Z" />
            <path d="M283.281 64.3124L284.608 65.078V66.6093L283.281 67.3749L281.955 66.6093V65.078L283.281 64.3124Z" />
            <path d="M286.344 64.3124L287.67 65.078V66.6093L286.344 67.3749L285.017 66.6093V65.078L286.344 64.3124Z" />
            <path d="M243.469 53.5938L244.795 54.3594V55.8906L243.469 56.6562L242.143 55.8906V54.3594L243.469 53.5938Z" />
            <path d="M245 93.7893L246.326 94.5549V96.0862L245 96.8518L243.674 96.0862V94.5549L245 93.7893Z" />
            <path d="M292.468 53.5938L293.795 54.3594V55.8906L292.468 56.6562L291.142 55.8906V54.3594L292.468 53.5938Z" />
            <path d="M294 29.4764L295.326 30.2421V31.7733L294 32.5389L292.673 31.7733V30.2421L294 29.4764Z" />
            <path d="M220.5 45.554L221.826 46.3196V47.8508L220.5 48.6165L219.174 47.8508V46.3196L220.5 45.554Z" />
            <path d="M269.5 45.554L270.826 46.3196V47.8508L269.5 48.6165L268.174 47.8508V46.3196L269.5 45.554Z" />
            <path d="M309.312 45.554L310.638 46.3196V47.8508L309.312 48.6165L307.986 47.8508V46.3196L309.312 45.554Z" />
            <path d="M271.031 85.7496L272.358 86.5153V88.0465L271.031 88.8121L269.705 88.0465V86.5153L271.031 85.7496Z" />
            <path d="M271.031 21.4375L272.358 22.2031V23.7344L271.031 24.5L269.705 23.7344V22.2031L271.031 21.4375Z" />
            <path d="M243.469 48.2341L244.795 48.9998V50.531L243.469 51.2966L242.143 50.531V48.9998L243.469 48.2341Z" />
            <path d="M245 88.4297L246.326 89.1953V90.7266L245 91.4922L243.674 90.7266V89.1953L245 88.4297Z" />
            <path d="M226.625 88.4297L227.951 89.1953V90.7266L226.625 91.4922L225.299 90.7266V89.1953L226.625 88.4297Z" />
            <path d="M292.468 48.2341L293.795 48.9998V50.531L292.468 51.2966L291.142 50.531V48.9998L292.468 48.2341Z" />
            <path d="M294 24.1174L295.326 24.8831V26.4143L294 27.1799L292.673 26.4143V24.8831L294 24.1174Z" />
            <path d="M218.969 42.8746L220.295 43.6403V45.1715L218.969 45.9371L217.643 45.1715V43.6403L218.969 42.8746Z" />
            <path d="M267.969 42.8746L269.295 43.6403V45.1715L267.969 45.9371L266.643 45.1715V43.6403L267.969 42.8746Z" />
            <path d="M307.781 42.8746L309.107 43.6403V45.1715L307.781 45.9371L306.455 45.1715V43.6403L307.781 42.8746Z" />
            <path d="M269.5 83.0702L270.826 83.8358V85.3671L269.5 86.1327L268.174 85.3671V83.8358L269.5 83.0702Z" />
            <path d="M241.937 45.554L243.263 46.3196V47.8508L241.937 48.6165L240.611 47.8508V46.3196L241.937 45.554Z" />
            <path d="M243.468 85.7496L244.794 86.5153V88.0465L243.468 88.8121L242.142 88.0465V86.5153L243.468 85.7496Z" />
            <path d="M225.094 85.7496L226.42 86.5153V88.0465L225.094 88.8121L223.768 88.0465V86.5153L225.094 85.7496Z" />
            <path d="M290.937 45.554L292.264 46.3196V47.8508L290.937 48.6165L289.611 47.8508V46.3196L290.937 45.554Z" />
            <path d="M240.406 37.5156L241.732 38.2812V39.8125L240.406 40.5781L239.08 39.8125V38.2812L240.406 37.5156Z" />
            <path d="M241.937 77.7106L243.263 78.4762V80.0074L241.937 80.7731L240.611 80.0074V78.4762L241.937 77.7106Z" />
            <path d="M223.562 77.7106L224.888 78.4762V80.0074L223.562 80.7731L222.236 80.0074V78.4762L223.562 77.7106Z" />
            <path d="M289.406 37.5156L290.732 38.2812V39.8125L289.406 40.5781L288.08 39.8125V38.2812L289.406 37.5156Z" />
            <path d="M329.219 37.5156L330.545 38.2812V39.8125L329.219 40.5781L327.893 39.8125V38.2812L329.219 37.5156Z" />
            <path d="M295.531 37.5156L296.857 38.2812V39.8125L295.531 40.5781L294.205 39.8125V38.2812L295.531 37.5156Z" />
            <path d="M335.344 37.5156L336.67 38.2812V39.8125L335.344 40.5781L334.018 39.8125V38.2812L335.344 37.5156Z" />
            <path d="M220.5 40.1957L221.826 40.9613V42.4926L220.5 43.2582L219.174 42.4926V40.9613L220.5 40.1957Z" />
            <path d="M269.5 40.1957L270.826 40.9613V42.4926L269.5 43.2582L268.174 42.4926V40.9613L269.5 40.1957Z" />
            <path d="M309.312 40.1957L310.638 40.9613V42.4926L309.312 43.2582L307.986 42.4926V40.9613L309.312 40.1957Z" />
            <path d="M271.031 80.3909L272.358 81.1565V82.6877L271.031 83.4534L269.705 82.6877V81.1565L271.031 80.3909Z" />
            <path d="M243.469 42.8746L244.795 43.6403V45.1715L243.469 45.9371L242.143 45.1715V43.6403L243.469 42.8746Z" />
            <path d="M245 83.0702L246.326 83.8358V85.3671L245 86.1327L243.674 85.3671V83.8358L245 83.0702Z" />
            <path d="M226.625 83.0702L227.951 83.8358V85.3671L226.625 86.1327L225.299 85.3671V83.8358L226.625 83.0702Z" />
            <path d="M292.468 42.8746L293.795 43.6403V45.1715L292.468 45.9371L291.142 45.1715V43.6403L292.468 42.8746Z" />
            <path d="M241.937 34.8361L243.263 35.6017V37.1329L241.937 37.8986L240.611 37.1329V35.6017L241.937 34.8361Z" />
            <path d="M243.469 75.0316L244.795 75.7972V77.3285L243.469 78.0941L242.143 77.3285V75.7972L243.469 75.0316Z" />
            <path d="M290.937 34.8361L292.264 35.6017V37.1329L290.937 37.8986L289.611 37.1329V35.6017L290.937 34.8361Z" />
            <path d="M330.75 34.8361L332.076 35.6017V37.1329L330.75 37.8986L329.424 37.1329V35.6017L330.75 34.8361Z" />
            <path d="M297.062 34.8361L298.388 35.6017V37.1329L297.062 37.8986L295.736 37.1329V35.6017L297.062 34.8361Z" />
            <path d="M336.875 34.8361L338.201 35.6017V37.1329L336.875 37.8986L335.549 37.1329V35.6017L336.875 34.8361Z" />
            <path d="M241.937 29.4764L243.263 30.2421V31.7733L241.937 32.5389L240.611 31.7733V30.2421L241.937 29.4764Z" />
            <path d="M243.469 69.672L244.795 70.4376V71.9689L243.469 72.7345L242.143 71.9689V70.4376L243.469 69.672Z" />
            <path d="M222.031 37.5156L223.357 38.2812V39.8125L222.031 40.5781L220.705 39.8125V38.2812L222.031 37.5156Z" />
            <path d="M271.031 37.5156L272.358 38.2812V39.8125L271.031 40.5781L269.705 39.8125V38.2812L271.031 37.5156Z" />
            <path d="M310.844 37.5156L312.17 38.2812V39.8125L310.844 40.5781L309.518 39.8125V38.2812L310.844 37.5156Z" />
            <path d="M272.562 77.7106L273.888 78.4762V80.0074L272.562 80.7731L271.236 80.0074V78.4762L272.562 77.7106Z" />
            <path d="M245 40.1957L246.326 40.9613V42.4926L245 43.2582L243.674 42.4926V40.9613L245 40.1957Z" />
            <path d="M246.531 80.3909L247.857 81.1565V82.6877L246.531 83.4534L245.205 82.6877V81.1565L246.531 80.3909Z" />
            <path d="M228.156 80.3909L229.482 81.1565V82.6877L228.156 83.4534L226.83 82.6877V81.1565L228.156 80.3909Z" />
            <path d="M294 40.1957L295.326 40.9613V42.4926L294 43.2582L292.673 42.4926V40.9613L294 40.1957Z" />
            <path d="M295.531 16.0773L296.857 16.8429V18.3741L295.531 19.1398L294.205 18.3741V16.8429L295.531 16.0773Z" />
            <path d="M243.469 32.1564L244.795 32.922V34.4532L243.469 35.2189L242.143 34.4532V32.922L243.469 32.1564Z" />
            <path d="M245 72.351L246.326 73.1166V74.6478L245 75.4135L243.674 74.6478V73.1166L245 72.351Z" />
            <path d="M243.469 26.7974L244.795 27.563V29.0942L243.469 29.8599L242.143 29.0942V27.563L243.469 26.7974Z" />
            <path d="M245 66.9913L246.326 67.757V69.2882L245 70.0538L243.674 69.2882V67.757L245 66.9913Z" />
            <path d="M194.469 85.7496L195.795 86.5153V88.0465L194.469 88.8121L193.143 88.0465V86.5153L194.469 85.7496Z" />
            <path d="M197.532 85.7496L198.858 86.5153V88.0465L197.532 88.8121L196.205 88.0465V86.5153L197.532 85.7496Z" />
            <path d="M200.594 85.7496L201.92 86.5153V88.0465L200.594 88.8121L199.268 88.0465V86.5153L200.594 85.7496Z" />
            <path d="M203.656 85.7496L204.982 86.5153V88.0465L203.656 88.8121L202.33 88.0465V86.5153L203.656 85.7496Z" />
            <path d="M205.188 88.4297L206.514 89.1953V90.7266L205.188 91.4922L203.862 90.7266V89.1953L205.188 88.4297Z" />
            <path d="M202.125 88.4297L203.451 89.1953V90.7266L202.125 91.4922L200.799 90.7266V89.1953L202.125 88.4297Z" />
            <path d="M196 83.0702L197.326 83.8358V85.3671L196 86.1327L194.674 85.3671V83.8358L196 83.0702Z" />
            <path d="M202.125 83.0702L203.451 83.8358V85.3671L202.125 86.1327L200.799 85.3671V83.8358L202.125 83.0702Z" />
            <path d="M197.532 75.0316L198.858 75.7972V77.3285L197.532 78.0941L196.205 77.3285V75.7972L197.532 75.0316Z" />
            <path d="M199.063 72.351L200.389 73.1166V74.6478L199.063 75.4135L197.737 74.6478V73.1166L199.063 72.351Z" />
            <path d="M202.125 72.351L203.451 73.1166V74.6478L202.125 75.4135L200.799 74.6478V73.1166L202.125 72.351Z" />
            <path d="M205.188 72.351L206.514 73.1166V74.6478L205.188 75.4135L203.862 74.6478V73.1166L205.188 72.351Z" />
            <path d="M208.25 72.351L209.576 73.1166V74.6478L208.25 75.4135L206.924 74.6478V73.1166L208.25 72.351Z" />
            <path d="M200.594 75.0316L201.92 75.7972V77.3285L200.594 78.0941L199.268 77.3285V75.7972L200.594 75.0316Z" />
            <path d="M205.188 83.0702L206.514 83.8358V85.3671L205.188 86.1327L203.862 85.3671V83.8358L205.188 83.0702Z" />
            <path d="M212.844 85.7496L214.17 86.5153V88.0465L212.844 88.8121L211.518 88.0465V86.5153L212.844 85.7496Z" />
            <path d="M212.844 80.3909L214.17 81.1565V82.6877L212.844 83.4534L211.518 82.6877V81.1565L212.844 80.3909Z" />
            <path d="M211.313 77.7106L212.639 78.4762V80.0074L211.313 80.7731L209.987 80.0074V78.4762L211.313 77.7106Z" />
            <path d="M209.781 80.3909L211.107 81.1565V82.6877L209.781 83.4534L208.455 82.6877V81.1565L209.781 80.3909Z" />
            <path d="M208.25 83.0702L209.576 83.8358V85.3671L208.25 86.1327L206.924 85.3671V83.8358L208.25 83.0702Z" />
            <path d="M211.313 83.0702L212.639 83.8358V85.3671L211.313 86.1327L209.987 85.3671V83.8358L211.313 83.0702Z" />
            <path d="M209.781 85.7496L211.107 86.5153V88.0465L209.781 88.8121L208.455 88.0465V86.5153L209.781 85.7496Z" />
            <path d="M206.719 85.7496L208.045 86.5153V88.0465L206.719 88.8121L205.393 88.0465V86.5153L206.719 85.7496Z" />
            <path d="M208.25 88.4297L209.576 89.1953V90.7266L208.25 91.4922L206.924 90.7266V89.1953L208.25 88.4297Z" />
            <path d="M209.781 91.1093L211.107 91.8749V93.4061L209.781 94.1718L208.455 93.4061V91.8749L209.781 91.1093Z" />
            <path d="M206.719 91.1093L208.045 91.8749V93.4061L206.719 94.1718L205.393 93.4061V91.8749L206.719 91.1093Z" />
            <path d="M203.656 91.1093L204.982 91.8749V93.4061L203.656 94.1718L202.33 93.4061V91.8749L203.656 91.1093Z" />
            <path d="M200.594 91.1093L201.92 91.8749V93.4061L200.594 94.1718L199.268 93.4061V91.8749L200.594 91.1093Z" />
            <path d="M199.063 88.4297L200.389 89.1953V90.7266L199.063 91.4922L197.737 90.7266V89.1953L199.063 88.4297Z" />
            <path d="M196 88.4297L197.326 89.1953V90.7266L196 91.4922L194.674 90.7266V89.1953L196 88.4297Z" />
            <path d="M214.375 83.0702L215.701 83.8358V85.3671L214.375 86.1327L213.049 85.3671V83.8358L214.375 83.0702Z" />
            <path d="M214.375 77.7106L215.701 78.4762V80.0074L214.375 80.7731L213.049 80.0074V78.4762L214.375 77.7106Z" />
            <path d="M214.375 88.4297L215.701 89.1953V90.7266L214.375 91.4922L213.049 90.7266V89.1953L214.375 88.4297Z" />
            <path d="M206.719 96.4688L208.045 97.2344V98.7656L206.719 99.5312L205.393 98.7656V97.2344L206.719 96.4688Z" />
            <path d="M208.25 93.7893L209.576 94.5549V96.0862L208.25 96.8518L206.924 96.0862V94.5549L208.25 93.7893Z" />
            <path d="M202.125 93.7893L203.451 94.5549V96.0862L202.125 96.8518L200.799 96.0862V94.5549L202.125 93.7893Z" />
            <path d="M205.188 93.7893L206.514 94.5549V96.0862L205.188 96.8518L203.862 96.0862V94.5549L205.188 93.7893Z" />
            <path d="M203.656 96.4688L204.982 97.2344V98.7656L203.656 99.5312L202.33 98.7656V97.2344L203.656 96.4688Z" />
            <path d="M199.063 83.0702L200.389 83.8358V85.3671L199.063 86.1327L197.737 85.3671V83.8358L199.063 83.0702Z" />
            <path d="M197.532 80.3909L198.858 81.1565V82.6877L197.532 83.4534L196.205 82.6877V81.1565L197.532 80.3909Z" />
            <path d="M211.313 104.507L212.639 105.273V106.804L211.313 107.57L209.987 106.804V105.273L211.313 104.507Z" />
            <path d="M203.656 80.3909L204.982 81.1565V82.6877L203.656 83.4534L202.33 82.6877V81.1565L203.656 80.3909Z" />
            <path d="M205.188 77.7106L206.514 78.4762V80.0074L205.188 80.7731L203.862 80.0074V78.4762L205.188 77.7106Z" />
            <path d="M206.719 75.0316L208.045 75.7972V77.3285L206.719 78.0941L205.393 77.3285V75.7972L206.719 75.0316Z" />
            <path d="M208.25 104.507L209.576 105.273V106.804L208.25 107.57L206.924 106.804V105.273L208.25 104.507Z" />
            <path d="M200.594 80.3909L201.92 81.1565V82.6877L200.594 83.4534L199.268 82.6877V81.1565L200.594 80.3909Z" />
            <path d="M196 72.351L197.326 73.1166V74.6478L196 75.4135L194.674 74.6478V73.1166L196 72.351Z" />
            <path d="M202.125 77.7106L203.451 78.4762V80.0074L202.125 80.7731L200.799 80.0074V78.4762L202.125 77.7106Z" />
            <path d="M214.375 99.1482L215.701 99.9138V101.445L214.375 102.211L213.049 101.445V99.9138L214.375 99.1482Z" />
            <path d="M203.656 75.0316L204.982 75.7972V77.3285L203.656 78.0941L202.33 77.3285V75.7972L203.656 75.0316Z" />
            <path d="M206.719 80.3909L208.045 81.1565V82.6877L206.719 83.4534L205.393 82.6877V81.1565L206.719 80.3909Z" />
            <path d="M208.25 77.7106L209.576 78.4762V80.0074L208.25 80.7731L206.924 80.0074V78.4762L208.25 77.7106Z" />
            <path d="M196 77.7106L197.326 78.4762V80.0074L196 80.7731L194.674 80.0074V78.4762L196 77.7106Z" />
            <path d="M209.781 101.828L211.107 102.594V104.125L209.781 104.891L208.455 104.125V102.594L209.781 101.828Z" />
            <path d="M208.25 99.1482L209.576 99.9138V101.445L208.25 102.211L206.924 101.445V99.9138L208.25 99.1482Z" />
            <path d="M209.781 96.4688L211.107 97.2344V98.7656L209.781 99.5312L208.455 98.7656V97.2344L209.781 96.4688Z" />
            <path d="M196 93.7893L197.326 94.5549V96.0862L196 96.8518L194.674 96.0862V94.5549L196 93.7893Z" />
            <path d="M211.313 93.7893L212.639 94.5549V96.0862L211.313 96.8518L209.987 96.0862V94.5549L211.313 93.7893Z" />
            <path d="M206.719 101.828L208.045 102.594V104.125L206.719 104.891L205.393 104.125V102.594L206.719 101.828Z" />
            <path d="M205.188 99.1482L206.514 99.9138V101.445L205.188 102.211L203.862 101.445V99.9138L205.188 99.1482Z" />
            <path d="M199.063 77.7106L200.389 78.4762V80.0074L199.063 80.7731L197.737 80.0074V78.4762L199.063 77.7106Z" />
            <path d="M212.844 101.828L214.17 102.594V104.125L212.844 104.891L211.518 104.125V102.594L212.844 101.828Z" />
            <path d="M211.313 99.1482L212.639 99.9138V101.445L211.313 102.211L209.987 101.445V99.9138L211.313 99.1482Z" />
            <path d="M212.844 96.4688L214.17 97.2344V98.7656L212.844 99.5312L211.518 98.7656V97.2344L212.844 96.4688Z" />
            <path d="M191.407 75.0316L192.733 75.7972V77.3285L191.407 78.0941L190.08 77.3285V75.7972L191.407 75.0316Z" />
            <path d="M188.344 75.0316L189.67 75.7972V77.3285L188.344 78.0941L187.018 77.3285V75.7972L188.344 75.0316Z" />
            <path d="M194.469 75.0316L195.795 75.7972V77.3285L194.469 78.0941L193.143 77.3285V75.7972L194.469 75.0316Z" />
            <path d="M192.938 72.351L194.264 73.1166V74.6478L192.938 75.4135L191.612 74.6478V73.1166L192.938 72.351Z" />
            <path d="M189.875 72.351L191.201 73.1166V74.6478L189.875 75.4135L188.549 74.6478V73.1166L189.875 72.351Z" />
            <path d="M189.875 77.7106L191.201 78.4762V80.0074L189.875 80.7731L188.549 80.0074V78.4762L189.875 77.7106Z" />
            <path d="M186.812 77.7106L188.138 78.4762V80.0074L186.812 80.7731L185.486 80.0074V78.4762L186.812 77.7106Z" />
            <path d="M192.938 77.7106L194.264 78.4762V80.0074L192.938 80.7731L191.612 80.0074V78.4762L192.938 77.7106Z" />
          </g>
          <g
            id="Africa"
            fill={highlight.includes('Africa') ? highlightColor : defaultColor}
            onClick={() => {
              onClick('Africa');
            }}
          >
            <path d="M174.562 88.4298L175.889 89.1954V90.7267L174.562 91.4923L173.236 90.7267V89.1954L174.562 88.4298Z" />
            <path d="M177.625 88.4298L178.951 89.1954V90.7267L177.625 91.4923L176.299 90.7267V89.1954L177.625 88.4298Z" />
            <path d="M180.687 88.4298L182.013 89.1954V90.7267L180.687 91.4923L179.361 90.7267V89.1954L180.687 88.4298Z" />
            <path d="M183.75 88.4298L185.076 89.1954V90.7267L183.75 91.4923L182.424 90.7267V89.1954L183.75 88.4298Z" />
            <path d="M186.812 88.4298L188.138 89.1954V90.7267L186.812 91.4923L185.486 90.7267V89.1954L186.812 88.4298Z" />
            <path d="M188.344 85.7496L189.67 86.5153V88.0465L188.344 88.8121L187.018 88.0465V86.5153L188.344 85.7496Z" />
            <path d="M191.407 85.7496L192.733 86.5153V88.0465L191.407 88.8121L190.08 88.0465V86.5153L191.407 85.7496Z" />
            <path d="M174.562 120.586L175.889 121.351V122.883L174.562 123.648L173.236 122.883V121.351L174.562 120.586Z" />
            <path d="M197.532 117.906L198.858 118.672V120.203L197.532 120.969L196.205 120.203V118.672L197.532 117.906Z" />
            <path d="M182.219 117.906L183.545 118.672V120.203L182.219 120.969L180.893 120.203V118.672L182.219 117.906Z" />
            <path d="M205.188 115.227L206.514 115.993V117.524L205.188 118.29L203.862 117.524V115.993L205.188 115.227Z" />
            <path d="M206.719 112.546L208.045 113.312V114.843L206.719 115.609L205.393 114.843V113.312L206.719 112.546Z" />
            <path d="M182.219 112.546L183.545 113.312V114.843L182.219 115.609L180.893 114.843V113.312L182.219 112.546Z" />
            <path d="M185.281 128.625L186.607 129.391V130.922L185.281 131.688L183.955 130.922V129.391L185.281 128.625Z" />
            <path d="M156.187 115.227L157.513 115.993V117.524L156.187 118.29L154.861 117.524V115.993L156.187 115.227Z" />
            <path d="M157.719 91.1093L159.045 91.8749V93.4061L157.719 94.1718L156.392 93.4061V91.8749L157.719 91.1093Z" />
            <path d="M157.719 85.7496L159.045 86.5153V88.0465L157.719 88.8121L156.392 88.0465V86.5153L157.719 85.7496Z" />
            <path d="M182.219 107.187L183.545 107.953V109.484L182.219 110.249L180.893 109.484V107.953L182.219 107.187Z" />
            <path d="M183.75 147.383L185.076 148.149V149.68L183.75 150.445L182.424 149.68V148.149L183.75 147.383Z" />
            <path d="M156.187 109.867L157.513 110.633V112.164L156.187 112.93L154.861 112.164V110.633L156.187 109.867Z" />
            <path d="M208.25 120.586L209.576 121.351V122.883L208.25 123.648L206.924 122.883V121.351L208.25 120.586Z" />
            <path d="M206.719 117.906L208.045 118.672V120.203L206.719 120.969L205.393 120.203V118.672L206.719 117.906Z" />
            <path d="M206.719 123.266L208.045 124.031V125.562L206.719 126.328L205.393 125.562V124.031L206.719 123.266Z" />
            <path d="M157.719 107.187L159.045 107.953V109.484L157.719 110.249L156.392 109.484V107.953L157.719 107.187Z" />
            <path d="M208.25 142.024L209.576 142.789V144.321L208.25 145.086L206.924 144.321V142.789L208.25 142.024Z" />
            <path d="M157.719 112.546L159.045 113.312V114.843L157.719 115.609L156.392 114.843V113.312L157.719 112.546Z" />
            <path d="M159.25 109.867L160.576 110.633V112.164L159.25 112.93L157.923 112.164V110.633L159.25 109.867Z" />
            <path d="M209.781 117.906L211.107 118.672V120.203L209.781 120.969L208.455 120.203V118.672L209.781 117.906Z" />
            <path d="M185.281 85.7496L186.607 86.5153V88.0465L185.281 88.8121L183.955 88.0465V86.5153L185.281 85.7496Z" />
            <path d="M182.219 85.7496L183.545 86.5153V88.0465L182.219 88.8121L180.893 88.0465V86.5153L182.219 85.7496Z" />
            <path d="M179.156 85.7496L180.482 86.5153V88.0465L179.156 88.8121L177.83 88.0465V86.5153L179.156 85.7496Z" />
            <path d="M176.094 85.7496L177.42 86.5153V88.0465L176.094 88.8121L174.768 88.0465V86.5153L176.094 85.7496Z" />
            <path d="M173.031 85.7496L174.357 86.5153V88.0465L173.031 88.8121L171.705 88.0465V86.5153L173.031 85.7496Z" />
            <path d="M171.5 83.0702L172.826 83.8358V85.3671L171.5 86.1327L170.174 85.3671V83.8358L171.5 83.0702Z" />
            <path d="M183.75 83.0702L185.076 83.8358V85.3671L183.75 86.1327L182.424 85.3671V83.8358L183.75 83.0702Z" />
            <path d="M160.781 96.4689L162.107 97.2345V98.7657L160.781 99.5314L159.455 98.7657V97.2345L160.781 96.4689Z" />
            <path d="M183.75 99.1482L185.076 99.9138V101.445L183.75 102.211L182.424 101.445V99.9138L183.75 99.1482Z" />
            <path d="M185.281 139.344L186.607 140.11V141.641L185.281 142.406L183.955 141.641V140.11L185.281 139.344Z" />
            <path d="M186.812 158.102L188.138 158.867V160.398L186.812 161.164L185.486 160.398V158.867L186.812 158.102Z" />
            <path d="M162.312 99.1482L163.638 99.9138V101.445L162.312 102.211L160.986 101.445V99.9138L162.312 99.1482Z" />
            <path d="M185.281 101.828L186.607 102.594V104.125L185.281 104.891L183.955 104.125V102.594L185.281 101.828Z" />
            <path d="M186.812 142.024L188.138 142.789V144.321L186.812 145.086L185.486 144.321V142.789L186.812 142.024Z" />
            <path d="M188.344 160.781L189.67 161.547V163.078L188.344 163.844L187.018 163.078V161.547L188.344 160.781Z" />
            <path d="M183.75 93.7893L185.076 94.5549V96.0862L183.75 96.8518L182.424 96.0862V94.5549L183.75 93.7893Z" />
            <path d="M185.281 133.985L186.607 134.75V136.282L185.281 137.047L183.955 136.282V134.75L185.281 133.985Z" />
            <path d="M186.812 152.742L188.138 153.508V155.039L186.812 155.805L185.486 155.039V153.508L186.812 152.742Z" />
            <path d="M163.844 101.828L165.17 102.594V104.125L163.844 104.891L162.517 104.125V102.594L163.844 101.828Z" />
            <path d="M186.812 104.507L188.138 105.273V106.804L186.812 107.57L185.486 106.804V105.273L186.812 104.507Z" />
            <path d="M188.344 144.703L189.67 145.469V147L188.344 147.766L187.018 147V145.469L188.344 144.703Z" />
            <path d="M189.875 163.461L191.201 164.226V165.758L189.875 166.523L188.549 165.758V164.226L189.875 163.461Z" />
            <path d="M185.281 96.4689L186.607 97.2345V98.7657L185.281 99.5314L183.955 98.7657V97.2345L185.281 96.4689Z" />
            <path d="M186.812 136.664L188.138 137.43V138.961L186.812 139.727L185.486 138.961V137.43L186.812 136.664Z" />
            <path d="M188.344 155.421L189.67 156.187V157.718L188.344 158.484L187.018 157.718V156.187L188.344 155.421Z" />
            <path d="M211.313 115.227L212.639 115.993V117.524L211.313 118.29L209.987 117.524V115.993L211.313 115.227Z" />
            <path d="M212.844 112.546L214.17 113.312V114.843L212.844 115.609L211.518 114.843V113.312L212.844 112.546Z" />
            <path d="M211.313 109.867L212.639 110.633V112.164L211.313 112.93L209.987 112.164V110.633L211.313 109.867Z" />
            <path d="M168.438 83.0702L169.764 83.8358V85.3671L168.438 86.1327L167.112 85.3671V83.8358L168.438 83.0702Z" />
            <path d="M174.562 83.0702L175.889 83.8358V85.3671L174.562 86.1327L173.236 85.3671V83.8358L174.562 83.0702Z" />
            <path d="M157.719 101.828L159.045 102.594V104.125L157.719 104.891L156.392 104.125V102.594L157.719 101.828Z" />
            <path d="M156.187 104.507L157.513 105.273V106.804L156.187 107.57L154.861 106.804V105.273L156.187 104.507Z" />
            <path d="M159.25 104.507L160.576 105.273V106.804L159.25 107.57L157.923 106.804V105.273L159.25 104.507Z" />
            <path d="M209.781 139.344L211.107 140.11V141.641L209.781 142.406L208.455 141.641V140.11L209.781 139.344Z" />
            <path d="M159.25 93.7893L160.576 94.5549V96.0862L159.25 96.8518L157.923 96.0862V94.5549L159.25 93.7893Z" />
            <path d="M159.25 88.4298L160.576 89.1954V90.7267L159.25 91.4923L157.923 90.7267V89.1954L159.25 88.4298Z" />
            <path d="M160.781 107.187L162.107 107.953V109.484L160.781 110.249L159.455 109.484V107.953L160.781 107.187Z" />
            <path d="M211.313 142.024L212.639 142.789V144.321L211.313 145.086L209.987 144.321V142.789L211.313 142.024Z" />
            <path d="M183.75 109.867L185.076 110.633V112.164L183.75 112.93L182.424 112.164V110.633L183.75 109.867Z" />
            <path d="M186.812 125.945L188.138 126.711V128.242L186.812 129.008L185.486 128.242V126.711L186.812 125.945Z" />
            <path d="M160.781 112.546L162.107 113.312V114.843L160.781 115.609L159.455 114.843V113.312L160.781 112.546Z" />
            <path d="M194.469 150.062L195.795 150.828V152.359L194.469 153.125L193.143 152.359V150.828L194.469 150.062Z" />
            <path d="M183.75 115.227L185.076 115.993V117.524L183.75 118.29L182.424 117.524V115.993L183.75 115.227Z" />
            <path d="M185.281 91.1093L186.607 91.8749V93.4061L185.281 94.1718L183.955 93.4061V91.8749L185.281 91.1093Z" />
            <path d="M186.812 131.305L188.138 132.071V133.602L186.812 134.368L185.486 133.602V132.071L186.812 131.305Z" />
            <path d="M188.344 150.062L189.67 150.828V152.359L188.344 153.125L187.018 152.359V150.828L188.344 150.062Z" />
            <path d="M162.312 109.867L163.638 110.633V112.164L162.312 112.93L160.986 112.164V110.633L162.312 109.867Z" />
            <path d="M196 147.383L197.326 148.149V149.68L196 150.445L194.674 149.68V148.149L196 147.383Z" />
            <path d="M194.469 144.703L195.795 145.469V147L194.469 147.766L193.143 147V145.469L194.469 144.703Z" />
            <path d="M185.281 112.546L186.607 113.312V114.843L185.281 115.609L183.955 114.843V113.312L185.281 112.546Z" />
            <path d="M188.344 128.625L189.67 129.391V130.922L188.344 131.688L187.018 130.922V129.391L188.344 128.625Z" />
            <path d="M165.375 115.227L166.701 115.993V117.524L165.375 118.29L164.049 117.524V115.993L165.375 115.227Z" />
            <path d="M166.906 91.1093L168.232 91.8749V93.4061L166.906 94.1718L165.58 93.4061V91.8749L166.906 91.1093Z" />
            <path d="M188.344 117.906L189.67 118.672V120.203L188.344 120.969L187.018 120.203V118.672L188.344 117.906Z" />
            <path d="M165.375 109.867L166.701 110.633V112.164L165.375 112.93L164.049 112.164V110.633L165.375 109.867Z" />
            <path d="M197.532 144.703L198.858 145.469V147L197.532 147.766L196.205 147V145.469L197.532 144.703Z" />
            <path d="M188.344 112.546L189.67 113.312V114.843L188.344 115.609L187.018 114.843V113.312L188.344 112.546Z" />
            <path d="M191.407 128.625L192.733 129.391V130.922L191.407 131.688L190.08 130.922V129.391L191.407 128.625Z" />
            <path d="M163.844 96.4689L165.17 97.2345V98.7657L163.844 99.5314L162.517 98.7657V97.2345L163.844 96.4689Z" />
            <path d="M192.938 88.4298L194.264 89.1954V90.7267L192.938 91.4923L191.612 90.7267V89.1954L192.938 88.4298Z" />
            <path d="M189.875 88.4298L191.201 89.1954V90.7267L189.875 91.4923L188.549 90.7267V89.1954L189.875 88.4298Z" />
            <path d="M186.812 99.1482L188.138 99.9138V101.445L186.812 102.211L185.486 101.445V99.9138L186.812 99.1482Z" />
            <path d="M188.344 139.344L189.67 140.11V141.641L188.344 142.406L187.018 141.641V140.11L188.344 139.344Z" />
            <path d="M189.875 158.102L191.201 158.867V160.398L189.875 161.164L188.549 160.398V158.867L189.875 158.102Z" />
            <path d="M165.375 99.1482L166.701 99.9138V101.445L165.375 102.211L164.049 101.445V99.9138L165.375 99.1482Z" />
            <path d="M188.344 101.828L189.67 102.594V104.125L188.344 104.891L187.018 104.125V102.594L188.344 101.828Z" />
            <path d="M189.875 142.024L191.201 142.789V144.321L189.875 145.086L188.549 144.321V142.789L189.875 142.024Z" />
            <path d="M191.407 160.781L192.733 161.547V163.078L191.407 163.844L190.08 163.078V161.547L191.407 160.781Z" />
            <path d="M186.812 93.7893L188.138 94.5549V96.0862L186.812 96.8518L185.486 96.0862V94.5549L186.812 93.7893Z" />
            <path d="M188.344 133.985L189.67 134.75V136.282L188.344 137.047L187.018 136.282V134.75L188.344 133.985Z" />
            <path d="M189.875 152.742L191.201 153.508V155.039L189.875 155.805L188.549 155.039V153.508L189.875 152.742Z" />
            <path d="M166.906 101.828L168.232 102.594V104.125L166.906 104.891L165.58 104.125V102.594L166.906 101.828Z" />
            <path d="M206.719 144.703L208.045 145.469V147L206.719 147.766L205.393 147V145.469L206.719 144.703Z" />
            <path d="M208.25 147.383L209.576 148.149V149.68L208.25 150.445L206.924 149.68V148.149L208.25 147.383Z" />
            <path d="M206.719 150.062L208.045 150.828V152.359L206.719 153.125L205.393 152.359V150.828L206.719 150.062Z" />
            <path d="M208.25 152.742L209.576 153.508V155.039L208.25 155.805L206.924 155.039V153.508L208.25 152.742Z" />
            <path d="M189.875 104.507L191.201 105.273V106.804L189.875 107.57L188.549 106.804V105.273L189.875 104.507Z" />
            <path d="M191.407 144.703L192.733 145.469V147L191.407 147.766L190.08 147V145.469L191.407 144.703Z" />
            <path d="M188.344 96.4689L189.67 97.2345V98.7657L188.344 99.5314L187.018 98.7657V97.2345L188.344 96.4689Z" />
            <path d="M189.875 136.664L191.201 137.43V138.961L189.875 139.727L188.549 138.961V137.43L189.875 136.664Z" />
            <path d="M191.407 155.421L192.733 156.187V157.718L191.407 158.484L190.08 157.718V156.187L191.407 155.421Z" />
            <path d="M157.719 96.4689L159.045 97.2345V98.7657L157.719 99.5314L156.392 98.7657V97.2345L157.719 96.4689Z" />
            <path d="M159.25 99.1482L160.576 99.9138V101.445L159.25 102.211L157.923 101.445V99.9138L159.25 99.1482Z" />
            <path d="M182.219 101.828L183.545 102.594V104.125L182.219 104.891L180.893 104.125V102.594L182.219 101.828Z" />
            <path d="M183.75 142.024L185.076 142.789V144.321L183.75 145.086L182.424 144.321V142.789L183.75 142.024Z" />
            <path d="M185.281 160.781L186.607 161.547V163.078L185.281 163.844L183.955 163.078V161.547L185.281 160.781Z" />
            <path d="M160.781 101.828L162.107 102.594V104.125L160.781 104.891L159.455 104.125V102.594L160.781 101.828Z" />
            <path d="M211.313 136.664L212.639 137.43V138.961L211.313 139.727L209.987 138.961V137.43L211.313 136.664Z" />
            <path d="M183.75 104.507L185.076 105.273V106.804L183.75 107.57L182.424 106.804V105.273L183.75 104.507Z" />
            <path d="M185.281 144.703L186.607 145.469V147L185.281 147.766L183.955 147V145.469L185.281 144.703Z" />
            <path d="M186.812 163.461L188.138 164.226V165.758L186.812 166.523L185.486 165.758V164.226L186.812 163.461Z" />
            <path d="M162.312 104.507L163.638 105.273V106.804L162.312 107.57L160.986 106.804V105.273L162.312 104.507Z" />
            <path d="M212.844 139.344L214.17 140.11V141.641L212.844 142.406L211.518 141.641V140.11L212.844 139.344Z" />
            <path d="M185.281 107.187L186.607 107.953V109.484L185.281 110.249L183.955 109.484V107.953L185.281 107.187Z" />
            <path d="M186.812 147.383L188.138 148.149V149.68L186.812 150.445L185.486 149.68V148.149L186.812 147.383Z" />
            <path d="M162.312 93.7893L163.638 94.5549V96.0862L162.312 96.8518L160.986 96.0862V94.5549L162.312 93.7893Z" />
            <path d="M162.312 88.4298L163.638 89.1954V90.7267L162.312 91.4923L160.986 90.7267V89.1954L162.312 88.4298Z" />
            <path d="M194.469 155.421L195.795 156.187V157.718L194.469 158.484L193.143 157.718V156.187L194.469 155.421Z" />
            <path d="M163.844 107.187L165.17 107.953V109.484L163.844 110.249L162.517 109.484V107.953L163.844 107.187Z" />
            <path d="M186.812 109.867L188.138 110.633V112.164L186.812 112.93L185.486 112.164V110.633L186.812 109.867Z" />
            <path d="M189.875 125.945L191.201 126.711V128.242L189.875 129.008L188.549 128.242V126.711L189.875 125.945Z" />
            <path d="M165.375 104.507L166.701 105.273V106.804L165.375 107.57L164.049 106.804V105.273L165.375 104.507Z" />
            <path d="M188.344 107.187L189.67 107.953V109.484L188.344 110.249L187.018 109.484V107.953L188.344 107.187Z" />
            <path d="M189.875 147.383L191.201 148.149V149.68L189.875 150.445L188.549 149.68V148.149L189.875 147.383Z" />
            <path d="M165.375 93.7893L166.701 94.5549V96.0862L165.375 96.8518L164.049 96.0862V94.5549L165.375 93.7893Z" />
            <path d="M165.375 88.4298L166.701 89.1954V90.7267L165.375 91.4923L164.049 90.7267V89.1954L165.375 88.4298Z" />
            <path d="M194.469 139.344L195.795 140.11V141.641L194.469 142.406L193.143 141.641V140.11L194.469 139.344Z" />
            <path d="M171.5 99.1482L172.826 99.9138V101.445L171.5 102.211L170.174 101.445V99.9138L171.5 99.1482Z" />
            <path d="M173.031 139.344L174.357 140.11V141.641L173.031 142.406L171.705 141.641V140.11L173.031 139.344Z" />
            <path d="M194.469 101.828L195.795 102.594V104.125L194.469 104.891L193.143 104.125V102.594L194.469 101.828Z" />
            <path d="M192.938 93.7893L194.264 94.5549V96.0862L192.938 96.8518L191.612 96.0862V94.5549L192.938 93.7893Z" />
            <path d="M171.5 93.7893L172.826 94.5549V96.0862L171.5 96.8518L170.174 96.0862V94.5549L171.5 93.7893Z" />
            <path d="M173.031 96.4689L174.357 97.2345V98.7657L173.031 99.5314L171.705 98.7657V97.2345L173.031 96.4689Z" />
            <path d="M174.562 136.664L175.889 137.43V138.961L174.562 139.727L173.236 138.961V137.43L174.562 136.664Z" />
            <path d="M197.532 133.985L198.858 134.75V136.282L197.532 137.047L196.205 136.282V134.75L197.532 133.985Z" />
            <path d="M196 99.1482L197.326 99.9138V101.445L196 102.211L194.674 101.445V99.9138L196 99.1482Z" />
            <path d="M156.187 93.7893L157.513 94.5549V96.0862L156.187 96.8518L154.861 96.0862V94.5549L156.187 93.7893Z" />
            <path d="M156.187 88.4298L157.513 89.1954V90.7267L156.187 91.4923L154.861 90.7267V89.1954L156.187 88.4298Z" />
            <path d="M154.656 96.4689L155.982 97.2345V98.7657L154.656 99.5314L153.33 98.7657V97.2345L154.656 96.4689Z" />
            <path d="M166.906 96.4689L168.232 97.2345V98.7657L166.906 99.5314L165.58 98.7657V97.2345L166.906 96.4689Z" />
            <path d="M194.469 133.985L195.795 134.75V136.282L194.469 137.047L193.143 136.282V134.75L194.469 133.985Z" />
            <path d="M189.875 99.1482L191.201 99.9138V101.445L189.875 102.211L188.549 101.445V99.9138L189.875 99.1482Z" />
            <path d="M191.407 139.344L192.733 140.11V141.641L191.407 142.406L190.08 141.641V140.11L191.407 139.344Z" />
            <path d="M192.938 158.102L194.264 158.867V160.398L192.938 161.164L191.612 160.398V158.867L192.938 158.102Z" />
            <path d="M169.969 96.4689L171.295 97.2345V98.7657L169.969 99.5314L168.642 98.7657V97.2345L169.969 96.4689Z" />
            <path d="M191.407 133.985L192.733 134.75V136.282L191.407 137.047L190.08 136.282V134.75L191.407 133.985Z" />
            <path d="M192.938 152.742L194.264 153.508V155.039L192.938 155.805L191.612 155.039V153.508L192.938 152.742Z" />
            <path d="M192.938 99.1482L194.264 99.9138V101.445L192.938 102.211L191.612 101.445V99.9138L192.938 99.1482Z" />
            <path d="M174.562 93.7893L175.889 94.5549V96.0862L174.562 96.8518L173.236 96.0862V94.5549L174.562 93.7893Z" />
            <path d="M176.094 133.985L177.42 134.75V136.282L176.094 137.047L174.768 136.282V134.75L176.094 133.985Z" />
            <path d="M177.625 93.7893L178.951 94.5549V96.0862L177.625 96.8518L176.299 96.0862V94.5549L177.625 93.7893Z" />
            <path d="M179.156 133.985L180.482 134.75V136.282L179.156 137.047L177.83 136.282V134.75L179.156 133.985Z" />
            <path d="M180.687 152.742L182.013 153.508V155.039L180.687 155.805L179.361 155.039V153.508L180.687 152.742Z" />
            <path d="M180.687 93.7893L182.013 94.5549V96.0862L180.687 96.8518L179.361 96.0862V94.5549L180.687 93.7893Z" />
            <path d="M182.219 133.985L183.545 134.75V136.282L182.219 137.047L180.893 136.282V134.75L182.219 133.985Z" />
            <path d="M183.75 152.742L185.076 153.508V155.039L183.75 155.805L182.424 155.039V153.508L183.75 152.742Z" />
            <path d="M177.625 93.7893L178.951 94.5549V96.0862L177.625 96.8518L176.299 96.0862V94.5549L177.625 93.7893Z" />
            <path d="M179.156 133.985L180.482 134.75V136.282L179.156 137.047L177.83 136.282V134.75L179.156 133.985Z" />
            <path d="M180.687 152.742L182.013 153.508V155.039L180.687 155.805L179.361 155.039V153.508L180.687 152.742Z" />
            <path d="M168.438 99.1482L169.764 99.9138V101.445L168.438 102.211L167.112 101.445V99.9138L168.438 99.1482Z" />
            <path d="M196 136.664L197.326 137.43V138.961L196 139.727L194.674 138.961V137.43L196 136.664Z" />
            <path d="M191.407 101.828L192.733 102.594V104.125L191.407 104.891L190.08 104.125V102.594L191.407 101.828Z" />
            <path d="M192.938 142.024L194.264 142.789V144.321L192.938 145.086L191.612 144.321V142.789L192.938 142.024Z" />
            <path d="M189.875 93.7893L191.201 94.5549V96.0862L189.875 96.8518L188.549 96.0862V94.5549L189.875 93.7893Z" />
            <path d="M168.438 93.7893L169.764 94.5549V96.0862L168.438 96.8518L167.112 96.0862V94.5549L168.438 93.7893Z" />
            <path d="M196 142.024L197.326 142.789V144.321L196 145.086L194.674 144.321V142.789L196 142.024Z" />
            <path d="M171.5 120.586L172.826 121.351V122.883L171.5 123.648L170.174 122.883V121.351L171.5 120.586Z" />
            <path d="M177.625 120.586L178.951 121.351V122.883L177.625 123.648L176.299 122.883V121.351L177.625 120.586Z" />
            <path d="M200.594 117.906L201.92 118.672V120.203L200.594 120.969L199.268 120.203V118.672L200.594 117.906Z" />
            <path d="M166.906 80.3909L168.232 81.1565V82.6877L166.906 83.4534L165.58 82.6877V81.1565L166.906 80.3909Z" />
            <path d="M159.25 83.0702L160.576 83.8358V85.3671L159.25 86.1327L157.923 85.3671V83.8358L159.25 83.0702Z" />
            <path d="M165.375 83.0702L166.701 83.8358V85.3671L165.375 86.1327L164.049 85.3671V83.8358L165.375 83.0702Z" />
            <path d="M186.812 120.586L188.138 121.351V122.883L186.812 123.648L185.486 122.883V121.351L186.812 120.586Z" />
            <path d="M163.844 112.546L165.17 113.312V114.843L163.844 115.609L162.517 114.843V113.312L163.844 112.546Z" />
            <path d="M197.532 150.062L198.858 150.828V152.359L197.532 153.125L196.205 152.359V150.828L197.532 150.062Z" />
            <path d="M186.812 115.227L188.138 115.993V117.524L186.812 118.29L185.486 117.524V115.993L186.812 115.227Z" />
            <path d="M188.344 91.1093L189.67 91.8749V93.4061L188.344 94.1718L187.018 93.4061V91.8749L188.344 91.1093Z" />
            <path d="M189.875 131.305L191.201 132.071V133.602L189.875 134.368L188.549 133.602V132.071L189.875 131.305Z" />
            <path d="M191.407 150.062L192.733 150.828V152.359L191.407 153.125L190.08 152.359V150.828L191.407 150.062Z" />
            <path d="M169.969 117.906L171.295 118.672V120.203L169.969 120.969L168.642 120.203V118.672L169.969 117.906Z" />
            <path d="M192.938 120.586L194.264 121.351V122.883L192.938 123.648L191.612 122.883V121.351L192.938 120.586Z" />
            <path d="M169.969 112.546L171.295 113.312V114.843L169.969 115.609L168.642 114.843V113.312L169.969 112.546Z" />
            <path d="M173.031 128.625L174.357 129.391V130.922L173.031 131.688L171.705 130.922V129.391L173.031 128.625Z" />
            <path d="M192.938 115.227L194.264 115.993V117.524L192.938 118.29L191.612 117.524V115.993L192.938 115.227Z" />
            <path d="M194.469 91.1093L195.795 91.8749V93.4061L194.469 94.1718L193.143 93.4061V91.8749L194.469 91.1093Z" />
            <path d="M196 131.305L197.326 132.071V133.602L196 134.368L194.674 133.602V132.071L196 131.305Z" />
            <path d="M169.969 107.187L171.295 107.953V109.484L169.969 110.249L168.642 109.484V107.953L169.969 107.187Z" />
            <path d="M192.938 109.867L194.264 110.633V112.164L192.938 112.93L191.612 112.164V110.633L192.938 109.867Z" />
            <path d="M196 125.945L197.326 126.711V128.242L196 129.008L194.674 128.242V126.711L196 125.945Z" />
            <path d="M176.094 117.906L177.42 118.672V120.203L176.094 120.969L174.768 120.203V118.672L176.094 117.906Z" />
            <path d="M199.063 115.227L200.389 115.993V117.524L199.063 118.29L197.737 117.524V115.993L199.063 115.227Z" />
            <path d="M200.594 112.546L201.92 113.312V114.843L200.594 115.609L199.268 114.843V113.312L200.594 112.546Z" />
            <path d="M202.125 109.867L203.451 110.633V112.164L202.125 112.93L200.799 112.164V110.633L202.125 109.867Z" />
            <path d="M176.094 112.546L177.42 113.312V114.843L176.094 115.609L174.768 114.843V113.312L176.094 112.546Z" />
            <path d="M179.156 128.625L180.482 129.391V130.922L179.156 131.688L177.83 130.922V129.391L179.156 128.625Z" />
            <path d="M150.062 115.227L151.389 115.993V117.524L150.062 118.29L148.736 117.524V115.993L150.062 115.227Z" />
            <path d="M176.094 107.187L177.42 107.953V109.484L176.094 110.249L174.768 109.484V107.953L176.094 107.187Z" />
            <path d="M177.625 147.383L178.951 148.149V149.68L177.625 150.445L176.299 149.68V148.149L177.625 147.383Z" />
            <path d="M150.062 109.867L151.389 110.633V112.164L150.062 112.93L148.736 112.164V110.633L150.062 109.867Z" />
            <path d="M169.969 80.3909L171.295 81.1565V82.6877L169.969 83.4534L168.642 82.6877V81.1565L169.969 80.3909Z" />
            <path d="M171.5 77.7106L172.826 78.4762V80.0074L171.5 80.7731L170.174 80.0074V78.4762L171.5 77.7106Z" />
            <path d="M154.656 80.3909L155.982 81.1565V82.6877L154.656 83.4534L153.33 82.6877V81.1565L154.656 80.3909Z" />
            <path d="M157.719 80.3909L159.045 81.1565V82.6877L157.719 83.4534L156.392 82.6877V81.1565L157.719 80.3909Z" />
            <path d="M160.781 80.3909L162.107 81.1565V82.6877L160.781 83.4534L159.455 82.6877V81.1565L160.781 80.3909Z" />
            <path d="M163.844 80.3909L165.17 81.1565V82.6877L163.844 83.4534L162.517 82.6877V81.1565L163.844 80.3909Z" />
            <path d="M162.312 77.7106L163.638 78.4762V80.0074L162.312 80.7731L160.986 80.0074V78.4762L162.312 77.7106Z" />
            <path d="M162.312 83.0702L163.638 83.8358V85.3671L162.312 86.1327L160.986 85.3671V83.8358L162.312 83.0702Z" />
            <path d="M156.187 83.0702L157.513 83.8358V85.3671L156.187 86.1327L154.861 85.3671V83.8358L156.187 83.0702Z" />
            <path d="M183.75 120.586L185.076 121.351V122.883L183.75 123.648L182.424 122.883V121.351L183.75 120.586Z" />
            <path d="M180.687 120.586L182.013 121.351V122.883L180.687 123.648L179.361 122.883V121.351L180.687 120.586Z" />
            <path d="M166.906 117.906L168.232 118.672V120.203L166.906 120.969L165.58 120.203V118.672L166.906 117.906Z" />
            <path d="M189.875 120.586L191.201 121.351V122.883L189.875 123.648L188.549 122.883V121.351L189.875 120.586Z" />
            <path d="M166.906 112.546L168.232 113.312V114.843L166.906 115.609L165.58 114.843V113.312L166.906 112.546Z" />
            <path d="M189.875 115.227L191.201 115.993V117.524L189.875 118.29L188.549 117.524V115.993L189.875 115.227Z" />
            <path d="M191.407 91.1093L192.733 91.8749V93.4061L191.407 94.1718L190.08 93.4061V91.8749L191.407 91.1093Z" />
            <path d="M192.938 131.305L194.264 132.071V133.602L192.938 134.368L191.612 133.602V132.071L192.938 131.305Z" />
            <path d="M166.906 107.187L168.232 107.953V109.484L166.906 110.249L165.58 109.484V107.953L166.906 107.187Z" />
            <path d="M189.875 109.867L191.201 110.633V112.164L189.875 112.93L188.549 112.164V110.633L189.875 109.867Z" />
            <path d="M192.938 125.945L194.264 126.711V128.242L192.938 129.008L191.612 128.242V126.711L192.938 125.945Z" />
            <path d="M173.031 117.906L174.357 118.672V120.203L173.031 120.969L171.705 120.203V118.672L173.031 117.906Z" />
            <path d="M196 120.586L197.326 121.351V122.883L196 123.648L194.674 122.883V121.351L196 120.586Z" />
            <path d="M173.031 112.546L174.357 113.312V114.843L173.031 115.609L171.705 114.843V113.312L173.031 112.546Z" />
            <path d="M176.094 128.625L177.42 129.391V130.922L176.094 131.688L174.768 130.922V129.391L176.094 128.625Z" />
            <path d="M196 115.227L197.326 115.993V117.524L196 118.29L194.674 117.524V115.993L196 115.227Z" />
            <path d="M197.532 112.546L198.858 113.312V114.843L197.532 115.609L196.205 114.843V113.312L197.532 112.546Z" />
            <path d="M199.063 109.867L200.389 110.633V112.164L199.063 112.93L197.737 112.164V110.633L199.063 109.867Z" />
            <path d="M173.031 107.187L174.357 107.953V109.484L173.031 110.249L171.705 109.484V107.953L173.031 107.187Z" />
            <path d="M174.562 147.383L175.889 148.149V149.68L174.562 150.445L173.236 149.68V148.149L174.562 147.383Z" />
            <path d="M196 109.867L197.326 110.633V112.164L196 112.93L194.674 112.164V110.633L196 109.867Z" />
            <path d="M179.156 117.906L180.482 118.672V120.203L179.156 120.969L177.83 120.203V118.672L179.156 117.906Z" />
            <path d="M202.125 115.227L203.451 115.993V117.524L202.125 118.29L200.799 117.524V115.993L202.125 115.227Z" />
            <path d="M203.656 112.546L204.982 113.312V114.843L203.656 115.609L202.33 114.843V113.312L203.656 112.546Z" />
            <path d="M205.188 109.867L206.514 110.633V112.164L205.188 112.93L203.862 112.164V110.633L205.188 109.867Z" />
            <path d="M200.594 107.187L201.92 107.953V109.484L200.594 110.249L199.268 109.484V107.953L200.594 107.187Z" />
            <path d="M197.532 107.187L198.858 107.953V109.484L197.532 110.249L196.205 109.484V107.953L197.532 107.187Z" />
            <path d="M199.063 104.507L200.389 105.273V106.804L199.063 107.57L197.737 106.804V105.273L199.063 104.507Z" />
            <path d="M197.532 101.828L198.858 102.594V104.125L197.532 104.891L196.205 104.125V102.594L197.532 101.828Z" />
            <path d="M197.532 96.4689L198.858 97.2345V98.7657L197.532 99.5314L196.205 98.7657V97.2345L197.532 96.4689Z" />
            <path d="M199.063 99.1482L200.389 99.9138V101.445L199.063 102.211L197.737 101.445V99.9138L199.063 99.1482Z" />
            <path d="M200.594 101.828L201.92 102.594V104.125L200.594 104.891L199.268 104.125V102.594L200.594 101.828Z" />
            <path d="M202.125 104.507L203.451 105.273V106.804L202.125 107.57L200.799 106.804V105.273L202.125 104.507Z" />
            <path d="M203.656 107.187L204.982 107.953V109.484L203.656 110.249L202.33 109.484V107.953L203.656 107.187Z" />
            <path d="M179.156 112.546L180.482 113.312V114.843L179.156 115.609L177.83 114.843V113.312L179.156 112.546Z" />
            <path d="M182.219 128.625L183.545 129.391V130.922L182.219 131.688L180.893 130.922V129.391L182.219 128.625Z" />
            <path d="M153.125 115.227L154.451 115.993V117.524L153.125 118.29L151.799 117.524V115.993L153.125 115.227Z" />
            <path d="M179.156 107.187L180.482 107.953V109.484L179.156 110.249L177.83 109.484V107.953L179.156 107.187Z" />
            <path d="M180.687 147.383L182.013 148.149V149.68L180.687 150.445L179.361 149.68V148.149L180.687 147.383Z" />
            <path d="M182.219 166.141L183.545 166.907V168.438L182.219 169.203L180.893 168.438V166.907L182.219 166.141Z" />
            <path d="M153.125 109.867L154.451 110.633V112.164L153.125 112.93L151.799 112.164V110.633L153.125 109.867Z" />
            <path d="M206.719 107.187L208.045 107.953V109.484L206.719 110.249L205.393 109.484V107.953L206.719 107.187Z" />
            <path d="M208.25 115.227L209.576 115.993V117.524L208.25 118.29L206.924 117.524V115.993L208.25 115.227Z" />
            <path d="M209.781 112.546L211.107 113.312V114.843L209.781 115.609L208.455 114.843V113.312L209.781 112.546Z" />
            <path d="M180.687 115.227L182.013 115.993V117.524L180.687 118.29L179.361 117.524V115.993L180.687 115.227Z" />
            <path d="M182.219 91.1093L183.545 91.8749V93.4061L182.219 94.1718L180.893 93.4061V91.8749L182.219 91.1093Z" />
            <path d="M183.75 131.305L185.076 132.071V133.602L183.75 134.368L182.424 133.602V132.071L183.75 131.305Z" />
            <path d="M185.281 150.062L186.607 150.828V152.359L185.281 153.125L183.955 152.359V150.828L185.281 150.062Z" />
            <path d="M180.687 109.867L182.013 110.633V112.164L180.687 112.93L179.361 112.164V110.633L180.687 109.867Z" />
            <path d="M183.75 125.945L185.076 126.711V128.242L183.75 129.008L182.424 128.242V126.711L183.75 125.945Z" />
            <path d="M154.656 112.546L155.982 113.312V114.843L154.656 115.609L153.33 114.843V113.312L154.656 112.546Z" />
            <path d="M180.687 104.507L182.013 105.273V106.804L180.687 107.57L179.361 106.804V105.273L180.687 104.507Z" />
            <path d="M182.219 144.703L183.545 145.469V147L182.219 147.766L180.893 147V145.469L182.219 144.703Z" />
            <path d="M183.75 163.461L185.076 164.226V165.758L183.75 166.523L182.424 165.758V164.226L183.75 163.461Z" />
            <path d="M154.656 107.187L155.982 107.953V109.484L154.656 110.249L153.33 109.484V107.953L154.656 107.187Z" />
            <path d="M209.781 144.703L211.107 145.469V147L209.781 147.766L208.455 147V145.469L209.781 144.703Z" />
            <path d="M211.313 147.383L212.639 148.149V149.68L211.313 150.445L209.987 149.68V148.149L211.313 147.383Z" />
            <path d="M209.781 150.062L211.107 150.828V152.359L209.781 153.125L208.455 152.359V150.828L209.781 150.062Z" />
            <path d="M179.156 101.828L180.482 102.594V104.125L179.156 104.891L177.83 104.125V102.594L179.156 101.828Z" />
            <path d="M180.687 142.024L182.013 142.789V144.321L180.687 145.086L179.361 144.321V142.789L180.687 142.024Z" />
            <path d="M182.219 160.781L183.545 161.547V163.078L182.219 163.844L180.893 163.078V161.547L182.219 160.781Z" />
            <path d="M179.156 160.781L180.482 161.547V163.078L179.156 163.844L177.83 163.078V161.547L179.156 160.781Z" />
            <path d="M177.625 158.102L178.951 158.867V160.398L177.625 161.164L176.299 160.398V158.867L177.625 158.102Z" />
            <path d="M179.156 155.421L180.482 156.187V157.718L179.156 158.484L177.83 157.718V156.187L179.156 155.421Z" />
            <path d="M176.094 155.421L177.42 156.187V157.718L176.094 158.484L174.768 157.718V156.187L176.094 155.421Z" />
            <path d="M177.625 152.742L178.951 153.508V155.039L177.625 155.805L176.299 155.039V153.508L177.625 152.742Z" />
            <path d="M179.156 150.062L180.482 150.828V152.359L179.156 153.125L177.83 152.359V150.828L179.156 150.062Z" />
            <path d="M176.094 150.062L177.42 150.828V152.359L176.094 153.125L174.768 152.359V150.828L176.094 150.062Z" />
            <path d="M153.125 104.507L154.451 105.273V106.804L153.125 107.57L151.799 106.804V105.273L153.125 104.507Z" />
            <path d="M203.656 139.344L204.982 140.11V141.641L203.656 142.406L202.33 141.641V140.11L203.656 139.344Z" />
            <path d="M151.594 96.4689L152.92 97.2345V98.7657L151.594 99.5314L150.267 98.7657V97.2345L151.594 96.4689Z" />
            <path d="M202.125 131.305L203.451 132.071V133.602L202.125 134.368L200.799 133.602V132.071L202.125 131.305Z" />
            <path d="M180.687 99.1482L182.013 99.9138V101.445L180.687 102.211L179.361 101.445V99.9138L180.687 99.1482Z" />
            <path d="M182.219 139.344L183.545 140.11V141.641L182.219 142.406L180.893 141.641V140.11L182.219 139.344Z" />
            <path d="M183.75 158.102L185.076 158.867V160.398L183.75 161.164L182.424 160.398V158.867L183.75 158.102Z" />
            <path d="M154.656 101.828L155.982 102.594V104.125L154.656 104.891L153.33 104.125V102.594L154.656 101.828Z" />
            <path d="M153.125 93.7893L154.451 94.5549V96.0862L153.125 96.8518L151.799 96.0862V94.5549L153.125 93.7893Z" />
            <path d="M153.125 88.4298L154.451 89.1954V90.7267L153.125 91.4923L151.799 90.7267V89.1954L153.125 88.4298Z" />
            <path d="M203.656 123.266L204.982 124.031V125.562L203.656 126.328L202.33 125.562V124.031L203.656 123.266Z" />
            <path d="M182.219 96.4689L183.545 97.2345V98.7657L182.219 99.5314L180.893 98.7657V97.2345L182.219 96.4689Z" />
            <path d="M183.75 136.664L185.076 137.43V138.961L183.75 139.727L182.424 138.961V137.43L183.75 136.664Z" />
            <path d="M185.281 155.421L186.607 156.187V157.718L185.281 158.484L183.955 157.718V156.187L185.281 155.421Z" />
            <path d="M156.187 99.1482L157.513 99.9138V101.445L156.187 102.211L154.861 101.445V99.9138L156.187 99.1482Z" />
            <path d="M154.656 91.1093L155.982 91.8749V93.4061L154.656 94.1718L153.33 93.4061V91.8749L154.656 91.1093Z" />
            <path d="M154.656 85.7496L155.982 86.5153V88.0465L154.656 88.8121L153.33 88.0465V86.5153L154.656 85.7496Z" />
            <path d="M205.188 120.586L206.514 121.351V122.883L205.188 123.648L203.862 122.883V121.351L205.188 120.586Z" />
            <path d="M203.656 117.906L204.982 118.672V120.203L203.656 120.969L202.33 120.203V118.672L203.656 117.906Z" />
            <path d="M165.375 77.7106L166.701 78.4762V80.0074L165.375 80.7731L164.049 80.0074V78.4762L165.375 77.7106Z" />
            <path d="M162.312 115.227L163.638 115.993V117.524L162.312 118.29L160.986 117.524V115.993L162.312 115.227Z" />
            <path d="M163.844 91.1093L165.17 91.8749V93.4061L163.844 94.1718L162.517 93.4061V91.8749L163.844 91.1093Z" />
            <path d="M163.844 85.7496L165.17 86.5153V88.0465L163.844 88.8121L162.517 88.0465V86.5153L163.844 85.7496Z" />
            <path d="M196 152.742L197.326 153.508V155.039L196 155.805L194.674 155.039V153.508L196 152.742Z" />
            <path d="M185.281 117.906L186.607 118.672V120.203L185.281 120.969L183.955 120.203V118.672L185.281 117.906Z" />
            <path d="M159.25 115.227L160.576 115.993V117.524L159.25 118.29L157.923 117.524V115.993L159.25 115.227Z" />
            <path d="M160.781 91.1093L162.107 91.8749V93.4061L160.781 94.1718L159.455 93.4061V91.8749L160.781 91.1093Z" />
            <path d="M160.781 85.7496L162.107 86.5153V88.0465L160.781 88.8121L159.455 88.0465V86.5153L160.781 85.7496Z" />
            <path d="M168.438 115.227L169.764 115.993V117.524L168.438 118.29L167.112 117.524V115.993L168.438 115.227Z" />
            <path d="M169.969 91.1093L171.295 91.8749V93.4061L169.969 94.1718L168.642 93.4061V91.8749L169.969 91.1093Z" />
            <path d="M191.407 117.906L192.733 118.672V120.203L191.407 120.969L190.08 120.203V118.672L191.407 117.906Z" />
            <path d="M168.438 109.867L169.764 110.633V112.164L168.438 112.93L167.112 112.164V110.633L168.438 109.867Z" />
            <path d="M171.5 125.945L172.826 126.711V128.242L171.5 129.008L170.174 128.242V126.711L171.5 125.945Z" />
            <path d="M169.969 123.266L171.295 124.031V125.562L169.969 126.328L168.642 125.562V124.031L169.969 123.266Z" />
            <path d="M182.219 123.266L183.545 124.031V125.562L182.219 126.328L180.893 125.562V124.031L182.219 123.266Z" />
            <path d="M191.407 112.546L192.733 113.312V114.843L191.407 115.609L190.08 114.843V113.312L191.407 112.546Z" />
            <path d="M194.469 128.625L195.795 129.391V130.922L194.469 131.688L193.143 130.922V129.391L194.469 128.625Z" />
            <path d="M168.438 104.507L169.764 105.273V106.804L168.438 107.57L167.112 106.804V105.273L168.438 104.507Z" />
            <path d="M191.407 107.187L192.733 107.953V109.484L191.407 110.249L190.08 109.484V107.953L191.407 107.187Z" />
            <path d="M192.938 147.383L194.264 148.149V149.68L192.938 150.445L191.612 149.68V148.149L192.938 147.383Z" />
            <path d="M174.562 115.227L175.889 115.993V117.524L174.562 118.29L173.236 117.524V115.993L174.562 115.227Z" />
            <path d="M176.094 91.1093L177.42 91.8749V93.4061L176.094 94.1718L174.768 93.4061V91.8749L176.094 91.1093Z" />
            <path d="M177.625 131.305L178.951 132.071V133.602L177.625 134.368L176.299 133.602V132.071L177.625 131.305Z" />
            <path d="M147 109.867L148.326 110.633V112.164L147 112.93L145.674 112.164V110.633L147 109.867Z" />
            <path d="M145.469 107.187L146.795 107.953V109.484L145.469 110.249L144.143 109.484V107.953L145.469 107.187Z" />
            <path d="M147 104.507L148.326 105.273V106.804L147 107.57L145.674 106.804V105.273L147 104.507Z" />
            <path d="M143.937 104.507L145.264 105.273V106.804L143.937 107.57L142.611 106.804V105.273L143.937 104.507Z" />
            <path d="M145.469 101.828L146.795 102.594V104.125L145.469 104.891L144.143 104.125V102.594L145.469 101.828Z" />
            <path d="M147 99.1482L148.326 99.9138V101.445L147 102.211L145.674 101.445V99.9138L147 99.1482Z" />
            <path d="M145.469 96.4689L146.795 97.2345V98.7657L145.469 99.5314L144.143 98.7657V97.2345L145.469 96.4689Z" />
            <path d="M143.937 99.1482L145.264 99.9138V101.445L143.937 102.211L142.611 101.445V99.9138L143.937 99.1482Z" />
            <path d="M147 93.7893L148.326 94.5549V96.0862L147 96.8518L145.674 96.0862V94.5549L147 93.7893Z" />
            <path d="M174.562 109.867L175.889 110.633V112.164L174.562 112.93L173.236 112.164V110.633L174.562 109.867Z" />
            <path d="M177.625 125.945L178.951 126.711V128.242L177.625 129.008L176.299 128.242V126.711L177.625 125.945Z" />
            <path d="M176.094 123.266L177.42 124.031V125.562L176.094 126.328L174.768 125.562V124.031L176.094 123.266Z" />
            <path d="M188.344 123.266L189.67 124.031V125.562L188.344 126.328L187.018 125.562V124.031L188.344 123.266Z" />
            <path d="M148.531 112.546L149.857 113.312V114.843L148.531 115.609L147.205 114.843V113.312L148.531 112.546Z" />
            <path d="M174.562 104.507L175.889 105.273V106.804L174.562 107.57L173.236 106.804V105.273L174.562 104.507Z" />
            <path d="M176.094 144.703L177.42 145.469V147L176.094 147.766L174.768 147V145.469L176.094 144.703Z" />
            <path d="M148.531 107.187L149.857 107.953V109.484L148.531 110.249L147.205 109.484V107.953L148.531 107.187Z" />
            <path d="M199.063 142.024L200.389 142.789V144.321L199.063 145.086L197.737 144.321V142.789L199.063 142.024Z" />
            <path d="M173.031 101.828L174.357 102.594V104.125L173.031 104.891L171.705 104.125V102.594L173.031 101.828Z" />
            <path d="M174.562 142.024L175.889 142.789V144.321L174.562 145.086L173.236 144.321V142.789L174.562 142.024Z" />
            <path d="M197.532 139.344L198.858 140.11V141.641L197.532 142.406L196.205 141.641V140.11L197.532 139.344Z" />
            <path d="M196 104.507L197.326 105.273V106.804L196 107.57L194.674 106.804V105.273L196 104.507Z" />
            <path d="M194.469 96.4689L195.795 97.2345V98.7657L194.469 99.5314L193.143 98.7657V97.2345L194.469 96.4689Z" />
            <path d="M174.562 99.1482L175.889 99.9138V101.445L174.562 102.211L173.236 101.445V99.9138L174.562 99.1482Z" />
            <path d="M176.094 139.344L177.42 140.11V141.641L176.094 142.406L174.768 141.641V140.11L176.094 139.344Z" />
            <path d="M148.531 101.828L149.857 102.594V104.125L148.531 104.891L147.205 104.125V102.594L148.531 101.828Z" />
            <path d="M199.063 136.664L200.389 137.43V138.961L199.063 139.727L197.737 138.961V137.43L199.063 136.664Z" />
            <path d="M197.532 128.625L198.858 129.391V130.922L197.532 131.688L196.205 130.922V129.391L197.532 128.625Z" />
            <path d="M197.532 123.266L198.858 124.031V125.562L197.532 126.328L196.205 125.562V124.031L197.532 123.266Z" />
            <path d="M176.094 96.4689L177.42 97.2345V98.7657L176.094 99.5314L174.768 98.7657V97.2345L176.094 96.4689Z" />
            <path d="M177.625 136.664L178.951 137.43V138.961L177.625 139.727L176.299 138.961V137.43L177.625 136.664Z" />
            <path d="M150.062 99.1482L151.389 99.9138V101.445L150.062 102.211L148.736 101.445V99.9138L150.062 99.1482Z" />
            <path d="M200.594 133.985L201.92 134.75V136.282L200.594 137.047L199.268 136.282V134.75L200.594 133.985Z" />
            <path d="M148.531 91.1093L149.857 91.8749V93.4061L148.531 94.1718L147.205 93.4061V91.8749L148.531 91.1093Z" />
            <path d="M153.125 83.0702L154.451 83.8358V85.3671L153.125 86.1327L151.799 85.3671V83.8358L153.125 83.0702Z" />
            <path d="M199.063 125.945L200.389 126.711V128.242L199.063 129.008L197.737 128.242V126.711L199.063 125.945Z" />
            <path d="M199.063 120.586L200.389 121.351V122.883L199.063 123.648L197.737 122.883V121.351L199.063 120.586Z" />
            <path d="M168.438 77.7106L169.764 78.4762V80.0074L168.438 80.7731L167.112 80.0074V78.4762L168.438 77.7106Z" />
            <path d="M171.5 115.227L172.826 115.993V117.524L171.5 118.29L170.174 117.524V115.993L171.5 115.227Z" />
            <path d="M173.031 91.1093L174.357 91.8749V93.4061L173.031 94.1718L171.705 93.4061V91.8749L173.031 91.1093Z" />
            <path d="M174.562 131.305L175.889 132.071V133.602L174.562 134.368L173.236 133.602V132.071L174.562 131.305Z" />
            <path d="M194.469 117.906L195.795 118.672V120.203L194.469 120.969L193.143 120.203V118.672L194.469 117.906Z" />
            <path d="M171.5 109.867L172.826 110.633V112.164L171.5 112.93L170.174 112.164V110.633L171.5 109.867Z" />
            <path d="M174.562 125.945L175.889 126.711V128.242L174.562 129.008L173.236 128.242V126.711L174.562 125.945Z" />
            <path d="M173.031 123.266L174.357 124.031V125.562L173.031 126.328L171.705 125.562V124.031L173.031 123.266Z" />
            <path d="M185.281 123.266L186.607 124.031V125.562L185.281 126.328L183.955 125.562V124.031L185.281 123.266Z" />
            <path d="M194.469 112.546L195.795 113.312V114.843L194.469 115.609L193.143 114.843V113.312L194.469 112.546Z" />
            <path d="M171.5 104.507L172.826 105.273V106.804L171.5 107.57L170.174 106.804V105.273L171.5 104.507Z" />
            <path d="M173.031 144.703L174.357 145.469V147L173.031 147.766L171.705 147V145.469L173.031 144.703Z" />
            <path d="M194.469 107.187L195.795 107.953V109.484L194.469 110.249L193.143 109.484V107.953L194.469 107.187Z" />
            <path d="M169.969 101.828L171.295 102.594V104.125L169.969 104.891L168.642 104.125V102.594L169.969 101.828Z" />
            <path d="M192.938 104.507L194.264 105.273V106.804L192.938 107.57L191.612 106.804V105.273L192.938 104.507Z" />
            <path d="M191.407 96.4689L192.733 97.2345V98.7657L191.407 99.5314L190.08 98.7657V97.2345L191.407 96.4689Z" />
            <path d="M192.938 136.664L194.264 137.43V138.961L192.938 139.727L191.612 138.961V137.43L192.938 136.664Z" />
            <path d="M177.625 115.227L178.951 115.993V117.524L177.625 118.29L176.299 117.524V115.993L177.625 115.227Z" />
            <path d="M179.156 91.1093L180.482 91.8749V93.4061L179.156 94.1718L177.83 93.4061V91.8749L179.156 91.1093Z" />
            <path d="M180.687 131.305L182.013 132.071V133.602L180.687 134.368L179.361 133.602V132.071L180.687 131.305Z" />
            <path d="M182.219 150.062L183.545 150.828V152.359L182.219 153.125L180.893 152.359V150.828L182.219 150.062Z" />
            <path d="M151.594 117.906L152.92 118.672V120.203L151.594 120.969L150.267 120.203V118.672L151.594 117.906Z" />
            <path d="M177.625 109.867L178.951 110.633V112.164L177.625 112.93L176.299 112.164V110.633L177.625 109.867Z" />
            <path d="M180.687 125.945L182.013 126.711V128.242L180.687 129.008L179.361 128.242V126.711L180.687 125.945Z" />
            <path d="M179.156 123.266L180.482 124.031V125.562L179.156 126.328L177.83 125.562V124.031L179.156 123.266Z" />
            <path d="M191.407 123.266L192.733 124.031V125.562L191.407 126.328L190.08 125.562V124.031L191.407 123.266Z" />
            <path d="M194.469 123.266L195.795 124.031V125.562L194.469 126.328L193.143 125.562V124.031L194.469 123.266Z" />
            <path d="M151.594 112.546L152.92 113.312V114.843L151.594 115.609L150.267 114.843V113.312L151.594 112.546Z" />
            <path d="M177.625 104.507L178.951 105.273V106.804L177.625 107.57L176.299 106.804V105.273L177.625 104.507Z" />
            <path d="M179.156 144.703L180.482 145.469V147L179.156 147.766L177.83 147V145.469L179.156 144.703Z" />
            <path d="M180.687 163.461L182.013 164.226V165.758L180.687 166.523L179.361 165.758V164.226L180.687 163.461Z" />
            <path d="M151.594 107.187L152.92 107.953V109.484L151.594 110.249L150.267 109.484V107.953L151.594 107.187Z" />
            <path d="M202.125 142.024L203.451 142.789V144.321L202.125 145.086L200.799 144.321V142.789L202.125 142.024Z" />
            <path d="M176.094 101.828L177.42 102.594V104.125L176.094 104.891L174.768 104.125V102.594L176.094 101.828Z" />
            <path d="M177.625 142.024L178.951 142.789V144.321L177.625 145.086L176.299 144.321V142.789L177.625 142.024Z" />
            <path d="M150.062 104.507L151.389 105.273V106.804L150.062 107.57L148.736 106.804V105.273L150.062 104.507Z" />
            <path d="M200.594 139.344L201.92 140.11V141.641L200.594 142.406L199.268 141.641V140.11L200.594 139.344Z" />
            <path d="M148.531 96.4689L149.857 97.2345V98.7657L148.531 99.5314L147.205 98.7657V97.2345L148.531 96.4689Z" />
            <path d="M199.063 131.305L200.389 132.071V133.602L199.063 134.368L197.737 133.602V132.071L199.063 131.305Z" />
            <path d="M177.625 99.1482L178.951 99.9138V101.445L177.625 102.211L176.299 101.445V99.9138L177.625 99.1482Z" />
            <path d="M179.156 139.344L180.482 140.11V141.641L179.156 142.406L177.83 141.641V140.11L179.156 139.344Z" />
            <path d="M180.687 158.102L182.013 158.867V160.398L180.687 161.164L179.361 160.398V158.867L180.687 158.102Z" />
            <path d="M151.594 101.828L152.92 102.594V104.125L151.594 104.891L150.267 104.125V102.594L151.594 101.828Z" />
            <path d="M202.125 136.664L203.451 137.43V138.961L202.125 139.727L200.799 138.961V137.43L202.125 136.664Z" />
            <path d="M150.062 93.7893L151.389 94.5549V96.0862L150.062 96.8518L148.736 96.0862V94.5549L150.062 93.7893Z" />
            <path d="M150.062 88.4298L151.389 89.1954V90.7267L150.062 91.4923L148.736 90.7267V89.1954L150.062 88.4298Z" />
            <path d="M200.594 128.625L201.92 129.391V130.922L200.594 131.688L199.268 130.922V129.391L200.594 128.625Z" />
            <path d="M200.594 123.266L201.92 124.031V125.562L200.594 126.328L199.268 125.562V124.031L200.594 123.266Z" />
            <path d="M179.156 96.4689L180.482 97.2345V98.7657L179.156 99.5314L177.83 98.7657V97.2345L179.156 96.4689Z" />
            <path d="M180.687 136.664L182.013 137.43V138.961L180.687 139.727L179.361 138.961V137.43L180.687 136.664Z" />
            <path d="M182.219 155.421L183.545 156.187V157.718L182.219 158.484L180.893 157.718V156.187L182.219 155.421Z" />
            <path d="M153.125 99.1482L154.451 99.9138V101.445L153.125 102.211L151.799 101.445V99.9138L153.125 99.1482Z" />
            <path d="M151.594 91.1093L152.92 91.8749V93.4061L151.594 94.1718L150.267 93.4061V91.8749L151.594 91.1093Z" />
            <path d="M151.594 85.7496L152.92 86.5153V88.0465L151.594 88.8121L150.267 88.0465V86.5153L151.594 85.7496Z" />
            <path d="M202.125 125.945L203.451 126.711V128.242L202.125 129.008L200.799 128.242V126.711L202.125 125.945Z" />
            <path d="M202.125 120.586L203.451 121.351V122.883L202.125 123.648L200.799 122.883V121.351L202.125 120.586Z" />
            <path d="M166.906 85.7496L168.232 86.5153V88.0465L166.906 88.8121L165.58 88.0465V86.5153L166.906 85.7496Z" />
            <path d="M168.438 88.4298L169.764 89.1954V90.7267L168.438 91.4923L167.112 90.7267V89.1954L168.438 88.4298Z" />
            <path d="M169.969 85.7496L171.295 86.5153V88.0465L169.969 88.8121L168.642 88.0465V86.5153L169.969 85.7496Z" />
            <path d="M171.5 88.4298L172.826 89.1954V90.7267L171.5 91.4923L170.174 90.7267V89.1954L171.5 88.4298Z" />
          </g>
          <g
            id="Europe"
            fill={highlight.includes('Europe') ? highlightColor : defaultColor}
            onClick={() => {
              onClick('Europe');
            }}
          >
            <path d="M177.625 29.4764L178.951 30.2421V31.7733L177.625 32.5389L176.299 31.7733V30.2421L177.625 29.4764Z" />
            <path d="M168.438 45.554L169.764 46.3196V47.8508L168.438 48.6165L167.112 47.8508V46.3196L168.438 45.554Z" />
            <path d="M169.969 42.8745L171.295 43.6401V45.1714L169.969 45.937L168.642 45.1714V43.6401L169.969 42.8745Z" />
            <path d="M173.031 53.5938L174.357 54.3594V55.8906L173.031 56.6562L171.705 55.8906V54.3594L173.031 53.5938Z" />
            <path d="M171.5 40.1957L172.826 40.9613V42.4926L171.5 43.2582L170.174 42.4926V40.9613L171.5 40.1957Z" />
            <path d="M173.031 42.8745L174.357 43.6401V45.1714L173.031 45.937L171.705 45.1714V43.6401L173.031 42.8745Z" />
            <path d="M171.5 34.8361L172.826 35.6017V37.1329L171.5 37.8986L170.174 37.1329V35.6017L171.5 34.8361Z" />
            <path d="M180.687 29.4764L182.013 30.2421V31.7733L180.687 32.5389L179.361 31.7733V30.2421L180.687 29.4764Z" />
            <path d="M174.562 34.8361L175.889 35.6017V37.1329L174.562 37.8986L173.236 37.1329V35.6017L174.562 34.8361Z" />
            <path d="M176.094 37.5156L177.42 38.2812V39.8125L176.094 40.5781L174.768 39.8125V38.2812L176.094 37.5156Z" />
            <path d="M182.219 48.2341L183.545 48.9998V50.531L182.219 51.2966L180.893 50.531V48.9998L182.219 48.2341Z" />
            <path d="M189.875 61.6324L191.201 62.3981V63.9293L189.875 64.6949L188.549 63.9293V62.3981L189.875 61.6324Z" />
            <path d="M196 61.6324L197.326 62.3981V63.9293L196 64.6949L194.674 63.9293V62.3981L196 61.6324Z" />
            <path d="M197.532 58.9521L198.858 59.7178V61.249L197.532 62.0146L196.205 61.249V59.7178L197.532 58.9521Z" />
            <path d="M199.063 56.2731L200.389 57.0387V58.5699L199.063 59.3356L197.737 58.5699V57.0387L199.063 56.2731Z" />
            <path d="M205.188 66.9913L206.514 67.757V69.2882L205.188 70.0538L203.862 69.2882V67.757L205.188 66.9913Z" />
            <path d="M200.594 58.9521L201.92 59.7178V61.249L200.594 62.0146L199.268 61.249V59.7178L200.594 58.9521Z" />
            <path d="M203.656 69.672L204.982 70.4376V71.9689L203.656 72.7345L202.33 71.9689V70.4376L203.656 69.672Z" />
            <path d="M206.719 69.672L208.045 70.4376V71.9689L206.719 72.7345L205.393 71.9689V70.4376L206.719 69.672Z" />
            <path d="M200.594 53.5938L201.92 54.3594V55.8906L200.594 56.6562L199.268 55.8906V54.3594L200.594 53.5938Z" />
            <path d="M199.063 61.6324L200.389 62.3981V63.9293L199.063 64.6949L197.737 63.9293V62.3981L199.063 61.6324Z" />
            <path d="M205.188 61.6324L206.514 62.3981V63.9293L205.188 64.6949L203.862 63.9293V62.3981L205.188 61.6324Z" />
            <path d="M203.656 53.5938L204.982 54.3594V55.8906L203.656 56.6562L202.33 55.8906V54.3594L203.656 53.5938Z" />
            <path d="M208.25 56.2731L209.576 57.0387V58.5699L208.25 59.3356L206.924 58.5699V57.0387L208.25 56.2731Z" />
            <path d="M197.532 53.5938L198.858 54.3594V55.8906L197.532 56.6562L196.205 55.8906V54.3594L197.532 53.5938Z" />
            <path d="M208.25 61.6324L209.576 62.3981V63.9293L208.25 64.6949L206.924 63.9293V62.3981L208.25 61.6324Z" />
            <path d="M186.812 61.6324L188.138 62.3981V63.9293L186.812 64.6949L185.486 63.9293V62.3981L186.812 61.6324Z" />
            <path d="M192.938 61.6324L194.264 62.3981V63.9293L192.938 64.6949L191.612 63.9293V62.3981L192.938 61.6324Z" />
            <path d="M206.719 58.9521L208.045 59.7178V61.249L206.719 62.0146L205.393 61.249V59.7178L206.719 58.9521Z" />
            <path d="M206.719 53.5938L208.045 54.3594V55.8906L206.719 56.6562L205.393 55.8906V54.3594L206.719 53.5938Z" />
            <path d="M185.281 58.9521L186.607 59.7178V61.249L185.281 62.0146L183.955 61.249V59.7178L185.281 58.9521Z" />
            <path d="M191.407 58.9521L192.733 59.7178V61.249L191.407 62.0146L190.08 61.249V59.7178L191.407 58.9521Z" />
            <path d="M203.656 58.9521L204.982 59.7178V61.249L203.656 62.0146L202.33 61.249V59.7178L203.656 58.9521Z" />
            <path d="M186.812 66.9913L188.138 67.757V69.2882L186.812 70.0538L185.486 69.2882V67.757L186.812 66.9913Z" />
            <path d="M188.344 58.9521L189.67 59.7178V61.249L188.344 62.0146L187.018 61.249V59.7178L188.344 58.9521Z" />
            <path d="M199.063 66.9913L200.389 67.757V69.2882L199.063 70.0538L197.737 69.2882V67.757L199.063 66.9913Z" />
            <path d="M202.125 66.9913L203.451 67.757V69.2882L202.125 70.0538L200.799 69.2882V67.757L202.125 66.9913Z" />
            <path d="M194.469 58.9521L195.795 59.7178V61.249L194.469 62.0146L193.143 61.249V59.7178L194.469 58.9521Z" />
            <path d="M200.594 64.3124L201.92 65.078V66.6093L200.594 67.3749L199.268 66.6093V65.078L200.594 64.3124Z" />
            <path d="M202.125 61.6324L203.451 62.3981V63.9293L202.125 64.6949L200.799 63.9293V62.3981L202.125 61.6324Z" />
            <path d="M196 56.2731L197.326 57.0387V58.5699L196 59.3356L194.674 58.5699V57.0387L196 56.2731Z" />
            <path d="M203.656 64.3124L204.982 65.078V66.6093L203.656 67.3749L202.33 66.6093V65.078L203.656 64.3124Z" />
            <path d="M205.188 56.2731L206.514 57.0387V58.5699L205.188 59.3356L203.862 58.5699V57.0387L205.188 56.2731Z" />
            <path d="M202.125 56.2731L203.451 57.0387V58.5699L202.125 59.3356L200.799 58.5699V57.0387L202.125 56.2731Z" />
            <path d="M188.344 64.3124L189.67 65.078V66.6093L188.344 67.3749L187.018 66.6093V65.078L188.344 64.3124Z" />
            <path d="M185.281 69.672L186.607 70.4376V71.9689L185.281 72.7345L183.955 71.9689V70.4376L185.281 69.672Z" />
            <path d="M200.594 48.2341L201.92 48.9998V50.531L200.594 51.2966L199.268 50.531V48.9998L200.594 48.2341Z" />
            <path d="M202.125 50.9142L203.451 51.6798V53.2111L202.125 53.9767L200.799 53.2111V51.6798L202.125 50.9142Z" />
            <path d="M199.063 45.554L200.389 46.3196V47.8508L199.063 48.6165L197.737 47.8508V46.3196L199.063 45.554Z" />
            <path d="M202.125 45.554L203.451 46.3196V47.8508L202.125 48.6165L200.799 47.8508V46.3196L202.125 45.554Z" />
            <path d="M203.656 48.2341L204.982 48.9998V50.531L203.656 51.2966L202.33 50.531V48.9998L203.656 48.2341Z" />
            <path d="M205.188 50.9142L206.514 51.6798V53.2111L205.188 53.9767L203.862 53.2111V51.6798L205.188 50.9142Z" />
            <path d="M208.25 50.9142L209.576 51.6798V53.2111L208.25 53.9767L206.924 53.2111V51.6798L208.25 50.9142Z" />
            <path d="M196 45.554L197.326 46.3196V47.8508L196 48.6165L194.674 47.8508V46.3196L196 45.554Z" />
            <path d="M197.532 48.2341L198.858 48.9998V50.531L197.532 51.2966L196.205 50.531V48.9998L197.532 48.2341Z" />
            <path d="M199.063 50.9142L200.389 51.6798V53.2111L199.063 53.9767L197.737 53.2111V51.6798L199.063 50.9142Z" />
            <path d="M205.188 45.554L206.514 46.3196V47.8508L205.188 48.6165L203.862 47.8508V46.3196L205.188 45.554Z" />
            <path d="M206.719 48.2341L208.045 48.9998V50.531L206.719 51.2966L205.393 50.531V48.9998L206.719 48.2341Z" />
            <path d="M208.25 45.554L209.576 46.3196V47.8508L208.25 48.6165L206.924 47.8508V46.3196L208.25 45.554Z" />
            <path d="M196 50.9142L197.326 51.6798V53.2111L196 53.9767L194.674 53.2111V51.6798L196 50.9142Z" />
            <path d="M215.906 32.1564L217.232 32.922V34.4532L215.906 35.2189L214.58 34.4532V32.922L215.906 32.1564Z" />
            <path d="M215.906 37.5156L217.232 38.2812V39.8125L215.906 40.5781L214.58 39.8125V38.2812L215.906 37.5156Z" />
            <path d="M217.437 29.4764L218.763 30.2421V31.7733L217.437 32.5389L216.111 31.7733V30.2421L217.437 29.4764Z" />
            <path d="M218.969 32.1564L220.295 32.922V34.4532L218.969 35.2189L217.643 34.4532V32.922L218.969 32.1564Z" />
            <path d="M217.437 34.8361L218.763 35.6017V37.1329L217.437 37.8986L216.111 37.1329V35.6017L217.437 34.8361Z" />
            <path d="M212.844 53.5938L214.17 54.3594V55.8906L212.844 56.6562L211.518 55.8906V54.3594L212.844 53.5938Z" />
            <path d="M212.844 48.2341L214.17 48.9998V50.531L212.844 51.2966L211.518 50.531V48.9998L212.844 48.2341Z" />
            <path d="M209.781 53.5938L211.107 54.3594V55.8906L209.781 56.6562L208.455 55.8906V54.3594L209.781 53.5938Z" />
            <path d="M209.781 48.2341L211.107 48.9998V50.531L209.781 51.2966L208.455 50.531V48.9998L209.781 48.2341Z" />
            <path d="M211.313 50.9142L212.639 51.6798V53.2111L211.313 53.9767L209.987 53.2111V51.6798L211.313 50.9142Z" />
            <path d="M211.313 45.554L212.639 46.3196V47.8508L211.313 48.6165L209.987 47.8508V46.3196L211.313 45.554Z" />
            <path d="M215.906 42.8745L217.232 43.6401V45.1714L215.906 45.937L214.58 45.1714V43.6401L215.906 42.8745Z" />
            <path d="M217.437 40.1957L218.763 40.9613V42.4926L217.437 43.2582L216.111 42.4926V40.9613L217.437 40.1957Z" />
            <path d="M214.375 50.9142L215.701 51.6798V53.2111L214.375 53.9767L213.049 53.2111V51.6798L214.375 50.9142Z" />
            <path d="M214.375 45.554L215.701 46.3196V47.8508L214.375 48.6165L213.049 47.8508V46.3196L214.375 45.554Z" />
            <path d="M194.469 64.3124L195.795 65.078V66.6093L194.469 67.3749L193.143 66.6093V65.078L194.469 64.3124Z" />
            <path d="M192.938 66.9913L194.264 67.757V69.2882L192.938 70.0538L191.612 69.2882V67.757L192.938 66.9913Z" />
            <path d="M206.719 64.3124L208.045 65.078V66.6093L206.719 67.3749L205.393 66.6093V65.078L206.719 64.3124Z" />
            <path d="M185.281 64.3124L186.607 65.078V66.6093L185.281 67.3749L183.955 66.6093V65.078L185.281 64.3124Z" />
            <path d="M197.532 64.3124L198.858 65.078V66.6093L197.532 67.3749L196.205 66.6093V65.078L197.532 64.3124Z" />
            <path d="M183.75 50.9142L185.076 51.6798V53.2111L183.75 53.9767L182.424 53.2111V51.6798L183.75 50.9142Z" />
            <path d="M183.75 29.4764L185.076 30.2421V31.7733L183.75 32.5389L182.424 31.7733V30.2421L183.75 29.4764Z" />
            <path d="M186.812 29.4764L188.138 30.2421V31.7733L186.812 32.5389L185.486 31.7733V30.2421L186.812 29.4764Z" />
            <path d="M185.281 26.7974L186.607 27.563V29.0942L185.281 29.8599L183.955 29.0942V27.563L185.281 26.7974Z" />
            <path d="M188.344 26.7974L189.67 27.563V29.0942L188.344 29.8599L187.018 29.0942V27.563L188.344 26.7974Z" />
            <path d="M189.875 29.4764L191.201 30.2421V31.7733L189.875 32.5389L188.549 31.7733V30.2421L189.875 29.4764Z" />
            <path d="M171.5 45.554L172.826 46.3196V47.8508L171.5 48.6165L170.174 47.8508V46.3196L171.5 45.554Z" />
            <path d="M176.094 32.1564L177.42 32.922V34.4532L176.094 35.2189L174.768 34.4532V32.922L176.094 32.1564Z" />
            <path d="M185.281 32.1564L186.607 32.922V34.4532L185.281 35.2189L183.955 34.4532V32.922L185.281 32.1564Z" />
            <path d="M177.625 8.03955L178.951 8.80518V10.3364L177.625 11.102L176.299 10.3364V8.80518L177.625 8.03955Z" />
            <path d="M183.75 34.8361L185.076 35.6017V37.1329L183.75 37.8986L182.424 37.1329V35.6017L183.75 34.8361Z" />
            <path d="M176.094 10.7189L177.42 11.4845V13.0157L176.094 13.7814L174.768 13.0157V11.4845L176.094 10.7189Z" />
            <path d="M185.281 37.5156L186.607 38.2812V39.8125L185.281 40.5781L183.955 39.8125V38.2812L185.281 37.5156Z" />
            <path d="M186.812 40.1957L188.138 40.9613V42.4926L186.812 43.2582L185.486 42.4926V40.9613L186.812 40.1957Z" />
            <path d="M188.344 42.8745L189.67 43.6401V45.1714L188.344 45.937L187.018 45.1714V43.6401L188.344 42.8745Z" />
            <path d="M179.156 32.1564L180.482 32.922V34.4532L179.156 35.2189L177.83 34.4532V32.922L179.156 32.1564Z" />
            <path d="M171.5 8.03955L172.826 8.80518V10.3364L171.5 11.102L170.174 10.3364V8.80518L171.5 8.03955Z" />
            <path d="M177.625 34.8361L178.951 35.6017V37.1329L177.625 37.8986L176.299 37.1329V35.6017L177.625 34.8361Z" />
            <path d="M180.687 40.1957L182.013 40.9613V42.4926L180.687 43.2582L179.361 42.4926V40.9613L180.687 40.1957Z" />
            <path d="M182.219 42.8745L183.545 43.6401V45.1714L182.219 45.937L180.893 45.1714V43.6401L182.219 42.8745Z" />
            <path d="M183.75 45.554L185.076 46.3196V47.8508L183.75 48.6165L182.424 47.8508V46.3196L183.75 45.554Z" />
            <path d="M151.594 26.7974L152.92 27.563V29.0942L151.594 29.8599L150.267 29.0942V27.563L151.594 26.7974Z" />
            <path d="M185.281 48.2341L186.607 48.9998V50.531L185.281 51.2966L183.955 50.531V48.9998L185.281 48.2341Z" />
            <path d="M186.812 50.9142L188.138 51.6798V53.2111L186.812 53.9767L185.486 53.2111V51.6798L186.812 50.9142Z" />
            <path d="M185.281 53.5938L186.607 54.3594V55.8906L185.281 56.6562L183.955 55.8906V54.3594L185.281 53.5938Z" />
            <path d="M188.344 32.1564L189.67 32.922V34.4532L188.344 35.2189L187.018 34.4532V32.922L188.344 32.1564Z" />
            <path d="M180.687 8.03955L182.013 8.80518V10.3364L180.687 11.102L179.361 10.3364V8.80518L180.687 8.03955Z" />
            <path d="M186.812 34.8361L188.138 35.6017V37.1329L186.812 37.8986L185.486 37.1329V35.6017L186.812 34.8361Z" />
            <path d="M179.156 10.7189L180.482 11.4845V13.0157L179.156 13.7814L177.83 13.0157V11.4845L179.156 10.7189Z" />
            <path d="M188.344 37.5156L189.67 38.2812V39.8125L188.344 40.5781L187.018 39.8125V38.2812L188.344 37.5156Z" />
            <path d="M180.687 13.3982L182.013 14.1638V15.6951L180.687 16.4607L179.361 15.6951V14.1638L180.687 13.3982Z" />
            <path d="M189.875 40.1957L191.201 40.9613V42.4926L189.875 43.2582L188.549 42.4926V40.9613L189.875 40.1957Z" />
            <path d="M191.407 42.8745L192.733 43.6401V45.1714L191.407 45.937L190.08 45.1714V43.6401L191.407 42.8745Z" />
            <path d="M173.031 32.1564L174.357 32.922V34.4532L173.031 35.2189L171.705 34.4532V32.922L173.031 32.1564Z" />
            <path d="M182.219 32.1564L183.545 32.922V34.4532L182.219 35.2189L180.893 34.4532V32.922L182.219 32.1564Z" />
            <path d="M174.562 8.03955L175.889 8.80518V10.3364L174.562 11.102L173.236 10.3364V8.80518L174.562 8.03955Z" />
            <path d="M173.031 10.7189L174.357 11.4845V13.0157L173.031 13.7814L171.705 13.0157V11.4845L173.031 10.7189Z" />
            <path d="M182.219 37.5156L183.545 38.2812V39.8125L182.219 40.5781L180.893 39.8125V38.2812L182.219 37.5156Z" />
            <path d="M174.562 13.3982L175.889 14.1638V15.6951L174.562 16.4607L173.236 15.6951V14.1638L174.562 13.3982Z" />
            <path d="M183.75 40.1957L185.076 40.9613V42.4926L183.75 43.2582L182.424 42.4926V40.9613L183.75 40.1957Z" />
            <path d="M185.281 42.8745L186.607 43.6401V45.1714L185.281 45.937L183.955 45.1714V43.6401L185.281 42.8745Z" />
            <path d="M186.812 45.554L188.138 46.3196V47.8508L186.812 48.6165L185.486 47.8508V46.3196L186.812 45.554Z" />
            <path d="M188.344 48.2341L189.67 48.9998V50.531L188.344 51.2966L187.018 50.531V48.9998L188.344 48.2341Z" />
            <path d="M188.344 53.5938L189.67 54.3594V55.8906L188.344 56.6562L187.018 55.8906V54.3594L188.344 53.5938Z" />
            <path d="M189.875 50.9142L191.201 51.6798V53.2111L189.875 53.9767L188.549 53.2111V51.6798L189.875 50.9142Z" />
            <path d="M189.875 45.554L191.201 46.3196V47.8508L189.875 48.6165L188.549 47.8508V46.3196L189.875 45.554Z" />
            <path d="M191.407 48.2341L192.733 48.9998V50.531L191.407 51.2966L190.08 50.531V48.9998L191.407 48.2341Z" />
            <path d="M191.407 53.5938L192.733 54.3594V55.8906L191.407 56.6562L190.08 55.8906V54.3594L191.407 53.5938Z" />
            <path d="M192.938 50.9142L194.264 51.6798V53.2111L192.938 53.9767L191.612 53.2111V51.6798L192.938 50.9142Z" />
            <path d="M194.469 42.8745L195.795 43.6401V45.1714L194.469 45.937L193.143 45.1714V43.6401L194.469 42.8745Z" />
            <path d="M192.938 45.554L194.264 46.3196V47.8508L192.938 48.6165L191.612 47.8508V46.3196L192.938 45.554Z" />
            <path d="M194.469 48.2341L195.795 48.9998V50.531L194.469 51.2966L193.143 50.531V48.9998L194.469 48.2341Z" />
            <path d="M194.469 53.5938L195.795 54.3594V55.8906L194.469 56.6562L193.143 55.8906V54.3594L194.469 53.5938Z" />
            <path d="M215.906 26.7974L217.232 27.563V29.0942L215.906 29.8599L214.58 29.0942V27.563L215.906 26.7974Z" />
            <path d="M192.938 34.8361L194.264 35.6017V37.1329L192.938 37.8986L191.612 37.1329V35.6017L192.938 34.8361Z" />
            <path d="M191.407 32.1564L192.733 32.922V34.4532L191.407 35.2189L190.08 34.4532V32.922L191.407 32.1564Z" />
            <path d="M183.75 8.03955L185.076 8.80518V10.3364L183.75 11.102L182.424 10.3364V8.80518L183.75 8.03955Z" />
            <path d="M189.875 34.8361L191.201 35.6017V37.1329L189.875 37.8986L188.549 37.1329V35.6017L189.875 34.8361Z" />
            <path d="M191.407 37.5156L192.733 38.2812V39.8125L191.407 40.5781L190.08 39.8125V38.2812L191.407 37.5156Z" />
            <path d="M192.938 29.4764L194.264 30.2421V31.7733L192.938 32.5389L191.612 31.7733V30.2421L192.938 29.4764Z" />
            <path d="M194.469 32.1564L195.795 32.922V34.4532L194.469 35.2189L193.143 34.4532V32.922L194.469 32.1564Z" />
            <path d="M194.469 37.5156L195.795 38.2812V39.8125L194.469 40.5781L193.143 39.8125V38.2812L194.469 37.5156Z" />
            <path d="M212.844 26.7974L214.17 27.563V29.0942L212.844 29.8599L211.518 29.0942V27.563L212.844 26.7974Z" />
            <path d="M211.313 24.1174L212.639 24.8831V26.4143L211.313 27.1799L209.987 26.4143V24.8831L211.313 24.1174Z" />
            <path d="M209.781 21.4375L211.107 22.2031V23.7344L209.781 24.5L208.455 23.7344V22.2031L209.781 21.4375Z" />
            <path d="M211.313 18.7571L212.639 19.5227V21.054L211.313 21.8196L209.987 21.054V19.5227L211.313 18.7571Z" />
            <path d="M212.844 16.0773L214.17 16.8429V18.3741L212.844 19.1398L211.518 18.3741V16.8429L212.844 16.0773Z" />
            <path d="M215.906 16.0773L217.232 16.8429V18.3741L215.906 19.1398L214.58 18.3741V16.8429L215.906 16.0773Z" />
            <path d="M218.969 16.0773L220.295 16.8429V18.3741L218.969 19.1398L217.643 18.3741V16.8429L218.969 16.0773Z" />
            <path d="M192.938 40.1957L194.264 40.9613V42.4926L192.938 43.2582L191.612 42.4926V40.9613L192.938 40.1957Z" />
            <path d="M191.407 26.7974L192.733 27.563V29.0942L191.407 29.8599L190.08 29.0942V27.563L191.407 26.7974Z" />
            <path d="M176.094 26.7974L177.42 27.563V29.0942L176.094 29.8599L174.768 29.0942V27.563L176.094 26.7974Z" />
            <path d="M168.438 50.9142L169.764 51.6798V53.2111L168.438 53.9767L167.112 53.2111V51.6798L168.438 50.9142Z" />
            <path d="M159.25 50.9142L160.576 51.6798V53.2111L159.25 53.9767L157.923 53.2111V51.6798L159.25 50.9142Z" />
            <path d="M160.781 42.8745L162.107 43.6401V45.1714L160.781 45.937L159.455 45.1714V43.6401L160.781 42.8745Z" />
            <path d="M137.812 34.8361L139.139 35.6017V37.1329L137.812 37.8986L136.486 37.1329V35.6017L137.812 34.8361Z" />
            <path d="M174.562 50.9142L175.889 51.6798V53.2111L174.562 53.9767L173.236 53.2111V51.6798L174.562 50.9142Z" />
            <path d="M140.875 34.8361L142.201 35.6017V37.1329L140.875 37.8986L139.549 37.1329V35.6017L140.875 34.8361Z" />
            <path d="M176.094 53.5938L177.42 54.3594V55.8906L176.094 56.6562L174.768 55.8906V54.3594L176.094 53.5938Z" />
            <path d="M142.406 37.5156L143.732 38.2812V39.8125L142.406 40.5781L141.08 39.8125V38.2812L142.406 37.5156Z" />
            <path d="M166.906 42.8745L168.232 43.6401V45.1714L166.906 45.937L165.58 45.1714V43.6401L166.906 42.8745Z" />
            <path d="M173.031 37.5156L174.357 38.2812V39.8125L173.031 40.5781L171.705 39.8125V38.2812L173.031 37.5156Z" />
            <path d="M182.219 26.7974L183.545 27.563V29.0942L182.219 29.8599L180.893 29.0942V27.563L182.219 26.7974Z" />
            <path d="M173.031 48.2341L174.357 48.9998V50.531L173.031 51.2966L171.705 50.531V48.9998L173.031 48.2341Z" />
            <path d="M174.562 45.554L175.889 46.3196V47.8508L174.562 48.6165L173.236 47.8508V46.3196L174.562 45.554Z" />
            <path d="M176.094 48.2341L177.42 48.9998V50.531L176.094 51.2966L174.768 50.531V48.9998L176.094 48.2341Z" />
            <path d="M143.937 34.8361L145.264 35.6017V37.1329L143.937 37.8986L142.611 37.1329V35.6017L143.937 34.8361Z" />
            <path d="M179.156 53.5938L180.482 54.3594V55.8906L179.156 56.6562L177.83 55.8906V54.3594L179.156 53.5938Z" />
            <path d="M145.469 37.5156L146.795 38.2812V39.8125L145.469 40.5781L144.143 39.8125V38.2812L145.469 37.5156Z" />
            <path d="M169.969 53.5938L171.295 54.3594V55.8906L169.969 56.6562L168.642 55.8906V54.3594L169.969 53.5938Z" />
            <path d="M168.438 40.1957L169.764 40.9613V42.4926L168.438 43.2582L167.112 42.4926V40.9613L168.438 40.1957Z" />
            <path d="M165.375 56.2731L166.701 57.0387V58.5699L165.375 59.3356L164.049 58.5699V57.0387L165.375 56.2731Z" />
            <path d="M171.5 56.2731L172.826 57.0387V58.5699L171.5 59.3356L170.174 58.5699V57.0387L171.5 56.2731Z" />
            <path d="M177.625 56.2731L178.951 57.0387V58.5699L177.625 59.3356L176.299 58.5699V57.0387L177.625 56.2731Z" />
            <path d="M174.562 40.1957L175.889 40.9613V42.4926L174.562 43.2582L173.236 42.4926V40.9613L174.562 40.1957Z" />
            <path d="M176.094 42.8745L177.42 43.6401V45.1714L176.094 45.937L174.768 45.1714V43.6401L176.094 42.8745Z" />
            <path d="M177.625 45.554L178.951 46.3196V47.8508L177.625 48.6165L176.299 47.8508V46.3196L177.625 45.554Z" />
            <path d="M180.687 50.9142L182.013 51.6798V53.2111L180.687 53.9767L179.361 53.2111V51.6798L180.687 50.9142Z" />
            <path d="M147 34.8361L148.326 35.6017V37.1329L147 37.8986L145.674 37.1329V35.6017L147 34.8361Z" />
            <path d="M182.219 53.5938L183.545 54.3594V55.8906L182.219 56.6562L180.893 55.8906V54.3594L182.219 53.5938Z" />
            <path d="M157.719 48.2341L159.045 48.9998V50.531L157.719 51.2966L156.392 50.531V48.9998L157.719 48.2341Z" />
            <path d="M156.187 45.554L157.513 46.3196V47.8508L156.187 48.6165L154.861 47.8508V46.3196L156.187 45.554Z" />
            <path d="M156.187 50.9142L157.513 51.6798V53.2111L156.187 53.9767L154.861 53.2111V51.6798L156.187 50.9142Z" />
            <path d="M153.125 50.9142L154.451 51.6798V53.2111L153.125 53.9767L151.799 53.2111V51.6798L153.125 50.9142Z" />
            <path d="M154.656 53.5938L155.982 54.3594V55.8906L154.656 56.6562L153.33 55.8906V54.3594L154.656 53.5938Z" />
            <path d="M151.594 53.5938L152.92 54.3594V55.8906L151.594 56.6562L150.267 55.8906V54.3594L151.594 53.5938Z" />
            <path d="M153.125 56.2731L154.451 57.0387V58.5699L153.125 59.3356L151.799 58.5699V57.0387L153.125 56.2731Z" />
            <path d="M159.25 56.2731L160.576 57.0387V58.5699L159.25 59.3356L157.923 58.5699V57.0387L159.25 56.2731Z" />
            <path d="M183.75 56.2731L185.076 57.0387V58.5699L183.75 59.3356L182.424 58.5699V57.0387L183.75 56.2731Z" />
            <path d="M189.875 56.2731L191.201 57.0387V58.5699L189.875 59.3356L188.549 58.5699V57.0387L189.875 56.2731Z" />
            <path d="M168.438 56.2731L169.764 57.0387V58.5699L168.438 59.3356L167.112 58.5699V57.0387L168.438 56.2731Z" />
            <path d="M174.562 56.2731L175.889 57.0387V58.5699L174.562 59.3356L173.236 58.5699V57.0387L174.562 56.2731Z" />
            <path d="M180.687 56.2731L182.013 57.0387V58.5699L180.687 59.3356L179.361 58.5699V57.0387L180.687 56.2731Z" />
            <path d="M160.781 53.5938L162.107 54.3594V55.8906L160.781 56.6562L159.455 55.8906V54.3594L160.781 53.5938Z" />
            <path d="M157.719 53.5938L159.045 54.3594V55.8906L157.719 56.6562L156.392 55.8906V54.3594L157.719 53.5938Z" />
            <path d="M186.812 56.2731L188.138 57.0387V58.5699L186.812 59.3356L185.486 58.5699V57.0387L186.812 56.2731Z" />
            <path d="M169.969 37.5156L171.295 38.2812V39.8125L169.969 40.5781L168.642 39.8125V38.2812L169.969 37.5156Z" />
            <path d="M179.156 26.7974L180.482 27.563V29.0942L179.156 29.8599L177.83 29.0942V27.563L179.156 26.7974Z" />
            <path d="M166.906 53.5938L168.232 54.3594V55.8906L166.906 56.6562L165.58 55.8906V54.3594L166.906 53.5938Z" />
            <path d="M192.938 56.2731L194.264 57.0387V58.5699L192.938 59.3356L191.612 58.5699V57.0387L192.938 56.2731Z" />
            <path d="M165.375 40.1957L166.701 40.9613V42.4926L165.375 43.2582L164.049 42.4926V40.9613L165.375 40.1957Z" />
            <path d="M203.656 37.5156L204.982 38.2812V39.8125L203.656 40.5781L202.33 39.8125V38.2812L203.656 37.5156Z" />
            <path d="M205.188 40.1957L206.514 40.9613V42.4926L205.188 43.2582L203.862 42.4926V40.9613L205.188 40.1957Z" />
            <path d="M205.188 29.4764L206.514 30.2421V31.7733L205.188 32.5389L203.862 31.7733V30.2421L205.188 29.4764Z" />
            <path d="M206.719 42.8745L208.045 43.6401V45.1714L206.719 45.937L205.393 45.1714V43.6401L206.719 42.8745Z" />
            <path d="M206.719 32.1564L208.045 32.922V34.4532L206.719 35.2189L205.393 34.4532V32.922L206.719 32.1564Z" />
            <path d="M200.594 42.8745L201.92 43.6401V45.1714L200.594 45.937L199.268 45.1714V43.6401L200.594 42.8745Z" />
            <path d="M200.594 32.1564L201.92 32.922V34.4532L200.594 35.2189L199.268 34.4532V32.922L200.594 32.1564Z" />
            <path d="M202.125 34.8361L203.451 35.6017V37.1329L202.125 37.8986L200.799 37.1329V35.6017L202.125 34.8361Z" />
            <path d="M206.719 37.5156L208.045 38.2812V39.8125L206.719 40.5781L205.393 39.8125V38.2812L206.719 37.5156Z" />
            <path d="M208.25 40.1957L209.576 40.9613V42.4926L208.25 43.2582L206.924 42.4926V40.9613L208.25 40.1957Z" />
            <path d="M209.781 42.8745L211.107 43.6401V45.1714L209.781 45.937L208.455 45.1714V43.6401L209.781 42.8745Z" />
            <path d="M209.781 32.1564L211.107 32.922V34.4532L209.781 35.2189L208.455 34.4532V32.922L209.781 32.1564Z" />
            <path d="M200.594 37.5156L201.92 38.2812V39.8125L200.594 40.5781L199.268 39.8125V38.2812L200.594 37.5156Z" />
            <path d="M202.125 40.1957L203.451 40.9613V42.4926L202.125 43.2582L200.799 42.4926V40.9613L202.125 40.1957Z" />
            <path d="M202.125 29.4764L203.451 30.2421V31.7733L202.125 32.5389L200.799 31.7733V30.2421L202.125 29.4764Z" />
            <path d="M199.063 40.1957L200.389 40.9613V42.4926L199.063 43.2582L197.737 42.4926V40.9613L199.063 40.1957Z" />
            <path d="M197.532 42.8745L198.858 43.6401V45.1714L197.532 45.937L196.205 45.1714V43.6401L197.532 42.8745Z" />
            <path d="M203.656 42.8745L204.982 43.6401V45.1714L203.656 45.937L202.33 45.1714V43.6401L203.656 42.8745Z" />
            <path d="M203.656 32.1564L204.982 32.922V34.4532L203.656 35.2189L202.33 34.4532V32.922L203.656 32.1564Z" />
            <path d="M205.188 34.8361L206.514 35.6017V37.1329L205.188 37.8986L203.862 37.1329V35.6017L205.188 34.8361Z" />
            <path d="M208.25 34.8361L209.576 35.6017V37.1329L208.25 37.8986L206.924 37.1329V35.6017L208.25 34.8361Z" />
            <path d="M196 40.1957L197.326 40.9613V42.4926L196 43.2582L194.674 42.4926V40.9613L196 40.1957Z" />
            <path d="M214.375 40.1957L215.701 40.9613V42.4926L214.375 43.2582L213.049 42.4926V40.9613L214.375 40.1957Z" />
            <path d="M214.375 29.4764L215.701 30.2421V31.7733L214.375 32.5389L213.049 31.7733V30.2421L214.375 29.4764Z" />
            <path d="M214.375 34.8361L215.701 35.6017V37.1329L214.375 37.8986L213.049 37.1329V35.6017L214.375 34.8361Z" />
            <path d="M197.532 37.5156L198.858 38.2812V39.8125L197.532 40.5781L196.205 39.8125V38.2812L197.532 37.5156Z" />
            <path d="M199.063 34.8361L200.389 35.6017V37.1329L199.063 37.8986L197.737 37.1329V35.6017L199.063 34.8361Z" />
            <path d="M197.532 32.1564L198.858 32.922V34.4532L197.532 35.2189L196.205 34.4532V32.922L197.532 32.1564Z" />
            <path d="M209.781 37.5156L211.107 38.2812V39.8125L209.781 40.5781L208.455 39.8125V38.2812L209.781 37.5156Z" />
            <path d="M212.844 37.5156L214.17 38.2812V39.8125L212.844 40.5781L211.518 39.8125V38.2812L212.844 37.5156Z" />
            <path d="M211.313 40.1957L212.639 40.9613V42.4926L211.313 43.2582L209.987 42.4926V40.9613L211.313 40.1957Z" />
            <path d="M211.313 29.4764L212.639 30.2421V31.7733L211.313 32.5389L209.987 31.7733V30.2421L211.313 29.4764Z" />
            <path d="M212.844 32.1564L214.17 32.922V34.4532L212.844 35.2189L211.518 34.4532V32.922L212.844 32.1564Z" />
            <path d="M211.313 34.8361L212.639 35.6017V37.1329L211.313 37.8986L209.987 37.1329V35.6017L211.313 34.8361Z" />
            <path d="M212.844 42.8745L214.17 43.6401V45.1714L212.844 45.937L211.518 45.1714V43.6401L212.844 42.8745Z" />
            <path d="M165.375 61.6324L166.701 62.3981V63.9293L165.375 64.6949L164.049 63.9293V62.3981L165.375 61.6324Z" />
            <path d="M171.5 61.6324L172.826 62.3981V63.9293L171.5 64.6949L170.174 63.9293V62.3981L171.5 61.6324Z" />
            <path d="M177.625 61.6324L178.951 62.3981V63.9293L177.625 64.6949L176.299 63.9293V62.3981L177.625 61.6324Z" />
            <path d="M183.75 61.6324L185.076 62.3981V63.9293L183.75 64.6949L182.424 63.9293V62.3981L183.75 61.6324Z" />
            <path d="M168.438 61.6324L169.764 62.3981V63.9293L168.438 64.6949L167.112 63.9293V62.3981L168.438 61.6324Z" />
            <path d="M176.094 75.0316L177.42 75.7972V77.3285L176.094 78.0941L174.768 77.3285V75.7972L176.094 75.0316Z" />
            <path d="M174.562 61.6324L175.889 62.3981V63.9293L174.562 64.6949L173.236 63.9293V62.3981L174.562 61.6324Z" />
            <path d="M182.219 75.0316L183.545 75.7972V77.3285L182.219 78.0941L180.893 77.3285V75.7972L182.219 75.0316Z" />
            <path d="M180.687 61.6324L182.013 62.3981V63.9293L180.687 64.6949L179.361 63.9293V62.3981L180.687 61.6324Z" />
            <path d="M165.375 66.9913L166.701 67.757V69.2882L165.375 70.0538L164.049 69.2882V67.757L165.375 66.9913Z" />
            <path d="M171.5 66.9913L172.826 67.757V69.2882L171.5 70.0538L170.174 69.2882V67.757L171.5 66.9913Z" />
            <path d="M168.438 72.351L169.764 73.1166V74.6478L168.438 75.4135L167.112 74.6478V73.1166L168.438 72.351Z" />
            <path d="M166.906 58.9521L168.232 59.7178V61.249L166.906 62.0146L165.58 61.249V59.7178L166.906 58.9521Z" />
            <path d="M177.625 66.9913L178.951 67.757V69.2882L177.625 70.0538L176.299 69.2882V67.757L177.625 66.9913Z" />
            <path d="M174.562 72.351L175.889 73.1166V74.6478L174.562 75.4135L173.236 74.6478V73.1166L174.562 72.351Z" />
            <path d="M173.031 58.9521L174.357 59.7178V61.249L173.031 62.0146L171.705 61.249V59.7178L173.031 58.9521Z" />
            <path d="M183.75 66.9913L185.076 67.757V69.2882L183.75 70.0538L182.424 69.2882V67.757L183.75 66.9913Z" />
            <path d="M180.687 72.351L182.013 73.1166V74.6478L180.687 75.4135L179.361 74.6478V73.1166L180.687 72.351Z" />
            <path d="M179.156 58.9521L180.482 59.7178V61.249L179.156 62.0146L177.83 61.249V59.7178L179.156 58.9521Z" />
            <path d="M168.438 66.9913L169.764 67.757V69.2882L168.438 70.0538L167.112 69.2882V67.757L168.438 66.9913Z" />
            <path d="M163.844 58.9521L165.17 59.7178V61.249L163.844 62.0146L162.517 61.249V59.7178L163.844 58.9521Z" />
            <path d="M162.312 61.6324L163.638 62.3981V63.9293L162.312 64.6949L160.986 63.9293V62.3981L162.312 61.6324Z" />
            <path d="M163.844 64.3124L165.17 65.078V66.6093L163.844 67.3749L162.517 66.6093V65.078L163.844 64.3124Z" />
            <path d="M160.781 64.3124L162.107 65.078V66.6093L160.781 67.3749L159.455 66.6093V65.078L160.781 64.3124Z" />
            <path d="M162.312 66.9913L163.638 67.757V69.2882L162.312 70.0538L160.986 69.2882V67.757L162.312 66.9913Z" />
            <path d="M159.25 66.9913L160.576 67.757V69.2882L159.25 70.0538L157.923 69.2882V67.757L159.25 66.9913Z" />
            <path d="M160.781 69.672L162.107 70.4376V71.9689L160.781 72.7345L159.455 71.9689V70.4376L160.781 69.672Z" />
            <path d="M157.719 69.672L159.045 70.4376V71.9689L157.719 72.7345L156.392 71.9689V70.4376L157.719 69.672Z" />
            <path d="M156.187 72.351L157.513 73.1166V74.6478L156.187 75.4135L154.861 74.6478V73.1166L156.187 72.351Z" />
            <path d="M157.719 75.0316L159.045 75.7972V77.3285L157.719 78.0941L156.392 77.3285V75.7972L157.719 75.0316Z" />
            <path d="M156.187 77.7106L157.513 78.4762V80.0074L156.187 80.7731L154.861 80.0074V78.4762L156.187 77.7106Z" />
            <path d="M159.25 72.351L160.576 73.1166V74.6478L159.25 75.4135L157.923 74.6478V73.1166L159.25 72.351Z" />
            <path d="M151.594 75.0316L152.92 75.7972V77.3285L151.594 78.0941L150.267 77.3285V75.7972L151.594 75.0316Z" />
            <path d="M154.656 69.672L155.982 70.4376V71.9689L154.656 72.7345L153.33 71.9689V70.4376L154.656 69.672Z" />
            <path d="M153.125 72.351L154.451 73.1166V74.6478L153.125 75.4135L151.799 74.6478V73.1166L153.125 72.351Z" />
            <path d="M154.656 75.0316L155.982 75.7972V77.3285L154.656 78.0941L153.33 77.3285V75.7972L154.656 75.0316Z" />
            <path d="M159.25 61.6324L160.576 62.3981V63.9293L159.25 64.6949L157.923 63.9293V62.3981L159.25 61.6324Z" />
            <path d="M156.187 61.6324L157.513 62.3981V63.9293L156.187 64.6949L154.861 63.9293V62.3981L156.187 61.6324Z" />
            <path d="M174.562 66.9913L175.889 67.757V69.2882L174.562 70.0538L173.236 69.2882V67.757L174.562 66.9913Z" />
            <path d="M169.969 58.9521L171.295 59.7178V61.249L169.969 62.0146L168.642 61.249V59.7178L169.969 58.9521Z" />
            <path d="M180.687 66.9913L182.013 67.757V69.2882L180.687 70.0538L179.361 69.2882V67.757L180.687 66.9913Z" />
            <path d="M177.625 72.351L178.951 73.1166V74.6478L177.625 75.4135L176.299 74.6478V73.1166L177.625 72.351Z" />
            <path d="M176.094 58.9521L177.42 59.7178V61.249L176.094 62.0146L174.768 61.249V59.7178L176.094 58.9521Z" />
            <path d="M183.75 72.351L185.076 73.1166V74.6478L183.75 75.4135L182.424 74.6478V73.1166L183.75 72.351Z" />
            <path d="M182.219 58.9521L183.545 59.7178V61.249L182.219 62.0146L180.893 61.249V59.7178L182.219 58.9521Z" />
            <path d="M169.969 64.3124L171.295 65.078V66.6093L169.969 67.3749L168.642 66.6093V65.078L169.969 64.3124Z" />
            <path d="M176.094 64.3124L177.42 65.078V66.6093L176.094 67.3749L174.768 66.6093V65.078L176.094 64.3124Z" />
            <path d="M173.031 69.672L174.357 70.4376V71.9689L173.031 72.7345L171.705 71.9689V70.4376L173.031 69.672Z" />
            <path d="M182.219 64.3124L183.545 65.078V66.6093L182.219 67.3749L180.893 66.6093V65.078L182.219 64.3124Z" />
            <path d="M179.156 69.672L180.482 70.4376V71.9689L179.156 72.7345L177.83 71.9689V70.4376L179.156 69.672Z" />
            <path d="M157.719 58.9521L159.045 59.7178V61.249L157.719 62.0146L156.392 61.249V59.7178L157.719 58.9521Z" />
            <path d="M166.906 64.3124L168.232 65.078V66.6093L166.906 67.3749L165.58 66.6093V65.078L166.906 64.3124Z" />
            <path d="M163.844 69.672L165.17 70.4376V71.9689L163.844 72.7345L162.517 71.9689V70.4376L163.844 69.672Z" />
            <path d="M173.031 64.3124L174.357 65.078V66.6093L173.031 67.3749L171.705 66.6093V65.078L173.031 64.3124Z" />
            <path d="M169.969 69.672L171.295 70.4376V71.9689L169.969 72.7345L168.642 71.9689V70.4376L169.969 69.672Z" />
            <path d="M179.156 64.3124L180.482 65.078V66.6093L179.156 67.3749L177.83 66.6093V65.078L179.156 64.3124Z" />
            <path d="M176.094 69.672L177.42 70.4376V71.9689L176.094 72.7345L174.768 71.9689V70.4376L176.094 69.672Z" />
            <path d="M182.219 69.672L183.545 70.4376V71.9689L182.219 72.7345L180.893 71.9689V70.4376L182.219 69.672Z" />
            <path d="M212.844 58.9521L214.17 59.7178V61.249L212.844 62.0146L211.518 61.249V59.7178L212.844 58.9521Z" />
            <path d="M209.781 58.9521L211.107 59.7178V61.249L209.781 62.0146L208.455 61.249V59.7178L209.781 58.9521Z" />
            <path d="M211.313 56.2731L212.639 57.0387V58.5699L211.313 59.3356L209.987 58.5699V57.0387L211.313 56.2731Z" />
            <path d="M214.375 56.2731L215.701 57.0387V58.5699L214.375 59.3356L213.049 58.5699V57.0387L214.375 56.2731Z" />
          </g>
          <g
            id="South America"
            fill={
              highlight.includes('South America')
                ? highlightColor
                : defaultColor
            }
            onClick={() => {
              onClick('South America');
            }}
          >
            <path d="M110.25 136.664L111.576 137.43V138.961L110.25 139.727L108.924 138.961V137.43L110.25 136.664Z" />
            <path d="M110.25 147.383L111.576 148.148V149.68L110.25 150.445L108.924 149.68V148.148L110.25 147.383Z" />
            <path d="M108.719 123.265L110.045 124.031V125.562L108.719 126.328L107.393 125.562V124.031L108.719 123.265Z" />
            <path d="M116.375 136.664L117.701 137.43V138.961L116.375 139.727L115.049 138.961V137.43L116.375 136.664Z" />
            <path d="M116.375 147.383L117.701 148.148V149.68L116.375 150.445L115.049 149.68V148.148L116.375 147.383Z" />
            <path d="M117.906 133.985L119.232 134.75V136.282L117.906 137.047L116.58 136.282V134.75L117.906 133.985Z" />
            <path d="M124.031 133.985L125.357 134.75V136.282L124.031 137.047L122.705 136.282V134.75L124.031 133.985Z" />
            <path d="M117.906 144.703L119.232 145.469V147L117.906 147.766L116.58 147V145.469L117.906 144.703Z" />
            <path d="M119.437 142.024L120.764 142.789V144.321L119.437 145.086L118.111 144.321V142.789L119.437 142.024Z" />
            <path d="M120.969 144.703L122.295 145.469V147L120.969 147.766L119.643 147V145.469L120.969 144.703Z" />
            <path d="M119.437 136.664L120.764 137.43V138.961L119.437 139.727L118.111 138.961V137.43L119.437 136.664Z" />
            <path d="M119.437 147.383L120.764 148.148V149.68L119.437 150.445L118.111 149.68V148.148L119.437 147.383Z" />
            <path d="M85.75 136.664L87.0761 137.43V138.961L85.75 139.727L84.4239 138.961V137.43L85.75 136.664Z" />
            <path d="M84.2189 123.265L85.545 124.031V125.562L84.2189 126.328L82.8928 125.562V124.031L84.2189 123.265Z" />
            <path d="M93.4064 160.781L94.7325 161.547V163.078L93.4064 163.844L92.0803 163.078V161.547L93.4064 160.781Z" />
            <path d="M88.8125 184.899L90.1386 185.664V187.195L88.8125 187.961L87.4864 187.195V185.664L88.8125 184.899Z" />
            <path d="M91.875 136.664L93.2011 137.43V138.961L91.875 139.727L90.5489 138.961V137.43L91.875 136.664Z" />
            <path d="M91.875 147.383L93.2011 148.148V149.68L91.875 150.445L90.5489 149.68V148.148L91.875 147.383Z" />
            <path d="M87.2814 171.5L88.6075 172.266V173.797L87.2814 174.563L85.9553 173.797V172.266L87.2814 171.5Z" />
            <path d="M90.3439 123.265L91.67 124.031V125.562L90.3439 126.328L89.0178 125.562V124.031L90.3439 123.265Z" />
            <path d="M99.5314 160.781L100.858 161.547V163.078L99.5314 163.844L98.2053 163.078V161.547L99.5314 160.781Z" />
            <path d="M94.9378 184.899L96.2639 185.664V187.195L94.9378 187.961L93.6117 187.195V185.664L94.9378 184.899Z" />
            <path d="M98.0001 136.664L99.3262 137.43V138.961L98.0001 139.727L96.674 138.961V137.43L98.0001 136.664Z" />
            <path d="M98.0001 147.383L99.3262 148.148V149.68L98.0001 150.445L96.674 149.68V148.148L98.0001 147.383Z" />
            <path d="M93.4064 171.5L94.7325 172.266V173.797L93.4064 174.563L92.0803 173.797V172.266L93.4064 171.5Z" />
            <path d="M96.4689 123.265L97.795 124.031V125.562L96.4689 126.328L95.1428 125.562V124.031L96.4689 123.265Z" />
            <path d="M96.4689 112.546L97.795 113.312V114.843L96.4689 115.609L95.1428 114.843V113.312L96.4689 112.546Z" />
            <path d="M105.656 160.781L106.983 161.547V163.078L105.656 163.844L104.33 163.078V161.547L105.656 160.781Z" />
            <path d="M104.125 136.664L105.451 137.43V138.961L104.125 139.727L102.799 138.961V137.43L104.125 136.664Z" />
            <path d="M104.125 147.383L105.451 148.148V149.68L104.125 150.445L102.799 149.68V148.148L104.125 147.383Z" />
            <path d="M99.5314 171.5L100.858 172.266V173.797L99.5314 174.563L98.2053 173.797V172.266L99.5314 171.5Z" />
            <path d="M102.594 123.265L103.92 124.031V125.562L102.594 126.328L101.268 125.562V124.031L102.594 123.265Z" />
            <path d="M81.1565 123.265L82.4826 124.031V125.562L81.1565 126.328L79.8304 125.562V124.031L81.1565 123.265Z" />
            <path d="M82.6876 136.664L84.0137 137.43V138.961L82.6876 139.727L81.3615 138.961V137.43L82.6876 136.664Z" />
            <path d="M90.3439 160.781L91.67 161.547V163.078L90.3439 163.844L89.0178 163.078V161.547L90.3439 160.781Z" />
            <path d="M88.8125 136.664L90.1386 137.43V138.961L88.8125 139.727L87.4864 138.961V137.43L88.8125 136.664Z" />
            <path d="M88.8125 147.383L90.1386 148.148V149.68L88.8125 150.445L87.4864 149.68V148.148L88.8125 147.383Z" />
            <path d="M87.2814 123.265L88.6075 124.031V125.562L87.2814 126.328L85.9553 125.562V124.031L87.2814 123.265Z" />
            <path d="M96.4689 160.781L97.795 161.547V163.078L96.4689 163.844L95.1428 163.078V161.547L96.4689 160.781Z" />
            <path d="M91.8753 184.899L93.2014 185.664V187.195L91.8753 187.961L90.5492 187.195V185.664L91.8753 184.899Z" />
            <path d="M94.9375 136.664L96.2636 137.43V138.961L94.9375 139.727L93.6114 138.961V137.43L94.9375 136.664Z" />
            <path d="M94.9375 147.383L96.2636 148.148V149.68L94.9375 150.445L93.6114 149.68V148.148L94.9375 147.383Z" />
            <path d="M90.3439 171.5L91.67 172.266V173.797L90.3439 174.563L89.0178 173.797V172.266L90.3439 171.5Z" />
            <path d="M93.4064 123.265L94.7325 124.031V125.562L93.4064 126.328L92.0803 125.562V124.031L93.4064 123.265Z" />
            <path d="M93.4064 112.546L94.7325 113.312V114.843L93.4064 115.609L92.0803 114.843V113.312L93.4064 112.546Z" />
            <path d="M102.594 160.781L103.92 161.547V163.078L102.594 163.844L101.268 163.078V161.547L102.594 160.781Z" />
            <path d="M101.063 136.664L102.389 137.43V138.961L101.063 139.727L99.7365 138.961V137.43L101.063 136.664Z" />
            <path d="M101.063 147.383L102.389 148.148V149.68L101.063 150.445L99.7365 149.68V148.148L101.063 147.383Z" />
            <path d="M96.4689 171.5L97.795 172.266V173.797L96.4689 174.563L95.1428 173.797V172.266L96.4689 171.5Z" />
            <path d="M99.5314 123.265L100.858 124.031V125.562L99.5314 126.328L98.2053 125.562V124.031L99.5314 123.265Z" />
            <path d="M78.094 123.265L79.4201 124.031V125.562L78.094 126.328L76.7679 125.562V124.031L78.094 123.265Z" />
            <path d="M108.719 160.781L110.045 161.547V163.078L108.719 163.844L107.393 163.078V161.547L108.719 160.781Z" />
            <path d="M107.187 136.664L108.514 137.43V138.961L107.187 139.727L105.861 138.961V137.43L107.187 136.664Z" />
            <path d="M107.187 147.383L108.514 148.148V149.68L107.187 150.445L105.861 149.68V148.148L107.187 147.383Z" />
            <path d="M102.594 171.5L103.92 172.266V173.797L102.594 174.563L101.268 173.797V172.266L102.594 171.5Z" />
            <path d="M105.656 123.265L106.983 124.031V125.562L105.656 126.328L104.33 125.562V124.031L105.656 123.265Z" />
            <path d="M113.312 136.664L114.639 137.43V138.961L113.312 139.727L111.986 138.961V137.43L113.312 136.664Z" />
            <path d="M113.312 147.383L114.639 148.148V149.68L113.312 150.445L111.986 149.68V148.148L113.312 147.383Z" />
            <path d="M84.2189 128.625L85.545 129.391V130.922L84.2189 131.688L82.8928 130.922V129.391L84.2189 128.625Z" />
            <path d="M81.1565 133.985L82.4826 134.75V136.282L81.1565 137.047L79.8304 136.282V134.75L81.1565 133.985Z" />
            <path d="M93.4064 166.141L94.7325 166.907V168.438L93.4064 169.203L92.0803 168.438V166.907L93.4064 166.141Z" />
            <path d="M94.9378 195.617L96.2639 196.383V197.914L94.9378 198.68L93.6117 197.914V196.383L94.9378 195.617Z" />
            <path d="M91.875 152.742L93.2011 153.508V155.039L91.875 155.805L90.5489 155.039V153.508L91.875 152.742Z" />
            <path d="M90.3439 128.625L91.67 129.391V130.922L90.3439 131.688L89.0178 130.922V129.391L90.3439 128.625Z" />
            <path d="M88.8125 158.102L90.1386 158.867V160.398L88.8125 161.164L87.4864 160.398V158.867L88.8125 158.102Z" />
            <path d="M87.2814 133.985L88.6075 134.75V136.282L87.2814 137.047L85.9553 136.282V134.75L87.2814 133.985Z" />
            <path d="M87.2814 144.703L88.6075 145.469V147L87.2814 147.766L85.9553 147V145.469L87.2814 144.703Z" />
            <path d="M85.75 120.586L87.0761 121.351V122.883L85.75 123.648L84.4239 122.883V121.351L85.75 120.586Z" />
            <path d="M99.5314 166.141L100.858 166.907V168.438L99.5314 169.203L98.2053 168.438V166.907L99.5314 166.141Z" />
            <path d="M94.9378 190.257L96.2639 191.023V192.554L94.9378 193.32L93.6117 192.554V191.023L94.9378 190.257Z" />
            <path d="M98.0001 152.742L99.3262 153.508V155.039L98.0001 155.805L96.674 155.039V153.508L98.0001 152.742Z" />
            <path d="M93.4064 176.859L94.7325 177.625V179.156L93.4064 179.922L92.0803 179.156V177.625L93.4064 176.859Z" />
            <path d="M96.4689 128.625L97.795 129.391V130.922L96.4689 131.688L95.1428 130.922V129.391L96.4689 128.625Z" />
            <path d="M94.9375 158.102L96.2636 158.867V160.398L94.9375 161.164L93.6114 160.398V158.867L94.9375 158.102Z" />
            <path d="M90.3439 182.219L91.67 182.985V184.516L90.3439 185.282L89.0178 184.516V182.985L90.3439 182.219Z" />
            <path d="M93.4064 133.985L94.7325 134.75V136.282L93.4064 137.047L92.0803 136.282V134.75L93.4064 133.985Z" />
            <path d="M93.4064 144.703L94.7325 145.469V147L93.4064 147.766L92.0803 147V145.469L93.4064 144.703Z" />
            <path d="M88.8125 168.821L90.1386 169.586V171.118L88.8125 171.883L87.4864 171.118V169.586L88.8125 168.821Z" />
            <path d="M91.875 120.586L93.2011 121.351V122.883L91.875 123.648L90.5489 122.883V121.351L91.875 120.586Z" />
            <path d="M105.656 166.141L106.983 166.907V168.438L105.656 169.203L104.33 168.438V166.907L105.656 166.141Z" />
            <path d="M101.063 190.257L102.389 191.023V192.554L101.063 193.32L99.7368 192.554V191.023L101.063 190.257Z" />
            <path d="M104.125 152.742L105.451 153.508V155.039L104.125 155.805L102.799 155.039V153.508L104.125 152.742Z" />
            <path d="M102.594 128.625L103.92 129.391V130.922L102.594 131.688L101.268 130.922V129.391L102.594 128.625Z" />
            <path d="M81.1565 128.625L82.4826 129.391V130.922L81.1565 131.688L79.8304 130.922V129.391L81.1565 128.625Z" />
            <path d="M101.063 158.102L102.389 158.867V160.398L101.063 161.164L99.7365 160.398V158.867L101.063 158.102Z" />
            <path d="M96.4689 182.219L97.795 182.985V184.516L96.4689 185.282L95.1428 184.516V182.985L96.4689 182.219Z" />
            <path d="M99.5314 133.985L100.858 134.75V136.282L99.5314 137.047L98.2053 136.282V134.75L99.5314 133.985Z" />
            <path d="M99.5314 144.703L100.858 145.469V147L99.5314 147.766L98.2053 147V145.469L99.5314 144.703Z" />
            <path d="M94.9375 168.821L96.2636 169.586V171.118L94.9375 171.883L93.6114 171.118V169.586L94.9375 168.821Z" />
            <path d="M98.0001 120.586L99.3262 121.351V122.883L98.0001 123.648L96.674 122.883V121.351L98.0001 120.586Z" />
            <path d="M110.25 152.742L111.576 153.508V155.039L110.25 155.805L108.924 155.039V153.508L110.25 152.742Z" />
            <path d="M108.719 128.625L110.045 129.391V130.922L108.719 131.688L107.393 130.922V129.391L108.719 128.625Z" />
            <path d="M107.187 158.102L108.514 158.867V160.398L107.187 161.164L105.861 160.398V158.867L107.187 158.102Z" />
            <path d="M105.656 133.985L106.983 134.75V136.282L105.656 137.047L104.33 136.282V134.75L105.656 133.985Z" />
            <path d="M105.656 144.703L106.983 145.469V147L105.656 147.766L104.33 147V145.469L105.656 144.703Z" />
            <path d="M101.063 168.821L102.389 169.586V171.118L101.063 171.883L99.7365 171.118V169.586L101.063 168.821Z" />
            <path d="M104.125 120.586L105.451 121.351V122.883L104.125 123.648L102.799 122.883V121.351L104.125 120.586Z" />
            <path d="M116.375 152.742L117.701 153.508V155.039L116.375 155.805L115.049 155.039V153.508L116.375 152.742Z" />
            <path d="M114.844 128.625L116.17 129.391V130.922L114.844 131.688L113.518 130.922V129.391L114.844 128.625Z" />
            <path d="M120.969 128.625L122.295 129.391V130.922L120.969 131.688L119.643 130.922V129.391L120.969 128.625Z" />
            <path d="M111.781 133.985L113.107 134.75V136.282L111.781 137.047L110.455 136.282V134.75L111.781 133.985Z" />
            <path d="M111.781 144.703L113.107 145.469V147L111.781 147.766L110.455 147V145.469L111.781 144.703Z" />
            <path d="M90.3439 166.141L91.67 166.907V168.438L90.3439 169.203L89.0178 168.438V166.907L90.3439 166.141Z" />
            <path d="M88.8125 152.742L90.1386 153.508V155.039L88.8125 155.805L87.4864 155.039V153.508L88.8125 152.742Z" />
            <path d="M87.2814 128.625L88.6075 129.391V130.922L87.2814 131.688L85.9553 130.922V129.391L87.2814 128.625Z" />
            <path d="M84.2189 133.985L85.545 134.75V136.282L84.2189 137.047L82.8928 136.282V134.75L84.2189 133.985Z" />
            <path d="M82.6876 120.586L84.0137 121.351V122.883L82.6876 123.648L81.3615 122.883V121.351L82.6876 120.586Z" />
            <path d="M96.4689 166.141L97.795 166.907V168.438L96.4689 169.203L95.1428 168.438V166.907L96.4689 166.141Z" />
            <path d="M91.8753 190.257L93.2014 191.023V192.554L91.8753 193.32L90.5492 192.554V191.023L91.8753 190.257Z" />
            <path d="M94.9375 152.742L96.2636 153.508V155.039L94.9375 155.805L93.6114 155.039V153.508L94.9375 152.742Z" />
            <path d="M90.3439 176.859L91.67 177.625V179.156L90.3439 179.922L89.0178 179.156V177.625L90.3439 176.859Z" />
            <path d="M93.4064 128.625L94.7325 129.391V130.922L93.4064 131.688L92.0803 130.922V129.391L93.4064 128.625Z" />
            <path d="M91.875 158.102L93.2011 158.867V160.398L91.875 161.164L90.5489 160.398V158.867L91.875 158.102Z" />
            <path d="M90.3439 133.985L91.67 134.75V136.282L90.3439 137.047L89.0178 136.282V134.75L90.3439 133.985Z" />
            <path d="M90.3439 144.703L91.67 145.469V147L90.3439 147.766L89.0178 147V145.469L90.3439 144.703Z" />
            <path d="M88.8125 120.586L90.1386 121.351V122.883L88.8125 123.648L87.4864 122.883V121.351L88.8125 120.586Z" />
            <path d="M102.594 166.141L103.92 166.907V168.438L102.594 169.203L101.268 168.438V166.907L102.594 166.141Z" />
            <path d="M101.063 152.742L102.389 153.508V155.039L101.063 155.805L99.7365 155.039V153.508L101.063 152.742Z" />
            <path d="M96.4689 176.859L97.795 177.625V179.156L96.4689 179.922L95.1428 179.156V177.625L96.4689 176.859Z" />
            <path d="M99.5314 128.625L100.858 129.391V130.922L99.5314 131.688L98.2053 130.922V129.391L99.5314 128.625Z" />
            <path d="M78.094 128.625L79.4201 129.391V130.922L78.094 131.688L76.7679 130.922V129.391L78.094 128.625Z" />
            <path d="M98.0001 158.102L99.3262 158.867V160.398L98.0001 161.164L96.674 160.398V158.867L98.0001 158.102Z" />
            <path d="M93.4064 182.219L94.7325 182.985V184.516L93.4064 185.282L92.0803 184.516V182.985L93.4064 182.219Z" />
            <path d="M96.4689 133.985L97.795 134.75V136.282L96.4689 137.047L95.1428 136.282V134.75L96.4689 133.985Z" />
            <path d="M96.4689 144.703L97.795 145.469V147L96.4689 147.766L95.1428 147V145.469L96.4689 144.703Z" />
            <path d="M91.875 168.821L93.2011 169.586V171.118L91.875 171.883L90.5489 171.118V169.586L91.875 168.821Z" />
            <path d="M94.9375 120.586L96.2636 121.351V122.883L94.9375 123.648L93.6114 122.883V121.351L94.9375 120.586Z" />
            <path d="M107.187 152.742L108.514 153.508V155.039L107.187 155.805L105.861 155.039V153.508L107.187 152.742Z" />
            <path d="M105.656 128.625L106.983 129.391V130.922L105.656 131.688L104.33 130.922V129.391L105.656 128.625Z" />
            <path d="M104.125 158.102L105.451 158.867V160.398L104.125 161.164L102.799 160.398V158.867L104.125 158.102Z" />
            <path d="M102.594 133.985L103.92 134.75V136.282L102.594 137.047L101.268 136.282V134.75L102.594 133.985Z" />
            <path d="M102.594 144.703L103.92 145.469V147L102.594 147.766L101.268 147V145.469L102.594 144.703Z" />
            <path d="M98.0001 168.821L99.3262 169.586V171.118L98.0001 171.883L96.674 171.118V169.586L98.0001 168.821Z" />
            <path d="M101.063 120.586L102.389 121.351V122.883L101.063 123.648L99.7365 122.883V121.351L101.063 120.586Z" />
            <path d="M79.625 120.586L80.9511 121.351V122.883L79.625 123.648L78.2989 122.883V121.351L79.625 120.586Z" />
            <path d="M113.312 152.742L114.639 153.508V155.039L113.312 155.805L111.986 155.039V153.508L113.312 152.742Z" />
            <path d="M111.781 128.625L113.107 129.391V130.922L111.781 131.688L110.455 130.922V129.391L111.781 128.625Z" />
            <path d="M110.25 158.102L111.576 158.867V160.398L110.25 161.164L108.924 160.398V158.867L110.25 158.102Z" />
            <path d="M108.719 133.985L110.045 134.75V136.282L108.719 137.047L107.393 136.282V134.75L108.719 133.985Z" />
            <path d="M108.719 144.703L110.045 145.469V147L108.719 147.766L107.393 147V145.469L108.719 144.703Z" />
            <path d="M104.125 168.821L105.451 169.586V171.118L104.125 171.883L102.799 171.118V169.586L104.125 168.821Z" />
            <path d="M107.187 120.586L108.514 121.351V122.883L107.187 123.648L105.861 122.883V121.351L107.187 120.586Z" />
            <path d="M119.437 152.742L120.764 153.508V155.039L119.437 155.805L118.111 155.039V153.508L119.437 152.742Z" />
            <path d="M117.906 128.625L119.232 129.391V130.922L117.906 131.688L116.58 130.922V129.391L117.906 128.625Z" />
            <path d="M122.5 136.664L123.826 137.43V138.961L122.5 139.727L121.174 138.961V137.43L122.5 136.664Z" />
            <path d="M114.844 133.985L116.17 134.75V136.282L114.844 137.047L113.518 136.282V134.75L114.844 133.985Z" />
            <path d="M120.969 133.985L122.295 134.75V136.282L120.969 137.047L119.643 136.282V134.75L120.969 133.985Z" />
            <path d="M114.844 144.703L116.17 145.469V147L114.844 147.766L113.518 147V145.469L114.844 144.703Z" />
            <path d="M120.969 139.344L122.295 140.11V141.641L120.969 142.406L119.643 141.641V140.11L120.969 139.344Z" />
            <path d="M120.969 150.062L122.295 150.828V152.359L120.969 153.124L119.643 152.359V150.828L120.969 150.062Z" />
            <path d="M116.375 131.305L117.701 132.071V133.602L116.375 134.368L115.049 133.602V132.071L116.375 131.305Z" />
            <path d="M122.5 131.305L123.826 132.071V133.602L122.5 134.368L121.174 133.602V132.071L122.5 131.305Z" />
            <path d="M116.375 142.024L117.701 142.789V144.321L116.375 145.086L115.049 144.321V142.789L116.375 142.024Z" />
            <path d="M84.2189 139.344L85.545 140.11V141.641L84.2189 142.406L82.8928 141.641V140.11L84.2189 139.344Z" />
            <path d="M91.875 163.461L93.2011 164.226V165.758L91.875 166.523L90.5489 165.758V164.226L91.875 163.461Z" />
            <path d="M93.4064 192.938L94.7325 193.704V195.235L93.4064 196.001L92.0803 195.235V193.704L93.4064 192.938Z" />
            <path d="M90.3439 139.344L91.67 140.11V141.641L90.3439 142.406L89.0178 141.641V140.11L90.3439 139.344Z" />
            <path d="M90.3439 150.062L91.67 150.828V152.359L90.3439 153.124L89.0178 152.359V150.828L90.3439 150.062Z" />
            <path d="M88.8125 125.945L90.1386 126.711V128.242L88.8125 129.008L87.4864 128.242V126.711L88.8125 125.945Z" />
            <path d="M85.75 131.305L87.0761 132.071V133.602L85.75 134.368L84.4239 133.602V132.071L85.75 131.305Z" />
            <path d="M85.75 142.024L87.0761 142.789V144.321L85.75 145.086L84.4239 144.321V142.789L85.75 142.024Z" />
            <path d="M84.2189 117.906L85.545 118.672V120.203L84.2189 120.969L82.8928 120.203V118.672L84.2189 117.906Z" />
            <path d="M98.0001 163.461L99.3262 164.226V165.758L98.0001 166.523L96.674 165.758V164.226L98.0001 163.461Z" />
            <path d="M93.4064 187.578L94.7325 188.343V189.874L93.4064 190.64L92.0803 189.874V188.343L93.4064 187.578Z" />
            <path d="M96.4689 139.344L97.795 140.11V141.641L96.4689 142.406L95.1428 141.641V140.11L96.4689 139.344Z" />
            <path d="M96.4689 150.062L97.795 150.828V152.359L96.4689 153.124L95.1428 152.359V150.828L96.4689 150.062Z" />
            <path d="M91.8753 174.179L93.2014 174.945V176.476L91.8753 177.242L90.5492 176.476V174.945L91.8753 174.179Z" />
            <path d="M94.9375 125.945L96.2636 126.711V128.242L94.9375 129.008L93.6114 128.242V126.711L94.9375 125.945Z" />
            <path d="M94.9375 115.227L96.2636 115.993V117.524L94.9375 118.29L93.6114 117.524V115.993L94.9375 115.227Z" />
            <path d="M93.4064 155.421L94.7325 156.187V157.718L93.4064 158.484L92.0803 157.718V156.187L93.4064 155.421Z" />
            <path d="M88.8125 179.54L90.1386 180.306V181.837L88.8125 182.602L87.4864 181.837V180.306L88.8125 179.54Z" />
            <path d="M91.875 131.305L93.2011 132.071V133.602L91.875 134.368L90.5489 133.602V132.071L91.875 131.305Z" />
            <path d="M91.875 142.024L93.2011 142.789V144.321L91.875 145.086L90.5489 144.321V142.789L91.875 142.024Z" />
            <path d="M90.3439 117.906L91.67 118.672V120.203L90.3439 120.969L89.0178 120.203V118.672L90.3439 117.906Z" />
            <path d="M104.125 163.461L105.451 164.226V165.758L104.125 166.523L102.799 165.758V164.226L104.125 163.461Z" />
            <path d="M102.594 139.344L103.92 140.11V141.641L102.594 142.406L101.268 141.641V140.11L102.594 139.344Z" />
            <path d="M102.594 150.062L103.92 150.828V152.359L102.594 153.124L101.268 152.359V150.828L102.594 150.062Z" />
            <path d="M98.0004 174.179L99.3265 174.945V176.476L98.0004 177.242L96.6743 176.476V174.945L98.0004 174.179Z" />
            <path d="M101.063 125.945L102.389 126.711V128.242L101.063 129.008L99.7365 128.242V126.711L101.063 125.945Z" />
            <path d="M79.625 125.945L80.9511 126.711V128.242L79.625 129.008L78.2989 128.242V126.711L79.625 125.945Z" />
            <path d="M99.5314 155.421L100.858 156.187V157.718L99.5314 158.484L98.2053 157.718V156.187L99.5314 155.421Z" />
            <path d="M94.9378 179.54L96.2639 180.306V181.837L94.9378 182.602L93.6117 181.837V180.306L94.9378 179.54Z" />
            <path d="M98.0001 131.305L99.3262 132.071V133.602L98.0001 134.368L96.674 133.602V132.071L98.0001 131.305Z" />
            <path d="M98.0001 142.024L99.3262 142.789V144.321L98.0001 145.086L96.674 144.321V142.789L98.0001 142.024Z" />
            <path d="M96.4689 117.906L97.795 118.672V120.203L96.4689 120.969L95.1428 120.203V118.672L96.4689 117.906Z" />
            <path d="M108.719 139.344L110.045 140.11V141.641L108.719 142.406L107.393 141.641V140.11L108.719 139.344Z" />
            <path d="M108.719 150.062L110.045 150.828V152.359L108.719 153.124L107.393 152.359V150.828L108.719 150.062Z" />
            <path d="M107.187 125.945L108.514 126.711V128.242L107.187 129.008L105.861 128.242V126.711L107.187 125.945Z" />
            <path d="M105.656 155.421L106.983 156.187V157.718L105.656 158.484L104.33 157.718V156.187L105.656 155.421Z" />
            <path d="M104.125 131.305L105.451 132.071V133.602L104.125 134.368L102.799 133.602V132.071L104.125 131.305Z" />
            <path d="M104.125 142.024L105.451 142.789V144.321L104.125 145.086L102.799 144.321V142.789L104.125 142.024Z" />
            <path d="M102.594 117.906L103.92 118.672V120.203L102.594 120.969L101.268 120.203V118.672L102.594 117.906Z" />
            <path d="M114.844 139.344L116.17 140.11V141.641L114.844 142.406L113.518 141.641V140.11L114.844 139.344Z" />
            <path d="M114.844 150.062L116.17 150.828V152.359L114.844 153.124L113.518 152.359V150.828L114.844 150.062Z" />
            <path d="M113.312 125.945L114.639 126.711V128.242L113.312 129.008L111.986 128.242V126.711L113.312 125.945Z" />
            <path d="M111.781 155.421L113.107 156.187V157.718L111.781 158.484L110.455 157.718V156.187L111.781 155.421Z" />
            <path d="M110.25 131.305L111.576 132.071V133.602L110.25 134.368L108.924 133.602V132.071L110.25 131.305Z" />
            <path d="M110.25 142.024L111.576 142.789V144.321L110.25 145.086L108.924 144.321V142.789L110.25 142.024Z" />
            <path d="M88.8125 163.461L90.1386 164.226V165.758L88.8125 166.523L87.4864 165.758V164.226L88.8125 163.461Z" />
            <path d="M87.2814 139.344L88.6075 140.11V141.641L87.2814 142.406L85.9553 141.641V140.11L87.2814 139.344Z" />
            <path d="M85.75 125.945L87.0761 126.711V128.242L85.75 129.008L84.4239 128.242V126.711L85.75 125.945Z" />
            <path d="M82.6876 131.305L84.0137 132.071V133.602L82.6876 134.368L81.3615 133.602V132.071L82.6876 131.305Z" />
            <path d="M81.1565 117.906L82.4826 118.672V120.203L81.1565 120.969L79.8304 120.203V118.672L81.1565 117.906Z" />
            <path d="M94.9375 163.461L96.2636 164.226V165.758L94.9375 166.523L93.6114 165.758V164.226L94.9375 163.461Z" />
            <path d="M90.3439 187.578L91.67 188.343V189.874L90.3439 190.64L89.0178 189.874V188.343L90.3439 187.578Z" />
            <path d="M93.4064 139.344L94.7325 140.11V141.641L93.4064 142.406L92.0803 141.641V140.11L93.4064 139.344Z" />
            <path d="M93.4064 150.062L94.7325 150.828V152.359L93.4064 153.124L92.0803 152.359V150.828L93.4064 150.062Z" />
            <path d="M88.8125 174.179L90.1386 174.945V176.476L88.8125 177.242L87.4864 176.476V174.945L88.8125 174.179Z" />
            <path d="M91.875 125.945L93.2011 126.711V128.242L91.875 129.008L90.5489 128.242V126.711L91.875 125.945Z" />
            <path d="M91.875 115.227L93.2011 115.993V117.524L91.875 118.29L90.5489 117.524V115.993L91.875 115.227Z" />
            <path d="M90.3439 155.421L91.67 156.187V157.718L90.3439 158.484L89.0178 157.718V156.187L90.3439 155.421Z" />
            <path d="M88.8125 131.305L90.1386 132.071V133.602L88.8125 134.368L87.4864 133.602V132.071L88.8125 131.305Z" />
            <path d="M88.8125 142.024L90.1386 142.789V144.321L88.8125 145.086L87.4864 144.321V142.789L88.8125 142.024Z" />
            <path d="M87.2814 117.906L88.6075 118.672V120.203L87.2814 120.969L85.9553 120.203V118.672L87.2814 117.906Z" />
            <path d="M101.063 163.461L102.389 164.226V165.758L101.063 166.523L99.7365 165.758V164.226L101.063 163.461Z" />
            <path d="M99.5314 139.344L100.858 140.11V141.641L99.5314 142.406L98.2053 141.641V140.11L99.5314 139.344Z" />
            <path d="M99.5314 150.062L100.858 150.828V152.359L99.5314 153.124L98.2053 152.359V150.828L99.5314 150.062Z" />
            <path d="M94.9378 174.179L96.2639 174.945V176.476L94.9378 177.242L93.6117 176.476V174.945L94.9378 174.179Z" />
            <path d="M98.0001 125.945L99.3262 126.711V128.242L98.0001 129.008L96.674 128.242V126.711L98.0001 125.945Z" />
            <path d="M98.0001 115.227L99.3262 115.993V117.524L98.0001 118.29L96.674 117.524V115.993L98.0001 115.227Z" />
            <path d="M96.4689 155.421L97.795 156.187V157.718L96.4689 158.484L95.1428 157.718V156.187L96.4689 155.421Z" />
            <path d="M91.8753 179.54L93.2014 180.306V181.837L91.8753 182.602L90.5492 181.837V180.306L91.8753 179.54Z" />
            <path d="M94.9375 131.305L96.2636 132.071V133.602L94.9375 134.368L93.6114 133.602V132.071L94.9375 131.305Z" />
            <path d="M94.9375 142.024L96.2636 142.789V144.321L94.9375 145.086L93.6114 144.321V142.789L94.9375 142.024Z" />
            <path d="M93.4064 117.906L94.7325 118.672V120.203L93.4064 120.969L92.0803 120.203V118.672L93.4064 117.906Z" />
            <path d="M107.187 163.461L108.514 164.226V165.758L107.187 166.523L105.861 165.758V164.226L107.187 163.461Z" />
            <path d="M104.125 190.257L105.451 191.023V192.554L104.125 193.32L102.799 192.554V191.023L104.125 190.257Z" />
            <path d="M105.656 139.344L106.983 140.11V141.641L105.656 142.406L104.33 141.641V140.11L105.656 139.344Z" />
            <path d="M105.656 150.062L106.983 150.828V152.359L105.656 153.124L104.33 152.359V150.828L105.656 150.062Z" />
            <path d="M104.125 125.945L105.451 126.711V128.242L104.125 129.008L102.799 128.242V126.711L104.125 125.945Z" />
            <path d="M82.6876 125.945L84.0137 126.711V128.242L82.6876 129.008L81.3615 128.242V126.711L82.6876 125.945Z" />
            <path d="M102.594 155.421L103.92 156.187V157.718L102.594 158.484L101.268 157.718V156.187L102.594 155.421Z" />
            <path d="M101.063 131.305L102.389 132.071V133.602L101.063 134.368L99.7365 133.602V132.071L101.063 131.305Z" />
            <path d="M79.625 131.305L80.9511 132.071V133.602L79.625 134.368L78.2989 133.602V132.071L79.625 131.305Z" />
            <path d="M101.063 142.024L102.389 142.789V144.321L101.063 145.086L99.7365 144.321V142.789L101.063 142.024Z" />
            <path d="M99.5314 117.906L100.858 118.672V120.203L99.5314 120.969L98.2053 120.203V118.672L99.5314 117.906Z" />
            <path d="M111.781 139.344L113.107 140.11V141.641L111.781 142.406L110.455 141.641V140.11L111.781 139.344Z" />
            <path d="M111.781 150.062L113.107 150.828V152.359L111.781 153.124L110.455 152.359V150.828L111.781 150.062Z" />
            <path d="M110.25 125.945L111.576 126.711V128.242L110.25 129.008L108.924 128.242V126.711L110.25 125.945Z" />
            <path d="M108.719 155.421L110.045 156.187V157.718L108.719 158.484L107.393 157.718V156.187L108.719 155.421Z" />
            <path d="M107.187 131.305L108.514 132.071V133.602L107.187 134.368L105.861 133.602V132.071L107.187 131.305Z" />
            <path d="M107.187 142.024L108.514 142.789V144.321L107.187 145.086L105.861 144.321V142.789L107.187 142.024Z" />
            <path d="M105.656 117.906L106.983 118.672V120.203L105.656 120.969L104.33 120.203V118.672L105.656 117.906Z" />
            <path d="M117.906 139.344L119.232 140.11V141.641L117.906 142.406L116.58 141.641V140.11L117.906 139.344Z" />
            <path d="M117.906 150.062L119.232 150.828V152.359L117.906 153.124L116.58 152.359V150.828L117.906 150.062Z" />
            <path d="M116.375 125.945L117.701 126.711V128.242L116.375 129.008L115.049 128.242V126.711L116.375 125.945Z" />
            <path d="M113.312 131.305L114.639 132.071V133.602L113.312 134.368L111.986 133.602V132.071L113.312 131.305Z" />
            <path d="M119.437 131.305L120.764 132.071V133.602L119.437 134.368L118.111 133.602V132.071L119.437 131.305Z" />
            <path d="M113.312 142.024L114.639 142.789V144.321L113.312 145.086L111.986 144.321V142.789L113.312 142.024Z" />
            <path d="M82.6876 115.227L84.0137 115.993V117.524L82.6876 118.29L81.3615 117.524V115.993L82.6876 115.227Z" />
            <path d="M84.2189 112.546L85.545 113.312V114.843L84.2189 115.609L82.8928 114.843V113.312L84.2189 112.546Z" />
            <path d="M85.75 109.867L87.0761 110.633V112.164L85.75 112.93L84.4239 112.164V110.633L85.75 109.867Z" />
            <path d="M88.8125 109.867L90.1386 110.633V112.164L88.8125 112.93L87.4864 112.164V110.633L88.8125 109.867Z" />
            <path d="M87.2814 112.546L88.6075 113.312V114.843L87.2814 115.609L85.9553 114.843V113.312L87.2814 112.546Z" />
            <path d="M90.3439 112.546L91.67 113.312V114.843L90.3439 115.609L89.0178 114.843V113.312L90.3439 112.546Z" />
            <path d="M85.75 115.227L87.0761 115.993V117.524L85.75 118.29L84.4239 117.524V115.993L85.75 115.227Z" />
            <path d="M88.8125 115.227L90.1386 115.993V117.524L88.8125 118.29L87.4864 117.524V115.993L88.8125 115.227Z" />
          </g>
          <g
            id="North America"
            fill={
              highlight.includes('North America')
                ? highlightColor
                : defaultColor
            }
            onClick={() => {
              onClick('North America');
            }}
          >
            <path d="M78.094 96.4689L79.4201 97.2345V98.7657L78.094 99.5314L76.7679 98.7657V97.2345L78.094 96.4689Z" />
            <path d="M81.1566 96.4689L82.4827 97.2345V98.7657L81.1566 99.5314L79.8305 98.7657V97.2345L81.1566 96.4689Z" />
            <path d="M82.6876 99.1482L84.0137 99.9138V101.445L82.6876 102.211L81.3615 101.445V99.9138L82.6876 99.1482Z" />
            <path d="M85.75 99.1482L87.0761 99.9138V101.445L85.75 102.211L84.4239 101.445V99.9138L85.75 99.1482Z" />
            <path d="M88.8126 99.1482L90.1387 99.9138V101.445L88.8126 102.211L87.4865 101.445V99.9138L88.8126 99.1482Z" />
            <path d="M93.0237 101.446L94.3498 102.212V103.743L93.0237 104.508L91.6976 103.743V102.212L93.0237 101.446Z" />
            <path d="M70.4379 99.1482L71.764 99.9138V101.445L70.4379 102.211L69.1118 101.445V99.9138L70.4379 99.1482Z" />
            <path d="M65.844 101.828L67.1701 102.594V104.125L65.844 104.891L64.5179 104.125V102.594L65.844 101.828Z" />
            <path d="M62.7812 101.828L64.1073 102.594V104.125L62.7812 104.891L61.4551 104.125V102.594L62.7812 101.828Z" />
            <path d="M59.7184 101.828L61.0445 102.594V104.125L59.7184 104.891L58.3923 104.125V102.594L59.7184 101.828Z" />
            <path d="M58.1873 99.1482L59.5134 99.9138V101.445L58.1873 102.211L56.8612 101.445V99.9138L58.1873 99.1482Z" />
            <path d="M59.7184 96.4689L61.0445 97.2345V98.7657L59.7184 99.5314L58.3923 98.7657V97.2345L59.7184 96.4689Z" />
            <path d="M58.1873 93.7893L59.5134 94.5549V96.0862L58.1873 96.8518L56.8612 96.0862V94.5549L58.1873 93.7893Z" />
            <path d="M59.7184 91.1093L61.0445 91.8749V93.4061L59.7184 94.1718L58.3923 93.4061V91.8749L59.7184 91.1093Z" />
            <path d="M56.6559 91.1093L57.982 91.8749V93.4061L56.6559 94.1718L55.3298 93.4061V91.8749L56.6559 91.1093Z" />
            <path d="M53.5936 91.1093L54.9197 91.8749V93.4061L53.5936 94.1718L52.2675 93.4061V91.8749L53.5936 91.1093Z" />
            <path d="M50.5309 91.1093L51.857 91.8749V93.4061L50.5309 94.1718L49.2048 93.4061V91.8749L50.5309 91.1093Z" />
            <path d="M48.9998 88.4298L50.3259 89.1954V90.7267L48.9998 91.4923L47.6737 90.7267V89.1954L48.9998 88.4298Z" />
            <path d="M52.0619 88.4298L53.388 89.1954V90.7267L52.0619 91.4923L50.7358 90.7267V89.1954L52.0619 88.4298Z" />
            <path d="M55.1248 88.4298L56.4509 89.1954V90.7267L55.1248 91.4923L53.7987 90.7267V89.1954L55.1248 88.4298Z" />
            <path d="M58.1873 88.4298L59.5134 89.1954V90.7267L58.1873 91.4923L56.8612 90.7267V89.1954L58.1873 88.4298Z" />
            <path d="M61.2498 88.4298L62.5759 89.1954V90.7267L61.2498 91.4923L59.9237 90.7267V89.1954L61.2498 88.4298Z" />
            <path d="M62.7812 85.7496L64.1073 86.5153V88.0465L62.7812 88.8121L61.4551 88.0465V86.5153L62.7812 85.7496Z" />
            <path d="M65.844 85.7496L67.1701 86.5153V88.0465L65.844 88.8121L64.5179 88.0465V86.5153L65.844 85.7496Z" />
            <path d="M68.9065 85.7496L70.2326 86.5153V88.0465L68.9065 88.8121L67.5804 88.0465V86.5153L68.9065 85.7496Z" />
            <path d="M71.969 85.7496L73.2951 86.5153V88.0465L71.969 88.8121L70.6429 88.0465V86.5153L71.969 85.7496Z" />
            <path d="M75.0315 85.7496L76.3576 86.5153V88.0465L75.0315 88.8121L73.7054 88.0465V86.5153L75.0315 85.7496Z" />
            <path d="M78.094 85.7496L79.4201 86.5153V88.0465L78.094 88.8121L76.7679 88.0465V86.5153L78.094 85.7496Z" />
            <path d="M76.5625 88.4298L77.8886 89.1954V90.7267L76.5625 91.4923L75.2364 90.7267V89.1954L76.5625 88.4298Z" />
            <path d="M78.094 91.1093L79.4201 91.8749V93.4061L78.094 94.1718L76.7679 93.4061V91.8749L78.094 91.1093Z" />
            <path d="M70.4379 83.0702L71.764 83.8358V85.3671L70.4379 86.1327L69.1118 85.3671V83.8358L70.4379 83.0702Z" />
            <path d="M65.844 75.0316L67.1701 75.7972V77.3285L65.844 78.0941L64.5179 77.3285V75.7972L65.844 75.0316Z" />
            <path d="M64.3129 50.9142L65.639 51.6798V53.2111L64.3129 53.9767L62.9868 53.2111V51.6798L64.3129 50.9142Z" />
            <path d="M114.844 10.7189L116.17 11.4845V13.0157L114.844 13.7814L113.518 13.0157V11.4845L114.844 10.7189Z" />
            <path d="M64.3129 61.6324L65.639 62.3981V63.9293L64.3129 64.6949L62.9868 63.9293V62.3981L64.3129 61.6324Z" />
            <path d="M114.844 21.4376L116.17 22.2032V23.7345L114.844 24.5001L113.518 23.7345V22.2032L114.844 21.4376Z" />
            <path d="M116.375 34.8361L117.701 35.6017V37.1329L116.375 37.8986L115.049 37.1329V35.6017L116.375 34.8361Z" />
            <path d="M62.7812 37.5157L64.1073 38.2814V39.8126L62.7812 40.5782L61.4551 39.8126V38.2814L62.7812 37.5157Z" />
            <path d="M67.3754 24.1174L68.7015 24.8831V26.4143L67.3754 27.1799L66.0493 26.4143V24.8831L67.3754 24.1174Z" />
            <path d="M71.969 21.4376L73.2951 22.2032V23.7345L71.969 24.5001L70.6429 23.7345V22.2032L71.969 21.4376Z" />
            <path d="M78.094 21.4376L79.4201 22.2032V23.7345L78.094 24.5001L76.7679 23.7345V22.2032L78.094 21.4376Z" />
            <path d="M79.6254 18.7572L80.9515 19.5228V21.0541L79.6254 21.8197L78.2993 21.0541V19.5228L79.6254 18.7572Z" />
            <path d="M91.8754 61.6324L93.2015 62.3981V63.9293L91.8754 64.6949L90.5493 63.9293V62.3981L91.8754 61.6324Z" />
            <path d="M76.5625 83.0702L77.8886 83.8358V85.3671L76.5625 86.1327L75.2364 85.3671V83.8358L76.5625 83.0702Z" />
            <path d="M71.969 75.0316L73.2951 75.7972V77.3285L71.969 78.0941L70.6429 77.3285V75.7972L71.969 75.0316Z" />
            <path d="M70.4379 50.9142L71.764 51.6798V53.2111L70.4379 53.9767L69.1118 53.2111V51.6798L70.4379 50.9142Z" />
            <path d="M120.969 10.7189L122.295 11.4845V13.0157L120.969 13.7814L119.643 13.0157V11.4845L120.969 10.7189Z" />
            <path d="M130.156 5.35889L131.482 6.12451V7.65576L130.156 8.42139L128.83 7.65576V6.12451L130.156 5.35889Z" />
            <path d="M130.156 0L131.482 0.765625V2.29687L130.156 3.0625L128.83 2.29687V0.765625L130.156 0Z" />
            <path d="M70.4379 61.6324L71.764 62.3981V63.9293L70.4379 64.6949L69.1118 63.9293V62.3981L70.4379 61.6324Z" />
            <path d="M120.969 21.4376L122.295 22.2032V23.7345L120.969 24.5001L119.643 23.7345V22.2032L120.969 21.4376Z" />
            <path d="M122.5 34.8361L123.826 35.6017V37.1329L122.5 37.8986L121.174 37.1329V35.6017L122.5 34.8361Z" />
            <path d="M68.9065 37.5157L70.2326 38.2814V39.8126L68.9065 40.5782L67.5804 39.8126V38.2814L68.9065 37.5157Z" />
            <path d="M98.0004 66.9913L99.3265 67.757V69.2882L98.0004 70.0538L96.6743 69.2882V67.757L98.0004 66.9913Z" />
            <path d="M99.5315 64.3125L100.858 65.0781V66.6094L99.5315 67.375L98.2054 66.6094V65.0781L99.5315 64.3125Z" />
            <path d="M73.5004 72.351L74.8265 73.1166V74.6478L73.5004 75.4135L72.1743 74.6478V73.1166L73.5004 72.351Z" />
            <path d="M71.969 48.2343L73.2951 48.9999V50.5311L71.969 51.2968L70.6429 50.5311V48.9999L71.969 48.2343Z" />
            <path d="M71.969 58.9521L73.2951 59.7178V61.249L71.969 62.0146L70.6429 61.249V59.7178L71.969 58.9521Z" />
            <path d="M122.5 18.7572L123.826 19.5228V21.0541L122.5 21.8197L121.174 21.0541V19.5228L122.5 18.7572Z" />
            <path d="M124.031 32.1564L125.357 32.922V34.4532L124.031 35.2189L122.705 34.4532V32.922L124.031 32.1564Z" />
            <path d="M70.4379 34.8361L71.764 35.6017V37.1329L70.4379 37.8986L69.1118 37.1329V35.6017L70.4379 34.8361Z" />
            <path d="M99.5315 58.9521L100.858 59.7178V61.249L99.5315 62.0146L98.2054 61.249V59.7178L99.5315 58.9521Z" />
            <path d="M75.0315 69.6721L76.3576 70.4377V71.969L75.0315 72.7346L73.7054 71.969V70.4377L75.0315 69.6721Z" />
            <path d="M125.563 29.4766L126.889 30.2422V31.7734L125.563 32.5391L124.237 31.7734V30.2422L125.563 29.4766Z" />
            <path d="M73.5004 56.2732L74.8265 57.0388V58.5701L73.5004 59.3357L72.1743 58.5701V57.0388L73.5004 56.2732Z" />
            <path d="M124.031 16.0773L125.357 16.8429V18.3741L124.031 19.1398L122.705 18.3741V16.8429L124.031 16.0773Z" />
            <path d="M71.969 32.1564L73.2951 32.922V34.4532L71.969 35.2189L70.6429 34.4532V32.922L71.969 32.1564Z" />
            <path d="M101.063 56.2732L102.389 57.0388V58.5701L101.063 59.3357L99.7368 58.5701V57.0388L101.063 56.2732Z" />
            <path d="M79.6254 66.9913L80.9515 67.757V69.2882L79.6254 70.0538L78.2993 69.2882V67.757L79.6254 66.9913Z" />
            <path d="M76.5625 72.351L77.8886 73.1166V74.6478L76.5625 75.4135L75.2364 74.6478V73.1166L76.5625 72.351Z" />
            <path d="M75.0315 58.9521L76.3576 59.7178V61.249L75.0315 62.0146L73.7054 61.249V59.7178L75.0315 58.9521Z" />
            <path d="M125.563 18.7572L126.889 19.5228V21.0541L125.563 21.8197L124.237 21.0541V19.5228L125.563 18.7572Z" />
            <path d="M127.094 32.1564L128.42 32.922V34.4532L127.094 35.2189L125.768 34.4532V32.922L127.094 32.1564Z" />
            <path d="M73.5004 34.8361L74.8265 35.6017V37.1329L73.5004 37.8986L72.1743 37.1329V35.6017L73.5004 34.8361Z" />
            <path d="M76.5625 34.8361L77.8886 35.6017V37.1329L76.5625 37.8986L75.2364 37.1329V35.6017L76.5625 34.8361Z" />
            <path d="M79.6254 34.8361L80.9515 35.6017V37.1329L79.6254 37.8986L78.2993 37.1329V35.6017L79.6254 34.8361Z" />
            <path d="M78.094 32.1564L79.4201 32.922V34.4532L78.094 35.2189L76.7679 34.4532V32.922L78.094 32.1564Z" />
            <path d="M81.1566 32.1564L82.4827 32.922V34.4532L81.1566 35.2189L79.8305 34.4532V32.922L81.1566 32.1564Z" />
            <path d="M84.2189 32.1564L85.545 32.922V34.4532L84.2189 35.2189L82.8928 34.4532V32.922L84.2189 32.1564Z" />
            <path d="M85.7504 29.4766L87.0765 30.2422V31.7734L85.7504 32.5391L84.4243 31.7734V30.2422L85.7504 29.4766Z" />
            <path d="M76.5625 29.4766L77.8886 30.2422V31.7734L76.5625 32.5391L75.2364 31.7734V30.2422L76.5625 29.4766Z" />
            <path d="M75.0315 26.7975L76.3576 27.5631V29.0944L75.0315 29.86L73.7054 29.0944V27.5631L75.0315 26.7975Z" />
            <path d="M78.094 37.5157L79.4201 38.2814V39.8126L78.094 40.5782L76.7679 39.8126V38.2814L78.094 37.5157Z" />
            <path d="M81.1566 37.5157L82.4827 38.2814V39.8126L81.1566 40.5782L79.8305 39.8126V38.2814L81.1566 37.5157Z" />
            <path d="M102.594 58.9521L103.92 59.7178V61.249L102.594 62.0146L101.268 61.249V59.7178L102.594 58.9521Z" />
            <path d="M104.125 56.2732L105.451 57.0388V58.5701L104.125 59.3357L102.799 58.5701V57.0388L104.125 56.2732Z" />
            <path d="M107.188 56.2732L108.514 57.0388V58.5701L107.188 59.3357L105.862 58.5701V57.0388L107.188 56.2732Z" />
            <path d="M105.656 53.5938L106.983 54.3594V55.8906L105.656 56.6562L104.33 55.8906V54.3594L105.656 53.5938Z" />
            <path d="M102.594 53.5938L103.92 54.3594V55.8906L102.594 56.6562L101.268 55.8906V54.3594L102.594 53.5938Z" />
            <path d="M102.594 48.2343L103.92 48.9999V50.5311L102.594 51.2968L101.268 50.5311V48.9999L102.594 48.2343Z" />
            <path d="M99.5315 53.5938L100.858 54.3594V55.8906L99.5315 56.6562L98.2054 55.8906V54.3594L99.5315 53.5938Z" />
            <path d="M99.5315 48.2343L100.858 48.9999V50.5311L99.5315 51.2968L98.2054 50.5311V48.9999L99.5315 48.2343Z" />
            <path d="M96.469 53.5938L97.7951 54.3594V55.8906L96.469 56.6562L95.1429 55.8906V54.3594L96.469 53.5938Z" />
            <path d="M96.469 48.2343L97.7951 48.9999V50.5311L96.469 51.2968L95.1429 50.5311V48.9999L96.469 48.2343Z" />
            <path d="M93.4064 53.5938L94.7325 54.3594V55.8906L93.4064 56.6562L92.0803 55.8906V54.3594L93.4064 53.5938Z" />
            <path d="M93.4064 48.2343L94.7325 48.9999V50.5311L93.4064 51.2968L92.0803 50.5311V48.9999L93.4064 48.2343Z" />
            <path d="M101.063 50.9142L102.389 51.6798V53.2111L101.063 53.9767L99.7368 53.2111V51.6798L101.063 50.9142Z" />
            <path d="M101.063 45.554L102.389 46.3196V47.8508L101.063 48.6165L99.7368 47.8508V46.3196L101.063 45.554Z" />
            <path d="M98.0004 50.9142L99.3265 51.6798V53.2111L98.0004 53.9767L96.6743 53.2111V51.6798L98.0004 50.9142Z" />
            <path d="M98.0004 45.554L99.3265 46.3196V47.8508L98.0004 48.6165L96.6743 47.8508V46.3196L98.0004 45.554Z" />
            <path d="M94.9379 50.9142L96.264 51.6798V53.2111L94.9379 53.9767L93.6118 53.2111V51.6798L94.9379 50.9142Z" />
            <path d="M94.9379 45.554L96.264 46.3196V47.8508L94.9379 48.6165L93.6118 47.8508V46.3196L94.9379 45.554Z" />
            <path d="M78.094 69.6721L79.4201 70.4377V71.969L78.094 72.7346L76.7679 71.969V70.4377L78.094 69.6721Z" />
            <path d="M82.6876 66.9913L84.0137 67.757V69.2882L82.6876 70.0538L81.3615 69.2882V67.757L82.6876 66.9913Z" />
            <path d="M79.6254 72.351L80.9515 73.1166V74.6478L79.6254 75.4135L78.2993 74.6478V73.1166L79.6254 72.351Z" />
            <path d="M81.1566 69.6721L82.4827 70.4377V71.969L81.1566 72.7346L79.8305 71.969V70.4377L81.1566 69.6721Z" />
            <path d="M85.7504 66.9913L87.0765 67.757V69.2882L85.7504 70.0538L84.4243 69.2882V67.757L85.7504 66.9913Z" />
            <path d="M82.6876 72.351L84.0137 73.1166V74.6478L82.6876 75.4135L81.3615 74.6478V73.1166L82.6876 72.351Z" />
            <path d="M84.2189 69.6721L85.545 70.4377V71.969L84.2189 72.7346L82.8928 71.969V70.4377L84.2189 69.6721Z" />
            <path d="M88.8126 66.9913L90.1387 67.757V69.2882L88.8126 70.0538L87.4865 69.2882V67.757L88.8126 66.9913Z" />
            <path d="M85.7504 72.351L87.0765 73.1166V74.6478L85.7504 75.4135L84.4243 74.6478V73.1166L85.7504 72.351Z" />
            <path d="M87.2814 69.6721L88.6075 70.4377V71.969L87.2814 72.7346L85.9553 71.969V70.4377L87.2814 69.6721Z" />
            <path d="M90.3439 69.6721L91.67 70.4377V71.969L90.3439 72.7346L89.0178 71.969V70.4377L90.3439 69.6721Z" />
            <path d="M91.8754 66.9913L93.2015 67.757V69.2882L91.8754 70.0538L90.5493 69.2882V67.757L91.8754 66.9913Z" />
            <path d="M75.0315 75.0316L76.3576 75.7972V77.3285L75.0315 78.0941L73.7054 77.3285V75.7972L75.0315 75.0316Z" />
            <path d="M73.5004 50.9142L74.8265 51.6798V53.2111L73.5004 53.9767L72.1743 53.2111V51.6798L73.5004 50.9142Z" />
            <path d="M124.031 10.7189L125.357 11.4845V13.0157L124.031 13.7814L122.705 13.0157V11.4845L124.031 10.7189Z" />
            <path d="M133.219 5.35889L134.545 6.12451V7.65576L133.219 8.42139L131.893 7.65576V6.12451L133.219 5.35889Z" />
            <path d="M133.219 0L134.545 0.765625V2.29687L133.219 3.0625L131.893 2.29687V0.765625L133.219 0Z" />
            <path d="M76.5625 50.9142L77.8886 51.6798V53.2111L76.5625 53.9767L75.2364 53.2111V51.6798L76.5625 50.9142Z" />
            <path d="M127.094 10.7189L128.42 11.4845V13.0157L127.094 13.7814L125.768 13.0157V11.4845L127.094 10.7189Z" />
            <path d="M128.625 13.3982L129.951 14.1638V15.6951L128.625 16.4607L127.299 15.6951V14.1638L128.625 13.3982Z" />
            <path d="M130.156 16.0773L131.482 16.8429V18.3741L130.156 19.1398L128.83 18.3741V16.8429L130.156 16.0773Z" />
            <path d="M131.687 18.7572L133.014 19.5228V21.0541L131.687 21.8197L130.361 21.0541V19.5228L131.687 18.7572Z" />
            <path d="M133.219 21.4376L134.545 22.2032V23.7345L133.219 24.5001L131.893 23.7345V22.2032L133.219 21.4376Z" />
            <path d="M134.75 24.1174L136.076 24.8831V26.4143L134.75 27.1799L133.424 26.4143V24.8831L134.75 24.1174Z" />
            <path d="M136.281 26.7975L137.607 27.5631V29.0944L136.281 29.86L134.955 29.0944V27.5631L136.281 26.7975Z" />
            <path d="M136.281 5.35889L137.607 6.12451V7.65576L136.281 8.42139L134.955 7.65576V6.12451L136.281 5.35889Z" />
            <path d="M139.344 5.35889L140.67 6.12451V7.65576L139.344 8.42139L138.018 7.65576V6.12451L139.344 5.35889Z" />
            <path d="M139.344 0L140.67 0.765625V2.29687L139.344 3.0625L138.018 2.29687V0.765625L139.344 0Z" />
            <path d="M136.281 0L137.607 0.765625V2.29687L136.281 3.0625L134.955 2.29687V0.765625L136.281 0Z" />
            <path d="M73.5004 61.6324L74.8265 62.3981V63.9293L73.5004 64.6949L72.1743 63.9293V62.3981L73.5004 61.6324Z" />
            <path d="M124.031 21.4376L125.357 22.2032V23.7345L124.031 24.5001L122.705 23.7345V22.2032L124.031 21.4376Z" />
            <path d="M71.969 37.5157L73.2951 38.2814V39.8126L71.969 40.5782L70.6429 39.8126V38.2814L71.969 37.5157Z" />
            <path d="M79.6254 83.0702L80.9515 83.8358V85.3671L79.6254 86.1327L78.2993 85.3671V83.8358L79.6254 83.0702Z" />
            <path d="M59.7184 85.7496L61.0445 86.5153V88.0465L59.7184 88.8121L58.3923 88.0465V86.5153L59.7184 85.7496Z" />
            <path d="M56.6559 85.7496L57.982 86.5153V88.0465L56.6559 88.8121L55.3298 88.0465V86.5153L56.6559 85.7496Z" />
            <path d="M53.5936 85.7496L54.9197 86.5153V88.0465L53.5936 88.8121L52.2675 88.0465V86.5153L53.5936 85.7496Z" />
            <path d="M50.5309 85.7496L51.857 86.5153V88.0465L50.5309 88.8121L49.2048 88.0465V86.5153L50.5309 85.7496Z" />
            <path d="M47.4686 85.7496L48.7947 86.5153V88.0465L47.4686 88.8121L46.1425 88.0465V86.5153L47.4686 85.7496Z" />
            <path d="M45.9373 83.0702L47.2634 83.8358V85.3671L45.9373 86.1327L44.6112 85.3671V83.8358L45.9373 83.0702Z" />
            <path d="M41.3437 75.0316L42.6698 75.7972V77.3285L41.3437 78.0941L40.0176 77.3285V75.7972L41.3437 75.0316Z" />
            <path d="M39.8126 50.9142L41.1387 51.6798V53.2111L39.8126 53.9767L38.4865 53.2111V51.6798L39.8126 50.9142Z" />
            <path d="M90.3439 10.7189L91.67 11.4845V13.0157L90.3439 13.7814L89.0178 13.0157V11.4845L90.3439 10.7189Z" />
            <path d="M96.469 5.35889L97.7951 6.12451V7.65576L96.469 8.42139L95.1429 7.65576V6.12451L96.469 5.35889Z" />
            <path d="M94.9376 8.03955L96.2637 8.80518V10.3364L94.9376 11.102L93.6115 10.3364V8.80518L94.9376 8.03955Z" />
            <path d="M78.094 10.7189L79.4201 11.4845V13.0157L78.094 13.7814L76.7679 13.0157V11.4845L78.094 10.7189Z" />
            <path d="M81.1566 5.35889L82.4827 6.12451V7.65576L81.1566 8.42139L79.8305 7.65576V6.12451L81.1566 5.35889Z" />
            <path d="M82.6876 8.03955L84.0137 8.80518V10.3364L82.6876 11.102L81.3615 10.3364V8.80518L82.6876 8.03955Z" />
            <path d="M39.8126 61.6324L41.1387 62.3981V63.9293L39.8126 64.6949L38.4865 63.9293V62.3981L39.8126 61.6324Z" />
            <path d="M90.3439 21.4376L91.67 22.2032V23.7345L90.3439 24.5001L89.0178 23.7345V22.2032L90.3439 21.4376Z" />
            <path d="M38.281 37.5157L39.6071 38.2814V39.8126L38.281 40.5782L36.9549 39.8126V38.2814L38.281 37.5157Z" />
            <path d="M19.9062 37.5157L21.2323 38.2814V39.8126L19.9062 40.5782L18.5801 39.8126V38.2814L19.9062 37.5157Z" />
            <path d="M52.0623 83.0702L53.3884 83.8358V85.3671L52.0623 86.1327L50.7362 85.3671V83.8358L52.0623 83.0702Z" />
            <path d="M47.4686 75.0316L48.7947 75.7972V77.3285L47.4686 78.0941L46.1425 77.3285V75.7972L47.4686 75.0316Z" />
            <path d="M45.9373 50.9142L47.2634 51.6798V53.2111L45.9373 53.9767L44.6112 53.2111V51.6798L45.9373 50.9142Z" />
            <path d="M45.9373 61.6324L47.2634 62.3981V63.9293L45.9373 64.6949L44.6112 63.9293V62.3981L45.9373 61.6324Z" />
            <path d="M44.4059 37.5157L45.732 38.2814V39.8126L44.4059 40.5782L43.0798 39.8126V38.2814L44.4059 37.5157Z" />
            <path d="M22.9688 37.5157L24.2949 38.2814V39.8126L22.9688 40.5782L21.6427 39.8126V38.2814L22.9688 37.5157Z" />
            <path d="M58.1873 83.0702L59.5134 83.8358V85.3671L58.1873 86.1327L56.8612 85.3671V83.8358L58.1873 83.0702Z" />
            <path d="M53.5936 75.0316L54.9197 75.7972V77.3285L53.5936 78.0941L52.2675 77.3285V75.7972L53.5936 75.0316Z" />
            <path d="M52.0623 50.9142L53.3884 51.6798V53.2111L52.0623 53.9767L50.7362 53.2111V51.6798L52.0623 50.9142Z" />
            <path d="M102.594 10.7189L103.92 11.4845V13.0157L102.594 13.7814L101.268 13.0157V11.4845L102.594 10.7189Z" />
            <path d="M110.25 8.03955L111.576 8.80518V10.3364L110.25 11.102L108.924 10.3364V8.80518L110.25 8.03955Z" />
            <path d="M119.437 8.03955L120.764 8.80518V10.3364L119.437 11.102L118.111 10.3364V8.80518L119.437 8.03955Z" />
            <path d="M128.625 8.03955L129.951 8.80518V10.3364L128.625 11.102L127.299 10.3364V8.80518L128.625 8.03955Z" />
            <path d="M137.812 8.03955L139.139 8.80518V10.3364L137.812 11.102L136.486 10.3364V8.80518L137.812 8.03955Z" />
            <path d="M136.281 10.7189L137.607 11.4845V13.0157L136.281 13.7814L134.955 13.0157V11.4845L136.281 10.7189Z" />
            <path d="M137.812 13.3982L139.139 14.1638V15.6951L137.812 16.4607L136.486 15.6951V14.1638L137.812 13.3982Z" />
            <path d="M140.875 18.7572L142.201 19.5228V21.0541L140.875 21.8197L139.549 21.0541V19.5228L140.875 18.7572Z" />
            <path d="M124.031 5.35889L125.357 6.12451V7.65576L124.031 8.42139L122.705 7.65576V6.12451L124.031 5.35889Z" />
            <path d="M125.563 2.67932L126.889 3.44495V4.9762L125.563 5.74182L124.236 4.9762V3.44495L125.563 2.67932Z" />
            <path d="M114.844 5.35889L116.17 6.12451V7.65576L114.844 8.42139L113.518 7.65576V6.12451L114.844 5.35889Z" />
            <path d="M94.9376 2.67932L96.2637 3.44495V4.9762L94.9376 5.74182L93.6115 4.9762V3.44495L94.9376 2.67932Z" />
            <path d="M87.2814 5.35889L88.6075 6.12451V7.65576L87.2814 8.42139L85.9553 7.65576V6.12451L87.2814 5.35889Z" />
            <path d="M52.0623 61.6324L53.3884 62.3981V63.9293L52.0623 64.6949L50.7362 63.9293V62.3981L52.0623 61.6324Z" />
            <path d="M50.5309 37.5157L51.857 38.2814V39.8126L50.5309 40.5782L49.2048 39.8126V38.2814L50.5309 37.5157Z" />
            <path d="M53.5936 21.4376L54.9197 22.2032V23.7345L53.5936 24.5001L52.2675 23.7345V22.2032L53.5936 21.4376Z" />
            <path d="M29.0937 37.5157L30.4198 38.2814V39.8126L29.0937 40.5782L27.7676 39.8126V38.2814L29.0937 37.5157Z" />
            <path d="M10.7187 37.5157L12.0448 38.2814V39.8126L10.7187 40.5782L9.39265 39.8126V38.2814L10.7187 37.5157Z" />
            <path d="M79.6254 61.6324L80.9515 62.3981V63.9293L79.6254 64.6949L78.2993 63.9293V62.3981L79.6254 61.6324Z" />
            <path d="M64.3129 83.0702L65.639 83.8358V85.3671L64.3129 86.1327L62.9868 85.3671V83.8358L64.3129 83.0702Z" />
            <path d="M59.7184 75.0316L61.0445 75.7972V77.3285L59.7184 78.0941L58.3923 77.3285V75.7972L59.7184 75.0316Z" />
            <path d="M58.1873 50.9142L59.5134 51.6798V53.2111L58.1873 53.9767L56.8612 53.2111V51.6798L58.1873 50.9142Z" />
            <path d="M108.719 10.7189L110.045 11.4845V13.0157L108.719 13.7814L107.393 13.0157V11.4845L108.719 10.7189Z" />
            <path d="M101.063 2.67932L102.389 3.44495V4.9762L101.063 5.74182L99.7365 4.9762V3.44495L101.063 2.67932Z" />
            <path d="M58.1873 61.6324L59.5134 62.3981V63.9293L58.1873 64.6949L56.8612 63.9293V62.3981L58.1873 61.6324Z" />
            <path d="M56.6559 37.5157L57.982 38.2814V39.8126L56.6559 40.5782L55.3298 39.8126V38.2814L56.6559 37.5157Z" />
            <path d="M61.2498 24.1174L62.5759 24.8831V26.4143L61.2498 27.1799L59.9237 26.4143V24.8831L61.2498 24.1174Z" />
            <path d="M35.2187 37.5157L36.5448 38.2814V39.8126L35.2187 40.5782L33.8926 39.8126V38.2814L35.2187 37.5157Z" />
            <path d="M16.8438 37.5157L18.1699 38.2814V39.8126L16.8438 40.5782L15.5177 39.8126V38.2814L16.8438 37.5157Z" />
            <path d="M85.7504 61.6324L87.0765 62.3981V63.9293L85.7504 64.6949L84.4243 63.9293V62.3981L85.7504 61.6324Z" />
            <path d="M42.8749 83.0702L44.201 83.8358V85.3671L42.8749 86.1327L41.5488 85.3671V83.8358L42.8749 83.0702Z" />
            <path d="M38.281 75.0316L39.6071 75.7972V77.3285L38.281 78.0941L36.9549 77.3285V75.7972L38.281 75.0316Z" />
            <path d="M36.7499 50.9142L38.076 51.6798V53.2111L36.7499 53.9767L35.4238 53.2111V51.6798L36.7499 50.9142Z" />
            <path d="M87.2814 10.7189L88.6075 11.4845V13.0157L87.2814 13.7814L85.9553 13.0157V11.4845L87.2814 10.7189Z" />
            <path d="M93.4064 5.35889L94.7325 6.12451V7.65576L93.4064 8.42139L92.0803 7.65576V6.12451L93.4064 5.35889Z" />
            <path d="M91.8751 8.03955L93.2012 8.80518V10.3364L91.8751 11.102L90.549 10.3364V8.80518L91.8751 8.03955Z" />
            <path d="M33.6876 50.9142L35.0137 51.6798V53.2111L33.6876 53.9767L32.3615 53.2111V51.6798L33.6876 50.9142Z" />
            <path d="M84.2189 10.7189L85.545 11.4845V13.0157L84.2189 13.7814L82.8928 13.0157V11.4845L84.2189 10.7189Z" />
            <path d="M71.969 10.7189L73.2951 11.4845V13.0157L71.969 13.7814L70.6429 13.0157V11.4845L71.969 10.7189Z" />
            <path d="M35.2187 53.5938L36.5448 54.3594V55.8906L35.2187 56.6562L33.8926 55.8906V54.3594L35.2187 53.5938Z" />
            <path d="M85.7504 13.3982L87.0765 14.1638V15.6951L85.7504 16.4607L84.4243 15.6951V14.1638L85.7504 13.3982Z" />
            <path d="M48.9998 83.0702L50.3259 83.8358V85.3671L48.9998 86.1327L47.6737 85.3671V83.8358L48.9998 83.0702Z" />
            <path d="M44.4059 75.0316L45.732 75.7972V77.3285L44.4059 78.0941L43.0798 77.3285V75.7972L44.4059 75.0316Z" />
            <path d="M42.8749 50.9142L44.201 51.6798V53.2111L42.8749 53.9767L41.5488 53.2111V51.6798L42.8749 50.9142Z" />
            <path d="M93.4064 10.7189L94.7325 11.4845V13.0157L93.4064 13.7814L92.0803 13.0157V11.4845L93.4064 10.7189Z" />
            <path d="M99.5315 5.35889L100.858 6.12451V7.65576L99.5315 8.42139L98.2054 7.65576V6.12451L99.5315 5.35889Z" />
            <path d="M102.594 5.35889L103.92 6.12451V7.65576L102.594 8.42139L101.268 7.65576V6.12451L102.594 5.35889Z" />
            <path d="M81.1566 10.7189L82.4827 11.4845V13.0157L81.1566 13.7814L79.8305 13.0157V11.4845L81.1566 10.7189Z" />
            <path d="M42.8749 61.6324L44.201 62.3981V63.9293L42.8749 64.6949L41.5488 63.9293V62.3981L42.8749 61.6324Z" />
            <path d="M41.3437 37.5157L42.6698 38.2814V39.8126L41.3437 40.5782L40.0176 39.8126V38.2814L41.3437 37.5157Z" />
            <path d="M55.1248 83.0702L56.4509 83.8358V85.3671L55.1248 86.1327L53.7987 85.3671V83.8358L55.1248 83.0702Z" />
            <path d="M50.5309 75.0316L51.857 75.7972V77.3285L50.5309 78.0941L49.2048 77.3285V75.7972L50.5309 75.0316Z" />
            <path d="M48.9998 50.9142L50.3259 51.6798V53.2111L48.9998 53.9767L47.6737 53.2111V51.6798L48.9998 50.9142Z" />
            <path d="M99.5315 10.7189L100.858 11.4845V13.0157L99.5315 13.7814L98.2054 13.0157V11.4845L99.5315 10.7189Z" />
            <path d="M107.187 8.03955L108.514 8.80518V10.3364L107.187 11.102L105.861 10.3364V8.80518L107.187 8.03955Z" />
            <path d="M116.375 8.03955L117.701 8.80518V10.3364L116.375 11.102L115.049 10.3364V8.80518L116.375 8.03955Z" />
            <path d="M120.969 5.35889L122.295 6.12451V7.65576L120.969 8.42139L119.643 7.65576V6.12451L120.969 5.35889Z" />
            <path d="M119.437 2.67932L120.764 3.44495V4.9762L119.437 5.74182L118.111 4.9762V3.44495L119.437 2.67932Z" />
            <path d="M111.781 5.35889L113.107 6.12451V7.65576L111.781 8.42139L110.455 7.65576V6.12451L111.781 5.35889Z" />
            <path d="M91.8751 2.67932L93.2012 3.44495V4.9762L91.8751 5.74182L90.549 4.9762V3.44495L91.8751 2.67932Z" />
            <path d="M48.9998 61.6324L50.3259 62.3981V63.9293L48.9998 64.6949L47.6737 63.9293V62.3981L48.9998 61.6324Z" />
            <path d="M47.4686 37.5157L48.7947 38.2814V39.8126L47.4686 40.5782L46.1425 39.8126V38.2814L47.4686 37.5157Z" />
            <path d="M26.0314 37.5157L27.3575 38.2814V39.8126L26.0314 40.5782L24.7053 39.8126V38.2814L26.0314 37.5157Z" />
            <path d="M61.2498 83.0702L62.5759 83.8358V85.3671L61.2498 86.1327L59.9237 85.3671V83.8358L61.2498 83.0702Z" />
            <path d="M56.6559 75.0316L57.982 75.7972V77.3285L56.6559 78.0941L55.3298 77.3285V75.7972L56.6559 75.0316Z" />
            <path d="M55.1248 50.9142L56.4509 51.6798V53.2111L55.1248 53.9767L53.7987 53.2111V51.6798L55.1248 50.9142Z" />
            <path d="M105.656 10.7189L106.983 11.4845V13.0157L105.656 13.7814L104.33 13.0157V11.4845L105.656 10.7189Z" />
            <path d="M113.312 8.03955L114.639 8.80518V10.3364L113.312 11.102L111.986 10.3364V8.80518L113.312 8.03955Z" />
            <path d="M122.5 8.03955L123.826 8.80518V10.3364L122.5 11.102L121.174 10.3364V8.80518L122.5 8.03955Z" />
            <path d="M131.687 8.03955L133.014 8.80518V10.3364L131.687 11.102L130.361 10.3364V8.80518L131.687 8.03955Z" />
            <path d="M130.156 10.7189L131.482 11.4845V13.0157L130.156 13.7814L128.83 13.0157V11.4845L130.156 10.7189Z" />
            <path d="M131.687 13.3982L133.014 14.1638V15.6951L131.687 16.4607L130.361 15.6951V14.1638L131.687 13.3982Z" />
            <path d="M133.219 16.0773L134.545 16.8429V18.3741L133.219 19.1398L131.893 18.3741V16.8429L133.219 16.0773Z" />
            <path d="M134.75 18.7572L136.076 19.5228V21.0541L134.75 21.8197L133.424 21.0541V19.5228L134.75 18.7572Z" />
            <path d="M136.281 21.4376L137.607 22.2032V23.7345L136.281 24.5001L134.955 23.7345V22.2032L136.281 21.4376Z" />
            <path d="M137.812 24.1174L139.139 24.8831V26.4143L137.812 27.1799L136.486 26.4143V24.8831L137.812 24.1174Z" />
            <path d="M139.344 26.7975L140.67 27.5631V29.0944L139.344 29.86L138.018 29.0944V27.5631L139.344 26.7975Z" />
            <path d="M140.875 8.03955L142.201 8.80518V10.3364L140.875 11.102L139.549 10.3364V8.80518L140.875 8.03955Z" />
            <path d="M139.344 10.7189L140.67 11.4845V13.0157L139.344 13.7814L138.018 13.0157V11.4845L139.344 10.7189Z" />
            <path d="M140.875 13.3982L142.201 14.1638V15.6951L140.875 16.4607L139.549 15.6951V14.1638L140.875 13.3982Z" />
            <path d="M142.406 16.0773L143.732 16.8429V18.3741L142.406 19.1398L141.08 18.3741V16.8429L142.406 16.0773Z" />
            <path d="M143.937 18.7572L145.264 19.5228V21.0541L143.937 21.8197L142.611 21.0541V19.5228L143.937 18.7572Z" />
            <path d="M127.094 5.35889L128.42 6.12451V7.65576L127.094 8.42139L125.768 7.65576V6.12451L127.094 5.35889Z" />
            <path d="M125.563 8.03955L126.889 8.80518V10.3364L125.563 11.102L124.236 10.3364V8.80518L125.563 8.03955Z" />
            <path d="M134.75 8.03955L136.076 8.80518V10.3364L134.75 11.102L133.424 10.3364V8.80518L134.75 8.03955Z" />
            <path d="M133.219 10.7189L134.545 11.4845V13.0157L133.219 13.7814L131.893 13.0157V11.4845L133.219 10.7189Z" />
            <path d="M134.75 13.3982L136.076 14.1638V15.6951L134.75 16.4607L133.424 15.6951V14.1638L134.75 13.3982Z" />
            <path d="M136.281 16.0773L137.607 16.8429V18.3741L136.281 19.1398L134.955 18.3741V16.8429L136.281 16.0773Z" />
            <path d="M137.812 18.7572L139.139 19.5228V21.0541L137.812 21.8197L136.486 21.0541V19.5228L137.812 18.7572Z" />
            <path d="M139.344 21.4376L140.67 22.2032V23.7345L139.344 24.5001L138.018 23.7345V22.2032L139.344 21.4376Z" />
            <path d="M143.937 8.03955L145.264 8.80518V10.3364L143.937 11.102L142.611 10.3364V8.80518L143.937 8.03955Z" />
            <path d="M142.406 10.7189L143.732 11.4845V13.0157L142.406 13.7814L141.08 13.0157V11.4845L142.406 10.7189Z" />
            <path d="M143.937 13.3982L145.264 14.1638V15.6951L143.937 16.4607L142.611 15.6951V14.1638L143.937 13.3982Z" />
            <path d="M145.469 5.35889L146.795 6.12451V7.65576L145.469 8.42139L144.143 7.65576V6.12451L145.469 5.35889Z" />
            <path d="M148.531 5.35889L149.857 6.12451V7.65576L148.531 8.42139L147.205 7.65576V6.12451L148.531 5.35889Z" />
            <path d="M128.625 2.67932L129.951 3.44495V4.9762L128.625 5.74182L127.299 4.9762V3.44495L128.625 2.67932Z" />
            <path d="M117.906 5.35889L119.232 6.12451V7.65576L117.906 8.42139L116.58 7.65576V6.12451L117.906 5.35889Z" />
            <path d="M98.0001 2.67932L99.3262 3.44495V4.9762L98.0001 5.74182L96.674 4.9762V3.44495L98.0001 2.67932Z" />
            <path d="M90.3439 5.35889L91.67 6.12451V7.65576L90.3439 8.42139L89.0178 7.65576V6.12451L90.3439 5.35889Z" />
            <path d="M55.1248 61.6324L56.4509 62.3981V63.9293L55.1248 64.6949L53.7987 63.9293V62.3981L55.1248 61.6324Z" />
            <path d="M53.5936 37.5157L54.9197 38.2814V39.8126L53.5936 40.5782L52.2675 39.8126V38.2814L53.5936 37.5157Z" />
            <path d="M58.1873 24.1174L59.5134 24.8831V26.4143L58.1873 27.1799L56.8612 26.4143V24.8831L58.1873 24.1174Z" />
            <path d="M32.1558 37.5157L33.4819 38.2814V39.8126L32.1558 40.5782L30.8297 39.8126V38.2814L32.1558 37.5157Z" />
            <path d="M13.7813 37.5157L15.1074 38.2814V39.8126L13.7813 40.5782L12.4552 39.8126V38.2814L13.7813 37.5157Z" />
            <path d="M82.6876 61.6324L84.0137 62.3981V63.9293L82.6876 64.6949L81.3615 63.9293V62.3981L82.6876 61.6324Z" />
            <path d="M67.3754 83.0702L68.7015 83.8358V85.3671L67.3754 86.1327L66.0493 85.3671V83.8358L67.3754 83.0702Z" />
            <path d="M62.7812 75.0316L64.1073 75.7972V77.3285L62.7812 78.0941L61.4551 77.3285V75.7972L62.7812 75.0316Z" />
            <path d="M61.2498 50.9142L62.5759 51.6798V53.2111L61.2498 53.9767L59.9237 53.2111V51.6798L61.2498 50.9142Z" />
            <path d="M111.781 10.7189L113.107 11.4845V13.0157L111.781 13.7814L110.455 13.0157V11.4845L111.781 10.7189Z" />
            <path d="M104.125 2.67932L105.451 3.44495V4.9762L104.125 5.74182L102.799 4.9762V3.44495L104.125 2.67932Z" />
            <path d="M107.187 2.67932L108.514 3.44495V4.9762L107.187 5.74182L105.861 4.9762V3.44495L107.187 2.67932Z" />
            <path d="M61.2498 61.6324L62.5759 62.3981V63.9293L61.2498 64.6949L59.9237 63.9293V62.3981L61.2498 61.6324Z" />
            <path d="M111.781 21.4376L113.107 22.2032V23.7345L111.781 24.5001L110.455 23.7345V22.2032L111.781 21.4376Z" />
            <path d="M113.313 34.8361L114.639 35.6017V37.1329L113.313 37.8986L111.987 37.1329V35.6017L113.313 34.8361Z" />
            <path d="M59.7184 37.5157L61.0445 38.2814V39.8126L59.7184 40.5782L58.3923 39.8126V38.2814L59.7184 37.5157Z" />
            <path d="M64.3129 24.1174L65.639 24.8831V26.4143L64.3129 27.1799L62.9868 26.4143V24.8831L64.3129 24.1174Z" />
            <path d="M88.8126 61.6324L90.1387 62.3981V63.9293L88.8126 64.6949L87.4865 63.9293V62.3981L88.8126 61.6324Z" />
            <path d="M73.5004 83.0702L74.8265 83.8358V85.3671L73.5004 86.1327L72.1743 85.3671V83.8358L73.5004 83.0702Z" />
            <path d="M68.9065 75.0316L70.2326 75.7972V77.3285L68.9065 78.0941L67.5804 77.3285V75.7972L68.9065 75.0316Z" />
            <path d="M67.3754 50.9142L68.7015 51.6798V53.2111L67.3754 53.9767L66.0493 53.2111V51.6798L67.3754 50.9142Z" />
            <path d="M117.906 10.7189L119.232 11.4845V13.0157L117.906 13.7814L116.58 13.0157V11.4845L117.906 10.7189Z" />
            <path d="M67.3754 61.6324L68.7015 62.3981V63.9293L67.3754 64.6949L66.0493 63.9293V62.3981L67.3754 61.6324Z" />
            <path d="M117.906 21.4376L119.232 22.2032V23.7345L117.906 24.5001L116.58 23.7345V22.2032L117.906 21.4376Z" />
            <path d="M119.438 34.8361L120.764 35.6017V37.1329L119.438 37.8986L118.112 37.1329V35.6017L119.438 34.8361Z" />
            <path d="M65.844 37.5157L67.1701 38.2814V39.8126L65.844 40.5782L64.5179 39.8126V38.2814L65.844 37.5157Z" />
            <path d="M94.9379 61.6324L96.264 62.3981V63.9293L94.9379 64.6949L93.6118 63.9293V62.3981L94.9379 61.6324Z" />
            <path d="M41.3437 80.3909L42.6698 81.1565V82.6877L41.3437 83.4534L40.0176 82.6877V81.1565L41.3437 80.3909Z" />
            <path d="M39.8126 66.9913L41.1387 67.757V69.2882L39.8126 70.0538L38.4865 69.2882V67.757L39.8126 66.9913Z" />
            <path d="M90.3439 26.7975L91.67 27.5631V29.0944L90.3439 29.86L89.0178 29.0944V27.5631L90.3439 26.7975Z" />
            <path d="M38.281 42.8746L39.6071 43.6403V45.1715L38.281 45.9371L36.9549 45.1715V43.6403L38.281 42.8746Z" />
            <path d="M36.7499 72.351L38.076 73.1166V74.6478L36.7499 75.4135L35.4238 74.6478V73.1166L36.7499 72.351Z" />
            <path d="M35.2187 48.2343L36.5448 48.9999V50.5311L35.2187 51.2968L33.8926 50.5311V48.9999L35.2187 48.2343Z" />
            <path d="M47.4686 80.3909L48.7947 81.1565V82.6877L47.4686 83.4534L46.1425 82.6877V81.1565L47.4686 80.3909Z" />
            <path d="M45.9373 66.9913L47.2634 67.757V69.2882L45.9373 70.0538L44.6112 69.2882V67.757L45.9373 66.9913Z" />
            <path d="M96.469 26.7975L97.7951 27.5631V29.0944L96.469 29.86L95.1429 29.0944V27.5631L96.469 26.7975Z" />
            <path d="M44.4059 42.8746L45.732 43.6403V45.1715L44.4059 45.9371L43.0798 45.1715V43.6403L44.4059 42.8746Z" />
            <path d="M22.9688 42.8746L24.2949 43.6403V45.1715L22.9688 45.9371L21.6427 45.1715V43.6403L22.9688 42.8746Z" />
            <path d="M4.59375 42.8746L5.91985 43.6403V45.1715L4.59375 45.9371L3.26765 45.1715V43.6403L4.59375 42.8746Z" />
            <path d="M42.8749 72.351L44.201 73.1166V74.6478L42.8749 75.4135L41.5488 74.6478V73.1166L42.8749 72.351Z" />
            <path d="M41.3437 48.2343L42.6698 48.9999V50.5311L41.3437 51.2968L40.0176 50.5311V48.9999L41.3437 48.2343Z" />
            <path d="M41.3437 58.9521L42.6698 59.7178V61.249L41.3437 62.0146L40.0176 61.249V59.7178L41.3437 58.9521Z" />
            <path d="M39.8126 34.8361L41.1387 35.6017V37.1329L39.8126 37.8986L38.4865 37.1329V35.6017L39.8126 34.8361Z" />
            <path d="M53.5936 80.3909L54.9197 81.1565V82.6877L53.5936 83.4534L52.2675 82.6877V81.1565L53.5936 80.3909Z" />
            <path d="M52.0623 66.9913L53.3884 67.757V69.2882L52.0623 70.0538L50.7362 69.2882V67.757L52.0623 66.9913Z" />
            <path d="M96.469 32.1564L97.7951 32.922V34.4532L96.469 35.2189L95.1429 34.4532V32.922L96.469 32.1564Z" />
            <path d="M50.5309 42.8746L51.857 43.6403V45.1715L50.5309 45.9371L49.2048 45.1715V43.6403L50.5309 42.8746Z" />
            <path d="M29.0937 42.8746L30.4198 43.6403V45.1715L29.0937 45.9371L27.7676 45.1715V43.6403L29.0937 42.8746Z" />
            <path d="M10.7187 42.8746L12.0448 43.6403V45.1715L10.7187 45.9371L9.39265 45.1715V43.6403L10.7187 42.8746Z" />
            <path d="M48.9998 72.351L50.3259 73.1166V74.6478L48.9998 75.4135L47.6737 74.6478V73.1166L48.9998 72.351Z" />
            <path d="M47.4686 48.2343L48.7947 48.9999V50.5311L47.4686 51.2968L46.1425 50.5311V48.9999L47.4686 48.2343Z" />
            <path d="M47.4686 58.9521L48.7947 59.7178V61.249L47.4686 62.0146L46.1425 61.249V59.7178L47.4686 58.9521Z" />
            <path d="M45.9373 34.8361L47.2634 35.6017V37.1329L45.9373 37.8986L44.6112 37.1329V35.6017L45.9373 34.8361Z" />
            <path d="M24.4998 34.8361L25.8259 35.6017V37.1329L24.4998 37.8986L23.1737 37.1329V35.6017L24.4998 34.8361Z" />
            <path d="M6.1253 34.8361L7.45141 35.6017V37.1329L6.1253 37.8986L4.7992 37.1329V35.6017L6.1253 34.8361Z" />
            <path d="M59.7184 80.3909L61.0445 81.1565V82.6877L59.7184 83.4534L58.3923 82.6877V81.1565L59.7184 80.3909Z" />
            <path d="M58.1873 66.9913L59.5134 67.757V69.2882L58.1873 70.0538L56.8612 69.2882V67.757L58.1873 66.9913Z" />
            <path d="M102.594 32.1564L103.92 32.922V34.4532L102.594 35.2189L101.268 34.4532V32.922L102.594 32.1564Z" />
            <path d="M56.6559 42.8746L57.982 43.6403V45.1715L56.6559 45.9371L55.3298 45.1715V43.6403L56.6559 42.8746Z" />
            <path d="M35.2187 42.8746L36.5448 43.6403V45.1715L35.2187 45.9371L33.8926 45.1715V43.6403L35.2187 42.8746Z" />
            <path d="M16.8438 42.8746L18.1699 43.6403V45.1715L16.8438 45.9371L15.5177 45.1715V43.6403L16.8438 42.8746Z" />
            <path d="M55.1248 72.351L56.4509 73.1166V74.6478L55.1248 75.4135L53.7987 74.6478V73.1166L55.1248 72.351Z" />
            <path d="M53.5936 48.2343L54.9197 48.9999V50.5311L53.5936 51.2968L52.2675 50.5311V48.9999L53.5936 48.2343Z" />
            <path d="M32.1558 48.2343L33.4819 48.9999V50.5311L32.1558 51.2968L30.8297 50.5311V48.9999L32.1558 48.2343Z" />
            <path d="M53.5936 58.9521L54.9197 59.7178V61.249L53.5936 62.0146L52.2675 61.249V59.7178L53.5936 58.9521Z" />
            <path d="M52.0623 34.8361L53.3884 35.6017V37.1329L52.0623 37.8986L50.7362 37.1329V35.6017L52.0623 34.8361Z" />
            <path d="M56.6559 21.4376L57.982 22.2032V23.7345L56.6559 24.5001L55.3298 23.7345V22.2032L56.6559 21.4376Z" />
            <path d="M30.6248 34.8361L31.9509 35.6017V37.1329L30.6248 37.8986L29.2987 37.1329V35.6017L30.6248 34.8361Z" />
            <path d="M12.2502 34.8361L13.5763 35.6017V37.1329L12.2502 37.8986L10.9241 37.1329V35.6017L12.2502 34.8361Z" />
            <path d="M81.1566 58.9521L82.4827 59.7178V61.249L81.1566 62.0146L79.8305 61.249V59.7178L81.1566 58.9521Z" />
            <path d="M65.844 80.3909L67.1701 81.1565V82.6877L65.844 83.4534L64.5179 82.6877V81.1565L65.844 80.3909Z" />
            <path d="M64.3129 66.9913L65.639 67.757V69.2882L64.3129 70.0538L62.9868 69.2882V67.757L64.3129 66.9913Z" />
            <path d="M114.844 26.7975L116.17 27.5631V29.0944L114.844 29.86L113.518 29.0944V27.5631L114.844 26.7975Z" />
            <path d="M116.375 40.1957L117.701 40.9613V42.4926L116.375 43.2582L115.049 42.4926V40.9613L116.375 40.1957Z" />
            <path d="M62.7812 42.8746L64.1073 43.6403V45.1715L62.7812 45.9371L61.4551 45.1715V43.6403L62.7812 42.8746Z" />
            <path d="M61.2498 72.351L62.5759 73.1166V74.6478L61.2498 75.4135L59.9237 74.6478V73.1166L61.2498 72.351Z" />
            <path d="M59.7184 48.2343L61.0445 48.9999V50.5311L59.7184 51.2968L58.3923 50.5311V48.9999L59.7184 48.2343Z" />
            <path d="M59.7184 58.9521L61.0445 59.7178V61.249L59.7184 62.0146L58.3923 61.249V59.7178L59.7184 58.9521Z" />
            <path d="M110.25 18.7572L111.576 19.5228V21.0541L110.25 21.8197L108.924 21.0541V19.5228L110.25 18.7572Z" />
            <path d="M58.1873 34.8361L59.5134 35.6017V37.1329L58.1873 37.8986L56.8612 37.1329V35.6017L58.1873 34.8361Z" />
            <path d="M62.7812 21.4376L64.1073 22.2032V23.7345L62.7812 24.5001L61.4551 23.7345V22.2032L62.7812 21.4376Z" />
            <path d="M87.2814 58.9521L88.6075 59.7178V61.249L87.2814 62.0146L85.9553 61.249V59.7178L87.2814 58.9521Z" />
            <path d="M71.969 80.3909L73.2951 81.1565V82.6877L71.969 83.4534L70.6429 82.6877V81.1565L71.969 80.3909Z" />
            <path d="M70.4379 66.9913L71.764 67.757V69.2882L70.4379 70.0538L69.1118 69.2882V67.757L70.4379 66.9913Z" />
            <path d="M120.969 26.7975L122.295 27.5631V29.0944L120.969 29.86L119.643 29.0944V27.5631L120.969 26.7975Z" />
            <path d="M68.9065 42.8746L70.2326 43.6403V45.1715L68.9065 45.9371L67.5804 45.1715V43.6403L68.9065 42.8746Z" />
            <path d="M67.3754 72.351L68.7015 73.1166V74.6478L67.3754 75.4135L66.0493 74.6478V73.1166L67.3754 72.351Z" />
            <path d="M65.844 48.2343L67.1701 48.9999V50.5311L65.844 51.2968L64.5179 50.5311V48.9999L65.844 48.2343Z" />
            <path d="M65.844 58.9521L67.1701 59.7178V61.249L65.844 62.0146L64.5179 61.249V59.7178L65.844 58.9521Z" />
            <path d="M116.375 18.7572L117.701 19.5228V21.0541L116.375 21.8197L115.049 21.0541V19.5228L116.375 18.7572Z" />
            <path d="M117.906 32.1564L119.232 32.922V34.4532L117.906 35.2189L116.58 34.4532V32.922L117.906 32.1564Z" />
            <path d="M64.3129 34.8361L65.639 35.6017V37.1329L64.3129 37.8986L62.9868 37.1329V35.6017L64.3129 34.8361Z" />
            <path d="M68.9065 21.4376L70.2326 22.2032V23.7345L68.9065 24.5001L67.5804 23.7345V22.2032L68.9065 21.4376Z" />
            <path d="M93.4064 58.9521L94.7325 59.7178V61.249L93.4064 62.0146L92.0803 61.249V59.7178L93.4064 58.9521Z" />
            <path d="M78.094 80.3909L79.4201 81.1565V82.6877L78.094 83.4534L76.7679 82.6877V81.1565L78.094 80.3909Z" />
            <path d="M79.6254 77.7106L80.9515 78.4762V80.0074L79.6254 80.7731L78.2993 80.0074V78.4762L79.6254 77.7106Z" />
            <path d="M81.1566 75.0316L82.4827 75.7972V77.3285L81.1566 78.0941L79.8305 77.3285V75.7972L81.1566 75.0316Z" />
            <path d="M44.4059 80.3909L45.732 81.1565V82.6877L44.4059 83.4534L43.0798 82.6877V81.1565L44.4059 80.3909Z" />
            <path d="M42.8749 66.9913L44.201 67.757V69.2882L42.8749 70.0538L41.5488 69.2882V67.757L42.8749 66.9913Z" />
            <path d="M93.4064 26.7975L94.7325 27.5631V29.0944L93.4064 29.86L92.0803 29.0944V27.5631L93.4064 26.7975Z" />
            <path d="M41.3437 42.8746L42.6698 43.6403V45.1715L41.3437 45.9371L40.0176 45.1715V43.6403L41.3437 42.8746Z" />
            <path d="M39.8126 72.351L41.1387 73.1166V74.6478L39.8126 75.4135L38.4865 74.6478V73.1166L39.8126 72.351Z" />
            <path d="M38.281 48.2343L39.6071 48.9999V50.5311L38.281 51.2968L36.9549 50.5311V48.9999L38.281 48.2343Z" />
            <path d="M38.281 58.9521L39.6071 59.7178V61.249L38.281 62.0146L36.9549 61.249V59.7178L38.281 58.9521Z" />
            <path d="M88.8126 18.7572L90.1387 19.5228V21.0541L88.8126 21.8197L87.4865 21.0541V19.5228L88.8126 18.7572Z" />
            <path d="M36.7499 34.8361L38.076 35.6017V37.1329L36.7499 37.8986L35.4238 37.1329V35.6017L36.7499 34.8361Z" />
            <path d="M18.3749 34.8361L19.701 35.6017V37.1329L18.3749 37.8986L17.0488 37.1329V35.6017L18.3749 34.8361Z" />
            <path d="M50.5309 80.3909L51.857 81.1565V82.6877L50.5309 83.4534L49.2048 82.6877V81.1565L50.5309 80.3909Z" />
            <path d="M48.9998 66.9913L50.3259 67.757V69.2882L48.9998 70.0538L47.6737 69.2882V67.757L48.9998 66.9913Z" />
            <path d="M99.5315 26.7975L100.858 27.5631V29.0944L99.5315 29.86L98.2054 29.0944V27.5631L99.5315 26.7975Z" />
            <path d="M47.4686 42.8746L48.7947 43.6403V45.1715L47.4686 45.9371L46.1425 45.1715V43.6403L47.4686 42.8746Z" />
            <path d="M26.0314 42.8746L27.3575 43.6403V45.1715L26.0314 45.9371L24.7053 45.1715V43.6403L26.0314 42.8746Z" />
            <path d="M7.65625 42.8746L8.98235 43.6403V45.1715L7.65625 45.9371L6.33015 45.1715V43.6403L7.65625 42.8746Z" />
            <path d="M45.9373 72.351L47.2634 73.1166V74.6478L45.9373 75.4135L44.6112 74.6478V73.1166L45.9373 72.351Z" />
            <path d="M44.4059 48.2343L45.732 48.9999V50.5311L44.4059 51.2968L43.0798 50.5311V48.9999L44.4059 48.2343Z" />
            <path d="M44.4059 58.9521L45.732 59.7178V61.249L44.4059 62.0146L43.0798 61.249V59.7178L44.4059 58.9521Z" />
            <path d="M42.8749 34.8361L44.201 35.6017V37.1329L42.8749 37.8986L41.5488 37.1329V35.6017L42.8749 34.8361Z" />
            <path d="M21.4373 34.8361L22.7634 35.6017V37.1329L21.4373 37.8986L20.1112 37.1329V35.6017L21.4373 34.8361Z" />
            <path d="M56.6559 80.3909L57.982 81.1565V82.6877L56.6559 83.4534L55.3298 82.6877V81.1565L56.6559 80.3909Z" />
            <path d="M55.1248 66.9913L56.4509 67.757V69.2882L55.1248 70.0538L53.7987 69.2882V67.757L55.1248 66.9913Z" />
            <path d="M99.5315 32.1564L100.858 32.922V34.4532L99.5315 35.2189L98.2054 34.4532V32.922L99.5315 32.1564Z" />
            <path d="M53.5936 42.8746L54.9197 43.6403V45.1715L53.5936 45.9371L52.2675 45.1715V43.6403L53.5936 42.8746Z" />
            <path d="M32.1558 42.8746L33.4819 43.6403V45.1715L32.1558 45.9371L30.8297 45.1715V43.6403L32.1558 42.8746Z" />
            <path d="M13.7813 42.8746L15.1074 43.6403V45.1715L13.7813 45.9371L12.4552 45.1715V43.6403L13.7813 42.8746Z" />
            <path d="M52.0623 72.351L53.3884 73.1166V74.6478L52.0623 75.4135L50.7362 74.6478V73.1166L52.0623 72.351Z" />
            <path d="M50.5309 48.2343L51.857 48.9999V50.5311L50.5309 51.2968L49.2048 50.5311V48.9999L50.5309 48.2343Z" />
            <path d="M50.5309 58.9521L51.857 59.7178V61.249L50.5309 62.0146L49.2048 61.249V59.7178L50.5309 58.9521Z" />
            <path d="M48.9998 34.8361L50.3259 35.6017V37.1329L48.9998 37.8986L47.6737 37.1329V35.6017L48.9998 34.8361Z" />
            <path d="M27.5626 34.8361L28.8887 35.6017V37.1329L27.5626 37.8986L26.2365 37.1329V35.6017L27.5626 34.8361Z" />
            <path d="M9.18738 34.8361L10.5135 35.6017V37.1329L9.18738 37.8986L7.86128 37.1329V35.6017L9.18738 34.8361Z" />
            <path d="M78.094 58.9521L79.4201 59.7178V61.249L78.094 62.0146L76.7679 61.249V59.7178L78.094 58.9521Z" />
            <path d="M62.7812 80.3909L64.1073 81.1565V82.6877L62.7812 83.4534L61.4551 82.6877V81.1565L62.7812 80.3909Z" />
            <path d="M61.2498 66.9913L62.5759 67.757V69.2882L61.2498 70.0538L59.9237 69.2882V67.757L61.2498 66.9913Z" />
            <path d="M111.781 26.7975L113.107 27.5631V29.0944L111.781 29.86L110.455 29.0944V27.5631L111.781 26.7975Z" />
            <path d="M59.7184 42.8746L61.0445 43.6403V45.1715L59.7184 45.9371L58.3923 45.1715V43.6403L59.7184 42.8746Z" />
            <path d="M58.1873 72.351L59.5134 73.1166V74.6478L58.1873 75.4135L56.8612 74.6478V73.1166L58.1873 72.351Z" />
            <path d="M56.6559 48.2343L57.982 48.9999V50.5311L56.6559 51.2968L55.3298 50.5311V48.9999L56.6559 48.2343Z" />
            <path d="M56.6559 58.9521L57.982 59.7178V61.249L56.6559 62.0146L55.3298 61.249V59.7178L56.6559 58.9521Z" />
            <path d="M55.1248 34.8361L56.4509 35.6017V37.1329L55.1248 37.8986L53.7987 37.1329V35.6017L55.1248 34.8361Z" />
            <path d="M59.7184 21.4376L61.0445 22.2032V23.7345L59.7184 24.5001L58.3923 23.7345V22.2032L59.7184 21.4376Z" />
            <path d="M33.6876 34.8361L35.0137 35.6017V37.1329L33.6876 37.8986L32.3615 37.1329V35.6017L33.6876 34.8361Z" />
            <path d="M15.3124 34.8361L16.6385 35.6017V37.1329L15.3124 37.8986L13.9863 37.1329V35.6017L15.3124 34.8361Z" />
            <path d="M84.2189 58.9521L85.545 59.7178V61.249L84.2189 62.0146L82.8928 61.249V59.7178L84.2189 58.9521Z" />
            <path d="M68.9065 80.3909L70.2326 81.1565V82.6877L68.9065 83.4534L67.5804 82.6877V81.1565L68.9065 80.3909Z" />
            <path d="M67.3754 66.9913L68.7015 67.757V69.2882L67.3754 70.0538L66.0493 69.2882V67.757L67.3754 66.9913Z" />
            <path d="M117.906 26.7975L119.232 27.5631V29.0944L117.906 29.86L116.58 29.0944V27.5631L117.906 26.7975Z" />
            <path d="M119.438 40.1957L120.764 40.9613V42.4926L119.438 43.2582L118.112 42.4926V40.9613L119.438 40.1957Z" />
            <path d="M65.844 42.8746L67.1701 43.6403V45.1715L65.844 45.9371L64.5179 45.1715V43.6403L65.844 42.8746Z" />
            <path d="M64.3129 72.351L65.639 73.1166V74.6478L64.3129 75.4135L62.9868 74.6478V73.1166L64.3129 72.351Z" />
            <path d="M62.7812 48.2343L64.1073 48.9999V50.5311L62.7812 51.2968L61.4551 50.5311V48.9999L62.7812 48.2343Z" />
            <path d="M62.7812 58.9521L64.1073 59.7178V61.249L62.7812 62.0146L61.4551 61.249V59.7178L62.7812 58.9521Z" />
            <path d="M113.313 18.7572L114.639 19.5228V21.0541L113.313 21.8197L111.987 21.0541V19.5228L113.313 18.7572Z" />
            <path d="M114.844 32.1564L116.17 32.922V34.4532L114.844 35.2189L113.518 34.4532V32.922L114.844 32.1564Z" />
            <path d="M61.2498 34.8361L62.5759 35.6017V37.1329L61.2498 37.8986L59.9237 37.1329V35.6017L61.2498 34.8361Z" />
            <path d="M65.844 21.4376L67.1701 22.2032V23.7345L65.844 24.5001L64.5179 23.7345V22.2032L65.844 21.4376Z" />
            <path d="M90.3439 58.9521L91.67 59.7178V61.249L90.3439 62.0146L89.0178 61.249V59.7178L90.3439 58.9521Z" />
            <path d="M75.0315 80.3909L76.3576 81.1565V82.6877L75.0315 83.4534L73.7054 82.6877V81.1565L75.0315 80.3909Z" />
            <path d="M73.5004 66.9913L74.8265 67.757V69.2882L73.5004 70.0538L72.1743 69.2882V67.757L73.5004 66.9913Z" />
            <path d="M124.031 26.7975L125.357 27.5631V29.0944L124.031 29.86L122.705 29.0944V27.5631L124.031 26.7975Z" />
            <path d="M71.969 42.8746L73.2951 43.6403V45.1715L71.969 45.9371L70.6429 45.1715V43.6403L71.969 42.8746Z" />
            <path d="M76.5625 66.9913L77.8886 67.757V69.2882L76.5625 70.0538L75.2364 69.2882V67.757L76.5625 66.9913Z" />
            <path d="M127.094 26.7975L128.42 27.5631V29.0944L127.094 29.86L125.768 29.0944V27.5631L127.094 26.7975Z" />
            <path d="M128.625 29.4766L129.951 30.2422V31.7734L128.625 32.5391L127.299 31.7734V30.2422L128.625 29.4766Z" />
            <path d="M70.4379 72.351L71.764 73.1166V74.6478L70.4379 75.4135L69.1118 74.6478V73.1166L70.4379 72.351Z" />
            <path d="M68.9065 48.2343L70.2326 48.9999V50.5311L68.9065 51.2968L67.5804 50.5311V48.9999L68.9065 48.2343Z" />
            <path d="M68.9065 58.9521L70.2326 59.7178V61.249L68.9065 62.0146L67.5804 61.249V59.7178L68.9065 58.9521Z" />
            <path d="M119.438 18.7572L120.764 19.5228V21.0541L119.438 21.8197L118.112 21.0541V19.5228L119.438 18.7572Z" />
            <path d="M120.969 32.1564L122.295 32.922V34.4532L120.969 35.2189L119.643 34.4532V32.922L120.969 32.1564Z" />
            <path d="M67.3754 34.8361L68.7015 35.6017V37.1329L67.3754 37.8986L66.0493 37.1329V35.6017L67.3754 34.8361Z" />
            <path d="M96.469 58.9521L97.7951 59.7178V61.249L96.469 62.0146L95.1429 61.249V59.7178L96.469 58.9521Z" />
            <path d="M76.5625 77.7106L77.8886 78.4762V80.0074L76.5625 80.7731L75.2364 80.0074V78.4762L76.5625 77.7106Z" />
            <path d="M75.0315 53.5938L76.3576 54.3594V55.8906L75.0315 56.6562L73.7054 55.8906V54.3594L75.0315 53.5938Z" />
            <path d="M125.563 13.3982L126.889 14.1638V15.6951L125.563 16.4607L124.237 15.6951V14.1638L125.563 13.3982Z" />
            <path d="M134.75 2.67932L136.076 3.44495V4.9762L134.75 5.74182L133.424 4.9762V3.44495L134.75 2.67932Z" />
            <path d="M75.0315 64.3125L76.3576 65.0781V66.6094L75.0315 67.375L73.7054 66.6094V65.0781L75.0315 64.3125Z" />
            <path d="M125.563 24.1174L126.889 24.8831V26.4143L125.563 27.1799L124.237 26.4143V24.8831L125.563 24.1174Z" />
            <path d="M73.5004 40.1957L74.8265 40.9613V42.4926L73.5004 43.2582L72.1743 42.4926V40.9613L73.5004 40.1957Z" />
            <path d="M76.5625 61.6324L77.8886 62.3981V63.9293L76.5625 64.6949L75.2364 63.9293V62.3981L76.5625 61.6324Z" />
            <path d="M127.094 21.4376L128.42 22.2032V23.7345L127.094 24.5001L125.768 23.7345V22.2032L127.094 21.4376Z" />
            <path d="M128.625 24.1174L129.951 24.8831V26.4143L128.625 27.1799L127.299 26.4143V24.8831L128.625 24.1174Z" />
            <path d="M130.156 26.7975L131.482 27.5631V29.0944L130.156 29.86L128.83 29.0944V27.5631L130.156 26.7975Z" />
            <path d="M131.687 29.4766L133.014 30.2422V31.7734L131.687 32.5391L130.361 31.7734V30.2422L131.687 29.4766Z" />
            <path d="M75.0315 37.5157L76.3576 38.2814V39.8126L75.0315 40.5782L73.7054 39.8126V38.2814L75.0315 37.5157Z" />
            <path d="M104.125 61.6324L105.451 62.3981V63.9293L104.125 64.6949L102.799 63.9293V62.3981L104.125 61.6324Z" />
            <path d="M71.969 69.6721L73.2951 70.4377V71.969L71.969 72.7346L70.6429 71.969V70.4377L71.969 69.6721Z" />
            <path d="M122.5 29.4766L123.826 30.2422V31.7734L122.5 32.5391L121.174 31.7734V30.2422L122.5 29.4766Z" />
            <path d="M70.4379 45.554L71.764 46.3196V47.8508L70.4379 48.6165L69.1118 47.8508V46.3196L70.4379 45.554Z" />
            <path d="M70.4379 56.2732L71.764 57.0388V58.5701L70.4379 59.3357L69.1118 58.5701V57.0388L70.4379 56.2732Z" />
            <path d="M120.969 16.0773L122.295 16.8429V18.3741L120.969 19.1398L119.643 18.3741V16.8429L120.969 16.0773Z" />
            <path d="M68.9065 32.1564L70.2326 32.922V34.4532L68.9065 35.2189L67.5804 34.4532V32.922L68.9065 32.1564Z" />
            <path d="M98.0004 56.2732L99.3265 57.0388V58.5701L98.0004 59.3357L96.6743 58.5701V57.0388L98.0004 56.2732Z" />
            <path d="M78.094 75.0316L79.4201 75.7972V77.3285L78.094 78.0941L76.7679 77.3285V75.7972L78.094 75.0316Z" />
            <path d="M81.1566 80.3909L82.4827 81.1565V82.6877L81.1566 83.4534L79.8305 82.6877V81.1565L81.1566 80.3909Z" />
            <path d="M82.6876 77.7106L84.0137 78.4762V80.0074L82.6876 80.7731L81.3615 80.0074V78.4762L82.6876 77.7106Z" />
            <path d="M84.2189 75.0316L85.545 75.7972V77.3285L84.2189 78.0941L82.8928 77.3285V75.7972L84.2189 75.0316Z" />
            <path d="M39.8126 77.7106L41.1387 78.4762V80.0074L39.8126 80.7731L38.4865 80.0074V78.4762L39.8126 77.7106Z" />
            <path d="M38.281 53.5938L39.6071 54.3594V55.8906L38.281 56.6562L36.9549 55.8906V54.3594L38.281 53.5938Z" />
            <path d="M88.8126 13.3982L90.1387 14.1638V15.6951L88.8126 16.4607L87.4865 15.6951V14.1638L88.8126 13.3982Z" />
            <path d="M45.9373 77.7106L47.2634 78.4762V80.0074L45.9373 80.7731L44.6112 80.0074V78.4762L45.9373 77.7106Z" />
            <path d="M44.4059 53.5938L45.732 54.3594V55.8906L44.4059 56.6562L43.0798 55.8906V54.3594L44.4059 53.5938Z" />
            <path d="M44.4059 64.3125L45.732 65.0781V66.6094L44.4059 67.375L43.0798 66.6094V65.0781L44.4059 64.3125Z" />
            <path d="M94.9379 24.1174L96.264 24.8831V26.4143L94.9379 27.1799L93.6118 26.4143V24.8831L94.9379 24.1174Z" />
            <path d="M42.8749 40.1957L44.201 40.9613V42.4926L42.8749 43.2582L41.5488 42.4926V40.9613L42.8749 40.1957Z" />
            <path d="M21.4373 40.1957L22.7634 40.9613V42.4926L21.4373 43.2582L20.1112 42.4926V40.9613L21.4373 40.1957Z" />
            <path d="M19.9062 42.8746L21.2323 43.6403V45.1715L19.9062 45.9371L18.5801 45.1715V43.6403L19.9062 42.8746Z" />
            <path d="M6.1253 45.554L7.45141 46.3196V47.8508L6.1253 48.6165L4.7992 47.8508V46.3196L6.1253 45.554Z" />
            <path d="M9.18738 45.554L10.5135 46.3196V47.8508L9.18738 48.6165L7.86128 47.8508V46.3196L9.18738 45.554Z" />
            <path d="M12.2502 45.554L13.5763 46.3196V47.8508L12.2502 48.6165L10.9241 47.8508V46.3196L12.2502 45.554Z" />
            <path d="M15.3124 45.554L16.6385 46.3196V47.8508L15.3124 48.6165L13.9863 47.8508V46.3196L15.3124 45.554Z" />
            <path d="M10.7187 48.2343L12.0448 48.9999V50.5311L10.7187 51.2968L9.39265 50.5311V48.9999L10.7187 48.2343Z" />
            <path d="M13.7813 48.2343L15.1074 48.9999V50.5311L13.7813 51.2968L12.4552 50.5311V48.9999L13.7813 48.2343Z" />
            <path d="M9.18738 50.9142L10.5135 51.6798V53.2111L9.18738 53.9767L7.86128 53.2111V51.6798L9.18738 50.9142Z" />
            <path d="M6.1253 50.9142L7.45141 51.6798V53.2111L6.1253 53.9767L4.7992 53.2111V51.6798L6.1253 50.9142Z" />
            <path d="M4.59375 53.5938L5.91985 54.3594V55.8906L4.59375 56.6562L3.26765 55.8906V54.3594L4.59375 53.5938Z" />
            <path d="M1.53125 53.5938L2.85735 54.3594V55.8906L1.53125 56.6562L0.205148 55.8906V54.3594L1.53125 53.5938Z" />
            <path d="M18.3749 45.554L19.701 46.3196V47.8508L18.3749 48.6165L17.0488 47.8508V46.3196L18.3749 45.554Z" />
            <path d="M41.3437 69.6721L42.6698 70.4377V71.969L41.3437 72.7346L40.0176 71.969V70.4377L41.3437 69.6721Z" />
            <path d="M91.8754 29.4766L93.2015 30.2422V31.7734L91.8754 32.5391L90.5493 31.7734V30.2422L91.8754 29.4766Z" />
            <path d="M90.3439 32.1564L91.67 32.922V34.4532L90.3439 35.2189L89.0178 34.4532V32.922L90.3439 32.1564Z" />
            <path d="M39.8126 45.554L41.1387 46.3196V47.8508L39.8126 48.6165L38.4865 47.8508V46.3196L39.8126 45.554Z" />
            <path d="M39.8126 56.2732L41.1387 57.0388V58.5701L39.8126 59.3357L38.4865 58.5701V57.0388L39.8126 56.2732Z" />
            <path d="M90.3439 16.0773L91.67 16.8429V18.3741L90.3439 19.1398L89.0178 18.3741V16.8429L90.3439 16.0773Z" />
            <path d="M38.281 32.1564L39.6071 32.922V34.4532L38.281 35.2189L36.9549 34.4532V32.922L38.281 32.1564Z" />
            <path d="M19.9062 32.1564L21.2323 32.922V34.4532L19.9062 35.2189L18.5801 34.4532V32.922L19.9062 32.1564Z" />
            <path d="M21.4373 29.4766L22.7634 30.2422V31.7734L21.4373 32.5391L20.1112 31.7734V30.2422L21.4373 29.4766Z" />
            <path d="M52.0623 77.7106L53.3884 78.4762V80.0074L52.0623 80.7731L50.7362 80.0074V78.4762L52.0623 77.7106Z" />
            <path d="M50.5309 53.5938L51.857 54.3594V55.8906L50.5309 56.6562L49.2048 55.8906V54.3594L50.5309 53.5938Z" />
            <path d="M101.063 13.3982L102.389 14.1638V15.6951L101.063 16.4607L99.7368 15.6951V14.1638L101.063 13.3982Z" />
            <path d="M50.5309 64.3125L51.857 65.0781V66.6094L50.5309 67.375L49.2048 66.6094V65.0781L50.5309 64.3125Z" />
            <path d="M48.9998 40.1957L50.3259 40.9613V42.4926L48.9998 43.2582L47.6737 42.4926V40.9613L48.9998 40.1957Z" />
            <path d="M27.5626 40.1957L28.8887 40.9613V42.4926L27.5626 43.2582L26.2365 42.4926V40.9613L27.5626 40.1957Z" />
            <path d="M9.18738 40.1957L10.5135 40.9613V42.4926L9.18738 43.2582L7.86128 42.4926V40.9613L9.18738 40.1957Z" />
            <path d="M78.094 64.3125L79.4201 65.0781V66.6094L78.094 67.375L76.7679 66.6094V65.0781L78.094 64.3125Z" />
            <path d="M47.4686 69.6721L48.7947 70.4377V71.969L47.4686 72.7346L46.1425 71.969V70.4377L47.4686 69.6721Z" />
            <path d="M98.0004 29.4766L99.3265 30.2422V31.7734L98.0004 32.5391L96.6743 31.7734V30.2422L98.0004 29.4766Z" />
            <path d="M45.9373 45.554L47.2634 46.3196V47.8508L45.9373 48.6165L44.6112 47.8508V46.3196L45.9373 45.554Z" />
            <path d="M45.9373 56.2732L47.2634 57.0388V58.5701L45.9373 59.3357L44.6112 58.5701V57.0388L45.9373 56.2732Z" />
            <path d="M44.4059 32.1564L45.732 32.922V34.4532L44.4059 35.2189L43.0798 34.4532V32.922L44.4059 32.1564Z" />
            <path d="M45.9373 29.4766L47.2634 30.2422V31.7734L45.9373 32.5391L44.6112 31.7734V30.2422L45.9373 29.4766Z" />
            <path d="M22.9688 32.1564L24.2949 32.922V34.4532L22.9688 35.2189L21.6427 34.4532V32.922L22.9688 32.1564Z" />
            <path d="M24.4998 29.4766L25.8259 30.2422V31.7734L24.4998 32.5391L23.1737 31.7734V30.2422L24.4998 29.4766Z" />
            <path d="M9.18738 29.4766L10.5135 30.2422V31.7734L9.18738 32.5391L7.86128 31.7734V30.2422L9.18738 29.4766Z" />
            <path d="M58.1873 77.7106L59.5134 78.4762V80.0074L58.1873 80.7731L56.8612 80.0074V78.4762L58.1873 77.7106Z" />
            <path d="M56.6559 53.5938L57.982 54.3594V55.8906L56.6559 56.6562L55.3298 55.8906V54.3594L56.6559 53.5938Z" />
            <path d="M107.188 13.3982L108.514 14.1638V15.6951L107.188 16.4607L105.862 15.6951V14.1638L107.188 13.3982Z" />
            <path d="M56.6559 64.3125L57.982 65.0781V66.6094L56.6559 67.375L55.3298 66.6094V65.0781L56.6559 64.3125Z" />
            <path d="M55.1248 40.1957L56.4509 40.9613V42.4926L55.1248 43.2582L53.7987 42.4926V40.9613L55.1248 40.1957Z" />
            <path d="M59.7184 26.7975L61.0445 27.5631V29.0944L59.7184 29.86L58.3923 29.0944V27.5631L59.7184 26.7975Z" />
            <path d="M33.6876 40.1957L35.0137 40.9613V42.4926L33.6876 43.2582L32.3615 42.4926V40.9613L33.6876 40.1957Z" />
            <path d="M15.3124 40.1957L16.6385 40.9613V42.4926L15.3124 43.2582L13.9863 42.4926V40.9613L15.3124 40.1957Z" />
            <path d="M84.2189 64.3125L85.545 65.0781V66.6094L84.2189 67.375L82.8928 66.6094V65.0781L84.2189 64.3125Z" />
            <path d="M53.5936 69.6721L54.9197 70.4377V71.969L53.5936 72.7346L52.2675 71.969V70.4377L53.5936 69.6721Z" />
            <path d="M98.0004 34.8361L99.3265 35.6017V37.1329L98.0004 37.8986L96.6743 37.1329V35.6017L98.0004 34.8361Z" />
            <path d="M52.0623 45.554L53.3884 46.3196V47.8508L52.0623 48.6165L50.7362 47.8508V46.3196L52.0623 45.554Z" />
            <path d="M30.6248 45.554L31.9509 46.3196V47.8508L30.6248 48.6165L29.2987 47.8508V46.3196L30.6248 45.554Z" />
            <path d="M52.0623 56.2732L53.3884 57.0388V58.5701L52.0623 59.3357L50.7362 58.5701V57.0388L52.0623 56.2732Z" />
            <path d="M50.5309 32.1564L51.857 32.922V34.4532L50.5309 35.2189L49.2048 34.4532V32.922L50.5309 32.1564Z" />
            <path d="M52.0623 18.7572L53.3884 19.5228V21.0541L52.0623 21.8197L50.7362 21.0541V19.5228L52.0623 18.7572Z" />
            <path d="M50.5309 21.4376L51.857 22.2032V23.7345L50.5309 24.5001L49.2048 23.7345V22.2032L50.5309 21.4376Z" />
            <path d="M52.0623 24.1174L53.3884 24.8831V26.4143L52.0623 27.1799L50.7362 26.4143V24.8831L52.0623 24.1174Z" />
            <path d="M52.0623 29.4766L53.3884 30.2422V31.7734L52.0623 32.5391L50.7362 31.7734V30.2422L52.0623 29.4766Z" />
            <path d="M56.6559 16.0773L57.982 16.8429V18.3741L56.6559 19.1398L55.3298 18.3741V16.8429L56.6559 16.0773Z" />
            <path d="M58.1873 13.3982L59.5134 14.1638V15.6951L58.1873 16.4607L56.8612 15.6951V14.1638L58.1873 13.3982Z" />
            <path d="M29.0937 32.1564L30.4198 32.922V34.4532L29.0937 35.2189L27.7676 34.4532V32.922L29.0937 32.1564Z" />
            <path d="M30.6248 29.4766L31.9509 30.2422V31.7734L30.6248 32.5391L29.2987 31.7734V30.2422L30.6248 29.4766Z" />
            <path d="M10.7187 32.1564L12.0448 32.922V34.4532L10.7187 35.2189L9.39265 34.4532V32.922L10.7187 32.1564Z" />
            <path d="M79.6254 56.2732L80.9515 57.0388V58.5701L79.6254 59.3357L78.2993 58.5701V57.0388L79.6254 56.2732Z" />
            <path d="M81.1566 53.5938L82.4827 54.3594V55.8906L81.1566 56.6562L79.8305 55.8906V54.3594L81.1566 53.5938Z" />
            <path d="M76.5625 56.2732L77.8886 57.0388V58.5701L76.5625 59.3357L75.2364 58.5701V57.0388L76.5625 56.2732Z" />
            <path d="M127.094 16.0773L128.42 16.8429V18.3741L127.094 19.1398L125.768 18.3741V16.8429L127.094 16.0773Z" />
            <path d="M128.625 18.7572L129.951 19.5228V21.0541L128.625 21.8197L127.299 21.0541V19.5228L128.625 18.7572Z" />
            <path d="M130.156 32.1564L131.482 32.922V34.4532L130.156 35.2189L128.83 34.4532V32.922L130.156 32.1564Z" />
            <path d="M130.156 21.4376L131.482 22.2032V23.7345L130.156 24.5001L128.83 23.7345V22.2032L130.156 21.4376Z" />
            <path d="M131.687 24.1174L133.014 24.8831V26.4143L131.687 27.1799L130.361 26.4143V24.8831L131.687 24.1174Z" />
            <path d="M133.219 26.7975L134.545 27.5631V29.0944L133.219 29.86L131.893 29.0944V27.5631L133.219 26.7975Z" />
            <path d="M134.75 29.4766L136.076 30.2422V31.7734L134.75 32.5391L133.424 31.7734V30.2422L134.75 29.4766Z" />
            <path d="M75.0315 32.1564L76.3576 32.922V34.4532L75.0315 35.2189L73.7054 34.4532V32.922L75.0315 32.1564Z" />
            <path d="M78.094 53.5938L79.4201 54.3594V55.8906L78.094 56.6562L76.7679 55.8906V54.3594L78.094 53.5938Z" />
            <path d="M64.3129 77.7106L65.639 78.4762V80.0074L64.3129 80.7731L62.9868 80.0074V78.4762L64.3129 77.7106Z" />
            <path d="M62.7812 53.5938L64.1073 54.3594V55.8906L62.7812 56.6562L61.4551 55.8906V54.3594L62.7812 53.5938Z" />
            <path d="M113.313 13.3982L114.639 14.1638V15.6951L113.313 16.4607L111.987 15.6951V14.1638L113.313 13.3982Z" />
            <path d="M62.7812 64.3125L64.1073 65.0781V66.6094L62.7812 67.375L61.4551 66.6094V65.0781L62.7812 64.3125Z" />
            <path d="M113.313 24.1174L114.639 24.8831V26.4143L113.313 27.1799L111.987 26.4143V24.8831L113.313 24.1174Z" />
            <path d="M114.844 37.5157L116.17 38.2814V39.8126L114.844 40.5782L113.518 39.8126V38.2814L114.844 37.5157Z" />
            <path d="M61.2498 40.1957L62.5759 40.9613V42.4926L61.2498 43.2582L59.9237 42.4926V40.9613L61.2498 40.1957Z" />
            <path d="M65.844 26.7975L67.1701 27.5631V29.0944L65.844 29.86L64.5179 29.0944V27.5631L65.844 26.7975Z" />
            <path d="M90.3439 64.3125L91.67 65.0781V66.6094L90.3439 67.375L89.0178 66.6094V65.0781L90.3439 64.3125Z" />
            <path d="M59.7184 69.6721L61.0445 70.4377V71.969L59.7184 72.7346L58.3923 71.969V70.4377L59.7184 69.6721Z" />
            <path d="M99.5315 37.5157L100.858 38.2814V39.8126L99.5315 40.5782L98.2054 39.8126V38.2814L99.5315 37.5157Z" />
            <path d="M96.469 37.5157L97.7951 38.2814V39.8126L96.469 40.5782L95.1429 39.8126V38.2814L96.469 37.5157Z" />
            <path d="M98.0004 40.1957L99.3265 40.9613V42.4926L98.0004 43.2582L96.6743 42.4926V40.9613L98.0004 40.1957Z" />
            <path d="M91.8754 34.8361L93.2015 35.6017V37.1329L91.8754 37.8986L90.5493 37.1329V35.6017L91.8754 34.8361Z" />
            <path d="M90.3439 37.5157L91.67 38.2814V39.8126L90.3439 40.5782L89.0178 39.8126V38.2814L90.3439 37.5157Z" />
            <path d="M58.1873 45.554L59.5134 46.3196V47.8508L58.1873 48.6165L56.8612 47.8508V46.3196L58.1873 45.554Z" />
            <path d="M58.1873 56.2732L59.5134 57.0388V58.5701L58.1873 59.3357L56.8612 58.5701V57.0388L58.1873 56.2732Z" />
            <path d="M108.719 16.0773L110.045 16.8429V18.3741L108.719 19.1398L107.393 18.3741V16.8429L108.719 16.0773Z" />
            <path d="M56.6559 32.1564L57.982 32.922V34.4532L56.6559 35.2189L55.3298 34.4532V32.922L56.6559 32.1564Z" />
            <path d="M61.2498 18.7572L62.5759 19.5228V21.0541L61.2498 21.8197L59.9237 21.0541V19.5228L61.2498 18.7572Z" />
            <path d="M65.844 16.0773L67.1701 16.8429V18.3741L65.844 19.1398L64.5179 18.3741V16.8429L65.844 16.0773Z" />
            <path d="M71.969 16.0773L73.2951 16.8429V18.3741L71.969 19.1398L70.6429 18.3741V16.8429L71.969 16.0773Z" />
            <path d="M62.7812 10.7189L64.1073 11.4845V13.0157L62.7812 13.7814L61.4551 13.0157V11.4845L62.7812 10.7189Z" />
            <path d="M85.7504 56.2732L87.0765 57.0388V58.5701L85.7504 59.3357L84.4243 58.5701V57.0388L85.7504 56.2732Z" />
            <path d="M70.4379 77.7106L71.764 78.4762V80.0074L70.4379 80.7731L69.1118 80.0074V78.4762L70.4379 77.7106Z" />
            <path d="M68.9065 53.5938L70.2326 54.3594V55.8906L68.9065 56.6562L67.5804 55.8906V54.3594L68.9065 53.5938Z" />
            <path d="M119.438 13.3982L120.764 14.1638V15.6951L119.438 16.4607L118.112 15.6951V14.1638L119.438 13.3982Z" />
            <path d="M68.9065 64.3125L70.2326 65.0781V66.6094L68.9065 67.375L67.5804 66.6094V65.0781L68.9065 64.3125Z" />
            <path d="M119.438 24.1174L120.764 24.8831V26.4143L119.438 27.1799L118.112 26.4143V24.8831L119.438 24.1174Z" />
            <path d="M120.969 37.5157L122.295 38.2814V39.8126L120.969 40.5782L119.643 39.8126V38.2814L120.969 37.5157Z" />
            <path d="M67.3754 40.1957L68.7015 40.9613V42.4926L67.3754 43.2582L66.0493 42.4926V40.9613L67.3754 40.1957Z" />
            <path d="M96.469 64.3125L97.7951 65.0781V66.6094L96.469 67.375L95.1429 66.6094V65.0781L96.469 64.3125Z" />
            <path d="M65.844 69.6721L67.1701 70.4377V71.969L65.844 72.7346L64.5179 71.969V70.4377L65.844 69.6721Z" />
            <path d="M116.375 29.4766L117.701 30.2422V31.7734L116.375 32.5391L115.049 31.7734V30.2422L116.375 29.4766Z" />
            <path d="M117.906 42.8746L119.232 43.6403V45.1715L117.906 45.9371L116.58 45.1715V43.6403L117.906 42.8746Z" />
            <path d="M64.3129 45.554L65.639 46.3196V47.8508L64.3129 48.6165L62.9868 47.8508V46.3196L64.3129 45.554Z" />
            <path d="M64.3129 56.2732L65.639 57.0388V58.5701L64.3129 59.3357L62.9868 58.5701V57.0388L64.3129 56.2732Z" />
            <path d="M114.844 16.0773L116.17 16.8429V18.3741L114.844 19.1398L113.518 18.3741V16.8429L114.844 16.0773Z" />
            <path d="M62.7812 32.1564L64.1073 32.922V34.4532L62.7812 35.2189L61.4551 34.4532V32.922L62.7812 32.1564Z" />
            <path d="M67.3754 18.7572L68.7015 19.5228V21.0541L67.3754 21.8197L66.0493 21.0541V19.5228L67.3754 18.7572Z" />
            <path d="M91.8754 56.2732L93.2015 57.0388V58.5701L91.8754 59.3357L90.5493 58.5701V57.0388L91.8754 56.2732Z" />
            <path d="M42.8749 77.7106L44.201 78.4762V80.0074L42.8749 80.7731L41.5488 80.0074V78.4762L42.8749 77.7106Z" />
            <path d="M41.3437 53.5938L42.6698 54.3594V55.8906L41.3437 56.6562L40.0176 55.8906V54.3594L41.3437 53.5938Z" />
            <path d="M91.8754 13.3982L93.2015 14.1638V15.6951L91.8754 16.4607L90.5493 15.6951V14.1638L91.8754 13.3982Z" />
            <path d="M41.3437 64.3125L42.6698 65.0781V66.6094L41.3437 67.375L40.0176 66.6094V65.0781L41.3437 64.3125Z" />
            <path d="M91.8754 24.1174L93.2015 24.8831V26.4143L91.8754 27.1799L90.5493 26.4143V24.8831L91.8754 24.1174Z" />
            <path d="M39.8126 40.1957L41.1387 40.9613V42.4926L39.8126 43.2582L38.4865 42.4926V40.9613L39.8126 40.1957Z" />
            <path d="M38.281 69.6721L39.6071 70.4377V71.969L38.281 72.7346L36.9549 71.969V70.4377L38.281 69.6721Z" />
            <path d="M88.8126 24.1174L90.1387 24.8831V26.4143L88.8126 27.1799L87.4865 26.4143V24.8831L88.8126 24.1174Z" />
            <path d="M87.2814 26.7975L88.6075 27.5631V29.0944L87.2814 29.86L85.9553 29.0944V27.5631L87.2814 26.7975Z" />
            <path d="M82.6876 24.1174L84.0137 24.8831V26.4143L82.6876 27.1799L81.3615 26.4143V24.8831L82.6876 24.1174Z" />
            <path d="M84.2189 16.0773L85.545 16.8429V18.3741L84.2189 19.1398L82.8928 18.3741V16.8429L84.2189 16.0773Z" />
            <path d="M84.2189 21.4376L85.545 22.2032V23.7345L84.2189 24.5001L82.8928 23.7345V22.2032L84.2189 21.4376Z" />
            <path d="M81.1566 16.0773L82.4827 16.8429V18.3741L81.1566 19.1398L79.8305 18.3741V16.8429L81.1566 16.0773Z" />
            <path d="M87.2814 21.4376L88.6075 22.2032V23.7345L87.2814 24.5001L85.9553 23.7345V22.2032L87.2814 21.4376Z" />
            <path d="M85.7504 24.1174L87.0765 24.8831V26.4143L85.7504 27.1799L84.4243 26.4143V24.8831L85.7504 24.1174Z" />
            <path d="M36.7499 45.554L38.076 46.3196V47.8508L36.7499 48.6165L35.4238 47.8508V46.3196L36.7499 45.554Z" />
            <path d="M36.7499 56.2732L38.076 57.0388V58.5701L36.7499 59.3357L35.4238 58.5701V57.0388L36.7499 56.2732Z" />
            <path d="M87.2814 16.0773L88.6075 16.8429V18.3741L87.2814 19.1398L85.9553 18.3741V16.8429L87.2814 16.0773Z" />
            <path d="M35.2187 32.1564L36.5448 32.922V34.4532L35.2187 35.2189L33.8926 34.4532V32.922L35.2187 32.1564Z" />
            <path d="M16.8438 32.1564L18.1699 32.922V34.4532L16.8438 35.2189L15.5177 34.4532V32.922L16.8438 32.1564Z" />
            <path d="M18.3749 29.4766L19.701 30.2422V31.7734L18.3749 32.5391L17.0488 31.7734V30.2422L18.3749 29.4766Z" />
            <path d="M22.9688 26.7975L24.2949 27.5631V29.0944L22.9688 29.86L21.6427 29.0944V27.5631L22.9688 26.7975Z" />
            <path d="M48.9998 77.7106L50.3259 78.4762V80.0074L48.9998 80.7731L47.6737 80.0074V78.4762L48.9998 77.7106Z" />
            <path d="M47.4686 53.5938L48.7947 54.3594V55.8906L47.4686 56.6562L46.1425 55.8906V54.3594L47.4686 53.5938Z" />
            <path d="M47.4686 64.3125L48.7947 65.0781V66.6094L47.4686 67.375L46.1425 66.6094V65.0781L47.4686 64.3125Z" />
            <path d="M45.9373 40.1957L47.2634 40.9613V42.4926L45.9373 43.2582L44.6112 42.4926V40.9613L45.9373 40.1957Z" />
            <path d="M24.4998 40.1957L25.8259 40.9613V42.4926L24.4998 43.2582L23.1737 42.4926V40.9613L24.4998 40.1957Z" />
            <path d="M6.1253 40.1957L7.45141 40.9613V42.4926L6.1253 43.2582L4.7992 42.4926V40.9613L6.1253 40.1957Z" />
            <path d="M44.4059 69.6721L45.732 70.4377V71.969L44.4059 72.7346L43.0798 71.969V70.4377L44.4059 69.6721Z" />
            <path d="M94.9379 29.4766L96.264 30.2422V31.7734L94.9379 32.5391L93.6118 31.7734V30.2422L94.9379 29.4766Z" />
            <path d="M42.8749 45.554L44.201 46.3196V47.8508L42.8749 48.6165L41.5488 47.8508V46.3196L42.8749 45.554Z" />
            <path d="M42.8749 56.2732L44.201 57.0388V58.5701L42.8749 59.3357L41.5488 58.5701V57.0388L42.8749 56.2732Z" />
            <path d="M41.3437 32.1564L42.6698 32.922V34.4532L41.3437 35.2189L40.0176 34.4532V32.922L41.3437 32.1564Z" />
            <path d="M42.8749 29.4766L44.201 30.2422V31.7734L42.8749 32.5391L41.5488 31.7734V30.2422L42.8749 29.4766Z" />
            <path d="M39.8126 29.4766L41.1387 30.2422V31.7734L39.8126 32.5391L38.4865 31.7734V30.2422L39.8126 29.4766Z" />
            <path d="M41.3437 26.7975L42.6698 27.5631V29.0944L41.3437 29.86L40.0176 29.0944V27.5631L41.3437 26.7975Z" />
            <path d="M55.1248 77.7106L56.4509 78.4762V80.0074L55.1248 80.7731L53.7987 80.0074V78.4762L55.1248 77.7106Z" />
            <path d="M53.5936 53.5938L54.9197 54.3594V55.8906L53.5936 56.6562L52.2675 55.8906V54.3594L53.5936 53.5938Z" />
            <path d="M104.125 13.3982L105.451 14.1638V15.6951L104.125 16.4607L102.799 15.6951V14.1638L104.125 13.3982Z" />
            <path d="M53.5936 64.3125L54.9197 65.0781V66.6094L53.5936 67.375L52.2675 66.6094V65.0781L53.5936 64.3125Z" />
            <path d="M52.0623 40.1957L53.3884 40.9613V42.4926L52.0623 43.2582L50.7362 42.4926V40.9613L52.0623 40.1957Z" />
            <path d="M56.6559 26.7975L57.982 27.5631V29.0944L56.6559 29.86L55.3298 29.0944V27.5631L56.6559 26.7975Z" />
            <path d="M30.6248 40.1957L31.9509 40.9613V42.4926L30.6248 43.2582L29.2987 42.4926V40.9613L30.6248 40.1957Z" />
            <path d="M12.2502 40.1957L13.5763 40.9613V42.4926L12.2502 43.2582L10.9241 42.4926V40.9613L12.2502 40.1957Z" />
            <path d="M81.1566 64.3125L82.4827 65.0781V66.6094L81.1566 67.375L79.8305 66.6094V65.0781L81.1566 64.3125Z" />
            <path d="M50.5309 69.6721L51.857 70.4377V71.969L50.5309 72.7346L49.2048 71.969V70.4377L50.5309 69.6721Z" />
            <path d="M94.9379 34.8361L96.264 35.6017V37.1329L94.9379 37.8986L93.6118 37.1329V35.6017L94.9379 34.8361Z" />
            <path d="M48.9998 45.554L50.3259 46.3196V47.8508L48.9998 48.6165L47.6737 47.8508V46.3196L48.9998 45.554Z" />
            <path d="M27.5626 45.554L28.8887 46.3196V47.8508L27.5626 48.6165L26.2365 47.8508V46.3196L27.5626 45.554Z" />
            <path d="M48.9998 56.2732L50.3259 57.0388V58.5701L48.9998 59.3357L47.6737 58.5701V57.0388L48.9998 56.2732Z" />
            <path d="M47.4686 32.1564L48.7947 32.922V34.4532L47.4686 35.2189L46.1425 34.4532V32.922L47.4686 32.1564Z" />
            <path d="M48.9998 29.4766L50.3259 30.2422V31.7734L48.9998 32.5391L47.6737 31.7734V30.2422L48.9998 29.4766Z" />
            <path d="M26.0314 32.1564L27.3575 32.922V34.4532L26.0314 35.2189L24.7053 34.4532V32.922L26.0314 32.1564Z" />
            <path d="M27.5626 29.4766L28.8887 30.2422V31.7734L27.5626 32.5391L26.2365 31.7734V30.2422L27.5626 29.4766Z" />
            <path d="M12.2502 29.4766L13.5763 30.2422V31.7734L12.2502 32.5391L10.9241 31.7734V30.2422L12.2502 29.4766Z" />
            <path d="M16.8438 26.7975L18.1699 27.5631V29.0944L16.8438 29.86L15.5177 29.0944V27.5631L16.8438 26.7975Z" />
            <path d="M61.2498 77.7106L62.5759 78.4762V80.0074L61.2498 80.7731L59.9237 80.0074V78.4762L61.2498 77.7106Z" />
            <path d="M59.7184 53.5938L61.0445 54.3594V55.8906L59.7184 56.6562L58.3923 55.8906V54.3594L59.7184 53.5938Z" />
            <path d="M110.25 13.3982L111.576 14.1638V15.6951L110.25 16.4607L108.924 15.6951V14.1638L110.25 13.3982Z" />
            <path d="M59.7184 64.3125L61.0445 65.0781V66.6094L59.7184 67.375L58.3923 66.6094V65.0781L59.7184 64.3125Z" />
            <path d="M58.1873 40.1957L59.5134 40.9613V42.4926L58.1873 43.2582L56.8612 42.4926V40.9613L58.1873 40.1957Z" />
            <path d="M62.7812 26.7975L64.1073 27.5631V29.0944L62.7812 29.86L61.4551 29.0944V27.5631L62.7812 26.7975Z" />
            <path d="M36.7499 40.1957L38.076 40.9613V42.4926L36.7499 43.2582L35.4238 42.4926V40.9613L36.7499 40.1957Z" />
            <path d="M18.3749 40.1957L19.701 40.9613V42.4926L18.3749 43.2582L17.0488 42.4926V40.9613L18.3749 40.1957Z" />
            <path d="M87.2814 64.3125L88.6075 65.0781V66.6094L87.2814 67.375L85.9553 66.6094V65.0781L87.2814 64.3125Z" />
            <path d="M56.6559 69.6721L57.982 70.4377V71.969L56.6559 72.7346L55.3298 71.969V70.4377L56.6559 69.6721Z" />
            <path d="M101.063 34.8361L102.389 35.6017V37.1329L101.063 37.8986L99.7368 37.1329V35.6017L101.063 34.8361Z" />
            <path d="M55.1248 45.554L56.4509 46.3196V47.8508L55.1248 48.6165L53.7987 47.8508V46.3196L55.1248 45.554Z" />
            <path d="M33.6876 45.554L35.0137 46.3196V47.8508L33.6876 48.6165L32.3615 47.8508V46.3196L33.6876 45.554Z" />
            <path d="M55.1248 56.2732L56.4509 57.0388V58.5701L55.1248 59.3357L53.7987 58.5701V57.0388L55.1248 56.2732Z" />
            <path d="M53.5936 32.1564L54.9197 32.922V34.4532L53.5936 35.2189L52.2675 34.4532V32.922L53.5936 32.1564Z" />
            <path d="M58.1873 18.7572L59.5134 19.5228V21.0541L58.1873 21.8197L56.8612 21.0541V19.5228L58.1873 18.7572Z" />
            <path d="M32.1558 32.1564L33.4819 32.922V34.4532L32.1558 35.2189L30.8297 34.4532V32.922L32.1558 32.1564Z" />
            <path d="M33.6876 29.4766L35.0137 30.2422V31.7734L33.6876 32.5391L32.3615 31.7734V30.2422L33.6876 29.4766Z" />
            <path d="M13.7813 32.1564L15.1074 32.922V34.4532L13.7813 35.2189L12.4552 34.4532V32.922L13.7813 32.1564Z" />
            <path d="M15.3124 29.4766L16.6385 30.2422V31.7734L15.3124 32.5391L13.9863 31.7734V30.2422L15.3124 29.4766Z" />
            <path d="M19.9062 26.7975L21.2323 27.5631V29.0944L19.9062 29.86L18.5801 29.0944V27.5631L19.9062 26.7975Z" />
            <path d="M67.3754 77.7106L68.7015 78.4762V80.0074L67.3754 80.7731L66.0493 80.0074V78.4762L67.3754 77.7106Z" />
            <path d="M65.844 53.5938L67.1701 54.3594V55.8906L65.844 56.6562L64.5179 55.8906V54.3594L65.844 53.5938Z" />
            <path d="M116.375 13.3982L117.701 14.1638V15.6951L116.375 16.4607L115.049 15.6951V14.1638L116.375 13.3982Z" />
            <path d="M65.844 64.3125L67.1701 65.0781V66.6094L65.844 67.375L64.5179 66.6094V65.0781L65.844 64.3125Z" />
            <path d="M116.375 24.1174L117.701 24.8831V26.4143L116.375 27.1799L115.049 26.4143V24.8831L116.375 24.1174Z" />
            <path d="M117.906 37.5157L119.232 38.2814V39.8126L117.906 40.5782L116.58 39.8126V38.2814L117.906 37.5157Z" />
            <path d="M64.3129 40.1957L65.639 40.9613V42.4926L64.3129 43.2582L62.9868 42.4926V40.9613L64.3129 40.1957Z" />
            <path d="M68.9065 26.7975L70.2326 27.5631V29.0944L68.9065 29.86L67.5804 29.0944V27.5631L68.9065 26.7975Z" />
            <path d="M93.4064 64.3125L94.7325 65.0781V66.6094L93.4064 67.375L92.0803 66.6094V65.0781L93.4064 64.3125Z" />
            <path d="M62.7812 69.6721L64.1073 70.4377V71.969L62.7812 72.7346L61.4551 71.969V70.4377L62.7812 69.6721Z" />
            <path d="M113.313 29.4766L114.639 30.2422V31.7734L113.313 32.5391L111.987 31.7734V30.2422L113.313 29.4766Z" />
            <path d="M61.2498 45.554L62.5759 46.3196V47.8508L61.2498 48.6165L59.9237 47.8508V46.3196L61.2498 45.554Z" />
            <path d="M61.2498 56.2732L62.5759 57.0388V58.5701L61.2498 59.3357L59.9237 58.5701V57.0388L61.2498 56.2732Z" />
            <path d="M111.781 16.0773L113.107 16.8429V18.3741L111.781 19.1398L110.455 18.3741V16.8429L111.781 16.0773Z" />
            <path d="M59.7184 32.1564L61.0445 32.922V34.4532L59.7184 35.2189L58.3923 34.4532V32.922L59.7184 32.1564Z" />
            <path d="M62.7812 16.0773L64.1073 16.8429V18.3741L62.7812 19.1398L61.4551 18.3741V16.8429L62.7812 16.0773Z" />
            <path d="M88.8126 56.2732L90.1387 57.0388V58.5701L88.8126 59.3357L87.4865 58.5701V57.0388L88.8126 56.2732Z" />
            <path d="M90.3439 53.5938L91.67 54.3594V55.8906L90.3439 56.6562L89.0178 55.8906V54.3594L90.3439 53.5938Z" />
            <path d="M90.3439 48.2343L91.67 48.9999V50.5311L90.3439 51.2968L89.0178 50.5311V48.9999L90.3439 48.2343Z" />
            <path d="M91.8754 50.9142L93.2015 51.6798V53.2111L91.8754 53.9767L90.5493 53.2111V51.6798L91.8754 50.9142Z" />
            <path d="M91.8754 45.554L93.2015 46.3196V47.8508L91.8754 48.6165L90.5493 47.8508V46.3196L91.8754 45.554Z" />
            <path d="M93.4064 42.8746L94.7325 43.6403V45.1715L93.4064 45.9371L92.0803 45.1715V43.6403L93.4064 42.8746Z" />
            <path d="M88.8126 50.9142L90.1387 51.6798V53.2111L88.8126 53.9767L87.4865 53.2111V51.6798L88.8126 50.9142Z" />
            <path d="M88.8126 45.554L90.1387 46.3196V47.8508L88.8126 48.6165L87.4865 47.8508V46.3196L88.8126 45.554Z" />
            <path d="M90.3439 42.8746L91.67 43.6403V45.1715L90.3439 45.9371L89.0178 45.1715V43.6403L90.3439 42.8746Z" />
            <path d="M87.2814 53.5938L88.6075 54.3594V55.8906L87.2814 56.6562L85.9553 55.8906V54.3594L87.2814 53.5938Z" />
            <path d="M87.2814 48.2343L88.6075 48.9999V50.5311L87.2814 51.2968L85.9553 50.5311V48.9999L87.2814 48.2343Z" />
            <path d="M73.5004 77.7106L74.8265 78.4762V80.0074L73.5004 80.7731L72.1743 80.0074V78.4762L73.5004 77.7106Z" />
            <path d="M71.969 53.5938L73.2951 54.3594V55.8906L71.969 56.6562L70.6429 55.8906V54.3594L71.969 53.5938Z" />
            <path d="M122.5 13.3982L123.826 14.1638V15.6951L122.5 16.4607L121.174 15.6951V14.1638L122.5 13.3982Z" />
            <path d="M131.687 2.67932L133.014 3.44495V4.9762L131.687 5.74182L130.361 4.9762V3.44495L131.687 2.67932Z" />
            <path d="M71.969 64.3125L73.2951 65.0781V66.6094L71.969 67.375L70.6429 66.6094V65.0781L71.969 64.3125Z" />
            <path d="M122.5 24.1174L123.826 24.8831V26.4143L122.5 27.1799L121.174 26.4143V24.8831L122.5 24.1174Z" />
            <path d="M70.4379 40.1957L71.764 40.9613V42.4926L70.4379 43.2582L69.1118 42.4926V40.9613L70.4379 40.1957Z" />
            <path d="M107.188 61.6324L108.514 62.3981V63.9293L107.188 64.6949L105.862 63.9293V62.3981L107.188 61.6324Z" />
            <path d="M108.719 64.3125L110.045 65.0781V66.6094L108.719 67.375L107.393 66.6094V65.0781L108.719 64.3125Z" />
            <path d="M68.9065 69.6721L70.2326 70.4377V71.969L68.9065 72.7346L67.5804 71.969V70.4377L68.9065 69.6721Z" />
            <path d="M119.438 29.4766L120.764 30.2422V31.7734L119.438 32.5391L118.112 31.7734V30.2422L119.438 29.4766Z" />
            <path d="M67.3754 45.554L68.7015 46.3196V47.8508L67.3754 48.6165L66.0493 47.8508V46.3196L67.3754 45.554Z" />
            <path d="M67.3754 56.2732L68.7015 57.0388V58.5701L67.3754 59.3357L66.0493 58.5701V57.0388L67.3754 56.2732Z" />
            <path d="M117.906 16.0773L119.232 16.8429V18.3741L117.906 19.1398L116.58 18.3741V16.8429L117.906 16.0773Z" />
            <path d="M65.844 32.1564L67.1701 32.922V34.4532L65.844 35.2189L64.5179 34.4532V32.922L65.844 32.1564Z" />
            <path d="M94.9379 56.2732L96.264 57.0388V58.5701L94.9379 59.3357L93.6118 58.5701V57.0388L94.9379 56.2732Z" />
            <path d="M41.3437 85.7496L42.6698 86.5153V88.0465L41.3437 88.8121L40.0176 88.0465V86.5153L41.3437 85.7496Z" />
            <path d="M42.8749 88.4298L44.201 89.1954V90.7267L42.8749 91.4923L41.5488 90.7267V89.1954L42.8749 88.4298Z" />
            <path d="M44.4059 91.1093L45.732 91.8749V93.4061L44.4059 94.1718L43.0798 93.4061V91.8749L44.4059 91.1093Z" />
            <path d="M45.937 93.7893L47.2631 94.5549V96.0862L45.937 96.8518L44.6109 96.0862V94.5549L45.937 93.7893Z" />
            <path d="M53.5936 96.4689L54.9197 97.2345V98.7657L53.5936 99.5314L52.2675 98.7657V97.2345L53.5936 96.4689Z" />
            <path d="M52.0619 93.7893L53.388 94.5549V96.0862L52.0619 96.8518L50.7358 96.0862V94.5549L52.0619 93.7893Z" />
            <path d="M55.1248 99.1482L56.4509 99.9138V101.445L55.1248 102.211L53.7987 101.445V99.9138L55.1248 99.1482Z" />
            <path d="M56.6559 96.4689L57.982 97.2345V98.7657L56.6559 99.5314L55.3298 98.7657V97.2345L56.6559 96.4689Z" />
            <path d="M55.1248 93.7893L56.4509 94.5549V96.0862L55.1248 96.8518L53.7987 96.0862V94.5549L55.1248 93.7893Z" />
            <path d="M61.2498 99.1482L62.5759 99.9138V101.445L61.2498 102.211L59.9237 101.445V99.9138L61.2498 99.1482Z" />
            <path d="M56.6559 101.828L57.982 102.594V104.125L56.6559 104.891L55.3298 104.125V102.594L56.6559 101.828Z" />
            <path d="M67.3751 104.507L68.7012 105.273V106.804L67.3751 107.57L66.049 106.804V105.273L67.3751 104.507Z" />
            <path d="M70.4379 104.507L71.764 105.273V106.804L70.4379 107.57L69.1118 106.804V105.273L70.4379 104.507Z" />
            <path d="M73.5001 104.507L74.8262 105.273V106.804L73.5001 107.57L72.174 106.804V105.273L73.5001 104.507Z" />
            <path d="M71.969 107.187L73.2951 107.953V109.484L71.969 110.25L70.6429 109.484V107.953L71.969 107.187Z" />
            <path d="M73.5001 109.867L74.8262 110.633V112.164L73.5001 112.93L72.174 112.164V110.633L73.5001 109.867Z" />
            <path d="M75.0315 112.546L76.3576 113.312V114.843L75.0315 115.609L73.7054 114.843V113.312L75.0315 112.546Z" />
            <path d="M78.094 112.546L79.4201 113.312V114.843L78.094 115.609L76.7679 114.843V113.312L78.094 112.546Z" />
            <path d="M79.625 115.227L80.9511 115.993V117.524L79.625 118.29L78.2989 117.524V115.993L79.625 115.227Z" />
            <path d="M68.9065 107.187L70.2326 107.953V109.484L68.9065 110.25L67.5804 109.484V107.953L68.9065 107.187Z" />
            <path d="M75.0315 96.4689L76.3576 97.2345V98.7657L75.0315 99.5314L73.7054 98.7657V97.2345L75.0315 96.4689Z" />
            <path d="M68.9065 101.828L70.2326 102.594V104.125L68.9065 104.891L67.5804 104.125V102.594L68.9065 101.828Z" />
            <path d="M139.344 16.0773L140.67 16.8429V18.3741L139.344 19.1398L138.018 18.3741V16.8429L139.344 16.0773Z" />
            <path d="M140.875 24.1174L142.201 24.8831V26.4143L140.875 27.1799L139.549 26.4143V24.8831L140.875 24.1174Z" />
          </g>
        </g>
        {continents.length && zoom === 'Global'
          ? continents.map(({ continent, partOfKnownPortfolio }, index) => (
              <g
                x={`${markerPositions[continent].x}`}
                y={markerPositions[continent].y}
                key={`continent-${continent}`}
                transform={`translate(${markerPositions[continent].x},${markerPositions[continent].y})`}
              >
                <circle r="22" fill="#ffffff" />
                <text
                  x={0}
                  y={3}
                  color={accent.ocean4}
                  textAnchor="middle"
                  fontFamily="IBMPlexSans"
                  fontWeight={400}
                  fontSize={9}
                >
                  {`${localizeFloat(partOfKnownPortfolio * 100, 1)}%`}
                </text>
              </g>
            ))
          : null}
      </svg>
      {!isTablet && !isMobile ? (
        <CountryMarkers
          countries={topThreeCountries}
          style={countryMarkersStyles}
        />
      ) : null}
    </StyledWrapper>
  );
};
