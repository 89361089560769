import React from 'react';
import styled from 'styled-components';

import { SPACING_16, SPACING_8 } from '../../constants/spacing';
import { StyledCell, StyledRow, TableList } from '../lists/TableList';
import { paragraphSmallSize } from '../../constants/text';
import { accent } from '../../constants/colors';

const StyledTableList = styled(TableList)`
  width: 100%;
  border-spacing: 0;
`;

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_8};

  &:first-child {
    padding-left: ${SPACING_16};
  }

  &:last-child {
    padding-right: ${SPACING_16};
  }

  span {
    font-size: ${paragraphSmallSize};
    font-weight: 400;
    text-align: left;
  }
`;

const LightStyledRow = styled(StyledRow)`
  &:nth-child(odd) {
    background: ${accent.sand150};
  }
`;

export const TableListSmall = (props) => {
  const { columns, ...rest } = props;

  const updatedColumns = columns.map((item) => ({
    ...item,
    component: item.component ? item.component : LightStyledCell,
  }));

  return (
    <StyledTableList
      rowComponent={LightStyledRow}
      {...rest}
      columns={updatedColumns}
    />
  );
};
