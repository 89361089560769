import { findClosest } from '@formue-app/core';

export const formatChartData = (data, includeDateZero, label = '') => {
  // The data needs to start from the value of zero. We need to fake
  // the first value in order to achieve that.
  // This will also help for "THIS MONTH" period filter since we will have
  // an actual pair of values to represent.
  const dateString = data[0].p.toString();
  const year = dateString.substring(0, 4);
  // Substracts one month
  const month = parseInt(dateString.substring(4), 10) - 1;
  const dateZero = new Date(year, month, 0);
  const dataLength = data.length;

  let marketValuesFromZero = [
    { twr: 0, mv: data[0].mv, x: dateZero },
    ...data.map(({ twr, mv, p, historicalLabel }, index) => {
      return {
        twr: twr ? twr : 0,
        mv: mv ? mv : 0,
        historicalLabel: historicalLabel,
        p: p,
        x: new Date(
          p.toString().substring(0, 4),
          parseInt(p.toString().substring(4), 10),
          0
        ),
        label: index === dataLength - 1 ? label : null,
      };
    }),
  ];

  if (!includeDateZero) marketValuesFromZero.shift();

  return marketValuesFromZero;
};

export const getLabelPositions = (
  labelItems,
  chartHeight = 100,
  offset = 7,
  numberOfLabelSteps = 40
) => {
  const labelItemsArray = Object.entries(labelItems).map((e) => ({
    assetClass: e[0],
    y: e[1],
  }));
  if (!labelItemsArray.length) return {};

  const possiblePositions = [...Array(numberOfLabelSteps)].map(
    (_, i) => (i * chartHeight) / numberOfLabelSteps
  );

  let labelPositions = {};
  const sortedLabelItems = labelItemsArray.sort((a, b) => a.y - b.y);

  sortedLabelItems.forEach((item) => {
    let position = findClosest(item.y, possiblePositions);
    // eslint-disable-next-line no-loop-func
    while (Object.values(labelPositions).find((_item) => _item === position)) {
      position += offset;
    }
    labelPositions = { ...labelPositions, [item.assetClass]: position };
  });

  return labelPositions;
};

export const formatScatterChartData = (data, xSelector, ySelector) => {
  return data.map((item, index) => ({
    x: item.hasOwnProperty(xSelector) ? item[xSelector] : 0,
    y: item.hasOwnProperty(ySelector) ? item[ySelector] : 0,
    ...item,
  }));
};

export const formatChartTimeSeriesData = (
  data,
  dateKeySelector = 'p',
  dateKey = 'x'
) => {
  return data.map((item) => {
    const date = new Date(
      item[dateKeySelector].toString().substring(0, 4),
      parseInt(item[dateKeySelector].toString().substring(4), 10),
      0
    );
    return {
      [dateKey]: date,
      ...item,
    };
  });
};

export const formatFundClassificationChartData = (dataObject) => {
  return Object.keys(dataObject).map(key => (
    { label: key, value: +dataObject[key].share.toFixed(3) }
  ))
};
