import React from 'react';
import styled, { keyframes } from 'styled-components';

import { primary } from '../../constants/colors';
import { SPACING_8 } from '../../constants/spacing';
import { GridElement } from '../layout';

import { H4 } from '../texts';

const dash = (circumference) => keyframes`
  0% {
    stroke-dasharray: ${circumference} ${circumference};
  }
  100% {
    stroke-dasharray: 0 ${circumference};
  }
`;

const ActivityIndicatorWrapper = styled(GridElement)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const SpinnerWrapper = styled.div`
  width: ${(props) => props.size || 50}px;
  height: ${(props) => props.size || 50}px;
`;

const SpinnerSvg = styled.svg`
  vertical-align: baseline;

  circle {
    animation: ${(props) => dash(props.circumference)} 2s infinite ease forwards;
    stroke-dashoffset: ${(props) => props.circumference};
  }
`;

export const ActivityIndicator = (props) => {
  const { size = 50, loadingColor, ...rest } = props;
  const r = size / 2;
  const stroke = r * 0.19;

  return (
    <SpinnerWrapper size={size} {...rest}>
      <SpinnerSvg
        viewBox={`0 0 ${size} ${size}`}
        circumference={2 * Math.PI * r}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={r - stroke / 2}
          stroke={loadingColor || primary}
          strokeWidth={stroke}
          fill="none"
        />
      </SpinnerSvg>
    </SpinnerWrapper>
  );
};

export const CenteredActivityIndicator = (props) => {
  const { columns, loadingText, style, ...rest } = props;
  return (
    <ActivityIndicatorWrapper columns={columns}>
      <ActivityIndicator {...rest} style={style} />
      {loadingText && <H4 style={{ textAlign: 'center' }}>{loadingText}</H4>}
    </ActivityIndicatorWrapper>
  );
};
