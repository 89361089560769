import { takeEvery, call, put } from 'redux-saga/effects';

import { getReadItems, markItemAsRead } from '../../../services/utilities/readItems';
import { actions } from './actions';

export const getEntities = state => state.entities;

function* hydrateReadItems() {
  yield takeEvery(actions.hydrateReadItems, function*({ payload }) {
    const type = payload;
    const locallySavedReadIds = yield call(getReadItems, type);
    if (locallySavedReadIds) {
      const items = locallySavedReadIds.map(id => ({
        id,
        type,
      }));

      yield put(actions.setReadItems(items));
    }

    yield put(actions.setAppIconBadgeNumber());
  });
}

function* saveReadItem() {
  yield takeEvery(actions.saveReadItem, function*({ payload }) {
    yield call(markItemAsRead, payload.type, payload.id);
    yield put(actions.setReadItems([payload]));
    yield put(actions.setAppIconBadgeNumber());
  });
}

export const sagas = [saveReadItem, hydrateReadItems];
