import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { formatBigMoney, percentage, truncateString } from '@formue-app/core';

import { mobileCondition } from '../../../../constants/media';
import { textLightest, uiActive } from '../../../../constants/colors';
import { SPACING_32, SPACING_8 } from '../../../../constants/spacing';
import { Paragraph, ParagraphSmall } from '../../../texts';
import { ReactComponent as PyramidTop } from '../../../../assets/portfolio/pyramid-full-top.svg';
import { ReactComponent as PyramidMiddle } from '../../../../assets/portfolio/pyramid-full-middle.svg';
import { ReactComponent as PyramidBottom } from '../../../../assets/portfolio/pyramid-full-bottom.svg';

const StyledNumber = styled.div`
  display: flex;
  background: ${uiActive};
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  margin-right: ${SPACING_32};
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
`;

const NumberText = styled(Paragraph)`
  text-align: center;
  color: ${textLightest};
  font-weight: bold;
`;

const pyramidComponent = (key) => {
  switch (key) {
    case 'S':
      return <PyramidBottom style={{ width: 32, height: 32 }} />;
    case 'MV':
      return <PyramidMiddle style={{ width: 32, height: 32 }} />;
    case 'AV':
      return <PyramidTop style={{ width: 32, height: 32 }} />;
    default:
      break;
  }
};

export const useFundsListColumns = (props) => {
  const items = props;

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  if (!items.length) {
    return null;
  }

  let columns = [
    {
      key: 'index',
      render: (item) => (
        <StyledNumber>
          <NumberText>{item.index + 1}</NumberText>
        </StyledNumber>
      ),
      defaultSort: true,
      width: '5%',
    },
    {
      key: 'name',
      width: '30%',
    },
    {
      key: 'marketValue',
      render: (item) => formatBigMoney(item.marketValue, false),
      type: 'number',
      width: '15%',
    },
    {
      key: 'partOfPortfolio',
      render: (item) => percentage(item.partOfPortfolio * 100, 2),
      type: 'number',
      width: '15%',
    },
    {
      key: 'type',
      render: (item) => pyramidComponent(item.assetParts[0].assetGroup),
      type: 'number',
      width: '35%',
    },
  ];

  if (mobileSize) {
    columns = [
      {
        key: 'index',
        render: (item) => (
          <StyledNumber>
            <NumberText>{item.index + 1}</NumberText>
          </StyledNumber>
        ),
        defaultSort: true,
        width: '10%',
      },
      {
        key: 'name',
        render: (item) => (
          <>
            <StyledNumber>
              <NumberText>{item.index + 1}</NumberText>
            </StyledNumber>
            <Paragraph>{truncateString(item.name, 32)}</Paragraph>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: SPACING_8,
              }}
            >
              <ParagraphSmall style={{ marginRight: SPACING_32 }}>
                {formatBigMoney(item.marketValue, false)}
              </ParagraphSmall>
              <ParagraphSmall>
                {percentage(item.partOfPortfolio * 100, 2)}
              </ParagraphSmall>
            </div>
          </>
        ),
        width: '80%',
      },
      {
        key: 'type',
        render: (item) => pyramidComponent(item.assetParts[0].assetGroup),
        type: 'number',
        width: '10%',
      },
    ];
  }

  return columns;
};
