import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import { formatTwrValue, getAssetClassName } from '@formue-app/core';
import { bodyFontStack } from '../../../fonts';
import { accent } from '../../../constants/colors';
import { mobileCondition } from '../../../constants/media';

export const PortfolioHoldingsChartLabel = (props) => {
  const { x, y, width, datum, height, fontSize, showTooltip } = props;

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const textRef = useRef(false);

  const assetClassName = datum.class ? getAssetClassName(datum.class).toUpperCase() : '';
  // We need to make the svg text labels to break lines.
  // This works for our case, altough it's not the ideal solution since it
  // will break line eaven if there are two really short words (which is not
  // the case for our asset class names)
  const assetClassNameLines = assetClassName.split(' ');
  let correctedY = y;
  const yCorrection = mobileSize ? 12 : 6;
  if (!showTooltip) {
    correctedY =
      datum.twr < 0 ? (correctedY += yCorrection) : (correctedY -= yCorrection);
  } else {
    correctedY -= yCorrection;
  }

  return (
    <g>
      <g transform={`translate(${x}, ${correctedY}) `} width={width}>
        <text
          textAnchor="middle"
          style={{
            fill: accent.velvet2,
            fontFamily: bodyFontStack,
            fontWeight: '500',
            fontSize: `${mobileSize ? fontSize * 3 : fontSize}px`,
          }}
        >
          {
            // If (twr > 1) set the number of decimals to 0 (zero)
            // We do this in order to fit the labels horizontally
            // Example: twr: 0.6743 => "+67.3%"  twr: 1.2311 => "+123%"
            formatTwrValue(datum.twr, datum.twr >= 1 ? 0 : 1)
          }
        </text>
      </g>

      {mobileSize ? (
        <g transform={`translate(${x}, ${height - 20})`}>
          <text
            x={-90}
            // lower the vertical position of the each line by 5
            y={5}
            fontSize="10"
            style={{
              fill: accent.velvet250,
              fontFamily: bodyFontStack,
              fontWeight: '500',
              fontSize: `${fontSize * 3}px`,
              transform: 'rotate(90deg)',
            }}
            ref={textRef}
          >
            {assetClassName}
          </text>
        </g>
      ) : (
        <g transform={`translate(${x}, ${height - 20})`}>
          {assetClassNameLines.map((line, index) => (
            <text
              key={`lable-${index}`}
              textAnchor="middle"
              x="0"
              // lower the vertical position of the each line by 5
              y={5 + index * 5}
              fontSize="10"
              style={{
                fill: accent.velvet250,
                fontFamily: bodyFontStack,
                fontWeight: '500',
                fontSize: `${fontSize}px`,
              }}
              ref={textRef}
            >
              {line}
            </text>
          ))}
        </g>
      )}
    </g>
  );
};
