import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';

import { backgroundMain } from '../../constants/colors';

import { GridElement } from '../../components/layout/GridElement';
import { LensesFooter } from '../../components/portfolio/lenses/LensesFooter';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { SectorsHeader } from '../../components/portfolio/lenses/sectors/SectorsHeader';
import { SectorsList } from '../../components/portfolio/lenses/sectors/SectorsList';
import { ParagraphSmall, Paragraph } from '../../components/texts';
import { SPACING_48 } from '../../constants/spacing';
import { Modal } from '../../components/common/Modal';
import { CoverageReadMore } from '../../components/portfolio/lenses/CoverageReadMore';

const {
  entities: {
    securities: { securitiesBySectorSelector, securitiesMetadataSelector },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

export const SectorsPage = () => {
  const loading = useResource(['SECURITIES/INDEX']);
  const bySector = useSelector(securitiesBySectorSelector);
  const metadata = useSelector(securitiesMetadataSelector);
  const { coverageSector = 0 } = metadata;
  const [modalOpen, setModalOpen] = useState(false);

  if (!bySector) return null;

  if (loading) {
    return (
      <GridElement columns={12}>
        <StyledCenteredActivityIndicator
          columns={12}
          size={30}
          background={backgroundMain}
        />
      </GridElement>
    );
  }

  return (
    <>
      <SectorsHeader />
      <SectorsList />
      <LensesFooter
        coverage={coverageSector}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <Paragraph style={{ fontWeight: 500 }}>
          {t('geography:classificationMethod')}
        </Paragraph>
        <ParagraphSmall>{t('geography:countryAllocationText')}</ParagraphSmall>
      </LensesFooter>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel={t(`esg:coverageReadMore:title`)}
        padding={SPACING_48}
      >
        <CoverageReadMore />
      </Modal>
    </>
  );
};
