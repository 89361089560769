import { createSelector } from 'reselect';
import { sortMarketValueItems } from '@formue-app/core';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  marketValuesAllIds,
  marketValuesById,
  allMarketValuesItemsSelector,
] = resourceSelectors('marketValues');

export const marketValuesSelector = createSelector(
  allMarketValuesItemsSelector,
  (items) => {
    return items.sort(sortMarketValueItems);
  }
);

export const marketValuesByAssetClassSelector = createSelector(
  allMarketValuesItemsSelector,
  (data) => {
    const assetClasses = ['b', 'o', 't', 'd', 'e', 'g', 'i', 'p'];

    const byAssetClass = assetClasses.reduce((accumulator, assetClass) => {
      return {
        ...accumulator,
        [assetClass]: data.map((date) => {
          return {
            ...date.perAssetClass.find((item) => item.ac === assetClass),
            p: date.p,
          };
        }),
      };
    }, 0);

    return byAssetClass;
  }
);

export const allAssetClassTwrValuesSelector = createSelector(
  allMarketValuesItemsSelector,
  (marketValues) => {
    const values = [];
    marketValues.forEach((marketValue) => {
      marketValue.perAssetClass.forEach((assetClass) => {
        values.push(assetClass.twr);
      });
    });

    return values;
  }
);
