import React, { useState } from 'react';
import styled from 'styled-components';

import { ANNUAL_REVIEW_REPORT_CONTENT_SPACING } from '../../constants/spacing';
import { accent } from '../../constants/colors';

import { SubmittedPresentation } from './SubmittedPresentation';
import { PresentationShareConfiguration } from './PresentationShareConfiguration';
import { MainGrid } from '../layout';

const Wrapper = styled(MainGrid)`
  min-width: 100%;
  height: 100%;
  position: fixed;
  padding: ${ANNUAL_REVIEW_REPORT_CONTENT_SPACING};
  padding-top: 100px;
  background-color: ${accent.sand1};
  z-index: 10;
  overflow: scroll;
`;

export const ShareOverlay = (props) => {
  const { slides, setShowSharePanel } = props;

  const [submitted, setSubmitted] = useState(false);

  if (submitted) {
    return (
      <Wrapper>
        <SubmittedPresentation setShowSharePanel={setShowSharePanel} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PresentationShareConfiguration
        slides={slides}
        setSubmitted={setSubmitted}
      />
    </Wrapper>
  );
};
