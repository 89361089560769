import React from 'react';

import { ButtonSmall } from '../../buttons';

export const TodoListItemLink = (props) => {
  const { link, className } = props;

  if (!link) {
    return null;
  }

  const { label, url, onClick } = link;

  if (onClick) {
    return (
      <ButtonSmall className={className} onClick={onClick}>
        {label}
      </ButtonSmall>
    );
  }

  return (
    <ButtonSmall className={className} to={url} external={true}>
      {label}
    </ButtonSmall>
  );
};
