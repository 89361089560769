import { apiFetch } from './utils/api';

export const investmentStrategyImplementationPlans = {
  /**
   * @desc Get a investment strategy's products
   *
   * @return json - data or error message
   */
  get: async (args = {}) => {
    if (!args.strategyId) {
      throw new Error('Strategy ID required for this API endpoint');
    }

    const options = {
      method: 'GET',
    };
    const url = `/v3/investment-strategies/${args.strategyId}/implementation-plan`;

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
  patch: async (args = {}) => {
    if (!args.strategyId) {
      throw new Error('Strategy ID required for this API endpoint');
    }

    // const options = {
    //   method: 'PATCH',
    //   body: args.payload,
    // };
    // const url = `/v3/investment-strategies/${args.strategyId}/implementation-plan`;

    // const { body } = await apiFetch(url, {
    //   ...options,
    // });

    // return body;
  },
};
