import { useSelector } from 'react-redux';
import { allFundsSelector } from '../../store/entities/selectors/funds';
import { portfolioTotalSelector } from '../../store/entities/selectors/portfolios';
import { percentage } from '../localization';

/**
 * Custom react hook to retrieve underlying funds and calculate the share of
 * each underlying fund in the portfolio
 * @param {array} underlyingFunds
 * @param {number} fundValue
 */
export const useUnderlyingFunds = (underlyingFunds, fundValue) => {
  const portfolioTotal = useSelector(portfolioTotalSelector);
  const fundsById = useSelector(state => state.entities.funds.byId);
  const funds = underlyingFunds.map(item => fundsById[item.id]);

  return funds
    .filter(fund => fund)
    .sort((a, b) => b.marketValue - a.marketValue)
    .map(underlyingFund => ({
      ...underlyingFund,
      shareInFund: underlyingFund.marketValue / fundValue,
      shareInPortfolio: underlyingFund.marketValue / portfolioTotal.mv,
    }));
};

export const reduceFundsStabilityData = (data) => {
  return data.reduce((accumulator, current) => {
    const { classification, share } = current;
    const classificationKey = classification ? classification : 'unclassified';
    if (accumulator.hasOwnProperty(classificationKey)) {
      accumulator[classificationKey].share += share;
      accumulator[classificationKey].funds.push(current)
    }
    else {
      accumulator[classificationKey] = {
        share,
        funds: [current]
      }
    }
    return accumulator;
  },
    {}
  );
}
