import React, { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { accent, textLightest, textMain } from '../../constants/colors';
import { mobileCondition } from '../../constants/media';
import { SPACING_16, SPACING_24, SPACING_8 } from '../../constants/spacing';

import { Paragraph, ParagraphSmall } from '../texts';
import { GridElement, MainGrid } from '../layout';
import { SpriteIcon } from '../common/SpriteIcon';

import { SpriteIconFunctional } from '../common/SpriteIconFunctional';
import { easeInOut, longAnimationTime } from '../../constants/animation';

const enter = (props) => keyframes`
  0% { margin-top: -90px; }
  100% { margin-top: 0; }
`;

const Wrapper = styled.div`
  background: ${accent.sand2};
  margin-top: -90px;
  animation: ${enter} ${longAnimationTime} ${easeInOut} 0.5s;
  animation-fill-mode: forwards;

  &.warning {
    background: ${accent.ocean250};
  }

  &.error {
    background: ${accent.raspberry1};
  }

  &.success {
    background: ${accent.forest250};
  }
`;

const Container = styled(MainGrid)`
  padding: ${SPACING_24};
`;

const ContentWrapper = styled(GridElement)`
  display: flex;
  justify-content: space-between;

  & > div {
    width: calc(100% - 32px);
    max-width: 80%;

    @media ${mobileCondition} {
      max-width: 100%;
    }
  }

  @media ${mobileCondition} {
    align-items: flex-start;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
`;

const StyledParagraph = styled(Paragraph)`
  color: ${textMain};

  ${(props) =>
    props.type === 'error' &&
    css`
      color: ${textLightest};
    `}
  ${(props) =>
    props.type === 'success' &&
    css`
      color: ${accent.forest4};
    `}
`;

const StyledParagraphSmall = styled(ParagraphSmall)`
  color: ${textMain};

  ${(props) =>
    props.type === 'error' &&
    css`
      color: ${textLightest};
    `}
  ${(props) =>
    props.type === 'success' &&
    css`
      color: ${accent.forest4};
    `}
`;

const MessageIcon = (props) => {
  const { type } = props;

  const icon = (t) => {
    switch (t) {
      case 'warning':
        return (
          <SpriteIcon id="Warning" size={SPACING_16} navigational={true} />
        );
      case 'error':
        return (
          <SpriteIcon
            id="Stop"
            size={SPACING_16}
            navigational={true}
            inverted={true}
          />
        );
      case 'success':
        return (
          <SpriteIcon
            id="Task"
            size={SPACING_16}
            color={accent.forest4}
            accentColor={accent.forest4}
            tertiaryColor={accent.forest4}
          />
        );
      default:
        return <SpriteIcon id="Info" size={SPACING_16} navigational={true} />;
    }
  };

  return icon(type);
};

export const Message = (props) => {
  const { message, dismissItem } = props;

  useEffect(() => {
    if (message.duration) {
      setTimeout(() => dismissItem(message.id), message.duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper className={message.type.toLowerCase()}>
      <Container>
        <ContentWrapper columns={12}>
          <div>
            <TitleWrapper>
              <MessageIcon
                type={message.type.toLowerCase()}
                style={{
                  verticalAlign: 'middle',
                }}
              />
              <StyledParagraph
                style={{ fontWeight: 'bold' }}
                type={message.type.toLowerCase()}
              >
                {message.title}
              </StyledParagraph>
            </TitleWrapper>
            <StyledParagraphSmall type={message.type.toLowerCase()}>
              {message.message}
            </StyledParagraphSmall>
          </div>
          <SpriteIconFunctional
            id="Close"
            size={16}
            style={{ cursor: 'pointer' }}
            onClick={() => dismissItem(message.id)}
            color={message.type.toLowerCase() === 'error' && textLightest}
          />
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
