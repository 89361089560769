import { createSelector } from 'reselect';
import { differenceWith, isEqual } from 'lodash';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [eventsAllIds, eventsById, allEventsSelector] =
  resourceSelectors('events');

export const futureEventsSelector = createSelector(
  allEventsSelector,
  (events) => {
    return events
      .filter((event) => {
        const now = new Date();
        const eventDate = new Date(event.startsAt);
        return eventDate.getTime() >= now.getTime();
      })
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  }
);

export const pastEventsSelector = createSelector(
  allEventsSelector,
  futureEventsSelector,
  (allEvents, futureEvents) => {
    return differenceWith(allEvents, futureEvents, isEqual);
  }
);

export const upcomingEventSelector = createSelector(
  futureEventsSelector,
  (events) => {
    return events[events.length - 1];
  }
);
