import React from 'react';
import styled from 'styled-components';

import { Input } from './Input';

import searchIcon from '../../assets/icons/form-elements/search-icon.svg';
import { Label } from './Label';
import { SPACING_8 } from '../../constants/spacing';

const StyledInput = styled(Input)`
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: left 16px center;
  padding-left: 39px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

export const SearchInput = (props) => {
  const {
    placeholder,
    style,
    value,
    onChange,
    name,
    inputstyle = 'filled',
    popoutLabel,
    ...rest
  } = props;

  return (
    <InputWrapper>
      {popoutLabel && placeholder && <Label>{placeholder}</Label>}
      <StyledInput
        {...rest}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
        inputstyle={inputstyle}
      />
    </InputWrapper>
  );
};
