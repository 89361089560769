import React, { useEffect, forwardRef } from 'react';

import { bodyFontStack } from '../../fonts';
import { useSvgWidth } from '../../services/hooks';

const splitStringByCharacterCount = (inputString, maxCharacterCount) => {
  const words = inputString.split(' ');
  const result = [];
  let currentChunk = '';

  words.forEach((word) => {
    if (
      currentChunk.length + word.length + (currentChunk.length > 0 ? 1 : 0) <=
      maxCharacterCount
    ) {
      if (currentChunk.length > 0) {
        currentChunk += ' ';
      }
      currentChunk += word;
    } else {
      if (currentChunk.length > 0) {
        result.push(currentChunk);
      }
      currentChunk = word;
    }
  });

  if (currentChunk.length > 0) {
    result.push(currentChunk);
  }

  return result;
};

const LabelText = forwardRef((props, ref) => {
  const { label, style, lineLength, ...rest } = props;

  return lineLength ? (
    <text x="3" y="21" fontSize="20" style={style} ref={ref} {...rest}>
      {splitStringByCharacterCount(label, lineLength).map((part, index) => (
        <tspan x="3" dy={index > 0 ? '1.5em' : '0'} key={`label-text-${index}`}>
          {part.toUpperCase()}
        </tspan>
      ))}
    </text>
  ) : (
    <text x="3" y="21" fontSize="20" style={style}>
      {label.toUpperCase()}
    </text>
  );
});

export const SpaghettiChartLabel = (props) => {
  const {
    y,
    cy,
    width,
    label,
    labelFormat = (string) => string,
    callbackY = () => {},
    color,
    solidColor,
    lineLength = 24,
  } = props;

  const labelString = labelFormat(label);
  const [ref, textWidth] = useSvgWidth();

  useEffect(() => {
    // The "y" position is calculated in this component.
    // We need to grab this value from it's parents component, hence the need
    // for a callback function
    callbackY(y);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  if (!label) return null;

  const translateX = solidColor ? label.length / 20 / 0.1 : 0;
  const rectPadding = 4;
  const rectWidth = textWidth + rectPadding;
  const numberOfLabelLines = Math.floor(labelString.length / lineLength) + 1;
  const rectHeight = numberOfLabelLines * 5;

  return (
    <g transform={`translate(410,${cy})`} width={width}>
      {!solidColor && <circle r={1.25} fill={color} />}
      <g transform={`translate(${-translateX}, -20)`}>
        {solidColor && (
          <rect
            height={rectHeight}
            width={rectWidth}
            fill={solidColor}
            x="1.5"
            y="17.5"
            rx="0.75"
          />
        )}
        <LabelText
          label={labelString}
          lineLength={lineLength}
          style={{
            fill: solidColor ? '#fff' : color,
            fontFamily: bodyFontStack,
            fontWeight: '400',
            fontSize: '3px',
          }}
          ref={ref}
        />
      </g>
    </g>
  );
};
