import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  t,
  getAssetClassName,
  getAssetClassGroupByAssetClass,
} from '@formue-app/core';

import { H2, H2Small, ParagraphSmall, ParagraphXSmall } from '../texts';
import { ButtonSmall } from '../buttons';
import { FixedPyramidChart } from '../wealthPlan/InvestmentSimulation/FixedPyramidChart';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_32,
  SPACING_40,
  SPACING_8,
} from '../../constants/spacing';
import { accent, backgroundWhite } from '../../constants/colors';
import { InvestmentStrategiesListItem } from './InvestmentStrategiesListItem';

const Container = styled.div`
  background-color: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  padding: ${SPACING_32};
  display: flex;
  flex-direction: column;
  gap: ${SPACING_32};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: ${SPACING_40};
`;

const ChartWrapper = styled.div`
  padding: ${SPACING_16};
`;

const ChildStrategies = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const Title = styled(H2)`
  padding-bottom: ${SPACING_8};
`;

const StyledInvestmentStrategiesListItem = styled(InvestmentStrategiesListItem)`
  background-color: ${accent.sand150};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

export const InvestmentStrategiesListItemParent = (props) => {
  const {
    id,
    title,
    accountName,
    assetClasses,
    childStrategies,
    assetAllocations,
  } = props;

  const assetClassGroups = assetClasses.reduce((acc, current) => {
    const assetClassGroup = getAssetClassGroupByAssetClass(current.assetClass);
    return {
      ...acc,
      [assetClassGroup]: {
        title: getAssetClassName(assetClassGroup),
        strategyShare: acc.hasOwnProperty(assetClassGroup)
          ? acc[assetClassGroup].strategyShare + current.strategyShare
          : current.strategyShare,
      },
    };
  }, {});

  const assetClassGroupKeys = ['AV', 'MV', 'S'];
  return (
    <Container>
      <Row>
        <Wrapper>
          <Title>{title}</Title>
          <ParagraphSmall style={{ paddingBottom: SPACING_32 }}>
            {accountName}
          </ParagraphSmall>
          <Link to={`/investment-strategy/${id}`}>
            <ButtonSmall buttonstyle="outline" size="small">
              {t('presentation:common:seeDetails')}
            </ButtonSmall>
          </Link>
        </Wrapper>
        <ChartWrapper>
          <FixedPyramidChart
            height={170}
            data={assetClassGroupKeys.map((key) => {
              const item = assetClassGroups[key];
              return {
                label: getAssetClassName(key),
                value: item.strategyShare,
              };
            })}
            colorScheme="light"
            LabelComponent={ParagraphXSmall}
            ValueComponent={H2Small}
            dashSpacing={10}
            dashWidth={50}
          />
        </ChartWrapper>
      </Row>
      {childStrategies && (
        <ChildStrategies>
          {childStrategies.map((strategy) => {
            const assetClasses = assetAllocations.filter(
              (assetAllocation) =>
                assetAllocation.investmentStrategy.id === strategy.id
            );
            return (
              <StyledInvestmentStrategiesListItem
                key={`investment-strategy-${strategy.id}`}
                assetClasses={assetClasses}
                showPyramid
                childStrategy
                {...strategy}
              />
            );
          })}
        </ChildStrategies>
      )}
    </Container>
  );
};
