import React from 'react';
import { useSelector } from 'react-redux';
import striptags from 'striptags';
import he from 'he';

import { BaseFeedItem } from './BaseFeedItem';

const imageFallback = require('../../assets/images/default/formue.png');

export const ArticleFeedItem = ({ id }) => {
  const articlesById = useSelector((state) => state.entities.articles.byId);
  const data = articlesById[id];

  // There is a possibility that /feed api returned a non-existing id
  // so we need to make sure we have the data in the /articles api
  if (!data) return null;

  const ingressNoHtml = striptags(data.excerpt);
  const decodedHtml = he.decode(ingressNoHtml);

  return (
    <BaseFeedItem
      columns={4}
      key={`feed-${id}`}
      {...data}
      image={data.thumbnail}
      description={decodedHtml}
      date={data.publishedAt}
      imageFallback={imageFallback}
    />
  );
};
