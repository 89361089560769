import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectors, useResource } from '@formue-app/core';
import { StyledSubRow, TableList } from '../../lists/TableList';
import { useHoldingsListColumns } from './holdingsListColumns';
import { PrintPageBreaker } from '../../common/PrintPageBreaker';
import { ConditionalWrapper } from '../../common/ConditionalWrapper';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { GridElement } from '../../layout';

const {
  entities: {
    funds: { allFundOfFundsSelector },
  },
} = selectors;

const SubRow = styled(StyledSubRow)`
  ${(props) =>
    props.isLink &&
    `
    cursor: pointer;
    &:hover {
      background-color: #F3EDF7 !important;

      .flip-arrow {
        margin-left: 16px;
      }
    }`}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: contents;
  cursor: pointer;

  > * {
    cursor: pointer;
  }
`;

export const HoldingsList = (props) => {
  const { items, openItems = [], clickCallback = () => {}, ...rest } = props;
  const loading = useResource(['FUNDS/INDEX']);

  const allFunds = useSelector(allFundOfFundsSelector);
  const columns = useHoldingsListColumns(items);

  if (loading || !Boolean(items.length)) {
    return (
      <GridElement columns={12}>
        <CenteredActivityIndicator />
      </GridElement>
    );
  }

  return (
    <>
      <PrintPageBreaker />
      <TableList
        id="holdingsList"
        sortable={false}
        columns={columns}
        rows={items}
        openItems={openItems}
        clickCallback={clickCallback}
        keyExtractor={(item, index) => `asset-class-${index}`}
        subRowComponent={({ item, ...rest }) => {
          const fund = allFunds.filter((fund) => fund.name === item.p);

          return (
            <ConditionalWrapper
              condition={Boolean(fund.length)}
              wrapper={(children) => (
                <StyledLink
                  to={`/portfolio/holdings/securities?id=${fund[0].id}`}
                >
                  {children}
                </StyledLink>
              )}
            >
              <SubRow {...rest} isLink={Boolean(fund.length)} />
            </ConditionalWrapper>
          );
        }}
        stickyHeader={true}
        {...rest}
      />
    </>
  );
};
