import { t, localizeFloat } from '../localization';

export const generateSensitivityMessage = (quantile, type) => {
  const texts = [];

  if (type === 'lossAversion') {
    if (quantile >= 5) {
      texts.push(
        t(
          `wealthPlan:investmentProfile:yourReactionDescription:${type}:quintileHigh:point1`
        )
      );
      texts.push(
        t(
          `wealthPlan:investmentProfile:yourReactionDescription:${type}:quintileHigh:point2`
        )
      );
      texts.push(
        t(
          `wealthPlan:investmentProfile:yourReactionDescription:${type}:quintileHigh:point3`
        )
      );
    } else if (quantile < 5) {
      texts.push(
        t(
          `wealthPlan:investmentProfile:yourReactionDescription:${type}:quintileLow:point1`
        )
      );
      texts.push(
        t(
          `wealthPlan:investmentProfile:yourReactionDescription:${type}:quintileLow:point2`
        )
      );
      texts.push(
        t(
          `wealthPlan:investmentProfile:yourReactionDescription:${type}:quintileLow:point3`
        )
      );
    }
  } else if (type === 'winningAppetite') {
    if (quantile >= 5) {
      texts.push(
        t(
          `wealthPlan:investmentProfile:yourReactionDescription:${type}:quintileHigh:point1`
        )
      );
    } else if (quantile < 5) {
      texts.push(
        t(
          `wealthPlan:investmentProfile:yourReactionDescription:${type}:quintileLow:point1`
        )
      );
    }
  }

  return texts;
};

export const generateRiskText = (lossAversion, winningAppetite) => {
  const constant =
    lossAversion.value < 1 ? localizeFloat(1 / 3.07) : localizeFloat(3.07);
  const value =
    lossAversion.value < 1
      ? localizeFloat(1 / lossAversion.value)
      : localizeFloat(lossAversion.value);
  let degreeAnnotationLoss = 'medium';
  let degreeAnnotationGain = 'medium';
  let feelingAnnotation = 'plesure';

  if (lossAversion.quantile < 2.5) {
    degreeAnnotationLoss = 'low';
  } else if (lossAversion.quantile > 7.5) {
    degreeAnnotationLoss = 'high';
  }

  if (winningAppetite.value < 0.51) {
    degreeAnnotationGain = 'low';
  } else if (winningAppetite.value > 0.76) {
    degreeAnnotationGain = 'high';
  }

  if (lossAversion.value >= 1) {
    feelingAnnotation = 'pain';
  }

  return t('wealthPlan:investmentProfile:sensitivityText:mainText', {
    value: value,
    constant: constant,
    degreeAnnotation1: t(
      `wealthPlan:investmentProfile:sensitivityText:annotations:${degreeAnnotationLoss}:annotation1`
    ),
    degreeAnnotation2: t(
      `wealthPlan:investmentProfile:sensitivityText:annotations:${degreeAnnotationLoss}:annotation2`
    ),
    degreeAnnotation3: t(
      `wealthPlan:investmentProfile:sensitivityText:annotations:${degreeAnnotationGain}:annotation3`
    ),
    feelingAnnotation: t(
      `wealthPlan:investmentProfile:sensitivityText:annotations:${feelingAnnotation}`
    ),
  });
};

export const riskAndLockinValueToText = (value) => {
  switch (value) {
    case 0:
      return t('wealthPlan:investmentProfile:riskProfiles:riskAndLockin:low');
    case 1:
      return t(
        'wealthPlan:investmentProfile:riskProfiles:riskAndLockin:medium'
      );
    case 2:
      return t('wealthPlan:investmentProfile:riskProfiles:riskAndLockin:high');
    case null:
      return t('wealthPlan:investmentProfile:riskProfiles:riskAndLockin:none');

    // Remove this when we make sure we did the change from "3" to "null" on other places
    case 3:
      return t('wealthPlan:investmentProfile:riskProfiles:riskAndLockin:none');
  }
};
