import React from 'react';
import styled from 'styled-components';
import { shortAnimationTime } from '../../constants/animation';

import { backgroundMain } from '../../constants/colors';
import { SpriteIconFunctional } from './SpriteIconFunctional';

const ArrowContainer = styled.span`
  display: flex;
  background: ${(props) => props.backgroundColor};
  margin: 0;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const StyledArrowWrapper = styled.div`
  transform: rotate(${(props) => props.rotation}deg);
  transition: all ${shortAnimationTime};
  transform-origin: center center;

  svg {
    vertical-align: super;
  }
`;

export const FlipArrow = (props) => {
  const {
    active,
    direction,
    size = 16,
    color,
    backgroundColor = backgroundMain,
  } = props;

  const localDirection = direction ? direction : active ? 'down' : 'right';
  let rotation = 0;

  switch (localDirection) {
    case 'up':
      rotation = -180;
      break;
    case 'left':
      rotation = 90;
      break;
    case 'right':
      rotation = 270;
      break;
    default:
      rotation = 0;
      break;
  }

  return (
    <ArrowContainer backgroundColor={backgroundColor} {...props}>
      <StyledArrowWrapper
        rotation={rotation}
        style={{ width: size, height: size }}
      >
        <SpriteIconFunctional
          size={size}
          id="Simple arrow"
          direction="Down"
          color={color}
        />
      </StyledArrowWrapper>
    </ArrowContainer>
  );
};
