import React from 'react';
import styled, { keyframes } from 'styled-components';
import { backgroundWhite } from '../../constants/colors';

const Wrapper = styled.div`
  width: 20px;
  height: 24px;
  position: relative;
`;
const Bar = styled.div`
  float: left;
  width: 4px;
  height: 10px;
  background: ${backgroundWhite};
  position: absolute;
  bottom: 0;
`;

const firstBar = keyframes`
  0%   { height: 8px; }
  50%  { height: 20px; }
  100% { height: 8px; }
`;

const secondBar = keyframes`
  0%   { height: 20px; }
  50%  { height: 8px; }
  100% { height: 20px; }
`;

const thirdBar = keyframes`
  0%   { height: 20px; }
  40%  { height: 8px; }
  60%  { height: 20px; }
  100% { height: 15px; }
`;

const FirstBar = styled(Bar)`
  left: 0px;
  animation-name: ${firstBar};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const SecondBar = styled(Bar)`
  left: 8px;
  animation-name: ${secondBar};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const ThirdBar = styled(Bar)`
  left: 16px;
  animation-name: ${thirdBar};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

export const EqualizerIcon = () => {
  return (
    <Wrapper>
      <FirstBar />
      <SecondBar />
      <ThirdBar />
    </Wrapper>
  );
};
