import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';

import { easeInOut, longAnimationTime } from '../../../constants/animation';
import { SPACING_24 } from '../../../constants/spacing';
import { desktopCondition } from '../../../constants/media';
import { accent } from '../../../constants/colors';

import { H1Small } from '../../texts';
import { titleFontStack } from '../../../fonts';

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  margin-top: ${SPACING_24};
`;

const StyledTitle = styled(H1Small)`
  text-align: center;
  position: relative;
  top: 20px;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime};
  font-size: 26px;

  ${(props) =>
    props.isInView &&
    css`
      top: 0;
      opacity: 1;
    `}

  @media ${desktopCondition} {
    white-space: pre-line;
    font-size: 40px;
  }
`;

export const ReportQuestion = (props) => {
  const { questionText, titleColor, highlightColor, values, ...rest } = props;

  const [isInViewRef, isInView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  return (
    <QuestionsWrapper isInView={isInView} ref={isInViewRef} {...rest}>
      <StyledTitle isInView={isInView} style={{ color: accent[titleColor] }}>
        <Trans
          i18nKey={questionText}
          values={values}
          components={{
            strong: (
              <span
                style={{
                  color: accent[highlightColor],
                  fontWeight: 700,
                  fontFamily: titleFontStack,
                }}
              />
            ),
          }}
        />
      </StyledTitle>
    </QuestionsWrapper>
  );
};
