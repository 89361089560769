import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { Paragraph } from '../texts';
import { CollapsibleListItem } from './CollapsibleListItem';
import { SpriteIcon } from './SpriteIcon';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_12,
  SPACING_24,
  SPACING_48,
  SPACING_8,
} from '../../constants/spacing';
import { blackTranslucent, textLightest } from '../../constants/colors';
import { SpriteIconFunctional } from './SpriteIconFunctional';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  width: 700px;
  background-color: ${blackTranslucent(0.13)};

  p {
    color: ${textLightest};
  }

  & + .details > div {
    padding-top: 0;

    pre {
      text-align: left;
      white-space: pre-wrap;
      margin: 0;
    }
  }
`;

const Content = styled.div`
  padding-top: ${SPACING_48};
`;

const StyledParagraph = styled(Paragraph)`
  font-weight: 600;
  display: inline-block;
  border-radius: 40px;
  margin-bottom: ${SPACING_48};
  background-color: ${blackTranslucent(0.13)};
  color: ${textLightest};
  padding: ${SPACING_8} ${SPACING_12};
`;

export const ErrorFallbackPresentation = ({ error, resetErrorBoundary }) => {
  return (
    <Wrapper role="alert">
      <Content>
        <StyledParagraph>{t('globals:error:title')}</StyledParagraph>

        <StyledCollapsibleListItem
          arrowComponent={
            <SpriteIconFunctional
              id="Simple arrow"
              direction="Down"
              color={textLightest}
            />
          }
          details={<pre style={{ color: 'red' }}>{error?.message}</pre>}
          detailsStyle={{
            paddingInline: SPACING_48,
            marginTop: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            paddingTop: 0,
            backgroundColor: blackTranslucent(0.13),
          }}
          expandedStyle={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          startExpanded={false}
          style={{ paddingBlock: SPACING_24, paddingInline: SPACING_48 }}
        >
          <SpriteIcon id="Info" inverted={true} navigational={true} />
          <Paragraph
            style={{ margin: 0, marginRight: 'auto', fontWeight: 600 }}
          >
            {t('globals:error:accordionTitle')}
          </Paragraph>
        </StyledCollapsibleListItem>
      </Content>
    </Wrapper>
  );
};
