import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import {
  formatBigMoney,
  localizeFloat,
  toLocaleDateString,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { mobileCondition, tabletCondition } from '../../../constants/media';
import { SPACING_32 } from '../../../constants/spacing';
import { TableList, StyledCell } from '../../lists/TableList';
import { H4, Paragraph } from '../../texts';

const LightStyledCell = styled(StyledCell)`
  span {
    font-weight: 400;
  }
`;

const StyledTableList = styled(TableList)`
  margin-top: ${SPACING_32};
`;

const TitleWrapper = styled.div`
  width: 60%;
`;

const AmountWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MetadataWrapper = styled.div`
  border-top: 1px solid ${accent.sand3};
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
`;

export const TransactionList = (props) => {
  const { t } = useTranslation();
  const { items: transactions } = props;

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const tabletSize = useMediaQuery({
    query: tabletCondition,
  });

  if (!transactions.length) {
    return null;
  }

  // Define the table columns
  let columns = [
    {
      key: 'date',
      label: t('globals:date'),
      render: ({ date }) => toLocaleDateString(date),
      width: '15%',
      defaultSort: true,
    },
    {
      key: 'product',
      label: t('portfolio:product'),
      component: LightStyledCell,
      width: '35%',
    },
    {
      key: 'type',
      label: t('portfolio:transaction'),
      component: LightStyledCell,
      width: '15%',
    },
    {
      key: 'quantity',
      label: t('portfolio:shares'),
      render: ({ quantity: q }) => (q ? localizeFloat(q, 4) : ''),
      type: 'number',
      component: LightStyledCell,
      width: '10%',
    },
    {
      key: 'price',
      label: t('portfolio:price'),
      render: ({ price: p }) => (p ? localizeFloat(p, 2) : ''),
      type: 'number',
      component: LightStyledCell,
      width: '10%',
    },
    {
      key: 'amount',
      label: t('globals:amount'),
      render: ({ amount: a }) => (a ? formatBigMoney(a, false) : ''),
      type: 'number',
      width: '15%',
    },
  ];

  const quantity = 10;
  const price = 0;

  if (tabletSize || mobileSize) {
    columns = [
      {
        key: 'product',
        render: ({ product, type, date, amount }) => (
          <ContentWrapper>
            <HeaderWrapper>
              <TitleWrapper>
                <H4>{product}</H4>
                <Paragraph>
                  {type} - {toLocaleDateString(date)}
                </Paragraph>
              </TitleWrapper>
              <AmountWrapper>
                <H4>{amount ? formatBigMoney(amount, false) : ''}</H4>
              </AmountWrapper>
            </HeaderWrapper>
            {quantity && price ? (
              <MetadataWrapper>
                <Paragraph style={{ marginRight: '10px' }}>
                  {t('portfolio:shares')}
                  {': '}
                  {quantity ? localizeFloat(quantity) : ''}
                </Paragraph>

                <Paragraph>
                  {t('portfolio:price')}: {price ? localizeFloat(price) : ''}
                </Paragraph>
              </MetadataWrapper>
            ) : (
              ''
            )}
          </ContentWrapper>
        ),
        label: '',
        width: '60%',
      },
    ];
  }

  return (
    <StyledTableList
      className="table-list"
      columns={columns}
      rows={transactions}
      sortable={mobileSize ? false : true}
      keyExtractor={(item, index) => `transaction-${index}`}
      infiniteScroll={true}
    />
  );
};
