import React from 'react';
import styled from 'styled-components';

import { SPACING_24 } from '../../constants/spacing';
import { H2 } from '../../components/texts';

const Container = styled.div``;

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING_24};
`;

const Content = styled.div`
  position: relative;
`;

export const ExtendedReportingBasePage = ({ title, children, ...rest }) => {
  return (
    <Container {...rest}>
      <SubHeader>
        <H2>{title}</H2>
      </SubHeader>
      <Content>{children}</Content>
    </Container>
  );
};
