import { useSelector } from 'react-redux';

/**
 * Custom react hook to retrieve an image from API that requires user
 * to be authenticated.
 * @param {} url
 */
export const useImageUrlWithAuthToken = (url) => {
  const accessToken = useSelector((state) => state.auth.accessToken);

  if (!url) {
    return null;
  }
  const urlWithAccessToken = `${url}?secret_token=${accessToken}`;

  // Return url with appended access token
  return urlWithAccessToken;
};
