import { apiFetch } from './utils/api';
// const mockedFeed = require('./mocks/v2/feed.json');

export const feed = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v2/feed';
    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
