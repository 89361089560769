import React from 'react';
import styled, { css } from 'styled-components';

import { accent, textLightest } from '../../../../constants/colors';
import { SPACING_16, SPACING_48 } from '../../../../constants/spacing';
import { H3 } from '../../../texts';

import { easeInOut, shortAnimationTime } from '../../../../constants/animation';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 700px;
  height: 40px;
  margin: 0 auto;
  margin-top: ${SPACING_48};
  overflow: hidden;
  position: relative;
`;

const YearsList = styled.div`
  display: flex;
  flex-direction: row;
  right: 50%;
  position: absolute;

  ${(props) => css`
    translate: calc(56px + ${71 * props.offset}px);
  `}

  transition: translate ${easeInOut} ${shortAnimationTime};
`;

const Year = styled(H3)`
  cursor: pointer;
  padding: 0 14px;
  color: ${textLightest};
  opacity: 0.3;

  ${(props) =>
    props.isActiveYear &&
    `
    padding: 0 34px;
    opacity: 1;
    `}

  transition: all ${easeInOut} ${shortAnimationTime};
`;

const ArrowWrapper = styled.div`
  padding: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  z-index: 1;
`;

export const FinancialValuesFilter = (props) => {
  const {
    activeYear,
    setActiveYear,
    years,
    backgroundColor = accent.ocean4,
  } = props;

  const currYear = years[years.length - 1];

  const offset = currYear - activeYear || 0;

  return (
    <FilterWrapper backgroundColor={backgroundColor}>
      <ArrowWrapper
        onClick={() =>
          activeYear > years[0] ? setActiveYear(activeYear - 1) : null
        }
        style={{
          cursor: activeYear === years[0] ? 'default' : 'pointer',
          opacity: activeYear === years[0] ? 0.5 : 1,
          left: 'calc(50% - 34px)',
          position: 'absolute',
          translate: '-100%',
        }}
      >
        <SpriteIconFunctional
          id="Simple arrow"
          direction="Left"
          color={textLightest}
          size={SPACING_16}
        />
      </ArrowWrapper>
      <YearsList offset={offset}>
        {years.map((year, index) => (
          <Year
            isActiveYear={year === activeYear}
            onClick={() => setActiveYear(year)}
            key={year}
            style={{
              opacity:
                1 * 1 - Math.abs((years.indexOf(activeYear) - index) / 4),
            }}
          >
            {year}
          </Year>
        ))}
      </YearsList>
      <ArrowWrapper
        onClick={() =>
          activeYear < years[years.length - 1]
            ? setActiveYear(activeYear + 1)
            : null
        }
        style={{
          cursor:
            activeYear === years[years.length - 1] ? 'default' : 'pointer',
          opacity: activeYear === years[years.length - 1] ? 0.5 : 1,
          right: 'calc(50% - 34px)',
          position: 'absolute',
          translate: '100%',
        }}
      >
        <SpriteIconFunctional
          id="Simple arrow"
          direction="Right"
          color={textLightest}
          size={SPACING_16}
        />
      </ArrowWrapper>
    </FilterWrapper>
  );
};
