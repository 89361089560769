import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  operationalMessagesAllIds,
  operationalMessagesById,
  allOperationalMessagesSelector,
] = resourceSelectors('operationalMessages');

const readItems = (state) => state.ui.readStatus.readItems;

export const allUnreadOperationalMessagesSelector = createSelector(
  allOperationalMessagesSelector,
  readItems,
  (allItems, allReadItems) => {
    return allItems.filter((item) => {
      return (
        !allReadItems
          .filter((readItem) => readItem.type === 'operationalMessages')
          .find((ritem) => ritem.id === item.id) && item
      );
    });
  }
);
