import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [fundsAllIds, fundsById, allFundsSelector] =
  resourceSelectors('funds');

export const allFundOfFundsSelector = createSelector(
  allFundsSelector,
  (allFunds) => {
    return allFunds.filter((item) => item.type === 'fund-of-funds');
  }
);

export const createFundOfFundsByAssetClassSelector = (assetClass) => {
  return createSelector(allFundOfFundsSelector, (funds) => {
    return funds.filter((item) => item.assetClass === assetClass);
  });
};
