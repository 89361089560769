import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  t,
  selectors,
  combineFamilyGroups,
  sortByFamilyhierarchy,
} from '@formue-app/core';

import {
  SPACING_16,
  SPACING_8,
  BORDER_RADIUS_LARGE,
  SPACING_24,
} from '../../../constants/spacing';
import { backgroundWhite } from '../../../constants/colors';
import { FamilyItem } from './FamilyItem';
import { GridElement } from '../../layout';
import { H2, H3 } from '../../texts';
import { ExpandableListItem } from '../../common/ExpandableListItem';
import { mobileCondition, tabletCondition } from '../../../constants/media';

const StyledGridElement = styled(GridElement)`
  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const FamilyMembersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 35px ${SPACING_16};
`;

const FamilyGroupWrapper = styled.div`
  background-color: ${backgroundWhite};
  padding: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_LARGE};
  margin-bottom: 2px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledTitle = styled(H2)`
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_16};

  @media ${mobileCondition} {
    margin-top: ${SPACING_24};
  }
`;

const {
  entities: {
    familyAndOwnership: { familySelector, familyMainMembersSelector },
  },
} = selectors;

export const FamilyTree = (props) => {
  const familyMembers = useSelector(familyMainMembersSelector);

  return Object.keys(combineFamilyGroups(familyMembers))
    .sort(sortByFamilyhierarchy)
    .map((key, familyMembersIndex) => (
      <FamilyGroupWrapper
        key={`familymembers-${familyMembersIndex}`}
        {...props}
      >
        <H3 style={{ marginBottom: SPACING_8 }}>
          {t(`wealthPlan:familyAndOwnership:family:memberCategories:${key}`)}
        </H3>
        <FamilyMembersWrapper>
          {familyMembers[key].map((item, familyMemberIndex) => (
            <FamilyItem key={`family-${familyMemberIndex}`} {...item} />
          ))}
        </FamilyMembersWrapper>
      </FamilyGroupWrapper>
    ));
};

export const Family = (props) => {
  const { columns = 6 } = props;
  const familyContent = useSelector(familySelector);

  if (!familyContent.length) {
    return null;
  }

  return (
    <StyledGridElement columns={columns}>
      <StyledTitle>
        {t('wealthPlan:familyAndOwnership:family:title')}
      </StyledTitle>

      <ExpandableListItem height={394}>
        <FamilyTree />
      </ExpandableListItem>
    </StyledGridElement>
  );
};
