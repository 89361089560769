import React from 'react';
import styled, { css } from 'styled-components';

import { backgroundWhite } from '../../../constants/colors';
import { Paragraph } from '../../texts';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';
import {
  easeInOut,
  fadeInFromSide,
  mediumAnimationTime,
} from '../../../constants/animation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${backgroundWhite};
  padding: ${SPACING_24};
  width: calc(100% / 3 - ${SPACING_16});
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  gap: ${(props) => props.titleSpacing};

  opacity: 0;
  transform: translateX(60px);

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeInFromSide} ${mediumAnimationTime} ${easeInOut} forwards;
      animation-delay: ${(props) => props.delay}s;
    `}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${SPACING_16};
`;

const Title = styled(Paragraph)`
  font-weight: 500;
  margin-left: ${SPACING_8};
`;

export const PortfolioCharacteristicsItem = (props) => {
  const {
    title,
    children,
    animate,
    index,
    icon,
    titleSpacing = SPACING_24,
    ...rest
  } = props;
  const Icon = icon;

  return (
    <Container
      animate={animate}
      delay={0.5 + index * 0.3}
      titleSpacing={titleSpacing}
      {...rest}
    >
      <TitleWrapper>
        {Icon}
        <Title>{title}</Title>
      </TitleWrapper>

      <Content>{children}</Content>
    </Container>
  );
};
