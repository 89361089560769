import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { t } from '@formue-app/core';
import { backgroundWhite, accent } from '../../../../constants/colors';
import {
  mobileCondition,
  notDesktopCondition,
  tabletCondition,
} from '../../../../constants/media';
import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { GridElement, MainGrid } from '../../../layout';
import { H1 } from '../../../texts';
import { BaseSection } from '../../../common/BaseSection';
import { SvgMap } from './SvgMap';
import { ContinentSwitcher } from './ContinentSwitcher';
import { ContinentText } from './ContinentText';

const StyledBackground = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: 0;
  background-color: ${accent.ocean4}
    ${(props) =>
      props.fullSize
        ? `@media ${mobileCondition} {
    border-radius: 0;
    padding-top: 145px;
    margin: 0 -${SPACING_16};
    margin-top: -145px;
  }`
        : null};

  @media ${notDesktopCondition} {
    flex-direction: column-reverse;
  }
`;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledGridElement = styled(GridElement)`
  padding: 80px ${SPACING_48};

  @media ${mobileCondition} {
    padding: ${SPACING_48};
  }
`;

const StyledGridElementLeft = styled(StyledGridElement)`
  padding-top: 130px;
  @media ${notDesktopCondition} {
    padding: ${SPACING_16};
    padding-top: 0;
    max-width: 380px;
  }
`;

const StyledGridElementRight = styled(StyledGridElement)`
  padding: 30px 0px;
  @media ${notDesktopCondition} {
    padding: 0;
    grid-row-start: 1;
  }
  @media ${mobileCondition} {
    padding: ${SPACING_8};
  }
`;

const StyledTitle = styled(H1)`
  color: ${backgroundWhite};
  margin-bottom: 16px;
  @media ${notDesktopCondition} {
    display: none;
  }
`;

export const GeographyHeader = (props) => {
  const {
    fullSize = false,
    children,
    continents,
    topThreeCountries,
    zoom,
    selectedContinent,
    onClick,
    ...rest
  } = props;

  const highlight =
    selectedContinent === 'Global'
      ? continents.map((item) => item.continent)
      : selectedContinent;

  const isTablet = useMediaQuery({
    query: tabletCondition,
  });

  return (
    <StyledBackground fullSize={fullSize} columns={12} {...rest}>
      <StyledSubGrid>
        <StyledGridElementLeft columns={isTablet ? 12 : 5}>
          <StyledTitle>{t('portfolio:geography')}</StyledTitle>
          <ContinentText selectedContinent={selectedContinent} />
        </StyledGridElementLeft>
        <StyledGridElementRight columns={isTablet ? 12 : 7}>
          <SvgMap
            fill={accent.ocean1}
            style={{ width: '100%' }}
            highlight={highlight}
            topThreeCountries={topThreeCountries}
            zoom={zoom}
            continents={continents}
            onClick={onClick}
          />
        </StyledGridElementRight>
      </StyledSubGrid>
      <ContinentSwitcher onClick={onClick} selected={selectedContinent} />
    </StyledBackground>
  );
};
