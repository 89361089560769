import { takeEvery, call, put } from 'redux-saga/effects';

import { AsyncStorage } from '@formue-app/core';

import { actions as appActions } from '../../app/actions';
import { actions } from './actions';

const STORAGE_KEY = 'dismissedPasskeysOnboarding';

function* hydrateDismissedPasskeysOnboarding() {
  yield takeEvery(appActions.init, function* () {
    const dismissedPasskeysOnboarding = yield call(
      AsyncStorage.getItem,
      STORAGE_KEY
    );

    if (dismissedPasskeysOnboarding) {
      yield put(
        actions.setDismissedPasskeysOnboarding(dismissedPasskeysOnboarding)
      );
    }
  });
}

function* dismissPasskeysOnboarding() {
  yield takeEvery(actions.dismissPasskeysOnboarding, function* ({ payload }) {
    yield call(AsyncStorage.setItem, STORAGE_KEY, payload);
    yield put(actions.setDismissedPasskeysOnboarding(payload));
  });
}

export const sagas = [
  dismissPasskeysOnboarding,
  hydrateDismissedPasskeysOnboarding,
];
