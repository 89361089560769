import { stringify } from 'query-string';
import { apiFetch } from './utils/api';

/**
 * @desc Portfolio data fetch
 *
 * @param string $type - adds type of resource to the URL string.
 * Left empty will return data aggregated on client level, with last registered values for market
 * values, gains and TWR.
 * Other options are AssetCategory, AssetClass, Product
 *
 * @param {(number|number[])} id - ID of the user we're trying to fetch the data for. Array of id's
 * supported.
 *
 * @param {string} [period=TWELVEMONTHS] -  THISMONTH, THISYEAR, TWELVEMONTHS, THREEYEARS,
 * FIVEYEARS, TENYEARS or SINCESTART
 *
 * @return json - data or error message
 */

export const portfolioData = {
  get: async (args = []) => {
    const [
      ssids,
      period,
      accounts,
      taxClasses,
      hideInternalPortfolio,
      hideExternalPortfolio,
    ] = args;

    const query = stringify(
      {
        ssids,
        period,
        account: accounts,
        taxclass: taxClasses,
        hideInternalPortfolio,
        hideExternalPortfolio,
      },
      { arrayFormat: 'comma' }
    );

    const options = {
      method: 'GET',
    };
    const url = `/v1/qlik/portfolio?${query}`;
    const response = await apiFetch(url, {
      ...options,
    });

    return response;
  },
};
