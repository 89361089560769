import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { t, useCountryOptions } from '@formue-app/core';

import { Fieldset, Select } from '../../formElements';

import { SPACING_16, SPACING_8 } from '../../../constants/spacing';
import { H4Size, H4LineHeight } from '../../../constants/text';
import { textLink } from '../../../constants/colors';

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${H4Size};
  line-height: ${H4LineHeight};
  font-weight: 500;
  color: ${textLink};
  margin-bottom: ${SPACING_8};
`;

export const CitizenshipFieldset = (props) => {
  const {
    primaryCitizenship,
    setPrimaryCitizenship,
    secondaryCitizenship,
    setSecondaryCitizenship,
  } = props;

  // List is the dataset for all tax id forms, while data is the
  // data for this one form we are representing
  const countryOptions = useCountryOptions();

  const [showSecondary, setShowSecondary] = useState(false);
  useEffect(() => {
    if (secondaryCitizenship) {
      setShowSecondary(true);
    }
  }, [secondaryCitizenship]);

  const addSecondaryCitizenship = (event) => {
    event.preventDefault();
    setShowSecondary(true);
  };

  const removeSecondaryCitizenship = (event) => {
    event.preventDefault();
    setSecondaryCitizenship(null);
    setShowSecondary(false);
  };

  return (
    <Fieldset legend={t('profile:selfDeclaration:citizenship:title')}>
      <Select
        placeholder={`${t('globals:select')} ${t(
          'profile:selfDeclaration:countryLabel'
        ).toLowerCase()}`}
        onChange={(event) => {
          setPrimaryCitizenship(event.target.value);
        }}
        id="primaryCitizenship"
        name="primaryCitizenship"
        value={primaryCitizenship ? primaryCitizenship.toLowerCase() : ''}
        options={countryOptions}
        inputstyle="outline"
        popoutLabel={true}
        label={t('profile:selfDeclaration:citizenship:primaryCitizenship')}
        style={{
          marginBottom: SPACING_16,
          width: '100%',
        }}
      />

      {showSecondary ? (
        <>
          <Select
            placeholder={`${t('globals:select')} ${t(
              'profile:selfDeclaration:countryLabel'
            ).toLowerCase()}`}
            onChange={(event) => {
              setSecondaryCitizenship(event.target.value);
            }}
            id="secondaryCitizenship"
            name="secondaryCitizenship"
            value={
              secondaryCitizenship ? secondaryCitizenship.toLowerCase() : ''
            }
            options={countryOptions}
            inputstyle="outline"
            popoutLabel={true}
            label={t(
              'profile:selfDeclaration:citizenship:secondaryCitizenship'
            )}
            style={{
              marginBottom: SPACING_8,
              width: '100%',
            }}
          />
          <br />
          <StyledLink onClick={removeSecondaryCitizenship} to="">
            {t(
              'profile:selfDeclaration:citizenship:removeSecondaryCitizenship'
            )}
          </StyledLink>
        </>
      ) : (
        <StyledLink onClick={addSecondaryCitizenship} to="">
          {t('profile:selfDeclaration:citizenship:addSecondaryCitizenship')}
        </StyledLink>
      )}
    </Fieldset>
  );
};
