import React from 'react';
import styled from 'styled-components';

import {
  H1XLSize,
  H1XLLineHeight,
  H1XLSizeMobile,
  H1XLLineHeightMobile,
} from '../../constants/text';
import { notDesktopCondition } from '../../constants/media';

export const StyledH1XL = styled.h1`
  font-size: ${H1XLSize};
  line-height: ${H1XLLineHeight};

  @media ${notDesktopCondition} {
    font-size: ${H1XLSizeMobile};
    line-height: ${H1XLLineHeightMobile};
  }
`;

export const H1XL = (props) => {
  const { children } = props;

  return <StyledH1XL {...props}>{children}</StyledH1XL>;
};
