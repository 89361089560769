import * as tasks from './tasks';
import * as esg from './esg';
import * as geography from './geography';
import * as sectors from './sectors';
import * as qlik from './qlik';
import * as app from './app';
import * as investingThemes from './investingThemes';
import * as sustainability from './sustainability';

export const constants = {
  tasks,
  esg,
  geography,
  sectors,
  qlik,
  app,
  investingThemes,
  sustainability,
};
