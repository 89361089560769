import { apiFetch } from './utils/api';

export const screenViews = {
  post: async (data) => {
    // Construct JSON-API serialized screen view data object
    const payload = {
      data: {
        type: 'screen-views',
        attributes: {
          'session-id': data.sessionId,
          os: data.os,
          'os-version': data.osVersion,
          'device-model': data.deviceModel,
          'device-name': data.deviceName,
          'device-year-class': data.deviceYearClass,
          'app-version': data.appVersion,
          'build-version': data.buildVersion,
          'installation-id': data.installationId,
          'screen-name': data.screenName,
          'related-id': data.relatedId,
          'related-description': data.relatedDescription,
        },
      },
    };

    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };

    const url = '/v1/analytics/screen-views';
    return apiFetch(url, options);
  },
};
