import { useSelector } from 'react-redux';
import { generateFullName } from '../services';
import { currentUserSelector } from '../store/ui/user/selectors';

export const useFullName = (prefix, maxMiddleNameLength = 9) => {
  const user = useSelector(currentUserSelector);
  let name = '';
  if (user) {
    name = [prefix, generateFullName(user, true, maxMiddleNameLength)].join(
      ' '
    );
  }

  return name;
};
