import React, { useState } from 'react';
import styled from 'styled-components';

import { t, formatBigMoney } from '@formue-app/core';

import { accent } from '../../../../constants/colors';
import {
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_40,
} from '../../../../constants/spacing';
import {
  easeInOut,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';
import { H3 } from '../../../texts';

import { ReactComponent as SafetyBar } from '../../../../assets/icons/annual-review/safety-bar.svg';
import { ReactComponent as PreserveAndGrowBar } from '../../../../assets/icons/annual-review/preserveAndGrow-bar.svg';
import { ReactComponent as CreateBar } from '../../../../assets/icons/annual-review/create-bar.svg';

import { DesktopWrapper, mobileCondition } from '../../../../constants/media';
import {
  H3LineHeight,
  H3Size,
  H5LineHeight,
  H5Size,
} from '../../../../constants/text';
import { CategoryList } from './CategoryList';
import { SpriteIcon } from '../../../common/SpriteIcon';

const openWidth = 315;
const closedWidth = 185;
const topOpenOffset = -212;

const mobileClosedWidth = 95;

const Category = styled.div`
  transition: all ${easeInOut} ${longAnimationTime};
  display: flex;
  flex-direction: column;
  cursor: ${(props) => (props.enableClick ? 'pointer' : 'default')};
  margin-right: ${SPACING_32};
  margin-top: ${(props) => (props.activeCategory ? topOpenOffset : 0)}px;
  width: ${(props) => (props.activeCategory ? openWidth : closedWidth)}px;
  padding-top: ${(props) => (props.activeCategory ? 10 : 70)}px;

  &:last-child {
    margin-right: 0;
  }

  @media ${mobileCondition} {
    margin-right: ${SPACING_16};
    width: ${mobileClosedWidth}px;
  }
`;

const BarWrapper = styled.div`
  transition: all ${easeInOut} ${longAnimationTime};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  // 70 === height of label + margins (24+22+24)
  padding-bottom: ${(props) => (props.activeCategory ? 0 : 70)}px;
  margin-bottom: ${SPACING_24};
  position: relative;
  width: 100%;
`;

const ScaledContent = styled.div`
  transition: all ${easeInOut} ${longAnimationTime};
  scale: ${(props) => (props.activeCategory ? 0.4 : 1)};
  transform-origin: bottom left;
  transform-box: fill-box;
  position: relative;
  width: 100%;
`;

const BarInner = styled.div`
  width: 100%;

  svg {
    transition: all ${easeInOut} ${longAnimationTime};
    vertical-align: bottom;

    & > rect,
    & > path {
      transform-origin: bottom center;
      transform-box: fill-box;
      transition: all ${easeInOut} ${longAnimationTime};
      transition-delay: ${slideAnimationDelay}s;
    }

    // For rects we move the whole shape up and down and clip it with the viewbox
    & > rect {
      translate: 0
        ${(props) => (props.isActiveSlide ? 100 - 100 * props.scale : 90)}%;
    }

    // For paths we need to just scale down the shape (triangle)
    & > path {
      transform: scaleY(
        ${(props) => (props.isActiveSlide ? props.scale : 0.1)}
      );
    }
  }
`;

const IconInner = styled.div`
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${slideAnimationDelay}s;
  transform-origin: bottom left;
  transform-box: fill-box;
  translate: 0 -100%;
  position: absolute;
  top: ${(props) => (props.isActiveSlide ? 100 - 100 * props.scale : 90)}%;
  width: 100%;

  svg {
    margin: 0 auto;
    margin-bottom: ${SPACING_24};
    display: block;

    @media ${mobileCondition} {
      width: 24px;
      margin-bottom: ${SPACING_8};
    }
  }
`;

const CategoryAmount = styled(H3)`
  color: ${accent.velvet270};
  transition: all ${easeInOut} ${longAnimationTime};
  margin-bottom: ${(props) => (props.activeCategory ? 0 : SPACING_24)};
  margin-left: ${(props) => (props.activeCategory ? SPACING_24 : 0)};
  translate: ${(props) => (props.activeCategory ? 0 : -50)}%;
  position: absolute;
  bottom: ${(props) => (props.activeCategory ? 0 : -28)}px;
  left: ${(props) => (props.activeCategory ? 40 : 50)}%;

  @media ${mobileCondition} {
    font-size: ${H3Size};
    line-height: ${H3LineHeight};
    width: 100%;
    text-align: center;
  }
`;

const CategoryTitle = styled(CategoryAmount)`
  color: ${accent.velvet2};
  bottom: ${(props) => (props.activeCategory ? 25 : 0)}px;
  word-wrap: break;

  @media ${mobileCondition} {
    font-size: ${H5Size};
    line-height: ${H5LineHeight};
    width: 100%;
    text-align: center;
  }
`;

export const CategoryItem = (props) => {
  const {
    dataset,
    maxValue,
    category,
    width,
    isActiveSlide,
    enableClick = true,
  } = props;

  const [active, setActive] = useState(false);

  if (!dataset[category]) return null;

  const categoryData = dataset[category];
  const scale = categoryData.amount / maxValue || 0;

  const barByCategory = (category) => {
    switch (category) {
      case 'safety':
        return <SafetyBar />;
      case 'preserveAndGrow':
        return <PreserveAndGrowBar />;
      case 'create':
        return <CreateBar />;
      default:
        break;
    }
  };

  const iconByCategory = (category) => {
    switch (category) {
      case 'safety':
        return (
          <SpriteIcon
            id="Safety"
            navigational={true}
            inverted={true}
            size={SPACING_40}
          />
        );
      case 'preserveAndGrow':
        return (
          <SpriteIcon
            id="Preserve and Develop"
            navigational={true}
            inverted={true}
            size={SPACING_40}
          />
        );
      case 'create':
        return (
          <SpriteIcon
            id="Create"
            navigational={true}
            inverted={true}
            size={SPACING_40}
          />
        );
      default:
        break;
    }
  };

  return (
    <Category
      onClick={() => enableClick && setActive(!active)}
      activeCategory={active}
      width={width}
      enableClick={enableClick}
    >
      <BarWrapper activeCategory={active}>
        <ScaledContent activeCategory={active}>
          <IconInner scale={scale} isActiveSlide={isActiveSlide}>
            {iconByCategory(category)}
          </IconInner>
          <BarInner scale={scale} isActiveSlide={isActiveSlide}>
            {barByCategory(category)}
          </BarInner>
        </ScaledContent>
        <CategoryTitle activeCategory={active}>
          {t(`wealthBalance:categories:${category}`)}
        </CategoryTitle>
        <CategoryAmount activeCategory={active}>
          {formatBigMoney(categoryData.amount, false)}
        </CategoryAmount>
      </BarWrapper>
      {enableClick ? (
        <DesktopWrapper>
          {categoryData.items ? (
            <CategoryList
              dataset={dataset}
              category={category}
              active={active}
            />
          ) : null}
        </DesktopWrapper>
      ) : null}
    </Category>
  );
};
