import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  formatPeriodLabel,
  t,
  useResource,
  useShowStrategy,
} from '@formue-app/core';

import { LandscapeOverrideStyled, printCondition } from '../../constants/media';
import {
  SPACING_16,
  SPACING_20,
  SPACING_24,
  SPACING_8,
} from '../../constants/spacing';

import { GridElement } from '../../components/layout';
import { H2, ParagraphSmall } from '../../components/texts';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { PeriodicHoldings } from '../../components/portfolio/reporting/PeriodicHoldings';
import { PeriodicTransactions } from '../../components/portfolio/reporting/PeriodicTransactions';
import { PeriodicReportingHeader } from '../../components/portfolio/reporting/PeriodicReportingHeader';
import { PeriodicActiveClassOverview } from '../../components/portfolio/reporting/PeriodicActiveClassOverview';
import { PeriodicGain } from '../../components/portfolio/reporting/PeriodicGain';
import { PeriodicChange } from '../../components/portfolio/reporting/PeriodicChange';
import { PeriodicStrategy } from '../../components/portfolio/reporting/PeriodicStrategy';
import { PeriodicReportingPrintHeader } from '../../components/portfolio/reporting/PeriodicReportingPrintHeader';
import { PrintPageNumbers } from '../../components/print/PrintPageNumber';
import { PrintPageBreaker } from '../../components/common/PrintPageBreaker';
import { PeriodicGainYear } from '../../components/portfolio/reporting/PeriodicGainYear';

const StyledGridElement = styled(GridElement)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_20};

  @media ${printCondition} {
    padding-left: ${SPACING_24} !important;
    padding-right: ${SPACING_24} !important;
  }
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  margin-top: ${SPACING_16};

  @media ${printCondition} {
    margin-top: 0;
  }
`;

export const PeriodicReportingPage = () => {
  const loading = useResource(['STRATEGIES/INDEX', 'ACCOUNTS/INDEX']);

  const [chartHeight, setChartHeight] = useState(225);

  const { period, startDate, endDate } = useSelector(
    (state) => state.ui.portfolio.filters
  );

  const showStartegy = useShowStrategy();

  useEffect(() => {
    const handleBeforePrint = () => {
      setChartHeight(160);
    };

    const handleAfterPrint = () => {
      setChartHeight(225);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  const onPrint = () => {
    setChartHeight(160);

    setTimeout(() => {
      window.print();
    }, 200);
  };

  if (loading) {
    return (
      <GridElement columns={12} style={{ height: 300 }}>
        <CenteredActivityIndicator />
      </GridElement>
    );
  }

  return (
    <>
      <LandscapeOverrideStyled />

      <PeriodicReportingPrintHeader />
      <PeriodicReportingHeader onPrint={onPrint} />

      <StyledGridElement columns={12}>
        <SubTitleWrapper>
          <H2>{t('portfolio:periodicReporting:overviewTitle')}</H2>
          <ParagraphSmall>
            {formatPeriodLabel(period, startDate, endDate, true)}
          </ParagraphSmall>
        </SubTitleWrapper>
      </StyledGridElement>

      <PeriodicChange />
      <PeriodicGain chartHeight={chartHeight} />
      <PeriodicGainYear />
      <PeriodicActiveClassOverview />

      <StyledGridElement columns={12}>
        {showStartegy ? (
          <>
            <PrintPageBreaker />
            <SubTitleWrapper>
              <H2>{t('portfolio:periodicReporting:strategy:title')}</H2>
              <ParagraphSmall>
                {formatPeriodLabel(period, startDate, endDate, true)}
              </ParagraphSmall>
            </SubTitleWrapper>
            <PeriodicStrategy />
          </>
        ) : null}

        <PrintPageBreaker />

        <SubTitleWrapper>
          <H2>{t('portfolio:periodicReporting:holdings:title')}</H2>
          <ParagraphSmall>
            {formatPeriodLabel(period, startDate, endDate, true)}
          </ParagraphSmall>
        </SubTitleWrapper>
        <PeriodicHoldings />

        <PrintPageBreaker />

        <SubTitleWrapper>
          <H2>{t('portfolio:periodicReporting:transactions:title')}</H2>
          <ParagraphSmall>
            {formatPeriodLabel(period, startDate, endDate, true)}
          </ParagraphSmall>
        </SubTitleWrapper>
        <PeriodicTransactions />
      </StyledGridElement>
      <PrintPageNumbers />
    </>
  );
};
