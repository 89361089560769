import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors } from '@formue-app/core';

import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_2,
} from '../../constants/spacing';
import { accent } from '../../constants/colors';
import { H4 } from '../texts';
import { CollapsibleListItem } from '../common/CollapsibleListItem';
import { GridElement } from '../layout';
import { ProfileIcon } from '../common/ProfileIcon';
import { MarkdownContent } from '../common/MarkdownContent';

const Content = styled.div`
  display: flex;
  margin-right: auto;
  align-items: center;
  gap: ${SPACING_16};

  h6 {
    color: ${accent.ocean450};
    margin-bottom: ${SPACING_2};
  }
`;

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  & + .details > div {
    padding-top: 0 !important;
  }
`;

const {
  entities: {
    accountTeamMembers: { mainAdvisorSelector },
  },
} = selectors;

export const StrategyComment = (props) => {
  const { title, body, ...rest } = props;

  const advisor = useSelector(mainAdvisorSelector);

  const [, setExpanded] = useState(false);

  if (!body) {
    return null;
  }

  const TOP_OFFSET = 0;

  return (
    <GridElement {...rest}>
      <StyledCollapsibleListItem
        details={
          <MarkdownContent style={{ maxWidth: 600 }}>{body}</MarkdownContent>
        }
        detailsStyle={{
          paddingInline: SPACING_48,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          paddingTop: 0,
          marginTop: -TOP_OFFSET,
          paddingLeft: 80,
        }}
        expandedStyle={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        startExpanded={false}
        style={{ paddingBlock: SPACING_24, paddingInline: SPACING_24 }}
        onExpand={setExpanded}
      >
        <Content>
          <ProfileIcon
            name={advisor?.name}
            url={advisor?.photoUrl}
            size={40}
            style={{ zIndex: 10 }}
          />
          <H4>{title}</H4>
        </Content>
      </StyledCollapsibleListItem>
    </GridElement>
  );
};
