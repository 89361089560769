import React, { useState } from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { selectors, t } from '@formue-app/core';

import {
  accent,
  backgroundWhite,
  textLightest,
} from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';

import { H1, Paragraph } from '../../texts';

import { SvgMap } from '../../portfolio/lenses/geography/SvgMap';
import { useSelector } from 'react-redux';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';
import { ContinentText } from '../../portfolio/lenses/geography/ContinentText';
import { H1LineHeight, H1Size } from '../../../constants/text';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: 0;
  padding-right: 0;
  align-items: center;
`;

const StyledContinentText = styled(ContinentText)`
  h1 span {
    font-weight: 700 !important;
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  gap: ${SPACING_8};
`;

const ListItem = styled.li`
  border: 1px solid ${accent.ocean1};
  padding: 7px ${SPACING_16};
  background: ${(props) => (props.selected ? backgroundWhite : 'none')};
  border-radius: 20px;
  cursor: pointer;

  opacity: ${(props) => (props.animate ? 1 : 0)};
  transition: ${(props) => `all ${easeInOut} ${shortAnimationTime}`};

  p {
    color: ${(props) => (props.selected ? accent.ocean490 : textLightest)};
    font-weight: bold;
    opacity: ${(props) => (props.selected ? 1.0 : 0.7)};
    transition: ${(props) => `all ${easeInOut} ${shortAnimationTime}`};
  }
`;

const {
  entities: {
    securities: { securitiesByContinentSelector, securitiesByCountrySelector },
  },
} = selectors;

export const Geography = (props) => {
  const { isActiveSlide, titleColor, highlightColor } = props;

  const byContinents = useSelector(securitiesByContinentSelector);
  const byCountry = useSelector(securitiesByCountrySelector);
  const [selectedContinent, setSelectedContinent] = useState('Global');
  const sortedContinents = Object.values(byContinents).sort(
    (a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio
  );
  const continentsWidthAssets = sortedContinents.filter(
    (item, index) => item.partOfKnownPortfolio > 0.01
  );
  const topThreeCountries = Object.values(byCountry)
    .filter((country) => country.continent === selectedContinent)
    .sort((a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio);

  const highlight =
    selectedContinent === 'Global'
      ? continentsWidthAssets.map((item) => item.continent)
      : selectedContinent;

  return (
    <Wrapper>
      <ContentWrapper>
        <StyledContinentText
          selectedContinent={selectedContinent}
          fontSize={H1Size}
          lineHeight={H1LineHeight}
          textColor={accent[titleColor]}
          highlightColor={accent[highlightColor]}
          TextComponent={H1}
        />

        <List>
          <ListItem
            onClick={() => setSelectedContinent('Global')}
            selected={selectedContinent === 'Global'}
            index={0}
            animate={isActiveSlide && !!sortedContinents}
          >
            <Paragraph>{t('geography:continents:global')}</Paragraph>
          </ListItem>
          {sortedContinents.map((continent, index) => (
            <ListItem
              key={`continent-${index}`}
              selected={selectedContinent === continent.continent}
              onClick={() => setSelectedContinent(continent.continent)}
              index={index + 1}
              animate={isActiveSlide && !!sortedContinents}
            >
              <Paragraph>
                {t(`geography:continents:${camelcase(continent.continent)}`)}
              </Paragraph>
            </ListItem>
          ))}
        </List>
      </ContentWrapper>

      <ContentWrapper style={{ width: 'auto', marginTop: 70 }}>
        <SvgMap
          width={600 * 1.4}
          height={350 * 1.4}
          zoom={selectedContinent}
          continents={sortedContinents.filter(
            (item, index) => item.partOfKnownPortfolio > 0.01
          )}
          topThreeCountries={topThreeCountries.filter(
            (item, index) => index < 3
          )}
          highlight={highlight}
          selectedContinent={selectedContinent}
          onClick={(continent) => setSelectedContinent(continent)}
        />
      </ContentWrapper>
    </Wrapper>
  );
};
