import { handleActions } from 'redux-actions';

export { sagas } from './sagas';
export { mock } from './mock';
import { actions } from './actions';
export { actions };

export const defaultState = {
  polling: false,
  shouldPoll: false,
  inProgress: [],
};

export const reducer = handleActions(
  {
    [actions.addToInProgress]: (state, { payload }) => {
      const inProgress = [...state.inProgress, payload];
      state.inProgress = inProgress;
      return state;
    },
    [actions.removeFromInProgress]: (state, { payload }) => {
      const inProgress = [...state.inProgress];
      const index = inProgress.indexOf(payload);
      if (index > -1) {
        inProgress.splice(index, 1);
      }

      state.inProgress = inProgress;
      return state;
    },
    [actions.startPolling]: state => ({
      ...state,
      polling: true,
    }),
    [actions.stopPolling]: state => ({
      ...state,
      polling: false,
    }),
  },
  defaultState
);
