import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReactModal from 'react-modal';
import { CookiesProvider } from 'react-cookie';

import { Sentry, i18n } from '@formue-app/core';

import { PrintDefault } from './components/print/PrintDefault';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store, history } from './store';
import { ScrollToTop } from './components/router/ScrollToTop';
ReactModal.setAppElement('#root');

Sentry.init({
  dsn: 'https://e159c08316714d14a00ff8ab03c17f58@o262048.ingest.sentry.io/5456773',
});

(async () => {
  await i18n.init();
  const root = createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <CookiesProvider>
          <ConnectedRouter history={history}>
            <PrintDefault>
              <ScrollToTop />
              <App />
            </PrintDefault>
          </ConnectedRouter>
        </CookiesProvider>
      </Provider>
    </React.StrictMode>
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
