import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { ActivityIndicator } from '../../common/ActivityIndicator';
import { accent } from '../../../constants/colors';
import { H6 } from '../../texts';

const StyledTitle = styled(H6)`
  padding-bottom: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PortfolioActivityIndicator = ({
  highlightColor = accent.velvet2,
  loadingColor = accent.ocean1,
  size = 32,
  ...rest
}) => {
  return (
    <div {...rest}>
      <StyledTitle style={{ color: highlightColor }}>
        {t('loading:portfolio')}{' '}
      </StyledTitle>
      <Wrapper>
        <ActivityIndicator
          loadingColor={loadingColor}
          size={size}
          columns={2}
        />
      </Wrapper>
    </div>
  );
};
