import * as signignEvents from './signingEvents';
import * as tasks from './tasks';
import * as readStatus from './readStatus';

export const tasksMock = {
  signingEvents: { ...signignEvents.mock },
  tasks: { ...tasks.mock },
};

export const readStatusMock = {
  signingEvents: { ...signignEvents.mock },
  readStatus: { ...readStatus.mock },
};
