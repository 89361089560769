import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { SvgMorphing } from '../common/SvgMorphing';
import { SECTORS } from '@formue-app/core/src/constants/sectors';

import { ReactComponent as Financials } from '@formue-app/core/src/assets/images/sectors/icon-financials.svg';
import { ReactComponent as HealthCare } from '@formue-app/core/src/assets/images/sectors/icon-health-care.svg';
import { ReactComponent as InformationTechnology } from '@formue-app/core/src/assets/images/sectors/icon-information-technology.svg';
import { ReactComponent as Industrials } from '@formue-app/core/src/assets/images/sectors/icon-industrials.svg';
import { ReactComponent as Utilities } from '@formue-app/core/src/assets/images/sectors/icon-utilities.svg';
import { ReactComponent as CommunicationServices } from '@formue-app/core/src/assets/images/sectors/icon-communication-services.svg';
import { ReactComponent as Energy } from '@formue-app/core/src/assets/images/sectors/icon-energy.svg';
import { ReactComponent as Materials } from '@formue-app/core/src/assets/images/sectors/icon-materials.svg';
import { ReactComponent as ConsumerStaples } from '@formue-app/core/src/assets/images/sectors/icon-consumer-staples.svg';
import { ReactComponent as RealEstate } from '@formue-app/core/src/assets/images/sectors/icon-real-estate.svg';
import { ReactComponent as ConsumerDiscretionary } from '@formue-app/core/src/assets/images/sectors/icon-consumer-discretionary.svg';

const SECTOR_ICONS = [
  Financials,
  HealthCare,
  InformationTechnology,
  Industrials,
  Utilities,
  CommunicationServices,
  Energy,
  Materials,
  ConsumerStaples,
  RealEstate,
  ConsumerDiscretionary,
];

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  width: 110px;
  height: 110px;
  margin: 0 50px;
`;

export const SectorsLoading = () => {
  const sectorsLength = SECTORS.length;

  const [activeIcons, setActiveIcons] = useState({
    0: 0,
    1: 1,
    2: 2,
  });

  const [counter, setCounter] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 500);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIcons]);

  useEffect(() => {
    const newIconIndex = counter % sectorsLength;
    const newActiveIcons = { ...activeIcons, [counter % 3]: newIconIndex };
    setActiveIcons(newActiveIcons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <Container>
      <IconWrapper>
        <SvgMorphing
          height={110}
          width={110}
          svgImage={SECTOR_ICONS[activeIcons['0']]}
        />
      </IconWrapper>
      <IconWrapper>
        <SvgMorphing
          height={110}
          width={110}
          svgImage={SECTOR_ICONS[activeIcons['1']]}
        />
      </IconWrapper>
      <IconWrapper>
        <SvgMorphing
          height={110}
          width={110}
          svgImage={SECTOR_ICONS[activeIcons['2']]}
        />
      </IconWrapper>
    </Container>
  );
};
