import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { actions, formatPeriodLabel } from '@formue-app/core';
import { ParagraphSmall } from '../../../texts';
import { accent, backgroundWhite } from '../../../../constants/colors';
import { SPACING_16, SPACING_8 } from '../../../../constants/spacing';

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

const StyledContainer = styled.div`
  display: flex;
  gap: ${SPACING_8};
`;

const StyledLabel = styled(ParagraphSmall)`
  color: ${backgroundWhite};
  font-weight: 500;
  white-space: nowrap;
`;

const StyledButton = styled.button`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 ${SPACING_16};
  background-color: transparent;
  outline: none;
  border: 1px solid ${accent.velvet170};
  border-radius: 36px;
  cursor: pointer;

  &.active {
    background-color: ${accent.velvet1};
    border-color: ${accent.velvet1};
  }
`;

export const ReturnContributionPeriodFilter = ({
  excludedPeriods = [],
  ...rest
}) => {
  const dispatch = useDispatch();
  const periodValues = useSelector(
    (state) => state.ui.portfolio.filterValues.period
  );

  const filteredPeriodValues = periodValues.filter(
    (period) => !excludedPeriods.includes(period)
  );

  const activePeriod = useSelector(
    (state) => state.ui.portfolio.filters.period
  );

  const renderLabel = (period) => {
    const label = formatPeriodLabel(period);
    return <StyledLabel>{label}</StyledLabel>;
  };

  return (
    <StyledContainer {...rest}>
      {filteredPeriodValues.map((period, index) => (
        <StyledButton
          key={index}
          onClick={() => {
            dispatch(uiPortfolioActions.filterPeriod({ period }));
          }}
          className={period === activePeriod ? 'active' : ''}
        >
          {renderLabel(period)}
        </StyledButton>
      ))}
    </StyledContainer>
  );
};
