import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AppStoreBadgeEN } from '../../assets/stores/AppStoreBadge_EN_blk.svg';
import { ReactComponent as AppStoreBadgeNO } from '../../assets/stores/AppStoreBadge_NO_blk.svg';
import { ReactComponent as AppStoreBadgeSE } from '../../assets/stores/AppStoreBadge_SE_blk.svg';
import { ReactComponent as AppStoreBadgeDK } from '../../assets/stores/AppStoreBadge_DK_blk.svg';
import { constants } from '@formue-app/core';

export const AppStoreBadge = (props) => {
  const { height, width, className } = props;
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { appStoreLink } = constants.app;

  let BadgeComponent;
  switch (language) {
    case 'nb':
      BadgeComponent = AppStoreBadgeNO;
      break;
    case 'se':
      BadgeComponent = AppStoreBadgeSE;
      break;
    case 'dk':
      BadgeComponent = AppStoreBadgeDK;
      break;
    default:
      BadgeComponent = AppStoreBadgeEN;
      break;
  }

  return (
    <a
      className={className}
      href={appStoreLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <BadgeComponent height={height} width={width} />
    </a>
  );
};
