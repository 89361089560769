import { call, put, takeEvery, select } from 'redux-saga/effects';

import { standardFilter } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the network resource
export const [actions, reducer, resourceSagas] = resourceReducer('standard-filters');

// Create the sagas for this entity
function* updateStandardFilter() {
  yield takeEvery(actions.updateRequestBegin, function* ({ payload }) {
    const { data } = payload;
    try {
      yield call(standardFilter.update, data);
      yield put(actions.updateRequestSuccess());
    } catch (error) {
      yield put(actions.updateRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

function* deleteStandardFilter() {
  yield takeEvery(actions.destroyRequestBegin, function* ({ payload }) {
    try {
      yield call(standardFilter.delete);
      yield put(actions.destroyRequestSuccess());
    } catch (error) {
      yield put(actions.destroyRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [updateStandardFilter, deleteStandardFilter, ...resourceSagas];
