import { useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from '../../../store';

const { accessibleAccountsSelector } = selectors.ui.user;

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

export const usePortfolioRelationshipFilter = () => {
  const dispatch = useDispatch();
  const accessibleAccounts = useSelector(accessibleAccountsSelector);
  const excludedAccountIds = useSelector(
    state => state.ui.portfolio.filters.excludedSsids
  );

  if (!accessibleAccounts) {
    return [];
  }

  const onChange = ssid => {
    // This array will hold the ssids we want to _exclude_.
    let ssids;

    // Excluded accounts holds the accounts we've already filtered out, so
    // if the ssid we get into this method already is in the excluded accounts
    // list we want to _remove_ the ssid from that list (to enable it).
    if (excludedAccountIds.includes(ssid)) {
      // Remove ssid from selected accounts
      ssids = excludedAccountIds.filter(excluded => excluded !== ssid);
    } else {
      // Add ssid to selected accounts
      ssids = [...excludedAccountIds, ssid];
    }

    // You can't exclude all you need at least one to get any data. So to determine
    // that we check if the list of ssids we want to exlude is the same length as
    // the number of accounts you have access to, if they are similar you've excluded
    // to many accounts, and we want to prevent that, so return false to prevent
    // the dispatch to happen.
    if (ssids.length === accessibleAccounts.length) {
      return false;
    }

    dispatch(uiPortfolioActions.filterSsids(ssids));
  };

  // Helper function to determine if a toggle switch can be disabled or not.
  // if all except one is excluded already, one should not be allowed to disable
  // the last one before another is enabled. The data source requires at least
  // one ssid to make any sense.
  const canChange = ssid => {
    const allButOneExcluded =
      excludedAccountIds.length + 1 === accessibleAccounts.length;
    if (!allButOneExcluded) {
      return true;
    }

    // if ssid is in excluded, it should can be toggled, if not that would
    // cause a problem since we have then excluded _all_ ssids, so that
    // should not be allowed
    if (excludedAccountIds.includes(ssid)) {
      return true;
    }

    return false;
  };

  const options = accessibleAccounts.map(account => ({
    ...account,
    disabled: !canChange(account.ssid),
    selected: !excludedAccountIds.includes(account.ssid),
    onClick: () => onChange(account.ssid),
  }));

  return options;
};
