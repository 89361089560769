import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { content } from '../../../constants/content';
import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_12,
} from '../../../constants/spacing';

import { ParagraphLarge } from '../../texts';
import { titleFontStack } from '../../../fonts';
import { EsgList } from '../slideComponents/esg/EsgList';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const StyledParagraph = styled(ParagraphLarge)`
  color: ${accent.forest250};
  max-width: 70%;
  align-self: flex-start;
  margin-top: ${SPACING_12};
  font-size: 22px;
  font-weight: 500;
`;

export const EsgPreferences = (props) => {
  const { isActiveSlide } = props;

  return (
    <Wrapper>
      <StyledParagraph>
        <Trans
          i18nKey={t('presentation:annualReview:esg:preferencesBody')}
          components={{
            strong: (
              <span
                style={{
                  color: accent.forest250,
                  fontWeight: 700,
                  fontFamily: titleFontStack,
                }}
              />
            ),
          }}
        />
      </StyledParagraph>
      <EsgList isActiveSlide={isActiveSlide} items={content.esgPreferences} />
    </Wrapper>
  );
};