import React from 'react';
import styled from 'styled-components';

import { SPACING_24, SPACING_16 } from '../../constants/spacing';
import { paragraphSize, paragraphLineHeight } from '../../constants/text';

const StyledList = styled.ol`
  padding-left: ${SPACING_24};
  margin: ${SPACING_16} 0;
  list-style: decimal !important;

  li {
    font-size: ${paragraphSize};
    line-height: ${paragraphLineHeight};
  }
`;

export const OrderedList = (props) => {
  const { children } = props;

  return <StyledList {...props}>{children}</StyledList>;
};
