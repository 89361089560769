import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import {
  t,
  actions,
  getTaskTitle,
  getTaskButtonOpenLinkLabel,
  getTaskUrl,
  toLocaleDateString,
} from '@formue-app/core';

import { TodoListItemLink } from '../TodoListItemLink';
import { Column } from '../TodoListItem';
import { tabletCondition, mobileCondition } from '../../../../constants/media';

import { H4, H6, Paragraph } from '../../../texts';
import { SPACING_16 } from '../../../../constants/spacing';
import { SpriteIcon } from '../../../common/SpriteIcon';

const {
  ui: { readStatus: readActions, tasks: taskActions },
} = actions;

const StyledTodoListItemLink = styled(TodoListItemLink)`
  margin-left: auto;

  @media ${mobileCondition} {
    margin-left: 40px;
    margin-right: auto;
  }
`;

const StyledColumn = styled(Column)`
  display: flex;
  align-items: center;
  padding: ${SPACING_16};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  margin-right: ${SPACING_16};
  min-width: 24px;
  min-height: 24px;
`;

const RightAlignCell = styled(Column)`
  display: flex;
  justify-content: flex-end;
  min-width: 170px;
`;

const StyledTitle = styled(H4)`
  word-break: break-word;
`;

const DueDateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

const MobileHeader = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 4px;
`;

export const useTaskColumns = (props) => {
  const { id, item, user } = props;
  const dispatch = useDispatch();

  const tabletSize = useMediaQuery({
    query: tabletCondition,
  });

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  if (!item || !user) {
    return null;
  }

  const {
    completedAt: date,
    dueAt,
    status,
    assignee: { name, id: assigneeId },
  } = item;

  const title = getTaskTitle(item);
  const type = t('todo:screenTitle:tasks');
  const url = getTaskUrl(item);

  let link;
  if (url) {
    const openLink = () => {
      dispatch(readActions.saveReadItem({ type: 'tasks', id }));
      dispatch(taskActions.addToInProgress(id));
      window.open(url, '_blank');
    };
    const buttonOpenLinkLabel = getTaskButtonOpenLinkLabel(item);
    link = {
      onClick: openLink,
      label: buttonOpenLinkLabel,
    };
  }

  // If the user viewing this task, is _not_ the assignee we shouldn't show
  // the description or the action link
  if (assigneeId !== user.id) {
    link = null;
  }

  let StatusIcon;
  switch (status.toLowerCase()) {
    case 'ongoing':
      StatusIcon = <SpriteIcon id="Iteration" size={24} />;
      link = null;
      break;
    case 'completed':
      StatusIcon = <SpriteIcon id="Task" size={24} />;
      link = null;
      break;
    default:
      StatusIcon = <SpriteIcon id="Document Single" size={24} />;
      break;
  }

  const showDateCondition = status.toLowerCase() === 'completed' && !!date;
  const showDueDateCondition = status.toLowerCase() !== 'completed' && !!dueAt;

  // Define the table columns
  let columns = [
    {
      key: 'title',
      render: () => (
        <>
          <IconWrapper>{StatusIcon}</IconWrapper>
          <TitleWrapper>
            <StyledTitle>{title}</StyledTitle>
            {showDueDateCondition ? (
              <DueDateWrapper>
                <SpriteIcon id="Time" size={12} style={{ marginRight: 4 }} />
                <H6>{toLocaleDateString(dueAt)}</H6>
              </DueDateWrapper>
            ) : null}
          </TitleWrapper>
        </>
      ),
      component: StyledColumn,
      width: '40%',
    },
    {
      key: 'date',
      render: () => (showDateCondition ? toLocaleDateString(date) : null),
      width: '15%',
    },
    {
      key: 'name',
      render: () => name,
      width: '20%',
    },
    {
      key: 'type',
      render: () => type,
      width: '15%',
    },
    {
      key: 'action',
      render: () => <StyledTodoListItemLink link={link} />,
      component: RightAlignCell,
      width: '10%',
    },
  ];

  if (tabletSize || mobileSize) {
    columns = [
      {
        key: 'title',
        render: () => (
          <>
            <div>
              <MobileHeader>
                <H6 style={{ marginRight: 5, lineHeight: '20px' }}>{type}</H6>
                {showDueDateCondition ? (
                  <DueDateWrapper>
                    <SpriteIcon id="Time" style={{ marginRight: 4 }} />
                    <H6>{toLocaleDateString(dueAt)}</H6>
                  </DueDateWrapper>
                ) : null}
                {showDateCondition ? (
                  <H6 style={{ height: 16 }}>{toLocaleDateString(date)}</H6>
                ) : null}
              </MobileHeader>

              <H4 style={{ marginBottom: 5, wordBreak: 'break-word' }}>
                {title}
              </H4>
              <Paragraph>{name}</Paragraph>
            </div>
          </>
        ),
        component: StyledColumn,
        width: '100%',
      },
      {
        key: 'action',
        render: () => <StyledTodoListItemLink link={link} />,
        component: RightAlignCell,
        width: '100%',
      },
    ];
  }

  return columns;
};
