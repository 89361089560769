import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { cashFlowEvents } from '../../../api';

import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the cashFlowEvents resource
export const [actions, reducer, ...resourceSagas] =
  resourceReducer('cash-flow-events');

// Create the sagas for this entity
function* readCashFlowEvents() {
  yield takeEvery(actions.indexRequestBegin, function* () {
    try {
      const result = yield call(cashFlowEvents.get);
      const { meta } = result;
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, meta }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readCashFlowEvents, ...resourceSagas];
