import React from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import {
  getEmissionComparisonIconType,
  localizeFloat,
  numberWithSpaces,
  t,
} from '@formue-app/core';

import {
  SPACING_12,
  SPACING_16,
  SPACING_20,
} from '../../../../constants/spacing';
import { accent } from '../../../../constants/colors';
import { SpriteIcon } from '../../../common/SpriteIcon';
import { ParagraphSmall } from '../../../texts';

const StyledContainer = styled.div`
  padding: ${SPACING_16} ${SPACING_20};
  border-radius: 20px;
  background-color: ${accent.forest490};
  align-items: center;
  gap: ${SPACING_12};
  display: flex;
`;

export const ComparisonItem = ({ type, value, ...rest }) => {
  return (
    <StyledContainer {...rest}>
      <SpriteIcon
        id={getEmissionComparisonIconType(type)}
        inverted={true}
        color={accent.forest250}
        size={16}
      />
      <ParagraphSmall style={{ color: accent.forest250 }}>
        {t(`sustainability:emissionComparison:${camelcase(type)}`, {
          value: numberWithSpaces(localizeFloat(value.toFixed(0), 0)),
        })}
      </ParagraphSmall>
    </StyledContainer>
  );
};
