import React from 'react';

import { SPACING_8 } from '../../../constants/spacing';

import { GridElement } from '../../layout';
import { ParagraphSmall } from '../../texts';

export const SlideFooter = (props) => {
  const { body } = props;

  return (
    <GridElement columns={12} style={{ height: 'fit-content' }}>
      <div style={{ display: 'flex', marginTop: SPACING_8 }}>
        <ParagraphSmall>{body}</ParagraphSmall>
      </div>
    </GridElement>
  );
};
