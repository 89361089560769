import { handleActions } from 'redux-actions';

import { actions } from './actions';
export { actions };

export const defaultState = {
  selected: []
};

export const reducer = handleActions(
  {
    [actions.filter]: (state, { payload }) => {
      const { selected } = state;
      let result;
      if (selected.includes(payload)) {
        result = selected.filter(type => type !== payload);
      } else {
        result = [...selected, payload];
      }
      return {
        ...state,
        selected: result
      };
    },
  },
  defaultState
);
