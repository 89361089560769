import React, { useState } from 'react';
import { accent } from '../../../constants/colors';
import { SPACING_8 } from '../../../constants/spacing';
import { HorizontalDivider } from '../../common/HorizontalDivider';
import { CollapsibleFilters } from './CollapsibleFilters';
import { ExtendedReportingMultiSelectFilter } from './ExtendedReportingMultiSelectFilter';

export const ExtendedReportingFilterGroup = ({
  icon,
  title,
  startExpanded,
  fields = [],
  app,
  selections,
  children,
  ...rest
}) => {
  const [count, setCount] = useState({});

  if (!fields.length && !children) return null;

  if (children) {
    return (
      <CollapsibleFilters
        startExpanded={startExpanded}
        title={title}
        icon={icon}
        count={
          Object.values(count).length &&
          Object.values(count).reduce((a, b) => a + b)
        }
        {...rest}
        style={{ border: `1px solid ${accent.sand1}`, marginBottom: 8 }}
      >
        {children}
      </CollapsibleFilters>
    );
  }

  return (
    <CollapsibleFilters
      startExpanded={startExpanded}
      title={title}
      icon={icon}
      count={
        Object.values(count).length &&
        Object.values(count).reduce((a, b) => a + b)
      }
      {...rest}
      style={{ border: `1px solid ${accent.sand1}`, marginBottom: 8 }}
    >
      {fields.map((field) => (
        <div key={`filter-${field.name}`}>
          <ExtendedReportingMultiSelectFilter
            title={field.label}
            field={field}
            app={app}
            selections={selections}
            onChangeCount={(optionsCount) => {
              setCount({ ...count, [field.name]: optionsCount });
            }}
          />
          <HorizontalDivider style={{ margin: `0 ${SPACING_8}` }} />
        </div>
      ))}
    </CollapsibleFilters>
  );
};
