import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../../store';

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

export const usePortfolioExternalReportingFilter = () => {
  const dispatch = useDispatch();
  const externalReportingValues = useSelector(
    state => state.ui.portfolio.filterValues.externalReporting
  );
  const selectedExternalReportingValues = useSelector(
    state => state.ui.portfolio.filters.externalReporting
  );

  const canChange = value => {
    const allButOneExcluded = selectedExternalReportingValues.length === 1;
    if (!allButOneExcluded) {
      return true;
    }

    if (!selectedExternalReportingValues.includes(value)) {
      return true;
    }

    return false;
  };

  const options = externalReportingValues.map(value => ({
    name: value,
    disabled: !canChange(value),
    selected: selectedExternalReportingValues.includes(value),
    onClick: () => dispatch(uiPortfolioActions.filterExternalReporting(value)),
  }));

  return options;
}
