import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  selectors,
  config,
  getAdvisorRoleName,
  getFlagsSelector,
  useResource,
} from '@formue-app/core';

import { SPACING_16 } from '../../constants/spacing';
import { textLightest, accent } from '../../constants/colors';
import { ProfileIcon } from '../common/ProfileIcon';

import { ParagraphSmall, H3 } from '../texts';
import { ButtonSmall } from '../buttons';

import logo from '../../assets/images/logo-contact.png';

const {
  entities: {
    accountTeamMembers: { mainAdvisorSelector },
  },
} = selectors;

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  * {
    text-align: center;
  }
`;

const ImageWrapper = styled(Link)`
  margin-bottom: ${SPACING_16};
  text-decoration: none;
`;

const Image = styled.img`
  border-radius: 50%;
`;

const Role = styled(H3)`
  color: ${accent.ocean2};
  margin-bottom: 4px;
  line-height: 19px;
`;

const Heading = styled(ParagraphSmall)`
  color: ${textLightest};
  margin-bottom: 22px;
`;

const StyledProfileIcon = styled(ProfileIcon)`
  background-color: ${accent.ocean2};

  p {
    color: ${accent.ocean1};
  }
`;

export const MainAdvisor = (props) => {
  const { t } = useTranslation();
  const contact = useSelector(mainAdvisorSelector);
  const { mainMenuOpen } = useSelector((state) => state.ui.navigation);
  const { isLead } = useSelector(getFlagsSelector);
  useResource(['ACCOUNT_TEAM_MEMBERS/INDEX']);

  // Don't render if we don't have a contact, or the menu is closed
  if (!contact) {
    return null;
  }

  const { role, name, photoUrl: url } = contact;

  if (isLead) {
    return (
      <Container {...props}>
        <ImageWrapper to="/contact">
          {mainMenuOpen ? (
            <Image src={logo} height={56} width={56} />
          ) : (
            <Image src={logo} height={32} width={32} />
          )}
        </ImageWrapper>

        {mainMenuOpen && (
          <>
            <Role>{config.title}</Role>
            <br />

            <ButtonSmall to="/contact">{t('menu:contact')}</ButtonSmall>
          </>
        )}
      </Container>
    );
  } else {
    return (
      <Container {...props}>
        <ImageWrapper to="/account-team">
          {mainMenuOpen ? (
            <StyledProfileIcon name={name} url={url} size={56} />
          ) : (
            <StyledProfileIcon name={name} url={url} size={32} />
          )}
        </ImageWrapper>

        {mainMenuOpen && (
          <>
            <Role>{getAdvisorRoleName(role)}</Role>
            <Heading>{name}</Heading>

            <ButtonSmall to="/account-team">
              {t('menu:menuItems:clientTeam')}
            </ButtonSmall>
          </>
        )}
      </Container>
    );
  }
};
