import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors } from '@formue-app/core';

import {
  SPACING_120,
  SPACING_64,
  SPACING_88,
} from '../../../constants/spacing';
import { GridElement, MainGrid } from '../../layout';

import { SlideHeader } from '../investmentStrategyExportComponents/SlideHeader';
import { ImplementationPlanChart } from '../../investmentStrategies/charts/ImplementationPlanChart';

const Wrapper = styled(MainGrid)`
  flex: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  max-width: 1232px;
`;

const {
  entities: {
    investmentStrategyImplementationPlans: {
      investmentStrategyImplementationPlansByIdSelector,
    },
  },
} = selectors;

export const ImplementationPlanGraph = (props) => {
  const { title } = props;

  const queryParams = new URLSearchParams(
    document.location.search.substring(1)
  );
  const strategyId = queryParams.get('strategyId');

  const implementationPlan = useSelector(
    investmentStrategyImplementationPlansByIdSelector(strategyId)
  );

  return (
    <Wrapper>
      <SlideHeader title={title} />

      {implementationPlan?.assetClasses ? (
        <GridElement columns={12}>
          <ImplementationPlanChart
            style={{ paddingTop: SPACING_64 }}
            implementationPlan={implementationPlan}
            animate={true}
          />
        </GridElement>
      ) : null}
    </Wrapper>
  );
};
