import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { ParagraphSmall } from '../texts';
import { BaseSection } from '../common/BaseSection';

const Container = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InvestmentStrategyDisclaimer = (props) => {
  return (
    <Container columns={12}>
      <ParagraphSmall>
        {t('wealthPlan:investmentStrategy:disclaimerText')}
      </ParagraphSmall>
    </Container>
  );
};
