import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, getTaskDescription, constants } from '@formue-app/core';

import { BaseListItem } from '../../common/BaseListItem';
import { CollapsibleListItem } from '../../common/CollapsibleListItem';
import { TodoListItem } from './TodoListItem';

import { useTaskColumns } from './columns/taskColumns';
import { Paragraph } from '../../texts';
import { SPACING_24 } from '../../../constants/spacing';

const {
  KNOWLEDGEBASE_TASK,
  NEUROPROFILER_TASK,
  ANNUAL_REVIEW_PREPERATION_TASK,
} = constants.tasks;

const { currentUserSelector } = selectors.ui.user;

const StyledBaseListItem = styled(BaseListItem)``;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: ${SPACING_24};
`;

const TaskListDetails = (props) => {
  const { item } = props;
  const description = getTaskDescription(item);

  return (
    <DetailsWrapper>
      <Paragraph>{description}</Paragraph>
    </DetailsWrapper>
  );
};

export const TaskListItem = (props) => {
  const { id } = props;
  const item = useSelector((state) => state.entities.tasks.byId[id]);
  const user = useSelector(currentUserSelector);
  const columns = useTaskColumns({ id, item, user });

  if (!item) {
    return null;
  }

  const {
    status,
    description,
    taskType,
    assignee: { id: assigneeId },
  } = item;

  const taskTypesThatShouldShowDescription = [
    NEUROPROFILER_TASK,
    KNOWLEDGEBASE_TASK,
    ANNUAL_REVIEW_PREPERATION_TASK,
  ];
  let detailsComponent;

  // Only show the description if the user is the assigned user
  if (
    taskTypesThatShouldShowDescription.includes(taskType?.toLowerCase()) &&
    assigneeId === user.id
  ) {
    detailsComponent = description ? <TaskListDetails item={item} /> : null;
  }

  if (detailsComponent) {
    const startExpanded = status.toLowerCase() === 'open';
    return (
      <CollapsibleListItem
        details={detailsComponent}
        showFlipArrow={false}
        startExpanded={startExpanded}
      >
        <TodoListItem columns={columns} />
      </CollapsibleListItem>
    );
  }

  return (
    <StyledBaseListItem>
      <TodoListItem columns={columns} />
    </StyledBaseListItem>
  );
};
