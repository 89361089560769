import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { selectors } from '@formue-app/core';

import { actions as navigationActions } from '../../../store/ui/navigation';
import { accent, backgroundWhite } from '../../../constants/colors';
import { ParagraphSmall } from '../../texts';
import { BORDER_RADIUS_LARGE } from '../../../constants/spacing';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';
import { SpriteIcon } from '../../common/SpriteIcon';

const {
  ui: {
    user: { accessibleAccountsSelector, excludedAccountIdsSelector },
  },
} = selectors;

const StyledButton = styled.button`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background-color: ${accent.ocean1};
  border-radius: ${BORDER_RADIUS_LARGE};
  height: 25px;
  cursor: pointer;

  transition: all ${easeInOut} ${shortAnimationTime};
`;

const StyledLabel = styled(ParagraphSmall)`
  color: ${backgroundWhite};
  line-height: 1;
  font-size: 11px;
  font-weight: 500;
  transition: all ${easeInOut} ${shortAnimationTime};
`;

export const AccountsButton = (props) => {
  const accessibleAccounts = useSelector(accessibleAccountsSelector);
  const excludedAccounts = useSelector(excludedAccountIdsSelector);
  const dispatch = useDispatch();

  if (!accessibleAccounts || !excludedAccounts) return null;

  const numberOfAccounts = accessibleAccounts.length - excludedAccounts.length;

  return (
    <StyledButton
      onClick={() => {
        dispatch(navigationActions.togglePopover('portfolioFilters'));
      }}
      {...props}
    >
      <SpriteIcon
        id="Individual"
        size={12}
        inverted={true}
        navigational={true}
      />
      <StyledLabel>{numberOfAccounts}</StyledLabel>
    </StyledButton>
  );
};
