/**
 *
 * @param {*} from A period - Number, e.g 202201
 * @param {*} to A Period - Number, e.g. 202203
 * @returns An array of all possible periods in between the two input values
 */
export const getPeriodsBetween = (from, to) => {
  const startYear = Number(String(from).slice(0, 4));
  const endYear = Number(String(to).slice(0, 4));
  let periods = [];

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i != endYear ? 11 : Number(String(to).slice(4, 6)) - 1;
    const startMonth =
      i === startYear ? Number(String(from).slice(4, 6)) - 1 : 0;

    for (let j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? '0' + month : month;
      periods.push(Number([i, displayMonth].join('')));
    }
  }
  return periods;
};

export const getZeroPaddedMonthFromDate = (date) => {
  return `0${date.getMonth() + 1}`.slice(-2);
};

export const getPeriodFromDate = (date) => {
  return [String(date.getFullYear()), getZeroPaddedMonthFromDate(date)].join(
    ''
  );
};

export const getFromAndToFromPeriodId = (periodId) => {
  const now = new Date();
  const firstMonth = '01';
  const currentYear = String(now.getFullYear());

  // We always want to show data until now
  const to = getPeriodFromDate(now);
  let from;

  switch (periodId) {
    case 'THISMONTH': {
      from = getPeriodFromDate(now);
      break;
    }
    case 'THISYEAR': {
      from = [currentYear, firstMonth].join('');
      break;
    }
    case 'TWELVEMONTHS': {
      const twelveMonthsAgo = new Date(
        new Date(now).setMonth(now.getMonth() - 12)
      );
      from = getPeriodFromDate(twelveMonthsAgo);
      break;
    }
    case 'THREEYEARS': {
      const threeYearsAgoAgo = new Date(
        new Date(now).setFullYear(now.getFullYear() - 3)
      );
      from = getPeriodFromDate(threeYearsAgoAgo);
      break;
    }
    case 'FIVEYEARS': {
      const fiveYearsAgoAgo = new Date(
        new Date(now).setFullYear(now.getFullYear() - 5)
      );
      from = getPeriodFromDate(fiveYearsAgoAgo);
      break;
    }
    case 'TENYEARS': {
      const tenYearsAgoAgo = new Date(
        new Date(now).setFullYear(now.getFullYear() - 8)
      );
      from = getPeriodFromDate(tenYearsAgoAgo);
      break;
    }
    case 'SINCESTART': {
      // Since start should be the same as not filtering on periods at all so
      // instead of creating a "from" value we just return nothing, which in
      // turn will cause the period filter to be cleared.
      break;
    }
    default: {
      break;
    }
  }

  return { from, to };
};
