export const strategyTitleWithAccountName = (strategy) => {
  const { title, accountName } = strategy;
  if (!accountName) {
    return title;
  }

  // If title already is prefixed with account name, don't add it already
  // (some advisors might to do so even though we say not to).
  if (title.startsWith(accountName)) {
    return title;
  }

  return `${accountName} - ${title}`;
}
