import { takeLatest, call } from 'redux-saga/effects';

import { presentationGenerate, investmentStrategyExport } from '../../../api';
import { actions } from './actions';

function* generatePresentation() {
  yield takeLatest(actions.generatePresentation, function* (payload) {
    yield call(presentationGenerate.post, payload.payload);
  });
}

function* exportInvestmentStrategy() {
  yield takeLatest(actions.exportInvestmentStrategy, function* (payload) {
    yield call(investmentStrategyExport.post, payload.payload);
  });
}

export const sagas = [generatePresentation, exportInvestmentStrategy];
