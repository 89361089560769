import React from 'react';
import styled from 'styled-components';

import { GridElement } from '../../layout/GridElement';

import { desktopCondition, notMobileCondition } from '../../../constants/media';

import { ExpertInputProgressBar } from './ExpertInputProgressBar';
import { ExpertInputDetailsStatus } from './ExpertInputDetailsStatus';
import { ExpertInputAuthorsList } from './ExpertInputAuthorsList';

const StyledSidebarElement = styled(GridElement)`
  @media ${notMobileCondition} {
    grid-area: Sidebar;
  }

  @media ${desktopCondition} {
    margin-top: -40px;
  }
`;

const StyledBarElement = styled(GridElement)`
  @media ${notMobileCondition} {
    grid-area: Bar;
  }
`;

const StyledStatusElement = styled(GridElement)`
  @media ${notMobileCondition} {
    grid-area: Status;
  }
`;

export const ExpertInputDetailsHeader = (props) => {
  const { item } = props;
  return (
    <>
      <StyledBarElement columns={8}>
        <ExpertInputProgressBar item={item} />
      </StyledBarElement>
      <StyledStatusElement columns={8}>
        <ExpertInputDetailsStatus item={item} />
      </StyledStatusElement>
      <StyledSidebarElement columns={4}>
        <ExpertInputAuthorsList item={item} />
      </StyledSidebarElement>
    </>
  );
};
