import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { Paragraph, H2, H4 } from '../texts';
import { SPACING_8 } from '../../constants/spacing';
import { StyledGridElement } from '../layout/GridElement';
import { ContentList } from '../lists';

const StyledH4 = styled(H4)`
  margin-bottom: ${SPACING_8};
`;

export const ProfileInfo = (props) => {
  const { user, columns } = props;

  const { firstName, middleName, lastName, email, mobilePhone: phone } = user;
  const name = [firstName, middleName, lastName].filter(Boolean).join(' ');

  return (
    <StyledGridElement columns={columns}>
      <H2 style={{ marginBottom: 20 }}>{t(`settings:contactInformation`)}</H2>
      <ContentList
        rows={[
          {
            content: (
              <>
                <StyledH4>{t(`form:nameLabel`)}</StyledH4>
                <Paragraph>{name}</Paragraph>
              </>
            ),
          },
          {
            content: (
              <>
                <StyledH4>{t(`form:emailLabel`)}</StyledH4>
                <Paragraph>{email}</Paragraph>
              </>
            ),
          },
          {
            content: (
              <>
                <StyledH4>{t(`form:phoneLabel`)}</StyledH4>
                <Paragraph>{phone}</Paragraph>
              </>
            ),
          },
        ]}
      />
    </StyledGridElement>
  );
};
