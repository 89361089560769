import React from 'react';
import styled from 'styled-components';
import { t } from '@formue-app/core';

import { Paragraph } from '../texts';
import { BaseSection } from '../common/BaseSection';

const Container = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InvestmentStrategySummary = (props) => {
  return (
    <Container columns={12}>
      <Paragraph style={{ maxWidth: 600 }}>
        {t('wealthPlan:investmentStrategy:summary:text')}
      </Paragraph>
    </Container>
  );
};
