import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  BORDER_RADIUS_LARGE,
  SPACING_32,
} from '../../../constants/spacing';
import { content } from '../../../constants/content';

import { GridElement, MainGrid } from '../../layout';

import { EsgListCardContent } from '../slideComponents/esg/EsgList';
import { backgroundWhite } from '../../../constants/colors';
import { H2 } from '../../texts';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const ItemWrapper = styled(GridElement)`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_32};
`;

const StyledEsgListCardContent = styled(EsgListCardContent)`
  background-color: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: ${SPACING_32};
  flex: 1;
`;

export const EsgPreferencesDetails = (props) => {
  const { isActiveSlide } = props;

  if (!isActiveSlide) {
    return null;
  }

  return (
    <Wrapper>
      <GridElement columns={12}>
        <H2 style={{ marginBottom: SPACING_32 }}>{t('wealthPlan:investmentProfile:sustainabilityPreferences:engagementStrategy:label')}</H2>
      </GridElement>

      <ItemWrapper columns={12}>
        {content.esgPreferences.map((item, index) => (
          <StyledEsgListCardContent
            items={content.esgPreferences}
            activeIndex={index}
          />
        ))}
      </ItemWrapper>
    </Wrapper>
  );
};