import React from 'react';

import { ReactComponent as FormueLogoFull } from '../../assets/icons/logo/formue-logo-full.svg';
import { ReactComponent as FormueLogoFullColors } from '../../assets/icons/logo/formue-logo-full-colors.svg';
import { ReactComponent as FormueLogoIcon } from '../../assets/icons/logo/formue-logo-icon.svg';
import { ReactComponent as FormueLogoIconColor } from '../../assets/icons/logo/formue-logo-icon-color.svg';

const LogoFull = (props) => {
  const { monochrome, ...rest } = props;
  return monochrome ? (
    <FormueLogoFull {...rest} />
  ) : (
    <FormueLogoFullColors {...rest} />
  );
};

const LogoIcon = (props) => {
  const { monochrome, ...rest } = props;
  return monochrome ? (
    <FormueLogoIcon {...rest} />
  ) : (
    <FormueLogoIconColor {...rest} />
  );
};

export const Logo = (props) => {
  const { size, ...rest } = props;

  switch (size) {
    case 'small':
      return <LogoIcon {...rest} />;
    default:
      return <LogoFull {...rest} />;
  }
};
