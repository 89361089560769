import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };

const defaultState = {
  activePeriod: 'TWELVEMONTHS',
  socialResponsibilityActiveSlide: 0,
};

export const reducer = handleActions(
  {
    [actions.setActivePeriod]: (state, { payload }) => ({
      ...state,
      activePeriod: payload,
    }),
    [actions.setSocialResponsibilityActiveSlide]: (state, { payload }) => ({
      ...state,
      socialResponsibilityActiveSlide: payload,
    }),
  },
  defaultState
);
