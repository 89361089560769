import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import camelcase from 'camelcase';
import { useSelector } from 'react-redux';

import {
  formatMoney,
  localizeCountryName,
  percentage,
  selectors,
  t,
} from '@formue-app/core';

import { H1, H4, H2, ParagraphXSmall, Paragraph } from '../../texts';

import { accent } from '../../../constants/colors';
import { SPACING_24, SPACING_16, SPACING_8 } from '../../../constants/spacing';
import { BaseSection } from '../../common/BaseSection';
import { PortfolioCharacteristicsItemPieChart } from '../../presentation/investmentStrategyComponents/PortfolioCharacteristicsItemPieChart';
import { SpriteIcon } from '../../common/SpriteIcon';
import { StretchedHorizontalBarChart } from '../../graphs/StretchedHorizontalBarChart';
import { titleFontStack } from '../../../fonts';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${SPACING_24};
`;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_16};
  flex: 1 0 calc(50% - ${SPACING_24});
  display: flex;
  flex-direction: column;
  border: 1px solid ${accent.sand1};
  max-width: calc(50% - ${SPACING_24} / 2);
`;

const Header = styled.div`
  display: flex;
  gap: ${SPACING_8};
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  padding: ${SPACING_16};
  flex: 1;
`;

const TotalCarbonFootprint = styled.div`
  display: flex;
  gap: ${SPACING_8};
  align-items: baseline;
`;

const {
  auth: { operatingCountrySelector },
} = selectors;

export const PreferencesKeyFigures = (props) => {
  const { portfolioCharacteristics, strategy } = props;

  const formuePortfolio =
    strategy && strategy.externalPortfolio
      ? strategy.totalPortfolio - strategy.externalPortfolio
      : strategy?.totalPortfolio;

  const operatingCountry = useSelector(operatingCountrySelector);

  return (
    <Wrapper {...props}>
      <StyledBaseSection>
        <Header>
          <SpriteIcon id="Investment Preferences" size={21} />
          <H4>
            {t('advisor:strategySimulator:preferences:portfolioLiquidity')}
          </H4>
        </Header>
        <Content>
          <PortfolioCharacteristicsItemPieChart
            data={portfolioCharacteristics.liquidity.map((item) => ({
              ...item,
              group: t(
                `advisor:strategySimulator:preferences:${camelcase(item.group)}`
              ),
            }))}
          />
        </Content>
      </StyledBaseSection>

      <StyledBaseSection>
        <Header>
          <SpriteIcon id="Asset Management" size={21} />
          <H4>{t('advisor:strategySimulator:preferences:indexExlution')}</H4>
        </Header>
        <Content>
          <PortfolioCharacteristicsItemPieChart
            data={portfolioCharacteristics.management.map((item) => ({
              ...item,
              group: t(
                `advisor:strategySimulator:preferences:${camelcase(item.group)}`
              ),
            }))}
          />
        </Content>
      </StyledBaseSection>

      <StyledBaseSection>
        <Header>
          <SpriteIcon id="Shares" size={21} />
          <H4>
            {t('advisor:strategySimulator:preferences:totalExpenseRatio')}
          </H4>
        </Header>
        <Content style={{ justifyContent: 'center' }}>
          <H1>{percentage(portfolioCharacteristics.expenseRatio * 100, 2)}</H1>
          <Paragraph>
            {formatMoney(
              formuePortfolio * portfolioCharacteristics.expenseRatio,
              'KR'
            )}
          </Paragraph>
        </Content>
      </StyledBaseSection>

      <StyledBaseSection>
        <Header style={{ marginBottom: SPACING_16 }}>
          <SpriteIcon id="Sustainability" size={21} />
          <H4>{t('advisor:strategySimulator:preferences:carbonFootprint')}</H4>
        </Header>
        <Content style={{ padding: 0 }}>
          <TotalCarbonFootprint>
            <ParagraphXSmall>
              <Trans
                i18nKey="advisor:strategySimulator:preferences:tonco2"
                values={{
                  carbonFootprint: parseInt(
                    portfolioCharacteristics.sustainability.carbonFootprint
                  ),
                }}
                components={{
                  strong: (
                    <H2
                      as="span"
                      style={{
                        fontFamily: titleFontStack,
                        fontWeight: 700,
                        display: 'inline',
                      }}
                    />
                  ),
                }}
              />
            </ParagraphXSmall>
          </TotalCarbonFootprint>
          <StretchedHorizontalBarChart
            data={[
              {
                label: t('advisor:strategySimulator:preferences:thisPortfolio'),
                value: parseInt(
                  portfolioCharacteristics.sustainability.carbonFootprint
                ),
              },
              {
                label: t(
                  'advisor:strategySimulator:preferences:localization:country',
                  {
                    context: operatingCountry,
                  }
                ),
                value: parseInt(
                  portfolioCharacteristics.sustainability.domesticBenchmark
                ),
              },
              {
                label: t('advisor:strategySimulator:preferences:world'),
                value: parseInt(
                  portfolioCharacteristics.sustainability.globalBenchmark
                ),
              },
            ]}
          />
          <ParagraphXSmall>
            {t('advisor:strategySimulator:preferences:coverage', {
              coverage: percentage(
                portfolioCharacteristics.sustainability.coverage * 100
              ),
            })}
          </ParagraphXSmall>
        </Content>
      </StyledBaseSection>

      <StyledBaseSection>
        <Header style={{ marginBottom: SPACING_16 }}>
          <SpriteIcon id="Position" size={21} />
          <H4>{t('advisor:strategySimulator:preferences:geography')}</H4>
        </Header>
        <Content style={{ padding: 0 }}>
          <StretchedHorizontalBarChart
            data={portfolioCharacteristics.geography.allocations
              .sort((a, b) => b.allocation - a.allocation)
              .slice(0, 5)
              .filter((item) => item.countryCode)
              .map((item) => ({
                label: localizeCountryName(item?.countryCode?.toUpperCase()),
                value: item.allocation,
              }))}
            formatValue={(value) => percentage(value * 100, 1)}
          />
          <ParagraphXSmall>
            {t('advisor:strategySimulator:preferences:coverage', {
              coverage: percentage(
                portfolioCharacteristics.geography.coverage * 100
              ),
            })}
          </ParagraphXSmall>
        </Content>
      </StyledBaseSection>

      <StyledBaseSection>
        <Header style={{ marginBottom: SPACING_16 }}>
          <SpriteIcon id="Company" size={21} />
          <H4>{t('advisor:strategySimulator:preferences:sectors')}</H4>
        </Header>
        <Content style={{ padding: 0 }}>
          <StretchedHorizontalBarChart
            data={portfolioCharacteristics.sector.allocations
              .sort((a, b) => b.allocation - a.allocation)
              .slice(0, 5)
              .filter((item) => item.sector)
              .map((item) => ({
                label: t(`sectors:${camelcase(item.sector)}:title`),
                value: item.allocation,
              }))}
            formatValue={(value) => percentage(value * 100, 1)}
          />

          <ParagraphXSmall>
            {t('advisor:strategySimulator:preferences:coverage', {
              coverage: percentage(
                portfolioCharacteristics.sector.coverage * 100
              ),
            })}
          </ParagraphXSmall>
        </Content>
      </StyledBaseSection>
    </Wrapper>
  );
};
