import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { accent, backgroundWhite } from '../../constants/colors';
import { actions as podcastActions } from '../../store/ui/podcast';
import {
  PlayIcon,
  PauseIcon,
  FastForwardIcon,
  RewindIcon,
  SkipNextIcon,
  SkipBackIcon,
} from './PodcastPlayerControlIcons';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPlayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
`;

const StyledButton = styled.button`
  border: none;
  outline: none;
  text-align: left;
  width: 100%;
  cursor: pointer;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  width: 44px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &:hover {
    opacity: 0.85;
  }
`;

export const PodcastPlayerControls = ({
  colorPrimary = backgroundWhite,
  colorSecondary = accent.ocean450,
  playButtonBackground = accent.ocean4,
}) => {
  const dispatch = useDispatch();
  const podcastUi = useSelector((state) => state.ui.podcast);
  const { isPlaying, intervalRef, audioRef, nextEpisodeId, previousEpisodeId } =
    podcastUi;

  if (!audioRef) return null;

  const { duration, buffered } = audioRef.current;

  const changeProgress = (value) => {
    clearInterval(intervalRef.current);
    const targetedValue = audioRef.current.currentTime + value;

    if (targetedValue <= buffered.end(0)) {
      audioRef.current.currentTime = targetedValue;
    } else {
      audioRef.current.currentTime = buffered.end(0);
    }
    dispatch(
      podcastActions.setTrackProgress(
        audioRef.current.currentTime,
        buffered,
        duration
      )
    );
  };

  const controlsCallback = (action) => {
    switch (action) {
      case 'play':
        dispatch(podcastActions.setIsPlaying(true));
        break;
      case 'pause':
        dispatch(podcastActions.setIsPlaying(false));
        break;
      case 'rewind':
        changeProgress(-10);
        break;
      case 'fastForward':
        changeProgress(10);
        break;
      case 'skipNext':
        dispatch(podcastActions.setEpisodeId(nextEpisodeId));
        dispatch(podcastActions.setIsPlaying(true));
        break;
      case 'skipBack':
        dispatch(podcastActions.setEpisodeId(previousEpisodeId));
        dispatch(podcastActions.setIsPlaying(true));
        break;
      default:
        break;
    }
  };

  return (
    <StyledWrapper>
      <StyledButton
        style={{ width: 24 }}
        onClick={() => {
          controlsCallback('skipBack');
        }}
      >
        <SkipBackIcon color={colorSecondary} />
      </StyledButton>
      <StyledButton>
        <RewindIcon
          color={colorSecondary}
          onClick={() => {
            controlsCallback('rewind');
          }}
        />
      </StyledButton>
      {isPlaying ? (
        <StyledButton
          onClick={() => {
            controlsCallback('pause');
          }}
        >
          <StyledPlayButton backgroundColor={playButtonBackground}>
            <PauseIcon color={colorPrimary} />
          </StyledPlayButton>
        </StyledButton>
      ) : (
        <StyledButton
          onClick={() => {
            controlsCallback('play');
          }}
        >
          <StyledPlayButton backgroundColor={playButtonBackground}>
            <PlayIcon
              color={colorPrimary}
              style={{ left: 1, bottom: 1, position: 'relative' }}
            />
          </StyledPlayButton>
        </StyledButton>
      )}
      <StyledButton
        onClick={() => {
          controlsCallback('fastForward');
        }}
      >
        <FastForwardIcon color={colorSecondary} />
      </StyledButton>
      <StyledButton
        style={{ width: 24 }}
        onClick={() => {
          controlsCallback('skipNext');
        }}
      >
        <SkipNextIcon color={colorSecondary} />
      </StyledButton>
    </StyledWrapper>
  );
};
