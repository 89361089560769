import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { t } from '@formue-app/core';

import {
  accent,
  backgroundMain,
  backgroundWhite,
  whiteTranslucent,
} from '../../../constants/colors';
import {
  SPACING_32,
  SPACING_16,
  SPACING_48,
  SPACING_4,
} from '../../../constants/spacing';
import {
  DesktopWrapper,
  MobileWrapper,
  mobileCondition,
} from '../../../constants/media';

import { Logo } from '../../common/Logo';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';
import { ButtonSmall } from '../../buttons';

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  background: ${(props) => whiteTranslucent(props.scrollPosition)};
  width: 100%;
  z-index: 10;

  @media ${mobileCondition} {
    background: ${backgroundMain};
  }
`;

const LogoWrapper = styled.div`
  padding: ${SPACING_16} ${SPACING_32};
`;

const Indicator = styled.div`
  height: 3px;
  width: 100%;
`;

const IndicatorInner = styled.div`
  height: 100%;
  width: ${(props) => 100 * props.scrollPosition}%;
  background: ${accent.ocean4};
`;

const StyledButtonSmall = styled(ButtonSmall)`
  position: absolute;
  left: ${SPACING_48};
  top: ${SPACING_48};
  color: ${accent.ocean4};
  display: flex;
  align-items: center;
  padding-left: 11px;
`;

export const ReportHeader = (props) => {
  const { wrapperElement = window } = props;

  let history = useHistory();

  const [headerScrollPosition, setHeaderScrollPosition] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (wrapperElement) {
      wrapperElement.addEventListener('scroll', () => {
        const scrollHeight =
          wrapperElement.scrollHeight - wrapperElement.offsetHeight;

        // Fade header from 1 to minimum 0.9
        setHeaderScrollPosition(
          1 - Math.min(wrapperElement.scrollTop / 300, 0.1)
        );
        setScrollPosition(wrapperElement.scrollTop / scrollHeight);
      });
    }
  }, [wrapperElement]);

  return (
    <>
      <MobileWrapper>
        <Header scrollPosition={headerScrollPosition}>
          <LogoWrapper>
            <Logo height="16" style={{ display: 'block' }} />
          </LogoWrapper>
          <Indicator>
            <IndicatorInner scrollPosition={scrollPosition} />
          </Indicator>
        </Header>
      </MobileWrapper>

      <DesktopWrapper>
        <StyledButtonSmall
          buttoncolor={backgroundWhite}
          buttoncolorhover={backgroundWhite}
          buttoncoloractive={backgroundWhite}
          onClick={() => history.push('/')}
        >
          <SpriteIconFunctional
            id="Simple arrow"
            direction="Left"
            size={14}
            style={{ marginTop: 1, marginRight: SPACING_4 }}
          />
          {t('globals:return')}
        </StyledButtonSmall>
      </DesktopWrapper>
    </>
  );
};
