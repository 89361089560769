import * as user from './user';
import * as expertInput from './expertInput';
import * as meeting from './meeting';
import * as presentation from './presentation';
import * as readStatus from './readStatus';
import * as recommendedDistribution from './recommendedDistribution';
import * as simulator from './simulator';
import * as signingEvents from './signingEvents';
import * as impersonation from './impersonation';
import * as tasks from './tasks';
import * as todo from './todo';
import * as portfolio from './portfolio';
import * as feed from './feed';
import * as qlik from './qlik';
import * as sustainability from './sustainability';
import * as flashMessages from './flashMessages';

export const actions = {
  user: user.actions,
  expertInput: expertInput.actions,
  meeting: meeting.actions,
  presentation: presentation.actions,
  recommendedDistribution: recommendedDistribution.actions,
  simulator: simulator.actions,
  readStatus: readStatus.actions,
  signingEvents: signingEvents.actions,
  impersonation: impersonation.actions,
  tasks: tasks.actions,
  todo: todo.actions,
  portfolio: portfolio.actions,
  feed: feed.actions,
  qlik: qlik.actions,
  sustainability: sustainability.actions,
  flashMessages: flashMessages.actions,
};
