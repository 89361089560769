import React from 'react';
import styled from 'styled-components';
import { accent, backgroundWhite } from '../../../constants/colors';
import { H6Size } from '../../../constants/text';
import { H6 } from '../../texts';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${accent.ocean4};
  border-radius: 9px;
  width: 18px;
  height: 18px;
`;

const Number = styled(H6)`
  line-height: ${H6Size};
  color: ${backgroundWhite};
  letter-spacing: 0;
  position: relative;
  bottom: 0.5px;
`;

export const CounterNumber = ({ number, ...rest }) => {
  if (number < 1) return null;
  return (
    <Container {...rest}>
      <Number>{number}</Number>
    </Container>
  );
};
