import { times, sumBy } from 'lodash';
import { addMonths, isSameYearAndMonth } from './dates';

/**
 * Calculate the capital movements during a specified period. It will
 * summarise the money coming in, and the money going out during the period
 * and return the sum of all the movements.
 */
const calculateCapitalInAndOut = (
  period,
  activeEvents,
  singleEvents,
  yearlyEvents
) => {
  const activeEventIds = activeEvents.flatMap((event) => {
    // Because we introduced grouped events in the filters, we need to check
    // if we have a list of ID's on the event, or just a single one. Grouped
    // single events have a list of ids, but yearly events do not.
    const idsToCompare = event.ids?.length ? event.ids : [event.id];
    return idsToCompare;
  });

  // Calculate the capital in and out number for the single events
  const filteredSingleEvents = singleEvents.filter((event) => {
    return activeEventIds.includes(event.id);
  });
  const singleEventsValue = sumBy(filteredSingleEvents, 'value');

  // Add any yearly events to the value as well
  const filteredYearlyEvents = yearlyEvents.filter((event) =>
    activeEventIds.includes(event.id)
  );
  const numberOfYears = period / 12;
  const yearlyEventsValue =
    sumBy(filteredYearlyEvents, 'value') * numberOfYears;

  return singleEventsValue + yearlyEventsValue;
};

export const useForecastData = (
  singleEvents,
  yearlyEvents,
  totalPortfolio,
  period, // months
  expectedYearlyReturn = 0.075,
  yearlyReturnAdjustment = 0,
  events = [],
  displayEventLabels
) => {
  if (!totalPortfolio) {
    return { data: [], totalReturnValue: 0 };
  }

  let storedValue = totalPortfolio;
  let storedReturn = 0;

  // Since we are going to create a time series of forecasted values on a "per month" basis,
  // we need "monthly return & adjustment" in order to calculate monthly value correctly.
  const expectedMonthlyReturn = expectedYearlyReturn / 12;
  const monthlyReturnAdjustment = yearlyReturnAdjustment / 12;

  // Create time series of dates with incrementing months.
  const now = new Date();
  const timeSeries = times(period, (index) => {
    return addMonths(now, index);
  });

  // Sum all yearly events - but make sure to exclude any that is disabled by
  // the filters
  const activeYearlyEvents = yearlyEvents.filter((event) => {
    // If yearly event is present in event list passed in as props
    // it is active and should be part of the calculations, if it's not
    // there it's filtered out and we should not include it.
    const activeEventIds = events.map((event) => event.id);
    return activeEventIds.includes(event.id);
  });
  const yearlyEventsSum = sumBy(activeYearlyEvents, 'value');

  const data = timeSeries.map((date, index) => {
    // On each January, get the yearly cash flow events (if there are any)
    let cashflowEventsSum = date.getMonth() === 11 ? yearlyEventsSum : 0;
    let eventLabels = [];

    const monthlyEvents = singleEvents.filter((event) =>
      isSameYearAndMonth(new Date(event.transactedAt), date)
    );

    // Iterate through the monthly events and include them in a total sum
    // only if they were specified in the "events" array. The events array holds
    // the list of events we want to show, e.g not filtered out using the forecast
    // filters.
    monthlyEvents.forEach((monthlyEvent) => {
      if (
        events.some((event) => {
          // Because we introduced grouped events in the filters, we need to check
          // if we have a list of ID's on the event, or just a single one. Grouped
          // single events have a list of ids, but yearly events do not.
          const idsToCompare = event.ids?.length ? event.ids : [event.id];
          return idsToCompare.includes(monthlyEvent.id);
        })
      ) {
        // Add the label/point in a dataset if needed
        if (displayEventLabels) {
          eventLabels.push(monthlyEvent);
        }
        cashflowEventsSum += monthlyEvent.value;
      }
    });

    // Calculations from excel document created by Fredrik Skjetne
    let newValue =
      storedValue * (1 + expectedMonthlyReturn - monthlyReturnAdjustment) +
      cashflowEventsSum;
    let newReturn = newValue - storedValue - cashflowEventsSum;
    // Skip calculations for first entry as its the current month
    // and should reflect the initial input
    if (index === 0) {
      newValue = storedValue;
      newReturn = storedReturn;
    }
    storedValue = newValue;
    storedReturn = newReturn;

    return {
      value: newValue,
      return: newReturn,
      date: date,
      eventLabels,
    };
  });

  const totalReturnValue = data.reduce((acc, val) => acc + val.return, 0);
  const capitalInAndOut = calculateCapitalInAndOut(
    period,
    events,
    singleEvents,
    yearlyEvents
  );

  return { data, totalReturnValue, capitalInAndOut };
};
