import React from 'react';
import styled from 'styled-components';

import {
  notDesktopCondition,
  NotMobileWrapper,
  MobileWrapper,
} from '../../constants/media';
import { accent, blackTranslucent } from '../../constants/colors';
import { SPACING_16 } from '../../constants/spacing';

import { StyledBaseSection } from './BaseSection';
import { ProfileIcon } from './ProfileIcon';

// Lift image to upper part of section, centered
const StyledProfileIcon = styled(ProfileIcon)`
  margin: 0 auto;
  margin-top: -60px;
  box-shadow: 0 0 5px 1px ${blackTranslucent(0.12)};
  background-color: ${accent.ocean2};

  p {
    color: ${accent.ocean1};
  }

  @media ${notDesktopCondition} {
    margin: 0;
  }
`;

// Center text in section body, and make space on top of section for profile icon
const CenteredBaseSection = styled(StyledBaseSection)`
  text-align: center;
  margin-top: 37px;
  word-break: break-word;

  @media ${notDesktopCondition} {
    align-items: center;
    display: flex;
    gap: ${SPACING_16};
    flex-direction: row;
    grid-column: span 12;
    margin-top: 0px;
    justify-content: space-between;
    min-height: 75px;
    height: 100%;
  }
`;

export const ProfileIconSection = (props) => {
  const { iconName, iconUrl, iconSize, iconImage, color, children } = props;

  return (
    <CenteredBaseSection as="article" {...props}>
      <NotMobileWrapper>
        <StyledProfileIcon
          name={iconName}
          url={iconUrl}
          size={iconSize}
          image={iconImage}
          color={color}
        />
      </NotMobileWrapper>
      <MobileWrapper>
        <StyledProfileIcon
          name={iconName}
          url={iconUrl}
          size={40}
          image={iconImage}
          color={color}
        />
      </MobileWrapper>
      {children}
    </CenteredBaseSection>
  );
};
