import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { investmentStrategyImplementationPlans } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer(
  'investment-strategy-implementation-plans'
);

// Create the sagas for this entity
function* readInvestmentStrategyImplementationPlans() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(
        investmentStrategyImplementationPlans.get,
        args
      );

      const normalized = normalize(result);

      yield put(actions.indexRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: { error, args } });
    }
  });
}

function* updateInvestmentStrategyImplementationPlans() {
  yield takeEvery(actions.updateRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(
        investmentStrategyImplementationPlans.patch,
        args
      );
      const normalized = normalize(result);
      yield put(actions.createRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.createRequestFailure({ error }));

      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [
  readInvestmentStrategyImplementationPlans,
  updateInvestmentStrategyImplementationPlans,
  ...resourceSagas,
];
