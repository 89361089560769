import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { users } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the clients resource
export const [actions, reducer, resourceSagas] = resourceReducer('users');

function* readUser() {
  yield takeEvery(actions.showRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(users.v2.get, args);
      const normalized = normalize(result);
      yield put(actions.showRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.showRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

function* updateUser() {
  yield takeEvery(actions.updateRequestBegin, function* ({ payload }) {
    const { args, data } = payload;
    try {
      const { body } = yield call(users.v2.update, args, data);
      const normalized = normalize(body);
      yield put(actions.updateRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.updateRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readUser, updateUser, ...resourceSagas];
