import React from 'react';

import { SPACING_24 } from '../../constants/spacing';
import { ButtonSmall } from '../buttons';
import { H3, Paragraph } from '../texts';
import { BaseSection } from './BaseSection';

export const InfoCard = (props) => {
  const { columns = 6, title, body, to, navLabel, disabled, ...rest } = props;

  return (
    <BaseSection {...rest} columns={columns}>
      <H3 style={{ marginBottom: 12 }}>{title}</H3>
      <Paragraph>{body}</Paragraph>
      {to || navLabel ? (
        <ButtonSmall
          style={{ display: 'inline-block', marginTop: SPACING_24 }}
          to={to}
          disabled={disabled}
        >
          {navLabel}
        </ButtonSmall>
      ) : null}
    </BaseSection>
  );
};
