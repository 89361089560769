import { createSelector } from 'reselect';

/**
 * Create a selector that filters a list of entities on a given field
 * and value.
 *
 * @param {*} entitySelector A selector for a entity
 * @param {*} status What we want to filter by, accepts a string or list of strings
 * @param {*} negative Should we do a negative filter or not
 * @param {*} field The field we want to use when filtering
 */
export const makeFilterSelector = (
  entitySelector,
  status,
  negative = false,
  field = 'status'
) => {
  // Make sure we are always working on a list of statuses
  if (!Array.isArray(status)) {
    status = [status.toLowerCase()];
  } else {
    status = status.map((s) => s.toLowerCase());
  }

  return createSelector(entitySelector, (items) =>
    Object.keys(items)
      .filter((id) => {
        // If we should do a negative filter or not
        if (negative) {
          return !status.includes(items[id][field].toLowerCase());
        } else {
          return status.includes(items[id][field].toLowerCase());
        }
      })
      .map((id) => items[id])
  );
};

export const uniqByWithException = (items, key, exception = false) => {
  let seen = {};
  return items.filter((item) => {
    // Skip undefined items
    if (typeof item === 'undefined') {
      return;
    }

    const k = item[key];
    return seen.hasOwnProperty(k) && k !== exception ? false : (seen[k] = true);
  });
};
