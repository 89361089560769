import React from 'react';
import styled from 'styled-components';

import { useErrorState, useResource, t } from '@formue-app/core';

import { PortfolioHoldingsChart } from '../../portfolio/charts/PortfolioHoldingsChart';
import { ChartLegend } from '../slideComponents/portfolio/ChartLegend';
import { ReturnContributionPeriodFilter } from '../slideComponents/portfolio/ReturnContributionPeriodFilter';
import { PortfolioActivityIndicator } from '../../portfolio/overview/PortfolioActivityIndicator';
import { accent } from '../../../constants/colors';
import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';

const Base = styled.div`
  flex: 1;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-bottom: 100px;
  align-items: center;
  justify-content: center;
`;

const HoldingsChartWrapper = styled.div`
  flex: 1;
  padding: 100px 10px 0 10px;
  height: 100%;
`;

const LoadingIndicatorWrapper = styled.div`
  margin-top: 300px;
`;

export const ReturnContributionSlide = (props) => {
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const error = useErrorState(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);

  if (error) {
    return <SlideErrorMessage />;
  }

  return (
    <Base>
      <ChartWrapper style={{ position: 'relative', height: '100%' }}>
        <ChartLegend
          textColor="#83769b"
          style={{ position: 'absolute', left: 100, top: 50, zIndex: 1 }}
          isSelectable={false}
          items={[
            {
              label: t('assetClasses:stability'),
              color: accent.velvet1,
              key: 'S',
            },
            {
              label: t('assetClasses:marketGrowth'),
              color: accent.velvet2,
              key: 'MV',
            },
            {
              label: t('assetClasses:alternativeGrowth'),
              color: accent.velvet3,
              key: 'AV',
            },
          ]}
        />
        <ReturnContributionPeriodFilter
          style={{ position: 'absolute', right: 100, top: 50, zIndex: 1 }}
          excludedPeriods={['THISMONTH']}
        />
        {loading ? (
          <LoadingIndicatorWrapper>
            <PortfolioActivityIndicator loadingColor={accent.velvet2} />
          </LoadingIndicatorWrapper>
        ) : (
          <HoldingsChartWrapper>
            <PortfolioHoldingsChart
              fontSize={4}
              height={160}
              showTooltip={false}
              style={{ flex: 1 }}
            />
          </HoldingsChartWrapper>
        )}
      </ChartWrapper>
    </Base>
  );
};
