import { AsyncStorage } from '../AsyncStorage';
import { fallback, supportedLanguages } from './config'

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async callback => {
    let language = await AsyncStorage.getItem('language');

    if (language === 'se') {
      language = 'sv';
    }

    if (!language) {
      // Fallback to browser settings preferred langauge
      language = navigator.language.split(/\-|\_/)[0];
    }

    // Check if language is one of our supported languages, if not
    // return the designated fallback language
    if (!supportedLanguages.includes(language)) {
      language = fallback;
    }

    callback(language);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export default languageDetector;
