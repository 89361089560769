import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { actions, formatPeriodLabel } from '@formue-app/core';

import { accent, backgroundWhite } from '../../../constants/colors';
import { SPACING_16 } from '../../../constants/spacing';

import { Paragraph } from '../../texts';
import { BaseSection } from '../../common/BaseSection';
import { easeInOut, longAnimationTime } from '../../../constants/animation';
import { PeriodFilterModal } from './PeriodFilterModal';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  background: ${accent.sand150};
  cursor: pointer;
  padding-inline: ${SPACING_16};
  transition: background ${easeInOut} ${longAnimationTime};
  border-radius: ${SPACING_16};

  &:hover {
    background: ${backgroundWhite};
  }
`;

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

export const PeriodFilter = (props) => {
  const { title, ...rest } = props;
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const { period, startDate, endDate } = useSelector(
    (state) => state.ui.portfolio.filters
  );

  const onChange = (data) => {
    const [period, startDate, endDate] = data;

    dispatch(
      uiPortfolioActions.filterPeriod({
        period: period,
        startDate: startDate?.toISOString().split('T')[0],
        endDate: endDate?.toISOString().split('T')[0],
      })
    );
  };

  return (
    <>
      <StyledBaseSection onClick={() => setModalOpen(true)} {...rest}>
        <Paragraph style={{ marginRight: 'auto' }}>{title}</Paragraph>
        <Paragraph style={{ fontWeight: 500, marginTop: 0 }}>
          {formatPeriodLabel(period, startDate, endDate)}
        </Paragraph>
      </StyledBaseSection>
      <PeriodFilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onChange={onChange}
      />
    </>
  );
};
