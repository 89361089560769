import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t, useErrorState, useResource } from '@formue-app/core';

import {
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING_32OBILE,
  BOX_PADDING_HORIZONTAL,
  SPACING_48,
  SPACING_88,
} from '../../../constants/spacing';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { accent, textLightest } from '../../../constants/colors';
import { PortfolioReturnChart } from '../slideComponents/portfolio/PortfolioReturnChart';
import { PortfolioPeriodFilter } from '../../portfolio/filters/PortfolioPeriodFilter';
import { MultipleSwitch } from '../../formElements';
import { PortfolioGainPerPeriodChart } from '../slideComponents/portfolio/PortfolioGainPerPeriodChart';
import { PortfolioReturnTitle } from '../slideComponents/portfolio/PortfolioReturnTitle';
import { mobileCondition } from '../../../constants/media';
import { ReportErrorMessage } from '../reportComponent/ReportErrorMessage';

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
} = selectors;

const Wrapper = styled.div`
  padding: ${ANNUAL_REVIEW_REPORT_CONTENT_SPACING} ${SPACING_88};
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;

  @media ${mobileCondition} {
    padding: ${ANNUAL_REVIEW_REPORT_CONTENT_SPACING_32OBILE}
      ${BOX_PADDING_HORIZONTAL};
    padding-bottom: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
  min-width: 340px;

  @media ${mobileCondition} {
    max-width: 90%;
  }
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-top: ${SPACING_48};
`;

const GraphWrapper = styled.div`
  height: 465px;
  width: 100%;
  margin-top: 65px;
  margin-bottom: 65px;
`;

export const PortfolioReturnSection = (props) => {
  const { backgroundColor, title, ...rest } = props;

  const [visibleChart, setVisibleChart] = useState('graph');
  const portfolioTotal = useSelector(portfolioTotalSelector);
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const error = useErrorState(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const activePeriod = useSelector(
    (state) => state.ui.portfolio.filters.period
  );

  if (error) {
    return <ReportErrorMessage {...rest} />;
  }

  return (
    <>
      <Wrapper>
        <ReportTitle {...rest} />

        <HeaderWrapper>
          <PortfolioReturnTitle
            style={{ textAlign: 'center' }}
            gain={portfolioTotal?.gain}
            twr={portfolioTotal?.twr}
            period={activePeriod}
            {...rest}
          />

          <StyledMultipleSwitch
            backgroundColor={accent.ocean270}
            activeBackgroundColor={accent.ocean1}
            color={accent.ocean1}
            activeColor={textLightest}
            onChange={(value) => {
              setVisibleChart(value);
            }}
            options={[
              {
                label: t(
                  'presentation:annualReview:portfolio:performanceOverTime'
                ),
                value: 'graph',
              },
              {
                label: t('presentation:annualReview:portfolio:aggregated'),
                value: 'bar',
              },
            ]}
          />
        </HeaderWrapper>
      </Wrapper>
      <GraphWrapper>
        {visibleChart === 'graph' ? (
          <>
            <div style={{ height: 400 }}>
              <PortfolioReturnChart loading={loading} />
            </div>

            <PortfolioPeriodFilter
              style={{
                position: 'relative',
                zIndex: 2,
                borderColor: accent.ocean4,
                borderWidth: 1,
                borderStyle: 'solid',
              }}
              excludedPeriods={['THISMONTH']}
            />
          </>
        ) : (
          <PortfolioGainPerPeriodChart style={{ margin: '0 auto' }} />
        )}
      </GraphWrapper>
    </>
  );
};
