import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_64,
  SPACING_8,
} from '../../../constants/spacing';

import { titleFontStack } from '../../../fonts';
import { H1, H3, Paragraph } from '../../texts';
import { StrategyPicker } from '../../portfolio/strategy/StrategyPicker';
import { PortfolioConstructionPyramid } from '../../portfolio/strategy/PortfolioConstructionPyramid';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const InvestmentPrfileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TooltipWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 180px;
  max-width: 70%;
`;

export const InvestmentStrategyConstructionSlide = (props) => {
  const { isActiveSlide, titleColor, highlightColor } = props;

  const [activeItem, setActiveItem] = useState(null);

  return (
    <Wrapper>
      <InvestmentPrfileWrapper>
        <ContentWrapper style={{ width: '45%' }}>
          <H1 style={{ color: accent[titleColor] }}>
            <Trans
              i18nKey={t(
                'presentation:annualReview:portfolioConstruction:pageTitle'
              )}
              components={{
                strong: (
                  <span
                    style={{
                      color: accent[highlightColor],
                      fontWeight: 700,
                      fontFamily: titleFontStack,
                    }}
                  />
                ),
              }}
            />
          </H1>

          {activeItem ? (
            <TooltipWrapper>
              <H3 style={{ color: accent.velvet250, marginBottom: SPACING_8 }}>
                {t(
                  `presentation:annualReview:portfolioConstruction:${activeItem.translationKey}:title`
                )}
              </H3>
              <Paragraph style={{ color: accent.velvet2 }}>
                {t(
                  `presentation:annualReview:portfolioConstruction:${activeItem.translationKey}:ingress`
                )}
              </Paragraph>
            </TooltipWrapper>
          ) : null}
        </ContentWrapper>
        <ContentWrapper style={{ width: '55%' }}>
          <StrategyPicker
            style={{ marginBottom: SPACING_64 }}
            filterStrategies={false}
          />
          <PortfolioConstructionPyramid
            isActiveSlide={isActiveSlide}
            setActiveItem={setActiveItem}
            style={{ marginTop: 'auto', marginBottom: 180 }}
          />
        </ContentWrapper>
      </InvestmentPrfileWrapper>
    </Wrapper>
  );
};
