import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  VictoryGroup,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
} from 'victory';
import { min } from 'lodash';

import {
  selectors,
  t,
  formatPerformanceChartData,
  formatTwrTicks,
} from '@formue-app/core';
import { H4, H6, Paragraph, ParagraphXSmall } from '../../texts';
import { accent, backgroundWhite } from '../../../constants/colors';
import { BaseSection } from '../../common/BaseSection';
import { bodyFontStack } from '../../../fonts';
import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_16,
  SPACING_8,
  SPACING_20,
} from '../../../constants/spacing';
import { mobileCondition } from '../../../constants/media';
import { Modal } from '../../common/Modal';
import { SpriteIcon } from '../../common/SpriteIcon';

const {
  entities: {
    securityPerformance: {
      securityPerformanceBySymbolSelector,
      securityPerformanceBySecurityIdSelector,
    },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLegend = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLegendItem = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: ${SPACING_16};
`;

const StyledLegendDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${BORDER_RADIUS_LARGE};
  margin-right: ${SPACING_8};
`;

const StyledChartWrapper = styled.div`
  flex: 1;
`;

const StyledLabelsContainer = styled.div`
  display: flex;
  padding: 0 35px 15px 75px;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media ${mobileCondition} {
    padding: 0px 15px 15px 38px;
    bottom: -15px;
  }
`;

const StyledLabel = styled(ParagraphXSmall)`
  color: ${accent.ocean4};
  width: 27px;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: ${SPACING_8};
  background-color: ${accent.sand110};
`;

export const FundDevelopment = ({ referenceIndex, securityId, ...rest }) => {
  const securityPerformanceBySymbol = useSelector(
    securityPerformanceBySymbolSelector
  );

  const securityPerformanceBySecurityId = useSelector(
    securityPerformanceBySecurityIdSelector
  );

  const indexPerformance = securityPerformanceBySymbol[referenceIndex];
  const fundPerformance = securityPerformanceBySecurityId[securityId];

  const [disclaimerOpen, setDisclaimerOpen] = useState(false);

  if (!indexPerformance || !fundPerformance)
    return (
      <StyledBaseSection columns={5} {...rest}>
        <H4>{t('portfolio:funds:performanceHistory')}</H4>
        <NoDataContainer>
          <H6>{t('globals:noData')}</H6>
        </NoDataContainer>
      </StyledBaseSection>
    );

  // There could be a case where index or fund has fewer data points (years) in a time-series.
  // We want to exclude the points for which we don't have another to compare to.
  // Example: fund: [2019, 2020, 2021, 2022]; index: [2020, 2021, 2022]
  // We want to filter out 2019 from the fund time-series since there is no 2019 in index time-series data.
  const performanceDataLength = min([
    indexPerformance.length,
    fundPerformance.length,
  ]);

  const indexPerformanceData = formatPerformanceChartData(
    indexPerformance,
    performanceDataLength
  );
  const fundPerformanceData = formatPerformanceChartData(
    fundPerformance,
    performanceDataLength
  );

  return (
    <StyledBaseSection columns={5} {...rest}>
      <StyledHeader>
        <StyledTitleWrapper>
          <H4>{t('portfolio:funds:performanceHistory')}</H4>
          <SpriteIcon
            id="Info"
            size={SPACING_20}
            style={{ marginLeft: SPACING_8, cursor: 'pointer' }}
            onClick={() => {
              setDisclaimerOpen(true);
            }}
          />
        </StyledTitleWrapper>
        <StyledLegend>
          <StyledLegendItem>
            <StyledLegendDot style={{ backgroundColor: accent.ocean1 }} />
            <H6>{t('portfolio:funds:fund')}</H6>
          </StyledLegendItem>
          <StyledLegendItem>
            <StyledLegendDot style={{ backgroundColor: accent.ocean2 }} />
            <H6>{t('portfolio:funds:index')}</H6>
          </StyledLegendItem>
        </StyledLegend>
      </StyledHeader>
      <StyledContainer>
        <StyledChartWrapper>
          <VictoryChart
            padding={{ left: 5, top: 20, right: 0, bottom: 30 }}
            domainPadding={{ x: [65, 40], y: 0 }}
          >
            <VictoryAxis
              animate={{
                duration: 0,
              }}
              crossAxis={false}
              dependentAxis
              style={{
                axis: { stroke: 'transparent' },
                grid: { stroke: '#CCDDEF' },
                tickLabels: {
                  fontSize: 10,
                  fontWeight: 300,
                  fill: accent.ocean4,
                  fontFamily: bodyFontStack,
                  textAnchor: 'start',
                },
              }}
              tickLabelComponent={
                <VictoryLabel
                  backgroundStyle={{ fill: backgroundWhite }}
                  // Horizontal offset
                  dx={20}
                  // Adds padding for the white background
                  backgroundPadding={{
                    left: 25,
                    right: -20,
                    top: 2,
                    bottom: 2,
                  }}
                  textAnchor="start"
                  verticalAnchor="middle"
                  // Fine tuning vertical offset
                  dy={-2}
                />
              }
              tickCount={8}
              tickFormat={(tick) => formatTwrTicks(tick)}
            />

            <VictoryGroup
              colorScale={[accent.ocean1, accent.ocean2]}
              offset={26}
              style={{
                data: {
                  width: 25,
                },
              }}
            >
              <VictoryBar
                data={fundPerformanceData}
                x={(item) => item.year}
                y={(item) => item.performance}
                cornerRadius={{ topLeft: 4 }}
                width={30}
                barRatio={0.3}
              />
              <VictoryBar
                data={indexPerformanceData}
                x={(item) => item.year}
                y={(item) => item.performance}
                cornerRadius={{ topRight: 4 }}
                width={30}
                barRatio={0.3}
              />
            </VictoryGroup>
          </VictoryChart>
        </StyledChartWrapper>
        <StyledLabelsContainer>
          {indexPerformanceData.map((item) => (
            <StyledLabel key={item.id}>{item.year}</StyledLabel>
          ))}
        </StyledLabelsContainer>
      </StyledContainer>
      <Modal
        isOpen={disclaimerOpen}
        onRequestClose={() => setDisclaimerOpen(false)}
        contentLabel={t('portfolio:funds:performanceHistory')}
        padding={SPACING_48}
      >
        <Paragraph>
          {t('portfolio:funds:performanceHistoryDisclaimer')}
        </Paragraph>
      </Modal>
    </StyledBaseSection>
  );
};
