import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';

import { backgroundMain } from '../../constants/colors';
import { GridElement } from '../../components/layout';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { SPACING_48, SPACING_24 } from '../../constants/spacing';

import { PortfolioConstructionFooter } from '../../components/portfolio/lenses/construction/PortfolioConstructionFooter';
import { Modal } from '../../components/common/Modal';
import { CoverageReadMore } from '../../components/portfolio/lenses/CoverageReadMore';
import { MenuPage } from '../base/MenuPage';
import { TableList } from '../../components/lists';
import { H2, Paragraph } from '../../components/texts';
import { SearchInput } from '../../components/formElements';
import { useFundsListColumns } from '../../components/portfolio/lenses/construction/fundsListColumns';

const {
  entities: {
    securities: { allFunds, securitiesMetadataSelector },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

export const FundsListPage = (props) => {
  const loading = useResource(['SECURITIES/INDEX']);

  const metadata = useSelector(securitiesMetadataSelector);
  const { coverage = 0 } = metadata;

  const funds = useSelector(allFunds);

  const [modal, setModal] = useState('');
  const [serchTerm, setSearchTerm] = useState('');
  const filteredFunds = funds
    .map((item, index) => ({ ...item, index: index }))
    .filter(
      (item) => !!item.name.toLowerCase().includes(serchTerm.toLowerCase())
    );

  const columns = useFundsListColumns(filteredFunds);

  return (
    <MenuPage
      {...props}
      title={t('portfolio:title')}
      icon={'portfolio'}
      subpage={true}
    >
      {loading ? (
        <GridElement columns={12}>
          <StyledCenteredActivityIndicator
            columns={12}
            size={30}
            background={backgroundMain}
          />
        </GridElement>
      ) : (
        <>
          <GridElement columns={8}>
            <H2>{t('portfolioConstruction:fundsList:title')}</H2>
            <Paragraph>
              {t('portfolioConstruction:fundsList:ingress')}
            </Paragraph>
          </GridElement>
          <GridElement
            columns={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <SearchInput
              placeholder={t('portfolioConstruction:fundsList:search')}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </GridElement>
          {filteredFunds.length ? (
            <TableList
              style={{ marginTop: SPACING_24 }}
              id="fundsList"
              sortable={false}
              columns={columns}
              rows={filteredFunds}
              keyExtractor={(item, index) => `funds-${index}`}
            />
          ) : null}

          <PortfolioConstructionFooter
            coverage={coverage}
            onClick={() => {
              setModal('coverage');
            }}
          />

          <Modal
            isOpen={modal.length ? true : false}
            onRequestClose={() => setModal('')}
            contentLabel={t(`esg:${modal}ReadMore:title`)}
            padding={SPACING_48}
            labelComponent={H2}
          >
            {modal === 'coverage' && <CoverageReadMore />}
          </Modal>
        </>
      )}
    </MenuPage>
  );
};
