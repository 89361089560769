import { parseUrl, stringifyUrl } from 'query-string';

import { config } from '../../config';
import { t } from '../localization';
import {
  KNOWLEDGEBASE_TASK,
  NEUROPROFILER_TASK,
  ANNUAL_REVIEW_PREPERATION_TASK,
} from '../../constants/tasks';

/**
 * Helper method to get a translated string for a task property if we have one
 * for the task type, if not return value directly from task.
 *
 * @param {*} property - The property on a Task to get translated value from
 * @param {*} task - The task to get fallback value from
 */
const getTaskProperty = (property, task) => {
  const taskType = task.taskType || '';

  switch (taskType.toLowerCase()) {
    case NEUROPROFILER_TASK: {
      return t(`todoItem:taskTypes:neuroprofiler:${property}`);
    }
    case KNOWLEDGEBASE_TASK: {
      return t(`todoItem:taskTypes:dataCollectionKnowledgeBase:${property}`);
    }
    case ANNUAL_REVIEW_PREPERATION_TASK: {
      return t(`todoItem:taskTypes:annualReviewPreparation:${property}`);
    }
    default: {
      // If we have a property on task with this name use that, if not
      // try to get a "global" translation value instead from the special task type
      if (task.hasOwnProperty(property)) {
        return task[property];
      }
      return t(`todoItem:${property}`);
    }
  }
};

export const getTaskTitle = (task) => getTaskProperty('title', task);
export const getTaskDescription = (task) =>
  getTaskProperty('description', task);
export const getTaskButtonOpenLinkLabel = (task) =>
  getTaskProperty('buttonOpenLinkLabel', task);

export const getTaskUrl = (task) => {
  const taskType = task.taskType || '';

  switch (taskType.toLowerCase()) {
    case KNOWLEDGEBASE_TASK: {
      if (!task.url) {
        return null;
      }
      const { domain, app, api } = config;
      const { host } = api;
      const { urlScheme, platform } = app;
      const callbackUrl = `${host}/integrations/knowledgebase/callback`;
      const externalId = task.id;
      const redirectUrl =
        platform === 'Web'
          ? `${urlScheme}://${domain}/todo`
          : `${urlScheme}://todo`;

      // Parse original url
      let parsedUrl;
      try {
        parsedUrl = parseUrl(task.url);
      } catch (_) {
        return null;
      }

      let { url, query } = parsedUrl;

      // Modify query params
      query = {
        ...query,
        callbackUrl,
        redirectUrl,
        externalId,
      };

      return stringifyUrl({ url, query });
    }
    case ANNUAL_REVIEW_PREPERATION_TASK: {
      const { domain, app } = config;
      const { urlScheme } = app;
      const url = `${urlScheme}://${domain}/report/annualReview`;
      return url;
    }
    default: {
      return task.url;
    }
  }
};
