import React from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { formatBigMoney, sortByAmount } from '@formue-app/core';

import { accent } from '../../../../constants/colors';
import { SPACING_24, SPACING_16 } from '../../../../constants/spacing';
import { easeInOut, longAnimationTime } from '../../../../constants/animation';
import { H4 } from '../../../texts';

const List = styled.ul`
  padding: 0;
  padding-inline: ${SPACING_24};
  margin: 0;
  width: 100%;
`;

const ListItem = styled.li`
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${(props) =>
    props.activeCategory ? 0.3 + 0.1 * props.index : 0}s;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: ${SPACING_16};
  padding: 0;
  opacity: ${(props) => (props.activeCategory ? 1 : 0)};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CategoryList = (props) => {
  const { dataset, category, active, textColor = accent.velvet270 } = props;

  if (!dataset[category]) return null;

  const categoryData = dataset[category];

  return (
    <>
      {categoryData.items && (
        <List>
          {categoryData.items
            .sort(sortByAmount)
            .slice(0, 3)
            .map((item, index) => (
              <ListItem
                index={index}
                activeCategory={active}
                key={`${camelcase(category)}-${index}`}
              >
                <H4
                  style={{
                    color: textColor,
                    maxWidth: '60%',
                  }}
                >
                  {item.name}
                </H4>
                <H4 style={{ color: textColor, alignSelf: 'center' }}>
                  {formatBigMoney(item.amount)}
                </H4>
              </ListItem>
            ))}
        </List>
      )}
    </>
  );
};
