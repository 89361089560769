import { apiFetch } from './utils/api';

export const offices = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v2/offices';
    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
