import React from 'react';
import styled from 'styled-components';

import { H1SizeMobile, H1LineHeightMobile } from '../../constants/text';

export const StyledH1Small = styled.h1`
  font-size: ${H1SizeMobile};
  line-height: ${H1LineHeightMobile};
`;

export const H1Small = (props) => {
  const { children } = props;

  return <StyledH1Small {...props}>{children}</StyledH1Small>;
};
