import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  t,
  formatBigMoney,
  percentage,
  useResource,
  selectors,
  formatTimeHorizionData,
  formatYearlyData,
  maxForYear,
} from '@formue-app/core';

import {
  BORDER_RADIUS_LARGE,
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';
import { accent, textMain } from '../../../constants/colors';

import { ProgressBar } from '../../graphs/ProgressBar';
import { H4, H6, ParagraphSmall } from '../../texts';
import { StyledCell, StyledRow, TableList } from '../../lists/TableList';
import { paragraphSmallSize } from '../../../constants/text';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { CashflowEventsChart } from '../../graphs/CashflowEventsChart';

import flexibleCashFlowBackground from '../../../assets/icons/icon-flexible-cash-flow-event.svg';
import { HorizontalDivider } from '../../common/HorizontalDivider';

const GraphWrapper = styled.div`
  background: ${accent.sand140};
  padding: ${SPACING_16} ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};

  margin-bottom: ${SPACING_32};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
`;

const TabMenu = styled.ul`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  margin-bottom: ${SPACING_24};
  list-style: none;
  gap: ${SPACING_16};
`;

const Tab = styled.li`
  border-bottom: 2px solid
    ${(props) => (props.active ? textMain : 'transparent')};
  border-radius: 2px;
  padding-bottom: 4px;
  cursor: pointer;
`;

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_8};

  &:first-child {
    padding-left: ${SPACING_16};
  }

  &:last-child {
    padding-right: ${SPACING_16};
  }

  span {
    font-size: ${paragraphSmallSize};
    font-weight: 400;
    text-align: left;
  }
`;

const StyledTableList = styled(TableList)`
  border-spacing: 0;
`;

const LightStyledRow = styled(StyledRow)`
  &:nth-child(odd) {
    background: ${accent.sand150};
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  .sub-bar {
    background: url(${flexibleCashFlowBackground});
    background-size: ${(props) => (props.bgSize ? props.bgSize : 12)}px;
  }
`;

const {
  entities: {
    cashFlowEvents: {
      formattedCashFlowEvents,
      allCashFlowEventsMetadataSelector,
      yearlyCashFlowEventsSelector,
      singleCashFlowEventsSelector,
    },
  },
} = selectors;

const Labels = (props) => {
  const { total, ...rest } = props;
  return (
    <LabelWrapper {...rest}>
      <StyledProgressBar
        value={1}
        subValueStart={0}
        subValueStop={1}
        height={8}
        style={{ width: 23 }}
      />
      <ParagraphSmall>
        {t('wealthPlan:investmentProfile:riskAndLockin:risk:flexible')}
      </ParagraphSmall>
      <ParagraphSmall style={{ marginLeft: 'auto', fontWeight: 500 }}>
        {t('wealthPlan:investmentProfile:riskAndLockin:risk:totalPortfolio')}:{' '}
        {formatBigMoney(total, false)}
      </ParagraphSmall>
    </LabelWrapper>
  );
};

const SummarySection = (props) => {
  const { formatedData, totalPortfolio } = props;

  return (
    <>
      {formatedData
        .map((group) => ({
          ...group,
          value: Math.abs(group.value),
          flexibleValue: Math.abs(group.flexibleValue),
        }))
        .map((group, index) => {
          const combinedValue = group.value + group.flexibleValue;
          const percentageOfTotal = combinedValue / totalPortfolio;
          return (
            <BarWrapper key={`bar-group-${index}`}>
              <ParagraphSmall style={{ fontWeight: 500, width: 100 }}>
                {t('wealthPlan:investmentProfile:riskAndLockin:yearsRange', {
                  range: group.range,
                })}
              </ParagraphSmall>
              <StyledProgressBar
                value={Math.min(percentageOfTotal, 1)}
                subValueStart={
                  Math.min(percentageOfTotal, 1) *
                  (1 - group.flexibleValue / combinedValue)
                }
                subValueStop={Math.min(percentageOfTotal, 1)}
                height={16}
                bgSize={24}
              />
              <ParagraphSmall style={{ width: 150, textAlign: 'right' }}>
                {formatBigMoney(combinedValue, false)} (
                {percentage(percentageOfTotal * 100)})
              </ParagraphSmall>
            </BarWrapper>
          );
        })}

      <Labels total={totalPortfolio} style={{ marginTop: SPACING_32 }} />
    </>
  );
};

export const CashflowDetailsSection = (props) => {
  const {
    yearlyFormatedData,
    totalPortfolio,
    max,
    barWidth,
    small = false,
    title,
  } = props;
  const yearlyCashFlowEvents = useSelector(yearlyCashFlowEventsSelector);
  const singleCashFlowEvents = useSelector(singleCashFlowEventsSelector);

  let columns = [
    {
      key: 'transactedAt',
      defaultSort: true,
      render: ({ transactedAt }) => (
        <ParagraphSmall style={{ fontWeight: 500 }}>
          {transactedAt ? new Date(transactedAt).getFullYear() : 'Yearly'}
        </ParagraphSmall>
      ),
      component: LightStyledCell,
    },
    {
      key: 'name',
      width: '60%',
      render: ({ name, flexible }) => (
        <>
          <ParagraphSmall>{name}</ParagraphSmall>
          {flexible ? (
            <StyledProgressBar
              value={1}
              subValueStart={0}
              subValueStop={1}
              height={8}
              style={{ width: 23, marginLeft: SPACING_8 }}
            />
          ) : null}
        </>
      ),
      component: LightStyledCell,
    },
    {
      key: 'value',
      width: '40%',
      component: LightStyledCell,
      render: ({ value }) => (
        <ParagraphSmall style={{ fontWeight: 500 }}>
          {formatBigMoney(value)}
        </ParagraphSmall>
      ),
      type: 'number',
    },
  ];

  return (
    <>
      <H6 style={{ textTransform: 'uppercase' }}>
        {title
          ? title
          : t(
              'wealthPlan:investmentProfile:riskAndLockin:risk:futureCapitalNeeds'
            )}
      </H6>

      <CashflowEventsChart
        data={yearlyFormatedData}
        max={max}
        style={{ marginTop: SPACING_32, marginBottom: SPACING_32 }}
        barWidth={barWidth}
        small={small}
      />

      <HorizontalDivider />

      <H6
        style={{
          textTransform: 'uppercase',
          marginTop: SPACING_16,
          marginBottom: SPACING_16,
        }}
      >
        {t('wealthPlan:investmentProfile:riskAndLockin:risk:details')}
      </H6>

      <StyledTableList
        id="holdingsList"
        sortable={false}
        showHeader={false}
        columns={columns}
        rows={[...yearlyCashFlowEvents, ...singleCashFlowEvents]}
        rowComponent={LightStyledRow}
        keyExtractor={(item, index) => `expenses-${index}`}
      />

      <Labels total={totalPortfolio} style={{ marginTop: SPACING_16 }} />
    </>
  );
};

export const RiskChart = (props) => {
  const loading = useResource(['CASH_FLOW_EVENTS/INDEX']);
  const cashFlowEventsMetaData = useSelector(allCashFlowEventsMetadataSelector);
  const cashFlowEventsData = useSelector(formattedCashFlowEvents);
  const [activeTab, setActiveTab] = useState('summary');

  const totalPortfolio = cashFlowEventsMetaData.totalPortfolio;
  const formatedData = formatTimeHorizionData(
    cashFlowEventsData,
    totalPortfolio
  );

  const yearlyFormatedData = formatYearlyData(cashFlowEventsData);
  const max = maxForYear(cashFlowEventsData);

  if (loading) {
    return <CenteredActivityIndicator />;
  }

  return (
    <GraphWrapper {...props}>
      <TabMenu>
        <Tab
          active={activeTab === 'summary'}
          onClick={() => setActiveTab('summary')}
        >
          <H4>
            {t('wealthPlan:investmentProfile:riskAndLockin:risk:summary')}
          </H4>
        </Tab>
        <Tab
          active={activeTab === 'details'}
          onClick={() => setActiveTab('details')}
        >
          <H4>
            {t('wealthPlan:investmentProfile:riskAndLockin:risk:details')}
          </H4>
        </Tab>
      </TabMenu>
      {activeTab === 'summary' ? (
        <SummarySection
          formatedData={formatedData}
          totalPortfolio={totalPortfolio}
        />
      ) : null}
      {activeTab === 'details' ? (
        <CashflowDetailsSection
          yearlyFormatedData={yearlyFormatedData}
          totalPortfolio={totalPortfolio}
          max={max}
        />
      ) : null}
    </GraphWrapper>
  );
};
