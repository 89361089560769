import React, { useState } from 'react';
import styled from 'styled-components';

import { useQlikFilterOptions } from '@formue-app/core';
import { CollapsibleFilters } from './CollapsibleFilters';
import { ExtendedReportingCheckbox } from './ExtendedReportingCheckbox';
import { SPACING_16, SPACING_8, SPACING_2 } from '../../../constants/spacing';
import { useEffect } from 'react';
import { SearchInput } from '../../formElements';
import { accent } from '../../../constants/colors';
import { paragraphSmallSize } from '../../../constants/text';
import { bodyFontStack } from '../../../fonts';

const StyledCollapsibleFilters = styled(CollapsibleFilters)`
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: transparent !important;
`;

const SeeAllButton = styled.button`
  border: none;
  outline: none;
  text-decoration: underline;
  margin: ${SPACING_8} 0;
  color: ${accent.ocean470};
  font-size: ${paragraphSmallSize};
  background: none;
`;

const StyledSearchInput = styled(SearchInput)`
  font-family: ${bodyFontStack} !important;
  background-color: #f8f8fa;
`;

const ItemsWrapper = styled.div`
  margin: 0 -${SPACING_16};
`;

export const ExtendedReportingMultiSelectFilter = (props) => {
  const {
    title,
    field,
    count,
    app,
    selections,
    maxOptions = 10,
    maxOptionsFuzziness = 2,
    onChangeCount = () => {},
    ...rest
  } = props;

  const [options, onChange] = useQlikFilterOptions(app, selections, field);
  const [searchString, setSearchString] = useState('');
  const [seeAll, setSeeAll] = useState(false);

  const optionsArrayToObject = (optionsArray) => {
    if (!optionsArray) return {};
    return optionsArray.reduce(
      (obj, cur) => ({ ...obj, [cur.value]: { ...cur } }),
      {}
    );
  };

  // This is a "workaround" for avoiding to use some sort of "deepEqual".
  // We need to check if something has changed in the options array in order to
  // fire "onChangeCount" callback.
  // The options shouldn't be too long so JSON.stringify might be a good way.
  const optionsJsonString = JSON.stringify(options);

  useEffect(() => {
    if (optionsJsonString) {
      onChangeCount(options.filter((option) => option.selected).length);
      setOptionsState(optionsArrayToObject(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsJsonString]);

  // Convert the options array to an object which will be stored in a component state
  const [optionsState, setOptionsState] = useState(
    optionsArrayToObject(options)
  );

  // Listen for changes in a component state and use "onChange" handler accordingly.
  // This way we use some kind of "optimistic approach" where we don't validate if the change
  // on Qlik side actually happened. However, at some point the Qlik should return the valid
  // state of filters and our code will reflect if the change didn't happen. It might be
  // a good idea to consider "loading state" in this component.
  useEffect(() => {
    if (Object.values(optionsState).length) {
      onChange(
        Object.values(optionsState)
          .filter((option) => option.selected)
          .map((option) => option.value)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsState]);

  if (!options || !options.length) return null;

  // We want to be a bit "clever" with how many options we show, so instead of
  // always respecting the number of maxOptions passed in, we allow for some
  // fuzzines. So with a maxOptions = 10, and maxOptionFuzziness of 2, we would
  // still show all options without truncating the list if we have 12 or less
  // options in the list. But if we have 13 or more items in the list, we only
  // show 10 items, and then we hide the 3 remaining.
  let numOptionsToShow = maxOptions;
  if (Object.values(optionsState).length <= maxOptions + maxOptionsFuzziness) {
    numOptionsToShow = Object.values(optionsState).length;
  }

  const shouldTruncateOptions =
    Object.values(optionsState).length > numOptionsToShow;

  const searchedOptions = Object.values(optionsState)
    .filter(
      (option) =>
        option.label &&
        option.label.toLowerCase().includes(searchString.toLowerCase())
    )
    .filter((_, index) => index < numOptionsToShow || seeAll);

  return (
    <StyledCollapsibleFilters
      title={title}
      childrenStyle={{ padding: `${SPACING_2} ${SPACING_16}` }}
      contentStyle={{ backgroundColor: 'transparent' }}
      count={options.filter((option) => option.selected).length}
      {...rest}
    >
      {shouldTruncateOptions && (
        <StyledSearchInput
          onChange={(event) => {
            setSearchString(event.target.value);
          }}
          type="text"
          value={searchString}
          style={{
            border: '1px solid rgba(24, 35, 97, 0.2)',
            width: '100%',
            marginBottom: 8,
          }}
          placeholder={`Search ${title.toLowerCase()}`}
        />
      )}
      <ItemsWrapper>
        {searchedOptions.map((option, index) => (
          <ExtendedReportingCheckbox
            onChange={() => {
              setOptionsState({
                ...optionsState,
                [option.value]: {
                  ...option,
                  selected: !optionsState[option.value].selected,
                },
              });
            }}
            highlightOnHover={shouldTruncateOptions}
            key={`checkbox-${index}`}
            {...option}
          />
        ))}
      </ItemsWrapper>
      {shouldTruncateOptions && !searchString.length && !seeAll && (
        <SeeAllButton onClick={() => setSeeAll(true)}>See all</SeeAllButton>
      )}
    </StyledCollapsibleFilters>
  );
};
