import React from 'react';
import styled from 'styled-components';

import { formatBigMoney, percentage } from '@formue-app/core';

import { primary } from '../../../../constants/colors';
import { SPACING_16, SPACING_8 } from '../../../../constants/spacing';
import { Paragraph, H2, ParagraphSmall } from '../../../texts';
import {
  DesktopWrapper,
  notDesktopCondition,
  NotDesktopWrapper,
} from '../../../../constants/media';

const ConstructionListHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${SPACING_8};

  @media ${notDesktopCondition} {
    flex-direction: row;
  }
`;

const ConstructionHeaderMetadata = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${SPACING_8};

  @media ${notDesktopCondition} {
    justify-content: flex-start;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: ${SPACING_16};

  @media ${notDesktopCondition} {
    margin-right: ${SPACING_16};
    margin-bottom: 0;

    svg {
      height: 50px;
      width: 50px;
    }
  }
`;

const Percent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  justify-content: center;
  height: 24px;
  width: 44px;
`;

export const PortfolioConstructionListHeader = (props) => {
  const {
    style,
    title,
    percent,
    amount = 0,
    Icon,
    percentColor = primary,
    percentBackgroundColor,
  } = props;

  return (
    <ConstructionListHeader style={style}>
      <IconWrapper>{Icon && <Icon />}</IconWrapper>
      <div>
        <DesktopWrapper>
          <H2 style={{ marginBottom: SPACING_8 }}>{title}</H2>
        </DesktopWrapper>
        <NotDesktopWrapper>
          <Paragraph style={{ marginBottom: SPACING_8, fontWeight: 500 }}>
            {title}
          </Paragraph>
        </NotDesktopWrapper>
        <ConstructionHeaderMetadata>
          {!!percent && (
            <Percent style={{ backgroundColor: percentBackgroundColor }}>
              <ParagraphSmall style={{ color: percentColor }}>
                {percentage(percent, 0)}
              </ParagraphSmall>
            </Percent>
          )}
          <Paragraph>{formatBigMoney(amount, false)}</Paragraph>
        </ConstructionHeaderMetadata>
      </div>
    </ConstructionListHeader>
  );
};
