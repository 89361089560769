import React from 'react';
import styled from 'styled-components';

import { accent } from '../../constants/colors';

const DonutGraphWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    rotate: -90deg;
  }
`;

export const DonutGraph = (props) => {
  const {
    size = 16,
    border = 2,
    value = 0,
    color = accent.velvet1,
    ...rest
  } = props;

  const circumference = 2 * Math.PI * (size / 2);

  const _value = 1 - value;

  return (
    <DonutGraphWrapper
      style={{
        width: size + border,
        height: size + border,
      }}
      {...rest}
    >
      <svg width={size + border} height={size + border}>
        <g>
          <circle
            r={size / 2}
            cy={size / 2 + border / 2}
            cx={size / 2 + border / 2}
            strokeWidth={border}
            stroke={accent.sand3}
            fill="none"
          />
        </g>
      </svg>
      <svg width={size + border} height={size + border}>
        <g>
          <circle
            style={{
              strokeDasharray: circumference,
              strokeDashoffset: circumference * _value,
            }}
            r={size / 2}
            cy={size / 2 + border / 2}
            cx={size / 2 + border / 2}
            strokeWidth={border}
            stroke={color}
            fill="none"
          />
        </g>
      </svg>
    </DonutGraphWrapper>
  );
};
