import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  getAssetClassName,
  getAssetClassGroupByAssetClass,
  percentage,
  getColorForAssetClassGroup,
  toLocaleDateString,
  t,
} from '@formue-app/core';

import { H3, H4, ParagraphSmall, ParagraphXSmall } from '../texts';
import { ButtonSmall } from '../buttons';
import {
  BORDER_RADIUS_LARGE,
  SPACING_12,
  SPACING_24,
  SPACING_8,
} from '../../constants/spacing';
import { BaseSection } from '../common/BaseSection';
import { accent, assetClassDefaultColorScheme } from '../../constants/colors';
import { PortfolioPyramid } from '../common/PortfolioPyramid';

const Container = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div``;

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_24};
`;

export const Dot = styled.span`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  background-color: ${(props) => props.color};
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_12};
  padding-bottom: 6px;
`;

const AssetGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const AssetGroupsWrapper = styled.div`
  display: flex;
  gap: ${SPACING_8};
  padding-top: ${SPACING_8};
`;

const StatusLabel = styled.div`
  background-color: ${accent.ocean408};
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: 2px ${SPACING_8} 3px ${SPACING_8};
  > p {
    font-weight: 500;
  }
`;

export const InvestmentStrategiesListItem = (props) => {
  const {
    id,
    title,
    lastModifiedAt,
    accountName,
    type,
    assetClasses,
    status,
    showPyramid,
    childStrategy,
    to,
    ...rest
  } = props;

  const assetClassGroups = assetClasses.reduce((acc, current) => {
    const assetClassGroup = getAssetClassGroupByAssetClass(current.assetClass);
    return {
      ...acc,
      [assetClassGroup]: {
        title: getAssetClassName(assetClassGroup),
        strategyShare: acc.hasOwnProperty(assetClassGroup)
          ? acc[assetClassGroup].strategyShare + current.strategyShare
          : current.strategyShare,
      },
    };
  }, {});

  const assetClassGroupKeys = ['S', 'MV', 'AV'];
  return (
    <Container {...rest}>
      <ChartWrapper>
        {showPyramid && <PortfolioPyramid height={44} />}
        <Wrapper>
          <TitleWrapper>
            {childStrategy ? <H3>{title}</H3> : <H4>{title}</H4>}
            {!childStrategy && (
              <StatusLabel>
                <ParagraphXSmall>
                  {t(
                    `wealthPlan:investmentStrategy:listItem:statuses:${status.toLowerCase()}`
                  )}
                </ParagraphXSmall>
              </StatusLabel>
            )}
          </TitleWrapper>
          <Row>
            <ParagraphSmall>
              {`${t(
                `wealthPlan:investmentStrategy:strategyCreated`
              )}: ${toLocaleDateString(lastModifiedAt)}`}
              <Dot
                color={accent.ocean490}
                size={4}
                style={{ margin: '3px 7px' }}
              />
            </ParagraphSmall>
            <ParagraphSmall>{accountName}</ParagraphSmall>
            {type && (
              <ParagraphSmall>
                <Dot size={4} style={{ margin: '3px 7px' }} />
                {type}
              </ParagraphSmall>
            )}
          </Row>
          <AssetGroupsWrapper>
            {assetClassGroupKeys.map((assetClassGroupKey) => {
              const assetClassGroup = assetClassGroups[assetClassGroupKey];
              return (
                <AssetGroup key={`asset-class-${assetClassGroupKey}`}>
                  <Dot
                    color={getColorForAssetClassGroup(
                      assetClassGroupKey,
                      assetClassDefaultColorScheme
                    )}
                    size={6}
                  />
                  <ParagraphXSmall>
                    {`${assetClassGroup.title} `}
                    <strong style={{ fontWeight: 500 }}>
                      {percentage(assetClassGroup.strategyShare * 100)}
                    </strong>
                  </ParagraphXSmall>
                </AssetGroup>
              );
            })}
          </AssetGroupsWrapper>
        </Wrapper>
      </ChartWrapper>
      <Link to={to ? to : `/investment-strategy/${id}`}>
        <ButtonSmall buttonstyle="outline" size="small">
          {t('presentation:common:seeDetails')}
        </ButtonSmall>
      </Link>
    </Container>
  );
};
