import { apiFetch } from './utils/api';

/**
 * @desc Fetch the push notifications list from the API
 *
 * @param {number} ssid - required if we are impersonated
 *
 * @return json - data or error message
 */

export const pushNotifications = {
  get: async () => {
    const options = {
      method: 'GET',
    };
    const url = '/v1/push-notifications';
    const { body } = await apiFetch(url, {
      ...options,
    });
    return body;
  },
};
