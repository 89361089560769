import React, { useEffect } from 'react';

import { accent, backgroundWhite } from '../../constants/colors';
import { ReactComponent as StripedCircleIcon } from '../../assets/icons/investment-simulator/striped-circle-icon.svg';
import { bodyFontStack } from '../../fonts';

const CircleIcon = ({ color }) => (
  <svg
    width="7"
    height="7"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="8"
      r="6.5"
      fill="white"
      stroke={color}
      stroke-width="3"
    />
  </svg>
);

export const SpaghettiChartTimePoint = (props) => {
  const {
    x,
    y,
    datum,
    cy = 0,
    cx = 0,
    width,
    callbackY = () => {},
    color = accent.ocean4,
    selected,
    entryKey,
    showLabels,
    index,
    data,
  } = props;

  const { eventLabels = [] } = datum;

  useEffect(() => {
    // The "y" position is calculated in this component.
    // We need to grab this value from it's parents component, hence the need
    // for a callback function.
    // We need the "y" position value only for the last item in the data.
    if (data.length === parseInt(index) + 1) {
      callbackY(y);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryKey, y]);

  if (!eventLabels.length || !selected) return null;

  const offset = 3.5;

  return (
    <g
      transform={`translate(${x + cx - offset},${y + cy - offset})`}
      width={width}
    >
      {eventLabels.map(({ flexible, name }, index) => {
        const labelWidth = name.length * 2;
        return (
          <g key={`time-point-${index}`}>
            <rect
              x={-labelWidth / 2 + offset}
              y="-10"
              width={labelWidth}
              height="7"
              fill={backgroundWhite}
              style={{
                filter: 'drop-shadow(0px 2px 2px rgb(0 0 0 / 0.1))',
                opacity: showLabels ? 1 : 0,
              }}
            ></rect>
            <text
              x={offset}
              y="-5"
              fontFamily={bodyFontStack}
              fontSize="3.5"
              fill={accent.ocean490}
              style={{ opacity: showLabels ? 1 : 0 }}
              textAnchor="middle"
            >
              {name}
            </text>

            {flexible ? <StripedCircleIcon /> : <CircleIcon color={color} />}
          </g>
        );
      })}
    </g>
  );
};
