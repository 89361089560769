import React from 'react';
import styled from 'styled-components';
import { ExtendedReportingNavigation } from '../../components/extendedReporting/ExtendedReportingNavigation';

import { H1 } from '../../components/texts';
import { SPACING_64 } from '../../constants/spacing';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${SPACING_64};
  align-items: center;
  justify-content: center;
`;

export const ExtendedReportingOverviewPage = () => {
  return (
    <ContentWrapper>
      <div>
        <H1 style={{ marginLeft: 32 }}>Views</H1>
        <ExtendedReportingNavigation />
      </div>
    </ContentWrapper>
  );
};
