import React from 'react';
import camelcase from 'camelcase';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { t, toLocaleDateString } from '@formue-app/core';

import { ExpertInputItemLink } from '../ExpertInputItemLink';
import { Column } from '../TodoListItem';
import { tabletCondition, mobileCondition } from '../../../../constants/media';

import { H4, H6, Paragraph } from '../../../texts';
import { SPACING_16 } from '../../../../constants/spacing';
import { SpriteIcon } from '../../../common/SpriteIcon';

const StyledExpertInputItemLink = styled(ExpertInputItemLink)`
  margin-left: auto;
`;

const StyledColumn = styled(Column)`
  display: flex;
  align-items: center;

  @media ${mobileCondition} {
    position: relative;
    padding: ${SPACING_16} !important;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  margin-right: ${SPACING_16};
  min-width: 24px;
  min-height: 24px;
`;

const RightAlignCell = styled(Column)`
  display: flex;
  justify-content: flex-end;
  min-width: 160px;
`;

const StyledTitle = styled(H4)`
  word-break: break-word;
`;

const DueDateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MobileHeader = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 4px;
`;

export const useExpertInputColumns = (props) => {
  const { id, item } = props;
  const match = useRouteMatch();

  const tabletSize = useMediaQuery({
    query: tabletCondition,
  });

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  if (!item) {
    return null;
  }

  const {
    name: itemName,
    completedAt: date,
    dueAt,
    type,
    status,
    authors,
  } = item;

  let title;
  try {
    title = type && t(`todo:expertInput:titles:${camelcase(type)}`);
  } catch (error) {
    title = itemName;
  }

  let name = '';
  if (authors.length > 0) {
    name = authors[0].name;
  }

  const url = `${match.url}/expert-inputs/${id}`;
  const link = {
    url: url,
    label: t('globals:readMore'),
  };

  let StatusIcon;
  switch (status.toLowerCase()) {
    case 'ongoing':
      StatusIcon = <SpriteIcon id="Iteration" size={24} />;
      break;
    case 'completed':
      StatusIcon = <SpriteIcon id="Task" size={24} />;
      break;
    default:
      StatusIcon = <SpriteIcon id="Document Single" size={24} />;
      break;
  }

  const showDateCondition = status.toLowerCase() === 'completed' && !!date;
  const showDueDateCondition = status.toLowerCase() !== 'completed' && !!dueAt;

  // Define the table columns
  let columns = [
    {
      key: 'title',
      render: () => (
        <>
          <IconWrapper>{StatusIcon}</IconWrapper>
          <TitleWrapper>
            <StyledTitle>{title}</StyledTitle>
            {showDueDateCondition ? (
              <DueDateWrapper>
                <SpriteIcon id="Time" size={12} style={{ marginRight: 4 }} />
                <H6>{toLocaleDateString(dueAt)}</H6>
              </DueDateWrapper>
            ) : null}
          </TitleWrapper>
        </>
      ),
      component: StyledColumn,
      width: '40%',
    },
    {
      key: 'date',
      render: () => (showDateCondition ? toLocaleDateString(date) : null),
      width: '15%',
    },
    {
      key: 'name',
      render: () => name,
      width: '20%',
    },
    {
      key: 'type',
      render: () => t('todo:expertInputTaskCategory'),
      width: '15%',
    },
    {
      key: 'action',
      render: () => <StyledExpertInputItemLink link={link} />,
      component: RightAlignCell,
      width: '10%',
    },
  ];

  if (tabletSize || mobileSize) {
    columns = [
      {
        key: 'title',
        render: () => (
          <>
            <div>
              <MobileHeader>
                <H6 style={{ marginRight: 5, lineHeight: '20px' }}>{type}</H6>
                {showDueDateCondition ? (
                  <DueDateWrapper>
                    <SpriteIcon
                      id="Time"
                      size={12}
                      style={{ marginRight: 4 }}
                    />
                    <H6>{toLocaleDateString(dueAt)}</H6>
                  </DueDateWrapper>
                ) : null}
                {showDateCondition ? (
                  <H6 style={{ height: 16 }}>{toLocaleDateString(date)}</H6>
                ) : null}
              </MobileHeader>

              <H4 style={{ marginBottom: 5, wordBreak: 'break-word' }}>
                {title}
              </H4>
              <Paragraph>{name}</Paragraph>
              <StyledExpertInputItemLink
                link={link}
                style={{
                  position: 'absolute',
                  right: 15,
                  top: '50%',
                  marginTop: -14,
                }}
              />
            </div>
          </>
        ),
        component: StyledColumn,
        width: '100%',
      },
    ];
  }

  return columns;
};
