import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { H2Small, H6, ParagraphSmall, ParagraphXSmall } from '../../texts';
import {
  SPACING_16,
  SPACING_24,
  SPACING_8,
  SPACING_48,
  SPACING_32,
} from '../../../constants/spacing';
import { accent, textLightest } from '../../../constants/colors';
import { ReactComponent as InvestmentStrategyPyramid } from '../../../assets/icons/investment-strategy-pyramid.svg';
import { LinkSummaryBox } from '../../common/SummaryBox';
import { mobileCondition, notDesktopCondition } from '../../../constants/media';

const StyledSummaryBox = styled(LinkSummaryBox)`
  @media ${notDesktopCondition} {
    grid-column: span 12;
  }
`;

const Wrapper = styled.div`
  margin-top: ${SPACING_24};
`;

const Bar = styled.div`
  height: 2px;
  width: 100%;
  margin: 27px 0;
  background-color: ${accent.velvet4};
  position: relative;
  display: block;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 12px;
    width: 2px;
    border-radius: 2px;
    background-color: ${accent.velvet4};
    display: block;
    top: -5px;
  }

  &:after {
    right: 0;
  }
`;

const Tooltip = styled(ParagraphXSmall)`
  background-color: ${accent.velvet1};
  position: absolute;
  top: -35px;
  padding: 2px 9px;
  border-radius: 2px;
  color: ${textLightest};
  left: calc((100% * ${(props) => props.position}));

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${accent.velvet1};
    left: calc(100% / 2 - 6px);
  }

  &:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: ${accent.velvet1};
    border-radius: 6px;
    position: absolute;
    top: 30px;
    left: calc(100% / 2 - 6px);
  }
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Legend = styled.div`
  display: flex;
  gap: ${SPACING_16};
`;

const LegendItem = styled(H6)`
  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
    background-color: ${(props) => props.color};
  }

  @media ${mobileCondition} {
    &:before {
      margin-right: 100%;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: ${SPACING_32};
  gap: ${SPACING_48};
`;

const BarGroup = styled.div`
  width: 100%;
`;

export const InvestmentSimulatorExcerpt = (props) => {
  return (
    <StyledSummaryBox
      columns={6}
      label={t('globals:readMore')}
      url={'/wealth-plan/investment-simulator'}
    >
      <H2Small style={{ marginBottom: SPACING_8 }}>
        {t('wealthPlan:investmentSimulator:title')}
      </H2Small>
      <ParagraphSmall>
        {t('wealthPlan:investmentSimulator:subtitle')}
      </ParagraphSmall>

      <Wrapper>
        <Legend>
          <LegendItem color={accent.velvet1}>
            {t('portfolio:structure:stability:title')}
          </LegendItem>
          <LegendItem color={accent.velvet2}>
            {t('portfolio:structure:marketGrowth:title')}
          </LegendItem>
          <LegendItem color={accent.velvet3}>
            {t('portfolio:structure:alternativeGrowth:shortTitle')}
          </LegendItem>
        </Legend>
        <ContentWrapper>
          <InvestmentStrategyPyramid />
          <BarGroup>
            <Bar>
              <Tooltip position={0.7}>
                {t('wealthPlan:investmentSimulator:investmentRisk:lock')}
              </Tooltip>
            </Bar>
            <Bar style={{ marginBottom: 14 }}>
              <Tooltip position={0.2}>
                {t('wealthPlan:investmentSimulator:investmentRisk:risk')}
              </Tooltip>
            </Bar>
            <Label>
              <H6>{t('wealthPlan:investmentSimulator:investmentRisk:low')}</H6>
              <H6>{t('wealthPlan:investmentSimulator:investmentRisk:high')}</H6>
            </Label>
          </BarGroup>
        </ContentWrapper>
      </Wrapper>
    </StyledSummaryBox>
  );
};
