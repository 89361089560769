import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Flag } from '../portfolio/lenses/geography/Flag';
import { COUNTRY_CODES } from '@formue-app/core/src/constants/geography';
import { usePrev } from '@react-spring/shared';
import { easeInOut, longAnimationTime } from '../../constants/animation';

const FlagIcon = (props) => {
  const { countryCode } = props;
  const [animated, setAnimated] = useState(false);
  const prevCountryCode = usePrev(countryCode);

  useEffect(() => {
    if (prevCountryCode !== countryCode) {
      setAnimated(false);
    } else {
      setTimeout(() => {
        setAnimated(true);
      }, 800);
    }
  }, [countryCode, prevCountryCode]);

  return (
    <FlagIconAnimation animated={animated}>
      {animated && <Flag {...props} />}
    </FlagIconAnimation>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const IconWrapper = styled.div`
  width: 110px;
  height: 110px;
  margin: 0 50px;
`;

const FlagIconAnimation = styled.div`
  transition: opacity ${easeInOut} ${longAnimationTime};
  opacity: 0;

  ${(props) =>
    props.animated &&
    `
    opacity: 1;
  `}
`;

export const GeographyLoading = () => {
  const [activeIcons, setActiveIcons] = useState({
    0: 'NO',
    1: 'SE',
    2: 'DK',
  });

  const [counter, setCounter] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIcons]);

  useEffect(() => {
    const newIconIndex = Math.floor(
      Math.random() * Object.keys(COUNTRY_CODES).length
    );

    const newActiveIcons = {
      ...activeIcons,
      [counter % 3]: Object.keys(COUNTRY_CODES)[newIconIndex],
    };
    setActiveIcons(newActiveIcons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <Container>
      <IconWrapper>
        <FlagIcon width={110} height={110} countryCode={activeIcons['0']} />
      </IconWrapper>
      <IconWrapper>
        <FlagIcon width={110} height={110} countryCode={activeIcons['1']} />
      </IconWrapper>
      <IconWrapper>
        <FlagIcon width={110} height={110} countryCode={activeIcons['2']} />
      </IconWrapper>
    </Container>
  );
};
