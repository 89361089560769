import React from 'react';
import styled from 'styled-components';

import { accent } from '../../constants/colors';

const Wrapper = styled.div`
  background: ${accent.sand250};
  width: 100%;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.height / 2}px;
  position: relative;
`;

const Bar = styled.div`
  background: ${(props) => (props.color ? props.color : accent.ocean1)};
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.height / 2}px;
  width: ${(props) => props.value * 100}%;
  position: absolute;
`;

const SubValue = styled.div`
  background: ${(props) => (props.color ? props.color : accent.ocean3)};
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.height / 2}px;
  width: ${(props) => (props.subValueStop - props.subValueStart) * 100}%;
  left: ${(props) => props.subValueStart * 100}%;
  position: absolute;
`;

export const ProgressBar = (props) => {
  const {
    height = 16,
    value = 0.5,
    subValueStart,
    subValueStop,
    color,
    subValueColor,
    ...rest
  } = props;

  return (
    <Wrapper height={height} {...rest}>
      <Bar height={height} value={value} color={color} className="bar" />
      {!!subValueStop ? (
        <SubValue
          height={height}
          subValueStart={subValueStart}
          subValueStop={subValueStop}
          color={subValueColor}
          className="sub-bar"
        />
      ) : null}
    </Wrapper>
  );
};
