import { keyframes } from 'styled-components';

export const easeInOut = 'cubic-bezier(0.2, 0.8, 0.25, 1)';
export const shortAnimationTime = '0.2s';
export const mediumAnimationTime = '0.5s';
export const longAnimationTime = '0.8s';
export const extraLongAnimationTime = '1.2s';

export const slideAnimationDelay = 0.7;

export const fadeInFromBottom = (distance = 60) => keyframes`
  0%  {
    opacity: 0;
    transform: translateY(${distance}px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const fadeInFromSide = (distance = 60) => keyframes`
  0%  {
    opacity: 0;
    transform: translateX(${distance}px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
