import React, { useCallback, useEffect, useState, memo } from 'react';

import { animated, useSpring } from 'react-spring';
import { usePrevious } from '@formue-app/core';
import { interpolate } from 'flubber';

/**
 * This component should act as a "wrapper" for any given svg image.
 * If the "file source" changes (svgImage prop) it should try to animate
 * svg paths from the old vector into a new one
 */
export const SvgMorphing = memo((props) => {
  const { svgImage, key, width, height } = props;
  const SvgImage = svgImage;

  const [paths, setPaths] = useState([]);
  const [viewBox, setViewBox] = useState('0 0 0 0');
  const [interpolators, setInterpolators] = useState([]);
  const previousPaths = usePrevious(paths);

  const handleSvg = useCallback((node) => {
    if (node) {
      const { x, y, width, height } = node.viewBox.baseVal;
      setViewBox(`${x} ${y} ${width} ${height}`);

      const svgPaths = [...node.children];
      setPaths(svgPaths);
    }
  }, []);

  useEffect(() => {
    setInterpolators(
      paths.map((path, index) => {
        // We want to interpolate the vector values from the "previous ones" to the "current ones".
        // We set the default path to animate from to be [0, 0]. We need to have a default one in case
        // this is a first render of the component, or in case that the number of previous paths was lower
        // than the number of the new ones
        let previousPath = [[0, 0]];

        if (previousPaths) {
          if (previousPaths[index]) {
            previousPath =
              previousPaths[index].attributes.getNamedItem('d').value;
          }
        }

        return interpolate(
          previousPath,
          path.attributes.getNamedItem('d').value,
          {
            maxSegmentLength: 0.5,
          }
        );
      })
    );
  }, [paths, previousPaths]);

  const animationProps = useSpring({
    from: { x: 0 },
    to: {
      x: 1,
    },
    config: {
      clamp: true, // interpolation function can't go above 1
    },
    reset: true,
  });

  if (!svgImage) return null;

  return (
    <div>
      {/* This is sort of hackish, but we need to render the svg to actually have access to the "React component properties" */}
      <div style={{ display: 'none' }}>
        <SvgImage ref={handleSvg} key={key} />
      </div>

      {paths && interpolators.length === paths.length && (
        <div style={{ width: `${width}px`, height: `${height}px` }}>
          <svg width="100%" viewBox={viewBox}>
            {paths.map((path, index) => {
              const fill = path.attributes.getNamedItem('fill');
              return (
                <animated.path
                  d={animationProps.x.to(interpolators[index])}
                  fill={fill && fill.value}
                  vectorEffect="non-scaling-stroke"
                />
              );
            })}
          </svg>
        </div>
      )}
    </div>
  );
});
