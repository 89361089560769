import React from 'react';
import styled from 'styled-components';

import { textLightest } from '../../../constants/colors';
import {
  SPACING_12,
  SPACING_16,
  SPACING_4,
  SPACING_8,
} from '../../../constants/spacing';
import { ProfileIcon } from '../../common/ProfileIcon';
import { Paragraph } from '../../texts';
import { SpriteIcon } from '../../common/SpriteIcon';

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${SPACING_12};
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_4};
`;

const LinkedIcons = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: ${SPACING_8};

  &:hover {
    text-decoration: underline;
  }
`;

export const Advisor = (props) => {
  const {
    contact: { name, email, phone, photoUrl },
  } = props;

  return (
    <ContactWrapper>
      <ProfileIcon
        name={name}
        url={photoUrl}
        size={94}
        style={{ border: `2px solid ${textLightest}` }}
      />
      {/* {role && (
        <H4 style={{ marginBottom: '5px' }}>{getAdvisorRoleName(role)}</H4>
      )} */}
      {name && (
        <Paragraph style={{ fontWeight: 500, textAlign: 'center' }}>
          {name}
        </Paragraph>
      )}

      <SubSection>
        {email && (
          <LinkedIcons href={`mailto:${email}`}>
            <SpriteIcon id="Contact Mail" size={SPACING_16} />
            <Paragraph>{email}</Paragraph>
          </LinkedIcons>
        )}
        {phone && (
          <LinkedIcons href={`tlf:${phone}`}>
            <SpriteIcon id="Contact Phone" size={SPACING_16} />
            <Paragraph>{phone}</Paragraph>
          </LinkedIcons>
        )}
      </SubSection>
    </ContactWrapper>
  );
};
