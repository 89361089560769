import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { getListData } from './useQlikListData';

import {
  getPeriodsBetween,
  getPeriodFromDate,
} from '../../services/utilities/qlik';
import { getLocalizedMonthName } from '../../services';

const getFilterLabelFromPeriod = (period) => {
  const year = String(period).slice(0, 4);
  const month = Number(String(period).slice(4));
  return `${getLocalizedMonthName(month - 1)}. ${year}`;
};

/**
 * Takes a list of period filters and looks into the values to generate a new
 * period filter that concatenates the filters into a single. E.g if the
 * periods in the filters is a range without any breaks, we show
 * "Feb. 21 - Aug. 21", or if the range is 12 months from the current month, we
 * show "Last 12 months"
 */
const createPeriodFilterBasedOnFilterList = (app, filters) => {
  // Convert filter into period. Filter is an object with a label
  // representing the period e.g 202208
  const periods = filters
    .map((filter) => Number(filter.label))
    .sort((a, b) => a - b); // by numerical value

  const firstPeriod = periods[0];
  const lastPeriod = periods[periods.length - 1];
  const periodsBetweenFirstAndLast = getPeriodsBetween(firstPeriod, lastPeriod);
  const now = new Date();
  const currentPeriod = Number(getPeriodFromDate(now));

  // Check if we have an uninterrupted range of periods
  if (isEqual(periods, periodsBetweenFirstAndLast)) {
    // Default to defining label as "From - To", e.g "Aug. 2020 - Nov. 2021"
    const firstPeriodLabel = getFilterLabelFromPeriod(firstPeriod);
    const lastPeriodLabel = getFilterLabelFromPeriod(lastPeriod);
    let label = `${firstPeriodLabel} - ${lastPeriodLabel}`;

    // Check if the period is one of the predefined ranges, e.g this month,
    // last 12 months, last three years etc.
    if (lastPeriod === currentPeriod) {
      switch (periods.length) {
        case 1: {
          label = 'This month';
          break;
        }
        case now.getMonth() + 1: {
          label = 'This year';
          break;
        }
        case 13: {
          label = 'Last 12 months';
          break;
        }
        default: {
          // If number of periods can be converted to years
          if ((periods.length - 1) % 12 === 0) {
            const years = (periods.length - 1) / 12;
            label = `Last ${years} years`;
          }
          break;
        }
      }
    }

    // Create a filter that contains a label that represent the periods that are
    // selected.
    const periodFilter = {
      label,
      fieldName: 'Period',
      onRemove: () => {
        app.field('Period').clear();
      },
    };

    return [periodFilter];
  }

  // If we don't have an uninterrupted range of periods, we just return the raw
  // filters again and fallback to showing the individual periods in the filter
  // badge list.
  return filters;
};

/**
 *
 * @param {*} app - Qlik App instance
 * @param {*} selections - Qlik Selections object
 * @returns a list of objects that represents what selections have been made on
 *          qlik fields.
 *
 * Qlik has a way to read selected values from different selections using the
 * "selectionState" object. But that has a limit where it only return 6 values
 * and a count of how many selections the field has. We want to show _all_
 * selected values and thusly need to use the "createList" API from qlik
 * to retreive all selected values.
 *
 * For performance reasons we try to use the selectionState data first, and
 * only fallback to use the createList API when needed. E.g when we have more
 * selections than the selection threshold.
 */
export const useQlikActiveFilters = (app, selections) => {
  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    // We need to wrap async calls inside a function when we are within a
    // useEffect hook
    const fetchActiveFilters = async () => {
      const promises = selections.map(async (selection) => {
        const {
          fieldName,
          selectedCount,
          qSelectionThreshold: selectionThreshold,
        } = selection;

        // if we have no selected values, just skip ahead
        if (!selectedCount >= 1) {
          return [];
        }

        // When selected count is larger than selection threshold, we use the
        // createlist API (getListData method) to retrieve a list of all options
        // and filter that on the selected value to generate the list of active
        // filters
        if (selectedCount > selectionThreshold) {
          // Get the list of options for this field. We might optimize things
          // by only doing this once. We do it twice now, one time here, and then
          // one time in the component that renders the filter itself. We could
          // in theory do it once and put into a local state object. Hopefully
          // qlik caches this on their end so it doesn't really hurt performance
          // to much.
          const data = await getListData(app, fieldName);
          return data
            .filter((datum) => datum.selected)
            .map((datum) => {
              const { value } = datum;
              return {
                label: value,
                fieldName,
                onRemove: () => {
                  app.field(fieldName).toggleSelect(value, true);
                },
              };
            });
        } else {
          return selection?.selectedValues.map((value) => {
            const { qName: label } = value;
            // Return an object with the label, what field it belongs to and
            // a function to call to remove the selection.
            return {
              label: value.qName,
              fieldName,
              onRemove: () => {
                app.field(fieldName).toggleSelect(label, true);
              },
            };
          });
        }
      });

      const activeFilters = (await Promise.all(promises)).flat();
      setActiveFilters(activeFilters);
    };

    // Call the async method
    fetchActiveFilters();
  }, [app, selections]);

  if (!activeFilters.length) {
    return [];
  }

  // We need to handle "Period" filters differently. We don't want to show
  // individual periods in the filter list, so we need to concatenate into
  // nicer looking values like "last 12 months", "Jan. 21 - Jun. 21" etc.
  // So first we need to extract period filters from the list, and then
  // create a filter that "concatenates" the filters based on the values
  let periodFilters = [];
  let activeFiltersWithoutPeriod = activeFilters.filter((filter) => {
    if (filter.fieldName === 'Period') {
      periodFilters.push(filter);
      return false;
    }
    return true;
  });

  if (!periodFilters.length) {
    return activeFiltersWithoutPeriod;
  }

  const generatedPeriodFilters = createPeriodFilterBasedOnFilterList(
    app,
    periodFilters
  );
  return [...generatedPeriodFilters, ...activeFiltersWithoutPeriod];
};
