import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from '../../store';
import { useResource } from '../api';
import { usePrevious } from '../common';

const { createFormByIdSelector } = selectors.entities.forms;
const { updateRequestBegin } = actions.entities.forms;

export const useSelfDeclarationFormData = () => {
  const formId = 'fatca-crs-pep';
  const dispatch = useDispatch();
  // Load any forms data
  useResource(['FORMS/INDEX']);

  const formSelector = createFormByIdSelector(formId);
  const form = useSelector(formSelector);
  const [primaryCitizenship, setPrimaryCitizenship] = useState(null);
  const [secondaryCitizenship, setSecondaryCitizenship] = useState(null);
  const [internationalResident, setInternationalResident] = useState(null);
  const [taxIdNumbers, setTaxIdNumbers] = useState([]);
  const [politicallyExposed, setPoliticallyExposed] = useState(null);
  const [politicallyExposedComment, setPoliticallyExposedComment] = useState('');
  const [politicallyExposedEndedAt, setPoliticallyExposedEndedAt] = useState('');
  const [showEndedAt, setShowEndedAt] = useState(false);
  const [consent, setConsent] = useState(false);
  const [canSubmitForm, setCanSubmitForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const prevTaxIdNumbers = usePrevious(taxIdNumbers);
  const prevPoliticallyExposed = usePrevious(politicallyExposed);

  useEffect(() => {
    if (form) {
      const { data } = form;
      // Initialize form values with data from form
      setPrimaryCitizenship(data.primaryCitizenship);
      setSecondaryCitizenship(data.secondaryCitizenship);
      setInternationalResident(data.internationalResident);
      setTaxIdNumbers(data.taxIdNumbers);
      setPoliticallyExposed(data.politicallyExposed);
      setPoliticallyExposedComment(data.politicallyExposedComment);
      setPoliticallyExposedEndedAt(data.politicallyExposedEndedAt || '');
    }
  }, [form]);

  // Make sure we always show 1 entry in the form
  useEffect(() => {
    // Need to also check if "prevtaxIdNumbers"  is defined, if not the form
    // would be empty when initiating the form when navigation from another
    // page for some reason. Not entirely sure why this fixes it, but it does 🤷
    if ((!taxIdNumbers || taxIdNumbers.length === 0) && prevTaxIdNumbers) {
      setTaxIdNumbers([{
        country: '',
        taxIdNumber: '',
      }])
    }
  }, [taxIdNumbers]);


  const onSubmit = (event) => {
    event.preventDefault();

    // Remove empty values
    const cleanedTaxIdNumbers = taxIdNumbers.filter(taxId => {
      return taxId.country && taxId.taxIdNumber;
    })

    const formData = {
      primaryCitizenship,
      secondaryCitizenship,
      internationalResident,
      taxIdNumbers: cleanedTaxIdNumbers,
      politicallyExposed,
      politicallyExposedComment,
      politicallyExposedEndedAt,
      consent,
    };

    // Do PATCH request against API
    dispatch(updateRequestBegin({ args: { id: formId }, data: formData }));
    setSubmitted(true);
  }

  useEffect(() => {
    // Determine if form is valid or not.
    let checks = [
      Boolean(primaryCitizenship),
      internationalResident !== null,
      politicallyExposed !== null,
      consent === true,
    ];

    // If one is filling out tax id numbers, make sure we get both
    // country and tax id number
    if (internationalResident === true && taxIdNumbers) {
      const validTaxIdNumbers = taxIdNumbers
        .every(t => (t.country.length > 0 && t.taxIdNumber.length > 0))
      checks.push(validTaxIdNumbers);
    }

    const result = checks.every(c => c === true);
    setCanSubmitForm(result);
  }, [
    primaryCitizenship,
    internationalResident,
    politicallyExposed,
    consent,
    taxIdNumbers,
  ])

  // Reset consent everytime you change any of the form data
  useEffect(() => {
    setConsent(false);
  }, [
    primaryCitizenship,
    secondaryCitizenship,
    internationalResident,
    politicallyExposed,
    taxIdNumbers,
  ]);

  // Only show endedAt input field if value of PEP changes from true to false.
  // We don't want to show the input field if you return to the form and value
  // is false already
  useEffect(() => {
    if (prevPoliticallyExposed === true && politicallyExposed === false) {
      setShowEndedAt(true);
      setPoliticallyExposedEndedAt(new Date().toISOString().slice(0,10));
    } else {
      setShowEndedAt(false);
    }
  }, [politicallyExposed])

  return {
    primaryCitizenship,
    setPrimaryCitizenship,
    secondaryCitizenship,
    setSecondaryCitizenship,
    internationalResident,
    setInternationalResident,
    taxIdNumbers,
    setTaxIdNumbers,
    politicallyExposed,
    setPoliticallyExposed,
    politicallyExposedComment,
    setPoliticallyExposedComment,
    politicallyExposedEndedAt,
    setPoliticallyExposedEndedAt,
    showEndedAt,
    consent,
    setConsent,
    canSubmitForm,
    setCanSubmitForm,
    onSubmit,
    submitted,
  }
}
