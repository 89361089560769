import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { textMain } from '../../../constants/colors';
import { DesktopWrapper } from '../../../constants/media';
import { SPACING_16 } from '../../../constants/spacing';
import { H4LineHeight, H4Size } from '../../../constants/text';

import { FlipArrow } from '../../common/FlipArrow';

import { bodyFontStack } from '../../../fonts';

export const StyledLink = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  font-size: ${H4Size};
  line-height: ${H4LineHeight};
  font-family: ${bodyFontStack};
  color: ${textMain};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-left: ${SPACING_16};
  }
`;

export const ExpertInputItemLink = (props) => {
  const { link, style } = props;

  if (!link) {
    return null;
  }

  const { label, url } = link;

  return (
    <StyledLink to={url} style={style}>
      <DesktopWrapper>{label}</DesktopWrapper> <FlipArrow />
    </StyledLink>
  );
};
