import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  localizeFloat,
  numberWithSpaces,
  selectors,
  t,
} from '@formue-app/core';

import { H1, H2, ParagraphLarge } from '../../../texts';
import { textMain } from '../../../../constants/colors';
import {
  SPACING_12,
  SPACING_24,
  SPACING_4,
  SPACING_8,
} from '../../../../constants/spacing';
import { H2Size, H3Size } from '../../../../constants/text';

const {
  entities: {
    portfolioLenses: { carbonFootprintDataSelector },
  },
} = selectors;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  padding-bottom: ${SPACING_24};
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled(H2)`
  padding: 0 ${SPACING_12};
  font-size: ${H3Size};
  white-space: pre-line;
`;

export const CarbonFootprintValue = ({
  color = textMain,
  breakText,
  ...rest
}) => {
  const carbonFootprintData = useSelector(carbonFootprintDataSelector);

  if (!carbonFootprintData) return null;

  return (
    <StyledContainer {...rest}>
      <StyledWrapper>
        <H1 style={{ color }}>
          {`${numberWithSpaces(
            localizeFloat(carbonFootprintData.carbonFootprint.toFixed(0), 0)
          )}`}
        </H1>
        {!breakText && (
          <StyledLabel style={{ color }}>
            {` ${t('sustainability:accordions:mainContributors:tonsPerYear')}`}
          </StyledLabel>
        )}
      </StyledWrapper>
      {breakText && (
        <ParagraphLarge style={{ color, fontWeight: 400 }}>
          {t('sustainability:tonsPerYear')}
        </ParagraphLarge>
      )}
    </StyledContainer>
  );
};
