import { call, put, takeLatest, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { portfolioLenses } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { activeFiltersSelector } from '../../ui/portfolio/selectors';
import { waitFor } from '../../../services';

// Create actions and a reducer for the portfolioLens resource
export const [actions, reducer, resourceSagas] =
  resourceReducer('portfolio-lenses');

// Create the sagas for this entity
function* readPortfolioLenses() {
  yield takeLatest(actions.indexRequestBegin, function* () {
    yield call(waitFor, (state) => state.ui.portfolio.initialized);
    const activeFilters = yield select(activeFiltersSelector);
    const { showExternalPortfolio, showInternalPortfolio, ssids } =
      activeFilters;
    try {
      const result = yield call(portfolioLenses.get, {
        ssids,
        showInternalPortfolio,
        showExternalPortfolio,
        period: 'THREEYEARS',
      });
      const { meta } = result;
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, meta }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readPortfolioLenses, ...resourceSagas];
