import { createSelector } from 'reselect';
import { makeFilterSelector } from '../../../services/helpers';

const signingEventsById = state => state.entities.signingEvents.byId;

// This holds a list of ids for signing events that we wait for feedback from Salesforce
// on. These should be added to the ongoing section of the list until the background poller
// mechanism remove is it from the inProgress list and we should use the state of the entity
// again to put in the correct section.
const signingEventIdsInProgressSelector = state => {
  if (state.ui && state.ui.signingEvents) {
    return state.ui.signingEvents.inProgress;
  }
  return [];
}

const readSigningEventsSelector = state =>
  state.ui.readStatus.readItems.filter(item => item.type === 'signingEvents');

/**
 * Create a selector that manipulates the status based on the in progress UI selector
 * It will change the status on the entity if the ID is in the in progress selector
 */
const manipulatedSigningEvents = createSelector(
  [signingEventsById, signingEventIdsInProgressSelector],
  (signingEvents, idsInProgress) => {
    return Object.keys(signingEvents).map(id => {
      const signingEvent = signingEvents[id];
      if (idsInProgress.includes(signingEvent.id)) {
        signingEvent.status = 'ongoing';
      }
      return signingEvent;
    });
  }
);

export const openSelector = makeFilterSelector(
  manipulatedSigningEvents,
  'open'
);
export const ongoingSelector = makeFilterSelector(
  manipulatedSigningEvents,
  'ongoing'
);
export const completedSelector = makeFilterSelector(manipulatedSigningEvents, [
  'completed',
  'cancelled',
]);

export const openCountSelector = createSelector(
  openSelector,
  items => Object.keys(items).length
);

export const readCountSelector = createSelector(
  readSigningEventsSelector,
  items => items.length
);

export const unreadCountSelector = createSelector(
  [openCountSelector, readCountSelector],
  (openCountSelectorCount, readCountSelectorCount) => {
    return openCountSelectorCount - readCountSelectorCount;
  }
);
