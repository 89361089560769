import { call, put, delay, takeLatest, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { portfolios } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { actions as UIActions } from '../../ui/portfolio/actions';
import { transformActiveFilters, waitFor } from '../../../services';
import { activeFiltersSelector } from '../../ui/portfolio/selectors';

export const [actions, reducer, resourceSagas] = resourceReducer('portfolios');

// Create the sagas for this entity
function* readPortfolios() {
  yield takeLatest(actions.indexRequestBegin, function* () {
    yield call(waitFor, (state) => state.ui.portfolio.initialized);
    const activeFilters = yield select(activeFiltersSelector);
    const args = transformActiveFilters(activeFilters);

    try {
      const [result, headers] = yield call(portfolios.get, args);
      const { meta } = result;
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, meta }));

      // We need to show the user the last time portfolio was updated/calculated
      // Get the timestamps from the headers and put them into the store
      const updatedAt = headers.get('x-source-last-modified');
      const calculatedAt = headers.get('x-source-last-calculation');
      if (updatedAt && calculatedAt) {
        yield put(
          UIActions.storePortfolioTimestamps({
            updatedAt: normalizeDate(updatedAt),
            calculatedAt: normalizeDate(calculatedAt),
          })
        );
      }
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readPortfolios, ...resourceSagas];

// Date from HTTP headers are formatted in the style of Tue, 15 Nov 1994 12:45:26 GMT
// so we need to convert this into a Date object.
const normalizeDate = (date) => new Date(date);
