import { closestPowerOfTen, formatBigMoney, nearest } from '@formue-app/core';
import React from 'react';
import styled from 'styled-components';
import { times } from 'lodash';

import { accent } from '../../constants/colors';
import { H4 } from '../texts';

const StyledContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const TickWrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 1px;
`;

const Line = styled.div`
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 100%;
  bottom: 23px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 1px;
    background-color: ${(props) => props.axisColor};
  }
`;

const Label = styled(H4)`
  font-size: 12px;
  position: relative;
  white-space: nowrap;
  padding-top: 10px;
  &:after {
    content: '';
    position: absolute;
    width: 1000px;
    top: 0;
    left: -150%;
    height: 1px;
    background-color: ${(props) => props.axisColor};
  }
`;

export const HorizontalBarChartAxis = (props) => {
  const {
    axisColor = accent.sand2,
    coef,
    max,
    min = 0,
    numberOfTicks = 5,
    valueFormat = formatBigMoney,
  } = props;

  const step = (max + min) / numberOfTicks;
  const nearestStep = nearest(step, closestPowerOfTen(step));

  return (
    <StyledContainer>
      <TickWrapper style={{ left: 0 }}>
        <Line style={{ backgroundColor: axisColor }} axisColor={axisColor} />
        <Label style={{ left: 3 }} axisColor={axisColor}>
          0
        </Label>
      </TickWrapper>
      {times(numberOfTicks, (index) => (
        <TickWrapper
          style={{ left: nearestStep * (index + 1) * coef }}
          key={`tick-wrapper-item-${index}`}
        >
          <Line style={{ backgroundColor: axisColor }} axisColor={axisColor} />
          <Label axisColor={axisColor}>
            {valueFormat(nearestStep * (index + 1), false)}
          </Label>
        </TickWrapper>
      ))}
    </StyledContainer>
  );
};
