import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { selectors, t, useResource } from '@formue-app/core';

import { accent, textLightest } from '../../../constants/colors';
import {
  easeInOut,
  longAnimationTime,
  shortAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
} from '../../../constants/spacing';

import { H1, H2, Paragraph } from '../../texts';
import { PortfolioConstructionList } from '../../portfolio/lenses/construction/PortfolioConstructionList';

import { ReactComponent as HexagonLeft } from '../../../assets/portfolio/hexagon-left.svg';
import { ReactComponent as HexagonRight } from '../../../assets/portfolio/hexagon-right.svg';

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 500px;
  background: ${accent.sand1};
  transition: width ${easeInOut} ${longAnimationTime};
  width: 100%;
`;

const HexagonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -170px;
  margin-top: -20px;
  translate: 0 -50%;

  span {
    position: absolute;
    top: 48%;
    left: 50%;
    translate: -50% -50%;
  }
`;

const LeftHexagonWrapper = styled(HexagonWrapper)`
  right: auto;
  left: 50%;
  margin-left: -150px;
  margin-top: 29px;
`;

const TriangleWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const TriangleBefore = styled.div`
  position: absolute;
  background: ${accent.velvet250};
  width: calc(50% - 300px);
  height: 100%;
`;

const Triangle = styled.div`
  position: absolute;
  width: 600px;
  height: 100%;
  clip-path: polygon(0% 0, 100% 100%, 0% 100%);
  background: ${accent.velvet250};
  left: 50%;
  margin-left: -300px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
  position: relative;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
  position: absolute;
  transition: all ${easeInOut} ${longAnimationTime};

  h1 {
    position: relative;
    opacity: 0;
    left: 20px;
    transition: all ${easeInOut} ${shortAnimationTime};
    transition-delay: ${slideAnimationDelay}s;
  }

  ${(props) =>
    props.isActiveSlide &&
    css`
      h1 {
        opacity: 1;
        left: 0;
      }
    `}
`;

const LeftPanel = styled(ContentWrapper)`
  width: 70%;
  justify-content: flex-end;
  padding-bottom: 200px;
`;

const RightPanel = styled(ContentWrapper)`
  width: 65%;
  left: 35%;
  top: 30px;
  align-items: flex-end;
`;

const Title = styled(H2)`
  max-width: 350px;
`;

const StyledPortfolioConstructionList = styled(PortfolioConstructionList)`
  padding: 0;
  margin-top: ${SPACING_48};

  & > p {
    font-size: 18px;
  }

  .list-item {
    p {
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
    }
  }
`;

const {
  entities: {
    securities: { securitiesMetadataSelector, allFunds, allSecurities },
  },
} = selectors;

export const PortfolioConstructionSlide = (props) => {
  const { isActiveSlide } = props;

  const securitiesMetadata = useSelector(securitiesMetadataSelector);
  const { distinctCompanies = 0, distinctFunds = 0 } = securitiesMetadata;

  const funds = useSelector(allFunds);
  const securities = useSelector(allSecurities);

  const loading = useResource(['SECURITIES/INDEX']);

  if (loading) return null;

  return (
    <>
      <Mask>
        <HexagonWrapper>
          <H1 as="span">{distinctFunds}</H1>
          <HexagonLeft />
        </HexagonWrapper>
        <TriangleWrapper>
          <TriangleBefore />
          <Triangle>
            <LeftHexagonWrapper>
              <H2 as="span">{distinctCompanies}</H2>
              <HexagonRight />
            </LeftHexagonWrapper>
          </Triangle>
        </TriangleWrapper>
      </Mask>
      <Wrapper>
        <LeftPanel isActiveSlide={isActiveSlide}>
          <Title>The strategy has exposure to 4284 companies.</Title>
          <Paragraph>
            Through holdings of equities and bonds of the funds.
          </Paragraph>
          <StyledPortfolioConstructionList
            data={securities.slice(0, 3)}
            title={t('portfolioConstruction:topCompanies', {
              companiesCount: 3,
            })}
            titleColor={accent.velvet490}
            textColor={accent.velvet490}
            numberBackgroundColor={accent.velvet3}
            numberColor={textLightest}
            active={isActiveSlide}
          />
        </LeftPanel>
        <RightPanel isActiveSlide={isActiveSlide}>
          <div>
            <Title>There are 62 funds in this strategy.</Title>
            <Paragraph>
              The funds have been selected in our manager selection process.
            </Paragraph>
            <StyledPortfolioConstructionList
              data={funds.slice(0, 3)}
              title={t('portfolioConstruction:topFunds', {
                fundsCount: 3,
              })}
              titleColor={accent.ocean490}
              textColor={accent.ocean490}
              numberBackgroundColor={accent.velvet3}
              numberColor={textLightest}
              active={isActiveSlide}
            />
          </div>
        </RightPanel>
      </Wrapper>
    </>
  );
};
