import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';

import { selectors, useResource } from '@formue-app/core';

import { GridElement } from '../layout/GridElement';
import { InfiniteScroll } from '../common/InfiniteScroll';
import { FeedFilters } from './FeedFilters';
import { EventFeedItem } from './EventFeedItem';
import { WebinarFeedItem } from './WebinarFeedItem';
import { ArticleFeedItem } from './ArticleFeedItem';
import { PodcastFeedItem } from './PodcastFeedItem';

const {
  entities: {
    feed: { allFeedItemsSelector },
  },
} = selectors;

export const Feed = memo(({ exclusiveItemType }) => {
  const [itemType, setItemType] = useQueryState('itemType', []);
  const [items, setItems] = useState([]);

  const numberOfFeedItemsPerPage = 20;
  const [numberOfFeedItemsToShow, setNumberOfFeedItemsToShow] = useState(
    numberOfFeedItemsPerPage
  );

  const loading = useResource([
    'FEED_ITEMS/INDEX',
    'FEED_CATEGORIES/INDEX',
    'EVENTS/INDEX',
    'WEBINARS/INDEX',
    'ARTICLES/INDEX',
  ]);

  const feed = useSelector(allFeedItemsSelector);
  useEffect(() => {
    // If we have exclusiveItemType set we want to exclude all other itemType-s
    if (exclusiveItemType) {
      setItems(feed.filter((item) => (item.itemType = exclusiveItemType)));
    } else {
      setItems(feed);
    }
  }, [feed, exclusiveItemType]);

  // filter items when itemTypes changes
  useEffect(() => {
    // reset the visible items when filtering
    setNumberOfFeedItemsToShow(numberOfFeedItemsPerPage);

    if (itemType.length) {
      setItems(
        feed.filter((item) => itemType.some((type) => type === item.itemType))
      );
    } else {
      // if no filter set default to showing all feed items
      setItems(feed);
    }
  }, [itemType.length, feed, itemType]);

  const renderItem = ({ itemType, itemId }) => {
    if (itemType === 'events') {
      return <EventFeedItem id={itemId} key={`feed-item-${itemId}`} />;
    }
    if (itemType === 'webinars') {
      return <WebinarFeedItem id={itemId} key={`feed-item-${itemId}`} />;
    }
    if (itemType === 'articles') {
      return <ArticleFeedItem id={itemId} key={`feed-item-${itemId}`} />;
    }
    if (itemType === 'podcasts') {
      return <PodcastFeedItem id={itemId} key={`feed-item-${itemId}`} />;
    }
  };

  const loadItems = (page) => {
    setNumberOfFeedItemsToShow((page + 1) * numberOfFeedItemsPerPage);
  };

  if (loading) return null;

  return (
    <>
      {
        // Render filters only if the Feed is supposed to include all "itemTypes"
        !exclusiveItemType && (
          <GridElement columns={12}>
            <FeedFilters onClick={(filters) => setItemType(filters)} />
          </GridElement>
        )
      }
      <InfiniteScroll resetIfChanges={itemType.length} next={loadItems}>
        {items.slice(0, numberOfFeedItemsToShow).map(renderItem)}
      </InfiniteScroll>
    </>
  );
});
