import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { H4 } from '../texts';
import { FlipArrow } from './FlipArrow';

import { SPACING_16 } from '../../constants/spacing';
import { tabletCondition } from '../../constants/media';
import { hoverBoxShadowLvl1 } from '../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

const StyledItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  text-decoration: none;
  padding: ${SPACING_16};
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  &:hover {
    box-shadow: ${hoverBoxShadowLvl1};
  }

  @media ${tabletCondition} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const StyledH4 = styled(H4)`
  padding-left: ${SPACING_16};
  word-break: break-word;
  width: 100%;
  padding-top: 2px;

  @media ${tabletCondition} {
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 4px;
  }
`;

const StyledArrow = styled.div`
  @media ${tabletCondition} {
    align-self: flex-end;
    margin-top: auto;
  }
`;

export const NavigatableItem = (props) => {
  const { text, to, children } = props;

  return (
    <StyledItem to={to}>
      {children}
      <StyledH4>{text}</StyledH4>

      <StyledArrow>
        <FlipArrow />
      </StyledArrow>
    </StyledItem>
  );
};
