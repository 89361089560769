import React from 'react';
import styled from 'styled-components';

import { SPACING_16 } from '../../constants/spacing';
import { notDesktopCondition } from '../../constants/media';

import { ButtonSmall } from '../buttons';
import { accent } from '../../constants/colors';
import { useSelector } from 'react-redux';
import {
  contentMaxWitdh,
  layoutMaxWitdh,
  menuClosedContentMaxWitdh,
  menuClosedWidth,
  menuMaxWidth,
} from '../../constants/grid';
import { t } from '@formue-app/core';

const Container = styled.div`
  padding: ${SPACING_16};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: ${(props) => (props.mainMenuOpen ? menuMaxWidth : menuClosedWidth)}px;
  background-color: ${accent.sand150};
  z-index: 0;
  @media ${notDesktopCondition} {
    left: 0;
  }
  max-width: ${layoutMaxWitdh}px;
  flex: 1;

  ${(props) =>
    !props.mainMenuOpen &&
    `
    max-width: ${layoutMaxWitdh + menuMaxWidth - menuClosedWidth}px;`}
`;

const InnerContainer = styled.div`
  max-width: ${layoutMaxWitdh}px;
  flex: 1;
  display: flex;

  max-width: ${(props) =>
    props.mainMenuOpen ? contentMaxWitdh : menuClosedContentMaxWitdh}px;
  justify-content: center;
`;

const StyledButton = styled(ButtonSmall)`
  background-color: ${accent.velvet1};

  @media ${notDesktopCondition} {
    left: 0;
  }
`;

export const InvestmentStrategySigningButton = (props) => {
  const { onClick, disabled } = props;
  const { mainMenuOpen } = useSelector((state) => state.ui.navigation);
  return (
    <Container mainMenuOpen={mainMenuOpen}>
      <InnerContainer mainMenuOpen={mainMenuOpen}>
        <StyledButton
          mainMenuOpen={mainMenuOpen}
          onClick={onClick}
          disabled={disabled}
        >
          {t('wealthPlan:investmentStrategy:proceedToSigning')}
        </StyledButton>
      </InnerContainer>
    </Container>
  );
};
