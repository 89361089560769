import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  selectors,
  t,
  useValuesForCountry,
  localizedEsgCategoryNames,
  useResource,
} from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2 } from '../../../texts';
import { MainGrid } from '../../../layout';
import { SPACING_16 } from '../../../../constants/spacing';
import { ArticleFeedItem } from '../../../feed/ArticleFeedItem';

const { createArticlesByCategorySelector } = selectors.entities.articles;

const StyledContainer = styled(BaseSection)`
  background: transparent;
`;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

export const SustainabilityNewsfeed = ({ ...props }) => {
  const loading = useResource([
    'FEED_ITEMS/INDEX',
    'FEED_CATEGORIES/INDEX',
    'EVENTS/INDEX',
    'WEBINARS/INDEX',
    'ARTICLES/INDEX',
  ]);

  const { esgCategoryName } = useValuesForCountry(
    localizedEsgCategoryNames,
    'no'
  );

  const articles = useSelector(
    createArticlesByCategorySelector(esgCategoryName)
  );

  const renderArticles = () => {
    return articles.slice(0, 3).map((item) => {
      return (
        <ArticleFeedItem columns={4} id={item.id} key={`article-${item.id}`} />
      );
    });
  };

  if (!articles.length) return null;

  return (
    <StyledContainer {...props}>
      <H2 style={{ paddingBottom: SPACING_16 }}>{t('esg:articles:title')}</H2>
      <StyledSubGrid>{renderArticles()}</StyledSubGrid>
    </StyledContainer>
  );
};
