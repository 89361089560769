import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import { Paragraph } from '../texts';
import { BaseSection } from '../common/BaseSection';
import { SPACING_12, SPACING_20 } from '../../constants/spacing';
import { Checkbox } from '../formElements';

const Container = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${SPACING_20};
`;

const AcceptanceWrapper = styled.div`
  display: flex;
  padding-top: ${SPACING_12};
  gap: ${SPACING_12};
`;

export const InvestmentStrategyCostsMessage = ({ costsAcceptedCallback }) => {
  const [termsAccepted, setTermsAccepted] = useState(true);

  useEffect(() => {
    costsAcceptedCallback(termsAccepted);
  }, [termsAccepted, costsAcceptedCallback]);

  return (
    <Container columns={12}>
      <Paragraph>{t('wealthPlan:investmentStrategy:costs:text')}</Paragraph>
      <AcceptanceWrapper>
        <Checkbox
          id="investment-strategy-costs"
          checked={termsAccepted}
          style={{ margin: 0 }}
          onChange={() => {
            setTermsAccepted(!termsAccepted);
          }}
        />
        <Paragraph style={{ fontWeight: 500 }}>
          {t('wealthPlan:investmentStrategy:costs:acceptanceText')}
        </Paragraph>
      </AcceptanceWrapper>
    </Container>
  );
};
