import React from 'react';
import styled from 'styled-components';
import { accent } from '../../constants/colors';

const StyledHr = styled.div`
  margin: 0;
  background-color: ${accent.sand1};
  height: 1px;
  width: 100%;
`;

export const HorizontalDivider = (props) => {
  return <StyledHr {...props} />;
};
