import { delay } from 'redux-saga/effects';
import {
  call,
  put,
  race,
  select,
  take,
  takeLatest,
  all,
} from 'redux-saga/effects';
import { actions as signingEventsActions } from '../../entities/resources/signingEvents';
import { actions } from './actions';

const inProgressSelector = state => state.ui.signingEvents.inProgress;
const signingEventsSelector = state => state.entities.signingEvents.byId;

/**
 * Uses a form of exponential backoff to call on loading the signing events
 * as long as we have any in progress, or we reach the max amount of requests
 * to poll with.
 */
function* poll() {
  const MAX_NUMBER_OF_ATTEMPTS = 10;
  let counter = 2;

  while (true) {
    const inProgress = yield select(inProgressSelector);

    try {
      // Fire of a request for each of the signing events in progress
      yield all(
        inProgress.map(id => put(signingEventsActions.showRequestBegin(id)))
      );
      yield delay(Math.pow(counter, 2) * 500);
    } catch (error) {
      yield put(actions.stopPolling());
    }
    counter++;
    const inProgressCount = inProgress.length;

    if (counter > MAX_NUMBER_OF_ATTEMPTS || inProgressCount < 1) {
      yield put(actions.stopPolling());
    }
  }
}

function* pollWatcher() {
  yield takeLatest(actions.startPolling, function*() {
    // Start a race between polling and stopPolling action. Whichever resolves (or rejects) first wins.
    // we do this so we exit out of the polling if we receive a stopPolling action.
    yield race([call(poll), take(actions.stopPolling)]);
  });
}

// Every time a read request is successfully completed for signing events we want to
// check if any of the ones in progress have completed, if so we move them out of the
// in progress list
function* signingEventsWatcher() {
  yield takeLatest(signingEventsActions.showRequestSuccess, function*() {
    const inProgress = yield select(inProgressSelector);
    if (inProgress.length > 0) {
      const signingEvents = yield select(signingEventsSelector);
      yield all(
        inProgress.map(id => {
          const signingEvent = signingEvents[id];
          if (signingEvent.status.toLowerCase() === 'completed') {
            return put(actions.removeFromInProgress(id));
          }
        })
      );
    }
  });
}

export const sagas = [pollWatcher, signingEventsWatcher];
