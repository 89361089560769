import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { formatMoney, t, selectors, getAssetClassName } from '@formue-app/core';

import { Paragraph, ParagraphSmall } from '../texts';
import { TableList, StyledRow, StyledCell } from '../lists/TableList';
import { Column } from '../todo/listItems/TodoListItem';

import { mobileCondition } from '../../constants/media';
import { SPACING_16, SPACING_8 } from '../../constants/spacing';
import { accent } from '../../constants/colors';
import { paragraphSmallSize } from '../../constants/text';

const {
  entities: {
    tradeOrders: { switchBuntTradeOrdersSelector },
  },
} = selectors;

const StyledColumn = styled(Column)`
  display: flex;
  align-items: center;
  padding: ${SPACING_16};
`;

const StyledMobileRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${SPACING_8};
`;

const Label = styled(ParagraphSmall)`
  min-width: 80px;
`;

const Value = styled(Paragraph)`
  font-weight: 500;
`;

const LightStyledCell = styled(StyledCell)`
  span {
    font-size: ${paragraphSmallSize};
    font-weight: 400;
  }
`;

const LightStyledRow = styled(StyledRow)`
  th {
    border-radius: 0;
  }

  &:nth-child(odd) {
    background: ${accent.sand150};
  }
`;

const translateAccoutNumber = (accountNumber) => {
  if (accountNumber === 'Kundekonto') {
    return t(`globals:customerAccount`);
  } else {
    return accountNumber;
  }
};

export const TradeOrders = ({ tradeOrders }) => {
  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  let columns = [
    {
      key: 'transactionType',
      label: t('portfolio:transaction'),
      render: ({ transactionType: type }) => t(`globals:${type.toLowerCase()}`),
      width: '15%',
      component: LightStyledCell,
    },
    {
      key: 'product',
      label: t('portfolio:product'),
      width: '40%',
      component: LightStyledCell,
    },
    {
      key: 'assetClass',
      label: t('portfolio:class'),
      render: ({ assetClassCode }) => getAssetClassName(assetClassCode),
      width: '15%',
      component: LightStyledCell,
    },
    {
      key: 'accountNumber',
      label: t('portfolio:account'),
      render: ({ accountNumber }) => translateAccoutNumber(accountNumber),
      width: '15%',
      component: LightStyledCell,
    },
    {
      key: 'amount',
      label: t('portfolio:value'),
      render: ({ amount }) => formatMoney(amount),
      width: '15%',
      component: LightStyledCell,
    },
  ];

  if (mobileSize) {
    columns = [
      {
        key: 'title',
        render: ({
          transactionType: type,
          assetClassCode,
          accountNumber,
          amount,
          product,
        }) => (
          <>
            <div>
              <StyledMobileRow>
                <Label>{t('portfolio:transaction')}</Label>
                <Value>{type}</Value>
              </StyledMobileRow>
              <StyledMobileRow>
                <Label>{t('portfolio:product')}</Label>
                <Value>{product}</Value>
              </StyledMobileRow>
              <StyledMobileRow>
                <Label>{t('portfolio:class')}</Label>
                <Value>{getAssetClassName(assetClassCode)}</Value>
              </StyledMobileRow>
              <StyledMobileRow>
                <Label>{t('portfolio:account')}</Label>
                <Value>{translateAccoutNumber(accountNumber)}</Value>
              </StyledMobileRow>
              <StyledMobileRow>
                <Label>{t('portfolio:value')}</Label>
                <Value>{formatMoney(amount)}</Value>
              </StyledMobileRow>
            </div>
          </>
        ),
        component: StyledColumn,
        width: '100%',
      },
    ];
  }

  const switchBuntTradeOrdersById = useSelector(switchBuntTradeOrdersSelector);

  const destructuredTradeOrders = [];

  for (let tradeOrder of tradeOrders) {
    const { switchId } = tradeOrder;
    const switchBunt = switchId ? switchBuntTradeOrdersById[switchId] : null;
    if (!switchBunt) {
      destructuredTradeOrders.push(tradeOrder);
    } else {
      switchBunt.map((switchTradeOrder) =>
        destructuredTradeOrders.push(switchTradeOrder)
      );
    }
  }

  return (
    <>
      <TableList
        showHeader={true}
        rows={destructuredTradeOrders}
        sortable={false}
        columns={columns}
        keyExtractor={(item, index) => `transaction-${index}-${item.id}`}
        rowComponent={LightStyledRow}
        style={{ paddingTop: SPACING_16 }}
      />
    </>
  );
};
