import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';

import { selectors, t, truncateString } from '@formue-app/core';

import { accent } from '../../../../constants/colors';
import { SPACING_24, SPACING_8 } from '../../../../constants/spacing';
import { H2, H3, H5, Paragraph, ParagraphSmall } from '../../../texts';
import {
  easeInOut,
  extraLongAnimationTime,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';

import { ReactComponent as MilestonesLine1 } from '../../../../assets/icons/presentation/milestones-line-1.svg';
import { ReactComponent as MilestonesLine2 } from '../../../../assets/icons/presentation/milestones-line-2.svg';
import { ReactComponent as MilestonesLine3 } from '../../../../assets/icons/presentation/milestones-line-3.svg';
import { ReactComponent as MilestonesLine4 } from '../../../../assets/icons/presentation/milestones-line-4.svg';
import { Question } from '../Question';
import { Modal } from '../../../common/Modal';
import { mobileCondition } from '../../../../constants/media';
import {
  H3LineHeight,
  H3Size,
  H4LineHeight,
  H4Size,
} from '../../../../constants/text';
import { useQueryState } from 'react-router-use-location-state';

const customAnimationDelay = slideAnimationDelay * 2;

const MilestoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  overflow: hidden;
  z-index: 2;

  & > div:nth-child(1) {
    position: absolute;
    top: 0;
    left: calc(50% + 88px);
    translate: -50%;

    @media ${mobileCondition} {
      left: calc(50% + 111px);
    }
  }

  & > div:nth-child(2) {
    position: absolute;
    top: 169px;
    right: calc(50% + 71px);
    translate: 50%;
  }

  & > div:nth-child(3) {
    position: absolute;
    top: 340px;
    left: calc(50% + 50px);
    translate: -50%;

    @media ${mobileCondition} {
      left: calc(50% + 70px);
    }
  }

  & > div:nth-child(4) {
    position: absolute;
    top: 516px;
    left: calc(50% - 51px);
    translate: -50%;

    @media ${mobileCondition} {
      left: calc(50% + -55px);
    }
  }
  & > div:nth-child(5) {
    position: absolute;
    top: 112px;
    left: calc(50% - 10px);
    translate: -50%;
  }
`;

const MilestoneItem = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  gap: ${SPACING_24};
  align-items: center;
  width: 450px;
`;

const StyledNumber = styled(H2)`
  color: ${accent.raspberry250};

  @media ${mobileCondition} {
    font-size: ${H3Size};
    line-height: ${H3LineHeight};
  }
`;

const StyledTitle = styled(H3)`
  color: ${accent.raspberry250};
  max-width: 300px;

  @media ${mobileCondition} {
    max-width: 200px;
    font-size: ${H4Size};
    line-height: ${H4LineHeight};
  }
`;

const Baloon = styled.div`
  display: flex;
  background-color: ${accent.raspberry1};
  border-radius: 50%;
  width: ${(props) => props.size || 98}px;
  height: ${(props) => props.size || 98}px;
  min-width: ${(props) => props.size || 98}px;
  min-height: ${(props) => props.size || 98}px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  scale: 0;
  transition: opacity ${easeInOut} ${longAnimationTime},
    scale ${easeInOut} ${longAnimationTime};
  transition-delay: ${(props) => customAnimationDelay + props.index * 0.2}s,
    ${(props) => customAnimationDelay + props.index * 0.2}s;

  ${(props) =>
    props.isActiveSlide &&
    `
    opacity: 1;
    scale: 1;
  `}

  @media ${mobileCondition} {
    width: ${(props) => props.size || 72}px;
    height: ${(props) => props.size || 72}px;
    min-width: ${(props) => props.size || 72}px;
    min-height: ${(props) => props.size || 72}px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  position: relative;
  opacity: 0;
  left: ${(props) => (props.reverse ? 30 : -30)}px;
  transition: all ${easeInOut} ${extraLongAnimationTime};
  transition-delay: ${(props) => customAnimationDelay + props.index * 0.2}s;

  ${(props) =>
    props.isActiveSlide &&
    `
      left: 0;
      opacity: 1;
  `}
`;

const Link = styled(H5)`
  color: ${accent.raspberry3};
  text-decoration: underline;
  cursor: pointer;
`;

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const StyledMilestonesLineWrapper = styled.span`
  margin-top: 51px;

  svg path {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;

    ${(props) =>
      props.isActiveSlide &&
      css`
        animation: ${dash}
          ${(props) => (props.exportMode ? '0s' : extraLongAnimationTime)}
          ${(props) => (props.exportMode ? 0 : customAnimationDelay)}s linear
          forwards;
      `}
  }
`;

const StyledQuestion = styled(Question)`
  translate: -50%;
  left: 26%;
  position: relative;
`;

const {
  entities: {
    familyAndOwnership: { milestonesSelector },
  },
} = selectors;

export const Milestone = (props) => {
  const { isActiveSlide, showQuestion = true, ...rest } = props;

  const [exportMode] = useQueryState('exportMode', false);
  const milestones = useSelector(milestonesSelector);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeMilestone, setActiveMilestone] = useState(null);

  const milestonesFiltered = [
    ...milestones.slice(0, 1),
    ...milestones.slice(
      // If milestones are less then the first item we default to 1 to not show items twice.
      milestones.length - 3 > 1 ? milestones.length - 3 : 1,
      milestones.length
    ),
  ];

  const count = milestones.length - milestonesFiltered.length;

  const remainingMilestonesCount = () => {
    return `+${count}`;
  };

  useEffect(() => {
    if (!isActiveSlide) {
      setModalIsOpen(false);
    }
  }, [isActiveSlide]);

  return (
    <MilestoneWrapper
      style={
        milestonesFiltered.length > 3
          ? {
              marginTop: -150,
              height: 'calc(100% + 150px)',
            }
          : null
      }
      {...rest}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        padding={64}
        backgroundColor={accent.sand1}
        contentWidth={500}
      >
        <Paragraph>{activeMilestone?.description}</Paragraph>
      </Modal>
      {milestonesFiltered.map((item, index) => (
        <MilestoneItem
          key={`milestone-${index}`}
          reverse={index === 1 || index === 3}
        >
          <Baloon isActiveSlide={isActiveSlide} index={index}>
            <StyledNumber>{item.year}</StyledNumber>
          </Baloon>
          <TextWrapper
            isActiveSlide={isActiveSlide}
            reverse={index === 1 || index === 3}
            index={index}
          >
            <StyledTitle>{truncateString(item.description, 95)}</StyledTitle>
            <Link
              onClick={() => {
                setActiveMilestone(item);
                setModalIsOpen(true);
              }}
            >
              {t('presentation:annualReview:familyAndOwnership:readMore')}
            </Link>
          </TextWrapper>
        </MilestoneItem>
      ))}
      {count > 0 && (
        <Baloon size={36} isActiveSlide={isActiveSlide} index={4}>
          <ParagraphSmall style={{ color: accent.raspberry250 }}>
            {remainingMilestonesCount()}
          </ParagraphSmall>
        </Baloon>
      )}
      <StyledMilestonesLineWrapper
        isActiveSlide={isActiveSlide}
        exportMode={exportMode}
      >
        {milestonesFiltered.length === 1 ? <MilestonesLine1 /> : null}
        {milestonesFiltered.length === 2 ? <MilestonesLine2 /> : null}
        {milestonesFiltered.length === 3 ? <MilestonesLine3 /> : null}
        {milestonesFiltered.length >= 4 ? <MilestonesLine4 /> : null}
      </StyledMilestonesLineWrapper>
      {showQuestion ? (
        <StyledQuestion
          isActiveSlide={isActiveSlide}
          bubbleColor={accent.raspberry1}
          textColor={accent.raspberry270}
          questionText={t(
            'presentation:annualReview:familyAndOwnership:question'
          )}
          customAnimationDelay={customAnimationDelay}
        />
      ) : null}
    </MilestoneWrapper>
  );
};
