import { apiFetch } from './utils/api';

export const verifyBankAccount = {
  verify: async (data) => {
    const options = {
      method: 'POST',
      body: data,
    };

    const url = '/v2/verify-bank-account';
    const { body } = await apiFetch(url, options);
    return body;
  },
};
