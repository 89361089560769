import React from 'react';

import { ExpertDocument } from './ExpertDocument';

export const ExpertDocumentList = (props) => {
  const { expertInputs } = props;

  return expertInputs.map((item, index) => (
    <ExpertDocument
      {...item}
      key={`document-list-item-${index}`}
      lastItem={index === expertInputs.length - 1}
    />
  ));
};
