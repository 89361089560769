import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { config } from '@formue-app/core';

import { Button } from '../buttons';
import { Input } from '../formElements';
import { textLightest } from '../../constants/colors';
import { BORDER_RADIUS_EXTRA_LARGE, SPACING_4 } from '../../constants/spacing';

import { actions as authActions } from '../../store/auth';

const StyledButton = styled(Button)`
  color: ${textLightest};
  border-radius: 0;
  border-top-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  border-bottom-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  position: absolute;
  right: 0;
  bottom: 0;
  height: 48px;
`;

const StyledForm = styled.form`
  position: relative;
  div {
    display: flex;
    flex-direction: column;
    gap: ${SPACING_4};
  }

  * > label {
    display: none;
  }
`;

export const UsernameLogin = (props) => {
  const { password: defaultPassword } = config.auth.usernameExchange;

  const [username, setUsername] = useState(props.username);
  const [password, setPassword] = useState(defaultPassword || '');
  const loading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  const onChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(
      authActions.startAuthentication({
        exchange: 'username',
        credentials: {
          username,
          password,
        },
      })
    );
  };

  return (
    <div>
      <StyledForm onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Username</label>
          <Input
            type="text"
            name="username"
            value={username}
            placeholder="Username"
            onChange={onChangeUsername}
          />
          <label htmlFor="password">Password</label>
          <Input
            type="password"
            name="password"
            value={password}
            placeholder="Password"
            onChange={onChangePassword}
          />
        </div>

        <StyledButton
          disabled={loading || !username || !password}
          label="Login"
        />
      </StyledForm>
    </div>
  );
};
