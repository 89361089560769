import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

import { localizeFloat } from '@formue-app/core';
import { RoundedBar } from './shapes/RoundedBar';
import { Trapezoid } from './shapes/Trapezoid';
import { ParagraphSmall, H5 } from '../texts';
import { SPACING_8 } from '../../constants/spacing';
import { backgroundWhite } from '../../constants/colors';
import { longAnimationTime } from '../../constants/animation';

const StyledContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  position: relative;
  z-index: 1;
`;

const AnimatedLabel = styled(animated.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Value = styled(H5)`
  margin-right: ${SPACING_8};
  line-height: 100%;
`;

export const HorizontalBar = (props) => {
  const {
    value,
    valueFormat = localizeFloat,
    label,
    color,
    barType = 'rounded',
    angle,
    barWidth = 20,
    labelsOutside,
    coef,
    isMaxValueBar,
    valueMargin = 13,
    barPadding = barWidth / 2 - 2,
    animate,
    animationDelay = 0,
    renderLabel,
    allEdgesRounded,
    index,
  } = props;

  const width = coef * value;

  const barStyles = useSpring({
    delay: animationDelay + 100 * index,
    duration: longAnimationTime,
    from: { width: 0, opacity: 0 },
    to: { width: animate ? width : 0, opacity: 1 },
  });

  const labelStyles = useSpring({
    delay: animationDelay + 100 * index,
    duration: longAnimationTime,
    from: { translateX: -width, opacity: 0 },
    to: { translateX: 0, opacity: 1 },
  });

  const maxLabelStyles =
    isMaxValueBar && labelsOutside
      ? {
          transform: 'translateX(-100%)',
          color: backgroundWhite,
          marginLeft: 0,
        }
      : {};

  return (
    <StyledContainer
      style={{
        paddingTop: barPadding,
        paddingBottom: barPadding,
      }}
    >
      {barType === 'triangle' && (
        <Trapezoid
          width={width}
          height={barWidth}
          style={animate ? barStyles : {}}
          color={color}
          angle={angle}
        />
      )}
      {barType === 'triangleInverted' && (
        <Trapezoid
          width={width}
          height={barWidth}
          style={animate ? barStyles : {}}
          color={color}
          angle={angle}
          inverted
        />
      )}
      {barType === 'rounded' && (
        <RoundedBar
          width={width}
          height={barWidth}
          style={animate ? barStyles : {}}
          color={color}
          allEdgesRounded={allEdgesRounded}
        />
      )}

      {renderLabel && (
        <AnimatedLabel
          style={{
            marginLeft: valueMargin,
            ...maxLabelStyles,
            ...(animate && labelStyles),
          }}
        >
          <Value
            style={
              isMaxValueBar && labelsOutside ? { color: backgroundWhite } : {}
            }
          >
            {valueFormat(value)}
          </Value>
          {!labelsOutside && (
            <ParagraphSmall style={{ lineHeight: '100%' }}>
              {label}
            </ParagraphSmall>
          )}
        </AnimatedLabel>
      )}
    </StyledContainer>
  );
};
