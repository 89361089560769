import React from 'react';
import styled from 'styled-components';

import {
  t,
  useSelfDeclarationFormData,
  selectors,
  useCountryOptions,
} from '@formue-app/core';

import { Paragraph, H2, H4 } from '../texts';
import { StyledGridElement } from '../layout/GridElement';
import { ContentList } from '../lists';
import { useSelector } from 'react-redux';
import { accent } from '../../constants/colors';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    max-width: 70%;
  }
`;

const { operatingCountrySelector } = selectors.auth;

export const SelfDeclarationOverview = (props) => {
  const { columns } = props;

  const { primaryCitizenship, internationalResident, politicallyExposed } =
    useSelfDeclarationFormData();
  const operatingCountry = useSelector(operatingCountrySelector);
  const countryOptions = useCountryOptions();

  return (
    <StyledGridElement columns={columns}>
      <H2 style={{ marginBottom: 20 }}>{t('profile:selfDeclaration:title')}</H2>
      <ContentList
        label={t('globals:edit')}
        url="/profile/self-declaration"
        rows={[
          {
            content: (
              <Content>
                <Paragraph>
                  {t('profile:selfDeclaration:citizenship:title')}
                </Paragraph>
                {primaryCitizenship ? (
                  <H4>
                    {
                      countryOptions.find(
                        (item) =>
                          item.value === primaryCitizenship.toLowerCase()
                      ).label
                    }
                  </H4>
                ) : (
                  <H4 style={{ color: accent.raspberry1 }}>
                    {t('globals:missing')}
                  </H4>
                )}
              </Content>
            ),
          },
          {
            content: (
              <Content>
                <Paragraph>
                  {t('profile:selfDeclaration:internationalResident', {
                    context: operatingCountry,
                  })}
                </Paragraph>
                {typeof internationalResident === 'boolean' ? (
                  <H4>
                    {internationalResident ? t('globals:yes') : t('globals:no')}
                  </H4>
                ) : (
                  <H4 style={{ color: accent.raspberry1 }}>
                    {t('globals:missing')}
                  </H4>
                )}
              </Content>
            ),
          },
          {
            content: (
              <Content>
                <Paragraph>
                  {t('profile:selfDeclaration:politicallyExposed')}
                </Paragraph>
                {typeof politicallyExposed === 'boolean' ? (
                  <H4>
                    {politicallyExposed ? t('globals:yes') : t('globals:no')}
                  </H4>
                ) : (
                  <H4 style={{ color: accent.raspberry1 }}>
                    {t('globals:missing')}
                  </H4>
                )}
              </Content>
            ),
          },
        ]}
      />
    </StyledGridElement>
  );
};
