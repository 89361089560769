import React from 'react';

import { SpriteIcon } from '../common/SpriteIcon';

import { SPACING_32 } from '../../constants/spacing';

export const ExtendedReportingIcon = ({ type, disabled, ...rest }) => {
  switch (type) {
    case 'overview':
      return (
        <SpriteIcon
          id="Overview"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );
    case 'holdings':
      return (
        <SpriteIcon
          id="Capital"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );
    case 'return':
      return (
        <SpriteIcon
          id="Investment"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );
    case 'geography':
      return (
        <SpriteIcon
          id="Position"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );
    case 'sustainability':
      return (
        <SpriteIcon
          id="Sustainability"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );
    case 'sectors':
      return (
        <SpriteIcon
          id="Company"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );
    case 'exposure':
      return (
        <SpriteIcon
          id="Risk"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );
    case 'strategy':
      return (
        <SpriteIcon
          id="Portfolio"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );
    case 'benchmarking':
      return (
        <SpriteIcon
          id="Results"
          navigational={disabled}
          size={SPACING_32}
          {...rest}
        />
      );

    default:
      return null;
  }
};
