import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  selectors,
  t,
  formatCashflowAxisPointsData,
  getAssetClassName,
  combineCashflowReportsData,
} from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
  SPACING_16,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { mobileCondition } from '../../../constants/media';

import { MultipleSwitch, Toggle } from '../../formElements';
import { BarChart } from '../../graphs/BarChart';
import { H1 } from '../../texts';
import { StrategyPicker } from '../../portfolio/strategy/StrategyPicker';
import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';

const Base = styled.div`
  height: 100%;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const RowWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &:last-child {
    margin-right: 0;
  }
`;

const Filters = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${SPACING_16};
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-left: auto;

  @media ${mobileCondition} {
    margin-left: 0;
  }
`;

const {
  entities: {
    illiquidCashflow: {
      createIlliquidCashflowByAssetClassSelector,
      allIlliquidCashflowStrategiesSelector,
    },
  },
} = selectors;

export const CashFlowSlide = (props) => {
  const privateEquity = useSelector(
    createIlliquidCashflowByAssetClassSelector('p')
  );
  const property = useSelector(createIlliquidCashflowByAssetClassSelector('i'));
  const allStrategies = useSelector(allIlliquidCashflowStrategiesSelector);

  const [activeAssetClass, setActiveAssetClass] = useState('p');
  const [includeExternal, setIncludeExternal] = useState(true);

  if (!property || !allStrategies) return <SlideErrorMessage />;

  const privateEquityData = combineCashflowReportsData(
    privateEquity,
    includeExternal
  );
  const propertyData = combineCashflowReportsData(property, includeExternal);
  const axisPoints = formatCashflowAxisPointsData(allStrategies);

  return (
    <Base>
      <Wrapper>
        <RowWrapper>
          <StrategyPicker
            style={{
              position: 'absolute',
              right: 95,
              zIndex: 5,
              marginTop: 10,
            }}
            textColor={accent.velvet1}
            color={accent.velvet2}
            arrowColor={accent.velvet1}
            hoverColor={accent.velvet270}
            filterStrategies={false}
          />
          <ContentWrapper style={{ alignSelf: 'flex-start' }}>
            <H1 style={{ color: accent.velvet4, paddingRight: 360 }}>
              {t('presentation:annualReview:cashFlow:text', {
                assetClass: getAssetClassName(activeAssetClass),
              })}
            </H1>

            <Filters>
              <Toggle
                value={includeExternal}
                onChange={() => setIncludeExternal(!includeExternal)}
                label={t(
                  'presentation:annualReview:cashFlow:includeExternalReports'
                )}
                small={true}
                labelColor={accent.velvet1}
                backgroundColor={accent.velvet1}
                backgroundColorActive={accent.velvet1}
              />
              <StyledMultipleSwitch
                backgroundColor={accent.velvet270}
                activeBackgroundColor={accent.velvet1}
                color={accent.velvet1}
                onChange={(value) => {
                  setActiveAssetClass(value);
                }}
                options={[
                  {
                    label: t(
                      'presentation:annualReview:cashFlow:privateEquity'
                    ),
                    value: 'p',
                  },
                  {
                    label: t('presentation:annualReview:cashFlow:realEstate'),
                    value: 'i',
                  },
                ]}
              />
            </Filters>
          </ContentWrapper>
          <ContentWrapper
            style={{ flex: 1, padding: `${SPACING_48} 0`, overflow: 'hidden' }}
          >
            <ChartWrapper className="resetZoom">
              <BarChart
                data={
                  activeAssetClass === 'p' ? privateEquityData : propertyData
                }
                axisPoints={axisPoints[activeAssetClass]}
                valueKey="amount"
                labelKey="year"
                yAxis={true}
                zeroAxis={true}
                color={accent.velvet1}
                showValue={false}
                paddingRight={160}
                labelBackgroundColor={accent.velvet250}
              />
            </ChartWrapper>
          </ContentWrapper>
        </RowWrapper>
      </Wrapper>
    </Base>
  );
};
