import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Sentry } from '@formue-app/core';

import { actions as authActions } from '../store/auth';
import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';

const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const AuthCallbackPage = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['codeVerifier']);
  const params = new URLSearchParams(document.location.search.substring(1));
  const [code] = useState(params.get('code'));
  const [error, setError] = useState(params.get('error'));
  const [codeVerifier] = useState(
    cookies.codeVerifier || window.sessionStorage.getItem('codeVerifier')
  );

  Sentry.setContext('AuthCallback', {
    code,
    codeVerifier,
    error,
    params,
  });

  useEffect(() => {
    if (code) {
      dispatch(
        authActions.startAuthentication({
          exchange: 'code',
          credentials: {
            code,
            codeVerifier,
          },
        })
      );
    }
  }, [code, codeVerifier, dispatch]);

  if (!codeVerifier) {
    setError('CookieProblem');
    Sentry.captureException(new Error(error));

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { error },
        }}
      />
    );
  }

  if (error) {
    Sentry.captureException(new Error(error));
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { error },
        }}
      />
    );
  }

  return (
    <LoadingContainer>
      <CenteredActivityIndicator />
    </LoadingContainer>
  );
};
