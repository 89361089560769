import React from 'react';
import styled from 'styled-components';

import { SPACING_24 } from '../../constants/spacing';
import { StyledBaseSection } from '../common/BaseSection';
import { mobileCondition } from '../../constants/media';

const StyledBaseListItem = styled(StyledBaseSection)`
  display: flex;
  flex-flow: row nowwrap;
  align-items: center;
  padding: 0;
  gap: ${SPACING_24};

  > svg {
    flex-shrink: 0;

    @media ${mobileCondition} {
      display: none;
    }
  }
`;

export const BaseListItem = (props) => {
  const { children, style } = props;

  return (
    <StyledBaseListItem as="article" {...props} style={style}>
      {children}
    </StyledBaseListItem>
  );
};
