import React from 'react';
import styled from 'styled-components';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_32,
} from '../../../constants/spacing';
import { BusinessAndOwnership } from '../slideComponents/familyAndOwnership/businessAndOwnership';
import { Family } from '../slideComponents/familyAndOwnership/family';
import { Milestone } from '../slideComponents/familyAndOwnership/milestone';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const FamilyAndOwnershipWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 70px;
  margin-top: ${SPACING_32};
`;

const ContentWrapper = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
`;

export const FamilyAndOwnership = (props) => {
  const { isActiveSlide } = props;

  return (
    <Wrapper>
      <FamilyAndOwnershipWrapper>
        <ContentWrapper>
          <Family isActiveSlide={isActiveSlide} />
          <BusinessAndOwnership isActiveSlide={isActiveSlide} />
        </ContentWrapper>
        <ContentWrapper>
          <Milestone isActiveSlide={isActiveSlide} />
        </ContentWrapper>
      </FamilyAndOwnershipWrapper>
    </Wrapper>
  );
};
