import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  selectors,
  toLocaleDateString,
  t,
} from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_16,
  SPACING_32,
  SPACING_64,
  SPACING_120,
} from '../../../constants/spacing';
import { H1, H2, Paragraph, ParagraphXSmall } from '../../texts';
import { Logo } from '../../common/Logo';

const Title = styled(H1)`
  margin-bottom: ${SPACING_32};
  margin-top: ${SPACING_16};
  font-size: 84px;
`;

const TextContainer = styled.div`
  width: 60%;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const StyledAboutText = styled(Paragraph)`
  margin-top: ${SPACING_64}
`;

const {
  entities: {
    investmentStrategies: { investmentStrategyByIdSelector },
  },
} = selectors;

export const WelcomeSlideExport = (props) => {
  const { title } = props;
  const { search } = useLocation();
  const strategyId = new URLSearchParams(search).get('strategyId');
  const strategy = useSelector(investmentStrategyByIdSelector(strategyId));

  return (
    <TextContainer>
      <Logo style={{ marginBottom: SPACING_120 }} height="24" />
      <ParagraphXSmall>{toLocaleDateString(new Date())}</ParagraphXSmall>
      <Title>{t(title)}</Title>
      <H2>{strategy?.accountName}</H2>
      <StyledAboutText>{t('advisor:strategySimulator:export:aboutDocument')}</StyledAboutText>
    </TextContainer>
  );
};
