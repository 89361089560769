import { handleActions } from 'redux-actions';

import { actions } from './actions';
export { actions };

const defaultState = {
  activeExpertInput: null,
};

export const reducer = handleActions(
  {
    [actions.setActiveExpertInput]: (state, { payload }) => ({
      ...state,
      activeExpertInput: payload,
    }),
  },
  defaultState
);
