import { AsyncStorage } from '../AsyncStorage';

export const markItemAsRead = async (type, id) => {
  let items = [];
  try {
    const loadItems = await AsyncStorage.getItem(type);
    if (loadItems !== null) {
      // We have data!!
      items = JSON.parse(loadItems);
      if (!items.includes(id)) {
        items.push(id);
        try {
          await AsyncStorage.setItem(type, JSON.stringify(items));
        } catch (error) {
          // We dont't really care if this fails
        }
      }
    } else {
      try {
        await AsyncStorage.setItem(type, JSON.stringify([id]));
      } catch (error) {
        // We dont't really care if this fails
      }
    }
  } catch (error) {
    // We dont't really care if this fails
  }
};

export const getReadItems = async type => {
  try {
    const items = await AsyncStorage.getItem(type);
    if (items !== null) {
      // We have data!!
      return JSON.parse(items);
    }
    return null;
  } catch (error) {
    // Error retrieving data
    return null;
  }
};
