import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { illiquidCashflow } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the illiquidCashflow resource
export const [actions, reducer, resourceSagas] =
  resourceReducer('illiquid-cashflows');

// Create the sagas for this entity
function* readIlliquidCashflow() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(illiquidCashflow.get, args);
      const { meta } = result;
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, meta, args }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readIlliquidCashflow, ...resourceSagas];
