import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { t } from '@formue-app/core';
import { CoveragePyramid } from './CoveragePyramid';
import { H2, Paragraph, ParagraphXSmall } from '../../texts';
import { MainGrid } from '../../layout';
import { BaseSection } from '../../common/BaseSection';
import {
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledBaseSection = styled(BaseSection)`
  padding: 0;
`;

const Tip = styled(Paragraph)`
  font-style: italic;
  padding-bottom: ${SPACING_32};
`;

export const CoverageReadMore = (props) => {
  return (
    <StyledSubGrid>
      <StyledBaseSection columns={6}>
        <H2 style={{ marginBottom: SPACING_16, marginTop: SPACING_24 }}>
          {t('esg:coverageReadMore:title')}
        </H2>
        <Tip>
          <Trans
            i18nKey="esg:coverageReadMore:tip"
            components={{
              strong: (
                <span
                  style={{
                    fontWeight: 700,
                  }}
                />
              ),
            }}
          />
        </Tip>

        <Paragraph style={{ fontWeight: 500, marginBottom: SPACING_8 }}>
          {t('esg:coverageReadMore:subTitle1')}
        </Paragraph>
        <Paragraph style={{ marginBottom: SPACING_8 }}>
          {t('esg:coverageReadMore:paragraph1')}
        </Paragraph>
        <ParagraphXSmall style={{ marginBottom: SPACING_32 }}>
          <Trans
            i18nKey="esg:coverageReadMore:excluded"
            components={{
              strong: (
                <span
                  style={{
                    fontWeight: 700,
                  }}
                />
              ),
            }}
          />
        </ParagraphXSmall>
        <Paragraph style={{ fontWeight: 500, marginBottom: SPACING_8 }}>
          {t('esg:coverageReadMore:subTitle2')}
        </Paragraph>
        <Paragraph style={{ marginBottom: SPACING_32 }}>
          {t('esg:coverageReadMore:paragraph2')}
        </Paragraph>
      </StyledBaseSection>
      <StyledBaseSection
        columns={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CoveragePyramid />
        <ParagraphXSmall style={{ textAlign: 'right', marginRight: 30 }}>
          {t('esg:coverageReadMore:pyramid:astrix')}
        </ParagraphXSmall>
      </StyledBaseSection>
    </StyledSubGrid>
  );
};
