import React, { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { t } from '@formue-app/core';
import { BaseSection } from '../common/BaseSection';
import { StyledGridElement } from '../layout';
import { Paragraph, H2, H4 } from '../texts';
import { SPACING_16 } from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';
import SelectArrowIconOpen from '../../assets/icons/form-elements/select-arrow-icon-open.svg';
import SelectArrowIconClose from '../../assets/icons/form-elements/select-arrow-icon-close.svg';
import {
  backgroundWhite,
  blackTranslucent,
  primary,
} from '../../constants/colors';
import { BORDER_RADIUS_LARGE } from '../../constants/spacing';

const StyledLink = styled.a`
  text-decoration: none;
`;

const StyledBaseSection = styled(BaseSection)`
  padding: 0px;
`;

const StyledNav = styled.nav`
  z-index: 2;
  padding: 10px ${SPACING_16};
  cursor: pointer;

  ul {
    visibility: hidden;
    display: none;
    opacity: 0;
    transition: opacity 250ms linear;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  background-image: url(${SelectArrowIconOpen});
  background-repeat: no-repeat;
  background-position: right 12px top 10px;

  &.active {
     background: ${backgroundWhite};
     box-shadow: 9px 9px 33px ${blackTranslucent(0.2)};
     border-radius: ${BORDER_RADIUS_LARGE};

    ul {
      visibility: visible;
      display: inherit;
      cursor: default;
      opacity: 1;
    }

    span {
      color: ${primary};
    }

    background-image: url(${SelectArrowIconClose});
    background-repeat: no-repeat;
    background-position: right 12px top 10px;
  }
}`;

export const PrivacyPolicy = (props) => {
  const [open, setOpen] = useState(false);
  const { className, columns = 6 } = props;
  const url = t(`settings:privacyPolicy:link`);
  const isMobile = useMediaQuery({
    query: mobileCondition,
  });

  return (
    <StyledGridElement columns={columns}>
      {!isMobile && (
        <>
          <H2 style={{ marginBottom: 20 }}>
            {t(`settings:privacyPolicy:title`)}
          </H2>
          <BaseSection>
            <Paragraph>{t(`settings:privacyPolicy:body`)}</Paragraph>
            <StyledLink href={url} target="_blank">
              <H4 style={{ marginTop: SPACING_16 }}>
                {t(`settings:privacyPolicy:readMore`)}
              </H4>
            </StyledLink>
          </BaseSection>
        </>
      )}

      {isMobile && (
        <StyledBaseSection>
          <StyledNav className={[className, open ? 'active' : '']}>
            <H4
              style={{ paddingTop: 4, paddingBottom: 4 }}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {t(`settings:privacyPolicy:title`)}
            </H4>
            <ul>
              <li>
                <Paragraph style={{ marginTop: 4 }}>
                  {t(`settings:privacyPolicy:body`)}
                </Paragraph>
                <StyledLink href={url} target="_blank">
                  <H4 style={{ marginTop: SPACING_16 }}>
                    {t(`settings:privacyPolicy:readMore`)}
                  </H4>
                </StyledLink>
              </li>
            </ul>
          </StyledNav>
        </StyledBaseSection>
      )}
    </StyledGridElement>
  );
};
