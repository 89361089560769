import { accent } from '../../constants/colors';

export const generateWealthBalanceAccentColors = (length, type = 'debt') => {
  const colorsArray = [...Array(length)];
  const accentColors = [
    accent.ocean1,
    accent.ocean2,
    accent.ocean3,
    accent.ocean4,
  ];
  return colorsArray.map((item, index) => {
    const accentColorsLength = accentColors.length;
    return accentColors[index % accentColorsLength];
  });
};

export const generateStrategyAccentColors = (index) => {
  // This is temporary. In future will probably need to limit the number of possible
  // strategies that we are showing, hence the colorScheme might look different.
  const colorScheme = [
    accent.ocean4,
    accent.velvet3,
    accent.ocean3,
    accent.velvet1,
    accent.raspberry3,
    accent.raspberry1,
  ];

  if (index >= 0) {
    return colorScheme[index];
  } else {
    return colorScheme;
  }
};

export const strategyCostColorMap = {
  ongoingServiceFee: '#45326A',
  productCost: '#6D4694',
  oneTimeFee: accent.velvet2,
  advisoryFee: accent.velvet2,
  structuringFee: accent.velvet3,
};
