import React from 'react';

import { lightenColor } from '@formue-app/core';
import { accent, backgroundWhite } from '../../../constants/colors';
import { SpaghettiChart } from '../../charts/SpaghettiChart';

export const InvestmentStrategyAreaChart = (props) => {
  const { chartData, valueKey, formatLineData, ...rest } = props;

  const dataObject = {
    currentStrategy: chartData,
  };

  return (
    <SpaghettiChart
      height={450}
      fontSizeY={10}
      fontSizeX={10}
      dataObject={dataObject}
      valueKey={valueKey}
      xAxisTicks={chartData.map((value) => new Date(value.changedAt))}
      xAxisTicksFormat={(changedAt) => changedAt.getFullYear()}
      yAxisTicksFormat={(value) =>
        new Intl.NumberFormat('no', { notation: 'standard' }).format(
          value.toFixed(0)
        )
      }
      domainPadding={{ x: [18, 5], y: [10, 30] }}
      selected={['currentStrategy']}
      loading={false}
      labelFormat={(entryKey) => entryKey}
      ticksColor={accent.ocean170}
      ticksBackground={backgroundWhite}
      gridColor={lightenColor(accent.ocean440, 0.4)}
      tickCount={11}
      formatLineData={formatLineData}
      padding={{ bottom: 25, top: 15 }}
      animate={false}
      showLineLabels={false}
      tooltipEnabled
      ticksWidth={40}
      defaultLineColor={accent.ocean4}
      areaChart
      areaChartOpacity={0.04}
      strokeWidth={2}
      {...rest}
    />
  );
};
