import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  combineFamilyGroups,
  selectors,
  sortByFamilyhierarchy,
  t,
  toLocaleDateString,
  useResource,
} from '@formue-app/core';

import {
  signingTasksSelector,
  unreadSelector,
} from '../../store/ui/todo/selectors';

import {
  BORDER_RADIUS_LARGE,
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { accent, whiteTranslucent } from '../../constants/colors';

import { GridElement } from '../layout';
import { H3, Paragraph, ParagraphSmall } from '../texts';
import { ProfileIcon } from '../common/ProfileIcon';
import { FlipArrow } from '../common/FlipArrow';
import { ActivityIndicator } from '../common/ActivityIndicator';
import { SpriteIcon } from '../common/SpriteIcon';

import { BusinessAndOwnershipItem } from '../presentation/slideComponents/familyAndOwnership/businessAndOwnershipItem';
import { FamilyItem } from '../presentation/slideComponents/familyAndOwnership/family';

const Wrapper = styled(GridElement)`
  border-radius: ${BORDER_RADIUS_LARGE};
  background: ${accent.ocean220};
  padding: ${SPACING_16};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Item = styled.div`
  background: ${whiteTranslucent(0.4)};
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};
  width: calc((100% - ${SPACING_16} * 2) / 3);
`;

const TextWrapper = styled.div`
  p {
    color: ${accent.ocean470};
  }
`;

const FamilyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${SPACING_24};
`;

const StyledFamilyItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
  padding-right: ${SPACING_24};
  border-right: 2px solid ${accent.ocean250};

  &:first-child {
    margin-left: 0;
    padding-right: 0;
    border: none;
  }

  &:nth-last-child(2) {
    padding-right: 0;
    border: none;
  }
`;

const SeeMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  cursor: pointer;
`;

const StyledFamilyAndOwnershipDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: ${SPACING_32};

  & > div {
    width: calc((100% - 32px) / 2);
  }
`;

const FamilyGroup = styled.div`
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &:not(:first-child) {
    margin-top: ${SPACING_32};
  }
`;

const FamilyInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${SPACING_16};
`;

const OrganizationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${SPACING_16};
`;

const {
  entities: {
    familyAndOwnership: { organizationSelector, familyMainMembersSelector },
  },
  ui: {
    user: { currentUserSelector },
  },
} = selectors;

export const FamilyAndOwnershipDetails = (props) => {
  const { organizations, familyMembers } = props;

  const [activeFamilyMember, setActiveFamilyMember] = useState(null);
  const [activeOrganization, setActiveOrganization] = useState(null);

  return (
    <StyledFamilyAndOwnershipDetails>
      <div>
        <H3 style={{ marginBottom: SPACING_24 }}>
          {t('wealthPlan:familyAndOwnership:family:title')}
        </H3>
        {Object.keys(combineFamilyGroups(familyMembers))
          .sort(sortByFamilyhierarchy)
          .map((key, familyMembersIndex) => (
            <FamilyGroup
              key={`familymembers-${familyMembersIndex}-${key}`}
              index={familyMembersIndex}
            >
              <FamilyInner>
                {familyMembers[key].map((item, familyMemberIndex) => (
                  <FamilyItem
                    index={familyMemberIndex}
                    key={`familymember-${key}-${item.id}`}
                    onClick={() =>
                      setActiveFamilyMember(
                        activeFamilyMember === item.id ? null : item.id
                      )
                    }
                    active={activeFamilyMember === item.id}
                    item={item}
                    color={accent.ocean270}
                    hoverColor={accent.ocean250}
                    textColor={accent.ocean490}
                  />
                ))}
              </FamilyInner>
            </FamilyGroup>
          ))}
      </div>
      {organizations.length ? (
        <div>
          <H3 style={{ marginBottom: SPACING_24 }}>
            {t('wealthPlan:familyAndOwnership:businessAndOwnership:title')}
          </H3>
          <OrganizationWrapper>
            {organizations.map((item, index) => (
              <BusinessAndOwnershipItem
                item={item}
                index={index}
                itemsPrRow={2}
                key={`business-${index}`}
                isActiveSlide={true}
                activeOrganization={activeOrganization}
                setActiveOrganization={setActiveOrganization}
                color={accent.ocean270}
                textColor={accent.ocean490}
              />
            ))}
          </OrganizationWrapper>
        </div>
      ) : null}
    </StyledFamilyAndOwnershipDetails>
  );
};

export const ClientMetadata = () => {
  useResource(['FAMILY_MEMBERS/INDEX', 'ORGANIZATIONS/INDEX']);
  // Load documents individually to get individual loading states
  const documentsLoading = useResource(['DOCUMENTS/INDEX']);
  const signingEventsLoading = useResource(['SIGNING_EVENTS/INDEX']);

  const numberOfTasksToSign = useSelector(signingTasksSelector).length;
  const numberOfUnreadDocuments = useSelector(unreadSelector).length;
  const familyMembers = useSelector(familyMainMembersSelector);
  const organizations = useSelector(organizationSelector);
  const currentUser = useSelector(currentUserSelector);

  const familyGroups = combineFamilyGroups(familyMembers);

  const [detailsOpen, setDetailsOpen] = useState(false);

  return (
    <Wrapper columns={12}>
      <Item>
        <SpriteIcon id="Complete" size={SPACING_24} />
        <TextWrapper>
          {currentUser ? (
            <H3>
              {currentUser?.lastLoginAt
                ? toLocaleDateString(currentUser.lastLoginAt)
                : '–'}
            </H3>
          ) : (
            <ActivityIndicator size={21} />
          )}
          <ParagraphSmall>{t('advisor:lastLogin')}</ParagraphSmall>
        </TextWrapper>
      </Item>
      <Item>
        <SpriteIcon id="Document Multi" size={SPACING_24} />
        <TextWrapper>
          {!documentsLoading ? (
            <H3>{numberOfUnreadDocuments}</H3>
          ) : (
            <ActivityIndicator size={21} />
          )}
          <ParagraphSmall>{t('advisor:unreadDocuments')}</ParagraphSmall>
        </TextWrapper>
      </Item>
      <Item>
        <SpriteIcon id="Signature" size={SPACING_24} />
        <TextWrapper>
          {!signingEventsLoading ? (
            <H3>{numberOfTasksToSign}</H3>
          ) : (
            <ActivityIndicator size={21} />
          )}
          <ParagraphSmall>{t('advisor:documentsToSign')}</ParagraphSmall>
        </TextWrapper>
      </Item>
      {/* <Item>
        <ChatIcon />
        <TextWrapper>
          <H3>…</H3>
          <ParagraphSmall>Content coming soon</ParagraphSmall>
        </TextWrapper>
      </Item> */}
      <Item
        style={{
          width: '100%',
          marginTop: SPACING_16,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <ParagraphSmall style={{ marginBottom: SPACING_8 }}>
          {t('advisor:costumerOverviewTitle')}
        </ParagraphSmall>
        <FamilyWrapper>
          {familyGroups.mainmember.map((familyMember, index) => (
            <StyledFamilyItem key={`family-${index}`}>
              <ProfileIcon
                size={24}
                name={familyMember.name}
                color={accent.ocean270}
              />
              <H3>{familyMember.name}</H3>
            </StyledFamilyItem>
          ))}
          {familyGroups.child ? (
            <StyledFamilyItem>
              <ProfileIcon
                size={24}
                name={familyGroups.child.length}
                color={accent.ocean270}
              />
              <H3>
                {t(
                  'wealthPlan:familyAndOwnership:family:memberCategories:child'
                )}
              </H3>
            </StyledFamilyItem>
          ) : null}
          {familyGroups.grandChildren ? (
            <StyledFamilyItem>
              <ProfileIcon
                size={24}
                name={familyGroups.grandChildren.length}
                color={accent.ocean270}
              />
              <H3>
                {t(
                  'wealthPlan:familyAndOwnership:family:memberCategories:grandchild'
                )}
              </H3>
            </StyledFamilyItem>
          ) : null}
          {organizations.length ? (
            <StyledFamilyItem>
              <ProfileIcon
                size={24}
                name={organizations.length}
                color={accent.ocean270}
              />
              <H3>
                {t(
                  'wealthPlan:familyAndOwnership:businessAndOwnership:companies'
                )}
              </H3>
            </StyledFamilyItem>
          ) : null}
          <SeeMoreWrapper onClick={() => setDetailsOpen(!detailsOpen)}>
            <Paragraph>
              {!detailsOpen ? t('advisor:seeMore') : t('advisor:seeLess')}
            </Paragraph>
            <FlipArrow
              backgroundColor="none"
              color={accent.ocean4}
              direction={detailsOpen ? 'up' : 'down'}
            />
          </SeeMoreWrapper>
        </FamilyWrapper>
        {detailsOpen ? (
          <FamilyAndOwnershipDetails
            organizations={organizations}
            familyMembers={familyMembers}
          />
        ) : null}
      </Item>
    </Wrapper>
  );
};
