import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';

import { SPACING_24 } from '../../../constants/spacing';
import { accent, textLightest } from '../../../constants/colors';

import { MainGrid } from '../../layout';
import { ModalWithHeader } from '../../common/ModalWithHeader';

import { InvestmentPreferencesChoicesContent } from '../../wealthPlan/investmentProfile/InvestmentPreferencesChoices';
import { InvestmentPreferencesContent } from '../../wealthPlan/investmentProfile/InvestmentPreferencesContent';

import { ReactComponent as PreferencesIcon } from '../../../assets/icons/advisor-corner/preferences-green-icon.svg';

const StyledMainGrid = styled(MainGrid)`
  background: ${accent.sand1};
  padding: ${SPACING_24};
`;

const {
  entities: {
    investmentProfile: { sustainabilityPreferencesSelector },
  },
} = selectors;

export const PreferencesModal = (props) => {
  const { onRequestClose, ...rest } = props;

  const sustainabilityPreferences = useSelector(
    sustainabilityPreferencesSelector
  );

  return (
    <ModalWithHeader
      onRequestClose={onRequestClose}
      padding={0}
      iconSize="12px"
      title={t('wealthPlan:investmentProfile:preferences')}
      subTitle={t('wealthPlan:investmentProfile:preferencesSubtitle')}
      textColor={textLightest}
      backgroundColor={accent.forest4}
      headerContent={
        <div style={{ paddingTop: SPACING_24 }}>
          <PreferencesIcon />
        </div>
      }
      {...rest}
    >
      <StyledMainGrid>
        {sustainabilityPreferences ? (
          <InvestmentPreferencesContent />
        ) : (
          <InvestmentPreferencesChoicesContent />
        )}
      </StyledMainGrid>
    </ModalWithHeader>
  );
};
