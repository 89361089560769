import _ from 'lodash';

export const createLoadingSelector = actions => state => {
  // if state.api.loading[action] is still to be initialized, return true
  if (actions.some(key => !Object.keys(state.api.loading).includes(key)))
    return true;
  // returns true only when all actions is not loading
  return _(actions).some(action => _.get(state, `api.loading.${action}`));
};

export const createErrorSelector = actions => state => {
  // returns true if any of them has errored
  return _(actions).some(action => _.get(state, `api.error.${action}`));
};
