import React from 'react';
import { accent } from '../../../../constants/colors';
import { BORDER_RADIUS_LARGE } from '../../../../constants/spacing';

import { ReactComponent as AD } from '@formue-app/core/src/assets/images/flags/AD.svg';
import { ReactComponent as AE } from '@formue-app/core/src/assets/images/flags/AE.svg';
import { ReactComponent as AF } from '@formue-app/core/src/assets/images/flags/AF.svg';
import { ReactComponent as AI } from '@formue-app/core/src/assets/images/flags/AI.svg';
import { ReactComponent as AL } from '@formue-app/core/src/assets/images/flags/AL.svg';
import { ReactComponent as AM } from '@formue-app/core/src/assets/images/flags/AM.svg';
import { ReactComponent as AQ } from '@formue-app/core/src/assets/images/flags/AQ.svg';
import { ReactComponent as AR } from '@formue-app/core/src/assets/images/flags/AR.svg';
import { ReactComponent as US } from '@formue-app/core/src/assets/images/flags/US.svg';
import { ReactComponent as AT } from '@formue-app/core/src/assets/images/flags/AT.svg';
import { ReactComponent as AU } from '@formue-app/core/src/assets/images/flags/AU.svg';
import { ReactComponent as AW } from '@formue-app/core/src/assets/images/flags/AW.svg';
import { ReactComponent as AX } from '@formue-app/core/src/assets/images/flags/AX.svg';
import { ReactComponent as AZ } from '@formue-app/core/src/assets/images/flags/AZ.svg';
import { ReactComponent as BA } from '@formue-app/core/src/assets/images/flags/BA.svg';
import { ReactComponent as BB } from '@formue-app/core/src/assets/images/flags/BB.svg';
import { ReactComponent as BE } from '@formue-app/core/src/assets/images/flags/BE.svg';
import { ReactComponent as BG } from '@formue-app/core/src/assets/images/flags/BG.svg';
import { ReactComponent as BH } from '@formue-app/core/src/assets/images/flags/BH.svg';
import { ReactComponent as BI } from '@formue-app/core/src/assets/images/flags/BI.svg';
import { ReactComponent as BM } from '@formue-app/core/src/assets/images/flags/BM.svg';
import { ReactComponent as BN } from '@formue-app/core/src/assets/images/flags/BN.svg';
import { ReactComponent as BO } from '@formue-app/core/src/assets/images/flags/BO.svg';
import { ReactComponent as BR } from '@formue-app/core/src/assets/images/flags/BR.svg';
import { ReactComponent as BS } from '@formue-app/core/src/assets/images/flags/BS.svg';
import { ReactComponent as BW } from '@formue-app/core/src/assets/images/flags/BW.svg';
import { ReactComponent as BY } from '@formue-app/core/src/assets/images/flags/BY.svg';
import { ReactComponent as BZ } from '@formue-app/core/src/assets/images/flags/BZ.svg';
import { ReactComponent as CA } from '@formue-app/core/src/assets/images/flags/CA.svg';
import { ReactComponent as CD } from '@formue-app/core/src/assets/images/flags/CD.svg';
import { ReactComponent as CF } from '@formue-app/core/src/assets/images/flags/CF.svg';
import { ReactComponent as CG } from '@formue-app/core/src/assets/images/flags/CG.svg';
import { ReactComponent as CH } from '@formue-app/core/src/assets/images/flags/CH.svg';
import { ReactComponent as CI } from '@formue-app/core/src/assets/images/flags/CI.svg';
import { ReactComponent as CL } from '@formue-app/core/src/assets/images/flags/CL.svg';
import { ReactComponent as CM } from '@formue-app/core/src/assets/images/flags/CM.svg';
import { ReactComponent as CN } from '@formue-app/core/src/assets/images/flags/CN.svg';
import { ReactComponent as CO } from '@formue-app/core/src/assets/images/flags/CO.svg';
import { ReactComponent as CR } from '@formue-app/core/src/assets/images/flags/CR.svg';
import { ReactComponent as CU } from '@formue-app/core/src/assets/images/flags/CU.svg';
import { ReactComponent as CV } from '@formue-app/core/src/assets/images/flags/CV.svg';
import { ReactComponent as CW } from '@formue-app/core/src/assets/images/flags/CW.svg';
import { ReactComponent as CX } from '@formue-app/core/src/assets/images/flags/CX.svg';
import { ReactComponent as CY } from '@formue-app/core/src/assets/images/flags/CY.svg';
import { ReactComponent as DE } from '@formue-app/core/src/assets/images/flags/DE.svg';
import { ReactComponent as DK } from '@formue-app/core/src/assets/images/flags/DK.svg';
import { ReactComponent as DM } from '@formue-app/core/src/assets/images/flags/DM.svg';
import { ReactComponent as DO } from '@formue-app/core/src/assets/images/flags/DO.svg';
import { ReactComponent as EC } from '@formue-app/core/src/assets/images/flags/EC.svg';
import { ReactComponent as EE } from '@formue-app/core/src/assets/images/flags/EE.svg';
import { ReactComponent as EG } from '@formue-app/core/src/assets/images/flags/EG.svg';
import { ReactComponent as EH } from '@formue-app/core/src/assets/images/flags/EH.svg';
import { ReactComponent as ES } from '@formue-app/core/src/assets/images/flags/ES.svg';
import { ReactComponent as ET } from '@formue-app/core/src/assets/images/flags/ET.svg';
import { ReactComponent as FI } from '@formue-app/core/src/assets/images/flags/FI.svg';
import { ReactComponent as FJ } from '@formue-app/core/src/assets/images/flags/FJ.svg';
import { ReactComponent as FM } from '@formue-app/core/src/assets/images/flags/FM.svg';
import { ReactComponent as FO } from '@formue-app/core/src/assets/images/flags/FO.svg';
import { ReactComponent as FR } from '@formue-app/core/src/assets/images/flags/FR.svg';
import { ReactComponent as GA } from '@formue-app/core/src/assets/images/flags/GA.svg';
import { ReactComponent as GB } from '@formue-app/core/src/assets/images/flags/GB.svg';
import { ReactComponent as GE } from '@formue-app/core/src/assets/images/flags/GE.svg';
import { ReactComponent as GG } from '@formue-app/core/src/assets/images/flags/GG.svg';
import { ReactComponent as GH } from '@formue-app/core/src/assets/images/flags/GH.svg';
import { ReactComponent as GI } from '@formue-app/core/src/assets/images/flags/GI.svg';
import { ReactComponent as GM } from '@formue-app/core/src/assets/images/flags/GM.svg';
import { ReactComponent as GP } from '@formue-app/core/src/assets/images/flags/GP.svg';
import { ReactComponent as GQ } from '@formue-app/core/src/assets/images/flags/GQ.svg';
import { ReactComponent as GR } from '@formue-app/core/src/assets/images/flags/GR.svg';
import { ReactComponent as GT } from '@formue-app/core/src/assets/images/flags/GT.svg';
import { ReactComponent as HK } from '@formue-app/core/src/assets/images/flags/HK.svg';
import { ReactComponent as HN } from '@formue-app/core/src/assets/images/flags/HN.svg';
import { ReactComponent as HR } from '@formue-app/core/src/assets/images/flags/HR.svg';
import { ReactComponent as HU } from '@formue-app/core/src/assets/images/flags/HU.svg';
import { ReactComponent as IE } from '@formue-app/core/src/assets/images/flags/IE.svg';
import { ReactComponent as IL } from '@formue-app/core/src/assets/images/flags/IL.svg';
import { ReactComponent as IM } from '@formue-app/core/src/assets/images/flags/IM.svg';
import { ReactComponent as IN } from '@formue-app/core/src/assets/images/flags/IN.svg';
import { ReactComponent as IQ } from '@formue-app/core/src/assets/images/flags/IQ.svg';
import { ReactComponent as IR } from '@formue-app/core/src/assets/images/flags/IR.svg';
import { ReactComponent as IS } from '@formue-app/core/src/assets/images/flags/IS.svg';
import { ReactComponent as IT } from '@formue-app/core/src/assets/images/flags/IT.svg';
import { ReactComponent as JE } from '@formue-app/core/src/assets/images/flags/JE.svg';
import { ReactComponent as JM } from '@formue-app/core/src/assets/images/flags/JM.svg';
import { ReactComponent as JO } from '@formue-app/core/src/assets/images/flags/JO.svg';
import { ReactComponent as JP } from '@formue-app/core/src/assets/images/flags/JP.svg';
import { ReactComponent as KE } from '@formue-app/core/src/assets/images/flags/KE.svg';
import { ReactComponent as KG } from '@formue-app/core/src/assets/images/flags/KG.svg';
import { ReactComponent as KI } from '@formue-app/core/src/assets/images/flags/KI.svg';
import { ReactComponent as KM } from '@formue-app/core/src/assets/images/flags/KM.svg';
import { ReactComponent as KN } from '@formue-app/core/src/assets/images/flags/KN.svg';
import { ReactComponent as KP } from '@formue-app/core/src/assets/images/flags/KP.svg';
import { ReactComponent as KR } from '@formue-app/core/src/assets/images/flags/KR.svg';
import { ReactComponent as KW } from '@formue-app/core/src/assets/images/flags/KW.svg';
import { ReactComponent as KY } from '@formue-app/core/src/assets/images/flags/KY.svg';
import { ReactComponent as KZ } from '@formue-app/core/src/assets/images/flags/KZ.svg';
import { ReactComponent as LA } from '@formue-app/core/src/assets/images/flags/LA.svg';
import { ReactComponent as LB } from '@formue-app/core/src/assets/images/flags/LB.svg';
import { ReactComponent as LC } from '@formue-app/core/src/assets/images/flags/LC.svg';
import { ReactComponent as LR } from '@formue-app/core/src/assets/images/flags/LR.svg';
import { ReactComponent as LS } from '@formue-app/core/src/assets/images/flags/LS.svg';
import { ReactComponent as LT } from '@formue-app/core/src/assets/images/flags/LT.svg';
import { ReactComponent as LU } from '@formue-app/core/src/assets/images/flags/LU.svg';
import { ReactComponent as LV } from '@formue-app/core/src/assets/images/flags/LV.svg';
import { ReactComponent as LY } from '@formue-app/core/src/assets/images/flags/LY.svg';
import { ReactComponent as MA } from '@formue-app/core/src/assets/images/flags/MA.svg';
import { ReactComponent as MD } from '@formue-app/core/src/assets/images/flags/MD.svg';
import { ReactComponent as ME } from '@formue-app/core/src/assets/images/flags/ME.svg';
import { ReactComponent as MH } from '@formue-app/core/src/assets/images/flags/MH.svg';
import { ReactComponent as MK } from '@formue-app/core/src/assets/images/flags/MK.svg';
import { ReactComponent as ML } from '@formue-app/core/src/assets/images/flags/ML.svg';
import { ReactComponent as MM } from '@formue-app/core/src/assets/images/flags/MM.svg';
import { ReactComponent as MN } from '@formue-app/core/src/assets/images/flags/MN.svg';
import { ReactComponent as MO } from '@formue-app/core/src/assets/images/flags/MO.svg';
import { ReactComponent as MR } from '@formue-app/core/src/assets/images/flags/MR.svg';
import { ReactComponent as MU } from '@formue-app/core/src/assets/images/flags/MU.svg';
import { ReactComponent as MV } from '@formue-app/core/src/assets/images/flags/MV.svg';
import { ReactComponent as MW } from '@formue-app/core/src/assets/images/flags/MW.svg';
import { ReactComponent as MY } from '@formue-app/core/src/assets/images/flags/MY.svg';
import { ReactComponent as MZ } from '@formue-app/core/src/assets/images/flags/MZ.svg';
import { ReactComponent as NA } from '@formue-app/core/src/assets/images/flags/NA.svg';
import { ReactComponent as NC } from '@formue-app/core/src/assets/images/flags/NC.svg';
import { ReactComponent as NE } from '@formue-app/core/src/assets/images/flags/NE.svg';
import { ReactComponent as NG } from '@formue-app/core/src/assets/images/flags/NG.svg';
import { ReactComponent as NI } from '@formue-app/core/src/assets/images/flags/NI.svg';
import { ReactComponent as NL } from '@formue-app/core/src/assets/images/flags/NL.svg';
import { ReactComponent as NO } from '@formue-app/core/src/assets/images/flags/NO.svg';
import { ReactComponent as NP } from '@formue-app/core/src/assets/images/flags/NP.svg';
import { ReactComponent as NR } from '@formue-app/core/src/assets/images/flags/NR.svg';
import { ReactComponent as NZ } from '@formue-app/core/src/assets/images/flags/NZ.svg';
import { ReactComponent as OM } from '@formue-app/core/src/assets/images/flags/OM.svg';
import { ReactComponent as PA } from '@formue-app/core/src/assets/images/flags/PA.svg';
import { ReactComponent as PE } from '@formue-app/core/src/assets/images/flags/PE.svg';
import { ReactComponent as PF } from '@formue-app/core/src/assets/images/flags/PF.svg';
import { ReactComponent as PG } from '@formue-app/core/src/assets/images/flags/PG.svg';
import { ReactComponent as PK } from '@formue-app/core/src/assets/images/flags/PK.svg';
import { ReactComponent as PR } from '@formue-app/core/src/assets/images/flags/PR.svg';
import { ReactComponent as PS } from '@formue-app/core/src/assets/images/flags/PS.svg';
import { ReactComponent as PT } from '@formue-app/core/src/assets/images/flags/PT.svg';
import { ReactComponent as PW } from '@formue-app/core/src/assets/images/flags/PW.svg';
import { ReactComponent as PY } from '@formue-app/core/src/assets/images/flags/PY.svg';
import { ReactComponent as QA } from '@formue-app/core/src/assets/images/flags/QA.svg';
import { ReactComponent as RO } from '@formue-app/core/src/assets/images/flags/RO.svg';
import { ReactComponent as RS } from '@formue-app/core/src/assets/images/flags/RS.svg';
import { ReactComponent as RU } from '@formue-app/core/src/assets/images/flags/RU.svg';
import { ReactComponent as RW } from '@formue-app/core/src/assets/images/flags/RW.svg';
import { ReactComponent as SA } from '@formue-app/core/src/assets/images/flags/SA.svg';
import { ReactComponent as SC } from '@formue-app/core/src/assets/images/flags/SC.svg';
import { ReactComponent as SD } from '@formue-app/core/src/assets/images/flags/SD.svg';
import { ReactComponent as SE } from '@formue-app/core/src/assets/images/flags/SE.svg';
import { ReactComponent as SG } from '@formue-app/core/src/assets/images/flags/SG.svg';
import { ReactComponent as SH } from '@formue-app/core/src/assets/images/flags/SH.svg';
import { ReactComponent as SI } from '@formue-app/core/src/assets/images/flags/SI.svg';
import { ReactComponent as SK } from '@formue-app/core/src/assets/images/flags/SK.svg';
import { ReactComponent as SL } from '@formue-app/core/src/assets/images/flags/SL.svg';
import { ReactComponent as SN } from '@formue-app/core/src/assets/images/flags/SN.svg';
import { ReactComponent as SO } from '@formue-app/core/src/assets/images/flags/SO.svg';
import { ReactComponent as SR } from '@formue-app/core/src/assets/images/flags/SR.svg';
import { ReactComponent as SS } from '@formue-app/core/src/assets/images/flags/SS.svg';
import { ReactComponent as ST } from '@formue-app/core/src/assets/images/flags/ST.svg';
import { ReactComponent as SV } from '@formue-app/core/src/assets/images/flags/SV.svg';
import { ReactComponent as SY } from '@formue-app/core/src/assets/images/flags/SY.svg';
import { ReactComponent as SZ } from '@formue-app/core/src/assets/images/flags/SZ.svg';
import { ReactComponent as TD } from '@formue-app/core/src/assets/images/flags/TD.svg';
import { ReactComponent as TG } from '@formue-app/core/src/assets/images/flags/TG.svg';
import { ReactComponent as TH } from '@formue-app/core/src/assets/images/flags/TH.svg';
import { ReactComponent as TJ } from '@formue-app/core/src/assets/images/flags/TJ.svg';
import { ReactComponent as TM } from '@formue-app/core/src/assets/images/flags/TM.svg';
import { ReactComponent as TN } from '@formue-app/core/src/assets/images/flags/TN.svg';
import { ReactComponent as TO } from '@formue-app/core/src/assets/images/flags/TO.svg';
import { ReactComponent as TR } from '@formue-app/core/src/assets/images/flags/TR.svg';
import { ReactComponent as TT } from '@formue-app/core/src/assets/images/flags/TT.svg';
import { ReactComponent as TW } from '@formue-app/core/src/assets/images/flags/TW.svg';
import { ReactComponent as TZ } from '@formue-app/core/src/assets/images/flags/TZ.svg';
import { ReactComponent as UG } from '@formue-app/core/src/assets/images/flags/UG.svg';
import { ReactComponent as UY } from '@formue-app/core/src/assets/images/flags/UY.svg';
import { ReactComponent as UZ } from '@formue-app/core/src/assets/images/flags/UZ.svg';
import { ReactComponent as VC } from '@formue-app/core/src/assets/images/flags/VC.svg';
import { ReactComponent as VE } from '@formue-app/core/src/assets/images/flags/VE.svg';
import { ReactComponent as VI } from '@formue-app/core/src/assets/images/flags/VI.svg';
import { ReactComponent as VN } from '@formue-app/core/src/assets/images/flags/VN.svg';
import { ReactComponent as WS } from '@formue-app/core/src/assets/images/flags/WS.svg';
import { ReactComponent as YE } from '@formue-app/core/src/assets/images/flags/YE.svg';
import { ReactComponent as MX } from '@formue-app/core/src/assets/images/flags/MX.svg';
import { ReactComponent as VG } from '@formue-app/core/src/assets/images/flags/VG.svg';
import { ReactComponent as MT } from '@formue-app/core/src/assets/images/flags/MT.svg';
import { ReactComponent as ZM } from '@formue-app/core/src/assets/images/flags/ZM.svg';
import { ReactComponent as AO } from '@formue-app/core/src/assets/images/flags/AO.svg';
import { ReactComponent as PH } from '@formue-app/core/src/assets/images/flags/PH.svg';
import { ReactComponent as ZA } from '@formue-app/core/src/assets/images/flags/ZA.svg';
import { ReactComponent as CZ } from '@formue-app/core/src/assets/images/flags/CZ.svg';
import { ReactComponent as ID } from '@formue-app/core/src/assets/images/flags/ID.svg';
import { ReactComponent as MC } from '@formue-app/core/src/assets/images/flags/MC.svg';
import { ReactComponent as UA } from '@formue-app/core/src/assets/images/flags/UA.svg';
import { ReactComponent as PL } from '@formue-app/core/src/assets/images/flags/PL.svg';

import { ReactComponent as Default } from '@formue-app/core/src/assets/images/flags/default.svg';

export const Flag = ({ width = 30, height = 30, countryCode, style, ...rest }) => {
  const iconComponent = (code) => {
    switch (code) {
      case 'AD':
        return <AD height="100%" width="100%" />;
      case 'AE':
        return <AE height="100%" width="100%" />;
      case 'AF':
        return <AF height="100%" width="100%" />;
      case 'AI':
        return <AI height="100%" width="100%" />;
      case 'AL':
        return <AL height="100%" width="100%" />;
      case 'AM':
        return <AM height="100%" width="100%" />;
      case 'AQ':
        return <AQ height="100%" width="100%" />;
      case 'AR':
        return <AR height="100%" width="100%" />;
      case 'US':
        return <US height="100%" width="100%" />;
      case 'AT':
        return <AT height="100%" width="100%" />;
      case 'AU':
        return <AU height="100%" width="100%" />;
      case 'AW':
        return <AW height="100%" width="100%" />;
      case 'AX':
        return <AX height="100%" width="100%" />;
      case 'AZ':
        return <AZ height="100%" width="100%" />;
      case 'BA':
        return <BA height="100%" width="100%" />;
      case 'BB':
        return <BB height="100%" width="100%" />;
      case 'BE':
        return <BE height="100%" width="100%" />;
      case 'BG':
        return <BG height="100%" width="100%" />;
      case 'BH':
        return <BH height="100%" width="100%" />;
      case 'BI':
        return <BI height="100%" width="100%" />;
      case 'BM':
        return <BM height="100%" width="100%" />;
      case 'BN':
        return <BN height="100%" width="100%" />;
      case 'BO':
        return <BO height="100%" width="100%" />;
      case 'BR':
        return <BR height="100%" width="100%" />;
      case 'BS':
        return <BS height="100%" width="100%" />;
      case 'BW':
        return <BW height="100%" width="100%" />;
      case 'BY':
        return <BY height="100%" width="100%" />;
      case 'BZ':
        return <BZ height="100%" width="100%" />;
      case 'CA':
        return <CA height="100%" width="100%" />;
      case 'CD':
        return <CD height="100%" width="100%" />;
      case 'CF':
        return <CF height="100%" width="100%" />;
      case 'CG':
        return <CG height="100%" width="100%" />;
      case 'CH':
        return <CH height="100%" width="100%" />;
      case 'CI':
        return <CI height="100%" width="100%" />;
      case 'CL':
        return <CL height="100%" width="100%" />;
      case 'CM':
        return <CM height="100%" width="100%" />;
      case 'CN':
        return <CN height="100%" width="100%" />;
      case 'CO':
        return <CO height="100%" width="100%" />;
      case 'CR':
        return <CR height="100%" width="100%" />;
      case 'CU':
        return <CU height="100%" width="100%" />;
      case 'CV':
        return <CV height="100%" width="100%" />;
      case 'CW':
        return <CW height="100%" width="100%" />;
      case 'CX':
        return <CX height="100%" width="100%" />;
      case 'CY':
        return <CY height="100%" width="100%" />;
      case 'DE':
        return <DE height="100%" width="100%" />;
      case 'DK':
        return <DK height="100%" width="100%" />;
      case 'DM':
        return <DM height="100%" width="100%" />;
      case 'DO':
        return <DO height="100%" width="100%" />;
      case 'EC':
        return <EC height="100%" width="100%" />;
      case 'EE':
        return <EE height="100%" width="100%" />;
      case 'EG':
        return <EG height="100%" width="100%" />;
      case 'EH':
        return <EH height="100%" width="100%" />;
      case 'ES':
        return <ES height="100%" width="100%" />;
      case 'ET':
        return <ET height="100%" width="100%" />;
      case 'FI':
        return <FI height="100%" width="100%" />;
      case 'FJ':
        return <FJ height="100%" width="100%" />;
      case 'FM':
        return <FM height="100%" width="100%" />;
      case 'FO':
        return <FO height="100%" width="100%" />;
      case 'FR':
        return <FR height="100%" width="100%" />;
      case 'GA':
        return <GA height="100%" width="100%" />;
      case 'GB':
        return <GB height="100%" width="100%" />;
      case 'GE':
        return <GE height="100%" width="100%" />;
      case 'GG':
        return <GG height="100%" width="100%" />;
      case 'GH':
        return <GH height="100%" width="100%" />;
      case 'GI':
        return <GI height="100%" width="100%" />;
      case 'GM':
        return <GM height="100%" width="100%" />;
      case 'GP':
        return <GP height="100%" width="100%" />;
      case 'GQ':
        return <GQ height="100%" width="100%" />;
      case 'GR':
        return <GR height="100%" width="100%" />;
      case 'GT':
        return <GT height="100%" width="100%" />;
      case 'HK':
        return <HK height="100%" width="100%" />;
      case 'HN':
        return <HN height="100%" width="100%" />;
      case 'HR':
        return <HR height="100%" width="100%" />;
      case 'HU':
        return <HU height="100%" width="100%" />;
      case 'IE':
        return <IE height="100%" width="100%" />;
      case 'IL':
        return <IL height="100%" width="100%" />;
      case 'IM':
        return <IM height="100%" width="100%" />;
      case 'IN':
        return <IN height="100%" width="100%" />;
      case 'IQ':
        return <IQ height="100%" width="100%" />;
      case 'IR':
        return <IR height="100%" width="100%" />;
      case 'IS':
        return <IS height="100%" width="100%" />;
      case 'IT':
        return <IT height="100%" width="100%" />;
      case 'JE':
        return <JE height="100%" width="100%" />;
      case 'JM':
        return <JM height="100%" width="100%" />;
      case 'JO':
        return <JO height="100%" width="100%" />;
      case 'JP':
        return <JP height="100%" width="100%" />;
      case 'KE':
        return <KE height="100%" width="100%" />;
      case 'KG':
        return <KG height="100%" width="100%" />;
      case 'KI':
        return <KI height="100%" width="100%" />;
      case 'KM':
        return <KM height="100%" width="100%" />;
      case 'KN':
        return <KN height="100%" width="100%" />;
      case 'KP':
        return <KP height="100%" width="100%" />;
      case 'KR':
        return <KR height="100%" width="100%" />;
      case 'KW':
        return <KW height="100%" width="100%" />;
      case 'KY':
        return <KY height="100%" width="100%" />;
      case 'KZ':
        return <KZ height="100%" width="100%" />;
      case 'LA':
        return <LA height="100%" width="100%" />;
      case 'LB':
        return <LB height="100%" width="100%" />;
      case 'LC':
        return <LC height="100%" width="100%" />;
      case 'LR':
        return <LR height="100%" width="100%" />;
      case 'LS':
        return <LS height="100%" width="100%" />;
      case 'LT':
        return <LT height="100%" width="100%" />;
      case 'LU':
        return <LU height="100%" width="100%" />;
      case 'LV':
        return <LV height="100%" width="100%" />;
      case 'LY':
        return <LY height="100%" width="100%" />;
      case 'MA':
        return <MA height="100%" width="100%" />;
      case 'MD':
        return <MD height="100%" width="100%" />;
      case 'ME':
        return <ME height="100%" width="100%" />;
      case 'MH':
        return <MH height="100%" width="100%" />;
      case 'MK':
        return <MK height="100%" width="100%" />;
      case 'ML':
        return <ML height="100%" width="100%" />;
      case 'MM':
        return <MM height="100%" width="100%" />;
      case 'MN':
        return <MN height="100%" width="100%" />;
      case 'MO':
        return <MO height="100%" width="100%" />;
      case 'MR':
        return <MR height="100%" width="100%" />;
      case 'MU':
        return <MU height="100%" width="100%" />;
      case 'MV':
        return <MV height="100%" width="100%" />;
      case 'MW':
        return <MW height="100%" width="100%" />;
      case 'MY':
        return <MY height="100%" width="100%" />;
      case 'MZ':
        return <MZ height="100%" width="100%" />;
      case 'NA':
        return <NA height="100%" width="100%" />;
      case 'NC':
        return <NC height="100%" width="100%" />;
      case 'NE':
        return <NE height="100%" width="100%" />;
      case 'NG':
        return <NG height="100%" width="100%" />;
      case 'NI':
        return <NI height="100%" width="100%" />;
      case 'NL':
        return <NL height="100%" width="100%" />;
      case 'NO':
        return <NO height="100%" width="100%" />;
      case 'NP':
        return <NP height="100%" width="100%" />;
      case 'NR':
        return <NR height="100%" width="100%" />;
      case 'NZ':
        return <NZ height="100%" width="100%" />;
      case 'OM':
        return <OM height="100%" width="100%" />;
      case 'PA':
        return <PA height="100%" width="100%" />;
      case 'PE':
        return <PE height="100%" width="100%" />;
      case 'PF':
        return <PF height="100%" width="100%" />;
      case 'PG':
        return <PG height="100%" width="100%" />;
      case 'PK':
        return <PK height="100%" width="100%" />;
      case 'PR':
        return <PR height="100%" width="100%" />;
      case 'PS':
        return <PS height="100%" width="100%" />;
      case 'PT':
        return <PT height="100%" width="100%" />;
      case 'PW':
        return <PW height="100%" width="100%" />;
      case 'PY':
        return <PY height="100%" width="100%" />;
      case 'QA':
        return <QA height="100%" width="100%" />;
      case 'RO':
        return <RO height="100%" width="100%" />;
      case 'RS':
        return <RS height="100%" width="100%" />;
      case 'RU':
        return <RU height="100%" width="100%" />;
      case 'RW':
        return <RW height="100%" width="100%" />;
      case 'SA':
        return <SA height="100%" width="100%" />;
      case 'SC':
        return <SC height="100%" width="100%" />;
      case 'SD':
        return <SD height="100%" width="100%" />;
      case 'SE':
        return <SE height="100%" width="100%" />;
      case 'SG':
        return <SG height="100%" width="100%" />;
      case 'SH':
        return <SH height="100%" width="100%" />;
      case 'SI':
        return <SI height="100%" width="100%" />;
      case 'SK':
        return <SK height="100%" width="100%" />;
      case 'SL':
        return <SL height="100%" width="100%" />;
      case 'SN':
        return <SN height="100%" width="100%" />;
      case 'SO':
        return <SO height="100%" width="100%" />;
      case 'SR':
        return <SR height="100%" width="100%" />;
      case 'SS':
        return <SS height="100%" width="100%" />;
      case 'ST':
        return <ST height="100%" width="100%" />;
      case 'SV':
        return <SV height="100%" width="100%" />;
      case 'SY':
        return <SY height="100%" width="100%" />;
      case 'SZ':
        return <SZ height="100%" width="100%" />;
      case 'TD':
        return <TD height="100%" width="100%" />;
      case 'TG':
        return <TG height="100%" width="100%" />;
      case 'TH':
        return <TH height="100%" width="100%" />;
      case 'TJ':
        return <TJ height="100%" width="100%" />;
      case 'TM':
        return <TM height="100%" width="100%" />;
      case 'TN':
        return <TN height="100%" width="100%" />;
      case 'TO':
        return <TO height="100%" width="100%" />;
      case 'TR':
        return <TR height="100%" width="100%" />;
      case 'TT':
        return <TT height="100%" width="100%" />;
      case 'TW':
        return <TW height="100%" width="100%" />;
      case 'TZ':
        return <TZ height="100%" width="100%" />;
      case 'UG':
        return <UG height="100%" width="100%" />;
      case 'UY':
        return <UY height="100%" width="100%" />;
      case 'UZ':
        return <UZ height="100%" width="100%" />;
      case 'VC':
        return <VC height="100%" width="100%" />;
      case 'VE':
        return <VE height="100%" width="100%" />;
      case 'VI':
        return <VI height="100%" width="100%" />;
      case 'VN':
        return <VN height="100%" width="100%" />;
      case 'WS':
        return <WS height="100%" width="100%" />;
      case 'YE':
        return <YE height="100%" width="100%" />;
      case 'MX':
        return <MX height="100%" width="100%" />;
      case 'VG':
        return <VG height="100%" width="100%" />;
      case 'MT':
        return <MT height="100%" width="100%" />;
      case 'ZM':
        return <ZM height="100%" width="100%" />;
      case 'AO':
        return <AO height="100%" width="100%" />;
      case 'PH':
        return <PH height="100%" width="100%" />;
      case 'ZA':
        return <ZA height="100%" width="100%" />;
      case 'CZ':
        return <CZ height="100%" width="100%" />;
      case 'ID':
        return <ID height="100%" width="100%" />;
      case 'MC':
        return <MC height="100%" width="100%" />;
      case 'UA':
        return <UA height="100%" width="100%" />;
      case 'PL':
        return <PL height="100%" width="100%" />;
      default:
        return <Default height="100%" width="100%" />;
    }
  };

  const IconComponent = iconComponent(countryCode);

  if (!IconComponent) {
    return null;
  }

  // We need the "inner wrapper" with "margin: -1px" due
  // to the nature of these SVG files which seem to have
  // 1px of spacing around.
  // Hackish but it works well
  return (
    <div
      style={{
        width,
        height,
        display: 'flex',
        overflow: 'hidden',
        borderWidth: 1,
        borderColor: accent.ocean415,
        borderStyle: 'solid',
        borderRadius: BORDER_RADIUS_LARGE,
        ...style,
        ...rest
      }}
    >
      {IconComponent}
    </div>
  );
};
