import React from 'react';

import { SelfDeclarationForm } from '../../components/profile/selfDeclaration/SelfDeclarationForm';
import { MenuPage } from '../base/MenuPage';

export const SelfDeclarationPage = (props) => {
  return (
    <MenuPage {...props}>
      <SelfDeclarationForm columns={12} />
    </MenuPage>
  );
};
