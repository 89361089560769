import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { H6, Paragraph } from '../../texts';

import { accent } from '../../../constants/colors';
import { SPACING_24 } from '../../../constants/spacing';

const StyledTable = styled.table`
  width: calc(100% + 50%);
  border-collapse: collapse;
  margin: ${SPACING_24} 0;

  tbody tr,
  thead tr {
    border-bottom: 1px solid ${accent.sand370};
  }

  tbody tr:last-child {
    border: none;
  }

  thead tr th.padding {
    padding: 12px 0;
  }

  tbody tr td.padding {
    padding: 17px 0;
  }

  tbody table {
    width: 80%;
    margin: 0 auto;
  }
`;

export const FeeTable = (props) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th className="padding">
            <H6>{t('portfolio:costReport:feeTable:header1')}</H6>
          </th>
          <th className="padding" style={{ textAlign: 'center' }}>
            <H6>{t('portfolio:costReport:feeTable:header2')}</H6>
          </th>
          <th className="padding" style={{ textAlign: 'center' }}>
            <H6>{t('portfolio:costReport:feeTable:header3')}</H6>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="padding">
            <Paragraph>
              {t('portfolio:costReport:feeTable:beforeFee')}
            </Paragraph>
          </td>
          <td className="padding">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Paragraph>13%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>15%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>18%</Paragraph>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="padding">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Paragraph>15%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>18%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>21%</Paragraph>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td className="padding">
            <Paragraph>
              {t('portfolio:costReport:feeTable:estimatedFee')}
            </Paragraph>
          </td>
          <td className="padding">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Paragraph>1,9%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>2,1%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>2,5%</Paragraph>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="padding">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Paragraph>2,1%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>2,5%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>2,8%</Paragraph>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td className="padding">
            <Paragraph>{t('portfolio:costReport:feeTable:afterFee')}</Paragraph>
          </td>
          <td className="padding">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Paragraph>11,1%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>12,9%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>15,5%</Paragraph>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="padding">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Paragraph>12,9%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>15,5%</Paragraph>
                  </td>
                  <td>
                    <Paragraph>18,2%</Paragraph>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </StyledTable>
  );
};
