import { combineReducers } from 'redux';

/**
 * @param {*} requestIdentifier - RESOURCE_NAME/REQUEST_VERB, e.g ARTICLES/INDEX
 * @param {*} payload - Object containing params needed to do retrieve the resource
 * @returns a string that uniquely identifies the request we need to do to load the
 * resource given the params in the payload.
 */
export const generateLoadingStateKey = (requestIdentifier, payload) => {
  const keyNameParts = [requestIdentifier];
  if (payload) {
    const uniqueRequestIdentifierPostfix = Object.entries(payload).flat().join('/');
    keyNameParts.push(uniqueRequestIdentifierPostfix)    
  }
  const loadingStateKey = keyNameParts.join('/');
  return loadingStateKey;
}

const loadingReducer = (state = {}, action) => {
  // Example action type: RESOURCE_NAME/INDEX_REQUEST_BEGIN
  const { type, payload } = action;

  // Find and matches related to loading resource data. We want to match actions that look like
  // FOO/INDEX_REQUEST_BEGIN
  // BAR/INDEX_REQUEST_SUCCESS
  // FOO_BAR/INDEX_REQUEST_FAILURE
  // FOO/INVALIDATE
  // FOO/FLUSH
  const matches = /(.*)\/([A-Z]*)_?(REQUEST_BEGIN|REQUEST_SUCCESS|REQUEST_FAILURE|INVALIDATE|FLUSH)/.exec(
    type
  );

  // If not a match to the regex just return current state without modifying anything
  if (!matches) {
    return state;
  }

  const [, resourceName, requestName, requestState] = matches;

  const requestIdentifier = `${resourceName}/${requestName}`;
  const loadingStateKey = generateLoadingStateKey(requestIdentifier, payload?.args);

  // Invalidation and flush actions needs to be handled differntly, since in those
  // cases we want to delete the loading state values from the store.
  if (requestState === 'INVALIDATE' || requestState === 'FLUSH') {
    // If we find a match, extract the RESOURCE_NAME/REQUEST_METHOD name, e.g
    // ARTICLES/INVALIDATE will give us a match of "ARTICLES". That is the same
    // name as we use in the "loadingReducer" when we populate the store. In this
    // case we want to remove the loading state for all requests related to this
    // resource
    //
    // Since we have keys in the store that look like
    // `RESOURCE_NAME/REQUEST_METHOD/arg1/value/arg2/value` we need to search
    // through the state object to see if we find any that matches, and if so
    // delete them.
    const keysToDelete = Object.keys(state)
      .filter((key) => key.startsWith(resourceName));

    keysToDelete.forEach((key) => {
      delete state[key];
    });

    return state;
  }

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving actions like like:
    //  * SIGNING_EVENTS/INDEX_REQUEST_BEGIN
    //  * SIGNING_EVENTS/CREATE_REQUEST_BEGIN
    // and false when receiving:
    //  * SIGNING_EVENTS/INDEX_REQUEST_SUCCESS
    //  * SIGNING_EVENTS/INDEX_REQUEST_FAILURE
    [loadingStateKey]: requestState === 'REQUEST_BEGIN',
  };
};

const errorReducer = (state = {}, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST_BEGIN|REQUEST_SUCCESS|REQUEST_FAILURE)/.exec(
    type
  );

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;
  const loadingStateKey = generateLoadingStateKey(requestName, payload?.args);

  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving WEALTH_PLANS/INDEX_REQUEST_FAILURE
    // else clear errorMessage when receiving WEALTH_PLANS/INDEX_REQUEST_SUCCESS
    [loadingStateKey]: requestState === 'REQUEST_FAILURE' ? payload?.error.message : null,
  };
};

export const reducer = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
});
