import { takeLatest, select, put } from 'redux-saga/effects';
import { actions } from './actions';

import { selectors } from '@formue-app/core';

const {
  entities: {
    podcastEpisodes: { allPodcastEpisodesSelector },
  },
} = selectors;

function* setNextAndPreviousEpisodeId() {
  yield takeLatest(actions.setEpisodeId, function* ({ payload }) {
    const episodeId = payload;
    const allEpisodes = yield select(allPodcastEpisodesSelector);

    const currentEpisodeIndex = allEpisodes.findIndex(
      (item) => item.id === episodeId
    );

    // Set the next item from the episodes array or revert to the first one
    // if there is no next item
    const nextEpisode = allEpisodes[currentEpisodeIndex + 1]
      ? allEpisodes[currentEpisodeIndex + 1]
      : allEpisodes[0];

    // Set the previous item from the episodes array or revert to the last one
    // if there is no previous item
    const previousEpisode = allEpisodes[currentEpisodeIndex - 1]
      ? allEpisodes[currentEpisodeIndex - 1]
      : allEpisodes[allEpisodes.length - 1];

    yield put(
      actions.setNextAndPreviousEpisodeId(nextEpisode.id, previousEpisode.id)
    );
  });
}

export const sagas = [setNextAndPreviousEpisodeId];
