import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { percentage } from '@formue-app/core';

import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl2,
} from '../../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { H3, H4 } from '../../../texts';
import {
  easeInOut,
  extraLongAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';

import { VictoryPie } from 'victory';
import { mobileCondition } from '../../../../constants/media';
import { SpriteIcon } from '../../../common/SpriteIcon';

const OrganizationItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: calc(
    (100% / ${(props) => props.itemsPrRow}) -
      (
        16px * ${(props) => props.itemsPrRow - 1} /
          ${(props) => props.itemsPrRow}
      )
  );
  cursor: pointer;
  position: relative;

  left: 50px;
  opacity: 0;
  transition: all ${easeInOut} ${extraLongAnimationTime};
  transition-delay: ${(props) => `${slideAnimationDelay + props.index * 0.2}s`};

  ${(props) =>
    props.isActiveSlide &&
    `
    left: 0;
    opacity: 1;
  `}

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        border-color: ${accent.raspberry190};

        & > div:first-child {
          background: ${accent.raspberry190};
        }
      }
    `}

  ${(props) =>
    props.active &&
    `
    background-color: ${accent.raspberry1};
  `}

  h3:hover {
    text-decoration: underline;
  }

  @media ${mobileCondition} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 31px;
  height: 31px;
  border-radius: 50%;
  background: ${(props) => props.color};

  svg path {
    fill: ${(props) => props.textColor};
  }
`;

const OrganizationDetails = styled.div`
  position: absolute;
  background: ${backgroundWhite};
  padding: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_LARGE};
  box-shadow: ${hoverBoxShadowLvl2};
  width: 300px;
  top: 50%;
  z-index: 1;

  ${(props) =>
    props.renderSide === 'right' &&
    css`
      left: calc(100% + 10px);
      translate: 0 -50%;
    `}

  ${(props) =>
    props.renderSide === 'left' &&
    css`
      right: calc(100% + 10px);
      translate: 0 -50%;
    `}

  @media ${mobileCondition} {
    margin-top: ${SPACING_8};
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    translate: 0 0%;
  }
`;

const OrganizationDetailsInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${SPACING_8};
  gap: ${SPACING_8};

  &:last-child {
    margin-bottom: 0;
  }
`;

const OrganizationContent = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};
`;

const ChartWrapper = styled.div`
  width: 20px;
  height: 20px;

  svg {
    vertical-align: baseline;
  }
`;

export const BusinessAndOwnershipItem = (props) => {
  const {
    itemsPrRow,
    isActiveSlide,
    index,
    item,
    activeOrganization,
    setActiveOrganization,
    color,
    textColor = accent.raspberry250,
  } = props;

  const [renderSide, setRenderSide] = useState('right');

  const owners = item.shareHolders.map(
    (
      { companyShare, name, classification, description, beneficialOwner },
      index
    ) => ({
      value: companyShare,
      label: name,
      classification,
      description,
      beneficialOwner,
    })
  );

  const ownersTotalValues = owners.reduce((acc, cur) => acc + cur.value, 0);

  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      if (
        window.innerWidth - boxRef.current.getBoundingClientRect().right <
        320
      ) {
        setRenderSide('left');
      }
    }
  }, [boxRef]);

  return (
    <OrganizationItem
      itemsPrRow={itemsPrRow}
      key={`organization-${index}-${item.id}`}
      index={index}
      isActiveSlide={isActiveSlide}
      onClick={() =>
        setActiveOrganization(activeOrganization === item.id ? null : item.id)
      }
      color={color}
    >
      <OrganizationContent ref={boxRef}>
        <Icon color={color} textColor={textColor}>
          <SpriteIcon
            id="Office"
            navigational={true}
            inverted={true}
            size={16}
          />
        </Icon>
        <H3 style={{ color: textColor }}>{item.name}</H3>
      </OrganizationContent>
      {activeOrganization === item.id ? (
        <OrganizationDetails renderSide={renderSide}>
          {owners.map((owner, index) => (
            <OrganizationDetailsInner>
              <ChartWrapper>
                <VictoryPie
                  padAngle={5}
                  radius={10}
                  innerRadius={6}
                  labels={() => null}
                  width={20}
                  height={20}
                  style={{
                    data: {
                      fill: ({ datum }) => datum.color,
                    },
                  }}
                  data={[
                    {
                      y: ownersTotalValues - owner.value,
                      color: accent.raspberry2,
                    },
                    { y: owner.value, color: accent.raspberry1 },
                  ]}
                />
              </ChartWrapper>

              <H4 style={{ maxWidth: '65%' }}>{`${owner.label}`}</H4>
              <H4 style={{ marginLeft: 'auto' }}>
                {percentage(owner.value, 2, true)}
              </H4>
            </OrganizationDetailsInner>
          ))}
        </OrganizationDetails>
      ) : null}
    </OrganizationItem>
  );
};
