import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategyAssetAllocationsAllIds,
  investmentStrategyAssetAllocationsById,
  investmentStrategyAssetAllocationsSelector,
  investmentStrategyAssetAllocationsMetadataSelector,
] = resourceSelectors('investmentStrategyAssetAllocations');

export const investmentStrategyAssetAllocationsByStrategyIdSelector = (id) =>
  createSelector(investmentStrategyAssetAllocationsSelector, (assetClasses) =>
    assetClasses.filter((assetClass) => assetClass.investmentStrategy.id === id)
  );

export const investmentStrategyAssetAllocationsMetadataByIdSelector = (id) =>
  createSelector(
    investmentStrategyAssetAllocationsMetadataSelector,
    (meta) => meta[id]
  );
