import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';

import { slideAnimationDelay } from '../../../constants/animation';

import { GoalsList } from '../slideComponents/goals/GoalsList';
import { Question } from '../slideComponents/Question';

const {
  entities: {
    goals: { allGoalsSelector },
  },
} = selectors;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

export const GoalsSlide = (props) => {
  const { isActiveSlide } = props;
  const goals = useSelector(allGoalsSelector);
  const inProgressGoals = goals.filter((item) => item.status !== 'Completed');

  return (
    <Wrapper>
      <GoalsList isActiveSlide={isActiveSlide} goals={inProgressGoals} />
      <Question
        isActiveSlide={isActiveSlide}
        bubbleColor={accent.forest1}
        textColor={accent.forest250}
        questionText={t('presentation:annualReview:goals:question')}
        customAnimationDelay={
          slideAnimationDelay * 1.2 + inProgressGoals.length * 0.2
        }
        vertical={true}
      />
    </Wrapper>
  );
};
