import React from 'react';
import styled from 'styled-components';
import { accent } from '../../constants/colors';

const StyledHr = styled.hr`
  border: 0;
  border-left: 1px solid ${(props) => props.color};
  height: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const VerticalDivider = ({ color = accent.sand2, ...rest }) => {
  return <StyledHr color={color} {...rest} />;
};
