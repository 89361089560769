export const combineCashflowReportsData = (data, includeExternal) => {
  if (includeExternal) {
    return data.map((cashflow) => ({
      ...cashflow,
      amount: cashflow.amount + cashflow.externalAmount,
    }));
  } else {
    return data;
  }
};
