import React from 'react';
import styled from 'styled-components';

import { t, createAddressLine } from '@formue-app/core';
import { BaseSection } from '../common/BaseSection';
import { accent } from '../../constants/colors';
import { Paragraph, H2, H4 } from '../texts';
import { SPACING_8 } from '../../constants/spacing';
import { mobileCondition } from '../../constants/media';
import { StyledGridElement } from '../layout/GridElement';

const StyledH4 = styled(H4)`
  margin-bottom: ${SPACING_8};
`;

const StyledLine = styled.hr`
  border: none;
  border-top: 1px solid ${accent.sand1};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  margin: 0 auto;

  @media ${mobileCondition} {
    margin-right: auto;
    margin-left: 0;
    text-align: left;
  }
`;

export const AccountInfo = (props) => {
  const { account, columns } = props;

  const { name, email, phone, shippingAddress, billingAddress } = account;

  return (
    <StyledGridElement columns={columns}>
      <H2 style={{ marginBottom: 20 }}>{name}</H2>
      <BaseSection>
        <Content>
          <StyledH4>{t(`form:nameLabel`)}</StyledH4>
          <Paragraph>{name}</Paragraph>
        </Content>
        <StyledLine />
        <Content>
          <StyledH4>{t(`form:emailLabel`)}</StyledH4>
          <Paragraph>{email}</Paragraph>
        </Content>
        <StyledLine />
        <Content>
          <StyledH4>{t(`form:phoneLabel`)}</StyledH4>
          <Paragraph>{phone}</Paragraph>
        </Content>
        <StyledLine />
        <Content>
          <StyledH4>{t(`form:shippingAddressLabel`)}</StyledH4>
          <Paragraph>{createAddressLine(shippingAddress)}</Paragraph>
        </Content>
        <StyledLine />
        <Content>
          <StyledH4>{t(`form:billingAddressLabel`)}</StyledH4>
          <Paragraph>{createAddressLine(billingAddress)}</Paragraph>
        </Content>
      </BaseSection>
    </StyledGridElement>
  );
};
