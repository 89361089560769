import React from 'react';
import styled, { css } from 'styled-components';
import camelcase from 'camelcase';

import { backgroundWhite, accent, uiActive } from '../../constants/colors';
import { SPACING_16 } from '../../constants/spacing';
import { shortAnimationTime, easeInOut } from '../../constants/animation';
import { titleFontStack } from '../../fonts';
import { Paragraph } from '../../components/texts';

const large = {
  height: '30px',
  width: '54px',
  handle: '24px',
};

const small = {
  height: '24px',
  width: '44px',
  handle: '18px',
};

const StyledToggleWrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};
`;

const StyledToggleInner = styled.div`
  background: ${(props) =>
    props.value ? props.backgroundColorActive : 'none'};
  border: 1px solid
    ${(props) =>
      props.value ? props.backgroundColorActive : props.backgroundColor};
  border-radius: 15px;
  cursor: pointer;
  display: block;
  height: ${(props) => (props.small ? small.height : large.height)};
  overflow: hidden;
  position: relative;
  padding: 2px;
  transition: all ${shortAnimationTime} ${easeInOut};
  width: ${(props) => (props.small ? small.width : large.width)};

  &:hover {
    ${(props) =>
      !props.value &&
      css`
        border: 1px solid ${accent.ocean490};

        .handle {
          background: ${accent.ocean490};
        }
      `}
  }
`;

const StyledCheckbox = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  left: -9999px;
`;

const StyledToggleHandle = styled.div`
  background: ${(props) => (props.value ? backgroundWhite : accent.ocean470)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.small ? small.handle : large.handle)};
  margin-left: ${(props) =>
    props.value ? (props.small ? small.handle : large.handle) : 0};
  transition: all ${shortAnimationTime} ${easeInOut};
  position: relative;
  width: ${(props) => (props.small ? small.handle : large.handle)};
  z-index: 2;
`;

const StyledLabelWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 44px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  pointer-events: none;
`;

const StyledTextValue = styled.span`
  font-family: ${titleFontStack};
  font-weight: 500;
  font-size: 13px;
  margin-top: -2px;
`;

const StyledTextHandleValue = styled.span`
  font-family: ${titleFontStack};
  font-weight: 500;
  font-size: 13px;
  margin-top: -2px;
  margin-left: -1px;
`;

export const Toggle = (props) => {
  const {
    value,
    id,
    label,
    labels = [],
    onChange,
    disabled,
    labelColor,
    backgroundColor = accent.ocean470,
    backgroundColorActive = uiActive,
    small,
    ...rest
  } = props;

  return (
    <StyledToggleWrapper className="toggle" htmlFor={id} {...rest}>
      {label && (
        <Paragraph as="h4" style={{ color: labelColor }}>
          {label}
        </Paragraph>
      )}
      <StyledToggleInner
        backgroundColor={backgroundColor}
        backgroundColorActive={backgroundColorActive}
        value={value}
        small={small}
      >
        <StyledLabelWrapper>
          {labels.map((label) => (
            <StyledTextValue key={`label-${camelcase(label)}`}>
              {label}
            </StyledTextValue>
          ))}
        </StyledLabelWrapper>
        <StyledToggleHandle value={value} small={small} className="handle">
          <StyledTextHandleValue>
            {value ? labels[1] : labels[0]}
          </StyledTextHandleValue>
        </StyledToggleHandle>
        <StyledCheckbox
          type="checkbox"
          name={id}
          id={id}
          onChange={(event) => onChange(event)}
          disabled={disabled}
          onClick={e => e.stopPropagation()}
        />
      </StyledToggleInner>
    </StyledToggleWrapper>
  );
};
