import { apiFetch } from './utils/api';

// const mockedStrategy = require('./mocks/v3/strategy.json');

export const strategy = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = 'v3/strategy';

    const { body } = await apiFetch(url, {
      ...options,
    });
    // return mockedStrategy;
    return body;
  },
};
