import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_2,
} from '../../constants/spacing';
import { H3 } from '../texts';
import { accent, backgroundWhite } from '../../constants/colors';
import { useRoutes } from '../../config/routes';
import { ExtendedReportingIcon } from './ExtendedReportingIcon';
import { FlipArrow } from '../common/FlipArrow';

const Container = styled.div`
  padding: ${SPACING_16} ${SPACING_24};
  display: flex;
  flex-wrap: wrap;
`;

const NavigationItemWrapper = styled.div`
  flex: 50%;
  flex-grow: 0;
  padding: ${SPACING_8} 12px;
`;

const navigationItemStyle = css`
  display: flex;
  background-color: ${backgroundWhite};
  align-items: center;
  padding: ${SPACING_24};
  margin: ${SPACING_2} 0;
  text-decoration: none;
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const StyledNavigationItem = styled(NavLink)`
  ${navigationItemStyle}
`;

const StyledDisabledWrapper = styled.a`
  ${navigationItemStyle}
  opacity: 0.3;
`;

const ArrowWrapper = styled.div`
  margin-left: auto;
`;

const NavigationItem = (props) => {
  const { title, link, icon, iconProps, disabled } = props;

  const Icon = icon;

  if (disabled) {
    return (
      <NavigationItemWrapper>
        <StyledDisabledWrapper>
          <Icon
            width={32}
            height={32}
            style={{ marginRight: 12 }}
            {...iconProps}
          />
          <H3>{title}</H3>
          <ArrowWrapper>
            <FlipArrow color={accent.ocean4} />
          </ArrowWrapper>
        </StyledDisabledWrapper>
      </NavigationItemWrapper>
    );
  }

  return (
    <NavigationItemWrapper>
      <StyledNavigationItem to={link} activeClassName="active">
        <Icon
          width={32}
          height={32}
          style={{ marginRight: 12 }}
          {...iconProps}
        />
        <H3>{title}</H3>
        <ArrowWrapper>
          <FlipArrow color={accent.ocean4} />
        </ArrowWrapper>
      </StyledNavigationItem>
    </NavigationItemWrapper>
  );
};

export const ExtendedReportingNavigation = (props) => {
  const routes = useRoutes();

  const extendedReportingRoutes = routes.find(
    (route) => route.path === '/extended-reporting'
  ).routes;

  return (
    <Container {...props}>
      {extendedReportingRoutes
        .filter((route) => route.path !== '/extended-reporting')
        .map((route, index) => (
          <NavigationItem
            title={route.title}
            link={route.path}
            icon={ExtendedReportingIcon}
            iconProps={{ type: route.icon }}
            disabled={route.disabled}
            key={`navigation-item-${index}`}
          />
        ))}
    </Container>
  );
};
