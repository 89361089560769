import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';

import { MenuPage } from './base/MenuPage';

import { H3 } from '../components/texts';
import { GridElement } from '../components/layout';
import { SPACING_16, SPACING_20, SPACING_24 } from '../constants/spacing';
import { InfiniteScroll } from '../components/common/InfiniteScroll';
import { EventFeedItem } from '../components/feed/EventFeedItem';
import { SpriteIcon } from '../components/common/SpriteIcon';

const {
  entities: {
    events: { pastEventsSelector, futureEventsSelector },
  },
} = selectors;

export const EventsPage = (props) => {
  const futureEvents = useSelector(futureEventsSelector);
  const pastEvents = useSelector(pastEventsSelector);

  const numberOfEventItemsPerPage = 6;
  const [numberOfEventItemsToShow, setNumberOfEventItemsToShow] = useState(
    numberOfEventItemsPerPage
  );
  const pastEventItems = pastEvents.slice(0, numberOfEventItemsToShow);

  const loadItems = (page) => {
    setNumberOfEventItemsToShow((page + 1) * numberOfEventItemsToShow);
  };

  const renderPastEventItem = (event, { id }) => {
    return (
      <EventFeedItem
        columns={4}
        key={`event-${event.id}`}
        icon={<SpriteIcon id="Event" size={SPACING_20} />}
        {...event}
      />
    );
  };

  const renderUpcomingEvents = () => {
    return futureEvents.map((event) => (
      <EventFeedItem
        columns={4}
        key={`event-${event.id}`}
        icon={<SpriteIcon id="Event" size={SPACING_20} />}
        {...event}
      />
    ));
  };

  return (
    <MenuPage {...props} title={t('eventCalendar:events')} subpage={true}>
      <GridElement columns={12} style={{ paddingTop: SPACING_16 }}>
        <H3>Upcoming events</H3>
      </GridElement>
      {renderUpcomingEvents()}
      <GridElement columns={12} style={{ paddingTop: SPACING_24 }}>
        <H3>Previous events</H3>
      </GridElement>
      <InfiniteScroll next={loadItems}>
        {pastEventItems.map(renderPastEventItem)}
      </InfiniteScroll>
    </MenuPage>
  );
};
