import { createActions } from 'redux-actions';

export const actions = createActions({
  TASKS: {
    ADD_TO_IN_PROGRESS: (id) => id,
    REMOVE_FROM_IN_PROGRESS: (id) => id,
    ADD_TASK: (payload) => payload,
    UPDATE_TASK: (payload) => payload,
  },
}).tasks;
