import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { SPACING_88 } from '../../../constants/spacing';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { ReportQuestion } from '../reportComponent/ReportQuestion';
import { Family } from '../slideComponents/familyAndOwnership/family';
import { BusinessAndOwnership } from '../slideComponents/familyAndOwnership/businessAndOwnership';
import { Milestone } from '../slideComponents/familyAndOwnership/milestone';
import {
  DesktopWrapper,
  mobileCondition,
  notDesktopCondition,
  NotDesktopWrapper,
  tabletCondition,
} from '../../../constants/media';
import { FamilyMobile } from '../slideComponents/familyAndOwnership/familyMobile';

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;

  @media ${tabletCondition} {
    flex-direction: column;
  }

  @media ${mobileCondition} {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${notDesktopCondition} {
    width: 100%;
  }
`;

const StyledBusinessAndOwnership = styled(BusinessAndOwnership)`
  @media ${mobileCondition} {
    margin-bottom: ${SPACING_88};
  }

  @media ${tabletCondition} {
    margin-bottom: ${SPACING_88};
  }
`;

export const FamilyAndOwnershipSection = (props) => {
  const { backgroundColor, isVisible, ...rest } = props;

  return (
    <>
      <ReportTitle {...rest} style={{ marginBottom: SPACING_88 }} />
      <InnerWrapper>
        <ContentWrapper>
          <DesktopWrapper>
            <Family isActiveSlide={isVisible} />
          </DesktopWrapper>
          <NotDesktopWrapper>
            <FamilyMobile
              isActiveSlide={isVisible}
              style={{ marginBottom: SPACING_88 }}
            />
          </NotDesktopWrapper>
          <StyledBusinessAndOwnership
            itemsPrRow={2}
            isActiveSlide={isVisible}
          />
        </ContentWrapper>
        <ContentWrapper>
          <Milestone
            isActiveSlide={isVisible}
            showQuestion={false}
            style={{ marginTop: 0, height: 'auto' }}
          />
        </ContentWrapper>
      </InnerWrapper>
      <ReportQuestion
        {...rest}
        style={{ marginTop: SPACING_88 }}
        questionText={t(
          'presentation:annualReview:familyAndOwnership:question'
        )}
      />
    </>
  );
};
