import React from 'react';
import styled from 'styled-components';
import { mobileCondition, notMobileCondition } from '../../../constants/media';

import { SPACING_8, SPACING_24, SPACING_16 } from '../../../constants/spacing';

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media ${mobileCondition} {
    flex-direction: column;
  }
`;

export const Column = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  padding: 18px ${SPACING_8};

  @media ${mobileCondition} {
    padding: ${SPACING_16};
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @media ${notMobileCondition} {
      padding-left: ${SPACING_24};
    }
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    @media ${notMobileCondition} {
      padding-right: ${SPACING_24};
    }

    @media ${mobileCondition} {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

export const TodoListItem = (props) => {
  const { columns } = props;

  // const readItems = useSelector(state => state.ui.readStatus.readItems);
  // const isRead = !!readItems.find(readItem => readItem.id === id);
  // const isUnread = !isRead && status.toLowerCase() === 'open';

  if (!columns) return null;

  return (
    <Wrapper>
      {columns.map((column) => {
        const { key, component, render, width } = column;

        const Component = component ? component : Column;

        return (
          <Component
            key={key}
            style={{ width: width, display: 'flex', alignItems: 'center' }}
          >
            {render()}
          </Component>
        );
      })}
    </Wrapper>
  );
};
