import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  podcastEpisodesAllIds,
  podcastEpisodesById,
  allPodcastEpisodesSelector,
] = resourceSelectors('podcastEpisodes');

export const podcastEpisodesByIdSelector = createSelector(
  allPodcastEpisodesSelector,
  (allPodcastEpisodes) => {
    const flatPodcasts = allPodcastEpisodes.flatMap(
      (podcast) => podcast.episodes
    );
    return flatPodcasts.reduce((accumulator, episode) => {
      return {
        ...accumulator,
        [episode.guid]: episode,
      };
    }, {});
  }
);

export const allPodcastsSelector = createSelector(
  allPodcastEpisodesSelector,
  (allPodcastEpisodes) => {
    return allPodcastEpisodes.reduce((accumulator, episode) => {
      if (!accumulator.hasOwnProperty(episode.podcast.itunesId)) {
        return accumulator.push({
          [episode.podcast.itunesId]: episode.podcast,
        });
      }
    }, []);
  }
);

export const episodesByPodcastIdSelector = createSelector(
  allPodcastEpisodesSelector,
  (allPodcastEpisodes) => {
    return allPodcastEpisodes.reduce((accumulator, episode) => {
      const { itunesId } = episode.podcast;
      if (!accumulator.hasOwnProperty(itunesId)) {
        accumulator[itunesId] = { ...episode.podcast, episodes: [episode] };
      } else {
        accumulator[itunesId] = {
          ...episode.podcast,
          episodes: [...accumulator[itunesId].episodes, episode],
        };
      }
      return accumulator;
    }, {});
  }
);
