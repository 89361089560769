import { createSelector } from 'reselect';
import { capitalize } from 'lodash';

import { makeFilterSelector } from '../../../services/helpers';
import { expertAdviceDocumentsSelector } from './documents';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [expertInputsAllIds, expertInputsById, expertInputsSelector] =
  resourceSelectors('expertInputs');

export const expertAdviceTypes = [
  'Tax and Legal Review',
  'Capital Insurance',
  'Investment Strategy',
  'Pension',
  'Accounting Review',
  'Other',
];

export const expertAdviceOverviewTypes = [
  'Investment Strategy',
  'Tax and Legal Review',
  'Pension',
  'Accounting Review',
];

/**
 * Normalizes documents we fetch directly/manually from the document archive
 * to match the structure we get from the document property on expert inputs
 * directly in the cases we get them there. This way the code that displays
 * these documents don't need to know the difference.
 */
const normalizeDocuments = (documents) =>
  documents.map(({ url, name, id }) => ({
    url,
    name,
    userId: id.split('-')[0],
    documentId: id,
  }));

export const expertAdviceSelector = createSelector(
  expertInputsSelector,
  expertAdviceDocumentsSelector,
  (expertInputs, documents) => {
    const expertAdvice = [];

    expertAdviceTypes.map((category) => {
      const categoryDocuments = documents.filter((document) =>
        document.contentType.includes(category)
      );
      const expertInput = expertInputs.filter((item) =>
        item.type.includes(category)
      );

      if (expertInput.length) {
        expertInput.forEach((item) => {
          expertAdvice.push(item);
        });
      } else {
        if (categoryDocuments.length) {
          expertAdvice.push({
            name: capitalize(category),
            type: category,
            authors: [],
            document: normalizeDocuments(categoryDocuments),
          });
        }
      }
    });

    return expertAdvice;
  }
);

// Compose a selector based on status. It's ongoing, if status !== 'completed'
// uses the property "negative" on makeFilterSelector to flip the filter
export const openSelector = makeFilterSelector(
  expertInputsById,
  'initializing'
);
export const ongoingSelector = makeFilterSelector(expertInputsById, [
  'review',
  'presentation',
  'accountOpening',
  'depositMoney',
  'transfer',
]);
export const completedSelector = makeFilterSelector(
  expertInputsById,
  'completed'
);

export const expertAdviceHasContent = createSelector(
  expertAdviceSelector,
  (expertAdvice) => {
    return !!expertAdvice.length;
  }
);
