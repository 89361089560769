import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '../../formElements';
import { Paragraph } from '../../texts';
import { SPACING_16, SPACING_8 } from '../../../constants/spacing';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 6px ${SPACING_16};
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.6;
    pointer-events: none;
  `}

  ${(props) =>
    props.highlightOnHover &&
    `
    &:hover {
      background-color: #F8F8FA;
    }
  `}
`;

export const StyledLabel = styled(Paragraph)`
  padding-right: ${SPACING_8};
`;

export const ExtendedReportingCheckbox = ({
  label,
  onChange,
  selected,
  disabled,
  highlightOnHover,
}) => {
  return (
    <Container
      onClick={onChange}
      disabled={disabled}
      highlightOnHover={highlightOnHover}
    >
      <StyledLabel>{label}</StyledLabel>
      <Checkbox
        type="checkbox"
        id={label}
        name={label}
        checked={selected}
        disabled={disabled}
        style={{ margin: 0, pointerEvents: 'none' }}
        size={20}
      />
    </Container>
  );
};
