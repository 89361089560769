import { call, put, delay, takeLatest, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { securityPerformance } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { waitFor } from '../../../services';
import { activeFiltersSelector } from '../../ui/portfolio/selectors';

// Create actions and a reducer for the securityPerformance resource
export const [actions, reducer, resourceSagas] = resourceReducer(
  'security-performances'
);

// Create the sagas for this entity
function* readSecurityPerformance() {
  yield takeLatest(actions.indexRequestBegin, function* () {
    yield call(waitFor, (state) => state.ui.portfolio.initialized);
    try {
      const result = yield call(securityPerformance.get);
      const { meta } = result;
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, meta }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readSecurityPerformance, ...resourceSagas];
