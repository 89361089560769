import { useSelector } from 'react-redux';

import { selectors } from '../../store';
import { constants } from '../../constants';
import {
  getTaskTitle,
  getTaskDescription,
  getTaskButtonOpenLinkLabel,
  getTaskUrl,
} from '../../services/utilities/tasks';

const { openSelector: openTasksSelector } = selectors.entities.tasks;
const { ANNUAL_REVIEW_PREPERATION_TASK } = constants.tasks;

/**
 * A react hook to retrieve todo's that should be promoted
 */
export const useActivePromotedTodos = () => {
  // Get all _open_ tasks, closed tasks should never be promoted
  const tasks = useSelector(openTasksSelector);

  // A list of task types that should be promoted if they exist in the open tasks list
  const taskTypesThatShouldBePromoted = [ANNUAL_REVIEW_PREPERATION_TASK];

  const promotedTasks = tasks.filter((task) =>
    taskTypesThatShouldBePromoted.includes(task.taskType?.toLowerCase())
  );

  // Fetch props for all promoted tasks
  const promotedTasksProps = promotedTasks.map((task) => ({
    title: getTaskTitle(task),
    body: getTaskDescription(task),
    navLabel: getTaskButtonOpenLinkLabel(task),
    to: getTaskUrl(task),
  }));

  return promotedTasksProps;
};
