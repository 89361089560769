import React from 'react';
import moment from 'moment';

import { formatHexOpacity, truncateString } from '@formue-app/core';
import { accent } from '../../constants/colors';
import { bodyFontStack } from '../../fonts';

export const SpaghettiChartTooltip = ({
  x,
  y,
  datum,
  data,
  width,
  height,
  dx,
  dy,
  color = accent.ocean3,
  backgroundColor = accent.ocean4,
  lineColor = accent.ocean3,
  change,
  style,
  text,
  activePoints,
  scale,
  reverseDataRendering,
  selected,
  ...rest
}) => {
  let activePointsForRender = activePoints;

  if (reverseDataRendering) {
    activePointsForRender = activePointsForRender.toReversed();
    text = text.toReversed();
  }

  // Filter if a "selection" is made in the chart
  if (selected.length) {
    activePointsForRender = activePointsForRender.filter((point) =>
      selected.includes(point.id.split('-')[0])
    );
  }

  const rectanglePadding = 8;
  const rectangleWidth = 64;
  const rectangleHeight = 14 + text.length * 7;

  let date = moment(datum.x);
  let xMax = x;
  let yMax = y;

  // If we want to keep the tooltip rectangle within the dimensions of the chart we need
  // to limit the xMax / yMax positions. On the x-axis it needs to be done on both sides.
  // On y-axis it needs to be done only on top due to the default position of the
  // tooltip (always on top)
  if (x + rectangleWidth > width) xMax = width - rectangleWidth / 2;
  if (x - rectangleWidth < 0) xMax = rectangleWidth / 2;
  if (y + rectangleHeight > height) yMax = height - rectangleHeight;
  if (y - 20 < 0) yMax = 20;

  return (
    <g>
      <line
        x1={x}
        y1="0"
        x2={x}
        y2="300"
        stroke={lineColor}
        strokeWidth={0.5}
      />

      {activePointsForRender.map((activePoint, index) => {
        // We can find the color of the line that this text label represents
        const itemLineStrokeColor = activePoint.style.data.stroke;

        return (
          <circle
            key={`spaghetti-chart-tooltip-circle-${index}`}
            cx={x}
            cy={scale.y(activePoint._y)}
            r="2.5"
            fill={itemLineStrokeColor}
            stroke="#fff"
            strokeWidth={0.5}
          />
        );
      })}

      <svg x={xMax - rectangleWidth / 2} y={yMax + rectangleHeight - 43}>
        <rect
          width={rectangleWidth}
          height={rectangleHeight}
          rx="3"
          fill={formatHexOpacity(backgroundColor, 0.8)}
          stroke={lineColor}
          strokeWidth={0.5}
        />
        <text
          x={rectanglePadding / 2}
          y={9}
          width={rectangleWidth}
          fontSize="4.5"
          fill={accent.ocean1}
          fontWeight={500}
          fontFamily={bodyFontStack}
        >
          {date.format('YYYY')}
        </text>

        {activePointsForRender.map((activePoint, index) => {
          // We can find the color of the line that this text label represents
          const itemLabelColor = activePoint.style.data.labelColor;

          return (
            <g key={`spaghetti-chart-tooltip-label-${index}`}>
              <circle
                cx={rectanglePadding / 2 + 1}
                cy={15 + index * 7}
                r="1"
                fill={itemLabelColor}
              ></circle>
              <text
                x={rectanglePadding / 2 + 4}
                y={16 + index * 7}
                width={rectangleWidth}
                height={8}
                textAnchor="start"
                fontSize="3.5"
                fill={accent.ocean490}
                fontWeight={300}
                fontFamily={bodyFontStack}
              >
                <tspan>{`${truncateString(
                  activePoint.childName,
                  22
                )}: `}</tspan>
                <tspan fontWeight={500}>{text[index]}</tspan>
              </text>
            </g>
          );
        })}
      </svg>
    </g>
  );
};
