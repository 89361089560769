import { apiFetch } from './utils/api';

export const events = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v2/events';

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
