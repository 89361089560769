import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };

export { sagas } from './sagas';

const defaultState = {
  errorMessage: '',
};

export const reducer = handleActions({}, defaultState);
