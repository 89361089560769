import React from 'react';
import styled from 'styled-components';

import { getAssetClassName } from '@formue-app/core';

import { SPACING_48, SPACING_32, SPACING_24 } from '../../../constants/spacing';
import { accent, textLightest } from '../../../constants/colors';

import { ModalWithHeader } from '../../common/ModalWithHeader';
import { StrategyMatrix } from '../../common/StrategyMatrix';
import { BaseSection } from '../../common/BaseSection';
import { FixedPyramidChart } from '../../wealthPlan/InvestmentSimulation/FixedPyramidChart';

import { H3, Paragraph } from '../../texts';
import { MainGrid } from '../../layout';

const StyledStrategyMatrix = styled(StrategyMatrix)`
  width: 80px;
  height: 80px;
  margin-top: ${SPACING_32};
`;

const SubGrid = styled(MainGrid)`
  padding: 0;
  max-width: 100%;
`;

const StyledBaseSection = styled(BaseSection)`
  background: ${accent.sand150};
`;

export const RecommendedStrategyModal = (props) => {
  const { onRequestClose, risk, lockIn, ...rest } = props;

  return (
    <ModalWithHeader
      {...rest}
      onRequestClose={onRequestClose}
      contentWidth={1050}
      padding={SPACING_48}
      iconSize="12px"
      title="Recommended strategy"
      subTitle="Based on the risk level and lock-in level we place you in a quadrant in our investment model. The quadrant defines some recommended boundaries and together we find the optimal asset allocation just for you."
      textColor={textLightest}
      backgroundColor={accent.velvet4}
      headerContent={
        <StyledStrategyMatrix
          risk={risk}
          lockin={lockIn}
          color={accent.velvet330}
          highlightColor={accent.velvet3}
        />
      }
    >
      <SubGrid>
        <StyledBaseSection columns={6}>
          <H3 style={{ marginBottom: SPACING_24 }}>
            A short summary of this strategy
          </H3>
          <Paragraph>
            More information about the constraints in this strategy.
            Specifically about the ranges for different asset classes and groups
            and how that relates to risk.
            <br />
            <br />
            Integer posuere erat a ante venenatis dapibus posuere velit aliquet.
            Nullam id dolor id nibh ultricies vehicula ut id elit. Fusce
            dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
            ut fermentum massa justo sit amet risus.
          </Paragraph>
        </StyledBaseSection>
        <StyledBaseSection columns={6} style={{ padding: '32px 50px' }}>
          <FixedPyramidChart
            height={220}
            data={[
              {
                label: getAssetClassName('AV'),
                value: 0.15,
              },
              {
                label: getAssetClassName('MV'),
                value: 0,
              },
              {
                label: getAssetClassName('S'),
                value: 0.3,
              },
            ]}
          />
        </StyledBaseSection>
      </SubGrid>
    </ModalWithHeader>
  );
};
