export const getEventsInForecastPeriod = (
  events,
  numberOfYearsToForecast = 10
) => {
  // Filter the single events to only include the events in the period we want.
  // That means we want to filter out events that are _before_ the current year
  // and _after_ the given period.
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return events.filter((event) => {
    const eventYear = new Date(event.transactedAt).getFullYear();
    const notInThePast = eventYear >= currentYear;
    const notTooLongInTheFuture =
      eventYear <= currentYear + numberOfYearsToForecast;
    return notInThePast && notTooLongInTheFuture;
  });
};

export const getGroupedSingleEvents = (events) => {
  return events
    .map((event) => {
      const date = new Date(event.transactedAt);
      return {
        ...event,
        year: date.getFullYear(),
      };
    })
    .sort((a, b) => a.year - b.year)
    .reduce((acc, current) => {
      const matchingAccIndex = acc.findIndex(
        (item) => item.name === current.name && item.to + 1 === current.year
      );

      if (matchingAccIndex >= 0) {
        acc[matchingAccIndex].to = current.year;
        acc[matchingAccIndex].ids.push(current.id);
        acc[matchingAccIndex].group = true;

        return acc;
      } else {
        return [
          ...acc,
          {
            ...current,
            from: current.year,
            to: current.year,
            ids: [current.id],
            group: false,
          },
        ];
      }
    }, []);
};
