import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from '@formue-app/core';

import { SPACING_48, SPACING_32, SPACING_16 } from '../../constants/spacing';
import {
  notDesktopCondition,
  tabletCondition,
  mobileCondition,
} from '../../constants/media';
import { AccountInfo } from '../../components/profile/AccountInfo';
import { BankAccountSection } from '../../components/profile/BankAccountSection';
import { StyledGridElement } from '../../components/layout/GridElement';

import { BackButton } from '../../components/common/BackButton';
import { MenuPage } from '../base/MenuPage';

const { accounts: accountActions } = actions.entities;
const { accountByIdSelector } = selectors.entities.accounts;

const StyledBankAccountSection = styled(BankAccountSection)`
  padding-top: ${SPACING_48};

  @media ${tabletCondition} {
    padding-top: ${SPACING_32};
  }

  @media ${mobileCondition} {
    padding-top: ${SPACING_16};
  }
`;

export const AcccountSettingsPage = (props) => {
  const dispatch = useDispatch();
  const { id: accountId } = useParams();
  const isTabletOrMobile = useMediaQuery({
    query: notDesktopCondition,
  });
  const account = useSelector(accountByIdSelector(accountId));

  // Load all accessible accounts when loading this page
  useEffect(() => {
    if (!account) {
      dispatch(accountActions.indexRequestBegin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!account) return null;

  let columnCount = isTabletOrMobile ? 12 : 6;

  return (
    <MenuPage {...props} title={account.name}>
      <BackButton columns={12} />
      <StyledGridElement columns={columnCount}>
        <AccountInfo account={account} />
        <StyledBankAccountSection account={account} />
      </StyledGridElement>
    </MenuPage>
  );
};
