import React from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { t } from '@formue-app/core';

import {
  easeInOut,
  extraLongAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';

import { backgroundWhite } from '../../../constants/colors';
import {
  SPACING_24,
  ANNUAL_REVIEW_CONTENT_SPACING,
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_32,
} from '../../../constants/spacing';
import { H2, Paragraph } from '../../texts';
import { SpriteIcon } from '../../common/SpriteIcon';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
  flex-grow: 1;
  flex-basis: 0;
`;

const CardWrapper = styled.ul`
  width: 100%;
  display: flex;
  gap: ${SPACING_24};
  padding: 0;
  margin: 0;
`;

const Card = styled.li`
  width: calc(100% / 3);
  background-color: ${backgroundWhite};
  padding: 80px;
  border-radius: ${BORDER_RADIUS_LARGE};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;

  right: -150px;
  opacity: 0;
  transition: opacity ${easeInOut} ${extraLongAnimationTime},
    right ${easeInOut} 1s;
  transition-delay: ${(props) => `${slideAnimationDelay + props.index * 0.3}s`},
    ${(props) => `${slideAnimationDelay - 0.1 + props.index * 0.3}s`};

  ${(props) =>
    props.isActiveSlide &&
    `
    right: 0;
    opacity: 1;
  `}

  &:last-child {
    margin-right: 0;
  }
`;

export const Framework = (props) => {
  const { isActiveSlide } = props;

  const category = [
    {
      icon: (
        <SpriteIcon
          id="Safety"
          size={72}
          style={{ marginBottom: SPACING_32 }}
        />
      ),
      title: t('presentation:annualReview:framework:safety'),
      description: t('presentation:annualReview:framework:safetyDescription'),
    },
    {
      icon: (
        <SpriteIcon
          id="Preserve and Develop"
          size={72}
          style={{ marginBottom: SPACING_32 }}
        />
      ),
      title: t('presentation:annualReview:framework:preserveAndDevelop'),
      description: t(
        'presentation:annualReview:framework:preserveAndDevelopDescription'
      ),
    },
    {
      icon: (
        <SpriteIcon
          id="Create"
          size={72}
          style={{ marginBottom: SPACING_32 }}
        />
      ),
      title: t('presentation:annualReview:framework:create'),
      description: t('presentation:annualReview:framework:createDescription'),
    },
  ];

  return (
    <Wrapper>
      <CardWrapper>
        {category.map((item, index) => (
          <Card
            key={`framework-${camelcase(item.title)}`}
            index={index}
            isActiveSlide={isActiveSlide}
          >
            {item.icon}
            <H2 style={{ marginBottom: SPACING_16 }}>{item.title}</H2>
            <Paragraph>{item.description}</Paragraph>
          </Card>
        ))}
      </CardWrapper>
    </Wrapper>
  );
};
