import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { t, config, getAuthorizationUrl } from '@formue-app/core';

import { Button } from '../buttons';

const StyledButton = styled(Button)`
  width: 100%;
`;

export const BankIdLogin = memo((props) => {
  const { method, codeVerifier, className } = props;
  const { authCallbackUrl: redirectUrl } = config.app;
  const loading = useSelector((state) => state.auth.loading);

  const { language: locale } = useSelector((state) => state.ui.user);
  const options = { locale };
  const authUrl = getAuthorizationUrl(
    method,
    redirectUrl,
    codeVerifier,
    options
  );

  const onClick = (e) => {
    e.preventDefault();
    // Redirect to the auth endpoint
    window.location = authUrl;
  };

  return (
    <div className={className}>
      <StyledButton
        disabled={loading}
        onClick={onClick}
        label={t(`login:authMethods:${method}`)}
      />
    </div>
  );
});
