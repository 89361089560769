import React from 'react';
import { useTranslation } from 'react-i18next';

import { constants } from '@formue-app/core';
import playStoreBadgeEN from '../../assets/stores/PlayStoreBadge_EN_blk.png';
import playStoreBadgeNO from '../../assets/stores/PlayStoreBadge_NO_blk.png';
import playStoreBadgeSE from '../../assets/stores/PlayStoreBadge_SE_blk.png';
import playStoreBadgeDK from '../../assets/stores/PlayStoreBadge_DK_blk.png';

export const PlayStoreBadge = (props) => {
  let { height, width, className } = props;
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { playStoreLink } = constants.app;

  // Because google only provides badges in PNG format with 9px padding on them we need to modify
  // the height property a bit, and add an marginTop to make it behave similarly as the
  // AppStoreBadge component. Given the same input they will now render the same.
  if (height) {
    height = parseInt(height) + 18;
  }

  let badgeUrl;
  switch (language) {
    case 'nb':
      badgeUrl = playStoreBadgeNO;
      break;
    case 'se':
      badgeUrl = playStoreBadgeSE;
      break;
    case 'dk':
      badgeUrl = playStoreBadgeDK;
      break;
    default:
      badgeUrl = playStoreBadgeEN;
      break;
  }

  return (
    <a
      className={className}
      href={playStoreLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        style={{ marginTop: -9 + 'px' }}
        src={badgeUrl}
        height={height}
        width={width}
        alt="Google Play Store"
      />
    </a>
  );
};
