import React from 'react';
import styled, { css } from 'styled-components';

import { SPACING_16, SPACING_4, SPACING_8 } from '../../constants/spacing';

import ListItemIcon from '../../assets/icons/arrows/arrow-right-list.svg';
import { H4 } from '../texts';
import { MarkdownContent } from './MarkdownContent';

const StyledList = styled.ul`
  padding-left: 12px;
  margin-bottom: ${SPACING_16};
  list-style: none;
`;

const StyledListItem = styled.li`
  font-size: 15px;
  line-height: 24px;
  padding-left: ${SPACING_16};
  margin-bottom: ${SPACING_8};

  background-image: url(${ListItemIcon});
  background-repeat: no-repeat;
  ${(props) =>
    props.title
      ? css`
          background-position: 0px 1px;
        `
      : css`
          background-position: 0px 5px;
        `}

  &:last-child {
    margin-bottom: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ListItem = (props) => {
  const { item, children, ...rest } = props;

  let itemContent = null;

  if (item) {
    itemContent = (
      <>
        {item.title && <H4>{item.title}</H4>}
        {item.content && (
          <div
            style={{
              marginTop: SPACING_4,
              marginBottom: SPACING_8,
            }}
          >
            <MarkdownContent>{item.content}</MarkdownContent>
          </div>
        )}
        {item.component && item.component}
      </>
    );
  }

  return (
    <StyledListItem {...rest} title={item?.title}>
      {children ? children : itemContent}
    </StyledListItem>
  );
};

export const List = (props) => {
  const { items, children, ...rest } = props;

  return (
    <StyledList {...rest}>
      {children
        ? children
        : items.map((item, index) => (
            <ListItem key={`item-${item.id}-${index}`} item={item} />
          ))}
    </StyledList>
  );
};
