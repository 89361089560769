import { stringify } from 'query-string';
import { apiFetch } from './utils/api';

/**
 * @desc Chart data fetch
 *
 * @param {(number|number[])} id - ID of the user we're trying to fetch the data for. Array of id's
 * supported.
 *
 * @param {number} [period=12] -  THISMONTH, THISYEAR, TWELVEMONTHS, THREEYEARS, FIVEYEARS, TENYEARS or
 * SINCESTART
 *
 * @return json - data or error message
 */

export const chartData = {
  get: async (args = []) => {
    const [
      id,
      period,
      accounts,
      taxClasses,
      hideInternalPortfolio,
      hideExternalPortfolio,
    ] = args;
    const query = stringify(
      {
        ssids: id,
        period,
        account: accounts,
        taxclass: taxClasses,
        hideInternalPortfolio,
        hideExternalPortfolio,
      },
      { arrayFormat: 'comma' }
    );

    const options = {
      method: 'GET',
    };

    const url = `/v1/qlik/marketvalue?${query}`;
    const response = await apiFetch(url, {
      ...options,
    });

    return response;
  },
};
