import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, useErrorState, useResource } from '@formue-app/core';

import { backgroundMain } from '../../constants/colors';
import { SPACING_32 } from '../../constants/spacing';
import { GridElement } from '../../components/layout/GridElement';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { CarbonFootprint } from '../../components/portfolio/lenses/sustainability/CarbonFootprint';
import { CompanyPromise } from '../../components/portfolio/lenses/sustainability/CompanyPromise';
import { SustainabilityNewsfeed } from '../../components/portfolio/lenses/sustainability/SustainabilityNewsfeed';
import { TemperatureRise } from '../../components/portfolio/lenses/sustainability/TemperatureRise';
import { CarbonFootprintChart } from '../../components/portfolio/lenses/sustainability/CarbonFootprintChart';
import { ScienceBasedTargets } from '../../components/portfolio/lenses/sustainability/ScienceBasedTargets';
import { SocialResponsibility } from '../../components/portfolio/lenses/sustainability/SocialResponsibility';
import { FundClassification } from '../../components/portfolio/lenses/sustainability/FundClassification';
import { ReloadPageMessage } from '../../components/common/ReloadPageMessage';

const {
  entities: {
    portfolioLenses: { allPortfolioLensesSelector },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

export const SustainabilityPage = () => {
  const loading = useResource(['PORTFOLIO_LENSES/INDEX']);
  const portfolioLensesError = useErrorState(['PORTFOLIO_LENSES/INDEX']);
  const portfolioLenses = useSelector(allPortfolioLensesSelector);
  const [modal, setModal] = useState('');

  if (loading) {
    return (
      <GridElement columns={12}>
        <StyledCenteredActivityIndicator
          columns={12}
          size={30}
          background={backgroundMain}
        />
      </GridElement>
    );
  }

  if (portfolioLensesError)
    return (
      <GridElement columns={12} style={{ justifyContent: 'center' }}>
        <ReloadPageMessage />
      </GridElement>
    );

  if (portfolioLenses.length)
    return (
      <>
        <CarbonFootprint columns={6} />
        <CarbonFootprintChart columns={6} />
        <TemperatureRise columns={3} temperature={2.3} />
        <ScienceBasedTargets columns={3} />
        <SocialResponsibility columns={3} />
        <FundClassification columns={3} />
        <CompanyPromise columns={12} style={{ marginTop: SPACING_32 }} />
        <SustainabilityNewsfeed columns={12} />
      </>
    );

  return null;
};
