import { createSelector } from 'reselect';
import { indexOf } from 'lodash';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  accountTeamMembersAllIds,
  accountTeamMembersById,
  allAccountTeamMembersSelector,
] = resourceSelectors('accountTeamMembers');

export const mainAdvisorSelector = createSelector(
  allAccountTeamMembersSelector,
  (members) => {
    let advisor = members.find((member) => member.role === 'Main Advisor');
    if (!advisor) {
      advisor = members[0];
    }
    return advisor;
  }
);

export const advisorsSelector = createSelector(
  allAccountTeamMembersSelector,
  (members) => {
    let advisors = members.filter(
      (member) =>
        member.role === 'Main Advisor' ||
        member.role === 'Advisor' ||
        member.role === 'Financial Advisor'
    );

    return advisors;
  }
);

/**
 * Returns the list of team members excluding main advisor
 */
export const teamMembersSelector = createSelector(
  allAccountTeamMembersSelector,
  mainAdvisorSelector,
  (members, mainAdvisor) => {
    // Find index of main advisor
    const index = indexOf(members, mainAdvisor);
    // Clone team from props so we can modify the array
    const membersCopy = members.slice(0);
    // Remove main advisor from list of team members
    membersCopy.splice(index, 1);
    // Order teamMembers by importance
    membersCopy.sort((a, b) => {
      const order = [
        'Family Office Main Advisor',
        'Family Office Advisor',
        'Advisor',
        'Family Office Associate',
        'Main Assistant',
        'Assistant Advisor',
        'Assistant',
      ];
      return order.indexOf(b.role) - order.indexOf(a.role);
    });

    return membersCopy;
  }
);
