import React from 'react';
import styled from 'styled-components';

import { percentage, t } from '@formue-app/core';
import { ScatterChart } from '../../charts/ScatterChart';
import { ChartSummary } from '../../charts/ChartSummary';
import { formatScatterChartData } from '../../../services/utils/charts';

const InnerChartWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
`;

const SummaryWrapper = styled.div`
  width: 320px;
  margin: 0 100px;
`;

export const RiskAndReturnChart = (props) => {
  const { chartData = [], selected, animate, ...rest } = props;

  return (
    <InnerChartWrapper style={{ flexDirection: 'row' }} {...rest}>
      <ScatterChart
        height={260}
        data={formatScatterChartData(chartData, 'risk', 'return')}
        selected={selected}
        origin={[0, 0]}
      />
      <SummaryWrapper>
        <ChartSummary
          items={chartData.map((item, index) => {
            return {
              ...item,
              backgroundColor: item.color,
              subItems: [
                {
                  label: t('presentation:investmentStrategy:common:risk'),
                  value: percentage(item.risk * 100, 1),
                },
                {
                  label: t(
                    'presentation:investmentStrategy:common:annualReturn'
                  ),
                  value: percentage(item.return * 100, 1),
                },
              ],
            };
          })}
          selected={selected}
          animate={animate}
        />
      </SummaryWrapper>
    </InnerChartWrapper>
  );
};
