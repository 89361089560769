import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '../router';

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export const RouteWithSubRoutes = (route) => {
  const RouteComponent = route.public ? Route : PrivateRoute;
  return (
    <RouteComponent
      exact={route.exact}
      path={route.path}
      title={route.title}
      render={(props) => {
        // pass the sub-routes down to keep nesting
        return <route.component {...props} {...route} />;
      }}
    />
  );
};
