import React from 'react';

import { SpriteIcon } from '../../../common/SpriteIcon';

export const SectorIcon = ({
  width = 32,
  height = 32,
  sector,
  style,
  iconColor,
  raw,
  ...rest
}) => {
  const iconComponent = (sectorName) => {
    switch (sectorName) {
      case 'financials':
        return (
          <SpriteIcon
            size={width}
            id="Investment"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'healthCare':
        return (
          <SpriteIcon
            size={width}
            id="Health Care"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'informationTechnology':
        return (
          <SpriteIcon
            size={width}
            id="Info Tech"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'industrials':
        return (
          <SpriteIcon
            size={width}
            id="Industrials"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'utilities':
        return (
          <SpriteIcon
            size={width}
            id="Utilities"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'communicationServices':
        return (
          <SpriteIcon
            size={width}
            id="Communication Services"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'energy':
        return (
          <SpriteIcon
            size={width}
            id="Energy"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'materials':
        return (
          <SpriteIcon
            size={width}
            id="Sector Materials"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'consumerStaples':
        return (
          <SpriteIcon
            size={width}
            id="Consumer Staples"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'realEstate':
        return (
          <SpriteIcon
            size={width}
            id="Office"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );
      case 'consumerDiscretionary':
        return (
          <SpriteIcon
            size={width}
            id="Consumer Discretionary"
            color={iconColor}
            accentColor={iconColor}
            tertiaryColor={iconColor}
          />
        );

      default:
        return (
          <SpriteIcon
            size={width}
            id="Search"
            inverted={!iconColor}
            navigational={!iconColor}
          />
        );
    }
  };

  const IconComponent = iconComponent(sector);

  if (!IconComponent) {
    return null;
  }

  if (raw) {
    return IconComponent;
  }

  return (
    <div
      style={{
        width,
        height,
        aspectRatio: 1,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        ...style,
      }}
      {...rest}
    >
      {IconComponent}
    </div>
  );
};
