/** Supposedly the fastest way to check if an object is empty or not
 * https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
 * 
 * We want to use a fast method since the object may consist of thousands of keys. One key
 * for each document the user has.
 */
const objectIsEmpty = (object) => {
  for (let i in object) { return null };
  return true;
}

/**
 *
 * @param {*} items Items to search through
 * @param {*} index The search index for the items
 * @param {*} query The query we want to use
 * @param {*} options Options to manipulate the query
 * @param {'leading'|'trailing'} options.wildcard Pre- or postpends a wildcard to query
 *                                                 terms to allow nonprecise matching "fo*"
 * @param {Number} options.fuzzy Lunr fuzzy value, enables typo's in query to still match
 * @param {Number} options.count How many search results to return
 */
const searchItems = (items, index, query, options = {}) => {
  let searchQuery = query;
  // Based on the options passed in we manipulate the query before searching
  const { wildcard, fuzzy, count } = options;
  if (wildcard === 'trailing') {
    searchQuery = searchQuery
      .split(' ')
      .map(term => `${term}*`)
      .join(' ');
  }
  if (wildcard === 'leading') {
    searchQuery = searchQuery
      .split(' ')
      .map(term => `*${term}`)
      .join(' ');
  }

  if (fuzzy) {
    searchQuery = searchQuery
      .split(' ')
      .map(term => `${term}~${fuzzy}`)
      .join(' ');
  }

  let results = [];
  try {
    results = index.search(searchQuery);
  } catch (e) {
    // Something went wrong, but don't really need to handle it
    // it will fix it self when the query changes
  }

  if (count) {
    results = results.splice(0, count);
  }

  // Return list of ids for the search result
  return results.map(result => result.ref);
};

/**
 * 
 * @param {*} allIds 
 * @param {*} byId 
 * @param {*} filters 
 * @returns 
 */
const getFilteredItems = (allIds, byId, filters) => {
  if (!allIds.length || objectIsEmpty(byId)) {
    return [];
  }

  let filteredIds = allIds;
  for (const filterKey in filters) {
    const filter = filters[filterKey];
    const { value, transform, searchIndex, searchOptions, type, filterRange } =
      filter;

    if (type === 'date' && filterRange) {
      const [start, end] = filterRange;

      filteredIds = filteredIds.filter((id) => {
        const startTimestamp = new Date(start).getTime();
        const endTimestamp = new Date(end).getTime();

        const date = new Date(byId[id][filterKey]).getTime();
        return date >= startTimestamp && date <= endTimestamp;
      });
    }

    if (value.length > 0 && type !== 'date') {
      if (searchIndex) {
        filteredIds = searchItems(
          filteredIds,
          searchIndex,
          value,
          searchOptions
        );
      } else {
        filteredIds = filteredIds.filter(id => {
          const itemValue = transform
            ? transform(byId[id][filterKey])
            : byId[id][filterKey];

          if (Array.isArray(itemValue)) {
            return itemValue.includes(value);
          }

          // enable to pass multiple filter values (as an array)
          if (Array.isArray(value)) {
            return value.includes(itemValue);
          }

          return itemValue === value;
        });
      }
    }
  }

  // Return a list of items based on the filtered id lists
  return filteredIds.map(id => byId[id]);
};


/**
 * Extracts unique values we can use to filter on.
 *
 * @param {*} allIds - A list of ids `[1, 2, 3, 4]`
 * @param {*} byId - An object with items
 * ```
 *   {
 *     1: { name: 'Kari' }
 *     2: { name: 'Ola' }
 *     3: { name: 'Jens' }
 *     4: { name: 'Kari' }
 *   }
 * ```
 * @param {*} filters - The filters one can use
 * ```
 *   {
 *     name: {
 *       value: name, // from useState()
 *       onChange: setName, // from useState()
 *       type: 'select', // type of filter UI (usually a select box)
 *       transform: value => `${value} Nordmann`), // optional transform function
 *     },
 *   }
 * ```
 * @returns - a list of unique values for each filter
 * 
 * ```
 *   {
 *     name: ['Kari Nordmann', 'Ola Nordmann', 'Jens Nordmann]
 *   }
 * ```
 */
const getFilterData = (allIds, byId, filters) => {
  if (!allIds.length || objectIsEmpty(byId)) {
    return null;
  }

  if (allIds.length !== Object.keys(byId).length) {
    return null;
  }

  let filterData = {};
  allIds.forEach(id => {
    for (const filterKey in filters) {
      const filter = filters[filterKey];
      const { transform, searchIndex } = filter;

      // Filters with search index are special, and we don't want to try
      // to find filter values for it obviously
      if (searchIndex) {
        continue;
      }

      if (!filterData.hasOwnProperty(filterKey)) {
        filterData[filterKey] = new Set();
      }

      const filterValue = transform
        ? transform(byId[id][filterKey])
        : byId[id][filterKey];

      if (Array.isArray(filterValue)) {
        // Replace the values with a new Set to make the list unique
        filterData[filterKey] = new Set([
          ...filterData[filterKey],
          ...filterValue,
        ]);
      } else {
        filterData[filterKey].add(filterValue);
      }
    }
  });

  // Convert unique set to sorted array
  for (const filterKey in filters) {
    if (filterData.hasOwnProperty(filterKey)) {
      const valueSet = filterData[filterKey];
      // Doing the sort on the end here might be a bit expensive if the array is huge
      // but in most cases the value set shouldn't be that long
      filterData[filterKey] = Array.from(valueSet).sort();
    }
  }

  return filterData;
};

const createClearFunction = filters => () =>
  Object.values(filters).forEach(f => f.onChange(''));

/**
 * @function
 * @name filterList
 * @description - Given a list of items and an object describing the filters will return
 *                (1.) the filtered data, (2.) some metadata for the filters, e.g the filter data
 *                and (3.) a method that can be called on to clear all filters.
 * @param {selector} items - A list of items that represents the unfiltered data set
 * @param {object} filters - An object describing what attributes to filter on.
 *
 * @typedef filters
 * @type {object}
 * @property {string} value - The current value of the input for the filter (the first part of
 *                            `useState`)
 * @property {function} onChange - The method to call when the value should be updated (the
 *                                 second part of `useState`)
 * @property {string} label - The label to show on the input in the UI
 * @property {function} transform - A function to transform the value from the object, e.g convert
 *                                  a date to a string
 * @property {array} searchIndex - If you want to search the list, give a search index and it will
 *                                 add a search input
 *
 * @typedef searchIndex
 * @type {Array.<Object>}
 * @property {string} id - The items id
 * @property {string} searchString - A compounded string that we can search through to find an
 *                                   object based on query, e.g "[title] [description]"
 */
export const filterList = (allIds = [], byId = {}, filters = {}) => [
  getFilteredItems(allIds, byId, filters),
  getFilterData(allIds, byId, filters),
  createClearFunction(filters),
];
