export const SECTORS = [
  'financials',
  'healthCare',
  'informationTechnology',
  'industrials',
  'utilities',
  'communicationServices',
  'energy',
  'materials',
  'consumerStaples',
  'realEstate',
  'consumerDiscretionary',
];
