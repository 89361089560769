import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';

import * as selectors from './selectors';
export { selectors };

const defaultState = {};

export const reducer = handleActions({}, defaultState);
