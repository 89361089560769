import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { t, selectors, useResource } from '@formue-app/core';

import { MenuPage } from './base/MenuPage';
import { H3 } from '../components/texts';
import { GridElement } from '../components/layout';
import { SPACING_16 } from '../constants/spacing';
import { InfiniteScroll } from '../components/common/InfiniteScroll';
import { WebinarFeedItem } from '../components/feed/WebinarFeedItem';
import { SpriteIcon } from '../components/common/SpriteIcon';

const {
  entities: {
    webinars: { allWebinarsSelector },
  },
} = selectors;

export const WebinarsPage = (props) => {
  const allWebinars = useSelector(allWebinarsSelector);
  useResource(['WEBINARS/INDEX']);

  const numberOfWebinarItemsPerPage = 6;
  const [numberOfWebinarItemsToShow, setNumberOfWebinarItemsToShow] = useState(
    numberOfWebinarItemsPerPage
  );
  const webinarItems = allWebinars.slice(0, numberOfWebinarItemsToShow);

  const loadItems = (page) => {
    setNumberOfWebinarItemsToShow((page + 1) * numberOfWebinarItemsToShow);
  };

  const renderWebinarItem = (webinar, { id }) => {
    return (
      <WebinarFeedItem
        columns={4}
        key={`feed-${webinar.id}`}
        icon={<SpriteIcon id="Art" size={20} />}
        {...webinar}
      />
    );
  };

  return (
    <MenuPage {...props} title={t('eventCalendar:webinars')} subpage={true}>
      <GridElement columns={12} style={{ paddingTop: SPACING_16 }}>
        <H3>{t('eventCalendar:webinars')}</H3>
      </GridElement>
      <InfiniteScroll next={loadItems}>
        {webinarItems.map(renderWebinarItem)}
      </InfiniteScroll>
    </MenuPage>
  );
};
