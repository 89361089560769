import React from 'react';
import styled from 'styled-components';
import { accent } from '../../constants/colors';
import { bodyFontStack } from '../../fonts';

const StyledSlider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  /* background: ${accent.ocean250}; */
  outline: none;
  -webkit-transition: 0.2s;
  background: -webkit-gradient(
    linear,
    0% 0%,
    100% 0%,
    color-stop(${(props) => props.value / props.max}, ${accent.ocean1}),
    color-stop(${(props) => props.value / props.max}, ${accent.ocean250})
  );

  &:hover {
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: ${accent.ocean1};
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: ${accent.ocean1};
    border-radius: 50%;
    cursor: pointer;
  }
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
`;

const Label = styled.span`
  font-family: ${bodyFontStack};
  font-size: 11px;
`;

export const Range = (props) => {
  const {
    step,
    min = 0,
    max = 100,
    value = 50,
    onChange = () => {},
    name,
    ...rest
  } = props;

  return (
    <div>
      <StyledSlider
        type="range"
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
      <Labels>
        <Label>{min}</Label>
        <Label>{max}</Label>
      </Labels>
    </div>
  );
};
