import React from 'react';
import camelcase from 'camelcase';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { SummaryBox } from '../../common/SummaryBox';

import { DocumentList } from './DocumentList';
import { ExpertContact } from './ExpertContact';
import { ExpertInputIcon } from './ExpertInputIcon';

import { H2, Paragraph } from '../../texts';

import {
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_48,
} from '../../../constants/spacing';
import {
  notMobileCondition,
  tabletCondition,
  desktopCondition,
  mobileCondition,
  NotMobileWrapper,
  MobileWrapper,
} from '../../../constants/media';

const StyledTitle = styled(H2)`
  @media ${desktopCondition} {
    margin-top: ${SPACING_24};
  }

  @media ${tabletCondition} {
    margin-top: ${SPACING_24};
    margin-bottom: ${SPACING_8};
  }

  @media ${mobileCondition} {
    margin-top: ${SPACING_24};
    margin-bottom: ${SPACING_8};
  }

  margin-top: ${SPACING_16};
  margin-bottom: ${SPACING_16};
`;

const StyledSummaryBox = styled(SummaryBox)`
  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const ContentWrapper = styled.div`
  @media ${notMobileCondition} {
    padding: ${SPACING_32} ${SPACING_24};
  }
`;

const AuthorsWrapper = styled.div`
  margin-top: ${SPACING_32};
`;

export const ExpertDocument = (props) => {
  const { id, type, authors, document } = props;

  return (
    <StyledSummaryBox
      columns={6}
      label={t('wealthPlan:expertAdvice:readMore')}
      url={!!id && `/todo/expert-inputs/${id}`}
    >
      <ContentWrapper>
        <NotMobileWrapper>
          <ExpertInputIcon type={type} size={SPACING_48} />
        </NotMobileWrapper>
        <MobileWrapper>
          <ExpertInputIcon type={type} size={SPACING_32} />
        </MobileWrapper>
        <StyledTitle>
          {t(`wealthPlan:expertAdvice:${camelcase(type)}:title`)}
        </StyledTitle>
        <Paragraph style={{ marginBottom: SPACING_16 }}>
          {t(`wealthPlan:expertAdvice:${camelcase(type)}:ingress`)}
        </Paragraph>
        <DocumentList type={camelcase(type)} document={document} />
        {!!authors.length && (
          <AuthorsWrapper>
            {authors.map((author, index) => (
              <ExpertContact
                contact={author}
                key={`author-${index}`}
                style={[
                  { marginBottom: SPACING_32 },
                  index === authors.length - 1 && { marginBottom: 0 },
                ]}
              />
            ))}
          </AuthorsWrapper>
        )}
      </ContentWrapper>
    </StyledSummaryBox>
  );
};
