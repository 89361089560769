import React from 'react';
import styled from 'styled-components';

import {
  BORDER_RADIUS_LARGE,
  BORDER_RADIUS_SMALL,
  SPACING_12,
  SPACING_24,
  SPACING_8,
} from '../../constants/spacing';
import { backgroundWhite, hoverBoxShadowLvl2 } from '../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

import { H4 } from '../texts';

const StyledSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  position: absolute;
  top: 60px;
  background: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_LARGE};
  right: ${SPACING_24};
  min-width: 240px;
  box-shadow: ${hoverBoxShadowLvl2};
  z-index: 1;
  padding: ${SPACING_12};
`;

const SubMenuItem = styled.div`
  padding: ${SPACING_8};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background ${shortAnimationTime} ${easeInOut};

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    background: #f0f5f9;
    border-radius: ${BORDER_RADIUS_SMALL};
  }

  ${(props) => props.customStyle};
`;

export const SubMenu = (props) => {
  const { items, showSubMenu, ...rest } = props;

  if (!showSubMenu) return null;

  return (
    <StyledSubMenu {...rest}>
      {items.map((item, index) => {
        const {
          style,
          icon,
          title,
          children,
          disable = false,
          ...itemRest
        } = item;

        if (disable) return null;

        return (
          <SubMenuItem
            customStyle={style}
            key={`sub-menu-item-${index}`}
            {...itemRest}
          >
            {icon}
            <H4 style={{ marginLeft: !!icon ? SPACING_12 : 0 }}>{title}</H4>
            {children}
          </SubMenuItem>
        );
      })}
    </StyledSubMenu>
  );
};
