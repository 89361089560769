import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';
import { Link } from 'react-router-dom';

import { t } from '@formue-app/core';

import { actions as navigationActions } from '../../store/ui/navigation';
import {
  BORDER_RADIUS_LARGE,
  SPACING_32,
  SPACING_16,
  SPACING_8,
  SPACING_24,
} from '../../constants/spacing';
import { accent, textLightest } from '../../constants/colors';
import {
  easeInOut,
  longAnimationTime,
  shortAnimationTime,
} from '../../constants/animation';

import { AccountsButton } from '../portfolio/filters/AccountsButton';
import { Logo } from '../common/Logo';
import { SubMenu } from '../common/SubMenu';
import { SpriteIcon } from '../common/SpriteIcon';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';
import { H4, H6 } from '../texts';

const HeaderWrapper = styled.header`
  position: fixed;
  padding: ${SPACING_32};
  padding-top: ${(props) => (!props.showDetails ? SPACING_16 : SPACING_16)};
  padding-bottom: ${(props) => (!props.showDetails ? SPACING_16 : SPACING_16)};
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  display: flex;
  justify-content: space-between;
  transition: ${easeInOut} background ${shortAnimationTime},
    ${easeInOut} padding ${longAnimationTime};

  ${(props) =>
    (props.showAdminPanel || props.showSharePanel) &&
    `
    background: ${accent.ocean4};
  `}
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PortfolioFiltersWrapper = styled.div`
  margin-left: auto;
  margin-right: ${SPACING_8};
  display: inline-flex;
  align-items: center;
`;

const CloseAdmin = styled(H4)`
  color: ${textLightest};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledMenuIcon = styled(SpriteIconFunctional)`
  cursor: pointer;
  vertical-align: bottom;
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  margin-right: ${SPACING_8};
  padding: 3px;
`;

const StyledAccountsButton = styled(AccountsButton)`
  background-color: ${(props) => props.backgroundColor};

  p {
    color: ${(props) => props.color};
  }

  path {
    fill: ${(props) => props.color};
    transition: all ${easeInOut} ${shortAnimationTime};
  }
`;

const StyledFilterIcon = styled(SpriteIcon)`
  cursor: pointer;
  margin-left: 12px;
`;

const customSubMenuItem = css`
  background: ${accent.sand1};
  border-bottom-left-radius: ${BORDER_RADIUS_LARGE};
  border-bottom-right-radius: ${BORDER_RADIUS_LARGE};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const PresentationHeader = (props) => {
  const {
    showAdminPanel,
    setShowAdminPanel,
    showSharePanel,
    setShowSharePanel,
    showPortfolioFilters,
    setActiveIndex,
    activeSlide,
    showDetails,
    alternativeColors,
    darkLogo,
    closePresentationLinkPath,
    enableShare,
    ...rest
  } = props;

  const { titleColor, filterBackgroundColor } = activeSlide;

  const dispatch = useDispatch();

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [exportMode] = useQueryState('exportMode', false);

  const toggleFullscreen = () => {
    if (!fullscreen) {
      document.documentElement.requestFullscreen().then(() => {
        setFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setFullscreen(false);
      });
    }
  };

  const subMenuItems = [
    {
      onClick: () => {
        setShowAdminPanel(!showAdminPanel);
        setShowSubMenu(!showSubMenu);
      },
      icon: <SpriteIcon id="Overview" size={SPACING_24} />,
      title: t('presentation:common:seeAllSlides'),
    },
    enableShare
      ? {
          onClick: () => {
            setShowSharePanel(!showSharePanel);
            setShowSubMenu(!showSubMenu);
          },
          icon: <SpriteIcon id="Share" size={SPACING_24} />,
          title: t('presentation:share:title'),
        }
      : null,
    {
      onClick: () => toggleFullscreen(),
      icon: !fullscreen ? (
        <SpriteIcon id="Fullscreen" size={SPACING_24} />
      ) : (
        <SpriteIcon id="Fullscreen exit" size={SPACING_24} />
      ),
      title: !fullscreen
        ? t('presentation:common:fullscreenOpen')
        : t('presentation:common:fullscreenClose'),
      children: (
        <H6 style={{ color: accent.ocean450, marginLeft: 'auto' }}>F11</H6>
      ),
    },
    {
      icon: <StyledSpriteIconFunctional id="Close" size={24} />,
      title: t('presentation:common:closePresentation'),
      style: customSubMenuItem,
      as: Link,
      to: closePresentationLinkPath,
    },
  ].filter(Boolean);

  return (
    <HeaderWrapper
      {...rest}
      showDetails={showDetails}
      showAdminPanel={showAdminPanel}
      showSharePanel={showSharePanel}
    >
      <LogoWrapper onClick={() => setActiveIndex(0)}>
        <Logo height={16} monochrome={!darkLogo} />
      </LogoWrapper>
      {!exportMode ? (
        <>
          {!showAdminPanel && showPortfolioFilters && (
            <PortfolioFiltersWrapper>
              <StyledAccountsButton
                backgroundColor={accent[filterBackgroundColor]}
                color={accent[titleColor]}
              />
              <StyledFilterIcon
                id="Filter"
                color={accent[titleColor]}
                accentColor={accent[titleColor]}
                tertiaryColor={accent[titleColor]}
                size={20}
                onClick={() => {
                  dispatch(navigationActions.togglePopover('portfolioFilters'));
                }}
              />
            </PortfolioFiltersWrapper>
          )}

          <div>
            {!showAdminPanel && !showSharePanel && (
              <StyledMenuIcon
                id="Menu"
                color={accent[titleColor]}
                onClick={() => setShowSubMenu(!showSubMenu)}
              />
            )}
            {(showAdminPanel || showSharePanel) && (
              <CloseAdmin
                onClick={() => {
                  setShowAdminPanel(false);
                  setShowSharePanel(false);
                }}
              >
                {t('presentation:common:closeAdminPanel')}
                <StyledSpriteIconFunctional
                  id="Close"
                  color={textLightest}
                  size={32}
                  style={{
                    marginLeft: SPACING_8,
                    padding: SPACING_8,
                  }}
                />
              </CloseAdmin>
            )}
            <SubMenu items={subMenuItems} showSubMenu={showSubMenu} />
          </div>
        </>
      ) : null}
    </HeaderWrapper>
  );
};
