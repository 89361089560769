import React from 'react';
import { Switch } from 'react-router-dom';

import { RouteWithSubRoutes } from '../../components/router';

export const HoldingsPage = (props) => {
  const { routes, children } = props;

  return (
    <>
      {children}
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </>
  );
};
