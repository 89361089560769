import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { FlatList } from '../lists/FlatList';
import { H4 } from '../texts/H4';
import { ProfileIcon } from '../common/ProfileIcon';
import { SPACING_16 } from '../../constants/spacing';

const RowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: ${SPACING_16};
`;

const StyledProfileIcon = styled(ProfileIcon)``;

const AccountRow = (props) => {
  const {
    account: { name },
  } = props;
  return (
    <RowContainer>
      <StyledProfileIcon name={name} size={24} />
      <H4>{name}</H4>
    </RowContainer>
  );
};

export const AccountSelector = (props) => {
  const { accounts, columns = 6 } = props;

  return (
    <FlatList
      title={t(`settings:accounts:title`)}
      columns={columns}
      rows={accounts}
      keyExtractor={(item, index) => `account-${index}`}
      renderItem={(account) => <AccountRow account={account} />}
      toExtractor={(item) => `/profile/accounts/${item.ssid}`}
    />
  );
};
