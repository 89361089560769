import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { selectors } from '@formue-app/core';

import { ParagraphSmall } from '../../texts';
import { SPACING_16, SPACING_8 } from '../../../constants/spacing';
import { accessTokenSelector } from '../../../store/auth/selectors';
import { SpriteIcon } from '../../common/SpriteIcon';

const Document = styled.a`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_8};
  margin-bottom: ${SPACING_8};
  text-decoration: none;
  cursor: default;

  ${(props) =>
    props.shouldDownload &&
    css`
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    `}
`;

const StyledParagraphSmall = styled(ParagraphSmall)`
  font-weight: 500;
`;

const {
  entities: {
    documents: { allDocumentsSelector },
  },
} = selectors;

export const DocumentList = (props) => {
  const { document, type } = props;

  const documentsList = useSelector(allDocumentsSelector);
  const accessToken = useSelector(accessTokenSelector);
  let documents = document;

  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);
  const isEmployeeClientUser = useSelector(
    (state) => state.ui.user.isEmployeeClientUser
  );
  const shouldBeAbleToOpenDocuments = !isImpersonating || isEmployeeClientUser;

  // Before documents is fully implemented on the backend we manualy fetch documents that are
  // available in the document list
  if (!document && type === 'investmentStrategy') {
    const documentNames = documentsList.map((key) => documentsList[key]);

    documents = documentNames.filter((item) =>
      item.contentType.includes('Investment Strategy')
    );
  }

  if (!documents || !documents.length) {
    return null;
  }

  return documents.map((doc, index) => (
    <Document
      href={
        shouldBeAbleToOpenDocuments
          ? `${doc.url}?secret_token=${accessToken}`
          : '#'
      }
      target="_blank"
      key={`document-${doc.documentId}-${index}`}
      download={doc.name}
      shouldDownload={shouldBeAbleToOpenDocuments}
      as={shouldBeAbleToOpenDocuments ? 'a' : 'span'}
    >
      <SpriteIcon id="Download" size={SPACING_16} />
      <StyledParagraphSmall style={{ opacity: !doc.url ? 0.5 : 1 }}>
        {doc.name}
      </StyledParagraphSmall>
    </Document>
  ));
};
