import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { selectors, t, humanFriendlyDate, useResource } from '@formue-app/core';

import { MenuPage } from './base/MenuPage';
import { GridElement } from '../components/layout';
import { H2, H4 } from '../components/texts';
import { BaseSection } from '../components/common/BaseSection';
import { SPACING_48, SPACING_16, SPACING_8 } from '../constants/spacing';
import { MarkdownContent } from '../components/common/MarkdownContent';
import { Feed } from '../components/feed/Feed';

const StyledHeading = styled(H2)`
  padding: ${SPACING_8} 0 ${SPACING_16} 0;
`;
const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_48};
`;
const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;
const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledSubtitle = styled(H2)`
  grid-column: span 12;
  padding-top: ${SPACING_16};
  margin-bottom: -${SPACING_8};
`;

const {
  entities: {
    webinars: { webinarsById },
  },
} = selectors;

export const WebinarPage = (props) => {
  const loading = useResource(['WEBINARS/INDEX']);
  const { id } = useParams();
  const webinars = useSelector(webinarsById);
  const webinar = webinars[id];

  if (loading || !webinar) return null;

  return (
    <MenuPage {...props} title={t('eventCalendar:webinars')} subpage={true}>
      <GridElement columns={12}>
        {webinar.hasOwnProperty('video') && (
          <PlayerWrapper className="player-wrapper">
            <StyledReactPlayer
              url={webinar.video.url}
              width="100%"
              height="100%"
            />
          </PlayerWrapper>
        )}
      </GridElement>
      <GridElement columns={12}>
        <StyledBaseSection>
          <H4>{humanFriendlyDate(webinar.publishedAt)}</H4>
          <StyledHeading>{webinar.title}</StyledHeading>
          <MarkdownContent>{webinar.description}</MarkdownContent>
        </StyledBaseSection>
      </GridElement>
      <StyledSubtitle>{t('eventCalendar:otherWebinars')}</StyledSubtitle>
      <Feed exclusiveItemType="webinars" />
    </MenuPage>
  );
};
