import React from 'react';
import styled, { css } from 'styled-components';

import ArrowDown from '../../assets/icons/arrows/arrow-down.svg';

import { StyledInputBase } from './Input';
import { Label } from './Label';
import { SPACING_8 } from '../../constants/spacing';
import { accent, blackTranslucent } from '../../constants/colors';

export const FilledSelectStyle = css`
  &:hover:not(:disabled):not(:focus) {
    background-color: #e7ebf2;
    border-color: #e7ebf2;
  }

  &:disabled {
    background-color: ${blackTranslucent(0.04)};
    border-color: transparent;
  }
`;

export const OutlineSelectStyle = css`
  background-color: transparent;
  border-color: ${accent.ocean420};

  &:hover:not(:disabled):not(:focus) {
    background-color: #e7ebf2;
  }

  &:disabled {
    background-color: ${blackTranslucent(0.04)};
    border-color: ${accent.ocean420};
  }
`;

const StyledSelect = styled.select`
  ${StyledInputBase}
  background-image: url(${ArrowDown});
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 35px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  ${(props) => props.inputstyle === 'filled' && FilledSelectStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineSelectStyle}
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

export const Select = (props) => {
  const {
    placeholder,
    value,
    onChange,
    options = [],
    name,
    style,
    popoutLabel,
    inputstyle,
    label,
    ...rest
  } = props;

  return (
    <InputWrapper>
      {popoutLabel && label && <Label>{label}</Label>}
      <StyledSelect
        name={name}
        value={value}
        onChange={onChange}
        style={style}
        inputstyle={inputstyle}
        {...rest}
      >
        {!!placeholder && !popoutLabel && (
          <option value="" defaultValue="" disabled key="value-0">
            {placeholder}
          </option>
        )}
        {options.map((option, index) => (
          <option
            value={option.value}
            key={`value-${value}-${index}`}
            disabled={option.disabled}
            defaultValue={option.defaultValue}
            selected={option.selected}
          >
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </InputWrapper>
  );
};
