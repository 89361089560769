import React, { useState } from 'react';
import {
  VictoryChart,
  VictoryAxis,
  VictoryArea,
  VictoryScatter,
} from 'victory';
import styled from 'styled-components';

import { formatHexOpacity } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { GraphFilter } from './GraphFilter';

const StyledVictoryChart = styled(VictoryChart)`
  width: calc(100% + 40px);
  margin-left: -20px;
`;

export const MarketDevelopmentGraph = (props) => {
  const {
    startYear,
    index,
    filteredIndex,
    historicalEvents,
    setIndexFilter,
    indexFilter,
    setSelectedHistoricalEvent,
  } = props;
  const [graphWidth] = useState(1);
  let markedPrice = 0;

  const indexPrices = filteredIndex.map((item) => {
    markedPrice = markedPrice + item.change;
    return { price: markedPrice, ...item };
  });

  const height = 200;

  const markers = [];
  for (const item of historicalEvents) {
    const priceIndex = indexPrices.findIndex(
      (price) => price.date === item.date
    );
    if (priceIndex >= 0) {
      const price = {
        index: priceIndex,
        price: indexPrices[priceIndex].price,
      };

      markers.push({
        price,
        ...item,
      });
    }
  }

  return (
    <>
      <StyledVictoryChart padding={15} height={height}>
        <VictoryArea
          padding={0}
          data={indexPrices}
          animate={{ duration: 500 }}
          // labels={({ data, index }) => (index === 10 ? 'last label' : index)}
          // labelComponent={<H6>Hey</H6>}
          x="date"
          y="price"
          style={{
            data: {
              stroke: formatHexOpacity(accent.ocean2, 0.2),
              strokeWidth: 1.5,
              fill: formatHexOpacity(accent.ocean3, 0.2),
            },
          }}
        />
        <VictoryAxis
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fill: 'transparent' },
          }}
        />
        {markers.map((item, index) => (
          <VictoryScatter
            key={`key-${index}`}
            size={15}
            style={{
              data: {
                fill: '#C4C4C4',
                stroke: accent.ocean3,
                fillOpacity: 0.3,
                strokeWidth: 1,
                cursor: 'pointer',
              },
            }}
            data={[{ x: item.price.index, y: item.price.price }]}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: (e, props) => {
                    const selectedMarker = markers.find(
                      (item) => item.price.index === props.datum.x
                    );
                    setSelectedHistoricalEvent(selectedMarker);
                  },
                },
              },
            ]}
          />
        ))}
      </StyledVictoryChart>
      <GraphFilter
        startYear={startYear}
        index={index}
        setIndexFilter={setIndexFilter}
        indexFilter={indexFilter}
        width={graphWidth}
      />
    </>
  );
};
