import { AsyncStorage } from '../services/AsyncStorage';
import { apiFetch } from './utils';
import { UserSerializer } from './serializers/user';

export const users = {
  v2: {
    get: async (args) => {
      // Allow consumers of this to pass in either id directly, or an object
      // with id as property
      let id;
      let impersonate = true;
      if (typeof args === 'object' && args !== null) {
        id = args.id;
        if (args.impersonate !== undefined) {
          impersonate = args.impersonate;
        }
      } else {
        id = args;
      }

      const options = {
        method: 'GET',
      };

      let url = '/v2/users';

      if (id) {
        url = `${url}/${id}`;
      }

      const { body } = await apiFetch(url, options, {}, impersonate);

      return body;
    },

    /**
     * @desc Update a user
     *
     * @return json - data or error message
     */
    update: async (args, data) => {
      const { id } = args;
      const payload = UserSerializer.serialize(data);

      const options = {
        method: 'PATCH',
        body: payload,
      };
      const url = `/v2/users/${id}`;

      return apiFetch(url, options);
    },
  },
  v1: {
    get: async () => {
      const options = {
        method: 'GET',
      };
      const url = '/v1/users';
      const { body } = await apiFetch(url, {
        ...options,
      });

      return body;
    },

    getOne: async () => {
      const options = {
        method: 'GET',
      };
      const url = '/v1/user';
      const { body } = await apiFetch(url, {
        ...options,
      });

      return body;
    },

    update: async (userData) => {
      const options = {
        method: 'PATCH',
        body: JSON.stringify(userData),
      };

      const url = '/v1/user';
      const { body } = await apiFetch(url, options);

      return body;
    },
  },
};

export const logOutUser = async () => {
  await Promise.all(AsyncStorage.removeItem('accessToken'));
};
