import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { percentage, t } from '@formue-app/core';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_8,
} from '../../../../constants/spacing';
import { H2, H3, Paragraph } from '../../../texts';
import { CollapsibleListItem } from '../../../common/CollapsibleListItem';
import { accent } from '../../../../constants/colors';
import { SpriteIcon } from '../../../common/SpriteIcon';

const i18nPrefix = 'sustainability:coverage';

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${(props) => props.backgroundColor};
`;

const Background = styled.div`
  background-color: ${(props) => props.backgroundColor};
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
`;

const ContentContainer = styled.div`
  padding: 0 ${SPACING_32} ${SPACING_32} ${SPACING_32};
`;

const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
  padding-bottom: ${SPACING_12};
`;

const StyledTip = styled(Paragraph)`
  font-style: italic;
  padding-bottom: ${SPACING_12};
`;

const StyledHeading = styled(H3)`
  padding: ${SPACING_16} 0;
`;

const CoverageContent = () => (
  <ContentContainer>
    <H2 style={{ paddingBottom: SPACING_24 }}>
      {t(`${i18nPrefix}:modal:title`)}
    </H2>
    <StyledTip>{t('sustainability:coverage:modal:tip')}</StyledTip>
    <StyledHeading>{t('sustainability:coverage:modal:heading1')}</StyledHeading>
    <StyledParagraph>
      <Trans
        i18nKey="sustainability:coverage:modal:text1"
        components={{
          strong: (
            <span
              style={{
                fontWeight: 500,
              }}
            />
          ),
        }}
      />
    </StyledParagraph>
    <StyledHeading>{t('sustainability:coverage:modal:heading2')}</StyledHeading>
    <StyledParagraph>
      {t('sustainability:coverage:modal:text2')}
    </StyledParagraph>
  </ContentContainer>
);

export const Coverage = ({
  coverage = 0,
  backgroundColor = accent.sand150,
  ...rest
}) => {
  return (
    <Background backgroundColor={backgroundColor}>
      <StyledCollapsibleListItem
        details={<CoverageContent />}
        startExpanded={false}
        padding={0}
        style={{ boxShadow: 'none' }}
        detailsBackgroundColor={backgroundColor}
        backgroundColor={backgroundColor}
        {...rest}
      >
        <InnerContainer backgroundColor={backgroundColor}>
          <TitleWrapper>
            <SpriteIcon
              id="Info"
              color={accent.ocean490}
              size={15}
              style={{ marginRight: SPACING_8 }}
            />
            <Paragraph
              style={{
                whiteSpace: 'pre-line',
                maxWidth: 700,
                marginTop: 0,
                marginRight: 'auto',
              }}
            >
              <Trans
                i18nKey={`${i18nPrefix}:coverageLabel`}
                values={{
                  percentage: percentage(coverage * 100, 1, false, false),
                }}
                components={{
                  strong: (
                    <strong
                      style={{
                        fontWeight: 500,
                      }}
                    />
                  ),
                }}
              />
            </Paragraph>
          </TitleWrapper>
        </InnerContainer>
      </StyledCollapsibleListItem>
    </Background>
  );
};
