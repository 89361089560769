import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { backgroundWhite, primary } from '../../constants/colors';
import { shortAnimationTime, easeInOut } from '../../constants/animation';
import { mobileCondition } from '../../constants/media';

import { actions as navigationActions } from '../../store/ui/navigation';

const StyledMobileMenuToggle = styled.div`
  display: none;
  height: 36px;
  width: 36px;
  padding: 5px;

  @media ${mobileCondition} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Bar = styled.div`
  height: 3px;
  width: 100%;
  background: ${(props) => (props.inverted ? backgroundWhite : primary)};
  display: block;
  margin: 0 auto;
  /* transition: all ${shortAnimationTime} ${easeInOut} ${shortAnimationTime}; */
  rotate: 0deg;
  transform-origin: center center;
`;

const Bar1 = styled(Bar)`
  margin-bottom: 5px;

  ${(props) =>
    props.open &&
    `
    width: 80%;
    margin-bottom: -1.5px;
    rotate: 45deg;
    background: ${backgroundWhite};
  `}
`;

const Bar2 = styled(Bar)`
  width: 51%;

  ${(props) =>
    props.open &&
    `
    opacity: 0;
    height: 0;
    `}
`;

const Bar3 = styled(Bar)`
  width: 80%;
  margin-top: 5px;

  ${(props) =>
    props.open &&
    `
    margin-top: -1.5px;
    rotate: -45deg;
    background: ${backgroundWhite};
  `}
`;

export const MobileMenuToggle = (props) => {
  const { inverted } = props;
  const dispatch = useDispatch();
  const { mainMenuOpen } = useSelector((state) => state.ui.navigation);

  return (
    <StyledMobileMenuToggle
      onClick={() => dispatch(navigationActions.toggleMainMenu())}
    >
      <Bar1 inverted={inverted} open={mainMenuOpen}></Bar1>
      <Bar2 inverted={inverted} open={mainMenuOpen}></Bar2>
      <Bar3 inverted={inverted} open={mainMenuOpen}></Bar3>
    </StyledMobileMenuToggle>
  );
};
