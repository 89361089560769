import { apiFetch } from './utils/api';

export const authenticatorDevices = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v3/authenticator-devices';
    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },

  delete: async (id) => {
    const options = {
      method: 'DELETE',
    };

    const url = `/v3/authenticator-devices/${id}`;
    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
