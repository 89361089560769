import React from 'react';
import { Trans } from 'react-i18next';

import {
  formatTwrValue,
  formatGainValue,
  formatPeriodLabel,
} from '@formue-app/core';

import { accent } from '../../../../constants/colors';
import { H1, H3 } from '../../../texts';
import { SPACING_16 } from '../../../../constants/spacing';
import { titleFontStack } from '../../../../fonts';

export const PortfolioReturnTitle = (props) => {
  const {
    gain,
    twr,
    period,
    startDate,
    endDate,
    titleColor,
    highlightColor,
    style,
  } = props;

  let year;

  switch (period) {
    case 'THISYEAR':
      year = new Date().getFullYear();
      break;
    case 'TWELVEMONTHS':
      year = 1;
      break;
    case 'THREEYEARS':
      year = 3;
      break;
    case 'FIVEYEARS':
      year = 5;
      break;
    case 'CUSTOM':
      year = formatPeriodLabel(period, startDate, endDate);
      break;
    default:
      year = 10;
      break;
  }

  return (
    <>
      <H1 style={{ color: accent[titleColor], whiteSpace: 'pre', ...style }}>
        <Trans
          i18nKey={`presentation:annualReview:portfolio:${period.toLowerCase()}`}
          values={{
            year,
            twr: twr ? formatTwrValue(twr) : '…',
          }}
          components={{
            strong: (
              <span
                style={{
                  color: accent[highlightColor],
                  fontWeight: 700,
                  fontFamily: titleFontStack,
                }}
              />
            ),
          }}
        />
      </H1>
      <H3
        style={{
          color: accent[titleColor],
          marginTop: SPACING_16,
          whiteSpace: 'pre',
          ...style,
        }}
      >
        <Trans
          i18nKey={'presentation:annualReview:portfolio:gain'}
          values={{
            gain: gain ? formatGainValue(gain) : '…',
          }}
          components={{
            strong: (
              <span
                style={{
                  fontWeight: 500,
                  color: accent[highlightColor],
                }}
              />
            ),
          }}
        />
      </H3>
    </>
  );
};
