import React from 'react';
import styled from 'styled-components';

import {
  accent,
  backgroundWhite,
  elevatedBoxShadowSmooth,
} from '../../../constants/colors';
import { SPACING_16 } from '../../../constants/spacing';
import { H6, Paragraph } from '../../texts';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';

const Container = styled.div`
  background-color: ${backgroundWhite};
  box-shadow: ${elevatedBoxShadowSmooth};
  padding: ${SPACING_16} 20px 0 20px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
`;

const ApplyButton = styled(Paragraph)`
  font-weight: 500;
  cursor: pointer;
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const Label = styled(H6)`
  text-transform: uppercase;
  color: ${accent.ocean470};
`;

const FilterSetItem = (props) => {
  const { filterSet, apply, remove } = props;
  const { id, title } = filterSet;

  return (
    <Wrapper>
      <ApplyButton
        onClick={() => {
          apply(id);
        }}
      >
        {title}
      </ApplyButton>
      <SpriteIconFunctional
        id="Close"
        onClick={() => {
          remove(id);
        }}
      />
    </Wrapper>
  );
};

export const FilterSets = ({ filterSets, remove, apply, ...rest }) => {
  return (
    <Container {...rest}>
      <Label>Your saved sets</Label>
      <StyledList>
        {filterSets.map((filterSet) => (
          <FilterSetItem
            key={filterSet.id}
            filterSet={filterSet}
            apply={apply}
            remove={remove}
          />
        ))}
      </StyledList>
    </Container>
  );
};
