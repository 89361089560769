import { createSelector } from 'reselect';
import { uniq } from 'lodash';

const investmentProfileById = (state) => state.entities.investmentProfile.byId;
export const investmentProfileAllIds = (state) =>
  state.entities.investmentProfile.allIds;

export const investmentProfileSelector = createSelector(
  investmentProfileAllIds,
  investmentProfileById,
  (allIds, byId) => {
    if (!allIds?.length) {
      return null;
    }
    return byId[allIds[0]];
  }
);

export const investmentProfilePreferencesSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile?.preferences;
  }
);

export const sustainabilityPreferencesSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile?.sustainabilityPreferences;
  }
);

export const expectedReturnTypesSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile.expectedReturnTypes;
  }
);

export const choiceTypesSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return [
      ...investmentProfile.preferenceCostPotentialTypes.reverse(),
      ...investmentProfile.preferenceEsgTotalTypes,
      ...investmentProfile.preferenceParticipationTypes.reverse(),
    ];
  }
);

export const choicesSelector = createSelector(
  investmentProfilePreferencesSelector,
  (preferences) => {
    return [
      preferences.costPotential,
      preferences.esgTotal,
      preferences.participation,
    ];
  }
);

export const riskLevelSelector = createSelector(
  investmentProfilePreferencesSelector,
  (preferences) => preferences.riskLevel
);

export const riskCapacityTimeHorizonSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile.riskCapacity.timeHorizon;
  }
);

export const riskCapacityAssetClassTypesSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return uniq([
      ...investmentProfile.assetClassExperienceTypes,
      ...investmentProfile.assetClassKnowledgeTypes,
    ]);
  }
);

export const riskCapacityAssetClassSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile.riskCapacity.assetClass;
  }
);

export const lossAversionSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile.riskTolerance.lossAversion;
  }
);

export const winningAppetiteSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile.riskTolerance.winningAppetite;
  }
);

export const riskProfileSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile.riskTolerance.riskProfile;
  }
);

export const riskProfileIdSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile.riskTolerance.neuroprofiler.riskProfile;
  }
);

export const idealVolatilitySelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    return investmentProfile.riskTolerance.idealVolatility;
  }
);

export const investmentStrategyDetailsSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    if (!investmentProfile) {
      return null;
    }
    return investmentProfile.investmentStrategyDetails;
  }
);

export const knowledgeAndTrainingCommentSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    if (!investmentProfile) {
      return null;
    }
    return investmentProfile.riskCapacity.knowledgeAndTrainingComment;
  }
);

export const investmentProfileHasContent = createSelector(
  investmentProfileAllIds,
  (allIds) => {
    return !!allIds.filter(Boolean).length;
  }
);

export const investmentStrategyRiskAndLockInSelector = createSelector(
  investmentProfileSelector,
  (investmentProfile) => {
    if (!investmentProfile) {
      return null;
    }
    return investmentProfile.riskAndLockIn;
  }
);
