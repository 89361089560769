import React from 'react';
import camelcase from 'camelcase';

import { SpriteIcon } from '../../common/SpriteIcon';

export const ExpertInputIcon = (props) => {
  const { type, size = 20 } = props;

  switch (camelcase(type)) {
    case 'investmentStrategy':
      return <SpriteIcon id="Results" size={size} />;
    case 'taxAndLegalReview':
      return <SpriteIcon id="Law" size={size} />;
    case 'pension':
    case 'pensionPlan':
      return <SpriteIcon id="Retirement" size={size} />;
    case 'accountingReview':
      return <SpriteIcon id="Account" size={size} />;
    default:
      break;
  }
};
