import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import camelcase from 'camelcase';

export const useEntities = (actions = []) => {
  const dispatch = useDispatch();
  const entities = useSelector((state) => state.entities);
  const loading = useSelector((state) => state.api.loading);

  useEffect(() => {
    // if state.api.loading[action] is still to be initialized, return true
    actions.forEach((action) => {
      const camelcasedEntity = camelcase(action.replace(/\/[^/]*$/, ''));

      // if state.api.loading[action] is yet to be initialized, dispatch a request
      if (Object.keys(loading).includes(action)) {
        // if the entity is not in a state of loading, dispatch a request
        if (!loading[action]) {
          dispatch({ type: `${action}_REQUEST_BEGIN` });
          return;
        }
      }

      // if the entity exists but it's empty for whatever the reason is, dispatch a request
      if (!entities[camelcasedEntity].allIds.length) {
        dispatch({ type: `${action}_REQUEST_BEGIN` });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
