import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';

import {
  formatHexOpacity,
  selectors,
  t,
  config,
  useResource,
  getCSVDownloadLink,
} from '@formue-app/core';

import { accent, textLightest } from '../../constants/colors';
import { SPACING_24, SPACING_16, SPACING_8 } from '../../constants/spacing';
import { H2, Paragraph } from '../../components/texts';

import { VisibleInCountry } from '../../components/common/VisibleInCountry';
import { ListFilterHeader } from '../../components/lists';
import { HoldingsList } from '../../components/portfolio/holdings/HoldingsList';
import { Total } from '../../components/portfolio/costAndFees/Total';
import { PortfolioHoldingsChart } from '../../components/portfolio/charts/PortfolioHoldingsChart';
import { BaseSection } from '../../components/common/BaseSection';
import { PortfolioPeriodFilter } from '../../components/portfolio/filters/PortfolioPeriodFilter';
import { PortfolioGainTotal } from '../../components/portfolio/overview/PortfolioGainTotal';
import { AssetClassGroups } from '../../components/portfolio/holdings/AssetClassGroups';
import { accessTokenSelector } from '../../store/auth/selectors';
import {
  mobileCondition,
  tabletCondition,
  desktopMaxCondition,
  printCondition,
} from '../../constants/media';

const {
  entities: {
    portfolios: { portfolioAssetClassesWithSubitemsSelector },
  },
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: 0;
  background-color: ${accent.velvet4};
`;

const StyledListFilterHeader = styled(ListFilterHeader)`
  label {
    margin-right: ${SPACING_16};
  }

  @media ${mobileCondition} {
    label {
      width: 100%;
      margin-bottom: ${SPACING_8};
      margin-right: 0;

      h4 {
        margin-right: auto !important;
      }

      &::last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${SPACING_24};
  padding-bottom: 20px;
  border-bottom: 1px solid ${formatHexOpacity(accent.velvet2, 0.3)};
  align-items: flex-end;
  height: 70px;

  @media ${desktopMaxCondition} {
    align-items: flex-start;
  }

  @media ${tabletCondition} {
    flex-direction: column;
  }

  @media ${mobileCondition} {
    flex-direction: column;
  }

  @media ${printCondition} {
    margin: 0;
  }
`;

const StyledNoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING_24};
`;

const StyledPortfolioGainTotalWrapper = styled.div`
  align-self: flex-start;
  @media ${desktopMaxCondition} {
    flex: 1;
  }
`;

const StyledPortfolioGainTotal = styled(PortfolioGainTotal)`
  margin-left: 0;
`;

const StyledAssetClassGroupsWrapper = styled.div`
  @media ${desktopMaxCondition} {
    flex: 1;
  }
`;

const StyledH2 = styled(H2)`
  color: ${textLightest};
`;

const StyledParagraph = styled(Paragraph)`
  color: ${textLightest};
`;

export const HoldingsOverviewPage = () => {
  const loading = useResource(['PORTFOLIOS/INDEX']);
  const assetClasses = useSelector(portfolioAssetClassesWithSubitemsSelector);
  const activeFilters = useSelector(activeFiltersSelector);
  const accessToken = useSelector(accessTokenSelector);
  const { language: chosenLanguage } = useSelector((state) => state.ui.user);
  const [showAll, setShowAll] = useQueryState('showAll', false);
  const [openItems, setOpenItems] = useQueryState('openItems', []);
  const [showSold, setShowSold] = useQueryState('showSold', true);

  const hasContent = loading || Boolean(assetClasses.length);

  const normalizeItems = () =>
    assetClasses.map((item) => ({
      ...item,
      isOpen: openItems.includes(item.id),
    }));

  // Filter all the subitems of items
  const filterSoldItems = (items) => {
    if (showSold) {
      return items;
    }

    return items.map((item) => {
      item.subItems = item.subItems.filter((subItem) => {
        return !!subItem.mv || !!subItem.quantity;
      });
      return item;
    });
  };

  const getItems = () => {
    const items = normalizeItems();
    const filteredItems = filterSoldItems(items);
    return filteredItems;
  };

  const [items, setItems] = useState(getItems());

  useEffect(() => {
    // Don't do anything until we have any asset classes
    if (!assetClasses.length) {
      return;
    }
    setItems(getItems());
  }, [assetClasses, showSold]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handler to call if you want toggle the open state of an item.
  const onSelectedAssetClass = ({ id }) => {
    const index = openItems.indexOf(id);

    if (index > -1) {
      openItems.splice(index, 1);
      setOpenItems([...openItems]);
    } else {
      setOpenItems([...new Set([...openItems, id])]);
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (showAll) {
      // If show all is false when we start, copy all ids into openItems to open all
      setOpenItems(assetClasses.map((item) => item.id));
    } else {
      setOpenItems([]);
    }
  }, [showAll, setOpenItems, assetClasses]);

  const filters = {
    showAll: {
      id: 'show-all-toggle',
      type: 'toggle',
      value: showAll,
      onChange: toggleShowAll,
      label: t('portfolio:seeAllSecurities'),
    },
    showSold: {
      id: 'show-sold',
      type: 'toggle',
      value: showSold,
      onChange: () => {
        setShowSold(!showSold);
      },
      label: t('portfolio:showSoldSecurities'),
    },
  };

  const doExport = () => {
    const url = `${config.api.host}${config.api.baseUrl}/v2/qlik/portfolios.csv`;
    const link = getCSVDownloadLink(
      url,
      activeFilters,
      chosenLanguage,
      accessToken
    );
    window.open(link);
  };

  return (
    <>
      <StyledBaseSection columns={12}>
        {hasContent ? (
          <>
            <StyledWrapper>
              <StyledPortfolioGainTotalWrapper>
                <StyledPortfolioGainTotal />
              </StyledPortfolioGainTotalWrapper>
              <StyledAssetClassGroupsWrapper>
                <AssetClassGroups />
              </StyledAssetClassGroupsWrapper>
            </StyledWrapper>
            <PortfolioHoldingsChart selectCallback={() => {}} />
            <PortfolioPeriodFilter
              highlightColor={formatHexOpacity(accent.velvet3, 0.3)}
              borderColor={accent.velvet3}
            />
          </>
        ) : (
          <>
            <StyledNoContentWrapper>
              <StyledH2>{t('errorMessages:noPortfolio:title')}</StyledH2>
              <StyledParagraph>
                {t('errorMessages:noPortfolio:text')}
              </StyledParagraph>
            </StyledNoContentWrapper>
          </>
        )}
      </StyledBaseSection>
      <StyledListFilterHeader filters={filters} data={[]} onExport={doExport} />
      <HoldingsList
        items={items}
        openItems={openItems}
        clickCallback={onSelectedAssetClass}
      />

      <VisibleInCountry country={'se'}>
        <Total />
      </VisibleInCountry>
    </>
  );
};
