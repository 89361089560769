import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MarkdownContent } from '../components/common/MarkdownContent';

import {
  selectors,
  t,
  useResource,
} from '@formue-app/core';

import { BaseSection } from '../components/common/BaseSection';
import { H2, H3 } from '../components/texts';
import { GridElement } from '../components/layout';

import { SPACING_16 } from '../constants/spacing';
import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';

const {
  entities: {
    investmentStrategies: { investmentStrategyByIdSelector },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  margin-bottom: ${SPACING_16};
`;

const StyledH3 = styled(H3)`
  margin-bottom: ${SPACING_16};
`;

const StyledContainer = styled.div`
  max-width: 700px;
`;

export const StrategyDetailsCommentsPage = (props) => {
  const { id } = useParams();
  const loading = useResource([
    {
      type: 'INVESTMENT_STRATEGY_IMPLEMENTATION_PLANS/INDEX',
      args: { strategyId: id },
    },
  ]);

  const strategy = useSelector(investmentStrategyByIdSelector(id));

  if (loading) {
    return (
      <GridElement columns={12}>
        <CenteredActivityIndicator />
      </GridElement>
    );
  }

  return (
    <>
      <GridElement columns={12}>
        <H2>{t('advisor:strategySimulator:comments:title')}</H2>
      </GridElement>

      <StyledBaseSection columns={12}>
        <StyledH3>{t('advisor:strategySimulator:export:strategyCommentTitle')}</StyledH3>
        <StyledContainer>
          <MarkdownContent>
            {strategy?.whyThisStrategyDescription || `_${t('advisor:strategySimulator:comments:commentMissing')}_`}
          </MarkdownContent>
        </StyledContainer>
      </StyledBaseSection>

      <StyledBaseSection columns={12}>
        <StyledH3>{t('advisor:strategySimulator:export:portfolioCommentTitle')}</StyledH3>
        <StyledContainer>
          <MarkdownContent>
            {strategy?.clientsInvestmentSolutionDescription || `_${t('advisor:strategySimulator:comments:commentMissing')}_`}
          </MarkdownContent>
        </StyledContainer>
      </StyledBaseSection>

      <StyledBaseSection columns={12}>
        <StyledH3>{t('advisor:strategySimulator:export:portfolioImplementationTitle')}</StyledH3>
        <StyledContainer>
          <MarkdownContent>
            {strategy?.portfolioImplementationDescription || `_${t('advisor:strategySimulator:comments:commentMissing')}_`}
          </MarkdownContent>
        </StyledContainer>
      </StyledBaseSection>
    </>
  );
};
