import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';
import { H3, ParagraphSmall } from '../texts';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_8,
  SPACING_2,
} from '../../constants/spacing';
import { BaseSection } from './BaseSection';
import { H5LineHeight, H5Size } from '../../constants/text';
import teaserImageUrl from '../../assets/images/default/teaser.jpg';
import { textLink } from '../../constants/colors';

const InnerContainer = styled.div`
  padding: ${SPACING_2} ${SPACING_16} ${SPACING_8} ${SPACING_16};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  gap: ${SPACING_16};
  margin: ${SPACING_16} 0;
`;

const Thumbnail = styled.div`
  width: 76px;
  height: 76px;
  border-radius: ${BORDER_RADIUS_LARGE};
  background-size: cover;
  background-position: center;
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-size: ${H5Size};
  line-height: ${H5LineHeight};
  font-weight: 500;
  color: ${textLink};
`;

const Description = styled(ParagraphSmall)`
  flex: 1;
`;

const { operatingCountrySelector } = selectors.auth;

export const AppTeaserWidget = (props) => {
  const operatingCountry = useSelector(operatingCountrySelector);
  let link = '';

  switch (operatingCountry) {
    case 'no': {
      link = 'https://formue.no/formueappen/';
      break;
    }
    case 'se': {
      link = 'https://formue.se/formueappen/';
      break;
    }
    case 'dk': {
      link = 'https://formue.dk/formueappen/';
      break;
    }
    default: {
      throw new Error(`Link to App missing for country ${operatingCountry}`);
    }
  }

  return (
    <BaseSection {...props}>
      <InnerContainer>
        <H3>{t('dashboard:teaser:title')}</H3>
        <ContentWrapper>
          <Thumbnail style={{ backgroundImage: `url(${teaserImageUrl})` }} />
          <Description>{t('dashboard:teaser:description')}</Description>
        </ContentWrapper>
        <StyledLink href={link} target="_blank">
          {t('globals:readMore')}
        </StyledLink>
      </InnerContainer>
    </BaseSection>
  );
};
