import React from 'react';
import styled from 'styled-components';

import { accent } from '../../../../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_24,
} from '../../../../constants/spacing';
import { GridElement } from '../../../layout';

const Container = styled(GridElement)`
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${accent.sand150};
`;

export const SustainabilityBaseItem = (props) => {
  const { children, ...rest } = props;

  return <Container {...rest}>{children}</Container>;
};
