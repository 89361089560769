import React from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  t,
  actions,
  sortStrategyType,
  usePortfolioExternalReportingFilter,
  getPredefinedRanges,
} from '@formue-app/core';

import { H3, Paragraph } from '../../texts';

import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl1,
  hoverBoxShadowLvl2,
} from '../../../constants/colors';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../constants/spacing';
import { mobileCondition } from '../../../constants/media';
import {
  H4LineHeight,
  H4Size,
  H6LineHeight,
  H6Size,
} from '../../../constants/text';

import { ProfileIcon } from '../../common/ProfileIcon';
import { Checkbox } from '../../formElements';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import {
  accountTypeIcon,
  activeStrategyAccountsName,
  formatedName,
} from '../strategy/StrategyPicker';

const ContentWrapper = styled.div`
  background: ${backgroundWhite};
  padding: ${SPACING_24};
  position: absolute;
  width: 416px;
  right: 0;
  box-shadow: ${hoverBoxShadowLvl2};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  z-index: 3;

  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  ${(props) =>
    props.position === 'bottom'
      ? css`
          translate: 0 100%;
          bottom: -5px;
        `
      : css`
          top: -5px;
          translate: 0 -100%;
        `}

  @media ${mobileCondition} {
    width: 100%;
    box-shadow: ${hoverBoxShadowLvl1};

    & > h3 {
      text-transform: uppercase;
      font-size: ${H6Size};
      line-height: ${H6LineHeight};
    }
  }
`;

const Item = styled.div`
  border-bottom: 1px solid ${accent.sand3};
  padding-bottom: ${SPACING_24};

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media ${mobileCondition} {
    padding: ${SPACING_16} 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};

  @media ${mobileCondition} {
    & > h3 {
      /* text-transform: uppercase; */
      font-size: ${H4Size};
      line-height: ${H4LineHeight};
    }
  }
`;

const SubList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding-left: 48px;
  margin-top: ${SPACING_16};

  @media ${mobileCondition} {
    margin-top: ${SPACING_8};
  }
`;

const SubListItem = styled.li`
  margin-bottom: ${SPACING_16};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}

  &:last-child {
    margin: 0;
  }
`;

const {
  ui: { portfolio: portfolioActions },
} = actions;

export const PeriodicStrategyContent = (props) => {
  const {
    accounts,
    portfolioAccounts,
    taxClasses,
    position,
    color,
    iconStyles,
    combinedStrategies,
    activeStrategy,
    ...rest
  } = props;

  const dispatch = useDispatch();

  // To prevent an error where multiple instances of the same component where interfering with each
  // other we add an id on runtime to each component. This is related to the radio button component.
  const id = Math.floor(Math.random() * 10000);

  const { period, endDate } = useSelector(
    (state) => state.ui.portfolio.filters
  );
  const { newEndDate } = getPredefinedRanges(period);
  const now = new Date();

  const isCurrentMonth =
    now.getFullYear() === new Date(endDate || newEndDate).getFullYear() &&
    now.getMonth() === new Date(endDate || newEndDate).getMonth();

  const handleSelectStrategy = (strategy) => {
    if (!isCurrentMonth) return;

    // If we click the selected strategy again we should deselect it
    // We also need to re-eanable the external portfolio filter if its not selected
    if (activeStrategy?.id === strategy.id) {
      dispatch(
        portfolioActions.setFilters({
          externalReporting: ['showInternalPortfolio', 'showExternalPortfolio'],
          excludedSsids: [],
          excludedTaxClasses: [],
          excludedInvestmentAccounts: [],
          activeStrategyId: null,
        })
      );
    } else {
      dispatch(
        portfolioActions.setFilters({
          externalReporting: strategy.externalReporting
            .map((item) => {
              switch (item) {
                case 'Formue Depot':
                  return 'showInternalPortfolio';
                case 'Ekstern':
                  return 'showExternalPortfolio';
                default:
                  return null;
              }
            })
            .filter(Boolean),
          excludedSsids: accounts
            .map((item) => item.ssid)
            .filter((ssid) => !strategy.accounts.includes(ssid)),
          excludedTaxClasses: taxClasses?.filter(
            (taxClass) => !strategy.taxClasses.includes(taxClass)
          ),
          excludedInvestmentAccounts: portfolioAccounts.filter(
            (portfolioAccount) =>
              !strategy.portfolioAccounts.includes(portfolioAccount)
          ),
          activeStrategyId: strategy.id,
        })
      );
    }
  };

  return (
    <ContentWrapper className="content" position={position} {...rest}>
      {Object.keys(combinedStrategies).length ? (
        Object.keys(combinedStrategies).map((key) => {
          const strategies = combinedStrategies[key];
          return (
            <Item className="item" key={`strategy-${key}`}>
              <Header>
                <ProfileIcon
                  size={32}
                  color={color}
                  CustomIcon={accountTypeIcon(
                    strategies[0].accounts,
                    accounts,
                    iconStyles
                  )}
                />
                <H3>
                  {formatedName(
                    activeStrategyAccountsName(strategies[0].accounts, accounts)
                  )}
                </H3>
              </Header>
              <SubList>
                {strategies.sort(sortStrategyType).map((strategy) => (
                  <SubListItem
                    key={`sub-item-${strategy.id}`}
                    onClick={() => {
                      handleSelectStrategy(strategy);
                    }}
                    disabled={!isCurrentMonth}
                  >
                    <Paragraph style={{ marginRight: 'auto' }}>
                      {t(
                        `presentation:annualReview:portfolioStrategy:strategyTypes:${strategy.strategyType}`
                      )}
                    </Paragraph>
                    <Checkbox
                      id={`strategy-${strategy.id}-${id}`}
                      name={`strategy-${strategy.id}-${id}`}
                      checked={activeStrategy?.id === strategy.id}
                      disabled={!isCurrentMonth}
                      onChange={() => {}}
                    />
                  </SubListItem>
                ))}
              </SubList>
            </Item>
          );
        })
      ) : (
        <CenteredActivityIndicator />
      )}
    </ContentWrapper>
  );
};
