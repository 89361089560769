import React from 'react';
import styled from 'styled-components';

import { formatMoney, t } from '@formue-app/core';

import { H3, Paragraph, ParagraphXSmall } from '../texts';
import { BaseSection } from '../common/BaseSection';
import {
  SPACING_16,
  SPACING_20,
  SPACING_24,
  SPACING_8,
} from '../../constants/spacing';
import { StrategyProductsTable } from './StrategyProductsTable';
import { accent } from '../../constants/colors';
import { mobileCondition } from '../../constants/media';
import { H2SizeMobile } from '../../constants/text';

const Container = styled(BaseSection)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Wrapper = styled.div`
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: ${SPACING_24};
  border-bottom: 1px solid ${accent.sand150};
  margin-bottom: ${SPACING_8};
  align-items: flex-start;
`;

const Title = styled(H3)`
  padding-bottom: ${SPACING_16};
`;

const StyledAmount = styled(H3)`
  font-size: 26px;

  @media ${mobileCondition} {
    font-size: ${H2SizeMobile};
  }
`;

const Text = styled(Paragraph)`
  max-width: 530px;
  white-space: pre-line;
`;

const TotalPortfolioWrapper = styled.div`
  background-color: ${accent.sand150};
  padding: ${SPACING_16} ${SPACING_20};
  border-radius: 16px;
`;

const i18nPrefix = 'wealthPlan:investmentStrategy';

export const StrategyProducts = (props) => {
  const { totalPortfolio, formuePortfolio, ...rest } = props;
  return (
    <Container columns={12}>
      <Row>
        <Wrapper>
          <Title>{t(`${i18nPrefix}:portfolioManagedByFormue`)}</Title>
          <Text>{t(`${i18nPrefix}:portfolioManagedByFormueText`)}</Text>
        </Wrapper>
        <TotalPortfolioWrapper>
          <ParagraphXSmall style={{ fontWeight: 500 }}>
            {t('advisor:strategySimulator:assetAllocation:formuePortfolio')}
          </ParagraphXSmall>
          <StyledAmount style={{ paddingTop: 4 }}>
            {formatMoney(formuePortfolio)}
          </StyledAmount>
        </TotalPortfolioWrapper>
      </Row>

      <StrategyProductsTable {...rest} />
    </Container>
  );
};
