import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategyIndexChangesAllIds,
  investmentStrategyIndexChangesById,
  investmentStrategyIndexChangesSelector,
  investmentStrategyIndexChangesMetadataSelector,
] = resourceSelectors('investmentStrategyIndexChanges');

export const investmentStrategyIndexChangesByIdSelector = (id) =>
  createSelector(investmentStrategyIndexChangesSelector, (indexChanges) =>
    indexChanges.filter(
      (indexChange) => indexChange.investmentStrategy.id === id
    )
  );

export const investmentStrategyIndexChangesByIdsSelector = (ids = []) =>
  createSelector(investmentStrategyIndexChangesSelector, (indexChanges) =>
    indexChanges.filter((indexChange) =>
      ids.includes(indexChange.investmentStrategy.id)
    )
  );

export const investmentStrategyIndexChangesMetadataByIdSelector = (id) =>
  createSelector(
    investmentStrategyIndexChangesMetadataSelector,
    (meta) => meta[id]
  );
