import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { H6 } from '../../texts';
import { accent } from '../../../constants/colors';
import { SPACING_8 } from '../../../constants/spacing';

const StyledTitle = styled(H6)`
  color: ${accent.velvet2};
  margin-bottom: ${SPACING_8};
`;

export const GainTitle = ({
  selectedClasses,
  highlightColor,
  fromDate,
  toDate,
}) => {
  let key = 'portfolio:returnSinceDate';

  if (toDate) {
    key = 'portfolio:returnBetweenDate';
  }

  const renderTitleText = () => {
    if (!selectedClasses.length) {
      return `${t(key, {
        returnDate: fromDate,
        startDate: fromDate,
        endDate: toDate,
      }).toUpperCase()}`;
    }
    return `${selectedClasses.length} ${t(
      'portfolio:selectedClasses'
    ).toUpperCase()}`;
  };

  return (
    <StyledTitle style={{ color: highlightColor }}>
      {renderTitleText()}
    </StyledTitle>
  );
};
