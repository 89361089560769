import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { selectors, t } from '@formue-app/core';
import {
  backgroundWhite,
  accent,
  whiteTranslucent,
} from '../../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';

import { notDesktopCondition } from '../../../../constants/media';

const {
  entities: {
    securities: { securitiesByContinentSelector },
  },
} = selectors;

const StyledContainer = styled.div`
  grid-column: span 12;
  display: flex;
  background-color: ${accent.ocean4};
  border-top: 1px solid ${accent.ocean1};
  border-bottom-left-radius: ${BORDER_RADIUS_LARGE};
  border-bottom-right-radius: ${BORDER_RADIUS_LARGE};
  overflow: hidden;

  @media ${notDesktopCondition} {
    overflow-x: scroll;
    padding: ${SPACING_16} ${SPACING_8};
  }
`;

const StyledButton = styled.button`
  padding: ${SPACING_16};
  width: 100%;
  max-width: 100%;
  line-height: 1;
  outline: none;
  border: none;
  border-bottom: 4px solid transparent;
  color: ${backgroundWhite};
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.selected ? 'rgba(69, 162, 255, 0.3)' : 'rgba(69, 162, 255, 0.15)'};
  }

  font-weight: ${(props) => (props.selected ? 500 : 400)};
  background-color: ${(props) =>
    props.selected ? 'rgba(69, 162, 255, 0.3)' : 'transparent'};
  border-color: ${(props) => (props.selected ? accent.ocean3 : 'transparent')};

  @media ${notDesktopCondition} {
    padding: 12px 20px;
    margin: 0 ${SPACING_8};
    border-radius: 36px;
    width: auto;
    border: none;
    white-space: nowrap;
    background-color: ${(props) =>
      props.selected ? backgroundWhite : accent.ocean1};
    color: ${(props) =>
      props.selected ? accent.ocean4 : whiteTranslucent(0.7)};
    &:hover {
      background-color: ${(props) =>
        props.selected ? backgroundWhite : accent.ocean1};
    }
  }
`;

export const ContinentSwitcher = ({ selected = 'Global', onClick }) => {
  const byContinent = useSelector(securitiesByContinentSelector);

  const sortedContinents = Object.values(byContinent).sort(
    (a, b) => b.partOfPortfolio - a.partOfPortfolio
  );

  return (
    <StyledContainer>
      <StyledButton
        onClick={() => {
          onClick('Global');
        }}
        selected={selected === 'Global'}
      >
        {t('geography:continents:global')}
      </StyledButton>
      {sortedContinents.map(({ continent }, index) => {
        return (
          <StyledButton
            onClick={() => {
              onClick(continent);
            }}
            selected={selected === continent}
            key={index}
          >
            {t(`geography:continents:${camelcase(continent)}`)}
          </StyledButton>
        );
      })}
    </StyledContainer>
  );
};
