import { createSelector } from 'reselect';

export const resourceSelectors = (entity) => {
  const allIds = (state) => state.entities[entity].allIds;
  const byId = (state) => state.entities[entity].byId;
  const meta = (state) => state.entities[entity].meta;
  const allEntities = createSelector(
    allIds,
    byId,
    (allEntityIds, byEntityId) => {
      return allEntityIds.map((id) => {
        const entity = byEntityId[id];
        return entity;
      });
    }
  );

  return [allIds, byId, allEntities, meta];
};
