import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import { RouteWithSubRoutes } from '../../components/router';

export const AdvisorMenuPage = (props) => {
  const { routes } = props;

  const isEmployee = useSelector((state) => state.auth.isEmployee);

  // If user is not advisor we prevent user from accessing advisor area
  if (!isEmployee) {
    return Redirect('/');
  }

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
};
