import { createGlobalStyle } from 'styled-components';

import '@ibm/plex/css/ibm-plex.css';

import { textMain } from '../constants/colors';

import BerlingskeBoldWoff2 from './BerlingskeSerif-bold.woff2';
import BerlingskeBoldWoff from './BerlingskeSerif-bold.woff';
import BerlingskeBoldItalicWoff2 from './BerlingskeSerif-bold-italic.woff2';
import BerlingskeBoldItalicWoff from './BerlingskeSerif-bold-italic.woff';
import BerlingskeWoff2 from './BerlingskeSerif.woff2';
import BerlingskeWoff from './BerlingskeSerif.woff';
import BerlingskeItalicWoff2 from './BerlingskeSerif-italic.woff2';
import BerlingskeItalicWoff from './BerlingskeSerif-italic.woff';

export const titleFontStack = '"BerlingskeSerif", Times New Roman, serif';
export const numbersFontStack = '"BerlingskeSerif", Times New Roman, serif';
export const bodyFontStack = 'IBM Plex Sans, Helvetica, sans-serif';

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: "BerlingskeSerif";
    src: url(${BerlingskeWoff2}) format("woff2"),
         url(${BerlingskeWoff}) format("woff");
    font-weight: 300;
  }

  @font-face {
    font-family: "BerlingskeSerif";
    src: url(${BerlingskeItalicWoff2}) format("woff2"),
         url(${BerlingskeItalicWoff}) format("woff");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "BerlingskeSerif";
    src: url(${BerlingskeBoldWoff2}) format("woff2"),
         url(${BerlingskeBoldWoff}) format("woff");
    font-weight: 700;
  }

  @font-face {
    font-family: "BerlingskeSerif";
    src: url(${BerlingskeBoldItalicWoff2}) format("woff2"),
         url(${BerlingskeBoldItalicWoff}) format("woff");
    font-weight: 700;
    font-style: italic;
  }

  h1,
  h2,
  h1 span,
  h2 span {
    color: ${textMain};
    font-family: ${titleFontStack};
    font-weight: 700;
  }

  h3,
  h4,
  h5,
  h3 span,
  h4 span,
  h5 span {
    color: ${textMain};
    font-family: ${bodyFontStack};
    font-weight: 500;
  }

  span,
  p,
  a,
  li,
  h4,
  h5,
  h6,
  label,
  input,
  button,
  textarea,
  select,
  li > h1 {
    color: ${textMain};
    font-family: ${bodyFontStack};
    font-weight: 400;
  }

  h1,
  h2,
  h1 span,
  h2 span,
  h3,
  h4,
  h5,
  h3 span,
  h4 span,
  h5 span,
  h6,
  span,
  p,
  a,
  li,
  label,
  input,
  button,
  textarea,
  select {
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
