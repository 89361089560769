import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  BORDER_RADIUS_LARGE,
  BOX_PADDING_HORIZONTAL,
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_88,
} from '../../../constants/spacing';

import { H2, Paragraph } from '../../texts';

import { Logo } from '../../common/Logo';

import { mobileCondition, NotMobileWrapper } from '../../../constants/media';

import welcomeImageUrl from '../../../assets/images/annual-review/report/welcome-background.jpg';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { whiteTranslucent } from '../../../constants/colors';
import { ButtonSmall } from '../../buttons';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  padding: ${SPACING_48} ${SPACING_88};

  @media ${mobileCondition} {
    padding: ${SPACING_48} ${BOX_PADDING_HORIZONTAL};
    padding-top: 91px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 0.39) 100%
      ),
      url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center center;
  }
`;

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${SPACING_24};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ErrorInner = styled.div`
  background: ${whiteTranslucent(0.8)};
  padding: ${SPACING_48};
  border-radius: ${BORDER_RADIUS_LARGE};
  max-width: 400px;
  text-align: center;
`;

export const ReportLoading = (props) => {
  const { loading, error } = props;

  return (
    <Wrapper backgroundImage={welcomeImageUrl}>
      <NotMobileWrapper>
        <Logo style={{ marginBottom: 'auto' }} height="24" />
      </NotMobileWrapper>
      {loading ? (
        <CenteredActivityIndicator />
      ) : error ? (
        <ErrorWrapper>
          <ErrorInner>
            <H2 style={{ marginBottom: SPACING_16 }}>
              {t('report:error:title')}
            </H2>
            <Paragraph style={{ marginBottom: SPACING_24 }}>
              {t('report:error:body')}
            </Paragraph>
            <ButtonSmall
              onClick={() => {
                window.location.reload(false);
              }}
            >
              {t('report:error:button')}
            </ButtonSmall>
          </ErrorInner>
        </ErrorWrapper>
      ) : null}
    </Wrapper>
  );
};
