import { useSelector } from 'react-redux';
import React from 'react';
import styled from 'styled-components';

import {
  t,
  selectors,
  toLocaleDateString,
  getPredefinedRanges,
} from '@formue-app/core';

import { ParagraphSmall } from '../../texts';
import { BaseSection } from '../../common/BaseSection';
import { printCondition } from '../../../constants/media';

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    display: none;
  }
`;

const {
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

export const DataVerificationNotice = (props) => {
  const { useBaseSection = false } = props;
  const activeFilters = useSelector(activeFiltersSelector);

  let endDate = new Date(activeFilters.endDate);

  if (!activeFilters.endDate) {
    const { newEndDate } = getPredefinedRanges(activeFilters.period);

    endDate = new Date(newEndDate);
  }

  const now = new Date();
  const cutoffData = new Date(now.getFullYear(), now.getMonth(), 10);

  // If we filter on previous month and it has not been T+08 businessdays we should show the notification
  const conditionPrevMonth =
    endDate.getMonth() === now.getMonth() - 1 && now < cutoffData;
  // Or if we filter on the current month we should always show the notification
  const conditionCurrentMonth = endDate.getMonth() === now.getMonth();

  const condition = conditionPrevMonth || conditionCurrentMonth;

  if (!condition) {
    return null;
  }

  // For display purpouses we should show in notification
  // This this is the last verified data and is always the last day of previous
  //  month if the above condition is true
  let notificationDate = '';
  if (conditionCurrentMonth) {
    notificationDate = toLocaleDateString(
      new Date(endDate.getFullYear(), endDate.getMonth() - 1, 0)
    );
  } else if (conditionPrevMonth) {
    notificationDate = toLocaleDateString(
      new Date(endDate.getFullYear(), endDate.getMonth(), 0)
    );
  }

  if (useBaseSection) {
    return (
      <StyledBaseSection columns={12}>
        <ParagraphSmall>
          {t('portfolio:periodicReporting:dataVerificationNotice', {
            endDate: notificationDate,
          })}
        </ParagraphSmall>
      </StyledBaseSection>
    );
  } else {
    return (
      <ParagraphSmall>
        {t('portfolio:periodicReporting:dataVerificationNotice', {
          endDate: notificationDate,
        })}
      </ParagraphSmall>
    );
  }
};
