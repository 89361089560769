import { takeLatest, take, put } from 'redux-saga/effects';
import * as lunr from 'lunr';

import { actions as coreActions, selectors } from '@formue-app/core';
import { actions } from './actions';
import { select } from 'redux-saga/effects';

require('lunr-languages/lunr.stemmer.support.js')(lunr);
require('lunr-languages/lunr.multi.js')(lunr);
require('lunr-languages/lunr.no.js')(lunr);

const {
  ui: {
    user: { accessibleAccountsSelector, currentUserSelector },
  },
} = selectors;

const { users: usersActions, documents: documentsActions } =
  coreActions.entities;

// Documents don't currently return which account they belong to, but
// the ID is generated on the server by composing the account id and
// document id into one ID, so until the server starts returning
// account id as well in the payload we extract it from there
const getAccountFromDocument = (id, accessibleAccounts, currentUser) => {
  const accountId = id.split('-')[0];
  // In case we don't find the ssid for this document in accessibleAccounts just return an empty
  // string. This can happen if the clientNetRead permission in SF is configured wrongly.
  try {
    const { name } = accessibleAccounts.find(({ ssid }) => ssid === accountId);
    return name;
  } catch (error) {
    // If we couldn't find the account in accessible accounts we check if the accountId
    // matches the currently logged in users id. If so, we return the name of this user.
    // This shouldn't really happen, but can happen if clientNetRoles is not setup correctly
    // for this user.
    if (accountId === currentUser?.ssid) {
      const { firstName, middleName, lastName } = currentUser;
      const name = [firstName, middleName, lastName].filter(Boolean).join(' ');
      return name;
    }

    return '';
  }
};

function* extendDocuments() {
  while (true) {
    // Make sure we have loaded the user before continuing
    yield take(usersActions.showRequestSuccess);

    yield takeLatest(
      documentsActions.indexRequestSuccess,
      function* ({ payload }) {
        const accessibleAccounts = yield select(accessibleAccountsSelector);
        const currentUser = yield select(currentUserSelector);

        const extendedDocuments = payload.result.documents?.reduce(
          (map, id) => {
            const document = payload.entities.documents[id];
            const relationship = getAccountFromDocument(
              id,
              accessibleAccounts ?? [],
              currentUser
            );
            const extendedDocument = { ...document, relationship };
            map[id] = extendedDocument;

            return map;
          },
          {}
        );

        // Store a copy of the ids as well, strictly not needed since
        // we could use the original values from the resource store
        // but since this action is completed at a different time
        // than the resource action handler they might not be in sync
        const extendedDocumentIds = payload.result.documents;
        yield put(
          actions.setDocuments({
            byId: extendedDocuments,
            allIds: extendedDocumentIds,
          })
        );
      }
    );

    // This is needed since the documents we list in the UI is not the documents in the entity
    // store, but rather this list of "extended documents". This list is not automatically
    // updated when a update request was successfull so we need to manually manipulate this
    // store as well.
    yield takeLatest(
      documentsActions.updateRequestSuccess,
      function* ({ payload }) {
        // Updated document data
        const id = payload.result.documents[0];
        const document = payload.entities.documents[id];

        // Update the extended document
        const extendedDocuments = yield select(
          (state) => state.ui.document.documentsById
        );

        const extendedDocumentIds = yield select(
          (state) => state.ui.document.documentsAllIds
        );

        const extendedDocument = extendedDocuments[id];
        extendedDocument.read = document.read;

        // Replace store to have UI reflect the changes
        yield put(
          actions.setDocuments({
            byId: extendedDocuments,
            allIds: extendedDocumentIds,
          })
        );
      }
    );
  }
}

function* createSearchIndex() {
  yield takeLatest(
    documentsActions.indexRequestSuccess,
    function* ({ payload }) {
      const numberOfDocuments = payload?.result?.documents?.length;
      // Skip unless we actually have data
      if (!numberOfDocuments > 0) {
        return null;
      }

      /**
       * Generating the search index is quite resource intensive, so doing it with
       * a huge amount of documents causes performance issues. So we only provide
       * a search index/search functionality if your filters return less than n
       * number of documents
       *
       * The lunr search library does support creating this index serverside and
       * so we _could_ precompile it serverside for customers and deliver it together
       * with the documents if we need to.
       *
       * 30000 is way more than we can expect a user to have, so it's mostly to be
       * "safe" in very special cases
       */
      const MAX_NUMBER_OF_DOCUMENTS_FOR_SEARCH = 30000;
      if (numberOfDocuments > MAX_NUMBER_OF_DOCUMENTS_FOR_SEARCH) {
        return null;
      }

      const {
        result: { documents: documentIds },
        entities: { documents: documentsById },
      } = payload;

      const index = lunr(function () {
        this.use(lunr.multiLanguage('en', 'no'));
        this.ref('id');
        this.field('name');
        this.field('extension');
        this.field('contentType');
        this.field('createdAt');

        documentIds.forEach((id) => {
          const { name, contentType, createdAt } = documentsById[id];
          // Index name and extension as different parts so that "SJG.pdf", can
          // be searched for by searching for SJG and not SJG.pdf
          const extension = name.split('.').reverse()[0];
          const filename = name.split(`.${extension}`)[0];
          this.add({
            id,
            name: filename,
            extension,
            contentType: contentType.toLowerCase(),
            createdAt,
          });
        });
      });

      // Store the serialized index in redux store for use later
      yield put(actions.setSearchIndex(JSON.stringify(index)));
    }
  );
}

export const sagas = [extendDocuments, createSearchIndex];
