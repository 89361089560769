import i18next from 'i18next';

export const localizeFloat = (
  value,
  numberOfDecimals = 1,
  fixedNumberOfDecimals
) => {
  // fixedNumberOfDecimals
  // localizeFloat(0,12030, 3, true) => 0,120
  // otherwise the function returns => 0,12
  const number = fixedNumberOfDecimals
    ? Number.parseFloat(value).toFixed(numberOfDecimals)
    : Number(parseFloat(value)).toFixed(numberOfDecimals);

  return `${number.replace(
    '.',
    i18next.language === 'sv' || i18next.language === 'nb' ? ',' : '.'
  )}`;
};
export const percentage = (
  value,
  numberOfDecimals = 1,
  fixedNumberOfDecimals,
  includeSpace = true,
  includePrefix
) => {
  let suffix = '%';
  if (includeSpace) {
    suffix = ' %'; // Yes, that is a non-breaking space
  }

  // We don't want to show 0% if we don't have data, so instead
  // return a string indicating that we are missing data.
  if (!value && value !== 0) {
    return '-';
  }

  let prefix = '';
  if (includePrefix && value > 0) {
    prefix = '+';
  }

  return (
    prefix +
    localizeFloat(value, numberOfDecimals, fixedNumberOfDecimals) +
    suffix
  );
};

export const numberWithSpaces = (number) => {
  var parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};
