import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { SPACING_24, SPACING_8 } from '../../constants/spacing';
import {
  backgroundWhite,
  textLightest,
  accent,
  uiActive,
} from '../../constants/colors';
import { notMobileCondition, mobileCondition } from '../../constants/media';
import { menuItemFontSize } from '../../constants/text';

import { bodyFontStack } from '../../fonts';
import { SpriteIcon } from '../common/SpriteIcon';

const StyledNavLink = styled(NavLink)`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: ${SPACING_8} 0;
  text-decoration: none;
  position: relative;
  background-color: ${(props) => props.backgroundcolor};

  &:hover > span {
    background-color: ${(props) => props.hovercolor};
  }

  &.active {
    > span {
      background-color: ${(props) => props.activecolor};
    }

    &:before {
      content: '';
      background-color: ${(props) => props.highlightcolor};
      height: 40px;
      left: 0;
      position: absolute;
      width: 4px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      z-index: 1;
    }
  }

  svg path {
    fill: ${textLightest};
    opacity: 0.5;
  }

  &.active svg path {
    fill: ${backgroundWhite};
    opacity: 1;
  }
`;

const HoverWrapper = styled.span`
  padding: ${SPACING_8} ${SPACING_24};
  min-height: 40px;
  width: 100%;
  position: relative;
`;

const SubNavLink = styled(NavLink)`
  text-decoration: none;
  padding: ${SPACING_8} ${SPACING_24};
  padding-left: 65px;
`;

const SubRoutesWrapper = styled.div`
  display: none;
  flex-direction: column;
  padding-bottom: 26px;
`;

const StyledSpriteIcon = styled(SpriteIcon)`
  margin-right: 20px;
  vertical-align: sub;
  opacity: 0.5;

  ${StyledNavLink}.active & {
    opacity: 1;
  }

  ${SubNavLink}.active & {
    opacity: 1;
  }
`;

const StyledText = styled.span`
  font-family: ${bodyFontStack};
  font-size: ${menuItemFontSize};
  line-height: ${menuItemFontSize};
  font-weight: 500;
  color: ${textLightest};
  opacity: 0.5;

  ${StyledNavLink}.active & {
    color: ${textLightest};
    opacity: 1;
  }

  ${SubNavLink}.active & {
    color: ${textLightest};
    opacity: 1;
  }

  @media ${notMobileCondition} {
    ${(props) =>
      !props.open &&
      `
      display: none;
    `}
  }
`;

const StyledTextSubItem = styled(StyledText)`
  font-size: 15px;
`;

const Badge = styled.span`
  background-color: ${uiActive};
  border-radius: 10px;
  color: ${textLightest};
  display: inline-block;
  font-family: ${bodyFontStack};
  font-size: 11px;
  font-weight: 600;
  height: 19px;
  min-width: 19px;
  text-align: center;
  line-height: 19px;
  margin-left: 6px;
  padding: 0 5px;
  top: -2px;
  position: relative;

  @media ${notMobileCondition} {
    ${(props) =>
      !props.open &&
      `
      position: absolute;
      top: 12px;
      left: 43px;
    `}
  }
`;

const MenuItem = styled.li`
  ${StyledNavLink}.active + ${SubRoutesWrapper} {
    display: flex;
  }
`;

export const MainMenuItem = (props) => {
  const {
    title,
    icon,
    showinmenu = true,
    open,
    routes,
    to,
    exact,
    selector = () => {},
    backgroundColor = 'transparent',
    hoverColor = '#223371',
    activeColor = '#223371',
    highlightColor = accent.ocean3,
  } = props;

  const isMobile = useMediaQuery({ query: mobileCondition });

  const badgeCount = useSelector(selector);

  if (!showinmenu) return null;

  return (
    <MenuItem>
      <StyledNavLink
        to={to}
        exact={exact}
        activeClassName="active"
        backgroundcolor={backgroundColor}
        hovercolor={hoverColor}
        activecolor={activeColor}
        highlightcolor={highlightColor}
      >
        <HoverWrapper>
          <StyledSpriteIcon
            id={icon}
            inverted={true}
            navigational={true}
            size={24}
          />
          <StyledText open={open}>{title}</StyledText>
          {!!badgeCount && <Badge open={open}>{badgeCount}</Badge>}
        </HoverWrapper>
      </StyledNavLink>
      {routes && isMobile ? (
        <SubRoutesWrapper>
          {routes.map((route, index) => (
            <SubNavLink
              to={route.path}
              exact={route.exact}
              activeClassName="active"
              key={`sub-menu-item-${index}`}
            >
              <StyledTextSubItem open={open}>{route.title}</StyledTextSubItem>
            </SubNavLink>
          ))}
        </SubRoutesWrapper>
      ) : null}
    </MenuItem>
  );
};
