import { createSelector } from 'reselect';
import { makeFilterSelector } from '../../../services/helpers';

const belongsToExpertInput = task =>
  task.parent && task.parent.type === 'expert-inputs';

const tasksById = state => state.entities.tasks.byId;

// This holds a list of ids for tasks that we wait for feedback from Salesforce
// on. These should be added to the ongoing section of the list until the background poller
// mechanism remove is it from the inProgress list and we should use the state of the entity
// again to put in the correct section.
const taskIdsInProgressSelector = state => {
  if (state.ui && state.ui.tasks) {
    return state.ui.tasks.inProgress;
  }
  return [];
};

/**
 * Create a selector that manipulates the status based on the in progress UI selector
 * It will change the status on the entity if the ID is in the in progress selector
 */
export const manipulatedTasks = createSelector(
  [tasksById, taskIdsInProgressSelector],
  (tasks, idsInProgress) => {
    return Object.keys(tasks).map(id => {
      const task = tasks[id];
      if (idsInProgress.includes(task.id)) {
        task.status = 'ongoing';
      }
      return task;
    });
  }
);

/**
 * We don't want to show tasks that belong to an expert input in the open and ongoing
 * lists so we filter those out here.
 */
const openWithExpertInputSelector = makeFilterSelector(
  manipulatedTasks,
  'open'
);
const openWithoutExpertInputSelector = createSelector(
  openWithExpertInputSelector,
  items => [
    // Make sure only one of each item is sent
    ...new Set([
      // Get all tasks not in a ExpertInput
      ...Object.keys(items)
        .filter(id => !belongsToExpertInput(items[id]))
        .map(id => items[id]),
      // Get all items that are in Expert input and high priority
      ...Object.keys(items)
        .filter(
          id => belongsToExpertInput(items[id]) && items[id].priority === 'high'
        )
        .map(id => items[id]),
    ]),
  ]
);

export const openSelector = openWithoutExpertInputSelector;
export const ongoingSelector = makeFilterSelector(manipulatedTasks, 'ongoing');
export const completedSelector = makeFilterSelector(
  manipulatedTasks,
  'completed'
);
