import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';

import { selectors, t, useErrorState, useResource } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_24,
  SPACING_16,
} from '../../../constants/spacing';

import { GridElement } from '../../layout/GridElement';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { ListFilterHeader } from '../../lists';
import { HoldingsList } from '../../portfolio/holdings/HoldingsList';
import { Total } from '../../portfolio/costAndFees/Total';
import { MainGrid } from '../../layout';
import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';

const {
  entities: {
    portfolios: { portfolioAssetClassesWithSubitemsSelector },
  },
} = selectors;

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;
const ActivityIndicatorWrapper = styled(GridElement)`
  padding-top: 51%;
  position: relative;
`;

const ActivityIndicatorInner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const StyledListFilterHeader = styled(ListFilterHeader)`
  margin-top: ${SPACING_24};

  label {
    margin-right: ${SPACING_16};
  }
`;

export const PortfolioConstructionBreakdownDetails = () => {
  const loading = useResource(['PORTFOLIOS/INDEX']);
  const error = useErrorState(['PORTFOLIOS/INDEX']);
  const assetClasses = useSelector(portfolioAssetClassesWithSubitemsSelector);
  const [showAll, setShowAll] = useQueryState('showAll', false);
  const [openItems, setOpenItems] = useState([]);
  const [showSold, setShowSold] = useQueryState('showSold', true);

  const normalizeItems = () =>
    assetClasses.map((item) => ({
      ...item,
      isOpen: openItems.includes(item.id),
    }));

  // Filter all the subitems of items
  const filterSoldItems = (items) => {
    if (showSold) {
      return items;
    }

    return items.map((item) => {
      item.subItems = item.subItems.filter((subItem) => {
        return !!subItem.mv || !!subItem.quantity;
      });
      return item;
    });
  };

  const getItems = () => {
    const items = normalizeItems();
    const filteredItems = filterSoldItems(items);
    return filteredItems;
  };

  const [items, setItems] = useState(getItems());

  useEffect(() => {
    // Don't do anything until we have any asset classes
    if (!assetClasses.length) {
      return;
    }

    // Check if openItems is 0 but showAll is true, if so make sure we update
    // open items. This happens most commenly if we are loading the page with
    // ?showAll query param.
    if (showAll && openItems.length === 0) {
      setOpenItems(assetClasses.map((item) => item.id));
    } else {
      setShowAll(openItems.length === assetClasses.length);
    }

    setItems(getItems());
  }, [showAll, openItems, assetClasses, showSold]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handler to call if you want toggle the open state of an item.
  const onSelectedAssetClass = ({ id }) => {
    const index = openItems.indexOf(id);

    if (index > -1) {
      openItems.splice(index, 1);
      setOpenItems([...openItems]);
    } else {
      setOpenItems([...new Set([...openItems, id])]);
    }
  };

  const toggleShowAll = () => {
    if (!showAll) {
      // If show all is false when we start, copy all ids into openItems to open all
      setOpenItems(assetClasses.map((item) => item.id));
    } else {
      setOpenItems([]);
    }
    setShowAll(!showAll);
  };

  const filters = {
    showAll: {
      id: 'show-all-toggle',
      type: 'toggle',
      value: showAll,
      onChange: toggleShowAll,
      label: t('portfolio:seeAllSecurities'),
    },
    showSold: {
      id: 'show-sold',
      type: 'toggle',
      value: showSold,
      onChange: () => {
        setShowSold(!showSold);
      },
      label: t('portfolio:showSoldSecurities'),
    },
  };

  if (loading) {
    return (
      <ActivityIndicatorWrapper columns={12}>
        <ActivityIndicatorInner>
          <CenteredActivityIndicator
            columns={12}
            size={30}
            loadingText={t('loading:holdings')}
          />
        </ActivityIndicatorInner>
      </ActivityIndicatorWrapper>
    );
  }

  if (error) {
    return <SlideErrorMessage />;
  }

  return (
    <Wrapper>
      <StyledListFilterHeader filters={filters} data={[]} enablePrint={false} />
      <HoldingsList
        items={items}
        openItems={openItems}
        clickCallback={onSelectedAssetClass}
      />

      <Total />
    </Wrapper>
  );
};
