import React from 'react';
import styled from 'styled-components';

import { Paragraph, ParagraphLarge } from '../../texts';
import { BaseSection } from '../../common/BaseSection';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_32,
  SPACING_4,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { Breadcrumbs } from '../../common/Breadcrumbs';

const Container = styled(BaseSection)`
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${accent.sand150};
  padding: ${SPACING_32};
`;

const Content = styled.div`
  width: 70%;
`;

const Heading = styled(ParagraphLarge)`
  padding-top: ${SPACING_4};
  padding-bottom: ${SPACING_16};
  font-weight: 600;
`;

const StyledParagraph = styled(Paragraph)`
  padding-bottom: ${SPACING_12};
`;

export const TermsAndConditionsDK = () => (
  <>
    <Breadcrumbs
      items={[
        { title: '1. Baggrund og kundeforhold' },
        { title: '2. Investeringsmandat' },
        { title: '3. Forvaltningsporteføljen' },
        { title: '4. Rammer for porteføljeplejen' },
        { title: '5. Afkast' },
        { title: '6. Honorar' },
      ]}
      style={{ margin: `${SPACING_16} 0` }}
      itemsSpacing={8}
    />
    <Container>
      <Content>
        <Heading>1. Baggrund og kundeforhold</Heading>
        <StyledParagraph>
          Formålet med denne aftale (herefter benævnt "Porteføljeplejeaftalen")
          er at regulere vilkårene for ydelsen skønsmæssig porteføljepleje, som
          Formue Danmark, filial af Formue Norge AS, Norge – CVR-nr. 43742981
          ("Formue") tilbyder Kunden med hensyn til Kundens portefølje af
          finansielle instrumenter. Kunde og Formue omtales under ét som
          "Parterne".
        </StyledParagraph>
        <StyledParagraph>
          Porteføljeforvaltningsaftalen er udarbejdet som et tillæg til
          Kundeaftalen og skal læses i sammenhæng med almindelige
          forretningsvilkår.
        </StyledParagraph>

        <Heading>2. Investeringsmandat</Heading>
        <StyledParagraph>
          Formues porteføljeplejeydelse består i pleje af Kundens portefølje af
          finansielle instrumenter i overensstemmelse med det
          investeringsmandat, som fremgår af denne Porteføljeplejeaftale. Når
          Formue yder porteføljepleje, handler Formue i henhold til skønsmæssige
          beføjelser. Det betyder, at Formue træffer beslutning om, hvorvidt
          Formue skal købe, sælge eller på anden måde disponere over finansielle
          instrumenter på Kundens vegne.
        </StyledParagraph>
        <StyledParagraph>
          Kunden giver Formue eneret til at udøve skønsmæssig porteføljepleje på
          en bestemt del af Kundens portefølje ("Forvaltningsporteføljen"), som
          beskrevet i pkt. 3 nedenfor. Gennem skønsmæssig porteføljepleje ønsker
          Parterne at sikre, at der regelmæssigt foretages justeringer af
          Forvaltningsporteføljen uden at Kunden skal inddrages i sådanne
          tilpasninger. Formålet med den skønsmæssige porteføljepleje er at
          sikre, at Kundens Forvaltningsportefølje så vidt muligt er i
          overensstemmelse med Kundens valgte strategi for
          Forvaltningsporteføljen. Udsving i markedet og inden for de
          forskellige aktivklasser kan medføre, at Forvaltningsporteføljen
          midlertidigt afviger fra strategien inden for de fastsatte rammer.
        </StyledParagraph>
        <StyledParagraph>
          Ved denne Porteføljeplejeaftale bemyndiger Kunden Formue til at udøve
          ejerrettigheder knyttet til Forvaltningsporteføljen på vegne af
          Kunden. Dette indebærer bl.a. retten til at købe, tegne, sælge eller
          på anden måde disponere over investeringsforeninger og -fonde og andre
          finansielle instrumenter, der til enhver tid er i
          Forvaltningsporteføljen. Formue kan endvidere på vegne af Kunden indgå
          alle aftaler, som Formue finder nødvendige eller hensigtsmæssige i
          forbindelse med pleje af Forvaltningsporteføljen. På opfordring skal
          Formue give Kunden oplysninger om alle sådanne aftaler.
        </StyledParagraph>
        <StyledParagraph>
          Formue skal holde sig inden for de rammer, der er fastsat i pkt. 4 i
          denne Porteføljeplejeaftale. Kunden forpligter sig til at informere
          Formue om ændringer i sin egen finansielle situation, mål eller behov
          der kan påvirke Formueplanen og disse rammer.
        </StyledParagraph>
        <StyledParagraph>
          Formue skal ved udførelsen af ordrer for Kunden træffe alle
          tilstrækkelige foranstaltninger for at opnå det efter omstændighederne
          bedst mulige resultat for Kunden med hensyn til pris, omkostninger,
          hurtighed, gennemførelses- og afregningssandsynlighed, omfang, art og
          andre forhold, der er relevante for udførelsen af ordren, medmindre
          Kunden giver Formue en specifik ordre. Der henvises til Formues til en
          hver tid gældende Politik for ordreudførelse (Best Execution Policy),
          som findes på www.formue.dk, og som udgør en integreret del af denne
          Porteføljeplejeaftale.
        </StyledParagraph>
        <StyledParagraph>
          Ved underskrivelsen af denne Porteføljeplejeaftale samtykker Kunden
          til Formues til enhver tid gældende Politik for ordreudførelse,
          herunder generelt til udførelse af ordre uden om et reguleret marked,
          en multilateral handelsfacilitet eller en organiseret
          handelsfacilitet. Den gældende Politik for ordreudførelse vedlægges
          som Bilag 3.
        </StyledParagraph>

        <Heading>3. Forvaltningsporteføljen</Heading>
        <StyledParagraph>
          I Forvaltningsporteføljen kan Formue anvende følgende finansielle
          instrumenter i sin porteføljepleje: UCITS-fonde (Undertakings for the
          Collective Investment of Transferable Securities), alternative
          investeringsfonde (AIF) og Forvaltningsporteføljen vil også omfatte
          indskud på Kundekontoen samt andre værdipapirer i Formue Depot, som
          Kunden ikke ønsker solgt. Forvalter kan ikke sælge disse værdipapirer
          eller foretage yderligere køb.
        </StyledParagraph>
        <StyledParagraph>
          Alle investeringsforeninger og fonde, som anvendes, skal være
          hjemmehørende i EU/EØS-området og under tilsyn af en EU/EØS-
          tilsynsmyndighed. Forvaltningsporteføljen skal bestå af finansielle
          instrumenter indenfor hver aktivklasse som er anbefalt af Forvalter.
          Der vil kunne foretages ændringer i finansielle instrumenter i
          Forvaltningsporteføljen for at samstemme med Formues anbefalede
          investeringsunivers. Anbefalede produkter er fonde som vælges af
          Formue og som sikrer kvaliteten af investeringerne.
        </StyledParagraph>
        <StyledParagraph>
          Forvaltningsporteføljen vil bestå af produkter anbefalet af forvalter
          og vil dermed følge Forvalterens investeringsanbefaling knyttet til
          bæredygtighed. Porteføljeaftalen vil forvaltes sådan at den fremmer
          bæredygtige egenskaber i henhold til Offentlighedsforordningen (SFDR)
          artikel 8. Nærmere information om denne investeringsanbefaling følger
          i bilag: <strong>Artikkel 8 Opplysninger.</strong>
        </StyledParagraph>
        <StyledParagraph>
          Formues anbefalede investeringsunivers kan omfatte aktivklasserne
          hedgefond, private equity og fast ejendom. Disse investeringer vil som
          regel blive gennemført ved brug af AIF og kan oftest ikke indløses.
        </StyledParagraph>
        <StyledParagraph>
          Ved indgåelse af Porteføljeplejeaftalen kan Kunden have en
          eksisterende portefølje af bankindskud og værdipapirer
          ("Indgangsportefølje").: Likvide værdipapirer i Indgangsporteføljen
          som Kunden ønsker at Formue skal sælge, vil blive solgt gennem en
          separat fuldmagt. "Forvaltningsporteføljen" består af finansielle
          instrumenter i Formue Depot (inklusiv investeringer fra
          Indgangsporteføljen som ikke ønskes solgt) og omfatter også
          Kundekontoen. Andre værdipapirer eller bankbeholdninger (udenfor
          depotet hos Formue, i det følgende benævnt "Rapporteringsportefølje"),
          er ikke omfattet af denne Porteføljeplejeaftale.
          Forvaltningsporteføljen og Rapporteringsporteføljen udgør tilsammen
          Kundens samlede portefølje ("Totalportefølje").
        </StyledParagraph>

        <Heading>4. Rammer for porteføljeplejen</Heading>
        <StyledParagraph>
          Rammerne for pleje af Forvaltningsporteføljen fremgår af dokumentet
          Investeringsstrategi som følger med Formueplanen. Dette dokument
          sammenfatter blandt andet Kundens mål, behov og risikopræferencer og
          er tilgængeligt i Formue-appen og på Kundenettet.
        </StyledParagraph>
        <StyledParagraph>
          Kunden har en strategi, som er beskrevet i Formueplanen, der tager
          højde for alle investeringer i Kundens Totalportefølje. De midler, der
          indgår i den skønsmæssige porteføljepleje, har deres egen strategi og
          egne rammer, som er beskrevet i Investeringsstrategi som følger med
          Formueplanen. De to strategier kan være forskellige, afhængigt af
          indholdet af Rapporteringsporteføljen og Kundens ønsker.
        </StyledParagraph>
        <StyledParagraph>
          I forbindelse med handler kan allokeringen til aktivklassen
          Pengemarked midlertidigt være højere end den øvre ramme, og
          allokeringen til andre aktivklasser kan midlertidigt være lavere end
          den nedre ramme.
        </StyledParagraph>
        <StyledParagraph>
          Efter indgåelse af aftale vil Forvalter normalt bruge op til seks
          måneder for at implementere ændringer i den likvide del af porteføljen
          (obligationsfonde og aktiefonde). Kundens portefølje vil i denne
          periode kunne afvige fra strategien specificeret i
          Investeringsstrategien til Formueplanen mens midlerne placeres.
          Nærmere beskrivelse af implementeringen i Investeringsstrategien i
          afsnittet Implementering.
        </StyledParagraph>
        <StyledParagraph>
          Såfremt Investeringsstrategien til Formueplanen indeholder allokering
          til aktivklasserne ejendom og private equity, er disse investeringer
          langsigtede og allokeringen bygges op over flere år. I denne periode
          vil der kunne opstå brud på de fastsatte rammer. Forvalter må tilpasse
          Forvaltningsporteføljens strategi og rammer midlertidig, mens
          porteføljen gradvis styres mod den fastsatte strategi. Tilpasningen af
          kundens strategi gøres i de tilfælde, hvor det på kort sigt ikke er
          muligt at holde porteføljen indenfor ordinære rammer og skyldes
          likviditeten i ejendom og private equity. Tilpasningen gøres ved at
          over- eller underallokeringen i ejendom og private equity fordeles på
          øvrige likvide aktivklasser.
        </StyledParagraph>

        <Heading>5. Afkast – indskud – udbetaling</Heading>
        <StyledParagraph>
          Alle afkast føjes til Forvaltningsporteføljen. Formue vil tilstræbe,
          men er ikke forpligtet til, at kontakte Kunden, hvis
          Forvaltningsporteføljen er faldet med 10 procentpoint eller mere i
          løbet af den seneste kalendermåned eller siden starten for porteføljer
          med en kortere historik.
        </StyledParagraph>
        <StyledParagraph>
          Afkastet på Forvaltningsporteføljen vil blive målt og rapporteret i
          forhold til et referenceindeks bestående af aktie- og
          rentekomponenter, der afspejler andelen af aktier og renter i
          Investeringsstrategien som følger med Formueplanen.
        </StyledParagraph>
        <StyledParagraph>
          Hvis Kunden ønsker at tilføre likvide midler til
          Forvaltningsporteføljen, skal der sendes meddelelse til Kundens
          rådgiver eller via andre etablerede kommunikationskanaler, der er
          nævnt i Formues almindelige forretningsbetingelser, med det ønskede
          beløb.
        </StyledParagraph>
        <StyledParagraph>
          Hvis Kunden ønsker udtage midler fra Forvaltningsporteføljen, skal
          Kunden underrette Formue om det ønskede udbetalingsbeløb gennem sin
          personlige rådgiver eller via andre etablerede kommunikationskanaler
          omtalt i Formues almindelige forretningsbetingelser. Udbetalinger vil
          ske, når Formue har modtaget opgørelser for de(n) indløste
          værdipapir(er). Formue er ikke ansvarlig, hvis det ønskede
          udbetalingsbeløb overstiger værdien af midlerne i likvide fonde, der
          kan handles dagligt eller ugentligt. Udbetaling vil ske til Kundens
          registrerede udbetalingskonto. Varslingsfristen og meddelelsesmetoden
          kan ændres under information på Kundenettet.
        </StyledParagraph>

        <Heading>6. Honorar</Heading>
        <StyledParagraph>
          Honorarer i Porteføljeplejeaftalen følger honorarer i Kundeaftalen og
          tillægget hertil i Honoraraftalen.
        </StyledParagraph>
        <StyledParagraph>
          Fonde som indgår i Forvaltningsporteføljen vil have tilknyttede
          produktomkostninger som debiteres af produktudbyderne. Disse
          omkostninger vil kunne variere over tid, enten som følge af ændringer
          i omkostningerne i fondene eller ved omlægning af fonde i
          Forvaltningsporteføljen.
        </StyledParagraph>

        <Heading>7. Varighed og opsigelse – ændringer</Heading>
        <StyledParagraph>
          Porteføljeplejeaftalen træder i kraft ved underskrift og løber, indtil
          den opsiges skriftligt af en af Parterne. Opsigelse af Kundeaftalen
          betragtes også som opsigelse af Porteføljeplejeaftalen.
        </StyledParagraph>
        <StyledParagraph>
          Hvis Kunden foretager hævninger eller ændringer i
          Forvaltningsporteføljen, der forhindrer Formue i at overholde de
          aftalte rammer med normal porteføljepleje (f.eks. en for høj andel af
          værdipapirer, som Formue ikke kan sælge og/eller illikvide
          værdipapirer), skal Formue underrette Kunden med 30 dagers frist til
          at rette op på dette, og hvis dette ikke sker anses denne
          Porteføljeplejeaftale for opsagt af Kunden uden yderligere varsel.
        </StyledParagraph>
        <StyledParagraph>
          Hvis Porteføljeplejeaftalen opsiges, ophører den skønsmæssige
          porteføljepleje øjeblikkeligt, og kundeforholdet reguleres udelukkende
          af Kundeaftalen. Dette gælder også, hvis Formue opsiger
          Porteføljeplejeaftalen.
        </StyledParagraph>
        <StyledParagraph>
          Købs- eller salgsaftaler indgået af Formue på vegne af Kunden, inden
          en skriftlig opsigelse er kommet frem til Formue, vil være bindende
          for Kunden.
        </StyledParagraph>
        <StyledParagraph>
          Når porteføljeplejeaftalen indeholder en allokering til aktivklasserne
          private equity og/eller ejendom, vil porteføljen være eksponeret for
          en højere likviditetsrisiko. Investeringer i disse aktivklasser består
          af et indskud og en investeringsforpligtelse, som hentes ind, når der
          foretages underliggende investeringer. Eventuelle betydelige
          indløsninger initieret af kunden af den likvide del af porteføljen vil
          kunne medføre manglende evne til at håndtere den ikke indbetalte
          kapital. Kunden er ansvarlig for den samlede forpligtelse, som er
          resultatet af Formues håndtering af porteføljeplejeaftalen. Formue
          kontakter kunden, hvis det ikke er muligt at videreføre
          porteføljeplejeaftalen.
        </StyledParagraph>
        <StyledParagraph>
          <strong>
            Kunden bekræfter at have læst og forstået Porteføljeplejeaftalen og
            Formues almindelige forretningsbetingelser, som sammen med
            Kundeaftalen, inkl. tillæg og bilag, regulerer kundeforholdet mellem
            Kunden og Formue.
          </strong>
        </StyledParagraph>
        <StyledParagraph>
          Porteføljeplejeaftalen skal betragtes som et tillæg til den
          eksisterende Kundeaftale. Porteføljeplejeaftalen har prioritet foran
          både Kundeaftalen og de almindelige forretningsbetingelser i tilfælde
          af overlap og fortolkningstvivl.
        </StyledParagraph>
      </Content>
    </Container>
  </>
);
