import React from 'react';
import { useSelector } from 'react-redux';

import { t, selectors, useResource } from '@formue-app/core';

import { BaseSection } from '../../components/common/BaseSection';

import { Family } from '../../components/wealthPlan/familyAndOwnership/Family';
import { Milestone } from '../../components/wealthPlan/familyAndOwnership/Milestone';
import { BusinessAndOwnership } from '../../components/wealthPlan/familyAndOwnership/BusinessAndOwnership';
import { HeaderWithBackgroundImage } from '../../components/common/HeaderWithBackgroundImage';
import { accent } from '../../constants/colors';
import { NoDataMessage } from '../../components/wealthPlan/NoDataMessage';

import backgroundImage from '../../assets/wealth-plan/family-background.jpg';
import { GridElement } from '../../components/layout';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { SPACING_88 } from '../../constants/spacing';

const {
  entities: {
    familyAndOwnership: { familyAndOwnershipHasContentSelector },
  },
} = selectors;

export const FamilyAndOwnershipPage = () => {
  const loading = useResource([
    'FAMILY_MEMBERS/INDEX',
    'MILESTONES/INDEX',
    'ORGANIZATIONS/INDEX',
  ]);
  const hasContent = useSelector(familyAndOwnershipHasContentSelector);

  return (
    <>
      <BaseSection columns={12} style={{ padding: 0 }}>
        <HeaderWithBackgroundImage
          title={t('wealthPlan:familyAndOwnership:title')}
          subtitle={t('wealthPlan:familyAndOwnership:subtitle')}
          backgroundImage={backgroundImage}
          textColor={accent.raspberry250}
          fullSize={true}
        />
      </BaseSection>
      {hasContent ? (
        <>
          <Milestone />
          <Family />
          <BusinessAndOwnership />
        </>
      ) : loading ? (
        <GridElement columns={12}>
          <CenteredActivityIndicator
            size={30}
            style={{ marginTop: SPACING_88 }}
          />
        </GridElement>
      ) : (
        <NoDataMessage columns={12} />
      )}
    </>
  );
};
