import { getCodeList } from 'country-list';
import { localizeCountryName } from '../../services';

/**
 *
 * @param {*} list - dataset for all the forms
 * @param {*} data - data for this one current form
 * @returns A list of countries one can use in a <Select>
 */
export const useCountryOptions = (list, data) => {
  // Generate a list of countries. This list should include all countries
  // except the ones already chosen in the form so that you cannot create
  // tax records for the same country twice. This should only happen if
  // this form does not already have that country in it, e.g if we are editing
  // a country
  const alreadyPresentCountryCodes = list
    ? list.map((d) => d.country.toLowerCase())
    : [];
  const shouldShowCountry = (countryCode) => {
    const alreadyPresentInForm = alreadyPresentCountryCodes.includes(
      countryCode.toLowerCase()
    );
    let editingThisCountry = false;
    if (data) {
      editingThisCountry =
        countryCode.toLowerCase() === data.country?.toLowerCase();
    }
    // If country code is not already in the form, or this form is to edit this country we should
    // allow for it to be displayed.
    return !alreadyPresentInForm || editingThisCountry;
  };

  const countryCodeList = getCodeList();
  const countryOptions = Object.entries(countryCodeList)
    .map(([code, name]) => {
      let label;
      try {
        label = localizeCountryName(code);
      } catch (err) {
        label = name;
      }
      return {
        value: code,
        label,
      };
    })
    .filter(({ value }) => shouldShowCountry(value))
    .sort((a, b) => a.label.localeCompare(b.label));

  return countryOptions || [];
};
