import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';

const StyledContainer = styled(animated.div)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const StyledShape = styled.div`
  ${(props) =>
    props.inverted &&
    `
    border-top: ${props.height}px solid ${props.color};
    `}

  ${(props) =>
    !props.inverted &&
    `
      border-bottom: ${props.height}px solid ${props.color};
    `}
    height: 0;
  border-right: ${(props) => props.triangleWidth}px solid transparent;
`;

export const Trapezoid = ({
  width,
  style,
  color = '#333',
  height = 20,
  inverted = false,
  angle = 30,
}) => {
  const triangleWidth = angle;

  return (
    <StyledContainer style={style}>
      <div
        style={{
          height,
          backgroundColor: color,
          width: width - triangleWidth,
        }}
      />
      <StyledShape
        height={height}
        color={color}
        triangleWidth={triangleWidth}
        inverted={inverted}
      />
    </StyledContainer>
  );
};
