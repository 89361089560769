import React from 'react';
import styled from 'styled-components';

import { H3SizeMobile, H3LineHeightMobile } from '../../constants/text';

export const StyledH3Small = styled.h3`
  font-size: ${H3SizeMobile};
  line-height: ${H3LineHeightMobile};
  font-weight: 500;
`;

export const H3Small = (props) => {
  const { children } = props;

  return <StyledH3Small {...props}>{children}</StyledH3Small>;
};
