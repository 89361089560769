export const BORDER_RADIUS_MODAL = '24px';
export const BORDER_RADIUS_EXTRA_LARGE = '8px';
export const BORDER_RADIUS_LARGE = '4px';
export const BORDER_RADIUS_SMALL = '2px';

export const SPACING_2 = '2px';
export const SPACING_4 = '4px';
export const SPACING_8 = '8px';
export const SPACING_12 = '12px';
export const SPACING_16 = '16px';
export const SPACING_20 = '20px';
export const SPACING_24 = '24px';
export const SPACING_32 = '32px';
export const SPACING_40 = '40px';
export const SPACING_48 = '48px';
export const SPACING_64 = '64px';
export const SPACING_88 = '88px';
export const SPACING_120 = '120px';

export const SECTION_SPACING = SPACING_24;
export const SIDE_PADDING = SPACING_16;
export const BOX_PADDING_VERTICAL = SPACING_16;
export const BOX_PADDING_HORIZONTAL = SPACING_16;
export const TOP_OFFSET = SPACING_32 * -1;
export const CONTAINER_BOTTOM_SPACING = 100;
export const CONTAINER_BOTTOM_SPACING_NO_OFFSET = 130;
export const HEADER_MAX_HEIGHT = 325;
export const ANNUAL_REVIEW_CONTENT_SPACING = '95px';
export const ANNUAL_REVIEW_REPORT_CONTENT_SPACING = '140px';
export const ANNUAL_REVIEW_REPORT_CONTENT_SPACING_32OBILE = '90px';
