import { stringify } from 'query-string';
import { apiFetch } from './utils/api';

export const marketValues = {
  get: async (args) => {
    const options = {
      method: 'GET',
    };

    const query = stringify(args, { arrayFormat: 'comma' });
    const url = `/v2/qlik/marketvalues?${query}`;

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
