import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';

const defaultState = {
  trackIndex: null,
  isPlaying: false,
  episodeId: null,
  podcastId: null,
  nextEpisodeId: null,
  previousEpisodeId: null,
  trackProgress: 0,
  buffered: 0,
  duration: 0,
  isReady: false,
  audioRef: null,
  intervalRef: null,
};

export const reducer = handleActions(
  {
    [actions.setTrackIndex]: (state, { payload }) => {
      return {
        ...state,
        trackIndex: payload,
      };
    },
    [actions.setIsPlaying]: (state, { payload }) => {
      return {
        ...state,
        isPlaying: payload,
      };
    },
    [actions.setEpisodeId]: (state, { payload }) => {
      return {
        ...state,
        episodeId: payload,
      };
    },
    [actions.setPodcastId]: (state, { payload }) => {
      return {
        ...state,
        podcastId: payload,
      };
    },
    [actions.setNextAndPreviousEpisodeId]: (state, { payload }) => {
      const { nextId, previousId } = payload;
      return {
        ...state,
        nextEpisodeId: nextId,
        previousEpisodeId: previousId,
      };
    },
    [actions.setTrackProgress]: (state, { payload }) => {
      const { progress, buffered, duration } = payload;
      return {
        ...state,
        trackProgress: progress,
        buffered: buffered,
        duration: duration,
      };
    },
    [actions.setIsReady]: (state, { payload }) => {
      return {
        ...state,
        isReady: payload,
      };
    },
    [actions.setAudioRef]: (state, { payload }) => {
      return {
        ...state,
        audioRef: payload,
      };
    },
    [actions.setIntervalRef]: (state, { payload }) => {
      return {
        ...state,
        intervalRef: payload,
      };
    },
  },
  defaultState
);
