import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';

import {
  selectors,
  t,
  percentage,
  marketValues,
  transformActiveFilters,
  portfolios,
  formatBigMoney,
  periodToDate,
  toLocaleDateString,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { H2, H5, ParagraphSmall } from '../../texts';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { useSelector } from 'react-redux';
import { printCondition } from '../../../constants/media';
import { PortfolioReportingChart } from '../charts/PortfolioReportingChart';
import { accent } from '../../../constants/colors';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  @media ${printCondition} {
    gap: ${SPACING_8};
    margin-right: ${SPACING_24} !important;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const {
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

export const PeriodicGain = (props) => {
  const { chartHeight } = props;
  const activeFilters = useSelector(activeFiltersSelector);
  const prevActiveFilters = useRef(null);

  const [portfolioTotal, setPortfolioTotal] = useState(null);
  const [marketValueData, setMarketValueData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);

  const colorSet = [
    '#99BBDE',
    accent.ocean170,
    accent.ocean490,
    accent.sand1,
    accent.sand1,
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLocalLoading(true);
      let args = transformActiveFilters(activeFilters);
      args = {
        ...args,
        startDate: new Date('12.31.12').toISOString().split('T')[0],
        endDate:
          activeFilters.endDate || new Date().toISOString().split('T')[0],
      };
      const marketValuesData = await marketValues.get(args);
      const [portfoliosData] = await portfolios.get(args);

      const formatedData = marketValuesData.data.map((item) => {
        return {
          id: item.id,
          type: item.type,
          ...item.attributes,
        };
      });

      const total = portfoliosData.data?.find(
        (item) => item.attributes.dataType === 'total'
      )?.attributes;

      setStartDate(periodToDate(formatedData[0].p));
      setPortfolioTotal(total);
      setMarketValueData(formatedData);
      setLocalLoading(false);
    };

    if (!isEqual(activeFilters, prevActiveFilters.current)) {
      prevActiveFilters.current = activeFilters;
      fetchData().catch(console.error);
    }
  }, [activeFilters]);

  return (
    <StyledBaseSection columns={6}>
      <TextWrapper>
        <ParagraphSmall>
          {t('portfolio:periodicReporting:benchmark', {
            period: toLocaleDateString(startDate),
          })}
        </ParagraphSmall>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: SPACING_8,
          }}
        >
          <H2>{formatBigMoney(portfolioTotal?.gain, true)}</H2>
          <H5 style={{ paddingBottom: 6 }}>
            ({percentage(portfolioTotal?.twr * 100)})
          </H5>
        </div>
      </TextWrapper>

      {localLoading ? (
        <CenteredActivityIndicator />
      ) : (
        <PortfolioReportingChart
          data={marketValueData}
          colorSet={colorSet}
          height={chartHeight}
        />
      )}
    </StyledBaseSection>
  );
};
