import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import camelcase from 'camelcase';
import { useSelector } from 'react-redux';

import {
  percentage,
  t,
  selectors,
  localizeCountryName,
  formatMoney,
} from '@formue-app/core';

import { SPACING_16, SPACING_8 } from '../../../constants/spacing';
import { titleFontStack } from '../../../fonts';
import { PortfolioCharacteristicsItem } from './PortfolioCharacteristicsItem';
import { H1, H2, Paragraph, ParagraphXSmall } from '../../texts';
import { PortfolioCharacteristicsItemPieChart } from './PortfolioCharacteristicsItemPieChart';
import { StretchedHorizontalBarChart } from '../../graphs/StretchedHorizontalBarChart';
import { SpriteIcon } from '../../common/SpriteIcon';
import { MainGrid } from '../../layout';
import { accent } from '../../../constants/colors';

const Container = styled(MainGrid)`
  padding: 0;
  gap: ${(props) => props.gap}px;
  max-width: 100%;
`;

const TotalCarbonFootprint = styled.div`
  display: flex;
  gap: ${SPACING_8};
  align-items: baseline;
  padding-bottom: 20px;
`;

const {
  entities: {
    investmentStrategyPortfolioCharacteristics: {
      investmentStrategyPortfolioCharacteristicsByStrategyIdSelector,
    },
    investmentStrategies: { investmentStrategyByIdSelector },
  },
  auth: { operatingCountrySelector },
} = selectors;

export const PortfolioCharacteristics = (props) => {
  const { animate, strategyId, gap = 24, ...rest } = props;
  const operatingCountry = useSelector(operatingCountrySelector);

  const portfolioCharacteristics = useSelector(
    investmentStrategyPortfolioCharacteristicsByStrategyIdSelector(strategyId)
  );

  const strategy = useSelector(investmentStrategyByIdSelector(strategyId));

  if (!strategyId) return null;

  const formuePortfolio =
    strategy && strategy.externalPortfolio
      ? strategy.totalPortfolio - strategy.externalPortfolio
      : strategy?.totalPortfolio;

  return (
    <Container gap={gap} {...rest}>
      <PortfolioCharacteristicsItem
        title={t('advisor:strategySimulator:preferences:portfolioLiquidity')}
        animate={animate}
        icon={<SpriteIcon id="Investment Preferences" size={21} />}
        spacing={gap - 8}
      >
        <PortfolioCharacteristicsItemPieChart
          data={portfolioCharacteristics.liquidity.map((item) => ({
            ...item,
            group: t(
              `advisor:strategySimulator:preferences:${camelcase(item.group)}`
            ),
          }))}
        />
      </PortfolioCharacteristicsItem>
      <PortfolioCharacteristicsItem
        title={t('advisor:strategySimulator:preferences:indexExlution')}
        animate={animate}
        icon={<SpriteIcon id="Asset Management" size={21} />}
        spacing={gap - 8}
      >
        <PortfolioCharacteristicsItemPieChart
          data={portfolioCharacteristics.management.map((item) => ({
            ...item,
            group: t(
              `advisor:strategySimulator:preferences:${camelcase(item.group)}`
            ),
          }))}
        />
      </PortfolioCharacteristicsItem>
      <PortfolioCharacteristicsItem
        title={t('advisor:strategySimulator:preferences:totalExpenseRatio')}
        animate={animate}
        icon={<SpriteIcon id="Shares" size={21} />}
        spacing={gap - 8}
      >
        <H1>{percentage(portfolioCharacteristics.expenseRatio * 100, 2)}</H1>
        <Paragraph>
          {formatMoney(
            formuePortfolio * portfolioCharacteristics.expenseRatio,
            'KR'
          )}
        </Paragraph>
      </PortfolioCharacteristicsItem>
      <PortfolioCharacteristicsItem
        title={t('advisor:strategySimulator:preferences:carbonFootprint')}
        animate={animate}
        icon={<SpriteIcon id="Sustainability" size={21} />}
        titleSpacing={SPACING_16}
        spacing={gap - 8}
      >
        <TotalCarbonFootprint>
          <ParagraphXSmall>
            <Trans
              i18nKey="advisor:strategySimulator:preferences:tonco2"
              values={{
                carbonFootprint: parseInt(
                  portfolioCharacteristics.sustainability.carbonFootprint
                ),
              }}
              components={{
                strong: (
                  <H2
                    as="span"
                    style={{
                      fontSize: 32,
                      fontFamily: titleFontStack,
                      fontWeight: 700,
                      display: 'inline',
                    }}
                  />
                ),
              }}
            />
          </ParagraphXSmall>
        </TotalCarbonFootprint>
        <StretchedHorizontalBarChart
          color={accent.ocean230}
          data={[
            {
              label: t('advisor:strategySimulator:preferences:thisPortfolio'),
              value: parseInt(
                portfolioCharacteristics.sustainability.carbonFootprint
              ),
            },
            {
              label: t('esg:localization:country', {
                context: operatingCountry,
              }),
              value: parseInt(
                portfolioCharacteristics.sustainability.domesticBenchmark
              ),
            },
            {
              label: t('advisor:strategySimulator:preferences:world'),
              value: parseInt(
                portfolioCharacteristics.sustainability.globalBenchmark
              ),
            },
          ]}
        />
        <ParagraphXSmall style={{ marginTop: 'auto' }}>
          {t('advisor:strategySimulator:preferences:coverage', {
            coverage: percentage(
              portfolioCharacteristics.sustainability.coverage * 100
            ),
          })}
        </ParagraphXSmall>
      </PortfolioCharacteristicsItem>
      <PortfolioCharacteristicsItem
        title={t('advisor:strategySimulator:preferences:geography')}
        animate={animate}
        icon={<SpriteIcon id="Position" size={21} />}
        titleSpacing={SPACING_16}
        spacing={gap - 8}
      >
        <StretchedHorizontalBarChart
          color={accent.ocean230}
          data={portfolioCharacteristics.geography.allocations
            .sort((a, b) => b.allocation - a.allocation)
            .slice(0, 5)
            .filter((item) => item.countryCode)
            .map((item) => ({
              label: localizeCountryName(item.countryCode.toUpperCase()),
              value: item.allocation,
            }))}
          formatValue={(value) => percentage(value * 100, 1)}
        />
        <ParagraphXSmall style={{ marginTop: 'auto' }}>
          {t('advisor:strategySimulator:preferences:coverage', {
            coverage: percentage(
              portfolioCharacteristics.geography.coverage * 100
            ),
          })}
        </ParagraphXSmall>
      </PortfolioCharacteristicsItem>
      <PortfolioCharacteristicsItem
        title={t('advisor:strategySimulator:preferences:sectors')}
        animate={animate}
        icon={
          <SpriteIcon
            id="Company"
            color={accent.raspberry3}
            accentColor={accent.raspberry190}
            tertiaryColor={accent.raspberry480}
            size={21}
          />
        }
        titleSpacing={SPACING_16}
        spacing={gap - 8}
      >
        <StretchedHorizontalBarChart
          color={accent.ocean230}
          data={portfolioCharacteristics.sector.allocations
            .sort((a, b) => b.allocation - a.allocation)
            .slice(0, 5)
            .filter((item) => item.sector)
            .map((item) => ({
              label: t(`sectors:${camelcase(item.sector)}:title`),
              value: item.allocation,
            }))}
          formatValue={(value) => percentage(value * 100, 1)}
        />

        <ParagraphXSmall style={{ marginTop: 'auto' }}>
          {t('advisor:strategySimulator:preferences:coverage', {
            coverage: percentage(
              portfolioCharacteristics.sector.coverage * 100
            ),
          })}
        </ParagraphXSmall>
      </PortfolioCharacteristicsItem>
    </Container>
  );
};
