import React from 'react';
import { useSelector } from 'react-redux';

import { selectors, useResource } from '@formue-app/core';

import { FinancialAbility } from './FinancialAbility';

const {
  entities: {
    investmentProfile: { investmentStrategyRiskAndLockInSelector },
  },
} = selectors;

export const LockinModalContent = (props) => {
  useResource(['INVESTMENT_PROFILE/INDEX']);

  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );

  if (!investmentStrategyRiskAndLockIn) {
    return null;
  }

  return (
    <>
      <FinancialAbility />
    </>
  );
};
