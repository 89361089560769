import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
  selectors,
  useResource,
  sumAssetClassesByAssetGroup,
} from '@formue-app/core';

import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';
import { StrategyMarketValues } from '../components/investmentStrategies/StrategyMarketValues';
import { StrategyAssetClasses } from '../components/investmentStrategies/StrategyAssetClasses';
import { StrategyMetrics } from '../components/investmentStrategies/StrategyMetrics';
import { InvestmentStrategyChildren } from '../components/investmentStrategies/InvestmentStrategyChildren';

const {
  entities: {
    investmentStrategies: {
      investmentStrategyByIdSelector,
      investmentStrategiesSelector,
    },
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsSelector,
      investmentStrategyAssetAllocationsMetadataByIdSelector,
      investmentStrategyAssetAllocationsByStrategyIdSelector,
    },
    investmentStrategyIndexChanges: {
      investmentStrategyIndexChangesMetadataByIdSelector,
    },
  },
} = selectors;

export const StrategyDetailsAssetAllocationPage = (props) => {
  const { id } = useParams();
  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_INDEX_CHANGES/INDEX',
      args: { strategyId: id },
    },
  ]);

  const allInvestmentStrategies = useSelector(investmentStrategiesSelector);
  const allAssetAllocations = useSelector(
    investmentStrategyAssetAllocationsSelector
  );
  const indexChangesMetadata = useSelector(
    investmentStrategyIndexChangesMetadataByIdSelector(id)
  );
  const strategy = useSelector(investmentStrategyByIdSelector(id));
  const assetAllocationMetadata = useSelector(
    investmentStrategyAssetAllocationsMetadataByIdSelector(id)
  );
  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(id)
  );
  const features = { strategyToolBeta: false }; //useSelector(getFlagsSelector);

  const isParentStrategy = strategy ? strategy.children.length : null;

  const childStrategies = allInvestmentStrategies.filter((s) =>
    strategy.children.some((e) => e.id === s.id)
  );

  const [editMode, setEditMode] = useState(false);
  const [editableAssetAllocations, setEditableAssetAllocations] =
    useState(null);
  const [assetGroups, setAssetGroups] = useState([]);

  useEffect(() => {
    if (assetAllocations) {
      setEditableAssetAllocations(
        assetAllocations.filter(
          (assetClass) => assetClass.investmentStrategy.id === id
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetAllocations.length, id]);

  useEffect(() => {
    if (editableAssetAllocations) {
      setAssetGroups(sumAssetClassesByAssetGroup(editableAssetAllocations));
    }
  }, [editableAssetAllocations]);

  return (
    <>
      {!editableAssetAllocations || loading ? (
        <CenteredActivityIndicator columns={12} />
      ) : (
        <>
          <StrategyMarketValues
            columns={12}
            strategyToolBeta={features.strategyToolBeta}
            editMode={editMode}
            strategy={strategy}
            onClick={() => {
              setEditMode(!editMode);
              editMode && setEditableAssetAllocations(assetAllocations);
            }}
          />
          <StrategyAssetClasses
            columns={12}
            editMode={editMode}
            strategyToolBeta={features.strategyToolBeta}
            setEditMode={setEditMode}
            editableAssetAllocations={editableAssetAllocations}
            setEditableAssetAllocations={setEditableAssetAllocations}
            assetAllocations={assetAllocations}
            assetGroups={assetGroups}
            showInputs={true}
          />
          <StrategyMetrics
            strategyReturn={assetAllocationMetadata.expectedPerformance.return}
            strategyRisk={assetAllocationMetadata.expectedPerformance.risk}
            annualAverageReturn={
              indexChangesMetadata.historicalPerformance.annualAverageReturn
            }
            annualAverageRisk={
              indexChangesMetadata.historicalPerformance.annualAverageRisk
            }
          />

          {isParentStrategy ? (
            <InvestmentStrategyChildren
              childStrategies={childStrategies}
              assetAllocations={allAssetAllocations}
              advisorTool={true}
            />
          ) : null}
        </>
      )}
    </>
  );
};
