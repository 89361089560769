import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { investmentPreferences } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer('investment-preferences');

// Create the sagas for this entity
function* readInvestmentPreferences() {
  yield takeEvery(actions.indexRequestBegin, function*() {
    try {
      const result = yield call(investmentPreferences.get);
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error })
    }
  });
}

// Create a selector to make it easier to loop over all investment preferences
export const allInvestmentPreferences = state => {
  const ids = state.entities.investmentPreferences.allIds;
  const items = state.entities.investmentPreferences.byId;
  return ids.map(id => items[id]);
};

export const sagas = [readInvestmentPreferences, ...resourceSagas];
