/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getAssetClassName, useResource } from '@formue-app/core';
import { useMediaQuery } from 'react-responsive';

import { accent } from '../../constants/colors';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { H2, H6 } from '../../components/texts';
import {
  BORDER_RADIUS_LARGE,
  BORDER_RADIUS_SMALL,
  SPACING_32,
  SPACING_8,
} from '../../constants/spacing';
import { desktopCondition } from '../../constants/media';
import { FundFacts } from '../../components/portfolio/funds/FundFacts';
import { MainGrid } from '../../components/layout';
import { BaseSection } from '../../components/common/BaseSection';
import { FundClassification } from '../../components/portfolio/funds/FundClassification';
import { FundDevelopment } from '../../components/portfolio/funds/FundDevelopment';
import { FundUnderlying } from '../../components/portfolio/funds/FundUnderlying';
import { BackButton } from '../../components/common/BackButton';

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

const StyledContainer = styled(BaseSection)`
  background-color: transparent;
  padding: 0;
`;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 30px 0;
`;

const BreadcrumbsItem = styled(H6)`
  color: ${accent.ocean470};
`;

const Dot = styled.div`
  width: ${BORDER_RADIUS_LARGE};
  height: ${BORDER_RADIUS_LARGE};
  border-radius: ${BORDER_RADIUS_SMALL};
  background-color: ${accent.ocean470};
  margin: 0 ${SPACING_8};
`;

export const FundsPage = () => {
  const loading = useResource(['FUNDS/INDEX', 'PORTFOLIOS/INDEX']);
  const fundsById = useSelector((state) => state.entities.funds.byId);

  const queryParams = new URLSearchParams(
    document.location.search.substring(1)
  );
  const id = queryParams.get('id');
  const fund = fundsById[id];

  const isDesktop = useMediaQuery({
    query: desktopCondition,
  });

  if (loading)
    return (
      <StyledContainer columns={12}>
        <StyledCenteredActivityIndicator background={accent.sand1} />
      </StyledContainer>
    );

  if (!fund) return null;

  return (
    <StyledContainer columns={12}>
      <BackButton columns={12} style={{ marginBottom: SPACING_32 }} />
      <H2>{fund.name}</H2>
      {/* To be brought back once we solve the issues with data source

      <Breadcrumbs>
        <BreadcrumbsItem>{getAssetClassName(fund.assetGroup)}</BreadcrumbsItem>
        <Dot />
        <BreadcrumbsItem>{getAssetClassName(fund.assetClass)}</BreadcrumbsItem>
      </Breadcrumbs>
      <StyledSubGrid>
        <FundFacts {...fund} columns={isDesktop ? 5 : 12} />
        <FundClassification {...fund} columns={isDesktop ? 5 : 12} />
        <FundDevelopment
          referenceIndex={fund.referenceIndex}
          securityId={fund.securityId}
          style={isDesktop ? { gridRow: '1 / span 2' } : {}}
          columns={isDesktop ? 7 : 12}
        />
      </StyledSubGrid>
       */}
      <FundUnderlying {...fund} />
    </StyledContainer>
  );
};
/* eslint-enable no-unused-vars */
