import { useSelector } from 'react-redux';

import { selectors } from '../../store';

const {
  entities: {
    strategy: { allStrategiesSelector },
  },
} = selectors;

export const useStartegyOptions = (filterStrategies = true) => {
  const allStrategies = useSelector(allStrategiesSelector);
  const excludedAccountIds = useSelector(
    (state) => state.ui.portfolio.filters.excludedSsids
  );

  if (filterStrategies) {
    return allStrategies.filter((item) =>
      item.accounts.some((acc) => !excludedAccountIds.includes(acc))
    );
  } else {
    return allStrategies;
  }
};
