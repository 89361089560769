import { stringify } from 'query-string';

import { apiFetch } from './utils/api';
import { FilterSerializer } from './serializers/filters';

// const mockedFilterValues = require('./mocks/v2/qlik/filtervalues.json');

export const filterValues = {
  /**
   * @desc Filter values fetch
   *
   * @param {(string)} ssids - Ssid of the user we're trying to fetch the data for. Comma seperated if multiple
   * supported.
   *
   * @return json - data or error message
   */
  get: async (args) => {
    // return mockedFilterValues;
    const options = {
      method: 'GET',
    };
    const query = stringify(args, { arrayFormat: 'comma' });
    const url = `/v2/qlik/filtervalues?${query}`;

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },

  // TODO - Upgrade this to V2 API as well
  post: async (defaultFilters) => {
    const payload = FilterSerializer.serialize(defaultFilters);
    const options = {
      method: 'POST',
      body: payload,
    };
    const url = '/v1/qlik/filtervalues';
    const { body } = await apiFetch(url, options);

    return body;
  },
};
