import React from 'react';

import { SpriteIcon } from '../../common/SpriteIcon';
import { SPACING_32 } from '../../../constants/spacing';

export const GoalIcon = ({ goalType, ...props }) => {
  const iconComponent = (type) => {
    switch (type) {
      case 'secureFuture':
        return <SpriteIcon id="Gen. Economy" size={SPACING_32} {...props} />;
      case 'liveWellOld':
        return <SpriteIcon id="Retirement" size={SPACING_32} {...props} />;
      case 'maintainValue':
        return (
          <SpriteIcon id="Preserve and Develop" size={SPACING_32} {...props} />
        );
      case 'dayToDay':
        return <SpriteIcon id="Iteration" size={SPACING_32} {...props} />;
      case 'purchaseDream':
        return <SpriteIcon id="House" size={SPACING_32} {...props} />;
      case 'helpChildren':
        return <SpriteIcon id="Help Children" size={SPACING_32} {...props} />;
      case 'helpGrandChildren':
        return <SpriteIcon id="Help Children" size={SPACING_32} {...props} />;
      case 'flexiConsumption':
        return (
          <SpriteIcon
            id="Sustainable Investment"
            size={SPACING_32}
            {...props}
          />
        );
      case 'businessDevelopment':
        return (
          <SpriteIcon id="Business Development" size={SPACING_32} {...props} />
        );
      case 'newBusiness':
        return <SpriteIcon id="New Business" size={SPACING_32} {...props} />;
      case 'importantTime':
        return <SpriteIcon id="Precious Time" size={SPACING_32} {...props} />;
      case 'giveBack':
        return <SpriteIcon id="Philanthropy" size={SPACING_32} {...props} />;
      case 'sustainableInvest':
        return (
          <SpriteIcon
            id="Sustainable Investment"
            size={SPACING_32}
            {...props}
          />
        );
      case 'financiallyIndependent':
        return (
          <SpriteIcon
            id="Financially Independent"
            size={SPACING_32}
            {...props}
          />
        );
      case 'nextGeneration':
        return <SpriteIcon id="Generation" size={SPACING_32} {...props} />;

      default:
        return <SpriteIcon id="Comment" size={SPACING_32} {...props} />;
    }
  };

  const IconComponent = iconComponent(goalType);

  if (!IconComponent) {
    return null;
  }

  return IconComponent;
};
