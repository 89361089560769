import React from 'react';
import styled from 'styled-components';

import { SPACING_48 } from '../../../constants/spacing';
import { SustainabilityPreferences } from './SustainabilityPreferences';
import { BaseSection } from '../../common/BaseSection';

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_48};
`;

export const InvestmentPreferencesContent = () => {
  return (
    <>
      <StyledBaseSection columns={12}>
        <SustainabilityPreferences />
      </StyledBaseSection>
    </>
  );
};
