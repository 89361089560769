import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { t } from '@formue-app/core';

import { H3, Paragraph } from '../../../texts';
import { accent, overlayColor } from '../../../../constants/colors';
import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_12,
  SPACING_32,
} from '../../../../constants/spacing';

import { BaseSection } from '../../../common/BaseSection';
import { Input, Label, RadioButton, Select } from '../../../formElements';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';
import { Button } from '../../../buttons';

import {
  easeInOut,
  longAnimationTime,
  shortAnimationTime,
} from '../../../../constants/animation';

const Wrapper = styled.div`
  background: ${overlayColor};
  position: fixed;
  top: -100%;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 0;
  transition: opacity ${easeInOut} ${shortAnimationTime};

  ${(props) =>
    props.overlay &&
    css`
      top: 0;
      opacity: 1;
    `}
`;

const Panel = styled(BaseSection)`
  padding: ${SPACING_48};
  background: ${accent.sand1};
  position: relative;
  top: 30px;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: 0.1s;

  ${(props) =>
    props.overlay &&
    css`
      top: 0px;
      opacity: 1;
    `}
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  cursor: pointer;
  position: absolute;
  top: ${SPACING_24};
  right: ${SPACING_24};
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_48};
  margin-bottom: ${SPACING_32};
`;

const RadioItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_12};
  cursor: pointer;
`;

export const EditTodoOvelay = (props) => {
  const {
    overlay,
    openOverlay,
    accountTeam = [],
    user,
    editId,
    onUpdate,
  } = props;

  const item = useSelector((state) => state.entities.tasks.byId[editId]);

  const [assignee, setAssignee] = useState();
  const [title, setTitle] = useState('');
  const [priority, setPriority] = useState('');
  const [validForm, setValidForm] = useState(true);

  useEffect(() => {
    if (item) {
      setAssignee(item?.assignee?.id);
      setTitle(item.title);
      setPriority(item.priority);
    }
  }, [item]);

  useEffect(() => {
    const valid = !(assignee && title);
    setValidForm(valid);
  }, [assignee, title]);

  // Fix a bug where navigating text field caused prtesentation to switch slides
  // Now we disable the keydown event when the overlay is open
  let oldKeyDown = useRef(window.onkeydown);

  useEffect(() => {
    if (overlay) {
      window.onkeydown = null;
    } else {
      window.onkeydown = oldKeyDown.current;
    }
  }, [overlay]);

  if (!user || !item) return null;

  const options = [
    { label: item?.assignee?.name, value: item?.assignee?.id },
    ...accountTeam.map((item) => ({
      label: item?.name,
      value: item?.ssid,
    })),
    ...user.accessibleAccounts.map((account) => ({
      label: account.name,
      value: account.ssid,
    })),
  ]
    // Filter out duplicates and empty values
    .filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.label === item.label && t.value === item.value)
    )
    .filter((item) => Boolean(item.value));

  const onSubmit = (event) => {
    event.preventDefault();

    const selectedAssignee = options.find((item) => item.value === assignee);

    onUpdate({
      ...item,
      assignee: {
        id: selectedAssignee.value,
        name: selectedAssignee.label,
      },
      priority,
      title,
    });
    openOverlay(false);
  };

  return (
    <Wrapper overlay={overlay}>
      <Panel overlay={overlay}>
        <StyledSpriteIconFunctional
          id="Close"
          onClick={() => openOverlay(false)}
        />
        <H3 style={{ color: accent.ocean490, marginBottom: SPACING_24 }}>
          {t('presentation:annualReview:measures:form:editTitle')}
        </H3>
        <form id="self-declaration-form" onSubmit={(event) => onSubmit(event)}>
          <RadioWrapper>
            <RadioItem>
              <RadioButton
                id="high-priority"
                name="high-priority"
                checked={priority === 'high'}
                onChange={() => setPriority('high')}
              />
              <Paragraph for="high-priority" as="label">
                {t('presentation:annualReview:measures:form:highPriority')}
              </Paragraph>
            </RadioItem>
            <RadioItem>
              <RadioButton
                id="normal-priority"
                name="normal-priority"
                checked={priority === 'normal'}
                onChange={() => setPriority('normal')}
              />
              <Paragraph for="normal-priority" as="label">
                {t('presentation:annualReview:measures:form:normalPriority')}
              </Paragraph>
            </RadioItem>
          </RadioWrapper>
          <Label>
            {t('presentation:annualReview:measures:form:assigneeLabel')}
          </Label>
          <br />
          <Select
            placeholder={t(
              'presentation:annualReview:measures:form:assigneePLaceholder'
            )}
            onChange={(event) => {
              setAssignee(event.target.value);
            }}
            id="assignee"
            name="assignee"
            value={assignee ? assignee : ''}
            options={options}
            style={{
              marginBottom: SPACING_16,
              width: 360,
            }}
          />
          <br />
          <Label>
            {t('presentation:annualReview:measures:form:descriptionLabel')}
          </Label>
          <br />
          <Input
            onChange={(event) => setTitle(event.target.value)}
            type="text"
            value={title}
            id="title"
            name="title"
            style={{
              marginBottom: SPACING_16,
              width: 360,
            }}
          />
          <br />
          <Button disabled={validForm} highlight={true}>
            {t('globals:edit')}
          </Button>
        </form>
      </Panel>
    </Wrapper>
  );
};
