import React, { useState } from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { SPACING_2, SPACING_8, SPACING_16 } from '../../../constants/spacing';
import { H4, H6, ParagraphSmall } from '../../texts';

import { MarketDevelopmentGraph } from './MarketDevelopmentGraph';
import { accent } from '../../../constants/colors';

const Wrapper = styled.div`
  border-top: 1px solid ${accent.sand3};
  padding-top: ${SPACING_16};
`;

const Year = styled(H6)`
  text-align: center;
  margin-bottom: ${SPACING_2};
`;

const Title = styled(H4)`
  text-align: center;
`;

const Description = styled(ParagraphSmall)`
  margin: 0 auto;
  width: 50%;
  text-align: center;
  margin-bottom: ${SPACING_8};
  min-height: 48px;
`;

export const MarketDevelopment = (props) => {
  const { startYear, index, historicalEvents } = props;

  const [indexFilter, setIndexFilter] = useState({
    from: new Date(startYear),
    to: new Date(),
  });
  const [selectedHistoricalEvent, setSelectedHistoricalEvent] = useState(null);

  const filteredIndex = index.filter((item) => {
    return (
      new Date(item.date).getTime() >= indexFilter.from.getTime() &&
      new Date(item.date).getTime() <= indexFilter.to.getTime()
    );
  });

  return (
    <Wrapper>
      <Year>
        {selectedHistoricalEvent
          ? new Date(selectedHistoricalEvent.date).getFullYear()
          : `${startYear} – ${new Date().getFullYear()}`}
      </Year>
      <Title>
        {selectedHistoricalEvent
          ? selectedHistoricalEvent.title
          : t('wealthPlan:investmentSimulator:historicPerformance:titleNoYears')}
      </Title>
      <Description>
        {selectedHistoricalEvent
          ? selectedHistoricalEvent.description
          : t('wealthPlan:investmentSimulator:historicPerformance:subTitle')}
      </Description>

      <MarketDevelopmentGraph
        startYear={startYear}
        index={index}
        filteredIndex={filteredIndex}
        historicalEvents={historicalEvents}
        setIndexFilter={setIndexFilter}
        indexFilter={indexFilter}
        selectedHistoricalEvent={selectedHistoricalEvent}
        setSelectedHistoricalEvent={setSelectedHistoricalEvent}
      />
    </Wrapper>
  );
};
