import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  selectors,
  formatGainValue,
  formatMoney,
  formatTwrValue,
} from '@formue-app/core';

import { tabletCondition, mobileCondition } from '../../../constants/media';
import { H3, H4, Paragraph } from '../../texts';
import { BaseSection } from '../../common/BaseSection';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  align-items: center;
`;

const StyledFirstColumn = styled(H4)`
  width: 53%;

  @media ${tabletCondition} {
    width: 50%;
  }

  @media ${mobileCondition} {
    width: 33%;
  }
`;

const StyledSecondColumn = styled(H4)`
  width: 15%;
  text-align: right;

  @media ${tabletCondition} {
    width: 20%;
  }

  @media ${mobileCondition} {
    width: 33%;
  }
`;

const StyledThirdColumn = styled.span`
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;

  @media ${tabletCondition} {
    width: 30%;
  }

  @media ${mobileCondition} {
    width: 33%;
  }
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
} = selectors;

export const Total = () => {
  const portfolioTotal = useSelector(portfolioTotalSelector);

  if (!portfolioTotal) {
    return null;
  }

  return (
    <StyledBaseSection columns="12">
      <StyledFirstColumn as={H3}>Totalt</StyledFirstColumn>
      <StyledSecondColumn>{formatMoney(portfolioTotal.mv)}</StyledSecondColumn>
      <StyledThirdColumn>
        <H4>{formatGainValue(portfolioTotal.gain)}</H4>
        <Paragraph style={{ marginLeft: '6px' }}>
          ({formatTwrValue(portfolioTotal.twr)})
        </Paragraph>
      </StyledThirdColumn>
    </StyledBaseSection>
  );
};
