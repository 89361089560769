import React from 'react';
import styled from 'styled-components';

import { getAdvisorRoleName } from '@formue-app/core';

import { ProfileIconSection } from './ProfileIconSection';
import { accent } from '../../constants/colors';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_20,
} from '../../constants/spacing';
import { Paragraph, H4 } from '../texts';
import {
  desktopCondition,
  tabletCondition,
  mobileCondition,
  NotMobileWrapper,
  MobileWrapper,
} from '../../constants/media';

import { SpriteIcon } from './SpriteIcon';

const StyledLine = styled.hr`
  border: none;
  border-top: 1px solid ${accent.sand3};
  margin-top: ${SPACING_16};
  margin-bottom: ${SPACING_24};
  opacity: 0.6;

  @media ${tabletCondition} {
    border: none;
    border-right: 1px solid ${accent.sand3};
    height: 100%;
  }

  @media ${mobileCondition} {
    display: none;
  }
`;

const TopContent = styled.div`
  margin: 0 auto;

  @media ${desktopCondition} {
    margin-top: 20px;
  }

  @media ${mobileCondition} {
    margin-right: auto;
    margin-left: 0;
    text-align: left;
  }
`;
const BottomContent = styled.div`
  min-width: 90px;

  @media ${tabletCondition} {
    width: 46.3%;
    margin: 0;
  }

  @media ${mobileCondition} {
    margin-top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  svg {
    vertical-align: bottom;
  }
`;

const LinkedIcons = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: ${SPACING_8};

  @media ${tabletCondition} {
    justify-content: flex-start;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const LinkedMobileIcons = styled.a`
  line-height: 0px;
  padding-top: 5px;
  padding-bottom: 5px;

  @media ${mobileCondition} {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const ContactCard = (props) => {
  const { contact, columns, ...rest } = props;

  if (!contact) {
    return null;
  }

  const {
    name,
    profileImageUrl,
    photoUrl,
    role,
    phone,
    email,
    zipcode,
    address,
  } = contact;

  const url = profileImageUrl || photoUrl;

  return (
    <ProfileIconSection
      iconName={name}
      iconUrl={url}
      iconSize={70}
      columns={columns}
      {...rest}
    >
      <TopContent>
        {role && (
          <H4 style={{ marginBottom: '5px' }}>{getAdvisorRoleName(role)}</H4>
        )}
        {name && <Paragraph>{name}</Paragraph>}
        {address && <Paragraph>{address}</Paragraph>}
        {zipcode && <Paragraph>{zipcode}</Paragraph>}
      </TopContent>
      <StyledLine />
      <BottomContent>
        <NotMobileWrapper>
          <LinkedIcons
            href={`mailto:${email}`}
            style={{ marginBottom: SPACING_8 }}
          >
            <SpriteIcon id="Contact Mail" size={SPACING_16} />
            <Paragraph>{email}</Paragraph>
          </LinkedIcons>

          <LinkedIcons href={`tlf:${phone}`}>
            <SpriteIcon id="Contact Phone" size={SPACING_16} />
            <Paragraph>{phone}</Paragraph>
          </LinkedIcons>
        </NotMobileWrapper>
        <MobileWrapper>
          <LinkedMobileIcons
            href={`tlf:${phone}`}
            style={{
              borderRight: `1px solid ${accent.sand3}`,
              paddingRight: SPACING_20,
            }}
          >
            <SpriteIcon id="Contact Phone" size={25} />
          </LinkedMobileIcons>
          <LinkedMobileIcons href={`mailto:${email}`}>
            <SpriteIcon id="Contact Mail" size={25} />
          </LinkedMobileIcons>
        </MobileWrapper>
      </BottomContent>
    </ProfileIconSection>
  );
};
