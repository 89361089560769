import React from 'react';

import { SPACING_24, SPACING_16 } from '../../constants/spacing';
import { ButtonSmall } from '../buttons';
import { H2Small, Paragraph } from '../texts';
import { BaseSection } from './BaseSection';

export const InfoCardHighlight = (props) => {
  const {
    columns = 6,
    title,
    body,
    to,
    navLabel,
    disabled,
    icon: IconSvg,
    external = false,
    ...rest
  } = props;

  const smallCondition = columns < 6;

  return (
    <BaseSection {...rest} columns={columns}>
      <div
        style={{
          display: 'flex',
          alignItems: smallCondition ? 'flex-start' : 'center',
          marginBottom: SPACING_16,
          flexDirection: smallCondition ? 'column' : 'row',
        }}
      >
        {IconSvg ? (
          <div
            style={{
              marginRight: SPACING_16,
              marginBottom: smallCondition ? SPACING_16 : 0,
            }}
          >
            {IconSvg}
          </div>
        ) : null}
        <H2Small>{title}</H2Small>
      </div>
      <Paragraph
        style={{ marginBottom: smallCondition ? SPACING_16 : SPACING_24 }}
      >
        {body}
      </Paragraph>
      <ButtonSmall to={to} disabled={disabled} external={external}>
        {navLabel}
      </ButtonSmall>
    </BaseSection>
  );
};
