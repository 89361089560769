import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  SPACING_32,
  SPACING_16,
  SPACING_8,
  SPACING_2,
} from '../../constants/spacing';
import {
  accent,
  backgroundMain,
  blackTranslucent,
} from '../../constants/colors';
import { mobileMenuMaxWidth } from '../../constants/grid';
import { easeInOut, longAnimationTime } from '../../constants/animation';
import { H3, Paragraph } from '../texts';
import { ExtendedReportingIcon } from '../extendedReporting/ExtendedReportingIcon';

const MenuWrapper = styled.nav`
  background-color: ${backgroundMain};
  height: calc(100vh - 64px);
  position: fixed;
  top: 64px;
  overflow: auto;
  width: ${mobileMenuMaxWidth}px;
  max-width: 100vw;
  box-shadow: 0 0 24px 0 ${blackTranslucent(0.12)};
  z-index: -1;
  opacity: 0;
  transition: all ${longAnimationTime} ${easeInOut};

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      z-index: 99;
    `}
`;

const Menu = styled.div`
  margin: ${SPACING_16} 0;
`;

const menuItemStyle = css`
  display: flex;
  align-items: center;
  padding: ${SPACING_16} ${SPACING_32};
  margin: ${SPACING_2} 0;
  text-decoration: none;
`;

const StyledMenuItem = styled(NavLink)`
  ${menuItemStyle}

  &:hover {
    cursor: pointer;
    background-color: ${accent.sand260};
  }

  &.active {
    background-color: ${accent.sand260};
  }
`;

const DisabledMenuItem = styled.a`
  ${menuItemStyle}
  opacity: 0.3;
`;

const Separator = styled.div`
  height: 1px;
  width: 40px;
  background-color: ${accent.sand2};
  margin: ${SPACING_16} ${SPACING_32};
`;

const BackButton = styled(NavLink)`
  display: block;
  padding: ${SPACING_8} ${SPACING_32};
`;

const MenuItem = (props) => {
  const { title, icon, link, iconProps, disabled, onClose } = props;

  const Icon = icon;

  if (disabled) {
    return (
      <DisabledMenuItem>
        <Icon
          width={24}
          height={24}
          style={{ marginRight: 12 }}
          {...iconProps}
        />
        <H3>{title}</H3>
      </DisabledMenuItem>
    );
  }

  return (
    <StyledMenuItem
      exact={true}
      to={link}
      activeClassName="active"
      onClick={() => {
        onClose();
      }}
    >
      <Icon width={24} height={24} style={{ marginRight: 12 }} {...iconProps} />
      <H3>{title}</H3>
    </StyledMenuItem>
  );
};

export const ExtendedReportingMenu = (props) => {
  const { open, routes = [], onClose } = props;

  return (
    <MenuWrapper open={open}>
      <Menu>
        {routes.map((route, index) => (
          <MenuItem
            title={route.title}
            link={route.path}
            icon={ExtendedReportingIcon}
            iconProps={{ type: route.icon, disabled: route.disabled }}
            key={`item-${index}`}
            disabled={route.disabled}
            onClose={onClose}
          />
        ))}
      </Menu>
      <Separator />
      <BackButton to="/">
        <Paragraph>Back to client web</Paragraph>
      </BackButton>
    </MenuWrapper>
  );
};
