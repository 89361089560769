import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t, useResource } from '@formue-app/core';
import {
  SPACING_16,
  SPACING_40,
  SPACING_48,
  SPACING_8,
} from '../../constants/spacing';
import { InvestmentStrategiesListItem } from './InvestmentStrategiesListItem';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { InvestmentStrategiesListItemParent } from './InvestmentStrategiesListItemParent';
import { H3, Paragraph } from '../texts';

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsSelector,
    },
  },
} = selectors;

const Container = styled.div`
  grid-column: span 12;
  padding: ${SPACING_16} 0;
`;

const List = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const Title = styled(H3)`
  padding: ${SPACING_40} 0 ${SPACING_16} 0;
`;

const InvestmentStrategyListItemWrapper = (props) => {
  const { strategy, strategies } = props;

  const allAssetAllocations = useSelector(
    investmentStrategyAssetAllocationsSelector
  );

  const assetClasses = allAssetAllocations.filter(
    (assetAllocation) => assetAllocation.investmentStrategy.id === strategy.id
  );

  // Check if strategy is a parent or not
  if (strategy.children.length) {
    return (
      <InvestmentStrategiesListItemParent
        assetClasses={assetClasses}
        childStrategies={strategies.filter((s) =>
          strategy.children.some((e) => e.id === s.id)
        )}
        assetAllocations={allAssetAllocations}
        {...strategy}
      />
    );
  }

  return (
    <InvestmentStrategiesListItem assetClasses={assetClasses} {...strategy} />
  );
};

export const InvestmentStrategiesList = ({ strategies, ...rest }) => {
  const loading = useResource([
    'INVESTMENT_PROFILES/INDEX',
    'INVESTMENT_STRATEGIES/INDEX',
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  // Filter out archived strategies, and strategies that have a parent.
  // The reason we are filtering out those who have a parent strategy is
  // that they are rendered inside the parent strategy
  const activeStrategies = strategies.filter(
    (strategy) =>
      strategy.status?.toLowerCase() !== 'archived' &&
      !strategy.parent.hasOwnProperty('id')
  );

  const previousStrategies = strategies.filter(
    (strategy) =>
      strategy.status?.toLowerCase() === 'archived' &&
      !strategy.parent.hasOwnProperty('id')
  );

  return (
    <Container {...rest}>
      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          {!activeStrategies.length ? (
            <Paragraph style={{ paddingLeft: SPACING_48 }}>
              {t('wealthPlan:investmentStrategy:noNewStrategiesAvailable')}
            </Paragraph>
          ) : (
            <>
              <List>
                {activeStrategies.map((strategy) => (
                  <InvestmentStrategyListItemWrapper
                    key={`investment-strategy-${strategy.id}`}
                    strategy={strategy}
                    strategies={strategies}
                  />
                ))}
              </List>
              {previousStrategies.length ? (
                <>
                  <Title>
                    {t('wealthPlan:investmentStrategy:previousStrategies')}
                  </Title>
                  <List>
                    {previousStrategies.map((strategy) => (
                      <InvestmentStrategyListItemWrapper
                        key={`investment-strategy-${strategy.id}`}
                        strategy={strategy}
                        strategies={strategies}
                      />
                    ))}
                  </List>
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </Container>
  );
};
