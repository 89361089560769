export const TRANSITION_CHART_DATA = [
  {
    label: 'Sustainable',
    value: 1,
  },
  {
    label: 'Neutral',
    value: 4,
  },
  {
    label: 'Transition',
    value: 2,
  },
  {
    label: 'Significant Harm',
    value: 1,
  },
];

export const EXCLUSION_CHART_DATA = [
  {
    label: 'Sustainable',
    value: 1,
  },
  {
    label: 'Neutral',
    value: 4,
  },
  {
    label: 'Transition',
    value: 0,
  },
  {
    label: 'Significant Harm',
    value: 0,
  },
];

// Some of the social responsibility trends are considered positive
// when their value is negative.
export const INVERTED_SOCIAL_RESPONSIBILITY_TRENDS = [
  'globalCompactCompliant',
  'genderPayGap',
  'controversialWeaponExposure',
  'humanRightsPolicy',
];
