import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';

import { backgroundMain } from '../../constants/colors';
import { GridElement } from '../../components/layout';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { SPACING_48, SPACING_24 } from '../../constants/spacing';
import { PortfolioConstructionFooter } from '../../components/portfolio/lenses/construction/PortfolioConstructionFooter';
import { Modal } from '../../components/common/Modal';
import { CoverageReadMore } from '../../components/portfolio/lenses/CoverageReadMore';
import { MenuPage } from '../base/MenuPage';
import { TableList } from '../../components/lists';
import { H2, Paragraph } from '../../components/texts';
import { useSecuritiesListColumns } from '../../components/portfolio/lenses/construction/securitiesListColumns';

const {
  entities: {
    securities: { allSecurities, securitiesMetadataSelector },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

export const CompaniesListPage = (props) => {
  const loading = useResource(['SECURITIES/INDEX']);

  const metadata = useSelector(securitiesMetadataSelector);
  const { coverage = 0 } = metadata;

  const securities = useSelector(allSecurities);

  const [modal, setModal] = useState('');
  const filteredSecurites = securities
    .slice(0, 10)
    .map((item, index) => ({ ...item, index: index }));

  const columns = useSecuritiesListColumns(filteredSecurites);

  return (
    <MenuPage
      {...props}
      title={t('portfolio:title')}
      icon={'portfolio'}
      subpage={true}
    >
      {loading ? (
        <GridElement columns={12}>
          <StyledCenteredActivityIndicator
            columns={12}
            size={30}
            background={backgroundMain}
          />
        </GridElement>
      ) : (
        <>
          <GridElement columns={8}>
            <H2>{t('portfolioConstruction:companiesList:title')}</H2>
            <Paragraph>
              {t('portfolioConstruction:companiesList:ingress')}
            </Paragraph>
          </GridElement>
          {filteredSecurites.length ? (
            <TableList
              style={{ marginTop: SPACING_24 }}
              id="companiesList"
              sortable={false}
              columns={columns}
              rows={filteredSecurites}
              keyExtractor={(item, index) => `funds-${index}`}
            />
          ) : null}

          <PortfolioConstructionFooter
            coverage={coverage}
            onClick={() => {
              setModal('coverage');
            }}
          />

          <Modal
            isOpen={modal.length ? true : false}
            onRequestClose={() => setModal('')}
            contentLabel={t(`esg:${modal}ReadMore:title`)}
            padding={SPACING_48}
            labelComponent={H2}
          >
            {modal === 'coverage' && <CoverageReadMore />}
          </Modal>
        </>
      )}
    </MenuPage>
  );
};
