import React from 'react';
import styled from 'styled-components';

import { ParagraphSmall } from '../texts';
import { SPACING_24, SPACING_8 } from '../../constants/spacing';
import { accent } from '../../constants/colors';
import { SpriteIconFunctional } from './SpriteIconFunctional';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.itemsSpacing}px;
  padding: ${SPACING_8} ${SPACING_24};
  position: ${(props) => (props.sticky ? 'sticky' : 'static')};
  top: 0;
  width: 100%;
  background-color: ${accent.sand150};
  grid-column: span 12;
  z-index: 20;
  border-radius: 16px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  cursor: pointer;
  gap: ${SPACING_8};
`;

const Label = styled(ParagraphSmall)`
  font-weight: 500;
  color: ${(props) => (props.active ? accent.ocean490 : accent.ocean470)};
  margin-right: ${(props) => props.itemsSpacing - 8}px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid;
  border-color: ${(props) =>
    props.active ? accent.ocean490 : accent.ocean440};
`;

export const Breadcrumbs = (props) => {
  const {
    items,
    activeIndex,
    dynamic,
    sticky,
    itemsSpacing = 24,
    onClick = () => {},
    ...rest
  } = props;
  return (
    <Container sticky={sticky} itemsSpacing={itemsSpacing} {...rest}>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;
        return (
          <LabelWrapper
            key={`breadcrumbs-item-${index}`}
            onClick={() => {
              onClick(index);
            }}
          >
            {dynamic && (
              <IconWrapper active={activeIndex >= index}>
                {activeIndex >= index && (
                  <SpriteIconFunctional id="Check" size={6} />
                )}
              </IconWrapper>
            )}
            <Label itemsSpacing={itemsSpacing} active={activeIndex >= index}>
              {item.title}
            </Label>
            {!isLastItem && (
              <SpriteIconFunctional
                id="Simple arrow"
                direction="Right"
                size={8}
              />
            )}
          </LabelWrapper>
        );
      })}
    </Container>
  );
};
