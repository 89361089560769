import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { humanFriendlyDate, useImageUrlWithAuthToken } from '@formue-app/core';
import { H3Small, H6, Paragraph, ParagraphSmall } from '../texts';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { tabletCondition } from '../../constants/media';
import { paragraphLineHeight, paragraphSize } from '../../constants/text';
import { accent, textLink } from '../../constants/colors';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 40px 66px 40px;
  height: 100%;
  gap: ${SPACING_16};
`;

const StyledImage = styled.div`
  width: 76px;
  height: 76px;
  flex-shrink: 0;
  border-radius: ${BORDER_RADIUS_LARGE};
  background-size: cover;
  background-position: center;

  @media ${tabletCondition} {
    width: 161px;
  }
`;

const StyledTitle = styled(Paragraph)`
  cursor: pointer;
  font-weight: 500;
  line-height: 21px;

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media ${tabletCondition} {
    font-size: ${paragraphSize};
    line-height: 21px;
  }
`;

const StyledLink = styled(ParagraphSmall)`
  cursor: pointer;
  flex: 1;
  display: flex;
  color: ${textLink};
  font-weight: 500;

  @media ${tabletCondition} {
    font-size: ${paragraphSize};
    line-height: ${paragraphLineHeight};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
`;

const StyledDate = styled(H6)`
  color: ${accent.ocean450};
  margin-top: ${SPACING_8};
`;

export const EventCarouselItem = ({
  image,
  imageFallback,
  slideTitle,
  title,
  description,
  link,
  externalLink,
  linkToArchiveText,
  linkToArchive,
  date,
}) => {
  const imageUrl = useImageUrlWithAuthToken(image);
  const history = useHistory();
  const preText = description ? `${description} ` : '';
  const formattedDate = humanFriendlyDate(date, true);

  return (
    <StyledContainer>
      <H3Small>{slideTitle}</H3Small>
      <StyledContentWrapper>
        <StyledImage
          style={{
            backgroundImage: `url('${imageUrl}'), url('${imageFallback.default}')`,
          }}
        />
        <div>
          <StyledTitle
            onClick={() => {
              externalLink ? window.open(link, '_blank') : history.push(link);
            }}
          >
            {preText} {title}
          </StyledTitle>
          <StyledDate>{formattedDate}</StyledDate>
        </div>
      </StyledContentWrapper>
      <StyledLink
        onClick={() => {
          history.push(linkToArchive);
        }}
      >
        {linkToArchiveText}
      </StyledLink>
    </StyledContainer>
  );
};
