const esgPreferences = [
  {
    title: 'presentation:annualReview:esg:exlusions:title',
    icon: 'Stop',
    color: '#2C5124',
    body: 'presentation:annualReview:esg:exlusions:body',
    listTitle: 'presentation:annualReview:esg:exlusions:listTitle',
    listItems: 'presentation:annualReview:esg:exlusions:listItems',
    pros: 'presentation:annualReview:esg:exlusions:pros',
    cons: 'presentation:annualReview:esg:exlusions:cons',
    investmentFocus: 'presentation:annualReview:esg:exlusions:investmentFocus',
  },
  {
    title: 'presentation:annualReview:esg:transition:title',
    icon: 'Sustainability',
    color: '#4D8D40',
    subTitle: 'presentation:annualReview:esg:transition:subTitle',
    body: 'presentation:annualReview:esg:transition:body',
    listTitle: 'presentation:annualReview:esg:transition:listTitle',
    listItems: 'presentation:annualReview:esg:transition:listItems',
    pros: 'presentation:annualReview:esg:transition:pros',
    cons: 'presentation:annualReview:esg:transition:cons',
    investmentFocus: 'presentation:annualReview:esg:transition:investmentFocus',
  },
  {
    title: 'presentation:annualReview:esg:sustainableFocus:title',
    icon: 'Environmental Opportunities',
    color: '#63B652',
    body: 'presentation:annualReview:esg:sustainableFocus:body',
    listTitle: 'presentation:annualReview:esg:sustainableFocus:listTitle',
    listItems: 'presentation:annualReview:esg:sustainableFocus:listItems',
    pros: 'presentation:annualReview:esg:sustainableFocus:pros',
    cons: 'presentation:annualReview:esg:sustainableFocus:cons',
    investmentFocus:
      'presentation:annualReview:esg:sustainableFocus:investmentFocus',
  },
];

export const content = {
  esgPreferences,
};
