import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { maxBy, minBy } from 'lodash';
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
} from 'victory';
import styled from 'styled-components';

import {
  formatBigMoney,
  formatHexOpacity,
  formatTwrTicks,
  selectors,
  toLocaleDateString,
  usePortfolioChartData,
  usePrevious,
  useResource,
} from '@formue-app/core';

import { bodyFontStack } from '../../../fonts';
import { PortfolioChartTooltip } from './PortfolioChartTooltip';

const {
  entities: {
    marketValues: { marketValuesSelector },
  },
} = selectors;

const Container = styled.div`
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

export const PortfolioChart = (props) => {
  const { dataExtractor = 'twr', colorSet, height = 160 } = props;
  const marketValues = useSelector(marketValuesSelector);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  // We only want to zero pad data if dataextractor is TWR, for marketvalues it results
  // in wrong data.
  const portfolioChartData = usePortfolioChartData(
    dataExtractor === 'twr',
    true
  );
  const previousPortfolioChartData = usePrevious(portfolioChartData);
  const [chartData, setChartData] = useState([]);
  const [localDataExtractor, setLocalDataExtractor] = useState(dataExtractor);
  const previousDataExtractor = usePrevious(dataExtractor);
  const loading = useResource(['MARKETVALUES/INDEX']);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(false);
  }, [marketValues]);

  useEffect(() => {
    setIsVisible(false);
    if (previousDataExtractor) {
      if (previousDataExtractor !== dataExtractor) {
        // Since there is no "api loading pause" when switching between showing
        // "twr" and "market values" we want to artifically create one with this
        // timeout. We only do this to get a consistent animation of the chart.
        setTimeout(() => {
          setIsVisible(true);
          setLocalDataExtractor(dataExtractor);
        }, 350);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataExtractor]);

  useEffect(() => {
    // Initial load of marketvalues
    if (!previousPortfolioChartData) {
      setChartData(portfolioChartData);
    } else {
      if (!previousPortfolioChartData.length && portfolioChartData.length) {
        setChartData(portfolioChartData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioChartData]);

  useEffect(() => {
    if (!loading) {
      setIsVisible(true);
      setTooltipEnabled(true);
    }
  }, [loading]);

  if (!chartData) return null;

  const maxValue = maxBy(chartData, localDataExtractor);
  const minValue = minBy(chartData, localDataExtractor);

  if (!minValue || !maxValue) return null;
  // If the minimum value isn't negative, we want to "push" the zero axis up a bit
  // We do this only to get a better visual experience
  const y0 =
    minValue[localDataExtractor] >= 0
      ? 0 - maxValue[localDataExtractor] / 7
      : 0;

  let chartLabels = chartData.map((value) => value.x);

  if (chartData.length < 15) {
    chartLabels = chartLabels.slice(1, chartData.length);
  }

  // Only slice last item if last month is the same as current month (for custom date ranges)
  if (
    chartData[chartData.length - 1].x.getMonth() === new Date().getMonth() &&
    new Date().getDate() < 20
  ) {
    chartLabels = chartLabels.slice(0, -1);
  }

  return (
    <Container isVisible={isVisible}>
      <VictoryChart
        domainPadding={{
          x: [localDataExtractor === 'mv' ? 10 : 0, 0],
          y: [0, 20],
        }}
        padding={{ bottom: 0, top: 0, left: 0 }}
        height={height}
        scale={{ x: 'time', y: 'linear' }}
        animate={false}
        containerComponent={
          <VictoryVoronoiContainer
            disable={!tooltipEnabled}
            voronoiDimension="x"
            labels={({ datum }) =>
              localDataExtractor === 'mv'
                ? formatBigMoney(datum.mv)
                : formatTwrTicks(datum.twr)
            }
            labelComponent={
              <PortfolioChartTooltip
                color={colorSet[2]}
                backgroundColor={colorSet[5]}
                lineColor={colorSet[4]}
                dataExtractor={localDataExtractor}
              />
            }
            style={{ display: 'flex' }}
          />
        }
      >
        <VictoryAxis
          crossAxis={false}
          dependentAxis
          offsetX={localDataExtractor === 'mv' ? 40 : 25}
          tickFormat={(tick) =>
            localDataExtractor === 'mv'
              ? formatBigMoney(tick)
              : formatTwrTicks(tick)
          }
          style={{
            axis: { stroke: 'transparent' },
            grid: {
              stroke: ({ tick }) => (tick === 0 ? colorSet[4] : colorSet[3]),
            },
            tickLabels: {
              fontSize: 5,
              fontWeight: 400,
              padding: 0,
              fill: colorSet[1],
              fontFamily: bodyFontStack,
              textAnchor: 'end',
            },
          }}
        />
        <VictoryAxis
          offsetY={16}
          scale="time"
          groupComponent={
            // Only do the transform for smaller timeframes
            chartData.length < 15 ? (
              // Very magic number, luckily the chart uses relative size regardless of actuall width.
              // So 106 seems to work out nicely. we devide that by the number of labels minus the
              // first and last that we remove
              <g transform={`translate(-${106 / (chartData.length - 2)}, 0)`} />
            ) : (
              <g transform="scale(0.97, 1), translate(6, 0)" />
            )
          }
          padding={{ right: 10, left: 10 }}
          tickValues={chartLabels}
          tickFormat={(x) => {
            if (chartData.length < 15)
              return toLocaleDateString(x, {
                month: 'short',
              });
            if (x.getMonth() === 0) return x.getFullYear();
          }}
          standalone={false}
          style={{
            axis: { stroke: 'transparent' },
            tickLabels: {
              fontSize: 5,
              fontWeight: 400,
              padding: 5,
              fill: colorSet[1],
              fontFamily: bodyFontStack,
              textAnchor: 'middle',
            },
          }}
        />
        <VictoryArea
          y={localDataExtractor}
          y0={(d) => y0}
          standalone={false}
          data={chartData}
          interpolation="monotoneX"
          style={{
            data: {
              stroke: colorSet[2],
              strokeWidth: 1,
              fill: formatHexOpacity(colorSet[0], 0.3),
            },
          }}
        />
      </VictoryChart>
    </Container>
  );
};
