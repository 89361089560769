export const H1XLSize = '70px';
export const H1XLSizeMobile = '50px';
export const H1Size = '50px';
export const H1SizeMobile = '32px';
export const H2Size = '26px';
export const H2SizeMobile = '22px';
export const H3Size = '18px';
export const H3SizeMobile = '18px';
export const H4Size = '15px';
export const H5Size = '13px';
export const H6Size = '11px';
export const paragraphSize = '15px';
export const paragraphSizeMobile = '13px';
export const paragraphSmallSize = '13px';
export const paragraphXSmallSize = '11px';
export const paragraphLargeSize = '22px';
export const paragraphLargeMobileSize = '16px';

export const buttonSizeLarge = '16px';
export const buttonSizeSmall = '13px';

export const H1XLLineHeight = '110%';
export const H1XLLineHeightMobile = '64px';
export const H1LineHeight = '110%';
export const H1LineHeightMobile = '120%';
export const H2LineHeight = '110%';
export const H2LineHeightMobile = '24px';
export const H3LineHeight = '120%';
export const H3LineHeightMobile = '24px';
export const H4LineHeight = '110%';
export const H5LineHeight = '110%';
export const H6LineHeight = '110%';
export const paragraphLineHeight = '24px';
export const paragraphLineHeightMobile = '16px';
export const paragraphSmallLineHeight = '140%';
export const paragraphXSmallLineHeight = '16px';
export const paragraphLargeLineHeight = '130%';

export const buttonLineHeightLarge = '120%';
export const buttonLineHeightSmall = '120%';

export const menuItemFontSize = H3SizeMobile;
