import { i18n } from './i18n';

export const localizedCountries = {
  nb: require('localized-countries')(require('localized-countries/data/nb')),
  sv: require('localized-countries')(require('localized-countries/data/sv')),
  da: require('localized-countries')(require('localized-countries/data/da')),
  en: require('localized-countries')(require('localized-countries/data/en')),
};

export const localizeCountryName = code => {
  return localizedCountries[i18n.locale].get(code.toUpperCase());
};
