import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { actions } from '@formue-app/core';

import { accessTokenSelector } from '../../store/auth/selectors';
import { TableList } from '../lists/TableList';
import { SPACING_8 } from '../../constants/spacing';

import { useDocumentsListColumns } from './documentsListColumns';

const { documents: documentsActions } = actions.entities;

const StyledTableList = styled(TableList)`
  margin-top: ${SPACING_8};
`;

export const DocumentList = (props) => {
  const { items: documents } = props;
  const dispatch = useDispatch();
  const accessToken = useSelector(accessTokenSelector);
  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);
  const isEmployeeClientUser = useSelector(
    (state) => state.ui.user.isEmployeeClientUser
  );
  const shouldBeAbleToOpenDocuments = !isImpersonating || isEmployeeClientUser;

  /**
   * We previously sorted documents locally before rendering this list, but sorting huge arrays
   * is quite performance intensive so it caused browser crashes and bad performance on users
   * with a lot of documents. We should probably do server-side sorting and filtering with
   * pagination in the API to make this API more performant. At least if we need to add support
   * for ordering.
   */
  const columns = useDocumentsListColumns(documents);

  if (!documents.length) {
    return null;
  }

  const generateDownloadLink = (url) => `${url}?secret_token=${accessToken}`;

  const openDocument = (document) => {
    const { id, url, read } = document;
    if (!read) {
      // Since document only allows editing read status, we just need to patch
      // it, and the code in the API function sets the right attributes etc
      dispatch(documentsActions.updateRequestBegin({ args: { id } }));
    }
    window.open(generateDownloadLink(url));
  };

  return (
    <StyledTableList
      className="table-list"
      columns={columns}
      rows={documents}
      sortable={false}
      clickCallback={shouldBeAbleToOpenDocuments ? openDocument : undefined}
      keyExtractor={({ id }) => id}
      infiniteScroll={true}
      perPage={200}
    />
  );
};
