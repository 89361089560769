import { takeLatest, put } from 'redux-saga/effects';
import * as lunr from 'lunr';

import { actions as coreActions } from '@formue-app/core';
import { actions } from './actions';

require('lunr-languages/lunr.stemmer.support.js')(lunr);
require('lunr-languages/lunr.multi.js')(lunr);
require('lunr-languages/lunr.no.js')(lunr);

const { clients: clientsActions } = coreActions.entities;

function* createSearchIndex() {
  yield takeLatest(clientsActions.indexRequestSuccess, function* ({ payload }) {
    const {
      result: { clients: clientIds },
      entities: { clients: clientsById },
    } = payload;

    const index = lunr(function () {
      this.use(lunr.multiLanguage('en', 'no'));
      this.ref('id');
      this.field('name', { boost: 10 });
      this.field('companies');

      clientIds.forEach((id) => {
        const { name, companies } = clientsById[id];
        this.add({
          id,
          name,
          companies: companies.join(' '),
        });
      });
    });

    // Store the serialized index in redux store for use later
    yield put(actions.setSearchIndex(JSON.stringify(index)));
  });
}

export const sagas = [createSearchIndex];
