import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { accent } from '../constants/colors';
import { selectors, useResource } from '@formue-app/core';
import { MenuPage } from './base/MenuPage';
import { OfficeInfo } from '../components/accountTeam';
import { ContactCard } from '../components/common/ContactCard';

const {
  entities: {
    accountTeamMembers: { mainAdvisorSelector, teamMembersSelector },
    offices: { officesById },
  },
} = selectors;

const StyledLine = styled.hr`
  grid-column: span 12;
  border: none;
  border-top: 1px solid ${accent.sand3};
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const AccountTeamPage = (props) => {
  const loading = useResource([
    'ACCOUNT_TEAM_MEMBERS/INDEX',
    'OFFICES/INDEX',
  ]);

  const mainAdvisor = useSelector(mainAdvisorSelector);
  const members = useSelector(teamMembersSelector);
  const offices = useSelector(officesById);

  if (loading || !mainAdvisor || !members || !offices) {
    return null;
  }

  const office = offices[mainAdvisor.office.id];
  const accountTeam = [mainAdvisor, ...members];

  const renderAccountMember = (member) => (
    <ContactCard
      contact={member}
      columns={4}
      key={`account-member-${member.id}`}
    />
  );

  return (
    <MenuPage {...props}>
      {accountTeam.map(renderAccountMember)}
      <StyledLine />
      <OfficeInfo columnOffset={5} columns={4} office={office} />
    </MenuPage>
  );
};
