import React from 'react';
import styled from 'styled-components';

import { t, useValuesForCountry } from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2, Paragraph, H4 } from '../../../texts';
import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_20,
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_8,
} from '../../../../constants/spacing';
import { SpriteIcon } from '../../../common/SpriteIcon';
import { textLink } from '../../../../constants/colors';
import { mobileCondition } from '../../../../constants/media';

const StyledContainer = styled(BaseSection)`
  padding: ${SPACING_48} ${SPACING_20};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  display: flex;
  @media ${mobileCondition} {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: ${SPACING_20};
  }
`;

const ImageWrapper = styled.div`
  padding: ${SPACING_20} 52px;
`;

const TextWrapper = styled.div`
  max-width: 630px;
  padding: 0 ${SPACING_8};
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledH4 = styled(H4)`
  color: ${textLink};
`;

export const CompanyPromise = ({ ...props }) => {
  const esgLinks = {
    se: {
      esgLink: 'https://formue.se/hallbarhet',
    },
    no: {
      esgLink: 'https://formue.no/baerekraft',
    },
    // TODO:dk - Change to danish website
    dk: {
      esgLink: 'https://formue.no/baerekraft',
    },
  };
  const { esgLink } = useValuesForCountry(esgLinks);

  return (
    <StyledContainer {...props}>
      <ImageWrapper>
        <SpriteIcon id="Preserve and Develop" size={128} />
      </ImageWrapper>
      <TextWrapper>
        <H2 style={{ paddingBottom: SPACING_16 }}>
          {t('esg:companyPromise:title')}
        </H2>
        <Paragraph style={{ paddingBottom: SPACING_16 }}>
          {t('esg:companyPromise:text')}
        </Paragraph>
        <StyledH4>
          <StyledLink href={esgLink} target="_blank">
            {t('esg:companyPromise:linkText')}
          </StyledLink>
        </StyledH4>
      </TextWrapper>
    </StyledContainer>
  );
};
