import { apiFetch } from './utils/api';

// const mockedSigningEvents = require('./mocks/v1/signing-events.json');

/**
 * @desc Signing events data fetch
 *
 * @return json - data or error message
 */

export const signingEvents = {
  get: async (id) => {
    // return mockedSigningEvents;

    const options = {
      method: 'GET',
    };

    let url = '/v1/signing-events';

    if (id) {
      url = `${url}/${id}`;
    }

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
