import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import {
  generateSpeedometerRiskChartColors,
  riskAndLockinValueToText,
  selectors,
  useResource,
  t,
} from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
  SPACING_64,
  SPACING_16,
} from '../../../constants/spacing';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { H2, H3, ParagraphLarge } from '../../texts';
import { SpeedometerChart } from '../../graphs/SpeedometerChart';
import { ErrorFallbackPresentation } from '../../common/ErrorFallbackPresentation';
import {
  easeInOut,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';

const {
  entities: {
    investmentProfile: { investmentStrategyRiskAndLockInSelector },
  },
} = selectors;

const Base = styled.div`
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: 0;
  display: flex;
  flex: 1;
  align-items: center;
`;

const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${SPACING_64} 72px;
`;

const Label = styled(H3)`
  margin-top: -22px;
  text-align: center;
`;

const Title = styled(H2)`
  padding: ${SPACING_48} 0 ${SPACING_16} 0;
  top: 0;
  opacity: 0;
  position: relative;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${(props) => `${slideAnimationDelay}s`};

  ${(props) =>
    props.isActiveSlide &&
    css`
      opacity: 1;
      top: -25px;
    `}
`;

const Text = styled(ParagraphLarge)`
  text-align: center;
  white-space: pre-line;
  max-width: 430px;
  top: 0;
  opacity: 0;
  position: relative;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${(props) => `${slideAnimationDelay + 0.1}s`};

  ${(props) =>
    props.isActiveSlide &&
    css`
      opacity: 1;
      top: -25px;
    `}
`;

export const RiskProfileSlide = (props) => {
  const { isActiveSlide } = props;
  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );

  const loading = useResource(['INVESTMENT_PROFILES/INDEX']);

  if (loading) return <CenteredActivityIndicator />;

  if (!investmentStrategyRiskAndLockIn) {
    return (
      <ErrorFallbackPresentation
        error={
          new Error(
            'Missing risk and lock-in data. `investmentStrategyRiskAndLockIn` is null'
          )
        }
      />
    );
  }

  const { risk, lockIn } = investmentStrategyRiskAndLockIn;

  return (
    <Base>
      <Item>
        <div style={{ margin: 'auto auto 0' }}>
          <SpeedometerChart
            value={isActiveSlide ? risk : 0}
            pieCount={3}
            padAngle={3}
            cornerRadius={4}
            startAngle={-90}
            endAngle={90}
            width={200}
            height={100}
            arrowPosition="top"
            colorScale={generateSpeedometerRiskChartColors(risk)}
            style={{ margin: '0 auto' }}
            animationDelay={slideAnimationDelay + 0.4}
          />
          <Label>{riskAndLockinValueToText(risk)}</Label>
        </div>
        <Title isActiveSlide={isActiveSlide}>
          {t('presentation:investmentStrategy:riskProfile:riskLevel:title')}
        </Title>
        <Text isActiveSlide={isActiveSlide}>
          {t('presentation:investmentStrategy:riskProfile:riskLevel:body')}
        </Text>
      </Item>

      <Item>
        <div style={{ margin: 'auto auto 0' }}>
          <SpeedometerChart
            value={isActiveSlide ? lockIn : 0}
            pieCount={3}
            padAngle={3}
            cornerRadius={4}
            startAngle={-90}
            endAngle={90}
            width={200}
            height={100}
            arrowPosition="top"
            colorScale={generateSpeedometerRiskChartColors(lockIn)}
            style={{ margin: '0 auto' }}
            animationDelay={slideAnimationDelay + 0.4}
          />
          <Label>{riskAndLockinValueToText(lockIn)}</Label>
        </div>
        <Title isActiveSlide={isActiveSlide}>
          {t('presentation:investmentStrategy:riskProfile:lockInLevel:title')}
        </Title>
        <Text isActiveSlide={isActiveSlide}>
          {t('presentation:investmentStrategy:riskProfile:lockInLevel:body')}
        </Text>
      </Item>
    </Base>
  );
};
