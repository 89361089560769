import React from 'react';
import styled from 'styled-components';

import { SPACING_16, SPACING_32, SPACING_8 } from '../../constants/spacing';
import { BaseSection } from '../common/BaseSection';
import { H2 } from '../texts';
import { MainGrid } from '../layout';
import { MultipleSwitch } from '../formElements';
import { accent, backgroundWhite } from '../../constants/colors';

const Container = styled(BaseSection)`
  background-color: transparent;
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-top: ${SPACING_32};
  margin-bottom: ${SPACING_16};
`;

const SectionContent = styled(MainGrid)`
  width: 100%;
  max-width: 100%;
  padding: 0;
  grid-column: span 12;
  grid-gap: ${SPACING_8};
`;

export const InvestmentStrategySection = (props) => {
  const {
    title,
    options = [],
    onChange = () => {},
    children,
    grid = true,
    headerStyle,
    ...rest
  } = props;

  return (
    <Container columns={12} {...rest}>
      <Header style={headerStyle}>
        <H2>{title}</H2>
        {options && (
          <MultipleSwitch
            onChange={(value) => {
              onChange(value);
            }}
            options={options}
            activeBackgroundColor={accent.ocean230}
            activeColor={accent.ocean490}
            backgroundColor={backgroundWhite}
          />
        )}
      </Header>
      <SectionContent style={!grid ? { display: 'block' } : {}}>
        {children}
      </SectionContent>
    </Container>
  );
};
