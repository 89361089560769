import React from 'react';
import styled from 'styled-components';

import { getAdvisorRoleName } from '@formue-app/core';

import { SPACING_16, SPACING_4, SPACING_8 } from '../../constants/spacing';

import { Paragraph, ParagraphXSmall, ParagraphSmall } from '../texts';

import { SpriteIcon } from './SpriteIcon';
import { ProfileIcon } from './ProfileIcon';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const TextWrapper = styled.div``;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_4};
`;

const LinkedIcons = styled.a`
  display: flex;
  text-decoration: none;
  gap: ${SPACING_8};

  &:hover {
    text-decoration: underline;
  }
`;

export const ContactCardSimple = (props) => {
  const { contact, columns, ...rest } = props;

  if (!contact) {
    return null;
  }

  const { name, profileImageUrl, photoUrl, role, phone, email } = contact;

  const url = profileImageUrl || photoUrl;

  return (
    <Content {...rest}>
      <ProfileIcon name={name} size={40} url={url} image={url} />
      <TextWrapper>
        {name && <Paragraph>{name}</Paragraph>}
        {role && <ParagraphXSmall>{getAdvisorRoleName(role)}</ParagraphXSmall>}
      </TextWrapper>

      <ContactInfo>
        <LinkedIcons href={`mailto:${email}`}>
          <SpriteIcon id="Contact Mail" size={SPACING_16} />
          <ParagraphSmall>{email}</ParagraphSmall>
        </LinkedIcons>

        <LinkedIcons href={`tlf:${phone}`}>
          <SpriteIcon id="Contact Phone" size={SPACING_16} />
          <ParagraphSmall>{phone}</ParagraphSmall>
        </LinkedIcons>
      </ContactInfo>
    </Content>
  );
};
