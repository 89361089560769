import * as selectors from './selectors';
export { selectors };

import { actions as entityActions } from '../../entities';

const wealthPlanEntities = [
  'goals',
  'wealthBalance',
  'family',
  'organization',
  'milestones',
  'network',
  'investmentPreferences',
  'investmentStrategySimulations',
  'investmentProfile',
];

/**
 * Extract a list of INDEX_REQUEST_SUCCESS action types for the entities
 * that make up the wealth plan section of the app. We can then use this
 * list to check if any of those actions return any data.
 */
const wealthPlanSuccessActions = Array
  .from(
    Object.keys(entityActions),
    key => wealthPlanEntities.includes(key) ? entityActions[key] : null
  )
  .filter(Boolean)
  .map(actions => actions.indexRequestSuccess().type);

const defaultState = {
  visible: false,
};

export const reducer = (state = {}, action) => {
  // If it's already marked as visible just return state, job done
  if (state.visible) { return state; }

  const { type, payload } = action;

  // Determine if the event type is one of the wealth plan related ones
  const match = wealthPlanSuccessActions.includes(type);
  if (match) {
    /**
     * Check if there is data in the payload. If there is we change the state
     * so that wealth plan is visible. If _none_ of the wealth plan API's
     * return any data we don't want to show the section at all.
     *
     * The result looks like this when it's empty:
     *   {}
     *
     * and with content:
     *   { goals: [1, 2, 3] }
     *
     * where goals is the entity name, so to termine if we received any content
     * we need just need to check if the object has any keys or not.
     */
    const result = payload.result;
    if (result && Object.keys(result).length > 0) {
      return {
        ...state,
        visible: true,
      }
    }
  }

  return {
    ...state,
    ...defaultState,
  };
};
