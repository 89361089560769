import React from 'react';

import { t } from '@formue-app/core';
import { H4, ParagraphSmall } from '../../texts';
import styled from 'styled-components';

import { SPACING_16, SPACING_2, SPACING_24 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { SpriteIcon } from '../../common/SpriteIcon';

const EmptyWrapper = styled.div`
  background: ${accent.sand250};
  padding: ${SPACING_16};
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: ${SPACING_16};
`;

export const EmptyPending = (props) => {
  const { sections } = props;
  const tasksOngoingLength = sections.find((item) => item.title === 'ongoing')
    .data.length;
  const status = tasksOngoingLength > 0 ? 'completed' : 'notCompleted';

  return (
    <EmptyWrapper>
      <SpriteIcon id="Philanthropy" size={SPACING_24} />
      <div>
        <H4 style={{ marginBottom: SPACING_2 }}>
          {t(`todo:noTasks:${status}:title`)}
        </H4>
        <ParagraphSmall>{t(`todo:noTasks:${status}:text`)}</ParagraphSmall>
      </div>
    </EmptyWrapper>
  );
};
