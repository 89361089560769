import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { VictoryPie } from 'victory';

import { accent } from '../../constants/colors';

import { ReactComponent as Pointer } from '../../assets/icons/speedometer-pointer.svg';
import { easeInOut, longAnimationTime } from '../../constants/animation';

const StyledChart = styled.div`
  position: relative;
  overflow: hidden;
`;

const ChartInner = styled.div`
  position: relative;
`;

const pointerAnimation = (props) => keyframes`
  0% {
    rotate: ${props.arrowStartAngle}deg;
    opacity: 0;
  }
  100% {
    rotate: ${props.arrowStartAngle + props.arrowEndAngle}deg;
    opacity: 1;
  }
`;

const PointerWrapper = styled.div`
  width: ${(props) => props.radius}px;
  height: ${(props) => props.heightscaled}px;
  position: absolute;
  left: 50%;
  top: ${(props) => props.radius}px;
  translate: calc(-100% + ${(props) => props.heightscaled}px / 2) -50%;
  transform-origin: calc(100% - ${(props) => props.heightscaled}px / 2) center;

  rotate: ${(props) => props.arrowStartAngle}deg;
  opacity: 0;
  animation: ${(props) => pointerAnimation(props)} ${longAnimationTime}
    ${easeInOut};
  animation-delay: ${(props) =>
    props.animationDelay ? props.animationDelay : 0.5}s;
  animation-fill-mode: forwards;
`;

const StyledPointer = styled(Pointer)`
  position: absolute;
  left: ${(props) => (props.arrowposition === 'top' ? 0 : props.radius)}px;
  translate: ${(props) => (props.arrowposition === 'top' ? 0 : -100)}%;
  top: 0;
  vertical-align: top;

  path {
    fill: ${(props) => props.color};
  }
`;

export const SpeedometerChart = ({
  value = 3,
  width = 250,
  height = width,
  borderWidth = 27,
  padAngle = 1,
  cornerRadius = 0,
  startAngle = -113,
  endAngle = 113,
  pieCount = 5,
  arrowPosition,
  colorScale,
  pointerColor = accent.ocean4,
  animationDelay,
  style,
  ...rest
}) => {
  const [chartWidth, setChartWidth] = useState(width - 50);
  const data = new Array(pieCount).fill(1);
  const radius = chartWidth / 2;
  const pieSize = (endAngle - startAngle) / data.length;
  const arrowStartAngle = startAngle + 90;
  const arrowEndAngle = value * pieSize + pieSize / 2;

  useEffect(() => {
    setTimeout(() => {
      setChartWidth(width);
    }, 0.5);
  }, [width]);

  return (
    <StyledChart
      style={{ width: width, height: height, ...style }}
      pointerEvents="none"
      {...rest}
    >
      <ChartInner
        style={{
          height: Math.max(height, width),
          width: Math.max(height, width),
        }}
      >
        <VictoryPie
          data={data}
          padAngle={padAngle}
          startAngle={startAngle}
          endAngle={endAngle}
          colorScale={colorScale}
          innerRadius={radius - borderWidth}
          cornerRadius={cornerRadius}
          radius={radius}
          height={Math.max(height, width)}
          width={Math.max(height, width)}
          labels={[]}
          animate={{
            animationWhitelist: [
              'endAngle',
              'data',
              'style',
              'colorScale',
              'innerRadius',
              'radius',
            ],
            duration: 200,
          }}
        />
        <PointerWrapper
          arrowStartAngle={arrowStartAngle}
          arrowEndAngle={arrowEndAngle}
          radius={radius}
          heightscaled={Math.floor(18 * (radius / 100))}
          animationDelay={animationDelay}
        >
          <StyledPointer
            width={Math.floor(40 * (radius / 100))}
            height={Math.floor(18 * (radius / 100))}
            arrowposition={arrowPosition}
            radius={radius}
            color={pointerColor}
          />
        </PointerWrapper>
      </ChartInner>
    </StyledChart>
  );
};
