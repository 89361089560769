import { takeLatest, put, call } from 'redux-saga/effects';
import { actions } from './actions';
import { AsyncStorage } from '../../../services/AsyncStorage';
import { i18n } from '../../../services';
import { updateLanguageAttribute } from './utils';

function* setLanguage() {
  yield takeLatest(actions.setLanguage, function*({ payload }) {
    AsyncStorage.setItem('language', payload);
    i18n.changeLanguage(payload);
    updateLanguageAttribute(payload);
  });
}

function* setLanguageFromStore() {
  yield takeLatest('APP/INIT', function*() {
    let language = yield call(AsyncStorage.getItem, 'language');

    // We used the language key 'se' before
    if (language === 'se') {
      yield call(AsyncStorage.setItem, 'language', 'sv');
      language = 'sv';
    }

    if (language) {
      yield put(actions.setLanguage(language));
    }
  });
}

export const sagas = [setLanguage, setLanguageFromStore];
