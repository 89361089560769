import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { operationalMessages }  from '../../../api';
import { actions as uiActions } from '../../ui/readStatus';
import { resourceReducer } from '../../utils/resourceReducer';

export const [actions, reducer, ...resourceSagas] = resourceReducer('operational-messages');

function* setOperationalMessages() {
  yield takeEvery(actions.indexRequestBegin, function*({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(operationalMessages.get, args);
      const normalized = normalize(result);

      yield put(uiActions.hydrateReadItems('operationalMessages'));
      yield put(actions.indexRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error })
    }
  });
}

export const sagas = [setOperationalMessages, ...resourceSagas];
