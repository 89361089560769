import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { SPACING_16, SPACING_8 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { GridElement } from '../../layout';
import { H2, Paragraph, ParagraphSmall } from '../../texts';

const MetadataWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: flex-end;
  gap: ${SPACING_16};
`;

const Metadata = styled.div`
  display: flex;
  gap: ${SPACING_16};
`;

const Line = styled.div`
  width: 1px;
  height: 18px;
  background: ${accent.sand1};
`;

export const SlideHeader = (props) => {
  const { title, subTitle, items = [] } = props;

  return (
    <GridElement columns={12} style={{ height: 'fit-content' }}>
      <div style={{ display: 'flex', marginBottom: SPACING_8 }}>
        <H2>{t(title)}</H2>
        <MetadataWrapper>
          {items.map((item, index) => (
            <React.Fragment key={`meta-item-${index}`}>
              <Metadata>
                <ParagraphSmall>{item.title}</ParagraphSmall>
                <ParagraphSmall style={{ fontWeight: '600' }}>
                  {item.value}
                </ParagraphSmall>
              </Metadata>
              {index !== items.length - 1 ? <Line /> : null}
            </React.Fragment>
          ))}
        </MetadataWrapper>
      </div>
      {subTitle ? (
        <Paragraph style={{ maxWidth: '50%' }}>{t(subTitle)}</Paragraph>
      ) : null}
    </GridElement>
  );
};
