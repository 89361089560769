import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions as navigationActions } from '../../store/ui/navigation';

import { tabletWidth } from '../../constants/media';

export const ScreenResizeTrigger = () => {
  const dispatch = useDispatch();

  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  const isMobile = size[0] <= tabletWidth;

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      dispatch(navigationActions.setMainMenuOpen(false));
    }
  }, [isMobile, dispatch]);

  return null;
};
