import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';

const defaultState = {
  mainMenuOpen: true,
  popoverOpen: false,
  popoverType: null,
  podcastPopoverOpen: false,
};

export const reducer = handleActions(
  {
    [actions.setMainMenuOpen]: (state, { payload }) => {
      return {
        ...state,
        mainMenuOpen: payload,
      };
    },
    [actions.toggleMainMenu]: (state) => {
      return {
        ...state,
        mainMenuOpen: !state.mainMenuOpen,
      };
    },
    [actions.togglePopover]: (state, { payload }) => {
      return {
        ...state,
        popoverOpen: !state.popoverOpen,
        popoverType: state.popoverOpen ? null : payload,
      };
    },
    [actions.setPodcastPopoverOpen]: (state, { payload }) => {
      return {
        ...state,
        podcastPopoverOpen: payload,
      };
    },
  },
  defaultState
);
