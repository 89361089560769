import { apiFetch } from './utils/api';
import { StandardFilterSerializer } from './serializers/standardFilters';

export const standardFilter = {
  update: async (data) => {
    const payload = StandardFilterSerializer.serialize(data);

    const options = {
      method: 'PUT',
      body: payload,
    };

    const url = '/v2/qlik/standardfilter';

    return apiFetch(url, options);
  },

  delete: async () => {
    const options = {
      method: 'DELETE',
    };

    const url = '/v2/qlik/standardfilter';

    return apiFetch(url, options);
  },
};
