import { createSelector } from 'reselect';

import { familySelector, organizationSelector } from './familyAndOwnership';
import { resourceSelectors } from '../../utils/resourceSelectors';

// OLD DOCUMENTS REMOVE ONCE MOBILE USES THE NEW STUFF
export const documentsByIdOld = (state) => state.old.documents.documentsList;
export const documentsAllIdsOld = (state) =>
  Object.keys(state.old.documents.documentsList);

export const documentsSelector = createSelector(
  documentsAllIdsOld,
  documentsByIdOld,
  (allIds, byId) => {
    return allIds.map((id) => {
      const entity = byId[id];
      return entity;
    });
  }
);
// END OLD DOCUMENTS STUFF

export const [
  documentsAllIdsSelector,
  documentsByIdSelector,
  allDocumentsSelector,
] = resourceSelectors('documents');

const filterOnSsids = (doc, ssids) => {
  const { id } = doc;
  // extract the owner of the document by splitting the id, the id is
  // "guaranteed" by the API to always be in the pattern "ssid-documentid".
  const ssid = id.split('-')[0];
  return ssids.includes(ssid);
};

/**
 * Fetch all documents of type expert input that belongs to members
 * off the household. This is used so we can create "expert-inputs"
 * in the wealth plan section only based of documents, not needing
 * to get an actual "expert input" from the expert inpu API. This
 * used at least for the the `Investmest strategy` where we find
 * a document with that type and add it to the list of expert inputs.
 *
 * We need to filter documents that includes the name "expert input"
 * and that belongs to any of the members or organizations in the
 * household
 *
 * This isn't really perfect, we shouldn't show documents for all
 * members of the family or all organizations that is registered as part
 * of the wealth plan, but only those that are "primary members" or organizations
 * that's part of that group in SF. But we don't have this information yet in
 * the client, so this combined with the fact that we only get documents
 * that we have a relationship with _should_ in most cases be the correct set
 * of expert advice documents.
 */
export const expertAdviceDocumentsSelector = createSelector(
  allDocumentsSelector,
  familySelector,
  organizationSelector,
  (documents, familyMembers, organizations) => {
    const householdSsids = [...familyMembers, ...organizations].map(
      (m) => m.id
    );
    return documents
      .filter((doc) => doc.contentType.includes('Expert Input'))
      .filter((doc) => filterOnSsids(doc, householdSsids));
  }
);
