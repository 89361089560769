import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useCountUp } from 'react-countup';

import { t, formatMoney, selectors } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
} from '../../../constants/spacing';

import { H1 } from '../../texts';

import { slideAnimationDelay } from '../../../constants/animation';

import { CategoryItem } from '../slideComponents/wealth/CategoryItem';
import { Question } from '../slideComponents/Question';
import { useQueryState } from 'react-router-use-location-state';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const TotalWealth = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -30px;
`;

const {
  entities: {
    wealthBalance: {
      totalWealthBalanceSelector,
      createWealthBalanceByCategorySelector,
    },
  },
} = selectors;

export const WealthSlide = (props) => {
  const { isActiveSlide, highlightColor } = props;

  const [exportMode] = useQueryState('exportMode', false);
  const totalWealth = useSelector(totalWealthBalanceSelector);

  const safety = useSelector(createWealthBalanceByCategorySelector('Safety'));
  const preserveAndGrow = useSelector(
    createWealthBalanceByCategorySelector('Preserve & Grow')
  );
  const create = useSelector(createWealthBalanceByCategorySelector('Create'));

  const maxValue = Math.max(
    safety.amount,
    preserveAndGrow.amount,
    create.amount
  );

  const dataset = {
    safety,
    preserveAndGrow,
    create,
  };

  const wealthCounter = useRef(null);

  const { start } = useCountUp({
    ref: wealthCounter,
    start: 0,
    end: totalWealth,
    duration: exportMode ? 0 : 3,
    useEasing: true,
    startOnMount: false,
    delay: exportMode ? 0 : slideAnimationDelay,
    formattingFn: formatMoney,
  });

  useEffect(() => {
    if (totalWealth > 0 && isActiveSlide) {
      start();
    }
  }, [totalWealth, start, isActiveSlide]);

  return (
    <Wrapper>
      <TotalWealth>
        <H1
          style={{ color: accent[highlightColor], marginTop: 5 }}
          ref={wealthCounter}
        >
          0
        </H1>

        <Question
          isActiveSlide={isActiveSlide}
          bubbleColor={accent.velvet1}
          textColor={accent.velvet250}
          questionText={t('presentation:annualReview:wealthBalance:question')}
          style={{ marginBottom: SPACING_48 }}
          customAnimationDelay={slideAnimationDelay * 3}
        />
      </TotalWealth>

      <CategoryWrapper>
        <CategoryItem
          category="safety"
          width={185}
          maxValue={maxValue}
          dataset={dataset}
          isActiveSlide={isActiveSlide}
        />
        <CategoryItem
          category="preserveAndGrow"
          width={185}
          maxValue={maxValue}
          dataset={dataset}
          isActiveSlide={isActiveSlide}
        />
        <CategoryItem
          category="create"
          width={185}
          maxValue={maxValue}
          dataset={dataset}
          isActiveSlide={isActiveSlide}
        />
      </CategoryWrapper>
    </Wrapper>
  );
};
