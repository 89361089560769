import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t, useStartegyOptions } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { StrategyList } from '../strategy/PortfolioStrategyList';
import { Paragraph } from '../../texts';
import { printCondition } from '../../../constants/media';

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    border-top: none;
  }
`;

const {
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

export const PeriodicStrategy = () => {
  const activeStrategyId = useSelector(activeStrategySelector);
  const allStrategies = useStartegyOptions();

  let [activeStrategy, setActiveStrategy] = useState(null);

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [allStrategies, activeStrategyId]);

  return (
    <StyledBaseSection columns={4}>
      {activeStrategy ? (
        <StrategyList strategy={activeStrategy.strategy} />
      ) : (
        <Paragraph>
          {t('portfolio:periodicReporting:strategy:noStrategyFound')}
        </Paragraph>
      )}
    </StyledBaseSection>
  );
};
