import * as impersonation from './impersonation';
import * as todo from './todo';
import * as portfolio from './portfolio';
import * as presentation from './presentation';
import * as user from './user';
import * as wealthPlan from './wealthPlan';

export const selectors = {
  impersonation: impersonation.selectors,
  todo: todo.selectors,
  portfolio: portfolio.selectors,
  presentation: presentation.selectors,
  user: user.selectors,
  wealthPlan: wealthPlan.selectors,
};
