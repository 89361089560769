import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, getAdvisorRoleName, useResource } from '@formue-app/core';

import { SPACING_32, SPACING_24, SPACING_16 } from '../../constants/spacing';
import { textLightest, accent } from '../../constants/colors';
import { ProfileIcon } from '../common/ProfileIcon';

import { ParagraphSmall, H3 } from '../texts';

import { MainMenuList } from '../navigation/MainMenu';
import { useRoutes } from '../../config/routes';
import { Link } from 'react-router-dom';

const {
  entities: {
    accountTeamMembers: { mainAdvisorSelector },
  },
} = selectors;

const Container = styled.section`
  background: ${accent.ocean1};
  height: 100%;
  margin-top: ${SPACING_32};
`;

const MainAdvisorWrapper = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${SPACING_24};
  text-decoration: none;

  &:hover {
    background: ${accent.ocean190};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Role = styled(H3)`
  color: ${accent.ocean250};
  line-height: 19px;
`;

const Heading = styled(ParagraphSmall)`
  color: ${textLightest};
`;

const StyledProfileIcon = styled(ProfileIcon)`
  background-color: ${accent.ocean250};
  margin-right: ${SPACING_16};

  p {
    color: ${accent.ocean1};
  }
`;

export const AdvisorsMainMenu = (props) => {
  const routes = useRoutes();
  const advisorRoutes = routes.filter((item) => item.showInAdvisorMenu)[0]
    .routes;

  useResource(['ACCOUNT_TEAM_MEMBERS/INDEX']);

  const contact = useSelector(mainAdvisorSelector);
  const { mainMenuOpen } = useSelector((state) => state.ui.navigation);

  return (
    <Container {...props}>
      {contact ? (
        <MainAdvisorWrapper to="/account-team">
          {mainMenuOpen ? (
            <StyledProfileIcon
              name={contact.name}
              url={contact.url}
              size={40}
            />
          ) : (
            <StyledProfileIcon
              name={contact.name}
              url={contact.url}
              size={32}
            />
          )}

          {mainMenuOpen && (
            <TextWrapper>
              <Role>{getAdvisorRoleName(contact.role)}</Role>
              <Heading>{contact.name}</Heading>
            </TextWrapper>
          )}
        </MainAdvisorWrapper>
      ) : null}

      <MainMenuList
        routes={advisorRoutes}
        mainMenuOpen={mainMenuOpen}
        hoverColor={accent.ocean190}
        activeColor={accent.ocean190}
      />
    </Container>
  );
};
