import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  personalGreetingAllIds,
  personalGreetingById,
  allPersonalGreetingSelector,
] = resourceSelectors('personalGreeting');

export const personalGreetingSelector = createSelector(
  allPersonalGreetingSelector,
  (allGreetings) => {
    return allGreetings[0];
  }
);
