import { apiFetch } from './utils/api';

export const investmentStrategyIndexChanges = {
  /**
   * @desc Get a investment strategy's index changes
   *
   * @return json - data or error message
   */
  get: async (args = {}) => {
    const options = {
      method: 'GET',
    };

    if (!args.strategyId) {
      throw new Error('Strategy ID required for this API endpoint');
    }

    const url = `/v3/investment-strategies/${args.strategyId}/index-changes`;

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
