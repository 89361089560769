import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_32,
} from '../../../constants/spacing';

import { MainGrid } from '../../layout';
import { SectionWithTitle } from '../../common/SectionWithTitle';
import { Distribution } from '../../wealthPlan/wealthBalance/Distribution';
import { Finance } from '../../wealthPlan/wealthBalance/Finance';
import { Debt } from '../../wealthPlan/wealthBalance/Debt';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

export const WealthDetails = (props) => {
  const { isActiveSlide } = props;

  if (!isActiveSlide) {
    return null;
  }

  return (
    <Wrapper>
      <SectionWithTitle
        title={t('wealthBalance:distribution')}
        style={{ marginBottom: 0 }}
      >
        <Distribution />
      </SectionWithTitle>
      <SectionWithTitle
        columns={6}
        title={t('wealthBalance:incomeAndWithdrawalNeeds')}
        style={{ marginTop: SPACING_32 }}
      >
        <Finance />
      </SectionWithTitle>
      <SectionWithTitle
        columns={6}
        title={t('wealthBalance:debt')}
        style={{ marginTop: SPACING_32 }}
      >
        <Debt />
      </SectionWithTitle>
    </Wrapper>
  );
};
