export const mock = {
  byId: {
    'random-id-1': {
      id: 'random-id-1',
      title: 'Fullmakt',
      category: 'Wealth Management',
      subCategory: 'Power of attorney',
      accountName: 'Nordmann Invest AS',
      document: {
        filename: 'Fullmakt',
        url: null,
        signatureUrl: 'http://example.com/document-url',
      },
      status: 'open',
      createdAt: '2020-03-26T14:21:38.000Z',
      completedAt: null,
      signees: [
        {
          name: 'Darth Vader',
          status: 'new',
          lastModifiedAt: '2020-04-22T02:00:30.000Z',
          dueAt: '2020-09-22T00:00:00.000Z',
        },
      ],
      tradeOrders: {},
    },
    'random-id-2': {
      id: 'random-id-2',
      title: 'Avtale',
      category: 'Wealth Management',
      subCategory: 'Agreement',
      accountName: 'Nordmann Invest AS',
      document: {
        filename: 'Avtale',
        url: null,
        signatureUrl: 'http://example.com/document-url',
      },
      status: 'open',
      createdAt: '2020-03-26T14:10:17.000Z',
      completedAt: null,
      signees: [
        {
          name: 'Darth Vader',
          status: 'new',
          lastModifiedAt: '2020-04-22T02:00:30.000Z',
          dueAt: '2020-09-22T00:00:00.000Z',
        },
      ],
      tradeOrders: {},
    },
    'random-id-3': {
      id: 'random-id-3',
      title: 'Test',
      category: 'Wealth Management',
      subCategory: 'Agreement',
      accountname: 'Darth Vader',
      document: {
        filename: 'Test',
        url: null,
        signatureUrl: 'http://example.com/document-url',
      },
      status: 'open',
      createdAt: '2020-03-26T14:08:14.000Z',
      completedAt: null,
      signees: [
        {
          name: 'Darth Vader',
          status: 'new',
          lastModifiedAt: '2020-04-22T02:00:29.000Z',
          dueAt: '2020-09-22T00:00:00.000Z',
        },
      ],
      tradeOrders: {},
    },
    'random-id-4': {
      id: 'random-id-4',
      title: 'Styrets beretning',
      category: 'Business Services',
      subCategory: 'The board of directors report',
      accountname: 'Darth Vader',
      document: {
        filename: 'Styrets beretning',
        url: 'http://example.com/document-url',
        signatureUrl: 'http://example.com/document-url',
      },
      status: 'completed',
      createdAt: '2020-03-26T14:21:09.000Z',
      completedAt: '2020-04-03T10:01:59.000Z',
      signees: [
        {
          name: 'Darth Vader',
          status: 'done',
          lastModifiedAt: '2020-04-03T10:01:51.000Z',
          dueAt: '2020-09-22T00:00:00.000Z',
        },
      ],
      tradeOrders: {},
    },
    'random-id-5': {
      id: 'random-id-5',
      title: 'Regnskap',
      category: 'Business Services',
      subCategory: 'Financial statements',
      accountname: 'Darth Vader',
      document: {
        filename: 'Regnskap',
        url: 'http://example.com/document-url',
        signatureUrl: 'http://example.com/document-url',
      },
      status: 'completed',
      createdAt: '2020-03-26T14:20:32.000Z',
      completedAt: '2020-03-27T16:56:43.000Z',
      signees: [
        {
          name: 'Darth Vader',
          status: 'done',
          lastModifiedAt: '2020-03-27T16:56:31.000Z',
          dueAt: '2020-09-22T00:00:00.000Z',
        },
      ],
      tradeOrders: {},
    },
  },
  allIds: [
    'random-id-1',
    'random-id-2',
    'random-id-3',
    'random-id-4',
    'random-id-5',
  ],
};
