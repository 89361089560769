import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { investmentStrategyCosts } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer(
  'investment-strategy-costs'
);

// Create the sagas for this entity
function* readInvestmentStrategyCosts() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(investmentStrategyCosts.get, args);
      const { meta } = result;
      // Define meta by strategyID to keep track of seperate metadata for each strategyId
      const strategyMeta = {};
      strategyMeta[args.strategyId] = meta;

      const normalized = normalize(result);
      yield put(
        actions.indexRequestSuccess({ ...normalized, meta: strategyMeta, args })
      );
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: { error, args } });
    }
  });
}

export const sagas = [readInvestmentStrategyCosts, ...resourceSagas];
