import React from 'react';
import styled from 'styled-components';

import DateIcon from '../../assets/icons/form-elements/date-icon.svg';

import { StyledInputBase } from './Input';
import { FilledSelectStyle, OutlineSelectStyle } from './Select';
import { SPACING_8 } from '../../constants/spacing';
import { Label } from './Label';

const StyledInput = styled.input`
  ${StyledInputBase}
  -webkit-align-items: center;
  display: -webkit-inline-flex;
  overflow: hidden;
  cursor: pointer;

  &::-webkit-datetime-edit-text {
    padding: 0;
  }

  &::-webkit-calendar-picker-indicator {
    margin: 0;
    padding: 0;
    background-image: url(${DateIcon});
    outline: none;
  }

  ${(props) => props.inputstyle === 'filled' && FilledSelectStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineSelectStyle}
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

export const DatePicker = (props) => {
  const {
    value,
    min,
    max,
    placeholder,
    inputstyle,
    popoutLabel,
    label,
    ...rest
  } = props;

  return (
    <InputWrapper>
      {popoutLabel && label && <Label>{label}</Label>}
      <StyledInput
        type="date"
        value={value}
        min={min}
        max={max}
        placeholder={placeholder}
        inputstyle={inputstyle}
        {...rest}
      />
    </InputWrapper>
  );
};
