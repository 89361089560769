import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors, t, useResource } from '@formue-app/core';

import { BaseSection } from '../../components/common/BaseSection';
import { GridElement } from '../../components/layout/GridElement';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { PlacementGraph } from '../../components/wealthPlan/InvestmentSimulation/PlacementGraph';
import { ExpectedReturn } from '../../components/wealthPlan/InvestmentSimulation/ExpectedReturn';
import { InvestmentRisk } from '../../components/wealthPlan/InvestmentSimulation/InvestmentRisk';
import { MarketDevelopment } from '../../components/wealthPlan/InvestmentSimulation/MarketDevelopment';
import { H2 } from '../../components/texts';
import { SPACING_16, SPACING_24, SPACING_88 } from '../../constants/spacing';
import { tabletCondition } from '../../constants/media';
import { NoDataMessage } from '../../components/wealthPlan/NoDataMessage';

const {
  entities: {
    investmentStrategySimulations: investmentStrategySimulationsActions,
  },
} = actions;

const {
  entities: {
    investmentStrategySimulations: {
      investmentSimulatorSelector,
      investmentSimulatorHasContent,
    },
  },
} = selectors;

const StyledGridElement = styled(GridElement)`
  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

export const InvestmentSimulatorPage = () => {
  const loading = useResource(['INVESTMENT_STRATEGY_SIMULATIONS/INDEX']);
  const dispatch = useDispatch();

  const initialAlternativeGrowthLevel = 3;
  const initialRiskLevel = 2;

  const investmentStrategySimulations = useSelector(
    investmentSimulatorSelector
  );
  const hasContent = useSelector(investmentSimulatorHasContent);

  const [localLoading, setLocalLoading] = useState(loading);
  const [activeSimulation, setActiveSimulation] = useState(null);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(initialRiskLevel);
  const [selectedAlternativeGrowthLevel, setSelectedAlternativeGrowthLevel] =
    useState(initialAlternativeGrowthLevel);

  const investmentRisksValues = [
    {
      value: 1,
      label: t('wealthPlan:investmentSimulator:investmentRisk:low'),
    },
    {
      value: 2,
      label: t('wealthPlan:investmentSimulator:investmentRisk:medium'),
    },
    {
      value: 3,
      label: t('wealthPlan:investmentSimulator:investmentRisk:high'),
    },
  ];

  const alternativeGrowthLevelValues = [
    {
      value: 1,
      label: t('wealthPlan:investmentSimulator:investmentRisk:noLock'),
    },
    {
      value: 2,
      label: t('wealthPlan:investmentSimulator:investmentRisk:low'),
    },
    {
      value: 3,
      label: t('wealthPlan:investmentSimulator:investmentRisk:medium'),
    },
    {
      value: 4,
      label: t('wealthPlan:investmentSimulator:investmentRisk:high'),
    },
  ];

  useEffect(() => {
    dispatch(
      investmentStrategySimulationsActions.indexRequestBegin({
        alternativeGrowthLevel: selectedAlternativeGrowthLevel,
        riskLevel: selectedRiskLevel,
      })
    );
  }, [dispatch, selectedAlternativeGrowthLevel, selectedRiskLevel]);

  useEffect(() => {
    // Find simulation from store with the correct risk and lock in levels
    // And set it as the active simulation
    const simulation = investmentStrategySimulations.find(
      (item) =>
        item.alternativeGrowthLevel === selectedAlternativeGrowthLevel &&
        item.riskLevel === selectedRiskLevel
    );

    if (simulation) {
      setActiveSimulation(simulation);
      // When we find a strategy we set local loading back to false
      setLocalLoading(false);
    }
  }, [
    investmentStrategySimulations,
    selectedAlternativeGrowthLevel,
    selectedRiskLevel,
  ]);

  useEffect(() => {
    // Set local loading to true everytime one of the alternatives changes
    setLocalLoading(true);
  }, [selectedAlternativeGrowthLevel, selectedRiskLevel]);

  const today = new Date();

  const [firstDecade, setFirstDecade] = useState(1970);
  useEffect(() => {
    if (activeSimulation) {
      const firstDecadeFromIndexData =
        Math.floor(
          new Date(activeSimulation.index[0].date).getFullYear() / 10
        ) * 10;
      setFirstDecade(firstDecadeFromIndexData);
    }
  }, [activeSimulation]);

  return (
    <>
      {hasContent ? (
        <>
          <InvestmentRisk
            selectedRiskLevel={selectedRiskLevel}
            setSelectedRiskLevel={setSelectedRiskLevel}
            investmentRisksValues={investmentRisksValues}
            selectedAlternativeGrowthLevel={selectedAlternativeGrowthLevel}
            setSelectedAlternativeGrowthLevel={
              setSelectedAlternativeGrowthLevel
            }
            alternativeGrowthLevelValues={alternativeGrowthLevelValues}
            loading={localLoading}
          />
          {activeSimulation ? (
            <>
              <PlacementGraph strategy={activeSimulation.strategy} />
              <StyledGridElement columns={6}>
                <H2 style={{ marginBottom: SPACING_16, marginTop: SPACING_24 }}>
                  {t(
                    'wealthPlan:investmentSimulator:historicPerformance:title',
                    {
                      startYear: firstDecade,
                      endYear: today.getFullYear(),
                    }
                  )}
                </H2>
                <BaseSection>
                  <ExpectedReturn
                    annualHistoricPerformance={
                      activeSimulation.annualHistoricPerformance
                    }
                    annualHistoricStandardDeviation={
                      activeSimulation.annualHistoricStandardDeviation
                    }
                  />
                  <MarketDevelopment
                    startYear={firstDecade}
                    index={activeSimulation.index}
                    historicalEvents={activeSimulation.historicalEvents}
                  />
                </BaseSection>
              </StyledGridElement>
            </>
          ) : null}
        </>
      ) : loading ? (
        <GridElement columns={12}>
          <CenteredActivityIndicator
            size={30}
            style={{ marginTop: SPACING_88 }}
          />
        </GridElement>
      ) : (
        <NoDataMessage columns={12} />
      )}
    </>
  );
};
