import React from 'react';
import styled from 'styled-components';

import { BaseSection } from '../common/BaseSection';
import { ImplementationPlanChart } from './charts/ImplementationPlanChart';
import {
  SPACING_16,
  SPACING_24,
  SPACING_48,
  SPACING_64,
} from '../../constants/spacing';
import { ImplementationPlanTable } from '../advisorsCorner/strategySimulator/ImplementationPlanTable';
import { accent } from '../../constants/colors';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: ${SPACING_24};
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: ${SPACING_48};
  padding-top: ${SPACING_64};
`;

const StyledImplementationPlanTable = styled(ImplementationPlanTable)`
  margin-top: 0;
  width: 100%;
  tbody tr:nth-child(odd) {
    background-color: ${accent.sand150};
  }
  thead tr th {
    text-transform: none;
  }
  tbody tr th {
    padding: ${SPACING_16};
    span {
      font-weight: 400;
    }
  }
`;

export const InvestmentStrategyImplementationPlan = (props) => {
  const { strategyId, type = 'overview', implementationPlan, ...rest } = props;

  return (
    <StyledBaseSection {...rest}>
      {type === 'overview' && (
        <ChartWrapper>
          <ImplementationPlanChart
            implementationPlan={implementationPlan}
            animate={true}
            gap={64}
            reverseRender
            legendOnSide
            showValueLabels
          />
        </ChartWrapper>
      )}
      {type === 'details' && (
        <StyledImplementationPlanTable
          implementationPlan={implementationPlan}
          rounds={implementationPlan.rounds}
          monthsPerRound={implementationPlan.monthsPerRound}
        />
      )}
    </StyledBaseSection>
  );
};
