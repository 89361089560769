import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  t,
  usePortfolioRelationshipFilter,
  selectors,
  useResource,
} from '@formue-app/core';

import { H2, H4, Paragraph } from '../texts';
import { accent, backgroundWhite, textLightest } from '../../constants/colors';
import { SPACING_48, SPACING_24, SPACING_20 } from '../../constants/spacing';
import { mobileCondition, notDesktopCondition } from '../../constants/media';

import { BaseSection } from '../common/BaseSection';
import { PortfolioGainTotal } from './overview/PortfolioGainTotal';
import { PortfolioMarketValueTotal } from './overview/PortfolioMarketValueTotal';
import { SpriteIcon } from '../common/SpriteIcon';
import { ButtonSmall } from '../buttons';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media ${mobileCondition} {
    flex-direction: column;
    margin-bottom: 0;
    gap: ${SPACING_24};
  }
`;

const StyledProfileIcon = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;

  h4 {
    color: ${backgroundWhite};
  }

  @media ${mobileCondition} {
    position: absolute;
    right: ${SPACING_24};
  }
`;

const StyledSummaryBox = styled(BaseSection)`
  background: ${accent.ocean4};
  padding: ${SPACING_48};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  gap: ${SPACING_24};

  @media ${notDesktopCondition} {
    padding: ${SPACING_24};
  }
`;

const StyledTitle = styled(H2)`
  color: ${textLightest};
`;

const StyledParagraph = styled(Paragraph)`
  color: ${textLightest};
`;

const {
  entities: {
    marketValues: { marketValuesSelector },
  },
} = selectors;

const MarketValues = () => {
  const options = usePortfolioRelationshipFilter();
  const numberOfAccounts = options
    ? options.filter((option) => {
        return option.selected;
      }).length
    : 0;

  return (
    <StyledWrapper>
      <PortfolioMarketValueTotal highlightColor={accent.ocean2} />
      <PortfolioGainTotal highlightColor={accent.ocean2} />
      <StyledProfileIcon>
        <SpriteIcon
          id="Individual"
          size={SPACING_20}
          inverted={true}
          navigational={true}
        />
        <H4>{numberOfAccounts}</H4>
      </StyledProfileIcon>
    </StyledWrapper>
  );
};
export const MarketValueSummary = (props) => {
  const { columns } = props;
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const marketValues = useSelector(marketValuesSelector);

  const hasPortfolioContent = loading || Boolean(marketValues.length);

  return (
    <StyledSummaryBox columns={columns}>
      {hasPortfolioContent ? (
        <>
          <MarketValues />
          <ButtonSmall to="/portfolio">{t('portfolio:goto')}</ButtonSmall>
        </>
      ) : (
        <>
          <StyledTitle>{t('errorMessages:noPortfolio:title')}</StyledTitle>
          <StyledParagraph>
            {t('errorMessages:noPortfolio:text')}
          </StyledParagraph>
        </>
      )}
    </StyledSummaryBox>
  );
};
