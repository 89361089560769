import React from 'react';
import styled from 'styled-components';

import {
  getAssetClassName,
  getAssetClassesByAssetClassGroup,
  getColorForAssetClassGroup,
} from '@formue-app/core';

import { SPACING_8, SPACING_2 } from '../../../constants/spacing';
import { assetClassDefaultColorScheme } from '../../../constants/colors';
import { H3, Paragraph } from '../../texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${SPACING_2} 0;
`;

const Dot = styled.div`
  margin-right: ${SPACING_8};
  width: ${SPACING_8};
  height: ${SPACING_8};
  border-radius: ${SPACING_8};
`;

export const PortfolioConstructionPyramidStaticLabel = (props) => {
  const { labelKey } = props;

  const assetClasses = getAssetClassesByAssetClassGroup(labelKey);
  return (
    <Container>
      <H3>{getAssetClassName(labelKey)}</H3>
      <List>
        {assetClasses.map((assetClass) => (
          <ListItem key={`label-${assetClass}`}>
            <Dot
              style={{
                backgroundColor: getColorForAssetClassGroup(
                  labelKey,
                  assetClassDefaultColorScheme
                ),
              }}
            />
            <Paragraph>{getAssetClassName(assetClass)}</Paragraph>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
