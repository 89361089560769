import React from 'react';
import styled, { css } from 'styled-components';

import { H6Size, H6LineHeight } from '../../constants/text';

export const h6Style = css`
  font-size: ${H6Size};
  line-height: ${H6LineHeight};
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
`;

export const StyledH6 = styled.h6`
  ${h6Style}
`;

export const H6 = (props) => {
  const { children } = props;

  return <StyledH6 {...props}>{children}</StyledH6>;
};
