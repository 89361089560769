import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';
import moment from 'moment';

import { investmentStrategySimulations } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer(
  'investment-strategy-simulations'
);

// Create the sagas for this entity
function* readInvestmentStrategies() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(investmentStrategySimulations.get, args);
      result.data.attributes.historicalEvents =
        result.data.attributes.historicalEvents.map((item) => {
          return {
            ...item,
            date: moment(item.date).format('YYYY-MM'),
          };
        });

      result.data.attributes.index = result.data.attributes.index.map(
        (item) => {
          return {
            ...item,
            date: moment(item.changedAt).format('YYYY-MM'),
          };
        }
      );
      // Include args in the attributes to be able to look up simulations later in the store
      result.data.attributes = { ...result.data.attributes, ...args };
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readInvestmentStrategies, ...resourceSagas];
