import React from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { accent, backgroundWhite } from '../../../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_20,
} from '../../../constants/spacing';

import { H4, Paragraph } from '../../texts';
import { BaseSection } from '../../common/BaseSection';
import { Checkbox } from '../../formElements';
import { easeInOut, longAnimationTime } from '../../../constants/animation';
import { ProfileIcon } from '../../common/ProfileIcon';

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_12};
`;

const OptionItem = styled.label`
  display: flex;
  gap: ${SPACING_12};
  align-items: center;
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0;
`;

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_12};
  background: ${accent.sand150};
  padding: ${SPACING_20} ${SPACING_16};
  transition: background ${easeInOut} ${longAnimationTime};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  &:hover {
    background: ${backgroundWhite};
  }
`;

export const RelationshipFilter = (props) => {
  const { title, options, TitleComponent = H4, ...rest } = props;

  // To prevent an error where multiple instances of the same component where interfering with each
  // other we add an id on runtime to each component. This is related to the checkbox component.
  const id = Math.floor(Math.random() * 10000);

  return (
    <StyledBaseSection {...rest}>
      <TitleComponent style={{ marginRight: 'auto' }}>{title}</TitleComponent>

      <OptionsWrapper>
        {options.map((option, index) => {
          const { name, selected, onClick, disabled } = option;
          return (
            <OptionItem
              key={`${camelcase(title)}-${index}-${id}`}
              for={`${camelcase(title)}-${index}-${id}`}
            >
              <ProfileIcon
                name={name}
                size={32}
                fontSize={11}
                color={accent.sand1}
              />
              <Paragraph style={{ marginRight: 'auto' }}>{name}</Paragraph>
              <StyledCheckbox
                id={`${camelcase(title)}-${index}-${id}`}
                checked={selected}
                disabled={disabled}
                onChange={onClick}
              />
            </OptionItem>
          );
        })}
      </OptionsWrapper>
    </StyledBaseSection>
  );
};
