import { apiFetch } from './utils/api';

export const articles = {
  get: async () => {
    const options = {
      method: 'GET',
    };

    const url = '/v2/articles';

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
