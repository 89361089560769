import decamelize from 'decamelize';
import camelcase from 'camelcase';
import { handleActions, createActions } from 'redux-actions';
import { takeEvery, put } from 'redux-saga/effects';

import { actionTypes } from './actionTypes';

export const resourceReducer = (resourceType) => {
  // make sure resourceType is camelCased
  const type = camelcase(resourceType);
  // convert camelCase into UPPER_SNAKE_CASE
  const namespace = decamelize(type).toUpperCase();

  const handleUpdateAndCreateSuccess = (state, { payload }) => {
    if (
      payload.entities.hasOwnProperty(resourceType) ||
      payload.result.hasOwnProperty(resourceType)
    ) {
      return {
        byId: {
          ...state.byId,
          ...payload.entities[resourceType],
        },
        // Uses "Set" to make the list unique, then spreds into an array
        allIds: [
          ...new Set([...state.allIds, ...payload.result[resourceType]]),
        ],
      };
    }

    return defaultState;
  };

  const handleOptimisticUpdate = (state, { payload }) => {
    const { data } = payload;
    return {
      byId: {
        ...state.byId,
        [data.id]: data,
      },
      allIds: state.allIds,
    };
  };

  // We expect to get the ID of the removed resource back here
  // so then we remove it from our local state on success
  const handleDestroySuccess = (state, { payload }) => {
    if (payload) {
      const modifiedById = {
        ...state.byId,
      };
      delete modifiedById.payload;

      // Remove id from allIds list
      const modifiedAllIds = [...state.allIds].filter((id) => id !== payload);

      return {
        byId: modifiedById,
        allIds: modifiedAllIds,
      };
    }

    return defaultState;
  };

  /**
   * Create actions for the common rest verbs:
   *
   *   * index: GET request to fetch a list of resources
   *   * show: GET reqeuest to fetch one resource
   *   * create: POST request to create a resource
   *   * update: PATCH request to update a resource
   *   * destroy: DESTROY request to delete a resource
   */
  const actions = createActions({
    [namespace]: {
      [`INDEX_${actionTypes.REQUEST_BEGIN}`]: (args) => ({ args }),
      [`INDEX_${actionTypes.REQUEST_SUCCESS}`]: (data) => data,
      [`INDEX_${actionTypes.REQUEST_FAILURE}`]: (data) => data,
      [`SHOW_${actionTypes.REQUEST_BEGIN}`]: (args) => ({ args }),
      [`SHOW_${actionTypes.REQUEST_SUCCESS}`]: (data) => data,
      [`SHOW_${actionTypes.REQUEST_FAILURE}`]: (data) => data,
      [`CREATE_${actionTypes.REQUEST_BEGIN}`]: (data) => data,
      [`CREATE_${actionTypes.REQUEST_SUCCESS}`]: (data) => data,
      [`CREATE_${actionTypes.REQUEST_FAILURE}`]: (data) => data,
      [`UPDATE_${actionTypes.REQUEST_BEGIN}`]: (data) => data,
      [`UPDATE_${actionTypes.REQUEST_SUCCESS}`]: (data) => data,
      [`UPDATE_${actionTypes.REQUEST_FAILURE}`]: (data) => data,
      [`DESTROY_${actionTypes.REQUEST_BEGIN}`]: (data) => data,
      [`DESTROY_${actionTypes.REQUEST_SUCCESS}`]: (data) => data,
      [`DESTROY_${actionTypes.REQUEST_FAILURE}`]: (data) => data,
      ['DO_OPTIMISTIC_UPDATE']: (data) => data,

      // We store fetched resources in the store, so that we can re-use
      // the data everywhere we want to use this resource without doing
      // a new network request. But at some point we might want to make
      // sure we load new/fresh data from the data source. We don't
      // want to flush the data from the store, but just trigger a
      // reload of the data.
      INVALIDATE: () => {},

      // The flush action is used to make sure we clear out everything
      // from the store related to this resource. It can be used when
      // a user logs out of the service or similar.
      FLUSH: () => {},
    },
  })[type];

  const defaultState = {
    byId: {},
    allIds: [],
    meta: {},
    resourceType: type,
  };

  const reducer = handleActions(
    {
      // When receiving data from INDEX request we merge the data in the store
      // be we need to make sure we don't duplicate data. This _might_ add data
      // to the store that's not relevant any more, e.g "old" data if you did
      // a new request adding/removing some query filters etc. It's not the
      // responsibility of this reducer to tackle that. In those cases we
      // should flush the store first instead.
      [actions.indexRequestSuccess]: (state, { payload }) => {
        if (
          payload.entities.hasOwnProperty(resourceType) ||
          payload.result.hasOwnProperty(resourceType)
        ) {
          return {
            byId: {
              ...state.byId,
              ...payload.entities[resourceType],
            },
            allIds: [
              ...new Set([
                ...state.allIds,
                ...(payload.result[resourceType]
                  ? payload.result[resourceType]
                  : []),
              ]),
            ],
            meta: {
              ...state.meta,
              ...payload.meta,
            },
          };
        } else if (payload.meta) {
          return {
            ...state,
            meta: {
              ...state.meta,
              ...payload.meta,
            },
          };
        }
        // If we don't have any data in the store, we can safely return the default state
        // in all other cases we should leave the state alone and just return it unmodified.
        if (!state.allIds.length) {
          return defaultState;
        }
        return state;
      },

      // After getting the SHOW response, we just want to add/update an entity
      // in the store, not replace the whole list
      [actions.showRequestSuccess]: (state, { payload }) => {
        if (
          payload.entities.hasOwnProperty(resourceType) ||
          payload.result.hasOwnProperty(resourceType)
        ) {
          return {
            byId: {
              ...state.byId,
              ...payload.entities[resourceType],
            },
            // Uses "Set" to make the list unqiue, then spreds into an array
            allIds: [
              ...new Set([...state.allIds, ...payload.result[resourceType]]),
            ],
          };
        }

        return defaultState;
      },

      // After getting the UPDATE response, we want to find the entity
      // in the list and replace it with what the server returned (if it did)
      [actions.updateRequestSuccess]: handleUpdateAndCreateSuccess,

      [actions.createRequestSuccess]: handleUpdateAndCreateSuccess,

      [actions.destroyRequestSuccess]: handleDestroySuccess,

      [actions.doOptimisticUpdate]: handleOptimisticUpdate,

      [actions.flush]: () => {
        return defaultState;
      },
    },
    defaultState
  );

  // By default all entities will react to an APP/FLUSH event by clearing out the store
  function* flush() {
    yield takeEvery('APP/FLUSH', function* () {
      // Flush will "empty" the entity reducer
      yield put(actions.flush());
    });
  }
  const sagas = [flush];

  return [actions, reducer, sagas];
};
