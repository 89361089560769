import React from 'react';
import styled from 'styled-components';

import { H2SizeMobile, H2LineHeightMobile } from '../../constants/text';

const StyledH2Small = styled.h2`
  font-size: ${H2SizeMobile};
  line-height: ${H2LineHeightMobile};
`;

export const H2Small = (props) => {
  const { children } = props;

  return <StyledH2Small {...props}>{children}</StyledH2Small>;
};
