import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { accent } from '../../../constants/colors';
import { H4 } from '../../texts';

const InputWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content 24px;
  margin: 1em auto;
  width: 100%;
  overflow: hidden; // in case <label> elements overflow
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background: ${accent.ocean1};
    grid-column: 1 / span 2;
    grid-row: 3;
    height: 4px;
    pointer-events: none;
    width: ${(props) => `
      calc(100% / ${props.max} * ${props.maxValue - props.minValue})
    `};
    left: ${(props) => `
      calc(100% / ${props.max} * ${props.minValue})
    `};
    top: -14px;
  }
`;

const Label = styled.label`
  position: absolute;
  clip-path: inset(50%);
`;

const thumb = css`
  background: ${accent.ocean1};
  border: none; /* get rid of Firefox thumb border */
  border-radius: 0; /* get rid of Firefox corner rounding */
  pointer-events: auto; /* catch clicks */
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: -10px;
  position: relative;
`;

const track = css`
  background: ${accent.ocean2}; /* get rid of Firefox track background */
  height: 4px;
  width: 100%;
`;

const InputRange = styled.input`
  grid-column: 1;
  grid-row: 2;
  background: none; /* get rid of white Chrome background */
  color: #000;
  font: inherit; /* fix too small font-size in both Chrome & Firefox */
  margin: 0;
  pointer-events: none; /* let clicks pass through */

  &::-webkit-slider-runnable-track,
  &::-webkit-slider-thumb,
  & {
    -webkit-appearance: none;
  }

  &::-webkit-slider-runnable-track {
    ${track}
  }
  &::-moz-range-track {
    ${track}
  }

  &::-webkit-slider-thumb {
    ${thumb}
  }
  &::-moz-range-thumb {
    ${thumb}
  }
`;

export const GraphFilter = (props) => {
  const { startYear, index, setIndexFilter, indexFilter } = props;

  const filterLow = startYear;
  const filterHigh =
    Math.ceil(new Date(index[index.length - 1].date).getFullYear() / 10) * 10;
  const filterYears = [];

  for (let i = filterLow; i <= filterHigh; i += 10) {
    filterYears.push(i);
  }

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(filterYears.length - 1);

  useEffect(() => {
    setIndexFilter({
      from: new Date(filterYears[minValue].toString()),
      to: new Date(filterYears[maxValue].toString()),
    });
  }, [minValue, maxValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        {filterYears.map((year) => (
          <H4
            style={{
              opacity:
                indexFilter.from.getFullYear() <= year &&
                indexFilter.to.getFullYear() >= year
                  ? 1
                  : 0.5,
            }}
            key={`year-${year}`}
          >
            {year}
          </H4>
        ))}
      </div>

      <InputWrapper
        role="group"
        aria-labelledby="multi-lbl"
        minValue={minValue}
        maxValue={maxValue}
        max={filterYears.length - 1}
      >
        <Label htmlFor="a">Value A:</Label>
        <InputRange
          id="a"
          type="range"
          min={0}
          value={minValue}
          max={filterYears.length - 1}
          onChange={(e) => {
            if (e.target.value < maxValue) setMinValue(e.target.value);
          }}
        />
        <Label htmlFor="b">Value B:</Label>
        <InputRange
          id="b"
          type="range"
          min={0}
          value={maxValue}
          max={filterYears.length - 1}
          onChange={(e) => {
            if (e.target.value > minValue) setMaxValue(e.target.value);
          }}
        />
      </InputWrapper>
    </>
  );
};
