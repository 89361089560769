import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { accent } from '../../constants/colors';

import { ReactComponent as StrategyMatrixIcon } from '../../assets/icons/strategy-matrix-icon.svg';

const StyledStrategyMatrixIcon = styled(StrategyMatrixIcon)`
  rect {
    fill: ${(props) => props.color};
  }

  rect:nth-child(${(props) => props.lockin * 3 + props.risk + 1}) {
    fill: ${(props) => props.highlightcolor};
  }
`;

export const StrategyMatrix = (props) => {
  const {
    risk,
    lockin,
    color = accent.ocean250,
    highlightColor = accent.ocean1,
    ...rest
  } = props;

  const [reversedLockin, setReversedLockin] = useState(2 - lockin);

  // Mapping from SF
  // Y-axis (no-lockin): High, Medium, Low, No-lock (from top → bottom)
  // X-axis (risk): Low, Medium High (from left → right)

  useEffect(() => {
    // If lockin is null (i.e. no lockin) we can just set it to 3 as its the last row of the matrix
    // Else we just ignore the bottom row and just reverse the values to match SF mapping
    if (lockin === null) {
      setReversedLockin(3);
    } else {
      setReversedLockin(2 - lockin);
    }
  }, [lockin, setReversedLockin]);

  return (
    <StyledStrategyMatrixIcon
      risk={risk}
      lockin={reversedLockin}
      color={color}
      highlightcolor={highlightColor}
      {...rest}
    />
  );
};
