import { createActions } from 'redux-actions';

export const actions = createActions({
  READ_STATUS: {
    SET_READ_ITEMS: payload => payload,
    HYDRATE_READ_ITEMS: payload => payload,
    SET_APP_ICON_BADGE_NUMBER: () => {},
    SAVE_READ_ITEM: payload => payload,
  },
}).readStatus;
