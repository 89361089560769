import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';

const defaultState = {
  sessionId: null,
};

export const reducer = handleActions(
  {
    [actions.setSessionId]: (state, { payload }) => ({
      ...state,
      sessionId: payload,
    }),
  },
  defaultState
);
