import { supportedLocales } from './config';

const translationLoader = {
  type: 'backend',
  init: () => {},
  read(language, namespace, callback) {
    let resource;
    if (language === 'se') {
      language = 'sv';
    }

    try {
      resource = supportedLocales[language].translationFileLoader()[namespace];
    } catch (error) {
      return callback(new Error('Language not found'))
    }

    return callback(null, resource);
  },
};

export default translationLoader;
