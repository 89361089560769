import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  formatBigMoney,
  getAssetClassName,
  getColorForAssetClass,
  percentage,
  t,
} from '@formue-app/core';

import {
  accent,
  assetClassDefaultColorScheme,
} from '../../../constants/colors';

import { StyledCell, StyledRow, TableList } from '../../lists';
import { DotIcon } from '../../common/DotIcon';
import { H4 } from '../../texts';
import { SPACING_8 } from '../../../constants/spacing';

const LightStyledCell = styled(StyledCell)`
  span {
    font-weight: 400;
    gap: ${SPACING_8};
  }
`;

const LightStyledRow = styled(StyledRow)`
  &:last-child {
    background: ${accent.sand150};
    span {
      font-weight: 500;
    }
  }
`;

const LightStyledSubRow = styled(StyledRow)`
  margin: 20px;
  padding: ${SPACING_8};

  &:nth-child(odd) {
    background: ${accent.sand150} !important;
  }
`;

export const ProductCostTable = (props) => {
  const { allocation, products, showAll } = props;
  // We are lacking the correct way to calculate the cost in amount for this
  // table, so until we have the correct way to do that in the API we disable
  // the amount conversion in the frontend. To change back extract `dataExtractor`
  // from props instead of defining it here.
  const dataExtractor = 'percentage';

  const [openItems, setOpenItems] = useState([]);

  const formattedData = allocation.map((item) => ({
    id: item.assetClass,
    strategyShare: item.formueStrategyShare,
    amount: item.cost.amount,
    percent: item.cost.percentage,
    share: item.cost.share,
    isOpen: false,
    subItems: products
      .filter((product) => product.assetClass === item.assetClass)
      .map((product) => ({
        id: product.name,
        strategyShare: product.strategyShare,
        amount: product.cost.amount,
        percent: product.cost.percentage,
        share: product.cost.share,
      })),
  }));

  const totalCost = allocation.reduce((arr, acc) => {
    return arr + acc.cost.share;
  }, 0);

  // Handler to call if you want toggle the open state of an item.
  const onSelectedAssetClass = ({ id }) => {
    const index = openItems.indexOf(id);

    if (index > -1) {
      openItems.splice(index, 1);
      setOpenItems([...openItems]);
    } else {
      setOpenItems([...new Set([...openItems, id])]);
    }
  };

  useEffect(() => {
    if (showAll) {
      // If show all is false when we start, copy all ids into openItems to open all
      setOpenItems(allocation.map((item) => item.assetClass));
    } else {
      setOpenItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  return (
    <TableList
      sortable={false}
      rowComponent={LightStyledRow}
      columns={[
        {
          key: 'id',
          label: 'Assetclass',
          width: '55%',
          component: LightStyledCell,
          render: ({ id, subItem, total }) => (
            <>
              {!subItem && !total ? (
                <DotIcon
                  color={getColorForAssetClass(
                    id,
                    assetClassDefaultColorScheme
                  )}
                />
              ) : null}
              <H4>{!subItem && !total ? getAssetClassName(id) : id}</H4>
            </>
          ),
        },
        {
          key: 'amount',
          label: 'Andel',
          width: '15%',
          type: 'number',
          render: ({ strategyShare }) => percentage(strategyShare * 100),
          component: LightStyledCell,
        },
        {
          key: 'percentage',
          label: 'Kostnad',
          width: '15%',
          type: 'number',
          render: ({ percent, amount }) =>
            dataExtractor === 'percentage'
              ? percentage(percent * 100, 2)
              : formatBigMoney(amount, false),
          component: LightStyledCell,
        },
        {
          key: 'share',
          label: 'Bidrag',
          width: '15%',
          type: 'number',
          render: ({ share }) => percentage(share * 100, 2),
          component: LightStyledCell,
        },
      ]}
      subRowComponent={(props) => <LightStyledSubRow {...props} />}
      rows={[
        ...formattedData,
        {
          id: t('advisor:strategySimulator:cost:total'),
          share: totalCost,
          isOpen: false,
          total: true,
        },
      ]}
      openItems={openItems}
      clickCallback={onSelectedAssetClass}
      keyExtractor={(item, index) => `product-cost-${index}`}
    />
  );
};
