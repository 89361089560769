import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { SPACING_24 } from '../../../../constants/spacing';
import { H2, ParagraphXSmall } from '../../../texts';

import { ReactComponent as ChartLow } from '../../../../assets/wealth-plan/sustainable-share/low.svg';
import { ReactComponent as ChartMedium } from '../../../../assets/wealth-plan/sustainable-share/medium.svg';
import { ReactComponent as ChartHigh } from '../../../../assets/wealth-plan/sustainable-share/high.svg';
import { accent } from '../../../../constants/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 6px;
`;

const ChartWrapper = styled.div`
  margin-right: ${SPACING_24};
`;

export const ShareChart = ({
  shareOfPortfolioToAllocate,
  textColor = accent.ocean490,
  showDescription = true,
  ...rest
}) => {
  const shareValue = shareOfPortfolioToAllocate.toLowerCase();
  return (
    <Container {...rest}>
      <Content>
        <ChartWrapper>
          {shareValue === 'low' && <ChartLow />}
          {shareValue === 'medium' && <ChartMedium />}
          {shareValue === 'high' && <ChartHigh />}
        </ChartWrapper>
        <H2 style={{ color: textColor }}>
          {`${t(
            `wealthPlan:investmentProfile:sustainabilityPreferences:share:${shareValue}`
          )}`}
        </H2>
      </Content>
      {showDescription && (
        <ParagraphXSmall style={{ color: textColor }}>
          {t(
            `wealthPlan:investmentProfile:sustainabilityPreferences:share:${shareValue}Description`
          )}
        </ParagraphXSmall>
      )}
    </Container>
  );
};
