import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { mock } from './mock';
export { actions };
export { sagas } from './sagas';

const defaultState = {
  readItems: [],
};

export const reducer = handleActions(
  {
    [actions.setReadItems]: (state, { payload }) => ({
      ...state,
      readItems: [
        ...state.readItems,
        // Filter to only add unique entries in array of read items
        ...payload.filter(item =>
          state.readItems.findIndex(readItem => readItem.id === item.id)
        ),
      ],
    }),
  },
  defaultState
);
