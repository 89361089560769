import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';

import { useQlikFilterOptions, getPeriodsBetween } from '@formue-app/core';
import { CollapsibleFilters } from './CollapsibleFilters';
import { SPACING_16, SPACING_2 } from '../../../constants/spacing';

import { ExtendedReportingMonthFilter } from './ExtendedReportingMonthFilter';

const StyledCollapsibleFilters = styled(CollapsibleFilters)`
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: transparent !important;
`;

const periodToDatePickerValue = (value) => {
  return {
    year: Number(value.toString().slice(0, 4)),
    month: Number(value.toString().slice(4, 6)),
  };
};

/**
 * While the multiselect filter can be used on bunch of different fields, this
 * range filter is custom for the "Period" field. I'm sure it would be possible
 * to make it reusauble if we need to, but for now there is only one date range
 * filter, so it's not worth the time to make it reusable as of now.
 */
export const ExtendedReportingPeriodRangeFilter = ({
  app,
  selections,
  onChangeCount = () => {},
  ...rest
}) => {
  const field = { name: 'Period', label: 'Month range', dataType: 'Number' };
  const [options] = useQlikFilterOptions(app, selections, field);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const fromMonthFilterRef = useRef();
  const toMonthFilterRef = useRef();

  useEffect(() => {
    // We need both a from and to value to make a range
    if (from && to) {
      // For some reason one needs to clear the selection first when using the
      // `selectMatch` api
      app.field(field.name).clear();
      app
        .field(field.name)
        .selectMatch(`=${field.name} >= ${from} and ${field.name} <= ${to}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  // After one makes a filter based on a range, the user might clear filters or
  // remove certains periods in that range. If that happens, we want the date
  // picker component to reset and not show any state. That is remove the values
  // from "To" and "From" in the UI so that it doesn't misrepresent the actual
  // selected values
  const selectionsJsonString = JSON.stringify(selections);
  useEffect(() => {
    const periodSelection = selections?.find((s) => s.fieldName === 'Period');
    // We have selected some period values, so we need to check if the selected
    // values match what should be in the range between our "from" and "to"
    // values.
    if (to && from) {
      // The periods in between our selected from and to values
      const periodsInRange = getPeriodsBetween(from, to);

      const resetMonthFilters = () => {
        setFrom(null);
        fromMonthFilterRef.current.reset();
        setTo(null);
        toMonthFilterRef.current.reset();
      };

      // Sadly the qlik selections object does not return a unlimited list of the
      // current selections. We only get a subset of them. So if we have made a
      // selection that spans more than the threshold (currently 6) we can't
      // compare the current selections with the range we have.
      //
      // So what we do instead if try to compare if we have less than threshold,
      // if more we just compare on the number of selections and hope for the best.
      // this might be wrong though, since one might have the same number of periods
      // selected, but not the same actual values.
      const qSelectionThreshold = periodSelection?.qSelectionThreshold;
      const selectedCount = periodSelection?.selectedCount;
      if (selectedCount > qSelectionThreshold) {
        if (selectedCount !== periodsInRange.length) {
          resetMonthFilters();
        }
      } else {
        const selectedPeriods = periodSelection?.selectedValues.map((value) =>
          Number(value.qName)
        );

        // Compare the two arrays for equality and if they do not match
        // we want to reset the UI of this filter so that we don't show
        // any selected values.
        if (!isEqual(periodsInRange, selectedPeriods)) {
          resetMonthFilters();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsJsonString]);

  if (!options || !options.length) return null;

  /**
   * We calculate the minToDate and maxFromDate to be higher/lower than the
   * corresponding values. This way one cannot select an invalid range.
   *
   * Sadly even if we do this, and pass the min/max values to the date filters
   * the date picker component doesn't seem to update itself like we would want
   * to. So this is kind of unecessary, but we leave it in in case we find
   * a solution to that problem later.
   */
  const minDate = periodToDatePickerValue(options[0].value);
  const maxDate = periodToDatePickerValue(options[options.length - 1].value);

  let minToDate = minDate;
  if (from) {
    minToDate = periodToDatePickerValue(from);
  }

  let maxFromDate = maxDate;
  if (to) {
    maxFromDate = periodToDatePickerValue(to);
  }

  return (
    <StyledCollapsibleFilters
      title={field.label}
      childrenStyle={{ padding: `${SPACING_2} ${SPACING_16}` }}
      contentStyle={{ backgroundColor: 'transparent' }}
      startExpanded={false}
      {...rest}
    >
      <ExtendedReportingMonthFilter
        ref={fromMonthFilterRef}
        label={'From'}
        minDate={minDate}
        maxDate={maxFromDate}
        onChange={(period) => {
          setFrom(Number(period));
        }}
      />
      <ExtendedReportingMonthFilter
        ref={toMonthFilterRef}
        label={'To'}
        minDate={minToDate}
        maxDate={maxDate}
        onChange={(period) => {
          setTo(Number(period));
        }}
      />
    </StyledCollapsibleFilters>
  );
};
