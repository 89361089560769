import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategyPortfolioCharacteristicsAllIds,
  investmentStrategyPortfolioCharacteristicsById,
  investmentStrategyPortfolioCharacteristicsSelector,
] = resourceSelectors('investmentStrategyPortfolioCharacteristics');

export const investmentStrategyPortfolioCharacteristicsByStrategyIdSelector = (
  strategyId
) =>
  createSelector(
    investmentStrategyPortfolioCharacteristicsSelector,
    (allProducts) =>
      allProducts.find((product) => product.investmentStrategy.id == strategyId)
  );
