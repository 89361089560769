import { useEffect, useRef } from 'react';

/**
 * A custom hook to store the previous value of something
 * in the render cycle.
 *
 * @param {*} value The value to remember from last render
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
