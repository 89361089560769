import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategySimulationsAllIds,
  investmentStrategySimulationsById,
  investmentSimulatorSelector,
] = resourceSelectors('investmentStrategySimulations');

export const investmentSimulatorHasContent = createSelector(
  investmentStrategySimulationsAllIds,
  (allIds) => {
    return !!allIds.length;
  }
);
