import React from 'react';
import styled, { css } from 'styled-components';

import { accent, textLightest } from '../../../constants/colors';
import { SPACING_24, SPACING_16 } from '../../../constants/spacing';

import { H3 } from '../../texts';
import {
  easeInOut,
  extraLongAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';

import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';

const QuestionsWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  margin-top: ${SPACING_24};

  h3 {
    text-align: ${(props) => (props.vertical ? 'center' : 'left')};
    line-height: 130%;
    position: relative;
    left: ${(props) => (props.vertical ? 0 : 25)}px;
    top: ${(props) => (props.vertical ? 25 : 0)}px;
    opacity: 0;
    transition: all ${easeInOut} ${(props) => props.customAnimationTime};
    transition-delay: ${(props) => props.customAnimationDelay + 0.1}s;
    white-space: pre-line;

    ${(props) =>
      props.isActiveSlide &&
      css`
        top: 0;
        left: 0;
        opacity: 1;
      `}
  }

  ${(props) =>
    props.vertical &&
    css`
      flex-direction: column;
    `}
`;

const QuestionIconWrapper = styled.div`
  background-color: ${(props) => props.bubbleColor};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => (props.vertical ? 0 : SPACING_16)};
  margin-bottom: ${(props) => (props.vertical ? SPACING_24 : 0)};
  position: relative;

  left: ${(props) => (props.vertical ? 0 : 25)}px;
  top: ${(props) => (props.vertical ? 25 : 0)}px;
  opacity: 0;
  transition: all ${easeInOut} ${(props) => props.customAnimationTime};
  transition-delay: ${(props) => props.customAnimationDelay}s;

  ${(props) =>
    props.isActiveSlide &&
    css`
      left: 0;
      top: 0;
      opacity: 1;
    `}
`;

export const Question = (props) => {
  const {
    isActiveSlide,
    questionText,
    textColor = accent.ocean250,
    bubbleColor = accent.ocean1,
    customAnimationDelay = slideAnimationDelay,
    customAnimationTime = extraLongAnimationTime,
    vertical = false,
    ...rest
  } = props;

  return (
    <QuestionsWrapper
      {...rest}
      isActiveSlide={isActiveSlide}
      customAnimationDelay={customAnimationDelay}
      customAnimationTime={customAnimationTime}
      vertical={vertical}
    >
      <QuestionIconWrapper
        isActiveSlide={isActiveSlide}
        bubbleColor={bubbleColor}
        customAnimationDelay={customAnimationDelay}
        customAnimationTime={customAnimationTime}
        vertical={vertical}
      >
        <SpriteIconFunctional
          id="Question mark"
          color={textLightest}
          size={10}
        />
      </QuestionIconWrapper>
      <H3 style={{ color: textColor }}>{questionText}</H3>
    </QuestionsWrapper>
  );
};
