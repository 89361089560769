import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { Sentry } from '@formue-app/core';

import { actions } from './actions';
import { actions as authActions } from '../auth';
// import { apiErrorMessage } from '../../services/flashMessages';

function* handleErrors() {
  yield takeEvery(actions.handleApiError, function* ({ payload: error }) {
    if (error.status === 401) {
      yield put(actions.handleAuthError());
      return;
    }

    if (error.status === 404) {
      // TODO - show flash message
      console.error('DISPLAY FLASH MESSAGE');
      //yield call(apiErrorMessage, error.location);
      return;
    }
    // Capture errors to sentry if they are not 401 or 204 that are handled
    Sentry.captureException(error);

    // Crash the app ?
    // while (1) {}
  });
}

function* handleAuthErrors() {
  yield takeEvery(actions.handleAuthError, function* () {
    yield put(authActions.removeAccessToken());

    // This is stored in session storage so that we can decide what login method
    // we want to redirect when a token expires. Maybe ideally it should be stored
    // in redux store, but the overhead of loading it into that on refreshes etc
    // didn't seem necessary.
    const authMethod = window.sessionStorage.getItem('authenticationMethod');
    if (authMethod === 'employeeSso') {
      // If a user authenticated using SSO we want to redirect back to the SSO endpoint
      // so that they will authenticate again using the same method.
      yield put(push('/auth/sso'));
    } else {
      yield put(push('/login'));
    }
  });
}

export const sagas = [handleErrors, handleAuthErrors];
