import React from 'react';
import styled from 'styled-components';

import { SPACING_48 } from '../../constants/spacing';
import { accent, backgroundWhite, textLightest } from '../../constants/colors';

import { Modal } from './Modal';
import { HeaderWithBackgroundImage } from './HeaderWithBackgroundImage';

const ContentWrapper = styled.div`
  padding: ${SPACING_48};
`;

const HeaderContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWithHeader = (props) => {
  const {
    title,
    subTitle,
    value,
    textColor = accent.ocean250,
    backgroundColor = accent.ocean4,
    contentBackgroundColor = backgroundWhite,
    children,
    headerContent,
    iconColor = textLightest,
    padding,
    ...rest
  } = props;

  return (
    <Modal {...rest} padding={0} iconColor={iconColor}>
      <HeaderWithBackgroundImage
        title={title}
        subtitle={subTitle}
        textColor={textColor}
        backgroundColor={backgroundColor}
        fullSize={true}
        sideChildren={true}
      >
        <HeaderContent>{headerContent}</HeaderContent>
      </HeaderWithBackgroundImage>

      <ContentWrapper
        style={{ backgroundColor: contentBackgroundColor, padding: padding }}
      >
        {children}
      </ContentWrapper>
    </Modal>
  );
};
