import React from 'react';
import styled from 'styled-components';

import { GridElement } from '../layout';
import { SPACING_16 } from '../../constants/spacing';
import { CostTable } from '../advisorsCorner/strategySimulator/CostTable';

const StyledGridElement = styled(GridElement)`
  display: flex;
  gap: ${SPACING_16};
  flex-direction: column;
`;

export const InvestmentStrategyCostEstimate = (props) => {
  const {
    investmentStrategy: strategy,
    costs,
    dataExtractor,
    advisoryFeeRelevant,
    structuringFeeRelevant,
  } = props;

  if (!costs.length || !strategy) {
    return null;
  }

  return (
    <>
      <StyledGridElement columns={12} style={{ marginTop: SPACING_16 }}>
        <CostTable
          managementType={strategy.managementType}
          dataExtractor={dataExtractor}
          costData={costs}
          advisoryFeeRelevant={advisoryFeeRelevant}
          structuringFeeRelevant={structuringFeeRelevant}
        />
      </StyledGridElement>
    </>
  );
};
