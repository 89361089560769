import { handleActions } from 'redux-actions';
import { actions } from './actions';
export { actions };

const defaultState = {
  compare: false,
  totalYears: null,
  currentItem: null,
  currentIndex: null,
};

export const reducer = handleActions(
  {
    [actions.compare]: (state, { payload }) => ({
      ...state,
      compare: payload,
    }),
    [actions.setTotalYears]: (state, { payload }) => ({
      ...state,
      totalYears: payload,
    }),
    [actions.setCurrentItem]: (state, { payload }) => ({
      ...state,
      currentItem: payload.item,
      currentIndex: payload.index,
    }),
  },
  defaultState
);
