import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';

import { selectors, actions } from '@formue-app/core';

import { Message } from './Message';

const {
  entities: {
    operationalMessages: { allUnreadOperationalMessagesSelector },
  },
} = selectors;

const {
  ui: { readStatus: readActions, flashMessages: flashMessagesActions },
} = actions;

export const OperationalMessages = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activeOperationalMessages = useSelector(
    allUnreadOperationalMessagesSelector
  );
  const flashMessages = useSelector((state) => state.ui.flashMessages.messages);
  const [exportMode] = useQueryState('exportMode', false);

  // Return the relevant operational messages based on their channel and our current route
  let filteredOperationalMessages = activeOperationalMessages;
  filteredOperationalMessages = filteredOperationalMessages.filter(
    (message) => {
      // make sure it's related to the client web (Community for legacy reasons)
      const channelsForWeb = message.channel
        .split(';')
        .filter(
          (item) => item.includes('Community') || item.includes('Portal')
        );
      return channelsForWeb.length;
    }
  );

  if (history.location.pathname === '/login') {
    filteredOperationalMessages = filteredOperationalMessages.filter(
      (message) => {
        const channelsForLogin = message.channel
          // Pick those just for the login page
          .split(';')
          .filter((item) => item.includes('Login'));
        return channelsForLogin.length;
      }
    );
  } else {
    filteredOperationalMessages = filteredOperationalMessages.filter(
      (message) => {
        const channelsNotForLogin = message.channel
          // Remove those just for the login page
          .split(';')
          .filter((item) => !item.includes('Login'));
        return channelsNotForLogin.length;
      }
    );
  }

  // Operational messages come from the API and we want to store their read status
  // in local storage so we don't show them more than once
  const dismissOperationalMessage = (id) =>
    dispatch(
      readActions.saveReadItem({
        type: 'operationalMessages',
        id,
      })
    );

  const dismissFlashMessage = (id) =>
    dispatch(flashMessagesActions.dismiss(id));

  if (exportMode) {
    return null;
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div style={{ position: 'absolute', width: '100%', zIndex: 13 }}>
        {filteredOperationalMessages.map((message) => (
          <Message
            message={message}
            dismissItem={dismissOperationalMessage}
            key={message.id}
          />
        ))}
        {flashMessages.map((message) => (
          <Message
            message={message}
            dismissItem={dismissFlashMessage}
            key={message.id}
          />
        ))}
      </div>
    </div>
  );
};
