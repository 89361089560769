import React, { useState, useEffect } from 'react';
import useCollapse from 'react-collapsed';
import styled from 'styled-components';

import { easeInOut, shortAnimationTime } from '../../../constants/animation';
import { accent } from '../../../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_2,
} from '../../../constants/spacing';
import { FlipArrow } from '../../common/FlipArrow';
import { H4 } from '../../texts';
import { CounterNumber } from './CounterNumber';

const Container = styled.div`
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: box-shadow ${shortAnimationTime} ${easeInOut};
  padding: 12px ${SPACING_16};

  ${(props) =>
    props.isExpanded &&
    `
    background-color: ${accent.sand1};
  `};
`;

const ChildrenWrapper = styled.div`
  > div:first-of-type {
    margin-top: ${SPACING_2};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const StyledFlipArrow = styled(FlipArrow)`
  margin: 0;
  width: 20px;
`;

export const CollapsibleFilters = (props) => {
  const {
    children,
    title,
    icon,
    startExpanded = false,
    childrenStyle,
    contentStyle,
    count = 0,
    ...rest
  } = props;

  const [isExpanded, setIsExpanded] = useState(startExpanded);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(startExpanded);
  }, [startExpanded]);

  return (
    <Container {...rest}>
      <Content
        {...getToggleProps({ onClick: toggleExpanded })}
        isExpanded={isExpanded}
        style={contentStyle}
      >
        <TitleWrapper>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <H4>{title}</H4>
          <CounterNumber number={count} style={{ marginLeft: 10 }} />
        </TitleWrapper>
        <StyledFlipArrow
          direction={isExpanded ? 'up' : 'down'}
          backgroundColor="transparent"
          color={accent.ocean490}
        />
      </Content>
      <ChildrenWrapper
        {...getCollapseProps()}
        style={Object.assign(getCollapseProps().style, childrenStyle)}
      >
        {children}
      </ChildrenWrapper>
    </Container>
  );
};
