import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';
import * as selectors from './selectors';
export { selectors };

const defaultState = {
  recentlyImpersonatedClientIds: [],
};

export const reducer = handleActions(
  {
    [actions.setRecentlyImpersonatedClientIds]: (state, { payload }) => ({
      ...state,
      recentlyImpersonatedClientIds: payload,
    }),
  },
  defaultState
);
