import React from 'react';

import { useSelector } from 'react-redux';

import { percentage, t, selectors } from '@formue-app/core';
import { H4, ParagraphXSmall } from '../../texts';

const {
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

export const LensesCoverageText = ({ coverage }) => {
  const activeFilters = useSelector(activeFiltersSelector);

  const { showInternalPortfolio, showExternalPortfolio } = activeFilters;
  let coverageText = '';

  if (showInternalPortfolio && showExternalPortfolio)
    coverageText += t('portfolioConstruction:footer:coverageText:total');
  else {
    if (showInternalPortfolio)
      coverageText += t('portfolioConstruction:footer:coverageText:internal');
    if (showExternalPortfolio)
      coverageText += coverageText = t(
        'portfolioConstruction:footer:coverageText:external'
      );
  }

  return (
    <div style={{ textAlign: 'left' }}>
      <H4 style={{ paddingBottom: 4 }}>
        {t('esg:footer:coverage', {
          percent: percentage(coverage * 100),
        })}
      </H4>
      <ParagraphXSmall>
        {`${t('esg:footer:selectedPortfolio')} `} <strong>{coverageText}</strong>
      </ParagraphXSmall>
    </div>
  );
};
