export const PERIODS = {
  THISMONTH: 'This month',
  THISYEAR: 'This year',
  TWELVEMONTHS: 'Last 12 months',
  THREEYEARS: 'Last 3 years',
  FIVEYEARS: 'Last 5 years',
  TENYEARS: 'Last 10 years',
  SINCESTART: 'Since start',
};

export const SUSTAINABILITY_PERIODS = {
  THISMONTH: 'Last month',
  THISYEAR: 'This year',
  TWELVEMONTHS: '1 year',
  THREEYEARS: '3 years',
};
