import { handleActions } from 'redux-actions';
import { actions } from './actions';
export { actions };

const defaultState = {
  compare: false,
};

export const reducer = handleActions(
  {
    [actions.compare]: (state, { payload }) => ({
      ...state,
      compare: payload,
    }),
  },
  defaultState
);
