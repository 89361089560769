import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors } from '@formue-app/core';

import { SPACING_120, SPACING_88 } from '../../../constants/spacing';

import { MainGrid } from '../../layout';

import { SlideHeader } from '../investmentStrategyExportComponents/SlideHeader';
import { ImplementationPlanTable } from '../../advisorsCorner/strategySimulator/ImplementationPlanTable';
import { StyledRow } from '../../lists/TableList';
import { accent } from '../../../constants/colors';

const Wrapper = styled(MainGrid)`
  flex: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  max-width: 1232px;
`;

const StyledTableRow = styled(StyledRow)`
  & > th {
    border-top: 1px solid ${accent.sand2};
    border-bottom: 1px solid ${accent.sand2};

    &:first-child {
      border-left: 1px solid ${accent.sand2};
    }
    &:last-child {
      border-right: 1px solid ${accent.sand2};
    }
  }
`;

const {
  entities: {
    investmentStrategyImplementationPlans: {
      investmentStrategyImplementationPlansByIdSelector,
    },
  },
} = selectors;

export const ImplementationPlan = (props) => {
  const { title, subTitle } = props;

  const queryParams = new URLSearchParams(
    document.location.search.substring(1)
  );
  const strategyId = queryParams.get('strategyId');

  const implementationPlan = useSelector(
    investmentStrategyImplementationPlansByIdSelector(strategyId)
  );

  return (
    <Wrapper>
      <SlideHeader title={title} subTitle={subTitle} />

      {implementationPlan?.assetClasses ? (
        <ImplementationPlanTable
          rowComponent={StyledTableRow}
          implementationPlan={implementationPlan}
          rounds={implementationPlan?.rounds}
          monthsPerRound={implementationPlan?.monthsPerRound}
        />
      ) : null }
    </Wrapper>
  );
};
