import React from 'react';
import styled, { css } from 'styled-components';
import { groupBy } from 'lodash';

import { t, toLocaleDateString, truncateString } from '@formue-app/core';

import { accent, textLightest } from '../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_8,
} from '../../constants/spacing';

import { Checkbox } from '../formElements';
import { ParagraphSmall } from '../texts';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${SPACING_24};
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${SPACING_8};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${accent.ocean250};
  border: 2px solid ${accent.ocean250};
  padding: 11px 12px;
  border-radius: ${BORDER_RADIUS_LARGE};
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};

  p {
    font-weight: 500;
  }

  ${(props) =>
    !props.editable &&
    props.editMode &&
    css`
      p {
        opacity: 0.5;
      }
    `}

  ${(props) =>
    props.enabled &&
    !props.editMode &&
    css`
      &:hover {
        border: 2px solid ${props.header ? accent.ocean4 : accent.ocean270};
        background-color: ${props.header ? accent.ocean4 : accent.ocean270};
      }
    `}

  ${(props) =>
    props.editMode &&
    css`
      padding: 10px 12px;
      cursor: ${props.editable ? 'pointer' : 'default'};
    `}

  ${(props) =>
    props.header &&
    css`
      background-color: ${accent.ocean490};
      border: 2px solid
        ${props.editMode && !props.lightBackground
          ? accent.ocean470
          : accent.ocean490};

      p {
        color: ${textLightest};
      }
    `}
`;

const Icon = (props) => {
  const {
    editMode,
    editable,
    enabled,
    header,
    section,
    partialChecked,
    onClick,
  } = props;

  if (editMode) {
    return (
      <Checkbox
        partialChecked={header ? partialChecked : false}
        checked={enabled}
        size={20}
        onChange={onClick}
        style={{ marginBottom: 0 }}
        disabled={(!editable && !header) || (section.length === 1 && !editable)}
      />
    );
  } else {
    if (header) {
      return (
        <SpriteIconFunctional
          id="Simple arrow"
          direction="Right"
          size={14}
          color={textLightest}
          style={{ opacity: enabled ? 1 : 0.5 }}
        />
      );
    } else {
      return (
        <SpriteIconFunctional
          id="Simple arrow"
          direction="Right"
          size={14}
          style={{ opacity: enabled ? 1 : 0.5 }}
        />
      );
    }
  }
};

export const SlideItem = (props) => {
  const {
    item,
    section,
    editMode,
    itemClickHandler,
    lightBackground,
    disabled,
    truncateLength = 23,
  } = props;

  const enabled = disabled ? false : item.editable;

  if (!editMode && !item.enabled) return null;

  return (
    <StyledItem
      editable={enabled}
      enabled={item.enabled}
      editMode={editMode}
      key={`item-${item.id}`}
      onClick={(e) => {
        e.preventDefault();
        itemClickHandler(item, section, item.adminHeader);
      }}
      header={item.adminHeader}
      lightBackground={lightBackground}
    >
      <ParagraphSmall>
        {truncateString(
          item.adminTitle
            ? t(item.adminTitle, { date: toLocaleDateString(new Date()) })
            : item.title,
          truncateLength
        )}
      </ParagraphSmall>
      <Icon
        onClick={(e) => {
          e.preventDefault();
          itemClickHandler(item, section, item.adminHeader);
        }}
        header={item.adminHeader}
        partialChecked={section.some(
          (_item) => !_item.enabled && _item.editable
        )}
        editMode={editMode}
        editable={enabled}
        enabled={item.enabled}
        section={section}
      />
    </StyledItem>
  );
};

export const SlideTableOfContents = (props) => {
  const {
    slides,
    setActiveSlides,
    onItemNavigate,
    editMode = false,
    lightBackground = false,
    truncateLength,
    ...rest
  } = props;

  const toggleSlideStatus = (index, overrideStatus) => {
    const slidesCopy = [...slides];
    slidesCopy[index].enabled = !!overrideStatus
      ? overrideStatus
      : !slidesCopy[index].enabled;

    setActiveSlides(slidesCopy);
  };

  const sections = groupBy(slides, (item) => Math.floor(item.id));

  const itemClickHandler = (item, section, header) => {
    if (editMode && header) {
      // For header items we need to add some additional functionality as it controlls all the sub
      // items.

      // First we check if there are any disbaled items and keep that as the main status for all
      //sub items
      const subItemsState = section
        .slice(1, section.length)
        .filter((_item) => _item.editable)
        .some((_item) => !_item.enabled);

      // Then we can use that to override the toggle value with our own status based on the status
      // from all the sub values
      section
        .slice(1, section.length)
        .map(
          (_item) =>
            _item.editable &&
            toggleSlideStatus(slides.indexOf(_item), subItemsState)
        );

      // Return early to prevent actually changing the header value as it should be always on
      if (!item.editable) {
        return;
      }
    }

    if (editMode && item.editable) {
      toggleSlideStatus(slides.indexOf(item));
    } else if (!editMode && item.enabled) {
      onItemNavigate(item);
    }
  };

  return (
    <Wrapper {...rest}>
      {Object.values(sections).map((section, index) => (
        <Section key={`section-${index}`}>
          {section.map((item, itemIndex) => (
            <SlideItem
              key={`slide-${itemIndex}`}
              item={item}
              section={section}
              editMode={editMode}
              itemClickHandler={itemClickHandler}
              lightBackground={lightBackground}
              truncateLength={truncateLength}
            />
          ))}
        </Section>
      ))}
    </Wrapper>
  );
};
