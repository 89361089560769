import { apiFetch } from './utils/api';
// const mockedInvestmentProfile = require('./mocks/v1/investment-profile.json');

export const investmentProfile = {
  /**
   * @desc Get list of investment profile
   *
   * @return json - data or error message
   */
  get: async () => {
    // return mockedInvestmentProfile;
    const options = {
      method: 'GET',
    };

    const url = '/v1/wealth-plan/investment-profile';

    const { body } = await apiFetch(url, {
      ...options,
    });

    // return mockedInvestmentProfile;
    return body;
  },
};
