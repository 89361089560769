import { handleActions } from 'redux-actions';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';

const defaultState = {
  searchIndex: undefined,
};

export const reducer = handleActions(
  {
    [actions.setSearchIndex]: (state, { payload }) => {
      return {
        ...state,
        searchIndex: payload,
      };
    },
  },
  defaultState
);
