import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SPACING_8 } from '../../../constants/spacing';
import { Paragraph } from '../../texts';
import { ReactComponent as DoneIcon } from '../../../assets/icons/todo/done-icon.svg';
import { ReactComponent as NotDoneIcon } from '../../../assets/icons/todo/not-done-icon.svg';

const ListItem = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${SPACING_8};
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExpertInputSubtaskItem = (props) => {
  const { id } = props;

  const item = useSelector((state) => state.entities.tasks.byId[id]);
  const { title, status } = item;
  const completed = status.toLowerCase() === 'completed';

  return (
    <ListItem>
      <IconWrapper>{completed ? <DoneIcon /> : <NotDoneIcon />}</IconWrapper>
      <Paragraph>{title}</Paragraph>
    </ListItem>
  );
};
