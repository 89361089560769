import { t } from '@formue-app/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { accent } from '../../../constants/colors';
import { SPACING_48 } from '../../../constants/spacing';
import { easeInOut, longAnimationTime } from '../../../constants/animation';

import { H1, H6 } from '../../texts';
import { titleFontStack } from '../../../fonts';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 1;
  align-items: center;
  max-width: 900px;
`;

const StyledSubTitle = styled(H6)`
  position: relative;
  top: 20px;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime};

  ${(props) =>
    props.isInView &&
    css`
      top: 0;
      opacity: 1;
    `}
`;

const StyledTitle = styled(H1)`
  position: relative;
  top: 20px;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime} 0.2s;
  white-space: pre-line;

  ${(props) =>
    props.isInView &&
    css`
      top: 0;
      opacity: 1;
    `}
`;

export const ReportTitle = (props) => {
  const {
    title,
    subTitle,
    subTitleColor,
    titleColor,
    highlightColor,
    values,
    children,
    ...rest
  } = props;

  const [isInViewRef, isInView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  return (
    <TitleWrapper {...rest} ref={isInViewRef}>
      {subTitle ? (
        <StyledSubTitle
          style={{
            color: accent[subTitleColor],
            marginBottom: SPACING_48,
          }}
          isInView={isInView}
        >
          {t(subTitle)}
        </StyledSubTitle>
      ) : null}
      {children}
      {title ? (
        <StyledTitle
          style={{
            color: accent[titleColor],
          }}
          isInView={isInView}
        >
          <Trans
            i18nKey={title}
            values={values}
            components={{
              strong: (
                <span
                  style={{
                    color: accent[highlightColor],
                    fontWeight: 700,
                    fontFamily: titleFontStack,
                  }}
                />
              ),
            }}
          />
        </StyledTitle>
      ) : null}
    </TitleWrapper>
  );
};
