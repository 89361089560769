import React from 'react';

import { ContactCard } from '../../common/ContactCard';

export const ExpertInputAuthorsList = (props) => {
  const { item } = props;
  const { authors } = item;

  const hasAuthors = authors && authors.length > 0 && authors[0].name;

  if (!hasAuthors) return null;

  return authors.map((author, index) => (
    <ContactCard contact={author} columns={4} key={`contact-${index}`} />
  ));
};
