import { useEffect, useState } from 'react';

import investmentStrategySlides from './investment-strategy.json';
import { useEnabledStrategies } from './useEnabledStrategies';

export const useInvestmentStrategySlides = () => {
  const enabledStrategies = useEnabledStrategies();

  const [
    investmentStrategySlidesWithVariations,
    setInvestmentStrategySlidesWithVariations,
  ] = useState([]);

  useEffect(() => {
    let slides = [];
    investmentStrategySlides
      // We need to filter out slides that are not enabled and editable since those
      // cannot be chosen, but just disabled ones should be counted since
      // the user might enable those.
      .filter((slide) => slide.enabled || slide.editable)
      .forEach((slide) => {
        if (slide.component === 'StrategyAlternativeSlide') {
          const originalIdParts = slide.id.toString().split('.');
          enabledStrategies.forEach((strategy, index) => {
            const id = Number(
              `${originalIdParts[0]}.${originalIdParts[1] + (index + 1)}`
            );
            slides = [
              ...slides,
              {
                ...slide,
                id,
                strategyId: strategy.id,
                adminTitle: strategy.title,
                title: strategy.title,
              },
            ];
          });
        } else {
          slides = [...slides, slide];
        }
      });

    setInvestmentStrategySlidesWithVariations(slides);
    // This hook might need a dependency that will indicate
    // the change of the variations in the store.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledStrategies.length]);

  return investmentStrategySlidesWithVariations;
};
