import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import {
  selectors,
  t,
  verifyBankAccount,
  getBankAccountStatus,
  actions,
  isValidBankAccountNumber,
  stripInput,
} from '@formue-app/core';

import { BaseSection } from '../common/BaseSection';
import { H2, H4, Paragraph, ParagraphSmall } from '../texts';
import { SPACING_8, SPACING_24 } from '../../constants/spacing';
import { H4Size, H4LineHeight } from '../../constants/text';
import { textLink, uiActive } from '../../constants/colors';
import { mobileCondition } from '../../constants/media';
import { StyledGridElement } from '../layout/GridElement';
import { Input, StyledInputBase, Checkbox } from '../formElements';
import { ActivityIndicator } from '../common/ActivityIndicator';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';
import { Button } from '../buttons';

const { operatingCountrySelector } = selectors.auth;
const { updateRequestBegin } = actions.entities.forms;

const StyledH4 = styled(H4)`
  margin-bottom: ${SPACING_8};
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${SPACING_24};
`;

const Content = styled.div`
  margin: 0 auto;

  @media ${mobileCondition} {
    margin-right: auto;
    margin-left: 0;
    text-align: left;
  }
`;

const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-size: ${H4Size};
  line-height: ${H4LineHeight};
  font-weight: 500;
  color: ${textLink};
  float: right;
  margin-bottom: ${SPACING_8};
  cursor: pointer;
`;

const Legend = styled(Paragraph)`
  width: 80%;
`;

const StyledInput = styled(Input)`
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.error ? 'red' : 'rgba(24, 35, 97, 0.2)')};
  width: 100%;
`;

const StyledInputContainerDenmark = styled.div`
  ${StyledInputBase}
  padding: 0;
  display: flex;
  flex: row;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.error ? 'red' : 'rgba(24, 35, 97, 0.2)')};
  width: 100%;
`;

const StyledInputDenmark = styled(StyledInput)`
  background: none;
  border-width: 0;
  border-radius: 0;
  min-width: 0;

  &:first-child {
    width: 10em;
    border-right: 1px solid;
    border-color: ${(props) => (props.error ? 'red' : 'rgba(24, 35, 97, 0.2)')};
  }
`;

const InputContainer = styled.div`
  position: relative;
  margin: ${SPACING_24} 0 ${SPACING_24} 0;
`;

const StyledCheckIcon = styled(SpriteIconFunctional)`
  position: absolute;
  top: 15px;
  right: 10px;
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  top: 12px;
  right: 10px;
`;

const BankAccountNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  svg {
    margin-right: ${SPACING_8};
    position: relative;
    top: 1px;
    right: auto;
  }
`;

const ErrorHelp = styled(ParagraphSmall)`
  margin-top: ${SPACING_8};
  font-style: italic;
`;

const DanishBankAccountInput = (props) => {
  const { bankAccountNumber, valid, loading, onChangeBankAccountNumber } =
    props;

  const accountNumberInputRef = useRef(null);

  const regex = /^(.{4})(\d+)$/;
  let matches;

  if (bankAccountNumber && bankAccountNumber.length) {
    matches = bankAccountNumber.match(regex);
  }

  const [registrationNumber, setRegistrationNumber] = useState(
    matches ? matches[1] : ''
  );
  const [accountNumber, setAccountNumber] = useState(matches ? matches[2] : '');

  const onChangeRegistrationNumber = (event) => {
    const { value } = event.currentTarget;
    // To enable a user to just keep typing even after inputing
    // the first 4 numbers of the account number we capture everything
    // after the registration number, put it into the account number
    // variable, and the move focus of the cursor to the next input field.
    const matches = value.match(regex);
    if (matches) {
      setRegistrationNumber(matches[1]);
      if (!accountNumber) {
        setAccountNumber(matches[2]);
        // Move focus to this input
        accountNumberInputRef.current.focus();
      }
    } else {
      setRegistrationNumber(value);
    }
  };

  const onChangeAccountNumber = (event) => {
    const { value } = event.currentTarget;
    setAccountNumber(value);
  };

  // Whenever either registration or account number changes we
  // want the top level change handler to be updated with the
  // concatenated value
  useEffect(() => {
    onChangeBankAccountNumber(`${registrationNumber}${accountNumber}`);
  }, [accountNumber, registrationNumber, onChangeBankAccountNumber]);

  return (
    <StyledInputContainerDenmark error={!valid && !loading}>
      <StyledInputDenmark
        type="text"
        pattern="\d*"
        name="registrationNumber"
        value={registrationNumber}
        placeholder={t('settings:accounts:bankAccountForm:registrationNumber')}
        onChange={onChangeRegistrationNumber}
        error={!valid && !loading}
        tabIndex="1"
      />
      <StyledInputDenmark
        type="text"
        pattern="\d*"
        ref={accountNumberInputRef}
        maxLength="10"
        name="accountNumber"
        value={accountNumber}
        placeholder={t('settings:accounts:bankAccountForm:accountNumber')}
        onChange={onChangeAccountNumber}
        tabIndex="2"
      />
    </StyledInputContainerDenmark>
  );
};

const BankAccountForm = (props) => {
  const {
    bankAccountNumber,
    setBankAccountNumber,
    bankAccountOwner,
    onComplete,
  } = props;

  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [invalidOwner, setInvalidOwner] = useState(false);
  const [editableNumber, setEditableNumber] = useState(bankAccountNumber);
  const operatingCountry = useSelector(operatingCountrySelector);

  const onChangeBankAccountNumber = async (value) => {
    setValid(false);
    setEditableNumber(value);

    if (!isValidBankAccountNumber(value)) {
      return null;
    } else {
      // Set valid and loading to true while waiting for bank account validation
      setValid(true);
      setLoading(true);

      // Do some validation to make sure we only submit
      // what looks like correct bank acocunt numbers.
      const { isOwner } = await verifyBankAccount.verify({
        bankAccountNumber: stripInput(value),
        entityId: bankAccountOwner,
      });

      setLoading(false);
      setValid(isOwner);
      setInvalidOwner(!isOwner);
    }
  };

  const onChangeConsent = () => {
    setConsent(!consent);
    // If number is not edited since we entered the edit form
    // we can assume the form is still valid
    if (editableNumber && editableNumber === bankAccountNumber) {
      setValid(true);
    }
  };

  const onSubmit = () => {
    setBankAccountNumber(editableNumber);
    onComplete(editableNumber);
  };

  return (
    <form id="bank-account-form" onSubmit={onSubmit}>
      <div>
        <Legend>{t('settings:accounts:bankAccountForm:legend')}</Legend>
        <InputContainer>
          {operatingCountry === 'dk' ? (
            // Handle denmark differently, they are used to a different UI for inputing
            // bank account numbers than norway and sweden.
            <DanishBankAccountInput
              bankAccountNumber={editableNumber}
              valid={valid}
              loading={loading}
              operatingCountry={operatingCountry}
              onChangeBankAccountNumber={onChangeBankAccountNumber}
            />
          ) : (
            <StyledInput
              type="text"
              name="bankAccountNumber"
              value={editableNumber}
              error={!valid && !loading}
              placeholder={t('settings:accounts:bankAccountForm:accountNumber')}
              onChange={(event) =>
                onChangeBankAccountNumber(event.currentTarget.value)
              }
              tabIndex="1"
            />
          )}

          {valid && !loading ? (
            <StyledCheckIcon id="Check" size={18} color={uiActive} />
          ) : null}
          {loading ? <StyledActivityIndicator size={18} /> : null}
          <ReactMarkdown components={{ p: ErrorHelp, a: styled.a`` }}>
            {invalidOwner && !loading
              ? t('profile:bankAccountForm:invalidDescription')
              : null}
          </ReactMarkdown>
          <StyledParagraph style={{ marginTop: SPACING_8 }}>
            {t(`settings:accounts:bankAccountForm:helpText`, {
              context: operatingCountry,
            })}
          </StyledParagraph>
        </InputContainer>
      </div>

      <div>
        <Checkbox
          type="checkbox"
          id="consent"
          name="consent"
          checked={consent}
          onChange={onChangeConsent}
          label={t('settings:accounts:bankAccountForm:consentLabel')}
        />
        <br />
      </div>

      <Button
        type="submit"
        highlight={true}
        form="bank-account-form"
        disabled={!consent || !valid}
      >
        {t('globals:confirm')}
      </Button>
    </form>
  );
};

const BankAccountSummary = (props) => {
  const { bankAccountNumber, bankAccountStatus } = props;
  const { status, verified } = getBankAccountStatus(bankAccountStatus);
  const operatingCountry = useSelector(operatingCountrySelector);

  const formatDanishBankAccountNumber = (input) => {
    if (!input || !input.length) {
      return '';
    }

    const regex = /^(.{4})(\d+)$/;
    let matches;
    matches = input.match(regex);

    return matches[1] + ' - ' + matches[2];
  };

  return (
    <>
      {bankAccountNumber ? (
        <BankAccountNumberContainer>
          {operatingCountry === 'dk' ? (
            <p>{formatDanishBankAccountNumber(bankAccountNumber)}</p>
          ) : (
            <p>{bankAccountNumber}</p>
          )}
          <StatusContainer>
            {verified && (
              <StyledCheckIcon height="18" width="18" style={{ top: '3px' }} />
            )}
            <p>{status}</p>
          </StatusContainer>
        </BankAccountNumberContainer>
      ) : (
        <p>{t('settings:accounts:bankAccountForm:notRegistered')}</p>
      )}
    </>
  );
};

export const BankAccountSection = (props) => {
  const { columns, account, ...rest } = props;

  const [editMode, setEditMode] = useState(false);
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const dispatch = useDispatch();
  const operatingCountry = useSelector(operatingCountrySelector);

  const { sfid: bankAccountOwner, financialAccount, editable } = account;

  const {
    accountNumber: financialAccountNumber = null,
    status: bankAccountStatus = null,
  } = financialAccount || {};

  useEffect(() => {
    setBankAccountNumber(financialAccountNumber);
  }, [financialAccountNumber]);

  const data = {
    bankAccountNumber,
    setBankAccountNumber,
    bankAccountOwner,
    bankAccountStatus,
  };

  const onComplete = (newBankAccountNumber) => {
    setEditMode(false);
    const formId = 'bank-account';
    const formData = {
      bankAccountOwner,
      bankAccountNumber: newBankAccountNumber,
    };

    dispatch(updateRequestBegin({ args: { id: formId }, data: formData }));
  };

  return (
    <StyledGridElement columns={columns} {...rest}>
      <H2 style={{ marginBottom: SPACING_8 }}>
        {t(`settings:accounts:bankAccountForm:title`)}
      </H2>
      <StyledParagraph>
        {t(`settings:accounts:bankAccountForm:description`, {
          context: operatingCountry,
        })}
      </StyledParagraph>
      <BaseSection>
        <Content>
          <Header>
            <StyledH4>
              {t(`settings:accounts:bankAccountForm:accountNumber`)}
            </StyledH4>
            {!editMode && editable && (
              <StyledLink onClick={() => setEditMode(true)}>
                {bankAccountNumber ? t('globals:edit') : t('globals:add')}
              </StyledLink>
            )}
            {editMode && (
              <StyledLink onClick={() => setEditMode(false)}>
                {t('globals:cancel')}
              </StyledLink>
            )}
          </Header>

          {editMode ? (
            <BankAccountForm {...data} onComplete={onComplete} />
          ) : (
            <BankAccountSummary {...data} />
          )}
        </Content>
      </BaseSection>
    </StyledGridElement>
  );
};
