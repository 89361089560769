import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, useResource } from '@formue-app/core';
import { BaseSection } from '../common/BaseSection';
import { MainGrid } from '../layout';
import {
  mobileCondition,
  desktopCondition,
  notDesktopCondition,
} from '../../constants/media';
import { SPACING_16, BORDER_RADIUS_LARGE } from '../../constants/spacing';
import { shortAnimationTime, easeInOut } from '../../constants/animation';
import { NavigatableItem } from '../common/NavigatableItem';

import {
  otherTasksSelector,
  signingTasksSelector,
  unreadSelector,
} from '../../store/ui/todo/selectors';

import { accent, backgroundWhite } from '../../constants/colors';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { SpriteIcon } from '../common/SpriteIcon';

const StyledGridElement = styled.div`
  grid-column: span ${(props) => props.columns};
  border-radius: ${BORDER_RADIUS_LARGE};
  background-color: ${backgroundWhite};
  transition: all ${shortAnimationTime} ${easeInOut};

  @media ${desktopCondition} {
    border: 1px solid ${accent.sand1};
  }

  @media ${mobileCondition} {
    grid-column: span ${(props) => props.columns * 3};
    margin-left: 0;
    margin-right: 0;
  }

  svg {
    float: left;
    flex-shrink: 0;
  }
`;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_16};

  ${(props) =>
    props.columns < 6 &&
    `
    padding: 0;
    background: none;
    grid-column: span 4;

    @media ${mobileCondition} {
      grid-column: span 12;
    }
  `}

  @media ${notDesktopCondition} {
    padding: 0;
    background-color: transparent;
  }
`;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;

  ${(props) =>
    props.columns < 6 &&
    `
    grid-gap: ${SPACING_16} 0;
    column-gap: 0;
  `}
`;

export const TodoListCompact = (props) => {
  const { columns } = props;

  const loading = useResource([
    'TASKS/INDEX',
    'EXPERT_INPUTS/INDEX',
    // We _don't_ want the loading indicator to show when fetching a single
    // signing event (SIGNING_EVENTS/SHOW) while we poll for updates
    'SIGNING_EVENTS/INDEX',
  ]);

  const numberOfTasksToSign = useSelector(signingTasksSelector).length;
  const numberOfOtherTasks = useSelector(otherTasksSelector).length;
  const numberOfUnreadDocuments = useSelector(unreadSelector).length;

  const renderUnreadDocuments = () => {
    let i18nKey = 'unread';
    if (numberOfUnreadDocuments > 9) {
      i18nKey = 'unread_many';
    }
    return (
      <StyledGridElement columns={columns < 6 ? 12 : columns / 3}>
        <NavigatableItem
          text={t(`dashboard:todo:${i18nKey}`, {
            count: numberOfUnreadDocuments,
          })}
          to="/documents"
        >
          <SpriteIcon id="Document Single" size={24} />
        </NavigatableItem>
      </StyledGridElement>
    );
  };

  const renderOtherTasks = () => {
    return (
      <StyledGridElement columns={columns < 6 ? 12 : columns / 3}>
        <NavigatableItem
          text={t('dashboard:todo:other', {
            count: numberOfOtherTasks,
          })}
          to="/todo"
        >
          <SpriteIcon id="To Do" size={24} />
        </NavigatableItem>
      </StyledGridElement>
    );
  };

  const renderSigningTasks = () => {
    return (
      <StyledGridElement columns={columns < 6 ? 12 : columns / 3}>
        <NavigatableItem
          text={t('dashboard:todo:toSign', {
            count: numberOfTasksToSign,
          })}
          to="/todo"
        >
          <SpriteIcon id="Signature" size={24} />
        </NavigatableItem>
      </StyledGridElement>
    );
  };

  return (
    <StyledBaseSection columns={columns}>
      {loading ? (
        <CenteredActivityIndicator background={accent.sand1} />
      ) : (
        <StyledSubGrid columns={columns}>
          {renderSigningTasks()}
          {renderOtherTasks()}
          {renderUnreadDocuments()}
        </StyledSubGrid>
      )}
    </StyledBaseSection>
  );
};
