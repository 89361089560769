import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { investmentStrategyProducts } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer(
  'investment-strategy-product-allocations'
);

// Create the sagas for this entity
function* readInvestmentStrategyProducts() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(investmentStrategyProducts.get, args);

      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: { error, args } });
    }
  });
}

export const sagas = [readInvestmentStrategyProducts, ...resourceSagas];
