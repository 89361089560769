import React from 'react';
import {
  formatTwrValue,
  getColorForAssetClassGroup,
  selectors,
  getAssetClassName,
} from '@formue-app/core';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { assetClassDefaultColorScheme } from '../../../constants/colors';
import { AssetClassGroup } from './AssetClassGroup';
import { notDesktopCondition } from '../../../constants/media';

const {
  entities: {
    portfolios: { portfolioAssetGroupsSelector },
  },
} = selectors;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media ${notDesktopCondition} {
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 20px;
  }
`;

const StyledAssetClassGroup = styled(AssetClassGroup)`
  margin-left: 40px;

  @media ${notDesktopCondition} {
    margin-left: 0;
  }
`;

export const AssetClassGroups = () => {
  const assetClassGroups = useSelector(portfolioAssetGroupsSelector);

  if (!assetClassGroups.length) return null;

  return (
    <StyledContainer>
      {assetClassGroups.map((item, index) => (
        <StyledAssetClassGroup
          key={`asset-class-group-${index}`}
          title={getAssetClassName(item.group)}
          value={formatTwrValue(item.twr)}
          color={getColorForAssetClassGroup(
            item.group,
            assetClassDefaultColorScheme
          )}
        />
      ))}
    </StyledContainer>
  );
};
