import {
  takeLeading,
  put,
  call,
} from 'redux-saga/effects';

import { actions as entityActions } from '../../entities/actions';
import { actions } from './actions';

const {
  tasks: tasksActions,
  signingEvents: signingEventsActions,
  expertInput: expertInputActions,
} = entityActions;

function* load() {
  yield takeLeading(actions.load, function*() {
    yield put(tasksActions.indexRequestBegin());
    yield put(signingEventsActions.indexRequestBegin());
    yield put(expertInputActions.indexRequestBegin());
  });
}

export const sagas = [
  load,
];
