import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import styled, { css } from 'styled-components';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';

import { getLocalizedMonthName } from '@formue-app/core';
import { paragraphSize } from '../../../constants/text';
import { accent } from '../../../constants/colors';
import { H6 } from '../../texts';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';

import { SpriteIcon } from '../../common/SpriteIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  align-items: stretch;
`;

const Label = styled(H6)`
  color: ${accent.ocean470};
  text-transform: uppercase;
`;

const StyledIcon = styled(SpriteIcon)`
  margin-right: ${SPACING_8};
  position: absolute;
  left: ${SPACING_16};
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid ${accent.ocean440};
  padding: 10px 0;
  margin: ${SPACING_8} 0;
  border-radius: ${BORDER_RADIUS_LARGE};
  position: relative;
  cursor: pointer;
`;

const StyledDate = styled.p`
  padding-left: 40px;
`;

const StyledPicker = styled(Picker)`
  ${css`
    .rmp-container .rmp-popup {
      width: 236px !important;
    }
    .rmp-container .rmp-popup .rmp-pad {
      width: 236px !important;
    }

    .rmp-container .rmp-popup .rmp-pad li {
      margin: 10px;
      padding: 6px 6px;
      width: 24% !important;
      line-height 1.15;
      font-size: ${paragraphSize};
    }

    .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker {
      background-color: ${accent.ocean1};
    }

    .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
      background-color: ${accent.ocean120};
    }

    .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
      background-color: ${accent.ocean4};
    }

    .rmp-container .rmp-popup .rmp-pad > div label {
      font-size: ${paragraphSize};
      font-weight: 500;
    }

    .rmp-container .rmp-popup .rmp-pad > div i {
      font-weight: 500;
    }

    .rmp-container .rmp-popup.light .rmp-pad {
      color: ${accent.ocean1};
    }
    .rmp-container .rmp-popup.light .rmp-pad .disable {
      color: ${accent.ocean120};
    }
  `}
`;

// In qlik we are working with a field called "period" that expects to reveive a value
// formated YYYYMM
const formatPeriod = (year, month) => {
  if (month < 10) {
    return `${year}0${month}`;
  }
  return `${year}${month}`;
};

export const ExtendedReportingMonthFilter = forwardRef((props, ref) => {
  const {
    label,
    onChange,
    defaultDate = { year: 0, month: 0 },
    minDate = { year: 2016, month: 1 },
    maxDate = { year: 2022, month: 7 },
  } = props;

  const pickMonthRef = useRef();

  const [activeDate, setActiveDate] = useState(defaultDate);

  // This is here so that we in the parent component can use the ref object
  // to call on a method called "reset".
  // const monthFilterRef = useRef();
  // <ExtendedReportingMonthFilter ref={monthFilterRef} />
  // monthFilterRef.current.reset();
  useImperativeHandle(ref, () => ({
    reset() {
      setActiveDate(defaultDate);
      // At this time we ideally want to reset the UI state of the component
      // as well, but I haven't found a way of doing that. The text is updated
      // but when one opens the month picker it still shows a selected value.
    },
  }));

  const handleMonthChange = (year, month) => {
    setActiveDate({ year, month });
    onChange(formatPeriod(year, month));
    // Dismiss picker after making selection
    pickMonthRef.current.dismiss();
  };

  const monthNames = Array.from(Array(12).keys()).map((number) => {
    return getLocalizedMonthName(number);
  });

  const makeText = (value) => {
    if (value) {
      const { year, month } = value;
      if (year && month) return `${getLocalizedMonthName(month - 1)}. ${year}`;
    }
    return '?';
  };

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Wrapper
        onClick={(event) => {
          if (!pickMonthRef.current.state.showed) {
            pickMonthRef.current.show();
          }
        }}
      >
        <StyledIcon id="Time" size={SPACING_16} />
        <StyledPicker
          ref={pickMonthRef}
          years={{ min: minDate, max: maxDate }}
          value={activeDate}
          onChange={handleMonthChange}
          lang={monthNames}
        >
          <StyledDate>{makeText(activeDate)}</StyledDate>
        </StyledPicker>
      </Wrapper>
    </Container>
  );
});
