import React from 'react';
import { useSelector } from 'react-redux';

import { useResource, selectors } from '@formue-app/core';
import { InvestmentStrategiesList } from '../../components/investmentStrategies/InvestmentStrategiesList';

const {
  entities: {
    investmentStrategies: { publishedInvestmentStrategiesSelector },
  },
} = selectors;

export const StrategiesPage = (props) => {
  const loading = useResource(['INVESTMENT_STRATEGIES/INDEX']);
  const strategies = useSelector(publishedInvestmentStrategiesSelector);
  return (
    <>
      {loading ? null : (
        <InvestmentStrategiesList strategies={strategies} columns={12} />
      )}
    </>
  );
};
