import React from 'react';
import styled, { css } from 'styled-components';

import { GridElement } from '../../layout';
import { H4, ParagraphSmall } from '../../texts';
import { CheckIcon } from '../../common/CheckIcon';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_16,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { desktopCondition } from '../../../constants/media';

const StyledContainer = styled(GridElement)`
  @media ${desktopCondition} {
    padding: ${SPACING_24} 100px ${SPACING_24} ${SPACING_24};
  }

  padding: ${SPACING_24};
  border: 1px solid ${accent.sand150};
  border-radius: ${BORDER_RADIUS_LARGE};
  background-color: ${accent.sand150};

  ${(props) =>
    !props.selected &&
    css`
      background-color: transparent;
      opacity: 0.7;
      border: 1px dashed ${accent.sand3};
    `}
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
  align-items: center;
  margin-bottom: ${SPACING_16};
`;

export const InvestmentPreferencesChoiceItem = ({
  title,
  text,
  selected,
  ...props
}) => {
  return (
    <StyledContainer selected={selected} {...props}>
      <TitleWrapper>
        <CheckIcon checked={selected} />
        <H4>{title}</H4>
      </TitleWrapper>
      <ParagraphSmall>{text}</ParagraphSmall>
    </StyledContainer>
  );
};
