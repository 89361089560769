import { SPACING_16, SPACING_24 } from './spacing';

export const contentColumnCount = 12;
export const contentColumnGap = SPACING_16;
export const contentColumnGapMobile = SPACING_16;
export const contentRowGap = contentColumnGap;
export const contentRowGapMobile = contentColumnGapMobile;
export const contentPadding = SPACING_24;
export const contentPaddingMobile = SPACING_16;
export const menuMaxWidth = 256;
export const mobileMenuMaxWidth = 300;
export const menuClosedWidth = 80;
export const layoutMaxWitdh = 1400;
export const contentMaxWitdh = 1128;
export const menuClosedContentMaxWitdh = 1280;
