import React from 'react';
import styled from 'styled-components';

import { Paragraph, ParagraphLarge } from '../../texts';
import { BaseSection } from '../../common/BaseSection';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_32,
  SPACING_4,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { Breadcrumbs } from '../../common/Breadcrumbs';

const Container = styled(BaseSection)`
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background-color: ${accent.sand150};
  padding: ${SPACING_32};
`;

const Content = styled.div`
  width: 70%;
`;

const Heading = styled(ParagraphLarge)`
  padding-top: ${SPACING_4};
  padding-bottom: ${SPACING_16};
  font-weight: 600;
`;

const StyledParagraph = styled(Paragraph)`
  padding-bottom: ${SPACING_12};
`;

export const TermsAndConditionsNO = () => (
  <>
    <Breadcrumbs
      items={[
        { title: '1. Bakgrunn og oppdrag' },
        { title: '2. Porteføljen' },
        { title: '3. Rammer for forvaltningen' },
        { title: '4. Avkastning' },
        { title: '5. Honorar' },
        { title: '6. Varighet og oppsigelse' },
      ]}
      style={{ margin: `${SPACING_16} 0` }}
      itemsSpacing={16}
    />
    <Container>
      <Content>
        <Heading>1. Bakgrunn og oppdrag</Heading>
        <StyledParagraph>
          Formålet med denne avtale (heretter kalt
          "Porteføljeforvaltningsavtalen") er å regulere vilkårene for tjenesten
          porteføljeforvaltning Formue Norge AS («Forvalter») tilbyr
          Oppdragsgiver (spesifisert på side 1) med hensyn til Oppdragsgivers
          portefølje av finansielle instrumenter. Oppdragsgiver og Forvalter er
          i fellesskap omtalt som «Partene».
        </StyledParagraph>
        <StyledParagraph>
          Porteføljeforvaltningsavtalen er utarbeidet i tillegg til Kundeavtalen
          og skal leses i sammenheng med alminnelige forretningsvilkår.
        </StyledParagraph>
        <StyledParagraph>
          Oppdragsgiver gir Forvalter en eksklusiv rett til å forvalte en
          nærmere angitt del av Oppdragsgivers portefølje
          («Forvaltningsporteføljen»), som beskrevet i punkt 2 nedenfor. Gjennom
          den diskresjonære forvaltningen ønsker Partene å sikre at justeringer
          i porteføljen skal skje regelmessig og at Oppdragsgiver ikke må
          involveres ved slike justeringer. Formålet med den diskresjonære
          forvaltningen er å sikre at Oppdragsgivers portefølje i størst mulig
          grad er i tråd med Oppdragsgivers valgte strategi for
          Forvaltningsporteføljen. Svingninger i markedet og innenfor de ulike
          aktivaklassene kan føre til at porteføljen midlertidig avviker fra
          strategien innenfor de rammer man har satt.
        </StyledParagraph>
        <StyledParagraph>
          Gjennom denne avtalen gir Oppdragsgiver Forvalter fullmakt til å utøve
          eierrettighetene knyttet til Forvaltningsporteføljen på Oppdragsgivers
          vegne. Dette innebærer blant annet rett til å kjøpe, tegne, selge
          eller på annen måte disponere over verdipapirfond og andre finansielle
          instrumenter som til enhver tid befinner seg i
          Forvaltningsporteføljen. Forvalter kan også på vegne av Oppdragsgiver
          inngå alle avtaler som Forvalter anser som nødvendige eller
          hensiktsmessige i forbindelse med forvaltningen av
          Forvaltningsporteføljen. På oppfordring skal Forvalter gi
          Oppdragsgiver informasjon om alle slike avtaler.
        </StyledParagraph>
        <StyledParagraph>
          Forvalter skal holde seg innenfor de rammer denne
          Porteføljeforvaltningsavtalens punkt 3 fastsetter. Oppdragsgiver
          forplikter seg til å informere Forvalter om endringer i egen
          finansiell situasjon, mål eller behov som kan påvirke Formuesplanen og
          disse rammene.
        </StyledParagraph>

        <Heading>2. Porteføljen</Heading>
        <StyledParagraph>
          I Forvaltningsporteføljen kan Forvalter anvende følgende finansielle
          instrumenter i forvaltningen: UCITS-fond (Undertakings for the
          Collective Investment of Transferable Securities), alternative
          investeringsfond (AIF) og vil omfatte også innskudd på Kundekonto samt
          øvrige verdipapirer på Formue depot som Oppdragsgiver ønsker ikke skal
          selges. Forvalter kan ikke selge disse verdipapirene eller foreta
          ytterligere kjøp.
        </StyledParagraph>
        <StyledParagraph>
          Alle fond som anvendes skal være hjemmehørende i EØS-området og under
          tilsyn av en EØS- tilsynsmyndighet. Forvaltningsporteføljen skal bestå
          av finansielle instrumenter innenfor hver aktivaklasse som er anbefalt
          av Forvalter. Det vil kunne foretas endringer i finansielle
          instrumenter i Forvaltningsporteføljen for å samstemme med
          Forvalterens anbefalte investeringsunivers. Anbefalte produkter er
          fond som velges av Formue og som sikrer kvaliteten av investeringene.
        </StyledParagraph>
        <StyledParagraph>
          Forvaltningsportføljen vil bestå av produkter anbefalt av forvalter og
          vil dermed følge Forvalterens investeringstilnærming knyttet til
          bærekraft. Porteføljeavtalen vil forvaltes slik at den fremmer
          bærekraftige egenskaper i henhold til Offentlighetsforordningen (SFDR)
          artikkel 8.
        </StyledParagraph>
        <StyledParagraph>
          Forvalters anbefalte investeringsunivers kan inkludere aktivaklassene
          private equity og eiendom. Disse investeringene vil gjennomføres ved
          bruk av AIF som ofte ikke innløses.
        </StyledParagraph>
        <StyledParagraph>
          Ved inngåelse av Porteføljeforvaltningsavtalen kan Oppdragsgiver ha en
          eksisterende portefølje med bankinnskudd og verdipapirer
          («Inngangsporteføljen»). Likvide verdipapirer i Inngangsporteføljen
          som Oppdragsgiver ønsker at Forvalter skal selge vil selges gjennom en
          separat fullmakt.
        </StyledParagraph>
        <StyledParagraph>
          Forvaltningsporteføljen består av investeringer som ligger på depot
          hos Forvalter (inkludert investeringer fra Inngangsporteføljen som
          ikke ønskes solgt) og inkluderer også Kundekonto. Øvrige verdipapirer
          eller bankbeholdninger (utenfor depoet hos Forvalter), heretter kalt
          «Rapporteringsporteføljen» omfattes ikke av denne avtalen.
          Forvaltningsporteføljen og Rapporteringsporteføljen utgjør samlet
          kundens totalportefølje («Totalporteføljen»).
        </StyledParagraph>

        <Heading>3. Rammer for forvaltningen</Heading>
        <StyledParagraph>
          Rammene for forvaltning av Forvaltningsporteføljen er fastsatt i
          dokumentet Investeringsstrategi som følger med Formuesplanen. Dette
          dokumentet sammenfatter blant annet Oppdragsgivers mål, behov og
          risikopreferanser og er tilgjengelig i Formue-appen og på Kundenettet.
        </StyledParagraph>
        <StyledParagraph>
          Oppdragsgiver har en strategi beskrevet i Formuesplanen som tar hensyn
          til alle investeringer i Oppdragsgivers Totalportefølje. De midlene
          som inngår i den diskresjonære forvaltningen, har egen strategi og
          rammer som er beskrevet i Investeringsstrategi til Formuesplanen. De
          to strategiene kan være ulike, avhengig av innholdet i
          Rapporteringsporteføljen og Oppdragsgivers ønsker.
        </StyledParagraph>
        <StyledParagraph>
          I forbindelse med handler kan allokeringen til aktivaklassen
          pengemarked midlertidig være høyere enn øvre ramme, og allokeringen
          til øvrige aktivaklasser midlertidig være lavere enn nedre ramme.
        </StyledParagraph>
        <StyledParagraph>
          Etter avtaleinngåelse vil Forvalter normalt bruke opptil 12 måneder
          for å implementere endringer i den likvide delen av porteføljen
          (rentefond og aksjefond). Oppdragsgivers portefølje vil i denne
          perioden kunne avvike fra strategen spesifisert i
          Investeringsstrategien til Formuesplanen mens midlene implementeres.
          Nærmere beskrivelse av implementeringen i Investeringsstrategien i
          avsnittet Implementering.
        </StyledParagraph>
        <StyledParagraph>
          Dersom Investeringsstrategien til Formuesplanen inneholder allokering
          til aktivaklassene eiendom og private equity, er disse investeringer
          langsiktige og allokeringen bygges opp over flere år. I denne fasen
          vil det kunne oppstå brudd på de fastsatte rammene. Forvalter må
          tilpasse Forvaltningsporteføljens strategi og rammer midlertidig, mens
          porteføljen gradvis styres mot fastsatt strategi. Tilpasningen av
          kundens strategi gjøres i de tilfeller der det på kort sikt ikke er
          mulig å holde porteføljen innenfor ordinære rammer og skyldes
          likviditeten i eiendom og private equity. Tilpasningen gjøres ved at
          over- eller underallokeringen i eiendom og private equity fordeles på
          øvrige likvide aktivaklasser.
        </StyledParagraph>

        <Heading>4. Avkastning – innskudd – uttak</Heading>
        <StyledParagraph>
          All avkastning tillegges Forvaltningsporteføljen. Forvalter tar
          kontakt med Oppdragsgiver dersom Forvaltningsporteføljen har falt 10
          prosentpoeng eller mer i løpet av siste måned eller siden oppstart for
          porteføljer med kortere historikk.
        </StyledParagraph>
        <StyledParagraph>
          Avkastningen i Forvaltningsporteføljen vil måles og rapporteres mot en
          referanseindeks som består av aksje- og rentedel som gjenspeiler
          andelen aksjer og renter i Investeringsstrategien til Formuesplan.
          Nærmere informasjon om valg av indeks angis i alminnelige
          forretningsvilkår.
        </StyledParagraph>
        <StyledParagraph>
          Dersom Oppdragsgiver ønsker å tilføre kontanter til
          Forvaltningsporteføljen, sender Oppdragsgiveren en melding til
          Oppdragsgivers rådgiver eller andre etablerte kommunikasjonskanaler
          omtalt i Oppdragstakers alminnelige forretningsvilkår med ønsket
          beløp.
        </StyledParagraph>
        <StyledParagraph>
          Dersom Oppdragsgiver ønsker uttak fra Forvaltningsporteføljen, må
          Oppdragsgiver sende melding om ønsket uttaksbeløp til Forvalter
          gjennom sin personlige rådgiver eller andre etablerte
          kommunikasjonskanaler omtalt i Forvalters alminnelige
          forretningsvilkår. Utbetalinger vil skje når Forvalter har mottatt
          oppgjør for de(t) innløste verdipapir(er). Forvalter er ikke ansvarlig
          hvis ønsket uttaksbeløp overstiger verdien av midlene i likvide fond
          som kan handles daglig eller ukentlig. Utbetaling vil skje til
          Oppdragsgivers registrerte utbetalingskonto. Varslingsfrist og metode
          for varsling kan endres ved informasjon på Kundenett.
        </StyledParagraph>
        <StyledParagraph>
          For kunde som har aksjesparekonto, vil bytter mellom aksje- og
          rentefond kunne medføre en skattemessig konsekvens. Skatt påløper
          dersom uttaket fra aksjeparekonto er større enn innskuddet. Det gjøres
          ikke en skattemessig vurdering ved reallokeringer i
          Forvaltningsporteføljen.
        </StyledParagraph>

        <Heading>5. Honorar</Heading>
        <StyledParagraph>
          Honorarer i Porteføljeforvaltningsavtalen følger honorarer i
          Kundeavtalen.
        </StyledParagraph>
        <StyledParagraph>
          Fond som inngår i Forvaltningsporteføljen vil ha tilknyttede
          produktkostnader som belastes av leverandørene. Disse kostnader vil
          kunne variere over tid, enten som følge av endringer i kostnadene i
          fond eller ved bytte av fond i Forvaltningsporteføljen.
        </StyledParagraph>

        <Heading>6. Varighet og oppsigelse – endringer</Heading>
        <StyledParagraph>
          Porteføljeforvaltningsavtalen trer i kraft ved undertegning og løper
          inntil den blir skriftlig oppsagt av en av Partene. Oppsigelse av
          Kundeavtalen regnes også som oppsigelse av
          Porteføljeforvaltningsavtalen.
        </StyledParagraph>
        <StyledParagraph>
          Dersom Oppdragsgiver gjør uttak eller endringer i
          Forvaltningsporteføljen som gjør at Forvalter ikke kan overholde de
          avtalte rammer med normal forvaltning (eksempelvis for høy andel
          verdipapirer som Forvalter ikke kan selge og/ eller illikvide
          verdipapirer), skal Forvalter varsle kunden med 1 måneds frist for
          oppretting, ellers er Porteføljeforvaltningsavtalen å anse som
          oppsagt.
        </StyledParagraph>
        <StyledParagraph>
          Dersom Porteføljeforvaltingsavtalen sies opp, vil den diskresjonære
          forvaltningen umiddelbart opphøre, og kundeforholdet utelukkende
          reguleres av Kundeavtalen. Dette gjelder også ved Forvalters
          oppsigelse av Porteføljeforvaltningsavtalen.
        </StyledParagraph>
        <StyledParagraph>
          Avtaler om kjøp eller salg som Forvalter har inngått på Oppdragsgivers
          vegne før skriftlig oppsigelse har kommet til Forvalters kunnskap vil
          være bindende for Oppdragsgiver.
        </StyledParagraph>
        <StyledParagraph>
          Når Porteføljeforvaltningsavtalen inneholder en allokering til
          aktivaklassene private equity og/eller eiendom vil
          Diskresjonærforvaltningsporteføljen være eksponert for høyere
          likviditetsrisiko. Investeringer i disse aktivaklasser består av et
          førsteinnskudd og en kommitering som kalles inn når det foretas
          underliggende investeringer. Eventuelle betydelige innløsninger
          initiert av Kunden av den likvide delen av
          Diskresjonærforvaltningsporteføljen vil kunne medføre manglende evne
          til å betjene den uinnkalte kapital. Kunden er ansvarlig for den
          samlede forpliktelsen som er resultat av Formues forvaltning av
          Porteføljeforvaltningsavtalen. Formue vil kontakte Kunden dersom det
          ikke er gjennomførbart å videreføre Porteføljeforvaltningsavtalen.
        </StyledParagraph>
        <StyledParagraph>
          Oppdragsgiver bekrefter å ha lest og forstått
          Porteføljeforvaltningsavtalen og Forvalters alminnelige
          forretningsvilkår, som sammen med Kundeavtalen og
          Porteføljeforvaltningsavtalen regulerer oppdragsforholdet mellom
          Oppdragsgiver og Forvalter. Porteføljeforvaltningsavtalen er å anse
          som et tillegg til eksisterende Kundeavtale.
          Porteføljeforvaltningsavtalen har forrang foran både Kundeavtalen og
          alminnelige forretningsvilkår.
        </StyledParagraph>

        <StyledParagraph style={{ fontWeight: 500 }}>
          Kundens bekreftelse
        </StyledParagraph>
        <StyledParagraph>
          Jeg godkjenner denne avtalen ved å signere med BankID
        </StyledParagraph>
      </Content>
    </Container>
  </>
);
