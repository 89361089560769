import { createActions } from 'redux-actions';

export const actions = createActions({
  USER: {
    SET_IMPERSONATION_ID: ssid => ssid,
    SET_IS_IMPERSONATING: boolean => boolean,
    SET_IS_EMPLOYEE_CLIENT_USER: boolean => boolean,
    SET_LANGUAGE: language => language,
  },
}).user;
