import React from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash';

import { H4 } from '../../texts';
import { longAnimationTime, easeInOut } from '../../../constants/animation';
import { mobileCondition } from '../../../constants/media';
import { H5LineHeight, H5Size } from '../../../constants/text';

const LabelWrapper = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  transition: all ${longAnimationTime} ${easeInOut};

  @media ${mobileCondition} {
    width: calc(100% + 36px);
    margin-left: calc(-36px / 2);
  }

  h4 {
    font-size: ${H5Size};
    line-height: ${H5LineHeight};
  }
`;

export const BarChartLabel = (props) => {
  const {
    value = 0,
    offsetY,
    isNegative,
    valueColor,
    valueFormatFunction,
    costumValueComponent,
    ...rest
  } = props;

  if (!value) return null;

  let formattedValue = value;
  if (isFunction(valueFormatFunction)) {
    formattedValue = valueFormatFunction(value);
  }

  return (
    <LabelWrapper style={isNegative ? { top: offsetY } : { bottom: offsetY }}>
      {!!costumValueComponent ? (
        costumValueComponent(rest)
      ) : (
        <H4 style={{ color: valueColor }}>{formattedValue}</H4>
      )}
    </LabelWrapper>
  );
};
