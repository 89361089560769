import { apiFetch } from './utils/api';
import { stringify } from 'query-string';

/**
 * @desc Transaction data fetch
 *
 * @param {(number|number[])} id - ID of the user we're trying to fetch the data for. Array of id's
 * supported.
 *
 * @param {string} [period=TWELVEMONTHS] -  THISMONTH, THISYEAR, TWELVEMONTHS, THREEYEARS,
 * FIVEYEARS, TENYEARS or SINCESTART
 *
 * @param {number} ssid - required if we are impersonated
 *
 * @return json - data or error message
 */
export const transactions = {
  get: async (args = []) => {
    const query = stringify(args, { arrayFormat: 'comma' });

    const options = {
      method: 'GET',
    };
    const url = `/v2/qlik/transactions?${query}`;

    const { body } = await apiFetch(url, {
      ...options,
    });

    return body;
  },
};
