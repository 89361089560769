import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  H4Size,
  H4LineHeight,
  H5Size,
  paragraphSize,
  paragraphLineHeight,
} from '../../constants/text';

import { bodyFontStack } from '../../fonts';
import { textMain, accent } from '../../constants/colors';

const QvContainer = styled.div`
  height: 100px;

  /**
   * Override the styles for the qlik header to use our internal style
   */
  .qv-object-header {
    .qv-object-title {
      color: ${textMain} !important;
      font-family: ${bodyFontStack} !important;
      font-weight: 400 !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      font-size: ${H4Size} !important;
      line-height: ${H4LineHeight} !important;
    }
  }

  /**
   * Used to override the styling of "input" boxes in Qlik visualizations
   */
  .lui-button {
    color: ${textMain} !important;
    line-height: ${paragraphSize} !important;
    font-size: ${paragraphSize} !important;
    line-height: ${paragraphLineHeight} !important;
    background: none !important;
  }

  /* Override to enable scrolling on the y-axis if the qlik objects is higher than our container */
  .qv-object-wrapper .qv-inner-object {
    overflow-y: auto;
  }

  .qv-object-content-container .qv-object-content .qlik .lui-button {
    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    padding: 6px 0px !important;
    height: 30px !important;
    background: none !important;
    border: 1px solid ${accent.ocean120} !important;
    border-radius: 4px !important;

    &:disabled {
      opacity: 1 !important;
    }

    &.lui-active {
      background: ${accent.ocean250} !important;
      border: 1px solid ${accent.ocean2} !important;
    }
  }

  .lui-tab {
    .lui-tab__text {
      color: ${textMain} !important;
      font-family: ${bodyFontStack} !important;
      font-weight: 400 !important;
      font-size: ${H5Size} !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.lui-active,
    &.lui-focused,
    &:focus {
      border-bottom-color: ${textMain} !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
    }
  }
`;

export const QvObject = (props) => {
  const { app, objectId, style, promiseCallback, options = {} } = props;
  const domId = `qv${objectId}`;

  // Run this code whenever the app instance changes
  useEffect(() => {
    if (!app) {
      return;
    }

    /**
     * The getObject method accepts a third object with options which is documented here:
     * https://help.qlik.com/en-US/sense-developer/May2021/Subsystems/APIs/Content/Sense_ClientAPIs/CapabilityAPIs/AppAPI/getObject-method.htm
     *
     * In short you can give it `noInteraction: true/false` or `noSelections: true/false` to tweak how
     * users can interact with the graphs.
     */
    const promise = app.getObject(domId, objectId, {
      noSelections: false,
      noInteractions: false,
      ...options,
    });

    // Add the promise from getObject to a "global" list of promises, using this
    // we can track if all objects are ready.
    promiseCallback(promise);
    // Adding promiseCallback to the useEffect dependency array which esLint whant's us to do
    // causes an infinite loop bug that prevents this component from working so disable the
    // eslint rule in this case.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, domId, objectId]);

  return <QvContainer id={domId} style={style} {...props} />;
};
