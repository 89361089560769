import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { easeInOut, mediumAnimationTime } from '../../constants/animation';
import {
  layoutMaxWitdh,
  menuClosedWidth,
  menuMaxWidth,
} from '../../constants/grid';
import { mobileCondition, printCondition } from '../../constants/media';
import { PodcastsPlayer } from '../podcast/PodcastsPlayer';

const popoverMaxHeight = 80;

const Wrapper = styled.div`
  max-width: ${layoutMaxWitdh}px;
  height: 100%;
`;

const StyledPopoverContent = styled.div`
  background: #ffffff;
  height: 100%;
  width: 100%;
  max-height: ${popoverMaxHeight}px;
`;

const CenterContent = styled.div`
  flex: 1;
  position: fixed;
  z-index: 10;
  height: 80px;
  bottom: -${popoverMaxHeight + 6}px;
  margin-left: ${menuMaxWidth}px;
  width: calc(100% - ${menuMaxWidth}px);
  transition: all ${mediumAnimationTime} ${easeInOut};

  &.open {
    bottom: 0;
  }

  ${(props) =>
    !props.menuOpen &&
    `
      margin-left: ${menuClosedWidth}px};
      width: calc(100% - ${menuClosedWidth}px);
    `}

  @media ${mobileCondition} {
    width: 100%;
    margin-left: 0;
  }

  @media ${printCondition} {
    display: none;
  }
`;

export const PodcastPopover = memo((props) => {
  const { mainMenuOpen } = useSelector((state) => state.ui.navigation);
  const isPlaying = useSelector((state) => state.ui.podcast.isPlaying);
  const location = useLocation();
  const isPodcastEpisodePage = location.pathname.includes('/podcasts');

  return (
    <CenterContent
      menuOpen={mainMenuOpen}
      className={!isPodcastEpisodePage && isPlaying && 'open'}
    >
      <StyledPopoverContent>
        <Wrapper>
          <PodcastsPlayer mainMenuOpen={mainMenuOpen} />
        </Wrapper>
      </StyledPopoverContent>
    </CenterContent>
  );
});
