import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { t, selectors, constructEmploymentString } from '@formue-app/core';

import { accent, textLightest } from '../../../../constants/colors';
import {
  BORDER_RADIUS_SMALL,
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { H2Small, H4, ParagraphSmall } from '../../../texts';
import { easeInOut, longAnimationTime } from '../../../../constants/animation';

import { ProfileIcon } from '../../../common/ProfileIcon';
import { mobileCondition, tabletCondition } from '../../../../constants/media';

const FamilyInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${SPACING_32} ${SPACING_16};
`;

const FamilyItem = styled.div`
  padding: ${SPACING_24};
  padding-top: ${SPACING_32};
  padding-bottom: ${SPACING_16};
  border: 2px solid ${accent.raspberry1};
  border-radius: ${BORDER_RADIUS_SMALL};
  position: relative;
  width: calc((100% / 2) - (16px / 2));
  cursor: pointer;
  opacity: 0;
  bottom: 20px;

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        border-color: ${accent.raspberry190};

        & > div {
          background: ${accent.raspberry190};
        }
      }
    `}

  ${(props) =>
    props.active &&
    `
    background-color: ${accent.raspberry1};
  `}

  ${(props) =>
    props.isActiveSlide &&
    `
    bottom: 0;
    opacity: 1;
  `}

  @media ${mobileCondition} {
    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  @media ${tabletCondition} {
    width: calc((100% / 3) - ((16px * 2) / 3));

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      margin-bottom: 0;
    }
  }
`;

const StyledProfileIcon = styled(ProfileIcon)`
  position: absolute;
  left: 50%;
  top: 0;
  translate: -50% -50%;

  p {
    color: ${textLightest};
  }
`;

const StyledTitle = styled(H2Small)`
  position: relative;
  color: ${accent.raspberry250};
  margin-bottom: ${SPACING_32};
  left: 25px;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime};

  ${(props) =>
    props.isActiveSlide &&
    css`
      left: 0;
      opacity: 1;
    `}
`;

const {
  entities: {
    familyAndOwnership: { familySelector, familyMainMembersSelector },
  },
} = selectors;

export const FamilyMobile = (props) => {
  const { isActiveSlide, ...rest } = props;

  const familyContent = useSelector(familySelector);
  const familyMembers = useSelector(familyMainMembersSelector);

  const [activeFamilyMember, setActiveFamilyMember] = useState(null);

  if (!familyContent.length) {
    return null;
  }

  return (
    <>
      <StyledTitle isActiveSlide={isActiveSlide}>
        {t('presentation:annualReview:familyAndOwnership:familyTitle')}
      </StyledTitle>
      <FamilyInner {...rest}>
        {Object.values(familyMembers)
          .flat()
          .map((item, familyMemberIndex) => (
            <FamilyItem
              index={familyMemberIndex}
              key={`familymember-${familyMemberIndex}-${item.id}`}
              onClick={() =>
                setActiveFamilyMember(
                  activeFamilyMember === item.id ? null : item.id
                )
              }
              active={activeFamilyMember === item.id}
              isActiveSlide={isActiveSlide}
            >
              <StyledProfileIcon
                name={item.name}
                color={accent.raspberry1}
                size={31}
                fontSize={15}
                style={{
                  border:
                    activeFamilyMember === item.id &&
                    `2px solid ${accent.raspberry250}`,
                }}
              />
              <H4 style={{ color: accent.raspberry250, textAlign: 'center' }}>
                {item.name}
              </H4>
              {activeFamilyMember === item.id &&
                (item.birthDate || item.employment) && (
                  <ParagraphSmall
                    style={{
                      color: accent.raspberry250,
                      marginTop: SPACING_8,
                    }}
                  >
                    {item.birthDate
                      ? t('wealthPlan:familyAndOwnership:family:birthdate', {
                          year: new Date(item.birthDate).getFullYear(),
                        })
                      : null}
                    {item.birthDate && item.employment ? ' | ' : null}
                    {item.employment
                      ? constructEmploymentString(item.employment)
                      : null}
                  </ParagraphSmall>
                )}
            </FamilyItem>
          ))}
      </FamilyInner>
    </>
  );
};
