import React from 'react';
import styled from 'styled-components';
import { contentColumnCount, contentColumnGap } from '../../constants/grid';
import {
  mobileCondition,
  tabletCondition,
  desktopMaxCondition,
} from '../../constants/media';

export const StyledGridElement = styled.div`
  grid-column: ${(props) =>
    props.columnOffset
      ? `${props.columnOffset} / span ${props.columns}`
      : `span ${props.columns}`};

  @media ${mobileCondition} {
    grid-column: span ${contentColumnCount};
  }

  ${(props) =>
    props.columns > props.maxColumns &&
    `
    grid-column: span ${props.columns};
    margin-left: -${contentColumnGap};

    @media ${desktopMaxCondition} {
      grid-column: span ${props.maxColumns};
      margin-right: -${contentColumnGap};
    }

    @media ${mobileCondition} {
      grid-column: span ${props.maxColumns};
      margin-left: 0;
      margin-right: 0;
    }`}

  ${(props) =>
    props.columns === contentColumnCount / 3 &&
    `
    @media ${tabletCondition} {
      grid-column: span ${contentColumnCount / 2};
    }`}
`;

export const GridElement = (props) => {
  const { children, style } = props;

  return (
    <StyledGridElement {...props} maxColumns={contentColumnCount} style={style}>
      {children}
    </StyledGridElement>
  );
};
