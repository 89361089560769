import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { t } from '@formue-app/core';

import { accent, textLightest } from '../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_8,
  SPACING_2,
  SPACING_20,
} from '../../../constants/spacing';

import { H2, H4, ParagraphSmall, ParagraphXSmall } from '../../texts';
import { SpriteIcon } from '../../common/SpriteIcon';

const Wrapper = styled.div`
  background: ${accent.velvet4};
  border-radius: ${BORDER_RADIUS_LARGE};
  padding-bottom: ${SPACING_24};
  position: relative;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${SPACING_24} 0;
  align-items: center;
`;

const Title = styled(H2)`
  color: ${accent.velvet2};
  margin-left: ${SPACING_8};
`;

const Row = styled.div`
  height: 80px;
  justify-content: center;
  padding: 0 ${SPACING_24};
`;

const Label = styled(ParagraphXSmall)`
  color: ${accent.velvet2};
`;

const StyledGraphics = styled.div`
  height: 118px;
  width: 166px;
  position: absolute;
  left: 130px;
  top: 78px;
`;

const line = css`
  top: 0;
  width: 130px;
  position: absolute;
  border: 1px solid #6d4694;
  background: #6d4694;
`;

const LineOne = styled.div`
  ${line}
  left: 50%;
  margin-left: -100px;
  margin-top: 58px;
  rotate: z -65deg;
`;

const LineTwo = styled.div`
  ${line}
  left: 50%;
  margin-left: -30px;
  margin-top: 58px;
  rotate: z 65deg;
`;

const SubText = styled(ParagraphSmall)`
  text-align: center;
  color: ${textLightest};
  margin-top: ${SPACING_2};
`;

const NumberWrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
`;

const Border = styled.div`
  border: 3px solid #6d4694;
  background: ${accent.velvet4};
  padding: 0 9px;
  border-radius: 20px;
`;

const StyledNumber = styled(H4)`
  text-align: center;
  color: ${textLightest};
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
`;

const Pluss = styled(H4)`
  position: absolute;
  left: 78px;
  top: 90px;
  font-size: 24px;
  line-height: 32px;
  color: ${accent.velvet2};
`;

const Number = (props) => {
  const { subText, number, style, ...rest } = props;

  return (
    <NumberWrapper style={style} {...rest}>
      <Border>
        <StyledNumber>{number}</StyledNumber>
      </Border>
      {subText && <SubText>{subText}</SubText>}
    </NumberWrapper>
  );
};

const Graphics = (props) => {
  const { fundOfFunds, directly, indirectly } = props;

  const [fundsInFundsWidth, setFundsInFundsWidth] = useState(0);
  const [directlyOwnedFundsWidth, setDirectlyOwnedFundsWidth] = useState(0);
  const [inDirectlyOwnedFundsWidth, setInDirectlyOwnedFundsWidth] = useState(0);

  return (
    <StyledGraphics>
      <LineOne />
      <LineTwo />

      <Number
        number={fundOfFunds}
        style={{
          transform: [{ translateX: -fundsInFundsWidth / 2 }],
          left: 42,
          top: 10,
        }}
        onLayout={(e) => setFundsInFundsWidth(e.nativeEvent.layout.width)}
      />
      <Number
        number={indirectly}
        subText={t('portfolioConstruction:fundsModal:graphic:label1')}
        style={{
          transform: [{ translateX: -directlyOwnedFundsWidth / 2 }],
          left: 10,
          top: 90,
        }}
        onLayout={(e) => setDirectlyOwnedFundsWidth(e.nativeEvent.layout.width)}
      />
      <Pluss>+</Pluss>
      <Number
        number={directly}
        subText={t('portfolioConstruction:fundsModal:graphic:label2')}
        style={{
          transform: [{ translateX: -inDirectlyOwnedFundsWidth / 2 }],
          left: 120,
          top: 90,
        }}
        onLayout={(e) =>
          setInDirectlyOwnedFundsWidth(e.nativeEvent.layout.width)
        }
      />
    </StyledGraphics>
  );
};

export const FundInFundGraphic = (props) => {
  return (
    <Wrapper>
      <Header>
        <SpriteIcon
          id="Investment"
          inverted={true}
          navigational={true}
          size={SPACING_20}
        />
        <Title>{t('portfolioConstruction:fundsModal:graphic:title')}</Title>
      </Header>

      <Row>
        <Label>{t('portfolioConstruction:fundsModal:graphic:row1')}</Label>
      </Row>
      <Row>
        <Label>{t('portfolioConstruction:fundsModal:graphic:row2')}</Label>
      </Row>

      <Graphics {...props} />
    </Wrapper>
  );
};
