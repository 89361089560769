import { useSelector, useDispatch } from 'react-redux';
import { isEqual, every } from 'lodash';

import { actions } from '../../../store';

const { portfolio: portfolioActions } = actions.ui;

const compareFilters = (x, y) => {
  if (!y || !x) {
    return false;
  }
  return every(Object.keys(x), key => {
    return isEqual(x[key], y[key]);
  });
};

export const usePortfolioDefaultFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.ui.portfolio.filters);
  const defaultFilters = useSelector(
    state => state.ui.portfolio.defaultFilters
  );

  const onChange = value => {
    if (value) {
      dispatch(portfolioActions.defaultFiltersSave());
    } else {
      dispatch(portfolioActions.defaultFiltersDelete());
    }
  };

  const isDefault = compareFilters(filters, defaultFilters);

  return [isDefault, onChange];
};
