import { useEffect, useState } from 'react';

/**
 * This hook will connect to Qlik using the provided Qlik app instance and
 * return a React state variable that contains a copy of the Qlik App selection
 * state. For convenience it also returns a function that can be used to clear
 * all selections.
 * 
 * @param {*} app - The Qlik app instance
 * @returns {Array.<{selections: Object, onClear: Function}>}
 */
export const useQlikSelections = (app) => {
  const [selections, setSelections] = useState([]);

  // After we have a qlik app instance we can add a listener to the Qlik selections
  // state object. We update our react state variable with a copy of the qlik state
  // whenever the selection state changes.
  useEffect(() => {
    if (!app) { return };

    // Get selection state so we can update our own filters based on the state in qlik
    const selectionState = app.selectionState();
    const onSelectionChange = () => {
      setSelections([...selectionState.selections])
    }
    selectionState.OnData.bind(onSelectionChange);
  }, [app]);

  const onClear = () => {
    app.clearAll();
  }

  return [selections, onClear];
}
