import React from 'react';

import { t } from '@formue-app/core';

import { SectorIcon } from './SectorIcon';
import { LensesListItem } from '../LensesListItem';
import { LensesPercentageBadge } from '../LensesPercentageBadge';
import { LensesTopThree } from '../LensesTopThree';
import { ParagraphSmall } from '../../../texts';

export const SectorItem = ({
  securities,
  sector,
  partOfPortfolio,
  partOfKnownSectorPortfolio,
  ...rest
}) => {
  const topThreeSecurities = securities.map((security) => ({
    percentage: security.partOfPortfolio,
    title: security.name,
  }));

  return (
    <LensesListItem {...rest}>
      <LensesPercentageBadge
        title={t(`sectors:${sector}:title`)}
        percentage={partOfKnownSectorPortfolio}
        icon={<SectorIcon sector={sector} />}
      />
      <ParagraphSmall>{t(`sectors:${sector}:description`)}</ParagraphSmall>
      <LensesTopThree
        title={t('geography:topThreeCompanies')}
        items={topThreeSecurities}
        style={{ marginTop: 'auto' }}
      />
    </LensesListItem>
  );
};
