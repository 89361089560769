import React from 'react';
import styled from 'styled-components';

import { localizeFloat } from '@formue-app/core';
import { backgroundWhite } from '../../../../constants/colors';
import { H4 } from '../../../texts';
import { Flag } from './Flag';

const StyledMarker = styled.div`
  display: flex;
  flex-direction: column;
  width: 63px;
  height: 63px;
  border-radius: 36px;
  background-color: ${backgroundWhite};
  justify-content: center;
  align-items: center;
`;

export const CountryMarker = ({
  partOfKnownPortfolio,
  countryCode,
  delay,
  ...rest
}) => {
  return (
    <StyledMarker {...rest}>
      <Flag countryCode={countryCode} width={16} height={16} />
      <H4 style={{ marginTop: 4 }}>
        {`${localizeFloat(
          partOfKnownPortfolio * 100,
          partOfKnownPortfolio * 100 > 1 ? 0 : 1
        )}%`}
      </H4>
    </StyledMarker>
  );
};
