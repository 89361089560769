import React, { useState } from 'react';
import styled from 'styled-components';
import { SPACING_16, SPACING_8 } from '../../../constants/spacing';
import { VerticalDivider } from '../../common/VerticalDivider';

import { FilterBadge } from './FilterBadge';
import { FilterButton } from './FilterButton';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: ${SPACING_16} 0;
`;

const StyledFilterBadge = styled(FilterBadge)`
  margin: 4px ${SPACING_8} 4px 0;
`;

const StyledFilterButton = styled(FilterButton)`
  margin: 4px ${SPACING_8} 4px 0;
`;

const StyledVerticalDivider = styled(VerticalDivider)`
  margin-right: ${SPACING_8};
  height: 25px;
`;

// limit the number of displayed filter badges by default
const MAX_NUMBER_OF_BADGES = 5;

export const FilterBadges = ({
  activeFilters = [],
  onSave = () => {},
  onClear = () => {},
  ...rest
}) => {
  const [showAll, setShowAll] = useState(false);
  if (!activeFilters.length) return null;

  return (
    <Container {...rest}>
      {activeFilters.map(({ label, onRemove }, index) => {
        if (!showAll) {
          if (index < MAX_NUMBER_OF_BADGES) {
            return (
              <StyledFilterBadge
                key={`label-${index}`}
                label={label}
                onRemove={onRemove}
              />
            );
          }
        } else {
          return (
            <StyledFilterBadge
              key={`label-${index}`}
              label={label}
              onRemove={onRemove}
            />
          );
        }

        return null;
      })}
      {activeFilters.length > MAX_NUMBER_OF_BADGES && !showAll && (
        <StyledFilterButton
          label={`+${
            activeFilters.length - MAX_NUMBER_OF_BADGES
          } filter option`}
          onClick={() => {
            setShowAll(true);
          }}
        />
      )}
      {activeFilters.length > MAX_NUMBER_OF_BADGES && showAll && (
        <StyledFilterButton
          label="View less"
          onClick={() => {
            setShowAll(false);
          }}
        />
      )}

      <StyledVerticalDivider />

      <StyledFilterButton
        label="Clear all"
        onClick={() => {
          onClear();
        }}
      />
      <StyledFilterButton
        label="Save filter set"
        onClick={() => {
          onSave();
        }}
      />
    </Container>
  );
};
