import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';

export const StandardFilterSerializer = new JSONAPISerializer('standard-filter', {
  keyForAttribute: 'camelCase',
  attributes: [
    'excludedSsids',
    'period',
    'investmentAccounts',
    'taxClasses',
  ],
});
