import React from 'react';
import styled, { css } from 'styled-components';

import { FixedPyramidLegendRow } from './FixedPyramidLegendRow';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;

  ${(props) =>
    props.labelPosition === 'left' &&
    css`
      flex-direction: row-reverse;
    `}
`;

const Legend = styled.div`
  min-width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${(props) => props.height}px;
`;

export const FixedPyramidChart = ({
  style,
  height,
  width,
  showLegend = true,
  data,
  labelPosition = 'right',
  LabelComponent,
  ValueComponent,
  dashWidth,
  dashSpacing,
  ...rest
}) => {
  if (!data) {
    return <div style={[style, { height: height, width: width }]} />;
  }

  // Calculate the scaling with 400 as a base
  const scaler = 400 / height;
  const offset = [-(120 / scaler), -(60 / scaler), 0];

  return (
    <Wrapper {...rest} labelPosition={labelPosition}>
      <svg height={height} viewBox="0 0 371 364">
        <path
          d="M181.846 6.93436C183.334 4.03614 187.476 4.03615 188.964 6.93437L254.018 133.697C255.384 136.359 253.452 139.523 250.46 139.523H120.35C117.359 139.523 115.426 136.359 116.792 133.697L181.846 6.93436Z"
          fill="#C76FF2"
        />
        <path
          d="M107.864 152.312L59.4492 244.53C58.0509 247.194 59.9826 250.39 62.9908 250.39H306.196C309.181 250.39 311.114 247.239 309.763 244.578L262.933 152.36C262.251 151.017 260.873 150.171 259.366 150.171H111.406C109.919 150.171 108.555 150.995 107.864 152.312Z"
          fill="#C5A5D6"
        />
        <path
          d="M314.524 261.663H54.2897C52.7821 261.663 51.4025 262.511 50.7213 263.856L2.94132 358.193C1.59369 360.853 3.52716 364 6.50974 364H364.068C367.076 364 369.008 360.804 367.609 358.14L318.066 263.803C317.375 262.487 316.011 261.663 314.524 261.663Z"
          fill="#6D4694"
        />
      </svg>
      {showLegend && (
        <Legend height={height}>
          {data.map((item, index) => (
            <FixedPyramidLegendRow
              key={`pyramid-legend-${index}`}
              labelPosition={labelPosition}
              label={item.label}
              value={item.value}
              height={height}
              offset={offset[index]}
              LabelComponent={LabelComponent}
              ValueComponent={ValueComponent}
              dashWidth={dashWidth}
              dashSpacing={dashSpacing}
            />
          ))}
        </Legend>
      )}
    </Wrapper>
  );
};
