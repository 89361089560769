import React from 'react';
import camelcase from 'camelcase';
import { useTranslation } from 'react-i18next';

import { PaddedBaseSection } from '../../common/BaseSection';

import { SPACING_16 } from '../../../constants/spacing';
import { H2, H6, Paragraph } from '../../texts';

export const ExpertInputDetailsStatus = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  let { description, status, type } = item;
  let title = '';

  if (status) {
    status = camelcase(status);
  }

  // If expert input has type use the type instead of name and description
  // this is so we don't show user created names, but rather predefined values
  if (type) {
    const _type = camelcase(type);
    title = t(`todo:expertInput:titles:${_type}`);
    description = t(`todo:expertInput:description:${_type}`);
  }

  let statusText;
  try {
    statusText = t('todo:expertInput:status', {
      status: t(`todo:statuses:${status}`),
    });
  } catch (error) {
    statusText = null;
  }

  return (
    <PaddedBaseSection>
      <H6 style={{ marginBottom: 6 }}>{t('todo:expertInputTaskCategory')}</H6>
      <H2 style={{ marginBottom: 6 }}>{title}</H2>
      {statusText && <Paragraph>{statusText}</Paragraph>}
      {description && (
        <Paragraph style={{ marginTop: SPACING_16 }}>{description}</Paragraph>
      )}
    </PaddedBaseSection>
  );
};
