import { flattenDeep } from 'lodash';

import * as clientSearch from './clientSearch';
import * as document from './document';
import * as podcast from './podcast';
import * as passkeys from './passkeys';

export const sagas = flattenDeep([
  ...document.sagas,
  ...clientSearch.sagas,
  ...podcast.sagas,
  ...passkeys.sagas,
]);
