/**
 * FilterSerializer
 *
 * Responsible for converting our locally stored filters to and from
 * the data format the API is expecting/returning;
 *
 * Example of how we store filters in our redux store:
 *
 * {
 *   "timeSpan":"FIVEYEARS",
 *   "disabledRelationshipIds":[],
 *   "categories":{
 *     "taxClass":{
 *       "title":"taxClassTitle",
 *       "active":[],
 *       "filters":{}
 *     },"account":{
 *       "title":"accountTitle",
 *       "active":[],
 *       "filters":{}
 *     }
 *   }
 *  }
 *
 * The format the API returns and expects to receive:
 *
 * {
 *   "excludedssids": "1234,2345",
 *   "period": "THREEYEARS"
 *   "accounts": "Nordnet 123456,OMI 123456",
 *   "taxclasses": "Kapitalförsäkring,Investeringssparkonto"
 * }
 */
export class FilterSerializer {
  /**
   * Convert our local version of default filters to the format the API expects
   *
   * @param {*} defaultFilters - The local version of the default filters
   */
  static serialize(defaultFilters) {
    const serialized = {};
    serialized.period = defaultFilters.timeSpan;
    serialized.excludedssids = defaultFilters.disabledRelationshipIds;
    serialized.accounts = convertCategoryFilters(
      defaultFilters.categories.account.filters
    );
    serialized.taxclasses = convertCategoryFilters(
      defaultFilters.categories.taxClass.filters
    );

    return serialized;
  }
}

/**
 * Convert our internal representation of filters into a comma seperated string
 */
const convertCategoryFilters = filters => {
  Object.entries(filters)
    .map(filter => filter[1].label)
    .join(',');
};
