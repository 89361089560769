import React from 'react';
import styled from 'styled-components';

import { percentage, truncateString } from '@formue-app/core';

import { H4, ParagraphSmall } from '../../texts';
import { SPACING_16 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

const StyledRow = styled.div`
  flex: 1;
  display: flex;
  padding: 4px 0;
  gap: ${SPACING_16};
`;

const StyledNumber = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${accent.ocean250};
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const LensesTopThree = ({ items, title, ...rest }) => {
  return (
    <div {...rest}>
      <ParagraphSmall style={{ paddingBottom: 12, fontWeight: 500 }}>
        {title}
      </ParagraphSmall>
      {items.map((item, index) => {
        if (!item) return null;
        return (
          <StyledRow key={index}>
            <StyledNumber>
              <H4
                style={{
                  fontSize: 11,
                  color: accent.ocean4,
                }}
              >
                {index + 1}
              </H4>
            </StyledNumber>
            <ParagraphSmall>
              {percentage(item.percentage * 100, 3, true)}
            </ParagraphSmall>
            <ParagraphSmall>{truncateString(item.title, 28)}</ParagraphSmall>
          </StyledRow>
        );
      })}
    </div>
  );
};
