import React from 'react';
import styled from 'styled-components';

import {
  formatBigMoney,
  formatTimeHorizionData,
  percentage,
  selectors,
  t,
  useResource,
} from '@formue-app/core';

import {
  BORDER_RADIUS_LARGE,
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { H2, H3, H6, Paragraph, ParagraphSmall } from '../../texts';

import { DonutGraph } from '../../graphs/DonutGraph';
import { useSelector } from 'react-redux';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { SpriteIcon } from '../../common/SpriteIcon';
import { GridElement } from '../../layout';

const GraphWrapper = styled(GridElement)`
  background: ${accent.sand140};
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
  display: flex;
  gap: ${SPACING_24};
`;

const LeftWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${SPACING_32};
`;

const RightWrapper = styled.div`
  width: 40%;
`;

const FactorsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  padding: 0;
  margin: 0;
  margin-top: ${SPACING_24};
`;

const FactorsListItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${SPACING_16};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const AmountWrapper = styled.div`
  display: flex;
  gap: ${SPACING_8};
  align-items: baseline;

  h2 {
    font-size: 32px;
  }
`;

const {
  entities: {
    cashFlowEvents: {
      allCashFlowEventsMetadataSelector,
      formattedCashFlowEvents,
      flexibleCashFlowEventsSelector,
    },
    wealthBalance: {
      totalWealthBalanceSelector,
      createWealthBalanceByGroupSelector,
    },
  },
} = selectors;

export const FinancialAbility = (props) => {
  const loading = useResource([
    'CASH_FLOW_EVENTS/INDEX',
    'BALANCE_ENTRIES/INDEX',
  ]);

  const totalWealth = useSelector(totalWealthBalanceSelector);
  const totalDebt = useSelector(
    createWealthBalanceByGroupSelector('Liability')
  );
  const cashFlowEventsMetaData = useSelector(allCashFlowEventsMetadataSelector);
  const cashFlowEventsData = useSelector(formattedCashFlowEvents);
  const flexibleCashFlowEvents = useSelector(flexibleCashFlowEventsSelector);
  const totalPortfolio = cashFlowEventsMetaData.totalPortfolio;
  const formatedData = formatTimeHorizionData(
    cashFlowEventsData,
    totalPortfolio
  );

  const longTermCapital = formatedData[formatedData.length - 1]?.value;
  const longTermPercentage = longTermCapital / totalPortfolio;

  // Use the sum of all cash flow events
  const totalCashFlow = Math.abs(
    cashFlowEventsData
      .map((event) => event.value)
      .reduce((acc, a) => acc + a, 0)
  );
  const totalFlexibleCashFlow = flexibleCashFlowEvents.reduce(
    (acc, obj) => acc + Math.abs(obj.value),
    0
  );

  if (loading) {
    return <CenteredActivityIndicator />;
  }

  return (
    <GraphWrapper columns={12} {...props}>
      <LeftWrapper>
        <DonutGraph
          size={130}
          border={24}
          value={longTermPercentage}
          color={accent.ocean1}
        />
        <LabelWrapper>
          <H6>
            {t(
              'wealthPlan:investmentProfile:riskAndLockin:lockIn:longTermCapital'
            )}
          </H6>
          <AmountWrapper>
            <H2>{formatBigMoney(longTermCapital, false)}</H2>
            <Paragraph style={{ fontWeight: 600 }}>
              ({percentage(longTermPercentage * 100)})
            </Paragraph>
          </AmountWrapper>
          <ParagraphSmall>
            {t(
              'wealthPlan:investmentProfile:riskAndLockin:lockIn:tenYearHorizon'
            )}
          </ParagraphSmall>
        </LabelWrapper>
      </LeftWrapper>
      <RightWrapper>
        <H6>
          {t(
            'wealthPlan:investmentProfile:riskAndLockin:lockIn:importantFactors'
          )}
        </H6>
        <FactorsList>
          <FactorsListItem>
            <SpriteIcon id="Bank" size={SPACING_32} />
            <div>
              <H3 style={{ marginBottom: 2 }}>
                {formatBigMoney(totalDebt.amount, false)}
              </H3>
              <ParagraphSmall>
                {t(
                  'wealthPlan:investmentProfile:riskAndLockin:lockIn:totalDept'
                )}
              </ParagraphSmall>
            </div>
          </FactorsListItem>
          <FactorsListItem>
            <SpriteIcon id="Portfolio" size={SPACING_32} />
            <div>
              <H3 style={{ marginBottom: 2 }}>
                {formatBigMoney(totalPortfolio, false)} /{' '}
                {formatBigMoney(totalWealth, false)}
              </H3>
              <ParagraphSmall>
                {t(
                  'wealthPlan:investmentProfile:riskAndLockin:lockIn:financialAssetsVsWealth'
                )}
              </ParagraphSmall>
            </div>
          </FactorsListItem>
          <FactorsListItem>
            <SpriteIcon id="Capital" size={SPACING_32} />
            <div>
              <H3 style={{ marginBottom: 2 }}>
                {formatBigMoney(totalFlexibleCashFlow, false)} (
                {percentage((totalFlexibleCashFlow / totalCashFlow) * 100)})
              </H3>
              <ParagraphSmall>
                {t(
                  'wealthPlan:investmentProfile:riskAndLockin:lockIn:flexibleCapital'
                )}
              </ParagraphSmall>
            </div>
          </FactorsListItem>
        </FactorsList>
      </RightWrapper>
    </GraphWrapper>
  );
};
