import React, { useEffect, useState, useRef } from 'react';

export const InfiniteScroll = (props) => {
  const { next, children, resetIfChanges } = props;
  const [page, setPage] = useState(1);

  const loader = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  useEffect(() => {
    next(page);
  }, [next, page]);

  useEffect(() => {
    setPage(1);
  }, [resetIfChanges]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage((page) => page + 1);
    }
  };

  return (
    <>
      {children}
      <div ref={loader}>
        {/* can put some 'loading...'-stuff here, if interesting. */}
      </div>
    </>
  );
};
