import { useSelector } from 'react-redux';
import { operatingCountrySelector } from '../store/auth/selectors';

/**
 *
 * @param {*} values - an object with different values for different countries
 * @param {*} fallbackCountry - a country code to use as the default if a default is wanted
 * @returns The values for the country the user belongs to
 */
export const useValuesForCountry = (values, fallbackCountry) => {
  const operatingCountry = useSelector(operatingCountrySelector);

  if (values.hasOwnProperty(operatingCountry)) {
    return values[operatingCountry];
  }

  if (fallbackCountry && values.hasOwnProperty(fallbackCountry)) {
    return values[fallbackCountry];
  }

  throw new Error(`Values for "${operatingCountry}" is not implemented`);
};
