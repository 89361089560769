import React from 'react';
import styled, { css } from 'styled-components';
import { sumBy } from 'lodash';

import { getColorForAssetClassGroup, hexToHsl } from '@formue-app/core';

import {
  accent,
  assetClassDefaultColorScheme,
  backgroundWhite,
} from '../../constants/colors';
import { H6, ParagraphSmall, ParagraphXSmall } from '../texts';
import {
  BORDER_RADIUS_LARGE,
  SPACING_12,
  SPACING_24,
  SPACING_4,
} from '../../constants/spacing';
import { easeInOut, shortAnimationTime } from '../../constants/animation';
import { DotIcon } from '../common/DotIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
`;

const StackContainer = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  gap: ${(props) => props.spacing}px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  ${(props) =>
    props.hover &&
    css`
      &:has(.bar:hover) .bar:not(:hover) {
        opacity: 0.6;
        transition-delay: 0s;
      }
    `}
`;

const TextContainer = styled.div`
  text-align: center;
  opacity: ${(props) => (props.animate ? 1 : 0)};
  transition: opacity ${shortAnimationTime} ${easeInOut};
`;

const StackItem = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color};
  transition: all ${shortAnimationTime} ${easeInOut};
  transition-delay: ${(props) => props.animationDelay}s;
  translate: 0% 100%;
  opacity: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  ${(props) =>
    props.index === 0 &&
    css`
      border-bottom-left-radius: ${BORDER_RADIUS_LARGE};
      border-bottom-right-radius: ${BORDER_RADIUS_LARGE};
    `}
  ${(props) =>
    props.index === props.numberOfItems - 1 &&
    css`
      border-top-left-radius: ${BORDER_RADIUS_LARGE};
      border-top-right-radius: ${BORDER_RADIUS_LARGE};
    `}
    ${(props) =>
    props.animate &&
    css`
      translate: 0% 0%;
      opacity: 1;
    `}

  &:hover {
    cursor: pointer;

    & + .hoverbox {
      opacity: 1;
    }
  }
`;

const HoverBox = styled.div`
  border: 1px solid ${(props) => props.color};
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: 6px ${SPACING_12};
  display: flex;
  gap: 6px;
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  opacity: 0;
  transition: opacity ${shortAnimationTime} ${easeInOut};

  p {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Value = styled(H6)`
  margin: 0 ${SPACING_4} ${SPACING_4} 0;
`;

export const StackedBar = (props) => {
  const {
    items = [],
    height = 364,
    width = 80,
    spacing = 2,
    offset = 0,
    animate,
    title,
    label,
    maxTotal,
    showValue,
    formatValue = (value) => value,
    valueColorLight = backgroundWhite,
    valueColorDark = accent.velvet4,
    animationDelay = 0,
    hover = false,
    ...rest
  } = props;

  if (!items.length) return null;

  const totalValue = maxTotal || sumBy(items, 'value');

  const coef = (height - (items.length - 1) * spacing) / totalValue;

  return (
    <Container {...rest}>
      <StackContainer
        spacing={spacing}
        height={height - offset * coef}
        width={width}
        className="container"
        hover={hover}
      >
        {items.length &&
          items.map((item, index) => {
            const color =
              item.color ||
              getColorForAssetClassGroup(
                item.group,
                assetClassDefaultColorScheme
              );
            // We want to convert the background hex color to hsl and use the "l"
            // which stands for "luminance". That way we can determine if we should
            // use a light or dark label color in order to achive apropriate contrast.
            const { l } = hexToHsl(color);
            const valueColor = l > 0.5 ? valueColorDark : valueColorLight;
            return (
              <>
                <StackItem
                  height={item.value * coef}
                  color={color}
                  index={index}
                  numberOfItems={items.length}
                  animate={animate}
                  key={`stack-item-${index}`}
                  animationDelay={animationDelay}
                  className="bar"
                >
                  {showValue && (
                    <Value style={{ color: valueColor }}>
                      {formatValue(item.value)}
                    </Value>
                  )}
                </StackItem>
                <HoverBox color={color} className="hoverbox">
                  <DotIcon color={color} />
                  <ParagraphSmall style={{ color: color }}>
                    {item.label}
                  </ParagraphSmall>
                </HoverBox>
              </>
            );
          })}
      </StackContainer>
      <TextContainer animate={animate}>
        {title && (
          <ParagraphXSmall style={{ paddingBottom: 4 }}>
            {title.toUpperCase()}
          </ParagraphXSmall>
        )}
        <ParagraphSmall style={{ fontWeight: 500 }}>{label}</ParagraphSmall>
      </TextContainer>
    </Container>
  );
};
