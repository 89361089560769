import React from 'react';
import styled from 'styled-components';
import { animated, useTransition, config } from 'react-spring';

import { CountryMarker } from './CountryMarker';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-around;
  top: 50%;
  left: 30px;
  translate: 0 -50%;
  z-index: 1;
  gap: 12px;
`;

const AnimatedCountryMarker = animated(CountryMarker);

export const CountryMarkers = ({ countries = [], ...rest }) => {
  const transitions = useTransition(
    countries.sort((a, b) => b.partOfKnownPortfolio - a.partOfKnownPortfolio),
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      to: { opacity: 1 },
      trail: 250,
      config: config.molasses,
      reset: true,
    }
  );

  if (!countries.length) return null;

  return (
    <StyledContainer {...rest}>
      {transitions(
        (style, { partOfKnownPortfolio, countryCode }, key, index) => (
          <AnimatedCountryMarker
            partOfKnownPortfolio={partOfKnownPortfolio}
            countryCode={countryCode}
            delay={index * 400}
            key={index}
            style={style}
          />
        )
      )}
    </StyledContainer>
  );
};
