import { takeEvery, call, put } from 'redux-saga/effects';

import { AsyncStorage } from '../../../services/AsyncStorage';
import { actions } from './actions';

const STORAGE_KEY = 'recentlyImpersonatedClientIds';
const MAX_NUMBER_TO_STORE = 50;

function* loadStoredClientIds() {
  const storedClientIds = yield call(AsyncStorage.getItem, STORAGE_KEY);
  let clientIds = [];
  if (storedClientIds) {
    clientIds = JSON.parse(storedClientIds);
  }
  return clientIds;
}

function* hydrateRecentlyImpersonatedClientIds() {
  yield takeEvery(actions.hydrateRecentlyImpersonatedClientIds, function*() {
    const clientIds = yield loadStoredClientIds();
    yield put(actions.setRecentlyImpersonatedClientIds(clientIds));
  });
}

function* saveRecentlyImpersonatedClientId() {
  yield takeEvery(actions.saveRecentlyImpersonatedClientId, function*({
    payload,
  }) {
    let clientIds = yield loadStoredClientIds();
    // Push client id from payload to top of the list
    clientIds.unshift(payload);
    // Make sure list is unique
    clientIds = [...new Set(clientIds)];
    // Limit list to max number to store
    clientIds = clientIds.slice(0, MAX_NUMBER_TO_STORE);
    yield call(AsyncStorage.setItem, STORAGE_KEY, JSON.stringify(clientIds));
    yield put(actions.setRecentlyImpersonatedClientIds(clientIds));
  });
}

export const sagas = [
  saveRecentlyImpersonatedClientId,
  hydrateRecentlyImpersonatedClientIds,
];
