import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { accent } from '../../constants/colors';
import { SPACING_24, SPACING_16, SPACING_8 } from '../../constants/spacing';

import flexibleCashFlowBackground from '../../assets/icons/icon-flexible-cash-flow-event-vertical.svg';
import { easeInOut, longAnimationTime } from '../../constants/animation';
import { DotIcon } from '../common/DotIcon';
import { H6 } from '../texts';

const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${SPACING_16};
  ${(props) =>
    !props.small
      ? css`
          margin-left: 20px;
          margin-right: 20px;
        `
      : css`
          padding-right: 12px;
        `}
`;

const BarContainer = styled.div`
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: space-between;

  &:first-child {
    border-bottom: 1px solid ${accent.ocean270};
    align-items: flex-end;
  }
`;

const barAnimation = (props) => keyframes`
  0% {
    height: 0%;
  }
  100% {
    height: ${Math.max(props.height, 10)}%;
  }
`;

const CashflowItem = styled.div`
  position: relative;
  height: 0%;
  width: ${(props) => props.barWidth}px;
  overflow: hidden;

  ${(props) =>
    props.positiveValue
      ? css`
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        `
      : css`
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        `}

  animation: ${(props) =>
    barAnimation(props)} ${longAnimationTime} ${easeInOut};
  animation-delay: ${(props) => props.index * 0.1}s;
  animation-fill-mode: forwards;
  ${(props) =>
    props.small
      ? css`
          &:first-child {
            margin-left: ${SPACING_8};
          }
          &:last-child {
            margin-right: ${SPACING_8};
          }
        `
      : css`
          &:first-child {
            margin-left: ${SPACING_24};
          }
          &:last-child {
            margin-right: ${SPACING_24};
          }
        `}
`;

const InnerItem = styled.div`
  ${(props) =>
    props.flexible
      ? css`
          background: url(${flexibleCashFlowBackground});
          background-size: 20px;
        `
      : css`
          background-color: ${!props.positiveValue
            ? accent.ocean2
            : accent.ocean1};
        `};
  height: ${(props) => props.height}%;
  min-height: 20px;
  width: 100%;
`;

const Bar = (props) => {
  const {
    items,
    max,
    index,
    positiveValue = false,
    barWidth = 20,
    small,
  } = props;

  const barValue = items.reduce((acc, item) => acc + Math.abs(item.value), 0);
  const barHeight = (barValue / max) * 100;

  return (
    <CashflowItem
      index={index}
      height={barHeight}
      positiveValue={positiveValue}
      barWidth={barWidth}
      small={small}
    >
      {items.map((item, index) => (
        <InnerItem
          key={`item-${index}`}
          flexible={item.flexible}
          positiveValue={positiveValue}
          height={(Math.abs(item.value) / barValue) * 100}
        />
      ))}
    </CashflowItem>
  );
};

const Labels = ({ small }) => {
  return (
    <LabelsWrapper small={small}>
      {small
        ? Array(10)
            .fill()
            .map((item, index) =>
              index % 2 !== 0 ? (
                <DotIcon
                  key={`dot-${index}`}
                  radius={2}
                  color={accent.ocean250}
                />
              ) : (
                <H6 key={`year-${index}`}>
                  {new Date().getFullYear() + index}
                </H6>
              )
            )
        : Array(10)
            .fill()
            .map((item, index) => (
              <H6 key={`year-${index}`}>{new Date().getFullYear() + index}</H6>
            ))}
    </LabelsWrapper>
  );
};

export const CashflowEventsChart = (props) => {
  const { data, max, height = 200, style, barWidth, small } = props;

  return (
    <div style={style}>
      <BarContainer height={height / 2}>
        {data.positive.map((items, index) => (
          <Bar
            key={`bar-${index}`}
            index={index}
            items={items}
            max={max}
            positiveValue={true}
            barWidth={barWidth}
            small={small}
          />
        ))}
      </BarContainer>
      <BarContainer height={height / 2}>
        {data.negative.map((items, index) => (
          <Bar
            key={`bar-${index}`}
            index={index}
            items={items}
            max={max}
            barWidth={barWidth}
            small={small}
          />
        ))}
      </BarContainer>
      <Labels small={small} />
    </div>
  );
};
