import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';

import { backgroundWhite, accent } from '../../../../constants/colors';
import { mobileCondition } from '../../../../constants/media';
import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_32,
  SPACING_16,
} from '../../../../constants/spacing';
import { GridElement, MainGrid } from '../../../layout';
import { H1 } from '../../../texts';
import { BaseSection } from '../../../common/BaseSection';
import { SectorsText } from './SectorsText';
import { SectorsBubbleChart } from './SectorsBubbleChart';
import {
  tabletCondition,
  notDesktopCondition,
} from '../../../../constants/media';

const StyledBackground = styled(BaseSection)`
  flex: 1;
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: 0;
  background-color: ${accent.raspberry4}
    ${(props) =>
      props.fullSize
        ? `@media ${mobileCondition} {
    border-radius: 0;
    padding-top: 145px;
    margin: 0 -${SPACING_16};
    margin-top: -145px;
  }`
        : null};
`;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledGridElement = styled(GridElement)`
  padding: 80px ${SPACING_48};

  @media ${mobileCondition} {
    padding: ${SPACING_48};
  }
`;

const StyledGridElementLeft = styled(StyledGridElement)`
  padding-top: 130px;
  @media ${notDesktopCondition} {
    padding: ${SPACING_32} ${SPACING_16};
    padding-bottom: 0;
  }
`;

const StyledGridElementRight = styled(StyledGridElement)`
  padding: 30px 48px;
  @media ${notDesktopCondition} {
    padding: 0;
  }
  @media ${mobileCondition} {
    position: relative;
    left: -20px;
  }
`;

const StyledTitle = styled(H1)`
  color: ${backgroundWhite};
  margin-bottom: 16px;
  @media ${notDesktopCondition} {
    display: none;
  }
`;

const {
  entities: {
    securities: { securitiesBySectorSelector },
  },
} = selectors;

export const SectorsHeader = (props) => {
  const {
    fullSize = false,
    children,
    continents,
    topThreeCountries,
    zoom,
    selectedContinent,
    onClick,
    ...rest
  } = props;

  const isTablet = useMediaQuery({
    query: tabletCondition,
  });

  const bySector = useSelector(securitiesBySectorSelector);

  return (
    <StyledBackground fullSize={fullSize} columns={12} {...rest}>
      <StyledSubGrid>
        <StyledGridElementLeft columns={isTablet ? 12 : 5}>
          <StyledTitle>{t('portfolio:sectors')}</StyledTitle>
          <SectorsText />
        </StyledGridElementLeft>
        <StyledGridElementRight columns={isTablet ? 12 : 7}>
          <SectorsBubbleChart
            style={{ marginLeft: 'auto' }}
            sectors={bySector}
          />
        </StyledGridElementRight>
      </StyledSubGrid>
    </StyledBackground>
  );
};
