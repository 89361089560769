import { createSelector } from 'reselect';
import { selectors } from '@formue-app/core';

import swedishFactSheets from './swedishFactSheets.json';
import norwegianFactSheets from './norwegianFactSheets.json';
import danishFactSheets from './danishFactSheets.json';

import factSheetFinalizedInvestments from './norwegianFactSheetsFinalizedInvestments.json';

import norwegianKeyInfo from './norwegianKeyInfo.json';

const { operatingCountrySelector } = selectors.auth;

export const factSheetSelector = createSelector(
  operatingCountrySelector,
  (operatingCountry) => {
    switch (operatingCountry) {
      case 'se': {
        return swedishFactSheets;
      }
      case 'no': {
        return norwegianFactSheets;
      }
      case 'dk': {
        return danishFactSheets;
      }
      default: {
        throw new Error(
          `Fact sheets for country "${operatingCountry}" is not implemented`
        );
      }
    }
  }
);

export const factSheetFinalizedInvestmentsSelector = createSelector(
  operatingCountrySelector,
  (operatingCountry) => {
    switch (operatingCountry) {
      case 'no': {
        return factSheetFinalizedInvestments;
      }
      default: {
        return [];
      }
    }
  }
);

export const keyInfoSelector = createSelector(
  operatingCountrySelector,
  (operatingCountry) => {
    switch (operatingCountry) {
      case 'no': {
        return norwegianKeyInfo;
      }

      default: {
        return [];
      }
    }
  }
);
