import { createSelector } from 'reselect';
import { orderBy } from 'lodash';

import { createLoadingSelector } from '../../api/selectors';

import {
  openSelector as openSigningEventsSelector,
  ongoingSelector as ongoingSigningEventsSelector,
  completedSelector as completedSigningEventsSelector,
} from '../../entities/selectors/signingEvents';

import {
  openSelector as openTasksSelector,
  ongoingSelector as ongoingTasksSelector,
  completedSelector as completedTasksSelector,
} from '../../entities/selectors/tasks';

import {
  openSelector as openExpertInputsSelector,
  ongoingSelector as ongoingExpertInputsSelector,
  completedSelector as completedExpertInputsSelector,
} from '../../entities/selectors/expertInputs';

export const loadingSelector = createLoadingSelector([
  'TASKS/INDEX',
  'EXPERT_INPUTS/INDEX',
  // We _don't_ want the loading indicator to show when fetching a single
  // signing event (SIGNING_EVENTS/SHOW) while we poll for updates
  'SIGNING_EVENTS/INDEX',
]);

/**
 * Normalize the different entities into something we can use to create the
 * todo list. We also adding a property to order things by. And also a priority
 * which is used to force certain types to be sorted above other. E.g signing
 * tasks should always be at the top of the list
 */
const normalizeSigningEvent = ({ id, status, completedAt, createdAt }) => ({
  id,
  type: 'signingEvents',
  status: status.toLowerCase(),
  priority: 1,
  // Order by completedAt if status is completed, _and_ we have a completedAt date,
  // if not fallback to createdAt. Sorry about the nested ternary operator...
  order:
    status.toLowerCase() === 'completed'
      ? completedAt
        ? completedAt
        : createdAt
      : createdAt,
});

const normalizeTask = ({ id, status, completedAt, createdAt }) => ({
  id,
  type: 'tasks',
  status: status.toLowerCase(),
  priority: 3,
  order:
    status.toLowerCase() === 'completed'
      ? completedAt
        ? completedAt
        : createdAt
      : createdAt,
});

const normalizeExpertInput = ({ id, createdAt, status }) => ({
  id,
  type: 'expertInputs',
  status: status.toLowerCase(),
  priority: 2,
  order: createdAt,
});

/**
 * Use `reselect` library to compose selectors for the different sections.
 */

// Order open tasks by priortity first then the order prop
export const openSelector = createSelector(
  [openSigningEventsSelector, openTasksSelector, openExpertInputsSelector],
  (signingEvents, tasks, expertInputs) =>
    orderBy(
      [
        ...signingEvents.filter((item) => item.id).map(normalizeSigningEvent),
        ...expertInputs.filter((item) => item.id).map(normalizeExpertInput),
        ...tasks.filter((item) => item.id).map(normalizeTask),
      ],
      ['priority', 'order'],
      ['asc', 'desc']
    )
);

// Order ongoing tasks by priority
export const ongoingSelector = createSelector(
  [
    ongoingSigningEventsSelector,
    ongoingTasksSelector,
    ongoingExpertInputsSelector,
  ],
  (signingEvents, tasks, expertInputs) =>
    orderBy(
      [
        ...signingEvents.map(normalizeSigningEvent),
        ...expertInputs.map(normalizeExpertInput),
        ...tasks.map(normalizeTask),
      ],
      'priority',
      'asc'
    )
);

// Order completed tasks by "completedAt" property
export const completedSelector = createSelector(
  [
    completedSigningEventsSelector,
    completedTasksSelector,
    completedExpertInputsSelector,
  ],
  (signingEvents, tasks, expertInputs) =>
    orderBy(
      [
        ...signingEvents.map(normalizeSigningEvent),
        ...expertInputs.map(normalizeExpertInput),
        ...tasks.map(normalizeTask),
      ],
      'order',
      'desc'
    )
);
