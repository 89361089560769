import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { SustainabilityBaseItem } from './SustainabilityBaseItem';
import { H3, H6 } from '../../../texts';
import { ShareChart } from './ShareChart';
import { SPACING_8 } from '../../../../constants/spacing';

const Value = styled(H3)`
  padding-top: ${SPACING_8};
`;

export const SustainableShareOfPortfolio = ({
  shareOfPortfolioToAllocate,
  ...rest
}) => {
  const i18nPrefix =
    'wealthPlan:investmentProfile:sustainabilityPreferences:share';
  return (
    <SustainabilityBaseItem {...rest}>
      <H6>{t(`${i18nPrefix}:title`)}</H6>
      <Value>
        {t(`${i18nPrefix}:${shareOfPortfolioToAllocate.toLowerCase()}Text`)}
      </Value>
      <ShareChart shareOfPortfolioToAllocate={shareOfPortfolioToAllocate} />
    </SustainabilityBaseItem>
  );
};
