import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { mobileCondition } from '../../constants/media';
import {
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING_32OBILE,
  BOX_PADDING_HORIZONTAL,
  SPACING_48,
  SPACING_88,
} from '../../constants/spacing';
import { accent } from '../../constants/colors';

import { reportSections } from './reportSection';

const SlideWrapper = styled.section`
  min-height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${ANNUAL_REVIEW_REPORT_CONTENT_SPACING} ${SPACING_88};
  scroll-snap-align: start;

  @media ${mobileCondition} {
    padding: ${ANNUAL_REVIEW_REPORT_CONTENT_SPACING_32OBILE}
      ${BOX_PADDING_HORIZONTAL};
    padding-bottom: ${SPACING_48};
  }
`;

export const BaseReportSection = (props) => {
  const {
    slide,
    index,
    setActiveSectionIndex = () => {},
    activeSectionIndex,
    wrapperElement,
    ...rest
  } = props;
  const { component, customProps, ...slideRest } = slide;
  const { style, borderColor } = slide.customProps;

  const [slideHasBeenInView, setSlideHasBeenInView] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const visibleTreshhold = 0.3;
  const slideElement = useRef(null);
  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const obeserver = new IntersectionObserver(callbackFunction, {
      root: wrapperElement.current,
      threshold:
        (window.innerHeight / wrapperElement.current.clientHeight) *
        visibleTreshhold,
    });

    if (slideElement.current) {
      obeserver.observe(slideElement.current);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (slideElement.current) obeserver.unobserve(slideElement.current);
    };
  }, [slideElement, wrapperElement]);

  useEffect(() => {
    // if the slide is in view but has not been in view before set it to true
    if (isVisible && !slideHasBeenInView) {
      setSlideHasBeenInView(true);
    }

    if (isVisible) {
      // if slide is first slide (with a background image) we keep the background for the next section
      setActiveSectionIndex(index === 0 ? 1 : index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <SlideWrapper
      style={{ ...style, borderColor: accent[borderColor] }}
      ref={slideElement}
    >
      {reportSections[component]({
        ...slideRest,
        ...rest,
        isVisible: slideHasBeenInView,
      })}
    </SlideWrapper>
  );
};
