import React from 'react';
import { accent, backgroundWhite } from '../../constants/colors';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

export const PlayIcon = ({ color = backgroundWhite }) => {
  return <SpriteIconFunctional id="Play" color={color} />;
};

export const PauseIcon = ({ color = backgroundWhite }) => {
  return <SpriteIconFunctional id="Pause" color={color} />;
};

export const RewindIcon = ({ color = accent.ocean450 }) => {
  return <SpriteIconFunctional id="Replay-10" color={color} />;
};

export const FastForwardIcon = ({ color = accent.ocean450 }) => {
  return <SpriteIconFunctional id="Forward-10" color={color} />;
};

export const SkipNextIcon = ({ color = accent.ocean450 }) => {
  return <SpriteIconFunctional id="Fast-forward" color={color} />;
};

export const SkipBackIcon = ({ color = accent.ocean450 }) => {
  return <SpriteIconFunctional id="Fast-rewind" color={color} />;
};
