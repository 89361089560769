import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  t,
  actions,
  selectors,
  generateFullName,
  usePortfolioRelationshipFilter,
  usePortfolioExternalReportingFilter,
  usePortfolioTaxClassFilter,
  usePortfolioInvestmentAccountsFilter,
  config,
} from '@formue-app/core';

import { accent } from '../../constants/colors';
import { H1, H2, Paragraph, ParagraphLarge } from '../texts';
import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';

import { StrategyPicker } from '../portfolio/strategy/StrategyPicker';
import { BaseSection } from '../common/BaseSection';
import { GridElement } from '../layout';
import { SlideTableOfContents } from './SlideTableOfContents';
import { SpriteIcon } from '../common/SpriteIcon';
import { Button } from '../buttons';
import { PeriodFilter, ToggleFilterGroup } from '../portfolio/filters';

const EditButton = styled(Button)`
  border-radius: 50px;
  padding: 8px 16px;
  margin-top: auto;
  border: 1px solid ${accent.ocean440};
  background: none;
  margin-bottom: 0;

  ${(props) =>
    props.editSlides &&
    css`
      margin-bottom: ${SPACING_16};
      background: ${accent.ocean408};
    `}

  svg {
    margin-right: 6px;
  }

  &:hover {
    background: ${accent.ocean408};
  }
`;

const StyledStrategyPicker = styled(StrategyPicker)`
  flex-direction: column;

  .select {
    margin-left: 0;
    width: fit-content;
    max-width: 100%;
    border: 1px solid ${accent.ocean440};
  }

  .content {
    margin-top: 9px;
    position: relative;
    transform: none;
    bottom: 0;
    translate: 0;
    border: 1px solid ${accent.ocean440};
    box-shadow: none;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const StyledToggleFilterGroup = styled(ToggleFilterGroup)`
  &:hover {
    background-color: ${accent.sand150};
    box-shadow: none;
    cursor: pointer;
  }

  background-color: ${accent.sand150} !important;

  & + div {
    background-color: ${accent.sand150} !important;
  }
`;

const StyledPeriodFilter = styled(PeriodFilter)`
  &:hover {
    background-color: ${accent.sand150};
    box-shadow: none;
    cursor: pointer;
  }
`;

const {
  ui: { presentation: uiPresentationActions },
} = actions;

const {
  ui: {
    user: { currentUserSelector },
  },
} = selectors;

export const PresentationShareConfiguration = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const portfolioFilters = useSelector((state) => state.ui.portfolio.filters);

  const { slides, setSubmitted } = props;

  const [selectedSlides, setSelectedSlides] = useState(slides);
  const [enabledSlides, setEnabledSlides] = useState(
    selectedSlides.filter((item) => item.enabled)
  );
  const [editSlides, setEditSlides] = useState(false);
  const [, setStrategy] = useState(null);
  const [activeStrategyId, setActiveStrategyId] = useState(null);

  useEffect(() => {
    setEnabledSlides(selectedSlides.filter((item) => item.enabled));
  }, [selectedSlides]);

  return (
    <>
      <GridElement columns={7}>
        <H1 style={{ marginBottom: SPACING_24 }}>
          {t('presentation:share:title')}
        </H1>
        <ParagraphLarge style={{ marginBottom: SPACING_48 }}>
          {t('presentation:share:intro', {
            name: generateFullName(currentUser),
          })}
        </ParagraphLarge>
      </GridElement>

      <BaseSection columns={12}>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('presentation:share:selectedScreensTitle')}
        </H2>
        <Paragraph style={{ marginBottom: SPACING_24 }}>
          {t('presentation:share:selectedScreensIntro', {
            numberOfSelectedSlides: enabledSlides.length,
            numberOfSlides: slides.length,
          })}
        </Paragraph>

        <EditButton
          onClick={() => setEditSlides(!editSlides)}
          editSlides={editSlides}
        >
          <SpriteIcon
            id="Settings"
            size={SPACING_16}
            style={{ verticalAlign: 'baseline' }}
          />
          <span>{t('presentation:share:selectedScreensButton')}</span>
        </EditButton>

        {editSlides ? (
          <SlideTableOfContents
            // Make all slides editable when in share config
            slides={selectedSlides.map((item) => ({ ...item, editable: true }))}
            setActiveSlides={setSelectedSlides}
            editMode={true}
            style={{ gap: SPACING_8 }}
          />
        ) : null}
      </BaseSection>

      <BaseSection columns={6} columnOffset={0}>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('presentation:share:costumerTitle')}
        </H2>
        <Paragraph style={{ marginBottom: SPACING_24 }}>
          {t('presentation:share:costumerIntro')}
        </Paragraph>

        <StyledStrategyPicker
          setDryrunStrategy={setStrategy}
          setDryrunActiveStrategyId={setActiveStrategyId}
          dryrunActiveStrategyId={activeStrategyId}
          dryrun={true}
          color={accent.ocean190}
          textColor={accent.ocean4}
          filterStrategies={false}
        />
      </BaseSection>

      <BaseSection columns={6} columnOffset={7}>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('presentation:share:portfolioTitle')}
        </H2>
        <Paragraph style={{ marginBottom: SPACING_24 }}>
          {t('presentation:share:portfolioIntro')}
        </Paragraph>

        <FilterWrapper>
          <StyledToggleFilterGroup
            title={t('filters:relationshipsTitle')}
            options={usePortfolioRelationshipFilter()}
          />
          <StyledToggleFilterGroup
            title={t('filters:accountTitle')}
            options={usePortfolioExternalReportingFilter().map((item) => ({
              ...item,
              name: t(`filters:${item.name}`, {
                companyName: config.title,
              }),
            }))}
          />
          <StyledToggleFilterGroup
            title={t('filters:taxClassTitle')}
            options={usePortfolioTaxClassFilter()}
          />
          <StyledToggleFilterGroup
            title={t('filters:investmentSavingsAccount')}
            options={usePortfolioInvestmentAccountsFilter()}
          />
          <StyledPeriodFilter title={t('filters:timeSpanTitle')} />
        </FilterWrapper>
      </BaseSection>

      <GridElement columns={6}>
        <Button
          onClick={() => {
            setSubmitted(true);
            dispatch(
              uiPresentationActions.generatePresentation({
                userId: currentUser.ssid,
                slides: enabledSlides.map((item) => item.id),
                options: {
                  strategyId: activeStrategyId,
                  ...portfolioFilters,
                },
              })
            );
          }}
        >
          {t('presentation:share:submitButton')}
        </Button>
      </GridElement>
    </>
  );
};
