import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { t, actions } from '@formue-app/core';

import { FlatList } from '../lists/FlatList';
import { H4, ParagraphXSmall } from '../texts';
import { ButtonSmall } from '../buttons';
import { SPACING_8 } from '../../constants/spacing';

const { authenticatorDevices: authenticatorDevicesActions } = actions.entities;

const RowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: ${SPACING_8};
`;

const Button = styled(ButtonSmall)`
  width: auto;
`;

const Details = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flext-start;
`;

const AuthenticatorDeviceRow = (props) => {
  const {
    device: { id, deviceName, platform, createdAt, lastUsedAt },
  } = props;

  const dispatch = useDispatch();

  const deleteDevice = () => {
    dispatch(authenticatorDevicesActions.destroyRequestBegin(id));
  };

  return (
    <RowContainer>
      <Details>
        <H4 style={{ paddingBottom: SPACING_8 }}>
          {deviceName} - {platform}
        </H4>
        <ParagraphXSmall>Last used at: {lastUsedAt}</ParagraphXSmall>
        <ParagraphXSmall>Created at: {createdAt}</ParagraphXSmall>
      </Details>
      <Button onClick={() => deleteDevice()}>Delete</Button>
    </RowContainer>
  );
};

export const AuthenticatorDeviceList = (props) => {
  const { devices, columns = 6 } = props;

  if (!devices?.length) {
    return null;
  }

  return (
    <FlatList
      columns={columns}
      rows={devices}
      keyExtractor={(item, index) => `authenticator-device-${index}`}
      renderItem={(device) => <AuthenticatorDeviceRow device={device} />}
    />
  );
};
