import { takeLatest, call } from 'redux-saga/effects';

import { meetingFeedback } from '../../../api';
import { actions } from './actions';

function* sendFeedback() {
  yield takeLatest(actions.sendFeedback, function* (payload) {
    yield call(meetingFeedback.post, payload.payload);
  });
}

export const sagas = [sendFeedback];
