import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  formatBigMoney,
  formatHexOpacity,
  formatTwrTicks,
  lightenColor,
} from '@formue-app/core';
import { accent, backgroundWhite } from '../../../constants/colors';
import { bodyFontStack } from '../../../fonts';

export const PortfolioChartTooltip = ({
  x,
  y,
  datum,
  width,
  height,
  dx,
  dy,
  color = accent.ocean3,
  backgroundColor = accent.ocean4,
  lineColor = accent.ocean3,
  dataExtractor,
}) => {
  const portfolio = useSelector((state) => state.ui.portfolio);
  const { calculatedAt } = portfolio;

  const rectangleWidth = 40;
  const rectangleHeight = 20;
  let date = moment(datum.x, 'YYYYMM');
  let xMax = x;
  let yMax = y;

  // We get the dates as the last days of the month.
  // For example if today is: 05052020 the input will be 31052020.
  // If that's the case, show the "calculatedAt" day instead.
  // Basically, the last date displayed
  // in the tooltip should be the last calculated value.
  if (date > moment(calculatedAt)) date = moment(calculatedAt);

  // If we want to keep the tooltip rectangle within the dimensions of the chart we need
  // to limit the xMax / yMax positions. On the x-axis it needs to be done on both sides.
  // On y-axis it needs to be done only on top due to the default position of the
  // tooltip (always on top)
  if (x + rectangleWidth > width) xMax = width - rectangleWidth / 2;
  if (x - rectangleWidth < 0) xMax = rectangleWidth / 2;
  if (y - 30 < 0) yMax = 30;

  return (
    <g>
      <line
        x1={x}
        y1="0"
        x2={x}
        y2="300"
        stroke={lineColor}
        strokeWidth={0.5}
      />
      <rect
        x={xMax - rectangleWidth / 2}
        y={yMax - rectangleHeight - 10}
        width={rectangleWidth}
        dx={dx}
        dy={dy}
        height={rectangleHeight}
        rx="3"
        fill={formatHexOpacity(backgroundColor, 0.8)}
        stroke={lightenColor(lineColor, 0.2)}
        strokeWidth={0.5}
      />
      <text
        x={xMax}
        y={yMax - 22}
        width={rectangleWidth}
        textAnchor="middle"
        fontSize="6"
        fill={backgroundWhite}
        fontFamily={bodyFontStack}
      >
        {dataExtractor === 'mv'
          ? formatBigMoney(datum.mv, false)
          : formatTwrTicks(datum.twr, 1, true)}
      </text>
      <text
        x={xMax}
        y={yMax - 13.5}
        width={rectangleWidth}
        textAnchor="middle"
        fontSize="4.5"
        fill={backgroundWhite}
        fontWeight={100}
        fontFamily={bodyFontStack}
      >
        {date.format('DD.MM.YYYY')}
      </text>

      <circle
        cx={x}
        cy={y}
        r="2.5"
        fill={color}
        stroke="#fff"
        strokeWidth={0.5}
      />
    </g>
  );
};
