import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from '@formue-app/core';

const excludedPathsList = [
  { from: '/portfolio/holdings/securities', to: '/portfolio/holdings' },
];

const inExcludedPathsList = (pathname, previousPathname) => {
  return excludedPathsList.find(
    (path) => path.to === pathname && path.from === previousPathname
  );
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    // We want to use "scroll to top" when routes change as a default
    // but there are some cases when we don't want that to happen.
    if (inExcludedPathsList(pathname, previousPathname)) return;

    window.scrollTo(0, 0);
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
