import React from 'react';
import styled from 'styled-components';

import { accent } from '../../constants/colors';
import { mobileCondition } from '../../constants/media';
import { SummaryBox } from '../common/SummaryBox';

const StyledLine = styled.hr`
  border: none;
  border-top: 1px solid ${accent.sand1};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  margin: 0 auto;

  @media ${mobileCondition} {
    margin-right: auto;
    margin-left: 0;
    text-align: left;
  }
`;

export const ContentList = (props) => {
  const { rows, ...rest } = props;

  return (
    <SummaryBox {...rest}>
      {rows.map((item, index) => (
        <React.Fragment key={index}>
          <Content>{item.content}</Content>
          {index < rows.length - 1 ? <StyledLine /> : null}
        </React.Fragment>
      ))}
    </SummaryBox>
  );
};
