import React from 'react';
import styled from 'styled-components';

import { SPACING_16 } from '../../constants/spacing';
import { paragraphSize, paragraphLineHeight } from '../../constants/text';

import ListItemIcon from '../../assets/icons/arrows/arrow-right-list.svg';

const StyledList = styled.ul`
  padding: 0;
  margin: ${SPACING_16} 0;
  list-style: none;
`;

const StyledListItem = styled.li`
  padding-left: ${SPACING_16};
  margin-bottom: ${SPACING_16};
  font-size: ${paragraphSize};
  line-height: ${paragraphLineHeight};
  background-image: url(${ListItemIcon});
  background-repeat: no-repeat;
  background-position: 0px 3px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const List = (props) => {
  const { children } = props;

  return <StyledList {...props}>{children}</StyledList>;
};

export const ListItem = (props) => {
  const { children } = props;

  return <StyledListItem {...props}>{children}</StyledListItem>;
};
