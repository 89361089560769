import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { useCountUp } from 'react-countup';
import { H1 } from '../texts';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const InstrumentsLoading = (props) => {
  const { reset } = props;
  const mvCounter = useRef(null);

  const { start } = useCountUp({
    ref: mvCounter,
    start: 0,
    end: 1000,
    duration: 3,
    useEasing: true,
    startOnMount: false,
    delay: 0,
  });

  useEffect(() => {
    if (reset) {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Container>
      <H1 style={{ fontSize: 160, lineHeight: '160px' }} ref={mvCounter}>
        0
      </H1>
    </Container>
  );
};
