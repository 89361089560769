import React from 'react';
import styled from 'styled-components';
import { SubMenuPage } from '../base/SubMenuPage';
import { GridElement } from '../../components/layout';
import { FilterButton } from '../../components/portfolio/FilterButton';

import { mobileCondition, printCondition } from '../../constants/media';
import { SPACING_8 } from '../../constants/spacing';

const FilterButtonWrapper = styled(GridElement)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  grid-column: span 3;

  @media ${mobileCondition} {
    align-items: flex-end;
    grid-column: span 2;
    margin-top: ${SPACING_8};
    padding: ${SPACING_8} 0;
  }

  @media ${printCondition} {
    display: none;
  }
`;

export const PortfolioPage = (props) => {
  const { routes, location } = props;

  const currentPath = routes
    .map((item) => (item.routes?.length ? item.routes : item))
    .flat()
    .find((item) => item.path === location.pathname);

  return (
    <SubMenuPage {...props}>
      {!currentPath?.hideFilter ? (
        <FilterButtonWrapper columns={4}>
          <FilterButton />
        </FilterButtonWrapper>
      ) : null}
    </SubMenuPage>
  );
};
