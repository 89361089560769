import React from 'react';
import styled from 'styled-components';
import {
  t,
  getAssetClassName,
  getColorForAssetClass,
  sortByAssetClass,
  sortByAssetGroup,
  percentage,
  sumAssetClassGroups,
} from '@formue-app/core';

import {
  accent,
  assetClassDefaultColorScheme,
} from '../../../constants/colors';
import { SPACING_32, SPACING_24, SPACING_16 } from '../../../constants/spacing';

import { DotIcon } from '../../common/DotIcon';
import { FixedPyramidChart } from './FixedPyramidChart';
import { BaseSection } from '../../common/BaseSection';
import { H2, H4 } from '../../texts';
import { GridElement } from '../../layout/GridElement';
import { tabletCondition } from '../../../constants/media';

const StyledGridElement = styled(GridElement)`
  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const Entry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: ${SPACING_16} 0;
  border-bottom: 2px solid ${accent.sand3};

  &:last-child {
    border: none;
  }
`;

const StyledFixedPyramidChart = styled(FixedPyramidChart)`
  margin: 0 auto;
  margin-bottom: ${SPACING_32};
`;

export const PlacementGraph = (props) => {
  const { strategy } = props;

  if (!strategy.length) {
    return null;
  }

  const assetClassGroupData = sumAssetClassGroups(
    strategy,
    assetClassDefaultColorScheme
  );

  const assetClassData = {
    title: t('wealthPlan:investmentSimulator:placementGraph:assetClasses'),
    amount: null,
    items: strategy.map((item) => ({
      name: getAssetClassName(item.assetClass),
      color: getColorForAssetClass(
        item.assetClass,
        assetClassDefaultColorScheme
      ),
      formatType: 'percent',
      assetClass: item.assetClass,
      amount: item.share,
    })),
  };

  return (
    <StyledGridElement columns="6">
      <H2 style={{ marginBottom: SPACING_16, marginTop: SPACING_24 }}>
        {t('wealthPlan:investmentSimulator:placementGraph:title')}
      </H2>
      <BaseSection>
        <StyledFixedPyramidChart
          height={175}
          data={assetClassGroupData.sort(sortByAssetGroup).reverse()}
        />
        {assetClassData.items.sort(sortByAssetClass).map((item, index) => (
          <Entry key={`entry-${index}`}>
            {item.color ? <DotIcon radius={12} color={item.color} /> : null}
            <H4>{item.name}</H4>
            <H4 style={{ marginLeft: 'auto' }}>
              {percentage(item.amount * 100, 0)}
            </H4>
          </Entry>
        ))}
      </BaseSection>
    </StyledGridElement>
  );
};
