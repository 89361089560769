import React from 'react';
import styled from 'styled-components';

import { generateInitials, useImageUrlWithAuthToken } from '@formue-app/core';

import { accent } from '../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

import { SpriteIcon } from './SpriteIcon';

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  border-radius: 50%;
  background: ${(props) =>
    props.url ? 'none' : props.color || accent.ocean170};
  align-items: center;
  justify-content: center;
  transition: background ${shortAnimationTime} ${easeInOut};
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

const Initials = styled.p`
  color: ${accent.ocean4};
  align-self: center;
  text-align: center;
  font-weight: 500;

  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.size * 0.407}px;
  line-height: ${(props) =>
    props.fontSize ? props.fontSize : props.size * 0.407}px;
  letter-spacing: ${(props) => props.size * 0.025}px;
  margin-left: ${(props) => props.size * 0.032}px;
`;

const InitialsSmallText = styled(Initials)`
  font-size: ${(props) => props.size * 0.23}px;
  line-height: ${(props) => props.size * 0.23}px;
  letter-spacing: ${(props) => props.size * 0.03}px;
`;

export const ProfileIcon = (props) => {
  const { name, size, url, image, fontSize, CustomIcon = null } = props;

  const urlWithAccessToken = useImageUrlWithAuthToken(url);
  const initials = typeof name === 'string' ? generateInitials(name) : name;

  return (
    <ImageContainer className="profile-icon" {...props}>
      {image && <Image size={size} src={image} />}
      {url && !image && <Image size={size} src={urlWithAccessToken} />}
      {initials ? (
        size > 20 ? (
          <Initials fontSize={fontSize} size={size}>
            {initials}
          </Initials>
        ) : (
          <InitialsSmallText fontSize={fontSize} size={size}>
            {initials}
          </InitialsSmallText>
        )
      ) : CustomIcon ? (
        <CustomIcon width={size / 2} height={size / 2} />
      ) : (
        <SpriteIcon
          id="Individual"
          inverted={true}
          navigational={true}
          size={size / 2}
        />
      )}
    </ImageContainer>
  );
};
