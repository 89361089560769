import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { documents } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';

// Create actions and a reducer for the expertAdvice resource
export const [actions, reducer, resourceSagas] = resourceReducer('documents');

// Create the sagas for this entity
function* readDocuments() {
  yield takeLatest(actions.indexRequestBegin, function*() {
    try {
      const result = yield call(documents.get, 'v2');
      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure(error));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

function* updateDocument() {
  // Documents api only allows setting the read attribute, so to simplify
  // we only require the id as payload not the whole document structure
  yield takeLatest(actions.updateRequestBegin, function*({ payload }) {
    const { args } = payload;
    const { id } = args;
    try {
      // let the saga continue executing by using fork instead of call
      yield fork(documents.update, id);

      // The PATCH endpoint in the API also does not return a body, it just returns 204
      // because of architectural reasons with document caching etc making it hard to
      // to do and be json:api compliant. This means that we don't have a body to normalize
      // and update the local store with here as we ideally should. So we need to manually
      // go into the store and update the read value on the document with the matching id.
      const document = yield select(state => state.entities.documents.byId[id])
      document.read = true;
      // recreate what we should have gotten back as a request body
      const fakeBody = {
        data: {
          id,
          type: 'documents',
          attributes: document,
        }
      }
      const normalized = normalize(fakeBody);
      yield put(actions.updateRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.updateRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readDocuments, updateDocument, ...resourceSagas];
