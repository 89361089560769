import React, { useRef } from 'react';
import { maxBy, minBy } from 'lodash';
import styled from 'styled-components';

import { HorizontalBar } from './HorizontalBar';
import { useResize } from '../../services/hooks';
import { HorizontalBarChartAxis } from './HorizontalBarChartAxis';
import { ParagraphSmall } from '../texts';
import { truncateString } from '@formue-app/core';
import { SPACING_24 } from '../../constants/spacing';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
`;

const StyledChartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${SPACING_24};
`;

const Label = styled(ParagraphSmall)`
  padding: 8px 0;
  height: 36px;
  text-align: right;
`;

export const HorizontalBarChart = (props) => {
  const {
    data,
    animate,
    angle,
    style,
    axis,
    headroom = 15,
    scale = 0.65,
    labelsOutside,
    numberOfTicks,
    valueFormat,
    valueMargin,
    renderLabel = true,
    ...rest
  } = props;

  const containerRef = useRef();
  const { width, height } = useResize(containerRef);

  if (!data.length) return null;

  const { value: maxValue } = maxBy(data, 'value');
  const { value: minValue } = minBy(data, 'value');

  const headroomPercentage = (100 + headroom) * 0.01;

  // 15% headroom for the icons
  let range =
    minValue < 0
      ? Math.abs(minValue) + Math.abs(maxValue) * headroomPercentage
      : Math.abs(maxValue) * headroomPercentage;

  // range can't be zero, otherwise we'll get an "infinite" coef value
  if (range === 0) {
    range = 1;
  }

  const coef = (width / range) * headroomPercentage * scale;

  return (
    <StyledContainer style={style}>
      {labelsOutside && renderLabel && (
        <LabelsContainer>
          {data.map((item, index) => (
            <Label key={`label-${index}`}>
              {truncateString(item.label, 34)}
            </Label>
          ))}
        </LabelsContainer>
      )}
      <StyledChartContainer
        ref={containerRef}
        style={{ paddingBottom: axis ? 30 : 0 }}
      >
        {axis && (
          <HorizontalBarChartAxis
            max={maxValue}
            min={minValue < 0 && minValue}
            coef={coef}
            height={height}
            valueFormat={valueFormat}
            numberOfTicks={numberOfTicks}
          />
        )}
        {data.map((item, index) => {
          return (
            <HorizontalBar
              value={item.value}
              label={item.label}
              color={item.color}
              barType={item.barType}
              delay={item.delay}
              angle={angle}
              coef={coef}
              animate={animate}
              index={index}
              key={index}
              labelsOutside={labelsOutside}
              valueFormat={valueFormat}
              isMaxValueBar={maxValue === item.value}
              valueMargin={valueMargin}
              renderLabel={renderLabel}
              {...rest}
            />
          );
        })}
      </StyledChartContainer>
    </StyledContainer>
  );
};
