import { createSelector } from 'reselect';
import { groupBy } from 'lodash';

const tradeOrdersById = state => state.entities.tradeOrders.byId;

export const switchBuntTradeOrdersSelector = createSelector(tradeOrdersById, items => {
  const result = Object.keys(items)
    .filter(key => items[key].switchId)
    .map(key => items[key]);
  return groupBy(result, item => item.switchId);
});
