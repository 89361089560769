export const LANGUAGE_TO_LANGUAGE_HEADER = {
  en: 'en',
  nb: 'nb-NO',
  sv: 'sv-SE',
  da: 'da-DK',
};

export const COUNTRY_CODE_TO_LANGUAGE_HEADER = {
  en: 'en',
  no: 'nb-NO',
  se: 'sv-SE',
  dk: 'da-DK',
};
