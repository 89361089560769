import { useQlikListData } from './useQlikListData';

/**
 * This hook will connect to Qlik using the provided Qlik app instance, fetch all the available
 * values for the given field and return an array of objects that can be used to generate a select
 * box for filtering purposes.
 *
 * @param {*} app - The Qlik app instance
 * @param {*} selections - The Qlik selections state object
 * @param {*} fieldName - The field you want to filter on
 * @returns {Array.<{value: String, label: String, selected: Boolean, disabled: Boolean}>}
 * [
 *   {
 *     value: 'darthvader',
 *     label: 'Darth Vader',
 *     selected: false,
 *     disabled: false,
 *   },
 *   ...
 * ]
 */
export const useQlikFilterOptions = (app, selections, field) => {
  const { name: fieldName, dataType } = field;

  // This is a list of all values for this field in Qlik
  const data = useQlikListData(app, selections, fieldName);

  // Create a list of options that we can use in a Select box
  const options = data
    ?.map((datum) => {
      const { value, selected, disabled } = datum;

      return {
        label: value,
        value,
        disabled,
        selected,
      };
    })
    .sort((a, b) => {
      // First sort by the disabled state
      if (a.disabled && !b.disabled) {
        return 1;
      }
      if (!a.disabled && b.disabled) {
        return -1;
      }

      // Then sort alphabetically
      return a.value.localeCompare(b.value);
    });

  /**
   * When we use filter options, we very likely also want to be able do changes
   * to the selected options. For convenience we also return an event handler
   * from this hook.
   */
  const onChangeHandler = (selectedValues) => {
    const values = selectedValues.map((value) => {
      if (dataType === 'Number') {
        return Number(value);
      }
      return value;
    });
    // Use the Qlik app instance to update the selections
    app.field(fieldName).selectValues(values);
  };

  return [options, onChangeHandler];
};
