import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { allInvestmentStrategiesSelector } from '@formue-app/core/src/store/entities/selectors/investmentStrategies';
import { generateStrategyAccentColors } from '../../../services/utils/colorize';

export const useEnabledStrategies = () => {
  const investmentStrategies = useSelector(allInvestmentStrategiesSelector);

  const queryParams = new URLSearchParams(
    document.location.search.substring(1)
  );

  const enabledStrategiesIds = queryParams.getAll('enabledStrategies');

  const filteredStrategies = investmentStrategies
    .filter((strategy) => enabledStrategiesIds.includes(strategy.id))
    .map((item, index) => ({
      ...item,
      color: generateStrategyAccentColors(index),
    }));

  const [enabledStrategies, setEnabledStrategies] =
    useState(filteredStrategies);

  useEffect(() => {
    setEnabledStrategies(filteredStrategies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enabledStrategies.length,
    investmentStrategies.length,
    filteredStrategies.length,
  ]);

  return enabledStrategies;
};
