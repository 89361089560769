import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { SPACING_32, SPACING_24, SPACING_16 } from '../../../constants/spacing';
import { mobileCondition } from '../../../constants/media';

import { KnowledgeAndExperienceBar } from './KnowledgeAndExperienceBar';
import { KnowledgeAndExperienceTable } from './KnowledgeAndExperienceTable';

import { BaseSection } from '../../common/BaseSection';
import { ExpandableListItem } from '../../common/ExpandableListItem';
import { H3 } from '../../texts';

const {
  entities: {
    investmentProfile: { riskCapacityAssetClassSelector },
  },
} = selectors;

const StyledContainer = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
`;

const StyledExpandableListItem = styled(ExpandableListItem)`
  margin: auto -${SPACING_24} 0;

  @media ${mobileCondition} {
    margin: auto -${SPACING_16} 0;
  }
`;

const baseItemStyles = css`
  padding: ${SPACING_16} ${SPACING_24};
  margin-left: -${SPACING_24};
  margin-right: -${SPACING_24};
  border-top: 1px solid ${accent.sand1};
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @media ${mobileCondition} {
    margin-left: -${SPACING_16};
    margin-right: -${SPACING_16};
  }
`;

export const KnowledgeAndExperience = ({ ...props }) => {
  const { knowledge, experience } = useSelector(riskCapacityAssetClassSelector);

  return (
    <StyledContainer {...props}>
      <H3>{t('wealthPlan:investmentProfile:knowledgeAndExperienceTitle')}</H3>
      <KnowledgeAndExperienceBar
        title={t('wealthPlan:investmentProfile:knowledgeAbout')}
        total={7}
        selected={knowledge.length}
        style={{ marginBottom: SPACING_32, marginTop: SPACING_24 }}
      />
      <KnowledgeAndExperienceBar
        style={{ marginBottom: SPACING_32 }}
        title={t('wealthPlan:investmentProfile:experienceWith')}
        total={7}
        selected={experience.length}
      />
      <StyledExpandableListItem
        seeMoreText={t(
          'wealthPlan:investmentProfile:knowledgeAndExperienceReadMore'
        )}
        height={0}
        flipArrowDirection={['up', 'down']}
        baseItemStyles={baseItemStyles}
      >
        <KnowledgeAndExperienceTable />
      </StyledExpandableListItem>
    </StyledContainer>
  );
};
