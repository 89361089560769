import React from 'react';
import styled, { css } from 'styled-components';

import { H4Size, H4LineHeight } from '../../constants/text';
import { textLink } from '../../constants/colors';

export const h4Style = css`
  font-size: ${H4Size};
  line-height: ${H4LineHeight};
  font-weight: 500;
`;

export const StyledH4 = styled.h4`
  ${h4Style}

  a {
    color: ${textLink};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const H4 = (props) => {
  const { children } = props;

  return <StyledH4 {...props}>{children}</StyledH4>;
};
