import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';

import {
  easeInOut,
  extraLongAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';
import { accent } from '../../../constants/colors';
import {
  SPACING_32,
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_16,
  SPACING_64,
} from '../../../constants/spacing';
import { H6LineHeight, H6Size } from '../../../constants/text';
import { H1XL, H6, ParagraphLarge } from '../../texts';
import { SidebarImage } from '../SidebarImage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Title = styled(H1XL)`
  margin-bottom: ${SPACING_32};
  margin-top: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const Description = styled(ParagraphLarge)`
  position: relative;
  max-width: 600px;
  top: 20px;
  opacity: 0;
  transition: opacity ${easeInOut} ${extraLongAnimationTime},
    top ${easeInOut} 1s;
  transition-delay: ${slideAnimationDelay}s, ${slideAnimationDelay}s;

  ${(props) =>
    props.isActiveSlide &&
    `
    top: 0;
    opacity: 1;
  `}
`;

const TextContainer = styled.div`
  width: 60%;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: 0;
`;

const AgendaOverview = styled.ol`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_32};
  list-style: decimal !important;
  padding: 0;
  padding-left: ${SPACING_16};
  margin: 0;
  margin-top: ${SPACING_64};
`;

const AgendaListItem = styled.li`
  font-size: ${H6Size};
  line-height: ${H6LineHeight};

  position: relative;
  right: -20px;
  opacity: 0;
  transition: opacity ${easeInOut} ${extraLongAnimationTime},
    right ${easeInOut} 1s;
  transition-delay: ${(props) => `${0.5 + props.index * 0.2}s`}, 0.5s;

  ${(props) =>
    props.isActiveSlide &&
    `
    right: 0;
    opacity: 1;
  `}
`;

const {
  ui: {
    presentation: { agendaItemsSelector },
  },
} = selectors;

export const AgendaItemHighlight = (props) => {
  const {
    id,
    agendaItemId,
    isActiveSlide,
    titleColor,
    highlightColor,
    listColor,
    presentationType,
  } = props;

  const agendaItems = useSelector(agendaItemsSelector);

  const highlightedItem = agendaItems.find((item) => item.id === agendaItemId);

  return (
    <Wrapper>
      <TextContainer>
        <Title style={{ color: accent[titleColor] }}>
          {t(highlightedItem.title)}
        </Title>
        <Description
          isActiveSlide={isActiveSlide}
          style={{ color: accent[titleColor] }}
        >
          {t(highlightedItem.description)}
        </Description>
        <AgendaOverview>
          {agendaItems.map((item, index) => (
            <AgendaListItem
              index={index}
              isActiveSlide={isActiveSlide}
              key={`agenda-item-${index}`}
              style={{
                color:
                  agendaItemId === item.id
                    ? accent[highlightColor]
                    : accent[listColor],
              }}
            >
              <H6
                style={{
                  color:
                    agendaItemId === item.id
                      ? accent[highlightColor]
                      : accent[listColor],
                }}
              >
                {t(item.title)}
              </H6>
            </AgendaListItem>
          ))}
        </AgendaOverview>
      </TextContainer>
      <SidebarImage id={id} presentationType={presentationType} />
    </Wrapper>
  );
};
