import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, useResource } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_24,
  SPACING_64,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { RiskAndReturnChart } from '../investmentStrategyComponents/RiskAndReturnChart';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { ChartLegend } from '../slideComponents/portfolio/ChartLegend';

const Base = styled.div`
  height: 100%;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  display: flex;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const InnerChartWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 0 10px;
`;

const StyledChartLegend = styled(ChartLegend)`
  padding: 0 ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_24};
  z-index: 1;
`;

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsMetadataSelector,
    },
  },
} = selectors;

export const ExpectedRiskAndReturnSlide = (props) => {
  const { isActiveSlide } = props;

  const [selected, setSelected] = useState([]);
  const strategies = useEnabledStrategies();
  const strategiesMeta = useSelector(
    investmentStrategyAssetAllocationsMetadataSelector
  );

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    ...strategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  // Colorize the items after we combine it with the "props data"
  const chartData = strategies.map((strategy, index) => ({
    title: strategy.title,
    key: strategy.id,
    risk: strategiesMeta[strategy.id]?.expectedPerformance.risk,
    return: strategiesMeta[strategy.id]?.expectedPerformance.return,
    color: strategy.color,
  }));

  return (
    <Base>
      <ChartWrapper style={{ position: 'relative' }}>
        <StyledChartLegend
          isSelectable
          selectMultiple
          selectedCallback={(selectedItem) => {
            setSelected(selectedItem);
          }}
          textColor={accent.ocean490}
          selectedTextColor={accent.ocean490}
          selectedBackgroundColor="#E0EBF5"
          selectedBorderColor={accent.ocean2}
          items={strategies.map((strategy, index) => ({
            label: strategy.title,
            key: strategy.id,
            color: strategy.color,
          }))}
        />
        {loading ? (
          <CenteredActivityIndicator />
        ) : (
          <InnerChartWrapper style={{ paddingTop: SPACING_64 }}>
            <RiskAndReturnChart
              selected={selected}
              chartData={chartData}
              animate={isActiveSlide}
            />
          </InnerChartWrapper>
        )}
      </ChartWrapper>
    </Base>
  );
};
