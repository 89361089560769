import { createActions } from 'redux-actions';

export const actions = createActions({
  SIGNING_EVENTS: {
    ADD_TO_IN_PROGRESS: id => id,
    REMOVE_FROM_IN_PROGRESS: id => id,
    SET_SHOULD_POLL: value => value,
    START_POLLING: () => ({}),
    STOP_POLLING: () => ({}),
  },
}).signingEvents;
