const ranges = ['0-2', '3-5', '6-10', '10+'];
const yearGroupOffset = [2, 5, 10];

export const formatTimeHorizionData = (data, total) => {
  const filteredData = data.filter((item) => Boolean(item.value));
  const totalAmount = filteredData.reduce((acc, curr) => acc + curr.value, 0);
  const currentYear = new Date().getFullYear();
  let yearStartOffset = -1;

  const dataByYearGroup = yearGroupOffset.map((yearEndOffset) => {
    // Find all records where the transactionAt year is within our range
    const groupedData = filteredData.filter((item) => {
      const yearIndex = new Date(item.transactedAt).getFullYear() - currentYear;
      return yearIndex > yearStartOffset && yearIndex <= yearEndOffset;
    });

    yearStartOffset = yearEndOffset;

    // In the cashflow summary we count positive values as 0 as we only want to know the burnrate
    return groupedData.map((item) => {
      const value = item.value < 0 ? item.value : 0;
      return {
        value: item.flexible ? 0 : value,
        flexible: item.flexible ? value : 0,
      };
    });
  });

  // Add 10+ years manually as it's in reality a totally different value then the rest of the age
  // groups. The other age groups are cashflow/burn rate for each of the years. But the 10+ years is
  // a representation of the remaining value.
  // So total portfolio amount (for strategy) - total amount of age groups
  dataByYearGroup.push([{ value: total + totalAmount, flexible: 0 }]);

  return dataByYearGroup.map((yearGroup, index) => ({
    range: ranges[index],
    value: yearGroup.reduce((acc, val) => acc + val.value, 0),
    flexibleValue: yearGroup.reduce((acc, val) => acc + val.flexible, 0),
  }));
};

const dataByYearGroup = (data) => {
  const currentYear = new Date().getFullYear();
  return Array(10)
    .fill()
    .map((year, index) => {
      const yaer = currentYear + index;
      return data.filter((item) => {
        const yearIndex = new Date(item.transactedAt).getFullYear();
        return yearIndex === yaer;
      });
    });
};

export const formatYearlyData = (data) => {
  const filteredData = data.filter((item) => Boolean(item.value));

  const positiveValues = filteredData.filter((item) => item.value > 0);
  const negativeValues = filteredData.filter((item) => item.value < 0);

  return {
    positive: dataByYearGroup(positiveValues),
    negative: dataByYearGroup(negativeValues),
  };
};

export const maxForYear = (data) => {
  const filteredData = data.filter((item) => Boolean(item.value));
  const dataByYear = dataByYearGroup(filteredData);

  const max = Math.max(
    ...dataByYear.map((items) =>
      items.reduce((acc, item) => acc + Math.abs(item.value + item.flexible), 0)
    )
  );

  return max;
};
