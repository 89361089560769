import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from '../../store/ui/qlik';
import { config } from '../../config';

import { getFromAndToFromPeriodId } from '../../services/utilities/qlik';

/**
 * Used to instantiate a Qlik App using the require.js
 * @returns A Qlik Sense app
 */
export const useQlikApp = (theme = 'Utvidetrapportering') => {
  const qlikPrefix = '/qap';
  const [app, setApp] = useState();
  const [qlik, setQlik] = useState();
  const [requireJsInitialized, setRequireJsInitialized] = useState(
    Boolean(window.require)
  );
  const dispatch = useDispatch();
  const sessionId = useSelector((state) => state.ui.qlik.sessionId);

  // Calculate the max-age value of the Qlik session cookie. The max-age is 60 seconds
  // more than the expiration of the access token to client web. That way we are sure
  // that the code that handles expiration/re-auth of client web kicks in before the
  // qlik session expires.
  const tokenExp = useSelector((state) => state.auth.exp);
  const now = new Date();
  const accessTokenExpirationDate = new Date(tokenExp * 1000);
  const sessionMaxAge =
    (accessTokenExpirationDate.getTime() - now.getTime()) / 1000 + 60;

  const { domain } = config;
  const { host: qlikHost, appId } = config.qlik;

  // The Qlik Sense API's relies on cookies for authentication, since our instance
  // of qlik is hosted by qlik, on a domain we don't have control over we cannot rely
  // on cookies directly (3.party cookies), so we instead receive a cookie representing
  // a session id from our API that behaves like a proxy for all the qlik stuff. We proxy
  // the API and the websocket interface for qlik. The server takes is responsible for
  // authentication to qlik, and keeping track of the cookies we get issues by qlik.
  const qlikCookieName = 'X-Qlik-Session-Id';
  // Take the domain from config, e.g `kundenett.formue.no` and create the cookie domain setting
  // that will work for all subdomains of the top level domain, e.g `.formue.no`
  const qlikCookieDomain = `.${domain.split('.').slice(-2).join('.')}`;
  const [cookies, setCookie] = useCookies([qlikCookieName]);
  useEffect(() => {
    if (!sessionId) {
      dispatch(actions.createSession());
    }
  }, [sessionId, dispatch]);

  // After we have received a session id from the API we need to set that cookie
  // in the browser if it's not already present.
  useEffect(() => {
    if (!sessionId || cookies[qlikCookieName]) {
      return;
    }

    setCookie(qlikCookieName, sessionId, {
      path: '/',
      maxAge: sessionMaxAge + 3600, // add an hour just to test if the happens less
      domain: qlikCookieDomain,
      secure: false,
      sameSite: 'lax',
    });
  }, [sessionId, cookies]);

  // When we are sure we have a cookie, we can inject the require.js library, it relies
  // on the cookie for authentication
  useEffect(() => {
    if (!cookies[qlikCookieName]) {
      return;
    }

    // After we have a cookie we can inject the require.js library into the header, this needs to be
    // done after we have a cookie because this version of require.js is behind the same authentication
    // as the rest of qlik.
    const requireJsSource = `https://${qlikHost}${qlikPrefix}/resources/assets/external/requirejs/require.js`;
    const scriptAlreadyAdded = Boolean(
      document.querySelectorAll(`[src="${requireJsSource}"]`).length
    );
    if (!scriptAlreadyAdded) {
      const styleElement = document.createElement('link');
      styleElement.setAttribute('rel', 'stylesheet');
      styleElement.setAttribute(
        'href',
        `https://${qlikHost}${qlikPrefix}/resources/autogenerated/qlik-styles.css`
      );
      document.head.appendChild(styleElement);

      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('src', requireJsSource);
      document.head.appendChild(scriptElement);
    }

    let interval;
    if (!requireJsInitialized) {
      interval = setInterval(() => {
        const requireAdded = Boolean(window.require);
        if (requireJsInitialized !== requireAdded) {
          setRequireJsInitialized(requireAdded);
        }
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [cookies, requireJsInitialized, setRequireJsInitialized]);

  // After we have a session, we inject require.js so we need to wait until that is
  // available before we start doing the actual Qlik Sense stuff.
  useEffect(() => {
    if (!requireJsInitialized) {
      return;
    }

    const require = window.require;
    const config = {
      host: qlikHost,
      prefix: `${qlikPrefix}/`,
      port: 443,
      isSecure: true,
    };

    const baseUrl =
      (config.isSecure ? 'https://' : 'http://') +
      config.host +
      (config.port ? ':' + config.port : '') +
      config.prefix +
      'resources';

    require.config({ baseUrl });

    require(['js/qlik'], (qlik) => {
      setQlik(qlik);
      // Set the theme on the qlik client
      if (theme) {
        qlik.theme.apply(theme);
      }

      const qlikApp = qlik.openApp(appId, config);

      // Apply some default filtering as soon as we have the app available
      const { from, to } = getFromAndToFromPeriodId('TWELVEMONTHS');

      qlikApp
        .field('Period')
        .selectMatch(`=Period >= ${from} and Period <= ${to}`);
      /*
       * If we want to customize error handling, we can do it by using this deprecated method
       * which means we probably shouldn't do it. But if needs be, we can, at least for now.
       * https://help.qlik.com/en-US/sense-developer/May2021/Subsystems/APIs/Content/Sense_ClientAPIs/CapabilityAPIs/RootAPI/setOnError-method.htm
       *
       * One reason to do this could be to automatically refresh if there is a connection error
       * or similar.
       */
      qlikApp.on('error', (error) => {
        console.log('onError:', error);
      });

      setApp(qlikApp);
    });
  }, [requireJsInitialized]);

  useEffect(() => {
    return () => {
      // Make sure we close any connection to qlik when we unmount this
      // component. It will prevent the websocket from running until the
      // the browser closes.
      if (qlik) {
        qlik.close();
      }
    };
  }, []);

  return [app, qlik];
};
