import React from 'react';
import styled from 'styled-components';

import { accent } from '../../constants/colors';
import {
  paragraphXSmallLineHeight,
  paragraphXSmallSize,
} from '../../constants/text';

const StyledLabel = styled.label`
  font-size: ${paragraphXSmallSize};
  line-height: ${paragraphXSmallLineHeight};
  font-weight: 400;
  color: ${accent.ocean490};
`;

export const Label = (props) => {
  const { children, ...rest } = props;
  return <StyledLabel {...rest}>{children}</StyledLabel>;
};
